import { Semaphore } from "async-mutex";
import TokenBucket from "./TokenBucket";
import TokenBucketImpl from "./TokenBucketImpl";

export default class TokenBucketImplFactory {
    public static create(
        capacity: number
    ): TokenBucket {
        return new TokenBucketImpl(
            new Semaphore(capacity)
        );
    }
}