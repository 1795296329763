import {
    atomFamily,
    selectorFamily
} from "recoil";

import { ComponentInitializationState } from "./ComponentInitializationState";
import { ComponentReadyToRenderState } from "./ComponentReadyToRenderState";

export const DOCUMENT_COMPONENT = "DOCUMENT_COMPONENT";
export const TENDER_DETAILS_FORM_COMPONENT = "TENDER_DETAILS_FORM_COMPONENT";

export const componentIdToInitializationStateSelectorFamily = selectorFamily<ComponentInitializationState, string>({
    key: "componentIdToInitializationStateSelectorFamily",
    get: (componentId: string) => ({ get }) => {
        const componentReadyToRenderState = get(componentReadyToRenderStateByComponentIdAtomFamily(componentId));
        if (componentReadyToRenderState === ComponentReadyToRenderState.NOT_READY) {
            return ComponentInitializationState.PENDING;
        }
        const componentChildIds = get(parentIdToChildInitializationStateIds(componentId));
        const areAllChildrenInitialized = componentChildIds.every((childId) => {
            return get(componentIdToInitializationStateSelectorFamily(childId)) === ComponentInitializationState.INITIALIZED
        });
        if (!areAllChildrenInitialized) {
            return ComponentInitializationState.PENDING;
        }
        return ComponentInitializationState.INITIALIZED;
    }
});

export const componentReadyToRenderStateByComponentIdAtomFamily = atomFamily<ComponentReadyToRenderState, string>({
    key: "componentReadyToRenderStateByComponentIdAtomFamily",
    default: ComponentReadyToRenderState.NOT_READY
});

export const parentIdToChildInitializationStateIds = atomFamily<Array<string>, string>({
    key: "parentIdToChildInitializationStateIds",
    default: []
});
