import {
    Semaphore,
    SemaphoreInterface
} from "async-mutex";
import TokenBucket from "./TokenBucket";

export default class TokenBucketImpl implements TokenBucket {
    private readonly semaphoreTokens: Semaphore;
    private readonly tokenReleasers: Array<SemaphoreInterface.Releaser>;

    constructor(
        semaphoreTokens: Semaphore
    ) {
        this.semaphoreTokens = semaphoreTokens;
        this.tokenReleasers = [];
    }

    public async acquire(): Promise<void> {
        const [value, tokenReleaser] = await this.semaphoreTokens.acquire();
        this.tokenReleasers.push(tokenReleaser);
    }

    public async tryAcquire(): Promise<boolean> {
        if (this.semaphoreTokens.isLocked()) {
            return false;
        }
        await this.acquire();
        return true;
    }

    public addToken(): void {
        const tokenReleaser = this.tokenReleasers.pop();
        tokenReleaser?.();
    }

    public isBucketFull(): boolean {
        return this.tokenReleasers.length === 0;
    }
}
