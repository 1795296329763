// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const AttachmentType = {
  "IMAGE": "IMAGE",
  "VIDEO": "VIDEO"
};

const BidStatus = {
  "AWARDED": "AWARDED",
  "REJECTED": "REJECTED",
  "CANCELLED": "CANCELLED"
};

const DocumentStatus = {
  "DRAFT": "DRAFT",
  "SUBMITTED": "SUBMITTED",
  "ACCEPTED": "ACCEPTED",
  "REJECTED": "REJECTED"
};

const DesignDocumentStatus = {
  "AUTOSAVE_DRAFT": "AUTOSAVE_DRAFT",
  "DRAFT": "DRAFT",
  "PUBLISHED": "PUBLISHED",
  "APPROVED": "APPROVED"
};

const DimensionType = {
  "LINEAR": "LINEAR",
  "SQUARE": "SQUARE"
};

const PermissionType = {
  "VIEW": "VIEW",
  "EDIT": "EDIT",
  "DELETE": "DELETE",
  "CREATE_CHILDREN": "CREATE_CHILDREN",
  "MANAGE_STATE": "MANAGE_STATE",
  "SHARE_PERMISSION": "SHARE_PERMISSION",
  "REMOVE_PERMISSION": "REMOVE_PERMISSION"
};

const PermissionEntityType = {
  "USER": "USER",
  "TEAM": "TEAM"
};

const PermissionResourceType = {
  "PROPERTY": "PROPERTY",
  "LOCATION": "LOCATION",
  "ISSUE": "ISSUE",
  "DIMENSION": "DIMENSION",
  "WORK_SPECIFICATION": "WORK_SPECIFICATION",
  "ATTACHMENT": "ATTACHMENT",
  "QUOTE": "QUOTE",
  "SOLUTION": "SOLUTION",
  "PROPOSAL": "PROPOSAL"
};

const InspectionStatus = {
  "IN_PROGRESS": "IN_PROGRESS",
  "PAUSED": "PAUSED",
  "COMPLETED": "COMPLETED",
  "ABANDONED": "ABANDONED"
};

const IssueStatus = {
  "NONE": "NONE",
  "ARCHIVED": "ARCHIVED",
  "UNDER_REVIEW": "UNDER_REVIEW",
  "APPROVED": "APPROVED",
  "REJECTED": "REJECTED",
  "ON_HOLD": "ON_HOLD",
  "COMPLETED": "COMPLETED"
};

const LogSeverityLevel = {
  "DEBUG": "DEBUG",
  "INFO": "INFO",
  "WARN": "WARN",
  "ERROR": "ERROR"
};

const MeasurementUnit = {
  "METER": "METER",
  "CENTIMETER": "CENTIMETER",
  "FOOT": "FOOT",
  "INCH": "INCH"
};

const OrganizationType = {
  "CONSULTANT": "CONSULTANT",
  "PROPERTY_MANAGEMENT": "PROPERTY_MANAGEMENT",
  "SOLE_SOURCE": "SOLE_SOURCE",
  "VENDOR": "VENDOR"
};

const ProposalStatus = {
  "DRAFT": "DRAFT",
  "SUBMITTED": "SUBMITTED"
};

const ResourceType = {
  "PROPERTY": "PROPERTY",
  "PROPOSAL": "PROPOSAL",
  "SOLUTION": "SOLUTION",
  "SOLUTION_MINOR_VERSION": "SOLUTION_MINOR_VERSION",
  "WORKTYPE": "WORKTYPE",
  "EXTERNAL_REPORT": "EXTERNAL_REPORT",
  "TENDER": "TENDER",
  "TENDER_MINOR_VERSION": "TENDER_MINOR_VERSION",
  "BID": "BID",
  "BID_MINOR_VERSION": "BID_MINOR_VERSION"
};

const SolutionTenderingType = {
  "SOLE_SOURCE": "SOLE_SOURCE",
  "TENDERED": "TENDERED"
};

const SolutionStatus = {
  "NEW_DOCUMENT": "NEW_DOCUMENT",
  "DRAFT": "DRAFT",
  "PENDING_REVIEW": "PENDING_REVIEW",
  "APPROVED": "APPROVED",
  "CANCELED": "CANCELED"
};

const UserOrganizationAssociationType = {
  "MEMBER_OF": "MEMBER_OF"
};

const UserOrganizationRole = {
  "ESTIMATOR": "ESTIMATOR",
  "ENGINEER": "ENGINEER",
  "PROPERTY_MANAGER": "PROPERTY_MANAGER",
  "OWNER": "OWNER"
};

const WorkTypeAssociationScopeEntity = {
  "PROPERTY": "PROPERTY",
  "USER": "USER",
  "ORGANIZATION": "ORGANIZATION"
};

const TenderStatus = {
  "PUBLISHED": "PUBLISHED",
  "AWARDED": "AWARDED",
  "COMPLETE": "COMPLETE",
  "CANCELLED": "CANCELLED",
  "CLOSED": "CLOSED"
};

const TemplateType = {
  "SOLUTION": "SOLUTION"
};

const TemplateEntityType = {
  "USER": "USER",
  "TEAM": "TEAM"
};

const SolutionOperationType = {
  "SAVE": "SAVE",
  "PUBLISH": "PUBLISH"
};

const ResponseStatus = {
  "SUCCESS": "SUCCESS",
  "ERROR": "ERROR"
};

const CompleteEventStatus = {
  "SUCCESS": "SUCCESS",
  "ERROR": "ERROR"
};

const LinkAction = {
  "ISSUE_FORM": "ISSUE_FORM",
  "PROPERTY_FORM": "PROPERTY_FORM",
  "EXTERNAL_REPORT": "EXTERNAL_REPORT",
  "UNASSIGNED": "UNASSIGNED"
};

const MeetingStatus = {
  "ACTIVE": "ACTIVE",
  "ENDED": "ENDED"
};

const AttendeeRole = {
  "INSPECTOR": "INSPECTOR",
  "AGENT": "AGENT"
};

const ExternalReportType = {
  "ISSUE": "ISSUE",
  "ASSESSMENT": "ASSESSMENT"
};

const GenerateReportResourceType = {
  "SOLE_SOURCE": "SOLE_SOURCE",
  "BID": "BID",
  "TENDER_SUMMARY": "TENDER_SUMMARY"
};

const S3DownloadLocation = {
  "LOGO": "LOGO"
};

const NotificationChannel = {
  "EMAIL": "EMAIL",
  "SMS": "SMS"
};

const AccountClaimStatus = {
  "CLAIMED": "CLAIMED",
  "UNCLAIMED": "UNCLAIMED"
};

const MeetingLinkType = {
  "INSPECTOR": "INSPECTOR",
  "AGENT": "AGENT"
};

const { Attachment, BiddingContact, BidInvitation, Bid, BidMinorVersion, ClientLog, Counter, DesignDocument, DesignDocumentGroup, Dimension, DiscoverableWorkTypes, EntityResourcePermission, EntityWorkTypeAssociation, Inspection, Issue, Location, Note, Organization, Project, ProjectCounter, Property, PropertyCounter, Proposal, ProposalCounter, ResourceUserPermissions, Solution, SolutionMinorVersion, SolutionGroupCounter, UserGroup, UserGroupMember, UserOrganizationAssociation, WorkSpecification, WorkType, WorkTypeGroup, WorkTypePricing, DefaultWorkTypePricing, TemporaryWorkType, Tender, TenderMinorVersion, UserOnboardingProgress, User, Template, TemporaryAuthorizationCode, LinkRegistry, ExternalReportBranding, Meeting, Attendee, BidMetadata, SolutionMetadata, Measurement, TenderMetadata, CreateTemporaryAuthorizationTokenResponse, ListResourceAggregateUserPermissionsOutput, AggregateUserPermissions, CompleteEvent, GetAccountClaimStatusResponse, ChimeMeetingOutput, SearchUsersOutput, SearchOrganizationsOutput } = initSchema(schema);

export {
  Attachment,
  BiddingContact,
  BidInvitation,
  Bid,
  BidMinorVersion,
  ClientLog,
  Counter,
  DesignDocument,
  DesignDocumentGroup,
  Dimension,
  DiscoverableWorkTypes,
  EntityResourcePermission,
  EntityWorkTypeAssociation,
  Inspection,
  Issue,
  Location,
  Note,
  Organization,
  Project,
  ProjectCounter,
  Property,
  PropertyCounter,
  Proposal,
  ProposalCounter,
  ResourceUserPermissions,
  Solution,
  SolutionMinorVersion,
  SolutionGroupCounter,
  UserGroup,
  UserGroupMember,
  UserOrganizationAssociation,
  WorkSpecification,
  WorkType,
  WorkTypeGroup,
  WorkTypePricing,
  DefaultWorkTypePricing,
  TemporaryWorkType,
  Tender,
  TenderMinorVersion,
  UserOnboardingProgress,
  User,
  Template,
  TemporaryAuthorizationCode,
  LinkRegistry,
  ExternalReportBranding,
  Meeting,
  Attendee,
  AttachmentType,
  BidStatus,
  DocumentStatus,
  DesignDocumentStatus,
  DimensionType,
  PermissionType,
  PermissionEntityType,
  PermissionResourceType,
  InspectionStatus,
  IssueStatus,
  LogSeverityLevel,
  MeasurementUnit,
  OrganizationType,
  ProposalStatus,
  ResourceType,
  SolutionTenderingType,
  SolutionStatus,
  UserOrganizationAssociationType,
  UserOrganizationRole,
  WorkTypeAssociationScopeEntity,
  TenderStatus,
  TemplateType,
  TemplateEntityType,
  SolutionOperationType,
  ResponseStatus,
  CompleteEventStatus,
  LinkAction,
  MeetingStatus,
  AttendeeRole,
  ExternalReportType,
  GenerateReportResourceType,
  S3DownloadLocation,
  NotificationChannel,
  AccountClaimStatus,
  MeetingLinkType,
  BidMetadata,
  SolutionMetadata,
  Measurement,
  TenderMetadata,
  CreateTemporaryAuthorizationTokenResponse,
  ListResourceAggregateUserPermissionsOutput,
  AggregateUserPermissions,
  CompleteEvent,
  GetAccountClaimStatusResponse,
  ChimeMeetingOutput,
  SearchUsersOutput,
  SearchOrganizationsOutput
};