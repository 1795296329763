import { Box, Typography } from '@mui/material';

import { H1_TYPOGRAPHY_STYLE } from '../TypographyConstants';
import TextField from '@mui/material/TextField';
import { solutionNotesAtom } from '../../../lib/design/document/state/DocumentState';
import { useRecoilState } from 'recoil';

const SolutionNotesField = () => {
    const [solutionNotes, setSolutionNotes] = useRecoilState(solutionNotesAtom);
    return (
        <Box>
            <Typography {...H1_TYPOGRAPHY_STYLE}>
                Additional Notes
            </Typography>
            <TextField
                defaultValue={solutionNotes}
                size='small'
                multiline
                fullWidth
                maxRows={4}
                placeholder="e.g. Required Licenses, Disclaimers, etc.."
                variant="outlined"
                onBlur={(e) => setSolutionNotes(e.target.value)}
            />
        </Box>
    );
};

export default SolutionNotesField;
