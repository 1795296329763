import { makeStyles } from "@mui/styles";

const cameraStyle = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden'
    },
    videoWrapper: {
        flex: '1 1 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100%',
        overflow: 'hidden'
    },
    video: {
        width: '100%',
        height: '100%',
        objectFit: "contain"
    },
    snapshot: {
        position: 'absolute',
        bottom: '1rem',
        right: '1rem',
        width: '20%',
        height: 'auto',
        borderRadius: '5px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
    },
    portraitCameraButton: {
        position: 'absolute',
        bottom: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },
    switchButton: {
        position: 'absolute',
        top: '1rem',
        left: '1rem',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },
    landscapeCameraButton: {
        position: 'absolute',
        bottom: '50%',
        right: '1rem',
        transform: 'translateY(50%)',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },
    closeButton: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },
    flash: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 1,
        animation: '$flashEffect 0.3s ease-in-out',
    },
    '@keyframes flashEffect': {
        '0%': {
            opacity: 0.5,
        },
        '100%': {
            opacity: 0,
        },
    },
}));

export default cameraStyle;
