import {
    CSSProperties,
    ChangeEventHandler
} from 'react';

import FormFieldSummary from '../form/FormFieldSummary';
import { SurveyQuestionText } from 'survey-react-ui';
import { TextField } from '@mui/material';

export default class MUISurveyQuestionText extends SurveyQuestionText {

    constructor(props: any) {
        super(props);
    }

    renderElement() {
        const style: CSSProperties = {
            height: 'auto',
        };

        const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
            this.question.value = e.target.value;
        };

        return (
            <>
                <TextField
                        fullWidth
                        disabled={this.isDisplayMode}
                        error={this.question.errors.length > 0}
                        id={this.question.inputId}
                        inputProps={{
                            maxLength: this.question.getMaxLength(),
                            min: this.question.renderedMin,
                            max: this.question.renderedMax,
                            step: this.question.renderedStep,
                            placeholder: this.question.renderedPlaceholder,
                            list: this.question.dataListId,
                            autoComplete: this.question.autocomplete,
                            onBlur: this.updateValueOnEvent,
                            'aria-required': this.question.isRequired,
                            'aria-label': this.question.locTitle.renderedHtml,
                            'aria-invalid': this.question.errors.length > 0,
                            'aria-describedby': this.question.errors.length > 0 ?
                                this.question.id + "_errors" : ""
                        }}
                        label={`${this.question.title} ${this.question.isRequired ? "(Required)" : ""}`}
                        InputLabelProps={{sx: {display: 'none'}}}
                        onChange={onChange}
                        style={style}
                        type={this.question.inputType}
                        ref={(input) => (this.control = input!)}
                        required={this.question.isRequired}
                        value={this.question.value}
                        variant='outlined'
                />
                <FormFieldSummary
                        isValid={this.question.errors.length === 0}
                        errorText={`${this.question.title} is required.`}
                />
            </>
        );
    }
}
