import * as yup from "yup";

import {
    useEffect,
    useRef,
    useState
} from "react";

import Alert from '@mui/material/Alert';
import { AlertMessage } from "../../lib/util/AlertMessage";
import { Auth } from "aws-amplify";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ConfirmationPrompt from "../general/ConfirmationPrompt";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import LocalForageUserEmailCache from "../../lib/auth/attributes/AuthUserEmailCache";
import LocalForageUserEmailCacheFactory from "../../lib/auth/attributes/LocalForageUserEmailCacheFactory";
import { PaletteColor } from "../../lib/util/ui/PaletteColor";
import { PasswordField } from "./PasswordField";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';

const REQUIRED_FIELD_TEXT = "Field cannot be empty";

const signInFormSchema = yup.object({
    email: yup.string().email().required(REQUIRED_FIELD_TEXT),
    password: yup.string().required(REQUIRED_FIELD_TEXT)
});

type SignInFormData = yup.InferType<typeof signInFormSchema>;

type SignInFormProps = {
    onUserNotConfirmed: (email: string) => void;
    onForgotPassword: () => void;
};

export const SignInForm = (props: SignInFormProps) => {
    const { onUserNotConfirmed, onForgotPassword } = props;
    const [isSigningIn, setIsSigningIn] = useState<boolean>(false);
    const [isInactiveUser, setIsInactiveUser] = useState<boolean>(false)
    const [isChangeUserPromptOpen, setIsChangeUserPromptOpen] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>(undefined);
    const userEmailCacheRef = useRef<LocalForageUserEmailCache>(LocalForageUserEmailCacheFactory.getInstance());

    useEffect(() => {
        checkForInactiveUser();
    }, [])

    const checkForInactiveUser = async () => {
        const userEmail: string | null | undefined = await userEmailCacheRef.current.get();
        if (userEmail) {
            formik.values.email = userEmail;
            setIsInactiveUser(true);
        }
    }

    const changeUser = () => {
        formik.values.email = "";
        setIsInactiveUser(false);
        setIsChangeUserPromptOpen(false);
    }

    const signIn = async (data: SignInFormData) => {
        try {
            setIsSigningIn(true);
            await Auth.signIn(data.email, data.password);
        } catch (error) {
            setAlertMessage({
                severity: "error",
                message: (error as Error).message
            });
            if ((error as Error).name === "UserNotConfirmedException") {
                await Auth.resendSignUp(data.email);
                onUserNotConfirmed(data.email);
                return;
            }
        } finally {
            setIsSigningIn(false);
        }
    };

    const formik = useFormik<SignInFormData>({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: signInFormSchema,
        onSubmit: signIn
    });

    return (
        <Container component="main" maxWidth="xs">
            <Box
                mt={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                disabled={isInactiveUser}
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordField
                                id="password"
                                name="password"
                                label="Password"
                                autoComplete="current-password"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                        {alertMessage && (
                            <Grid item xs={12}>
                                <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
                            </Grid>
                        )}
                        {isInactiveUser && (
                            <>
                                <Grid item xs={12}>
                                    <Alert severity="warning">
                                        {formik.values.email} has been signed out due to inactivity, sign in to resume session.
                                    </Alert>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => {
                                            setIsChangeUserPromptOpen(true);
                                        }}
                                    >
                                        Change User
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        loading={isSigningIn}
                        loadingPosition="start"
                        startIcon={<></>}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </LoadingButton>
                    <Box display="flex" justifyContent="center">
                        <Button onClick={onForgotPassword}>
                            <Typography variant='body2'>
                                Forgot password?
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
            <ConfirmationPrompt
                isVisible={isChangeUserPromptOpen}
                confirmButtonColor={PaletteColor.DESTRUCTIVE}
                promptTitle={"Change User"}
                promptMessage={`Are you sure? Any data not synchronized from ${formik.values.email}'s session will be lost.`}
                onCancelButtonClicked={() => {
                    setIsChangeUserPromptOpen(false)
                }}
                onConfirmButtonClicked={changeUser}
            />
        </Container>
    );
};