import { DataStore } from "aws-amplify";

/**
 * @deprecated since version 0.370
 */
export default class DataStoreClientLogDAO {
    async save(clientLog) {
        return await DataStore.save(clientLog);
    }
}
