import {
    Box,
    Typography
} from "@mui/material";

import { BLACK_OUTLINED_BUTTON } from "../general/button/ButtonStyles";
import { DefaultTemplateDAOFactory } from "../../lib/template/DefaultTemplateDAOFactory";
import { DownloadIcon } from "../icons";
import LoadingButton from "@mui/lab/LoadingButton";
import { Template } from "../../models";
import { TemplateDAO } from "../../lib/template/TemplateDAO";
import { grey } from "@mui/material/colors";
import { useDownloadFile } from "../general/hooks/useDownloadFile";
import { useSnackbar } from "notistack";
import { useState } from "react";

type ViewTemplateProps = {
    template: Template;
};

const templateDAO: TemplateDAO = DefaultTemplateDAOFactory.getInstance();

export const ViewTemplate = (props: ViewTemplateProps) => {
    const { template } = props;
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const snackbar = useSnackbar();
    const { downloadFileFromBlob } = useDownloadFile();

    const downloadTemplate = async () => {
        setIsDownloading(true);
        snackbar.enqueueSnackbar("Downloading template...", { variant: "info" });
        try {
            const templateFile: Blob = await templateDAO.getContent(template.s3Key!);
            downloadFileFromBlob(templateFile, `${template.name}.docx` ?? "template.docx");
            snackbar.closeSnackbar();
        } catch (e) {
            snackbar.enqueueSnackbar("Error downloading template.", { variant: "error" });
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <Box
            width="100%"
            height="100%"
            display="grid"
            gridTemplateColumns={{
                xs: "1fr",
                sm: "1fr 1fr"
            }}
            alignItems="center"
            gap={1}
        >
            <Box display="flex" flexDirection="column">
                <Typography color={grey[500]} variant="caption">
                    Name:
                </Typography>
                <Typography>
                    {template.name}
                </Typography>
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography color={grey[500]} variant="caption">
                    Template Type:
                </Typography>
                <Typography>
                    {template.type}
                </Typography>
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
                <Typography color={grey[500]} variant="caption">
                    Template File:
                </Typography>
            </Box>
            <Box>
                <LoadingButton
                    fullWidth
                    loadingPosition="start"
                    variant="outlined"
                    color="primary"
                    onClick={downloadTemplate}
                    loading={isDownloading}
                    startIcon={<DownloadIcon />}
                    sx={BLACK_OUTLINED_BUTTON}
                >
                    Download
                </LoadingButton>
            </Box>
        </Box>
    );
};