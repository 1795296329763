import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { GraphQLAttachmentRecordDAOFactory } from "../../attachment/record/graphql/GraphQLAttachmentRecordDAOFactory";
import { GraphQLDimensionDAOFactory } from "../../dimension/dao/graphql/GraphQLDimensionDAOFactory";
import { GraphQLIssueDAOFactory } from "../../issue/dao/graphql/GraphQLIssueDAOFactory";
import GraphQLLocationDAOFactory from "../../location/dao/graphql/GraphQLLocationDAOFactory";
import GraphQLPropertyDAOFactory from "../../property/dao/graphql/GraphQLPropertyDAOFactory";
import GraphQLWorkScopeSpecificationDAOFactory from "../../design/workscopespecification/dao/graphql/GraphQLWorkScopeSpecificationDAOFactory";
import { SyncReconciliationWorker } from "./SyncReconciliationWorker";
import Task from "../../util/concurrency/Task";

export class SyncReconciliationWorkerFactory {
    private static readonly INSTANCE: SyncReconciliationWorker = new SyncReconciliationWorker(
        GraphQLAttachmentRecordDAOFactory.getInstance(),
        GraphQLPropertyDAOFactory.getInstance(),
        GraphQLLocationDAOFactory.getInstance(),
        GraphQLIssueDAOFactory.getInstance(),
        GraphQLWorkScopeSpecificationDAOFactory.getInstance(),
        GraphQLDimensionDAOFactory.getInstance(),
        ClientLoggerFactory.getClientLogger("SyncReconciliationWorker")
    );

    public static getInstance(): Task<void> {
        return this.INSTANCE;
    }
}
