import {
    AppBar,
    Box,
    IconButton,
    Toolbar,
    Typography
} from "@mui/material";
import {
    Auth,
    DataStore
} from "aws-amplify";
import {
    FunctionComponent,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    eligibilityCheckErrorAtom,
    isLogoutWarningPromptOpenAtom,
    showUserOnboardingWizardAtom
} from "../../lib/sidebar/SidebarRecoilStates";
import {
    useHistory,
    useLocation
} from "react-router-dom";

import ConfirmationPrompt from "../general/ConfirmationPrompt";
import DefaultLocalForageCleanupHandlerFactory from "../../lib/localforage/DefaultLocalForageCleanupHandlerFactory";
import EligibilityCheckError from "../../lib/ui/EligibilityCheckError";
import { HamburgerIcon } from "../icons";
import { ReactComponent as Logo } from "../../assets/images/logo-tenera-inspections.svg";
import SearchBar from "../general/SearchBar";
import SessionExpiryNotificationSnackbar from "../globalstate/SessionExpiryNotificationSnackbar";
import { SidebarDrawer } from "./SidebarDrawer";
import { SnackbarProvider } from "notistack";
import UserOnboardingEligibilityCheckerFactory from "../../lib/onboarding/UserOnboardingEligibilityCheckerFactory";
import UserOnboardingNotFoundErrorPage from "../../pages/error/UserOnboardingNotFoundErrorPage";
import UserOnboardingWizard from "../../pages/onboarding/UserOnboardingWizard";
import { useGoogleLogin } from "../../lib/googleDrive/hooks/useGoogleLogin";
import { useRecoilState } from "recoil";

const drawerWidth = 360;

export const APPBAR_ID = "tenera_appbar";

const MainAppBar: FunctionComponent<{ open: boolean, onClick: () => void; }> = (props) => {
    return <AppBar
        id={APPBAR_ID}
        position="fixed"
        sx={{
            backgroundColor: theme => theme.palette.text.primary,
            zIndex: theme => theme.zIndex.drawer + 1,
            transition: theme => theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            ...(props.open && {
                ml: { md: `${drawerWidth}px` },
                width: { md: `calc(100% - ${drawerWidth}px)` },
                transition: theme => theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            })
        }}
    >
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={props.onClick}
                sx={{ mr: 2 }}
            >
                <HamburgerIcon fontSize="large" />
            </IconButton>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <Logo width="45px" />
            </Box>
            <Typography
                component="h1"
                variant="h6"
                color="secondary"
                noWrap
                sx={{ flexGrow: 1 }}
            >
                Tenera Inspections
            </Typography>
            <SearchBar />
        </Toolbar>
    </AppBar>;
};

interface SidebarProps {
    readonly children: any;
}

const Sidebar = (props: SidebarProps) => {
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => setOpen(!open);

    const userOnboardingEligibilityChecker = useRef(UserOnboardingEligibilityCheckerFactory.getInstance());

    const [showUserOnboardingWizard, setShowUserOnboardingWizard] = useRecoilState(showUserOnboardingWizardAtom);
    const [eligibilityCheckError, setEligibilityCheckError] = useRecoilState(eligibilityCheckErrorAtom);
    const [isLogoutWarningPromptOpen, setLogoutWarningPromptOpen] = useRecoilState(isLogoutWarningPromptOpenAtom);

    const locationToCheckUserOnboarding = ["/"];
    const location = useLocation();
    const history = useHistory();
    const localForageCleanupHandlerRef = useRef(DefaultLocalForageCleanupHandlerFactory.getInstance());
    const { logoutFromGoogle } = useGoogleLogin();

    const page = useMemo(() => {
        if (eligibilityCheckError) {
            return (<UserOnboardingNotFoundErrorPage />);
        }
        if (showUserOnboardingWizard) {
            return (<UserOnboardingWizard onDismissButtonClicked={() => {
                setShowUserOnboardingWizard(false);
                history.push("/team");
            }} />);
        }
        return props.children;
    }, [eligibilityCheckError, showUserOnboardingWizard]);

    const checkEligibilityAndPromptUserOnboardingWizard = async () => {
        try {
            const eligibilityCheck = await userOnboardingEligibilityChecker.current.checkEligibility();
            setShowUserOnboardingWizard(eligibilityCheck);
        } catch (error) {
            if (error instanceof EligibilityCheckError) {
                setShowUserOnboardingWizard(false);
                setEligibilityCheckError(true);
            }
        }
    };

    useEffect(() => {
        const appBarHeight = document.getElementById(APPBAR_ID)?.offsetHeight!;
        window.scrollTo({
            top: window.pageYOffset - appBarHeight
        });
        if (locationToCheckUserOnboarding.includes(location.pathname)) {
            checkEligibilityAndPromptUserOnboardingWizard();
        }
    }, [location]);

    async function handleLogout() {
        history.push("/");
        await localForageCleanupHandlerRef.current.clear();
        DataStore.clear();
        await Auth.signOut();
        logoutFromGoogle();
    }

    return (
        <div>
            <MainAppBar open={false} onClick={toggleDrawer} />
            <SnackbarProvider maxSnack={3}>
                <Box
                    component="nav"
                    aria-label="menu items"
                >
                    <SidebarDrawer
                        drawerWidth={drawerWidth}
                        setIsOpen={setOpen}
                        open={open}
                    />
                </Box>
                <Box
                    sx={{
                        pl: { xs: 0, md: 9 },
                        pt: 8,
                        width: "100%",
                        height: "100%",
                        position: "absolute"
                    }}
                    onClick={() => {
                        if (open) {
                            setOpen(false);
                        }
                    }}
                >
                    {page}
                </Box>
                <SessionExpiryNotificationSnackbar />
            </SnackbarProvider>
            <ConfirmationPrompt
                isVisible={isLogoutWarningPromptOpen}
                promptTitle="Sign out"
                promptMessage="Any unsaved changes and incomplete uploads will be lost after signing out. Are you sure you want to sign out now?"
                onCancelButtonClicked={() => setLogoutWarningPromptOpen(false)}
                onConfirmButtonClicked={handleLogout}
            />
        </div>
    );
};

export default Sidebar;
