import { Template } from "../../models";
import { TemplateType } from "../../models";

export const DEFAULT_TEMPLATE_FILE_NAME = "Tenera Solutions - Sole Source";
export const S3_TEMPLATE_FOLDER_NAME = "Templates";
export const DEFAULT_TEMPLATE: Template = {
    id: "default",
    name: "Default template",
    s3Key: `${S3_TEMPLATE_FOLDER_NAME}/${DEFAULT_TEMPLATE_FILE_NAME}`,
    type: TemplateType.SOLUTION
};