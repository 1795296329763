import {
    Location,
    PermissionResourceType
} from '../../models';
import {
    OnCreateLocationSubscription,
    OnDeleteLocationSubscription,
    OnUpdateLocationSubscription
} from '../../API';
import {
    isEditModeByDesignElementIdentifierAtomFamily,
    locationByLocationIdentifierSelectorFamily
} from '../design/document/state/DocumentState';
import {
    onCreateLocation,
    onDeleteLocation,
    onUpdateLocation
} from '../../graphql/subscriptions';

import { API } from 'aws-amplify';
import { AuthIDTokenSupplierFactory } from '../auth/AuthIDTokenSupplierFactory';
import AuthUsernameSupplierFactory from '../auth/AuthUsernameSupplierFactory';
import { ContextAwareIdentifier } from '../design/document/ContextAwareIdentifier';
import { GraphQLSubscription } from '@aws-amplify/api';
import { ModelType } from '../design/document/ModelType';
import { StateContext } from '../design/document/state/StateContext';
import { elementFocusManager } from '../ui/ElementFocusManager';
import { fetchPermissionsForResource } from '../permission/state/ResourcePermissionRecoilState';
import { numberOfLocationsCreatedAtomFamily } from '../design/document/state/LocationNamingState';
import { useRecoilCallback } from 'recoil';

let createSub: ZenObservable.Subscription | undefined;
let updateSub: ZenObservable.Subscription | undefined;
let deleteSub: ZenObservable.Subscription | undefined;
const tokenSupplier = AuthIDTokenSupplierFactory.getInstance()
const usernameSupplier = AuthUsernameSupplierFactory.getInstance()

export const useLocationSubscription = () => {
    const subscribe = async (propertyId: string) => {
        const username = await usernameSupplier.get();
        const authCode = await tokenSupplier.get();

        const filterVariables = {
            canView: {
                contains: username
            },
            propertyId: {
                eq: propertyId
            }
        }
        createSub = API.graphql<GraphQLSubscription<OnCreateLocationSubscription>>({
            query: onCreateLocation,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handleLocationUpdate(value.data?.onCreateLocation as Location, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
        updateSub = API.graphql<GraphQLSubscription<OnUpdateLocationSubscription>>({
            query: onUpdateLocation,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handleLocationUpdate(value.data?.onUpdateLocation as Location, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
        deleteSub = API.graphql<GraphQLSubscription<OnDeleteLocationSubscription>>({
            query: onDeleteLocation,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handleLocationDelete(value.data?.onDeleteLocation as Location, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
    }

    const handleLocationUpdate = useRecoilCallback(({ set, snapshot }) => async (location: Location, context: StateContext) => {
        const locationIdentifier = new ContextAwareIdentifier(location.id, context, ModelType.LOCATION);
        const existingLocation = await snapshot.getPromise(locationByLocationIdentifierSelectorFamily(locationIdentifier));
        if (existingLocation == null) {
            await fetchPermissionsForResource(location.id, PermissionResourceType.LOCATION, set);
            set(locationByLocationIdentifierSelectorFamily(locationIdentifier), location);
            set(isEditModeByDesignElementIdentifierAtomFamily(locationIdentifier), false);
            set(numberOfLocationsCreatedAtomFamily(context), (original) => {
                return original + 1;
            });
            elementFocusManager.scrollToElement()
            return location;
        } else {
            if (existingLocation?.localLastUpdatedTime! >= location.localLastUpdatedTime!) {
                // Ignore updates that are older than the current state
                return;
            }
            set(locationByLocationIdentifierSelectorFamily(locationIdentifier), location);
            return location;
        }
    });

    const handleLocationDelete = useRecoilCallback(({ set, reset, snapshot }) => async (location: Location, context: StateContext) => {
        const locationIdentifier = new ContextAwareIdentifier(location.id, context, ModelType.LOCATION);
        reset(locationByLocationIdentifierSelectorFamily(locationIdentifier));
        return location;
    });

    const unsubscribe = () => {
        createSub?.unsubscribe();
        updateSub?.unsubscribe();
        deleteSub?.unsubscribe();
    }

    return { subscribe, unsubscribe }
}