import MaskedInput from "react-text-mask";
import { createPriceNumberMask } from "./createPriceNumberMask";

const numberMask = createPriceNumberMask(true);

export const NegativePriceNumberMask = (props: any) => {
    const { mask, ...other } = props;
    return (
        <MaskedInput
                {...other}
                mask={mask ?? numberMask}
        />
    );
};

