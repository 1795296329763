import {
    Box,
    alpha
} from "@mui/material";
import {
    Dispatch,
    SetStateAction,
    useEffect
} from "react";
import {
    getResetIssueFilter,
    isFilterAppliedAtom
} from "../../lib/issue/state/IssueFilterRecoilState";

import { DrawerListItemButton } from "./DrawerListItemButton";
import { FilterIcon } from "../icons";
import { IssueFilter } from "../../componentsV2/issue/filter/IssueFilter";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { stateContextInFocusAtom } from "../../lib/ui/InFocusRecoilStates";
import theme from "../../assets/style/theme";
import { useRecoilValue } from "recoil";

type IssueFilterDrawerListItemProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const IssueFilterDrawerListItem = (props: IssueFilterDrawerListItemProps) => {
    const { isOpen, setIsOpen } = props;
    const context = useRecoilValue<StateContext>(stateContextInFocusAtom);
    const isFilterApplied = useRecoilValue<boolean>(isFilterAppliedAtom);

    /* Dependencies */
    const resetFilter = getResetIssueFilter();

    useEffect(() => {
        return () => {
            resetFilter();
        };
    }, [context]);

    return (
        <>
            <Box sx={{ backgroundColor: isFilterApplied ? alpha(theme.palette.primary.main, 0.2) : undefined }}>
                <DrawerListItemButton
                    title="Filter Settings"
                    icon={<FilterIcon />}
                    onClick={() => setIsOpen(true)}
                    isOpen={isOpen}
                    disabled={isOpen}
                />
            </Box>
            <Box px={1} py={2} display={isOpen ? undefined : "none"}>
                <IssueFilter
                    onFilter={() => setIsOpen(false)}
                    onReset={() => setIsOpen(false)}
                />
            </Box>
        </>
    );
};