import {
    ForwardedRef,
    forwardRef
} from "react";

import { TextField } from "@mui/material";

// This is a custom input component so we can use MUI TextField with PhoneNumberInput.
const PhoneInputTextField = (props: any, ref: ForwardedRef<HTMLInputElement>) => (
    <TextField
        {...props}
        inputRef={ref}
    />
);
export default forwardRef(PhoneInputTextField);
