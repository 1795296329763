import { DimensionType } from "../../../models";
import { MeasurementUnit } from "../../../models/index";
import _ from "lodash";

export default class DimensionUnitConverter{
    private static METER_METER_RATIO = 1;
    private static CENTIMETER_METER_RATIO = 1/100;
    private static FOOT_METER_RATIO = 0.3048;
    private static INCH_METER_RATIO = 0.0254;

    public static convertUnitMeasurement(
        dimensionType: DimensionType,
        sourceUnit: MeasurementUnit,
        targetUnit: MeasurementUnit,
        sourceUnitMeasurement: number
    ): number {
        const factor = _.round(this.getUnitRelativeToMeter(sourceUnit) / this.getUnitRelativeToMeter(targetUnit), 12);
        if (dimensionType === DimensionType.LINEAR) {
            return sourceUnitMeasurement * factor;
        }
        if (dimensionType === DimensionType.SQUARE) {
            return sourceUnitMeasurement * factor * factor;
        }
        throw Error (`Cannot convert unrecognized dimensionType: ${dimensionType}`);
    }

    private static getUnitRelativeToMeter(
        unit: MeasurementUnit
    ) {
        switch(unit) {
            case MeasurementUnit.METER:
                return this.METER_METER_RATIO;
            case MeasurementUnit.CENTIMETER:
                return this.CENTIMETER_METER_RATIO;
            case MeasurementUnit.FOOT:
                return this.FOOT_METER_RATIO;
            case MeasurementUnit.INCH:
                return this.INCH_METER_RATIO;
            default:
                throw new Error(`Cannot convert unrecognized measurementUnit: ${unit}`);
        }
    }
}