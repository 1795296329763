import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const ExpandAllIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8 16h8V8H8v8zm1-7h6v6H9V9z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M7.71 6.29 6.29 7.71 2 3.41V6H0V1l1-1h5v2H3.41zM24 1v5h-2V3.41l-4.29 4.3-1.42-1.42L20.59 2H18V0h5zM3.41 22H6v2H1l-1-1v-5h2v2.59l4.29-4.3 1.42 1.42zM24 18v5l-1 1h-5v-2h2.59l-4.3-4.29 1.42-1.42 4.29 4.3V18z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(ExpandAllIcon);
