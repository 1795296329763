import {
    CollapseAllIcon,
    ExpandAllIcon
} from "../icons";

import { ChildrenExpandState } from "../../lib/design/document/state/enum/ChildrenExpandState";
import { QuickAccessKey } from "./QuickAccessKey";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { areAllLocationsCollapsedSelectorFamily } from "../../lib/design/document/state/ComponentExpandState";
import { locationIdsByContextAtomFamily } from "../../lib/design/document/state/DocumentState";
import { stateContextInFocusAtom } from "../../lib/ui/InFocusRecoilStates";
import { useRecoilValue } from "recoil";
import { useSetChildrenExpandState } from "../../lib/design/hooks/useSetChildrenExpandState";

export const QuickExpandAllKey = () => {
    const contextInFocus = useRecoilValue<StateContext>(stateContextInFocusAtom);
    const areAllLocationsCollapsed = useRecoilValue<boolean>(areAllLocationsCollapsedSelectorFamily(contextInFocus));
    const locationIds = useRecoilValue<Array<string>>(locationIdsByContextAtomFamily(contextInFocus));
    const { setChildrenExpandStateForAllLocations } = useSetChildrenExpandState(contextInFocus);

    return (
        <QuickAccessKey
            disabled={locationIds.length === 0}
            onClick={() => {
                if (areAllLocationsCollapsed) {
                    setChildrenExpandStateForAllLocations(ChildrenExpandState.EXPAND_CHILDREN, true);
                    return;
                }
                setChildrenExpandStateForAllLocations(ChildrenExpandState.COLLAPSE_CHILDREN, true);
            }}
            in
        >
            {
                (areAllLocationsCollapsed && locationIds.length > 0) ?
                    <ExpandAllIcon accent={locationIds.length === 0 ? "disabled" : "primary"} /> :
                    <CollapseAllIcon accent={locationIds.length === 0 ? "disabled" : "primary"} />
            }
        </QuickAccessKey>
    );
};