import {
    FormControl,
    FormControlProps,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";

import { PermissionTemplate } from "../../lib/permission/template/PermissionTemplate";

type PermissionTemplateSelectProps = {
    displayRemoveUser?: boolean;
    label?: string;
    permissionTemplateOptions: Array<PermissionTemplate>;
    selectedTemplate: PermissionTemplate | undefined;
    onSelectedTemplateChange: (template: PermissionTemplate) => Promise<void> | void;
} & FormControlProps;

export const PermissionTemplateSelect = (props: PermissionTemplateSelectProps) => {
    const { displayRemoveUser, label, permissionTemplateOptions, selectedTemplate, onSelectedTemplateChange, ...formControlProps } = props;
    const showRemoveOption = displayRemoveUser && permissionTemplateOptions.includes(PermissionTemplate.NONE);
    const templateOptionsWithoutNone = permissionTemplateOptions.filter(template => template !== PermissionTemplate.NONE);

    return (
        <FormControl {...formControlProps}>
            {label && <InputLabel>{label}</InputLabel>}
            <Select
                onChange={(e) => onSelectedTemplateChange(e.target.value as PermissionTemplate)}
                value={selectedTemplate ?? ""}
                label={label}
            >
                {templateOptionsWithoutNone.map((template, index) => (
                    <MenuItem key={template} value={template} divider={index === templateOptionsWithoutNone.length - 1 && showRemoveOption}>{template}</MenuItem>
                ))}
                {showRemoveOption && (
                    <MenuItem sx={{ pt: 2 }} value={PermissionTemplate.NONE}>
                        <Typography color="red">
                            Remove
                        </Typography>
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
};