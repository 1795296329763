import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const SaveCloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M22.243 22.242a6 6 0 1 0-8.486-8.485A5.999 5.999 0 0 0 18 24a5.98 5.98 0 0 0 4.243-1.758zM20.4 14.751a.848.848 0 0 1 .601 1.449L19.2 18l1.8 1.8a.848.848 0 0 1-1.2 1.201L18 19.2 16.2 21a.849.849 0 0 1-1.201-1.2l1.8-1.8L15 16.2a.85.85 0 0 1 1.2-1.2l1.8 1.8 1.8-1.8a.846.846 0 0 1 .6-.249z"
      className="accent"
    />
    <path
      fill="currentColor"
      d="M18 0H0v24h14.406a7.07 7.07 0 0 1-1.3-1H5V13h8.106c.397-.389.834-.72 1.3-1H4v11H1V1h3v9h13V1h.586L23 6.414v6.692c.393.401.723.841 1 1.303V6l-6-6zm-2 9H5V1h11v8z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M12 3h2v4h-2z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(SaveCloseIcon);
