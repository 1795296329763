import { AuthIDTokenSupplierFactory } from "../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../logging/ClientLoggerFactory";
import { GraphQLManageResourcePermissionClient } from "./GraphQLManageResourcePermissionClient";
import { ManageResourcePermissionClient } from "./ManageResourcePermissionClient";

export class GraphQLManageResourcePermissionClientFactory {
    private static readonly INSTANCE: ManageResourcePermissionClient = new GraphQLManageResourcePermissionClient(
        ClientLoggerFactory.getClientLogger("GraphQLManageResourcePermissionClient"),
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance(): ManageResourcePermissionClient {
        return this.INSTANCE;
    }
}