import {
    Issue,
    PermissionResourceType
} from "../../../models";
import {
    MeetingStatus,
    useMeetingStatus
} from "amazon-chime-sdk-component-library-react";
import {
    defaultRecordToPropertyAssociationModeAtom,
    isEditModeByDesignElementIdentifierAtomFamily,
    issueByIssueIdentifierSelectorFamily,
    recentlyCreatedRecordIds
} from "../../design/document/state/DocumentState";
import {
    issueDefaultNameFactorySelectorFamily,
    numberOfIssuesCreatedAtomFamily
} from "../../design/document/state/IssueNamingState";

import { ChildrenExpandState } from "../../design/document/state/enum/ChildrenExpandState";
import { ContextAwareIdentifier } from "../../design/document/ContextAwareIdentifier";
import { CreateIssueStateError } from "../../design/document/state/error/issue/CreateIssueStateError";
import DataStoreIssueDAOFactory from "../dao/datastore/DataStoreIssueDAOFactory";
import { IssueDefaultNameFactory } from "../../../components/issue/name/IssueDefaultNameFactory";
import { IssueStatus } from "../../../models";
import { ModelType } from "../../design/document/ModelType";
import { RecordToPropertyAssociationMode } from "../../design/document/state/RecordToPropertyAssociationMode";
import { StateContext } from "../../design/document/state/StateContext";
import { fetchPermissionsForResource } from "../../permission/state/ResourcePermissionRecoilState";
import { isAiAssistedNamingEnabledAtom } from "../../../components/chime/hooks/state/AISummarizationStates";
import { useCreateDimension } from "../../dimension/hook/useCreateDimension";
import { usePopulateIssueFromTranscript } from "./usePopulateIssueFromTranscript";
import { useRecoilCallback } from "recoil";
import { useSetChildrenExpandState } from "../../design/hooks/useSetChildrenExpandState";
import { useSnackbar } from "notistack";

const issueDAO = DataStoreIssueDAOFactory.getInstance();

export const useCreateIssue = (context: StateContext) => {
    const { createDimension } = useCreateDimension(context);
    const { setModelChildrenExpandState } = useSetChildrenExpandState(context);
    const { populateIssueFromTranscript } = usePopulateIssueFromTranscript(context);
    const snackbar = useSnackbar();
    const meetingStatus = useMeetingStatus();
    const isMeetingActive = meetingStatus === MeetingStatus.Succeeded || meetingStatus === MeetingStatus.Reconnecting;

    const createIssue = useRecoilCallback(({ snapshot, set }) => async (
        issue: Issue,
        createChildren: boolean = true,
        inEditMode: boolean = true
    ): Promise<Issue> => {
        const releaseSnapshot = snapshot.retain();
        try {
            const createdInSolution: boolean = context === StateContext.SOLUTION_AUTHORING_CONTENT;
            let issueToCreate: Issue = { ...issue, createdInSolution };
            // Remove propertyId association, if the issue is created outside of the context of inspection
            // (e.g. solution authoring)
            const recordToPropertyAssociationMode = await snapshot.getLoadable(defaultRecordToPropertyAssociationModeAtom).contents as RecordToPropertyAssociationMode;
            if (recordToPropertyAssociationMode === RecordToPropertyAssociationMode.DO_NOT_ASSOCIATE) {
                issueToCreate = { ...issueToCreate, propertyId: undefined };
            }
            const createdIssue = await issueDAO.createNewIssue(issueToCreate);
            // Always create dimension for the issue, pass createChildren flag to create children of the dimension
            await createDimension(createdIssue.id, createdIssue.propertyId!, createChildren);
            await fetchPermissionsForResource(createdIssue.id, PermissionResourceType.ISSUE, set);
            const issueIdentifier = new ContextAwareIdentifier(createdIssue.id, context, ModelType.ISSUE);
            set(issueByIssueIdentifierSelectorFamily(issueIdentifier), createdIssue);
            setModelChildrenExpandState(ModelType.LOCATION, createdIssue.locationId!, ChildrenExpandState.EXPAND_CHILDREN);
            setModelChildrenExpandState(ModelType.ISSUE, createdIssue.id, ChildrenExpandState.EXPAND_CHILDREN);
            set(recentlyCreatedRecordIds, (prevValue: Set<string>) => {
                const copy = new Set(prevValue);
                copy.add(createdIssue.id);
                return copy;
            });
            if (inEditMode) {
                set(isEditModeByDesignElementIdentifierAtomFamily(issueIdentifier), true);
            }
            set(numberOfIssuesCreatedAtomFamily(context), (original) => {
                return original + 1;
            });
            return createdIssue;
        } catch (error) {
            throw new CreateIssueStateError(
                `Error occurred while trying to Create State for Issue with id: ${issue.id}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    }, [context]);

    const createDefaultIssue = useRecoilCallback(({ snapshot, set }) => async (
        propertyId: string,
        locationId: string,
        createChildren: boolean = true
    ): Promise<Issue | undefined> => {
        const releaseSnapshot = snapshot.retain();
        try {
            if (isMeetingActive) {
                const isAiAssistedNamingEnabled = await snapshot.getPromise(isAiAssistedNamingEnabledAtom);
                if (isAiAssistedNamingEnabled) {
                    const issue = await createIssue(
                        new Issue({
                            name: "Pending",
                            propertyId,
                            locationId,
                            status: IssueStatus.NONE
                        }),
                        createChildren,
                        true
                    );
                    populateIssueFromTranscript(issue);
                    return issue;
                }
            }
            const issueNameFactory: IssueDefaultNameFactory | undefined = await snapshot.getPromise(issueDefaultNameFactorySelectorFamily(context));
            if (!issueNameFactory) {
                throw new Error("Issue Name Factory is not defined");
            }
            const issueName = await issueNameFactory.getName();
            const createdIssue = await createIssue(
                new Issue({
                    name: issueName,
                    propertyId,
                    locationId,
                    status: IssueStatus.NONE
                }),
                createChildren
            );

            return createdIssue;
        } catch (error) {
            snackbar.enqueueSnackbar(
                "Failed to create Issue",
                { variant: "error" }
            );
        } finally {
            releaseSnapshot();
        }
    }, [createIssue, populateIssueFromTranscript]);

    return { createIssue, createDefaultIssue };
};