import {
    Backdrop,
    CircularProgress,
    Typography
} from "@mui/material";

type LoadingBackDropProps = {
    readonly title: string;
    readonly open: boolean;
};

export const LoadingBackDrop = ({ title, open }: LoadingBackDropProps) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <Typography>{title}</Typography>
            <CircularProgress color="inherit" size={20} />
        </Backdrop>
    );
};