import { AutocompleteOption } from "../ui/autocomplete/AutocompleteOption";
import LocalForageBackedRecentlyUsedItemsDAO from "./LocalForageBackedRecentlyUsedItemsDAO";
import RecentlyUsedItemsDAO from "./RecentlyUsedItemsDAO";

export default class RecentlyUsedWorktypeDAOFactory {
    private static readonly ITEM_GROUP_NAME: string = "WorkType";
    private static readonly ITEMS_IN_MEMORY: number = 10;

    private static readonly INSTANCE: RecentlyUsedItemsDAO<AutocompleteOption> =
        new LocalForageBackedRecentlyUsedItemsDAO<AutocompleteOption>(this.ITEM_GROUP_NAME, this.ITEMS_IN_MEMORY)

    public static create() {
        return this.INSTANCE;
    }
}
