import { AttachmentUploadStatus } from "./AttachmentUploadStatus";
import AttachmentUploadStatusDAO from "./AttachmentUploadStatusDAO";
import ClientLogger from "../../../logging/ClientLogger";

export default class MetricsLoggingAttachmentUploadStatusDAODecorator implements AttachmentUploadStatusDAO {
    static readonly GET_ALL_ATTACHMENT_STATUS_TAG = "getAllAttachmentUploadStatus";
    static readonly GET_ALL_ATTACHMENT_STATUS_FAILURE_TAG = "getAllAttachmentUploadStatusFailed";
    static readonly GET_ATTACHMENT_STATUS_TAG = "getAttachmentUploadStatus";
    static readonly GET_ATTACHMENT_STATUS_FAILURE_TAG = "getAttachmentUploadStatusFailed";
    static readonly SET_ATTACHMENT_STATUS_TAG = "setAttachmentUploadStatus";
    static readonly SET_ATTACHMENT_STATUS_FAILURE_TAG = "setAttachmentUploadStatusFailed";
    static readonly REMOVE_ATTACHMENT_STATUS_TAG = "removeAttachmentUploadStatus";
    static readonly REMOVE_ATTACHMENT_STATUS_FAILURE_TAG = "removeAttachmentUploadStatusFailed";

    private readonly delegate: AttachmentUploadStatusDAO;
    private readonly logger: ClientLogger;

    constructor(
        delegate: AttachmentUploadStatusDAO,
        logger: ClientLogger
    ) {
        this.delegate = delegate;
        this.logger = logger;
    }

    public async getUploadStatusForAllAttachments(): Promise<Map<string, AttachmentUploadStatus>> {
        try {
            this.logger.info(
                `Retrieving all AttachmentUploadStatus`,
                undefined,
                [MetricsLoggingAttachmentUploadStatusDAODecorator.GET_ALL_ATTACHMENT_STATUS_TAG]
            )
            return await this.delegate.getUploadStatusForAllAttachments();
        } catch (error) {
            this.logger.error(
                `Failed to get AttachmentUploadStatus`,
                error,
                [MetricsLoggingAttachmentUploadStatusDAODecorator.GET_ALL_ATTACHMENT_STATUS_FAILURE_TAG]
            )
            throw error;
        }
    }

    public async getAttachmentUploadStatus(attachmentId: string): Promise<AttachmentUploadStatus> {
        try {
            this.logger.info(
                `Retrieving AttachmentUploadStatus for ${attachmentId}`,
                undefined,
                [MetricsLoggingAttachmentUploadStatusDAODecorator.GET_ATTACHMENT_STATUS_TAG]
            );
            return await this.delegate.getAttachmentUploadStatus(attachmentId);
        } catch (error) {
            this.logger.error(
                `Failed to get AttachmentUploadStatus for ${attachmentId}`,
                error,
                [MetricsLoggingAttachmentUploadStatusDAODecorator.GET_ATTACHMENT_STATUS_FAILURE_TAG]
            );
            throw error;
        }
    }

    public async setAttachmentUploadStatus(
        attachmentId: string,
        status: AttachmentUploadStatus
    ): Promise<void> {
        try {
            this.logger.info(
                `Setting upload status for ${attachmentId} to ${AttachmentUploadStatus[status]}`,
                undefined,
                [MetricsLoggingAttachmentUploadStatusDAODecorator.SET_ATTACHMENT_STATUS_TAG]
            )
            return await this.delegate.setAttachmentUploadStatus(attachmentId, status);
        } catch (error) {
            this.logger.error(
                `Failed to set upload status for ${attachmentId}`,
                error,
                [MetricsLoggingAttachmentUploadStatusDAODecorator.SET_ATTACHMENT_STATUS_FAILURE_TAG]
            )
            throw error;
        }
    }

    public async removeAttachmentUploadStatus(
        attachmentId: string
    ): Promise<void> {
        try {
            this.logger.info(
                `Removing AttachmentUploadStatus for ${attachmentId}`,
                undefined,
                [MetricsLoggingAttachmentUploadStatusDAODecorator.REMOVE_ATTACHMENT_STATUS_TAG]
            )
            return await this.delegate.removeAttachmentUploadStatus(attachmentId)
        } catch (error) {
            this.logger.error(
                `Failed to remove AttachmentUploadStatus for ${attachmentId}`,
                error,
                [MetricsLoggingAttachmentUploadStatusDAODecorator.REMOVE_ATTACHMENT_STATUS_FAILURE_TAG]
            )
            throw error;
        }
    }
}
