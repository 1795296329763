import { ContextAwareIdentifier } from "../../../../lib/design/document/ContextAwareIdentifier";
import { ModelType } from "../../../../lib/design/document/ModelType";
import { StateContext } from "../../../../lib/design/document/state/StateContext";
import { WorkSpecification } from "../../../../models";
import { isAiResourceGeneratingAtomFamily } from "../state/AISummarizationStates";
import { useAISummarizer } from "../useAISummarizer";
import { useRecoilCallback } from "recoil";
import { useSnackbar } from "notistack";
import { useUpdateWorkSpecification } from "../../../../lib/design/workscopespecification/hook/useUpdateWorkSpecification";
import { workSpecificationByWorkSpecificationIdentifierSelectorFamily } from "../../../../lib/design/document/state/DocumentState";

export const useAppendToWSDescriptionQuickAction = () => {
    const snackbar = useSnackbar();
    const { updateWorkSpecification } = useUpdateWorkSpecification(StateContext.INSPECTION);
    const { generateTranscriptSummaryAddition } = useAISummarizer();

    const appendToWSDescription = useRecoilCallback(({ snapshot, set }) => async (
        workSpecificationId: string
    ) => {
        const identifier = new ContextAwareIdentifier(workSpecificationId, StateContext.INSPECTION, ModelType.WORK_SPECIFICATION);
        const workSpecification: WorkSpecification | null = await snapshot.getPromise(workSpecificationByWorkSpecificationIdentifierSelectorFamily(identifier));
        if (!workSpecification) {
            snackbar.enqueueSnackbar('Work Specification not found', { variant: 'error' });
            return;
        }
        set(isAiResourceGeneratingAtomFamily(workSpecificationId), true);
        const newDescription = await generateTranscriptSummaryAddition(workSpecification.description || '');
        const updatedWorkSpecification = WorkSpecification.copyOf(workSpecification, (draft) => {
            draft.description = newDescription;
        });
        updateWorkSpecification(updatedWorkSpecification);
        set(isAiResourceGeneratingAtomFamily(workSpecificationId), false);
        snackbar.enqueueSnackbar('Work Specification description updated', { variant: 'success' });
    }, [snackbar]);

    return { appendToWSDescription };
};