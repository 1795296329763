import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { DataStore } from "aws-amplify";
import DataStoreEntityWorkTypeAssociationDAO from "./DataStoreEntityWorkTypeAssociationDAO";
import EntityWorkTypeAssociationDAO from "../EntityWorkTypeAssociationDAO";

export default class DataStoreEntityWorkTypeAssociationDAOFactory {

    private static readonly INSTANCE: EntityWorkTypeAssociationDAO =
        new DataStoreEntityWorkTypeAssociationDAO(
            ClientLoggerFactory.getClientLogger("DataStoreEntityWorkTypeAssociationDAO"),
            DataStore
        );

    public static getInstance() {
        return this.INSTANCE;
    }
}