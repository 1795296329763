import { createTheme } from "@mui/material/styles";
import { green } from "./colors";

const theme = createTheme({
    palette: {
        primary: {
            main: green[500],
            light: green[200],
            dark: green[800],
            contrastText: "#FFFFFF"
        },
        secondary: {
            main: "#FFFFFF"
        },
        error: {
            main: "#F44336"
        },
        warning: {
            main: "#FFB74D"
        },
        info: {
            main: "#64B5F6"
        },
        success: {
            main: "#4CAF50"
        },
        background: {
            default: "#F9F9F9"
        }
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        backgroundColor: '#FFFFFF',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.15)', // Change this to the color you want
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.30)', // Change this to the color you want for hover state
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.50)', // Change this to the color you want for focused state
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    backgroundColor: '#FFFFFF',
                }
            },
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 1024,
            lg: 1200,
            xl: 1536
        }
    },
    typography: {
        button: {
            textTransform: "none"
        }
    }
});

export default theme;
