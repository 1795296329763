import {
    LOCAL_FORAGE_DATABASE_NAME,
    LOCAL_FORAGE_OFFLINE_PROPERTY_TABLE_NAME,
    LOCAL_FORAGE_PENDING_UPLOAD_PROPERTY_TABLE_NAME,
    LOCAL_FORAGE_UPLOAD_ATTACHMENT_RECORD_TABLE_NAME,
    LOCAL_FORAGE_UPLOAD_STATUS_TABLE_NAME
} from "./LocalForageConstant";

import LocalForageCleanupHandler from "./LocalForageCleanupHandler";
import localforage from "localforage";

export default class DefaultLocalForageCleanupHandler implements LocalForageCleanupHandler {

    public async clear(): Promise<void> {
        await Promise.all([
            localforage.dropInstance({ name: 'localforage' }),
            localforage.dropInstance({ name: LOCAL_FORAGE_PENDING_UPLOAD_PROPERTY_TABLE_NAME }),
            localforage.dropInstance({ name: LOCAL_FORAGE_OFFLINE_PROPERTY_TABLE_NAME }),
            localforage.dropInstance({ name: LOCAL_FORAGE_DATABASE_NAME }),
            localforage.dropInstance({ name: LOCAL_FORAGE_UPLOAD_STATUS_TABLE_NAME }),
            localforage.dropInstance({ name: LOCAL_FORAGE_UPLOAD_ATTACHMENT_RECORD_TABLE_NAME }),
            localforage.clear()
        ]);
    }

}