import {
    Box,
    Button,
    LinearProgress,
    Typography
} from "@mui/material";
import {
    ProviderContext,
    useSnackbar
} from "notistack";
import {
    ReactNode,
    useEffect,
    useRef,
    useState
} from "react";
import {
    useHistory,
    useParams
} from "react-router-dom";

import { DataStoreStatus } from "../../lib/datastore/DataStoreStatus";
import { OfflinePropertyListManagerFactory } from "../../lib/datastore/sync/offline/OfflinePropertyListManagerFactory";
import { PropertyListManager } from "../../lib/datastore/sync/offline/PropertyListManager";
import { RecentlyCreatedPropertyListManagerFactory } from "../../lib/datastore/sync/offline/RecentlyCreatedPropertyListManagerFactory";
import { dataStorePropertySyncStatusAtom } from "../../lib/datastore/state/DataStoreState";
import { useDataStoreSynchronizer } from "../../lib/datastore/sync/hook/useDataStoreSynchronizer";
import { useErrorBoundary } from "react-error-boundary";
import { usePageDefaultStyle } from "../../assets/style/usePageDefaultStyle";
import { useRecoilValue } from "recoil";
import { useSetUpProperty } from "../../lib/property/hooks/useSetUpProperty";

type AwaitPropertiesChildrenSyncedWrapperParams = {
    propertyId: string;
};

type PropertySynchronizingComponentWrapperProps = {
    children: ReactNode;
};

export const AwaitPropertiesChildrenSyncedWrapper = ({ children }: PropertySynchronizingComponentWrapperProps) => {
    const history = useHistory();
    const snackbar: ProviderContext = useSnackbar();
    const recentlyCreatedPropertyListManagerRef = useRef<PropertyListManager>(RecentlyCreatedPropertyListManagerFactory.getInstance());
    const offlinePropertyListManagerRef = useRef<PropertyListManager>(OfflinePropertyListManagerFactory.getInstance());
    const { addPropertyIdAndSync } = useDataStoreSynchronizer();
    const { setUpProperty, resetProperty } = useSetUpProperty();
    const { propertyId } = useParams<AwaitPropertiesChildrenSyncedWrapperParams>();
    const syncStatus = useRecoilValue(dataStorePropertySyncStatusAtom);
    const [showTimeoutMessage, setShowTimeoutMessage] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const { pageDefaultStyle } = usePageDefaultStyle();
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        const checkAndAddProperty = async () => {
            try {
                if (propertyId == undefined) {
                    setInitialized(true);
                    return;
                }
                const success = await addPropertyIdAndSync(propertyId);
                if (!success) {
                    const recentProperties = await recentlyCreatedPropertyListManagerRef.current.getList();
                    const offlineProperties = await offlinePropertyListManagerRef.current.getList();
                    if (!recentProperties.includes(propertyId) && !offlineProperties.includes(propertyId)) {
                        snackbar.enqueueSnackbar("Could not download property, please try again on a more stable network connection", { variant: "error" });
                        history.replace("/properties");
                    }
                    if (recentProperties.includes(propertyId)) {
                        snackbar.enqueueSnackbar("Unable to upload property to cloud, will retry later", { variant: "info" });
                    }
                }
                await setUpProperty(propertyId);
                setInitialized(true);
            } catch (error) {
                showBoundary(error);
            }
        };
        checkAndAddProperty();
        return () => {
            resetProperty();
        };
    }, [propertyId]);

    useEffect(() => {
        if (syncStatus !== DataStoreStatus.SYNC_COMPLETED) {
            const timer = setTimeout(() => {
                setShowTimeoutMessage(true);
            }, 30000);
            return () => clearTimeout(timer);
        } else {
            setShowTimeoutMessage(false);
        }
    }, [syncStatus]);

    return (
        <>
            {syncStatus !== DataStoreStatus.SYNC_COMPLETED || !initialized ? (
                <Box
                    sx={pageDefaultStyle}
                >
                    <Typography variant="body2">
                        Downloading Properties from the Cloud...
                    </Typography>
                    {showTimeoutMessage && (
                        <>
                            <Typography variant="body2" color="error">
                                This is taking longer than expected, if you are on a poor network connection please find a stable connection and refresh this page.
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => window.location.reload()}
                            >
                                Refresh Page
                            </Button>
                        </>
                    )}
                    <Box width="100%" pt={1}>
                        <LinearProgress />
                    </Box>
                </Box>
            ) : (
                children
            )}
        </>
    );
};
