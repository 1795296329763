import {
    Box,
    Button,
    Typography
} from "@mui/material";

import AuthUsernameSupplierFactory from "../../lib/auth/AuthUsernameSupplierFactory";
import { ClientLog } from "../../models";
import { DataStore } from "aws-amplify";
import { FallbackProps } from "react-error-boundary";
import { awsRum } from "../../App";
import { useEffect } from "react";

export const TENERA_SUPPORT_EMAIL = "support@tenerapro.com";

const getErrorReportEmailBody = (error: Error) => {
    return `
Error occurred on: ${window.location.href} at ${new Date().toLocaleString()}%0D%0A%0D%0A
Error message: %0D%0A
${error.message}%0D%0A%0D%0A
Stack: %0D%0A
${error.stack}
`;
};

/**
 * ErrorFallback is treated as class component where hooks are not allowed.
 * This is a workaround to use hooks in ErrorFallback.
 */
const ErrorFallbackContent = ({ error, resetErrorBoundary }: FallbackProps) => {
    useEffect(() => {
        awsRum?.recordError(error);
        AuthUsernameSupplierFactory.getInstance().get()
            .then((userId) => {
                const clientLog = new ClientLog({
                    details: JSON.stringify({ stack: error.stack }),
                    message: error.message,
                    severityLevel: "ERROR",
                    componentName: "",
                    userAgent: navigator.userAgent,
                    userId: userId
                });
                DataStore.save(clientLog);
            })
            .catch((error) => {
                const clientLog = new ClientLog({
                    details: JSON.stringify({ stack: error.stack }),
                    message: error.message,
                    severityLevel: "ERROR",
                    componentName: "",
                    userAgent: navigator.userAgent,
                });
                DataStore.save(clientLog);
            });
    }, []);

    return (
        <Box width="100%" height="100%" display="flex" justifyContent="center">
            <Box
                display="flex"
                flexDirection="column"
                pt={2}
                px={1}
                sx={{
                    maxWidth: {
                        lg: "md"
                    }
                }}
                gap={1}
            >
                <Typography fontSize="1.75rem" fontWeight="500">
                    Something went wrong
                </Typography>
                <Typography variant="subtitle1">
                    Error message: {error.message}
                </Typography>
                <Typography variant="caption" >
                    {error.stack}
                </Typography>
                <Box display="flex" gap={1} justifyContent="center">
                    <Button onClick={resetErrorBoundary} variant="contained">
                        Retry
                    </Button>
                    <Button
                        href={`mailto:${TENERA_SUPPORT_EMAIL}?subject=${`Unexpected error on ${window.location.origin}`}&body=${getErrorReportEmailBody(error)}`}
                        variant="outlined"
                    >
                        Report error
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
    return (
        <ErrorFallbackContent error={error} resetErrorBoundary={resetErrorBoundary} />
    );
};;