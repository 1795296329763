import {
    Fragment,
    useMemo
} from "react";
import {
    WORD_ISSUE_PRICE,
    WORD_ISSUE_TITLE
} from "../ViewSolutionStyles";
import {
    dimensionIdsByIssueIdentifierAtomFamily,
    isSolutionSoleSourceSelectorFamily,
    issueByIssueIdentifierSelectorFamily
} from "../../../../lib/design/document/state/DocumentState";

import { ClipboardImageList } from "./ClipboardImageList";
import ClipboardSolutionDimension from "./ClipboardSolutionDimension";
import { ContextAwareIdentifier } from "../../../../lib/design/document/ContextAwareIdentifier";
import { Issue } from "../../../../models";
import { ModelType } from "../../../../lib/design/document/ModelType";
import NumberStringConverter from "../../../../lib/util/NumberStringConverter";
import { StateContext } from "../../../../lib/design/document/state/StateContext";
import { Typography } from "@mui/material";
import { hidePricesInUIAtom } from "../../../../lib/design/bidding/state/v2/ProposalStates";
import { proposalItemPriceTotalByIssueIdentifierSelectorFamily } from "../../../../lib/design/bidding/state/v2/ProposalItemStates";
import { useRecoilValue } from "recoil";

type ClipboardSolutionIssueProps = {
    readonly context: StateContext,
    readonly issueId: string;
    readonly isForClipboard?: boolean;
};

const ClipboardSolutionIssue = ({ context, issueId, isForClipboard = false }: ClipboardSolutionIssueProps) => {
    const issueIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
    }, []);
    const issue = useRecoilValue<Issue | null>(issueByIssueIdentifierSelectorFamily(issueIdentifier));
    const dimensionIds = useRecoilValue<Array<string>>(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier));
    const issuePriceTotal = useRecoilValue(proposalItemPriceTotalByIssueIdentifierSelectorFamily(issueIdentifier));
    const hidePrices = useRecoilValue<boolean>(hidePricesInUIAtom);
    const isSoleSource = useRecoilValue<boolean>(isSolutionSoleSourceSelectorFamily(StateContext.SOLUTION_VIEWING));

    return (
        <>
            <Typography style={WORD_ISSUE_TITLE} component="h2">
                {issue?.name}
            </Typography>
            <ClipboardImageList
                context={context}
                parentId={issue?.id!}
                parentType={ModelType.ISSUE}
                isForClipboard={isForClipboard}
            />
            {
                isSoleSource &&
                <Typography style={{ ...WORD_ISSUE_PRICE, textAlign: "right" }} component="h3" >
                    {hidePrices ? (
                        `Issue Total: $ -`
                    ) : (
                        `Issue Total: $ ${NumberStringConverter.numberToString(issuePriceTotal, 0, true)}`
                    )}
                </Typography>
            }
            {dimensionIds.map((dimensionId, index) => (
                <Fragment key={dimensionId}>
                    <ClipboardSolutionDimension
                        dimensionId={dimensionId}
                        context={context}
                    />
                </Fragment>
            ))}
        </>
    );
};

export default ClipboardSolutionIssue;
