import {
    Grid,
    IconButton,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
import {
    Organization,
    WorkTypeAssociationScopeEntity
} from "../../../models";
import {
    ProviderContext,
    useSnackbar
} from "notistack";
import {
    TYPOGRAPHY_ATTRIBUTE_LABEL,
    TYPOGRAPHY_ATTRIBUTE_VALUE
} from "../../../components/general/typography/TypographyStyles";
import {
    useRef,
    useState
} from "react";

import AssociateWorkTypeWithEntityHandler from "../../../lib/worktype/handler/association/AssociateWorkTypeWithEntityHandler";
import AssociateWorkTypeWithEntityHandlerFactory from "../../../lib/worktype/handler/association/AssociateWorkTypeWithEntityHandlerFactory";
import CancelIcon from '@mui/icons-material/Cancel';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TeamSearchBox from "../../team/TeamSearchBox";
import WorkTypeDTO from "../../../lib/worktype/DTO/WorkTypeDTO";
import { WorkTypeDisplayMode } from "../../../lib/worktype/state/WorkTypeDisplayMode";
import globalStyle from "../../../assets/style/globalStyle";
import { useSetRecoilState } from "recoil";
import { workTypeDisplayModeAtomFamily } from "../../../lib/worktype/state/WorkTypeState";

type AssociateWorkTypeManagementFormProps = {
    readonly workType: WorkTypeDTO;
};

const AssociateWorkTypeManagementForm = (props: AssociateWorkTypeManagementFormProps) => {
    /* Internal page states */
    const { workType } = props;
    const [team, setTeam] = useState<Organization | null>(null);
    const setDisplayMode = useSetRecoilState<WorkTypeDisplayMode>(workTypeDisplayModeAtomFamily(workType.groupId));

    /* Dependencies */
    const associateWorkTypeWithEntityHandlerRef = useRef<AssociateWorkTypeWithEntityHandler>(AssociateWorkTypeWithEntityHandlerFactory.getInstance());
    const globalClasses = globalStyle();
    const snackbar: ProviderContext = useSnackbar();

    const onSave = async () => {
        if (team) {
            try {
                await associateWorkTypeWithEntityHandlerRef.current.handle(team.id, WorkTypeAssociationScopeEntity.ORGANIZATION, workType);
                snackbar.enqueueSnackbar(`${workType.name} is now associated with ${team.legalName}`, {variant: "info"});
            } catch (error) {
                snackbar.enqueueSnackbar(`Failed to associate ${workType.name} with ${team.legalName}, please try again later.`, {variant: "error"});
            }
        }
    };

    return (
        <Grid
            container
            wrap="nowrap"
            alignItems="flex-start"
            direction="row"
            justifyContent="space-between"
        >
            <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                p={2}
            >
                <Grid item xs={12} sm={6}>
                    <ListItemText
                        primary={
                            <>
                                <Typography sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                                    Work Type Name
                                </Typography>
                                <Typography sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>
                                    {workType.name}
                                </Typography>
                            </>
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TeamSearchBox
                        onSelect={(team: Organization | null) => setTeam(team)}
                        onClear={() => setTeam(null)}
                        selectedTeam={team}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <Stack
                    minWidth="100%"
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <IconButton
                        className={globalClasses.squareIconButton}
                        onClick={onSave}
                        size="small"
                    >
                        <PersonAddIcon />
                    </IconButton>
                    <IconButton
                        className={globalClasses.squareIconButton}
                        size="small"
                        onClick={() => setDisplayMode(WorkTypeDisplayMode.VIEW)}
                    >
                        <CancelIcon />
                    </IconButton>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default AssociateWorkTypeManagementForm;
