import {
    MeetingProvider,
    VoiceFocusProvider,
    lightTheme
} from "amazon-chime-sdk-component-library-react";
import {
    joinMeetingInfoSelector,
    voiceFocusSpecNameSelector
} from "../state/ChimeStates";

import { AudioInjectionProvider } from "../sound/AudioInjectionProvider";
import { CustomRosterProvider } from "./CustomRosterProvider";
import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { TranscriptionProvider } from "../transcription/ChimeTranscriptionContext";
import { useRecoilValue } from "recoil";

export const CustomMeetingProvider = ({ children }: { children: ReactNode; }) => {
    const joinMeetingInfo = useRecoilValue(joinMeetingInfoSelector);
    const voiceFocusSpecName = useRecoilValue(voiceFocusSpecNameSelector);

    return (
        <ThemeProvider theme={lightTheme}>
            <MeetingProvider>
                <VoiceFocusProvider spec={{ name: voiceFocusSpecName }} createMeetingResponse={joinMeetingInfo}>
                    <CustomRosterProvider>
                        <TranscriptionProvider>
                            <AudioInjectionProvider>
                                {children}
                            </AudioInjectionProvider>
                        </TranscriptionProvider>
                    </CustomRosterProvider>
                </VoiceFocusProvider>
            </MeetingProvider>
        </ThemeProvider>
    );
};
