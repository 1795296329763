import Comparator from "../../util/data/sort/Comparator";
import { ComparisonResult } from "../../util/data/sort/ComparisonResult";
import { Property } from "../../../models";
import { SortDirection } from "../../sorting/SortDirection";

export class ByRecentlyUpdatedComparator implements Comparator<Property> {
    private readonly sortDirection: SortDirection;

    constructor(sortDirection: SortDirection) {
        this.sortDirection = sortDirection;
    }

    public compare(a: Property, b: Property): ComparisonResult {
        const aLocalLastUpdatedTime: number | null | undefined = a.localLastUpdatedTime;
        const bLocalLastUpdatedTime: number | null | undefined = b.localLastUpdatedTime;

        let result = 0;
        if (aLocalLastUpdatedTime != null && bLocalLastUpdatedTime != null) {
            result = aLocalLastUpdatedTime - bLocalLastUpdatedTime;
        }
        if (aLocalLastUpdatedTime == null || bLocalLastUpdatedTime == null) {
            const aUpdatedAt: string | null | undefined = a.updatedAt;
            const bUpdatedAt: string | null | undefined = b.updatedAt;
            if (aUpdatedAt != null && bUpdatedAt != null) {
                result = aUpdatedAt.localeCompare(bUpdatedAt);
            }
            if (aUpdatedAt == null || bUpdatedAt == null) {
                if (aLocalLastUpdatedTime == null) {
                    result = -1;
                }
                if (bLocalLastUpdatedTime == null) {
                    result = 1;
                }
            }
        }
        if (this.sortDirection === SortDirection.DESCENDING) {
            result = -result;
        }
        if (result < 0) {
            return ComparisonResult.LESS_THAN;
        }
        if (result > 0) {
            return ComparisonResult.GREATER_THAN;
        }
        return ComparisonResult.EQUAL;
    }
}