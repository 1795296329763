import { useCallback } from "react";

export const useGoogleLogin = () => {

    const loginToGoogle = useCallback(async (
        tokenClientConfig: Omit<google.accounts.oauth2.TokenClientConfig, "callback">
    ) => {
        await new Promise<void>((resolve, reject) => {
            try {
                const tokenClient = google.accounts.oauth2.initTokenClient({
                    ...tokenClientConfig,
                    error_callback: (error) => {
                        reject(error);
                    },
                    callback: (res) => {
                        resolve()
                    }
                });
                tokenClient.requestAccessToken();
            } catch (error) {
                reject(error);
            }
        });
    }, []);

    const logoutFromGoogle = useCallback(() => {
        const accessToken: string = gapi.auth.getToken()?.access_token;
        if (accessToken) {
            google.accounts.oauth2.revoke(accessToken, () => { });
        }
    }, []);

    return { loginToGoogle, logoutFromGoogle };
};