import {
    Box,
    Button,
    Typography
} from "@mui/material";
import {
    DropEvent,
    FileRejection
} from "react-dropzone";

import AddIcon from "../icons/AddIcon";
import { CustomDropZone } from "../image/CustomDropZone";
import { FileCard } from "./FileCard";
import { useSnackbar } from "notistack";

type TemplateInputProps = {
    uploadedTemplate: File | undefined;
    setFile: (file: File | undefined) => void;
};
export const TemplateInput = (props: TemplateInputProps) => {
    const { uploadedTemplate, setFile } = props;
    const snackbar = useSnackbar();

    const onDrop = (acceptedFiles: Array<File>, fileRejections: Array<FileRejection>, event: DropEvent) => {
        setFile(acceptedFiles[0]);
        fileRejections.forEach((rejection) => {
            snackbar.enqueueSnackbar(`File ${rejection.file.name} is rejected, reason: ${rejection.errors.map(error => error.message).join(", ")}`, { variant: "error" });
        });
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={1}
            alignItems="center"
            justifyContent="center"
            border={theme => `dashed 2px ${theme.palette.primary.light}`}
            borderRadius={1}
            height="100%"
            width="100%"
            p={1}
        >
            <CustomDropZone
                onDrop={onDrop}
                noClick={true}
                noKeyboard={true}
                accept={{
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
                }}
                multiple={false}
            >
                {({ open: openDropzoneInput }) => (
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
                        {uploadedTemplate ? (
                            <Box height="100%" width="min(100%, 330px)">
                                <FileCard
                                    file={uploadedTemplate}
                                    removeFile={() => setFile(undefined)}
                                />
                            </Box>
                        ) : (
                            <Box display="flex" flexDirection="column" py={1} alignItems="center" justifyContent="center">
                                <Typography variant="body2">
                                    Drag your template here to start uploading
                                </Typography>
                                <Typography>
                                    <Typography component="span" color="primary">
                                        &#11835;&nbsp;
                                    </Typography>
                                    <Typography component="span" color="text.primary">
                                        or
                                    </Typography>
                                    <Typography component="span" color="primary">
                                        &nbsp;&#11835;
                                    </Typography>
                                </Typography>
                                <Button variant="contained" startIcon={<AddIcon />} onClick={openDropzoneInput}>
                                    <Typography>
                                        Browse Files
                                    </Typography>
                                </Button>
                            </Box>
                        )}
                    </Box>
                )}
            </CustomDropZone >
        </Box >
    );
};