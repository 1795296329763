import {
    DefaultWorkTypePricing,
    WorkTypeGroup
} from "../../../models";
import {
    defaultWorkTypePricingByGroupIdAtomFamily,
    getUpdateWorkTypeCallback,
    getUpdateWorkTypeGroupCallback,
    workTypeDisplayModeAtomFamily,
    workTypeGroupByIdAtomFamily
} from "../../../lib/worktype/state/WorkTypeState";
import {
    useRecoilValue,
    useSetRecoilState
} from "recoil";

import SetDefaultPricingForWorkTypeGroupOrchestrator from "../../../lib/worktype/defaultPricing/orchestrator/SetDefaultPricingForWorkTypeGroupOrchestrator";
import SetDefaultPricingForWorkTypeGroupOrchestratorFactory from "../../../lib/worktype/defaultPricing/orchestrator/SetDefaultPricingForWorkTypeGroupOrchestratorFactory";
import WorkTypeDTO from "../../../lib/worktype/DTO/WorkTypeDTO";
import { WorkTypeDisplayMode } from "../../../lib/worktype/state/WorkTypeDisplayMode";
import WorkTypeManagementForm from "./WorkTypeManagementForm";
import { useRef } from "react";

type EditWorkTypeManagementFormProps = {
    readonly workType: WorkTypeDTO
}

const EditWorkTypeManagementForm = (props: EditWorkTypeManagementFormProps) => {
    /* Internal page state */
    const { workType } = props;
    const workTypeGroup = useRecoilValue<WorkTypeGroup | null>(workTypeGroupByIdAtomFamily(workType.groupId));
    const setDisplayMode = useSetRecoilState<WorkTypeDisplayMode>(workTypeDisplayModeAtomFamily(workType.groupId));
    const setDefaultWorkTypePricing = useSetRecoilState<DefaultWorkTypePricing | null>(defaultWorkTypePricingByGroupIdAtomFamily(workType.groupId));

    /* Dependencies */
    const updateWorkType = getUpdateWorkTypeCallback();
    const updateWorkTypeGroup = getUpdateWorkTypeGroupCallback();
    const setDefaultWorkTypePricingOrchestratorRef = useRef<SetDefaultPricingForWorkTypeGroupOrchestrator>(SetDefaultPricingForWorkTypeGroupOrchestratorFactory.getInstance());

    const onFormSave = async (workTypeForm: WorkTypeDTO, unitCost: number, unitPrice: number, isDiscoverable: boolean) => {
        updateWorkType(workTypeForm);
        if (workTypeGroup) {
            const updatedWorkTypeGroup: WorkTypeGroup = {
                ...workTypeGroup,
                isDiscoverable: isDiscoverable
            }
            updateWorkTypeGroup(updatedWorkTypeGroup);
        }
        const defaultWorkTypePricing = await setDefaultWorkTypePricingOrchestratorRef.current.orchestrate(workTypeForm.groupId, unitCost, unitPrice);
        setDefaultWorkTypePricing(defaultWorkTypePricing);
    };

    const onFormCancel = () => {
        setDisplayMode(WorkTypeDisplayMode.VIEW);
    };

    return (
        <WorkTypeManagementForm
            workType={workType}
            onFormSave={onFormSave}
            onFormCancel={onFormCancel}
        />
    );
};

export default EditWorkTypeManagementForm;
