import {
    Dimension,
    DimensionType,
    MeasurementUnit
} from "../../models";

import DimensionV2 from "./DimensionV2";
import uuidv4 from "../util/UuidGenerator";

export default class DimensionV2Factory {

    public static createEmptyDimension(): DimensionV2 {
        return {
            id: uuidv4(),
            measurementUnit: MeasurementUnit.FOOT
        }
    }

    public static fromV1Dimension(v1Dimension: Dimension): DimensionV2 {
        return {
            id: v1Dimension.id,
            description: v1Dimension.description as string,
            length: v1Dimension.length || undefined,
            width: v1Dimension.width || undefined,
            dimensionType: v1Dimension.width ? DimensionType.SQUARE : DimensionType.LINEAR,
            measurementUnit: DimensionV2Factory.v1UnitToMeasurementUnit(v1Dimension.lengthUnit)
        };
    }

    private static v1UnitToMeasurementUnit(unit: string | null | undefined): MeasurementUnit {
        switch (unit) {
            case "centimeters":
                return MeasurementUnit.CENTIMETER;
            case "meters":
                return MeasurementUnit.METER
            case "inches":
                return MeasurementUnit.INCH;
            case "feet":
            default:
                return MeasurementUnit.FOOT
        }
    }
}
