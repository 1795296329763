import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { DataStore } from "aws-amplify";
import DataStoreDimensionDAO from "./DataStoreDimensionDAO";
import DimensionDAO from "../DimensionDAO";

export default class DataStoreDimensionDAOFactory {
    private static readonly INSTANCE: DimensionDAO = new DataStoreDimensionDAO(
        ClientLoggerFactory.getClientLogger("DataStoreDimensionDAO"),
        DataStore
    )

    public static getDimensionDAO() {
        return DataStoreDimensionDAOFactory.INSTANCE;
    }
}
