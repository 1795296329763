import {
    Avatar,
    Divider,
    Grid,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {
    useEffect,
    useState
} from 'react';

import AttachmentDAOFactory from '../../lib/attachment/AttachmentDAOFactory';
import AttachmentRecordDAO from '../../lib/attachment/record/AttachmentRecordDAO';
import AttachmentUploadLinearProgress from './AttachmentUploadLinearProgress';
import { AttachmentUploadStatus } from '../../lib/attachment/upload/status/AttachmentUploadStatus';
import Box from '@mui/material/Box';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DataStoreAttachmentRecordDAOFactory from '../../lib/attachment/record/datastore/DataStoreAttachmentRecordDAOFactory';
import ImageIcon from '@mui/icons-material/Image';
import ImageVariant from '../../lib/attachment/ImageVariant';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { ZoomAnimatedFab } from '../general/fab/ZoomAnimatedFab';
import { incompleteAttachmentIdToAttachmentUploadStatusMapAtom } from '../../lib/attachment/upload/status/state/AttachmentUploadStatusRecoilState';
import { makeStyles } from '@mui/styles';
import { useRecoilValue } from 'recoil';

const useStyles = makeStyles((theme) => ({
    largeAvatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    drawerOpener: {
        height: 50,
        top: -50,
        backgroundColor: theme.palette.secondary.main,
        position: 'absolute',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        width: '100%',
        pointerEvents: 'auto',
        cursor: 'pointer'
    },
    deleteButton: {
        position: 'absolute',
        top: 0,
        right: 0
    }
}));

export default function AttachmentsUploadStatusDrawer() {
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const incompleteAttachmentIdToAttachmentUploadStatusMap = useRecoilValue<Map<string, AttachmentUploadStatus>>(incompleteAttachmentIdToAttachmentUploadStatusMapAtom);

    const [renderAttachmentIds, setRenderAttachmentIds] = useState<Array<string>>([]);
    const [additionalPendingAttachmentCount, setAdditionalPendingAttachmentCount] = useState<number | null>(null);

    const [attachmentDAO] = useState(AttachmentDAOFactory.getAttachmentDAO());
    const [attachmentRecordDAO] = useState<AttachmentRecordDAO>(DataStoreAttachmentRecordDAOFactory.getInstance());
    const [attachmentIdToAttachmentURLMap, setAttachmentIdToAttachmentURLMap] = useState<Map<string, string>>(new Map<string, string>());
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const mapAttachmentIdToThumbnailKey = async (attachmentId: string) => {
        try {
            const attachmentRecord = await attachmentRecordDAO.getById(attachmentId);
            return attachmentRecord.key ?? "";
        } catch (error) {
            return "";
        }
    };

    const fetchAttachmentURLsByAttachmentIds = async (attachmentIds: string[]) => {
        const attachmentIdsToFetch = attachmentIds.filter(attachmentId => !attachmentIdToAttachmentURLMap.has(attachmentId));
        const thumbnailKeys = await Promise.all(
            attachmentIdsToFetch.map(mapAttachmentIdToThumbnailKey)
        );
        const imagesURLs = await attachmentDAO.getAttachmentURLsByAttachmentKeys(
            thumbnailKeys,
            ImageVariant.ORIGINAL
        );
        setAttachmentIdToAttachmentURLMap(original => {
            const newState = new Map<string, string>(original);
            attachmentIdsToFetch.forEach((value, index) => {
                newState.set(value, imagesURLs[index]);
            });
            return newState;
        });
    };

    useEffect(() => {
        const attachmentIds = Array.from(incompleteAttachmentIdToAttachmentUploadStatusMap.keys());
        const additionalAttachmentCount = attachmentIds.length - 3;
        setAdditionalPendingAttachmentCount(additionalAttachmentCount > 0 ? additionalAttachmentCount : null);
        const renderAttachmentIds = attachmentIds.splice(0, 3);
        setRenderAttachmentIds(renderAttachmentIds);
        fetchAttachmentURLsByAttachmentIds(renderAttachmentIds);
    }, [incompleteAttachmentIdToAttachmentUploadStatusMap]);

    return (
        incompleteAttachmentIdToAttachmentUploadStatusMap.size > 0 ?
            <>
                <ZoomAnimatedFab
                    in={!isOpenDrawer}
                    color='secondary'
                    onClick={() => setIsOpenDrawer(true)}
                >
                    <CloudUploadIcon color='primary' />
                </ZoomAnimatedFab>
                <SwipeableDrawer
                    anchor="bottom"
                    BackdropProps={{
                        invisible: true
                    }}
                    open={isOpenDrawer}
                    onOpen={() => setIsOpenDrawer(true)}
                    onClose={() => setIsOpenDrawer(false)}
                    PaperProps={{
                        sx: {
                            maxHeight: '40%',
                            overflow: 'visible',
                            width: isSmallScreen ? '100%' : '300px',
                            ml: isSmallScreen ? '0' : 'auto',
                            mr: isSmallScreen ? '0' : 2
                        },
                    }}
                    variant={isSmallScreen ? 'temporary' : 'persistent'}
                >
                    <Box
                        alignItems='center'
                        boxShadow={4}
                        className={classes.drawerOpener}
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-around'
                        onClick={() => setIsOpenDrawer(prev => !prev)}
                        p={2}
                        sx={{
                            visibility: 'unset',
                        }}
                    >
                        <CloudUploadIcon color='primary' />
                        <Typography>
                            Attachment Upload Status
                        </Typography>
                    </Box>
                    <Box p={2} overflow='auto'>
                        {renderAttachmentIds.map((value, index) => {
                            return (
                                <Box key={value}>
                                    {index !== 0 && <Divider />}
                                    <Box my={2}>
                                        <Grid container alignItems='center'>
                                            <Grid xs={4} item>
                                                <Avatar
                                                    className={classes.largeAvatar}
                                                    src={attachmentIdToAttachmentURLMap.get(value)}
                                                    style={{ border: "2px solid white" }}
                                                    variant="square"
                                                >
                                                    <ImageIcon />
                                                </Avatar>
                                            </Grid>
                                            <Grid xs={8} item>
                                                <AttachmentUploadLinearProgress status={incompleteAttachmentIdToAttachmentUploadStatusMap.get(value)!} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            );
                        })}
                        {additionalPendingAttachmentCount && (
                            <Box p={1}>
                                <Typography>
                                    And {additionalPendingAttachmentCount} more {additionalPendingAttachmentCount === 1 ? "attachment" : "attachments"}.
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </SwipeableDrawer>
            </> : <></>
    );
}
