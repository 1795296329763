import {
    DefaultValue,
    RecoilState,
    Snapshot,
    atom,
    atomFamily,
    selectorFamily,
    useRecoilCallback
} from "recoil";
import {
    Proposal,
    Solution,
    SolutionTenderingType,
    WorkSpecification
} from "../../../../../models";
import {
    initializeProposalItemByWorkSpecification,
    priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily,
    priceCalculationTypeByProposalItemIdentifierAtomFamily,
    proposalItemIdByWorkSpecificationIdentifierAtomFamily,
    proposalItemIdToProposalItemBeforeAdjustmentMapByContextSelectorFamily,
    proposalItemIdToProposalItemMapByContextSelectorFamily,
    unitPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily,
    updateProposalItemConfiguredUnitPrice,
    workSpecificationIdToProposalItemIdMapByContextSelectorFamily
} from "./ProposalItemStates";

import AdjustmentItem from "../../../element/adjustment/AdjustmentItem";
import CleanUpProposalStateError from "../../errors/CleanUpProposalStateError";
import { ContextAwareIdentifier } from "../../../document/ContextAwareIdentifier";
import DefaultProposalPriceAfterAdjustmentCalculatorFactory from "../../DefaultProposalPriceAfterAdjustmentCalculatorFactory";
import DefaultProposalPriceBeforeAdjustmentCalculatorFactory from "../../DefaultProposalPriceBeforeAdjustmentCalculatorFactory";
import DefaultTotalAdjustmentAmountCalculatorFactory from "../../DefaultTotalAdjustmentAmountCalculatorFactory";
import GraphQLProposalRecordDAOFactory from "../../../../proposal/dao/record/GraphQLProposalRecordDAOFactory";
import GraphQLSolutionDAOFactory from "../../../../solution/majorVersion/dao/GraphQLSolutionDAOFactory";
import InitializeProposalStateError from "../../errors/InitializeProposalStateError";
import { ModelType } from "../../../document/ModelType";
import { PriceCalculationType } from "../../PriceCalculationType";
import ProposalContent from "../../ProposalContent";
import ProposalContentBuilder from "../../ProposalContentBuilder";
import ProposalContentDAO from "../../../../proposal/dao/content/ProposalContentDAO";
import ProposalItem from "../../ProposalItem";
import ProposalPriceAfterAdjustmentCalculator from "../../ProposalPriceAfterAdjustmentCalculator";
import ProposalPriceBeforeAdjustmentCalculator from "../../ProposalPriceBeforeAdjustmentCalculator";
import ProposalRecordDAO from "../../../../proposal/dao/record/ProposalRecordDAO";
import { ProposalType } from "../../ProposalType";
import S3ProposalContentDAOFactory from "../../../../proposal/dao/content/S3ProposalContentDAOFactory";
import SaveProposalHandler from "../../../../proposal/operation/save/SaveProposalHandler";
import SaveProposalHandlerFactory from "../../../../proposal/operation/save/SaveProposalHandlerFactory";
import SaveProposalStateError from "../../errors/SaveProposalStateError";
import SolutionContent from "../../../types/SolutionContent";
import SolutionDAO from "../../../../solution/majorVersion/dao/SolutionDAO";
import { StateContext } from "../../../document/state/StateContext";
import TotalAdjustmentAmountCalculator from "../../TotalAdjustmentAmountCalculator";
import WorkTypeDTO from "../../../../worktype/DTO/WorkTypeDTO";
import { proposalRecordAtom } from "../../../document/state/DocumentState";
import { workTypeByWorkTypeIdAtomFamily } from "../../../../worktype/state/WorkTypeRecoilState";

const totalAdjustmentAmountCalculator: TotalAdjustmentAmountCalculator =
    DefaultTotalAdjustmentAmountCalculatorFactory.getInstance();
const proposalPriceBeforeAdjustmentCalculator: ProposalPriceBeforeAdjustmentCalculator =
    DefaultProposalPriceBeforeAdjustmentCalculatorFactory.getInstance();
const proposalPriceAfterAdjustmentCalculator: ProposalPriceAfterAdjustmentCalculator =
    DefaultProposalPriceAfterAdjustmentCalculatorFactory.getInstance();
const solutionMajorVersionRecordDAO: SolutionDAO = GraphQLSolutionDAOFactory.getInstance();
const proposalRecordDAO: ProposalRecordDAO = GraphQLProposalRecordDAOFactory.getInstance();
const proposalContentDAO: ProposalContentDAO = S3ProposalContentDAOFactory.getInstance();
const saveProposalHandler: SaveProposalHandler = SaveProposalHandlerFactory.getInstance();

export const hidePricesInUIAtom = atom<boolean>({
    key: "hidePricesInUIAtom",
    default: false
});

export const hideAdjustmentsInUIAtom = atom<boolean>({
    key: "hideAdjustmentsInUIAtom",
    default: true
});

export const proposalTypeByContextAtomFamily = atomFamily<ProposalType, StateContext>({
    key: "proposalTypeByContextAtomFamily",
    default: ProposalType.SOLE_SOURCE
});

export const proposalAdjustmentItemsByContextAtomFamily = atomFamily<Array<AdjustmentItem>, StateContext>({
    key: "proposalAdjustmentItemsByContextAtomFamily",
    default: []
});

export const proposalAdjustmentAmountByContextSelectorFamily = selectorFamily<number | undefined, StateContext>({
    key: "proposalAdjustmentAmountSelector",
    get: (context: StateContext) => ({ get }) => {
        const adjustmentItems: Array<AdjustmentItem> = get(proposalAdjustmentItemsByContextAtomFamily(context));
        try {
            return totalAdjustmentAmountCalculator.calculate(adjustmentItems);
        } catch (error) {
            return undefined;
        }
    }
});

export const proposalPriceBeforeAdjustmentByContextSelectorFamily = selectorFamily<number | undefined, StateContext>({
    key: "proposalPriceBeforeAdjustmentByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const proposalItemIdToProposalItemBeforeAdjustmentMap: Map<string, ProposalItem> = get(
            proposalItemIdToProposalItemBeforeAdjustmentMapByContextSelectorFamily(context)
        );
        const proposalItems: Array<ProposalItem> = Array.from(proposalItemIdToProposalItemBeforeAdjustmentMap.values());
        try {
            return proposalPriceBeforeAdjustmentCalculator.calculate(proposalItems);
        } catch (error) {
            return undefined;
        }
    }
});

export const proposalPriceAfterAdjustmentByContextSelectorFamily = selectorFamily<number | undefined, StateContext>({
    key: "proposalPriceAfterAdjustmentByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const proposalPriceBeforeAdjustment = get(proposalPriceBeforeAdjustmentByContextSelectorFamily(context));
        const proposalAdjustmentAmount = get(proposalAdjustmentAmountByContextSelectorFamily(context));
        try {
            return proposalPriceAfterAdjustmentCalculator.calculate(proposalPriceBeforeAdjustment, proposalAdjustmentAmount);
        } catch (error) {
            return undefined;
        }
    }
});

export const wssIdToProposalItemMapByContextSelectorFamily = selectorFamily<Map<string, ProposalItem>, StateContext>({
    key: "wssIdToProposalItemMapByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const workSpecificationIdToProposalItemIdMap: Map<string, string> =
            get(workSpecificationIdToProposalItemIdMapByContextSelectorFamily(context));
        const workSpecificationIds: Array<string> = Array.from(workSpecificationIdToProposalItemIdMap.keys());
        const proposalItemIdToProposalItemMap: Map<string, ProposalItem> = get(proposalItemIdToProposalItemMapByContextSelectorFamily(context));
        return workSpecificationIds.reduce((accumulatorMap: Map<string, ProposalItem>, wssId: string) => {
            const proposalItemId = workSpecificationIdToProposalItemIdMap.get(wssId)!;
            if (!proposalItemIdToProposalItemMap.has(proposalItemId)) {
                return accumulatorMap;
            }
            const proposalItem = proposalItemIdToProposalItemMap.get(proposalItemId)!;
            accumulatorMap.set(wssId, proposalItem);
            return accumulatorMap;
        }, new Map<string, ProposalItem>());
    },
    set: (context: StateContext) => ({ set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            return;
        }
        const wssIdToProposalItemIdMap = new Map<string, string>();
        const proposalItemIdToProposalItemMap = new Map<string, ProposalItem>();
        for (const [wssId, proposalItem] of newValue.entries()) {
            wssIdToProposalItemIdMap.set(wssId, proposalItem.id);
            proposalItemIdToProposalItemMap.set(proposalItem.id, proposalItem);
        }
        set(workSpecificationIdToProposalItemIdMapByContextSelectorFamily(context), wssIdToProposalItemIdMap);
        set(proposalItemIdToProposalItemMapByContextSelectorFamily(context), proposalItemIdToProposalItemMap);
    }
});

export const proposalContentByContextSelectorFamily = selectorFamily<ProposalContent, StateContext>({
    key: "proposalSelector",
    get: (context: StateContext) => ({ get }) => {
        const proposalType: ProposalType = get(proposalTypeByContextAtomFamily(context));
        const adjustmentItems: Array<AdjustmentItem> = get(proposalAdjustmentItemsByContextAtomFamily(context));
        const proposalPriceBeforeAdjustment: number | undefined = get(proposalPriceBeforeAdjustmentByContextSelectorFamily(context));
        const proposalPriceAfterAdjustment: number | undefined = get(proposalPriceAfterAdjustmentByContextSelectorFamily(context));
        const wssIdToProposalItem: Map<string, ProposalItem> = get(wssIdToProposalItemMapByContextSelectorFamily(context));
        return new ProposalContentBuilder()
            .proposalType(proposalType)
            .adjustmentItems(adjustmentItems)
            .priceBeforeAdjustment(proposalPriceBeforeAdjustment)
            .priceAfterAdjustment(proposalPriceAfterAdjustment)
            .wssIdToProposalItemMap(wssIdToProposalItem)
            .build();
    },
    set: (context: StateContext) => ({ set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            reset(proposalTypeByContextAtomFamily(context));
            reset(proposalAdjustmentItemsByContextAtomFamily(context));
            reset(wssIdToProposalItemMapByContextSelectorFamily(context));
            return;
        }
        newValue.proposalType ? set(proposalTypeByContextAtomFamily(context), newValue.proposalType) : reset(proposalTypeByContextAtomFamily(context));
        newValue.adjustmentItems ? set(proposalAdjustmentItemsByContextAtomFamily(context), newValue.adjustmentItems) :
            reset(proposalAdjustmentItemsByContextAtomFamily(context));
        newValue.wssIdToProposalItemMap ? set(wssIdToProposalItemMapByContextSelectorFamily(context), newValue.wssIdToProposalItemMap) :
            reset(wssIdToProposalItemMapByContextSelectorFamily(context));
    }
});

export const getSaveProposal = (context: StateContext) => useRecoilCallback(({ snapshot, set }) => {
    return async (solutionId: string, solutionMinorVersion: number) => {
        try {
            return await saveProposal(snapshot, set, solutionId, solutionMinorVersion, context);
        } catch (error) {
            throw new SaveProposalStateError(
                `Error occurred while trying to save proposal for solution: ${solutionId}`,
                { cause: error as Error }
            );
        }
    };
});

const saveProposal = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void,
    solutionId: string,
    solutionMinorVersion: number,
    context: StateContext
): Promise<Proposal> => {
    const proposalContent: ProposalContent = await snapshot.getPromise(proposalContentByContextSelectorFamily(context));
    return await saveProposalHandler.handle(
        solutionId,
        solutionMinorVersion,
        proposalContent
    );
};

export const getInitializeProposalAndBackfillConfiguredPrice = (context: StateContext) => useRecoilCallback(({ snapshot, set }) => {
    return async (
        solutionRecord: Solution,
        solutionContent: SolutionContent
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            if (solutionRecord.metadata?.tenderingType !== SolutionTenderingType.TENDERED) {
                const proposalContent: ProposalContent = await initializeProposalBySolutionIdAndVersionAndContext(set, solutionRecord.id, solutionRecord.latestMinorVersion!, context);
                backfillProposalItemsConfiguredPrice(
                    snapshot,
                    set,
                    proposalContent,
                    solutionContent.workSpecificationIdToWorkSpecificationMap!,
                    context
                );
                return;
            }
            // Initialize a default proposal for tendered solution in case users switch tender type
            await Promise.all(Array.from(solutionContent.workSpecificationIdToWorkSpecificationMap?.values() ?? []).map(async workSpecification => {
                return await initializeProposalItemByWorkSpecification(snapshot, set, workSpecification, context);
            }));
            return;
        } catch (error) {
            throw new InitializeProposalStateError(
                `Error occurred while trying to initialize proposal content State for solution: ${solutionRecord.id}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

export const getInitializeProposal = (context: StateContext) => useRecoilCallback(({ set }) => {
    return async (
        solutionId: string,
        solutionMinorVersion: number
    ) => {
        try {
            return await initializeProposalBySolutionIdAndVersionAndContext(set, solutionId, solutionMinorVersion, context);
        } catch (error) {
            throw new InitializeProposalStateError(
                `Error occurred while trying to initialize proposal content State for solution: ${solutionId}`,
                { cause: error as Error }
            );
        }
    };
});

const initializeProposalBySolutionIdAndVersionAndContext = async (
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void,
    solutionId: string,
    solutionMinorVersion: number,
    context: StateContext
) => {
    const proposalRecords: Proposal[] = await proposalRecordDAO.listBySolutionIdAndMinorVersion(solutionId, solutionMinorVersion);
    const proposalRecord: Proposal = proposalRecords[0];
    const proposalContent: ProposalContent = await proposalContentDAO.get(proposalRecord.proposalKey!);
    set(proposalContentByContextSelectorFamily(context), proposalContent);
    set(proposalRecordAtom, proposalRecord);
    return proposalContent;
};

/**
 * When fetching an existing proposal item with manual price calculation type, configured price is not set up.
 * Therefore, need this function to back fill all the configured price.
 */
const backfillProposalItemsConfiguredPrice = (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void,
    proposalContent: ProposalContent,
    workSpecificationIdToWorkSpecificationMap: Map<string, WorkSpecification>,
    context: StateContext
) => {
    Array.from(proposalContent.wssIdToProposalItemMap?.entries() ?? []).forEach(([workSpecificationId, proposalItem]) => {
        if (proposalItem.priceCalculationType !== PriceCalculationType.MANUAL) {
            return;
        }
        const workSpecification: WorkSpecification | undefined = workSpecificationIdToWorkSpecificationMap.get(workSpecificationId);
        if (workSpecification?.workTypeId) {
            const selectedWorkType: WorkTypeDTO | undefined = snapshot.getLoadable(workTypeByWorkTypeIdAtomFamily(workSpecification.workTypeId)).contents;
            if (selectedWorkType && selectedWorkType.groupId) {
                updateProposalItemConfiguredUnitPrice(snapshot, set, context, proposalItem.id, selectedWorkType.groupId, workSpecification.measurement!);
            }
        }
    });
};

export const getCleanUpProposalStates = (context: StateContext) => useRecoilCallback(({ snapshot, reset }) => {
    return (
    ) => {
        try {
            cleanUpProposalStates(snapshot, reset, context);
        } catch (error) {
            throw new CleanUpProposalStateError(
                `Error occurred while trying to clean up proposal State in ${context}`,
                { cause: error as Error }
            );
        }
    };
});

const cleanUpProposalStates = async (
    snapshot: Snapshot,
    reset: (recoilVal: RecoilState<any>) => void,
    context: StateContext
) => {
    const workSpecificationIdToProposalItemMap: Map<string, ProposalItem> = snapshot.getLoadable(
        wssIdToProposalItemMapByContextSelectorFamily(context)
    ).contents;

    const workSpecificationIds: Array<string> = Array.from(workSpecificationIdToProposalItemMap.keys());
    const proposalItemIds: Array<string> = Array.from(workSpecificationIdToProposalItemMap.values()).map(proposalItem => proposalItem.id);

    workSpecificationIds.forEach(workSpecificationId => {
        const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, context, ModelType.WORK_SPECIFICATION);
        reset(proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier));
    });

    proposalItemIds.forEach(proposalItemId => {
        const proposalItemIdentifier = new ContextAwareIdentifier(proposalItemId, context, ModelType.PROPOSAL_ITEM);
        reset(priceCalculationTypeByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
        reset(priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
        reset(unitPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    });

    reset(proposalTypeByContextAtomFamily(context));
    reset(proposalAdjustmentItemsByContextAtomFamily(context));
    reset(proposalRecordAtom);
};
