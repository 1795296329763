import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const EraseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M1 21h22v2H1z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="m15.67 2-15 15 4 4h1.41l-4-4 3.2-3.19 6.1 6.1L10.26 21h1.44L23.33 9.67 15.67 2zM5.98 13.1l9.69-9.68 6.24 6.24-9.81 9.56-6.12-6.12z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(EraseIcon);
