import {
    API,
    graphqlOperation
} from 'aws-amplify';

import { AuthIDTokenSupplierFactory } from '../../../auth/AuthIDTokenSupplierFactory';
import ClientLoggerFactory from '../../../logging/ClientLoggerFactory';
import CounterAdoptingSerialNumberGeneratorFactory from "../../../serialNumber/CounterAdoptingSerialNumberGeneratorFactory";
import GraphQLSolutionDAO from "./GraphQLSolutionDAO";
import SolutionDAO from "./SolutionDAO";

export default class GraphQLSolutionDAOFactory {
    private static readonly INSTANCE: SolutionDAO = new GraphQLSolutionDAO(
        CounterAdoptingSerialNumberGeneratorFactory.getInstance(),
        API,
        graphqlOperation,
        AuthIDTokenSupplierFactory.getInstance(),
        ClientLoggerFactory.getClientLogger("GraphQLSolutionDAO")
    );

    public static getInstance(): SolutionDAO {
        return GraphQLSolutionDAOFactory.INSTANCE;
    }
}
