/**
 * Format: MAJOR_VERSION.MINOR_VERSION
 *
 * Version number is determined as follows:
 * {Major Version}.{Minor Version}.{Patch}
 * Large feature releases increment major version.
 * Scheduled deployments increment minor version.
 * Hotfixes increment patch number.
 **/
export const SOFTWARE_VERSION: string = "2.26.0";
