import Comparator from "../sort/Comparator";
import { ComparisonResult } from "../sort/ComparisonResult";
import DataGroup from "./DataGroup";

/**
 * Compares two {@link DataGroup} elements using {@link DataGroup.groupName}, ascending.
 */
export default class DataGroupStringKeyComparatorAsc<V> implements Comparator<DataGroup<string, V>> {
    compare(a: DataGroup<string, V>, b: DataGroup<string, V>): ComparisonResult {
        if (a.key.toLocaleLowerCase() < b.key.toLocaleLowerCase()) {
            return ComparisonResult.LESS_THAN;
        }
        if (a.key.toLocaleLowerCase() > b.key.toLocaleLowerCase()) {
            return ComparisonResult.GREATER_THAN;
        }
        return ComparisonResult.EQUAL;
    }
};
