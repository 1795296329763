import {
    Dimension,
    Issue,
    Location,
    Proposal,
    Solution,
    WorkSpecification
} from "../../../models";
import {
    RecoilState,
    Snapshot,
    atom,
    useRecoilCallback
} from "recoil";
import {
    dimensionIdToDimensionMapByContextSelectorFamily,
    issueIdToIssueMapByContextSelectorFamily,
    locationIdToLocationMapByContextSelectorFamily,
    proposalRecordAtom,
    solutionRecordAtom,
    workSpecificationIdToWorkSpecificationMapByContextSelectorFamily
} from "../../design/document/state/DocumentState";

import { ContextAwareIdentifier } from "../../design/document/ContextAwareIdentifier";
import DataStoreWorkTypeDAOFactory from "../../worktype/dataStore/DataStoreWorkTypeDAOFactory";
import { ModelType } from "../../design/document/ModelType";
import NumberStringConverter from "../../util/NumberStringConverter";
import ProposalItem from "../../design/bidding/ProposalItem";
import SetUpTableRowsError from "./error/SetUpTableRowsError";
import { StateContext } from "../../design/document/state/StateContext";
import WorkTypeDAO from "../../worktype/WorkTypeDAO";
import WorkTypeDTO from "../../worktype/DTO/WorkTypeDTO";
import { proposalItemByWorkSpecificationIdentifierSelectorFamily } from "../../design/bidding/state/v2/ProposalItemStates";

const workTypeDao: WorkTypeDAO = DataStoreWorkTypeDAOFactory.getInstance();

export const getSetUpTableRowsCallback = () => useRecoilCallback(({ snapshot, set }) => {
    return async () => {
        const releaseSnapshot = snapshot.retain();
        try {
            await setUpTableRows(snapshot, set);
        } catch (error) {
            throw new SetUpTableRowsError(
                `Error occurred while trying to set up solution table view rows`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

const setUpTableRows = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void
) => {
    const solutionRecord: Solution | undefined = snapshot.getLoadable(solutionRecordAtom).contents;
    const proposalRecord: Proposal | undefined = snapshot.getLoadable(proposalRecordAtom).contents;
    const locations: Array<Location> = Array.from(snapshot.getLoadable(locationIdToLocationMapByContextSelectorFamily(StateContext.SOLUTION_VIEWING)).contents.values());
    const issues: Array<Issue> = Array.from(snapshot.getLoadable(issueIdToIssueMapByContextSelectorFamily(StateContext.SOLUTION_VIEWING)).contents.values());
    const dimensions: Array<Dimension> = Array.from(snapshot.getLoadable(dimensionIdToDimensionMapByContextSelectorFamily(StateContext.SOLUTION_VIEWING)).contents.values());
    const workSpecifications: Array<WorkSpecification> = Array.from(snapshot.getLoadable(workSpecificationIdToWorkSpecificationMapByContextSelectorFamily(StateContext.SOLUTION_VIEWING)).contents.values());
    const result: Array<object> = [];
    for (const location of locations) {
        const locationIssues: Array<Issue> = issues.filter(issue => issue.locationId === location.id);
        for (const issue of locationIssues) {
            const issueDimensions: Array<Dimension> = dimensions.filter(dimension => dimension.parentId === issue.id);
            for (const dimension of issueDimensions) {
                const dimensionWorkSpecifications: Array<WorkSpecification> = workSpecifications.filter(workSpec => workSpec.dimensionId === dimension.id);
                for (const workSpecification of dimensionWorkSpecifications) {
                    const proposalItem: ProposalItem = snapshot.getLoadable(proposalItemByWorkSpecificationIdentifierSelectorFamily(new ContextAwareIdentifier(
                        workSpecification.id,
                        StateContext.SOLUTION_VIEWING,
                        ModelType.WORK_SPECIFICATION
                    ))).contents;
                    let optionalWorkType: WorkTypeDTO | undefined;
                    if (workSpecification.workTypeId!) {
                        optionalWorkType = await workTypeDao.getById(workSpecification.workTypeId!);
                    }
                    result.push({
                        id: workSpecification.id,
                        locationName: location.name,
                        issueName: issue.name,
                        length: workSpecification.measurement?.length,
                        width: workSpecification.measurement?.width,
                        dimensionDescription: dimension.description,
                        workType: optionalWorkType?.name || "N/A",
                        description: workSpecification.description,
                        longDescription: optionalWorkType?.longDescription || "N/A",
                        quantity: proposalItem?.unitCount,
                        unitType: workSpecification.measurement?.measurementUnit,
                        pricePerUnit: NumberStringConverter.numberToString(proposalItem?.unitPriceAfterAdjustment, 2, true),
                        totalPrice: NumberStringConverter.numberToString(proposalItem?.priceAfterAdjustment, 2, true),
                        solutionNumber: solutionRecord?.serialNumber,
                        proposalNumber: proposalRecord?.proposalNumber
                    });
                }
            }
        }
    }
    set(rowAtom, result);
};

export const rowAtom = atom<Array<object>>({
    key: "rowAtom",
    default: []
});
