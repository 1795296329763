import {
    propertyIdInFocusAtom,
    stateContextInFocusAtom
} from "../../lib/ui/InFocusRecoilStates";

import { AddLocationIcon } from "../icons";
import { PermissionType } from "../../models";
import { QuickAccessKey } from "./QuickAccessKey";
import { SolutionAuthoringEnabledPermissionRestrictedControl } from "../general/button/SolutionAuthoringEnabledPermissionRestrictedControl";
import { StateContext } from '../../lib/design/document/state/StateContext';
import { useCreateLocation } from "../../lib/location/hook/useCreateLocation";
import { useRecoilValue } from "recoil";

export const QuickCreateLocationKey = () => {
    const contextInFocus = useRecoilValue<StateContext>(stateContextInFocusAtom);
    const propertyIdInFocus = useRecoilValue<string | null>(propertyIdInFocusAtom);
    const { createDefaultLocation } = useCreateLocation(contextInFocus);

    return (
        <SolutionAuthoringEnabledPermissionRestrictedControl
            context={contextInFocus}
            requiredPermission={PermissionType.CREATE_CHILDREN}
            resourceId={propertyIdInFocus ?? ""}
        >
            {(disabled) => (
                <QuickAccessKey
                    disabled={disabled || !propertyIdInFocus}
                    onClick={() => {
                        if (!propertyIdInFocus) {
                            return;
                        }
                        createDefaultLocation(propertyIdInFocus);
                    }}
                    in
                >
                    <AddLocationIcon accent={disabled ? "disabled" : "primary"} />
                </QuickAccessKey>
            )}
        </SolutionAuthoringEnabledPermissionRestrictedControl>
    );
};