import API from "@aws-amplify/api";
import { AuthIDTokenSupplierFactory } from "../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { GraphQLEntityResourcePermissionDAO } from "./GraphQLEntityResourcePermissionDAO";
import { graphqlOperation } from "aws-amplify";

export class GraphQLEntityResourcePermissionDAOFactory {
    private static INSTANCE = new GraphQLEntityResourcePermissionDAO(
        API,
        graphqlOperation,
        ClientLoggerFactory.getClientLogger("GraphQlPropertyDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    )

    public static getInstance() {
        return this.INSTANCE;
    }
}