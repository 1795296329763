import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const AddPropertyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    style={{
      //@ts-ignore
      enableBackground: "new 0 0 24 24",
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m9 14-1 1v8H1V9.5l11-8.2c3.7 2.7 7.3 5.5 11 8.2v3.6c.4.4.7.8 1 1.3V9L12 0 0 9v15h9v-9h2.7c.2-.4.4-.7.6-1H9z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M18 12a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm2.667 6.667h-2v2a.666.666 0 0 1-1.333 0v-2h-2a.667.667 0 0 1 0-1.333h2v-2a.667.667 0 0 1 1.333 0v2h2a.666.666 0 1 1 0 1.333z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(AddPropertyIcon);
