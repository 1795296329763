import {
    API,
    graphqlOperation
} from "aws-amplify";

import { AuthIDTokenSupplierFactory } from "../../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import CounterAdoptingSerialNumberGeneratorFactory from "../../../serialNumber/CounterAdoptingSerialNumberGeneratorFactory";
import GraphQLProposalRecordDAO from "./GraphQLProposalRecordDAO";
import ProposalRecordDAO from "./ProposalRecordDAO";

export default class GraphQLProposalRecordDAOFactory {
    private static readonly INSTANCE: ProposalRecordDAO = new GraphQLProposalRecordDAO(
        CounterAdoptingSerialNumberGeneratorFactory.getInstance(),
        API,
        graphqlOperation,
        AuthIDTokenSupplierFactory.getInstance(),
        ClientLoggerFactory.getClientLogger("GraphQLProposalRecordDAO")
    );

    public static getInstance(): ProposalRecordDAO {
        return GraphQLProposalRecordDAOFactory.INSTANCE;
    }
}
