import ClientLogger from "../../../logging/ClientLogger";
import { DataStoreClass } from "@aws-amplify/datastore";
import DataStoreQueryIssuesByLocationIdPredicateFactory from "./query/filter/DataStoreQueryIssuesByLocationIdPredicateFactory";
import { Issue } from "../../../../models";
import IssueCreationDateSortDirectionAndLimitFactory from "./query/pagination/IssueCreationDateSortDirectionAndLimitFactory";
import { IssueDAO } from "../IssueDAO";
import LoggerCommonTags from "../../../logging/LoggerCommonTags";
import ResourceNotFoundError from "../../../ResourceNotFoundError";
import { SortDirection } from "aws-amplify";
import _ from "lodash";

export default class DataStoreIssueDAO implements IssueDAO {
    private static DELETE_ATTEMPT_METRIC_NAME = "DataStoreIssueDAO.Delete.Attempt"
    private static DELETE_FAILURE_METRIC_NAME = "DataStoreIssueDAO.Delete.Failure"

    private readonly dataStore: DataStoreClass;
    private readonly predicateSupplier: DataStoreQueryIssuesByLocationIdPredicateFactory<Issue>;
    private readonly logger: ClientLogger;
    private readonly paginationSupplier: IssueCreationDateSortDirectionAndLimitFactory<Issue>;

    constructor(
        dataStore: DataStoreClass,
        predicateSupplier: DataStoreQueryIssuesByLocationIdPredicateFactory<Issue>,
        logger: ClientLogger,
        paginationSupplier: IssueCreationDateSortDirectionAndLimitFactory<Issue>
    ) {
        this.dataStore = dataStore;
        this.predicateSupplier = predicateSupplier;
        this.logger = logger;
        this.paginationSupplier = paginationSupplier;
    }

    async getIssueById(issueId: string): Promise<Issue> {
        const result: Issue[] = await this.dataStore.query(Issue, issue => issue.id("eq", issueId));
        if (result.length === 0) {
            throw new ResourceNotFoundError("Issue");
        }
        return result[0];
    }

    public async getIssuesByLocationIdWithLimitAndSortDirection(
        locationId: string,
        sortDirection: SortDirection,
        limit: number
    ): Promise<Array<Issue>> {
        try {
            this.logger.info(
                `Fetching Issues with sortDirection and limit for: ${locationId}`,
                {},
                [LoggerCommonTags.ISSUE_LIST_RETRIEVAL_BY_LOCATION_ID_ATTEMPT]
            );
            const issues: Array<Issue> = await this.dataStore.query(
                Issue,
                this.predicateSupplier.create(locationId),
                this.paginationSupplier.create(sortDirection, limit)
            );
            return issues;
        } catch (error) {
            this.logger.error(
                `Error occurred while retrieving Issues by Location  with sortDirection and limit for: ${locationId}`,
                error,
                [LoggerCommonTags.ISSUE_LIST_RETRIEVAL_FAILURE]
            );
            throw error;
        }
    }

    public async getIssuesByLocationId(locationId: string): Promise<Array<Issue>> {
        try {
            this.logger.info(
                `Fetching Issues for: ${locationId}`,
                {},
                [LoggerCommonTags.ISSUE_LIST_RETRIEVAL_BY_LOCATION_ID_ATTEMPT]
            )
            const issues: Array<Issue> = await this.dataStore.query(
                Issue,
                this.predicateSupplier.create(locationId)
            );
            return issues;
        } catch (error) {
            this.logger.error(
                `Error occurred while retrieving Issues by Location for: ${locationId}`,
                error,
                [LoggerCommonTags.ISSUE_LIST_RETRIEVAL_FAILURE]
            )
            throw error;
        }
    }

    public async getIssuesByPropertyId(propertyId: string): Promise<Array<Issue>> {
        const issues: Array<Issue> = await this.dataStore.query(Issue, issue => issue.propertyId("eq", propertyId));
        return issues;
    }

    public async createNewIssue(issue: Issue): Promise<Issue> {
        try {
            const issueToCreate = new Issue({
                ...issue,
                clientCreationDate: Date.now(),
                localLastUpdatedTime: Date.now()
            });
            const createdIssue = await this.dataStore.save(issueToCreate);
            return createdIssue;
        } catch (error) {
            throw new Error("Failed to create the issue");
        }
    }

    public async editIssue(issueId: string, issue: Issue): Promise<Issue> {
        try {
            const existingIssue: Issue = await this.getIssueById(issueId);
            const issueToUpdate: Issue = Issue.copyOf(existingIssue, issueToUpdate => {
                issueToUpdate.name = issue.name;
                issueToUpdate.note = issue.note;
                issueToUpdate.propertyId = issue.propertyId;
                issueToUpdate.status = issue.status;
                issueToUpdate.localLastUpdatedTime = Date.now();
            });
            let result: Issue = existingIssue;
            if (!_.isEqual(existingIssue, issueToUpdate)) {
                result = await this.dataStore.save(issueToUpdate);
            }
            return result;
        } catch (error) {
            throw new Error("Failed to update the issue");
        }
    }

    public async deleteIssue(issueId: string): Promise<void> {
        this.logger.info(
            `Deleting issue with id: ${issueId}`,
            {},
            [DataStoreIssueDAO.DELETE_ATTEMPT_METRIC_NAME]
        );
        try {
            this.dataStore.delete(Issue, issueId);
        } catch (error) {
            this.logger.error(
                `Failed to delete issue with id: ${issueId}`,
                error,
                [DataStoreIssueDAO.DELETE_FAILURE_METRIC_NAME]
            );
            throw error;
        }
    }
}
