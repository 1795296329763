import ClientLogger from "../../logging/ClientLogger";
import { GoogleDriveFolder } from "../type/GoogleDriveFolder";
import { GoogleDriveFolderDAO } from "./GoogleDriveFolderDAO";
import { MimeType } from "../type/MimeType";
import { UnauthenticatedError } from "../error/UnauthenticatedError";

export class GApiGoogleDriveFolderDAO implements GoogleDriveFolderDAO {
    private static readonly GET_BY_NAME_ATTEMPT = "GET_BY_NAME_ATTEMPT";
    private static readonly GET_BY_NAME_SUCCESS = "GET_BY_NAME_SUCCESS";
    private static readonly GET_BY_NAME_FAILURE = "GET_BY_NAME_FAILURE";
    private static readonly CREATE_ATTEMPT = "CREATE_ATTEMPT";
    private static readonly CREATE_SUCCESS = "CREATE_SUCCESS";
    private static readonly CREATE_FAILURE = "CREATE_FAILURE";

    private readonly logger: ClientLogger;

    constructor(
        logger: ClientLogger,
    ) {
        this.logger = logger;
    }

    public async getByName(
        name: string,
        parentId?: string
    ): Promise<Array<gapi.client.drive.File>> {
        try {
            this.logger.info(
                `Attempting to get Google Drive folder by name: ${name}`,
                undefined,
                [GApiGoogleDriveFolderDAO.GET_BY_NAME_ATTEMPT]
            );
            const query = [
                `name = '${name}'`,
                `mimeType = '${MimeType.GOOGLE_FOLDER}'`,
                parentId ? `'${parentId}' in parents` : undefined,
            ].filter((q) => q != null)
                .join(' and ');
            const response: gapi.client.Response<gapi.client.drive.FileList> = await gapi.client.drive.files.list({
                q: query,
            });
            const data: Array<gapi.client.drive.File> = response.result.files ?? [];
            this.logger.info(
                `Successfully got Google Drive folder by name: ${name}`,
                undefined,
                [GApiGoogleDriveFolderDAO.GET_BY_NAME_SUCCESS]
            );
            return data;
        } catch (error) {
            this.logger.error(
                `Failed to get Google Drive folder by name: ${name}`,
                error,
                [GApiGoogleDriveFolderDAO.GET_BY_NAME_FAILURE]
            );
            if ((error as Response).status) {
                switch ((error as Response).status) {
                    case 401:
                    case 403:
                        throw new UnauthenticatedError("User is not authenticated.");
                    default:
                        break;
                }
            }
            throw error;
        }
    }

    public async createIfNotExists(folder: GoogleDriveFolder): Promise<gapi.client.drive.File> {
        try {
            this.logger.info(
                `Attempting to create Google Drive folder: ${JSON.stringify(folder)}`,
                undefined,
                [GApiGoogleDriveFolderDAO.CREATE_ATTEMPT]
            );
            let parentFolder: gapi.client.drive.File | undefined;
            if (folder.parentFolder) {
                parentFolder = await this.createIfNotExists(folder.parentFolder);
            }
            const existingFolder: Array<gapi.client.drive.File> = await this.getByName(folder.name!, parentFolder?.id);
            if (existingFolder && existingFolder.length > 0) {
                return existingFolder[0];
            }
            const { parentFolder: _, ...folderMetadata } = folder;
            const response: gapi.client.Response<gapi.client.drive.File> = await gapi.client.drive.files.create({
                resource: {
                    ...folderMetadata,
                    mimeType: MimeType.GOOGLE_FOLDER,
                    parents: parentFolder ? [parentFolder.id!] : undefined,
                },
            });
            this.logger.info(
                `Successfully created Google Drive folder: ${JSON.stringify(folder)}`,
                undefined,
                [GApiGoogleDriveFolderDAO.CREATE_SUCCESS]
            );
            return response.result;
        } catch (error) {
            if (!(error instanceof UnauthenticatedError)) {
                this.logger.error(
                    `Failed to create Google Drive folder: ${JSON.stringify(folder)}`,
                    error,
                    [GApiGoogleDriveFolderDAO.CREATE_FAILURE]
                );
                if ((error as Response).status) {
                    switch ((error as Response).status) {
                        case 401:
                        case 403:
                            throw new UnauthenticatedError("User is not authenticated.");
                        default:
                            break;
                    }
                }
            }
            throw error;
        }
    }
}
