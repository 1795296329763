import '@aws-amplify/ui-react/styles.css';
import './App.css';

import {
    AwsRum,
    AwsRumConfig
} from "aws-rum-web";
import {
    Box,
    CssBaseline
} from "@mui/material";
import {
    QueryClient,
    QueryClientProvider
} from "react-query";
import {
    Route,
    Switch,
    useLocation
} from "react-router-dom";
import {
    clearInterval,
    setInterval
} from "timers";
import {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

import AdminManagement from './pages/admin/AdminManagement';
import { CognitoUser as AmplifyUser } from "@aws-amplify/auth";
import { Auth } from 'aws-amplify';
import ClientLoggerFactory from './lib/logging/ClientLoggerFactory';
import { CognitoUser } from 'amazon-cognito-identity-js';
import CreateProperty from "./pages/property/CreateProperty";
import { CustomMeetingProvider } from './components/chime/customized/CustomMeetingProvider';
import { CustomerLinkSetUpPage } from './pages/linkRegistry/CustomerLinkSetUpPage';
import { DataStoreInitializationLayer } from './components/sync/DataStoreInitializationLayer';
import DefaultAttachmentUploadManagerFactory from './lib/attachment/upload/manager/DefaultAttachmentUploadManagerFactory';
import EditSolution from './components/solution/EditSolution';
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from './components/general/ErrorFallback';
import { FABManager } from './components/general/fab/FABManager';
import GlobalStateComponent from './components/globalstate/GlobalStateComponent';
import Home from "./pages/home/Home";
import LogRocket from 'logrocket';
import { OrganizationManagementPage } from "./pages/organization/OrganizationManagementPage";
import PropertiesPage from "./pages/property/PropertiesPage";
import PropertyDetails from './pages/property/PropertyDetails';
import { RecoilRoot } from 'recoil';
import { RecoverImageBackups } from "./pages/recovery/RecoverImageBackups";
import { SOFTWARE_VERSION } from './lib/util/troubleshooting/SoftwareVersion';
import { SettingsPage } from './pages/settings/SettingsPage';
import Sidebar from "./components/sidebar/Sidebar";
import { SnackbarProvider } from 'notistack';
import { StyledEngineProvider } from '@mui/material/styles';
import SurveyJSGlobalStateSetupHandlerFactory from './lib/ui/SurveyJSGlobalStateSetupHandlerFactory';
import SyncTest from './pages/test/SyncTest';
import SystemReadyRoute from './pages/route/SystemReadyRoute';
import { UnauthorizedPagePaths } from './lib/auth/bypass/UnauthorizedPagePaths';
import UpdateNotificationSnackbar from './components/globalstate/UpdateNotificationSnackbar';
import { VideoCall } from './pages/test/VideoCall';
import ViewLogin from './pages/login/ViewLogin';
import { ViewSettings } from './pages/settings/ViewSettings';
import ViewSolution from './components/solution/view/ViewSolution';
import awsExport from './aws-exports';
import { sleep } from './lib/util/concurrency/Sleep';
import { withBrowserRouter } from "./components/providers/withBrowserRouter";
import { withSignInPortal } from "./components/auth/withSignInPortal";
import { withThemeProvider } from "./components/theme/withThemeProvider";

//username is defined outside of App so that username will get updated inside syncExpression, or else syncExpression will not get updated.
let username: string;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    }
});

export let awsRum: AwsRum | undefined;

try {
    // @ts-ignore
    const RUM_APPLICATION_ID = awsExport.aws_cloudwatch_rum_app_id;
    if (!RUM_APPLICATION_ID) {
        throw new Error("RUM_APPLICATION_ID is not defined");
    }

    const REGION = awsExport.aws_project_region ?? "us-east-2";
    const config: AwsRumConfig = {
        sessionSampleRate: 1,
        identityPoolId: awsExport.aws_cognito_identity_pool_id,
        endpoint: `https://dataplane.rum.${REGION}.amazonaws.com`,
        telemetries: ["performance", "errors", "http"],
        allowCookies: true,
        enableXRay: true,
        sessionEventLimit: 1000,
        recordResourceUrl: false,
    };

    awsRum = new AwsRum(
        RUM_APPLICATION_ID,
        SOFTWARE_VERSION,
        REGION,
        config
    );
} catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
}

const AuthenticatedPages = withSignInPortal(({ user }: { user: CognitoUser; }) => {
    const clientLoggerRef = useRef(ClientLoggerFactory.getClientLogger("Root"));
    const attachmentUploadManager = useRef(DefaultAttachmentUploadManagerFactory.getInstance());
    const surveyJSGlobalStateSetupHandler = useRef(SurveyJSGlobalStateSetupHandlerFactory.getInstance());

    username = useMemo(() => {
        return user?.getUsername();
    }, [user]);

    useEffect(() => {
        awsRum?.recordEvent('UserLoggedIn', { data: 'User logged in successfully' });
        let intervalId: any;
        if (user) {
            intervalId = setInterval(() => {
                attachmentUploadManager.current.initiateUploadCycle();
            }, 30000);
            surveyJSGlobalStateSetupHandler.current.setup();
            initializeLogRocket(user);
            refreshTokenUntilSuccess();
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [username]);

    const initializeLogRocket = (user: AmplifyUser) => {
        // This identifies the user for this session in LogRocket
        LogRocket.identify(username);
    };

    const refreshTokenUntilSuccess = async () => {
        let shouldRefreshToken = true;
        while (shouldRefreshToken) {
            if (navigator.onLine) {
                try {
                    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
                    const currentSession = await Auth.currentSession();
                    await new Promise((resolve, reject) => {
                        cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
                            if (err || !session) {
                                reject(err);
                            }
                            resolve(session);
                        });
                    });
                    shouldRefreshToken = false;
                } catch (error) {
                    clientLoggerRef.current.warn(
                        "Refresh token failed, retry again after 30s",
                        error,
                        ["RefreshTokenFailure"]
                    );
                    shouldRefreshToken = true;
                }
            }
            await sleep(30000);
        }
    };
    return (
        <RecoilRoot key={username}>
            <StyledEngineProvider injectFirst={true}>
                <CustomMeetingProvider>
                    <CssBaseline />
                    <Sidebar>
                        <ErrorBoundary fallbackRender={ErrorFallback}>
                            <DataStoreInitializationLayer user={user}>
                                <GlobalStateComponent user={user} />
                                <FABManager />
                                <Box>
                                    <Switch>
                                        <Route component={Home} path="/" exact />

                                        <SystemReadyRoute component={PropertiesPage} path="/properties" exact />

                                        <SystemReadyRoute component={CreateProperty} path="/properties/create" exact />
                                        <SystemReadyRoute component={PropertyDetails} path="/properties/:propertyId" exact />
                                        <SystemReadyRoute component={PropertyDetails} path="/properties/:propertyId/issues" exact />
                                        <SystemReadyRoute component={PropertyDetails} path="/properties/:propertyId/solutions" exact />
                                        <SystemReadyRoute component={PropertyDetails} path="/properties/:propertyId/tendering" exact />

                                        {/* Routes for design components. */}
                                        <SystemReadyRoute component={EditSolution} path="/properties/:propertyId/solutions/create" exact />
                                        <SystemReadyRoute component={EditSolution} path="/properties/:propertyId/solutions/:solutionId/edit" exact />
                                        <SystemReadyRoute component={ViewSolution} path="/properties/:propertyId/solutions/:solutionId/view" exact />

                                        <Route component={ViewLogin} path="/login" exact />
                                        <SystemReadyRoute component={OrganizationManagementPage} path="/team" exact />

                                        <Route component={CustomerLinkSetUpPage} path="/link-registry/:id" exact />

                                        <Route component={SettingsPage} path="/settings" exact />

                                        {/* 'Hidden' Pages */}
                                        <SystemReadyRoute component={AdminManagement} path="/admin" exact />
                                        <SystemReadyRoute component={ViewSettings} path="/view-settings" exact />
                                        <Route component={RecoverImageBackups} path="/recovery" exact />

                                        {/* Test Page routes */}
                                        <Route component={SyncTest} path="/test/sync" exact />
                                    </Switch>
                                </Box>
                            </DataStoreInitializationLayer>
                        </ErrorBoundary>
                    </Sidebar>
                </CustomMeetingProvider>
            </StyledEngineProvider>
        </RecoilRoot>
    );
});

const UnauthenticatedPages = () => {
    return (
        <RecoilRoot key="unauthenticated">
            <SnackbarProvider>
                <StyledEngineProvider injectFirst={true}>
                    <CustomMeetingProvider>
                        <CssBaseline />
                        <Switch>
                            <Route component={VideoCall} path="/remote/inspection" exact />
                        </Switch>
                    </CustomMeetingProvider>
                </StyledEngineProvider>
            </SnackbarProvider>
        </RecoilRoot>
    );
};

const App = () => {
    const [bypassAuth, setBypassAuth] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        if (UnauthorizedPagePaths.includes(location.pathname)) {
            setBypassAuth(true);
            return;
        }
        setBypassAuth(false);
    }, [location]);

    useEffect(() => {
        LogRocket.init('4w8z6m/property-manager');
    }, []);

    return (
        <ErrorBoundary fallbackRender={ErrorFallback}>
            <QueryClientProvider client={queryClient}>
                {!bypassAuth && <AuthenticatedPages />}
                {bypassAuth && <UnauthenticatedPages />}
                <UpdateNotificationSnackbar />
            </QueryClientProvider>
        </ErrorBoundary>
    );
};

export default withBrowserRouter(
    withThemeProvider(
        App
    )
);
