import { LoggedInUserIdDAO } from "./LoggedInUserIdDAO";

export class LastLoggedInUserIdManager {
    private lastLoggedInUserIdDAO: LoggedInUserIdDAO;
    private currentLoggedInUserIdDAO: LoggedInUserIdDAO;

    constructor(
        lastLoggedInUserIdDAO: LoggedInUserIdDAO,
        currentLoggedInUserIdDAO: LoggedInUserIdDAO
    ) {
        this.lastLoggedInUserIdDAO = lastLoggedInUserIdDAO;
        this.currentLoggedInUserIdDAO = currentLoggedInUserIdDAO;
    }

    public async get(): Promise<string | undefined> {
        return await this.lastLoggedInUserIdDAO.get();
    }

    public async setNewLogin(currentUserId: string): Promise<void> {
        const previousUserId = await this.currentLoggedInUserIdDAO.get();
        if (previousUserId != undefined) {
            await this.lastLoggedInUserIdDAO.update(previousUserId);
        }
        await this.currentLoggedInUserIdDAO.update(currentUserId);
    }
} 