import Typography from '@mui/material/Typography';
import { WORD_TITLE } from '../ViewSolutionStyles';
import { solutionNotesAtom } from '../../../../lib/design/document/state/DocumentState';
import { useRecoilValue } from 'recoil';

const SolutionNotes = () => {
    const solutionNotes = useRecoilValue(solutionNotesAtom);
    return (
        <>
            {solutionNotes &&
                <>
                    <Typography style={WORD_TITLE} component="h1">
                        Additional Notes
                    </Typography>
                    <Typography
                        style={{ whiteSpace: "break-spaces" }}
                    >
                        {solutionNotes}
                    </Typography>
                </>
            }
        </>

    );
};

export default SolutionNotes;
