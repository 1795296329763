import {
    Fragment,
    useMemo
} from "react";

import ClipboardSolutionWorkSpecification from "./ClipboardSolutionWorkSpecification";
import { ContextAwareIdentifier } from "../../../../lib/design/document/ContextAwareIdentifier";
import { LineBreak } from "./LineBreak";
import { ModelType } from "../../../../lib/design/document/ModelType";
import { StateContext } from "../../../../lib/design/document/state/StateContext";
import { useRecoilValue } from "recoil";
import { workSpecificationIdsByDimensionIdentifierAtomFamily } from "../../../../lib/design/document/state/DocumentState";

type ClipboardSolutionDimensionProps = {
    readonly context: StateContext,
    readonly dimensionId: string;
    readonly isForClipboard?: boolean;
};

const ClipboardSolutionDimension = ({ context, dimensionId, isForClipboard = false }: ClipboardSolutionDimensionProps) => {
    const dimensionIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(dimensionId, context, ModelType.DIMENSION);
    }, []);
    const workSpecificationIds = useRecoilValue<Array<string>>(workSpecificationIdsByDimensionIdentifierAtomFamily(dimensionIdentifier));

    return (
        <>
            {workSpecificationIds.map((workSpecificationId, index) => (
                <Fragment key={index}>
                    <ClipboardSolutionWorkSpecification
                        context={context}
                        index={index}
                        workSpecificationId={workSpecificationId}
                        isForClipboard={isForClipboard}
                    />
                    <LineBreak />
                </Fragment>
            ))}
        </>
    );
};

export default ClipboardSolutionDimension;
