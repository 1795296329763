import ClientLogger from "../../logging/ClientLogger";
import { DataStoreConfigurationManager } from "./configuration/DataStoreConfigurationManager";
import { DataStoreLifecycleController } from "./lifecycle/DataStoreLifecycleController";
import { DataStoreSyncResult } from "./DataStoreSynchronizationConstants";
import { DataStoreSynchronizationOrchestrator } from "./DataStoreSynchronizationOrchestrator";
import { Property } from "../../../models";
import PropertyDAO from "../../property/dao/PropertyDAO";
import { PropertyListManager } from "./offline/PropertyListManager";

export class HierarchicalDataStoreSynchronizationOrchestrator implements DataStoreSynchronizationOrchestrator {
    private static PROPERTY_SYNC_ERROR = "PropertySynchronizationError";

    private configurationManager: DataStoreConfigurationManager;
    private dataStoreLifecycleController: DataStoreLifecycleController;
    private offlinePropertyListManager: PropertyListManager;
    private recentlyCreatedPropertyListManager: PropertyListManager;
    private propertyDAO: PropertyDAO;
    private numberOfPropertiesToSync: number;
    private logger: ClientLogger;

    constructor(
        configurationManager: DataStoreConfigurationManager,
        dataStoreLifecycleController: DataStoreLifecycleController,
        offlinePropertyListManager: PropertyListManager,
        pendingUploadPropertyListManager: PropertyListManager,
        propertyDAO: PropertyDAO,
        numberOfPropertiesToSync: number,
        logger: ClientLogger
    ) {
        this.configurationManager = configurationManager;
        this.dataStoreLifecycleController = dataStoreLifecycleController;
        this.offlinePropertyListManager = offlinePropertyListManager;
        this.recentlyCreatedPropertyListManager = pendingUploadPropertyListManager;
        this.propertyDAO = propertyDAO;
        this.numberOfPropertiesToSync = numberOfPropertiesToSync;
        this.logger = logger;
    }

    public async initialize(): Promise<boolean> {
        await this.dataStoreLifecycleController.configure();
        await this.dataStoreLifecycleController.synchronize();
        return true;
    }

    public async syncPropertiesChildren(): Promise<boolean> {
        await this.initializeOfflinePropertyList();
        const propertyIds: Array<string> = await this.offlinePropertyListManager.getList();
        await this.syncPropertyChildren(propertyIds);
        return true;
    }

    private async initializeOfflinePropertyList(): Promise<void> {
        if (!await this.offlinePropertyListManager.isInitialized()) {
            const properties: Array<Property> = await this.propertyDAO.listProperties(this.numberOfPropertiesToSync); // sorted by most recent first
            // reverse the list so that least recent are added first
            for (const property of properties.reverse()) {
                await this.offlinePropertyListManager.addPropertyId(property.id);
            }
        }
    }

    private async syncPropertyChildren(propertyIds: Array<string>): Promise<DataStoreSyncResult> {
        this.configurationManager.setPropertyIds(propertyIds);
        return await this.dataStoreLifecycleController.synchronize();
    }

    public async addPropertyIdsAndSync(
        propertyIdsToAdd: Array<string>,
        fullSync: boolean
    ): Promise<boolean> {
        try {
            await this.initializeOfflinePropertyList();
            const offlinePropertyIds: Array<string> = await this.offlinePropertyListManager.getList(); // sorted by least recent first
            const recentlyCreatedPropertyIds: Array<string> = await this.recentlyCreatedPropertyListManager.getList();
            // reverse offlinePropertyIds so that least recently updated are removed first
            const propertiesToSync: Array<string> = this.createListOfPropertiesToSync(propertyIdsToAdd, recentlyCreatedPropertyIds, offlinePropertyIds.reverse());

            // if there are no new properties not already in the offline list to sync, then we are done
            const newPropertyIdsToSync: Array<string> = propertiesToSync.filter(propertyId => !offlinePropertyIds.includes(propertyId));
            if (!fullSync && newPropertyIdsToSync.length === 0) {
                return true;
            }
            // Otherwise sync all the new properties
            const syncResult = await this.syncPropertyChildren(propertiesToSync);
            if (syncResult === DataStoreSyncResult.FAILURE) {
                // if the sync failed, we should revert the offline property list to the previous state
                await this.syncPropertyChildren(offlinePropertyIds);
                return false;
            }
            // Once complete add the new properties to the offline property list
            for (const propertyId of newPropertyIdsToSync) {
                await this.offlinePropertyListManager.addPropertyId(propertyId);
            }
            // Empty the recently created property list, since we have now synced all of them
            await this.recentlyCreatedPropertyListManager.clear();
            return true;
        } catch (error) {
            this.logger.error(
                "Error synchronizing properties",
                error,
                [HierarchicalDataStoreSynchronizationOrchestrator.PROPERTY_SYNC_ERROR]
            );
            return false;
        }
    }

    private createListOfPropertiesToSync(
        requestedPropertyIdList: Array<string>,
        recentlyCreatedPropertyIdList: Array<string>,
        offlinePropertyIdList: Array<string>
    ): Array<string> {
        // This is the order of precedence for the properties to sync
        const mergedPropertyIdLists: Array<string> = [...requestedPropertyIdList, ...recentlyCreatedPropertyIdList, ...offlinePropertyIdList];
        // remove duplicate
        const propertyIdsToDownload: Array<string> = mergedPropertyIdLists.filter((item, index) => mergedPropertyIdLists.indexOf(item) === index);
        // now we return the first offlinePropertyIdList.length elements of the new list
        return propertyIdsToDownload.splice(0, offlinePropertyIdList.length);
    }
}