import {
    CacheProvider,
    EmotionCache
} from "@emotion/react";
import {
    useEffect,
    useState
} from "react";

import { StyleSheetManager } from "styled-components";
import createCache from '@emotion/cache';
import { createPortal } from "react-dom";

type ExternalWindowPopUpProps = {
    children: React.ReactNode;
    onClose: () => void;
};

function copyStyles(source: Document, destination: Document) {
    // Copy head elements from the original document to the new one
    source.head.childNodes.forEach((node) => {
        destination.head.appendChild(node.cloneNode(true));
    });

    // Copy inline styles from body to the new document
    const sourceBodyStyle = source.body.getAttribute("style");
    if (!sourceBodyStyle) {
        return;
    }
    destination.body.setAttribute("style", sourceBodyStyle);
}

export const ExternalWindowPopUp = ({ onClose, children }: ExternalWindowPopUpProps) => {
    const [portalRoot, setPortalRoot] = useState<HTMLDivElement | null>(null);
    const [externalWindow, setExternalWindow] = useState<Window | null>(null);
    const [emotionCache, setEmotionCache] = useState<EmotionCache | null>(null);

    useEffect(() => {
        const createdPortalRoot = document.createElement("div");
        setPortalRoot(createdPortalRoot);
        const createdExternalWindow = window.open("", "", "width=600,height=400,left=200,top=200, menubar=no, toolbar=no, location=no, status=no");
        setExternalWindow(createdExternalWindow);
        createdExternalWindow?.document.body.appendChild(createdPortalRoot);
        copyStyles(document, createdExternalWindow?.document!); // necessary for styles from css files (for instance, App.css and index.css)
        const createdEmotionCache = createCache({ key: "external-window", container: createdExternalWindow?.document.head });
        setEmotionCache(createdEmotionCache);
        const cleanup = () => {
            onClose();
            createdExternalWindow?.close();
            createdPortalRoot.remove();
            setPortalRoot(null);
            setExternalWindow(null);
            setEmotionCache(null);
        };
        createdExternalWindow?.addEventListener("unload", cleanup);
        return () => {
            createdExternalWindow?.removeEventListener("unload", cleanup);
            cleanup();
        };
    }, []);

    return portalRoot && externalWindow && emotionCache ? (
        createPortal(
            <CacheProvider value={emotionCache}>
                <StyleSheetManager target={externalWindow?.document.head}>
                    {portalRoot && (
                        <>
                            {children}
                        </>
                    )}
                </StyleSheetManager>
            </CacheProvider>,
            portalRoot
        )
    ) : <></>;
};