import { CircleTickIcon } from "../../../components/icons";
import { ClickAwayListener } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ImageCarouselOption from "./ImageCarouselOption";
import { useState } from "react";

type DeleteImageCarouselOptionProps = {
    readonly onDeleteImage?: () => void;
    readonly disabled?: boolean;
};

export const DeleteImageCarouselOption = (props: DeleteImageCarouselOptionProps) => {
    const { onDeleteImage, disabled } = props;
    const [clickedDelete, setClickedDelete] = useState(false);

    return (
        <ClickAwayListener onClickAway={() => setClickedDelete(false)}>
            <ImageCarouselOption
                IconComponent={clickedDelete ? CircleTickIcon : DeleteOutlineIcon}
                iconProps={clickedDelete ? { primary: "error" } : undefined}
                tooltip="Delete"
                onClick={() => {
                    if (clickedDelete) {
                        onDeleteImage?.();
                    }
                    setClickedDelete(prev => !prev);
                }}
                disabled={disabled}
            />
        </ClickAwayListener>
    );
};