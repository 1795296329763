import Comparator from "./Comparator";
import { ComparisonResult } from "./ComparisonResult";
import DataSorter from "./DataSorter";

/**
 * Sorts arrays using merge sort algorithm and provided {@link Comparator}.
 */
export default class MergeDataSorter<T> implements DataSorter<T> {

    private readonly comparator: Comparator<T>;

    constructor(comparator: Comparator<T>) {
        this.comparator = comparator;
    }

    private merge(left: T[], right: T[]): T[] {
        let array: T[] = [];
        while (left.length && right.length) {
            if (this.comparator.compare(left[0], right[0]) === ComparisonResult.LESS_THAN) {
                array.push(left.shift() as T);
            } else {
                array.push(right.shift() as T);
            }
        }
        return [...array, ...left, ...right];
    }

    public sort(array: T[]): T[] {
        const arrayCopy = array;
        if (arrayCopy.length < 2) {
            return arrayCopy;
        }
        const half = arrayCopy.length / 2;
        const left = arrayCopy.splice(0, half);
        return this.merge(
            this.sort(left),
            this.sort(arrayCopy)
        );
    }
};
