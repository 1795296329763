import {
    Box,
    Checkbox,
    Grid,
    ListItem,
    Stack,
    Typography
} from "@mui/material";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import WorkTypeDTO from "../../lib/worktype/DTO/WorkTypeDTO";
import { isWorkTypeSelectedByWorkTypeGroupIdSelectorFamily } from "../../lib/worktype/state/WorkTypePickerState";
import { latestWorkTypeByGroupIdAtomFamily } from "../../lib/worktype/state/WorkTypeState";

interface WorkTypePickerItemProps {
    readonly workTypeGroupId: string
}

const WorkTypePickerItem = (props: WorkTypePickerItemProps) => {
    const { workTypeGroupId } = props;
    /* Internal page states */
    const workType = useRecoilValue<WorkTypeDTO | null>(latestWorkTypeByGroupIdAtomFamily(workTypeGroupId));
    const [isWorkTypeSelected, setIsWorkTypeSelected] = useRecoilState<boolean>(isWorkTypeSelectedByWorkTypeGroupIdSelectorFamily(workTypeGroupId));

    return (
        <>
            <ListItem disablePadding sx={{ minHeight: "5em" }}>
                <Box pl={3} width="100%">
                    <Grid
                        container
                        wrap="nowrap"
                    >
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Checkbox
                                checked={isWorkTypeSelected}
                                onChange={(event, isChecked) => {
                                    setIsWorkTypeSelected(isChecked);
                                }}
                            />
                            <Typography variant="body1">
                                {workType?.name || "N/A"}
                            </Typography>
                        </Stack>
                    </Grid>
                </Box>
            </ListItem>
        </>
    );
}

export default WorkTypePickerItem;
