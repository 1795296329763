import {
    Box,
    Chip,
    FormControl,
    MenuItem,
    Select
} from '@mui/material';

import FormFieldSummary from '../form/FormFieldSummary';
import { ItemValue } from 'survey-core';
import { SurveyQuestionCheckbox } from 'survey-react-ui';

export default class MUISurveyQuestionCheckbox extends SurveyQuestionCheckbox {

    constructor(props: any) {
        super(props);
    }

    renderElement() {

        const getStyles = (option: string, selectedOptions: readonly string[]) => {
            return {
                fontWeight:
                    selectedOptions.indexOf(option) === -1 ? '400' : '500',
            };
        };

        const onChange = (e: any) => {
            this.question.value = e.target.value;
        };

        return (
            <>
                <FormControl
                        error={this.question.errors.length > 0}
                        fullWidth
                        required={this.question.isRequired}
                        variant='standard'
                >
                    <Select
                        error={this.question.errors.length > 0}
                        id={this.question.inputId}
                        multiple
                        onChange={onChange}
                        renderValue={(selected: any) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                {selected.map((value: string) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            // getContentAnchorEl: null,
                            PaperProps: {
                                style: {
                                    maxHeight: 250,
                                    width: 250
                                }
                            }
                        }}
                        value={this.question.value}
                    >
                        {this.question.visibleChoices.map((option: ItemValue) => {
                            return (
                                <MenuItem
                                    key={option.text}
                                    value={option.value}
                                    style={getStyles(option.value, this.question.value)}
                                >
                                    {option.text}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormFieldSummary
                    isValid={this.question.errors.length === 0}
                    errorText={`${this.question.title} is required.`}
                />
            </>

        );
    }
}
