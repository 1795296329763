import {
    E_ALREADY_LOCKED,
    Mutex,
    tryAcquire
} from "async-mutex";

import TokenBucket from "./TokenBucket";
import TokenBucketRefiller from "./TokenBucketRefiller";
import TokenRefillStrategy from "./TokenRefillStrategy";
import { sleep } from "../concurrency/Sleep";

export default class TokenBucketRefillerImpl implements TokenBucketRefiller {
    private readonly tokenRefillMutex: Mutex;
    private readonly tokenRefillStrategy: TokenRefillStrategy;
    private readonly tokenBucket: TokenBucket;

    constructor(
        tokenRefillMutex: Mutex,
        tokenRefillStrategy: TokenRefillStrategy,
        tokenBucket: TokenBucket
    ) {
        this.tokenRefillMutex = tokenRefillMutex;
        this.tokenRefillStrategy = tokenRefillStrategy;
        this.tokenBucket = tokenBucket;
    };

    public async reportSuccess(): Promise<void> {
        return this.tokenRefillStrategy.reportSuccess();
    }

    public async reportFailure(): Promise<void> {
        return this.tokenRefillStrategy.reportFailure();
    }

    public async scheduleNextRefill(): Promise<void> {
        await tryAcquire(this.tokenRefillMutex)
            .runExclusive(async () => {
                let isBucketFull = this.tokenBucket.isBucketFull();
                while (!isBucketFull) {
                    const delay = this.tokenRefillStrategy.getNextTokenRefillDelay();
                    await sleep(delay);
                    this.tokenBucket.addToken();
                    isBucketFull = this.tokenBucket.isBucketFull();
                }
            })
            .catch(error => {
                if (error !== E_ALREADY_LOCKED) {
                    throw error;
                }
            });
    }
}
