import {
    Box,
    Typography,
    useTheme
} from "@mui/material";
import {
    DimensionType,
    Measurement,
    MeasurementUnit
} from "../../models";
import {
    priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily,
    unitCountByProposalItemIdentifierSelectorFamily,
    unitPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily
} from "../../lib/design/bidding/state/v2/ProposalItemStates";

import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import DimensionTypeAbbreviationFactory from "../../lib/util/dimension/DimensionTypeAbbreviationFactory";
import { ExpandableDisplayText } from "../misc/ExpandableDisplayText";
import MeasurementUnitAbbreviationFactory from "../../lib/util/dimension/MeasurementUnitAbbreviationFactory";
import { ModelType } from "../../lib/design/document/ModelType";
import NumberStringConverter from "../../lib/util/NumberStringConverter";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";

type ViewProposalItemProps = {
    proposalItemId: string;
    context: StateContext;
    measurement: Measurement;
};

const XS_DESCRIPTION_FONT_SIZE: string = "0.625rem";

export const ViewProposalItem = (props: ViewProposalItemProps) => {
    const { proposalItemId, context, measurement } = props;
    const proposalItemIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(proposalItemId, context, ModelType.PROPOSAL_ITEM);
    }, [proposalItemId, context]);
    const theme = useTheme();
    const unitPriceBeforeAdjustment = useRecoilValue<number | undefined>(unitPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    const totalPriceBeforeAdjustment = useRecoilValue<number | undefined>(priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    const unitCount = useRecoilValue<number | undefined>(unitCountByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    const dimensionTypeString: string = useMemo(() => {
        if (!measurement?.dimensionType) {
            return "";
        }
        return DimensionTypeAbbreviationFactory.getAbbreviation(measurement?.dimensionType as DimensionType);
    }, [measurement?.dimensionType]);
    const measurementUnitString: string = useMemo(() => {
        if (!measurement?.measurementUnit) {
            return "";
        }
        return MeasurementUnitAbbreviationFactory.getAbbreviation(measurement?.measurementUnit as MeasurementUnit);
    }, [measurement?.measurementUnit]);

    return (
        <Box
            height="100%"
            display="grid"
            alignContent={{ sm: "space-around" }}
            gridTemplateColumns={{
                xs: "minmax(60px, 80px) minmax(60px, 80px) 1fr minmax(60px, 80px)",
                sm: "1fr"
            }}
            gridTemplateAreas={{
                xs: `
                    "unitPriceBeforeAdjustment  unitCount . totalPriceBeforeAdjustment"
                `,
                sm: `
                    "totalPriceBeforeAdjustment"
                    "unitPriceBeforeAdjustment"
                `
            }}
            gap={1}
        >
            <Box gridArea="unitCount" display={{ xs: "flex", sm: "none" }} alignItems="center">
                <Typography fontSize={{ xs: theme.typography.body2.fontSize, sm: theme.typography.body1.fontSize }}>
                    {unitCount ?? "N/A"} &nbsp;
                </Typography>
                <Typography fontSize={XS_DESCRIPTION_FONT_SIZE} color="text.secondary">
                    {dimensionTypeString} {measurementUnitString}
                </Typography>
            </Box>
            <Box gridArea="unitPriceBeforeAdjustment" display="flex" alignItems="center">
                <Typography fontSize={{ xs: XS_DESCRIPTION_FONT_SIZE, sm: theme.typography.body1.fontSize }} color="text.secondary">
                    $&nbsp;&nbsp;
                </Typography>
                <ExpandableDisplayText
                    description={
                        unitPriceBeforeAdjustment ? NumberStringConverter.numberToString(unitPriceBeforeAdjustment, 0, true) : "N/A"
                    }
                    linesToDisplayWhenCollapsed={1}
                    typographyProps={{
                        fontSize: {
                            xs: theme.typography.body2.fontSize,
                            sm: theme.typography.body1.fontSize
                        }
                    }}
                />
                <Typography fontSize={{ xs: XS_DESCRIPTION_FONT_SIZE, sm: theme.typography.body1.fontSize }} color="text.secondary">
                    / {dimensionTypeString} {measurementUnitString}
                </Typography>
            </Box>
            <Box gridArea="totalPriceBeforeAdjustment" display={{ xs: "flex" }} alignItems="center" justifyContent="space-between">
                <Typography fontSize={{ xs: XS_DESCRIPTION_FONT_SIZE, sm: theme.typography.body1.fontSize }} color="text.secondary">
                    $&nbsp;&nbsp;
                </Typography>
                <Box display="flex" justifyContent={{ sm: "center" }} flex={1}>
                    <ExpandableDisplayText
                        description={
                            totalPriceBeforeAdjustment ? NumberStringConverter.numberToString(totalPriceBeforeAdjustment, 0, true) : "N/A"
                        }
                        linesToDisplayWhenCollapsed={1}
                        typographyProps={{
                            fontSize: {
                                xs: theme.typography.body1.fontSize,
                                sm: "1.125rem"
                            }
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};
