import { PermissionResourceType } from "../../../models";

export class Resource {
    public resourceId: string;
    public resourceType: PermissionResourceType;

    toJSON: () => string = () => {
        return `${this.resourceType}_${this.resourceId}`;
    };

    constructor(
        resourceId: string,
        resourceType: PermissionResourceType
    ) {
        this.resourceId = resourceId;
        this.resourceType = resourceType;
    }
}