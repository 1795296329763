export class RefetchNotifier {
    private static subscribers: Map<string, () => void> = new Map<string, () => void>();

    public static subscribe(key: string, callback: () => void): void {
        this.subscribers.set(key, callback);
    }

    public static unsubscribe(key: string): void {
        this.subscribers.delete(key);
    }

    public static notify(id: string): void {
        const callback: (() => void) | undefined = this.subscribers.get(id);
        if (callback) {
            callback();
        }
    }
}