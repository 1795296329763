export default class NumberStringConverter {
    static stringToNumber(numberString: string) {
        if (numberString.length === 0) {
            return null;
        } else {
            return parseFloat(numberString);
        }
    }

    static numberToString(
        number: number | undefined,
        decimalPoints: number,
        useThousandsSeparator: boolean
    ) {
        if (typeof number !== "number") {
            return "";
        }
        const result = number.toFixed(decimalPoints).toString();
        if (useThousandsSeparator) {
            return result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return result;
    }
}