import {
    atomFamily,
    selectorFamily
} from "recoil";
import {
    filteredIssuesByContextSelectorFamily,
    filteredLocationIdsByContextSelectorFamily,
    issueFeatureConfigurationByContextAtomFamily,
    locationFeatureConfigurationByContextAtomFamily
} from "./DocumentState";

import { CheckBoxValue } from "../../../ui/checkbox/CheckBoxValue";
import { ContextAwareIdentifier } from "../ContextAwareIdentifier";
import { Issue } from "../../../../models";
import { IssueFeatureConfiguration } from "./IssueFeatureConfiguration";
import { LocationFeatureConfiguration } from "./LocationFeatureConfiguration";
import { ModelType } from "../ModelType";
import { SelectionBehavior } from "../../../ui/SelectionBehavior";
import { StateContext } from "./StateContext";

export const selectedIssueIdsByContextAtomFamily = atomFamily<Array<string>, StateContext>({
    key: "selectedIssueIdsByContextAtomFamily",
    default: []
});

export const isIssueSelectedByIssueIdentifierSelectorFamily = selectorFamily<boolean, ContextAwareIdentifier>({
    key: "isIssueSelectedByIssueIdentifierSelectorFamily",
    get: (identifier: ContextAwareIdentifier) => ({ get }) => {
        const selectedIssueIds: Array<string> = get(selectedIssueIdsByContextAtomFamily(identifier.context));
        return selectedIssueIds.includes(identifier.id);
    }
});

export const selectAllIssueCheckBoxValueByContextSelectorFamily = selectorFamily<CheckBoxValue, StateContext>({
    key: "selectAllIssueCheckBoxValueByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const issues: Array<Issue> = get(filteredIssuesByContextSelectorFamily(context));
        if (issues.length === 0) {
            return CheckBoxValue.UNCHECKED;
        }
        if (issues.every((issue) => {
            const issueIdentifier = new ContextAwareIdentifier(issue.id, context, ModelType.ISSUE);
            return get(isIssueSelectedByIssueIdentifierSelectorFamily(issueIdentifier));
        })) {
            return CheckBoxValue.CHECKED;
        }
        if (!issues.some((issue) => {
            const issueIdentifier = new ContextAwareIdentifier(issue.id, context, ModelType.ISSUE);
            return get(isIssueSelectedByIssueIdentifierSelectorFamily(issueIdentifier));
        })) {
            return CheckBoxValue.UNCHECKED;
        }
        return CheckBoxValue.INDETERMINATE;
    }
});

export const selectAllLocationCheckBoxValueByContextSelectorFamily = selectorFamily<CheckBoxValue, StateContext>({
    key: "selectAllLocationCheckBoxValueByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const locationIds: Array<string> = get(filteredLocationIdsByContextSelectorFamily(context));
        if (locationIds.length === 0) {
            return CheckBoxValue.UNCHECKED;
        }
        if (locationIds.every((locationId) => {
            const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
            return get(isLocationSelectedByLocationIdentifierSelectorFamily(locationIdentifier));
        })) {
            return CheckBoxValue.CHECKED;
        }
        if (!locationIds.some((locationId) => {
            const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
            return get(isLocationSelectedByLocationIdentifierSelectorFamily(locationIdentifier));
        })) {
            return CheckBoxValue.UNCHECKED;
        }
        return CheckBoxValue.INDETERMINATE;
    }
});

export const selectAllCheckboxByContextSelectorFamily = selectorFamily<CheckBoxValue, StateContext>({
    key: "selectAllCheckboxByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const issueFeatureConfiguration: IssueFeatureConfiguration = get(issueFeatureConfigurationByContextAtomFamily(context));
        const locationFeatureConfiguration: LocationFeatureConfiguration = get(locationFeatureConfigurationByContextAtomFamily(context));
        const issueSelectionBehavior: SelectionBehavior = issueFeatureConfiguration.selectionBehavior;
        const locationSelectionBehavior: SelectionBehavior = locationFeatureConfiguration.selectionBehavior;
        const allowToSelectIssues: boolean = issueSelectionBehavior !== SelectionBehavior.DISABLED;
        const allowToSelectLocations: boolean = locationSelectionBehavior !== SelectionBehavior.DISABLED;
        if (!allowToSelectIssues && !allowToSelectLocations) {
            return CheckBoxValue.UNCHECKED;
        }
        const issueSelectAllCheckBoxValue: CheckBoxValue = get(selectAllIssueCheckBoxValueByContextSelectorFamily(context));
        const locationSelectAllCheckBoxValue: CheckBoxValue = get(selectAllLocationCheckBoxValueByContextSelectorFamily(context));
        if (allowToSelectIssues && allowToSelectLocations) {
            if ([locationSelectAllCheckBoxValue, issueSelectAllCheckBoxValue].every(checkBoxValue => checkBoxValue === CheckBoxValue.CHECKED)) {
                return CheckBoxValue.CHECKED;
            }
            if ([locationSelectAllCheckBoxValue, issueSelectAllCheckBoxValue].every(checkBoxValue => checkBoxValue === CheckBoxValue.UNCHECKED)) {
                return CheckBoxValue.UNCHECKED;
            }
            return CheckBoxValue.INDETERMINATE;
        }
        if (allowToSelectLocations) {
            return locationSelectAllCheckBoxValue;
        }
        return issueSelectAllCheckBoxValue;

    },
    set: (context: StateContext) => ({ get, set }, newValue) => {
        const issueFeatureConfiguration: IssueFeatureConfiguration = get(issueFeatureConfigurationByContextAtomFamily(context));
        const locationFeatureConfiguration: LocationFeatureConfiguration = get(locationFeatureConfigurationByContextAtomFamily(context));
        const issueSelectionBehavior: SelectionBehavior = issueFeatureConfiguration.selectionBehavior;
        const locationSelectionBehavior: SelectionBehavior = locationFeatureConfiguration.selectionBehavior;
        const allowToSelectIssues: boolean = issueSelectionBehavior !== SelectionBehavior.DISABLED;
        const allowToSelectLocations: boolean = locationSelectionBehavior !== SelectionBehavior.DISABLED;
        if (newValue === CheckBoxValue.UNCHECKED) {
            if (allowToSelectIssues) {
                set(selectedIssueIdsByContextAtomFamily(context), []);
            }
            if (allowToSelectLocations) {
                set(selectedLocationIdsByContextAtomFamily(context), []);
            }
            return;
        }
        if (allowToSelectIssues) {
            const issues: Array<Issue> = get(filteredIssuesByContextSelectorFamily(context));
            const selectedIssueIds: Array<string> = issues.map((issue) => issue.id);
            set(selectedIssueIdsByContextAtomFamily(context), selectedIssueIds);
        }
        if (allowToSelectLocations) {
            const locationIds: Array<string> = get(filteredLocationIdsByContextSelectorFamily(context));
            set(selectedLocationIdsByContextAtomFamily(context), locationIds);
        }
    }
});

export const selectedLocationIdsByContextAtomFamily = atomFamily<Array<string>, StateContext>({
    key: "selectedLocationIdsByContextAtomFamily",
    default: []
});

export const isLocationSelectedByLocationIdentifierSelectorFamily = selectorFamily<boolean, ContextAwareIdentifier>({
    key: "isLocationSelectedByLocationIdentifierSelectorFamily",
    get: (identifier: ContextAwareIdentifier) => ({ get }) => {
        const selectedLocationIds: Array<string> = get(selectedLocationIdsByContextAtomFamily(identifier.context));
        return selectedLocationIds.includes(identifier.id);
    }
});