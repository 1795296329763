import {
    issueByIssueIdentifierSelectorFamily,
    issueFeatureConfigurationByContextAtomFamily,
    issueIdsByLocationIdentifierAtomFamily,
    locationFeatureConfigurationByContextAtomFamily
} from "../state/DocumentState";
import {
    selectedIssueIdsByContextAtomFamily,
    selectedLocationIdsByContextAtomFamily
} from "../state/IssuePickerState";

import { ContextAwareIdentifier } from "../ContextAwareIdentifier";
import { Issue } from "../../../../models";
import { ModelType } from "../ModelType";
import { SelectionBehavior } from "../../../ui/SelectionBehavior";
import { StateContext } from "../state/StateContext";
import { useRecoilCallback } from "recoil";

export const useSelectResource = (context: StateContext) => {
    const selectIssue = useRecoilCallback(({ snapshot, set }) => async (
        issueId: string,
        isSelected: boolean,
    ) => {
        set(selectedIssueIdsByContextAtomFamily(context), (prev) => {
            if (isSelected) {
                if (prev.includes(issueId)) {
                    return prev;
                }
                return [...prev, issueId];
            }
            return prev.filter((id) => id !== issueId);
        });
        const issueFeatureConfiguration = await snapshot.getPromise(issueFeatureConfigurationByContextAtomFamily(context));
        const locationFeatureConfiguration = await snapshot.getPromise(locationFeatureConfigurationByContextAtomFamily(context));
        const allowToSelectLocations = locationFeatureConfiguration.selectionBehavior !== SelectionBehavior.DISABLED;
        if (isSelected && allowToSelectLocations && issueFeatureConfiguration.selectionBehavior === SelectionBehavior.PROPAGATE_TO_PARENT) {
            const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
            const selectedIssue: Issue | null = await snapshot.getPromise(issueByIssueIdentifierSelectorFamily(issueIdentifier));
            const selectedLocationIds: string[] = await snapshot.getPromise(selectedLocationIdsByContextAtomFamily(context));
            if (selectedIssue && selectedIssue.locationId && !selectedLocationIds.includes(selectedIssue.locationId)) {
                selectLocation(selectedIssue.locationId, isSelected);
            }
        }
    }, [context]);

    const selectLocation = useRecoilCallback(({ snapshot, set }) => async (
        locationId: string,
        isSelected: boolean,
    ) => {
        set(selectedLocationIdsByContextAtomFamily(context), (prev) => {
            if (isSelected) {
                if (prev.includes(locationId)) {
                    return prev;
                }
                return [...prev, locationId];
            }
            return prev.filter((id) => id !== locationId);
        });
        const locationFeatureConfiguration = await snapshot.getPromise(locationFeatureConfigurationByContextAtomFamily(context));
        const issueFeatureConfiguration = await snapshot.getPromise(issueFeatureConfigurationByContextAtomFamily(context));
        const allowToSelectIssues = issueFeatureConfiguration.selectionBehavior !== SelectionBehavior.DISABLED;
        if (!allowToSelectIssues) {
            return;
        }
        const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
        const issueIdsUnderLocation: string[] = await snapshot.getPromise(issueIdsByLocationIdentifierAtomFamily(locationIdentifier));
        const selectedIssueIds: string[] = await snapshot.getPromise(selectedIssueIdsByContextAtomFamily(context));
        if (locationFeatureConfiguration.selectionBehavior === SelectionBehavior.PROPAGATE_TO_CHILDREN) {
            const issueIdsToPropagate: string[] = issueIdsUnderLocation.filter((issueId) => {
                if (isSelected) {
                    return !selectedIssueIds.includes(issueId);
                }
                return selectedIssueIds.includes(issueId);
            });
            issueIdsToPropagate.forEach((issueId) => {
                selectIssue(issueId, isSelected);
            });
            return;
        }
        if (!isSelected && issueFeatureConfiguration.selectionBehavior === SelectionBehavior.PROPAGATE_TO_PARENT) {
            const selectedIssueIdsUnderLocation: string[] = issueIdsUnderLocation.filter((issueId) => {
                return selectedIssueIds.includes(issueId);
            });
            selectedIssueIdsUnderLocation.forEach((issueId) => {
                selectIssue(issueId, false);
            });
            return;
        }
    }, [context]);

    const resetSelectedResources = useRecoilCallback(({ reset }) => () => {
        reset(selectedIssueIdsByContextAtomFamily(context));
        reset(selectedLocationIdsByContextAtomFamily(context));
    }, [context]);

    return { selectIssue, selectLocation, resetSelectedResources };
};