import { AuthIDTokenSupplierFactory } from "../../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { GenerateReportClient } from "./GenerateReportClient";
import { GraphQLGenerateReportClient } from "./GraphQLGenerateReportClient";

export class GraphQLGenerateReportClientFactory {
    private static INSTANCE: GenerateReportClient = new GraphQLGenerateReportClient(
        ClientLoggerFactory.getClientLogger("GraphQLGenerateReportClient"),
        AuthIDTokenSupplierFactory.getInstance()
    );
    public static getInstance(): GenerateReportClient {
        return GraphQLGenerateReportClientFactory.INSTANCE;
    }
}