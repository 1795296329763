import {
    DimensionType,
    Measurement,
    WorkSpecification
} from "../../models";
import {
    IconButton,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {
    getRemoveWorkSpecificationCallback,
    isEditModeByDesignElementIdentifierSelectorFamily,
    isSolutionSoleSourceSelectorFamily,
    workSpecificationByWorkSpecificationIdentifierSelectorFamily
} from "../../lib/design/document/state/DocumentState";
import {
    memo,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";

import { Box } from "@mui/system";
import { ButtonConfirmationPrompt } from "../../components/general/button/ButtonConfirmationPrompt";
import { ChildrenExpandState } from "../../lib/design/document/state/enum/ChildrenExpandState";
import { CommentIcon } from "../../components/icons";
import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import DataStoreWorkTypeDAOFactory from "../../lib/worktype/dataStore/DataStoreWorkTypeDAOFactory";
import DefaultUnitCountCalculatorFactory from "../../lib/dimension/v2/DefaultUnitCountCalculatorFactory";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionField from "./DescriptionField";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { EditProposalItem } from "../proposal/EditProposalItem";
import { MeasurementField } from "../measurement/MeasurementField";
import { ModelType } from "../../lib/design/document/ModelType";
import { Mutex } from "async-mutex";
import { PermissionType } from "../../models";
import { SolutionAuthoringEnabledPermissionRestrictedControl } from "../../components/general/button/SolutionAuthoringEnabledPermissionRestrictedControl";
import { StateContext } from "../../lib/design/document/state/StateContext";
import UnitCountCalculator from "../../lib/dimension/v2/UnitCountCalculator";
import { ViewProposalItem } from "../proposal/ViewProposalItem";
import WorkTypeAutoComplete from "../worktype/WorkTypeAutoComplete";
import WorkTypeDAO from "../../lib/worktype/WorkTypeDAO";
import WorkTypeDTO from "../../lib/worktype/DTO/WorkTypeDTO";
import _ from "lodash";
import { childrenExpandStateByDesignElementIdentifierAtomFamily } from "../../lib/design/document/state/ComponentExpandState";
import { isDesktop } from "react-device-detect";
import { proposalItemIdByWorkSpecificationIdentifierAtomFamily } from "../../lib/design/bidding/state/v2/ProposalItemStates";
import { useRecoilValue } from "recoil";
import { useUpdateWorkSpecification } from "../../lib/design/workscopespecification/hook/useUpdateWorkSpecification";
import { workTypeByWorkTypeIdAtomFamily } from "../../lib/worktype/state/WorkTypeRecoilState";

type WSSFormProps = {
    readonly workSpecificationId: string;
    readonly stateContext: StateContext;
    readonly dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
};

const updateWSMutex: Mutex = new Mutex();
const unitCountCalculator: UnitCountCalculator = DefaultUnitCountCalculatorFactory.getInstance();
const workTypeDAO: WorkTypeDAO = DataStoreWorkTypeDAOFactory.getInstance();

export const WSSForm = memo((props: WSSFormProps) => {
    const { workSpecificationId, stateContext, dragHandleProps } = props;
    const workSpecificationIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(workSpecificationId, stateContext, ModelType.WORK_SPECIFICATION);
    }, [workSpecificationId, stateContext]);
    const workSpecification = useRecoilValue<WorkSpecification | null>(
        workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier)
    );
    const proposalItemId: string | null = useRecoilValue(proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier));
    const proposalItemIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(proposalItemId ?? "", stateContext, ModelType.PROPOSAL_ITEM);
    }, [proposalItemId, stateContext]);
    const isEditProposalItem = useRecoilValue<boolean>(isEditModeByDesignElementIdentifierSelectorFamily(proposalItemIdentifier));

    const childrenExpandState: ChildrenExpandState = useRecoilValue(childrenExpandStateByDesignElementIdentifierAtomFamily(workSpecificationIdentifier));
    const isSoleSource = useRecoilValue<boolean>(isSolutionSoleSourceSelectorFamily(stateContext));
    const [pendingToSyncWorkSpecification, setPendingToSyncWorkSpecification] = useState<WorkSpecification | null>(workSpecification);
    const pendingToSyncWorkSpecificationRef = useRef<WorkSpecification | null>(pendingToSyncWorkSpecification);
    const [showDescription, setShowDescription] = useState<boolean>(isEditProposalItem || workSpecification?.description != null || isDesktop);
    const selectedWorkTypeDTO: WorkTypeDTO | undefined = useRecoilValue(workTypeByWorkTypeIdAtomFamily(workSpecification?.workTypeId ?? ""));
    const workTypeDescriptionOptions: Array<string> = useMemo(() => {
        return [
            selectedWorkTypeDTO?.shortDescription,
            selectedWorkTypeDTO?.longDescription
        ].filter((description) => !!description) as Array<string>;
    }, [selectedWorkTypeDTO?.longDescription, selectedWorkTypeDTO?.shortDescription]);

    /* Dependencies */
    const theme = useTheme();
    const isScreenSizeXs = useMediaQuery(theme.breakpoints.down("sm"));
    const removeWorkSpecification = getRemoveWorkSpecificationCallback();

    const showWidth: boolean = workSpecification?.measurement?.dimensionType === DimensionType.SQUARE;
    const unitCount: number | undefined = useMemo(() => {
        if (!workSpecification?.measurement) {
            return undefined;
        }
        try {
            return unitCountCalculator.calculate(workSpecification.measurement);
        } catch (e) {
            return undefined;
        }
    }, [workSpecification?.measurement]);

    const { updateWorkSpecification } = useUpdateWorkSpecification(stateContext);

    const onSelectWorkType = async (workTypeId: string | undefined) => {
        if (workTypeId === workSpecification?.workTypeId) {
            return;
        }
        if (workTypeId == undefined) {
            setPendingToSyncWorkSpecification((workSpecification) => {
                return {
                    ...workSpecification!,
                    workTypeId: undefined,
                };
            });
            return;
        }
        const optionalWorkType: WorkTypeDTO | undefined = await workTypeDAO.getById(workTypeId);
        if (optionalWorkType) {
            setPendingToSyncWorkSpecification((workSpecification) => {
                return {
                    ...workSpecification!,
                    workTypeId: optionalWorkType.id,
                    measurement: {
                        ...workSpecification!.measurement!,
                        dimensionType: optionalWorkType.dimensionType || DimensionType.SQUARE
                    }
                };
            });
        }
    };

    const saveDescription = (description: string) => {
        if (description === workSpecification?.description) {
            return;
        }
        setPendingToSyncWorkSpecification((workSpecification) => {
            return {
                ...workSpecification!,
                description: description
            };
        });
    };

    const saveMeasurement = (field: keyof Measurement, value: any) => {
        if (value === (workSpecification?.measurement as any)?.[field]) {
            return;
        }
        setPendingToSyncWorkSpecification((workSpecification) => {
            return {
                ...workSpecification!,
                measurement: {
                    ...workSpecification?.measurement!,
                    [field]: value
                }
            };
        });
    };

    const deleteButton = (
        <SolutionAuthoringEnabledPermissionRestrictedControl
            requiredPermission={PermissionType.DELETE}
            resourceId={workSpecificationId}
            context={stateContext}
        >
            {(disabled) => (
                <ButtonConfirmationPrompt
                    onConfirmButtonClicked={() => removeWorkSpecification(workSpecificationId, stateContext)}
                    promptMessage="Really?"
                    promptStyles={{
                        color: "error",
                        variant: "contained"
                    }}
                >
                    {(onClick, ref) => (
                        <IconButton disabled={disabled} onClick={onClick} sx={{ p: 0 }} ref={ref}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    )}
                </ButtonConfirmationPrompt>
            )}
        </SolutionAuthoringEnabledPermissionRestrictedControl>
    );

    useEffect(() => {
        const saveUpdatedWorkSpecification = async (updatedWorkSpecification: WorkSpecification) => {
            await updateWSMutex.runExclusive(async () => {
                if (!_.isEqual(
                    _.omit(pendingToSyncWorkSpecification, ["localLastUpdatedTime"]),
                    _.omit(pendingToSyncWorkSpecificationRef.current, ["localLastUpdatedTime"]),
                )) {
                    await updateWorkSpecification(updatedWorkSpecification);
                    pendingToSyncWorkSpecificationRef.current = updatedWorkSpecification;
                }
            });
        };
        if (pendingToSyncWorkSpecification != null) {
            saveUpdatedWorkSpecification(pendingToSyncWorkSpecification);
        }
    }, [pendingToSyncWorkSpecification]);

    useEffect(() => {
        // This is to keep pendingToSyncWorkSpecification in sync with workSpecification
        setPendingToSyncWorkSpecification(workSpecification);
        pendingToSyncWorkSpecificationRef.current = workSpecification;
    }, [workSpecification]);

    return (
        <Box
            display="flex"
            flexDirection={{
                xs: proposalItemId && isEditProposalItem ? "column" : "row", // only set to column when editing proposal item to prevent distortion
                md: "row"
            }}
        >
            <Box
                flex={1}
                display="grid"
                px={{
                    xs: 0.25,
                    sm: 1
                }}
                columnGap={{
                    xs: 0.25,
                }}
                rowGap={{
                    xs: 0.5,
                    sm: 0
                }}
                gridTemplateColumns={{
                    xs: "30px 30px 30px 1fr minmax(90px, 100px) 15px 62px 15px 62px",
                    sm: `30px minmax(220px, 2fr) 1fr 62px 72px 68px ${!isEditProposalItem ? "minmax(95px, 120px)" : ""} 30px`,
                }}
                gridTemplateAreas={{
                    xs: `
                    "dragBtn commentBtn deleteBtn . viewProposalItem viewProposalItem viewProposalItem viewProposalItem viewProposalItem"
                    "workType workType workType workType workType lengthLabel length ${showWidth ? "widthLabel width" : "length length"}"
                    "description description description description description description description description description"
                `,
                    sm: `
                    "dragBtn workTypeLabel . lengthLabel ${showWidth ? "widthLabel" : "lengthLabel"} unitCountLabel ${!isEditProposalItem ? "viewProposalItem" : ""} deleteBtn"
                    "commentBtn workType . length ${showWidth ? "width" : "length"} unitCount ${!isEditProposalItem ? "viewProposalItem" : ""} ."
                    "description description description description description description description ${!isEditProposalItem ? "description" : ""}"
                `,
                }}
                alignItems="center"
                pb={{ xs: isEditProposalItem ? 1 : 0, md: 0 }}
            >
                <Box gridArea="commentBtn" display="flex" justifyContent="center">
                    <IconButton onClick={() => setShowDescription(prev => !prev)} sx={{ p: 0 }}>
                        <CommentIcon />
                    </IconButton>
                </Box>
                <Box gridArea="dragBtn" display="flex" justifyContent="center">
                    <SolutionAuthoringEnabledPermissionRestrictedControl
                        requiredPermission={PermissionType.EDIT}
                        resourceId={workSpecificationId}
                        context={stateContext}
                    >
                        {(disabled) => (
                            <Box {...(disabled ? undefined : dragHandleProps)}>
                                <DragHandleIcon color={disabled ? "disabled" : undefined} />
                            </Box>
                        )}
                    </SolutionAuthoringEnabledPermissionRestrictedControl>
                </Box>
                <Box gridArea="deleteBtn" display={{ xs: "flex", md: isEditProposalItem ? "none" : undefined }} justifyContent="center">
                    {deleteButton}
                </Box>
                {!isScreenSizeXs && (
                    <Box gridArea="workTypeLabel">
                        <Typography color="text.secondary">
                            Work Type
                        </Typography>
                    </Box>
                )}
                <Box gridArea="workType">
                    <WorkTypeAutoComplete
                        size="small"
                        onSelectWorkType={onSelectWorkType}
                        selectedWorkTypeId={workSpecification?.workTypeId ?? undefined}
                        label={undefined}
                        placeholder="Select Work Type"
                        defaultOpen={!isDesktop && childrenExpandState === ChildrenExpandState.EXPAND_CHILDREN}
                    />
                </Box>
                <Box gridArea="lengthLabel" textAlign="center">
                    <Typography color="text.secondary">
                        {isScreenSizeXs ? "L" : "Length"}
                    </Typography>
                </Box>
                {showWidth && (
                    <Box gridArea="widthLabel" textAlign="center">
                        <Typography color="text.secondary">
                            {isScreenSizeXs ? "W" : "Width"}
                        </Typography>
                    </Box>
                )}
                <Box gridArea="length">
                    <MeasurementField
                        size="small"
                        label={undefined}
                        initialValue={workSpecification?.measurement?.length?.toString() || ""}
                        onSave={(value) => saveMeasurement("length", value)}
                    />
                </Box>
                {showWidth && (
                    <Box gridArea="width" display="flex" alignItems="center" gap={0.25}>
                        {!isScreenSizeXs && (
                            <Typography color="text.secondary">
                                x
                            </Typography>
                        )}
                        <Box flexGrow={1}>
                            <MeasurementField
                                size="small"
                                label={undefined}
                                initialValue={workSpecification?.measurement?.width?.toString() || ""}
                                onSave={(value) => saveMeasurement("width", value)}
                            />
                        </Box>
                    </Box>
                )}
                {!isScreenSizeXs && (
                    <Box gridArea="unitCountLabel" textAlign="center">
                        <Typography color="text.secondary">
                            Total
                        </Typography>
                    </Box>
                )}
                {!isScreenSizeXs && (
                    <Box gridArea="unitCount" display="flex" alignItems="center" gap={0.25}>
                        <Typography color="text.secondary">
                            :
                        </Typography>
                        <Box flexGrow={1}>
                            <TextField
                                size="small"
                                disabled
                                value={unitCount}
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    sx: {
                                        px: 0,
                                        textAlign: "center"
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                )}
                {proposalItemId && !isEditProposalItem && (
                    <Box gridArea="viewProposalItem" pl={{ xs: 0, sm: 1 }} height="100%">
                        <ViewProposalItem
                            proposalItemId={proposalItemId}
                            context={stateContext}
                            measurement={workSpecification?.measurement!}
                        />
                    </Box>
                )}
                {showDescription && (
                    <Box gridArea="description" p={{ xs: 0, sm: 1 }} pb={{ sm: 0 }}>
                        <DescriptionField
                            size="small"
                            onSaveDescription={saveDescription}
                            workTypeDescriptionOptions={workTypeDescriptionOptions}
                            workSpecificationId={workSpecificationId}
                            stateContext={stateContext}
                        />
                    </Box>
                )}
            </Box >
            {proposalItemId && isEditProposalItem && isSoleSource && (
                <Box
                    display="flex"
                    alignItems="flex-start"
                    pl={{ md: 2 }}
                    borderLeft={{ md: "lightgrey 1px solid" }}
                    pt={{ xs: 0.5, md: 0 }}
                    borderTop={{ xs: "lightgrey 1px solid", md: "none" }}
                    width={{ xs: "100%", md: "475px" }}
                >
                    <EditProposalItem
                        proposalItemId={proposalItemId}
                        context={stateContext}
                        measurement={workSpecification?.measurement!}
                        size="small"
                        deleteProposalItemButton={
                            <Box display={{ xs: "none", md: isEditProposalItem ? "flex" : undefined }} width="40px" height="40px" justifyContent="center" >
                                {deleteButton}
                            </Box>
                        }
                    />
                </Box>
            )}
        </Box>
    );
});
