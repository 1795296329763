import {
    AtomEffect,
    DefaultValue
} from "recoil";

import Cookies from "js-cookie";
import localforage from "localforage";

export const localForageEffect = <T>(key: string): AtomEffect<T> => {
    return ({ setSelf, onSet }) => {
        const loadPersisted = async () => {
            const savedValue: unknown | null | undefined = await localforage.getItem(key);
            setSelf(savedValue != undefined ? savedValue as T : new DefaultValue());
        };
        loadPersisted();
        onSet((newValue, _, isReset) => {
            isReset ?
                localforage.removeItem(key) :
                localforage.setItem(key, newValue);
        });
    };
};

export const localForageMigrateToCookiesEffect = <T>(key: string): AtomEffect<T> => {
    return ({ setSelf, onSet }) => {
        const loadPersisted = async () => {
            const persistedValue: string | undefined = Cookies.get(key);
            if (persistedValue != undefined) {
                setSelf(JSON.parse(persistedValue) as T);
                return;
            }
            const legacyValue: unknown | null | undefined = await localforage.getItem(key);
            if (legacyValue != undefined) {
                Cookies.set(key, JSON.stringify(legacyValue));
                setSelf(legacyValue as T);
            }
        };
        loadPersisted();
        onSet((newValue, _, isReset) => {
            if (isReset) {
                Cookies.remove(key);
                localforage.removeItem(key);
                return;
            }
            Cookies.set(key, JSON.stringify(newValue), { domain: getDomain() });
            // persist to localForage as a backup in case cookies are disabled or cookies are not available
            localforage.setItem(key, newValue);
        });
    };
};

// Ex. app.tenerapro.com -> tenerapro.com
const getDomain = () => {
    const parts = window.location.hostname.split(".");
    if (parts.length <= 2) {
        return parts.join(".");
    }
    return `.${parts.slice(-2).join(".")}`;
};