import AuthUsernameSupplierFactory from "../../auth/AuthUsernameSupplierFactory";
import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import DefaultAttachmentUploadManagerFactory from "../../attachment/upload/manager/DefaultAttachmentUploadManagerFactory";
import DefaultImageResizerFactory from "../DefaultImageResizerFactory";
import DefaultImageSelectionHandler from "./DefaultImageSelectionHandler";
import FileInputValidatorFactory from "../validation/FileInputValidatorFactory";
import ImageSelectionHandler from "./ImageSelectionHandler";

export default class ImageSelectionHandlerFactory {
    private static readonly INSTANCE: ImageSelectionHandler = new DefaultImageSelectionHandler(
        ClientLoggerFactory.getClientLogger("ImageSelectionHandlerFactory"),
        FileInputValidatorFactory.getInstance(),
        DefaultImageResizerFactory.getInstance(),
        DefaultAttachmentUploadManagerFactory.getInstance(),
        AuthUsernameSupplierFactory.getInstance()
    );

    public static getInstance(): ImageSelectionHandler {
        return this.INSTANCE;
    }
}
