import ProposalItem from "./ProposalItem";
import ProposalPriceBeforeAdjustmentCalculationError from "./errors/ProposalPriceBeforeAdjustmentCalculationError";
import ProposalPriceBeforeAdjustmentCalculator from "./ProposalPriceBeforeAdjustmentCalculator";

export default class DefaultProposalPriceBeforeAdjustmentCalculator implements ProposalPriceBeforeAdjustmentCalculator {
    calculate(proposalItems: Array<ProposalItem>): number | undefined {
        let proposalPriceBeforeAdjustment = 0;
        for (const proposalItem of proposalItems) {
            if (proposalItem.priceBeforeAdjustment == null) {
                throw new ProposalPriceBeforeAdjustmentCalculationError("Proposal item price before adjustment is undefined.");
            }
            proposalPriceBeforeAdjustment += proposalItem.priceBeforeAdjustment;
        }
        return proposalPriceBeforeAdjustment;
    }
}
