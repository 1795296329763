import AttachmentUploadQueueFactory from "../request/AttachmentUploadQueueFactory";
import AttachmentUploadScheduler from "../scheduler/AttachmentUploadScheduler";
import AttachmentUploadWorkerFactoryImpl from "../scheduler/AttachmentUploadWorkerFactoryImpl";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { DefaultAttachmentUploadManager } from "./DefaultAttachmentUploadManager";
import FixedDelayTokenRefillStrategyFactory from "../../../util/ratelimit/FixedDelayTokenRefillStrategyFactory";
import RateLimitedAttachmentUploadWorkerDecoratorFactory from "../scheduler/RateLimitedAttachmentUploadWorkerDecoratorFactory";
import TokenBucketBasedRateLimiterFactory from "../../../util/ratelimit/TokenBucketBasedRateLimiterFactory";

export default class DefaultAttachmentUploadManagerFactory {
    private static readonly TOKEN_BUCKET_CAPACITY = 10;
    private static readonly MIN_DELAY_IN_MILLISECOND = 1000;

    private static readonly ATTACHMENT_UPLOAD_QUEUE = AttachmentUploadQueueFactory.getInstance();
    private static readonly RATE_LIMITER = TokenBucketBasedRateLimiterFactory.create(
        this.TOKEN_BUCKET_CAPACITY,
        FixedDelayTokenRefillStrategyFactory.create(this.MIN_DELAY_IN_MILLISECOND)
    );

    private static readonly INSTANCE = new DefaultAttachmentUploadManager(
        this.ATTACHMENT_UPLOAD_QUEUE,
        new AttachmentUploadScheduler(
            new RateLimitedAttachmentUploadWorkerDecoratorFactory(
                this.RATE_LIMITER,
                new AttachmentUploadWorkerFactoryImpl()
            ),
            this.ATTACHMENT_UPLOAD_QUEUE,
            ClientLoggerFactory.getClientLogger("DefaultAttachmentUploadManager")
        )
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}