import AuthUsernameSupplierFactory from "../auth/AuthUsernameSupplierFactory";
import ClientLogDAOFactory from "./ClientLogDAOFactory";
import ClientLogger from "./ClientLogger";

export default class ClientLoggerFactory {
    private static COMPONENT_NAME_TO_INSTANCE_MAP: Map<string, ClientLogger> = new Map();

    static getClientLogger(componentName: string) {
        if (ClientLoggerFactory.COMPONENT_NAME_TO_INSTANCE_MAP.has(componentName)) {
            return ClientLoggerFactory.COMPONENT_NAME_TO_INSTANCE_MAP.get(componentName)!;
        }
        const clientLogDAO = ClientLogDAOFactory.getClientLogDAO();
        const logger = new ClientLogger(
            clientLogDAO,
            componentName,
            AuthUsernameSupplierFactory.getInstance()
        );
        ClientLoggerFactory.COMPONENT_NAME_TO_INSTANCE_MAP.set(componentName, logger);
        return logger;
    }
}
