import Comparator from "../../util/data/sort/Comparator";
import { ComparisonResult } from "../../util/data/sort/ComparisonResult";
import { Location } from "../../../models";
import { SortDirection } from "../../sorting/SortDirection";

/**
 * Compares two {@link Location} by clientCreationDate.
 */
export default class ByLocalCreationTimeComparator implements Comparator<Location> {
    private sortDirection: SortDirection;

    constructor(
        sortDirection: SortDirection = SortDirection.DESCENDING
    ) {
        this.sortDirection = sortDirection;
    }
    
    public compare(a: Location, b: Location): ComparisonResult {
        if (this.sortDirection === SortDirection.ASCENDING) {
            return this.sortDescending(b, a);
        }
        return this.sortDescending(a, b);
    }

    private sortDescending(a: Location, b: Location): ComparisonResult {
        if (a.localCreationTime! < b.localCreationTime!) {
            return ComparisonResult.GREATER_THAN;
        }
        if (a.localCreationTime! > b.localCreationTime!) {
            return ComparisonResult.LESS_THAN;
        }
        return ComparisonResult.EQUAL;
    }
}
