import API, {
    GraphQLSubscription
} from "@aws-amplify/api";
import GraphQLAPI, {
    GraphQLResult,
    graphqlOperation
} from "@aws-amplify/api-graphql";
import {
    ManageResourcePermissionInput,
    OnCompleteEventSubscription,
    RevokeResourcePermissionMutationVariables,
    ShareResourcePermissionMutationVariables
} from "../../API";
import {
    revokeResourcePermission,
    shareResourcePermission
} from "../../graphql/mutations";

import ClientLogger from "../logging/ClientLogger";
import { CompleteEventStatus } from "../../models";
import { IDTokenSupplier } from "../auth/IDTokenSupplier";
import { MAXIMUM_BATCH_SIZE } from "../util/batch/Constants";
import { ManageResourcePermissionClient } from "./ManageResourcePermissionClient";
import { RevokeResourcePermissionError } from "./errors/RevokeResourcePermissionError";
import { ShareResourcePermissionError } from "./errors/ShareResourcePermissionError";
import { onCompleteEvent } from "../../graphql/subscriptions";
import uuidv4 from "../util/UuidGenerator";

enum OperationType {
    SHARE = "SHARE",
    REVOKE = "REVOKE"
};

export class GraphQLManageResourcePermissionClient implements ManageResourcePermissionClient {
    private static readonly SHARE_ATTEMPT_METRIC_NAME = "GraphQLManageResourcePermissionClient.Share.Attempt";
    private static readonly SHARE_FAILURE_METRIC_NAME = "GraphQLManageResourcePermissionClient.Share.Failure";
    private static readonly REVOKE_ATTEMPT_METRIC_NAME = "GraphQLManageResourcePermissionClient.Revoke.Attempt";
    private static readonly REVOKE_FAILURE_METRIC_NAME = "GraphQLManageResourcePermissionClient.Revoke.Failure";

    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async share(input: Array<ManageResourcePermissionInput>): Promise<void> {
        this.logger.info(
            `Attempting to share resource.`,
            undefined,
            [GraphQLManageResourcePermissionClient.SHARE_ATTEMPT_METRIC_NAME]
        );
        const batchedInput: Array<Array<ManageResourcePermissionInput>> = this.breakInputsIntoBatches(input, MAXIMUM_BATCH_SIZE);
        const batchedPromises: Array<Promise<void>> = batchedInput.map((batch: Array<ManageResourcePermissionInput>) =>
            this.handleBatch(OperationType.SHARE, batch)
        );
        await Promise.all(batchedPromises);
    }

    public async revoke(input: Array<ManageResourcePermissionInput>): Promise<void> {
        this.logger.info(
            `Attempting to revoke resource.`,
            undefined,
            [GraphQLManageResourcePermissionClient.REVOKE_ATTEMPT_METRIC_NAME]
        );
        const batchedInput: Array<Array<ManageResourcePermissionInput>> = this.breakInputsIntoBatches(input, MAXIMUM_BATCH_SIZE);
        const batchedPromises: Array<Promise<void>> = batchedInput.map(async (batch: Array<ManageResourcePermissionInput>) =>
            await this.handleBatch(OperationType.REVOKE, batch)
        );
        await Promise.all(batchedPromises);
    }

    private async handleBatch(
        operation: OperationType,
        input: Array<ManageResourcePermissionInput>
    ): Promise<void> {
        const authToken: string = await this.idTokenSupplier.get();
        const executionId: string = uuidv4();
        const mutation: typeof shareResourcePermission | typeof revokeResourcePermission = operation === OperationType.SHARE ? shareResourcePermission : revokeResourcePermission;
        const variables: ShareResourcePermissionMutationVariables | RevokeResourcePermissionMutationVariables = {
            executionId: executionId,
            input: input
        };
        await new Promise<void>(async (resolve, reject) => {
            const subscription = API.graphql<GraphQLSubscription<OnCompleteEventSubscription>>(
                graphqlOperation(onCompleteEvent, { executionId }, authToken)
            ).subscribe({
                next: ({ provider, value }) => {
                    subscription.unsubscribe();
                    if (value.data?.onCompleteEvent?.status === CompleteEventStatus.SUCCESS) {
                        resolve();
                    }
                    reject(new RevokeResourcePermissionError("Unable to revoke resource"));
                },
                error: (error) => {
                    reject(error);
                }
            });

            try {
                await GraphQLAPI.graphql(
                    graphqlOperation(
                        mutation,
                        variables,
                        authToken
                    )
                );
            } catch (error) {
                const errors: Array<Error> = (error as GraphQLResult).errors ?? [];
                const errorMessage: string = `Unable to ${operation} resource: ${(error as GraphQLResult).errors?.map(err => err.message).join(', ')}`;
                this.logger.error(
                    errorMessage,
                    error,
                    [operation === OperationType.SHARE ? GraphQLManageResourcePermissionClient.SHARE_FAILURE_METRIC_NAME : GraphQLManageResourcePermissionClient.REVOKE_FAILURE_METRIC_NAME]
                );
                const executionTimeout: boolean = errors.some(error => error.message === "Execution timed out.");
                if (!executionTimeout) {
                    subscription.unsubscribe();
                    reject(operation === OperationType.SHARE ? new ShareResourcePermissionError(errorMessage, error as Error) :
                        new RevokeResourcePermissionError(errorMessage, error as Error)
                    );
                }
            }
        });
    }

    // handling requests in batches to avoid hitting the 10 second timeout limit from lambda authorizer
    private breakInputsIntoBatches(
        inputs: Array<ManageResourcePermissionInput>,
        batchSize: number
    ): Array<Array<ManageResourcePermissionInput>> {
        const batches = new Array<Array<ManageResourcePermissionInput>>();
        for (let i = 0; i < inputs.length; i += batchSize) {
            batches.push(inputs.slice(i, i + batchSize));
        }
        return batches;
    }
}