import {
    API,
    graphqlOperation
} from "aws-amplify";
import {
    CreateAttachmentMutation,
    GetAttachmentQuery
} from "../../../../API";

import { Attachment } from "../../../../models";
import AttachmentRecordAlreadyExistsError from "../errors/AttachmentRecordAlreadyExistsError";
import AttachmentRecordDAO from "../AttachmentRecordDAO";
import AttachmentRecordMetricNames from "../AttachmentRecordMetricNames";
import ClientLogger from "../../../logging/ClientLogger";
import CreateAttachmentRecordError from "../errors/CreateAttachmentRecordError";
import GetAttachmentRecordByIdError from "../errors/GetAttachmentRecordByIdError";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { IDTokenSupplier } from "../../../auth/IDTokenSupplier";
import { createAttachment } from "../../../../graphql/mutations";
import { getAttachment } from "../../../../graphql/queries";

export default class GraphQLAttachmentRecordDAO implements AttachmentRecordDAO {
    private readonly logger: ClientLogger;
    private readonly gqlOperation: typeof graphqlOperation;
    private readonly api: typeof API;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async create(
        record: Attachment
    ): Promise<Attachment> {
        this.logger.info(
            `Creating Attachment Record: ${record.id}`,
            record,
            [AttachmentRecordMetricNames.CREATE_ATTEMPT]
        );
        try {
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<CreateAttachmentMutation> = await this.api.graphql(
                this.gqlOperation(
                    createAttachment,
                    { input: record },
                    authToken
                )
            ) as GraphQLResult<CreateAttachmentMutation>;
            return result.data?.createAttachment!;
        } catch (error) {
            if ((error as any)?.errors && (error as any)?.errors[0].errorType === "ConditionalCheckFailedException") {
                const errorMessage = `Attachment record already exists: ${record.id}`;
                this.logger.warn(
                    errorMessage,
                    error,
                    [AttachmentRecordMetricNames.CREATE_FAILURE]
                );
                throw new AttachmentRecordAlreadyExistsError(
                    errorMessage
                );
            }
            const errorMessage = `Unable to create Attachment Record: ${record.id}`;
            this.logger.error(
                errorMessage,
                error,
                [AttachmentRecordMetricNames.CREATE_FAILURE]
            );
            throw new CreateAttachmentRecordError(errorMessage, {
                cause: error as Error
            });
        }
    }

    public async delete(
        id: string
    ): Promise<void> {
        throw new Error("Method not implemented.");
    }

    public async getById(
        id: string
    ): Promise<Attachment> {
        this.logger.info(
            `Getting Attachment Record: ${id}`,
            undefined,
            [AttachmentRecordMetricNames.GET_BY_ID_ATTEMPT]
        );
        try {
            const authToken: string = await this.idTokenSupplier.get();
            const queryResult = await this.api.graphql(
                this.gqlOperation(
                    getAttachment,
                    { id },
                    authToken
                )
            ) as GraphQLResult<GetAttachmentQuery>;
            const result = queryResult.data?.getAttachment as Attachment;
            if (!result) {
                throw new Error("Attachment Record not found");
            }
            return result;
        } catch (error) {
            const errorMessage: string = `Unable to get Attachment Record: ${id}`;
            this.logger.error(
                errorMessage,
                error,
                [AttachmentRecordMetricNames.GET_BY_ID_FAILURE]
            );
            throw new GetAttachmentRecordByIdError(errorMessage, error as Error);
        }
    }

    public async listByParentId(parentId: string): Promise<Attachment[]> {
        throw new Error("Method not implemented.");
    }

    public async listMostRecentByParentId(
        parentId: string
    ): Promise<Attachment[]> {
        throw new Error("Method not implemented.");
    }

    public async listMostRecentByParentIdWithLimit(
        parentId: string,
        limit: number
    ): Promise<Attachment[]> {
        throw new Error("Method not implemented.");
    }
}
