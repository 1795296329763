import { ModelType } from "./ModelType";
import { StateContext } from "./state/StateContext";

export class ContextAwareIdentifier {
    readonly id: string;
    readonly context: StateContext;
    readonly modelType: ModelType;

    toJSON: () => string = () => {
        return `${this.context}_${this.id}`;
    }
    
    constructor(
        id: string, 
        context: StateContext,
        modelType: ModelType
    ) {
        this.id = id;
        this.context = context;
        this.modelType = modelType;
    }
}
