import {
    CreateTemplateMutation,
    CreateTemplateMutationVariables,
    DeleteTemplateMutationVariables,
    GetTemplateQuery,
    GetTemplateQueryVariables,
    ListTemplatesByOwnerEntityIdQuery,
    ListTemplatesByOwnerEntityIdQueryVariables,
    Template,
    UpdateTemplateMutation,
    UpdateTemplateMutationVariables
} from "../../API";
import GraphQLAPI, {
    GraphQLResult
} from "@aws-amplify/api-graphql";
import {
    createTemplate,
    deleteTemplate,
    updateTemplate
} from "../../graphql/mutations";
import {
    getTemplate,
    listTemplatesByOwnerEntityId
} from "../../graphql/queries";

import ClientLogger from "../logging/ClientLogger";
import { IDTokenSupplier } from "../auth/IDTokenSupplier";
import { TemplateEntityType } from "../../models";
import { TemplateRecordDAO } from "./TemplateRecordDAO";

export class GraphQLTemplateRecordDAO implements TemplateRecordDAO {

    private static readonly CREATE_ATTEMPT_METRIC_NAME = "GraphQLTemplateRecordDAO.Create.Attempt";
    private static readonly CREATE_SUCCESS_METRIC_NAME = "GraphQLTemplateRecordDAO.Create.Success";
    private static readonly CREATE_FAILURE_METRIC_NAME = "GraphQLTemplateRecordDAO.Create.Failure";
    private static readonly UPDATE_ATTEMPT_METRIC_NAME = "GraphQLTemplateRecordDAO.Update.Attempt";
    private static readonly UPDATE_SUCCESS_METRIC_NAME = "GraphQLTemplateRecordDAO.Update.Success";
    private static readonly UPDATE_FAILURE_METRIC_NAME = "GraphQLTemplateRecordDAO.Update.Failure";
    private static readonly GET_ATTEMPT_METRIC_NAME = "GraphQLTemplateRecordDAO.Get.Attempt";
    private static readonly GET_SUCCESS_METRIC_NAME = "GraphQLTemplateRecordDAO.Get.Success";
    private static readonly GET_FAILURE_METRIC_NAME = "GraphQLTemplateRecordDAO.Get.Failure";
    private static readonly DELETE_ATTEMPT_METRIC_NAME = "GraphQLTemplateRecordDAO.Delete.Attempt";
    private static readonly DELETE_SUCCESS_METRIC_NAME = "GraphQLTemplateRecordDAO.Delete.Success";
    private static readonly DELETE_FAILURE_METRIC_NAME = "GraphQLTemplateRecordDAO.Delete.Failure";
    private static readonly LIST_BY_ENTITY_ATTEMPT_METRIC_NAME = "GraphQLTemplateRecordDAO.ListByEntity.Attempt";
    private static readonly LIST_BY_ENTITY_SUCCESS_METRIC_NAME = "GraphQLTemplateRecordDAO.ListByEntity.Success";
    private static readonly LIST_BY_ENTITY_FAILURE_METRIC_NAME = "GraphQLTemplateRecordDAO.ListByEntity.Failure";


    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async create(template: Omit<Template, "id">): Promise<Template> {
        try {
            this.logger.info(
                `Creating Template with name=${template.name}`,
                undefined,
                [GraphQLTemplateRecordDAO.CREATE_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: CreateTemplateMutationVariables = {
                input: template
            };
            const result: GraphQLResult<CreateTemplateMutation> = await GraphQLAPI.graphql({
                query: createTemplate,
                variables,
                authToken
            }) as GraphQLResult<CreateTemplateMutation>;
            this.logger.info(
                `Successfully created Template with name=${template.name}`,
                result.data?.createTemplate?.id,
                [GraphQLTemplateRecordDAO.CREATE_SUCCESS_METRIC_NAME]
            );
            return result.data?.createTemplate!;
        } catch (error) {
            const errorMessage = `Unable to create Template with name=${template.name}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLTemplateRecordDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new Error(errorMessage);
        }
    }

    public async update(template: Template): Promise<Template> {
        try {
            this.logger.info(
                `Updating Template with id=${template.id}`,
                template,
                [GraphQLTemplateRecordDAO.UPDATE_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const existingTemplate: Template = await this.get(template.id);
            const variables: UpdateTemplateMutationVariables = {
                input: {
                    id: template.id,
                    name: template.name,
                    ownerId: template.ownerId,
                    ownerType: template.ownerType,
                    type: template.type,
                    s3Key: template.s3Key,
                    _version: existingTemplate._version
                }
            };
            const result: GraphQLResult<UpdateTemplateMutation> = await GraphQLAPI.graphql({
                query: updateTemplate,
                variables,
                authToken
            }) as GraphQLResult<UpdateTemplateMutation>;
            this.logger.info(
                `Successfully updated Template with id=${template.id}`,
                result.data?.updateTemplate?.id,
                [GraphQLTemplateRecordDAO.UPDATE_SUCCESS_METRIC_NAME]
            );
            return result.data?.updateTemplate!;
        } catch (error) {
            const errorMessage = `Unable to update Template with id=${template.id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLTemplateRecordDAO.UPDATE_FAILURE_METRIC_NAME]
            );
            throw new Error(errorMessage);
        }
    }

    public async get(id: string): Promise<Template> {
        try {
            this.logger.info(
                `Getting Template with id=${id}`,
                undefined,
                [GraphQLTemplateRecordDAO.GET_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: GetTemplateQueryVariables = { id };
            const result: GraphQLResult<GetTemplateQuery> = await GraphQLAPI.graphql({
                query: getTemplate,
                variables,
                authToken
            }) as GraphQLResult<GetTemplateQuery>;
            if (!result.data?.getTemplate) {
                throw new Error(`Template not found with id=${id}`);
            }
            this.logger.info(
                `Successfully retrieved Template with id=${id}`,
                result.data?.getTemplate?.id,
                [GraphQLTemplateRecordDAO.GET_SUCCESS_METRIC_NAME]
            );
            return result.data?.getTemplate;
        } catch (error) {
            const errorMessage = `Unable to retrieve Template with id=${id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLTemplateRecordDAO.GET_FAILURE_METRIC_NAME]
            );
            throw new Error(errorMessage);
        }
    }

    public async delete(
        id: string,
        version: number
    ): Promise<void> {
        try {
            this.logger.info(
                `Deleting Template with id=${id}`,
                undefined,
                [GraphQLTemplateRecordDAO.DELETE_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: DeleteTemplateMutationVariables = {
                input: {
                    id,
                    _version: version
                }
            };
            await GraphQLAPI.graphql({
                query: deleteTemplate,
                variables,
                authToken
            });
            this.logger.info(
                `Successfully deleted Template with id=${id}`,
                undefined,
                [GraphQLTemplateRecordDAO.DELETE_SUCCESS_METRIC_NAME]
            );
        } catch (error) {
            const errorMessage = `Unable to delete Template with id=${id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLTemplateRecordDAO.DELETE_FAILURE_METRIC_NAME]
            );
            throw new Error(errorMessage);
        }
    }

    public async listByEntity(
        entityId: string,
        entityType?: TemplateEntityType
    ): Promise<Array<Template>> {
        try {
            this.logger.info(
                `Listing Templates for entity with id=${entityId} and type=${entityType}`,
                undefined,
                [GraphQLTemplateRecordDAO.LIST_BY_ENTITY_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: ListTemplatesByOwnerEntityIdQueryVariables = {
                ownerId: entityId,
                ownerType: entityType ? {
                    eq: entityType
                } : undefined
            };
            const result: GraphQLResult<ListTemplatesByOwnerEntityIdQuery> = await GraphQLAPI.graphql({
                query: listTemplatesByOwnerEntityId,
                variables,
                authToken
            }) as GraphQLResult<ListTemplatesByOwnerEntityIdQuery>;
            this.logger.info(
                `Successfully listed Templates for entity with id=${entityId} and type=${entityType}`,
                undefined,
                [GraphQLTemplateRecordDAO.LIST_BY_ENTITY_SUCCESS_METRIC_NAME]
            );
            if (!result.data?.listTemplatesByOwnerEntityId?.items) {
                return [];
            }
            return result.data?.listTemplatesByOwnerEntityId!.items as Array<Template>;
        } catch (error) {
            const errorMessage = `Unable to list Templates for entity with id=${entityId} and type=${entityType}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLTemplateRecordDAO.LIST_BY_ENTITY_FAILURE_METRIC_NAME]
            );
            throw new Error(errorMessage);
        }
    }
}
