import {
    ContentBlock,
    ConverseCommandOutput,
    Message,
    ToolConfiguration,
    ToolUseBlock
} from "@aws-sdk/client-bedrock-runtime";
import {
    Measurement,
    MeasurementUnit
} from "../../../models";

import { handleConverseCommandClient } from "../../../lib/bedrock/GraphQLHandleConverseCommandClient";
import { useRef } from "react";
import { useTranscriptionContext } from "../transcription/ChimeTranscriptionContext";

const MODEL_ID: string = "anthropic.claude-3-haiku-20240307-v1:0";
const PROMPT = "Please provide the most recently stated  if not explicitly stated from the following snippet of transcript from a building inspection. Consider only the most recent measurement if multiple are mentioned in the transcript. Respond by triggering the getMeasurement function.";
const TOOL_CONFIG: ToolConfiguration = {
    tools: [
        {
            toolSpec:
            {
                name: "getMeasurement",
                description: "Get the most recently stated measurement of width and length from the messages in unit feet, correct to the nearest 0.1",
                inputSchema: {
                    json: {
                        "type": "object",
                        "properties": {
                            "length": {
                                "type": "number",
                                "description": "length of the object, or first number if not explicitly stated",
                            },
                            "width": {
                                "type": "number",
                                "description": "width of the object, or second number if not explicitly stated, or 0 if not provided",
                            },
                        },
                    }
                }
            }
        }
    ]
}

export const useAIMeasurementSummarizer = () => {
    const {transcripts, markTranscriptAsUsed} = useTranscriptionContext();
    const transcriptsRef = useRef(transcripts);
    transcriptsRef.current = transcripts;

    const generateMeasurementFromMessages = async (): Promise<Measurement> => {
        try {
            const compoundMessage = getLastMessagesAsChatCompletionRequestMessage(5);
            if (compoundMessage.content?.length === 0) {
                return "No Transcript Found, please try again.";
            }
            const chatCompletion: ConverseCommandOutput = await handleConverseCommandClient.handleConverseCommand({
                modelId: MODEL_ID,
                system: [{ text: PROMPT }],
                messages: [compoundMessage],
                toolConfig: TOOL_CONFIG
            });
            const response: ContentBlock = chatCompletion.output?.message?.content![0]!;
            return parseFunctionInput(response.toolUse);
        } catch (error) {
            console.error("Error sending messages: " + error);
            return "Error occurred";
        }
    };

    const getLastMessagesAsChatCompletionRequestMessage = (count: number): Message => {
        const messages = transcriptsRef.current;
        // filter out message that are less than 2 word
        const filteredMessages = messages.filter((message) => {
            return message.message.split(" ").length > 1;
        });
        const recentMessages = filteredMessages.slice(-count).map((message) => message);
        markTranscriptAsUsed(recentMessages.map((message) => message.message));
        return {
            role: "user",
            content: recentMessages.map((message) => {
                return {
                    text: message.message
                }
            })
        }
    };

    const parseFunctionInput = (toolUse: ToolUseBlock | undefined): Measurement => {
        if (!toolUse || !toolUse.input) {
            return new Measurement({
                length: 0,
                width: 0,
                measurementUnit: MeasurementUnit.FOOT
            });
        }
        const functionInput = toolUse.input as any;
        return new Measurement({
            length: functionInput.length || 0,
            width: functionInput.width || 0,
            measurementUnit: MeasurementUnit.FOOT
        });
    }

    return {
        generateMeasurementFromMessages
    };
};
