import {
    FormControl,
    MenuItem,
    Select
} from '@mui/material';

import FormFieldSummary from '../form/FormFieldSummary';
import { SurveyQuestionDropdown } from 'survey-react-ui';

export default class MUISurveyQuestionDropdown extends SurveyQuestionDropdown {

    constructor(props) {
        super(props);
    }

    renderElement() {
        const onChange = (e) => {
            this.question.value = e.target.value;
        };

        return (
            <>
                <FormControl
                        error={this.question.errors.length > 0}
                        fullWidth
                        required={this.question.isRequired}
                        variant="standard"
                >
                    <Select
                        error={this.question.errors.length > 0}
                        id={this.question.inputId}
                        onChange={onChange}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                                style: {
                                    maxHeight: 250,
                                    width: 250
                                }
                            }
                        }}
                        value={this.question.value}
                    >
                        {this.question.visibleChoices.map((option) => {
                            return (
                                <MenuItem
                                    key={option.text}
                                    value={option.value}
                                >
                                    {option.text}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormFieldSummary
                    isValid={this.question.errors.length === 0}
                    errorText={`${this.question.title} is required.`}
                />
            </>

        );
    }
}
