import 'swiper/css';
import 'swiper/css/pagination';

import {
    AddPhotoIcon,
    AnnotationIcon,
    CancelIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    DownloadIcon,
    MoreIcon,
    TakePhotoIcon
} from '../../components/icons';
import {
    Box,
    ClickAwayListener,
    Dialog,
    Skeleton
} from '@mui/material';
import {
    DropEvent,
    FileRejection
} from 'react-dropzone';
import {
    ProviderContext,
    useSnackbar
} from 'notistack';
import {
    Swiper,
    SwiperProps,
    SwiperSlide
} from 'swiper/react';
import SwiperClass, {
    Controller,
    FreeMode,
    Pagination
} from 'swiper';
import {
    useEffect,
    useMemo,
    useState
} from 'react';

import Camera from './camera/Camera';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { CustomDropZone } from '../../components/image/CustomDropZone';
import { DeleteImageCarouselOption } from './carouselComponent/DeleteImageCarouselOption';
import { EmptyImagePlaceholder } from './carouselComponent/EmptyImagePlaceholder';
import ImageCarouselButton from './carouselComponent/ImageCarouselButton';
import ImageCarouselOption from './carouselComponent/ImageCarouselOption';
import { ImageEditor } from '../../components/image/ImageEditor';
import { LazyLoadImage } from './carouselComponent/LazyLoadImage';
import { Property } from "csstype";
import { isDesktop } from 'react-device-detect';
import theme from '../../assets/style/theme';
import useCopyImageToClipboard from '../../components/image/useCopyImageToClipboard';
import { useDownloadFile } from '../../components/general/hooks/useDownloadFile';
import { useQuery } from 'react-query';
import useReadImageFromClipboard from '../../components/image/useReadImageFromClipboard';

export type ImageCarouselProps<T> = {
    imageDTOs: Array<T> | undefined;
    fetchStatus?: "idle" | "error" | "loading" | "success";
    objectFit?: Property.ObjectFit;
    showAddButton?: boolean;
    showContextMenu?: boolean;
    showTimestamp?: boolean;
    showNavigation?: boolean;
    showEditButton?: boolean;
    enableDropZone?: boolean;
    swiperProps?: SwiperProps;
    swiper: SwiperClass | null;
    onImagesUpload?: (images: Array<Blob>, resize: boolean) => Promise<void>;
    onDeleteImage?: (imageDTO: T) => Promise<void>;
    getImageUrl: (imageDTO: T) => (string | Promise<string>);
    getImageTimestamp?: (imageDTO: T) => number | undefined;
    getImageKey: (imageDTO: T) => string;
    reverseButtonOrder?: boolean;
};

export const ImageCarousel = <T,>(props: ImageCarouselProps<T>) => {
    const { imageDTOs, fetchStatus, objectFit = "cover", swiperProps, swiper, reverseButtonOrder } = props;
    const { onImagesUpload, onDeleteImage, getImageUrl, getImageTimestamp, getImageKey } = props;
    const { showAddButton, showContextMenu, showNavigation, showTimestamp, showEditButton, enableDropZone } = props;

    const [isCameraOpen, setIsCameraOpen] = useState<boolean>(false);
    const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false);
    const [showNavigationButtons, setShowNavigationButtons] = useState<boolean>(true);
    const [isExpandOptions, setIsExpandOptions] = useState<boolean>(false);
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
    const activeImageDTO = useMemo(() => {
        return imageDTOs?.[activeSlideIndex];
    }, [imageDTOs, activeSlideIndex]);
    const { downloadFileFromUrl } = useDownloadFile();

    const { data: activeSlideImageUrl } = useQuery({
        queryKey: ["imageUrl", activeImageDTO ? getImageKey(activeImageDTO) : undefined],
        queryFn: () => {
            if (!activeImageDTO) {
                return undefined;
            }
            return getImageUrl(activeImageDTO);
        },
        enabled: !!activeImageDTO
    });

    const snackbar: ProviderContext = useSnackbar();
    const copyImage = useCopyImageToClipboard();
    const pasteImage = useReadImageFromClipboard();

    const onDrop = (acceptedFiles: Array<File>, fileRejections: Array<FileRejection>, event: DropEvent) => {
        onImagesUpload?.(acceptedFiles, true);
        fileRejections.forEach((rejection) => {
            snackbar.enqueueSnackbar(`File ${rejection.file.name} is rejected, reason: ${rejection.errors.map(error => error.message).join(", ")}`, { variant: "error" });
        });
    };

    const onDownloadImage = () => {
        const imageKey = activeImageDTO ? getImageKey(activeImageDTO) : undefined;
        const imageUrl = activeSlideImageUrl ?? undefined;
        if (!imageKey || !imageUrl) {
            return;
        }
        downloadFileFromUrl(imageUrl, imageKey);
    };

    const modules = useMemo(() => {
        const modules = [];
        if (swiperProps?.controller) {
            modules.push(Controller);
        }
        if (swiperProps?.freeMode) {
            modules.push(FreeMode);
        }
        if (swiperProps?.pagination) {
            modules.push(Pagination);
        }
        return modules;
    }, [swiperProps?.controller, swiperProps?.freeMode, swiperProps?.pagination]);

    const displayNavigationButtons = useMemo(() => {
        return showNavigationButtons && showNavigation && imageDTOs && imageDTOs.length > 1;
    }, [showNavigationButtons, showNavigation, imageDTOs]);

    const loop = useMemo<boolean>(() => {
        if (!swiperProps?.loop || !imageDTOs) {
            return false;
        }
        if (swiperProps.slidesPerView === "auto") {
            return swiperProps?.loop ?? false;
        }
        return imageDTOs.length >= 2 * (swiperProps.slidesPerView ?? 1);
    }, [swiperProps?.loop, swiperProps?.slidesPerView, imageDTOs?.length]);

    useEffect(() => {
        swiper?.slideTo(0);
    }, [imageDTOs?.length]);

    if (fetchStatus === "loading") {
        return <Skeleton variant="rectangular" width="100%" height="100%" />;
    }

    return (
        <CustomDropZone
            onDrop={onDrop}
            noClick={true}
            noKeyboard={true}
            accept={{
                'image/*': ['.jpeg', '.png']
            }}
            multiple={true}
            disabled={!enableDropZone}
        >
            {({ open: openDropzoneInput }) => (
                <>
                    <ClickAwayListener onClickAway={() => setIsExpandOptions(false)}>
                        <Box
                            position="relative"
                            width="100%"
                            height="100%"
                            bgcolor="background.paper"
                            display="grid"
                            gridTemplateColumns="repeat(5, 20%)"
                            gridTemplateRows="repeat(3, 33.3%)"
                            borderLeft={isExpandOptions ? "0.5px solid lightgray" : undefined}
                            borderTop={isExpandOptions ? "0.5px solid lightgray" : undefined}
                        >
                            <Box
                                bgcolor="rgba(0,0,0,0.05)"
                                gridColumn={isExpandOptions ? "1 / 5" : "1 / -1"}
                                gridRow={isExpandOptions ? "1 / 3" : "1 / -1"}
                                borderBottom={isExpandOptions ? "0.5px solid lightgray" : undefined}
                                borderRight={isExpandOptions ? "0.5px solid lightgray" : undefined}
                            >
                                {imageDTOs == undefined || imageDTOs.length == 0 && (
                                    <EmptyImagePlaceholder
                                        allowDropImages={enableDropZone}
                                        error={fetchStatus === "error"}
                                        openDropzoneInput={openDropzoneInput}
                                    />
                                )}
                                <Box
                                    width="100%"
                                    height="100%"
                                    sx={{
                                        display: imageDTOs == undefined || imageDTOs.length == 0 ? "none" : "block"
                                    }}
                                >
                                    <Swiper
                                        watchSlidesProgress
                                        onSlideChange={(swiper) => {
                                            setActiveSlideIndex(Math.min(swiper.realIndex, imageDTOs!.length - 1));
                                        }}
                                        modules={modules}
                                        centeredSlides={!loop} // centeredSlides is not compatible with loop
                                        {...swiperProps}
                                        loop={loop}
                                    >
                                        {imageDTOs?.map((imageDTO) =>
                                            <SwiperSlide key={getImageKey(imageDTO)}>
                                                {({ isActive, isVisible, isNext, isPrev }) => (
                                                    <LazyLoadImage
                                                        imageKey={getImageKey(imageDTO)}
                                                        imageDTO={imageDTO}
                                                        isActive={isActive || isVisible || isNext || isPrev}
                                                        timestamp={showTimestamp ? getImageTimestamp?.(imageDTO) : undefined}
                                                        timestampPosition={reverseButtonOrder ? "bottomLeft" : "bottomRight"}
                                                        getImageUrl={getImageUrl}
                                                        objectFit={objectFit}
                                                    />
                                                )}
                                            </SwiperSlide>
                                        )}
                                    </Swiper>
                                </Box>
                                {/* Layover buttons */}
                                {!isExpandOptions && <Box
                                    position="absolute"
                                    top={theme.spacing(1)}
                                    left={theme.spacing(1)}
                                    width={`calc(100% - ${theme.spacing(2)})`}
                                    height={`calc(100% - ${theme.spacing(2)})`}
                                    display="grid"
                                    gridTemplateRows="repeat(3, 1fr)"
                                    sx={{ pointerEvents: "none" }}
                                    zIndex={1}
                                >
                                    <Box display="flex" justifyContent="space-between" width="100%" flexDirection={reverseButtonOrder ? "row" : "row-reverse"}>
                                        <Box sx={{ pointerEvents: "auto" }}>
                                            {showContextMenu && (
                                                <ImageCarouselButton
                                                    icon={<MoreIcon />}
                                                    tooltip="More"
                                                    onClick={() => setIsExpandOptions(true)}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                                        {displayNavigationButtons && (
                                            <>
                                                <Box sx={{ pointerEvents: "auto" }}>
                                                    <ImageCarouselButton
                                                        icon={<ChevronLeftIcon primary="primary" />}
                                                        tooltip="Prev"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            swiper?.slidePrev();
                                                        }}
                                                    />
                                                </Box>
                                                <Box sx={{ pointerEvents: "auto" }}>
                                                    <ImageCarouselButton
                                                        icon={<ChevronRightIcon primary="primary" />}
                                                        tooltip="Next"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            swiper?.slideNext();
                                                        }}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" width="100%" alignItems="flex-end" flexDirection={reverseButtonOrder ? "row-reverse" : "row"}>
                                        <Box sx={{ pointerEvents: "auto" }}>
                                            {showAddButton && !isDesktop && <ImageCarouselButton
                                                icon={<TakePhotoIcon />}
                                                tooltip="Open Camera"
                                                onClick={() => setIsCameraOpen(true)}
                                            />}
                                            {showAddButton && isDesktop && <ImageCarouselButton
                                                icon={<AddPhotoIcon />}
                                                tooltip="Upload Image"
                                                onClick={openDropzoneInput}
                                            />}
                                        </Box>
                                    </Box>
                                </Box>}
                            </Box>
                            {isExpandOptions && (
                                <>
                                    <ImageCarouselOption
                                        IconComponent={CancelIcon}
                                        tooltip="Close"
                                        onClick={() => setIsExpandOptions(false)}
                                    />
                                    <ImageCarouselOption
                                        IconComponent={DownloadIcon}
                                        tooltip="Download"
                                        onClick={onDownloadImage}
                                        disabled={!activeImageDTO || !activeSlideImageUrl}
                                    />
                                    {showAddButton && isDesktop && <ImageCarouselOption
                                        IconComponent={TakePhotoIcon}
                                        tooltip="Camera"
                                        onClick={() => setIsCameraOpen(true)}
                                    />}
                                    {showAddButton && !isDesktop && <ImageCarouselOption
                                        IconComponent={AddPhotoIcon}
                                        tooltip="Upload"
                                        onClick={openDropzoneInput}
                                    />}
                                    {showEditButton && <ImageCarouselOption
                                        IconComponent={AnnotationIcon}
                                        tooltip="Edit"
                                        onClick={() => setIsEditorOpen(true)}
                                        disabled={!activeSlideImageUrl}
                                    />}
                                    <ImageCarouselOption
                                        IconComponent={ContentCopyIcon}
                                        tooltip="Copy"
                                        onClick={async () => {
                                            if (activeSlideImageUrl) {
                                                copyImage(activeSlideImageUrl, navigator);
                                            }
                                        }}
                                        disabled={!activeSlideImageUrl}
                                    />
                                    <ImageCarouselOption
                                        IconComponent={ContentPasteIcon}
                                        tooltip="Paste"
                                        onClick={async () => {
                                            const image = await pasteImage(navigator);
                                            if (image) {
                                                await onImagesUpload?.([image], false);
                                            }
                                        }}
                                    />
                                    <DeleteImageCarouselOption
                                        disabled={!activeImageDTO}
                                        onDeleteImage={() => {
                                            onDeleteImage?.(activeImageDTO!);
                                        }}
                                    />
                                </>
                            )}
                        </Box>
                    </ClickAwayListener>
                    {showAddButton && (
                        <Dialog
                            fullScreen={false}
                            open={isCameraOpen}
                            onClose={() => {
                                setIsCameraOpen(false);
                            }}
                            maxWidth="lg"
                        >
                            <Camera
                                onPictureTaken={async (imageUrl) => {
                                    const blob = await (await fetch(imageUrl)).blob();
                                    onImagesUpload?.([blob], false);
                                }}
                                onClose={() => {
                                    setIsCameraOpen(false);
                                }}
                            />
                        </Dialog>
                    )}
                    {showEditButton && (
                        <Dialog
                            fullScreen={false}
                            sx={{
                                '& .MuiDialog-paper': {
                                    width: '90%',
                                    height: '90%'
                                },
                            }}
                            open={isEditorOpen}
                            onClose={() => {
                                setIsEditorOpen(false);
                            }}
                            maxWidth="lg"
                        >
                            <ImageEditor
                                source={activeSlideImageUrl!}
                                onImageUpload={(image) => {
                                    onImagesUpload?.([image], false);
                                }}
                                onClose={() => setIsEditorOpen(false)}
                            />
                        </Dialog>
                    )}
                </>
            )}
        </CustomDropZone >
    );
};
