import { AttachmentParent } from "../../../../componentsV2/image/AttachmentParent";
import { PermissionResourceType } from "../../../../models";
import { StateContext } from "../../../../lib/design/document/state/StateContext";
import { propertyIdInFocusAtom } from "../../../../lib/ui/InFocusRecoilStates";
import { useCaptureMeeting } from "../useCaptureMeeting";
import { useCreateLocation } from "../../../../lib/location/hook/useCreateLocation";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useQueryClient } from "react-query";
import { useRecoilCallback } from "recoil";
import { useSnackbar } from "notistack";
import { useUploadImages } from "../../../../componentsV2/image/carouselComponent/useUploadImage";

export const useCreateLocationQuickAction = () => {
    const meetingManager = useMeetingManager();
    const snackbar = useSnackbar();
    const queryClient = useQueryClient();
    const [uploadImages] = useUploadImages();
    const { captureMeeting: captureVideo } = useCaptureMeeting();
    const { createDefaultLocation } = useCreateLocation(StateContext.INSPECTION);

    const createLocationQuickAction = useRecoilCallback(({ snapshot }) => async () => {
        const propertyIdInFocus = await snapshot.getPromise(propertyIdInFocusAtom);
        if (!propertyIdInFocus) {
            snackbar.enqueueSnackbar('No property in focus', { variant: 'error' });
            return;
        }
        const createdLocation = await createDefaultLocation(propertyIdInFocus, false);
        const attachmentParent: AttachmentParent = { id: createdLocation.id, type: PermissionResourceType.LOCATION };
        const blob: Blob | undefined = await captureVideo();
        if (!blob) {
            snackbar.enqueueSnackbar('Failed to take snapshot', { variant: 'error' });
            return;
        }
        await uploadImages([blob], { shouldResize: false }, attachmentParent);
        snackbar.enqueueSnackbar('Location created and snapshot taken and uploaded', { variant: 'success' });
        queryClient.invalidateQueries(["attachments", attachmentParent]);
    }, [snackbar, meetingManager, createDefaultLocation]);

    return {createLocationQuickAction};
}