import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import {
    ControlBarButton,
    Phone,
    useMeetingManager
} from "amazon-chime-sdk-component-library-react";

import AuthUsernameSupplierFactory from "../../lib/auth/AuthUsernameSupplierFactory";
import { MeetingDTO } from "../../lib/meeting/dao/type/MeetingDTO";
import UsernameSupplier from "../../lib/auth/UsernameSupplier";
import { graphQLMeetingClient } from "../../lib/meeting/chime/GraphQLMeetingClient";
import { graphQLMeetingDAO } from "../../lib/meeting/dao/GraphQLMeetingDAO";
import { meetingIdAtom } from "./state/ChimeStates";
import { useQuery } from "react-query";
import { useRecoilCallback } from "recoil";
import { useState } from "react";

const userNameSupplier: UsernameSupplier = AuthUsernameSupplierFactory.getInstance();

export const EndMeetingControlBarButton = () => {
    const [showDialog, setShowDialog] = useState(false);
    const meetingManager = useMeetingManager();
    const { data: meeting } = useQuery({
        queryKey: ["meeting", meetingManager.meetingId],
        queryFn: async () => {
            if (meetingManager.meetingId) {
                return await graphQLMeetingDAO.getById(meetingManager.meetingId);
            }
            return undefined;
        }
    });

    const onEndCallButtonClicked = async () => {
        try {
            const userName = await userNameSupplier.get();
            if (!meeting || meeting.createdBy !== userName) {
                meetingManager.leave();
            }
            setShowDialog(true);
        } catch (e) {
            // Just leave the meeting if there is an error getting username
            meetingManager.leave();
        }
    };

    const endMeetingAndLeave = useRecoilCallback(({ reset }) => async () => {
        const meetingId = meetingManager.meetingId;
        if (meetingId) {
            try {
                const userName = await userNameSupplier.get();
                const existingMeeting: MeetingDTO = await graphQLMeetingDAO.getById(meetingId);
                if (existingMeeting && existingMeeting.createdBy === userName) {
                    await graphQLMeetingClient.endMeeting(meetingId);
                }
            } finally {
                meetingManager.leave();
                reset(meetingIdAtom);
            }
        }
    }, []);

    return (
        <>
            <ControlBarButton icon={<Phone />} onClick={onEndCallButtonClicked} label="End" />
            <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
                <DialogTitle>End Meeting</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to end meeting for all participants?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" flex={1} justifyContent="space-between">
                        <Button onClick={() => setShowDialog(false)} color="error" variant="outlined">
                            Cancel
                        </Button>
                        <Box display="flex" gap={1}>
                            <Button onClick={() => meetingManager.leave()} color="primary" variant="contained">
                                Just Leave
                            </Button>
                            <Button onClick={endMeetingAndLeave} color="error" variant="contained">
                                End for Everyone
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
}


