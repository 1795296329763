import {
    LinearProgress,
    LinearProgressProps,
    alpha
} from '@mui/material';

import { AttachmentUploadStatus } from '../../lib/attachment/upload/status/AttachmentUploadStatus';
import { makeStyles } from '@mui/styles';

interface AttachmentLinearProgressProps {
    status: AttachmentUploadStatus;
}

const useStyles = makeStyles((theme) => ({
    successfulBarColorPrimary: {
        backgroundColor: theme.palette.success.main,
    },
    failedColorPrimary: {
        backgroundColor: alpha(theme.palette.error.light, 0.5),
    },
    failedBarColorPrimary: {
        backgroundColor: theme.palette.error.main,
    },
}));

const AttachmentUploadLinearProgress = (props: AttachmentLinearProgressProps) => {
    const classes = useStyles();

    const failLinearProgressProps: LinearProgressProps = {
        classes: {
            colorPrimary: classes.failedColorPrimary,
            barColorPrimary: classes.failedBarColorPrimary
        },
        title: 'Upload Failed',
        variant: 'determinate'
    };

    const inProgressLinearProgressProps: LinearProgressProps = {
        title: 'In progress',
        variant: 'determinate'
    };

    const successLinearProgressProps: LinearProgressProps = {
        classes: {
            barColorPrimary: classes.successfulBarColorPrimary
        },
        title: 'Upload Successful',
        variant: 'determinate'
    };

    const linearProgressPropsMap = new Map<AttachmentUploadStatus, LinearProgressProps>([
        [AttachmentUploadStatus.PENDING_UPLOAD, {
            ...inProgressLinearProgressProps,
            value: 30
        }],
        [AttachmentUploadStatus.UPLOAD_FAILED, {
            ...failLinearProgressProps,
            value: 30
        }],
        [AttachmentUploadStatus.UPLOAD_IN_PROGRESS, {
            ...inProgressLinearProgressProps,
            value: 60
        }],
        [AttachmentUploadStatus.PENDING_RECORD_VALIDATION, {
            ...inProgressLinearProgressProps,
            value: 90
        }],
        [AttachmentUploadStatus.COMPLETE, {
            ...successLinearProgressProps,
            value: 100
        }]
    ]);

    return (
        <LinearProgress
                {...linearProgressPropsMap.get(props.status)}
        />
    );
};

export default AttachmentUploadLinearProgress;
