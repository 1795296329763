import {
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    Typography
} from "@mui/material";
import {
    MeetingStatus,
    useMeetingStatus
} from "amazon-chime-sdk-component-library-react";

import { AudioInputDeviceTest } from "./AudioInputDeviceTest";
import { AudioOutputDeviceTest } from "./AudioOutputDeviceTest";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { StateContext } from "../../../lib/design/document/state/StateContext";
import { VideoInputPreview } from "./VideoInputPreview";
import { isJoiningMeetingAtom } from "../state/ChimeStates";
import { stateContextInFocusAtom } from "../../../lib/ui/InFocusRecoilStates";
import { useRecoilValue } from "recoil";
import { useState } from "react";

export const AudioVideoSettings = () => {
    const context = useRecoilValue(stateContextInFocusAtom);
    const inspectionMode = context === StateContext.INSPECTION;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const meetingStatus = useMeetingStatus();
    const isMeetingActive = meetingStatus === MeetingStatus.Succeeded || meetingStatus === MeetingStatus.Reconnecting;
    const isJoiningMeeting = useRecoilValue(isJoiningMeetingAtom);

    return (
        <>
            <Button
                onClick={() => setIsDrawerOpen(true)}
                disabled={isMeetingActive || isJoiningMeeting}
            >
                Audio and Video Settings
            </Button>
            <Drawer
                anchor="right"
                open={!isMeetingActive && !isJoiningMeeting && isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                PaperProps={{
                    sx: {
                        width: {
                            xs: "100vw",
                            sm: 300,
                            md: 400,
                            lg: 500,
                        },
                        pt: inspectionMode ? 8 : undefined
                    }
                }}
            >
                <Box display="flex" flexDirection="column" gap={2} p={1}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6">
                            Audio and Video Settings
                        </Typography>
                        <IconButton
                            onClick={() => setIsDrawerOpen(false)}
                            sx={{ marginLeft: "auto" }}
                        >
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Box>
                    <Box display="flex" flexDirection="column" gap={1}>
                        <Typography fontWeight={700}>
                            Speaker Test
                        </Typography>
                        <AudioOutputDeviceTest />
                    </Box>
                    <Divider />
                    <Box display="flex" flexDirection="column" gap={1}>
                        <Typography fontWeight={700}>
                            Microphone Test
                        </Typography>
                        <AudioInputDeviceTest />
                    </Box>
                    <Divider />
                    <Box display="flex" flexDirection="column" gap={1}>
                        <Typography fontWeight={700}>
                            Video Preview
                        </Typography>
                        <VideoInputPreview />
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};