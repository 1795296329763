import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

/**
 * This hook works around a special behaviour in Safari, where the navigator.clipboard.read() method
 * is only available in response to a user event. This hook accepts the navigator object as a parameter
 * passed from a button's onclick.
 * 
 * Tl:dr; test this hook in Safari thoroughly when making changes.
 */
const useReadImageFromClipboard = () => {

    const snackbar = useSnackbar();

    const readImageFromClipboard = useCallback(async (nav: Navigator): Promise<Blob | null> => {
        try {
            const clipboardItems = await nav.clipboard.read();
            for (const clipboardItem of clipboardItems) {
                for (const type of clipboardItem.types) {
                    if (type.startsWith('image/')) {
                        const blob = await clipboardItem.getType(type);
                        snackbar.enqueueSnackbar('Image read from clipboard', { variant: 'success' });
                        return blob;
                    }
                }
            }
        } catch (error) {
            snackbar.enqueueSnackbar(`Failed to read image from clipboard ${error}`, { variant: 'error' });
        }
        return null;
    }, []);

    return readImageFromClipboard;
};

export default useReadImageFromClipboard;
