import {
    useEffect,
    useState
} from "react";

import GraphQLLocationDAOFactory from "../../lib/location/dao/graphql/GraphQLLocationDAOFactory";
import { GraphQLSearchLocationByPropertyIdOperation } from "../../lib/location/dao/GraphQLSearchLocationByPropertyIdOperation";
import { Location } from "../../models";
import LocationDAO from "../../lib/location/dao/LocationDAO";
import { SearchBox } from "../misc/SearchBox";
import { TextFieldProps } from "@mui/material";

type LocationSearchBoxProps = {
    readonly propertyId: string | undefined;
    readonly onSelect: (option: Location | null) => void;
    readonly onClear: () => void;
    readonly label: string;
    readonly disabled?: boolean;
    readonly initialLocationId: string | undefined;
} & Omit<TextFieldProps, "onSelect" | "value">;

const locationDAO: LocationDAO & GraphQLSearchLocationByPropertyIdOperation = GraphQLLocationDAOFactory.getInstance();

export const LocationSearchBox = (props: LocationSearchBoxProps) => {
    const { propertyId, onSelect, onClear, label, disabled, initialLocationId, ...textFieldProps } = props;
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(() => {
        if (isInitialized) {
            setSelectedLocation(null);
            onClear();
        }
    }, [propertyId]);

    useEffect(() => {
        const initializeLocation = async () => {
            if (initialLocationId) {
                const location: Location = await locationDAO.getLocationById(initialLocationId);
                setSelectedLocation(location);
            }
            setIsInitialized(true);
        };
        initializeLocation();
    }, []);

    return (
        <SearchBox
            label={label}
            typeName="Group"
            labelFields={["name"]}
            value={selectedLocation}
            onClear={() => {
                setSelectedLocation(null);
                onClear();
            }}
            onSelect={(option: Location | null) => {
                onSelect(option);
                setSelectedLocation(option);
            }}
            search={(searchValue, nextToken) => {
                return locationDAO.searchLocationsByPropertyId(propertyId!, searchValue, nextToken);
            }}
            queryKeys={propertyId ? [propertyId]: []}
            textFieldStyle={textFieldProps}
            searchWithoutSearchTerm={!disabled}
            disabled={disabled}
        />
    );
};

export default LocationSearchBox;
