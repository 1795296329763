import Comparator from "../../util/data/sort/Comparator";
import { ComparisonResult } from "../../util/data/sort/ComparisonResult";
import { Property } from "../../../models";
import { SortDirection } from "../../sorting/SortDirection";

export class ByRecentlyCreatedComparator implements Comparator<Property> {
    private readonly sortDirection: SortDirection;

    constructor(sortDirection: SortDirection) {
        this.sortDirection = sortDirection;
    }

    public compare(a: Property, b: Property): ComparisonResult {
        let result = 0;
        // Property with no createdAt comes before property with createdAt.
        if (a.createdAt == null) {
            result = -1;
        }
        if (b.createdAt == null) {
            result = 1;
        }
        if (a.createdAt != null && b.createdAt != null) {
            result = a.createdAt.localeCompare(b.createdAt);
        }
        if (this.sortDirection === SortDirection.DESCENDING) {
            result = -result;
        }
        if (result < 0) {
            return ComparisonResult.LESS_THAN;
        }
        if (result > 0) {
            return ComparisonResult.GREATER_THAN;
        }
        return ComparisonResult.EQUAL;
    }
}