import AdjustmentItem from "../element/adjustment/AdjustmentItem";
import { AdjustmentType } from "../element/adjustment/AdjustmentType";
import TotalAdjustmentAmountCalculationError from "./errors/TotalAdjustmentAmountCalculationError";
import TotalAdjustmentAmountCalculator from "./TotalAdjustmentAmountCalculator";

export default class DefaultTotalAdjustmentAmountCalculator implements TotalAdjustmentAmountCalculator {
    calculate(items: Array<AdjustmentItem>): number {
        return items.reduce((sum: number, item: AdjustmentItem) => {
            if (item.amount == null) {
                throw new TotalAdjustmentAmountCalculationError("Adjustment item amount not set.")
            }
            if (item.type === AdjustmentType.ADDITION) {
                return sum + item.amount;
            }
            if (item.type === AdjustmentType.SUBTRACTION) {
                return sum - item.amount;
            }
            throw new TotalAdjustmentAmountCalculationError(`Unknown adjustment type: ${item.type}.`);
        }, 0)
    }
}
