import {
    Issue,
    PermissionResourceType
} from "../../../../models";
import {
    idInFocusByModelTypeAtomFamily,
    propertyIdInFocusAtom
} from "../../../../lib/ui/InFocusRecoilStates";

import { AttachmentParent } from "../../../../componentsV2/image/AttachmentParent";
import { ModelType } from "../../../../lib/design/document/ModelType";
import { StateContext } from "../../../../lib/design/document/state/StateContext";
import { useCaptureMeeting } from "../useCaptureMeeting";
import { useCreateIssue } from "../../../../lib/issue/hook/useCreateIssue";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useQueryClient } from "react-query";
import { useRecoilCallback } from "recoil";
import { useSnackbar } from "notistack";
import { useUploadImages } from "../../../../componentsV2/image/carouselComponent/useUploadImage";

export const useCreateIssueQuickAction = () => {
    const meetingManager = useMeetingManager();
    const snackbar = useSnackbar();
    const queryClient = useQueryClient();
    const [uploadImages] = useUploadImages();
    const { captureMeeting: captureVideo } = useCaptureMeeting();
    const { createDefaultIssue } = useCreateIssue(StateContext.INSPECTION);
    
    const createIssueQuickAction = useRecoilCallback(({ snapshot }) => async () => {
        const propertyIdInFocus = await snapshot.getPromise(propertyIdInFocusAtom);
        if (!propertyIdInFocus) {
            snackbar.enqueueSnackbar('No property in focus', { variant: 'error' });
            return;
        }
        const locationIdInFocus = await snapshot.getPromise(idInFocusByModelTypeAtomFamily(ModelType.LOCATION));
        if (!locationIdInFocus) {
            snackbar.enqueueSnackbar('No location in focus', { variant: 'error' });
            return;
        }
        const createdIssue = await createDefaultIssue(propertyIdInFocus, locationIdInFocus, false);
        if (!createdIssue) {
            snackbar.enqueueSnackbar('Failed to create issue', { variant: 'error' });
            return;
        }
        const attachmentParent: AttachmentParent = { id: createdIssue.id, type: PermissionResourceType.ISSUE };
        const blob: Blob | undefined = await captureVideo();
        if (!blob) {
            snackbar.enqueueSnackbar('Failed to take snapshot', { variant: 'error' });
            return;
        }
        await uploadImages([blob], { shouldResize: false }, attachmentParent);
        snackbar.enqueueSnackbar('Issue created and snapshot taken and uploaded', { variant: 'success' });
        queryClient.invalidateQueries(["attachments", attachmentParent]);
    }, [snackbar, meetingManager, createDefaultIssue]);

    return { createIssueQuickAction };
}