import {
    Dispatch,
    SetStateAction
} from "react";

import AuthSignInEventHandler from "./AuthSignInEventHandler";
import AuthSignOutEventHandler from "./AuthSignOutEventHandler";
import { CognitoUser } from "@aws-amplify/auth";
import HubEventHandler from "../event/HubEventHandler";

export default class UserEventHandlerFactory {
    public static create(
        setUser: Dispatch<SetStateAction<CognitoUser | undefined>>
    ) {
        const signInEventHandler: HubEventHandler = new AuthSignInEventHandler(setUser);
        return new AuthSignOutEventHandler(
            setUser,
            signInEventHandler
        );
    }
}
