import {
    Box,
    Button,
    Divider,
    List,
    ListItemButton,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";
import {
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";

import DataGroup from "../../lib/util/data/group/DataGroup";
import { HotkeysEvent } from "react-hotkeys-hook/dist/types";
import { Measurement } from "../../models";
import WorkTypeDTO from "../../lib/worktype/DTO/WorkTypeDTO";
import { associatedWorkTypeDataGroupsSelector } from "../../lib/worktype/state/WorkTypeState";
import { useAIMeasurementSummarizer } from "../chime/hooks/useAIMeasurementSummarizer";
import { useHotkeys } from "react-hotkeys-hook";
import { useRecoilValue } from "recoil";

type WorkScopeSpecificationQuickCreateFormProps = {
    readonly isOpened: boolean;
    readonly onCancel: () => void;
    readonly onSubmit: (
        workType?: WorkTypeDTO,
        width?: number,
        length?: number
    ) => void;
    readonly defaultMeasurement?: Measurement;
};

enum FormPhase {
    SELECT_WORK_TYPE_GROUP,
    SELECT_WORK_TYPE,
    SET_WIDTH,
    SET_LENGTH
}

const decimalNumberRegex = /^\d*\.?\d{0,2}$/;
export const WorkScopeSpecificationQuickCreateForm = (props: WorkScopeSpecificationQuickCreateFormProps) => {
    const { isOpened, onCancel, onSubmit, defaultMeasurement } = props;
    const { generateMeasurementFromMessages } = useAIMeasurementSummarizer();
    const associatedWorkTypeGroups = useRecoilValue<Array<DataGroup<string, WorkTypeDTO>>>(associatedWorkTypeDataGroupsSelector);
    const [selectedWorkTypeGroup, setSelectedWorkTypeGroup] = useState<DataGroup<string, WorkTypeDTO>>();
    const [formPhase, setFormPhase] = useState<FormPhase>(FormPhase.SELECT_WORK_TYPE_GROUP);
    const [selectedWorkType, setSelectedWorkType] = useState<WorkTypeDTO>();
    const [isGeneratingMeasurement, setIsGeneratingMeasurement] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(defaultMeasurement?.width ?? 0);
    const [length, setLength] = useState<number>(defaultMeasurement?.length ?? 0);
    const widthInputRef = useRef<HTMLInputElement>(null);
    const lengthInputRef = useRef<HTMLInputElement>(null);
    const [searchText, setSearchText] = useState<string>("");

    const filteredWorkTypeGroups = useMemo(() => associatedWorkTypeGroups.filter((group) => {
        if (!searchText) {
            return true;
        }
        return group.dataItems.some((workType) => workType.name.toLowerCase().includes(searchText.toLowerCase()));
    }), [associatedWorkTypeGroups, searchText]);

    const filteredWorkTypesInSelectedGroup = useMemo(() => selectedWorkTypeGroup?.dataItems.filter((workType) => {
        if (!searchText) {
            return true;
        }
        return workType.name.toLowerCase().includes(searchText.toLowerCase());
    }), [selectedWorkTypeGroup, searchText]);

    useEffect(() => {
        if (formPhase === FormPhase.SET_WIDTH && widthInputRef.current) {
            widthInputRef.current.focus();
            return;
        }
        if (formPhase === FormPhase.SET_LENGTH && lengthInputRef.current) {
            lengthInputRef.current.focus();
            return;
        }
        widthInputRef.current?.blur();
        lengthInputRef.current?.blur();
    }, [formPhase]);

    const getMeasurementsFromTranscript = async () => {
        setIsGeneratingMeasurement(true);
        try {
            const measurement = await generateMeasurementFromMessages();
            setWidth(measurement.width ?? 0);
            setLength(measurement.length ?? 0);
        } finally {
            setIsGeneratingMeasurement(false);
        }
    };

    useEffect(() => {
        const doesDefaultMeasurementExist = defaultMeasurement && (defaultMeasurement.width || defaultMeasurement.length);
        if (isOpened && !doesDefaultMeasurementExist) {
            getMeasurementsFromTranscript();
        }
    }, [isOpened]);

    const handleNumericHotKeys = (_: KeyboardEvent, handler: HotkeysEvent) => {
        const index = parseInt(handler.keys![0]);
        switch (formPhase) {
            case FormPhase.SELECT_WORK_TYPE_GROUP:
                if (index < associatedWorkTypeGroups.length) {
                    const chosenGroup = associatedWorkTypeGroups[index];
                    if (filteredWorkTypeGroups.every((group) => group.key !== chosenGroup.key)) {
                        return;
                    }
                    setSelectedWorkTypeGroup(chosenGroup);
                    setFormPhase(FormPhase.SELECT_WORK_TYPE);
                }
                break;
            case FormPhase.SELECT_WORK_TYPE:
                if (selectedWorkTypeGroup && index < selectedWorkTypeGroup.dataItems.length) {
                    const chosenWorkType = selectedWorkTypeGroup.dataItems[index];
                    if (filteredWorkTypesInSelectedGroup?.every((workType) => workType.name !== chosenWorkType.name)) {
                        return;
                    }
                    setSelectedWorkType(chosenWorkType);
                    setFormPhase(FormPhase.SET_LENGTH);
                }
                break;
            case FormPhase.SET_LENGTH:
            case FormPhase.SET_WIDTH:
                break;
        }
    };

    const handleHotKeys = (_: KeyboardEvent, handler: HotkeysEvent) => {
        if (!handler.keys || handler.keys.length === 0) {
            return;
        }
        switch (formPhase) {
            case FormPhase.SELECT_WORK_TYPE_GROUP:
                if (handler.keys[0] === 'enter') {
                    setFormPhase(FormPhase.SET_LENGTH);
                }
                if (handler.keys[0] === '-') {
                    onCancel();
                }
                break;
            case FormPhase.SELECT_WORK_TYPE:
                if (handler.keys[0] === 'enter') {
                    setFormPhase(FormPhase.SET_LENGTH);
                }
                if (handler.keys[0] === '-') {
                    setSelectedWorkTypeGroup(undefined);
                    setFormPhase(FormPhase.SELECT_WORK_TYPE_GROUP);
                }
                break;
            case FormPhase.SET_LENGTH:
                if (handler.keys[0] === 'enter') {
                    setFormPhase(FormPhase.SET_WIDTH);
                }
                if (handler.keys[0] === '-') {
                    if (selectedWorkTypeGroup !== undefined) {
                        setSelectedWorkType(undefined);
                        setFormPhase(FormPhase.SELECT_WORK_TYPE);
                        return;
                    }
                    setFormPhase(FormPhase.SELECT_WORK_TYPE_GROUP);
                }
                break;
            case FormPhase.SET_WIDTH:
                if (handler.keys[0] === 'enter') {
                    onSubmit(
                        selectedWorkType,
                        width,
                        length
                    );
                }
                if (handler.keys[0] === '-') {
                    setFormPhase(FormPhase.SET_LENGTH);
                }
                break;
        }
    };

    useHotkeys('0, 1, 2, 3, 4, 5, 6, 7, 8, 9', handleNumericHotKeys, { enabled: isOpened, preventDefault: true });
    useHotkeys('enter, -', handleHotKeys, { enabled: isOpened, preventDefault: true, enableOnFormTags: ['INPUT'] });

    const handleManualWorkTypeGroupSelection = (group: DataGroup<string, WorkTypeDTO>) => {
        setSelectedWorkTypeGroup(group);
        setSelectedWorkType(undefined);
        setFormPhase(FormPhase.SELECT_WORK_TYPE);
    };

    const handleManualWorkTypeSelection = (workType: WorkTypeDTO) => {
        setSelectedWorkType(workType);
        setFormPhase(FormPhase.SET_LENGTH);
    };

    return (
        <Box>
            <TextField
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onBlur={() => setFormPhase(FormPhase.SELECT_WORK_TYPE_GROUP)}
                placeholder="Search for WorkType"
                fullWidth
                size="small"
            />
            <Box
                flex={0}
                display="flex"
                flexDirection="row"
                sx={{
                    gap: 2
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '300px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography sx={{ my: 1 }} variant="body1" component="div">
                        WorkType Category
                    </Typography>
                    <List
                        disablePadding
                        sx={{
                            //give the list an outline
                            border: 1,
                            borderColor: 'divider',
                            borderStyle: 'solid',
                            borderRadius: 1,
                            //make the list scrollable
                            overflow: 'auto',
                            width: '100%',
                            flexGrow: 1
                        }}
                    >
                        {associatedWorkTypeGroups.map((group: DataGroup<string, WorkTypeDTO>, index: number) => {
                            if (filteredWorkTypeGroups.every((filteredGroup) => filteredGroup.key !== group.key)) {
                                return null;
                            }
                            return (
                                <>
                                    <ListItemButton
                                        key={group.key}
                                        onClick={() => {
                                            handleManualWorkTypeGroupSelection(group);
                                        }}
                                        sx={{
                                            backgroundColor: selectedWorkTypeGroup === group ? 'lightblue' : 'inherit',
                                        }}
                                    >
                                        <ListItemText>
                                            {index < 10 ? `${index}. ` : ""}{group.key}
                                        </ListItemText>
                                    </ListItemButton>
                                    {index !== associatedWorkTypeGroups.length - 1 &&
                                        <Divider />
                                    }
                                </>
                            );
                        })}
                    </List>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: '300px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography sx={{ my: 1 }} variant="body1" component="div">
                        WorkType
                    </Typography>
                    <List
                        disablePadding
                        sx={{
                            //give the list an outline
                            border: 1,
                            borderColor: 'divider',
                            borderStyle: 'solid',
                            borderRadius: 1,
                            //make the list scrollable
                            overflow: 'auto',
                            width: '100%',
                            height: '600px',
                            flex: 1
                        }}
                    >
                        {selectedWorkTypeGroup?.dataItems?.map((workType, index) => {
                            if (filteredWorkTypesInSelectedGroup?.every((filteredWorkType) => filteredWorkType.name !== workType.name)) {
                                return null;
                            }
                            return (
                                <>
                                    <ListItemButton
                                        key={workType.name}
                                        sx={{
                                            backgroundColor: selectedWorkType === workType ? 'lightblue' : 'inherit',
                                        }}
                                        onClick={() => {
                                            handleManualWorkTypeSelection(workType);
                                        }}
                                    >
                                        <ListItemText>
                                            {index < 10 ? `${index}. ` : ""}{workType.name}
                                        </ListItemText>
                                    </ListItemButton>
                                    {index !== associatedWorkTypeGroups.length - 1 &&
                                        <Divider />
                                    }
                                </>
                            );
                        })}
                    </List>
                </Box>
                <Box
                    sx={{
                        height: '300px',
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box py={2}>
                        <Typography sx={{ my: 1 }} variant="body1" component="div">
                            Length:
                        </Typography>
                        <TextField
                            disabled={isGeneratingMeasurement}
                            type={isGeneratingMeasurement ? "text" : "number"}
                            value={isGeneratingMeasurement ? "Pending" : length}
                            onChange={(e) => {
                                if (!decimalNumberRegex.test(e.target.value)) {
                                    return;
                                }
                                setLength(parseFloat(e.target.value));
                            }}
                            inputRef={lengthInputRef}
                            onFocus={(e) => e.target.select()}
                        />
                    </Box>
                    <Box>
                        <Typography sx={{ my: 1 }} variant="body1" component="div">
                            Width:
                        </Typography>
                        <TextField
                            disabled={isGeneratingMeasurement}
                            type={isGeneratingMeasurement ? "text" : "number"}
                            value={isGeneratingMeasurement ? "Pending" : width}
                            onChange={(e) => {
                                if (!decimalNumberRegex.test(e.target.value)) {
                                    return;
                                }
                                setWidth(parseFloat(e.target.value));
                            }}
                            inputRef={widthInputRef}
                            onFocus={(e) => e.target.select()}
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                pt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
                sx={{
                    gap: 2
                }}
            >
                <Button
                    size="large"
                    variant="outlined"
                    onClick={() => {
                        onCancel();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    size="large"
                    variant="contained"
                    onClick={() => {
                        onSubmit(
                            selectedWorkType,
                            width,
                            length
                        );
                    }}
                >
                    Create
                </Button>
            </Box>
        </Box>
    );
};