import { AttachmentContent } from "../AttachmentContent";
import AttachmentContentDAO from "../AttachmentContentDAO";
import AttachmentContentKeyWithVariantFactory from "../AttachmentContentKeyWithVariantFactory";
import { Storage } from "@aws-amplify/storage";

export class S3AttachmentContentDAO implements AttachmentContentDAO {
    
    private readonly contentType: string;
    private readonly directory: string | undefined;

    constructor(
        contentType: string,
        directory?: string
    ) {
        this.contentType = contentType;
        this.directory = directory;
    }

    public async getAttachmentContentByAttachmentKey(
        key: string,
        variant?: string
    ): Promise<AttachmentContent> {
        const keyWithVariant: string = AttachmentContentKeyWithVariantFactory.createKey(key, variant, this.directory);
        // Full signature of Storage.get explained here https://docs.amplify.aws/lib/storage/download/q/platform/js/#file-download-option
        const attachment: any = await Storage.get(keyWithVariant, {expires: 60, download: true});
        const attachmentContent: AttachmentContent = {
            data: await attachment.Body.arrayBuffer()
        }
        return attachmentContent;
    }

    public async listAttachmentContentKeys(): Promise<string[]> {
        throw new Error("Method not implemented.");
    }

    public async saveAttachment(
        key: string,
        content: AttachmentContent,
        variant?: string
    ): Promise<void> {
        const keyWithVariant: string = AttachmentContentKeyWithVariantFactory.createKey(key, variant, this.directory);
        await Storage.put(keyWithVariant as string, content.data, {
            contentType: this.contentType
        });
    }
}
