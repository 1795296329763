import GraphQLProposalRecordDAOFactory from "../../dao/record/GraphQLProposalRecordDAOFactory";
import SubmitProposalHandler from "./SubmitProposalHandler";
import SubmitProposalHandlerImpl from "./SubmitProposalHandlerImpl";

export default class SubmitProposalHandlerFactory {
    private static readonly INSTANCE: SubmitProposalHandler = new SubmitProposalHandlerImpl(
        GraphQLProposalRecordDAOFactory.getInstance()
    );

    public static getInstance(): SubmitProposalHandler {
        return SubmitProposalHandlerFactory.INSTANCE;
    }
}
