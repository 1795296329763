import {
    AggregateUserPermissions,
    EntityResourcePermission,
    Organization,
    PermissionEntityType,
    PermissionResourceType,
    PermissionType,
    User
} from "../../models";
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import { DataStore } from "aws-amplify";
import { DataStoreSnapshot } from "@aws-amplify/datastore";
import { GraphQLResourceAggregateUserPermissionDAOFactory } from "../../lib/permission/dao/GraphQLResourceAggregateUserPermissionDAOFactory";
import { PermissionChangeHandler } from "../../lib/permission/template/PermissionChangeHandler";
import { PermissionChangeHandlerFactory } from "../../lib/permission/template/PermissionChangeHandlerFactory";
import { Resource } from "./type/Resource";
import { ResourceAggregateUserPermissionDAO } from "../../lib/permission/dao/ResourceAggregateUserPermissionDAO";
import _ from "lodash";
import { isUpdatingPermissionByResourceAtomFamily } from "./state/ManagePermissionState";
import { propertyIdInFocusAtom } from "../../lib/ui/InFocusRecoilStates";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";

export const useManageResourcePermissions = (resourceId: string, resourceType: PermissionResourceType) => {
    const [userIdToAggregateUserPermissionsMap, setUserIdToAggregateUserPermissionsMap] = useState<Map<string, AggregateUserPermissions> | undefined>();
    const resource = useMemo<Resource>(() => {
        return new Resource(resourceId, resourceType);
    }, [resourceId, resourceType]);
    const propertyId = useRecoilValue(propertyIdInFocusAtom);
    const [isUpdatingResourcePermission, setIsUpdatingResourcePermission] = useRecoilState<boolean>(isUpdatingPermissionByResourceAtomFamily(resource));
    const resourceAggregateUserPermissionDAORef = useRef<ResourceAggregateUserPermissionDAO>(GraphQLResourceAggregateUserPermissionDAOFactory.getInstance());
    const permissionChangeHandlerRef = useRef<PermissionChangeHandler>(PermissionChangeHandlerFactory.getInstance());
    const snackbar = useSnackbar();

    const fetchAggregateUserPermissions = async (resourceId: string, resourceType: PermissionResourceType) => {
        let token = undefined;
        const map = new Map<string, AggregateUserPermissions>();
        do {
            const result = await resourceAggregateUserPermissionDAORef.current.listByResource(resourceId, resourceType);
            result.items!.reduce((map, item) => {
                if (!item || !item.userId) {
                    return map;
                }
                if (!map.has(item.userId)) {
                    return map.set(item.userId, item);
                }
                const existingAggregateUserPermissions: AggregateUserPermissions = map.get(item.userId)!;
                const newPermissions: Array<PermissionType | null> = item.permissions!;
                const newAggregateUserPermissions: AggregateUserPermissions = {
                    ...existingAggregateUserPermissions,
                    permissions: (existingAggregateUserPermissions.permissions! as Array<PermissionType | null>).concat(newPermissions)
                };
                return map.set(item.userId, newAggregateUserPermissions);
            }, map);
            token = result.nextToken ?? undefined;
        } while (token != undefined);
        return map;
    };

    const { data: fetchUserPermissionsResultData, isFetching, refetch } = useQuery({
        queryKey: ["ManageUserPermissionsList", resourceId, resourceType],
        queryFn: () => fetchAggregateUserPermissions(resourceId, resourceType),
    });

    const debouncedRefetch = useCallback(_.debounce(refetch, 200), []);

    useEffect(() => {
        setUserIdToAggregateUserPermissionsMap(fetchUserPermissionsResultData);
        let subscription: ZenObservable.Subscription;
        if (fetchUserPermissionsResultData?.size === 0) {
            subscription = DataStore.observeQuery(EntityResourcePermission, erp => erp.resourceId("eq", resourceId).and(erp => erp.resourceType("eq", resourceType)))
                .subscribe((value: DataStoreSnapshot<EntityResourcePermission>) => {
                    if (value.items.length > 0) {
                        debouncedRefetch();
                    }
                });
        }
        return () => subscription?.unsubscribe();
    }, [fetchUserPermissionsResultData]);

    const isUpdatingData = useMemo<boolean>(() => {
        return isFetching || isUpdatingResourcePermission;
    }, [isFetching, isUpdatingResourcePermission]);

    const onSharePermissions = async (
        entityType: PermissionEntityType,
        entitiesToShare: Array<User | Organization>,
        permissions: Array<PermissionType>
    ) => {
        try {
            if (!propertyId) {
                throw new Error("No property is in focus");
            }
            setIsUpdatingResourcePermission(true);
            await Promise.all(entitiesToShare.map((entity => permissionChangeHandlerRef.current.changeEntityPermissionsForResource(
                entity.id,
                entityType,
                resourceId,
                resourceType,
                propertyId,
                [],
                permissions
            ))));
            if (entityType === PermissionEntityType.USER) {
                setUserIdToAggregateUserPermissionsMap(existingMap => {
                    if (!existingMap) {
                        return existingMap;
                    }
                    const newMap: Map<string, AggregateUserPermissions> = new Map(existingMap);
                    entitiesToShare.forEach((user: User) => {
                        newMap.set(user.id, {
                            userId: user.id,
                            username: user.name,
                            permissions: permissions
                        });
                    });
                    return newMap;
                });
                return;
            }
            await refetch();
        } catch (error) {
            snackbar.enqueueSnackbar((error as Error).message, { variant: "error" });
        } finally {
            setIsUpdatingResourcePermission(false);
        }
    };

    const onRemoveUserPermissions = async (userId: string) => {
        try {
            setIsUpdatingResourcePermission(true);
            if (!propertyId) {
                throw new Error("No property is in focus");
            }
            await permissionChangeHandlerRef.current.changeEntityPermissionsForResource(
                userId,
                PermissionEntityType.USER,
                resourceId,
                resourceType,
                propertyId,
                userIdToAggregateUserPermissionsMap!.get(userId)!.permissions as Array<PermissionType>,
                []
            );
            setUserIdToAggregateUserPermissionsMap(existingMap => {
                if (!existingMap) {
                    return existingMap;
                }
                const newMap: Map<string, AggregateUserPermissions> = new Map(existingMap);
                newMap.delete(userId);
                return newMap;
            });
        } catch (error) {
            snackbar.enqueueSnackbar((error as Error).message, { variant: "error" });
        } finally {
            setIsUpdatingResourcePermission(false);
        }
    };

    const onUpdateUserPermissions = async (userId: string, newPermissions: Array<PermissionType>) => {
        try {
            setIsUpdatingResourcePermission(true);
            if (!propertyId) {
                throw new Error("No property is in focus");
            }
            await permissionChangeHandlerRef.current.changeEntityPermissionsForResource(
                userId,
                PermissionEntityType.USER,
                resourceId,
                resourceType,
                propertyId,
                userIdToAggregateUserPermissionsMap!.get(userId)!.permissions as Array<PermissionType>,
                newPermissions
            );
            setUserIdToAggregateUserPermissionsMap(existingMap => {
                if (!existingMap || !existingMap.has(userId)) {
                    return existingMap;
                }
                const newMap: Map<string, AggregateUserPermissions> = new Map(existingMap);
                const existingAggregateUserPermissions: AggregateUserPermissions = newMap.get(userId)!;
                return newMap.set(userId, {
                    ...existingAggregateUserPermissions,
                    permissions: newPermissions
                });
            });
        } catch (error) {
            snackbar.enqueueSnackbar((error as Error).message, { variant: "error" });
        } finally {
            setIsUpdatingResourcePermission(false);
        }
    };

    return { userIdToAggregateUserPermissionsMap, isUpdatingData, onSharePermissions, onRemoveUserPermissions, onUpdateUserPermissions, refetch };
};