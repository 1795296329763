import { APPBAR_ID } from "../../components/sidebar/Sidebar";

export class ElementFocusManager {
    private locationElement: Element | null = null;
    private issueElement: Element | null = null;

    constructor() {
        this.scrollToElement = this.scrollToElement.bind(this);
    }

    public scrollToElement() {
        const appBarElement: HTMLElement | null = document.getElementById(APPBAR_ID);
        const appBarHeight: number = appBarElement?.offsetHeight ?? 0;
        const issueToScrollTo = this.getIssueElement();
        const locationToScrollTo = this.getLocationElement();
        if (issueToScrollTo) {
            const elementTop: number = issueToScrollTo.getBoundingClientRect().top;
            window.scrollTo({
                top: elementTop + window.scrollY - appBarHeight,
                //@ts-ignore
                behavior: "instant"
            });
            return;
        }
        if (locationToScrollTo) {
            const elementTop: number = locationToScrollTo.getBoundingClientRect().top;
            window.scrollTo({
                top: elementTop + window.scrollY - appBarHeight,
                //@ts-ignore
                behavior: "instant"
            });
            return;
        }
    }
    
    public setLocationElement(element: Element) {
        this.locationElement = element;
    }

    public resetLocationElement() {
        this.locationElement = null;
    }

    public setIssueElement(element: Element) {
        this.issueElement = element;
    }

    public resetIssueElement() {
        this.issueElement = null;
    }

    private getLocationElement() {
        return this.locationElement;
    }
    
    private getIssueElement() {
        return this.issueElement;
    }
}

export const elementFocusManager = new ElementFocusManager();