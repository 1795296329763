import {
    AttachmentType,
    PermissionResourceType
} from "../../models";
import {
    Box,
    Card,
    CardContent,
    List,
    Typography
} from "@mui/material";

import { RecordingListItem } from "./RecordingListItem";
import globalStyle from "../../assets/style/globalStyle";
import { useFetchAttachments } from "../../lib/attachment/hooks/useFetchAttachments";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const RecordingList = () => {
    const { propertyId } = useParams<{ propertyId: string; }>();
    const { attachments } = useFetchAttachments({ id: propertyId, type: PermissionResourceType.PROPERTY });

    const globalClasses = globalStyle();

    const videoAttachments = useMemo(() => {
        return attachments?.filter((attachment) => attachment.type === AttachmentType.VIDEO && !!attachment.key);
    }, [attachments]);

    return (
        !videoAttachments || videoAttachments?.length === 0 ? (
            <></>
        ) : (
            <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="h6">Meeting Recordings</Typography>
                <Card className={globalClasses.listContainerWithBorder}>
                    <CardContent sx={{ p: "0px !important" }}>
                        <List disablePadding>
                            {videoAttachments?.map((attachment, index) => (
                                <RecordingListItem
                                    key={attachment.id}
                                    attachment={attachment}
                                    divider={index < videoAttachments.length - 1}
                                />
                            ))}
                        </List>
                    </CardContent>
                </Card>
            </Box>
        )
    );
};