import {
    Box,
    IconButton,
    InputAdornment,
    TextField
} from '@mui/material';
import {
    useEffect,
    useMemo,
    useRef
} from 'react';

import SearchCriteriaFactory from '../../lib/search/SearchCriteriaFactory';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import { searchCriteriaState } from '../../lib/search/SearchCriteriaState';
import theme from '../../assets/style/theme';
import { useLocation } from "react-router-dom";
import { useRecoilState } from 'recoil';

const SearchBar = () => {
    const SUPPORTED_PAGE_PATHS = ["/properties", "/team", "/admin"];
    const [searchCriteria, setSearchCriteria] = useRecoilState(searchCriteriaState);
    const searchFieldRef = useRef<HTMLInputElement>();
    const location = useLocation();

    const showSearchBar = useMemo(() => {
        return SUPPORTED_PAGE_PATHS.some(path => location.pathname === path);
    }, [location.pathname]);

    useEffect(() => {
        resetSearchCriteria();
    }, [location]);

    function resetSearchCriteria(): void {
        if (searchFieldRef?.current?.value) {
            searchFieldRef.current.value = '';
        }
        setSearchCriteria(SearchCriteriaFactory.getAllResultsCriteria());
    }

    const handleChange = debounce(event => {
        setSearchCriteria(prev => {
            return SearchCriteriaFactory.create(prev.globalSearch, event.target.value);
        });
    }, 300);

    if (!showSearchBar) {
        return (<></>);
    };

    return (
        <Box
            ml="auto"
            display="flex"
        >
            <TextField
                variant='outlined'
                size="small"
                placeholder='Search'
                InputProps={{
                    sx: {
                        "color": "text.secondary",
                        backgroundColor: "secondary.main",
                        px: 0,
                        "& .MuiInputBase-input": {
                            px: searchCriteria.keywords.length > 0 ? 1 : 0,
                            transition: theme.transitions.create('width'),
                            width: searchCriteria.keywords.length > 0 ? "20ch" : "0px",
                            '&:focus': {
                                px: 1,
                                width: "20ch",
                            },
                        }
                    },
                    endAdornment:
                        <InputAdornment position="end" sx={{ mx: 0 }}>
                            <IconButton onClick={() => searchFieldRef.current?.focus()}>
                                <SearchIcon fontSize="inherit" />
                            </IconButton>
                        </InputAdornment>
                }}
                inputRef={searchFieldRef}
                onChange={handleChange}
            />
        </Box>
    );
};

export default SearchBar;
