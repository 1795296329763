import {
    HubCallback,
    HubClass
} from "@aws-amplify/core/lib-esm/Hub";

import EventListener from "./EventListener";

export default class HubBackedEventListener implements EventListener {
    private readonly hub: HubClass;
    private readonly hubEventHandler: HubCallback;
    private readonly channel;

    constructor(
        channel: string,
        hub: HubClass,
        hubEventHandler: HubCallback
    ) {
        this.channel = channel;
        this.hub = hub;
        this.hubEventHandler = hubEventHandler;
    }

    public listen(): void {
        this.hub.listen(this.channel, this.hubEventHandler);
    }

    public stopListening(): void {
        this.hub.remove(this.channel, this.hubEventHandler);
    }
}