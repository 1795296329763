import { DataStore } from "aws-amplify";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { DataStoreAttachmentRecordDAO } from "./DataStoreAttachmentRecordDAO";

export default class DataStoreAttachmentRecordDAOFactory {
    private static readonly INSTANCE = new DataStoreAttachmentRecordDAO(
        ClientLoggerFactory.getClientLogger("DataStoreAttachmentRecordDAO"),
        DataStore
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}