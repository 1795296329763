import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const CollapseAllIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="M8.001 16h8V8h-8v8zm1-7h6v6h-6V9z"
        className="primary"
      />
      <path
        fill="currentColor"
        d="M2.005 6.005v2h5l1-1v-5h-2v2.59l-4.3-4.3-1.41 1.41 4.3 4.3zM22.005 8.005v-2h-2.6l4.3-4.3-1.41-1.41-4.29 4.29v-2.58h-2v5l1 1zM2.005 16.005v2h2.58l-4.29 4.29 1.41 1.41 4.3-4.3v2.6h2v-5l-1-1zM22.005 18.005v-2h-5l-1 1v5h2v-2.59l4.29 4.29 1.41-1.41-4.29-4.29z"
        className="accent"
      />
    </g>
  </svg>
);
export default withCustomIcon(CollapseAllIcon);
