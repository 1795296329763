import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const InfoCloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 1c2.404 0 4.665.931 6.365 2.622C20.064 5.311 21 7.555 21 9.942s-.936 4.631-2.635 6.32c-1.078 1.072-1.859 1.691-3.135 2.1l-.493.35L12 22.34l-2.737-3.628-.493-.35c-1.277-.41-2.058-1.029-3.135-2.1C3.936 14.573 3 12.328 3 9.942s.936-4.631 2.635-6.32A8.967 8.967 0 0 1 12 1m0-1a9.995 9.995 0 0 0-7.07 2.913 9.897 9.897 0 0 0 0 14.058c1.161 1.154 2.074 1.874 3.535 2.343L12 24l3.535-4.686c1.461-.469 2.374-1.189 3.535-2.343a9.897 9.897 0 0 0 0-14.058A9.995 9.995 0 0 0 12 0z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="m16.243 7.172-1.415-1.415L12 8.586 9.172 5.757 7.757 7.172 10.586 10l-2.829 2.828 1.415 1.415L12 11.414l2.828 2.829 1.415-1.415L13.414 10z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(InfoCloseIcon);
