import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    useMediaQuery
} from "@mui/material";
import {
    ImageCarousel,
    ImageCarouselProps
} from "./ImageCarousel";

import CloseIcon from "@mui/icons-material/Close";
import Swiper from "swiper";
import { grey } from "@mui/material/colors";
import { isDesktop } from "react-device-detect";
import theme from "../../assets/style/theme";
import { useState } from "react";

export type ImageCarouselInstanceProps<T> = Pick<
    ImageCarouselProps<T>,
    "showAddButton" | "showContextMenu" | "showNavigation" | "showTimestamp" | "showEditButton" |
    "enableDropZone" | "swiperProps" | "objectFit" | "reverseButtonOrder"
>;

type ExpandableImageCarouselProps<T> = {
    imageDTOs: Array<T> | undefined;
    fetchStatus?: "idle" | "error" | "loading" | "success";
    thumbnailImageCarouselConfiguration?: ImageCarouselInstanceProps<T>;
    fullScreenImageCarouselConfiguration?: ImageCarouselInstanceProps<T>;
    onImagesUpload?: (images: Array<Blob>, resize: boolean) => Promise<void>;
    onDeleteImage?: (imageDTO: T) => Promise<void>;
    getImageUrl: (imageDTO: T) => (string | Promise<string>);
    getImageTimestamp?: (imageDTO: T) => number | undefined;
    getImageKey: (imageDTO: T) => string;
    reverseButtonOrder?: boolean;
};

export const ExpandableImageCarousel = <T,>(props: ExpandableImageCarouselProps<T>) => {
    const { imageDTOs, fetchStatus, getImageKey, getImageTimestamp, getImageUrl, onImagesUpload, onDeleteImage, reverseButtonOrder } = props;
    const [isFullScreenViewOpen, setIsFullScreenViewOpen] = useState<boolean>(false);
    const [thumbnailSwiperInstance, setThumbnailSwiperInstance] = useState<Swiper | null>(null);
    const [fullScreenSwiperInstance, setFullScreenSwiperInstance] = useState<Swiper | null>(null);

    const thumbnailImageCarouselConfiguration: Partial<ImageCarouselInstanceProps<T>> = {
        objectFit: "cover",
        showAddButton: true,
        showContextMenu: true,
        showNavigation: isDesktop,
        showTimestamp: true,
        showEditButton: true,
        enableDropZone: true,
        ...props.thumbnailImageCarouselConfiguration,
        reverseButtonOrder: reverseButtonOrder,
        swiperProps: {
            loop: true,
            pagination: {
                type: imageDTOs && imageDTOs?.length > 10 ? "fraction" : "bullets",
                clickable: true
            },
            ...props.thumbnailImageCarouselConfiguration?.swiperProps
        },
    };

    const fullScreenImageCarouselConfiguration: Partial<ImageCarouselInstanceProps<T>> = {
        objectFit: "contain",
        showAddButton: false,
        showContextMenu: true,
        showNavigation: isDesktop,
        showTimestamp: true,
        showEditButton: true,
        enableDropZone: false,
        reverseButtonOrder: reverseButtonOrder,
        ...props.fullScreenImageCarouselConfiguration,
        swiperProps: {
            loop: true,
            pagination: {
                type: imageDTOs && imageDTOs?.length > 10 ? "fraction" : "bullets",
                clickable: true
            },
            ...props.fullScreenImageCarouselConfiguration?.swiperProps
        },
    };

    const isScreenSmallerThanMD = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <Box height="100%" width="100%" position="relative">
                <ImageCarousel
                    imageDTOs={imageDTOs}
                    fetchStatus={fetchStatus}
                    swiper={thumbnailSwiperInstance}
                    onImagesUpload={onImagesUpload}
                    onDeleteImage={onDeleteImage}
                    getImageKey={getImageKey}
                    getImageTimestamp={getImageTimestamp}
                    getImageUrl={getImageUrl}
                    {...thumbnailImageCarouselConfiguration}
                    swiperProps={{
                        ...thumbnailImageCarouselConfiguration.swiperProps,
                        onSwiper: setThumbnailSwiperInstance,
                        controller: {
                            control: fullScreenSwiperInstance
                        },
                        ["onDoubleClick"]: () => setIsFullScreenViewOpen(true)
                    }}
                />
            </Box >
            <Dialog
                onClose={() => setIsFullScreenViewOpen(false)}
                open={isFullScreenViewOpen}
                maxWidth="xl"
                fullScreen={isScreenSmallerThanMD}
                PaperProps={{
                    onClick: e => {
                        e.stopPropagation();
                    },
                    sx: {
                        height: "100%",
                        padding: {
                            xs: 0,
                            md: 1
                        }
                    }
                }}
                fullWidth
                keepMounted //keep mounted so that the two carousels can be kept in sync
            >
                <DialogTitle sx={{ m: 0, pt: 4 }} >
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsFullScreenViewOpen(false);
                        }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <ImageCarousel
                        imageDTOs={imageDTOs}
                        fetchStatus={fetchStatus}
                        swiper={fullScreenSwiperInstance}
                        onImagesUpload={onImagesUpload}
                        onDeleteImage={onDeleteImage}
                        getImageKey={getImageKey}
                        getImageTimestamp={getImageTimestamp}
                        getImageUrl={getImageUrl}
                        {...fullScreenImageCarouselConfiguration}
                        swiperProps={{
                            ...fullScreenImageCarouselConfiguration.swiperProps,
                            onSwiper: setFullScreenSwiperInstance,
                            controller: {
                                control: thumbnailSwiperInstance
                            }
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};
