import {
    useRecoilState,
    useResetRecoilState
} from "recoil";

import { TranscriptContextWindowBehaviour } from "../../../lib/meeting/transcript/TranscriptContextWindowBehaviour";
import { transcriptContextWindowAtom } from "./state/AISummarizationStates";
import { useState } from "react";

enum IndexSelectionStep {
    START,
    END,
    DONE
}

export const useChangeTranscriptContextWindow = () => {
    const [transcriptContextWindow, setTranscriptContextWindow] = useRecoilState(transcriptContextWindowAtom);
    const resetTranscriptContextWindow = useResetRecoilState(transcriptContextWindowAtom);
    const [indexSelectionStep, setIndexSelectionStep] = useState(IndexSelectionStep.START);

    const setContextWindowEdge = (selectedTranscriptMessageIndex: number) => {
        switch (indexSelectionStep) {
            case IndexSelectionStep.START:
                setWindowStart(selectedTranscriptMessageIndex);
                return;
            case IndexSelectionStep.END:
                setWindowEnd(selectedTranscriptMessageIndex);
                return;
            default:
                return;
        }
    }

    const setWindowStart = (selectedTranscriptMessageIndex: number) => {
        setTranscriptContextWindow((prev) => {
            return {
                ...prev,
                endIndex: 0,
                startIndex: selectedTranscriptMessageIndex,
                behaviour: TranscriptContextWindowBehaviour.START_DEFINED
            }
        });
        setIndexSelectionStep(IndexSelectionStep.END);
    }

    const setWindowEnd = (selectedTranscriptMessageIndex: number) => {
        let startIndex = transcriptContextWindow.startIndex;
        let endIndex = selectedTranscriptMessageIndex + 1;
        if (selectedTranscriptMessageIndex < transcriptContextWindow.startIndex) {
            // Swap the start and end index if the 'start' is after the 'end'
            startIndex = selectedTranscriptMessageIndex;
            endIndex = transcriptContextWindow.startIndex + 1;
        } 
        setTranscriptContextWindow((prev) => {
            return {
                ...prev,
                startIndex: startIndex,
                endIndex: endIndex,
                behaviour: TranscriptContextWindowBehaviour.STATIC
            }
        });
        setIndexSelectionStep(IndexSelectionStep.DONE);
    }

    const resetContextWindow = () => {
        resetTranscriptContextWindow();
        setIndexSelectionStep(IndexSelectionStep.START);
        return;
    }

    return {setContextWindowEdge, resetContextWindow};
}