import {
    ATTACHMENT_PENDING_UPLOAD_STATUS,
    ATTACHMENT_UPLOADED_STATUS
} from "./AttachmentConstants";
import {
    DataStore,
    SortDirection
} from "aws-amplify";

import { Attachment } from "../../models";
import { AttachmentContent } from "./content/AttachmentContent";
import AttachmentContentDAO from "./content/AttachmentContentDAO";
import AttachmentRecordDAO from "./record/AttachmentRecordDAO";
import AttachmentUploadQueueFactory from "./upload/request/AttachmentUploadQueueFactory";
import AttachmentUploadRequestQueue from "./upload/request/AttachmentUploadRequestQueue";
import ClientLogger from "../logging/ClientLogger";
import { DeleteAttachmentError } from "./errors/DeleteAttachmentError";
import ImageVariant from './ImageVariant';
import Storage from "@aws-amplify/storage";
import { encode as arrayBufferToBase64 } from "base64-arraybuffer";
import { awaitTimeout } from "../util/concurrency/AwaitTimeout";
import localforage from "localforage";

export default class AttachmentDAO {
    private static readonly ATTACHMENT_DELETE_ATTEMPT: string = "AttachmentDeleteAttempt";
    private static readonly ATTACHMENT_DELETE_FAILURE: string = "AttachmentDeleteFailure";

    private logger: ClientLogger;
    private readonly attachmentContentDAO: AttachmentContentDAO;
    private readonly attachmentRecordDAO: AttachmentRecordDAO;
    private readonly attachmentUploadQueue: AttachmentUploadRequestQueue;

    constructor(
        logger: ClientLogger,
        attachmentContentDAO: AttachmentContentDAO,
        attachmentRecordDAO: AttachmentRecordDAO,
        attachmentUploadQueue: AttachmentUploadRequestQueue
    ) {
        this.logger = logger;
        this.attachmentContentDAO = attachmentContentDAO;
        this.attachmentRecordDAO = attachmentRecordDAO;
        this.attachmentUploadQueue = attachmentUploadQueue;
    }

    public async getAttachmentsByParentId(
        parentId: string
    ): Promise<Array<Attachment>> {
        const attachments: Array<Attachment> = await DataStore.query(Attachment, attachment => attachment.parentId("eq", parentId));
        return attachments;
    }

    public async getAttachmentByParentIdWithLimitAndSortDirection(
        parentId: string,
        sortDirection: SortDirection,
        limit: number
    ): Promise<Array<Attachment>> {
        return await DataStore.query(Attachment, attachment => attachment.parentId("eq", parentId), {
            sort: s => s.createdAt(SortDirection[sortDirection]),
            limit: limit
        });
    }

    public async getAttachmentURLsByAttachments(
        attachments: Array<Attachment>,
        variant: string
    ): Promise<Array<string>> {
        const attachmentKeys: Array<string> = attachments.map(attachment => attachment.key as string);
        return this.getAttachmentURLsByAttachmentKeys(attachmentKeys, variant);
    }

    public async getAttachmentURLsByAttachmentKeys(
        attachmentKeys: Array<string>,
        variant: string
    ): Promise<Array<string>> {
        const attachmentUrls: Array<string> = [];
        for (let key of attachmentKeys) {
            const attachmentUrl = await this.getAttachmentURLByAttachmentKey(key, variant) as string;
            attachmentUrls.push(attachmentUrl);
        }
        return attachmentUrls;
    }

    public async getAttachmentURLByAttachmentKey(
        attachmentKey: string,
        variant: string | undefined
    ): Promise<string | null> {
        if (!attachmentKey) {
            return null;
        }
        const key = variant ? (attachmentKey + variant) : attachmentKey;
        try {
            const attachmentContent: AttachmentContent = await this.attachmentContentDAO.getAttachmentContentByAttachmentKey(attachmentKey, variant);
            return "data:image/png;base64," + arrayBufferToBase64(attachmentContent.data);
        } catch (error) {
            this.logger.warn(
                `Failed to retrieve the Attachment URL with attachment key: ${key}`,
                error,
                ["AttachmentURLRetrievalFailure"]
            );
            // TODO: Implement a mechanism to allow clients to specify the fallback behaviour instead
            if (variant) {
                return this.getAttachmentURLByAttachmentKey(attachmentKey, undefined);
            } else {
                return null;
            }
        }
    }

    public async getThumbnail(
        parentId: string,
        limit: number
    ): Promise<string | undefined | null> {
        const attachments: Array<Attachment> = await this.attachmentRecordDAO.listMostRecentByParentIdWithLimit(
            parentId, 
            limit
        );
        if (attachments.length === 0) {
            return undefined;
        }

        try {
            const attachmentUrl: string = await Promise.race([
                this.getAttachmentURLByAttachmentKey(
                    attachments[0].key as string,
                    ImageVariant.SMALL // Using SMALL instead of THUMBNAIL because THUMBNAIL creates black borders on the image
                ),
                awaitTimeout(2000, "Attachment URL fetch timeout.")
            ]) as string;
            return attachmentUrl;
        } catch (error) {
            console.log(error);
            return await this.getAttachmentURLByAttachmentKey(
                attachments[0].key as string,
                ImageVariant.ORIGINAL
            );
        }
    }

    public async save(
        attachment: Attachment,
        file: any,
        base64Url: string
    ): Promise <void> {

        if (attachment.status === ATTACHMENT_PENDING_UPLOAD_STATUS) {

            // Race-condition guard: DataStore updates sometimes take a while to process, so let's
            // make sure that the Attachment entry does not exist yet.
            const attachments = await DataStore.query(Attachment, a => a.id("eq", attachment.id));
            if (attachments.length > 0) {
                return;
            }
            // Save the attachment metadata.

            const savedAttachment: Attachment = await DataStore.save(attachment);
            // Populate the local cache with the attachment contents.
            localforage.setItem(savedAttachment.key as string, {
                file: file,
                url: base64Url
            });

            // Attempt to upload the attachment.
            await this.uploadAttachment(savedAttachment, file);
        }
    }

    private async uploadAttachment(
        attachment: Attachment,
        file: any
    ): Promise<void> {
        const key = attachment.key as string;
        Storage.put(key, file, {
            contentType: "image/png"
        }).then(async function() {
            const attachments = await DataStore.query(Attachment, a => a.id("eq", attachment.id));
            await DataStore.save(Attachment.copyOf(attachments[0], updated => {
                updated.status = ATTACHMENT_UPLOADED_STATUS;
            }));
        }).catch (async function(error) {
            // This is broken, can't access `this` from this context
            // this.logger.warn (
            //     "Error occurred while uploading the attachment, will retry.",
            //     error
            //     [LoggerCommonTags.ATTACHMENT_UPLOAD_FAILURE]
            // );
        });
    }

    public async deleteAttachment(
        id: string
    ) {
        try {
            this.logger.info(
                `Deleting Attachment: ${id}`,
                undefined,
                [AttachmentDAO.ATTACHMENT_DELETE_ATTEMPT]
            );
            const attachment: Attachment = await this.attachmentRecordDAO.getById(id);
            if (!attachment || !attachment.key) {
                throw new Error("Attachment Key not found");
            }
            await this.attachmentRecordDAO.delete(id);
            await this.attachmentUploadQueue.removeAttachmentFromUploadQueue(id);
            await localforage.removeItem(attachment.key);
        } catch (error) {
            const message = "Failed to delete attachment"
            this.logger.error(
                message,
                error,
                [AttachmentDAO.ATTACHMENT_DELETE_FAILURE]
            )
            throw new DeleteAttachmentError(message);
        }
    }
}