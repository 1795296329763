import {
    Issue,
    IssueStatus,
    Location,
    PermissionResourceType
} from "../../../models";
import {
    MeetingStatus,
    useMeetingStatus
} from "amazon-chime-sdk-component-library-react";
import {
    defaultRecordToPropertyAssociationModeAtom,
    isEditModeByDesignElementIdentifierAtomFamily,
    locationByLocationIdentifierSelectorFamily,
    recentlyCreatedRecordIds
} from "../../design/document/state/DocumentState";
import {
    locationDefaultNameFactorySelectorFamily,
    numberOfLocationsCreatedAtomFamily
} from "../../design/document/state/LocationNamingState";

import { ChildrenExpandState } from "../../design/document/state/enum/ChildrenExpandState";
import { ContextAwareIdentifier } from "../../design/document/ContextAwareIdentifier";
import { CreateLocationStateError } from "../../design/document/state/error/location/CreateLocationStateError";
import DataStoreLocationDAOFactory from "../dao/datastore/DataStoreLocationDAOFactory";
import LocationDAO from "../dao/LocationDAO";
import LocationDefaultNameFactory from "../name/LocationDefaultNameFactory";
import { ModelType } from "../../design/document/ModelType";
import { RecordToPropertyAssociationMode } from "../../design/document/state/RecordToPropertyAssociationMode";
import { StateContext } from "../../design/document/state/StateContext";
import { fetchPermissionsForResource } from "../../permission/state/ResourcePermissionRecoilState";
import { isAiAssistedNamingEnabledAtom } from "../../../components/chime/hooks/state/AISummarizationStates";
import { issueDefaultNameFactorySelectorFamily } from "../../design/document/state/IssueNamingState";
import { useCreateIssue } from "../../issue/hook/useCreateIssue";
import { usePopulateLocationFromTranscript } from "./usePopulateLocationFromTranscript";
import { useRecoilCallback } from "recoil";
import { useSetChildrenExpandState } from "../../design/hooks/useSetChildrenExpandState";

const locationDAO: LocationDAO = DataStoreLocationDAOFactory.getInstance();

export const useCreateLocation = (context: StateContext) => {
    const { createIssue } = useCreateIssue(context);
    const { setModelChildrenExpandState } = useSetChildrenExpandState(context);
    const { populateLocationFromTranscript } = usePopulateLocationFromTranscript(context);
    const meetingStatus = useMeetingStatus();
    const isMeetingActive = meetingStatus === MeetingStatus.Succeeded || meetingStatus === MeetingStatus.Reconnecting;

    const createLocation = useRecoilCallback(({ snapshot, set }) => async (
        location: Location,
        createChildren: boolean = true,
        inEditMode: boolean = true
    ): Promise<Location> => {
        const releaseSnapshot = snapshot.retain();
        try {
            const createdInSolution: boolean = context === StateContext.SOLUTION_AUTHORING_CONTENT;
            let locationToCreate: Location = { ...location, createdInSolution };
            const recordToPropertyAssociationMode = await snapshot.getLoadable(defaultRecordToPropertyAssociationModeAtom).contents as RecordToPropertyAssociationMode;
            if (recordToPropertyAssociationMode === RecordToPropertyAssociationMode.DO_NOT_ASSOCIATE) {
                locationToCreate = { ...locationToCreate, propertyId: undefined };
            }
            const createdLocation = await locationDAO.create(locationToCreate);
            if (createChildren) {
                const issueNameFactory = await snapshot.getPromise(issueDefaultNameFactorySelectorFamily(context));
                const defaultIssue = new Issue({
                    name: await issueNameFactory!.getName(),
                    status: IssueStatus.NONE,
                    locationId: createdLocation.id,
                    propertyId: createdLocation.propertyId
                });
                await createIssue(defaultIssue);
            }
            await fetchPermissionsForResource(createdLocation.id, PermissionResourceType.LOCATION, set);
            const locationIdentifier = new ContextAwareIdentifier(createdLocation.id, context, ModelType.LOCATION);
            set(locationByLocationIdentifierSelectorFamily(locationIdentifier), createdLocation);
            set(recentlyCreatedRecordIds, (prevValue: Set<string>) => {
                const copy = new Set(prevValue);
                copy.add(createdLocation.id);
                return copy;
            });
            if (inEditMode) {
                set(isEditModeByDesignElementIdentifierAtomFamily(locationIdentifier), true);
            }
            set(numberOfLocationsCreatedAtomFamily(context), (original) => {
                return original + 1;
            });
            setModelChildrenExpandState(ModelType.LOCATION, createdLocation.id, ChildrenExpandState.EXPAND_CHILDREN);
            return createdLocation;
        } catch (error) {
            throw new CreateLocationStateError(
                `Error occurred while trying to Create State for Location with id: ${location.id}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    }, [context, setModelChildrenExpandState]);

    const createDefaultLocation = useRecoilCallback(({ snapshot }) => async (
        propertyId: string,
        createChildren: boolean = true,
    ) => {
        if (isMeetingActive) {
            const isAiAssistedNamingEnabled = await snapshot.getPromise(isAiAssistedNamingEnabledAtom);
            if (isAiAssistedNamingEnabled) {
                const defaultLocation: Location = new Location({
                    propertyId,
                    name: "Pending",
                });
                const location = await createLocation(
                    defaultLocation,
                    createChildren,
                    true
                );
                populateLocationFromTranscript(location);
                return location;
            }
        }
        const locationNameFactory = snapshot.getLoadable(locationDefaultNameFactorySelectorFamily(context)).contents as LocationDefaultNameFactory;
        const defaultLocation: Location = new Location({
            propertyId,
            name: await locationNameFactory!.getName(),
        });
        return await createLocation(defaultLocation, createChildren);
    }, [context, createLocation, populateLocationFromTranscript]);

    return { createLocation, createDefaultLocation };
};