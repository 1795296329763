import {
    atom,
    selector
} from "recoil";
import {
    propertyIdInFocusAtom,
    stateContextInFocusAtom
} from "../ui/InFocusRecoilStates";

import { SidebarDrawerConfiguration } from "./SidebarDrawerConfiguration";
import { StateContext } from "../design/document/state/StateContext";
import { isDesktop } from "react-device-detect";

export const sidebarDrawerConfigurationsAtom = atom<SidebarDrawerConfiguration>({
    key: "sidebarDrawerConfigurationsAtom",
    default: {
        displayValidationButton: false,
        displayIssueFilteringButton: false
    }
});

export const showUserOnboardingWizardAtom = atom<boolean>({
    key: "showUserOnboardingWizardAtom",
    default: false
});

export const eligibilityCheckErrorAtom = atom<boolean>({
    key: "eligibilityCheckErrorAtom",
    default: false
});

export const isLogoutWarningPromptOpenAtom = atom<boolean>({
    key: "isLogoutWarningPromptOpenAtom",
    default: false
});

export const showQuickAccessKeysSelector = selector<boolean>({
    key: "showQuickAccessKeysSelector",
    get: ({ get }) => {
        const propertyIdInFocus = get(propertyIdInFocusAtom);
        const contextInFocus = get(stateContextInFocusAtom);
        return propertyIdInFocus != null && !isDesktop &&
            (contextInFocus === StateContext.INSPECTION || contextInFocus === StateContext.SOLUTION_AUTHORING_CONTENT);
    }
});

export const showVideoCallAssistantSelector = selector<boolean>({
    key: "showVideoCallAssistantSelector",
    get: ({ get }) => {
        const contextInFocus = get(stateContextInFocusAtom);
        return contextInFocus === StateContext.INSPECTION;
    }
});

export const isLiveInspectionDrawerOpenAtom = atom<boolean>({
    key: "isLiveInspectionDrawerOpenAtom",
    default: false
});

export const isMeetingDrawerOpenAtom = atom<boolean>({
    key: "isMeetingDrawerOpenAtom",
    default: false
});
