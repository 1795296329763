import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const ShareGroupIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="m11.467 17.247 1.891.646L12.857 16h-.728c-.066.13-.127.255-.197.388-.146.28-.306.569-.465.859zM14.17 11.249l-.259.092c-.134.759-.419 1.649-.844 2.659h2.937l-1.834-2.751zM17.672 7.885l-2.887-2.159-.883 3.495zM2.962 15.923c.349.669.76 1.408 1.259 2.261a81.826 81.826 0 0 0 2.253 3.573c.187.286.364.547.53.79.167-.244.343-.504.526-.783a80.525 80.525 0 0 0 2.261-3.585c.495-.848.907-1.587 1.255-2.254 1.3-2.481 1.959-4.326 1.959-5.487 0-3.55-2.692-6.438-6-6.438s-6 2.888-6 6.438c-.001 1.161.658 3.006 1.957 5.485zM7.004 6.5c1.933 0 3.5 1.57 3.5 3.5 0 1.94-1.567 3.5-3.5 3.5s-3.5-1.56-3.5-3.5c0-1.93 1.567-3.5 3.5-3.5z"
        className="accent"
      />
      <path
        fill="currentColor"
        d="m16.196 18.86.323-.946.947.323-.323.947zM14.305 18.216l.323-.946.947.323-.324.946zM17.004 13h3v1h-3zM17.004 11h3v1h-3z"
        className="primary"
      />
      <path d="M21.323.225 10.561 4.04A6.652 6.652 0 0 0 7.004 3c-3.867 0-7 3.334-7 7.438 0 1.391.742 3.413 2.072 5.95.383.735.816 1.505 1.281 2.301a82.03 82.03 0 0 0 2.284 3.622c.269.411.522.779.751 1.111.139.193.237.333.286.411a.382.382 0 0 0 .326.167.382.382 0 0 0 .326-.166c.049-.079.147-.219.286-.411.228-.333.481-.7.751-1.111a84.05 84.05 0 0 0 2.085-3.297l7.938 2.71L20.344 16h1.66V8.471l1.992-.706-2.673-7.54zM9.791 18.179a80.525 80.525 0 0 1-2.261 3.585c-.183.28-.359.539-.526.783a46.224 46.224 0 0 1-.53-.79 80.644 80.644 0 0 1-2.253-3.573 54.785 54.785 0 0 1-1.259-2.261c-1.299-2.479-1.958-4.325-1.958-5.486 0-3.55 2.692-6.438 6-6.438s6 2.888 6 6.438c0 1.161-.659 3.007-1.959 5.487a53.907 53.907 0 0 1-1.254 2.255zm7.976 2.275-6.798-2.321c.172-.299.339-.596.499-.886.159-.29.319-.579.465-.86.069-.133.131-.258.197-.388h3.989l-.221.646.946.323.323-.946-.068-.022h2.188l-1.52 4.454zM21.004 15H12.62c.162-.345.312-.679.447-1 .425-1.01.71-1.9.843-2.659l.259-.092L20.513 9h.491v6zm-7.009-4.75a7.902 7.902 0 0 0-.093-1.028c-.276-1.778-1.141-3.344-2.382-4.46l9.194-3.259 2.005 5.655-8.724 3.092z" />
      <path
        fill="currentColor"
        d="m18.28 6.609.943-.334.334.942-.943.334zM20.165 5.94l.942-.334.334.942-.942.335zM17.611 4.723l2.828-1.002.334.943-2.827 1.002zM7.004 13.5c1.933 0 3.5-1.56 3.5-3.5 0-1.93-1.567-3.5-3.5-3.5s-3.5 1.57-3.5 3.5c0 1.94 1.567 3.5 3.5 3.5zm0-6c1.379 0 2.5 1.122 2.5 2.5 0 1.379-1.121 2.5-2.5 2.5a2.503 2.503 0 0 1-2.5-2.5c0-1.378 1.122-2.5 2.5-2.5z"
        className="primary"
      />
    </g>
  </svg>
);
export default withCustomIcon(ShareGroupIcon);
