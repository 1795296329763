import CreateWorkTypeError from "./error/CreateWorkTypeError";
import CreateWorkTypeHandler from "./CreateWorkTypeHandler";
import CreateWorkTypeRequest from "./CreateWorkTypeRequest";
import CreateWorkTypeResponse from "./CreateWorkTypeResponse";
import WorkTypeDAO from "../../WorkTypeDAO";
import WorkTypeDTO from "../../DTO/WorkTypeDTO";
import { WorkTypeGroup } from "../../../../models";
import WorkTypeGroupDAO from "../../group/WorkTypeGroupDAO";

export default class CreateWorkTypeHandlerImpl implements CreateWorkTypeHandler {
    private readonly workTypeDAO: WorkTypeDAO;
    private readonly workTypeGroupDAO: WorkTypeGroupDAO;
  
    constructor(
        workTypeDAO: WorkTypeDAO,
        workTypeGroupDAO: WorkTypeGroupDAO
    ) {
        this.workTypeDAO = workTypeDAO;
        this.workTypeGroupDAO = workTypeGroupDAO;
    }
  
    public async handle(
        request: CreateWorkTypeRequest
    ): Promise<CreateWorkTypeResponse> {
        try {
            const createdWorkTypeGroup: WorkTypeGroup = await this.workTypeGroupDAO.create(request.isDiscoverable);
            const workTypeDtoToCreate: WorkTypeDTO = {
                ...request.workType,
                groupId: createdWorkTypeGroup.id,
                version: createdWorkTypeGroup.latestWorkTypeVersion,
            };
            const workTypeCreated: WorkTypeDTO = await this.workTypeDAO.create(workTypeDtoToCreate);
            return { 
                workType: workTypeCreated,
                workTypeGroup: createdWorkTypeGroup
            } as CreateWorkTypeResponse;
        } catch (error) {
            throw new CreateWorkTypeError(
                `Error occurred while creating new workType: ${request.workType.name}`,
                error as Error
            );
        }
    }
}
