/**
 * Class used to generate a default name for an issue.
 *      NamePrefix - The portion of the name which is shared between different issues, e.g. "Solution Issue"
 *      getIssueNumber - callback method used to get the correct number to use for the new issue
 */
export class IssueDefaultNameFactory {
    private namePrefix: string;
    private getIssueNumber: () => Promise<number>;

    constructor(
        namePrefix: string,
        getIssueNumber: () => Promise<number>,
    ) {
        this.namePrefix = namePrefix;
        this.getIssueNumber = getIssueNumber;
    }

    public async getName(): Promise<string> {
        const issueNumber = await this.getIssueNumber();
        return `${this.namePrefix} ${issueNumber}`;
    };
}
