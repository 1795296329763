import { ConsolidatedAttachmentUploadStatus } from "../../attachment/upload/status/ConsolidatedAttachmentUploadStatus";
import { ConsolidatedDataSyncStatus } from "../ConsolidatedDataSyncStatus";
import { DataStoreStatus } from "../DataStoreStatus";
import { SyncReconciliationStatus } from "../reconciliation/SyncReconciliationStatus";
import { consolidatedAttachmentUploadStatusSelector } from "../../attachment/upload/status/state/AttachmentUploadStatusRecoilState";
import { dataStorePropertySyncStatusAtom, dataStoreStatusAtom } from "./DataStoreState";
import { selector } from "recoil";
import { syncReconciliationStatusAtom } from "../reconciliation/state/SyncReconciliationStatusState";

export const consolidatedDataSyncStatusSelector = selector<ConsolidatedDataSyncStatus>({
    key: "consolidatedDataSyncStatusSelector",
    get: ({ get }) => {
        const dataStoreStatus: DataStoreStatus = get(dataStoreStatusAtom);
        const syncReconciliationStatus: SyncReconciliationStatus = get(syncReconciliationStatusAtom);
        const consolidatedAttachmentUploadStatus: ConsolidatedAttachmentUploadStatus = get(consolidatedAttachmentUploadStatusSelector);
        const dataStorePropertySyncStatus: DataStoreStatus = get(dataStorePropertySyncStatusAtom);
        if (dataStoreStatus === DataStoreStatus.NOT_READY || dataStorePropertySyncStatus !== DataStoreStatus.SYNC_COMPLETED) {
            return ConsolidatedDataSyncStatus.SYNCING_FROM_CLOUD;
        }
        if (dataStoreStatus === DataStoreStatus.SYNC_COMPLETED &&
            syncReconciliationStatus === SyncReconciliationStatus.SYNC_COMPLETED &&
            consolidatedAttachmentUploadStatus === ConsolidatedAttachmentUploadStatus.SYNC_COMPLETED) {
            return ConsolidatedDataSyncStatus.NOTHING_TO_SYNC;
        }
        return ConsolidatedDataSyncStatus.SYNCING_TO_CLOUD;
    }
});