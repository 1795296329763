import SolutionContent from "../../design/types/SolutionContent";
import { WorkSpecification } from "../../../models";
import { useFetchWorkTypes } from "../../worktype/hooks/useFetchWorkTypes";
import { useRecoilCallback } from "recoil";

export const useFetchSolutionWorkTypes = () => {
    const { fetchWorkTypes, resetWorkTypeStates } = useFetchWorkTypes();

    const fetchSolutionWorkTypes = useRecoilCallback(({ set }) => async (solution: SolutionContent) => {
        const workSpecifications: Array<WorkSpecification> = Array.from(solution.workSpecificationIdToWorkSpecificationMap?.values() ?? []);
        const workTypeIds: Array<string> = workSpecifications.reduce((array: Array<string>, workSpecification: WorkSpecification) => {
            if (workSpecification.workTypeId && !array.includes(workSpecification.workTypeId)) {
                array.push(workSpecification.workTypeId);
            }
            return array;
        }, new Array<string>());
        await fetchWorkTypes(workTypeIds);
    });

    const resetSolutionWorkTypeStates = () => {
        resetWorkTypeStates();
    };
    
    return { fetchSolutionWorkTypes, resetSolutionWorkTypeStates };
};