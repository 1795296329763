import {
    priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily,
    priceCalculationTypeByProposalItemIdentifierAtomFamily
} from "../../lib/design/bidding/state/v2/ProposalItemStates";
import {
    useEffect,
    useMemo,
    useState
} from "react";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { ModelType } from "../../lib/design/document/ModelType";
import NumberStringConverter from "../../lib/util/NumberStringConverter";
import { PriceCalculationType } from "../../lib/design/bidding/PriceCalculationType";
import { PriceNumberMask } from "../../components/design/document/element/wss/PriceNumberMask";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { TextField } from "@mui/material";
import ValidationResult from "../../lib/util/validation/ValidationResult";
import { validationResultByDesignElementIdentifierSelectorFamily } from "../../lib/design/document/state/DocumentValidationState";

interface PriceBeforeAdjustmentFieldProps {
    proposalItemId: string;
    context: StateContext;
    size?: "small" | "medium";
}

const PriceBeforeAdjustmentField = ({ proposalItemId, context, size }: PriceBeforeAdjustmentFieldProps) => {
    const proposalItemIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(proposalItemId, context, ModelType.PROPOSAL_ITEM);
    }, [proposalItemId, context]);
    const [priceBeforeAdjustment, setPriceBeforeAdjustment] = useRecoilState<number | undefined>(
        priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier)
    );
    const [priceText, setPriceText] = useState<string>(priceBeforeAdjustment?.toString() || "");
    const priceCalculationType = useRecoilValue<PriceCalculationType | undefined>(priceCalculationTypeByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
    const isProposalItemValid = useRecoilValue<ValidationResult>(validationResultByDesignElementIdentifierSelectorFamily(proposalItemIdentifier));
    const onPriceChange = (value: string, save: boolean) => {
        const valueWithoutFormatting: string = value.replace("$", "").replaceAll(",", "");

        setPriceText(valueWithoutFormatting);
        if (save) {
            const valueAsNumber = parseFloat(valueWithoutFormatting);
            if (isNaN(valueAsNumber)) {
                setPriceText("");
                setPriceBeforeAdjustment(undefined);
                return;
            }
            setPriceBeforeAdjustment(valueAsNumber);
        }
    };

    useEffect(() => {
        setPriceText(NumberStringConverter.numberToString(priceBeforeAdjustment, 2, true) || "");
    }, [priceBeforeAdjustment]);

    return (
        <TextField
            disabled={priceCalculationType === PriceCalculationType.WORK_TYPE_UNIT_PRICING}
            error={!isProposalItemValid.isValid}
            inputProps={{ inputMode: "decimal" }}
            value={priceText}
            variant="standard"
            InputProps={{
                inputComponent: PriceNumberMask,
            }}
            onBlur={(event) => onPriceChange(event.target.value, true)}
            onChange={(event) => onPriceChange(event.target.value, false)}
            size={size || "medium"}
        >
        </TextField>
    );
};

export default PriceBeforeAdjustmentField;
