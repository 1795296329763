import {
    Attachment,
    Dimension,
    Issue,
    Location,
    Property,
    WorkSpecification
} from "../../../models";
import {
    DataStore,
    PersistentModel,
    PersistentModelConstructor
} from "@aws-amplify/datastore";

import AttachmentRecordAlreadyExistsError from "../../attachment/record/errors/AttachmentRecordAlreadyExistsError";
import AttachmentRecordDAO from "../../attachment/record/AttachmentRecordDAO";
import ClientLogger from "../../logging/ClientLogger";
import DimensionDAO from '../../dimension/dao/DimensionDAO.d';
import DimensionRecordAlreadyExistsError from "../../dimension/dao/error/DimensionRecordAlreadyExistsError";
import { IssueDAO } from "../../issue/dao/IssueDAO";
import IssueRecordAlreadyExistsError from "../../issue/dao/error/IssueRecordAlreadyExistsError";
import LocationDAO from "../../location/dao/LocationDAO";
import LocationRecordAlreadyExistsError from "../../location/dao/error/LocationRecordAlreadyExistsError";
import PropertyDAO from "../../property/dao/PropertyDAO";
import PropertyRecordAlreadyExistsError from "../../property/dao/error/PropertyRecordAlreadyExistsError";
import Task from "../../util/concurrency/Task";
import WorkScopeSpecificationDAO from "../../design/workscopespecification/dao/WorkScopeSpecificationDAO";
import WorkScopeSpecificationRecordAlreadyExistsError from "../../design/workscopespecification/dao/error/WorkScopeSpecificationRecordAlreadyExistsError";

export class SyncReconciliationWorker implements Task<void> {
    private static readonly START_RECONCILIATION_WORKER = "StartReconciliationWorker";
    private static readonly RECONCILIATION_WORKER_OFFENDING_RECORD = "ReconciliationWorkerOffendingRecord";
    private static readonly RECONCILIATION_WORKER_RECORD_CORRECTED = "StartReconciliationWorkerRecordCorrected";
    private static readonly RECONCILIATION_WORKER_RECORD_CORRECTION_FAILED = "StartReconciliationWorkerRecordCorrectionFailed";
    private static readonly RECONCILIATION_WORKER_RECORD_TOKEN_UPDATE_FAILED = "StartReconciliationWorkerRecordTokenUpdateFailed";

    private readonly attachmentReconciliationDAO: AttachmentRecordDAO;
    private readonly propertyReconciliationDAO: PropertyDAO;
    private readonly locationReconciliationDAO: LocationDAO;
    private readonly issueReconciliationDAO: IssueDAO;
    private readonly workSpecificationDAO: WorkScopeSpecificationDAO;
    private readonly dimensionReconciliationDAO: DimensionDAO;
    private readonly clientLogger: ClientLogger;

    constructor(
        attachmentReconciliationDAO: AttachmentRecordDAO,
        propertyReconciliationDAO: PropertyDAO,
        locationReconciliationDAO: LocationDAO,
        issueReconciliationDAO: IssueDAO,
        workScopeSpecificationDAO: WorkScopeSpecificationDAO,
        dimensionReconciliationDAO: DimensionDAO,
        clientLogger: ClientLogger
    ) {
        this.attachmentReconciliationDAO = attachmentReconciliationDAO;
        this.propertyReconciliationDAO = propertyReconciliationDAO;
        this.locationReconciliationDAO = locationReconciliationDAO;
        this.issueReconciliationDAO = issueReconciliationDAO;
        this.workSpecificationDAO = workScopeSpecificationDAO;
        this.dimensionReconciliationDAO = dimensionReconciliationDAO;
        this.clientLogger = clientLogger;
    }

    public async run(): Promise<void> {
        /**
        this.clientLogger.info(
            "Starting reconciliation worker",
            [],
            [SyncReconciliationWorker.START_RECONCILIATION_WORKER]
        );
        const allAttachment: Array<Attachment> = await DataStore.query(Attachment);
        for (const attachment of allAttachment) {
            if ((attachment as any)._version === undefined) {
                try {
                    this.clientLogger.warn(
                        "Found the offending Attachment record",
                        [attachment],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_OFFENDING_RECORD]
                    );
                    await this.attachmentReconciliationDAO.create(attachment);
                    this.clientLogger.warn(
                        "Corrected the offending Attachment record",
                        [attachment],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_RECORD_CORRECTED]
                    );
                    await this.updateOpaqueReconciliationToken(Attachment, attachment);
                } catch (error) {
                    if (error instanceof AttachmentRecordAlreadyExistsError) {
                        await this.updateOpaqueReconciliationToken(Attachment, attachment);
                        return;
                    }
                    this.clientLogger.error(
                        "Failed Reconcilliation for Attachment record",
                        [error],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_RECORD_CORRECTION_FAILED]
                    );
                    throw error;
                }
            }
        }

        const allProperties: Array<Property> = await DataStore.query(Property);
        for (const property of allProperties) {
            if ((property as any)._version === undefined) {
                try {
                    this.clientLogger.warn(
                        "Found the offending Property record",
                        [property],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_OFFENDING_RECORD]
                    );
                    await this.propertyReconciliationDAO.create(property);
                    this.clientLogger.warn(
                        "Corrected the offending Property record",
                        [property],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_RECORD_CORRECTED]
                    );
                    await this.updateOpaqueReconciliationToken(Property, property);
                } catch (error) {
                    if (error instanceof PropertyRecordAlreadyExistsError) {
                        await this.updateOpaqueReconciliationToken(Property, property);
                        return;
                    }
                    this.clientLogger.error(
                        "Failed Reconcilliation for Property record",
                        [error],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_RECORD_CORRECTION_FAILED]
                    );
                    throw error;
                }
            }
        }

        const allLocations: Array<Location> = await DataStore.query(Location);
        for (const location of allLocations) {
            if ((location as any)._version === undefined) {
                try {
                    this.clientLogger.warn(
                        "Found the offending Location record",
                        [location],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_OFFENDING_RECORD]
                    );
                    await this.locationReconciliationDAO.create(location);
                    this.clientLogger.warn(
                        "Corrected the offending Location record",
                        [location],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_RECORD_CORRECTED]
                    );
                    await this.updateOpaqueReconciliationToken(Location, location);
                } catch (error) {
                    if (error instanceof LocationRecordAlreadyExistsError) {
                        await this.updateOpaqueReconciliationToken(Location, location);
                        return;
                    }
                    this.clientLogger.error(
                        "Failed Reconcilliation for Location record",
                        [error],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_RECORD_CORRECTION_FAILED]
                    );
                    throw error;
                }
            }
        }

        const allIssues: Array<Issue> = await DataStore.query(Issue);
        for (const issue of allIssues) {
            if ((issue as any)._version === undefined) {
                try {
                    this.clientLogger.warn(
                        "Found the offending Issue record",
                        [issue],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_OFFENDING_RECORD]
                    );
                    await this.issueReconciliationDAO.createNewIssue(issue);
                    this.clientLogger.warn(
                        "Corrected the offending Issue record",
                        [issue],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_RECORD_CORRECTED]
                    );
                    await this.updateOpaqueReconciliationToken(Issue, issue);
                } catch (error) {
                    if (error instanceof IssueRecordAlreadyExistsError) {
                        await this.updateOpaqueReconciliationToken(Issue, issue);
                        return;
                    }
                    this.clientLogger.error(
                        "Failed Reconcilliation for Issue record",
                        [error],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_RECORD_CORRECTION_FAILED]
                    );
                    throw error;
                }
            }
        }

        const allWorkScopeSpecifications: Array<WorkSpecification> = await DataStore.query(WorkSpecification);
        for (const workScopeSpecification of allWorkScopeSpecifications) {
            if ((workScopeSpecification as any)._version === undefined) {
                try {
                    this.clientLogger.warn(
                        "Found the offending WorkScopeSpecification record",
                        [workScopeSpecification],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_OFFENDING_RECORD]
                    );
                    await this.workSpecificationDAO.create(workScopeSpecification);
                    this.clientLogger.warn(
                        "Corrected the offending WorkScopeSpecification record",
                        [workScopeSpecification],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_RECORD_CORRECTED]
                    );
                } catch (error) {
                    if (error instanceof WorkScopeSpecificationRecordAlreadyExistsError) {
                        await this.updateOpaqueReconciliationToken(WorkSpecification, workScopeSpecification);
                        return;
                    }
                    this.clientLogger.error(
                        "Failed Reconcilliation for WorkSpecification record",
                        [error],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_RECORD_CORRECTION_FAILED]
                    );
                    throw error;
                }
            }
        }

        const allDimensions: Array<Dimension> = await DataStore.query(Dimension);
        for (const dimension of allDimensions) {
            if ((dimension as any)._version === undefined) {
                try {
                    this.clientLogger.warn(
                        "Found the offending Dimension record",
                        [dimension],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_OFFENDING_RECORD]
                    );
                    await this.dimensionReconciliationDAO.create(dimension);
                    this.clientLogger.warn(
                        "Corrected the offending Dimension record",
                        [dimension],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_RECORD_CORRECTED]
                    );
                } catch (error) {
                    if (error instanceof DimensionRecordAlreadyExistsError) {
                        await this.updateOpaqueReconciliationToken(Dimension, dimension);
                        return;
                    }
                    this.clientLogger.error(
                        "Failed Reconcilliation for Dimension record",
                        [error],
                        [SyncReconciliationWorker.RECONCILIATION_WORKER_RECORD_CORRECTION_FAILED]
                    );
                    throw error;
                }
            }
        }
        */
    }

    private async updateOpaqueReconciliationToken<T extends PersistentModel>(
        modelConstructor: PersistentModelConstructor<T>,
        data: T
    ): Promise<T | undefined> {
        try {
            return await DataStore.save(
                modelConstructor.copyOf(data, (update: any) => {
                    update.opaqueSyncReconciliationToken = Date.now().toString();
                })
            );
        } catch (error) {
            this.clientLogger.error(
                "Failed to update opaqueSyncReconciliationToken",
                [error],
                [SyncReconciliationWorker.RECONCILIATION_WORKER_RECORD_TOKEN_UPDATE_FAILED]
            );
            throw error;
        }
    }
}
