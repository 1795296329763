import {
    ReactNode,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";

import AuthUsernameSupplierFactory from "../../lib/auth/AuthUsernameSupplierFactory";
import { CognitoUser } from "@aws-amplify/auth";
import { DataStore } from "aws-amplify";
import DefaultLocalForageCleanupHandlerFactory from "../../lib/localforage/DefaultLocalForageCleanupHandlerFactory";
import { LastLoggedInUserIdManager } from "../../lib/auth/user/LastLoggedInUserIdManager";
import { LastLoggedInUserIdManagerFactory } from "../../lib/auth/user/LastLoggedInUserIdManagerFactory";
import LocalForageUserEmailCacheFactory from "../../lib/auth/attributes/LocalForageUserEmailCacheFactory";
import { useDataStoreSynchronizer } from "../../lib/datastore/sync/hook/useDataStoreSynchronizer";

interface DataStoreInitializationLayerProps {
    user: CognitoUser;
    children: ReactNode;
}

const lastLoggedInUserIdManager: LastLoggedInUserIdManager = LastLoggedInUserIdManagerFactory.getInstance();

export const DataStoreInitializationLayer = ({ user, children }: DataStoreInitializationLayerProps) => {
    const userAttributeCacheRef = useRef(LocalForageUserEmailCacheFactory.getInstance());
    const localForageCleanupHandlerRef = useRef(DefaultLocalForageCleanupHandlerFactory.getInstance());
    const [startedDataStoreInitialization, setStartedDataStoreInitialization] = useState<boolean>(false);

    const { initialize: initializeDataStore } = useDataStoreSynchronizer();

    const username = useMemo(() => {
        return user.getUsername();
    }, [user]);

    useEffect(() => {
        const initialize = async () => {
            await lastLoggedInUserIdManager.setNewLogin(username);
            const previousUsername = await lastLoggedInUserIdManager.get();
            if (previousUsername !== username) {
                await localForageCleanupHandlerRef.current.clear();
                await DataStore.clear();
            }
            userAttributeCacheRef.current.populate();
            initializeDataStore();
            setStartedDataStoreInitialization(true);
        };
        if (username) {
            initialize();
        }
    }, [username]);

    return (
        <>
            {startedDataStoreInitialization ? children : null}
        </>
    );
};