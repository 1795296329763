import {
    RosterType,
    useMeetingManager,
    useRosterState
} from "amazon-chime-sdk-component-library-react";
import {
    createContext,
    useContext,
    useEffect,
    useState
} from "react";

import { EventObserver } from "amazon-chime-sdk-js";

type RosterContextType = {
    roster: RosterType;
};

const CustomRosterContext = createContext<RosterContextType | null>(null);

/**
 * CustomRosterProvider builds on top of the existing roster provider from ChimeSDK
 * to cache names of attendees even after they leave the meeting. 
 */

export const CustomRosterProvider = ({ children }: { children: React.ReactNode; }) => {
    const meetingManager = useMeetingManager();
    const { roster } = useRosterState();

    const [customRoster, setCustomRoster] = useState<RosterType>(roster);

    useEffect(() => {
        setCustomRoster((prev) => {
            const attendeeIds = Array.from(new Set([...Object.keys(prev), ...Object.keys(roster)]));
            return attendeeIds.reduce((acc: RosterType, attendeeId) => {
                acc[attendeeId] = {
                    ...prev[attendeeId],
                    name: roster[attendeeId]?.name || prev[attendeeId]?.name,
                };
                return acc;
            }, {});
        });
    }, [roster]);

    useEffect(() => {
        const eventDidReceive: EventObserver["eventDidReceive"] = (name, attributes) => {
            if (name === "meetingEnded") {
                setCustomRoster({});
            }
        };
        meetingManager.subscribeToEventDidReceive(eventDidReceive);
        return () => {
            meetingManager.unsubscribeFromEventDidReceive(eventDidReceive);
        };
    }, []);

    return (
        <CustomRosterContext.Provider value={{ roster: customRoster }}>
            {children}
        </CustomRosterContext.Provider>
    );
};

export const useCustomRosterState = () => {
    const context = useContext(CustomRosterContext);
    if (!context) {
        throw new Error("useCustomRosterState must be used within a CustomRosterProvider");
    }
    return context;
};
