import {
    MenuItem,
    Select
} from "@mui/material";

import { MeasurementUnit } from "../../models";

interface MeasurementUnitFieldProps {
    measurementUnit: MeasurementUnit | keyof typeof MeasurementUnit | null | undefined;
    onSaveMeasurementUnit: (MeasurementUnit: MeasurementUnit) => void;
    defaultUnit?: MeasurementUnit;
    size?: "small" | "medium";
}

const MeasurementUnitField = (props: MeasurementUnitFieldProps) => {
    return (
        <Select
            onChange={(e) => props.onSaveMeasurementUnit(e.target.value as MeasurementUnit)}
            variant="standard"
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                },
                //getContentAnchorEl: null
            }}
            value={props.measurementUnit || props.defaultUnit || ""}
            size={props.size || "small"}
            inputProps={{
                sx: {
                    bgcolor: "transparent !important",
                    pb: 0
                }
            }}
            sx={{
                ":before": {
                    borderBottom: "none !important"
                },
                ":after": {
                    borderBottom: "none !important"
                }
            }}
        >
            <MenuItem value={MeasurementUnit.METER}>
                M
            </MenuItem>
            <MenuItem value={MeasurementUnit.CENTIMETER}>
                CM
            </MenuItem>
            <MenuItem value={MeasurementUnit.FOOT}>
                FT
            </MenuItem>
            <MenuItem value={MeasurementUnit.INCH}>
                IN
            </MenuItem>
        </Select>
    );
};
export default MeasurementUnitField;
