import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { LOCAL_FORAGE_UPLOAD_ATTACHMENT_RECORD_TABLE_NAME } from "../../../localforage/LocalForageConstant";
import { LocalForageAttachmentRecordDAO } from "./LocalForageAttachmentRecordDAO";
import localforage from "localforage";

export class LocalForageAttachmentRecordDAOFactory {
    private static readonly LOCALFORAGE_TABLE = localforage.createInstance({ name: LOCAL_FORAGE_UPLOAD_ATTACHMENT_RECORD_TABLE_NAME });

    private static readonly INSTANCE = new LocalForageAttachmentRecordDAO(
        this.LOCALFORAGE_TABLE,
        ClientLoggerFactory.getClientLogger("LocalForageAttachmentRecordDAO")
    );
    
    static getInstance() {
        return this.INSTANCE;
    }
}