import {
    Button,
    Snackbar
} from '@mui/material';
import {
    useEffect,
    useState
} from 'react';

import ClientLogger from '../../lib/logging/ClientLogger';
import ClientLoggerFactory from '../../lib/logging/ClientLoggerFactory';

const UpdateNotificationSnackbar = () => {
    const COMPONENT_NAME = "UpdateNotificationSnackbar"
    const logger: ClientLogger = ClientLoggerFactory.getClientLogger(COMPONENT_NAME);
    const [showReload, setShowReload] = useState(false);

    const listenForUpdateFoundEvent = async () => {
        const registration: ServiceWorkerRegistration | undefined = await navigator.serviceWorker.getRegistration();

        // Worker is already in the process of installing
        const isWorkerInstalling = !!registration?.installing;
        if (isWorkerInstalling) {
            waitForInstallFinished(registration.installing);
            return;
        }

        // Worker has installed and is waiting to activate
        const isWorkerWaitingToActivate = !!registration?.waiting;
        if (isWorkerWaitingToActivate) {
            setShowReload(true);
            return;
        }

        // No worker detected yet, listen for new incoming workers
        registration?.addEventListener('updatefound', () => {
            const installingWorker: ServiceWorker | null = registration?.installing;
            if (installingWorker != null) {
                waitForInstallFinished(installingWorker);
                return;
            }
        });
    };

    const waitForInstallFinished = (installingWorker: ServiceWorker) => {
        if (installingWorker.state === 'installed') {
            setShowReload(true);
            return;
        }
        installingWorker.addEventListener('statechange', () => {
            if (installingWorker.state === 'installed') {
                setShowReload(true);
                return;
            }
        });
    }

    const checkForServiceWorkerUpdate = async () => {
        try {
            const registration = await navigator.serviceWorker.getRegistration();
            await registration?.update();
        } catch (error) {
            logger.error(
                "Error occurred while checking for service worker updates",
                error,
                ["CheckForServiceWorkerUpdateFailure"]
            );
        }
    };

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            listenForUpdateFoundEvent();
            setInterval(() => {
                checkForServiceWorkerUpdate();
            }, 30000);
        }
    }, []);

    const reloadPage = async () => {
        const registration: ServiceWorkerRegistration | undefined = await navigator.serviceWorker.getRegistration();
        const waitingForActivationWorker: ServiceWorker | null | undefined = registration?.waiting;
        if (waitingForActivationWorker) {
            waitingForActivationWorker?.addEventListener('statechange', () => {
                if (waitingForActivationWorker.state === 'activated') {
                    window.location.reload();
                }
            });
            waitingForActivationWorker?.postMessage({ type: 'SKIP_WAITING' }); // Move service worker from waiting -> activate
            setShowReload(false);
            return;
        }
        // there is no waiting worker if service worker is already activated from another tab, so just reload page
        window.location.reload();
    };

    return (
        <Snackbar
                open={showReload}
                message="A new version is available!"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                action={
                    <Button
                            color="secondary"
                            data-cy="reload-button"
                            onClick={reloadPage}
                    >
                        Update
                    </Button>
                }
        />
    );
};

export default UpdateNotificationSnackbar;
