import UsernameSupplier from "./UsernameSupplier";
import localforage from "localforage";

export default class AuthUsernameSupplierCachedDecorator implements UsernameSupplier {
    readonly delegate: UsernameSupplier;
    readonly USERNAME_KEY: string = "username";

    constructor(
        delegate: UsernameSupplier
    ) {
        this.delegate = delegate;
    }

    public async get(): Promise<string> {
        let username: string | null = await localforage.getItem(this.USERNAME_KEY);
        if (!username) {
            username = await this.delegate.get();
            localforage.setItem(this.USERNAME_KEY, username);
        }
        return username;
    }
}