import { isSubscribedToNewResourcesAtom } from "../settings/SettingStates";
import { useAttachmentSubscription } from "./useAttachmentSubscription";
import { useDimensionSubscription } from "./useDimensionSubscription";
import { useEffect } from "react";
import { useIssueSubscription } from "./useIssueSubscription";
import { useLocationSubscription } from "./useLocationSubscription";
import { useParams } from "react-router-dom";
import { usePermissionsSubscription } from "./usePermissionSubscription";
import { useRecoilState } from "recoil";
import { useWorkSpecificationSubscription } from "./useWorkSpecificationSubscription";

export const useLiveSubscription = () => {
    const [isSubscribed, setIsSubscribed] = useRecoilState(isSubscribedToNewResourcesAtom);
    const { subscribe: subscribeToLocations, unsubscribe: unsubscribeFromLocations } = useLocationSubscription();
    const { subscribe: subscribeToIssues, unsubscribe: unsubscribeFromIssues } = useIssueSubscription();
    const { subscribe: subscribeToWorkSpecifications, unsubscribe: unsubscribeFromWorkSpecifications } = useWorkSpecificationSubscription();
    const { subscribe: subscribeToDimensions, unsubscribe: unsubscribeFromDimensions } = useDimensionSubscription();
    const { subscribe: subscribeToAttachments, unsubscribe: unsubscribeFromAttachments } = useAttachmentSubscription();
    const { subscribe: subscribeToPermissions, unsubscribe: unsubscribeFromPermissions } = usePermissionsSubscription();
    const { propertyId } = useParams<{ propertyId: string; }>();

    useEffect(() => {
        if (!isSubscribed) {
            unsubscribe();
            return;
        }
        if (propertyId) {
            subscribe(propertyId);
        }
    }, [propertyId, isSubscribed]);

    useEffect(() => {
        return () => {
            unsubscribe();
        };
    }, []);

    const subscribe = async (propertyId: string) => {
        subscribeToLocations(propertyId);
        subscribeToIssues(propertyId);
        subscribeToWorkSpecifications(propertyId);
        subscribeToAttachments(propertyId);
        subscribeToDimensions(propertyId);
        subscribeToPermissions(propertyId);
    };

    const unsubscribe = () => {
        unsubscribeFromLocations();
        unsubscribeFromIssues();
        unsubscribeFromWorkSpecifications();
        unsubscribeFromAttachments();
        unsubscribeFromDimensions();
        unsubscribeFromPermissions();
    };

    const toggleSubscription = () => {
        if (isSubscribed) {
            setIsSubscribed(false);
            unsubscribe();
        } else {
            setIsSubscribed(true);
            subscribe(propertyId);
        }
    };

    return { isSubscribed, toggleSubscription };
};