import { DataStoreStatus } from "./DataStoreStatus";
import HubEventHandler from "../event/HubEventHandler";
import { HubPayload } from "@aws-amplify/core";
import { SetterOrUpdater } from "recoil";
import { SyncReconciliationManager } from "./reconciliation/SyncReconciliationManager";
import { SyncReconciliationStatus } from "./reconciliation/SyncReconciliationStatus";

export default class DataStoreOutboxStatusEventHandler implements HubEventHandler {
    private static readonly OUTBOX_STATUS_EVENT = "outboxStatus";
    private readonly setDataStoreStatus: SetterOrUpdater<DataStoreStatus>;
    private readonly setSyncReconciliationStatus: SetterOrUpdater<SyncReconciliationStatus>;
    private readonly syncReconciliationManager: SyncReconciliationManager;

    constructor(
        setDataStoreStatus: SetterOrUpdater<DataStoreStatus>,
        setSyncReconciliationStatus: SetterOrUpdater<SyncReconciliationStatus>,
        syncReconciliationManager: SyncReconciliationManager
    ) {
        this.setDataStoreStatus = setDataStoreStatus;
        this.setSyncReconciliationStatus = setSyncReconciliationStatus;
        this.syncReconciliationManager = syncReconciliationManager;
    }

    public async handle(payload: HubPayload): Promise<void> {
        if (payload.event !== DataStoreOutboxStatusEventHandler.OUTBOX_STATUS_EVENT) {
            return;
        }
        if (payload.data?.isEmpty) {
            this.syncReconciliationManager.handle();
            this.setDataStoreStatus(prevDataStoreStatus => {
                if (prevDataStoreStatus === DataStoreStatus.SYNC_IN_PROGRESS) {
                    return DataStoreStatus.SYNC_COMPLETED;
                }
                return prevDataStoreStatus;
            });
            return;
        }
        this.setSyncReconciliationStatus(prevSyncReconciliationStatus => {
            if (prevSyncReconciliationStatus === SyncReconciliationStatus.SYNC_COMPLETED) {
                return SyncReconciliationStatus.NOT_STARTED;
            }
            return prevSyncReconciliationStatus;
        })
        this.setDataStoreStatus(prevDataStoreStatus => {
            if (prevDataStoreStatus === DataStoreStatus.SYNC_COMPLETED) {
                return DataStoreStatus.SYNC_IN_PROGRESS;
            }
            return prevDataStoreStatus;
        });
    }
}
