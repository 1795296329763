import { DefaultSyncReconciliationManager } from "./DefaultSyncReconciliationManager";
import { RateLimitedSyncReconciliationWorkerDecoratorFactory } from "./RateLimitedSyncReconciliationWorkerDecoratorFactory";
import { SetterOrUpdater } from "recoil";
import { SyncReconciliationManager } from "./SyncReconciliationManager";
import { SyncReconciliationStatus } from "./SyncReconciliationStatus";

export class DefaultSyncReconciliationManagerFactory {
    public static create(
        setSyncReconciliationStatus: SetterOrUpdater<SyncReconciliationStatus>
    ): SyncReconciliationManager {
        return new DefaultSyncReconciliationManager(
            setSyncReconciliationStatus,
            RateLimitedSyncReconciliationWorkerDecoratorFactory.getInstance()
        );
    }
}
