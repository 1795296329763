import {
    atom,
    selector
} from "recoil";

import { SystemReadyState } from "./SystemReadyState";
import { isDataStoreReadySelector } from './../../datastore/state/DataStoreState';
import { isUserSessionReadySelector } from '../../userSession/state/UserSessionState';

export const isSystemReadySelector = selector<boolean>({
    key: "isSystemReadySelector",
    get: ({ get }) => {
        const isDataStoreReady: boolean = get(isDataStoreReadySelector);
        const isUserSessionReady: boolean = get(isUserSessionReadySelector);
        return isDataStoreReady && isUserSessionReady;
    }
});

export const systemReadyProgressBarTextSelector = selector<SystemReadyState>({
    key: "systemReadyProgressBarTextSelector",
    get: ({ get }) => {
        const isDataStoreReady: boolean = get(isDataStoreReadySelector);
        return !isDataStoreReady ? SystemReadyState.SYNCING_DATASTORE : SystemReadyState.SET_UP_USER_SESSION;
    }
});

export const systemReadyProgressBarHelperTextAtom = atom<Array<string>>({
    key: "systemReadyProgressBarHelperTextAtom",
    default: []
 });
