import {
    Issue,
    Location,
    PermissionEntityType,
    PermissionResourceType,
    PermissionType
} from "../../../models";

import { IssueDAO } from "../../issue/dao/IssueDAO";
import LocationDAO from "../../location/dao/LocationDAO";
import { ManageResourcePermissionInput } from "../../../API";
import { ManageResourcePermissionRequestBuilder } from "./ManageResourcePermissionRequestBuilder";

export class ShareResourcePermissionRequestBuilder implements ManageResourcePermissionRequestBuilder {

    private readonly locationDAO: LocationDAO;
    private readonly issueDAO: IssueDAO;

    constructor(
        locationDAO: LocationDAO,
        issueDAO: IssueDAO
    ) {
        this.locationDAO = locationDAO;
        this.issueDAO = issueDAO;
    }

    public async create(
        entityId: string,
        entityType: PermissionEntityType,
        resourceType: PermissionResourceType,
        resourceId: string,
        propertyId: string,
        permissions: Array<PermissionType>
    ): Promise<Array<ManageResourcePermissionInput>> {
        if (permissions.length === 0) {
            return [];
        }
        const requests: Array<ManageResourcePermissionInput> = permissions.map((permission) => ({
            entityId: entityId,
            entityType: entityType,
            resourceId: resourceId,
            resourceType: resourceType,
            propertyId: propertyId,
            permissionType: permission
        }));
        switch (resourceType) {
            case PermissionResourceType.LOCATION:
                const propertyPermissions: Array<PermissionType> = permissions.includes(PermissionType.SHARE_PERMISSION) ?
                    [PermissionType.SHARE_PERMISSION, PermissionType.VIEW] : [PermissionType.VIEW];
                const propertyRequests: Array<ManageResourcePermissionInput> = await this.create(entityId, entityType, PermissionResourceType.PROPERTY, propertyId, propertyId, propertyPermissions);
                return propertyRequests.concat(requests);
            case PermissionResourceType.ISSUE:
                const issue: Issue = await this.issueDAO.getIssueById(resourceId);
                const locationId: string = issue.locationId!;
                const locationPermissions: Array<PermissionType> = permissions.includes(PermissionType.SHARE_PERMISSION) ?
                    [PermissionType.SHARE_PERMISSION, PermissionType.VIEW] : [PermissionType.VIEW];
                const locationRequests: Array<ManageResourcePermissionInput> = await this.create(entityId, entityType, PermissionResourceType.LOCATION, locationId, propertyId, locationPermissions);
                return locationRequests.concat(requests);
            default:
                return requests;
        }
    }
}