export default class ImageResizeMetricNames {
    static RESIZE_ATTEMPT = "ImageResizeAttempt";
    static RESIZE_FAILURE = "ImageResizeFailure";
    static REACT_IMAGE_RESIZE_ATTEMPT = "ReactImageResizeAttempt";
    static REACT_IMAGE_RESIZE_IN_PROCESS = "ReactImageResizeInProcess";
    static REACT_IMAGE_RESIZE_DONE= "ReactImageResizeDone";
    static REACT_IMAGE_RESIZE_FAILURE = "ReactImageResizeFailure";
    static REACT_IMAGE_RESIZE_AND_ROTATE_ATTEMPT = "ReactImageResizeAndRotateAttempt";
    static REACT_IMAGE_RESIZE_AND_ROTATE_IN_PROCESS = "ReactImageResizeAndRotateInProcess";
    static REACT_IMAGE_RESIZE_AND_ROTATE_FAILURE = "ReactImageResizeAndRotateFailure";
}
