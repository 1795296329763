import { AttachmentContent } from "../AttachmentContent";
import AttachmentContentDAO from "../AttachmentContentDAO";

export default class CachingAttachmentContentDAO implements AttachmentContentDAO {

    private readonly cloudDelegateDAO: AttachmentContentDAO;
    private readonly localDelegateDAO: AttachmentContentDAO;

    constructor(
        cloudDelegateDAO: AttachmentContentDAO,
        localDelegateDAO: AttachmentContentDAO
    ) {
        this.cloudDelegateDAO = cloudDelegateDAO;
        this.localDelegateDAO = localDelegateDAO;
    }

    public async getAttachmentContentByAttachmentKey(
        key: string,
        variant?: string
    ): Promise<AttachmentContent> {
        try {
            return await this.localDelegateDAO.getAttachmentContentByAttachmentKey(key, variant);
        } catch (error) {
            const attachmentContent: AttachmentContent = await this.cloudDelegateDAO.getAttachmentContentByAttachmentKey(key, variant);
            await this.localDelegateDAO.saveAttachment(key, attachmentContent, variant);
            return attachmentContent;
        }
    }

    public async listAttachmentContentKeys(): Promise<string[]> {
        throw new Error("Method not implemented.");
    }

    public async saveAttachment(
        attachmentKey: string,
        attachmentContent: AttachmentContent,
        variant?: string
    ): Promise<void> {
        await this.localDelegateDAO.saveAttachment(attachmentKey, attachmentContent, variant);
        await this.cloudDelegateDAO.saveAttachment(attachmentKey, attachmentContent, variant);
    }
}
