import {
    API,
    SortDirection,
    graphqlOperation
} from "aws-amplify";
import {
    CreateIssueMutation,
    GetIssueQuery
} from "../../../../API";

import ClientLogger from "../../../logging/ClientLogger";
import CreateIssueError from "../error/CreateIssueError";
import GetIssueByIdError from "../error/GetIssueByIdError";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { IDTokenSupplier } from "../../../auth/IDTokenSupplier";
import { Issue } from "../../../../models";
import { IssueDAO } from "../IssueDAO";
import IssueRecordAlreadyExistsError from "../error/IssueRecordAlreadyExistsError";
import { createIssue } from "../../../../graphql/mutations";
import { getIssue } from "../../../../graphql/queries";

export class GraphQLIssueDAO implements IssueDAO {

    private static readonly CREATE_ATTEMPT_METRIC_NAME = "GraphQLIssueDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "GraphQLIssueDAO.Create.Failure";
    private static readonly GET_BY_ID_ATTEMPT_METRIC_NAME = "GraphQLIssueDAO.GetById.Attempt";
    private static readonly GET_BY_ID_FAILURE_METRIC_NAME = "GraphQLIssueDAO.GetById.Failure";

    private api: typeof API;
    private gqlOperation: typeof graphqlOperation;
    private logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async getIssueById(issueId: string): Promise<Issue> {
        try {
            this.logger.info(
                `Retrieving Issue with id=${issueId}`,
                undefined,
                [GraphQLIssueDAO.GET_BY_ID_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<GetIssueQuery> = await this.api.graphql(
                this.gqlOperation(
                    getIssue,
                    { issueId },
                    authToken)
            ) as GraphQLResult<GetIssueQuery>;
            return result.data?.getIssue!;
        } catch (error) {
            const errorMessage = `Failed to retrieve Issue with id=${issueId}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLIssueDAO.GET_BY_ID_FAILURE_METRIC_NAME]
            );
            throw new GetIssueByIdError(
                errorMessage, {
                cause: error as Error
            });
        }
    }

    public getIssuesByLocationIdWithLimitAndSortDirection(
        locationId: string,
        sortDirection: SortDirection,
        limit: number
    ): Promise<Issue[]> {
        throw new Error("Method not implemented.");
    }

    public getIssuesByPropertyId(propertyId: string): Promise<Issue[]> {
        throw new Error("Method not implemented.");
    }

    public getIssuesByLocationId(locationId: string): Promise<Issue[]> {
        throw new Error("Method not implemented.");
    }

    public async createNewIssue(issue: Issue): Promise<Issue> {
        this.logger.info(
            `Creating Issue with id=${issue.id}`,
            issue,
            [GraphQLIssueDAO.CREATE_ATTEMPT_METRIC_NAME]
        );
        try {
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<CreateIssueMutation> = await this.api.graphql(
                this.gqlOperation(
                    createIssue,
                    { input: issue },
                    authToken
                )
            ) as GraphQLResult<CreateIssueMutation>;
            return result.data?.createIssue!;
        } catch (error) {
            if ((error as any)?.errors && (error as any)?.errors[0].errorType === "ConditionalCheckFailedException") {
                const errorMessage = `Issue record already exists: ${issue.id}`;
                this.logger.warn(
                    errorMessage,
                    error,
                    [GraphQLIssueDAO.CREATE_FAILURE_METRIC_NAME]
                );
                throw new IssueRecordAlreadyExistsError(
                    errorMessage
                );
            }
            const errorMessage = `Unable to create Issue with id=${issue.id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLIssueDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreateIssueError(errorMessage, {
                cause: error as Error
            });
        }
    }

    public editIssue(issueId: string, updatedIssue: Issue): Promise<Issue> {
        throw new Error("Method not implemented.");
    }

    public deleteIssue(issueId: string): Promise<void> {
        throw new Error("Method not implemented.");
    }
}