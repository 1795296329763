import {
    Attachment,
    AttachmentType
} from "../../../models";

import { AttachmentContent } from "../content/AttachmentContent";
import AttachmentContentDAO from '../content/AttachmentContentDAO';
import AttachmentRecordDAO from '../record/AttachmentRecordDAO';
import { ListImageURLsByParentIDOperation } from "./ListImageURLsByParentIDOperation";
import { encode as arrayBufferToBase64 } from "base64-arraybuffer";

export class ListImageURLsByParentIDOperationImpl implements ListImageURLsByParentIDOperation {

    /* Dependencies */
    private readonly attachmentRecordDAO: AttachmentRecordDAO;
    private readonly attachmentContentDAO: AttachmentContentDAO;

    constructor(
        attachmentRecordDAO: AttachmentRecordDAO,
        attachmentContentDAO: AttachmentContentDAO
    ) {
        this.attachmentRecordDAO = attachmentRecordDAO;
        this.attachmentContentDAO = attachmentContentDAO;
    }

    public async handle(parentId: string): Promise<Array<string>> {
        const attachmentRecords: Array<Attachment> = await this.getAttachmentRecords(parentId);
        return this.getImageUrls(attachmentRecords);
    }

    private getAttachmentRecords = async (parentId: string) => {
        const attachments: Array<Attachment> = await this.attachmentRecordDAO.listMostRecentByParentId(parentId);
        return attachments.filter(attachment => !attachment.type || attachment.type === AttachmentType.IMAGE);
    };

    private getImageUrls = async (
        attachmentRecords: Array<Attachment>
    ): Promise<Array<string>> => {
        const imageUrls: Array<string> = [];
        for (const attachmentRecord of attachmentRecords) {
            const attachmentContent: AttachmentContent = await this.attachmentContentDAO.getAttachmentContentByAttachmentKey(attachmentRecord.key!);
            const imageUrl = `data:image/png;base64,${arrayBufferToBase64(attachmentContent.data)}`;
            imageUrls.push(imageUrl);
        }
        return imageUrls;
    };
}
