import {
    CreateLinkRegistryInput,
    CreateLinkRegistryMutation,
    CreateLinkRegistryMutationVariables,
    GetLinkRegistryQuery,
    GetLinkRegistryQueryVariables,
    UpdateLinkRegistryInput,
    UpdateLinkRegistryMutation,
    UpdateLinkRegistryMutationVariables
} from "../../../API";
import GraphQLAPI, {
    GraphQLResult
} from "@aws-amplify/api-graphql";
import {
    createLinkRegistry,
    updateLinkRegistry
} from "../../../graphql/mutations";

import ClientLogger from "../../logging/ClientLogger";
import { CreateLinkRegistryError } from "../error/CreateLinkRegistryError";
import { GetLinkRegistryByIdError } from "../error/GetLinkRegistryByIdError";
import { IDTokenSupplier } from "../../auth/IDTokenSupplier";
import { LinkRegistry } from "../../../models";
import { LinkRegistryDAO } from "./LinkRegistryDAO";
import { UpdateLinkRegistryError } from "../error/UpdateLinkRegistryError";
import { getLinkRegistry } from "../../../graphql/queries";

export class GraphQLLinkRegistryDAO implements LinkRegistryDAO {

    private static readonly CREATE_ATTEMPT_METRIC_NAME = "GraphQLLinkRegistryDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "GraphQLLinkRegistryDAO.Create.Failure";
    private static readonly GET_BY_ID_ATTEMPT_METRIC_NAME = "GraphQLLinkRegistryDAO.GetById.Attempt";
    private static readonly GET_BY_ID_FAILURE_METRIC_NAME = "GraphQLLinkRegistryDAO.GetById.Failure";
    private static readonly UPDATE_ATTEMPT_METRIC_NAME = "GraphQLLinkRegistryDAO.Update.Attempt";
    private static readonly UPDATE_FAILURE_METRIC_NAME = "GraphQLLinkRegistryDAO.Update.Failure";

    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async getById(id: string): Promise<LinkRegistry | undefined> {
        this.logger.info(
            `Getting LinkRegistry record with id=${id}`,
            { id },
            [GraphQLLinkRegistryDAO.GET_BY_ID_ATTEMPT_METRIC_NAME]
        );
        try {
            const authToken: string = await this.idTokenSupplier.get();
            const variables: GetLinkRegistryQueryVariables = {
                id: id
            };
            const result: GraphQLResult<GetLinkRegistryQuery> = await GraphQLAPI.graphql({
                query: getLinkRegistry,
                variables,
                authToken
            }) as GraphQLResult<GetLinkRegistryQuery>;
            return result.data?.getLinkRegistry as LinkRegistry ?? undefined;
        } catch (error) {
            const errorMessage = `Unable to get LinkRegistry record with id=${id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLLinkRegistryDAO.GET_BY_ID_FAILURE_METRIC_NAME]
            );
            throw new GetLinkRegistryByIdError(errorMessage, { cause: error });
        }
    }

    public async create(link: CreateLinkRegistryInput): Promise<LinkRegistry> {
        this.logger.info(
            `Creating LinkRegistry record with id=${link.id}`,
            link,
            [GraphQLLinkRegistryDAO.CREATE_ATTEMPT_METRIC_NAME]
        );
        try {
            const authToken: string = await this.idTokenSupplier.get();
            const variables: CreateLinkRegistryMutationVariables = {
                input: link
            };
            const result: GraphQLResult<CreateLinkRegistryMutation> = await GraphQLAPI.graphql({
                query: createLinkRegistry,
                variables,
                authToken
            }) as GraphQLResult<CreateLinkRegistryMutation>;
            return result.data?.createLinkRegistry as LinkRegistry;
        } catch (error) {
            const errorMessage = `Unable to create LinkRegistry record with id=${link.id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLLinkRegistryDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreateLinkRegistryError(errorMessage, { cause: error });
        }
    }

    public async update(updatedLink: UpdateLinkRegistryInput): Promise<LinkRegistry> {
        this.logger.info(
            `Updating LinkRegistry record with id=${updatedLink.id}`,
            updatedLink,
            [GraphQLLinkRegistryDAO.UPDATE_ATTEMPT_METRIC_NAME]
        );
        try {
            const existingLink: LinkRegistry | undefined = await this.getById(updatedLink.id);
            if (!existingLink) {
                throw new Error(`Unable to update LinkRegistry record with id=${updatedLink.id} because it does not exist`);
            }
            const authToken: string = await this.idTokenSupplier.get();
            const variables: UpdateLinkRegistryMutationVariables = {
                input: {
                    ...updatedLink,
                    _version: (existingLink as any)._version
                }
            };
            const result: GraphQLResult<UpdateLinkRegistryMutation> = await GraphQLAPI.graphql({
                query: updateLinkRegistry,
                variables,
                authToken
            }) as GraphQLResult<UpdateLinkRegistryMutation>;
            return result.data?.updateLinkRegistry as LinkRegistry;
        } catch (error) {
            const errorMessage = `Unable to update LinkRegistry record with id=${updatedLink.id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLLinkRegistryDAO.UPDATE_FAILURE_METRIC_NAME]
            );
            throw new UpdateLinkRegistryError(errorMessage, { cause: error });
        }
    }
}