import {
    AddLocationIcon,
    CancelIcon,
    ShareGroupIcon
} from "../../components/icons";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import {
    PermissionType,
    Property
} from "../../models";
import {
    ProviderContext,
    useSnackbar
} from "notistack";
import {
    currentPageByGroupIdAtomFamily,
    numberOfPagesByGroupIdAtomFamily
} from "../../lib/pagination/PaginationBarState";
import {
    issueFeatureConfigurationByContextAtomFamily,
    locationFeatureConfigurationByContextAtomFamily
} from "../../lib/design/document/state/DocumentState";
import {
    selectAllCheckboxByContextSelectorFamily,
    selectedIssueIdsByContextAtomFamily,
    selectedLocationIdsByContextAtomFamily
} from "../../lib/design/document/state/IssuePickerState";
import {
    useCallback,
    useMemo,
    useRef,
    useState
} from "react";
import {
    useRecoilState,
    useRecoilValue,
    useSetRecoilState
} from "recoil";
import {
    getResetIssueFilter,
    isFilterAppliedAtom
} from "../../lib/issue/state/IssueFilterRecoilState";

import AddressFallbackPropertyNameFactory from "../../lib/util/property/v2/AddressFallbackPropertyNameFactory";
import { BLACK_OUTLINED_BUTTON } from "../../components/general/button/ButtonStyles";
import { CheckBoxValue } from "../../lib/ui/checkbox/CheckBoxValue";
import { ChildrenExpandState } from "../../lib/design/document/state/enum/ChildrenExpandState";
import { DragDropContext } from "react-beautiful-dnd";
import { ExpandableDisplayText } from "../misc/ExpandableDisplayText";
import { IssueFeatureConfiguration } from "../../lib/design/document/state/IssueFeatureConfiguration";
import IssueSettings from "../issue/filter/IssueSettings";
import { LOCATION_PAGINATION_GROUP_ID_PREFIX } from "../../lib/pagination/Constants";
import { ListLocations } from "./ListLocations";
import { LocationFeatureConfiguration } from "../../lib/design/document/state/LocationFeatureConfiguration";
import NumberStringConverter from "../../lib/util/NumberStringConverter";
import { SelectionBehavior } from "../../lib/ui/SelectionBehavior";
import { ShareMultipleResourcesDialog } from "../permission/ShareMultipleResourcesDialog";
import { SolutionAuthoringEnabledPermissionRestrictedControl } from "../../components/general/button/SolutionAuthoringEnabledPermissionRestrictedControl";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { documentSortDirectionAtom } from "../../lib/design/document/state/DocumentSortState";
import globalStyle from "../../assets/style/globalStyle";
import { isSelectionModeAtomFamily } from "../../lib/design/document/state/SelectionModeState";
import { propertyIdToPropertySelectorFamily } from "../../lib/property/state/PropertyRecoilState";
import { proposalItemPriceTotalByContextSelectorFamily } from "../../lib/design/bidding/state/v2/ProposalItemStates";
import theme from "../../assets/style/theme";
import { useCreateLocation } from "../../lib/location/hook/useCreateLocation";
import { useOnDragEnd } from "../../lib/ui/hooks/useOnDragEnd";
import { useSelectResource } from "../../lib/design/document/hooks/useSelectResource";
import { useSetChildrenExpandState } from "../../lib/design/hooks/useSetChildrenExpandState";

export interface LocationManagerProps {
    readonly propertyId: string;
    readonly stateContext: StateContext;
}

const addressFallbackPropertyNameFactory = new AddressFallbackPropertyNameFactory();

const LocationManager = (props: LocationManagerProps) => {
    /* Internal page states */
    const { propertyId, stateContext } = props;
    const propertyPriceTotal = useRecoilValue<number>(proposalItemPriceTotalByContextSelectorFamily(stateContext));
    const property = useRecoilValue<Property | null>(propertyIdToPropertySelectorFamily(propertyId));
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const locationFeatureConfig = useRecoilValue<LocationFeatureConfiguration>(locationFeatureConfigurationByContextAtomFamily(stateContext));
    const issueFeatureConfig = useRecoilValue<IssueFeatureConfiguration>(issueFeatureConfigurationByContextAtomFamily(stateContext));
    const allowToSelectIssue = issueFeatureConfig.selectionBehavior !== SelectionBehavior.DISABLED;
    const allowToSelectLocation = locationFeatureConfig.selectionBehavior !== SelectionBehavior.DISABLED;
    const scrollElementRef = useRef<Element>();
    const { setChildrenExpandStateForAllLocations } = useSetChildrenExpandState(stateContext);
    const [isSelectionMode, setIsSelectionMode] = useRecoilState<boolean>(isSelectionModeAtomFamily(stateContext));
    const { resetSelectedResources } = useSelectResource(stateContext);
    const selectedLocationIds = useRecoilValue(selectedLocationIdsByContextAtomFamily(stateContext));
    const selectedIssueIds = useRecoilValue(selectedIssueIdsByContextAtomFamily(stateContext));
    const [isOpenShareDialog, setIsOpenShareDialog] = useState<boolean>(false);
    const [selectAllCheckBoxState, setSelectAllCheckBoxState] = useRecoilState(selectAllCheckboxByContextSelectorFamily(stateContext));

    const resetIssueFilter = getResetIssueFilter();
    const isFilterApplied = useRecoilValue<boolean>(isFilterAppliedAtom);

    const propertyName = useMemo<string>(() => {
        if (property) {
            return addressFallbackPropertyNameFactory.getName(property);
        }
        return "";
    }, [property]);

    /* Dependencies */
    const globalClasses = globalStyle();
    const snackbar: ProviderContext = useSnackbar();
    const isSizeOverSM = useMediaQuery(theme.breakpoints.up('sm'));
    const setCurrentPage = useSetRecoilState(currentPageByGroupIdAtomFamily(LOCATION_PAGINATION_GROUP_ID_PREFIX + propertyId));
    const numberOfPages = useRecoilValue<number>(numberOfPagesByGroupIdAtomFamily(LOCATION_PAGINATION_GROUP_ID_PREFIX + propertyId));
    const { createDefaultLocation } = useCreateLocation(stateContext);
    const { onDragEnd } = useOnDragEnd(stateContext);
    const documentSortDirection = useRecoilValue(documentSortDirectionAtom);

    const onCreate = useCallback(async () => {
        try {
            setIsCreating(true);
            if (isFilterApplied) {
                snackbar.enqueueSnackbar(
                    "You have filtering enabled, this may hide the newly created group",
                    {
                        variant: "warning",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        },
                        autoHideDuration: 3000,
                        preventDuplicate: true,
                        action: () => (
                            <Button
                                variant="outlined"
                                color="inherit"
                                onClick={() => {
                                    resetIssueFilter();
                                    snackbar.closeSnackbar();
                                }}
                            >
                                Reset Filter
                            </Button>
                        )
                    }
                );
            }
            setCurrentPage(0);
            await createDefaultLocation(propertyId);
            setIsCreating(false);
        } catch (error) {
            snackbar.enqueueSnackbar(
                "Failed to create Group",
                { variant: "error" }
            );
            setIsCreating(false);
        }
    }, [createDefaultLocation, setCurrentPage, numberOfPages, documentSortDirection]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box className={globalClasses.managerLayout}>
                <Typography variant="h6">
                    Groups
                </Typography>
                <Box display="flex" justifyContent="space-between" flexWrap={{ xs: "wrap", sm: "nowrap" }} gap={1}>
                    <Box display="flex" gap={1} justifyContent="space-between" maxWidth="100%" minWidth="0px">
                        <SolutionAuthoringEnabledPermissionRestrictedControl
                            context={stateContext}
                            requiredPermission={PermissionType.CREATE_CHILDREN}
                            resourceId={propertyId}
                        >
                            {(disabled) => (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={onCreate}
                                    disabled={isCreating || disabled}
                                    startIcon={<AddLocationIcon accent={isCreating || disabled ? "disabled" : "primary"} />}
                                    size={isSizeOverSM ? "medium" : "small"}
                                    sx={{
                                        ...BLACK_OUTLINED_BUTTON,
                                        minWidth: { // To prevent the button from wrapping when screen size is small
                                            xs: "110px",
                                            sm: "126px"
                                        }
                                    }}
                                >
                                    Add Group
                                </Button>
                            )}
                        </SolutionAuthoringEnabledPermissionRestrictedControl>
                        <ExpandableDisplayText
                            description={propertyName}
                            typographyProps={{
                                variant: "h6"
                            }}
                            linesToDisplayWhenCollapsed={1}
                        />
                    </Box>
                    <Stack
                        spacing={1}
                        direction="row"
                        maxWidth="100%"
                        minWidth={{ // To prevent the button from wrapping when screen size is small
                            xs: "352px",
                            sm: "386px"
                        }}
                    >
                        {(allowToSelectLocation || allowToSelectIssue) && (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    setIsSelectionMode(true);
                                    setChildrenExpandStateForAllLocations(ChildrenExpandState.EXPAND_CHILDREN, false);
                                }}
                                disabled={isSelectionMode}
                                startIcon={<ShareGroupIcon accent={isSelectionMode ? "disabled" : "primary"} />}
                                sx={{ ...BLACK_OUTLINED_BUTTON }}
                                size={isSizeOverSM ? "medium" : "small"}
                            >
                                Share Group
                            </Button>
                        )}
                        {issueFeatureConfig.displaySettingsMenu &&
                            <IssueSettings
                                context={stateContext}
                                size={isSizeOverSM ? "medium" : "small"}
                            />
                        }
                        {stateContext === StateContext.INSPECTION && (
                            <Box
                                display="flex"
                                gap={1}
                                alignItems="center"
                                justifyContent="space-between"
                                bgcolor="primary.main"
                                color="primary.contrastText"
                                px={1.5}
                                sx={{
                                    borderRadius: "4px",
                                }}
                                minWidth="120px"
                            >
                                <Typography variant="body2">
                                    $
                                </Typography>
                                <Typography variant="h6">
                                    {NumberStringConverter.numberToString(propertyPriceTotal, 0, true)}
                                </Typography>
                            </Box>
                        )}
                    </Stack>
                </Box >
                {
                    isSelectionMode && (
                        <Box
                            sx={{
                                pl: {
                                    xs: 0,
                                    sm: 3
                                }
                            }}
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            gap={1}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectAllCheckBoxState === CheckBoxValue.CHECKED}
                                        indeterminate={selectAllCheckBoxState === CheckBoxValue.INDETERMINATE}
                                        onChange={(event, isChecked) => {
                                            setSelectAllCheckBoxState(isChecked ? CheckBoxValue.CHECKED : CheckBoxValue.UNCHECKED);
                                        }}
                                        size="small"
                                    />
                                }
                                label={
                                    <Typography variant="body2">
                                        Select All
                                    </Typography>
                                }
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<ShareGroupIcon
                                    accent={selectedLocationIds.length === 0 && selectedIssueIds.length === 0 ? "disabled" : "primary"}
                                />}
                                onClick={() => setIsOpenShareDialog(true)}
                                disabled={selectedLocationIds.length === 0 && selectedIssueIds.length === 0}
                            >
                                Share
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                size="small"
                                startIcon={<CancelIcon accent="inherit" />}
                                onClick={() => {
                                    resetSelectedResources();
                                    setIsSelectionMode(false);
                                    setChildrenExpandStateForAllLocations(ChildrenExpandState.COLLAPSE_CHILDREN, false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    )
                }
                <Box ref={scrollElementRef} data-cy="location-list">
                    <ListLocations
                        stateContext={stateContext}
                    />
                </Box>
                <ShareMultipleResourcesDialog
                    context={stateContext}
                    onClose={() => setIsOpenShareDialog(false)}
                    onShareButtonClick={() => {
                        resetSelectedResources();
                        setIsSelectionMode(false);
                        setIsOpenShareDialog(false);
                        setChildrenExpandStateForAllLocations(ChildrenExpandState.COLLAPSE_CHILDREN, false);
                    }}
                    open={isOpenShareDialog}
                />
            </Box >
        </DragDropContext>
    );
};

export default LocationManager;
