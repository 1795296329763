import ClientLogDAO from "./ClientLogDAO";
import ConsoleClientLogDAO from "./ConsoleClientLogDAO";
import { LogSeverityLevel } from "./LogSeverityLevel";
import NoOpClientLogDAO from "./NoOpClientLogDAO";

export default class ClientLogDAOFactory {
    private static readonly NO_OP_INSTANCE: ClientLogDAO = new NoOpClientLogDAO();

    // Not meant for production use. Only use for debugging.
    private static readonly CONSOLE_INSTANCE: ClientLogDAO = new ConsoleClientLogDAO(
        this.NO_OP_INSTANCE,
        [
            // LogSeverityLevel.DEBUG,
            // LogSeverityLevel.INFO,
            LogSeverityLevel.WARN,
            LogSeverityLevel.ERROR
        ]
    );

    static getClientLogDAO(): ClientLogDAO {
        return this.CONSOLE_INSTANCE;
    }
}
