import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8 2h8v1H8z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M24 2v22H0V2h4v1H1v20h22V3h-3V2z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M4 6h10v2H4zM16 6h4v2h-4zM4 10h4v2H4zM4 14h4v2H4zM4 18h4v2H4zM10 10h4v2h-4zM10 14h4v2h-4zM10 18h4v2h-4zM16 10h4v2h-4zM16 14h4v2h-4zM16 18h4v2h-4z"
      className="accent"
    />
    <path
      fill="currentColor"
      d="M5 0h2v4H5zM17 0h2v4h-2z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(CalendarIcon);
