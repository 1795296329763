import {
    Box,
    LinearProgress,
    Typography
} from "@mui/material";

import { GraphQLLinkRegistryDAOFactory } from "../../lib/linkRegistry/dao/GraphQLLinkRegistryDAOFactory";
import { LinkRegistryDAO } from "../../lib/linkRegistry/dao/LinkRegistryDAO";
import { LinkRegistryForm } from "../../components/linkRegistry/LinkRegistryForm";
import { RoundedCard } from "../../componentsV2/misc/RoundedCard";
import globalStyle from "../../assets/style/globalStyle";
import { usePageDefaultStyle } from "../../assets/style/usePageDefaultStyle";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

type CustomerLinkSetUpPageParams = {
    id: string;
};

const linkRegistryDAO: LinkRegistryDAO = GraphQLLinkRegistryDAOFactory.getInstance();

export const CustomerLinkSetUpPage = () => {
    const { id } = useParams<CustomerLinkSetUpPageParams>();
    const { data, refetch, isFetchedAfterMount } = useQuery({
        queryKey: ["linkRegistry", id],
        queryFn: async () => {
            return await linkRegistryDAO.getById(id);
        },
        staleTime: Infinity
    });

    const globalClasses = globalStyle();
    const { pageDefaultStyle } = usePageDefaultStyle();

    return (
        <Box sx={pageDefaultStyle}>
            <Box className={globalClasses.managerLayout}>
                <Typography variant="h4">
                    Customer Link Set Up
                </Typography>
                <RoundedCard>
                    {!isFetchedAfterMount ? <LinearProgress /> : (
                        <LinkRegistryForm
                            id={id}
                            linkRegistry={data}
                            onSaved={async () => { await refetch(); }}
                        />
                    )}
                </RoundedCard>
            </Box>
        </Box>
    );
};