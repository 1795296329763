import {
    ShareMeetingLinkInput,
    ShareMeetingLinkMutationVariables
} from "../../API";

import { AuthIDTokenSupplierFactory } from "../auth/AuthIDTokenSupplierFactory";
import ClientLogger from "../logging/ClientLogger";
import ClientLoggerFactory from "../logging/ClientLoggerFactory";
import GraphQLAPI from "@aws-amplify/api-graphql";
import { IDTokenSupplier } from "../auth/IDTokenSupplier";
import { ShareMeetingLinkClient } from "./ShareMeetingLinkClient";
import { shareMeetingLink } from "../../graphql/mutations";

export class GraphQLShareMeetingLinkClient implements ShareMeetingLinkClient {
    public static readonly SHARE_MEETING_ATTEMPT_METRIC_NAME = "GraphQLShareMeetingLinkClient.SendNotification.Attempt";
    public static readonly SHARE_MEETING_SUCCESS_METRIC_NAME = "GraphQLShareMeetingLinkClient.SendNotification.Success";
    public static readonly SHARE_MEETING_FAILURE_METRIC_NAME = "GraphQLShareMeetingLinkClient.SendNotification.Failure";

    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async shareMeeting(input: ShareMeetingLinkInput): Promise<void> {
        try {
            this.logger.info(
                `Sending notification to users: ${input.recipients.join(', ')}`,
                undefined,
                [GraphQLShareMeetingLinkClient.SHARE_MEETING_ATTEMPT_METRIC_NAME]
            );
            const authToken = await this.idTokenSupplier.get();
            const variables: ShareMeetingLinkMutationVariables = {
                input
            };
            await GraphQLAPI.graphql({
                query: shareMeetingLink,
                variables,
                authToken
            });
            this.logger.info(
                `Successfully sent notification to users: ${input.recipients.join(', ')}`,
                undefined,
                [GraphQLShareMeetingLinkClient.SHARE_MEETING_SUCCESS_METRIC_NAME]
            );
        } catch (error) {
            this.logger.error(
                `Failed to send notification to users: ${input.recipients.join(', ')}`,
                error,
                [GraphQLShareMeetingLinkClient.SHARE_MEETING_FAILURE_METRIC_NAME]
            );
            throw error;
        }
    }
}

export const graphQLShareMeetingLinkClient: ShareMeetingLinkClient = new GraphQLShareMeetingLinkClient(
    ClientLoggerFactory.getClientLogger("GraphQLShareMeetingLinkClient"),
    AuthIDTokenSupplierFactory.getInstance()
);