import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from "@mui/material";
import {
    Dispatch,
    SetStateAction,
    useState
} from "react";

import ClientLogger from "../../lib/logging/ClientLogger";
import ClientLoggerFactory from "../../lib/logging/ClientLoggerFactory";
import { CreateEntityWorkTypeForm } from "./CreateEntityWorkTypeForm";
import LoadingButton from "@mui/lab/LoadingButton";
import WorkTypeDTO from "../../lib/worktype/DTO/WorkTypeDTO";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

type CreateWorkTypeDialogProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    newWorkTypeName: string;
    onCreatedWorkType: (workType: WorkTypeDTO) => void;
};
const logger: ClientLogger = ClientLoggerFactory.getClientLogger("CreateWorkTypeDialog");

export const CreateWorkTypeDialog = (props: CreateWorkTypeDialogProps) => {
    const { open, setOpen, newWorkTypeName, onCreatedWorkType } = props;
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [formik, setFormik] = useState<ReturnType<typeof useFormik>>();
    const snackbars = useSnackbar();

    const onCreateButtonClicked = async () => {
        if (!formik) {
            return;
        }
        try {
            setIsCreating(true);
            const createdWorkType = await formik.submitForm();
            onCreatedWorkType(createdWorkType);
        } catch (error) {
            snackbars.enqueueSnackbar("Failed to create work type", { variant: "error" });
            logger.error(
                "Failed to create work type",
                error,
                ["FAILED_TO_CREATE_WORK_TYPE"]
            );
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle>
                <Typography variant="h5" textAlign="center">
                    Create Work Type
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <CreateEntityWorkTypeForm
                    defaultWorkTypeName={newWorkTypeName}
                    setFormik={setFormik}
                />
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            loading={isCreating}
                            startIcon={<></>}
                            loadingPosition="start"
                            onClick={onCreateButtonClicked}
                        >
                            Create
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => setOpen(false)}
                            disabled={isCreating}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};