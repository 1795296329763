import {
    Box,
    LinearProgress,
    Theme,
    ThemeProvider,
    createTheme
} from "@mui/material";
import {
    memo,
    useEffect,
    useState
} from "react";
import {
    useLocation,
    useParams
} from "react-router-dom";
import {
    useRecoilState,
    useRecoilValue,
    useResetRecoilState
} from "recoil";

import { AwaitPropertiesChildrenSyncedWrapper } from "../../components/sync/AwaitPropertiesChildrenSyncedWrapper";
import Inspection from "../../componentsV2/inspection/Inspection";
import { MEETING_DRAWER_WIDTH } from "../../components/chime/LiveInspectionDrawer";
import { Property } from "../../models";
import PropertyInfo from "../../componentsV2/property/PropertyInfo";
import { PropertyTab } from "../../lib/property/v2/PropertyTab";
import PropertyTabs from "../../componentsV2/property/PropertyTabs";
import TenderingTab from "../../components/tendering/TenderingTab";
import { ViewPropertySolutions } from "../../components/solution/ViewPropertySolutions";
import { WorkTypeFetchWrapper } from "../../components/WorkTypeFetchWrapper";
import { activePropertyTabAtom } from "../../lib/property/state/PropertyTabsRecoilState";
import globalStyle from "../../assets/style/globalStyle";
import { isMeetingDrawerOpenAtom } from "../../lib/sidebar/SidebarRecoilStates";
import { propertyIdToPropertySelectorFamily } from "../../lib/property/state/PropertyRecoilState";
import { usePageDefaultStyle } from "../../assets/style/usePageDefaultStyle";

type PropertyDetailsParams = {
    readonly propertyId: string;
};

const meetingDrawerTheme = (theme: Theme) => createTheme({
    ...theme,
    breakpoints: {
        values: {
            xs: theme.breakpoints.values.xs + MEETING_DRAWER_WIDTH,
            sm: theme.breakpoints.values.sm + MEETING_DRAWER_WIDTH,
            md: theme.breakpoints.values.md + MEETING_DRAWER_WIDTH,
            lg: theme.breakpoints.values.lg + MEETING_DRAWER_WIDTH,
            xl: theme.breakpoints.values.xl + MEETING_DRAWER_WIDTH
        }
    },
});

const PropertyDetails = () => {
    /* Internal page state */
    const { propertyId } = useParams<PropertyDetailsParams>();
    const property = useRecoilValue<Property | null>(propertyIdToPropertySelectorFamily(propertyId));
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    /* PropertyTabs state */
    const [activeTab, setActiveTab] = useRecoilState<PropertyTab>(activePropertyTabAtom);
    const resetActiveTab = useResetRecoilState(activePropertyTabAtom);

    /* Dependencies */
    const urlLocation = useLocation();
    const globalClasses = globalStyle();
    const { pageDefaultStyle } = usePageDefaultStyle();
    const isMeetingDrawerOpen = useRecoilValue(isMeetingDrawerOpenAtom);

    useEffect(() => () => {
        resetActiveTab();
    }, []);

    useEffect(() => {
        if (property && property.id === propertyId) {
            changeActiveTab();
            setIsPageLoading(false);
        }
    }, [property]);

    useEffect(() => {
        changeActiveTab();
    }, [urlLocation]);

    const changeActiveTab = () => {
        const currentTabName: string = urlLocation.pathname.split("/").pop() || "";
        if (currentTabName === PropertyTab.ISSUES) {
            setActiveTab(PropertyTab.ISSUES);
            return;
        }
        if (currentTabName === PropertyTab.SOLUTIONS) {
            setActiveTab(PropertyTab.SOLUTIONS);
            return;
        }
        if (currentTabName === PropertyTab.TENDERING) {
            setActiveTab(PropertyTab.TENDERING);
            return;
        }
        setActiveTab(PropertyTab.DETAILS);
    };

    return (
        <AwaitPropertiesChildrenSyncedWrapper>
            <WorkTypeFetchWrapper>
                {isPageLoading ? (
                    <Box sx={pageDefaultStyle}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <ThemeProvider theme={isMeetingDrawerOpen ? meetingDrawerTheme : theme => theme}>
                        <Box
                            pr={isMeetingDrawerOpen ? `${MEETING_DRAWER_WIDTH}px` : undefined}
                            pb={isMeetingDrawerOpen ? "80px" : undefined}
                        >
                            <Box pt={1}>
                                <PropertyTabs propertyId={propertyId} />
                            </Box>
                            <Box sx={pageDefaultStyle}>
                                <Box className={activeTab !== PropertyTab.DETAILS ? globalClasses.hidden : ""}>
                                    <PropertyInfo propertyId={propertyId} />
                                </Box>
                                <Box className={activeTab !== PropertyTab.ISSUES ? globalClasses.hidden : ""}>
                                    <Inspection propertyId={propertyId} />
                                </Box>
                                <Box className={activeTab !== PropertyTab.SOLUTIONS ? globalClasses.hidden : ""}>
                                    <ViewPropertySolutions />
                                </Box>
                                <Box className={activeTab !== PropertyTab.TENDERING ? globalClasses.hidden : ""}>
                                    <TenderingTab />
                                </Box>
                            </Box>
                        </Box>
                    </ThemeProvider>
                )}
            </WorkTypeFetchWrapper>
        </AwaitPropertiesChildrenSyncedWrapper >
    );
};

export default memo(PropertyDetails);
