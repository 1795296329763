import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { DataStore } from "aws-amplify";
import DataStoreWorkTypeGroupDAO from "./DataStoreWorkTypeGroupDAO";
import WorkTypeGroupDAO from "../WorkTypeGroupDAO";

export default class DataStoreWorkTypeGroupDAOFactory {
    private static readonly INSTANCE: WorkTypeGroupDAO = new DataStoreWorkTypeGroupDAO(
        ClientLoggerFactory.getClientLogger("WorkTypeGroupDAO"),
        DataStore
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}
