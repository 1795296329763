import {
    Box,
    Typography,
    TypographyProps
} from "@mui/material";
import {
    useMemo,
    useState
} from "react";

interface ExpandableDisplayTextProps {
    readonly description: string;
    readonly linesToDisplayWhenCollapsed: number;
    readonly maxLineToDisplayWhenExpanded?: number | undefined;
    readonly typographyProps?: TypographyProps;
}

export const ExpandableDisplayText = (props: ExpandableDisplayTextProps) => {
    const { description, linesToDisplayWhenCollapsed, maxLineToDisplayWhenExpanded, typographyProps } = props;
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const maxLinesDisplayed: number | undefined = useMemo(() => {
        return isExpanded ? maxLineToDisplayWhenExpanded : linesToDisplayWhenCollapsed;
    }, [isExpanded, maxLineToDisplayWhenExpanded, linesToDisplayWhenCollapsed]);

    return (
        <Box onClick={() => setIsExpanded(original => !original)} maxWidth="100%" overflow="hidden">
            <Typography
                {...typographyProps}
                sx={{
                    ...typographyProps?.sx,
                    wordWrap: "break-word",
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: maxLinesDisplayed, 
                    whiteSpace: isExpanded ? "pre-line": "normal"
                }}
            >
                {description}
            </Typography>
        </Box>
    );
};
