import {
    AudioInputDevice,
    VideoInputDevice,
    VoiceFocusModelName
} from "amazon-chime-sdk-js";
import {
    atom,
    selector
} from "recoil";

import { JoinMeetingInfo } from "amazon-chime-sdk-component-library-react/lib/types";
import { MeetingFeatureStatus } from "@aws-sdk/client-chime-sdk-meetings";
import { MeetingSession } from "../../../lib/meeting/chime/type/MeetingSession";
import { localForageEffect } from "../../../lib/design/state/RecoilEffect";

export const meetingSessionAtom = atom<MeetingSession | undefined>({
    key: 'meetingSessionAtom',
    default: undefined,
    effects: [localForageEffect('meetingSessionAtom')]
});

export const joinMeetingInfoSelector = selector<JoinMeetingInfo | undefined>({
    key: 'joinMeetingInfoSelector',
    get: ({ get }) => {
        const meetingSession = get(meetingSessionAtom);
        if (!meetingSession) {
            return undefined;
        }
        return {
            Attendee: meetingSession.attendeeInfo.AttendeeId!,
            Meeting: {
                MeetingFeatures: {
                    Audio: {
                        "EchoReduction": meetingSession.meetingInfo.MeetingFeatures?.Audio?.EchoReduction === MeetingFeatureStatus.AVAILABLE ?
                            "AVAILABLE" : "UNAVAILABLE"
                    }
                }
            }
        }
    }
});

export const voiceFocusSpecNameSelector = selector<VoiceFocusModelName>({
    key: 'voiceFocusSpecName',
    get: ({ get }) => {
        const joinInfo = get(joinMeetingInfoSelector);
        if (
            joinInfo &&
            joinInfo.Meeting?.MeetingFeatures?.Audio?.EchoReduction === 'AVAILABLE'
        ) {
            return "ns_es";
        }
        return "default";
    }
});

export const defaultVideoInputDeviceAtom = atom<VideoInputDevice | undefined>({
    key: 'defaultVideoInputDeviceAtom',
    default: undefined,
    effects: [localForageEffect('defaultVideoInputDeviceAtom')]
});

export const defaultAudioInputDeviceAtom = atom<AudioInputDevice | undefined>({
    key: 'defaultAudioInputDeviceAtom',
    default: undefined,
    effects: [localForageEffect('defaultAudioInputDeviceAtom')]
});

export const defaultAudioOutputDeviceIdAtom = atom<string | undefined>({
    key: 'defaultAudioOutputDeviceIdAtom',
    default: undefined,
    effects: [localForageEffect('defaultAudioOutputDeviceIdAtom')]
});

export const availableMediaDevicesAtom = atom<Array<MediaDeviceInfo>>({
    key: 'availableMediaDevicesAtom',
    default: [],
    effects: [({ setSelf }) => {
        const initializeDevices = async () => {
            // check if audio permission is granted
            //@ts-ignore
            const microphonePermission = await navigator.permissions.query({ name: 'microphone' });
            //@ts-ignore
            const cameraPermission = await navigator.permissions.query({ name: 'camera' });
            if (microphonePermission.state !== 'granted' || cameraPermission.state !== 'granted') {
                try {
                    const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
                    mediaStream.getTracks().forEach(track => track.stop());
                } catch (e) {
                    // Permission denied
                    return;
                }
            }
            const devices = await navigator.mediaDevices.enumerateDevices();
            setSelf(devices);
        };
        initializeDevices();
        navigator.mediaDevices.addEventListener('devicechange', initializeDevices);
        return () => navigator.mediaDevices.removeEventListener('devicechange', initializeDevices);
    }]
});

export const availableAudioInputDevicesSelector = selector<Array<MediaDeviceInfo>>({
    key: 'availableAudioInputDevicesSelector',
    get: ({ get }) => {
        return get(availableMediaDevicesAtom).filter(device => device.kind === 'audioinput');
    }
});

export const availableAudioOutputDevicesSelector = selector<Array<MediaDeviceInfo>>({
    key: 'availableAudioOutputDevicesSelector',
    get: ({ get }) => {
        return get(availableMediaDevicesAtom).filter(device => device.kind === 'audiooutput');
    }
});

export const availableVideoInputDevicesSelector = selector<Array<MediaDeviceInfo>>({
    key: 'availableVideoInputDevicesSelector',
    get: ({ get }) => {
        return get(availableMediaDevicesAtom).filter(device => device.kind === 'videoinput');
    }
});

export const defaultAudioEnabledAtom = atom<boolean>({
    key: 'defaultAudioEnabledAtom',
    default: true,
    effects: [localForageEffect('defaultAudioEnabledAtom')]
});

export const defaultVideoEnabledAtom = atom<boolean>({
    key: 'defaultVideoEnabledAtom',
    default: false,
    effects: [localForageEffect('defaultVideoEnabledAtom')]
});

export const meetingIdAtom = atom<string | undefined>({
    key: 'meetingId',
    default: undefined,
    effects: [({ setSelf, onSet }) => {
        const urlParams = new URLSearchParams(window.location.search);
        const meetingId = urlParams.get('meetingId');
        setSelf(meetingId ?? undefined);
        onSet((newValue, _, isReset) => {
            const currentUrl = new URL(window.location.href);
            if (isReset || !newValue) {
                currentUrl.searchParams.delete('meetingId');
                window.history.replaceState({}, '', currentUrl.toString());
            } else {
                currentUrl.searchParams.set('meetingId', newValue);
                window.history.replaceState({}, '', currentUrl.toString());
            }
        });
    }]
});

export const isJoiningMeetingAtom = atom<boolean>({
    key: 'isJoiningMeetingAtom',
    default: false
});