import {
    dataStoreStatusAtom,
    isDataStoreReadySelector
} from "../../lib/datastore/state/DataStoreState";
import {
    getCleanUpUserSessionStateCallback,
    getSetUpUserSessionStateCallback
} from "../../lib/userSession/state/UserSessionState";
import {
    incompleteAttachmentIdToAttachmentUploadStatusMapAtom,
    uploadCompletedAttachmentIdsAtom
} from "../../lib/attachment/upload/status/state/AttachmentUploadStatusRecoilState";
import {
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    useRecoilValue,
    useSetRecoilState
} from "recoil";

import { AttachmentUploadStatus } from "../../lib/attachment/upload/status/AttachmentUploadStatus";
import AttachmentUploadStatusDAO from "../../lib/attachment/upload/status/AttachmentUploadStatusDAO";
import { CognitoUser } from "@aws-amplify/auth";
import { DataStoreStatus } from "../../lib/datastore/DataStoreStatus";
import { DefaultSyncReconciliationManagerFactory } from "../../lib/datastore/reconciliation/DefaultSyncReconciliationManagerFactory";
import HubBackedAttachmentUploadEventListenerFactory from "../../lib/attachment/upload/status/HubBackedAttachmentUploadEventListenerFactory";
import HubBackedAuthEventListenerFactory from "../../lib/auth/HubBackedAuthEventListenerFactory";
import HubBackedDataStoreEventListenerFactory from "../../lib/datastore/HubBackedDataStoreEventListenerFactory";
import HubBackedDataStoreEventLoggingListenerFactory from "../../lib/datastore/HubBackedDataStoreEventLoggingListenerFactory";
import HubBackedEventListener from "../../lib/event/HubBackedEventListener";
import { LocalForageAttachmentUploadStatusDAOFactory } from "../../lib/attachment/upload/status/LocalForageAttachmentUploadStatusDAOFactory";
import { SyncReconciliationManager } from "../../lib/datastore/reconciliation/SyncReconciliationManager";
import { SyncReconciliationStatus } from "../../lib/datastore/reconciliation/SyncReconciliationStatus";
import { syncReconciliationStatusAtom } from "../../lib/datastore/reconciliation/state/SyncReconciliationStatusState";
import { systemReadyProgressBarHelperTextAtom } from "../../lib/system/state/SystemState";
import { tokenExpireEpochMillisAtom } from "../../lib/auth/state/AuthRecoilState";

interface GlobalStateComponentProps {
    readonly user: CognitoUser;
}
const GlobalStateComponent = (props: GlobalStateComponentProps) => {
    const setDataStoreStatus = useSetRecoilState<DataStoreStatus>(dataStoreStatusAtom);
    const isDataStoreReady = useRecoilValue<boolean>(isDataStoreReadySelector);
    const setSystemReadyProgressBarHelperText = useSetRecoilState<Array<string>>(systemReadyProgressBarHelperTextAtom);

    const setSyncReconciliationStatus = useSetRecoilState<SyncReconciliationStatus>(syncReconciliationStatusAtom);
    const syncReconciliationManagerRef = useRef<SyncReconciliationManager>(DefaultSyncReconciliationManagerFactory.create(setSyncReconciliationStatus));
    const hubBackedDataStoreEventListener = useRef<HubBackedEventListener>(HubBackedDataStoreEventListenerFactory.create(
        setDataStoreStatus,
        setSyncReconciliationStatus,
        syncReconciliationManagerRef.current
    ));
    const hubBackedDataStoreEventLoggingListener = useRef<HubBackedEventListener>(HubBackedDataStoreEventLoggingListenerFactory.create(
        setSystemReadyProgressBarHelperText
    ));
    const setIncompleteAttachmentIdToAttachmentUploadStatus = useSetRecoilState<Map<string, AttachmentUploadStatus>>(incompleteAttachmentIdToAttachmentUploadStatusMapAtom);
    const setUploadCompletedAttachmentIds = useSetRecoilState<string[]>(uploadCompletedAttachmentIdsAtom);
    const hubBackedAttachmentUploadEventListener = useRef<HubBackedEventListener>(HubBackedAttachmentUploadEventListenerFactory.create(
        setIncompleteAttachmentIdToAttachmentUploadStatus,
        setUploadCompletedAttachmentIds
    ));
    const [localForageAttachmentUploadStatusDAO] = useState<AttachmentUploadStatusDAO>(LocalForageAttachmentUploadStatusDAOFactory.getInstance());

    const setTokenExpireEpochMillis = useSetRecoilState<number|undefined>(tokenExpireEpochMillisAtom);
    const hubBackedAuthEventListener = useRef<HubBackedEventListener>(HubBackedAuthEventListenerFactory.create(setTokenExpireEpochMillis));

    const initializeAttachmentKeyToAttachmentUploadStatusMap = async () => {
        const uploadStatusMap = await localForageAttachmentUploadStatusDAO.getUploadStatusForAllAttachments();
        setIncompleteAttachmentIdToAttachmentUploadStatus(uploadStatusMap);
    }

    const setUpUserSessionState = getSetUpUserSessionStateCallback();
    const cleanUpUserSessionState = getCleanUpUserSessionStateCallback();
    const username = useMemo(() => {
        return props.user.getUsername();
    }, [props.user])

    useEffect(() => {
        hubBackedAuthEventListener.current.listen();
        return () => {
            hubBackedAuthEventListener.current.stopListening();
        }
    }, [username]);

    useEffect(() => {
        if (username) {
            initializeAttachmentKeyToAttachmentUploadStatusMap();
            hubBackedDataStoreEventLoggingListener.current.listen();
            hubBackedDataStoreEventListener.current.listen();
            hubBackedAttachmentUploadEventListener.current.listen();
            const expireEpochSeconds = props.user.getSignInUserSession()?.getAccessToken().getExpiration();
            setTokenExpireEpochMillis(expireEpochSeconds == null ? undefined : expireEpochSeconds * 1000);
        }
        return () => {
            hubBackedDataStoreEventListener.current.stopListening();
            hubBackedAttachmentUploadEventListener.current.stopListening();
        };
    }, [username]);

    useEffect(() => {
        if (isDataStoreReady) {
            const initializeUserSession = async () => {
                try {
                    await setUpUserSessionState(props.user.getUsername());
                } catch (error) {
                    console.warn(error);
                }
            }
            initializeUserSession();
            hubBackedDataStoreEventLoggingListener.current.stopListening();
        }
        return () => {
            cleanUpUserSessionState();
        }
    }, [isDataStoreReady]);

    return null;
};

export default GlobalStateComponent;
