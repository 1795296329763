import Comparator from "./Comparator";
import DataSorter from "./DataSorter";
import MergeDataSorter from "./MergeDataSorter";

export default class MergeDataSorterFactory {
    public static createInstance<T>(comparator: Comparator<T>): DataSorter<T> {
        return new MergeDataSorter(
            comparator
        );
    }
}
