import DataStoreIssueDAOFactory from "../../issue/dao/datastore/DataStoreIssueDAOFactory";
import DataStoreLocationDAOFactory from "../../location/dao/datastore/DataStoreLocationDAOFactory";
import { ManageResourcePermissionRequestBuilder } from "./ManageResourcePermissionRequestBuilder";
import { ShareResourcePermissionRequestBuilder } from "./ShareResourcePermissionRequestBuilder";

export class ShareResourcePermissionRequestBuilderFactory {
    private static readonly INSTANCE: ManageResourcePermissionRequestBuilder = new ShareResourcePermissionRequestBuilder(
        DataStoreLocationDAOFactory.getInstance(),
        DataStoreIssueDAOFactory.getInstance()
    );
    public static getInstance(): ManageResourcePermissionRequestBuilder {
        return this.INSTANCE;
    }
}