import AttachmentDAOFactory from "../../../lib/attachment/AttachmentDAOFactory";
import ClientLoggerFactory from "../../../lib/logging/ClientLoggerFactory";
import { useRecoilCallback } from "recoil";
import { useSnackbar } from "notistack";

const logger = ClientLoggerFactory.getClientLogger("useDeleteImages");
const attachmentDAO = AttachmentDAOFactory.getAttachmentDAO();

export const useDeleteAttachment = () => {
    const snackbar = useSnackbar();

    const deleteAttachment = useRecoilCallback(() => async (id: string) => {
        try {
            await attachmentDAO.deleteAttachment(id);
        } catch (error) {
            snackbar.enqueueSnackbar(`Failed to delete image: ${error}`, { variant: "error", });
            logger.warn(
                "Failed to delete images",
                error,
                ["ImageDeletionFailure"]
            );
        }
    }, []);

    return {deleteAttachment};
};