import {
    API,
    graphqlOperation
} from "aws-amplify";
import {
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables,
    CustomSearchOrganizationsQuery,
    CustomSearchUsersQueryVariables,
    GetOrganizationQuery,
    GetOrganizationQueryVariables,
    SearchableOrganizationFilterInput,
    UpdateOrganizationMutationVariables
} from "../../../API";
import {
    Organization,
    OrganizationType
} from "../../../models";
import {
    createOrganization,
    updateOrganization
} from "../../../graphql/mutations";
import {
    customSearchOrganizations,
    getOrganization,
} from './../../../graphql/queries';

import ClientLogger from "../../logging/ClientLogger";
import CreateOrganizationError from "../error/CreateOrganizationError";
import GetOrganizationByIdError from "../error/GetOrganizationByIdError";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { IDTokenSupplier } from "../../auth/IDTokenSupplier";
import OrganizationDAO from "../OrganizationDAO";
import PartialSearchOrganizationInLegalNameError from "../error/PartialSearchOrganizationInLegalNameError";
import ResourceNotFoundError from "../../ResourceNotFoundError";
import SearchByLegalNameOperation from "../SearchByLegalNameOperation";
import { SearchResponse } from "../../util/search/SearchResponse";
import UpdateOrganizationError from "../error/UpdateOrganizationError";
import { UpdateOrganizationMutation } from "../../../API";
import _ from "lodash";

export default class GraphQLOrganizationDAO implements OrganizationDAO, SearchByLegalNameOperation {
    public static ORGANIZATION_SEARCH_ATTEMPT = "GraphQLOrganizationDAO.searchByLegalNameWithLimit.Attempt";
    public static ORGANIZATION_SEARCH_FAILURE = "GraphQLOrganizationDAO.searchByLegalNameWithLimit.Failure";
    public static ORGANIZATION_CREATION_ATTEMPT = "GraphQLOrganizationDAO.Create.Attempt";
    public static ORGANIZATION_CREATION_FAILURE = "GraphQLOrganizationDAO.Create.Failure";
    public static ORGANIZATION_GET_BY_ID_ATTEMPT = "GraphQLOrganizationDAO.GetById.Attempt";
    public static ORGANIZATION_GET_BY_ID_FAILURE = "GraphQLOrganizationDAO.GetById.Failure";
    public static ORGANIZATION_UPDATE_ATTEMPT = "GraphQLOrganizationDAO.Update.Attempt";
    public static ORGANIZATION_UPDATE_FAILURE = "GraphQLOrganizationDAO.Update.Failure";

    private readonly api: typeof API;
    private readonly gqlOperation: typeof graphqlOperation;
    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async create(
        legalName: string,
        organizationType: OrganizationType
    ): Promise<Organization> {
        try {
            this.logger.info(
                `Attempting to create organization: ${legalName}`,
                undefined,
                [GraphQLOrganizationDAO.ORGANIZATION_CREATION_ATTEMPT]
            );
            const variables: CreateOrganizationMutationVariables = {
                input: {
                    legalName: legalName,
                    type: organizationType
                }
            };
            const authToken: string = await this.idTokenSupplier.get();
            const result = await this.api.graphql(graphqlOperation(
                createOrganization,
                variables,
                authToken
            )) as GraphQLResult<CreateOrganizationMutation>;
            return result.data?.createOrganization as Organization;
        } catch (error) {
            this.logger.error(
                `Failed to create organization: ${legalName}`,
                error,
                [GraphQLOrganizationDAO.ORGANIZATION_CREATION_FAILURE]
            );
            throw new CreateOrganizationError("Failed to create organization");
        }
    }

    public async getById(
        organizationId: string
    ): Promise<Organization> {
        try {
            this.logger.info(
                `Attempting to retrieve Organization for: ${organizationId}`,
                undefined,
                [GraphQLOrganizationDAO.ORGANIZATION_GET_BY_ID_ATTEMPT]
            );
            const variables: GetOrganizationQueryVariables = {
                id: organizationId
            };
            const authToken: string = await this.idTokenSupplier.get();
            const result = await this.api.graphql(graphqlOperation(
                getOrganization,
                variables,
                authToken
            )) as GraphQLResult<GetOrganizationQuery>;
            const existingOrganization = result.data?.getOrganization as Organization;
            if (!existingOrganization) {
                throw new ResourceNotFoundError("Organization");
            }
            return existingOrganization;
        } catch (error) {
            this.logger.error(
                `Error occurred while retrieving Organization for: ${organizationId}`,
                error,
                [GraphQLOrganizationDAO.ORGANIZATION_GET_BY_ID_FAILURE]
            );
            throw new GetOrganizationByIdError(`Failed to get Organization by Id: ${organizationId}`);
        }
    }

    public async update(
        organizationId: string,
        editedOrganization: Organization
    ): Promise<Organization> {
        try {
            this.logger.info(
                `Attempting to update organization for: ${organizationId}`,
                undefined,
                [GraphQLOrganizationDAO.ORGANIZATION_UPDATE_ATTEMPT]
            );
            const existingOrganization = await this.getById(organizationId);
            if (!_.isEqual(existingOrganization, editedOrganization)) {
                const variables: UpdateOrganizationMutationVariables = {
                    input: {
                        ...editedOrganization,
                        type: editedOrganization.type ? OrganizationType[editedOrganization.type] : undefined,
                    }
                };
                const authToken: string = await this.idTokenSupplier.get();
                const result = await this.api.graphql(graphqlOperation(
                    updateOrganization,
                    variables,
                    authToken
                )) as GraphQLResult<UpdateOrganizationMutation>;
                return result.data?.updateOrganization as Organization;
            }
            return existingOrganization!;
        } catch (error) {
            this.logger.error(
                `Failed to update organization for: ${organizationId}`,
                error,
                [GraphQLOrganizationDAO.ORGANIZATION_UPDATE_FAILURE]
            );
            throw new UpdateOrganizationError("Failed to update organization");
        }
    }

    public async searchByLegalNameWithLimit(
        searchPhrase: string,
        limit: number,
        nextToken: string | undefined
    ): Promise<SearchResponse<Organization>> {
        try {
            this.logger.info(
                `Search organizations by legal name: ${searchPhrase}. Limit: ${limit}, NextToken: ${nextToken}`,
                undefined,
                [GraphQLOrganizationDAO.ORGANIZATION_SEARCH_ATTEMPT]
            );
            const filterInput: SearchableOrganizationFilterInput = {
                or: [
                    {
                        legalName: {
                            contains: searchPhrase
                        }
                    }
                ]
            };
            const authToken: string = await this.idTokenSupplier.get();
            const gqlOperation = this.gqlOperation(customSearchOrganizations,
                {
                    input: {
                        filter: filterInput,
                        limit: limit,
                        nextToken: nextToken,
                    }
                } satisfies CustomSearchUsersQueryVariables,
                authToken
            );
            const queryResult = await this.api.graphql(gqlOperation) as GraphQLResult<CustomSearchOrganizationsQuery>;
            const organizations = queryResult.data?.customSearchOrganizations?.items as Array<Organization>;
            const newNextToken = queryResult.data?.customSearchOrganizations?.nextToken as string;
            const total = organizations.length;
            return {
                objects: organizations,
                nextToken: newNextToken,
                total: total
            } as SearchResponse<Organization>;
        } catch (error) {
            const errorMessage: string = `Unable to search organizations by legal name: ${searchPhrase}. Limit: ${limit}, NextToken: ${nextToken}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLOrganizationDAO.ORGANIZATION_SEARCH_FAILURE]
            );
            throw new PartialSearchOrganizationInLegalNameError(errorMessage, error as Error);
        }
    }
}