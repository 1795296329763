import {
    API,
    graphqlOperation
} from "aws-amplify";
import {
    CreateWorkTypeGroupMutation,
    GetWorkTypeGroupQuery,
    UpdateWorkTypeGroupMutation
} from './../../../../API.d';
import { createWorkTypeGroup, updateWorkTypeGroup } from "../../../../graphql/mutations";

import ClientLogger from "../../../logging/ClientLogger";
import CreateWorkTypeGroupError from "../errors/CreateWorkTypeGroupError";
import GetWorkTypeGroupByIdError from '../errors/GetWorkTypeGroupByIdError';
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { IDTokenSupplier } from "../../../auth/IDTokenSupplier";
import UpdateWorkTypeGroupError from "../errors/UpdateWorkTypeGroupError";
import { WorkTypeGroup } from "../../../../models";
import WorkTypeGroupDAO from "../WorkTypeGroupDAO";
import WorkTypeGroupMetricNames from "../WorkTypeGroupMetricNames";
import { getWorkTypeGroup } from './../../../../graphql/queries';

export default class GraphQLWorkTypeGroupDAO implements WorkTypeGroupDAO {

    private logger: ClientLogger;
    private gqlOperation: typeof graphqlOperation;
    private api: typeof API;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async create(
        isDiscoverable: boolean
    ): Promise<WorkTypeGroup> {
        try {
            this.logger.info(
                `Creating a new workTypeGroup`,
                undefined,
                [WorkTypeGroupMetricNames.CREATE_ATTEMPT]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<CreateWorkTypeGroupMutation> = await this.api.graphql(
                this.gqlOperation(
                    createWorkTypeGroup,
                    {
                        input: {
                            latestWorkTypeVersion: 0,
                            isDiscoverable: isDiscoverable
                        }
                    },
                    authToken
                )
            ) as GraphQLResult<CreateWorkTypeGroupMutation>;
            return result.data?.createWorkTypeGroup as WorkTypeGroup;
        } catch (error) {
            const errorMessage: string = `Unable to create a new workTypeGroup`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeGroupMetricNames.CREATE_FAILURE]
            );
            throw new CreateWorkTypeGroupError(errorMessage, error as Error);
        }
    }

    public async update(
        id: string,
        workTypeGroup: WorkTypeGroup
    ): Promise<WorkTypeGroup> {
        try {
            this.logger.info(
                `Updating workTypeGroup: ${id}`,
                undefined,
                [WorkTypeGroupMetricNames.UPDATE_ATTEMPT]
            );
            const currentWorkTypeGroup: WorkTypeGroup = await this.getById(workTypeGroup.id);
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<UpdateWorkTypeGroupMutation> = await this.api.graphql(
                this.gqlOperation(
                    updateWorkTypeGroup,
                    {
                        input: {
                            id: workTypeGroup.id,
                            latestWorkTypeVersion: workTypeGroup.latestWorkTypeVersion,
                            isDiscoverable: workTypeGroup.isDiscoverable,
                            _version: (currentWorkTypeGroup as any)._version
                        }
                    },
                    authToken
                )
            ) as GraphQLResult<UpdateWorkTypeGroupMutation>;
            return result.data?.updateWorkTypeGroup as WorkTypeGroup;
        } catch (error) {
            const errorMessage: string = `Unable to update workTypeGroup: ${id}`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeGroupMetricNames.UPDATE_FAILURE]
            );
            throw new UpdateWorkTypeGroupError(errorMessage, error as Error);
        }
    }

    public async getById(
        id: string
    ): Promise<WorkTypeGroup> {
        try {
            this.logger.info(
                `Getting workTypeGroup: ${id}`,
                undefined,
                [WorkTypeGroupMetricNames.GET_BY_ID_ATTEMPT]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const queryResult = await this.api.graphql(
                this.gqlOperation(
                    getWorkTypeGroup,
                    { id: id },
                    authToken
                )
            ) as GraphQLResult<GetWorkTypeGroupQuery>;
            const result = queryResult.data?.getWorkTypeGroup as WorkTypeGroup;
            if (!result) {
                throw new Error('WorkTypeGroup not found');
            }
            return result;
        } catch (error) {
            const errorMessage: string = `Unable to get workTypeGroup: ${id}`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeGroupMetricNames.GET_BY_ID_FAILURE]
            );
            throw new GetWorkTypeGroupByIdError(errorMessage, error as Error);
        }
    }

    public listAll(): Promise<Array<WorkTypeGroup>> {
        throw new Error("Method not implemented.");
    }

    public listDiscoverable(): Promise<Array<WorkTypeGroup>> {
        throw new Error("Method not implemented.");
    }
}
