export default class AttachmentUploadWorkerError extends Error {

    private readonly attachmentId: string;

    constructor(
        attachmentId: string,
        message: string
    ) {
        super(message);
        this.attachmentId = attachmentId;
    }

    public getAttachmentId(): string {
        return this.attachmentId;
    }
}
