import {
    RosterAttendee,
    RosterAttendeeType,
    useRosterState
} from "amazon-chime-sdk-component-library-react";

import { Box } from "@mui/material";

export const AttendeeList = () => {
    const { roster } = useRosterState();
    const attendees = Object.values(roster);
    // Filter out media pipeline participant
    const attendeesToDisplay: Array<RosterAttendeeType> = attendees.filter(({ externalUserId }) =>
        !externalUserId || !externalUserId.startsWith("aws:MediaPipeline")
    );

    return (
        <Box>
            {attendeesToDisplay.map(({ chimeAttendeeId }) => (
                <RosterAttendee
                    key={chimeAttendeeId}
                    attendeeId={chimeAttendeeId}
                />
            ))}
        </Box>
    );
};