import {
    Box,
    Typography
} from "@mui/material";

export const DropFilesHereMessage = () => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
            px={1}
            border="solid 1px lightgray"
            borderRadius={1}
        >
            <Typography variant="body1" align="justify">
                Drop files here
            </Typography>
        </Box>
    );
};