import JSONSerializer from "./JSONSerializer";
import Serializer from "./Serializer";

export default class JSONSerializerFactory {
    static readonly INSTANCE = new JSONSerializer();

    public static getInstance(): Serializer<any, string> {
        return JSONSerializerFactory.INSTANCE;
    }
}
