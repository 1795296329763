import {
    ConverseCommandOutput,
    Message
} from "@aws-sdk/client-bedrock-runtime";
import {
    appendToDescriptionPrompt,
    descriptionGenerationPrompt,
    locationGenerationPrompt,
    titleGenerationPrompt
} from "./prompts/TranscriptSummaryPrompts";

import { TranscriptPromptType } from "./prompts/TranscriptPromptType";
import { handleConverseCommandClient } from "../../../lib/bedrock/GraphQLHandleConverseCommandClient";
import { useRef } from "react";
import { useTranscriptionContext } from "../transcription/ChimeTranscriptionContext";

const model: string = "anthropic.claude-3-haiku-20240307-v1:0";

export const useAISummarizer = () => {
    const {getMessagesInContextWindow, markTranscriptAsUsed} = useTranscriptionContext();
    const getContextWindowMessageRef = useRef(getMessagesInContextWindow);
    getContextWindowMessageRef.current = getMessagesInContextWindow;

    const generateFieldValue = async (promptType: TranscriptPromptType): Promise<string> => {
        switch (promptType) {
            case TranscriptPromptType.ISSUE_TITLE:
                return await generateTitleFromMessage();
            case TranscriptPromptType.ISSUE_DESCRIPTION:
                return await generateDescriptionFromMessage();
            case TranscriptPromptType.GROUP_LOCATION_NAME:
                return await generateLocationFromMessage();
            case TranscriptPromptType.ISSUE_DESCRIPTION_APPEND:
                return await generateDescriptionFromMessage();
            default:
                return "Error";
        }
    }

    const generateTitleFromMessage = async (): Promise<string> => {
        return await sendOneShotMessageWithPrompt([getContextWindowMessagesAsChatCompletionMessage()], titleGenerationPrompt);
    }

    const generateLocationFromMessage = async (): Promise<string> => {
        return await sendOneShotMessageWithPrompt([getContextWindowMessagesAsChatCompletionMessage()], locationGenerationPrompt);
    }

    const generateDescriptionFromMessage = async (): Promise<string> => {
        return await sendOneShotMessageWithPrompt([getContextWindowMessagesAsChatCompletionMessage()], descriptionGenerationPrompt);
    }
    
    const generateTranscriptSummaryAddition = async (currentDescription: string): Promise<string> => {
        const messages = getContextWindowMessagesAsChatCompletionMessage();
        const descriptionMessage: Message = {
            role: "user",
            content: [{
                text: `Summarized description so far: "${currentDescription}"`
            }]
        }
        const fakeAssistantResponse: Message = {
            role: "assistant",
            content: [{
                text: `Understood, and what would you like me to add to this summary?`
            }]
        }
        return await sendOneShotMessageWithPrompt([descriptionMessage, fakeAssistantResponse, messages], appendToDescriptionPrompt);
    }

    const getContextWindowMessagesAsChatCompletionMessage = (): Message => {
        const messages = getContextWindowMessageRef.current();
        markTranscriptAsUsed(messages.map((message) => message.message));
        return {
            role: "user",
            content: messages.map((message) => {
                return {
                    text: message.message
                }
            })
        }
    }

    const sendOneShotMessageWithPrompt = async (messages: Message[], prompt: string): Promise<string> => {
        try {
            const chatCompletion: ConverseCommandOutput = await handleConverseCommandClient.handleConverseCommand({
                modelId: model,
                system: [{ text: prompt }],
                messages: messages
            });
            const response = chatCompletion.output?.message?.content![0].text;
            return response || "Error";
        } catch (error) {
            console.error("Error sending messages: " + error);
            return "Error occurred"
        }
    }

    return {
        generateTitleFromMessage,
        generateDescriptionFromMessage,
        generateTranscriptSummaryAddition,
        generateLocationFromMessage,
        generateFieldValue
    };
};
