import {
    Box,
    Drawer,
    Fab,
    IconButton,
    Theme,
    Typography,
    useMediaQuery
} from "@mui/material";
import {
    LocalVideo,
    useLocalVideo
} from "amazon-chime-sdk-component-library-react";

import { AttendeeList } from "./AttendeeList";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { MeetingControlBar } from "./MeetingControlBar";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PleaseStandByLogo from "../../assets/images/please_stand_by.png";
import { SpeakingOnMuteSnackbar } from "./hooks/SpeakingOnMuteSnackbar";
import { useState } from "react";

export const InspectorMeetingView = () => {
    const { isVideoEnabled } = useLocalVideo();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const isScreenSizeXs = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

    return (
        <Box sx={{ height: '80dvh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
            {isVideoEnabled ? (
                <LocalVideo />
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} width="100%" height="100%">
                    <img src={PleaseStandByLogo} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                </Box>
            )}
            <Drawer
                open={isDrawerOpen}
                anchor="left"
                variant="temporary"
                onClose={() => setIsDrawerOpen(false)}
                PaperProps={{
                    sx: {
                        width: {
                            xs: "100vw",
                            sm: 200,
                            md: 300,
                            lg: 400,
                        }
                    }
                }}
            >
                <Box display="flex" flexDirection="column" gap={2} p={1}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6">
                            Attendees
                        </Typography>
                        <IconButton
                            onClick={() => setIsDrawerOpen(false)}
                            sx={{ marginLeft: "auto" }}
                        >
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Box>
                    <AttendeeList />
                </Box>
            </Drawer>
            <MeetingControlBar />
            <SpeakingOnMuteSnackbar />
            <Fab
                onClick={() => setIsDrawerOpen(prev => !prev)}
                sx={{ position: "fixed", bottom: 80, left: { xs: 8, md: 16 } }}
                size={isScreenSizeXs ? "medium" : "large"}
            >
                <PersonOutlineOutlinedIcon
                    fontSize={isScreenSizeXs ? "small" : "medium"}
                />
            </Fab>
        </Box>
    );
};
