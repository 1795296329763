import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import {
    Proposal,
    Solution,
    SolutionStatus,
    SolutionTenderingType
} from "../../models";
import {
    useMemo,
    useState
} from "react";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ConfirmationPrompt from "../general/ConfirmationPrompt";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import GradingIcon from '@mui/icons-material/Grading';
import { Link } from "react-router-dom";
import { LoadingBackDrop } from "../../componentsV2/misc/LoadingBackDrop";
import NumberStringConverter from "../../lib/util/NumberStringConverter";
import SolutionListItemMenu from "./SolutionListItemMenu";
import { useRemoveSolutionAndProposal } from "../../lib/design/document/hooks/useRemoveSolutionAndProposal";

type SolutionListItemProps = {
    solution: Solution;
    propertyId: string;
    divider: boolean;
    disabled: boolean;
    proposalPrice?: number;
    proposal?: Proposal;
    onRemovedSolution: () => (void | Promise<void>);
};

export const SolutionListItem = ({ solution, proposalPrice, propertyId, divider, disabled, proposal, onRemovedSolution }: SolutionListItemProps) => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [copyingSolution, setCopyingSolution] = useState<boolean>(false);
    const { removeSolutionAndProposal } = useRemoveSolutionAndProposal();

    const url = `/properties/${propertyId}/solutions/${solution.id}${solution.status === SolutionStatus.DRAFT ? "/edit" : "/view"}`;

    const detailText = useMemo(() => {
        const projectNumberText: string = `Project #${solution.serialNumber}`;
        const proposalNumberText: string = ` - Proposal #${proposal?.proposalNumber || "N/A"}`;
        const projectStatusText: string = ` - ${solution.status?.toLowerCase().split("_").map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")}`;
        const proposalPriceText: string = `${proposalPrice != null ?  " - $" + NumberStringConverter.numberToString(proposalPrice, 2, true) : ""}`
        const projectTenderingTypeText: string = ` - ${solution.metadata?.tenderingType === SolutionTenderingType.TENDERED ? "Tendering" : "Sole Source"}`
        if (solution.metadata?.tenderingType === SolutionTenderingType.TENDERED) {
            return `${projectNumberText}${projectStatusText}${projectTenderingTypeText}`;
        }
        return `${projectNumberText}${proposalNumberText}${projectStatusText}${projectTenderingTypeText}${proposalPriceText}`
    }, [solution, proposalPrice]);

    return (
        <>
            <ListItem
                divider={divider}
                disablePadding
                secondaryAction={
                    <SolutionListItemMenu
                        solution={solution}
                        size="small"
                        setCopyingSolution={setCopyingSolution}
                        setShowDeleteConfirmation={setShowDeleteConfirmation}
                    />
                }
            >
                <ListItemButton
                    component={Link}
                    disabled={disabled}
                    to={url}
                >
                    <ListItemIcon>
                        {solution.status === SolutionStatus.DRAFT ? <DesignServicesIcon /> : <GradingIcon />}
                    </ListItemIcon>
                    <ListItemText
                        primary={solution.name}
                        secondary={detailText}
                    />
                    <ChevronRightIcon />
                </ListItemButton>
            </ListItem>
            <ConfirmationPrompt
                promptMessage={`Are you sure you want to delete this solution (${solution.name})?`}
                onConfirmButtonClicked={async () => {
                    await removeSolutionAndProposal(solution.id, (solution as any)._version);
                    await onRemovedSolution();
                }}
                onCancelButtonClicked={() => setShowDeleteConfirmation(false)}
                isVisible={showDeleteConfirmation}
            />
            <LoadingBackDrop
                title="Copying solution..."
                open={copyingSolution}
            />
        </>
    );
};
