import {
    Box,
    Button,
    Divider,
    LinearProgress,
    List,
    ListItem,
    Stack,
    Typography
} from "@mui/material";
import React, {
    useEffect,
    useState,
} from "react";
import {
    allWorkTypeDataGroupsSelector,
    getFetchAllWorkTypesCallback
} from "../../../lib/worktype/state/WorkTypeState";

import AddIcon from "@mui/icons-material/Add";
import DataGroup from "../../../lib/util/data/group/DataGroup";
import NoItemsPlaceholder from "../../../components/NoItemsPlaceholder";
import { RoundedCard } from "../../misc/RoundedCard";
import WorkTypeDTO from "../../../lib/worktype/DTO/WorkTypeDTO";
import { WorkTypeManagementCategoryListItem } from "../WorkTypeManagementCategoryListItem";
import WorkTypeManagementForm from "./WorkTypeManagementForm";
import globalStyle from "../../../assets/style/globalStyle";
import { useCreateWorkType } from "../../../lib/worktype/hooks/useCreateWorkType";
import { useRecoilValue } from "recoil";

const WorkTypeAdminManagement = () => {
    /* Internal page states */
    const workTypeCategories: Array<DataGroup<string, WorkTypeDTO>> = useRecoilValue(allWorkTypeDataGroupsSelector);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [isCreatingWorkType, setIsCreatingWorkType] = useState<boolean>(false);

    /* Dependencies */
    const fetchAllWorkTypes = getFetchAllWorkTypesCallback();
    const globalClasses = globalStyle();
    const { createNewWorkTypeWithDefaultPricing } = useCreateWorkType();

    useEffect(() => {
        const setupWorkTypes = async () => {
            await fetchAllWorkTypes();
            setIsInitialized(true);
        };
        setupWorkTypes();
    }, []);

    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box mt={1}>
                    <Typography variant="h4">
                        Manage Work Types
                    </Typography>
                    <Typography variant="body2">
                        Changes to these WorkTypes will be applied to future usages for ALL users
                    </Typography>
                </Box>
                <Button
                    variant="outlined"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() => setIsCreatingWorkType(true)}
                    disabled={isCreatingWorkType}
                >
                    Create
                </Button>
            </Stack>
            <RoundedCard style={{ marginTop: "10px" }}>
                {!isInitialized ? (
                    <LinearProgress />
                ) : (
                    workTypeCategories.length === 0 && !isCreatingWorkType ? (
                        <Box className={globalClasses.containerWithBorder}>
                            <NoItemsPlaceholder text="No Work Types found." />
                        </Box>
                    ) : (
                        <List disablePadding>
                            {isCreatingWorkType && (
                                <ListItem sx={{ minHeight: "5em" }}>
                                    <Box pl={3} width="100%">
                                        <Typography variant="h6">
                                            Creating a new work type
                                        </Typography>
                                        <WorkTypeManagementForm
                                            workType={{ version: 0 }}
                                            onFormSave={async (workTypeForm: WorkTypeDTO, unitCost: number, unitPrice: number, isDiscoverable: boolean) => {
                                                await createNewWorkTypeWithDefaultPricing(workTypeForm, unitPrice, unitCost, isDiscoverable);
                                                setIsCreatingWorkType(false);
                                            }}
                                            onFormCancel={() => setIsCreatingWorkType(false)}
                                        />
                                    </Box>
                                </ListItem>
                            )}
                            {workTypeCategories?.map((workTypeCategory, index) => {
                                return (
                                    <React.Fragment key={workTypeCategory.key}>
                                        <WorkTypeManagementCategoryListItem
                                            key={workTypeCategory.key}
                                            workTypeCategory={workTypeCategory}
                                        />
                                        {index !== workTypeCategories.length - 1 && <Divider />}
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    )
                )}
            </RoundedCard>
        </Box>
    );
};

export default WorkTypeAdminManagement;
