/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSolutionEvent = /* GraphQL */ `
  mutation CreateSolutionEvent($input: CreateSolutionEventInput!) {
    createSolutionEvent(input: $input)
  }
`;
export const createBidInvitationEvent = /* GraphQL */ `
  mutation CreateBidInvitationEvent($input: CreateBidInvitationEventInput!) {
    createBidInvitationEvent(input: $input)
  }
`;
export const shareResourcePermission = /* GraphQL */ `
  mutation ShareResourcePermission(
    $executionId: ID!
    $input: [ManageResourcePermissionInput]!
  ) {
    shareResourcePermission(executionId: $executionId, input: $input) {
      executionId
      status
      result
    }
  }
`;
export const revokeResourcePermission = /* GraphQL */ `
  mutation RevokeResourcePermission(
    $executionId: ID!
    $input: [ManageResourcePermissionInput]!
  ) {
    revokeResourcePermission(executionId: $executionId, input: $input) {
      executionId
      status
      result
    }
  }
`;
export const resendTemporaryCredentials = /* GraphQL */ `
  mutation ResendTemporaryCredentials(
    $input: ResendTemporaryCredentialsRequest
  ) {
    resendTemporaryCredentials(input: $input)
  }
`;
export const createChimeMeeting = /* GraphQL */ `
  mutation CreateChimeMeeting($name: String!, $role: AttendeeRole!) {
    createChimeMeeting(name: $name, role: $role) {
      meetingInfo
      attendeeInfo
    }
  }
`;
export const joinChimeMeeting = /* GraphQL */ `
  mutation JoinChimeMeeting(
    $meetingId: String!
    $name: String!
    $role: AttendeeRole!
  ) {
    joinChimeMeeting(meetingId: $meetingId, name: $name, role: $role) {
      meetingInfo
      attendeeInfo
    }
  }
`;
export const endChimeMeeting = /* GraphQL */ `
  mutation EndChimeMeeting($meetingId: String!) {
    endChimeMeeting(meetingId: $meetingId)
  }
`;
export const startChimeMeetingRecording = /* GraphQL */ `
  mutation StartChimeMeetingRecording(
    $meetingId: String!
    $attachmentParentResourceType: PermissionResourceType
    $attachmentParentId: String
  ) {
    startChimeMeetingRecording(
      meetingId: $meetingId
      attachmentParentResourceType: $attachmentParentResourceType
      attachmentParentId: $attachmentParentId
    )
  }
`;
export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
      type
      parentId
      parentType
      propertyId
      key
      localCreatedAtTime
      status
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      type
      parentId
      parentType
      propertyId
      key
      localCreatedAtTime
      status
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      type
      parentId
      parentType
      propertyId
      key
      localCreatedAtTime
      status
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBiddingContact = /* GraphQL */ `
  mutation CreateBiddingContact(
    $input: CreateBiddingContactInput!
    $condition: ModelBiddingContactConditionInput
  ) {
    createBiddingContact(input: $input, condition: $condition) {
      id
      email
      ownerEntityId
      contactEntityId
      name
      privateDescription
      publicDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBiddingContact = /* GraphQL */ `
  mutation UpdateBiddingContact(
    $input: UpdateBiddingContactInput!
    $condition: ModelBiddingContactConditionInput
  ) {
    updateBiddingContact(input: $input, condition: $condition) {
      id
      email
      ownerEntityId
      contactEntityId
      name
      privateDescription
      publicDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBiddingContact = /* GraphQL */ `
  mutation DeleteBiddingContact(
    $input: DeleteBiddingContactInput!
    $condition: ModelBiddingContactConditionInput
  ) {
    deleteBiddingContact(input: $input, condition: $condition) {
      id
      email
      ownerEntityId
      contactEntityId
      name
      privateDescription
      publicDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBidInvitation = /* GraphQL */ `
  mutation CreateBidInvitation(
    $input: CreateBidInvitationInput!
    $condition: ModelBidInvitationConditionInput
  ) {
    createBidInvitation(input: $input, condition: $condition) {
      id
      biddingContactId
      tenderId
      ownerEntityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBidInvitation = /* GraphQL */ `
  mutation UpdateBidInvitation(
    $input: UpdateBidInvitationInput!
    $condition: ModelBidInvitationConditionInput
  ) {
    updateBidInvitation(input: $input, condition: $condition) {
      id
      biddingContactId
      tenderId
      ownerEntityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBidInvitation = /* GraphQL */ `
  mutation DeleteBidInvitation(
    $input: DeleteBidInvitationInput!
    $condition: ModelBidInvitationConditionInput
  ) {
    deleteBidInvitation(input: $input, condition: $condition) {
      id
      biddingContactId
      tenderId
      ownerEntityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBid = /* GraphQL */ `
  mutation CreateBid(
    $input: CreateBidInput!
    $condition: ModelBidConditionInput
  ) {
    createBid(input: $input, condition: $condition) {
      id
      tenderId
      latestMinorVersion
      latestAcceptedMinorVersion
      latestSubmittedMinorVersion
      status
      bidNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBid = /* GraphQL */ `
  mutation UpdateBid(
    $input: UpdateBidInput!
    $condition: ModelBidConditionInput
  ) {
    updateBid(input: $input, condition: $condition) {
      id
      tenderId
      latestMinorVersion
      latestAcceptedMinorVersion
      latestSubmittedMinorVersion
      status
      bidNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBid = /* GraphQL */ `
  mutation DeleteBid(
    $input: DeleteBidInput!
    $condition: ModelBidConditionInput
  ) {
    deleteBid(input: $input, condition: $condition) {
      id
      tenderId
      latestMinorVersion
      latestAcceptedMinorVersion
      latestSubmittedMinorVersion
      status
      bidNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBidMinorVersion = /* GraphQL */ `
  mutation CreateBidMinorVersion(
    $input: CreateBidMinorVersionInput!
    $condition: ModelBidMinorVersionConditionInput
  ) {
    createBidMinorVersion(input: $input, condition: $condition) {
      id
      bidId
      minorVersion
      tenderId
      tenderMinorVersion
      proposalId
      status
      metadata {
        bidderContactName
        bidderContactEmail
        bidderCompanyName
        bidderContactAddress
        bidderContactPhoneNumber
        bidderWebsite
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBidMinorVersion = /* GraphQL */ `
  mutation UpdateBidMinorVersion(
    $input: UpdateBidMinorVersionInput!
    $condition: ModelBidMinorVersionConditionInput
  ) {
    updateBidMinorVersion(input: $input, condition: $condition) {
      id
      bidId
      minorVersion
      tenderId
      tenderMinorVersion
      proposalId
      status
      metadata {
        bidderContactName
        bidderContactEmail
        bidderCompanyName
        bidderContactAddress
        bidderContactPhoneNumber
        bidderWebsite
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBidMinorVersion = /* GraphQL */ `
  mutation DeleteBidMinorVersion(
    $input: DeleteBidMinorVersionInput!
    $condition: ModelBidMinorVersionConditionInput
  ) {
    deleteBidMinorVersion(input: $input, condition: $condition) {
      id
      bidId
      minorVersion
      tenderId
      tenderMinorVersion
      proposalId
      status
      metadata {
        bidderContactName
        bidderContactEmail
        bidderCompanyName
        bidderContactAddress
        bidderContactPhoneNumber
        bidderWebsite
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createClientLog = /* GraphQL */ `
  mutation CreateClientLog(
    $input: CreateClientLogInput!
    $condition: ModelClientLogConditionInput
  ) {
    createClientLog(input: $input, condition: $condition) {
      id
      userId
      userAgent
      severityLevel
      componentName
      message
      details
      tags
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateClientLog = /* GraphQL */ `
  mutation UpdateClientLog(
    $input: UpdateClientLogInput!
    $condition: ModelClientLogConditionInput
  ) {
    updateClientLog(input: $input, condition: $condition) {
      id
      userId
      userAgent
      severityLevel
      componentName
      message
      details
      tags
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteClientLog = /* GraphQL */ `
  mutation DeleteClientLog(
    $input: DeleteClientLogInput!
    $condition: ModelClientLogConditionInput
  ) {
    deleteClientLog(input: $input, condition: $condition) {
      id
      userId
      userAgent
      severityLevel
      componentName
      message
      details
      tags
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCounter = /* GraphQL */ `
  mutation CreateCounter(
    $input: CreateCounterInput!
    $condition: ModelCounterConditionInput
  ) {
    createCounter(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCounter = /* GraphQL */ `
  mutation UpdateCounter(
    $input: UpdateCounterInput!
    $condition: ModelCounterConditionInput
  ) {
    updateCounter(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCounter = /* GraphQL */ `
  mutation DeleteCounter(
    $input: DeleteCounterInput!
    $condition: ModelCounterConditionInput
  ) {
    deleteCounter(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDesignDocument = /* GraphQL */ `
  mutation CreateDesignDocument(
    $input: CreateDesignDocumentInput!
    $condition: ModelDesignDocumentConditionInput
  ) {
    createDesignDocument(input: $input, condition: $condition) {
      id
      designDocumentGroupId
      propertyId
      projectId
      name
      status
      lastEvaluatedValidityStatus
      uploadStatus
      documentKey
      lastWorkScopeSpecificationItemNumber
      version
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDesignDocument = /* GraphQL */ `
  mutation UpdateDesignDocument(
    $input: UpdateDesignDocumentInput!
    $condition: ModelDesignDocumentConditionInput
  ) {
    updateDesignDocument(input: $input, condition: $condition) {
      id
      designDocumentGroupId
      propertyId
      projectId
      name
      status
      lastEvaluatedValidityStatus
      uploadStatus
      documentKey
      lastWorkScopeSpecificationItemNumber
      version
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDesignDocument = /* GraphQL */ `
  mutation DeleteDesignDocument(
    $input: DeleteDesignDocumentInput!
    $condition: ModelDesignDocumentConditionInput
  ) {
    deleteDesignDocument(input: $input, condition: $condition) {
      id
      designDocumentGroupId
      propertyId
      projectId
      name
      status
      lastEvaluatedValidityStatus
      uploadStatus
      documentKey
      lastWorkScopeSpecificationItemNumber
      version
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDesignDocumentGroup = /* GraphQL */ `
  mutation CreateDesignDocumentGroup(
    $input: CreateDesignDocumentGroupInput!
    $condition: ModelDesignDocumentGroupConditionInput
  ) {
    createDesignDocumentGroup(input: $input, condition: $condition) {
      id
      projectId
      propertyId
      name
      latestDocumentVersion
      documentContentKey
      solutionGroupNumber
      isLocked
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDesignDocumentGroup = /* GraphQL */ `
  mutation UpdateDesignDocumentGroup(
    $input: UpdateDesignDocumentGroupInput!
    $condition: ModelDesignDocumentGroupConditionInput
  ) {
    updateDesignDocumentGroup(input: $input, condition: $condition) {
      id
      projectId
      propertyId
      name
      latestDocumentVersion
      documentContentKey
      solutionGroupNumber
      isLocked
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDesignDocumentGroup = /* GraphQL */ `
  mutation DeleteDesignDocumentGroup(
    $input: DeleteDesignDocumentGroupInput!
    $condition: ModelDesignDocumentGroupConditionInput
  ) {
    deleteDesignDocumentGroup(input: $input, condition: $condition) {
      id
      projectId
      propertyId
      name
      latestDocumentVersion
      documentContentKey
      solutionGroupNumber
      isLocked
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDimension = /* GraphQL */ `
  mutation CreateDimension(
    $input: CreateDimensionInput!
    $condition: ModelDimensionConditionInput
  ) {
    createDimension(input: $input, condition: $condition) {
      id
      parentId
      propertyId
      createdInSolution
      description
      length
      lengthUnit
      width
      widthUnit
      dimensionType
      measurementUnit
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDimension = /* GraphQL */ `
  mutation UpdateDimension(
    $input: UpdateDimensionInput!
    $condition: ModelDimensionConditionInput
  ) {
    updateDimension(input: $input, condition: $condition) {
      id
      parentId
      propertyId
      createdInSolution
      description
      length
      lengthUnit
      width
      widthUnit
      dimensionType
      measurementUnit
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDimension = /* GraphQL */ `
  mutation DeleteDimension(
    $input: DeleteDimensionInput!
    $condition: ModelDimensionConditionInput
  ) {
    deleteDimension(input: $input, condition: $condition) {
      id
      parentId
      propertyId
      createdInSolution
      description
      length
      lengthUnit
      width
      widthUnit
      dimensionType
      measurementUnit
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDiscoverableWorkTypes = /* GraphQL */ `
  mutation CreateDiscoverableWorkTypes(
    $input: CreateDiscoverableWorkTypesInput!
    $condition: ModelDiscoverableWorkTypesConditionInput
  ) {
    createDiscoverableWorkTypes(input: $input, condition: $condition) {
      id
      workTypeGroupId
      defaultVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDiscoverableWorkTypes = /* GraphQL */ `
  mutation UpdateDiscoverableWorkTypes(
    $input: UpdateDiscoverableWorkTypesInput!
    $condition: ModelDiscoverableWorkTypesConditionInput
  ) {
    updateDiscoverableWorkTypes(input: $input, condition: $condition) {
      id
      workTypeGroupId
      defaultVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDiscoverableWorkTypes = /* GraphQL */ `
  mutation DeleteDiscoverableWorkTypes(
    $input: DeleteDiscoverableWorkTypesInput!
    $condition: ModelDiscoverableWorkTypesConditionInput
  ) {
    deleteDiscoverableWorkTypes(input: $input, condition: $condition) {
      id
      workTypeGroupId
      defaultVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEntityResourcePermission = /* GraphQL */ `
  mutation CreateEntityResourcePermission(
    $input: CreateEntityResourcePermissionInput!
    $condition: ModelEntityResourcePermissionConditionInput
  ) {
    createEntityResourcePermission(input: $input, condition: $condition) {
      id
      propertyId
      entityId
      entityType
      resourceId
      resourceType
      permissionType
      isGranted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEntityResourcePermission = /* GraphQL */ `
  mutation UpdateEntityResourcePermission(
    $input: UpdateEntityResourcePermissionInput!
    $condition: ModelEntityResourcePermissionConditionInput
  ) {
    updateEntityResourcePermission(input: $input, condition: $condition) {
      id
      propertyId
      entityId
      entityType
      resourceId
      resourceType
      permissionType
      isGranted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEntityResourcePermission = /* GraphQL */ `
  mutation DeleteEntityResourcePermission(
    $input: DeleteEntityResourcePermissionInput!
    $condition: ModelEntityResourcePermissionConditionInput
  ) {
    deleteEntityResourcePermission(input: $input, condition: $condition) {
      id
      propertyId
      entityId
      entityType
      resourceId
      resourceType
      permissionType
      isGranted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEntityWorkTypeAssociation = /* GraphQL */ `
  mutation CreateEntityWorkTypeAssociation(
    $input: CreateEntityWorkTypeAssociationInput!
    $condition: ModelEntityWorkTypeAssociationConditionInput
  ) {
    createEntityWorkTypeAssociation(input: $input, condition: $condition) {
      id
      entityId
      entityType
      workTypeGroupId
      workTypeVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEntityWorkTypeAssociation = /* GraphQL */ `
  mutation UpdateEntityWorkTypeAssociation(
    $input: UpdateEntityWorkTypeAssociationInput!
    $condition: ModelEntityWorkTypeAssociationConditionInput
  ) {
    updateEntityWorkTypeAssociation(input: $input, condition: $condition) {
      id
      entityId
      entityType
      workTypeGroupId
      workTypeVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEntityWorkTypeAssociation = /* GraphQL */ `
  mutation DeleteEntityWorkTypeAssociation(
    $input: DeleteEntityWorkTypeAssociationInput!
    $condition: ModelEntityWorkTypeAssociationConditionInput
  ) {
    deleteEntityWorkTypeAssociation(input: $input, condition: $condition) {
      id
      entityId
      entityType
      workTypeGroupId
      workTypeVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInspection = /* GraphQL */ `
  mutation CreateInspection(
    $input: CreateInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    createInspection(input: $input, condition: $condition) {
      id
      propertyId
      createdByOrganizationId
      createdByUserId
      startTime
      endTime
      status
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInspection = /* GraphQL */ `
  mutation UpdateInspection(
    $input: UpdateInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    updateInspection(input: $input, condition: $condition) {
      id
      propertyId
      createdByOrganizationId
      createdByUserId
      startTime
      endTime
      status
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInspection = /* GraphQL */ `
  mutation DeleteInspection(
    $input: DeleteInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    deleteInspection(input: $input, condition: $condition) {
      id
      propertyId
      createdByOrganizationId
      createdByUserId
      startTime
      endTime
      status
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createIssue = /* GraphQL */ `
  mutation CreateIssue(
    $input: CreateIssueInput!
    $condition: ModelIssueConditionInput
  ) {
    createIssue(input: $input, condition: $condition) {
      id
      locationId
      propertyId
      inspectionId
      createdInSolution
      name
      description
      note
      tags
      status
      clientCreationDate
      localLastUpdatedTime
      createdBy
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateIssue = /* GraphQL */ `
  mutation UpdateIssue(
    $input: UpdateIssueInput!
    $condition: ModelIssueConditionInput
  ) {
    updateIssue(input: $input, condition: $condition) {
      id
      locationId
      propertyId
      inspectionId
      createdInSolution
      name
      description
      note
      tags
      status
      clientCreationDate
      localLastUpdatedTime
      createdBy
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteIssue = /* GraphQL */ `
  mutation DeleteIssue(
    $input: DeleteIssueInput!
    $condition: ModelIssueConditionInput
  ) {
    deleteIssue(input: $input, condition: $condition) {
      id
      locationId
      propertyId
      inspectionId
      createdInSolution
      name
      description
      note
      tags
      status
      clientCreationDate
      localLastUpdatedTime
      createdBy
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      propertyId
      createdInSolution
      name
      tags
      localCreationTime
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      propertyId
      createdInSolution
      name
      tags
      localCreationTime
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      propertyId
      createdInSolution
      name
      tags
      localCreationTime
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      parentId
      propertyId
      note
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      parentId
      propertyId
      note
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      parentId
      propertyId
      note
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      legalName
      type
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      legalName
      type
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      legalName
      type
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      propertyId
      name
      tenderNumber
      customer
      address
      propertyType
      dueDate
      contactName
      contactNumber
      contactEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      propertyId
      name
      tenderNumber
      customer
      address
      propertyType
      dueDate
      contactName
      contactNumber
      contactEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      propertyId
      name
      tenderNumber
      customer
      address
      propertyType
      dueDate
      contactName
      contactNumber
      contactEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProjectCounter = /* GraphQL */ `
  mutation CreateProjectCounter(
    $input: CreateProjectCounterInput!
    $condition: ModelProjectCounterConditionInput
  ) {
    createProjectCounter(input: $input, condition: $condition) {
      id
      propertyId
      latestProjectNumber
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProjectCounter = /* GraphQL */ `
  mutation UpdateProjectCounter(
    $input: UpdateProjectCounterInput!
    $condition: ModelProjectCounterConditionInput
  ) {
    updateProjectCounter(input: $input, condition: $condition) {
      id
      propertyId
      latestProjectNumber
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProjectCounter = /* GraphQL */ `
  mutation DeleteProjectCounter(
    $input: DeleteProjectCounterInput!
    $condition: ModelProjectCounterConditionInput
  ) {
    deleteProjectCounter(input: $input, condition: $condition) {
      id
      propertyId
      latestProjectNumber
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProperty = /* GraphQL */ `
  mutation CreateProperty(
    $input: CreatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    createProperty(input: $input, condition: $condition) {
      id
      name
      description
      address
      ownerOrganizationId
      primaryContactNumber
      primaryContactEmail
      primaryContactName
      propertyNumber
      locations {
        items {
          id
          propertyId
          createdInSolution
          name
          tags
          localCreationTime
          localLastUpdatedTime
          opaqueSyncReconciliationToken
          canView
          canEdit
          canDelete
          createdBy
          canViewList
          canViewEntityVersionMap
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdBy
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canCreateSolutions
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProperty = /* GraphQL */ `
  mutation UpdateProperty(
    $input: UpdatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    updateProperty(input: $input, condition: $condition) {
      id
      name
      description
      address
      ownerOrganizationId
      primaryContactNumber
      primaryContactEmail
      primaryContactName
      propertyNumber
      locations {
        items {
          id
          propertyId
          createdInSolution
          name
          tags
          localCreationTime
          localLastUpdatedTime
          opaqueSyncReconciliationToken
          canView
          canEdit
          canDelete
          createdBy
          canViewList
          canViewEntityVersionMap
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdBy
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canCreateSolutions
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProperty = /* GraphQL */ `
  mutation DeleteProperty(
    $input: DeletePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    deleteProperty(input: $input, condition: $condition) {
      id
      name
      description
      address
      ownerOrganizationId
      primaryContactNumber
      primaryContactEmail
      primaryContactName
      propertyNumber
      locations {
        items {
          id
          propertyId
          createdInSolution
          name
          tags
          localCreationTime
          localLastUpdatedTime
          opaqueSyncReconciliationToken
          canView
          canEdit
          canDelete
          createdBy
          canViewList
          canViewEntityVersionMap
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdBy
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canCreateSolutions
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPropertyCounter = /* GraphQL */ `
  mutation CreatePropertyCounter(
    $input: CreatePropertyCounterInput!
    $condition: ModelPropertyCounterConditionInput
  ) {
    createPropertyCounter(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePropertyCounter = /* GraphQL */ `
  mutation UpdatePropertyCounter(
    $input: UpdatePropertyCounterInput!
    $condition: ModelPropertyCounterConditionInput
  ) {
    updatePropertyCounter(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePropertyCounter = /* GraphQL */ `
  mutation DeletePropertyCounter(
    $input: DeletePropertyCounterInput!
    $condition: ModelPropertyCounterConditionInput
  ) {
    deletePropertyCounter(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProposal = /* GraphQL */ `
  mutation CreateProposal(
    $input: CreateProposalInput!
    $condition: ModelProposalConditionInput
  ) {
    createProposal(input: $input, condition: $condition) {
      id
      schemaVersion
      designDocumentGroupId
      designDocumentVersion
      proposalKey
      proposalNumber
      status
      author
      createdAt
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProposal = /* GraphQL */ `
  mutation UpdateProposal(
    $input: UpdateProposalInput!
    $condition: ModelProposalConditionInput
  ) {
    updateProposal(input: $input, condition: $condition) {
      id
      schemaVersion
      designDocumentGroupId
      designDocumentVersion
      proposalKey
      proposalNumber
      status
      author
      createdAt
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProposal = /* GraphQL */ `
  mutation DeleteProposal(
    $input: DeleteProposalInput!
    $condition: ModelProposalConditionInput
  ) {
    deleteProposal(input: $input, condition: $condition) {
      id
      schemaVersion
      designDocumentGroupId
      designDocumentVersion
      proposalKey
      proposalNumber
      status
      author
      createdAt
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProposalCounter = /* GraphQL */ `
  mutation CreateProposalCounter(
    $input: CreateProposalCounterInput!
    $condition: ModelProposalCounterConditionInput
  ) {
    createProposalCounter(input: $input, condition: $condition) {
      id
      solutionGroupId
      solutionVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProposalCounter = /* GraphQL */ `
  mutation UpdateProposalCounter(
    $input: UpdateProposalCounterInput!
    $condition: ModelProposalCounterConditionInput
  ) {
    updateProposalCounter(input: $input, condition: $condition) {
      id
      solutionGroupId
      solutionVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProposalCounter = /* GraphQL */ `
  mutation DeleteProposalCounter(
    $input: DeleteProposalCounterInput!
    $condition: ModelProposalCounterConditionInput
  ) {
    deleteProposalCounter(input: $input, condition: $condition) {
      id
      solutionGroupId
      solutionVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createResourceUserPermissions = /* GraphQL */ `
  mutation CreateResourceUserPermissions(
    $input: CreateResourceUserPermissionsInput!
    $condition: ModelResourceUserPermissionsConditionInput
  ) {
    createResourceUserPermissions(input: $input, condition: $condition) {
      id
      resourceId
      userId
      userEmail
      userFullName
      resourceAndUserId
      permissions
      resourceType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateResourceUserPermissions = /* GraphQL */ `
  mutation UpdateResourceUserPermissions(
    $input: UpdateResourceUserPermissionsInput!
    $condition: ModelResourceUserPermissionsConditionInput
  ) {
    updateResourceUserPermissions(input: $input, condition: $condition) {
      id
      resourceId
      userId
      userEmail
      userFullName
      resourceAndUserId
      permissions
      resourceType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteResourceUserPermissions = /* GraphQL */ `
  mutation DeleteResourceUserPermissions(
    $input: DeleteResourceUserPermissionsInput!
    $condition: ModelResourceUserPermissionsConditionInput
  ) {
    deleteResourceUserPermissions(input: $input, condition: $condition) {
      id
      resourceId
      userId
      userEmail
      userFullName
      resourceAndUserId
      permissions
      resourceType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSolution = /* GraphQL */ `
  mutation CreateSolution(
    $input: CreateSolutionInput!
    $condition: ModelSolutionConditionInput
  ) {
    createSolution(input: $input, condition: $condition) {
      id
      propertyId
      projectId
      name
      status
      latestMinorVersion
      latestContentKey
      serialNumber
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
        tenderingType
      }
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSolution = /* GraphQL */ `
  mutation UpdateSolution(
    $input: UpdateSolutionInput!
    $condition: ModelSolutionConditionInput
  ) {
    updateSolution(input: $input, condition: $condition) {
      id
      propertyId
      projectId
      name
      status
      latestMinorVersion
      latestContentKey
      serialNumber
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
        tenderingType
      }
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSolution = /* GraphQL */ `
  mutation DeleteSolution(
    $input: DeleteSolutionInput!
    $condition: ModelSolutionConditionInput
  ) {
    deleteSolution(input: $input, condition: $condition) {
      id
      propertyId
      projectId
      name
      status
      latestMinorVersion
      latestContentKey
      serialNumber
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
        tenderingType
      }
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSolutionMinorVersion = /* GraphQL */ `
  mutation CreateSolutionMinorVersion(
    $input: CreateSolutionMinorVersionInput!
    $condition: ModelSolutionMinorVersionConditionInput
  ) {
    createSolutionMinorVersion(input: $input, condition: $condition) {
      id
      solutionId
      propertyId
      name
      contentKey
      minorVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSolutionMinorVersion = /* GraphQL */ `
  mutation UpdateSolutionMinorVersion(
    $input: UpdateSolutionMinorVersionInput!
    $condition: ModelSolutionMinorVersionConditionInput
  ) {
    updateSolutionMinorVersion(input: $input, condition: $condition) {
      id
      solutionId
      propertyId
      name
      contentKey
      minorVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSolutionMinorVersion = /* GraphQL */ `
  mutation DeleteSolutionMinorVersion(
    $input: DeleteSolutionMinorVersionInput!
    $condition: ModelSolutionMinorVersionConditionInput
  ) {
    deleteSolutionMinorVersion(input: $input, condition: $condition) {
      id
      solutionId
      propertyId
      name
      contentKey
      minorVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSolutionGroupCounter = /* GraphQL */ `
  mutation CreateSolutionGroupCounter(
    $input: CreateSolutionGroupCounterInput!
    $condition: ModelSolutionGroupCounterConditionInput
  ) {
    createSolutionGroupCounter(input: $input, condition: $condition) {
      id
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSolutionGroupCounter = /* GraphQL */ `
  mutation UpdateSolutionGroupCounter(
    $input: UpdateSolutionGroupCounterInput!
    $condition: ModelSolutionGroupCounterConditionInput
  ) {
    updateSolutionGroupCounter(input: $input, condition: $condition) {
      id
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSolutionGroupCounter = /* GraphQL */ `
  mutation DeleteSolutionGroupCounter(
    $input: DeleteSolutionGroupCounterInput!
    $condition: ModelSolutionGroupCounterConditionInput
  ) {
    deleteSolutionGroupCounter(input: $input, condition: $condition) {
      id
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserGroup = /* GraphQL */ `
  mutation CreateUserGroup(
    $input: CreateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    createUserGroup(input: $input, condition: $condition) {
      id
      resourceId
      groupType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserGroup = /* GraphQL */ `
  mutation UpdateUserGroup(
    $input: UpdateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    updateUserGroup(input: $input, condition: $condition) {
      id
      resourceId
      groupType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserGroup = /* GraphQL */ `
  mutation DeleteUserGroup(
    $input: DeleteUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    deleteUserGroup(input: $input, condition: $condition) {
      id
      resourceId
      groupType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserGroupMember = /* GraphQL */ `
  mutation CreateUserGroupMember(
    $input: CreateUserGroupMemberInput!
    $condition: ModelUserGroupMemberConditionInput
  ) {
    createUserGroupMember(input: $input, condition: $condition) {
      id
      resourceId
      resourceAndUserId
      userId
      userGroupId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserGroupMember = /* GraphQL */ `
  mutation UpdateUserGroupMember(
    $input: UpdateUserGroupMemberInput!
    $condition: ModelUserGroupMemberConditionInput
  ) {
    updateUserGroupMember(input: $input, condition: $condition) {
      id
      resourceId
      resourceAndUserId
      userId
      userGroupId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserGroupMember = /* GraphQL */ `
  mutation DeleteUserGroupMember(
    $input: DeleteUserGroupMemberInput!
    $condition: ModelUserGroupMemberConditionInput
  ) {
    deleteUserGroupMember(input: $input, condition: $condition) {
      id
      resourceId
      resourceAndUserId
      userId
      userGroupId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserOrganizationAssociation = /* GraphQL */ `
  mutation CreateUserOrganizationAssociation(
    $input: CreateUserOrganizationAssociationInput!
    $condition: ModelUserOrganizationAssociationConditionInput
  ) {
    createUserOrganizationAssociation(input: $input, condition: $condition) {
      id
      userId
      organizationId
      associationType
      permissions
      role
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserOrganizationAssociation = /* GraphQL */ `
  mutation UpdateUserOrganizationAssociation(
    $input: UpdateUserOrganizationAssociationInput!
    $condition: ModelUserOrganizationAssociationConditionInput
  ) {
    updateUserOrganizationAssociation(input: $input, condition: $condition) {
      id
      userId
      organizationId
      associationType
      permissions
      role
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserOrganizationAssociation = /* GraphQL */ `
  mutation DeleteUserOrganizationAssociation(
    $input: DeleteUserOrganizationAssociationInput!
    $condition: ModelUserOrganizationAssociationConditionInput
  ) {
    deleteUserOrganizationAssociation(input: $input, condition: $condition) {
      id
      userId
      organizationId
      associationType
      permissions
      role
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createWorkSpecification = /* GraphQL */ `
  mutation CreateWorkSpecification(
    $input: CreateWorkSpecificationInput!
    $condition: ModelWorkSpecificationConditionInput
  ) {
    createWorkSpecification(input: $input, condition: $condition) {
      id
      dimensionId
      issueId
      propertyId
      createdInSolution
      body
      description
      workTypeId
      itemNumber
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      version
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateWorkSpecification = /* GraphQL */ `
  mutation UpdateWorkSpecification(
    $input: UpdateWorkSpecificationInput!
    $condition: ModelWorkSpecificationConditionInput
  ) {
    updateWorkSpecification(input: $input, condition: $condition) {
      id
      dimensionId
      issueId
      propertyId
      createdInSolution
      body
      description
      workTypeId
      itemNumber
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      version
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteWorkSpecification = /* GraphQL */ `
  mutation DeleteWorkSpecification(
    $input: DeleteWorkSpecificationInput!
    $condition: ModelWorkSpecificationConditionInput
  ) {
    deleteWorkSpecification(input: $input, condition: $condition) {
      id
      dimensionId
      issueId
      propertyId
      createdInSolution
      body
      description
      workTypeId
      itemNumber
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      version
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createWorkType = /* GraphQL */ `
  mutation CreateWorkType(
    $input: CreateWorkTypeInput!
    $condition: ModelWorkTypeConditionInput
  ) {
    createWorkType(input: $input, condition: $condition) {
      id
      name
      shortDescription
      longDescription
      groupName
      unitPrice
      unitCost
      dimensionType
      measurementUnit
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateWorkType = /* GraphQL */ `
  mutation UpdateWorkType(
    $input: UpdateWorkTypeInput!
    $condition: ModelWorkTypeConditionInput
  ) {
    updateWorkType(input: $input, condition: $condition) {
      id
      name
      shortDescription
      longDescription
      groupName
      unitPrice
      unitCost
      dimensionType
      measurementUnit
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteWorkType = /* GraphQL */ `
  mutation DeleteWorkType(
    $input: DeleteWorkTypeInput!
    $condition: ModelWorkTypeConditionInput
  ) {
    deleteWorkType(input: $input, condition: $condition) {
      id
      name
      shortDescription
      longDescription
      groupName
      unitPrice
      unitCost
      dimensionType
      measurementUnit
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createWorkTypeGroup = /* GraphQL */ `
  mutation CreateWorkTypeGroup(
    $input: CreateWorkTypeGroupInput!
    $condition: ModelWorkTypeGroupConditionInput
  ) {
    createWorkTypeGroup(input: $input, condition: $condition) {
      id
      latestWorkTypeVersion
      environmentAgnosticWorkTypeGroupId
      isDiscoverable
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateWorkTypeGroup = /* GraphQL */ `
  mutation UpdateWorkTypeGroup(
    $input: UpdateWorkTypeGroupInput!
    $condition: ModelWorkTypeGroupConditionInput
  ) {
    updateWorkTypeGroup(input: $input, condition: $condition) {
      id
      latestWorkTypeVersion
      environmentAgnosticWorkTypeGroupId
      isDiscoverable
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteWorkTypeGroup = /* GraphQL */ `
  mutation DeleteWorkTypeGroup(
    $input: DeleteWorkTypeGroupInput!
    $condition: ModelWorkTypeGroupConditionInput
  ) {
    deleteWorkTypeGroup(input: $input, condition: $condition) {
      id
      latestWorkTypeVersion
      environmentAgnosticWorkTypeGroupId
      isDiscoverable
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createWorkTypePricing = /* GraphQL */ `
  mutation CreateWorkTypePricing(
    $input: CreateWorkTypePricingInput!
    $condition: ModelWorkTypePricingConditionInput
  ) {
    createWorkTypePricing(input: $input, condition: $condition) {
      id
      entityId
      workTypeGroupId
      body
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateWorkTypePricing = /* GraphQL */ `
  mutation UpdateWorkTypePricing(
    $input: UpdateWorkTypePricingInput!
    $condition: ModelWorkTypePricingConditionInput
  ) {
    updateWorkTypePricing(input: $input, condition: $condition) {
      id
      entityId
      workTypeGroupId
      body
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteWorkTypePricing = /* GraphQL */ `
  mutation DeleteWorkTypePricing(
    $input: DeleteWorkTypePricingInput!
    $condition: ModelWorkTypePricingConditionInput
  ) {
    deleteWorkTypePricing(input: $input, condition: $condition) {
      id
      entityId
      workTypeGroupId
      body
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDefaultWorkTypePricing = /* GraphQL */ `
  mutation CreateDefaultWorkTypePricing(
    $input: CreateDefaultWorkTypePricingInput!
    $condition: ModelDefaultWorkTypePricingConditionInput
  ) {
    createDefaultWorkTypePricing(input: $input, condition: $condition) {
      id
      unitCost
      unitPrice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDefaultWorkTypePricing = /* GraphQL */ `
  mutation UpdateDefaultWorkTypePricing(
    $input: UpdateDefaultWorkTypePricingInput!
    $condition: ModelDefaultWorkTypePricingConditionInput
  ) {
    updateDefaultWorkTypePricing(input: $input, condition: $condition) {
      id
      unitCost
      unitPrice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDefaultWorkTypePricing = /* GraphQL */ `
  mutation DeleteDefaultWorkTypePricing(
    $input: DeleteDefaultWorkTypePricingInput!
    $condition: ModelDefaultWorkTypePricingConditionInput
  ) {
    deleteDefaultWorkTypePricing(input: $input, condition: $condition) {
      id
      unitCost
      unitPrice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTemporaryWorkType = /* GraphQL */ `
  mutation CreateTemporaryWorkType(
    $input: CreateTemporaryWorkTypeInput!
    $condition: ModelTemporaryWorkTypeConditionInput
  ) {
    createTemporaryWorkType(input: $input, condition: $condition) {
      id
      workTypeGroupId
      environmentAgnosticWorkTypeGroupId
      body
      version
      schemaVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTemporaryWorkType = /* GraphQL */ `
  mutation UpdateTemporaryWorkType(
    $input: UpdateTemporaryWorkTypeInput!
    $condition: ModelTemporaryWorkTypeConditionInput
  ) {
    updateTemporaryWorkType(input: $input, condition: $condition) {
      id
      workTypeGroupId
      environmentAgnosticWorkTypeGroupId
      body
      version
      schemaVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTemporaryWorkType = /* GraphQL */ `
  mutation DeleteTemporaryWorkType(
    $input: DeleteTemporaryWorkTypeInput!
    $condition: ModelTemporaryWorkTypeConditionInput
  ) {
    deleteTemporaryWorkType(input: $input, condition: $condition) {
      id
      workTypeGroupId
      environmentAgnosticWorkTypeGroupId
      body
      version
      schemaVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTender = /* GraphQL */ `
  mutation CreateTender(
    $input: CreateTenderInput!
    $condition: ModelTenderConditionInput
  ) {
    createTender(input: $input, condition: $condition) {
      id
      solutionId
      status
      latestVersion
      awardedVersion
      projectNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTender = /* GraphQL */ `
  mutation UpdateTender(
    $input: UpdateTenderInput!
    $condition: ModelTenderConditionInput
  ) {
    updateTender(input: $input, condition: $condition) {
      id
      solutionId
      status
      latestVersion
      awardedVersion
      projectNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTender = /* GraphQL */ `
  mutation DeleteTender(
    $input: DeleteTenderInput!
    $condition: ModelTenderConditionInput
  ) {
    deleteTender(input: $input, condition: $condition) {
      id
      solutionId
      status
      latestVersion
      awardedVersion
      projectNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTenderMinorVersion = /* GraphQL */ `
  mutation CreateTenderMinorVersion(
    $input: CreateTenderMinorVersionInput!
    $condition: ModelTenderMinorVersionConditionInput
  ) {
    createTenderMinorVersion(input: $input, condition: $condition) {
      id
      tenderId
      version
      solutionId
      solutionMinorVersion
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
      }
      contentKey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTenderMinorVersion = /* GraphQL */ `
  mutation UpdateTenderMinorVersion(
    $input: UpdateTenderMinorVersionInput!
    $condition: ModelTenderMinorVersionConditionInput
  ) {
    updateTenderMinorVersion(input: $input, condition: $condition) {
      id
      tenderId
      version
      solutionId
      solutionMinorVersion
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
      }
      contentKey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTenderMinorVersion = /* GraphQL */ `
  mutation DeleteTenderMinorVersion(
    $input: DeleteTenderMinorVersionInput!
    $condition: ModelTenderMinorVersionConditionInput
  ) {
    deleteTenderMinorVersion(input: $input, condition: $condition) {
      id
      tenderId
      version
      solutionId
      solutionMinorVersion
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
      }
      contentKey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserOnboardingProgress = /* GraphQL */ `
  mutation CreateUserOnboardingProgress(
    $input: CreateUserOnboardingProgressInput!
    $condition: ModelUserOnboardingProgressConditionInput
  ) {
    createUserOnboardingProgress(input: $input, condition: $condition) {
      id
      userId
      hasUserBeenAssociatedWithOrganization
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserOnboardingProgress = /* GraphQL */ `
  mutation UpdateUserOnboardingProgress(
    $input: UpdateUserOnboardingProgressInput!
    $condition: ModelUserOnboardingProgressConditionInput
  ) {
    updateUserOnboardingProgress(input: $input, condition: $condition) {
      id
      userId
      hasUserBeenAssociatedWithOrganization
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserOnboardingProgress = /* GraphQL */ `
  mutation DeleteUserOnboardingProgress(
    $input: DeleteUserOnboardingProgressInput!
    $condition: ModelUserOnboardingProgressConditionInput
  ) {
    deleteUserOnboardingProgress(input: $input, condition: $condition) {
      id
      userId
      hasUserBeenAssociatedWithOrganization
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      id
      type
      name
      s3Key
      ownerId
      ownerType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      id
      type
      name
      s3Key
      ownerId
      ownerType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      id
      type
      name
      s3Key
      ownerId
      ownerType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTemporaryAuthorizationCode = /* GraphQL */ `
  mutation CreateTemporaryAuthorizationCode(
    $input: CreateTemporaryAuthorizationCodeInput!
    $condition: ModelTemporaryAuthorizationCodeConditionInput
  ) {
    createTemporaryAuthorizationCode(input: $input, condition: $condition) {
      id
      code
      userId
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTemporaryAuthorizationCode = /* GraphQL */ `
  mutation UpdateTemporaryAuthorizationCode(
    $input: UpdateTemporaryAuthorizationCodeInput!
    $condition: ModelTemporaryAuthorizationCodeConditionInput
  ) {
    updateTemporaryAuthorizationCode(input: $input, condition: $condition) {
      id
      code
      userId
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTemporaryAuthorizationCode = /* GraphQL */ `
  mutation DeleteTemporaryAuthorizationCode(
    $input: DeleteTemporaryAuthorizationCodeInput!
    $condition: ModelTemporaryAuthorizationCodeConditionInput
  ) {
    deleteTemporaryAuthorizationCode(input: $input, condition: $condition) {
      id
      code
      userId
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createLinkRegistry = /* GraphQL */ `
  mutation CreateLinkRegistry(
    $input: CreateLinkRegistryInput!
    $condition: ModelLinkRegistryConditionInput
  ) {
    createLinkRegistry(input: $input, condition: $condition) {
      id
      action
      variables
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLinkRegistry = /* GraphQL */ `
  mutation UpdateLinkRegistry(
    $input: UpdateLinkRegistryInput!
    $condition: ModelLinkRegistryConditionInput
  ) {
    updateLinkRegistry(input: $input, condition: $condition) {
      id
      action
      variables
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLinkRegistry = /* GraphQL */ `
  mutation DeleteLinkRegistry(
    $input: DeleteLinkRegistryInput!
    $condition: ModelLinkRegistryConditionInput
  ) {
    deleteLinkRegistry(input: $input, condition: $condition) {
      id
      action
      variables
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createExternalReportBranding = /* GraphQL */ `
  mutation CreateExternalReportBranding(
    $input: CreateExternalReportBrandingInput!
    $condition: ModelExternalReportBrandingConditionInput
  ) {
    createExternalReportBranding(input: $input, condition: $condition) {
      id
      name
      logoKey
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateExternalReportBranding = /* GraphQL */ `
  mutation UpdateExternalReportBranding(
    $input: UpdateExternalReportBrandingInput!
    $condition: ModelExternalReportBrandingConditionInput
  ) {
    updateExternalReportBranding(input: $input, condition: $condition) {
      id
      name
      logoKey
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteExternalReportBranding = /* GraphQL */ `
  mutation DeleteExternalReportBranding(
    $input: DeleteExternalReportBrandingInput!
    $condition: ModelExternalReportBrandingConditionInput
  ) {
    deleteExternalReportBranding(input: $input, condition: $condition) {
      id
      name
      logoKey
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMeeting = /* GraphQL */ `
  mutation CreateMeeting(
    $input: CreateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    createMeeting(input: $input, condition: $condition) {
      id
      status
      data
      createdBy
      mediaPipelineId
      recordingKey
      recordingAttachmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMeeting = /* GraphQL */ `
  mutation UpdateMeeting(
    $input: UpdateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    updateMeeting(input: $input, condition: $condition) {
      id
      status
      data
      createdBy
      mediaPipelineId
      recordingKey
      recordingAttachmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMeeting = /* GraphQL */ `
  mutation DeleteMeeting(
    $input: DeleteMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    deleteMeeting(input: $input, condition: $condition) {
      id
      status
      data
      createdBy
      mediaPipelineId
      recordingKey
      recordingAttachmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAttendee = /* GraphQL */ `
  mutation CreateAttendee(
    $input: CreateAttendeeInput!
    $condition: ModelAttendeeConditionInput
  ) {
    createAttendee(input: $input, condition: $condition) {
      id
      meetingId
      entityId
      name
      role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAttendee = /* GraphQL */ `
  mutation UpdateAttendee(
    $input: UpdateAttendeeInput!
    $condition: ModelAttendeeConditionInput
  ) {
    updateAttendee(input: $input, condition: $condition) {
      id
      meetingId
      entityId
      name
      role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAttendee = /* GraphQL */ `
  mutation DeleteAttendee(
    $input: DeleteAttendeeInput!
    $condition: ModelAttendeeConditionInput
  ) {
    deleteAttendee(input: $input, condition: $condition) {
      id
      meetingId
      entityId
      name
      role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTemporaryAuthorizationTokenEvent = /* GraphQL */ `
  mutation CreateTemporaryAuthorizationTokenEvent(
    $input: CreateTemporaryAuthorizationTokenEventInput!
  ) {
    createTemporaryAuthorizationTokenEvent(input: $input) {
      status
      authCode
      expiresAt
    }
  }
`;
export const sendCompleteEvent = /* GraphQL */ `
  mutation SendCompleteEvent(
    $executionId: ID!
    $status: CompleteEventStatus!
    $result: String
  ) {
    sendCompleteEvent(
      executionId: $executionId
      status: $status
      result: $result
    ) {
      executionId
      status
      result
    }
  }
`;
export const handleExternalReportSubmission = /* GraphQL */ `
  mutation HandleExternalReportSubmission($input: ExternalReportSubmission!) {
    handleExternalReportSubmission(input: $input)
  }
`;
export const generateReport = /* GraphQL */ `
  mutation GenerateReport($executionId: ID!, $input: GenerateReportRequest!) {
    generateReport(executionId: $executionId, input: $input) {
      executionId
      status
      result
    }
  }
`;
export const sendNotificationToUsers = /* GraphQL */ `
  mutation SendNotificationToUsers($input: sendNotificationToUsersInput!) {
    sendNotificationToUsers(input: $input)
  }
`;
export const updateBidStatus = /* GraphQL */ `
  mutation UpdateBidStatus($input: UpdateBidStatusInput!) {
    updateBidStatus(input: $input)
  }
`;
export const joinChimeMeetingPublic = /* GraphQL */ `
  mutation JoinChimeMeetingPublic(
    $meetingId: String!
    $name: String!
    $role: AttendeeRole!
  ) {
    joinChimeMeetingPublic(meetingId: $meetingId, name: $name, role: $role)
  }
`;
export const shareMeetingLink = /* GraphQL */ `
  mutation ShareMeetingLink($input: ShareMeetingLinkInput!) {
    shareMeetingLink(input: $input)
  }
`;
