import {
    Fab,
    FabProps,
    Zoom,
    useTheme
} from "@mui/material";

type ZoomAnimatedFabProps = FabProps & { in: boolean; };

export const ZoomAnimatedFab = (props: ZoomAnimatedFabProps) => {
    const { children, in: show, ...fabProps } = props;

    const theme = useTheme();
    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    return (
        <Zoom
            in={show}
            timeout={transitionDuration}
            style={{ transitionDelay: `${show ? transitionDuration.enter : 0}ms`, zIndex: theme.zIndex.drawer - 1 }}
            unmountOnExit
        >
            <Fab
                {...fabProps}
            >
                {props.children}
            </Fab>
        </Zoom>
    );
};