import {
    Box,
    Divider,
    LinearProgress,
    Typography
} from "@mui/material";
import {
    filteredLocationIdsByContextSelectorFamily,
    locationIdsByContextAtomFamily
} from "../../lib/design/document/state/DocumentState";
import {
    getResetIssueFilter,
    isFilterAppliedAtom
} from "../../lib/issue/state/IssueFilterRecoilState";

import { LOCATION_PAGINATION_GROUP_ID_PREFIX } from "../../lib/pagination/Constants";
import LocationCard from "./LocationCard";
import NoItemsPlaceholder from "../../components/NoItemsPlaceholder";
import { PaginationBar } from "../../components/general/pagination/PaginationBar";
import { StateContext } from "../../lib/design/document/state/StateContext";
import globalStyle from "../../assets/style/globalStyle";
import { isDesktop } from "react-device-detect";
import { propertyIdInFocusAtom } from "../../lib/ui/InFocusRecoilStates";
import { useRecoilValue } from "recoil";
import { useState } from "react";

export interface ListLocationsProps {
    readonly stateContext: StateContext;
}

export const ListLocations = (props: ListLocationsProps) => {
    const LOCATIONS_PER_PAGE = isDesktop? 10 : 5;

    /* Internal page state */
    const { stateContext } = props;
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const locationIds = useRecoilValue<Array<string>>(locationIdsByContextAtomFamily(stateContext));
    const isFilterApplied = useRecoilValue<boolean>(isFilterAppliedAtom);
    const filteredLocationIds = useRecoilValue<Array<string>>(filteredLocationIdsByContextSelectorFamily(stateContext));
    const [locationIdsToDisplay, setLocationIdsToDisplay] = useState<Array<string>>([]);
    const propertyId = useRecoilValue(propertyIdInFocusAtom);

    /* Dependencies */
    const globalClasses = globalStyle();
    const resetFilter = getResetIssueFilter();

    const filterLocations = (items: Array<string>) => {
        setLocationIdsToDisplay(items);
        setIsPageLoading(false);
    };

    return (
        <>
            <Box display="flex" justifyContent="center" mb={2}>
                <PaginationBar
                    groupId={LOCATION_PAGINATION_GROUP_ID_PREFIX + propertyId}
                    items={filteredLocationIds}
                    itemsPerPage={LOCATIONS_PER_PAGE}
                    setItemsToDisplay={filterLocations}
                />
            </Box>
            {isPageLoading ? (
                <LinearProgress />
            ) : (
                <>
                    {locationIds.length === 0 && (
                        <Box className={globalClasses.containerWithBorder}>
                            <NoItemsPlaceholder text="No groups found." />
                        </Box>
                    )}
                    {(locationIds.length !== 0 && locationIdsToDisplay.length === 0) && (
                        <Box className={globalClasses.containerWithBorder}>
                            <NoItemsPlaceholder text="No groups found." />
                            {isFilterApplied && (
                                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                    <Typography>
                                        Try&nbsp;
                                    </Typography>
                                    <Typography onClick={resetFilter} color="primary" sx={{ textDecoration: "underline", cursor: "pointer" }}>
                                        removing the filters
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    )}
                    <Box display="flex" flexDirection="column" gap={1}>
                        {locationIdsToDisplay.map((locationId) => (
                            <Box key={locationId} data-cy="location-card">
                                <LocationCard key={locationId} stateContext={stateContext} locationId={locationId} />
                            </Box>
                        ))}
                    </Box>
                </>
            )}
            <Box display="flex" justifyContent="center" mt={2}>
                <PaginationBar
                    groupId={LOCATION_PAGINATION_GROUP_ID_PREFIX + propertyId}
                    items={filteredLocationIds}
                    itemsPerPage={LOCATIONS_PER_PAGE}
                    setItemsToDisplay={filterLocations}
                />
            </Box>
        </>
    );
};
