import Deserializer from "./Deserializer";
import JSONDeserializer from "./JSONDeserializer";

export default class JSONDeserializerFactory {
    static readonly INSTANCE = new JSONDeserializer();

    public static getInstance(): Deserializer<string, any> {
        return JSONDeserializerFactory.INSTANCE;
    }
}
