import { Issue } from "../../../models";
import { StateContext } from "../../design/document/state/StateContext";
import { TranscriptPromptType } from "../../../components/chime/hooks/prompts/TranscriptPromptType";
import { getUpdateIssueCallback } from "../../design/document/state/DocumentState";
import { isAiResourceGeneratingAtomFamily } from "../../../components/chime/hooks/state/AISummarizationStates";
import { useAISummarizer } from "../../../components/chime/hooks/useAISummarizer";
import { useRecoilCallback } from "recoil";

export const usePopulateIssueFromTranscript = (context: StateContext) => {
    const { generateFieldValue } = useAISummarizer();
    const updateIssue = getUpdateIssueCallback();

    const populateIssueFromTranscript = useRecoilCallback(({set}) => async (
        issue: Issue,
    ): Promise<Issue> => {
        set(isAiResourceGeneratingAtomFamily(issue.id), true);
        const title = await generateFieldValue(TranscriptPromptType.ISSUE_TITLE);
        const description = await generateFieldValue(TranscriptPromptType.ISSUE_DESCRIPTION);
        const updatedIssue: Issue = new Issue({
            ...issue,
            name: title,
            note: description
        });
        await updateIssue(updatedIssue, context);
        set(isAiResourceGeneratingAtomFamily(issue.id), false);
        return updatedIssue;
    }, [updateIssue, generateFieldValue]);

    return { populateIssueFromTranscript };
}