import ClientLogger from "../../logging/ClientLogger";
import { GoogleDriveFileDAO } from "./GoogleDriveFileDAO";
import { GoogleDriveFolder } from "../type/GoogleDriveFolder";
import { MimeType } from "../type/MimeType";
import { UnauthenticatedError } from "../error/UnauthenticatedError";

const UPLOAD_URL = "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart";

export class FetchGoogleDriveFileDAO implements GoogleDriveFileDAO {
    private static readonly CREATE_ATTEMPT = "CREATE_ATTEMPT";
    private static readonly CREATE_SUCCESS = "CREATE_SUCCESS";
    private static readonly CREATE_FAILURE = "CREATE_FAILURE";

    private readonly logger: ClientLogger;

    constructor(
        logger: ClientLogger,
    ) {
        this.logger = logger;
    }

    public async create(
        metadata: gapi.client.drive.File,
        contentBody: string | Blob,
        contentType: string
    ): Promise<gapi.client.drive.File> {
        try {
            this.logger.info(
                `Attempting to create Google Drive file: ${metadata.name}`,
                undefined,
                [FetchGoogleDriveFileDAO.CREATE_ATTEMPT]
            );
            const authToken = gapi.auth.getToken()?.access_token;
            if (!authToken) {
                throw new UnauthenticatedError("User is not authenticated.");
            }
            const formData = new FormData();
            formData.append("metadata", new Blob([JSON.stringify(metadata)], { type: "application/json" }));
            formData.append("media", new Blob([contentBody], { type: contentType }));
            const response = await fetch(
                UPLOAD_URL,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                    body: formData
                }
            );
            switch (response.status) {
                case 200:
                    this.logger.info(
                        `Successfully created Google Drive file: ${metadata.name}`,
                        undefined,
                        [FetchGoogleDriveFileDAO.CREATE_SUCCESS]
                    );
                    return await response.json() as gapi.client.drive.File;
                case 401:
                case 403:
                    throw new UnauthenticatedError("User is not authenticated.");
                default:
                    throw new Error(`Failed to create Google Drive file, response: ${response}`);
            }
        } catch (error) {
            if (!(error instanceof UnauthenticatedError)) {
                this.logger.error(
                    `Failed to create Google Drive file: ${metadata.name}`,
                    error,
                    [FetchGoogleDriveFileDAO.CREATE_FAILURE]
                );
            }
            throw error;
        }
    }

    public async list(
        name: string,
        mimeType: MimeType,
        parentFolder?: GoogleDriveFolder
    ): Promise<gapi.client.drive.File[]> {
        throw new Error("Method not implemented.");
    }

    public async get(fileId: string): Promise<Blob | undefined> {
        throw new Error("Method not implemented.");
    }

    public async export(
        fileId: string,
        exportMimeType: MimeType
    ): Promise<Blob | undefined> {
        throw new Error("Method not implemented.");
    }
}