import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const NoStatusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="M12 4c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8m0-2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2z"
        className="primary"
      />
      <path
        fill="currentColor"
        d="M13 7h-2v6h2V7zM13 15h-2v2h2v-2z"
        className="primary"
      />
    </g>
  </svg>
);
export default withCustomIcon(NoStatusIcon);
