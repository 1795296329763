import AttachmentContentDAO from "../../content/AttachmentContentDAO";
import AttachmentRecordDAO from "../../record/AttachmentRecordDAO";
import AttachmentUploadRequest from "../request/AttachmentUploadRequest";
import AttachmentUploadRequestRevokedError from "../errors/AttachmentUploadRequestRevokedError";
import { AttachmentUploadStatus } from "../status/AttachmentUploadStatus";
import AttachmentUploadStatusDAO from "../status/AttachmentUploadStatusDAO";
import AttachmentUploadStatusNotFoundError from "../errors/AttachmentUploadStatusNotFoundError";
import AttachmentUploadWorkerError from "../errors/AttachmentUploadWorkerError";
import Task from "../../../util/concurrency/Task";

export default class AttachmentUploadWorker implements Task<string> {
    private readonly attachmentUploadRequest: AttachmentUploadRequest;
    private readonly attachmentContentDAO: AttachmentContentDAO;
    private readonly cachingAttachmentRecordDAO: AttachmentRecordDAO;
    private readonly validationAttachmentRecordDAO: AttachmentRecordDAO;
    private readonly attachmentUploadStatusDAO: AttachmentUploadStatusDAO;
    private readonly attachmentId: string;
    private attachmentUploadStatus: AttachmentUploadStatus;

    constructor(
        attachmentUploadRequest: AttachmentUploadRequest,
        attachmentContentDAO: AttachmentContentDAO,
        cachingAttachmentRecordDAO: AttachmentRecordDAO,
        validationAttachmentRecordDAO: AttachmentRecordDAO,
        attachmentUploadStatusDAO: AttachmentUploadStatusDAO,
    ) {
        this.attachmentUploadRequest = attachmentUploadRequest;
        this.attachmentContentDAO = attachmentContentDAO;
        this.cachingAttachmentRecordDAO = cachingAttachmentRecordDAO;
        this.validationAttachmentRecordDAO = validationAttachmentRecordDAO;
        this.attachmentUploadStatusDAO = attachmentUploadStatusDAO;
        this.attachmentId = attachmentUploadRequest.record.id;
        this.attachmentUploadStatus = attachmentUploadRequest.uploadStatus;
    }

    public async run(): Promise<string> {
        if (this.attachmentUploadStatus === AttachmentUploadStatus.COMPLETE) {
            return this.attachmentId;
        }
        if (this.attachmentUploadStatus === AttachmentUploadStatus.PENDING_UPLOAD ||
            this.attachmentUploadStatus === AttachmentUploadStatus.UPLOAD_IN_PROGRESS ||
            this.attachmentUploadStatus === AttachmentUploadStatus.UPLOAD_FAILED
        ) {
            await this.upload();
        }
        if (this.attachmentUploadStatus === AttachmentUploadStatus.PENDING_RECORD_VALIDATION) {
            await this.validateAttachmentRecordExistsInAuthoritativeStore();
        }
        return this.attachmentId;
    }

    private async upload() {
        try {
            // Instruct DataStore to attempt sync of the record to cloud, in case automatic sync failed
            this.cachingAttachmentRecordDAO.getById(this.attachmentId);

            await this.updateUploadStatus(AttachmentUploadStatus.UPLOAD_IN_PROGRESS);
            await this.attachmentContentDAO.saveAttachment(
                this.attachmentUploadRequest.record.key!,
                this.attachmentUploadRequest.content
            );
            await this.updateUploadStatus(AttachmentUploadStatus.PENDING_RECORD_VALIDATION);
        } catch (error) {
            if (error instanceof AttachmentUploadRequestRevokedError) {
                return;
            }
            await this.updateUploadStatus(AttachmentUploadStatus.UPLOAD_FAILED);
            throw new AttachmentUploadWorkerError(this.attachmentId, "Failed to Upload Attachment");
        }
    }

    private async validateAttachmentRecordExistsInAuthoritativeStore() {
        try {
            await this.validationAttachmentRecordDAO.getById(this.attachmentUploadRequest.record.id);
            await this.updateUploadStatus(AttachmentUploadStatus.COMPLETE);
        } catch (error) {
            if (error instanceof AttachmentUploadRequestRevokedError) {
                return;
            }
            throw new AttachmentUploadWorkerError(this.attachmentId, "Could not validate that attachment record was created");
        }
    }

    private async updateUploadStatus(
        status: AttachmentUploadStatus
    ): Promise<void> {
        try {
            await this.attachmentUploadStatusDAO.getAttachmentUploadStatus(this.attachmentId);
            await this.attachmentUploadStatusDAO.setAttachmentUploadStatus(
                this.attachmentId,
                status
            );
            this.attachmentUploadStatus = status;
        } catch (error) {
            if (error instanceof AttachmentUploadStatusNotFoundError) {
                throw new AttachmentUploadRequestRevokedError(`Attachment upload request revoked for id: ${this.attachmentId}`);
            }
            throw error;
        }
    }
}
