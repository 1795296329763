import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { DataStoreCompletionEventListener } from "./DataStoreCompletionEventListener";
import { Hub } from "aws-amplify";

export class DataStoreCompletionEventListenerFactory {
    private static INSTANCE: DataStoreCompletionEventListener = new DataStoreCompletionEventListener(
        Hub,
        ClientLoggerFactory.getClientLogger("DataStoreCompletionEventListener")
    );

    public static getInstance(): DataStoreCompletionEventListener {
        return this.INSTANCE;
    }
}