import ClientLog from "./ClientLog";
import ClientLogDAO from "./ClientLogDAO";
import { LogSeverityLevel } from "./LogSeverityLevel";
import getUserAgentInfo from "../util/UserAgentDetector";
import UsernameSupplier from "../auth/UsernameSupplier";

export default class ClientLogger {

    private readonly clientLogDAO: ClientLogDAO;
    private readonly componentName: string;
    private readonly usernameSupplier: UsernameSupplier;
    
    constructor(
        clientLogDAO: ClientLogDAO,
        componentName: string,
        usernameSupplier: UsernameSupplier
    ) {
        this.clientLogDAO = clientLogDAO;
        this.componentName = componentName;
        this.usernameSupplier = usernameSupplier;
    }

    public async debug(
        message: string,
        details: any,
        tags: Array<string>
    ) {
        await this.log(message, details, tags, LogSeverityLevel.DEBUG);
    }

    public async info(
        message: string,
        details: any,
        tags: Array<string>
    ) {
        await this.log(message, details, tags, LogSeverityLevel.INFO);
    }

    public async warn(
        message: string,
        details: any,
        tags: Array<string>
    ) {
        await this.log(message, details, tags, LogSeverityLevel.WARN);
    }

    public async error(
        message: string,
        details: any,
        tags: Array<string>
    ) {
        await this.log(message, details, tags, LogSeverityLevel.ERROR);
    }

    private async log(
        message: string,
        details: any,
        tags: Array<string>,
        severityLevel: LogSeverityLevel
    ) {
        const additionalAttributes = await this.getAdditionalAttributes();
        const detailsJson = details ? JSON.stringify(details, Object.getOwnPropertyNames(details)) : undefined;
        const tagsJson = tags ? JSON.stringify(tags) : undefined;
        const clientLog: ClientLog = {
            ...additionalAttributes,
            message: message,
            details: detailsJson,
            tags: tagsJson,
            severityLevel: severityLevel
        };
        await this.clientLogDAO.save(clientLog);
    }

    private async getAdditionalAttributes() {
        const username: string = await this.usernameSupplier.get();
        return {
            userId: username,
            userAgent: getUserAgentInfo(),
            componentName: this.componentName
        }
    }
}
