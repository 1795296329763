import {
    EntityWorkTypeAssociation,
    WorkTypeAssociationScopeEntity
} from "../../../../models";

import ClientLogger from "../../../logging/ClientLogger";
import CreateEntityWorkTypeAssociationError from "../errors/CreateEntityWorkTypeAssociationError";
import { DataStoreClass } from "@aws-amplify/datastore";
import DeleteEntityWorkTypeAssociationError from "../errors/DeleteEntityWorkTypeAssociationError";
import EntityWorkTypeAssociationDAO from "../EntityWorkTypeAssociationDAO";
import GetEntityWorkTypeAssociationByEntityIdAndWorkTypeGroupIdError from "../errors/GetEntityWorkTypeAssociationByEntityIdAndWorkTypeGroupIdError";
import ListWorkTypeAssociationsByEntityIdError from "../errors/ListEntityWorkTypeAssociationsByEntityIdError";
import WorkTypeAssociationMetricNames from "../EntityWorkTypeAssociationMetricNames";

export default class DataStoreEntityWorkTypeAssociationDAO implements EntityWorkTypeAssociationDAO {
    private logger: ClientLogger;
    private dataStore: DataStoreClass;

    constructor(
        logger: ClientLogger,
        dataStore: DataStoreClass,
    ) {
        this.logger = logger;
        this.dataStore = dataStore;
    }

    public async create(
        entityId: string,
        entityType: WorkTypeAssociationScopeEntity,
        workTypeGroupId: string,
        workTypeVersion: number | null
    ) : Promise<EntityWorkTypeAssociation> {
        try {
            this.logger.info(
                `Creating entity-workType association workType: ${workTypeGroupId} version ${workTypeVersion}, entityId: ${entityId} and entityType: ${entityType}`,
                undefined,
                [WorkTypeAssociationMetricNames.CREATE_ATTEMPT]
            );
            const optionalAssociation: EntityWorkTypeAssociation | undefined = await this.getByEntityIdAndWorkTypeGroupId(entityId, workTypeGroupId);
            if (optionalAssociation) {
                return optionalAssociation;
            }
            const associationToCreate: EntityWorkTypeAssociation = new EntityWorkTypeAssociation({
                entityId: entityId,
                entityType: entityType,
                workTypeGroupId: workTypeGroupId,
                workTypeVersion: workTypeVersion
            });
            return await this.dataStore.save(associationToCreate);
        } catch (error) {
            const errorMessage: string = `Unable to create entity-workType association with workType: ${workTypeGroupId} version ${workTypeVersion}, entityId: ${entityId} and entityType: ${entityType}`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeAssociationMetricNames.CREATE_FAILURE]
            );
            throw new CreateEntityWorkTypeAssociationError(errorMessage, error as Error);
        }
    }

    public async delete(
        associationId: string
    ): Promise<void> {
        this.logger.info(
            `Deleting entity-workType association with id: ${associationId}`,
            undefined,
            [WorkTypeAssociationMetricNames.DELETE_ATTEMPT]
        );
        try {
            await this.dataStore.delete(EntityWorkTypeAssociation, associationId);
        } catch (error) {
            const errorMessage: string = `Unable to delete entity-workType association with id: ${associationId}`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeAssociationMetricNames.DELETE_FAILURE]
            );
            throw new DeleteEntityWorkTypeAssociationError(errorMessage, error as Error);
        }
    }

    public async getByEntityIdAndWorkTypeGroupId(
        entityId: string, 
        workTypeGroupId: string
    ): Promise<EntityWorkTypeAssociation | undefined> {
        try {
            this.logger.info(
                `Getting entity-workType association for entity: ${entityId} and workType: ${workTypeGroupId}`,
                undefined,
                [WorkTypeAssociationMetricNames.LIST_BY_ENTITY_ID_AND_WORK_TYPE_GROUP_ID_ATTEMPT]
            )
            const associations: Array<EntityWorkTypeAssociation> = await this.dataStore.query(
                EntityWorkTypeAssociation,
                workType => workType.entityId("eq", entityId).workTypeGroupId("eq", workTypeGroupId)
            );
            if (associations.length === 0) {
                return undefined;
            }
            return associations[0];
        } catch (error) {
            const errorMessage: string = `Unable to get entity-workType association for entity: ${entityId} and workType: ${workTypeGroupId}`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeAssociationMetricNames.LIST_BY_ENTITY_ID_AND_WORK_TYPE_GROUP_ID_FAILURE]
            )
            throw new GetEntityWorkTypeAssociationByEntityIdAndWorkTypeGroupIdError(errorMessage, error as Error);
        }
    }

    public async listByEntityId(
        entityId: string
    ): Promise<Array<EntityWorkTypeAssociation>> {
        try {
            this.logger.info(
                `Listing entity-workType associations for entity: ${entityId}`,
                undefined,
                [WorkTypeAssociationMetricNames.LIST_BY_ENTITY_ID_ATTEMPT]
            )
            const associations: Array<EntityWorkTypeAssociation> = await this.dataStore.query(
                EntityWorkTypeAssociation,
                workType => workType.entityId("eq", entityId)
            );
            return associations;
        } catch (error) {
            const errorMessage: string = `Unable to list entity-workType associations for entity: ${entityId}`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeAssociationMetricNames.LIST_BY_ENTITY_ID_FAILURE]
            )
            throw new ListWorkTypeAssociationsByEntityIdError(errorMessage, error as Error);
        }
    }
}
