import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    List,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React, {
    useEffect,
    useState
} from "react";
import {
    filteredWorkTypeDataGroupsSelector,
    getAssociateWorkTypeCallback,
    getFetchDiscoverableWorkTypesCallback
} from "../../lib/worktype/state/WorkTypeState";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import CloseIcon from '@mui/icons-material/Close';
import DataGroup from "../../lib/util/data/group/DataGroup";
import NoItemsPlaceholder from "../../components/NoItemsPlaceholder";
import WorkTypeDTO from "../../lib/worktype/DTO/WorkTypeDTO";
import WorkTypePickerCategoryListItem from "./WorkTypePickerCategoryListItem";
import { actingAsEntityIdAtom } from "../../lib/userSession/state/UserSessionState";
import globalStyle from "../../assets/style/globalStyle";
import grey from "@mui/material/colors/grey";
import { selectedWorkTypeGroupIdsAtom } from "../../lib/worktype/state/WorkTypePickerState";

interface WorkTypePickerProps {
    isOpen: boolean;
    onClose: () => void;
}

const WorkTypePicker = (props: WorkTypePickerProps) => {
    /* Internal page states */
    const { isOpen, onClose } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const actingAsEntityId = useRecoilValue<string>(actingAsEntityIdAtom);
    const filteredWorkTypeCategories: Array<DataGroup<string, WorkTypeDTO>> = useRecoilValue(filteredWorkTypeDataGroupsSelector);
    const [selectedWorkTypeGroupIds, setSelectedWorkTypeGroupIds] = useRecoilState(selectedWorkTypeGroupIdsAtom);
    const [isPageInitialized, setIsPageInitialized] = useState<boolean>(false);

    /* Dependencies */
    const globalClasses = globalStyle();
    const fetchDiscoverableWorkTypes = getFetchDiscoverableWorkTypesCallback();
    const associateWorkType = getAssociateWorkTypeCallback();

    useEffect(() => {
        const initialize = async () => {
            await fetchDiscoverableWorkTypes();
            setIsPageInitialized(true);
        };
        initialize();
    }, []);

    const onAssociateWorkTypes = async () => {
        for (const workTypeGroupId of selectedWorkTypeGroupIds) {
            await associateWorkType(actingAsEntityId, workTypeGroupId)
        }
        setSelectedWorkTypeGroupIds([]);
        onClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            scroll="paper"
            fullWidth
            fullScreen={fullScreen}
            maxWidth="lg"
            PaperProps={{
                sx: {
                    backgroundColor: grey[50],
                    height: {
                        md: "80vh"
                    }
                }
            }}
        >
            <DialogTitle>
                <Grid container width="100%" justifyContent="center" alignItems="center">
                    <Typography variant="h5">
                        Add Work Types to Organization
                    </Typography>
                    <IconButton
                        className={globalClasses.squareIconButton}
                        size="small"
                        style={{
                            position: 'absolute',
                            right: 10
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    paddingLeft: {
                        xs: 0,
                        sm: 3
                    },
                    paddingRight: {
                        xs: 0,
                        sm: 3
                    },
                    paddingBottom: 1
                }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography>
                        <Checkbox
                            onChange={(event) => {
                                if (event.target.checked) {
                                    setSelectedWorkTypeGroupIds(filteredWorkTypeCategories.map(
                                        (workTypeCategory) => workTypeCategory.dataItems.map((workType) => workType.groupId)
                                    ).flat())
                                } else {
                                    setSelectedWorkTypeGroupIds([])
                                }
                            }}
                            checked={
                                selectedWorkTypeGroupIds.length === filteredWorkTypeCategories.map(
                                    (workTypeCategory) => workTypeCategory.dataItems.map((workType) => workType.groupId)
                                ).flat().length
                            }
                        />
                        Select all
                    </Typography>
                    <Typography variant="h6">
                        Available Work Types
                    </Typography>
                    <Typography>
                        {selectedWorkTypeGroupIds.length} Work Types selected
                    </Typography>
                </Stack>
                {!isPageInitialized ? (
                    <LinearProgress />
                ) : (
                    filteredWorkTypeCategories.length === 0 ? (
                        <Box className={globalClasses.containerWithBorder}>
                            <NoItemsPlaceholder text="No more available Work Types for now." />
                        </Box>
                    ) : (
                        <List disablePadding>
                            {filteredWorkTypeCategories?.map((workTypeCategory, index) => {
                                return (
                                    <React.Fragment key={workTypeCategory.key}>
                                        <WorkTypePickerCategoryListItem
                                            workTypeCategory={workTypeCategory}
                                        />
                                        {index !== filteredWorkTypeCategories.length - 1 && <Divider />}
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    )
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() => onAssociateWorkTypes()}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WorkTypePicker;
