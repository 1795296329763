import {
    ManageResourcePermissionInput,
    PermissionResourceType,
    PermissionType
} from "../../../API";

import { ManageResourcePermissionClient } from "../ManageResourcePermissionClient";
import { ManageResourcePermissionRequestBuilder } from "./ManageResourcePermissionRequestBuilder";
import { PermissionChangeHandler } from "./PermissionChangeHandler";
import { PermissionDifference } from "./PermissionDifference";
import { PermissionDifferenceCalculator } from "./PermissionDifferenceCalculator";
import { PermissionEntityType } from "../../../models";

export class PermissionChangeHandlerImpl implements PermissionChangeHandler {
    private differenceCalculator: PermissionDifferenceCalculator;
    private manageResourcePermissionClient: ManageResourcePermissionClient;
    private revokeResourcePermissionRequestBuilder: ManageResourcePermissionRequestBuilder;
    private shareResourcePermissionRequestBuilder: ManageResourcePermissionRequestBuilder;

    constructor(
        differenceCalculator: PermissionDifferenceCalculator,
        manageResourcePermissionClient: ManageResourcePermissionClient,
        revokeResourcePermissionRequestBuilder: ManageResourcePermissionRequestBuilder,
        shareResourcePermissionRequestBuilder: ManageResourcePermissionRequestBuilder
    ) {
        this.differenceCalculator = differenceCalculator;
        this.manageResourcePermissionClient = manageResourcePermissionClient;
        this.revokeResourcePermissionRequestBuilder = revokeResourcePermissionRequestBuilder;
        this.shareResourcePermissionRequestBuilder = shareResourcePermissionRequestBuilder;
    }

    public async changeEntityPermissionsForResource(
        entityId: string,
        entityType: PermissionEntityType,
        resourceId: string,
        resourceType: PermissionResourceType,
        propertyId: string,
        currentPermissions: Array<PermissionType>,
        newPermissions: Array<PermissionType>
    ): Promise<void> {
        const delta: PermissionDifference = this.differenceCalculator.getDifference(currentPermissions, newPermissions);

        const shareResourcePermissionRequests: Array<ManageResourcePermissionInput> = await this.shareResourcePermissionRequestBuilder.create(
            entityId,
            entityType,
            resourceType,
            resourceId,
            propertyId,
            delta.permissionsToAdd
        );
        const revokeResourcePermissionRequests: Array<ManageResourcePermissionInput> = await this.revokeResourcePermissionRequestBuilder.create(
            entityId,
            entityType,
            resourceType,
            resourceId,
            propertyId,
            delta.permissionsToRemove
        );
        let [sharePromise, revokePromise]: Array<Promise<void> | undefined> = [undefined, undefined];
        if (shareResourcePermissionRequests.length > 0) {
            sharePromise = this.manageResourcePermissionClient.share(shareResourcePermissionRequests);
        }
        if (revokeResourcePermissionRequests.length > 0) {
            revokePromise = this.manageResourcePermissionClient.revoke(revokeResourcePermissionRequests);
        }
        await Promise.all([sharePromise, revokePromise]);
    }
}