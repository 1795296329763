import ClientLogger from "../../../logging/ClientLogger";
import { DataStoreClass } from "@aws-amplify/datastore";
import { Location } from "../../../../models";
import LocationDAO from "../LocationDAO";
import LocationMetricNames from "../../LocationMetricNames";
import ResourceNotFoundError from "../../../ResourceNotFoundError";
import _ from "lodash";

export default class DataStoreLocationDAO implements LocationDAO {
    private readonly dataStore: DataStoreClass;
    private readonly logger: ClientLogger;

    constructor(
        dataStore: DataStoreClass,
        logger: ClientLogger
    ) {
        this.dataStore = dataStore;
        this.logger = logger;
    }

    public async getLocationById(locationId: string): Promise<Location> {
        const result: Location[] = await this.dataStore.query(Location, location => location.id("eq", locationId));
        if (result.length === 0) {
            throw new ResourceNotFoundError("Location");
        }
        return result[0];
    }

    public async getLocationsByPropertyId(propertyId: string): Promise<Array<Location>> {
        return await this.dataStore.query(Location, location => location.propertyId("eq", propertyId));
    }

    public async getLocationCountByParentId(parentId: string): Promise<number> {
        const locations: Array<Location> = await this.getLocationsByPropertyId(parentId);
        return locations.length;
    }

    public async editLocation(
        locationId: string,
        updatedLocation: Location
    ): Promise<Location> {
        try {
            const existingLocation: Location = await this.getLocationById(locationId);
            let result: Location = existingLocation;
            if (!_.isEqual(existingLocation, updatedLocation)) {
                result = await this.dataStore.save(
                    Location.copyOf(existingLocation, (locationToUpdate) => {
                        locationToUpdate.propertyId = updatedLocation.propertyId;
                        locationToUpdate.name = updatedLocation.name;
                        locationToUpdate.localLastUpdatedTime = Date.now();
                    })
                );
            }
            return result;
        } catch (error) {
            this.logger.warn(
                "Failed to update the location",
                error,
                ["LocationUpdateFailure"]
            );
            throw new Error("Failed to update the location");
        }
    }

    public async create(formState: Location): Promise<Location> {
        try {
            const locationToCreate: Location = new Location({
                ...formState,
                localCreationTime: Date.now(),
                localLastUpdatedTime: Date.now()
            });
            const createdLocation: Location = await this.dataStore.save(locationToCreate);
            return createdLocation;
        } catch (error) {
            this.logger.warn(
                "Failed to create the location",
                error,
                [LocationMetricNames.CREATE_FAILURE]
            );
            throw new Error("Failed to create the location");
        }
    }

    public async deleteLocation(locationId: string): Promise<void> {
        try {
            this.logger.info(
                `Deleting location with id: ${locationId}`,
                undefined,
                [LocationMetricNames.DELETE_ATTEMPT]
            );
            this.dataStore.delete(Location, locationId);
        } catch (error) {
            this.logger.error(
                `Failed to delete issue with id: ${locationId}`,
                error,
                [LocationMetricNames.DELETE_FAILURE]
            );
            throw error;
        }
    }
}
