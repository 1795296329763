import {
    Box,
    FormControlLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material"

import { issuesPerPageAtom } from "../../lib/design/document/state/DocumentState";
import { useRecoilState } from "recoil";

export const IssuePaginationSizeForm = () => {
    const [issuesPerPage, setIssuesPerPage] = useRecoilState<number>(issuesPerPageAtom);
    
    return (
        <FormControlLabel
            sx={{
                m: 0,
                ml: "auto"
            }}
            control={
                <Select
                    size="small"
                    labelId="issue-manager-page-size-label"
                    value={issuesPerPage}
                    onChange={(e) => setIssuesPerPage(e.target.value as number)}
                >
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                </Select>
            }
            label={
                <Box mr={1}>
                    <Typography variant="body2">
                        View:
                    </Typography>
                </Box>
            }
            labelPlacement="start"
        />
    );
}