import {
    Dimension,
    DimensionType,
    MeasurementUnit,
    PermissionResourceType,
    WorkSpecification
} from "../../../../models";
import {
    dimensionByDimensionIdentifierSelectorFamily,
    dimensionToWorkSpecMeasurementPropagationBehavior,
    isEditModeByDesignElementIdentifierAtomFamily,
    recentlyCreatedRecordIds,
    workSpecificationByWorkSpecificationIdentifierSelectorFamily
} from "../../document/state/DocumentState";

import { ChildrenExpandState } from "../../document/state/enum/ChildrenExpandState";
import { ContextAwareIdentifier } from "../../document/ContextAwareIdentifier";
import CreateWorkScopeSpecificationError from "../dao/error/CreateWorkScopeSpecificationError";
import DataStoreWorkScopeSpecificationDAOFactory from "../dao/datastore/DataStoreWorkScopeSpecificationDAOFactory";
import { MeasurementPropagationBehavior } from "../../../measurement/MeasurementPropagationBehavior";
import { ModelType } from "../../document/ModelType";
import { StateContext } from "../../document/state/StateContext";
import { childrenExpandStateByDesignElementIdentifierAtomFamily } from "../../document/state/ComponentExpandState";
import { createProposalItem } from "../../bidding/state/v2/ProposalItemStates";
import { fetchPermissionsForResource } from "../../../permission/state/ResourcePermissionRecoilState";
import { useCallback } from "react";
import { useRecoilCallback } from "recoil";
import { useUpdateDimension } from "../../../dimension/hook/useUpdateDimension";

const workSpecificationDAO = DataStoreWorkScopeSpecificationDAOFactory.getInstance();

export const useCreateWorkSpecification = (context: StateContext) => {
    const { updateDimension } = useUpdateDimension(context);

    const createWorkSpecification = useRecoilCallback(({ snapshot, set }) => async (
        workSpecification: WorkSpecification,
        isExpanded: boolean = false
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            const createdInSolution: boolean = context === StateContext.SOLUTION_AUTHORING_CONTENT
            let workSpecificationToCreate: WorkSpecification = { ...workSpecification, createdInSolution };

            const parentDimensionIdentifier: ContextAwareIdentifier = new ContextAwareIdentifier(workSpecification.dimensionId!, context, ModelType.DIMENSION);
            const measurementPropagationBehavior = snapshot.getLoadable<MeasurementPropagationBehavior>(
                dimensionToWorkSpecMeasurementPropagationBehavior(parentDimensionIdentifier)
            ).contents;

            if (measurementPropagationBehavior === MeasurementPropagationBehavior.COPY) {
                const parentDimension: Dimension | null = await snapshot.getLoadable<Dimension | null>(
                    dimensionByDimensionIdentifierSelectorFamily(parentDimensionIdentifier)
                ).contents;
                if (parentDimension && parentDimension.measurement?.length) { // If we had just created the dimension, it won't be in the snapshot yet.
                    const measurement = workSpecification.measurement?.length ? workSpecification.measurement : { ...parentDimension.measurement, dimensionType: DimensionType.SQUARE };
                    workSpecificationToCreate = {
                        ...workSpecification,
                        measurement: measurement
                    };
                }
                if (parentDimension) {
                    const updatedDimensionValue: Dimension = {
                        ...parentDimension,
                        measurement: workSpecification.measurement
                    };
                    await updateDimension(updatedDimensionValue);
                }
            }

            const createdWorkSpecification: WorkSpecification = await workSpecificationDAO.create(workSpecificationToCreate);
            await fetchPermissionsForResource(createdWorkSpecification.id, PermissionResourceType.WORK_SPECIFICATION, set);
            const workSpecificationIdentifier = new ContextAwareIdentifier(createdWorkSpecification.id, context, ModelType.WORK_SPECIFICATION);
            if (isExpanded) {
                set(childrenExpandStateByDesignElementIdentifierAtomFamily(workSpecificationIdentifier), ChildrenExpandState.EXPAND_CHILDREN);
            }
            set(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier), createdWorkSpecification);
            set(recentlyCreatedRecordIds, (prevValue: Set<string>) => {
                const copy = new Set(prevValue);
                copy.add(createdWorkSpecification.id);
                return copy;
            });
            set(isEditModeByDesignElementIdentifierAtomFamily(workSpecificationIdentifier), true);
            createProposalItem(set, context, createdWorkSpecification, snapshot);
            return createdWorkSpecification;
        } catch (error) {
            throw new CreateWorkScopeSpecificationError(
                `Error occurred while trying to Create WorkSpecification State for ${workSpecification.id}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    }, [context]);

    const createDefaultWorkSpecification = useCallback(async (
        dimensionId: string | null | undefined,
        issueId: string | null | undefined,
        propertyId: string | null | undefined,
        isExpanded: boolean = false
    ) => {
        const defaultWorkSpecification = new WorkSpecification({
            dimensionId: dimensionId,
            issueId: issueId,
            propertyId: propertyId,
            measurement: {
                dimensionType: DimensionType.SQUARE,
                measurementUnit: MeasurementUnit.FOOT
            }
        });
        await createWorkSpecification(defaultWorkSpecification, isExpanded);
    }, [createWorkSpecification]);

    return { createWorkSpecification, createDefaultWorkSpecification };
};
