import {
    CreateTenderMutation,
    CreateTenderMutationVariables,
    GetTenderQuery,
    GetTenderQueryVariables,
    ListTendersByProjectNumberQuery,
    ListTendersByProjectNumberQueryVariables,
    Tender,
    UpdateTenderMutation,
    UpdateTenderMutationVariables
} from "../../../../../API";
import GraphQLAPI, {
    GraphQLResult
} from "@aws-amplify/api-graphql";
import {
    createTender,
    updateTender
} from "../../../../../graphql/mutations";
import {
    getTender,
    listTendersByProjectNumber
} from "../../../../../graphql/queries";

import ClientLogger from "../../../../logging/ClientLogger";
import { IDTokenSupplier } from "../../../../auth/IDTokenSupplier";
import { TenderMajorVersionDAO } from "../TenderMajorVersionDAO";
import { TenderNotFoundError } from "../error/TenderNotFoundError";

export class GraphQLTenderMajorVersionDAO implements TenderMajorVersionDAO {
    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async get(id: string): Promise<Tender> {
        try {
            this.logger.info(
                `Retrieving Tender with id=${id}`,
                undefined,
                ["GraphQLTenderDAO.Get.Attempt"]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: GetTenderQueryVariables = {
                id: id
            };
            const result: GraphQLResult<GetTenderQuery> = await GraphQLAPI.graphql({
                query: getTender,
                variables: variables,
                authToken: authToken
            }) as GraphQLResult<GetTenderQuery>;
            return result.data?.getTender!;
        } catch (error) {
            const errorMessage = `Failed to retrieve Tender with id=${id}`;
            this.logger.error(
                errorMessage,
                error,
                ["GraphQLTenderDAO.Get.Failure"]
            );
            throw new Error(errorMessage);
        }
    }

    public async getByProjectNumber(projectNumber: number): Promise<Tender> {
        try {
            this.logger.info(
                `Retrieving Tender with projectId=${projectNumber}`,
                undefined,
                ["GraphQLTenderDAO.Get.Attempt"]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: ListTendersByProjectNumberQueryVariables = { projectNumber: projectNumber };
            const result: GraphQLResult<ListTendersByProjectNumberQuery> = await GraphQLAPI.graphql({
                query: listTendersByProjectNumber,
                variables: variables,
                authToken: authToken
            }) as GraphQLResult<ListTendersByProjectNumberQuery>;
            const items = result.data?.listTendersByProjectNumber!.items;
            if (items == undefined || items.length == 0) {
                throw new TenderNotFoundError(`Did not find Tender with projectNumber ${projectNumber}`)
            }
            return items[0]!;
        } catch (error) {
            if (error instanceof TenderNotFoundError) {
                this.logger.warn(
                    error.message,
                    error,
                    ["GraphQLTenderDAO.GetByProjectNumber.NotFound"]
                );
                throw error;
            }
            const errorMessage = `Failed to retrieve Tender with projectNumber=${projectNumber}`;
            this.logger.error(
                errorMessage,
                error,
                ["GraphQLTenderDAO.GetByProjectNumber.Failure"]
            );
            throw new Error(errorMessage);
        }
    }

    public async create(tender: Tender): Promise<Tender> {
        try {
            this.logger.info(
                `Creating Tender for solution with id=${tender.solutionId}`,
                tender,
                ["GraphQLTenderDAO.Create.Attempt"]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: CreateTenderMutationVariables = {
                input: tender
            }
            const result: GraphQLResult<CreateTenderMutation> = await GraphQLAPI.graphql({
                query: createTender,
                variables: variables,
                authToken: authToken
            }) as GraphQLResult<CreateTenderMutation>;
            return result.data?.createTender!;
        } catch (error) {
            console.log(error);
            const errorMessage = `Failed to create Tender for solution with Id=${tender.solutionId}`;
            this.logger.error(
                errorMessage,
                error,
                ["GraphQLTenderDAO.Create.Failure"]
            );
            throw new Error(errorMessage);
        }
    }

    public async update(id: string, tender: Tender): Promise<Tender> {
        try {
            this.logger.info(
                `Updating Tender with id=${id}`,
                tender,
                ["GraphQLTenderDAO.Update.Attempt"]
            );
            const existingTender: Tender = await this.get(id);
            const authToken: string = await this.idTokenSupplier.get();
            const variables: UpdateTenderMutationVariables = {
                input: {
                    ...tender,
                    _version: existingTender._version
                }
            }
            const result: GraphQLResult<UpdateTenderMutation> = await GraphQLAPI.graphql({
                query: updateTender,
                variables: variables,
                authToken: authToken
            }) as GraphQLResult<UpdateTenderMutation>;
            return result.data?.updateTender!;

        } catch (error) {
            const errorMessage = `Failed to update Tender with id=${tender.id}`;
            this.logger.error(
                errorMessage,
                error,
                ["GraphQLTenderDAO.Update.Failure"]
            );
            throw new Error(errorMessage);
        }
    }
}