import {
    Proposal,
    ProposalStatus
} from "../../../../models";

import ProposalContent from "../../../design/bidding/ProposalContent";
import ProposalContentDAO from "../../dao/content/ProposalContentDAO";
import ProposalRecordDAO from "../../dao/record/ProposalRecordDAO";
import SaveProposalHandler from "./SaveProposalHandler";
import SaveProposalHandlerError from './error/SaveProposalHandlerError';
import uuidv4 from "../../../util/UuidGenerator";

export default class SaveProposalHandlerImpl implements SaveProposalHandler {

    private readonly proposalContentDAO: ProposalContentDAO;
    private readonly proposalRecordDAO: ProposalRecordDAO;

    constructor(
        proposalContentDAO: ProposalContentDAO,
        proposalRecordDAO: ProposalRecordDAO
    ) {
        this.proposalContentDAO = proposalContentDAO;
        this.proposalRecordDAO = proposalRecordDAO;
    }

    public async handle(
        solutionId: string,
        solutionMinorVersion: number,
        proposalContent: ProposalContent
    ): Promise<Proposal> {
        try {
            const proposalId: string = uuidv4();
            // Save the content first; in case it fails, we will not advertise the record to the user
            const contentKey: string = await this.proposalContentDAO.save(proposalContent);

            // Create the record
            const proposal: Proposal = {
                id: proposalId,
                designDocumentGroupId: solutionId,
                designDocumentVersion: solutionMinorVersion,
                proposalKey: contentKey,
                status: ProposalStatus.DRAFT,
                schemaVersion: 2
            };
            return await this.proposalRecordDAO.create(proposal);
        } catch (error) {
            // TODO logger
            throw new SaveProposalHandlerError(
                `Failed to save proposal with solutionId=${solutionId} and solutionMinorVersion=${solutionMinorVersion}`,
                {cause: error as Error}
            );
        }
    }
}
