import {
    RECONNECTING_SNACKBAR_KEY,
    useConnectivityMonitor
} from "./hooks/useConnectivityMonitor";

import { EventObserver } from "amazon-chime-sdk-js";
import { useDeviceChangeSubscription } from "./hooks/useDeviceChangeSubscription";
import { useEffect } from "react";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useOnAttendeePresenceReceived } from "./hooks/useOnAttendeePresenceReceived";
import { useOnMeetingStartRequested } from "./hooks/useOnMeetingStartRequested";
import { useSnackbar } from "notistack";

export const MeetingEventManager = () => {
    const meetingManager = useMeetingManager();
    const { onMeetingStartRequested } = useOnMeetingStartRequested();
    const { subscribeToDeviceChange, unsubscribeFromDeviceChange } = useDeviceChangeSubscription();
    const { subscribeToAttendeePresence, unsubscribeFromAttendeePresence } = useOnAttendeePresenceReceived();
    const { subscribeToConnectivityEvents, unsubscribeFromConnectivityEvents } = useConnectivityMonitor();
    const snackbar = useSnackbar();

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined;
        const eventDidReceive: EventObserver["eventDidReceive"] = (name, attributes) => {
            switch (name) {
                case "meetingStartRequested":
                    onMeetingStartRequested();
                    break;
                case 'meetingStartSucceeded':
                    // Adding a delay to avoid receiving updates from attendee who joined before the current user has joined
                    timeout = setTimeout(subscribeToAttendeePresence, 100);
                    subscribeToDeviceChange();
                    subscribeToConnectivityEvents();
                    break;
                case 'meetingEnded':
                    unsubscribeFromDeviceChange();
                    unsubscribeFromAttendeePresence();
                    unsubscribeFromConnectivityEvents();
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                    break;
                case 'meetingReconnected':
                    snackbar.enqueueSnackbar('Reconnected to meeting', { key: RECONNECTING_SNACKBAR_KEY, variant: 'success' });
                    break;
                case "meetingStartFailed":
                    snackbar.enqueueSnackbar(attributes.meetingErrorMessage ?? 'Failed to start / join meeting', { variant: 'error' });
                    break;
            }
        };
        meetingManager.subscribeToEventDidReceive(eventDidReceive);
        return () => {
            meetingManager.unsubscribeFromEventDidReceive(eventDidReceive);
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [meetingManager]);

    return <></>;
};