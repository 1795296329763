import { AttachmentParent } from "../../../componentsV2/image/AttachmentParent";
import AttachmentRecordDAO from "../record/AttachmentRecordDAO";
import DataStoreAttachmentRecordDAOFactory from "../record/datastore/DataStoreAttachmentRecordDAOFactory";
import { useQuery } from "react-query";

const attachmentRecordDAO: AttachmentRecordDAO = DataStoreAttachmentRecordDAOFactory.getInstance();

export const useFetchAttachments = (attachmentParent: AttachmentParent) => {
    const { data: attachments, status, refetch } = useQuery({
        queryKey: ["attachments", attachmentParent],
        queryFn: () => fetchAttachments()
    });

    const fetchAttachments = async () => {
        return await attachmentRecordDAO.listMostRecentByParentId(attachmentParent.id);
    };

    return { attachments, status, refetch };
};