import {
    CreateTenderMinorVersionMutation,
    CreateTenderMinorVersionMutationVariables,
    GetTenderMinorVersionQuery,
    GetTenderMinorVersionQueryVariables,
    UpdateTenderMinorVersionMutation,
    UpdateTenderMinorVersionMutationVariables
} from "../../../../../API";
import GraphQLAPI, {
    GraphQLResult
} from "@aws-amplify/api-graphql";
import {
    createTenderMinorVersion,
    updateTenderMinorVersion
} from "../../../../../graphql/mutations";

import ClientLogger from "../../../../logging/ClientLogger";
import { IDTokenSupplier } from "../../../../auth/IDTokenSupplier";
import { TenderMinorVersion } from "../../../../../models";
import { TenderMinorVersionDAO } from "../TenderMinorVersionDAO";
import { getTenderMinorVersion } from "../../../../../graphql/queries";

export class GraphQLTenderMinorVersionDAO implements TenderMinorVersionDAO {
    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async get(id: string): Promise<TenderMinorVersion> {
        try {
            this.logger.info(
                `Retrieving TenderMinorVersion with id=${id}`,
                undefined,
                ["GraphQLTenderMinorVersionDAO.Get.Attempt"]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: GetTenderMinorVersionQueryVariables = {
                id: id
            }
            const result: GraphQLResult<GetTenderMinorVersionQuery> = await GraphQLAPI.graphql({
                query: getTenderMinorVersion,
                variables: variables,
                authToken: authToken
            }) as GraphQLResult<GetTenderMinorVersionQuery>;
            return result.data?.getTenderMinorVersion!;
        } catch (error) {
            const errorMessage = `Failed to retrieve TenderMinorVersion with id=${id}`;
            this.logger.error(
                errorMessage,
                error,
                ["GraphQLTenderMinorVersionDAO.Get.Failure"]
            );
            throw new Error(errorMessage);
        }
    }

    public async create(tenderMinorVersion: Omit<TenderMinorVersion, "id">): Promise<TenderMinorVersion> {
        try {
            this.logger.info(
                `Creating TenderMinorVersion for solution with id=${tenderMinorVersion.solutionId}`,
                tenderMinorVersion,
                ["GraphQLTenderMinorVersionDAO.Create.Attempt"]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: CreateTenderMinorVersionMutationVariables = {
                input: tenderMinorVersion
            }
            const result: GraphQLResult<CreateTenderMinorVersionMutation> = await GraphQLAPI.graphql({
                query: createTenderMinorVersion,
                variables: variables,
                authToken: authToken
            }) as GraphQLResult<CreateTenderMinorVersionMutation>;
            return result.data?.createTenderMinorVersion!;
        
        } catch (error) {
            const errorMessage = `Failed to create TenderMinorVersion for solution with Id=${tenderMinorVersion.solutionId}`;
            this.logger.error(
                errorMessage,
                error,
                ["GraphQLTenderMinorVersionDAO.Create.Failure"]
            );
            throw new Error(errorMessage);
        }
    }

    public async update(id: string, tenderMinorVersion: TenderMinorVersion): Promise<TenderMinorVersion> {
        try {
            this.logger.info(
                `Updating TenderMinorVersion with id=${id}`,
                tenderMinorVersion,
                ["GraphQLTenderMinorVersionDAO.Update.Attempt"]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: UpdateTenderMinorVersionMutationVariables = {
                input: tenderMinorVersion
            }
            const result: GraphQLResult<UpdateTenderMinorVersionMutation> = await GraphQLAPI.graphql({
                query: updateTenderMinorVersion,
                variables: variables,
                authToken: authToken
            }) as GraphQLResult<UpdateTenderMinorVersionMutation>;
            return result.data?.updateTenderMinorVersion!;
        } catch (error) {
            const errorMessage = `Failed to update TenderMinorVersion with id=${tenderMinorVersion.id}`;
            this.logger.error(
                errorMessage,
                error,
                ["GraphQLTenderMinorVersionDAO.Update.Failure"]
            );
            throw new Error(errorMessage);
        }
    }    
}