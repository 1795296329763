import GraphQLAPI, {
    GraphQLResult
} from "@aws-amplify/api-graphql";
import {
    CreateChimeMeetingMutation,
    CreateChimeMeetingMutationVariables,
    EndChimeMeetingMutation,
    EndChimeMeetingMutationVariables,
    JoinChimeMeetingPublicMutation,
    JoinChimeMeetingPublicMutationVariables,
    JoinChimeMeetingMutation,
    JoinChimeMeetingMutationVariables,
    StartChimeMeetingRecordingMutation,
    StartChimeMeetingRecordingMutationVariables
} from "../../../API";
import {
    createChimeMeeting,
    endChimeMeeting,
    joinChimeMeeting,
    joinChimeMeetingPublic,
    startChimeMeetingRecording
} from "../../../graphql/mutations";
import {
    AttendeeRole,
    ChimeMeetingOutput
} from "../../../models";

import { AuthIDTokenSupplierFactory } from "../../auth/AuthIDTokenSupplierFactory";
import { IDTokenSupplier } from "../../auth/IDTokenSupplier";
import ClientLogger from "../../logging/ClientLogger";
import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { MeetingClient } from "./MeetingClient";
import { ChimeMeetingDTO } from "./type/ChimeMeetingDTO";

export class GraphQLMeetingClient implements MeetingClient {
    private static readonly CREATE_MEETING_ATTEMPT_METRIC_NAME = "GraphQLChimeMeetingClient.CreateMeeting.Attempt";
    private static readonly CREATE_MEETING_SUCCESS_METRIC_NAME = "GraphQLChimeMeetingClient.CreateMeeting.Success";
    private static readonly CREATE_MEETING_FAILURE_METRIC_NAME = "GraphQLChimeMeetingClient.CreateMeeting.Failure";
    private static readonly JOIN_MEETING_ATTEMPT_METRIC_NAME = "GraphQLChimeMeetingClient.JoinMeeting.Attempt";
    private static readonly JOIN_MEETING_SUCCESS_METRIC_NAME = "GraphQLChimeMeetingClient.JoinMeeting.Success";
    private static readonly JOIN_MEETING_FAILURE_METRIC_NAME = "GraphQLChimeMeetingClient.JoinMeeting.Failure";
    private static readonly JOIN_MEETING_UNAUTHENTICATED_ATTEMPT_METRIC_NAME = "GraphQLChimeMeetingClient.JoinMeeting_Unauthenticated.Attempt";
    private static readonly JOIN_MEETING_UNAUTHENTICATED_SUCCESS_METRIC_NAME = "GraphQLChimeMeetingClient.JoinMeeting_Unauthenticated.Success";
    private static readonly JOIN_MEETING_UNAUTHENTICATED_FAILURE_METRIC_NAME = "GraphQLChimeMeetingClient.JoinMeeting_Unauthenticated.Failure";
    private static readonly END_MEETING_ATTEMPT_METRIC_NAME = "GraphQLChimeMeetingClient.EndMeeting.Attempt";
    private static readonly END_MEETING_SUCCESS_METRIC_NAME = "GraphQLChimeMeetingClient.EndMeeting.Success";
    private static readonly END_MEETING_FAILURE_METRIC_NAME = "GraphQLChimeMeetingClient.EndMeeting.Failure";
    private static readonly START_RECORDING_ATTEMPT_METRIC_NAME = "GraphQLChimeMeetingClient.StartRecording.Attempt";
    private static readonly START_RECORDING_SUCCESS_METRIC_NAME = "GraphQLChimeMeetingClient.StartRecording.Success";
    private static readonly START_RECORDING_FAILURE_METRIC_NAME = "GraphQLChimeMeetingClient.StartRecording.Failure";

    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async createMeeting(
        name: string,
        role: AttendeeRole
    ): Promise<ChimeMeetingDTO> {
        try {
            this.logger.info(
                `Creating meeting with name: ${name}`,
                undefined,
                [GraphQLMeetingClient.CREATE_MEETING_ATTEMPT_METRIC_NAME]
            );
            const authToken = await this.idTokenSupplier.get();
            const result: GraphQLResult<CreateChimeMeetingMutation> = await GraphQLAPI.graphql({
                query: createChimeMeeting,
                variables: {
                    name,
                    role
                } satisfies CreateChimeMeetingMutationVariables,
                authToken
            }) as GraphQLResult<CreateChimeMeetingMutation>;
            const createdMeetingInfo: ChimeMeetingOutput | undefined = result.data?.createChimeMeeting ?? undefined;
            if (!createdMeetingInfo) {
                throw new Error("Failed to create meeting");
            }
            this.logger.info(
                `Successfully created meeting with name: ${name}`,
                undefined,
                [GraphQLMeetingClient.CREATE_MEETING_SUCCESS_METRIC_NAME]
            );
            return {
                meetingInfo: JSON.parse(createdMeetingInfo.meetingInfo),
                attendeeInfo: JSON.parse(createdMeetingInfo.attendeeInfo)
            };
        } catch (error) {
            this.logger.error(
                `Failed to create meeting with name: ${name}`,
                error,
                [GraphQLMeetingClient.CREATE_MEETING_FAILURE_METRIC_NAME]
            );
            throw error;
        }
    }

    public async joinMeetingUnauthenticated(
        meetingId: string,
        name: string,
        role: AttendeeRole
    ): Promise<ChimeMeetingDTO> {
        try {
            this.logger.info(
                `Joining meeting as unauthenticated user with meetingId: ${meetingId} and name: ${name}`,
                undefined,
                [GraphQLMeetingClient.JOIN_MEETING_UNAUTHENTICATED_ATTEMPT_METRIC_NAME]
            );
            const result: GraphQLResult<JoinChimeMeetingPublicMutation> = await GraphQLAPI.graphql({
                query: joinChimeMeetingPublic,
                variables: {
                    meetingId,
                    name,
                    role
                } satisfies JoinChimeMeetingPublicMutationVariables,
                authMode: "API_KEY"
            }) as GraphQLResult<JoinChimeMeetingPublicMutation>;
            const resultString: string | undefined = result.data?.joinChimeMeetingPublic ?? undefined;
            const joinChimeMeetingOutput: ChimeMeetingOutput | undefined = resultString ? JSON.parse(resultString) : undefined;
            if (!joinChimeMeetingOutput) {
                throw new Error("Failed to join meeting");
            }
            this.logger.info(
                `Successfully joined meeting as unauthorized user with meetingId: ${meetingId} and name: ${name}`,
                undefined,
                [GraphQLMeetingClient.JOIN_MEETING_UNAUTHENTICATED_SUCCESS_METRIC_NAME]
            );
            return {
                attendeeInfo: JSON.parse(joinChimeMeetingOutput.attendeeInfo),
                meetingInfo: JSON.parse(joinChimeMeetingOutput.meetingInfo)
            };
        } catch (error) {
            this.logger.error(
                `Failed to join meeting as unauthorized user with meetingId: ${meetingId} and name: ${name}`,
                error,
                [GraphQLMeetingClient.JOIN_MEETING_UNAUTHENTICATED_FAILURE_METRIC_NAME]
            );
            throw error;
        }
    }

    public async joinMeeting(
        meetingId: string,
        name: string,
        role: AttendeeRole
    ): Promise<ChimeMeetingDTO> {
        try {
            this.logger.info(
                `Joining meeting with meetingId: ${meetingId} and name: ${name}`,
                undefined,
                [GraphQLMeetingClient.JOIN_MEETING_ATTEMPT_METRIC_NAME]
            );
            const authToken = await this.idTokenSupplier.get();
            const result: GraphQLResult<JoinChimeMeetingMutation> = await GraphQLAPI.graphql({
                query: joinChimeMeeting,
                variables: {
                    meetingId,
                    name,
                    role
                } satisfies JoinChimeMeetingMutationVariables,
                authToken
            }) as GraphQLResult<JoinChimeMeetingMutation>;
            const joinChimeMeetingOutput: ChimeMeetingOutput | undefined = result.data?.joinChimeMeeting ?? undefined;
            if (!joinChimeMeetingOutput) {
                throw new Error("Failed to join meeting");
            }
            this.logger.info(
                `Successfully joined meeting with meetingId: ${meetingId} and name: ${name}`,
                undefined,
                [GraphQLMeetingClient.JOIN_MEETING_SUCCESS_METRIC_NAME]
            );
            return {
                attendeeInfo: JSON.parse(joinChimeMeetingOutput.attendeeInfo),
                meetingInfo: JSON.parse(joinChimeMeetingOutput.meetingInfo)
            };
        } catch (error) {
            this.logger.error(
                `Failed to join meeting with meetingId: ${meetingId} and name: ${name}`,
                error,
                [GraphQLMeetingClient.JOIN_MEETING_FAILURE_METRIC_NAME]
            );
            throw error;
        }
    }

    public async endMeeting(meetingId: string): Promise<void> {
        try {
            this.logger.info(
                `Ending meeting with meetingId: ${meetingId}`,
                undefined,
                [GraphQLMeetingClient.END_MEETING_ATTEMPT_METRIC_NAME]
            );
            const authToken = await this.idTokenSupplier.get();
            const result: GraphQLResult<EndChimeMeetingMutation> = await GraphQLAPI.graphql({
                query: endChimeMeeting,
                variables: { meetingId } satisfies EndChimeMeetingMutationVariables,
                authToken
            }) as GraphQLResult<EndChimeMeetingMutation>;
            if (!result.data?.endChimeMeeting) {
                throw new Error("Failed to end meeting");
            }
            this.logger.info(
                `Successfully ended meeting with meetingId: ${meetingId}`,
                undefined,
                [GraphQLMeetingClient.END_MEETING_SUCCESS_METRIC_NAME]
            );
        } catch (error) {
            this.logger.error(
                `Failed to end meeting with meetingId: ${meetingId}`,
                error,
                [GraphQLMeetingClient.END_MEETING_FAILURE_METRIC_NAME]
            );
            throw error;
        }
    }

    public async startRecording(variables: StartChimeMeetingRecordingMutationVariables): Promise<void> {
        const { meetingId } = variables;
        try {
            this.logger.info(
                `Starting recording for meeting with meetingId: ${meetingId}`,
                undefined,
                [GraphQLMeetingClient.START_RECORDING_ATTEMPT_METRIC_NAME]
            );
            const authToken = await this.idTokenSupplier.get();
            const result: GraphQLResult<StartChimeMeetingRecordingMutation> = await GraphQLAPI.graphql({
                query: startChimeMeetingRecording,
                variables,
                authToken
            }) as GraphQLResult<StartChimeMeetingRecordingMutation>;
            if (!result.data?.startChimeMeetingRecording) {
                throw new Error("Failed to start recording");
            }
            this.logger.info(
                `Successfully started recording for meeting with meetingId: ${meetingId}`,
                undefined,
                [GraphQLMeetingClient.START_RECORDING_SUCCESS_METRIC_NAME]
            );
        } catch (error) {
            this.logger.error(
                `Failed to start recording for meeting with meetingId: ${meetingId}`,
                error,
                [GraphQLMeetingClient.START_RECORDING_FAILURE_METRIC_NAME]
            );
            throw error;
        }
    }
}

export const graphQLMeetingClient: MeetingClient = new GraphQLMeetingClient(
    ClientLoggerFactory.getClientLogger("GraphQLMeetingClient"),
    AuthIDTokenSupplierFactory.getInstance()
);