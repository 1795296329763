import {
    ProducerPaginationInput,
    SortDirection
} from "@aws-amplify/datastore";

import { Issue } from "../../../../../../models";

export default class IssueCreationDateSortDirectionAndLimitFactory<T extends Issue> {
    create(sortDirection: SortDirection, limit: number): ProducerPaginationInput<T> {
        return {
            sort: s => s.clientCreationDate(SortDirection[sortDirection]),
            limit: limit
        }
    }
}