import HubEventHandler from "../event/HubEventHandler";
import { HubPayload } from "@aws-amplify/core";
import { SetterOrUpdater } from "recoil";

export default class DataStoreEventLoggingHandler implements HubEventHandler {
    private readonly setSystemReadyStateProgressText: SetterOrUpdater<Array<string>>;

    constructor(
        setSystemReadyStateProgressText: SetterOrUpdater<Array<string>>
    ) {
        this.setSystemReadyStateProgressText = setSystemReadyStateProgressText;
    }

    public async handle(payload: HubPayload): Promise<void> {
        const currentTimeString: string = new Date().toISOString();
        const otherInfo: string = JSON.stringify(payload.data, null, 1);
        const logMessage: string = `${currentTimeString} ${payload.event} ${payload.data?.model?.name ?? ""} ${otherInfo ?? ""}`;
        this.setSystemReadyStateProgressText(origin => {
            return [...origin, logMessage];
        });
    }
}
