import { PermissionTemplate } from "./PermissionTemplate";
import { PermissionType } from "../../../models";

export const VIEW_PERMISSIONS: Array<PermissionType> = [
    PermissionType.VIEW
];

export const EDIT_PERMISSIONS: Array<PermissionType> = [
    PermissionType.VIEW,
    PermissionType.EDIT,
    PermissionType.DELETE,
    PermissionType.CREATE_CHILDREN,
    PermissionType.MANAGE_STATE
];

export const SHARE_PERMISSIONS: Array<PermissionType> = [
    PermissionType.SHARE_PERMISSION,
];

export const MANAGE_PERMISSIONS: Array<PermissionType> = [
    PermissionType.SHARE_PERMISSION,
    PermissionType.REMOVE_PERMISSION
];

export const TEMPLATE_TYPE_TO_PERMISSION_MAP: Map<PermissionTemplate, Array<PermissionType>> = new Map([
    [PermissionTemplate.VIEW, [ ...VIEW_PERMISSIONS, ...SHARE_PERMISSIONS]],
    [PermissionTemplate.EDIT, [ ...EDIT_PERMISSIONS, ...SHARE_PERMISSIONS]],
    [PermissionTemplate.OWNER, [ ...EDIT_PERMISSIONS, ...MANAGE_PERMISSIONS]],
    [PermissionTemplate.NONE, []]
])