import { filteredLocationIdsByContextSelectorFamily, isSolutionSoleSourceSelectorFamily } from "../../../../lib/design/document/state/DocumentState";

import { Box } from "@mui/material";
import ClipboardSolutionLocation from "./ClipboardSolutionLocation";
import { Fragment } from "react";
import { LineBreak } from "./LineBreak";
import { PageBreak } from "./PageBreak";
import SolutionNotes from "./SolutionNotes";
import { StateContext } from "../../../../lib/design/document/state/StateContext";
import { ViewProposalSummary } from "../ViewProposalSummary";
import { ViewTenderDetail } from "../ViewTenderDetail";
import { ViewWorkTypeSummaries } from "../ViewWorkTypeSummaries";
import teneraLogo from "../../../../assets/images/tenera_building_design_services.png";
import { useRecoilValue } from "recoil";

interface SolutionDetailsForClipboardProps {
    readonly isForClipboard?: boolean;
}

const SolutionDetailsForClipboard = (
    { isForClipboard = false }: SolutionDetailsForClipboardProps
) => {
    /* Constants */
    const stateContext = StateContext.SOLUTION_VIEWING;
    /* Internal page states */
    const locationIds = useRecoilValue<Array<string>>(filteredLocationIdsByContextSelectorFamily(stateContext));
    const isSoleSource = useRecoilValue<boolean>(isSolutionSoleSourceSelectorFamily(StateContext.SOLUTION_VIEWING));

    return (
        <>
            <Box component="p" style={{ textAlign: "center" }}>
                <img src={teneraLogo} />
            </Box>
            <LineBreak />
            <ViewTenderDetail />
            <PageBreak />
            <SolutionNotes />
            <LineBreak />
            {
                isSoleSource &&
                <>
                    <ViewProposalSummary />
                    <LineBreak />
                </>
            }
            <ViewWorkTypeSummaries />
            {locationIds.map((locationId, index) => (
                <Fragment key={locationId}>
                    <PageBreak />
                    <ClipboardSolutionLocation
                        context={stateContext}
                        locationId={locationId}
                        isForClipboard={isForClipboard}
                    />
                </Fragment>
            ))}
        </>
    );
};

export default SolutionDetailsForClipboard;
