import "survey-core/modern.min.css";

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    LinearProgress,
    Typography,
} from '@mui/material';
import {
    Model,
    SurveyModel
} from 'survey-core';
import {
    Organization,
    OrganizationType,
    UserOrganizationRole
} from "../../models";
import {
    actingAsEntityIdAtom,
    userSessionReadinessStatusAtom
} from "../../lib/userSession/state/UserSessionState";
import {
    useEffect,
    useRef,
    useState
} from 'react';

import AuthUsernameSupplierFactory from "../../lib/auth/AuthUsernameSupplierFactory";
import DataStoreUserOnboardingProgressDAOFactory from "../../lib/onboarding/DataStoreUserOnboardingProgressDAOFactory";
import DataStoreUserOrganizationAssociationDAOFactory from "../../lib/organization/DataStoreUserOrganizationAssociationDAOFactory";
import GraphQLOrganizationDAOFactory from "../../lib/organization/graphQL/GraphQLOrganizationDAOFactory";
import OrganizationDAO from "../../lib/organization/OrganizationDAO";
import { Survey } from 'survey-react-ui';
import { UserOrganizationAssociationType } from "../../models";
import { UserSessionStatus } from "../../lib/userSession/state/UserSessionStatus";
import { makeStyles } from "@mui/styles";
import { useSetRecoilState } from "recoil";
import userOnboardingWizardJson from './userOnboardingWizard.json';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        "& .sv-page__title": {
            display: 'none'
        },
        "& .sv-title": {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium
        },
        "& .sv-row": {
            "& div": {
                minWidth: "inherit !important"
            }
        }
    },
    container: {
        maxWidth: "600px",
        margin: "auto",
        "& .MuiButtonBase-root": {
            margin: theme.spacing(1, 0, 0, 0),
        }
    }
}));

interface UserOnboardingWizardFormState {
    organizationType: OrganizationType;
    organization: Organization;
    userOrganizationRoles?: Array<UserOrganizationRole>,
    userFieldOfProfession?: string;
}

interface UserOnboardingWizardProps {
    onDismissButtonClicked: () => void;
}

const UserOnboardingWizard = (props: UserOnboardingWizardProps) => {
    const survey = useRef(new Model(userOnboardingWizardJson));
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [pageTitle, setPageTitle] = useState(survey.current.currentPage.title);
    const organizationDAO = useRef<OrganizationDAO>(GraphQLOrganizationDAOFactory.getInstance());
    const userOrganizationAssociationDAO = useRef(DataStoreUserOrganizationAssociationDAOFactory.getInstance());
    const userOnboardingProgressDAO = useRef(DataStoreUserOnboardingProgressDAOFactory.getInstance());
    const authUsernameSupplier = useRef(AuthUsernameSupplierFactory.getInstance());

    const setActingAsEntity = useSetRecoilState<string>(actingAsEntityIdAtom);
    const setUserSessionReadinessStatus = useSetRecoilState<UserSessionStatus>(userSessionReadinessStatusAtom);

    const onComplete = async (model: SurveyModel) => {
        try {
            setIsLoading(true);
            const data = model.data as UserOnboardingWizardFormState;
            const username = await authUsernameSupplier.current.get();
            let organization: Organization = data.organization;
            // new organization case
            if (data.organization.id == undefined) {
                organization = await organizationDAO.current.create(organization.legalName, data.organizationType);
            }
            if (data.userOrganizationRoles && data.userOrganizationRoles.length > 0) {
                const userOrganizationAssociationPromises = data.userOrganizationRoles.map(role =>
                    userOrganizationAssociationDAO.current.createUserOrganizationAssociation(
                        username,
                        organization.id,
                        UserOrganizationAssociationType.MEMBER_OF,
                        role
                    )
                );
                await Promise.all(userOrganizationAssociationPromises);
            } else {
                await userOrganizationAssociationDAO.current.createUserOrganizationAssociation(
                    username,
                    organization.id,
                    UserOrganizationAssociationType.MEMBER_OF
                );
            }
            setActingAsEntity(organization.id);
            setUserSessionReadinessStatus(UserSessionStatus.READY);
            await userOnboardingProgressDAO.current.createUserOnboardingProgress(username, true);
            setPageTitle("Your settings have been saved!");
        } catch (error: any) {
            setPageTitle(error.message)
        }
        setIsLoading(false);
        setIsCompleted(true);
    };

    const onPageChange = (model: SurveyModel) => {
        setPageTitle(model.currentPage.title);
    };

    useEffect(() => {
        survey.current.onComplete.add(onComplete);
        survey.current.onCurrentPageChanged.add(onPageChange);
        return () => {
            survey.current.onComplete.remove(onComplete);
            survey.current.onCurrentPageChanged.remove(onPageChange);
        };
    }, []);

    return (
        <Box p={3} className={classes.container}>
            <Box mt={3}>
                {isLoading ? <LinearProgress /> : (
                    <Card>
                        <CardHeader
                                title={pageTitle}
                                style={{ borderBottom: "1px solid #ddd" }}
                        />
                        <CardContent>
                            <Box mt={1}>
                                <Survey model={survey.current} className={classes.root} />
                                {isCompleted && (
                                    <Box mt={2}>
                                        <Typography display="block" variant="h5">
                                            Dismiss this message to start using Tenera
                                        </Typography>
                                        <Box mt={2} display="flex">
                                            <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={props.onDismissButtonClicked}
                                                    style={{ marginLeft: "auto" }}
                                            >
                                                Dismiss
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                )}
            </Box>
        </Box>
    );
};

export default UserOnboardingWizard;
