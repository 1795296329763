import AuthHubEventHandlerFactory from "./AuthHubEventHandlerFactory";
import { Hub } from "aws-amplify";
import HubBackedEventListener from "../event/HubBackedEventListener";
import { HubCapsule } from "@aws-amplify/core";
import HubEventHandler from "../event/HubEventHandler";
import { SetterOrUpdater } from "recoil";

export default class HubBackedAuthEventListenerFactory {
    private static readonly AUTH_CHANNEL = "auth";

    public static create(
        setTokenExpireEpochMillis: SetterOrUpdater<number | undefined>
    ) {
        const hubEventHandler: HubEventHandler = AuthHubEventHandlerFactory.create(setTokenExpireEpochMillis);
        return new HubBackedEventListener(
            HubBackedAuthEventListenerFactory.AUTH_CHANNEL,
            Hub,
            (data: HubCapsule) => {
                hubEventHandler.handle(data.payload);
            }
        );
    }
}
