import { Typography } from '@mui/material';

const NoItemsPlaceholder = (props) => {
    return (
        <div>
            <Typography color="textSecondary" variant={"body1"}>{props.text}</Typography>
        </div>
    )
}
export default NoItemsPlaceholder;
