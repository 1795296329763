import {
    FormikValues,
    useFormik
} from "formik";

import { Grid } from "@mui/material";
import LocationSearchBox from "../../componentsV2/location/LocationSearchBox";
import PropertySearchBox from "../../componentsV2/property/PropertySearchBox";

type IssueFormVariablesFormProps<T extends FormikValues> = {
    formik: ReturnType<typeof useFormik<T>>;
};

export const IssueFormVariablesForm = <T extends FormikValues>(props: IssueFormVariablesFormProps<T>) => {
    const { formik } = props;

    return (
        <>
            <Grid item xs={12}>
                <PropertySearchBox
                    name="variables.propertyId"
                    id="variables.propertyId"
                    label="Link to Property"
                    onClear={() => {
                        formik.setFieldValue("variables.propertyId", undefined);
                    }}
                    onSelect={(property) => {
                        formik.setFieldValue("variables.propertyId", property?.id);
                    }}
                    initialPropertyId={formik.values.variables?.propertyId}
                    error={Boolean((formik.errors.variables as any)?.propertyId)}
                    helperText={(formik.errors.variables as any)?.propertyId}
                />
            </Grid>
            <Grid item xs={12}>
                <LocationSearchBox
                    name="variables.locationId"
                    id="variables.locationId"
                    propertyId={formik.values.variables?.propertyId}
                    label="Link to Group"
                    onClear={() => {
                        formik.setFieldValue("variables.locationId", undefined);
                    }}
                    onSelect={(location) => {
                        formik.setFieldValue("variables.locationId", location?.id);
                    }}
                    disabled={formik.values.variables?.propertyId == undefined}
                    initialLocationId={formik.values.variables?.locationId}
                    error={Boolean((formik.errors.variables as any)?.locationId)}
                    helperText={(formik.errors.variables as any)?.locationId}
                />
            </Grid>
        </>
    );
};