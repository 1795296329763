import {
    Theme,
    createTheme
} from "@mui/material/styles";

import { darkBlue } from "./colors";

export const workSpecificationTheme = (theme: Theme) => createTheme({
    ...theme,
    palette: {
        ...theme.palette,
        text: {
            primary: "#000000",
            secondary: "#D1D1D1"
        },
        primary: {
            main: darkBlue[800],
            light: darkBlue[200],
            dark: darkBlue[900],
            contrastText: "#FFFFFF"
        },
        secondary: {
            main: "#FFFFFF"
        },
        error: {
            main: "#F44336"
        },
        warning: {
            main: "#FFB74D"
        },
        info: {
            main: "#64B5F6"
        },
        success: {
            main: "#4CAF50"
        },
        background: {
            default: "#F9F9F9"
        }
    }
});
