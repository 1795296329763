import {
    EntityResourcePermission,
    PermissionEntityType,
    PermissionResourceType,
    PermissionType
} from "../../../models";

import { EntityResourcePermissionDAO } from "../dao/EntityResourcePermissionDAO";
import { PermissionTemplate } from "../template/PermissionTemplate";
import { ResourceIngressCompletionChecker } from "../ingress/ResourceIngressCompletionChecker";
import { ResourcePermissionProvider } from "./ResourcePermissionProvider";
import { TEMPLATE_TYPE_TO_PERMISSION_MAP } from "../template/PermissionTemplateConstants";
import { UnrecognizedPermissionResourceTypeError } from "../errors/UnrecognizedPermissionResourceTypeError";
import UsernameSupplier from "../../auth/UsernameSupplier";

export class ResourcePermissionProviderImpl implements ResourcePermissionProvider {
    private userNameSupplier: UsernameSupplier;
    private resourceTypeToEntityResourcePermissionDAOMap: Map<PermissionResourceType, EntityResourcePermissionDAO>;
    private ingressCompletionCheckerMap: Map<PermissionResourceType, ResourceIngressCompletionChecker>;

    constructor(
        userNameSupplier: UsernameSupplier,
        resourceTypeToEntityResourcePermissionDAOMap: Map<PermissionResourceType, EntityResourcePermissionDAO>,
        resourceTypeToIngressCompletionCheckerMap: Map<PermissionResourceType, ResourceIngressCompletionChecker>
    ) {
        this.userNameSupplier = userNameSupplier;
        this.resourceTypeToEntityResourcePermissionDAOMap = resourceTypeToEntityResourcePermissionDAOMap;
        this.ingressCompletionCheckerMap = resourceTypeToIngressCompletionCheckerMap;
    }

    public async getCurrentUserPermissionsForResource(
        resourceId: string,
        resourceType: PermissionResourceType
    ): Promise<Set<PermissionType>> {
        const userName = await this.userNameSupplier.get();
        return this.getUserPermissionsForResource(resourceId, resourceType, userName)
    }

    private async getUserPermissionsForResource(
        resourceId: string,
        resourceType: PermissionResourceType,
        userId: string
    ): Promise<Set<PermissionType>> {
        const entityResourcePermissionDAO = this.resourceTypeToEntityResourcePermissionDAOMap.get(resourceType);
        if (entityResourcePermissionDAO == undefined) {
            throw new UnrecognizedPermissionResourceTypeError(`could not determine ERP DAO to use for resource ${resourceType}`);
        }
        const permissionRecords: Array<EntityResourcePermission> = await entityResourcePermissionDAO.listByEntityAndResource(
            PermissionEntityType.USER,
            userId,
            resourceType,
            resourceId,
            true
        );
        if (!await this.hasResourceCompletedIngress(resourceId, resourceType)) {
            // This indicates the resource is too new to have ERPs, or created while offline
            return new Set(TEMPLATE_TYPE_TO_PERMISSION_MAP.get(PermissionTemplate.OWNER));
        }
        const permissionTypes: Array<PermissionType> = permissionRecords.filter(record => {
            return record.permissionType != null && record.permissionType != undefined;
        }).map(record => {
            return record.permissionType;
        }) as Array<PermissionType>;

        return new Set(permissionTypes);
    }

    private async hasResourceCompletedIngress(
        resourceId: string,
        resourceType: PermissionResourceType
    ) {
        const ingressCompletionChecker: ResourceIngressCompletionChecker | undefined = this.ingressCompletionCheckerMap.get(resourceType);
        if (ingressCompletionChecker == undefined) {
            throw new UnrecognizedPermissionResourceTypeError(`Could not determine ingress checker for resource type ${UnrecognizedPermissionResourceTypeError}`);
        }
        const isIngressComplete = await ingressCompletionChecker.hasResourceCompletedIngress(resourceId);
        return isIngressComplete;
    }
}
