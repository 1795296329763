import {
    Attachment,
    Dimension,
    Issue,
    IssueStatus,
    Location,
    Proposal,
    Solution,
    SolutionMetadata,
    SolutionStatus,
    SolutionTenderingType,
    WorkSpecification
} from "../../../../models";
import {
    DefaultValue,
    RecoilState,
    Snapshot,
    atom,
    atomFamily,
    selectorFamily,
    useRecoilCallback
} from "recoil";
import {
    DimensionType,
    MeasurementUnit,
    PermissionResourceType
} from "../../../../models";
import {
    copyProposalItemToContext,
    initializeProposalItemByWorkSpecification,
    proposalItemFeatureConfigurationByContextAtomFamily,
    proposalItemIdByWorkSpecificationIdentifierAtomFamily,
    removeProposalItemByWorkSpecificationId,
    unitCountByProposalItemIdentifierSelectorFamily
} from "../../bidding/state/v2/ProposalItemStates";
import {
    isFilterAppliedAtom,
    issueFilterPredicateByStateContextSelectorFamily
} from "../../../issue/state/IssueFilterRecoilState";
import {
    issueSorterSelector,
    locationSorterSelector
} from "./DocumentSortState";

import { CleanUpPropertyStateError } from "./error/CleanUpPropertyStateError";
import { CleanUpSolutionStateError } from "./error/CleanUpSolutionStateError";
import { ComponentModificationMode } from "./ComponentModificationMode";
import { ContextAwareIdentifier } from "../ContextAwareIdentifier";
import DataStoreDimensionDAOFactory from "../../../dimension/dao/datastore/DataStoreDimensionDAOFactory";
import DataStoreIssueDAOFactory from "../../../issue/dao/datastore/DataStoreIssueDAOFactory";
import DataStoreLocationDAOFactory from "../../../location/dao/datastore/DataStoreLocationDAOFactory";
import DataStoreWorkScopeSpecificationDAOFactory from "../../workscopespecification/dao/datastore/DataStoreWorkScopeSpecificationDAOFactory";
import DimensionDAO from "../../../dimension/dao/DimensionDAO";
import { DimensionFeatureConfiguration } from "./DimensionFeatureConfiguration";
import DimensionUnitConverter from "../../../util/dimension/DimensionUnitConverter";
import { FetchDimensionStateError } from "./error/dimension/FetchDimensionStateError";
import { FetchIssueStateError } from "./error/issue/FetchIssueStateError";
import { FetchLocationStateError } from "./error/location/FetchLocationStateError";
import GraphQLSolutionDAOFactory from "../../../solution/majorVersion/dao/GraphQLSolutionDAOFactory";
import { InitializeSolutionStateError } from "./error/solution/InitializeSolutionStateError";
import { IssueDAO } from "../../../issue/dao/IssueDAO";
import { IssueFeatureConfiguration } from "./IssueFeatureConfiguration";
import LocationDAO from "../../../location/dao/LocationDAO";
import { LocationFeatureConfiguration } from "./LocationFeatureConfiguration";
import { MeasurementPropagationBehavior } from "../../../measurement/MeasurementPropagationBehavior";
import MergeDataSorterFactory from "../../../util/data/sort/MergeDataSorterFactory";
import { ModelType } from "../ModelType";
import { RecordPersistenceConfiguration } from "./RecordPersistenceConfiguration";
import { RecordPersistenceMode } from "./RecordPersistenceMode";
import { RecordToPropertyAssociationMode } from "./RecordToPropertyAssociationMode";
import { RemoveDimensionStateError } from "./error/dimension/RemoveDimensionStateError";
import { RemoveIssueStateError } from "./error/issue/RemoveIssueStateError";
import { RemoveLocationStateError } from "./error/location/RemoveLocationStateError";
import ResourceNotFoundError from "../../../ResourceNotFoundError";
import S3SolutionMinorVersionContentDAOFactory from "../../../solution/minorVersion/dao/content/v2/S3SolutionMinorVersionContentDAOFactory";
import SaveSolutionHandler from "../../../solution/operation/save/v2/SaveSolutionHandler";
import SaveSolutionHandlerFactory from "../../../solution/operation/save/v2/SaveSolutionHandlerFactory";
import { SaveSolutionStateError } from "./error/solution/SaveSolutionStateError";
import { SelectionBehavior } from "../../../ui/SelectionBehavior";
import SolutionContent from "../../types/SolutionContent";
import SolutionContentBuilder from "../../types/SolutionContentBuilder";
import SolutionDAO from "../../../solution/majorVersion/dao/SolutionDAO";
import SolutionMinorVersionContentDAO from "../../../solution/minorVersion/dao/content/v2/SolutionMinorVersionContentDAO";
import { StateContext } from "./StateContext";
import { UpdateIsEditModeStateError } from "./error/UpdateIsEditModeStateError";
import { UpdateIssueStateError } from "./error/issue/UpdateIssueStateError";
import { UpdateLocationStateError } from "./error/location/UpdateLocationStateError";
import WorkScopeSpecificationDAO from "../../workscopespecification/dao/WorkScopeSpecificationDAO";
import { WorkSpecificationFeatureConfiguration } from "./WorkSpecificationFeatureConfiguration";
import { WorkSpecificationItemNumberComparatorAsc } from "../../workscopespecification/WorkSpecificationItemNumberComparatorAsc";
import { fetchPermissionsForResource } from "../../../permission/state/ResourcePermissionRecoilState";
import { isDesktop } from "react-device-detect";
import { localForageEffect } from "../../state/RecoilEffect";
import { numberOfIssuesCreatedAtomFamily } from "./IssueNamingState";
import { numberOfLocationsCreatedAtomFamily } from "./LocationNamingState";
import { propertyIdInFocusAtom } from "../../../ui/InFocusRecoilStates";

const locationDAO: LocationDAO = DataStoreLocationDAOFactory.getInstance();
const issueDAO: IssueDAO = DataStoreIssueDAOFactory.getInstance();
const dimensionDAO: DimensionDAO = DataStoreDimensionDAOFactory.getDimensionDAO();
const workSpecificationDAO: WorkScopeSpecificationDAO = DataStoreWorkScopeSpecificationDAOFactory.getInstance();
const solutionMajorVersionRecordDAO: SolutionDAO = GraphQLSolutionDAOFactory.getInstance();
const solutionMinorVersionContentDAO: SolutionMinorVersionContentDAO = S3SolutionMinorVersionContentDAOFactory.getInstance();
const saveSolutionHandler: SaveSolutionHandler = SaveSolutionHandlerFactory.getInstance();

/* Sorting */
const workSpecificationItemNumberAscendingSorter = MergeDataSorterFactory.createInstance<WorkSpecification>(new WorkSpecificationItemNumberComparatorAsc());

export const designElementIdsState = atom({
    key: "designElementIdsState",
    default: new Array<string>()
});

export const isEditModeByDesignElementIdentifierAtomFamily = atomFamily<boolean, ContextAwareIdentifier>({
    key: "isEditModeByDesignElementIdAtomFamily",
    default: false
});

export const aggregatedChildrenInEditModeByIssueIdentifierSelectorFamily = selectorFamily<boolean, ContextAwareIdentifier>({
    key: "aggregatedChildrenInEditModeByIssueIdentifierSelectorFamily",
    get: (issueIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const context = issueIdentifier.context;
        const dimensionIds: Array<string> = get(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier));
        for (const dimensionId of dimensionIds) {
            const dimensionIdentifier = new ContextAwareIdentifier(dimensionId, context, ModelType.DIMENSION);
            if (get(isEditModeByDesignElementIdentifierSelectorFamily(dimensionIdentifier))) {
                return true;
            }
            const workSpecificationIds: Array<string> = get(workSpecificationIdsByDimensionIdentifierAtomFamily(dimensionIdentifier));
            for (const workSpecificationId of workSpecificationIds) {
                const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, context, ModelType.WORK_SPECIFICATION);
                if (get(isEditModeByDesignElementIdentifierSelectorFamily(workSpecificationIdentifier))) {
                    return true;
                }
            }
        }
        return false;
    }
});

export const isEditModeByDesignElementIdentifierSelectorFamily = selectorFamily<boolean, ContextAwareIdentifier>({
    key: "isEditModeByDesignElementIdentifierSelectorFamily",
    get: (identifier: ContextAwareIdentifier) => ({ get }) => {
        let componentModificationMode: ComponentModificationMode | undefined;
        if (identifier.modelType === ModelType.WORK_SPECIFICATION) {
            componentModificationMode = get(workSpecificationFeatureConfigurationByContextAtomFamily(identifier.context)).componentModificationMode;
        }
        if (identifier.modelType === ModelType.DIMENSION) {
            componentModificationMode = get(dimensionFeatureConfigurationByContextAtomFamily(identifier.context)).componentModificationMode;
        }
        if (identifier.modelType === ModelType.ISSUE) {
            componentModificationMode = get(issueFeatureConfigurationByContextAtomFamily(identifier.context)).componentModificationMode;
        }
        if (identifier.modelType === ModelType.LOCATION) {
            componentModificationMode = get(locationFeatureConfigurationByContextAtomFamily(identifier.context)).componentModificationMode;
        }
        if (identifier.modelType === ModelType.PROPOSAL_ITEM) {
            componentModificationMode = get(proposalItemFeatureConfigurationByContextAtomFamily(identifier.context)).componentModificationMode;
        }

        if (componentModificationMode === ComponentModificationMode.ALWAYS_EDIT) {
            return true;
        }
        if (componentModificationMode === ComponentModificationMode.ALWAYS_VIEW) {
            return false;
        }
        return get(isEditModeByDesignElementIdentifierAtomFamily(identifier));
    },
    set: (identifier: ContextAwareIdentifier) => ({ set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            reset(isEditModeByDesignElementIdentifierAtomFamily(identifier));
            return;
        }
        set(isEditModeByDesignElementIdentifierAtomFamily(identifier), newValue);
    }
});

export const getUpdateIsEditModeCallback = () => useRecoilCallback(({ snapshot, set }) => {
    return async (
        modelType: ModelType,
        id: string,
        isEditMode: boolean,
        context: StateContext
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            await updateIsEditMode(snapshot, set, modelType, id, isEditMode, context);
        } catch (error) {
            throw new UpdateIsEditModeStateError(
                `Error occurred while trying to Update State for ${modelType} with id: ${id}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

const updateIsEditMode = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void,
    modelType: ModelType,
    id: string,
    isEditMode: boolean,
    context: StateContext
) => {
    const elementIdentifier = new ContextAwareIdentifier(id, context, modelType);
    if (modelType === ModelType.WORK_SPECIFICATION) {
        set(isEditModeByDesignElementIdentifierSelectorFamily(elementIdentifier), isEditMode);
        return;
    }
    if (modelType === ModelType.DIMENSION) {
        set(isEditModeByDesignElementIdentifierSelectorFamily(elementIdentifier), isEditMode);
        const workSpecificationIds: Array<string> = await snapshot.getPromise(workSpecificationIdsByDimensionIdentifierAtomFamily(elementIdentifier));
        workSpecificationIds.forEach(workSpecificationId => updateIsEditMode(snapshot, set, ModelType.WORK_SPECIFICATION, workSpecificationId, isEditMode, context));
        return;
    }
    if (modelType === ModelType.ISSUE) {
        set(isEditModeByDesignElementIdentifierSelectorFamily(elementIdentifier), isEditMode);
        const dimensionIds: Array<string> = await snapshot.getPromise(dimensionIdsByIssueIdentifierAtomFamily(elementIdentifier));
        dimensionIds.forEach(dimensionId => updateIsEditMode(snapshot, set, ModelType.DIMENSION, dimensionId, isEditMode, context));
        return;
    }
    if (modelType === ModelType.LOCATION) {
        set(isEditModeByDesignElementIdentifierSelectorFamily(elementIdentifier), isEditMode);
        const issueIds: Array<string> = await snapshot.getPromise(issueIdsByLocationIdentifierAtomFamily(elementIdentifier));
        issueIds.forEach(issueId => updateIsEditMode(snapshot, set, ModelType.ISSUE, issueId, isEditMode, context));
        return;
    }
};

export const issuesPerPageAtom = atom({
    key: "issuesPerPageAtom",
    default: isDesktop ? 10 : 5,
    effects: [localForageEffect("ISSUES_PER_PAGE")]
});

// LOCATION STATES BELOW //

export const getFetchLocationCallback = () => useRecoilCallback(({ snapshot, set }) => {
    return async (
        locationId: string,
        context: StateContext
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            await fetchLocation(snapshot, set, locationId, context);
        } catch (error) {
            throw new FetchLocationStateError(
                `Error occurred while trying to Fetch State for Location with id: ${locationId}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

export const getUpdateLocationCallback = () => useRecoilCallback(({ snapshot, set }) => {
    return async (
        location: Location,
        context: StateContext
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            await updateLocation(snapshot, set, location, context);
        } catch (error) {
            throw new UpdateLocationStateError(
                `Error occurred while trying to Update State for Location with id: ${location.id}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

export const getRemoveLocationCallback = () => useRecoilCallback(({ snapshot, reset }) => {
    return async (
        locationId: string,
        context: StateContext
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            await removeLocation(snapshot, reset, locationId, context);
        } catch (error) {
            throw new RemoveLocationStateError(
                `Error occurred while trying to Remove State for Location with id: ${locationId}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

export const getCopyLocationToContextCallback = () => useRecoilCallback(({ snapshot, set, reset }) => async (
    locationId: string,
    fromContext: StateContext,
    toContext: StateContext
) => {
    const releaseSnapshot = snapshot.retain();
    try {
        copyLocationToContext(snapshot, set, locationId, fromContext, toContext);
    } finally {
        releaseSnapshot();
    }
});

const fetchLocation = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    locationId: string,
    context: StateContext
): Promise<void> => {
    const location: Location = await locationDAO.getLocationById(locationId);
    const locationIdentifier = new ContextAwareIdentifier(location.id, context, ModelType.LOCATION);
    await fetchPermissionsForResource(locationId, PermissionResourceType.LOCATION, set);
    set(locationByLocationIdentifierSelectorFamily(locationIdentifier), location);
    const issues = await issueDAO.getIssuesByLocationId(location.id);
    await Promise.all(issues.filter(issue => issue.propertyId != undefined)
        .map((issue) => issue.id).map((issueId) => {
            return fetchIssue(snapshot, set, issueId, context);
        }));
};

const updateLocation = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    location: Location,
    context: StateContext
): Promise<Location> => {
    const recordPersistenceConfiguration = snapshot.getLoadable(recordPersistenceConfigurationAtom).contents as RecordPersistenceConfiguration;
    let updatedLocation: Location = location;
    if (recordPersistenceConfiguration.update === RecordPersistenceMode.ALWAYS_PERSIST) {
        updatedLocation = await locationDAO.editLocation(location.id, location);
    }
    if (recordPersistenceConfiguration.update === RecordPersistenceMode.PERSIST_RECENTLY_CREATED) {
        const recentRecordIds = snapshot.getLoadable(recentlyCreatedRecordIds).contents as Set<string>;
        if (recentRecordIds.has(location.id)) {
            updatedLocation = await locationDAO.editLocation(location.id, location);
        }
    }
    const locationIdentifier = new ContextAwareIdentifier(location.id, context, ModelType.LOCATION);
    set(locationByLocationIdentifierSelectorFamily(locationIdentifier), updatedLocation);
    return updatedLocation;
};

const removeLocation = async (
    snapshot: Snapshot,
    reset: <T>(recoilVal: RecoilState<T>) => void,
    locationId: string,
    context: StateContext
): Promise<void> => {
    const recordPersistenceConfiguration = snapshot.getLoadable(recordPersistenceConfigurationAtom).contents as RecordPersistenceConfiguration;
    if (recordPersistenceConfiguration.delete === RecordPersistenceMode.ALWAYS_PERSIST) {
        await locationDAO.deleteLocation(locationId);
    }
    if (recordPersistenceConfiguration.update === RecordPersistenceMode.PERSIST_RECENTLY_CREATED) {
        const recentRecordIds = snapshot.getLoadable(recentlyCreatedRecordIds).contents as Set<string>;
        if (recentRecordIds.has(locationId)) {
            await locationDAO.deleteLocation(locationId);
        }
    }
    const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
    reset(locationByLocationIdentifierSelectorFamily(locationIdentifier));
    const locationIssueIds: Array<string> = await snapshot.getPromise(issueIdsByLocationIdentifierAtomFamily(locationIdentifier));
    locationIssueIds.forEach((issueId) => {
        removeIssue(snapshot, reset, issueId, context);
    });
};

const copyLocationToContext = (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    locationId: string,
    fromContext: StateContext,
    toContext: StateContext
) => {
    const locationIdAndFromContextIdentifier = new ContextAwareIdentifier(locationId, fromContext, ModelType.LOCATION);
    const locationIdAndToContextIdentifier = new ContextAwareIdentifier(locationId, toContext, ModelType.LOCATION);
    const locationToCopy: Location = snapshot.getLoadable(
        locationByLocationIdentifierSelectorFamily(locationIdAndFromContextIdentifier)
    ).contents as Location;

    set(locationByLocationIdentifierSelectorFamily(locationIdAndToContextIdentifier), locationToCopy);
};

/* Use this atom for ALL persisted locations ID keys */
/* Use for import/export. */
export const locationIdsByContextAtomFamily = atomFamily<Array<string>, StateContext>({
    key: "locationIdsByContextAtomFamily",
    default: []
});

/* Internal Location state storage, do not reference from components */
export const locationByLocationIdentifierAtomFamily = atomFamily<Location | null, ContextAwareIdentifier>({
    key: "locationByLocationIdentifierAtomFamily",
    default: null
});

/* Use this selector family for CRUD operations on Location state */
export const locationByLocationIdentifierSelectorFamily = selectorFamily<Location | null, ContextAwareIdentifier>({
    key: "locationByLocationIdentifierSelectorFamily",
    get: (locationIdentifier: ContextAwareIdentifier) => ({ get }) => {
        return get(locationByLocationIdentifierAtomFamily(locationIdentifier));
    },
    set: (locationIdentifier: ContextAwareIdentifier) => ({ get, set, reset }, newValue) => {
        /* Handle reset */
        if (newValue instanceof DefaultValue) {
            set(locationIdsByContextAtomFamily(locationIdentifier.context), (prevValue: Array<string>) => {
                const updatedValue: Array<string> = [...prevValue];
                const index: number = updatedValue.indexOf(locationIdentifier.id);
                updatedValue.splice(index, 1);
                return updatedValue;
            });
            reset(locationByLocationIdentifierAtomFamily(locationIdentifier));
            return;
        }

        /* Handle set */
        set(locationIdsByContextAtomFamily(locationIdentifier.context), (prevValue: Array<string>) => {
            if (prevValue.indexOf(locationIdentifier.id) !== -1) {
                return prevValue;
            }
            return [locationIdentifier.id, ...prevValue];
        });
        set(locationByLocationIdentifierAtomFamily(locationIdentifier), newValue);
    }
});

/* Use for import/export. */
export const locationIdToLocationMapByContextSelectorFamily = selectorFamily<Map<string, Location>, StateContext>({
    key: "locationIdToLocationMapByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const locationIds: Array<string> = get(locationIdsByContextAtomFamily(context));
        return locationIds.reduce((map: Map<string, Location>, locationId: string) => {
            const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
            const location: Location = get(locationByLocationIdentifierAtomFamily(locationIdentifier))!;
            return map.set(locationId, location);
        }, new Map<string, Location>());
    },
    set: (context: StateContext) => ({ get, set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            return;
        }
        for (const [locationId, location] of newValue.entries()) {
            const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
            set(locationByLocationIdentifierAtomFamily(locationIdentifier), location);
        }
    }
});

// ISSUE STATES BELOW //

export const getFetchIssueCallback = () => useRecoilCallback(({ snapshot, set }) => {
    return async (
        issueId: string,
        context: StateContext
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            await fetchIssue(snapshot, set, issueId, context);
        } catch (error) {
            throw new FetchIssueStateError(
                `Error occurred while trying to Fetch State for Issue with id: ${issueId}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

export const getUpdateIssueCallback = () => useRecoilCallback(({ snapshot, set }) => {
    return async (
        issue: Issue,
        context: StateContext
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            await updateIssue(snapshot, set, issue, context);
        } catch (error) {
            throw new UpdateIssueStateError(
                `Error occurred while trying to Update State for Issue with id: ${issue.id}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

/**
 * IssueStatus is updated separately from Issue content since Status is context-agnostic
 */
export const getUpdateIssueStatusCallback = () => useRecoilCallback(({ snapshot, set }) => {
    return async (
        issueId: string,
        status: IssueStatus
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            await updateIssueStatus(set, issueId, status);
        } catch (error) {
            throw new UpdateIssueStateError(
                `Error occurred while trying to Update Status for Issue with id: ${issueId}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

export const getRemoveIssueCallback = () => useRecoilCallback(({ snapshot, reset }) => {
    return async (
        issueId: string,
        context: StateContext
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            await removeIssue(snapshot, reset, issueId, context);
        } catch (error) {
            throw new RemoveIssueStateError(
                `Error occurred while trying to Remove State for Issue with id: ${issueId}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

export const getAssociateIssueWithPropertyCallback = () => useRecoilCallback(({ snapshot, set, reset }) => {
    return async (
        issue: Issue,
        propertyId: string,
        context: StateContext
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            const locationIdentifier = new ContextAwareIdentifier(issue.locationId!, context, ModelType.LOCATION);
            const location: Location = await snapshot.getLoadable(locationByLocationIdentifierSelectorFamily(locationIdentifier)).contents as Location;
            await associateLocationWithProperty(snapshot, set, location!, propertyId, context);
            await associateIssueWithProperty(snapshot, set, issue, propertyId, context);
        } finally {
            releaseSnapshot();
        }
    };
});

export const getCopyIssueToContextCallback = () => useRecoilCallback(({ snapshot, set, reset }) => async (
    issueId: string,
    fromContext: StateContext,
    toContext: StateContext
) => {
    const releaseSnapshot = snapshot.retain();
    try {
        copyIssueToContext(snapshot, set, issueId, fromContext, toContext);
    } finally {
        releaseSnapshot();
    }
});

const associateLocationWithProperty = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    location: Location,
    propertyId: string,
    context: StateContext
): Promise<Location> => {
    const locationWithPropertyId: Location = {
        ...location,
        propertyId: propertyId
    };
    const updatedLocation: Location = await updateLocation(snapshot, set, locationWithPropertyId, context);
    set(recentlyCreatedRecordIds, (prevValue: Set<string>) => {
        const copy = new Set(prevValue);
        copy.delete(location.id);
        return copy;
    });
    return updatedLocation;
};

const associateIssueWithProperty = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    issue: Issue,
    propertyId: string,
    context: StateContext
): Promise<Issue> => {
    const issueWithPropertyId: Issue = {
        ...issue,
        propertyId: propertyId
    };
    const updatedIssue: Issue = await updateIssue(snapshot, set, issueWithPropertyId, context);
    set(recentlyCreatedRecordIds, (prevValue: Set<string>) => {
        const copy = new Set(prevValue);
        copy.delete(issue.id);
        return copy;
    });
    const issueIdentifier = new ContextAwareIdentifier(issue.id, context, ModelType.ISSUE);
    const dimensionIds: Array<string> = await snapshot.getLoadable(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier)).contents as Array<string>;
    for (const dimensionId of dimensionIds) {
        await associateDimensionWithProperty(snapshot, set, dimensionId, propertyId, context);
    }

    return updatedIssue;
};

const fetchIssue = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    issueId: string,
    context: StateContext
): Promise<void> => {
    const issue: Issue = await issueDAO.getIssueById(issueId);
    const issueIdentifier = new ContextAwareIdentifier(issue.id, context, ModelType.ISSUE);
    set(issueByIssueIdentifierSelectorFamily(issueIdentifier), issue);
    set(issueStatusForIssueIdAtomFamily(issue.id), issue.status ? IssueStatus[issue.status] : IssueStatus.NONE);
    await fetchPermissionsForResource(issue.id, PermissionResourceType.ISSUE, set);
    const dimensions = await dimensionDAO.getDimensionsByParentId(issue.id);
    await Promise.all(dimensions.map((dimension) => dimension.id).map((dimensionId) => {
        return fetchDimension(snapshot, set, dimensionId, context);
    }));
};

const updateIssue = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    issue: Issue,
    context: StateContext
): Promise<Issue> => {
    const recordPersistenceConfiguration = await snapshot.getLoadable(recordPersistenceConfigurationAtom).contents as RecordPersistenceConfiguration;
    let updatedIssue: Issue = { ...issue };
    if (recordPersistenceConfiguration.update === RecordPersistenceMode.ALWAYS_PERSIST) {
        updatedIssue = await issueDAO.editIssue(issue.id, issue);
    }
    if (recordPersistenceConfiguration.update === RecordPersistenceMode.PERSIST_RECENTLY_CREATED) {
        const recentRecordIds = await snapshot.getLoadable(recentlyCreatedRecordIds).contents as Set<string>;
        if (recentRecordIds.has(issue.id)) {
            updatedIssue = await issueDAO.editIssue(issue.id, issue);
        }
    }
    const issueIdentifier = new ContextAwareIdentifier(updatedIssue.id, context, ModelType.ISSUE);
    set(issueByIssueIdentifierSelectorFamily(issueIdentifier), updatedIssue);
    return updatedIssue;
};

const updateIssueStatus = async (
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    issueId: string,
    status: IssueStatus
) => {
    try {
        const issue: Issue = await issueDAO.getIssueById(issueId);
        const issueWithNewStatus: Issue = {
            ...issue,
            status: status
        };
        await issueDAO.editIssue(issue.id, issueWithNewStatus);
    } catch (error) {
        if (!(error instanceof ResourceNotFoundError)) {
            throw error;
        }
    }
    set(issueStatusForIssueIdAtomFamily(issueId), status);
};

const removeIssue = async (
    snapshot: Snapshot,
    reset: <T>(recoilVal: RecoilState<T>) => void,
    issueId: string,
    context: StateContext
): Promise<void> => {
    const recordPersistenceConfiguration = snapshot.getLoadable(recordPersistenceConfigurationAtom).contents as RecordPersistenceConfiguration;
    if (recordPersistenceConfiguration.delete === RecordPersistenceMode.ALWAYS_PERSIST) {
        await issueDAO.deleteIssue(issueId);
    }
    if (recordPersistenceConfiguration.update === RecordPersistenceMode.PERSIST_RECENTLY_CREATED) {
        const recentRecordIds = snapshot.getLoadable(recentlyCreatedRecordIds).contents as Set<string>;
        if (recentRecordIds.has(issueId)) {
            await issueDAO.deleteIssue(issueId);
        }
    }
    const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
    reset(issueByIssueIdentifierSelectorFamily(issueIdentifier));
    const issueDimensionIds: Array<string> = await snapshot.getPromise(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier));
    issueDimensionIds.forEach((dimensionId) => {
        removeDimension(snapshot, reset, dimensionId, context);
    });
};

const copyIssueToContext = (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    issueId: string,
    fromContext: StateContext,
    toContext: StateContext
) => {
    const issueIdAndFromContextIdentifier = new ContextAwareIdentifier(issueId, fromContext, ModelType.ISSUE);
    const issueIdAndToContextIdentifier = new ContextAwareIdentifier(issueId, toContext, ModelType.ISSUE);
    const issueToCopy: Issue = snapshot.getLoadable(
        issueByIssueIdentifierSelectorFamily(issueIdAndFromContextIdentifier)
    ).contents as Issue;

    const issueDimensionIds: Array<string> = snapshot.getLoadable(
        dimensionIdsByIssueIdentifierAtomFamily(issueIdAndFromContextIdentifier)
    ).contents as Array<string>;
    for (const dimensionId of issueDimensionIds) {
        copyDimensionToContext(snapshot, set, dimensionId, fromContext, toContext);
    }

    set(issueByIssueIdentifierSelectorFamily(issueIdAndToContextIdentifier), issueToCopy);
};

/* Use this atom for ALL persisted issues ID keys, in the context of parent location. */
export const issueIdsByLocationIdentifierAtomFamily = atomFamily<Array<string>, ContextAwareIdentifier>({
    key: "issueIdsByLocationIdentifierAtomFamily",
    default: []
});

/* Use for import/export. */
export const locationIdToIssueIdsMapByContextSelectorFamily = selectorFamily<Map<string, Array<string>>, StateContext>({
    key: "locationIdToIssueIdsMapByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const locationIds: Array<string> = get(locationIdsByContextAtomFamily(context));
        return locationIds.reduce((map: Map<string, Array<string>>, locationId: string) => {
            const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
            const issueIds: Array<string> = get(issueIdsByLocationIdentifierAtomFamily(locationIdentifier));
            return map.set(locationId, issueIds);
        }, new Map<string, Array<string>>());
    },
    set: (context: StateContext) => ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            return;
        }
        for (const [locationId, issuesIds] of newValue.entries()) {
            const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
            set(issueIdsByLocationIdentifierAtomFamily(locationIdentifier), issuesIds);
        }
    }
});

/* Internal Issue state storage, do not reference from components */
export const issueByIssueIdentifierAtomFamily = atomFamily<Issue | null, ContextAwareIdentifier>({
    key: "issueByIssueIdentifierAtomFamily",
    default: null
});

/* Use this selector family for CRUD operations on Issue state */
export const issueByIssueIdentifierSelectorFamily = selectorFamily<Issue | null, ContextAwareIdentifier>({
    key: "issueByIssueIdentifierSelectorFamily",
    get: (issueIdentifier: ContextAwareIdentifier) => ({ get }) => {
        return get(issueByIssueIdentifierAtomFamily(issueIdentifier));
    },
    set: (issueIdentifier: ContextAwareIdentifier) => ({ get, set, reset }, newValue) => {
        /* Handle reset */
        if (newValue instanceof DefaultValue) {
            const issueToDelete: Issue = get(issueByIssueIdentifierSelectorFamily(issueIdentifier))!;
            if (issueToDelete && issueToDelete.locationId) {
                const locationIdentifier = new ContextAwareIdentifier(issueToDelete.locationId, issueIdentifier.context, ModelType.LOCATION);
                set(issueIdsByLocationIdentifierAtomFamily(locationIdentifier), (prevValue: Array<string>) => {
                    const updatedValue: Array<string> = [...prevValue];
                    const index: number = updatedValue.indexOf(issueIdentifier.id);
                    updatedValue.splice(index, 1);
                    return updatedValue;
                });
            }
            reset(issueByIssueIdentifierAtomFamily(issueIdentifier));
            return;
        }

        /* Handle set */
        set(issueByIssueIdentifierAtomFamily(issueIdentifier), newValue);
        const locationIdentifier = new ContextAwareIdentifier(newValue!.locationId!, issueIdentifier.context, ModelType.LOCATION);
        set(issueIdsByLocationIdentifierAtomFamily(locationIdentifier), (prevValue: Array<string>) => {
            if (prevValue.indexOf(issueIdentifier.id) !== -1) {
                return prevValue;
            }
            return [issueIdentifier.id, ...prevValue];
        });
    }
});

/* Use for import/export. */
export const issueIdToIssueMapByContextSelectorFamily = selectorFamily<Map<string, Issue>, StateContext>({
    key: "issueIdToIssueMapByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const locationIds: Array<string> = get(locationIdsByContextAtomFamily(context));
        const issueIds: Array<string> = locationIds.reduce((issuesIds: Array<string>, locationId: string) => {
            const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
            const currentLocationIssueIds: Array<string> = get(issueIdsByLocationIdentifierAtomFamily(locationIdentifier));
            return [...issuesIds, ...currentLocationIssueIds];
        }, []);
        return issueIds.reduce((map: Map<string, Issue>, issueId: string) => {
            const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
            let issue: Issue = get(issueByIssueIdentifierSelectorFamily(issueIdentifier))!;
            issue = {
                ...issue,
                status: get(issueStatusForIssueIdAtomFamily(issueId))
            };
            return map.set(issueId, issue);
        }, new Map<string, Issue>());
    },
    set: (context: StateContext) => ({ get, set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            return;
        }
        for (const [issueId, issue] of newValue.entries()) {
            const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
            set(issueByIssueIdentifierAtomFamily(issueIdentifier), issue);
        }
    }
});

// FILTERING STATES BELOW //

/* Internal Issue state storage, do not reference from components */
export const filteredIssuesByContextSelectorFamily = selectorFamily<Array<Issue>, StateContext>({
    key: "filteredIssueIdsByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const predicateForStateContext = get(issueFilterPredicateByStateContextSelectorFamily(context));
        const allIssuesByIssueIdMap: Map<string, Issue> = get(issueIdToIssueMapByContextSelectorFamily(context));
        const locationIds: Array<string> = get(locationIdsByContextAtomFamily(context));

        const filteredIssues: Array<Issue> = [];
        for (const issue of allIssuesByIssueIdMap.values()) {
            if (issue.locationId && locationIds.includes(issue.locationId) && predicateForStateContext.apply(issue)) {
                filteredIssues.push(issue);
            }
        }
        return filteredIssues;
    }
});

/* Use this for discoverability of location ID keys and for ordering. */
export const filteredLocationIdsByContextSelectorFamily = selectorFamily<Array<string>, StateContext>({
    key: "filteredLocationIdsByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const isFilterApplied: boolean = get(isFilterAppliedAtom);
        const locationFeatureConfiguration: LocationFeatureConfiguration = get(locationFeatureConfigurationByContextAtomFamily(context));
        const locationsLocalCreationTimeSorter = get(locationSorterSelector);
        let locationIds = get(locationIdsByContextAtomFamily(context));
        if (!isFilterApplied && locationFeatureConfiguration.displayEmptyLocation) {
            const locations = locationIds.map((locationId: string) => {
                const locationContextAwareIdentifier: ContextAwareIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
                return get(locationByLocationIdentifierSelectorFamily(locationContextAwareIdentifier))!;
            });
            locationIds = locations.map((location: Location) => location.id);
        } else {
            const filteredIssues: Array<Issue> = get(filteredIssuesByContextSelectorFamily(context));
            locationIds = filteredIssues.reduce((locationIds, issue) => {
                if (issue.locationId && locationIds.indexOf(issue.locationId) === -1) {
                    locationIds.push(issue.locationId);
                }
                return locationIds;
            }, new Array<string>());
        }
        const filteredLocations: Array<Location> = locationIds.map((locationId: string) => {
            const locationContextAwareIdentifier: ContextAwareIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
            return get(locationByLocationIdentifierSelectorFamily(locationContextAwareIdentifier))!;
        });
        return locationsLocalCreationTimeSorter
            .sort(filteredLocations)
            .map((location: Location) => location.id);
    }
});

/* Use this for discoverability of issue ID keys and for ordering, in the context of parent location. */
export const filteredIssueIdsByLocationIdentifierSelectorFamily = selectorFamily<Array<string>, ContextAwareIdentifier>({
    key: "filteredIssueIdsByLocationIdSelectorFamily",
    get: (locationIdentifier: ContextAwareIdentifier) => ({ get }) => {
        let filteredIssues: Array<Issue> = get(filteredIssuesByContextSelectorFamily(locationIdentifier.context));
        const issuesClientCreationDateSorter = get(issueSorterSelector);
        filteredIssues = issuesClientCreationDateSorter.sort([...filteredIssues]);

        const issueIds: Array<string> = [];
        for (const issue of filteredIssues) {
            if (locationIdentifier.id === issue.locationId) {
                issueIds.push(issue.id);
            }
        }
        return issueIds;
    }
});

export const issueStatusForIssueIdAtomFamily = atomFamily<IssueStatus, string>({
    key: "issueStatusForIssueIdAtomFamily",
    default: IssueStatus.NONE
});

export const issueIdIssueStatusMapSelector = selectorFamily<Map<string, IssueStatus>, StateContext>({
    key: "issueIdIssueStatusMapSelector",
    get: (context) => ({ get }) => {
        const issueIds = Array.from(get(issueIdToIssueMapByContextSelectorFamily(context)).keys());
        const issueIdToIssueStatusMap = new Map();
        for (const issueId of issueIds) {
            const issueStatus = get(issueStatusForIssueIdAtomFamily(issueId));
            issueIdToIssueStatusMap.set(issueId, issueStatus);
        }
        return issueIdToIssueStatusMap;
    }
});

// DIMENSION STATES BELOW //

export const getFetchDimensionCallback = () => useRecoilCallback(({ snapshot, set }) => {
    return async (
        dimensionId: string,
        context: StateContext
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            fetchDimension(snapshot, set, dimensionId, context);
        } catch (error) {
            throw new FetchDimensionStateError(`Error occurred while trying to Fetch Dimension with id: ${dimensionId}`, error as Error);
        } finally {
            releaseSnapshot();
        }
    };
});

export const getRemoveDimensionCallback = () => useRecoilCallback(({ snapshot, reset }) => {
    return async (
        dimensionId: string,
        context: StateContext
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            await removeDimension(snapshot, reset, dimensionId, context);
        } catch (error) {
            throw new RemoveDimensionStateError(`Error occurred while trying to Remove Dimension with id: ${dimensionId}`, error as Error);
        } finally {
            releaseSnapshot();
        }
    };
});

const fetchDimension = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    dimensionId: string,
    context: StateContext
) => {
    const dimension = await dimensionDAO.getDimensionById(dimensionId);
    if (dimension) {
        const dimensionIdentifier = new ContextAwareIdentifier(dimension.id, context, ModelType.DIMENSION);
        set(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier), dimension);
        let workSpecifications: Array<WorkSpecification> = await workSpecificationDAO.listByDimensionId(dimensionId);
        workSpecifications = workSpecificationItemNumberAscendingSorter.sort(workSpecifications);
        await Promise.all(workSpecifications.map((workSpecification: WorkSpecification) => {
            return fetchWorkSpecificationByIdentifier(snapshot, set, workSpecification.id, context);
        }));
    }
};

const updateDimension = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    dimension: Dimension,
    context: StateContext
): Promise<Dimension> => {
    const recordPersistenceConfiguration = await snapshot.getLoadable(recordPersistenceConfigurationAtom).contents as RecordPersistenceConfiguration;
    let updatedDimension = dimension;
    if (recordPersistenceConfiguration.update === RecordPersistenceMode.ALWAYS_PERSIST) {
        updatedDimension = await dimensionDAO.updateDimension(dimension.id, dimension);
    }
    if (recordPersistenceConfiguration.update === RecordPersistenceMode.PERSIST_RECENTLY_CREATED) {
        const recentRecordIds = snapshot.getLoadable(recentlyCreatedRecordIds).contents as Set<string>;
        if (recentRecordIds.has(dimension.id)) {
            updatedDimension = await dimensionDAO.updateDimension(dimension.id, dimension);
        }
    }
    const dimensionIdentifier = new ContextAwareIdentifier(updatedDimension.id, context, ModelType.DIMENSION);
    set(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier), updatedDimension);
    return updatedDimension;
};

const removeDimension = async (
    snapshot: Snapshot,
    reset: <T>(recoilVal: RecoilState<T>) => void,
    dimensionId: string,
    context: StateContext
) => {
    const recordPersistenceConfiguration = snapshot.getLoadable(recordPersistenceConfigurationAtom).contents as RecordPersistenceConfiguration;
    if (recordPersistenceConfiguration.delete === RecordPersistenceMode.ALWAYS_PERSIST) {
        await dimensionDAO.deleteDimension(dimensionId);
    }
    if (recordPersistenceConfiguration.update === RecordPersistenceMode.PERSIST_RECENTLY_CREATED) {
        const recentRecordIds = snapshot.getLoadable(recentlyCreatedRecordIds).contents as Set<string>;
        if (recentRecordIds.has(dimensionId)) {
            await dimensionDAO.deleteDimension(dimensionId);
        }
    }

    const dimensionIdentifier = new ContextAwareIdentifier(dimensionId, context, ModelType.DIMENSION);
    reset(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier));
    const workSpecificationIds: Array<string> = await snapshot.getPromise(workSpecificationIdsByDimensionIdentifierAtomFamily(dimensionIdentifier));
    workSpecificationIds.forEach((workSpecificationId) => {
        removeWorkSpecification(snapshot, reset, workSpecificationId, context);
    });
};

const associateDimensionWithProperty = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    dimensionId: string,
    propertyId: string,
    context: StateContext
): Promise<Dimension> => {
    const dimensionIdentifier = new ContextAwareIdentifier(dimensionId, context, ModelType.DIMENSION);
    const dimension: Dimension = await snapshot.getLoadable(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier)).contents as Dimension;
    const dimensionWithPropertyId: Dimension = {
        ...dimension,
        propertyId: propertyId
    };
    return await updateDimension(snapshot, set, dimensionWithPropertyId, context);
};

const copyDimensionToContext = (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    dimensionId: string,
    fromContext: StateContext,
    toContext: StateContext
) => {
    const dimensionIdAndFromContextIdentifier = new ContextAwareIdentifier(dimensionId, fromContext, ModelType.DIMENSION);
    const dimensionIdAndToContextIdentifier = new ContextAwareIdentifier(dimensionId, toContext, ModelType.DIMENSION);
    const dimensionToCopy: Dimension = snapshot.getLoadable(
        dimensionByDimensionIdentifierSelectorFamily(dimensionIdAndFromContextIdentifier)
    ).contents as Dimension;

    const dimensionWorkSpecIds: Array<string> = snapshot.getLoadable(
        workSpecificationIdsByDimensionIdentifierAtomFamily(dimensionIdAndFromContextIdentifier)
    ).contents as Array<string>;
    for (const workSpecificationId of dimensionWorkSpecIds) {
        copyWorkSpecificationToContext(snapshot, set, workSpecificationId, fromContext, toContext);
    }

    set(dimensionByDimensionIdentifierSelectorFamily(dimensionIdAndToContextIdentifier), dimensionToCopy);
};

/* Use this atom for discoverability of dimension ID keys and ordering, in the context of parent issue. */
export const dimensionIdsByIssueIdentifierAtomFamily = atomFamily<Array<string>, ContextAwareIdentifier>({
    key: "dimensionIdsByIssueIdentifierAtomFamily",
    default: []
});

// /* Use for import/export. */
export const issueIdToDimensionIdsMapByContextSelectorFamily = selectorFamily<Map<string, Array<string>>, StateContext>({
    key: "issueIdToDimensionIdsMapByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const locationIdToIssueIdsMap: Map<string, Array<string>> = get(locationIdToIssueIdsMapByContextSelectorFamily(context));
        const issuesIdToDimensionIdsMap: Map<string, Array<string>> = new Map<string, Array<string>>();
        for (const issueIds of locationIdToIssueIdsMap.values()) {
            issueIds.reduce((map: Map<string, Array<string>>, issueId: string) => {
                const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
                const dimensionIds: Array<string> = get(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier));
                return map.set(issueId, dimensionIds);
            }, issuesIdToDimensionIdsMap);
        }
        return issuesIdToDimensionIdsMap;
    },
    set: (context: StateContext) => ({ get, set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            return;
        }
        for (const [issueId, dimensionIds] of newValue.entries()) {
            const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
            set(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier), dimensionIds);
        }
    }
});

/* Internal Dimension state storage, do not reference from components */
export const dimensionByDimensionIdentifierAtomFamily = atomFamily<Dimension | null, ContextAwareIdentifier>({
    key: "dimensionByDimensionIdentifierAtomFamily",
    default: null
});

/* Use this selector family for CRUD operations on Dimension state */
export const dimensionByDimensionIdentifierSelectorFamily = selectorFamily<Dimension | null, ContextAwareIdentifier>({
    key: "dimensionByDimensionIdSelectorFamily",
    get: (dimensionIdentifier: ContextAwareIdentifier) => ({ get }) => {
        return get(dimensionByDimensionIdentifierAtomFamily(dimensionIdentifier));
    },
    set: (dimensionIdentifier: ContextAwareIdentifier) => ({ get, set, reset }, newValue) => {
        /* Reset case */
        if (newValue instanceof DefaultValue) {
            const dimensionToDelete: Dimension = get(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier))!;
            if (dimensionToDelete && dimensionToDelete.parentId) {
                const issueIdentifier = new ContextAwareIdentifier(dimensionToDelete.parentId!, dimensionIdentifier.context, ModelType.ISSUE);
                set(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier), (prevValue: Array<string>) => {
                    const updatedValue: Array<string> = [...prevValue];
                    const index: number = updatedValue.indexOf(dimensionIdentifier.id);
                    updatedValue.splice(index, 1);
                    return updatedValue;
                });
                reset(dimensionByDimensionIdentifierAtomFamily(dimensionIdentifier));
            }
            // TODO: reset work specifications
            return;
        }

        /* Set case */
        set(dimensionByDimensionIdentifierAtomFamily(dimensionIdentifier), newValue);
        if (newValue!.parentId) {
            const issueIdentifier = new ContextAwareIdentifier(newValue!.parentId, dimensionIdentifier.context, ModelType.ISSUE);
            set(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier), (prevValue: Array<string>) => {
                if (prevValue.indexOf(dimensionIdentifier.id) !== -1) {
                    return prevValue;
                }
                return [dimensionIdentifier.id, ...prevValue];
            });
        }

        // TODO: If dimension is new (as opposed to edit case), create a default empty work specification.
    }
});

/* Use for import/export. */
export const dimensionIdToDimensionMapByContextSelectorFamily = selectorFamily<Map<string, Dimension>, StateContext>({
    key: "dimensionIdToDimensionMapByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const issueIdToDimensionIdsMap: Map<string, Array<string>> = get(issueIdToDimensionIdsMapByContextSelectorFamily(context));
        const dimensionIds: Array<string> = Array.from(issueIdToDimensionIdsMap.values()).flat();
        return dimensionIds.reduce((map: Map<string, Dimension>, dimensionId: string) => {
            const dimensionIdentifier = new ContextAwareIdentifier(dimensionId, context, ModelType.DIMENSION);
            const dimension: Dimension = get(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier))!;
            return map.set(dimensionId, dimension);
        }, new Map<string, Dimension>());
    },
    set: (context: StateContext) => ({ get, set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            return;
        }
        for (const [dimensionId, dimension] of newValue.entries()) {
            const dimensionIdentifier = new ContextAwareIdentifier(dimensionId, context, ModelType.DIMENSION);
            set(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier), dimension);
        }
    }
});

// WORK_SPECIFICATION STATES BELOW //

export const dimensionToWorkSpecMeasurementPropagationBehavior = selectorFamily<MeasurementPropagationBehavior, ContextAwareIdentifier>({
    key: "dimensionToWorkSpecMeasurementPropagationBehavior",
    get: (dimensionIdentifier: ContextAwareIdentifier) => ({ get }) => {
        // Always copy the parent dimension's measurement to new work specifications.
        return MeasurementPropagationBehavior.COPY;
    }
});

export const workSpecToDimensionMeasurementPropagationBehavior = selectorFamily<MeasurementPropagationBehavior, ContextAwareIdentifier>({
    key: "measurementPropagationBehaviorByWorkSpecificationIdentifier",
    get: (workSpecificationIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const workSpecification = get(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier))!;
        const parentDimensionIdentifier: ContextAwareIdentifier = new ContextAwareIdentifier(workSpecification.dimensionId!, workSpecificationIdentifier.context, ModelType.DIMENSION);
        const parentDimensionWorkSpecIds = get(workSpecificationIdsByDimensionIdentifierAtomFamily(parentDimensionIdentifier));

        // Copy measurement to dimension only if there is more than one work specification.
        if (parentDimensionWorkSpecIds.length === 1) {
            return MeasurementPropagationBehavior.COPY;
        }
        return MeasurementPropagationBehavior.NONE;
    }
});

export const getFetchWorkSpecificationByIdCallback = () => useRecoilCallback(({ snapshot, set }) => {
    return async (
        workSpecificationId: string,
        context: StateContext
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            await fetchWorkSpecificationByIdentifier(snapshot, set, workSpecificationId, context);
        } catch (error) {
            throw new FetchIssueStateError(
                `Error occurred while trying to Fetch State for WorkSpecification with id: ${workSpecificationId}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

export const getRemoveWorkSpecificationCallback = () => useRecoilCallback(({ snapshot, reset }) => {
    return async (
        workSpecificationId: string,
        context: StateContext
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            await removeWorkSpecification(snapshot, reset, workSpecificationId, context);
        } catch (error) {
            throw new RemoveIssueStateError(
                `Error occurred while trying to Remove State for WorkSpecification with id: ${workSpecificationId}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

const fetchWorkSpecificationByIdentifier = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    id: string,
    context: StateContext
) => {
    const workSpecification: WorkSpecification = await workSpecificationDAO.getById(id);
    const workSpecificationIdentifier = new ContextAwareIdentifier(id, context, ModelType.WORK_SPECIFICATION);
    await fetchPermissionsForResource(id, PermissionResourceType.WORK_SPECIFICATION, set);
    set(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier), workSpecification);
    await initializeProposalItemByWorkSpecification(snapshot, set, workSpecification, context);
};

const removeWorkSpecification = async (
    snapshot: Snapshot,
    reset: (recoilVal: RecoilState<any>) => void,
    workSpecificationId: string,
    context: StateContext
) => {
    const recordPersistenceConfiguration = snapshot.getLoadable(recordPersistenceConfigurationAtom).contents as RecordPersistenceConfiguration;
    if (recordPersistenceConfiguration.delete === RecordPersistenceMode.ALWAYS_PERSIST) {
        await workSpecificationDAO.delete(workSpecificationId);
    }
    if (recordPersistenceConfiguration.update === RecordPersistenceMode.PERSIST_RECENTLY_CREATED) {
        const recentRecordIds = snapshot.getLoadable(recentlyCreatedRecordIds).contents as Set<string>;
        if (recentRecordIds.has(workSpecificationId)) {
            await workSpecificationDAO.delete(workSpecificationId);
        }
    }
    const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, context, ModelType.WORK_SPECIFICATION);
    reset(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier));
    await removeProposalItemByWorkSpecificationId(snapshot, reset, context, workSpecificationId);
};

const copyWorkSpecificationToContext = (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    workSpecificationId: string,
    fromContext: StateContext,
    toContext: StateContext
) => {
    const workSpecificationIdAndFromContextIdentifier = new ContextAwareIdentifier(workSpecificationId, fromContext, ModelType.WORK_SPECIFICATION);
    const workSpecificationIdAndToContextIdentifier = new ContextAwareIdentifier(workSpecificationId, toContext, ModelType.WORK_SPECIFICATION);
    const workSpecificationToCopy: WorkSpecification = snapshot.getLoadable(
        workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdAndFromContextIdentifier)
    ).contents as WorkSpecification;
    set(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdAndToContextIdentifier), workSpecificationToCopy);
    const proposalItemId: string | null = snapshot.getLoadable(
        proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdAndFromContextIdentifier)
    ).contents;
    if (proposalItemId) {
        copyProposalItemToContext(snapshot, set, proposalItemId, workSpecificationId, fromContext, toContext);
    }
};

/* Use this atom for discoverability of work specification ID keys and ordering, in the context of parent dimension. */
export const workSpecificationIdsByDimensionIdentifierAtomFamily = atomFamily<Array<string>, ContextAwareIdentifier>({
    key: "workSpecificationIdsByDimensionIdentifierAtomFamily",
    default: []
});

/* Use for import/export. */
export const dimensionIdToWorkSpecificationIdsMapByContextSelectorFamily = selectorFamily<Map<string, Array<string>>, StateContext>({
    key: "workSpecificationIdsByDimensionIdSelector",
    get: (context: StateContext) => ({ get }) => {
        const dimensionIdsByIssueIdMap: Map<string, Array<string>> = get(issueIdToDimensionIdsMapByContextSelectorFamily(context));
        const dimensionIds: Array<string> = Array.from(dimensionIdsByIssueIdMap.values()).flat();
        return dimensionIds.reduce((map: Map<string, Array<string>>, dimensionId: string) => {
            const dimensionIdentifier = new ContextAwareIdentifier(dimensionId, context, ModelType.DIMENSION);
            const workSpecificationIds: Array<string> = get(workSpecificationIdsByDimensionIdentifierAtomFamily(dimensionIdentifier));
            return map.set(dimensionId, workSpecificationIds);
        }, new Map<string, Array<string>>());
    },
    set: (context: StateContext) => ({ get, set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            return;
        }
        for (const [dimensionId, workSpecificationIds] of newValue.entries()) {
            const dimensionIdentifier = new ContextAwareIdentifier(dimensionId, context, ModelType.DIMENSION);
            set(workSpecificationIdsByDimensionIdentifierAtomFamily(dimensionIdentifier), workSpecificationIds);
        }
    }
});

/* Internal WorkSpecification state storage, do not reference from components */
export const workSpecificationByWorkSpecificationIdentifierAtomFamily = atomFamily<WorkSpecification | null, ContextAwareIdentifier>({
    key: "workSpecificationByWorkSpecificationIdentifierAtomFamily",
    default: null
});

/* Use this selector family for CRUD operations on WorkSpecification state */
export const workSpecificationByWorkSpecificationIdentifierSelectorFamily = selectorFamily<WorkSpecification | null, ContextAwareIdentifier>({
    key: "workSpecificationByWorkSpecificationIdentifierSelectorFamily",
    get: (workSpecificationIdentifier: ContextAwareIdentifier) => ({ get }) => {
        return get(workSpecificationByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier));
    },
    set: (workSpecificationIdentifier: ContextAwareIdentifier) => ({ get, set, reset }, newValue) => {
        /* Reset case */
        if (newValue instanceof DefaultValue) {
            const workSpecificationToDelete: WorkSpecification = get(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier))!;
            if (workSpecificationToDelete && workSpecificationToDelete.dimensionId) {
                const dimensionIdentifier = new ContextAwareIdentifier(workSpecificationToDelete.dimensionId!, workSpecificationIdentifier.context, ModelType.DIMENSION);
                set(workSpecificationIdsByDimensionIdentifierAtomFamily(dimensionIdentifier), (prevValue: Array<string>) => {
                    const updatedValue: Array<string> = [...prevValue];
                    const index: number = updatedValue.indexOf(workSpecificationIdentifier.id);
                    updatedValue.splice(index, 1);
                    return updatedValue;
                });
                reset(workSpecificationByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier));
            }
            return;
        }

        /* Set case */
        set(workSpecificationByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier), newValue);
        const dimensionIdentifier = new ContextAwareIdentifier(newValue?.dimensionId!, workSpecificationIdentifier.context, ModelType.DIMENSION);
        set(workSpecificationIdsByDimensionIdentifierAtomFamily(dimensionIdentifier), (prevValue: Array<string>) => {
            if (prevValue.indexOf(workSpecificationIdentifier.id) !== -1) {
                return prevValue;
            }
            return [...prevValue, workSpecificationIdentifier.id];
        });
    }
});

/* Use for import/export. */
export const workSpecificationIdToWorkSpecificationMapByContextSelectorFamily = selectorFamily<Map<string, WorkSpecification>, StateContext>({
    key: "workSpecificationIdToWorkSpecificationMapByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const workSpecificationIdsByDimensionIdMap: Map<string, Array<string>> = get(dimensionIdToWorkSpecificationIdsMapByContextSelectorFamily(context));
        const workSpecificationIds: Array<string> = Array.from(workSpecificationIdsByDimensionIdMap.values()).flat();
        return workSpecificationIds.reduce((map: Map<string, WorkSpecification>, workSpecificationId: string) => {
            const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, context, ModelType.WORK_SPECIFICATION);
            const workSpecification: WorkSpecification | null = get(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier));
            return map.set(workSpecificationId, workSpecification!);
        }, new Map<string, WorkSpecification>());
    },
    set: (context: StateContext) => ({ get, set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            return;
        }
        for (const [workSpecificationId, workSpecification] of newValue.entries()) {
            const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, context, ModelType.WORK_SPECIFICATION);
            set(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier), workSpecification);
        }
    }
});

export const recordPersistenceConfigurationAtom = atom<RecordPersistenceConfiguration>({
    key: "defaultRecordPersistenceConfigurationAtom",
    default: {
        create: RecordPersistenceMode.ALWAYS_PERSIST,
        delete: RecordPersistenceMode.ALWAYS_PERSIST,
        update: RecordPersistenceMode.ALWAYS_PERSIST
    }
});

/* Use for import/export (only for DRAFT documents?) */
export const recentlyCreatedRecordIds = atom<Set<string>>({
    key: "recentlyCreatedRecordIds",
    default: new Set()
});

export const isRecordRecentlyCreatedSelectorFamily = selectorFamily<boolean, string>({
    key: "isRecordRecentlyCreatedSelectorFamily",
    get: (id: string) => ({ get }) => {
        return get(recentlyCreatedRecordIds).has(id);
    }
});

export const locationFeatureConfigurationByContextAtomFamily = atomFamily<LocationFeatureConfiguration, StateContext>({
    key: "locationFeatureConfigurationByContextAtomFamily",
    default: {
        displayContextMenu: true,
        displayViewEditToggle: true,
        allowShare: true,
        selectionBehavior: SelectionBehavior.PROPAGATE_TO_PARENT,
        componentModificationMode: ComponentModificationMode.COMPONENT_DECIDES,
        displayEmptyLocation: true
    }
});

export const issueFeatureConfigurationByContextAtomFamily = atomFamily<IssueFeatureConfiguration, StateContext>({
    key: "issueFeatureConfigurationByContextAtomFamily",
    default: {
        displayContextMenu: true,
        displayViewEditToggle: true,
        displayStatusSelector: true,
        displaySettingsMenu: true,
        allowShare: true,
        selectionBehavior: SelectionBehavior.PROPAGATE_TO_PARENT,
        allowFiltering: false,
        displayCreateButton: true,
        allowValidationToggle: false,
        componentModificationMode: ComponentModificationMode.COMPONENT_DECIDES
    }
});

export const dimensionFeatureConfigurationByContextAtomFamily = atomFamily<DimensionFeatureConfiguration, StateContext>({
    key: "dimensionFeatureConfigurationByContextAtomFamily",
    default: {
        displayContextMenu: true,
        displayViewEditToggle: true,
        componentModificationMode: ComponentModificationMode.COMPONENT_DECIDES
    }
});

export const workSpecificationFeatureConfigurationByContextAtomFamily = atomFamily<WorkSpecificationFeatureConfiguration, StateContext>({
    key: "workSpecificationFeatureConfigurationByContextAtomFamily",
    default: {
        displayContextMenu: true,
        displayViewEditToggle: true,
        displayAddButton: true,
        componentModificationMode: ComponentModificationMode.COMPONENT_DECIDES
    }
});

export const defaultRecordToPropertyAssociationModeAtom = atom<RecordToPropertyAssociationMode>({
    key: "defaultRecordToPropertyAssociationModeAtom",
    default: RecordToPropertyAssociationMode.ASSOCIATE
});

export const solutionContentByContextSelectorFamily = selectorFamily<SolutionContent, StateContext>({
    key: "solutionContentByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        return new SolutionContentBuilder()
            .propertyId(get(propertyIdInFocusAtom) ?? undefined)
            .solutionNotes(get(solutionNotesAtom))
            .locationIdToLocationMap(get(locationIdToLocationMapByContextSelectorFamily(context)))
            .locationIdToIssueIdsMap(get(locationIdToIssueIdsMapByContextSelectorFamily(context)))
            .issueIdToIssueMap(get(issueIdToIssueMapByContextSelectorFamily(context)))
            .issueIdToDimensionIdsMap(get(issueIdToDimensionIdsMapByContextSelectorFamily(context)))
            .dimensionIdToDimensionMap(get(dimensionIdToDimensionMapByContextSelectorFamily(context)))
            .dimensionIdToWorkSpecificationIdsMap(get(dimensionIdToWorkSpecificationIdsMapByContextSelectorFamily(context)))
            .workSpecificationIdToWorkSpecificationMap(get(workSpecificationIdToWorkSpecificationMapByContextSelectorFamily(context)))
            .numberOfLocationsCreated(get(numberOfLocationsCreatedAtomFamily(context)))
            .numberOfIssuesCreated(get(numberOfIssuesCreatedAtomFamily(context)))
            .build();
    }
});

export const solutionMetadataAtom = atom<SolutionMetadata>({
    key: "solutionMetadataAtom",
    default: {}
});

export const isSolutionSoleSourceSelectorFamily = selectorFamily<boolean, StateContext>({
    key: "shouldDisplayPricesSelector",
    get: (context: StateContext) => ({ get }) => {
        const tenderingType: SolutionTenderingType | "SOLE_SOURCE" | "TENDERED" | null | undefined = get(solutionMetadataAtom).tenderingType;
        if (tenderingType != undefined) {
            switch (context) {
                case StateContext.INSPECTION:
                    return true;
                default:
                    return tenderingType == SolutionTenderingType.SOLE_SOURCE;
            }
        }
        // Default to true to support legacy sole source documents without TenderingType
        return true;
    }
});

export const getInitializeSolutionBySolutionId = (context: StateContext) => useRecoilCallback(({ set }) => {
    return async (solutionId: string) => {
        try {
            return await initializeSolutionBySolutionIdAndContext(set, solutionId, context);
        } catch (error) {
            throw new InitializeSolutionStateError(
                `Error occurred while trying to initialize solution content State with id: ${solutionId}`,
                { cause: error as Error }
            );
        }
    };
});

export const solutionNotesAtom = atom<string>({
    key: "solutionNotesAtom",
    default: ""
});

export const solutionRecordAtom = atom<Solution | undefined>({
    key: "solutionRecordAtom",
    default: undefined
});

export const proposalRecordAtom = atom<Proposal | undefined>({
    key: "proposalRecordAtom",
    default: undefined
});

const initializeSolutionBySolutionIdAndContext = async (
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void,
    solutionId: string,
    context: StateContext
) => {
    await fetchPermissionsForResource(solutionId, PermissionResourceType.SOLUTION, set);
    const solutionRecord: Solution = await solutionMajorVersionRecordDAO.getById(solutionId);
    set(solutionRecordAtom, solutionRecord);
    const solutionContent: SolutionContent = await solutionMinorVersionContentDAO.get(solutionRecord.latestContentKey!);
    set(solutionMetadataAtom, solutionRecord.metadata!);
    set(solutionNotesAtom, solutionContent.solutionNotes || "");
    for (const [locationId, location] of solutionContent.locationIdToLocationMap!.entries()) {
        const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
        set(locationByLocationIdentifierSelectorFamily(locationIdentifier), location);
    }
    for (const [issueId, issue] of solutionContent.issueIdToIssueMap!.entries()) {
        const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
        let issueStatus: IssueStatus;
        try {
            const persistedIssue = await issueDAO.getIssueById(issueId);
            issueStatus = persistedIssue.status ? IssueStatus[persistedIssue.status] : IssueStatus.NONE;
        } catch (error) {
            issueStatus = issue.status ? IssueStatus[issue.status] : IssueStatus.NONE;
        }
        set(issueStatusForIssueIdAtomFamily(issueId), issueStatus);
        set(issueByIssueIdentifierSelectorFamily(issueIdentifier), issue);
    }
    for (const [dimensionId, dimension] of solutionContent.dimensionIdToDimensionMap!.entries()) {
        const dimensionIdentifier = new ContextAwareIdentifier(dimensionId, context, ModelType.DIMENSION);
        set(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier), dimension);
    }
    for (const [workSpecificationId, workSpecification] of solutionContent.workSpecificationIdToWorkSpecificationMap!.entries()) {
        const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, context, ModelType.WORK_SPECIFICATION);
        set(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier), workSpecification);
    }
    set(locationIdsByContextAtomFamily(context), Array.from(solutionContent.locationIdToLocationMap!.keys()));
    set(locationIdToIssueIdsMapByContextSelectorFamily(context), solutionContent.locationIdToIssueIdsMap!);
    set(issueIdToDimensionIdsMapByContextSelectorFamily(context), solutionContent.issueIdToDimensionIdsMap!);
    set(dimensionIdToWorkSpecificationIdsMapByContextSelectorFamily(context), solutionContent.dimensionIdToWorkSpecificationIdsMap!);
    set(numberOfLocationsCreatedAtomFamily(context), solutionContent.numberOfLocationsCreated!);
    set(numberOfIssuesCreatedAtomFamily(context), solutionContent.numberOfIssuesCreated!);
    return { solutionRecord, solutionContent };
};

export const getSaveSolution = (context: StateContext) => useRecoilCallback(({ snapshot, set }) => {
    return async (propertyId: string, solutionId?: string, underSameProject?: boolean) => {
        try {
            return await saveSolution(snapshot, set, propertyId, solutionId, underSameProject, context);
        } catch (error) {
            throw new SaveSolutionStateError(
                `Error occurred while trying to save solution with id: ${solutionId}`,
                { cause: error as Error }
            );
        }
    };
});

const saveSolution = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void,
    propertyId: string,
    solutionId: string | undefined,
    underSameProject: boolean | undefined,
    context: StateContext
): Promise<Solution> => {
    const solutionMetadata: SolutionMetadata = await snapshot.getPromise(solutionMetadataAtom);
    const solutionContent: SolutionContent = await snapshot.getPromise(solutionContentByContextSelectorFamily(context));
    const solutionRecord: Solution | undefined = await snapshot.getPromise(solutionRecordAtom);

    const solutionRecordToSave: Solution = {
        id: solutionId || "temporarySolutionId",
        propertyId: propertyId,
        name: solutionMetadata.name!,
        metadata: solutionMetadata,
        status: solutionId ? SolutionStatus.DRAFT : SolutionStatus.NEW_DOCUMENT,
        serialNumber: underSameProject ? solutionRecord?.serialNumber : undefined,
    };
    const savedSolution: Solution = await saveSolutionHandler.handle(
        solutionRecordToSave,
        solutionContent
    );
    set(solutionRecordAtom, savedSolution);
    return savedSolution;
};

export const isLocationBeingCreatedAtom = atom<boolean>({
    key: "isLocationBeingCreatedAtom",
    default: false
});

export const isIssueBeingCreatedAtom = atom<boolean>({
    key: "isIssueBeingCreatedAtom",
    default: false
});

export const issuesByLocationIdentifierSelectorFamily = selectorFamily<Array<Issue>, ContextAwareIdentifier>({
    key: "issuesByLocationIdentifierSelectorFamily",
    get: (locationIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const issueIds = get(issueIdsByLocationIdentifierAtomFamily(locationIdentifier));
        const issues = issueIds.reduce((list, id) => {
            const issueIdentifier = new ContextAwareIdentifier(id, locationIdentifier.context, ModelType.ISSUE);
            const issue: Issue | null = get(issueByIssueIdentifierSelectorFamily(issueIdentifier));
            if (!issue) {
                return list;
            }
            list.push(issue);
            return list;
        }, new Array<Issue>());
        const issuesClientCreationDateDescendingSorter = get(issueSorterSelector);
        return issuesClientCreationDateDescendingSorter.sort(issues);
    }
});

export const getCleanUpPropertyStates = (context: StateContext) => useRecoilCallback(({ snapshot, reset }) => {
    return () => {
        try {
            cleanUpPropertyStates(snapshot, reset, context);
        } catch (error) {
            throw new CleanUpPropertyStateError(
                `Error occurred while trying to clean up property states in ${context}`,
                { cause: error as Error }
            );
        }
    };
});

const cleanUpPropertyStates = (
    snapshot: Snapshot,
    reset: (recoilVal: RecoilState<any>) => void,
    context: StateContext
) => {
    const issueIdToDimensionIdsMap: Map<string, Array<string>> = snapshot.getLoadable(issueIdToDimensionIdsMapByContextSelectorFamily(context)).contents;
    const dimensionIdToWorkSpecificationIdsMap: Map<string, Array<string>> = snapshot.getLoadable(dimensionIdToWorkSpecificationIdsMapByContextSelectorFamily(context)).contents;

    const locationIds: Array<string> = snapshot.getLoadable(locationIdsByContextAtomFamily(context)).contents;
    const issueIds: Array<string> = Array.from(issueIdToDimensionIdsMap.keys());
    const dimensionIds: Array<string> = Array.from(dimensionIdToWorkSpecificationIdsMap.keys());
    const workSpecificationIds: Array<string> = Array.from(dimensionIdToWorkSpecificationIdsMap.values()).flat();

    locationIds.forEach(locationId => {
        const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
        reset(locationByLocationIdentifierSelectorFamily(locationIdentifier));
        reset(issueIdsByLocationIdentifierAtomFamily(locationIdentifier));
    });

    issueIds.forEach(issueId => {
        const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
        reset(issueStatusForIssueIdAtomFamily(issueId));
        reset(issueByIssueIdentifierSelectorFamily(issueIdentifier));
        reset(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier));
    });

    dimensionIds.forEach(dimensionId => {
        const dimensionIdentifier = new ContextAwareIdentifier(dimensionId, context, ModelType.DIMENSION);
        reset(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier));
        reset(workSpecificationIdsByDimensionIdentifierAtomFamily(dimensionIdentifier));
    });

    workSpecificationIds.forEach(workSpecificationId => {
        const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, context, ModelType.WORK_SPECIFICATION);
        reset(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier));
    });
    reset(locationIdsByContextAtomFamily(context));
};

export const getCleanUpSolutionStates = (context: StateContext) => useRecoilCallback(({ snapshot, reset }) => {
    return () => {
        try {
            cleanUpSolutionStates(snapshot, reset, context);
        } catch (error) {
            throw new CleanUpSolutionStateError(
                `Error occurred while trying to clean up solution states in ${context}`,
                { cause: error as Error }
            );
        }
    };
});

const cleanUpSolutionStates = (
    snapshot: Snapshot,
    reset: (recoilVal: RecoilState<any>) => void,
    context: StateContext
) => {
    cleanUpPropertyStates(snapshot, reset, context);
    reset(numberOfLocationsCreatedAtomFamily(context));
    reset(numberOfIssuesCreatedAtomFamily(context));
    reset(solutionRecordAtom);
    reset(solutionMetadataAtom);
    reset(solutionNotesAtom);
};

export const workSpecificationIdsByIssueIdentifierSelectorFamily = selectorFamily<Array<string>, ContextAwareIdentifier>({
    key: "workSpecificationIdsByIssueIdentifierSelectorFamily",
    get: (identifier: ContextAwareIdentifier) => ({ get }) => {
        const dimensionIds: Array<string> = get(dimensionIdsByIssueIdentifierAtomFamily(identifier));
        return dimensionIds.reduce((list, dimensionId) => {
            const dimensionIdentifier = new ContextAwareIdentifier(dimensionId, identifier.context, ModelType.DIMENSION);
            const workSpecificationIds = get(workSpecificationIdsByDimensionIdentifierAtomFamily(dimensionIdentifier));
            return list.concat(workSpecificationIds);
        }, new Array<string>());
    }
});

export const isSavingSolutionAtom = atom<boolean>({
    key: "isSavingSolutionAtom",
    default: false
});

export const attachmentRecordsByIdentifierAtomFamily = atomFamily<Attachment[], ContextAwareIdentifier>({
    key: "attachmentRecordsByIdentifierAtomFamily",
    default: []
});

export const resizedImageUrlByAttachmentKeyAtomFamily = atomFamily<string | null, string>({
    key: "resizedImageUrlByAttachmentKeyAtomFamily",
    default: null
});

export const resizedImageUrlsByIdentifierSelectorFamily = selectorFamily<Array<string>, ContextAwareIdentifier>({
    key: "resizedImageUrlsByIdentifierSelectorFamily",
    get: (identifier: ContextAwareIdentifier) => ({ get }) => {
        const attachments: Array<Attachment> = get(attachmentRecordsByIdentifierAtomFamily(identifier));
        return attachments.reduce((list, attachment) => {
            const imageUrl = get(resizedImageUrlByAttachmentKeyAtomFamily(attachment.key!));
            if (imageUrl) {
                list.push(imageUrl);
            }
            return list;
        }, new Array<string>());
    }
});

export const propertyImageUrlAtom = atom<string | undefined>({
    key: "propertyImageUrlAtom",
    default: undefined
});

export const numberOfAttachmentsByContextSelectorFamily = selectorFamily<number, StateContext>({
    key: "numberOfAttachmentsByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const issueIds = Array.from(get(issueIdToIssueMapByContextSelectorFamily(context)).keys());
        return issueIds.reduce((count, issueId) => {
            const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
            const attachments = get(attachmentRecordsByIdentifierAtomFamily(issueIdentifier));
            return count + attachments.length;
        }, 0);
    }
});

export const numberOfImagesResizedByContextSelectorFamily = selectorFamily<number, StateContext>({
    key: "numberOfImagesResizedForClipboardByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const issueIds = Array.from(get(issueIdToIssueMapByContextSelectorFamily(context)).keys());
        return issueIds.reduce((count, issueId) => {
            const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
            const resizedImageUrlsForClipboard = get(resizedImageUrlsByIdentifierSelectorFamily(issueIdentifier));
            return count + resizedImageUrlsForClipboard.length;
        }, 0);
    }
});

export const workTypeIdToUnitCountMapByContextSelectorFamily = selectorFamily<Map<string, number>, StateContext>({
    key: "workTypeIdToUnitCountMapByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const workTypeSpecifications: Array<WorkSpecification> = Array.from(get(workSpecificationIdToWorkSpecificationMapByContextSelectorFamily(context)).values());
        return workTypeSpecifications.reduce((map, workSpecification) => {
            const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecification.id!, context, ModelType.WORK_SPECIFICATION);
            const proposalItemId: string | null = get(proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier));
            if (proposalItemId && workSpecification.workTypeId) {
                const proposalItemIdentifier = new ContextAwareIdentifier(proposalItemId, context, ModelType.PROPOSAL_ITEM);
                const unitCount = get(unitCountByProposalItemIdentifierSelectorFamily(proposalItemIdentifier)) ?? 0;
                const unitCountInFoot = DimensionUnitConverter.convertUnitMeasurement(
                    DimensionType[workSpecification.measurement?.dimensionType!],
                    MeasurementUnit[workSpecification.measurement?.measurementUnit!],
                    MeasurementUnit.FOOT,
                    unitCount
                );
                map.set(workSpecification.workTypeId, (map.get(workSpecification.workTypeId) || 0) + unitCountInFoot);
            }
            return map;
        }, new Map<string, number>());
    }
});
