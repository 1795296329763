import {
    Proposal,
    Solution
} from "../../../models";

import GraphQLProposalRecordDAOFactory from "../dao/record/GraphQLProposalRecordDAOFactory";
import GraphQLSolutionDAOFactory from "../../solution/majorVersion/dao/GraphQLSolutionDAOFactory";
import { NoLatestProposalRecordFoundError } from "./errors/NoLatestProposalRecordFoundError";
import ProposalRecordDAO from "../dao/record/ProposalRecordDAO";
import SolutionDAO from "../../solution/majorVersion/dao/SolutionDAO";
import uuidv4 from "../../util/UuidGenerator";

const solutionMajorVersionDAO: SolutionDAO = GraphQLSolutionDAOFactory.getInstance();
const proposalRecordDAO: ProposalRecordDAO = GraphQLProposalRecordDAOFactory.getInstance();

export const useRecoverProposal = () => {

    const recoverProposalBySolutionId = async (solutionId: string) => {
        const solution: Solution = await solutionMajorVersionDAO.getById(solutionId);
        const proposals = await proposalRecordDAO.listBySolutionIdAndMinorVersion(solution.id);
        const isLatestProposalRecordExisted = proposals.some(proposal => proposal.designDocumentVersion === solution.latestMinorVersion);
        if (isLatestProposalRecordExisted) {
            return;
        }
        const latestProposal: Proposal | undefined = proposals.reduce((latestProposal: Proposal | undefined, proposal) => {
            if (proposal.proposalKey && proposal.designDocumentVersion > (latestProposal?.designDocumentVersion ?? -1)) {
                return proposal;
            }
            return latestProposal;
        }, undefined);
        if (!latestProposal) {
            throw new NoLatestProposalRecordFoundError(`No latest proposal record found for solution with id=${solutionId}`);
        }
        await proposalRecordDAO.create({
            id: uuidv4(),
            designDocumentGroupId: latestProposal.designDocumentGroupId,
            designDocumentVersion: solution.latestMinorVersion!,
            proposalKey: latestProposal.proposalKey,
            status: latestProposal.status,
            schemaVersion: latestProposal.schemaVersion
        });
    };

    return {
        recoverProposalBySolutionId
    };
};