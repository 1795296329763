import {
    Box,
    IconButton,
    ListItem
} from "@mui/material";

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ConfirmationPrompt from "../general/ConfirmationPrompt";
import { DefaultTemplateDAOFactory } from "../../lib/template/DefaultTemplateDAOFactory";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { EditTemplate } from "./EditTemplate";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Template } from "../../models";
import { TemplateDAO } from "../../lib/template/TemplateDAO";
import { ViewTemplate } from "./ViewTemplate";
import globalStyle from "../../assets/style/globalStyle";
import { useSnackbar } from "notistack";
import { useState } from "react";

type TemplateListItemProps = {
    template: Template;
    refetchTemplates: () => Promise<void>;
    isViewOnly?: boolean;
};

const templateDAO: TemplateDAO = DefaultTemplateDAOFactory.getInstance();

export const TemplateListItem = (props: TemplateListItemProps) => {
    const { refetchTemplates, isViewOnly = false } = props;
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [template, setTemplate] = useState<Template>(props.template);
    const [templateForm, setTemplateForm] = useState<Template>(template);
    const [newTemplateFile, setNewTemplateFile] = useState<File | undefined>(undefined);
    const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);
    const snackbar = useSnackbar();
    const globalClasses = globalStyle();

    const onSaveButtonClick = async () => {
        try {
            const updatedTemplate = await templateDAO.update(template.id, templateForm, newTemplateFile);
            setTemplate(updatedTemplate);
            setNewTemplateFile(undefined);
            setIsEditMode(false);
        } catch (e) {
            snackbar.enqueueSnackbar("Failed to update template.", { variant: "error" });
        }
    };

    const onDeleteConfirmation = async () => {
        try {
            await templateDAO.delete(template.id, (template as any)._version);
            await refetchTemplates();
        } catch (e) {
            snackbar.enqueueSnackbar("Failed to delete template.", { variant: "error" });
        } finally {
            setShowDeletePrompt(false);
        }
    };

    return (
        <ListItem
            sx={{
                backgroundColor: "background.paper",
                gap: 1
            }}
        >
            {isEditMode ? (
                <EditTemplate
                    newTemplateFile={newTemplateFile}
                    setNewTemplateFile={setNewTemplateFile}
                    templateForm={templateForm}
                    setTemplateForm={setTemplateForm}
                />
            ) : (
                <ViewTemplate
                    template={template}
                />
            )}
            <Box display="flex" gap={1} alignSelf="flex-start" flexDirection={{ xs: "column", md: "row" }}>
                {isViewOnly ? (
                    <>
                        {/* Dummy boxes to keep the layout consistent */}
                        <Box width="34px" height="34px" />
                        <Box width="34px" height="34px" />
                    </>
                ) : isEditMode ? (
                    <>
                        <IconButton
                            size="small"
                            className={globalClasses.squareIconButton}
                            onClick={onSaveButtonClick}
                        >
                            <SaveOutlinedIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            className={globalClasses.squareIconButton}
                            onClick={() => {
                                setTemplateForm(template);
                                setIsEditMode(false);
                            }}
                        >
                            <CancelOutlinedIcon />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <IconButton
                            size="small"
                            className={globalClasses.squareIconButton}
                            onClick={() => setIsEditMode(true)}
                        >
                            <EditOutlinedIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            className={globalClasses.squareIconButton}
                            onClick={() => setShowDeletePrompt(true)}
                        >
                            <DeleteOutlineIcon />
                        </IconButton>
                    </>
                )}
            </Box>
            <ConfirmationPrompt
                isVisible={showDeletePrompt}
                promptTitle={`Delete Template ${template.name}`}
                promptMessage="Are you sure you want to Delete this Template?"
                onCancelButtonClicked={() => {
                    setShowDeletePrompt(false);
                }}
                onConfirmButtonClicked={onDeleteConfirmation}
            />
        </ListItem >
    );
};
