import {
    ResourceType,
    Solution,
    SolutionMinorVersion,
    SolutionStatus
} from "../../../../../models";

import InvalidSolutionStateTransitionError from "../../../majorVersion/dao/error/InvalidSolutionStateTransitionError";
import SaveSolutionHandler from "./SaveSolutionHandler";
import SaveSolutionHandlerError from "../error/SaveSolutionHandlerError";
import SerialNumberGenerator from "../../../../serialNumber/SerialNumberGenerator";
import { SolutionAttachmentPopulator } from "../attachment/SolutionAttachmentPopulator";
import SolutionContent from "../../../../design/types/SolutionContent";
import SolutionDAO from "../../../majorVersion/dao/SolutionDAO";
import SolutionMinorVersionContentDAO from "../../../minorVersion/dao/content/v2/SolutionMinorVersionContentDAO";
import SolutionMinorVersionRecordDAO from "../../../minorVersion/dao/record/SolutionMinorVersionRecordDAO";
import { UuidGenerator } from "../../../../util/UuidGenerator";

export default class SaveSolutionHandlerImpl implements SaveSolutionHandler {

    private readonly solutionDAO: SolutionDAO;
    private readonly solutionMinorVersionRecordDAO: SolutionMinorVersionRecordDAO;
    private readonly solutionMinorVersionContentDAO: SolutionMinorVersionContentDAO;
    private readonly minorVersionGenerator: SerialNumberGenerator;
    private readonly solutionAttachmentPopulator: SolutionAttachmentPopulator;
    private readonly uuidGenerator: UuidGenerator;

    constructor(
        solutionDAO: SolutionDAO,
        minorVersionGenerator: SerialNumberGenerator,
        solutionMinorVersionRecordDAO: SolutionMinorVersionRecordDAO,
        solutionMinorVersionContentDAO: SolutionMinorVersionContentDAO,
        solutionAttachmentPopulator: SolutionAttachmentPopulator,
        uuidGenerator: UuidGenerator
    ) {
        this.solutionDAO = solutionDAO;
        this.minorVersionGenerator = minorVersionGenerator;
        this.solutionMinorVersionRecordDAO = solutionMinorVersionRecordDAO;
        this.solutionMinorVersionContentDAO = solutionMinorVersionContentDAO;
        this.solutionAttachmentPopulator = solutionAttachmentPopulator;
        this.uuidGenerator = uuidGenerator;
    }

    public async handle(
        solutionRecord: Solution,
        solutionContent: SolutionContent
    ): Promise<Solution> {
        try {
            return await this._handle(solutionRecord, solutionContent);
        } catch (error) {
            // Solution status does not support modifications, so create a new Solution
            if ((error as Error).cause instanceof InvalidSolutionStateTransitionError) {
                // Create a copy of old Solution with new ID
                const newSolutionRecord: Solution = {
                    ...solutionRecord,
                    latestMinorVersion: undefined,
                    latestContentKey: undefined,
                    status: SolutionStatus.NEW_DOCUMENT
                };
                return await this._handle(newSolutionRecord, solutionContent);
            }
            throw error;
        }
    }

    private async _handle(
        solutionRecord: Solution,
        solutionContent: SolutionContent
    ): Promise<Solution> {
        const solutionId: string = solutionRecord.status === SolutionStatus.NEW_DOCUMENT ?
            this.uuidGenerator.generate() : solutionRecord.id;
        try {
            // Create new solution minor version...
            const minorVersion: number = await this.minorVersionGenerator.generate(
                solutionId,
                ResourceType.SOLUTION
            );

            // ...populate attachments to solutionContent...
            const contentWithAttachments = await this.solutionAttachmentPopulator.populateAttachments(solutionContent);

            // ...save the content first; in case it fails, we won't advertise the record to the user
            const contentKey: string = await this.solutionMinorVersionContentDAO.save(solutionId, minorVersion, contentWithAttachments);
            const solutionMinorVersion: SolutionMinorVersion = {
                id: this.uuidGenerator.generate(),
                solutionId: solutionId,
                minorVersion: minorVersion,
                contentKey: contentKey,
                name: solutionRecord.name,
                propertyId: solutionRecord.propertyId
            };
            // ...save the record referencing the content by key
            const createdSolutionMinorVersion: SolutionMinorVersion = await this.solutionMinorVersionRecordDAO.create(
                solutionMinorVersion
            );

            // Populate Solution with additional attributes and create/update it
            const solutionWithAdditionalAttributes: Solution = {
                ...solutionRecord,
                id: solutionId,
                latestMinorVersion: createdSolutionMinorVersion.minorVersion!,
                latestContentKey: createdSolutionMinorVersion.contentKey,
                status: SolutionStatus.DRAFT
            };
            if (solutionRecord.status === SolutionStatus.NEW_DOCUMENT) {
                return await this.solutionDAO.create(solutionWithAdditionalAttributes);
            }
            return await this.solutionDAO.update(solutionWithAdditionalAttributes);
        } catch (error) {
            throw new SaveSolutionHandlerError(
                `Error occurred while saving the Solution with ID=${solutionRecord.id}`,
                {cause: error as Error}
            );
        }
    }
}
