import {
    ReactNode,
    useEffect
} from "react";
import {
    dataStorePropertySyncStatusAtom,
    isDataStoreStoppedAtom
} from "../../lib/datastore/state/DataStoreState";

import { DataStoreStatus } from "../../lib/datastore/DataStoreStatus";
import { LinearProgress } from "@mui/material";
import { useDataStoreSynchronizer } from "../../lib/datastore/sync/hook/useDataStoreSynchronizer";
import { useRecoilValue } from "recoil";

type StartPropertiesChildrenSyncWrapperProps = {
    children: ReactNode;
};

export const StartPropertiesChildrenSyncWrapper = ({ children }: StartPropertiesChildrenSyncWrapperProps) => {
    const { syncPropertiesChildren } = useDataStoreSynchronizer();
    const dataStorePropertySyncStatus = useRecoilValue<DataStoreStatus>(dataStorePropertySyncStatusAtom);
    const isDataStoreStopped = useRecoilValue<boolean>(isDataStoreStoppedAtom);

    useEffect(() => {
        if (dataStorePropertySyncStatus === DataStoreStatus.NOT_READY) {
            syncPropertiesChildren();
        }
    }, []);

    return (
        <>
            {isDataStoreStopped ? <LinearProgress /> : children}
        </>
    );
};
