import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const AddIssueIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    style={{
      //@ts-ignore
      enableBackground: "new 0 0 24 24",
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M1.8 19 12 1.9l5.5 9.1h1.1L12 0 0 20h11.3c-.1-.3-.2-.7-.2-1H1.8zm9.2-8v5h.3c.3-1.1.9-2.1 1.7-2.9V11h-2zm0-3h2v2h-2V8z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M18 12a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm2.667 6.667h-2v2a.666.666 0 0 1-1.333 0v-2h-2a.667.667 0 0 1 0-1.333h2v-2a.667.667 0 0 1 1.333 0v2h2a.666.666 0 1 1 0 1.333z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(AddIssueIcon);
