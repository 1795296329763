import { DimensionIngressCompletionCheckerFactory } from "./factory/DimensionIngressCompletionCheckerFactory";
import { IssueIngressCompletionCheckerFactory } from "./factory/IssueIngressCompletionCheckerFactory";
import { LocationIngressCompletionCheckerFactory } from "./factory/LocationIngressCompletionCheckerFactory";
import { PermissionResourceType } from "../../../models";
import { PropertyIngressCompletionCheckerFactory } from "./factory/PropertyIngressCompletionCheckerFactory";
import { ProposalIngressCompletionCheckerFactory } from "./factory/ProposalIngressCompletionCheckerFactory";
import { ResourceIngressCompletionChecker } from "./ResourceIngressCompletionChecker";
import { SolutionIngressCompletionCheckerFactory } from "./factory/SolutionIngressCompletionCheckerFactory";
import { WorkSpecificationIngressCompletionCheckerFactory } from "./factory/WorkSpecificationIngressCompletionCheckerFactory";

export class IngressCompletionCheckerMapFactory {
    private static INSTANCE = new Map<PermissionResourceType, ResourceIngressCompletionChecker>([
        [PermissionResourceType.PROPERTY, PropertyIngressCompletionCheckerFactory.getInstance()],
        [PermissionResourceType.LOCATION, LocationIngressCompletionCheckerFactory.getInstance()],
        [PermissionResourceType.ISSUE, IssueIngressCompletionCheckerFactory.getInstance()],
        [PermissionResourceType.DIMENSION, DimensionIngressCompletionCheckerFactory.getInstance()],
        [PermissionResourceType.WORK_SPECIFICATION, WorkSpecificationIngressCompletionCheckerFactory.getInstance()],
        [PermissionResourceType.SOLUTION, SolutionIngressCompletionCheckerFactory.getInstance()],
        [PermissionResourceType.PROPOSAL, ProposalIngressCompletionCheckerFactory.getInstance()],
    ])

    public static getInstance() {
        return this.INSTANCE;
    }
}