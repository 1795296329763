import {
    Grid,
    IconButton,
    Stack
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ViewWorkTypeDetail from "./ViewWorkTypeDetail";
import WorkTypeDTO from "../../../lib/worktype/DTO/WorkTypeDTO"
import { WorkTypeDisplayMode } from "../../../lib/worktype/state/WorkTypeDisplayMode";
import globalStyle from "../../../assets/style/globalStyle";
import { useSetRecoilState } from "recoil";
import { workTypeDisplayModeAtomFamily } from "../../../lib/worktype/state/WorkTypeState";

type ViewWorkTypeManagementListItemForAdminProps = {
    readonly workType: WorkTypeDTO;
};

const ViewWorkTypeManagementListItemForAdmin = (props: ViewWorkTypeManagementListItemForAdminProps) => {
    /* Internal page state */
    const { workType } = props;
    const setDisplayMode = useSetRecoilState<WorkTypeDisplayMode>(workTypeDisplayModeAtomFamily(workType.groupId));

    /* Dependencies */
    const globalClasses = globalStyle();

    return (
        <Grid
            container
            wrap="nowrap"
            alignItems="flex-start"
            direction="row"
            justifyContent="space-between"
        >
            <ViewWorkTypeDetail workType={workType} />
            <Grid item alignItems="center">
                <Stack
                    minWidth="100%"
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <IconButton
                        className={globalClasses.squareIconButton}
                        onClick={() => setDisplayMode(WorkTypeDisplayMode.EDIT)}
                        size="small"
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        className={globalClasses.squareIconButton}
                        onClick={() => setDisplayMode(WorkTypeDisplayMode.ASSOCIATION)}
                        size="small"
                    >
                        <PersonAddIcon />
                    </IconButton>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default ViewWorkTypeManagementListItemForAdmin;
