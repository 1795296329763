import { GraphQLUserDAOFactory } from "../../lib/user/GraphQLUserDAOFactory";
import { SearchBox } from "../../componentsV2/misc/SearchBox";
import { User } from "../../models";
import { UserDAO } from "../../lib/user/UserDAO";
import { useRef } from "react";

interface UserSearchBoxProps {
    readonly onSelect: (users: Array<User>) => void;
    readonly onClear: () => void;
    readonly userIdsToExclude: Array<string>;
    readonly selectedUsers: Array<User>;
    readonly label: string;
}

export const UserSearchBox = (props: UserSearchBoxProps) => {
    const { onSelect, onClear, userIdsToExclude, selectedUsers, label } = props;
    const userDaoRef = useRef<UserDAO>(GraphQLUserDAOFactory.getInstance());

    return (
        <SearchBox
            labelFields={["name"]}
            typeName="User"
            label={label}
            onClear={onClear}
            onSelect={onSelect}
            search={(searchValue, nextToken?, limit?) => userDaoRef.current.searchByUsernameOrEmail(searchValue, userIdsToExclude, nextToken, limit)}
            multiple
            value={selectedUsers}
        />
    );
};

export default UserSearchBox;
