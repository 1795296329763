import { AuthIDTokenSupplierFactory } from "../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../logging/ClientLoggerFactory";
import { ExternalReportBrandingDAO } from "./ExternalReportBrandingDAO";
import { GraphQLExternalReportBrandingDAO } from "./GraphQLExternalReportBrandingDAO";

export class GraphQLExternalReportBrandingDAOFactory {
    private static INSTANCE: ExternalReportBrandingDAO = new GraphQLExternalReportBrandingDAO(
        ClientLoggerFactory.getClientLogger("GraphQLExternalReportBrandingDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance(): ExternalReportBrandingDAO {
        return this.INSTANCE;
    }
}