import {
    Box,
    Button,
    LinearProgress,
    Typography
} from "@mui/material";
import {
    ChevronDownIcon,
    ChevronUpIcon
} from "../icons";
import {
    DOCUMENT_COMPONENT,
    TENDER_DETAILS_FORM_COMPONENT,
    componentIdToInitializationStateSelectorFamily,
    componentReadyToRenderStateByComponentIdAtomFamily,
    parentIdToChildInitializationStateIds
} from "../../lib/design/document/state/DocumentInitializationState";
import {
    E_ALREADY_LOCKED,
    Mutex,
    tryAcquire
} from "async-mutex";
import {
    Property,
    Proposal,
    Solution,
    SolutionMetadata,
    SolutionOperationType
} from "../../models";
import {
    ProviderContext,
    useSnackbar
} from "notistack";
import {
    clearTimeout,
    setTimeout
} from "timers";
import {
    defaultRecordToPropertyAssociationModeAtom,
    dimensionFeatureConfigurationByContextAtomFamily,
    getCleanUpSolutionStates,
    getCopyIssueToContextCallback,
    getCopyLocationToContextCallback,
    getInitializeSolutionBySolutionId,
    getSaveSolution,
    isSavingSolutionAtom,
    isSolutionSoleSourceSelectorFamily,
    issueByIssueIdentifierSelectorFamily,
    issueFeatureConfigurationByContextAtomFamily,
    locationFeatureConfigurationByContextAtomFamily,
    recentlyCreatedRecordIds,
    recordPersistenceConfigurationAtom,
    solutionContentByContextSelectorFamily,
    solutionMetadataAtom,
    workSpecificationFeatureConfigurationByContextAtomFamily
} from "../../lib/design/document/state/DocumentState";
import {
    getCleanUpProposalStates,
    getInitializeProposalAndBackfillConfiguredPrice,
    getSaveProposal,
    proposalContentByContextSelectorFamily
} from "../../lib/design/bidding/state/v2/ProposalStates";
import {
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    useHistory,
    useParams
} from "react-router-dom";
import {
    useRecoilCallback,
    useRecoilState,
    useRecoilValue,
    useResetRecoilState,
    useSetRecoilState
} from "recoil";

import { AwaitPropertiesChildrenSyncedWrapper } from "../sync/AwaitPropertiesChildrenSyncedWrapper";
import { ComponentModificationMode } from "../../lib/design/document/state/ComponentModificationMode";
import { ComponentReadyToRenderState } from "../../lib/design/document/state/ComponentReadyToRenderState";
import ConfirmationPrompt from "../general/ConfirmationPrompt";
import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { DefaultTenderDAOFactory } from "../../lib/tender/dao/DefaultTenderDAOFactory";
import { DimensionFeatureConfiguration } from '../../lib/design/document/state/DimensionFeatureConfiguration';
import GraphQLSolutionEventClientFactory from "../../lib/solution/solutionEvent/GraphQLSolutionEventClientFactory";
import { H1_TYPOGRAPHY_STYLE } from "./TypographyConstants";
import { IssueFeatureConfiguration } from '../../lib/design/document/state/IssueFeatureConfiguration';
import { IssuePicker } from "./IssuePicker";
import LinkData from "../../lib/util/link/LinkData";
import { LocationFeatureConfiguration } from "../../lib/design/document/state/LocationFeatureConfiguration";
import LocationManager from "../../componentsV2/location/LocationManager";
import { ModelType } from "../../lib/design/document/ModelType";
import { PersistantMutexProvider } from "../../lib/util/concurrency/PersistantMutexProvider";
import PropertyListLinkFactory from "../../lib/util/link/PropertyListLinkFactory";
import ProposalContent from "../../lib/design/bidding/ProposalContent";
import { ProposalItemFeatureConfiguration } from "../../lib/design/document/state/ProposalItemFeatureConfiguration";
import { ProposalSummaryForm } from "./ProposalSummaryForm";
import PublishSolutionHandler from "../../lib/solution/operation/publish/PublishSolutionHandler";
import PublishSolutionHandlerFactory from "../../lib/solution/operation/publish/PublishSolutionHandlerFactory";
import { RecordPersistenceConfiguration } from "../../lib/design/document/state/RecordPersistenceConfiguration";
import { RecordPersistenceMode } from "../../lib/design/document/state/RecordPersistenceMode";
import { RecordToPropertyAssociationMode } from "../../lib/design/document/state/RecordToPropertyAssociationMode";
import { RecoverProposalDialog } from "./RecoverProposalDialog";
import ResponsiveBreadcrumbs from "../ResponsiveBreadcrumbs";
import { SelectionBehavior } from "../../lib/ui/SelectionBehavior";
import SolutionContent from "../../lib/design/types/SolutionContent";
import SolutionEventClient from "../../lib/solution/solutionEvent/SolutionEventClient";
import SolutionLinkFactory from "../../lib/util/link/SolutionLinkFactory";
import SolutionNotesField from "./note/SolutionNotesField";
import { StateContext } from "../../lib/design/document/state/StateContext";
import SubmitProposalHandler from "../../lib/proposal/operation/submit/SubmitProposalHandler";
import SubmitProposalHandlerFactory from "../../lib/proposal/operation/submit/SubmitProposalHandlerFactory";
import { TenderDAO } from "../../lib/tender/dao/TenderDAO";
import { TenderDetailsForm } from "./TenderDetailsForm";
import { WorkSpecificationFeatureConfiguration } from '../../lib/design/document/state/WorkSpecificationFeatureConfiguration';
import { WorkTypeFetchWrapper } from "../WorkTypeFetchWrapper";
import globalStyle from "../../assets/style/globalStyle";
import { isDocumentValidSelector } from "../../lib/design/document/state/DocumentValidationState";
import { propertyIdToPropertySelectorFamily } from "../../lib/property/state/PropertyRecoilState";
import { proposalItemFeatureConfigurationByContextAtomFamily } from "../../lib/design/bidding/state/v2/ProposalItemStates";
import { selectedIssueIdsByContextAtomFamily } from "../../lib/design/document/state/IssuePickerState";
import { stateContextInFocusAtom } from "../../lib/ui/InFocusRecoilStates";
import { usePageDefaultStyle } from "../../assets/style/usePageDefaultStyle";
import { useSearchParams } from "../../lib/util/hooks/useSearchParams";
import { useSelectResource } from "../../lib/design/document/hooks/useSelectResource";

type EditSolutionParams = {
    readonly propertyId: string;
    readonly solutionId?: string;
};

const tenderDAO: TenderDAO = DefaultTenderDAOFactory.getInstance();

const EditSolution = () => {
    /* Constants */
    const COMPONENT_NAME: string = "EditSolution";
    const RECORD_PERSISTENCE_CONFIGURATION: RecordPersistenceConfiguration = {
        create: RecordPersistenceMode.ALWAYS_PERSIST,
        update: RecordPersistenceMode.PERSIST_RECENTLY_CREATED,
        delete: RecordPersistenceMode.NEVER_PERSIST
    };
    const SOLUTION_LOCATION_FEATURE_CONFIGURATION: LocationFeatureConfiguration = {
        displayContextMenu: true,
        displayViewEditToggle: false,
        allowShare: false,
        selectionBehavior: SelectionBehavior.DISABLED,
        componentModificationMode: ComponentModificationMode.ALWAYS_EDIT,
        displayEmptyLocation: true
    };
    const SOLUTION_ISSUE_FEATURE_CONFIGURATION: IssueFeatureConfiguration = {
        displayContextMenu: true,
        displayViewEditToggle: false,
        displayCreateButton: true,
        displayStatusSelector: true,
        displaySettingsMenu: false,
        allowShare: false,
        selectionBehavior: SelectionBehavior.DISABLED,
        allowFiltering: false,
        allowValidationToggle: false,
        componentModificationMode: ComponentModificationMode.ALWAYS_EDIT
    };
    const SOLUTION_DIMENSION_FEATURE_CONFIGURATION: DimensionFeatureConfiguration = {
        displayContextMenu: true,
        displayViewEditToggle: false,
        componentModificationMode: ComponentModificationMode.ALWAYS_EDIT
    };
    const SOLUTION_WORK_SPECIFICATION_FEATURE_CONFIGURATION: WorkSpecificationFeatureConfiguration = {
        displayContextMenu: true,
        displayViewEditToggle: false,
        displayAddButton: true,
        componentModificationMode: ComponentModificationMode.ALWAYS_EDIT
    };
    const SOLUTION_PROPOSAL_ITEM_FEATURE_CONFIGURATION: ProposalItemFeatureConfiguration = {
        calculateAdjustedPrice: true,
        componentModificationMode: ComponentModificationMode.ALWAYS_EDIT
    };
    const DEFAULT_RECORD_TO_PROPERTY_ASSOCIATION_MODE: RecordToPropertyAssociationMode = RecordToPropertyAssociationMode.DO_NOT_ASSOCIATE;

    /* Internal page states */
    const { propertyId, solutionId } = useParams<EditSolutionParams>();
    const property = useRecoilValue<Property | null>(propertyIdToPropertySelectorFamily(propertyId));
    const solutionIdRef = useRef<string | undefined>(solutionId);
    const [isIssuePickerOpen, setIsIssuePickerOpen] = useState<boolean>(false);
    const [showTenantDetails, setShowTenantDetails] = useState<boolean>(true);
    const [showRecoverProposalDialog, setShowRecoverProposalDialog] = useState<boolean>(false);
    const isDocumentInitialized = useRecoilValue(componentIdToInitializationStateSelectorFamily(DOCUMENT_COMPONENT));
    const [componentReadyToRender, setComponentReadyToRender] = useRecoilState<ComponentReadyToRenderState>(componentReadyToRenderStateByComponentIdAtomFamily(DOCUMENT_COMPONENT));
    const setStateContextInFocus = useSetRecoilState(stateContextInFocusAtom);
    const setChildComponentIdsToInitialize = useSetRecoilState(parentIdToChildInitializationStateIds(DOCUMENT_COMPONENT));
    const isDocumentValid = useRecoilValue(isDocumentValidSelector);
    const setRecordPersistenceConfiguration = useSetRecoilState(recordPersistenceConfigurationAtom);
    const setLocationFeatureConfigurationForSolutionContext = useSetRecoilState(locationFeatureConfigurationByContextAtomFamily(StateContext.SOLUTION_AUTHORING_CONTENT));
    const setIssueFeatureConfigurationForSolutionContext = useSetRecoilState(issueFeatureConfigurationByContextAtomFamily(StateContext.SOLUTION_AUTHORING_CONTENT));
    const setDimensionFeatureConfigurationForSolutionContext = useSetRecoilState(dimensionFeatureConfigurationByContextAtomFamily(StateContext.SOLUTION_AUTHORING_CONTENT));
    const setWorkSpecificationFeatureConfigurationForSolutionContext = useSetRecoilState(workSpecificationFeatureConfigurationByContextAtomFamily(StateContext.SOLUTION_AUTHORING_CONTENT));
    const setProposalItemFeatureConfigurationForSolutionContext = useSetRecoilState(proposalItemFeatureConfigurationByContextAtomFamily(StateContext.SOLUTION_AUTHORING_CONTENT));
    const setDefaultRecordToPropertyAssociationMode = useSetRecoilState(defaultRecordToPropertyAssociationModeAtom);
    const selectedIssueIds = useRecoilValue(selectedIssueIdsByContextAtomFamily(StateContext.SOLUTION_AUTHORING_ISSUE_PICKER));
    const { resetSelectedResources } = useSelectResource(StateContext.SOLUTION_AUTHORING_ISSUE_PICKER);
    const isSoleSource = useRecoilValue<boolean>(isSolutionSoleSourceSelectorFamily(StateContext.SOLUTION_AUTHORING_CONTENT));

    const resetDefaultRecordToPropertyAssociationMode = useResetRecoilState(defaultRecordToPropertyAssociationModeAtom);
    const resetRecordPersistenceConfiguration = useResetRecoilState(recordPersistenceConfigurationAtom);

    const resetRecentlyCreatedRecordIds = useResetRecoilState(recentlyCreatedRecordIds);

    /* Save/Publish solution dependencies */
    const solutionContent = useRecoilValue<SolutionContent>(solutionContentByContextSelectorFamily(StateContext.SOLUTION_AUTHORING_CONTENT));
    const solutionMetadata = useRecoilValue<SolutionMetadata>(solutionMetadataAtom);
    const proposalContent = useRecoilValue<ProposalContent>(proposalContentByContextSelectorFamily(StateContext.SOLUTION_AUTHORING_CONTENT));
    const setIsSavingSolution = useSetRecoilState<boolean>(isSavingSolutionAtom);
    const solutionEventClientRef = useRef<SolutionEventClient>(GraphQLSolutionEventClientFactory.getInstance());
    const publishSolutionHandlerRef = useRef<PublishSolutionHandler>(PublishSolutionHandlerFactory.getInstance());
    const submitProposalHandlerRef = useRef<SubmitProposalHandler>(SubmitProposalHandlerFactory.getInstance());
    const [isSaveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(true);
    const [isPublishButtonEnabled, setPublishButtonEnabled] = useState<boolean>(true);
    const persistDocumentLockRef = useRef<Mutex>(PersistantMutexProvider.getMutex("persistDocumentLock"));
    const autosaveConcurrencyLockRef = useRef<Mutex>(new Mutex());
    const cancelScheduledAutosaveJobRef = useRef<() => void | undefined>();

    const initializeSolutionBySolutionId = getInitializeSolutionBySolutionId(StateContext.SOLUTION_AUTHORING_CONTENT);
    const initializeProposalAndBackfillConfiguredPrice = getInitializeProposalAndBackfillConfiguredPrice(StateContext.SOLUTION_AUTHORING_CONTENT);
    const cleanUpSolutionStates: () => void = getCleanUpSolutionStates(StateContext.SOLUTION_AUTHORING_CONTENT);
    const cleanUpProposalStates: () => void = getCleanUpProposalStates(StateContext.SOLUTION_AUTHORING_CONTENT);
    const saveSolution = getSaveSolution(StateContext.SOLUTION_AUTHORING_CONTENT);
    const saveProposal: (solutionId: string, solutionMinorVersion: number) => Promise<Proposal> = getSaveProposal(StateContext.SOLUTION_AUTHORING_CONTENT);
    const publishedSolutionCallbackRef = useRef<() => (void | Promise<void>) | undefined>();
    const updateTenderCallbackRef = useRef<() => (void | Promise<void>) | undefined>();
    const [openUpdateTenderDialog, setOpenUpdateTenderDialog] = useState<boolean>(false);

    /* Dependencies */
    const globalClasses = globalStyle();
    const copyIssueToContext = getCopyIssueToContextCallback();
    const copyLocationToContext = getCopyLocationToContextCallback();
    const snackbar: ProviderContext = useSnackbar();
    const { pageDefaultStyle } = usePageDefaultStyle();
    const history = useHistory();
    const { params } = useSearchParams();

    useEffect(() => {
        initialize();
        return () => {
            const cleanUp = async () => {
                if (autosaveConcurrencyLockRef.current.isLocked()) {
                    await handleSaveSolution(true);
                }
                cancelScheduledAutosaveJobRef.current?.();
                setComponentReadyToRender(ComponentReadyToRenderState.NOT_READY);
                await persistDocumentLockRef.current.runExclusive(() => {
                    resetRecoilState();
                });
            };
            cleanUp();
        };
    }, []);

    const initialize = async () => {
        const release = await persistDocumentLockRef.current.acquire();
        try {
            setChildComponentIdsToInitialize([TENDER_DETAILS_FORM_COMPONENT]);
            setRecordPersistenceConfiguration(RECORD_PERSISTENCE_CONFIGURATION);
            setLocationFeatureConfigurationForSolutionContext(SOLUTION_LOCATION_FEATURE_CONFIGURATION);
            setIssueFeatureConfigurationForSolutionContext(SOLUTION_ISSUE_FEATURE_CONFIGURATION);
            setDimensionFeatureConfigurationForSolutionContext(SOLUTION_DIMENSION_FEATURE_CONFIGURATION);
            setWorkSpecificationFeatureConfigurationForSolutionContext(SOLUTION_WORK_SPECIFICATION_FEATURE_CONFIGURATION);
            setProposalItemFeatureConfigurationForSolutionContext(SOLUTION_PROPOSAL_ITEM_FEATURE_CONFIGURATION);
            setDefaultRecordToPropertyAssociationMode(DEFAULT_RECORD_TO_PROPERTY_ASSOCIATION_MODE);
            if (solutionIdRef.current) {
                const { solutionContent, solutionRecord } = await initializeSolutionBySolutionId(solutionIdRef.current);
                try {
                    await initializeProposalAndBackfillConfiguredPrice(solutionRecord, solutionContent);
                } catch (error) {
                    setShowRecoverProposalDialog(true);
                    throw error;
                }
            }
            setStateContextInFocus(StateContext.SOLUTION_AUTHORING_CONTENT);
            setComponentReadyToRender(ComponentReadyToRenderState.READY);
        } finally {
            release();
        }
    };

    const resetRecoilState = () => {
        resetDefaultRecordToPropertyAssociationMode();
        resetRecordPersistenceConfiguration();
        resetRecentlyCreatedRecordIds();
        cleanUpSolutionStates();
        cleanUpProposalStates();
    };

    const breadCrumbs = useMemo(() => {
        if (!property) {
            return null;
        }
        if (isDocumentInitialized) {
            const links: Array<LinkData> = [];
            links.push(PropertyListLinkFactory.create());
            links.push(SolutionLinkFactory.createByProperty(property));

            return (
                <ResponsiveBreadcrumbs
                    links={links}
                    currentPageText={solutionMetadata.name || "Edit Solution"}
                />
            );
        }
    }, [property, solutionMetadata.name, isDocumentInitialized]);

    const saveSolutionAndNotifyUser = async () => {
        const sameProject: boolean = params.get("sameProject") === "true";
        const savedSolutionRecord: Solution = await saveSolution(propertyId, solutionIdRef.current, sameProject);
        if (solutionIdRef.current && solutionIdRef.current !== savedSolutionRecord.id) {
            snackbar.enqueueSnackbar("Saved as new document since the old was in read-only status.", { variant: "info" });
        }
        solutionIdRef.current = savedSolutionRecord.id;
        const url = new URL(`properties/${propertyId}/solutions/${savedSolutionRecord.id}/edit`, window.location.origin);
        url.search = params.toString();
        window.history.pushState(null, "", url);
        return savedSolutionRecord;
    };

    const handleSaveSolution = async (isAutosave: boolean) => {
        cancelScheduledAutosaveJobRef.current?.();
        tryAcquire(persistDocumentLockRef.current)
            .runExclusive(async () => {
                try {
                    setSaveButtonEnabled(false);
                    setPublishButtonEnabled(false);
                    setIsSavingSolution(true);

                    const savedSolution: Solution = await saveSolutionAndNotifyUser();
                    const savedProposal: Proposal = await saveProposal(savedSolution.id, savedSolution.latestMinorVersion!);
                    await solutionEventClientRef.current.create(savedSolution.id, savedSolution.latestMinorVersion!, savedProposal.id, SolutionOperationType.SAVE);

                    let successMessage: string = "Saved the document.";
                    if (isAutosave) {
                        successMessage = "Auto-saved the document.";
                    }
                    snackbar.enqueueSnackbar(successMessage, { variant: isAutosave ? "info" : "success" });
                } finally {
                    setSaveButtonEnabled(true);
                    setPublishButtonEnabled(true);
                    setIsSavingSolution(false);
                }
            })
            .catch(error => {
                if (error !== E_ALREADY_LOCKED) {
                    snackbar.enqueueSnackbar("There was an error saving the document.", { variant: "error" });
                }
            });
    };

    const handlePublishDocument = async () => {
        cancelScheduledAutosaveJobRef.current?.();
        tryAcquire(persistDocumentLockRef.current)
            .runExclusive(async () => {
                try {
                    publishedSolutionCallbackRef.current = undefined;
                    updateTenderCallbackRef.current = undefined;
                    setSaveButtonEnabled(false);
                    setPublishButtonEnabled(false);

                    const savedSolution: Solution = await saveSolutionAndNotifyUser();
                    const savedProposal: Proposal = await saveProposal(savedSolution.id, savedSolution.latestMinorVersion!);

                    const publishedSolution = await publishSolutionHandlerRef.current.handle(savedSolution.id);
                    await submitProposalHandlerRef.current.handle(savedProposal.id);
                    await solutionEventClientRef.current.create(savedSolution.id, savedSolution.latestMinorVersion!, savedProposal.id, SolutionOperationType.PUBLISH);
                    try {
                        const existingTender = await tenderDAO.getByProjectNumber(savedSolution.serialNumber!);
                        if (existingTender) {
                            updateTenderCallbackRef.current = async () => {
                                await tenderDAO.update(savedSolution.serialNumber!, publishedSolution);
                            };
                            publishedSolutionCallbackRef.current = async () => {
                                snackbar.enqueueSnackbar("Published the document.", { variant: "success" });
                                history.replace(`/properties/${propertyId}/solutions/${savedSolution.id}/view?version=${savedSolution.latestMinorVersion!}`);
                            };
                            setOpenUpdateTenderDialog(true);
                            return;
                        }
                    } catch (ignored) {
                        // Ignore error
                    }
                    snackbar.enqueueSnackbar("Published the document.", { variant: "success" });
                    history.replace(`/properties/${propertyId}/solutions/${savedSolution.id}/view?version=${savedSolution.latestMinorVersion!}`);
                } finally {
                    setSaveButtonEnabled(true);
                }
            })
            .catch(error => {
                if (error !== E_ALREADY_LOCKED) {
                    snackbar.enqueueSnackbar("There was an error publishing the document.", { variant: "error" });
                }
            });
    };

    const scheduleAutosave = async (): Promise<void> => {
        await tryAcquire(autosaveConcurrencyLockRef.current)
            .runExclusive(async () => {
                await new Promise<void>((resolve, reject) => {
                    const timeout = setTimeout(async () => {
                        await handleSaveSolution(true);
                        resolve();
                    }, 120000);
                    cancelScheduledAutosaveJobRef.current = () => {
                        clearTimeout(timeout);
                        resolve();
                    };
                });
            })
            .catch((error) => {
                if (error !== E_ALREADY_LOCKED) {
                    snackbar.enqueueSnackbar("There was an error auto saving the document.", { variant: "error" });
                }
            });
    };

    useEffect(() => {
        if (isDocumentInitialized) {
            scheduleAutosave();
        }
    }, [solutionMetadata, solutionContent, proposalContent]);

    const onIssuesSelected = useRecoilCallback(({ snapshot, set }) => {
        return async () => {
            // Copy selected issues to document context
            const locationIdsToSet: Array<string> = [];
            for (const issueId of selectedIssueIds) {
                copyIssueToContext(issueId, StateContext.SOLUTION_AUTHORING_ISSUE_PICKER, StateContext.SOLUTION_AUTHORING_CONTENT);
                const issueIdentifier = new ContextAwareIdentifier(issueId, StateContext.SOLUTION_AUTHORING_ISSUE_PICKER, ModelType.ISSUE);
                const issue = await snapshot.getPromise(issueByIssueIdentifierSelectorFamily(issueIdentifier));
                if (issue && issue.locationId) {
                    locationIdsToSet.push(issue.locationId);
                }
            }
            // Also Copy locations for selected issues to document context
            for (const locationId of locationIdsToSet) {
                copyLocationToContext(locationId, StateContext.SOLUTION_AUTHORING_ISSUE_PICKER, StateContext.SOLUTION_AUTHORING_CONTENT);
            }
            resetSelectedResources();
            setIsIssuePickerOpen(false);
        };
    });

    return (
        <AwaitPropertiesChildrenSyncedWrapper>
            <WorkTypeFetchWrapper>
                <Box
                    sx={pageDefaultStyle}
                    className={globalClasses.managerLayout}
                >
                    {componentReadyToRender === ComponentReadyToRenderState.NOT_READY ? (
                        <LinearProgress />
                    ) : (
                        <>
                            {breadCrumbs}
                            <Box
                                flexDirection="column"
                                display="flex"
                                justifyContent="center"
                                alignItems="left"
                                gap={2}
                                mt={2}
                            >
                                <Box display="flex" justifyContent="space-between" flexWrap="wrap-reverse">
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        flex={1}
                                        onClick={() => setShowTenantDetails(original => !original)}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <Typography {...H1_TYPOGRAPHY_STYLE}>
                                            Tender Information
                                        </Typography>
                                        <Box display="flex">
                                            {showTenantDetails ?
                                                <ChevronUpIcon primary="primary" /> :
                                                <ChevronDownIcon primary="primary" />
                                            }
                                        </Box>
                                    </Box>
                                    <Box display="flex" gap={1} mx="auto">
                                        <Button
                                            disabled={!isSaveButtonEnabled}
                                            variant="outlined"
                                            onClick={() => handleSaveSolution(false)}
                                            size="small"
                                            sx={{ px: 5 }}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            color="primary"
                                            disabled={!isPublishButtonEnabled || !isDocumentValid}
                                            variant="contained"
                                            onClick={handlePublishDocument}
                                            size="small"
                                            sx={{ px: 5 }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Box>
                                <TenderDetailsForm
                                    property={property!}
                                    showTenantDetails={showTenantDetails}
                                />
                                <SolutionNotesField />
                                {
                                    isSoleSource &&
                                    <ProposalSummaryForm
                                        context={StateContext.SOLUTION_AUTHORING_CONTENT}
                                    />
                                }
                                <IssuePicker
                                    open={isIssuePickerOpen}
                                    onIssuesSelected={onIssuesSelected}
                                />
                                {
                                    solutionContent.issueIdToDimensionIdsMap?.size != 0 &&
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={() => setIsIssuePickerOpen(true)}
                                    >
                                        Select Issues
                                    </Button>
                                }

                                <Box mt={1}></Box>
                                <LocationManager
                                    propertyId={propertyId}
                                    stateContext={StateContext.SOLUTION_AUTHORING_CONTENT}
                                />
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={() => setIsIssuePickerOpen(true)}
                                >
                                    Select Issues
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
                <RecoverProposalDialog
                    solutionId={solutionIdRef.current ?? ""}
                    open={showRecoverProposalDialog}
                    onCancel={() => {
                        setShowRecoverProposalDialog(false);
                        history.push(`/properties/${propertyId}/solutions`);
                    }}
                    onRecovered={async () => {
                        await initialize();
                        setShowRecoverProposalDialog(false);
                    }}
                    onRemoved={async () => {
                        setShowRecoverProposalDialog(false);
                        solutionIdRef.current = undefined;
                        resetRecoilState();
                        history.push(`/properties/${propertyId}/solutions/create`);
                        await initialize();
                    }}
                />
                <ConfirmationPrompt
                    isVisible={openUpdateTenderDialog}
                    promptTitle="Update Tender"
                    promptMessage="Do you want to update the tender with the latest version of the solution?"
                    onCancelButtonClicked={async () => {
                        await publishedSolutionCallbackRef.current?.();
                        setOpenUpdateTenderDialog(false);
                    }}
                    onConfirmButtonClicked={async () => {
                        await updateTenderCallbackRef.current?.();
                        await publishedSolutionCallbackRef.current?.();
                        setOpenUpdateTenderDialog(false);
                    }}
                />
            </WorkTypeFetchWrapper>
        </AwaitPropertiesChildrenSyncedWrapper>
    );
};

export default EditSolution;
