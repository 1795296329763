import {
    RendererFactory,
    StylesManager
} from 'survey-core';

import MUISurveyQuestionCheckbox from '../../components/wizard/MUISurveyQuestionCheckbox';
import MUISurveyQuestionDropdown from '../../components/wizard/MUISurveyQuestionDropdown';
import MUISurveyQuestionText from '../../components/wizard/MUISurveyQuestionText';
import { MUISurveyTeamSearchBox } from '../../components/wizard/MUISurveyTeamSearchBox';
import { ReactQuestionFactory } from 'survey-react-ui';
import { createElement } from 'react';

export default class SurveyJSGlobalStateSetupHandler {
    private initialized: boolean;

    constructor() {
        this.initialized = false;
    }

    public setup() {
        if (this.initialized) {
            return;
        }
        const modernThemeColors = StylesManager.ThemeColors['modern'];
        modernThemeColors['$main-color'] = '#1976d2';
        modernThemeColors['$answer-background-color'] = 'inherit';
        modernThemeColors['$error-background-color'] = 'inherit';
        StylesManager.applyTheme('modern');

        ReactQuestionFactory.Instance.registerQuestion(
            "sv-mui-text",
            (props) => {
                return createElement(MUISurveyQuestionText, props);
            }
        );
        ReactQuestionFactory.Instance.registerQuestion(
            "sv-mui-checkbox",
            (props) => {
                return createElement(MUISurveyQuestionCheckbox, props);
            }
        );
        ReactQuestionFactory.Instance.registerQuestion(
            "sv-mui-dropdown",
            (props) => {
                return createElement(MUISurveyQuestionDropdown, props);
            }
        );

        ReactQuestionFactory.Instance.registerQuestion(
            "sv-team-search",
            function (props) {
                return createElement(MUISurveyTeamSearchBox, props);
            }
        );

        RendererFactory.Instance.registerRenderer(
            "multipletext",
            "teamsearch",
            "sv-team-search"
        );
        RendererFactory.Instance.registerRenderer(
            "text",
            "muitext",
            "sv-mui-text"
        );
        RendererFactory.Instance.registerRenderer(
            "checkbox",
            "muicheckbox",
            "sv-mui-checkbox"
        );
        RendererFactory.Instance.registerRenderer(
            "dropdown",
            "muidropdown",
            "sv-mui-dropdown"
        );
        this.initialized = true;
    }
}