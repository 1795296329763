import {
    FormEvent,
    useMemo,
    useState
} from "react";

import Alert from '@mui/material/Alert';
import { AlertMessage } from '../../lib/util/AlertMessage';
import { Auth } from "aws-amplify";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

type SignUpConfirmationFormProps = {
    email: string;
};
export const SignUpConfirmationForm = (props: SignUpConfirmationFormProps) => {
    const { email } = props;
    const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [isResendingConfirmationCode, setIsResendingConfirmationCode] = useState<boolean>(false);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            setIsConfirming(true);
            const data = new FormData(event.currentTarget);
            setAlertMessage(undefined);
            await Auth.confirmSignUp(email, data.get('confirmationCode')?.toString()!);
        } catch (error) {
            setAlertMessage({
                severity: "error",
                message: (error as Error).message
            });
        } finally {
            setIsConfirming(false);
        }
    };

    const resendConfirmationCode = async () => {
        try {
            setIsResendingConfirmationCode(true);
            await Auth.resendSignUp(email);
            setAlertMessage({
                severity: "info",
                message: "Confirmation code resent."
            });
        } catch (error) {
            setAlertMessage({
                severity: "error",
                message: (error as Error).message
            });
        } finally {
            setIsResendingConfirmationCode(false);
        }
    };

    const maskedEmail = useMemo(() => {
        const atIndex = email.indexOf('@');
        const firstChar = email[0];
        const afterAtChar = email[atIndex + 1];
        return `${firstChar}***@${afterAtChar}***`;
    }, [email]);

    return (
        <Container component="main" maxWidth="xs">
            <Box
                mt={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" fontWeight="500">
                                We Emailed You
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Your code is on the way. To log in, enter the code we emailed to {maskedEmail}. It may take a minute to arrive.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="confirmationCode"
                                label="Confirmation Code"
                                name="confirmationCode"
                            />
                        </Grid>
                        {alertMessage &&
                            <Grid item xs={12}>
                                <Alert severity={alertMessage.severity}> {alertMessage.message}</Alert>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                loading={isConfirming}
                                startIcon={<></>}
                                loadingPosition="start"
                            >
                                Confirm
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                fullWidth
                                variant="outlined"
                                onClick={resendConfirmationCode}
                                loading={isResendingConfirmationCode}
                                startIcon={<></>}
                                loadingPosition="start"
                            >
                                Resend Code
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};