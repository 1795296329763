import {
    AddWorktypeIcon,
    EditIcon,
    SaveCloseIcon
} from "../icons";
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    FormControlLabel,
    ThemeProvider,
    Typography,
    lighten
} from "@mui/material";
import {
    Dimension,
    Issue,
    PermissionType
} from "../../models";
import {
    darkBlue,
    orange
} from "../../assets/style/colors";
import {
    defaultRecordToPropertyAssociationModeAtom,
    dimensionByDimensionIdentifierSelectorFamily,
    dimensionIdsByIssueIdentifierAtomFamily,
    getAssociateIssueWithPropertyCallback,
    getUpdateIsEditModeCallback,
    isRecordRecentlyCreatedSelectorFamily,
    isSolutionSoleSourceSelectorFamily,
    issueByIssueIdentifierSelectorFamily,
    issueFeatureConfigurationByContextAtomFamily
} from "../../lib/design/document/state/DocumentState";
import {
    isSelectionModeAtomFamily,
    selectionFeatureConfigurationByContextAtomFamily
} from "../../lib/design/document/state/SelectionModeState";
import {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef
} from "react";

import { AttachmentImageCarousel } from "../../componentsV2/image/AttachmentImageCarousel";
import { CHECK_BOX_WIDTH } from "../../lib/ui/Constants";
import { ChildrenExpandState } from "../../lib/design/document/state/enum/ChildrenExpandState";
import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import IssueCardHeader from "../../componentsV2/issue/IssueCardHeader";
import { IssueFeatureConfiguration } from "../../lib/design/document/state/IssueFeatureConfiguration";
import { IssueStatusSelectMenu } from "./IssueStatusSelectMenu";
import MeasurementUnitField from "../../componentsV2/wss/MeasurementUnitField";
import { ModelType } from "../../lib/design/document/ModelType";
import NumberStringConverter from "../../lib/util/NumberStringConverter";
import { PermissionResourceType } from "../../models";
import { PermissionRestrictedControl } from "../general/button/PermissionRestrictedControl";
import { RecordToPropertyAssociationMode } from "../../lib/design/document/state/RecordToPropertyAssociationMode";
import { Resource } from "../../componentsV2/permission/type/Resource";
import { SelectionBehavior } from "../../lib/ui/SelectionBehavior";
import { SelectionFeatureConfiguration } from "../../lib/design/document/state/SelectionFeatureConfiguration";
import { SolutionAuthoringEnabledPermissionRestrictedControl } from "../general/button/SolutionAuthoringEnabledPermissionRestrictedControl";
import { StateContext } from "../../lib/design/document/state/StateContext";
import ValidationResult from "../../lib/util/validation/ValidationResult";
import WorkSpecificationByIssue from "../../componentsV2/wss/WorkSpecificationByIssue";
import WorkSpecificationSummaryByIssue from "../../componentsV2/wss/WorkSpecificationSummaryByIssue";
import { childrenExpandStateByDesignElementIdentifierAtomFamily } from "../../lib/design/document/state/ComponentExpandState";
import { elementFocusManager } from "../../lib/ui/ElementFocusManager";
import { getEndOfIssueRefId } from "./name/getEndOfIssueRefId";
import { isIssueSelectedByIssueIdentifierSelectorFamily } from "../../lib/design/document/state/IssuePickerState";
import { isUpdatingPermissionByResourceAtomFamily } from "../../componentsV2/permission/state/ManagePermissionState";
import { issueTheme } from "../../assets/style/issueTheme";
import { propertyIdInFocusAtom } from "../../lib/ui/InFocusRecoilStates";
import { proposalItemPriceTotalByIssueIdentifierSelectorFamily } from "../../lib/design/bidding/state/v2/ProposalItemStates";
import { useCreateWorkSpecification } from "../../lib/design/workscopespecification/hook/useCreateWorkSpecification";
import { useRecoilValue } from "recoil";
import { useScrollResourceIntoView } from "../../lib/ui/hooks/useScrollResourceIntoView";
import { useSelectResource } from "../../lib/design/document/hooks/useSelectResource";
import { useSetChildrenExpandState } from "../../lib/design/hooks/useSetChildrenExpandState";
import { useSlidesPerView } from "../../componentsV2/image/hooks/useSlidesPerView";
import { useUpdateChildMeasurementUnit } from "../../lib/issue/hook/useUpdateChildMeasurementUnit";
import { validationResultByDesignElementIdentifierSelectorFamily } from "../../lib/design/document/state/DocumentValidationState";
import { workSpecificationTheme } from "../../assets/style/workSpecificationTheme";

const responsiveCardHeights = {
    xs: "245px",
    sm: "260px",
    lg: "290px"
};

export interface ViewIssueProps {
    issueId: string;
    stateContext: StateContext;
}

export const END_OF_WS_SECTION = "end-of-ws-section";

export const IssueCard = memo((props: ViewIssueProps) => {
    const { issueId, stateContext } = props;

    const issueIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(issueId, stateContext, ModelType.ISSUE);
    }, [issueId, stateContext]);
    const resource = useMemo<Resource>(() => {
        return new Resource(issueId, PermissionResourceType.ISSUE);
    }, [issueId]);
    const endOfWsSectionId = useMemo<string>(() => {
        return getEndOfIssueRefId(issueId)
    }, [issueId]);

    const propertyId = useRecoilValue<string | null>(propertyIdInFocusAtom);
    const isSelectionMode = useRecoilValue<boolean>(isSelectionModeAtomFamily(stateContext));
    const selectionFeatureConfiguration = useRecoilValue<SelectionFeatureConfiguration>(selectionFeatureConfigurationByContextAtomFamily(stateContext));
    const isUpdatingResourcePermission = useRecoilValue<boolean>(isUpdatingPermissionByResourceAtomFamily(resource));
    const defaultRecordToPropertyAssociationMode = useRecoilValue<RecordToPropertyAssociationMode>(defaultRecordToPropertyAssociationModeAtom);

    const issue = useRecoilValue<Issue | null>(issueByIssueIdentifierSelectorFamily(issueIdentifier));
    const isIssueRecentlyCreated = useRecoilValue<boolean>(isRecordRecentlyCreatedSelectorFamily(issueId));
    const issueFeatureConfiguration = useRecoilValue<IssueFeatureConfiguration>(issueFeatureConfigurationByContextAtomFamily(stateContext));
    const allowSelectIssue = issueFeatureConfiguration.selectionBehavior !== SelectionBehavior.DISABLED;
    const isValidIssue = useRecoilValue<ValidationResult>(validationResultByDesignElementIdentifierSelectorFamily(issueIdentifier));
    const childrenExpandState = useRecoilValue<ChildrenExpandState>(childrenExpandStateByDesignElementIdentifierAtomFamily(issueIdentifier));
    const issuePriceTotal = useRecoilValue(proposalItemPriceTotalByIssueIdentifierSelectorFamily(issueIdentifier));
    const setIsEditMode = getUpdateIsEditModeCallback();

    const dimensionIds = useRecoilValue(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier));
    const defaultDimensionId: string = dimensionIds[0]; // TODO: update the default dimension after the first dimension has been edited
    const dimensionIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(defaultDimensionId, stateContext, ModelType.WORK_SPECIFICATION);
    }, [defaultDimensionId]);
    const parentDimension = useRecoilValue<Dimension | null>(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier));

    const isIssueSelected = useRecoilValue(isIssueSelectedByIssueIdentifierSelectorFamily(issueIdentifier));
    const { selectIssue } = useSelectResource(stateContext);
    const isEditModeEnabled = childrenExpandState === ChildrenExpandState.EXPAND_CHILDREN;
    const issueElementRef = useRef<Element>();
    const statusBoxRef = useRef<Element>(null);

    const { scrollResourceIntoView } = useScrollResourceIntoView(stateContext);
    const associateIssueWithProperty = getAssociateIssueWithPropertyCallback();
    const { createDefaultWorkSpecification } = useCreateWorkSpecification(stateContext);
    const { setModelChildrenExpandState } = useSetChildrenExpandState(stateContext);
    const setChildrenExpandState = (childrenExpandState: ChildrenExpandState) => {
        setModelChildrenExpandState(ModelType.ISSUE, issueId, childrenExpandState);
    };
    const shouldDisplayPrices = useRecoilValue<boolean>(isSolutionSoleSourceSelectorFamily(stateContext));

    const addWorkSpecificationAndExpandAccordion = useCallback(async () => {
        await createDefaultWorkSpecification(defaultDimensionId, parentDimension?.parentId!, propertyId, true);
        setChildrenExpandState(ChildrenExpandState.EXPAND_CHILDREN);
        const lastWorkSpecificationElement = document.getElementById(endOfWsSectionId);
        scrollResourceIntoView(lastWorkSpecificationElement, "bottom");
    }, [createDefaultWorkSpecification, parentDimension, endOfWsSectionId]);

    const { updateChildMeasurementUnit } = useUpdateChildMeasurementUnit(stateContext);
    const sizeDynamicSlidesPerView: number = useSlidesPerView();
    const slidesPerView = isEditModeEnabled ? sizeDynamicSlidesPerView : 1;

    useEffect(() => {
        if (isEditModeEnabled && issueElementRef.current) {
            elementFocusManager.setIssueElement(issueElementRef.current);
        }
    }, [isEditModeEnabled]);

    return (
        <ThemeProvider theme={isEditModeEnabled ? workSpecificationTheme : issueTheme}>
            <Box
                ref={issueElementRef}
                display="flex"
                flexDirection="column"
                gap={1}
                sx={{
                    borderLeft: theme => `10px solid ${isValidIssue.isValid ? theme.palette.primary.main : theme.palette.error.main}`,
                    backgroundColor: theme => lighten(theme.palette.primary.light, 0.75),
                }}
                py={1}
            >
                <Typography variant="caption" color="red">{isValidIssue.message}</Typography>
                <IssueCardHeader
                    issueId={issueId}
                    stateContext={stateContext}
                />
                <Box width="100%" sx={{ display: "flex", flexDirection: "row", alignItems: "stretch" }}>
                    {allowSelectIssue && isSelectionMode && (
                        <Box width={CHECK_BOX_WIDTH} display="flex">
                            <PermissionRestrictedControl
                                requiredPermission={selectionFeatureConfiguration.requiredPermission}
                                permissionOverridden={!selectionFeatureConfiguration.permissionChecking}
                                resourceId={issueId}
                            >
                                {(disabled) => (
                                    <Checkbox
                                        checked={isIssueSelected}
                                        onChange={(e) => {
                                            selectIssue(issueId, e.target.checked);
                                        }}
                                        disabled={disabled || isUpdatingResourcePermission}
                                    />
                                )}
                            </PermissionRestrictedControl>
                        </Box>
                    )}
                    <Box
                        position="relative"
                        display="flex"
                        justifyContent={isEditModeEnabled ? "flex-end" : "flex-start"}
                        width={allowSelectIssue && isSelectionMode ? `calc(100% - ${CHECK_BOX_WIDTH})` : "100%"}
                        sx={{
                            flexDirection: {
                                xs: "column-reverse",
                                sm: "row"
                            }
                        }}
                    >
                        {!isEditModeEnabled && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                sx={{
                                    maxHeight: {
                                        xs: `calc(${responsiveCardHeights.xs} + ${statusBoxRef.current?.clientHeight}px)`,
                                        sm: `calc(${responsiveCardHeights.sm} + ${statusBoxRef.current?.clientHeight}px)`,
                                        lg: `calc(${responsiveCardHeights.lg} + ${statusBoxRef.current?.clientHeight}px)`,
                                    },
                                    backgroundImage: `linear-gradient(to right, #FFFFFF, ${orange[50]})`,
                                }}
                                flex={1}
                                minWidth={0}
                                overflow="auto"
                                pl={2}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    flex="1 1 auto"
                                    overflow="auto"
                                >
                                    <WorkSpecificationSummaryByIssue
                                        issueId={issueId}
                                        stateContext={stateContext}
                                    />
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    pr={1}
                                    pt={1}
                                >
                                    <Button
                                        onClick={() => {
                                            setIsEditMode(ModelType.ISSUE, issueId, true, stateContext);
                                            setChildrenExpandState(ChildrenExpandState.EXPAND_CHILDREN);
                                        }}
                                        sx={{
                                            color: "text.primary"
                                        }}
                                        variant="outlined"
                                        startIcon={<EditIcon primary="primary" />}
                                    >
                                        Add or Edit
                                    </Button>
                                </Box>
                            </Box>
                        )}
                        <Box
                            sx={{
                                transition: theme => isEditModeEnabled ? theme.transitions.create("all") : undefined,
                                maxWidth: {
                                    sm: isEditModeEnabled ? undefined : "60%"
                                }
                            }}
                            flex="0 1 auto"
                            minWidth={0}
                            flexGrow={isEditModeEnabled ? 1 : undefined}
                        >
                            <Box
                                sx={{
                                    height: responsiveCardHeights,
                                    aspectRatio: "16/9",
                                }}
                                width="100%"
                            >
                                <AttachmentImageCarousel
                                    // Key required to force re-render when slidesPerView changed to keep images ordering
                                    key={slidesPerView}
                                    attachmentParent={{ id: issueId, type: PermissionResourceType.ISSUE }}
                                    reverseButtonOrder={true}
                                    thumbnailImageCarouselConfiguration={{
                                        swiperProps: {
                                            slidesPerView: slidesPerView,
                                            spaceBetween: slidesPerView > 1 ? 4 : undefined,
                                            loop: slidesPerView === 1
                                        }
                                    }}
                                    fullScreenImageCarouselConfiguration={{
                                        swiperProps: {
                                            loop: slidesPerView === 1
                                        }
                                    }}
                                />
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                                flexWrap="wrap"
                                gap={1}
                                ref={statusBoxRef}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%"
                                    sx={{
                                        backgroundColor: isEditModeEnabled ? darkBlue[200] : orange[100],
                                    }}
                                    px={2}
                                    py={1}
                                >
                                    {issueFeatureConfiguration.displayStatusSelector &&
                                        <IssueStatusSelectMenu
                                            issueId={issueId}
                                            size="small"
                                        />
                                    }
                                    <Box display="flex" alignItems="center" gap={1}>
                                        {isEditModeEnabled && (
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="caption">
                                                        Units:&nbsp;&nbsp;
                                                    </Typography>
                                                }
                                                control={
                                                    <MeasurementUnitField
                                                        measurementUnit={parentDimension?.measurement?.measurementUnit}
                                                        size="small"
                                                        onSaveMeasurementUnit={(measurementUnit) => updateChildMeasurementUnit(issueId, measurementUnit)}
                                                    />
                                                }
                                                labelPlacement="start"
                                                sx={{ pr: 1 }}
                                            />
                                        )}
                                        {
                                            shouldDisplayPrices &&
                                            <Box display="flex" alignItems="center">
                                                <Typography component="span">
                                                    $&nbsp;
                                                </Typography>
                                                <Typography variant="h6" component="span" fontWeight="400">
                                                    {issuePriceTotal == null ? "N/A" : `${NumberStringConverter.numberToString(issuePriceTotal, 0, true)}`}
                                                </Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                                {isIssueRecentlyCreated && defaultRecordToPropertyAssociationMode === RecordToPropertyAssociationMode.DO_NOT_ASSOCIATE &&
                                    <PermissionRestrictedControl
                                        requiredPermission={PermissionType.CREATE_CHILDREN}
                                        resourceId={propertyId!}
                                    >
                                        {(isDisabled) => (
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                onClick={() => associateIssueWithProperty(issue!, propertyId!, stateContext)}
                                                disabled={isDisabled}
                                            >
                                                Add Issue To Property
                                            </Button>
                                        )}
                                    </PermissionRestrictedControl>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Collapse
                    in={isEditModeEnabled}
                    onEntered={() => {
                        scrollResourceIntoView(issueElementRef.current, "top");
                    }}
                    timeout={0}
                    unmountOnExit
                    mountOnEnter
                >
                    <Box display="flex" flexDirection="column" gap={2}>
                        <WorkSpecificationByIssue
                            issueId={issueId}
                            stateContext={stateContext}
                        />
                        <Box display="flex" justifyContent="space-between" p={1}>
                            <SolutionAuthoringEnabledPermissionRestrictedControl
                                requiredPermission={PermissionType.CREATE_CHILDREN}
                                resourceId={issueId}
                                context={stateContext}
                            >
                                {(isDisabled) => (
                                    <Button
                                        variant="outlined"
                                        onClick={addWorkSpecificationAndExpandAccordion}
                                        disabled={isDisabled}
                                        endIcon={<AddWorktypeIcon accent={isDisabled ? "disabled" : "primary"} />}
                                    >
                                        Add Worktype
                                    </Button>
                                )}
                            </SolutionAuthoringEnabledPermissionRestrictedControl>
                            {isEditModeEnabled && (
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setChildrenExpandState(ChildrenExpandState.COLLAPSE_CHILDREN);
                                        elementFocusManager.resetIssueElement();
                                        if (issue?.locationId) {
                                            setIsEditMode(ModelType.LOCATION, issue?.locationId, false, stateContext);
                                            return;
                                        }
                                        setIsEditMode(ModelType.ISSUE, issueId, false, stateContext);
                                    }}
                                    startIcon={<SaveCloseIcon primary="secondary" accent="secondary" />}
                                >
                                    Save and Close
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Collapse>
                <Box id={endOfWsSectionId} />
            </Box>
        </ThemeProvider>
    );
});