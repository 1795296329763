import {
    getUpdateIssueCallback,
    issueByIssueIdentifierSelectorFamily
} from "../../../../lib/design/document/state/DocumentState";

import { ContextAwareIdentifier } from "../../../../lib/design/document/ContextAwareIdentifier";
import { Issue } from "../../../../models";
import { ModelType } from "../../../../lib/design/document/ModelType";
import { StateContext } from "../../../../lib/design/document/state/StateContext";
import { idInFocusByModelTypeAtomFamily } from "../../../../lib/ui/InFocusRecoilStates";
import { isAiResourceGeneratingAtomFamily } from "../state/AISummarizationStates";
import { useAISummarizer } from "../useAISummarizer";
import { useRecoilCallback } from "recoil";
import { useSnackbar } from "notistack";

export const useAppendToIssueNoteQuickAction = () => {
    const snackbar = useSnackbar();
    const updateIssue = getUpdateIssueCallback();
    const { generateTranscriptSummaryAddition } = useAISummarizer();

    const appendToIssueNoteInFocus = useRecoilCallback(({ snapshot, set }) => async () => {
        const issueIdInFocus = await snapshot.getPromise(idInFocusByModelTypeAtomFamily(ModelType.ISSUE));
        if (!issueIdInFocus) {
            snackbar.enqueueSnackbar('No issue in focus', { variant: 'error' });
            return;
        }
        set(isAiResourceGeneratingAtomFamily(issueIdInFocus), true);
        const identifier = new ContextAwareIdentifier(issueIdInFocus, StateContext.INSPECTION, ModelType.ISSUE);
        const issue = await snapshot.getPromise(issueByIssueIdentifierSelectorFamily(identifier));
        const newDescription = await generateTranscriptSummaryAddition(issue?.note || '');
        const updatedIssue = Issue.copyOf(issue!, (draft) => {
            draft.note = newDescription;
        });
        updateIssue(updatedIssue, StateContext.INSPECTION);
        set(isAiResourceGeneratingAtomFamily(issueIdInFocus), false);
        snackbar.enqueueSnackbar('Issue Note Updated', { variant: 'success' });
    }, [snackbar]);

    return { appendToIssueNoteInFocus };
}