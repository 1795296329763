import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const ValidateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M24.002 18a5.998 5.998 0 0 1-11.82 1.45c.68-.14 1.39-.39 2.08-.73l2.06 2.05a.651.651 0 0 0 .92 0l4.63-4.63a.64.64 0 0 0 0-.92.658.658 0 0 0-.92 0l-4.17 4.18-1.35-1.35a9.78 9.78 0 0 0 1.49-1.2c1.06-1.06 1.97-2.56 2.42-4 .07-.22.13-.44.17-.65 2.58.67 4.49 3.01 4.49 5.8z"
      className="accent"
    />
    <path
      fill="currentColor"
      d="m18.982 9.13-2.91-2.91-.02.02c-.05-.07-.1-.14-.16-.2-1.16-1.16-3.4-.94-5.4.01-1.08-1.45-2.16-2.89-3.24-4.34A4.286 4.286 0 0 0 3.802 0c-1.02 0-2.02.36-2.73 1.07-1.58 1.58-1.46 4.59.64 6.18l4.37 3.26c-.91 1.98-1.11 4.16.03 5.31.06.06.14.1.2.16l-.02.02 2.91 2.91c.46.46 1.13.66 1.9.66.34 0 .71-.05 1.08-.12.68-.14 1.39-.39 2.08-.73a10.405 10.405 0 0 0 2.66-1.87c1.06-1.06 1.97-2.56 2.42-4 .07-.22.13-.44.17-.65.3-1.34.12-2.42-.53-3.07zM2.322 6.45a3.285 3.285 0 0 1-1.31-2.42c-.06-.88.22-1.7.77-2.25.5-.5 1.23-.78 2.02-.78 1.05 0 2.02.48 2.65 1.31l2.98 4 2.39 3.19c0 .37-.07 1.09-.65 1.67-.56.56-1.27.64-1.64.64h-.03l-7.18-5.36zm6.82 6.34c.04.01.18.02.39.02.54 0 1.54-.12 2.35-.93 1.12-1.12.93-2.59.91-2.74-.56-.76-1.13-1.52-1.69-2.27.89-.39 1.8-.61 2.56-.61.65 0 1.18.15 1.52.49 1.14 1.14.2 4.57-1.79 6.57-2 1.99-5.44 2.93-6.57 1.79-.72-.72-.61-2.37.08-3.99l2.24 1.67zm9.25-.24c-.41 1.3-1.22 2.64-2.18 3.59-1.99 2-5.29 3.07-6.3 2.06l-1.6-1.6c.01 0 .02.01.03.01 1.89 0 4.25-1.08 5.75-2.59 1.51-1.51 2.6-3.88 2.58-5.78l1.6 1.6c.46.46.5 1.48.12 2.71z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(ValidateIcon);
