import API, {
    GraphQLResult
} from "@aws-amplify/api";
import {
    CreateProposalMutation,
    DeleteProposalMutationVariables,
    GetProposalQuery,
    ListProposalsByDesignDocumentGroupIdAndVersionQuery,
    ListProposalsByDesignDocumentGroupIdAndVersionQueryVariables,
    UpdateProposalMutation
} from "../../../../API";
import {
    Proposal,
    ProposalStatus,
    ResourceType
} from "../../../../models";
import {
    createProposal,
    deleteProposal,
    updateProposal
} from "../../../../graphql/mutations";
import {
    getProposal,
    listProposalsByDesignDocumentGroupIdAndVersion
} from "../../../../graphql/queries";

import ClientLogger from "../../../logging/ClientLogger";
import CreateProposalRecordError from "./error/CreateProposalRecordError";
import DeleteProposalRecordError from "./error/DeleteProposalRecordError";
import GetProposalRecordError from "./error/GetProposalRecordError";
import { IDTokenSupplier } from "../../../auth/IDTokenSupplier";
import InvalidProposalStateTransitionError from "./error/InvalidProposalStateTransitionError";
import ListProposalRecordsError from "./error/ListProposalRecordsError";
import ProposalRecordDAO from "./ProposalRecordDAO";
import SerialNumberGenerator from "../../../serialNumber/SerialNumberGenerator";
import UpdateProposalRecordError from "./error/UpdateProposalRecordError";
import { graphqlOperation } from "aws-amplify";

export default class GraphQLProposalRecordDAO implements ProposalRecordDAO {
    private static readonly GET_BY_ID_ATTEMPT_METRIC_NAME = "GraphQLProposalRecordDAO.GetById.Attempt";
    private static readonly GET_BY_ID_FAILURE_METRIC_NAME = "GraphQLProposalRecordDAO.GetById.Failure";
    private static readonly CREATE_ATTEMPT_METRIC_NAME = "GraphQLProposalRecordDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "GraphQLProposalRecordDAO.Create.Failure";
    private static readonly UPDATE_STATUS_ATTEMPT_METRIC_NAME = "GraphQLProposalRecordDAO.UpdateStatus.Attempt";
    private static readonly UPDATE_STATUS_FAILURE_METRIC_NAME = "GraphQLProposalRecordDAO.UpdateStatus.Failure";
    private static readonly LIST_BY_SOLUTION_ID_AND_MINOR_VERSION_ATTEMPT_METRIC_NAME = "GraphQLProposalRecordDAO.ListBySolutionIdAndMinorVersion.Attempt";
    private static readonly LIST_BY_SOLUTION_ID_AND_MINOR_VERSION_FAILURE_METRIC_NAME = "GraphQLProposalRecordDAO.ListBySolutionIdAndMinorVersion.Failure";
    private static readonly DELETE_ATTEMPT_METRIC_NAME = "GraphQLProposalRecordDAO.Delete.Attempt";
    private static readonly DELETE_FAILURE_METRIC_NAME = "GraphQLProposalRecordDAO.Delete.Failure";

    private readonly serialNumberGenerator: SerialNumberGenerator;
    private readonly api: typeof API;
    private readonly gqlOperation: typeof graphqlOperation;
    private readonly idTokenSupplier: IDTokenSupplier;
    private readonly logger: ClientLogger;

    constructor(
        serialNumberGenerator: SerialNumberGenerator,
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        idTokenSupplier: IDTokenSupplier,
        logger: ClientLogger
    ) {
        this.serialNumberGenerator = serialNumberGenerator;
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.idTokenSupplier = idTokenSupplier;
        this.logger = logger;
    }

    public async create(
        proposal: Proposal
    ): Promise<Proposal> {
        try {
            this.logger.info(
                `Attempting to create proposal with ID=${proposal.id}`,
                undefined,
                [GraphQLProposalRecordDAO.CREATE_ATTEMPT_METRIC_NAME]
            );
            const serialNumber: number = await this.serialNumberGenerator.generate(ResourceType.PROPOSAL.toString(), ResourceType.PROPOSAL);
            const proposalWithSerialNumber: Proposal = {
                ...proposal,
                proposalNumber: serialNumber
            };
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<CreateProposalMutation> = await this.api.graphql(this.gqlOperation(createProposal, {
                input: proposalWithSerialNumber
            }, authToken)) as GraphQLResult<CreateProposalMutation>;
            return result.data?.createProposal as Proposal;
        } catch (error) {
            this.logger.error(
                `Failed to create proposal with ID=${proposal.id}`,
                error,
                [GraphQLProposalRecordDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreateProposalRecordError(
                `Failed to create proposal with ID=${proposal.id}`,
                { cause: error as Error }
            );
        }
    }

    public async updateStatus(
        id: string,
        newStatus: ProposalStatus
    ): Promise<Proposal> {
        try {
            this.logger.info(
                `Attempting to update proposal record status with ID=${id} to ${newStatus}`,
                undefined,
                [GraphQLProposalRecordDAO.UPDATE_STATUS_ATTEMPT_METRIC_NAME]
            );
            const currentProposal: Proposal = await this.getById(id);

            let statusCondition: object = {};
            if (newStatus === ProposalStatus.DRAFT) {
                statusCondition = {
                    status: {
                        "eq": ProposalStatus.DRAFT
                    }
                };
            }
            if (newStatus === ProposalStatus.SUBMITTED) {
                statusCondition = {
                    or: [
                        { status: { "eq": ProposalStatus.DRAFT } },
                        { status: { "eq": ProposalStatus.SUBMITTED } },
                    ]
                };
            }
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<UpdateProposalMutation> = await this.api.graphql(this.gqlOperation(updateProposal, {
                input: {
                    id: id,
                    status: newStatus,
                    _version: (currentProposal as any)._version
                },
                condition: statusCondition
            }, authToken)) as GraphQLResult<UpdateProposalMutation>;
            return result.data?.updateProposal as Proposal;
        } catch (error) {
            this.logger.error(
                `Failed to update proposal record status with ID=${id} to ${newStatus}`,
                error,
                [GraphQLProposalRecordDAO.UPDATE_STATUS_FAILURE_METRIC_NAME]
            );
            if ((error as any)?.errors && (error as any)?.errors[0].errorType === "ConditionalCheckFailedException") {
                throw new InvalidProposalStateTransitionError(
                    `Invalid proposal status state transition with ID=${id}`
                );
            }
            throw new UpdateProposalRecordError(
                `Unexpected error occurred while updating proposal record status with ID=${id}`,
                { cause: error as Error }
            );
        }
    }

    public async getById(
        id: string
    ): Promise<Proposal> {
        try {
            this.logger.info(
                `Attempting to retrieve proposal record with id=${id}`,
                undefined,
                [GraphQLProposalRecordDAO.GET_BY_ID_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<GetProposalQuery> = await this.api.graphql(this.gqlOperation(getProposal, {
                id: id
            }, authToken)) as GraphQLResult<GetProposalQuery>;
            const proposal: Proposal = result.data?.getProposal as Proposal;
            if (!proposal) {
                throw new Error(`Unable to find proposal record with id=${id}`);
            }
            return proposal;
        } catch (error) {
            this.logger.error(
                `Failed to retrieve proposal record with id=${id}`,
                error,
                [GraphQLProposalRecordDAO.GET_BY_ID_FAILURE_METRIC_NAME]
            );
            throw new GetProposalRecordError(
                `Failed to retrieve proposal record with id=${id}`,
                { cause: error as Error }
            );
        }
    }

    public async listBySolutionIdAndMinorVersion(
        solutionId: string,
        solutionMinorVersion?: number
    ): Promise<Array<Proposal>> {
        try {
            this.logger.info(
                `Attempting to retrieve proposal records by solutionId=${solutionId} and minorVersion=${solutionMinorVersion}`,
                undefined,
                [GraphQLProposalRecordDAO.LIST_BY_SOLUTION_ID_AND_MINOR_VERSION_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: ListProposalsByDesignDocumentGroupIdAndVersionQueryVariables = solutionMinorVersion != undefined ? {
                designDocumentGroupId: solutionId,
                designDocumentVersion: {
                    "eq": solutionMinorVersion
                }
            } : {
                designDocumentGroupId: solutionId
            };
            const result: GraphQLResult<ListProposalsByDesignDocumentGroupIdAndVersionQuery> = await this.api.graphql(this.gqlOperation(
                listProposalsByDesignDocumentGroupIdAndVersion,
                variables,
                authToken
            )) as GraphQLResult<ListProposalsByDesignDocumentGroupIdAndVersionQuery>;
            return result.data?.listProposalsByDesignDocumentGroupIdAndVersion?.items as Array<Proposal>;
        } catch (error) {
            this.logger.error(
                `Failed to retrieve proposal records by solutionId=${solutionId} and minorVersion=${solutionMinorVersion}`,
                error,
                [GraphQLProposalRecordDAO.LIST_BY_SOLUTION_ID_AND_MINOR_VERSION_FAILURE_METRIC_NAME]
            );
            throw new ListProposalRecordsError(
                `Failed to retrieve proposal records by solutionId=${solutionId} and minorVersion=${solutionMinorVersion}`,
                { cause: error as Error }
            );
        }
    }

    public async deleteByIdAndVersion(
        id: string,
        version: number
    ): Promise<void> {
        try {
            this.logger.info(
                `Attempting to delete proposal record with id=${id} and version=${version}`,
                undefined,
                [GraphQLProposalRecordDAO.DELETE_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: DeleteProposalMutationVariables = {
                input: {
                    id: id,
                    _version: version
                },
                condition: {
                    status: {
                        eq: ProposalStatus.DRAFT
                    }
                }
            };
            await this.api.graphql(this.gqlOperation(
                deleteProposal,
                variables,
                authToken
            ));
        } catch (error) {
            this.logger.error(
                `Failed to delete proposal record with id=${id} and version=${version}`,
                error,
                [GraphQLProposalRecordDAO.DELETE_FAILURE_METRIC_NAME]
            );
            throw new DeleteProposalRecordError(
                `Failed to delete proposal record with id=${id} and version=${version}`,
                { cause: error as Error }
            );
        }
    }
}
