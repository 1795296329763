import ClientLogger from "../../../logging/ClientLogger";
import CreateWorkTypeGroupError from "../errors/CreateWorkTypeGroupError";
import { DataStoreClass } from "@aws-amplify/datastore";
import GetWorkTypeGroupByIdError from "../errors/GetWorkTypeGroupByIdError";
import ListAllWorkTypeGroupsError from "../errors/ListAllWorkTypeGroupsError";
import { WorkTypeGroup } from "../../../../models";
import WorkTypeGroupDAO from "../WorkTypeGroupDAO";
import WorkTypeGroupMetricNames from "../WorkTypeGroupMetricNames";
import _ from "lodash";

export default class DataStoreWorkTypeGroupDAO implements WorkTypeGroupDAO {
    private logger: ClientLogger;
    private dataStore: DataStoreClass;

    constructor(
        logger: ClientLogger,
        dataStore: DataStoreClass,
    ) {
        this.logger = logger;
        this.dataStore = dataStore;
    }

    public async create(
        isDiscoverable: boolean
    ): Promise<WorkTypeGroup> {
        try {
            this.logger.info(
                `Creating a new workTypeGroup`,
                undefined,
                [WorkTypeGroupMetricNames.CREATE_ATTEMPT]
            );
            return await this.dataStore.save(
                new WorkTypeGroup({
                    latestWorkTypeVersion: 0,
                    isDiscoverable: isDiscoverable
                })
            );
        } catch (error) {
            const errorMessage: string = `Unable to create a new workTypeGroup`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeGroupMetricNames.CREATE_FAILURE]
            );
            throw new CreateWorkTypeGroupError(errorMessage, error as Error);
        }
    }

    public async getById(
        id: string
    ): Promise<WorkTypeGroup> {
        try {
            this.logger.info(
                `Getting workTypeGroup: ${id}`,
                undefined,
                [WorkTypeGroupMetricNames.GET_BY_ID_ATTEMPT]
            );
            const result: WorkTypeGroup | undefined = await this.dataStore.query(WorkTypeGroup, id);
            if (!result) {
                throw new Error('WorkTypeGroup not found');
            }
            return result;
        } catch (error) {
            const errorMessage: string = `Unable to get workTypeGroup: ${id}`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeGroupMetricNames.GET_BY_ID_FAILURE]
            );
            throw new GetWorkTypeGroupByIdError(errorMessage, error as Error);
        }
    }

    public async listAll(): Promise<Array<WorkTypeGroup>> {
        try {
            this.logger.info(
                "Listing all workTypeGroups",
                undefined,
                [WorkTypeGroupMetricNames.LIST_ALL_ATTEMPT]
            );
            return await this.dataStore.query(WorkTypeGroup);
        } catch (error) {
            const errorMessage: string = "Unable to list all workTypeGroups";
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeGroupMetricNames.LIST_ALL_FAILURE]
            );
            throw new ListAllWorkTypeGroupsError(errorMessage, error as Error);
        }
    }

    public async listDiscoverable(): Promise<Array<WorkTypeGroup>> {
        try {
            this.logger.info(
                "Listing discoverable workTypeGroups",
                undefined,
                [WorkTypeGroupMetricNames.LIST_DISCOVERABLE_ATTEMPT]
            );
            return await this.dataStore.query(
                WorkTypeGroup,
                workTypeGroup => workTypeGroup.isDiscoverable("eq", true)
            );
        } catch (error) {
            const errorMessage: string = "Unable to list discoverable workTypeGroups";
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeGroupMetricNames.LIST_DISCOVERABLE_FAILURE]
            );
            throw new ListAllWorkTypeGroupsError(errorMessage, error as Error);
        }
    }

    public async update(
        id: string,
        workTypeGroup: WorkTypeGroup
    ): Promise<WorkTypeGroup> {
        throw new Error("Method not implemented.");
    }
}
