import {
    Theme,
    createTheme
} from "@mui/material/styles";

import { orange } from "./colors";

export const issueTheme = (theme: Theme) => createTheme({
    ...theme,
    palette: {
        ...theme.palette,
        text: {
            primary: "#000000",
            secondary: "#ABABAB"
        },
        primary: {
            main: orange[800],
            light: orange[200],
            dark: orange[900],
            contrastText: "#FFFFFF"
        },
        secondary: {
            main: "#FFFFFF"
        },
        error: {
            main: "#F44336"
        },
        warning: {
            main: "#FFB74D"
        },
        info: {
            main: "#64B5F6"
        },
        success: {
            main: "#4CAF50"
        },
        background: {
            default: "#F9F9F9"
        }
    }
});
