import {
    ChangeEvent,
    FormEvent,
    useMemo,
    useState
} from 'react';
import {
    Flex,
    FormField,
    Input,
    MeetingStatus,
    PrimaryButton,
    useMeetingStatus
} from 'amazon-chime-sdk-component-library-react';

import { AttendeeRole } from '../../models';
import { AudioVideoSettings } from './test/AudioVideoSettings';
import { Box } from '@mui/material';
import { JoinMeetingSettings } from './JoinMeetingSettings';
import { isJoiningMeetingAtom } from './state/ChimeStates';
import teneraLogo from "../../assets/images/tenera_building_design_services.png";
import { useHistory } from 'react-router-dom';
import { useJoinMeeting } from './hooks/useJoinMeeting';
import { useRecoilState } from 'recoil';
import { useSearchParams } from "../../lib/util/hooks/useSearchParams";

export const RemoteInspectorMeetingForm = () => {
    const { params } = useSearchParams();
    const { joinMeeting } = useJoinMeeting();
    const [attendeeName, setAttendeeName] = useState(params.get('attendeeName') ?? '');
    const history = useHistory();
    const [isJoiningMeeting, setIsJoiningMeeting] = useRecoilState(isJoiningMeetingAtom);
    const meetingStatus = useMeetingStatus();
    const isMeetingActive = useMemo(() => {
        return meetingStatus === MeetingStatus.Succeeded || meetingStatus === MeetingStatus.Reconnecting;
    }, [meetingStatus]);

    const clickedJoinMeeting = async (event: FormEvent) => {
        try {
            setIsJoiningMeeting(true);
            event.preventDefault();
            if ("speechSynthesis" in window) {
                // Triggering a speech synthesis utterance to get around IOS browser's autoplay policy
                speechSynthesis.speak(new SpeechSynthesisUtterance(""));
            }
            const meetingId = params.get('meetingId');
            if (!meetingId) {
                return;
            }
            const url = new URL(window.location.href);
            url.searchParams.set('attendeeName', attendeeName);
            history.replace({ pathname: url.pathname, search: url.search });
            await joinMeeting(meetingId, attendeeName, AttendeeRole.INSPECTOR);
        } catch (error) {
            console.error(error);
        } finally {
            setIsJoiningMeeting(false);
        }
    };

    return (
        <Box flexDirection={"column"}>
            <Box component="p" style={{ textAlign: "center" }}>
                <img src={teneraLogo} />
            </Box>
            <Box p={1} display="flex" flexDirection="column" gap={1}>
                <FormField
                    field={Input}
                    label=""
                    value={attendeeName}
                    fieldProps={{
                        placeholder: 'Enter your Attendee Name',
                        disabled: isMeetingActive || isJoiningMeeting
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setAttendeeName(e.target.value);
                    }}
                />
                <JoinMeetingSettings />
                <AudioVideoSettings />
                <PrimaryButton
                    disabled={!params.get('meetingId')}
                    label={"Join Meeting"}
                    onClick={clickedJoinMeeting}
                />
            </Box>
        </Box>
    );
};
