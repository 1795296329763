import {
    Dimension,
    Issue,
    Location,
    Property,
    Proposal,
    Solution,
    WorkSpecification
} from "../../../API";
import {
    Dimension as DimensionModel,
    Issue as IssueModel,
    Location as LocationModel,
    Property as PropertyModel,
    Proposal as ProposalModel,
    Solution as SolutionModel,
    WorkSpecification as WorkSpecificationModel
} from "../../../models";

export type ResolvableType = Property | Location | Issue | Dimension | WorkSpecification | Solution | Proposal;
export const ResolvableModels = [
    PropertyModel,
    LocationModel,
    IssueModel,
    DimensionModel,
    WorkSpecificationModel,
    SolutionModel,
    ProposalModel
];