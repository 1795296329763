/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://e554iz72j5cyflgl2pmivjviu4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AWS_LAMBDA",
    "aws_appsync_apiKey": "da2-7fswkiyusfd7tpvfqxklqdwvxe",
    "aws_cognito_identity_pool_id": "us-east-2:f4ff0a6b-f73e-4d2b-bc5b-6afe5eca2a46",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_cXyNnU4an",
    "aws_user_pools_web_client_id": "3bojkhi3fve8c74atd9433f4h8",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Bill-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "BillLineItem-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "Invoice-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "InvoiceLineItem-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "ResourceEvent-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "SolutionEvent-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "dynamo2b82b65a-prod",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "attachments102453-prod",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cloudwatch_rum_app_id": "82afd9d8-1d4b-4720-a671-ee67bca86da9"
};


export default awsmobile;
