import {
    Attachment,
    Dimension,
    Issue,
    Location,
    WorkSpecification
} from "../../../models";

import SolutionContent from "./SolutionContent";

export default class SolutionContentBuilder {
    private _instance: SolutionContent;

    constructor(instance?: SolutionContent) {
        if (instance) {
            this._instance = { ...instance };
            return;
        }
        this._instance = {};
    }

    public propertyId(value: string | undefined): SolutionContentBuilder {
        this._instance = {
            ...this._instance,
            propertyId: value
        };
        return this;
    }

    public solutionNotes(value: string): SolutionContentBuilder {
        this._instance = {
            ...this._instance,
            solutionNotes: value
        };
        return this;
    }

    public locationIdToLocationMap(value: Map<string, Location>): SolutionContentBuilder {
        this._instance = {
            ...this._instance,
            locationIdToLocationMap: value
        };
        return this;
    }

    public locationIdToIssueIdsMap(value: Map<string, Array<string>>): SolutionContentBuilder {
        this._instance = {
            ...this._instance,
            locationIdToIssueIdsMap: value
        };
        return this;
    }

    public issueIdToIssueMap(value: Map<string, Issue>): SolutionContentBuilder {
        this._instance = {
            ...this._instance,
            issueIdToIssueMap: value
        };
        return this;
    }

    public issueIdToDimensionIdsMap(value: Map<string, Array<string>>): SolutionContentBuilder {
        this._instance = {
            ...this._instance,
            issueIdToDimensionIdsMap: value
        };
        return this;
    }

    public dimensionIdToDimensionMap(value: Map<string, Dimension>): SolutionContentBuilder {
        this._instance = {
            ...this._instance,
            dimensionIdToDimensionMap: value
        };
        return this;
    }

    public dimensionIdToWorkSpecificationIdsMap(value: Map<string, Array<string>>): SolutionContentBuilder {
        this._instance = {
            ...this._instance,
            dimensionIdToWorkSpecificationIdsMap: value
        };
        return this;
    }

    public workSpecificationIdToWorkSpecificationMap(value: Map<string, WorkSpecification>): SolutionContentBuilder {
        this._instance = {
            ...this._instance,
            workSpecificationIdToWorkSpecificationMap: value
        };
        return this;
    }

    public numberOfLocationsCreated(value: number): SolutionContentBuilder {
        this._instance = {
            ...this._instance,
            numberOfLocationsCreated: value
        };
        return this;
    }

    public numberOfIssuesCreated(value: number): SolutionContentBuilder {
        this._instance = {
            ...this._instance,
            numberOfIssuesCreated: value
        };
        return this;
    }

    public resourceToAttachmentsMap(value: Map<string, Array<Attachment>>): SolutionContentBuilder {
        this._instance = {
            ...this._instance,
            resourceToAttachmentsMap: value
        };
        return this;
    }

    public build(): SolutionContent {
        return this._instance;
    }
}
