import {
    Box,
    CircularProgress,
    IconButton,
    TextField,
    useMediaQuery
} from "@mui/material";
import {
    MeetingStatus,
    useMeetingStatus
} from "amazon-chime-sdk-component-library-react";
import {
    getUpdateIssueCallback,
    isEditModeByDesignElementIdentifierSelectorFamily,
    issueByIssueIdentifierSelectorFamily,
    issueFeatureConfigurationByContextAtomFamily
} from "../../lib/design/document/state/DocumentState";
import {
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    useRecoilValue,
    useResetRecoilState
} from "recoil";

import { ChildrenExpandState } from "../../lib/design/document/state/enum/ChildrenExpandState";
import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { ExpandableDisplayText } from "../misc/ExpandableDisplayText";
import { Issue } from "../../models";
import { IssueFeatureConfiguration } from "../../lib/design/document/state/IssueFeatureConfiguration";
import { ModelType } from "../../lib/design/document/ModelType";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { StateContext } from "../../lib/design/document/state/StateContext";
import ViewIssueMenu from "./ViewIssueMenu";
import { childrenExpandStateByDesignElementIdentifierAtomFamily } from "../../lib/design/document/state/ComponentExpandState";
import globalStyle from "../../assets/style/globalStyle";
import { isAiResourceGeneratingAtomFamily } from "../../components/chime/hooks/state/AISummarizationStates";
import theme from "../../assets/style/theme";
import { useAppendToIssueNoteQuickAction } from "../../components/chime/hooks/actions/useAppendToIssueNoteQuickAction";

type IssueCardHeaderProps = {
    readonly issueId: string;
    readonly stateContext: StateContext;
};

const IssueCardHeader = (props: IssueCardHeaderProps) => {
    const { issueId, stateContext } = props;
    const issueIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(issueId, stateContext, ModelType.ISSUE);
    }, [issueId, stateContext]);
    const issue = useRecoilValue<Issue | null>(issueByIssueIdentifierSelectorFamily(issueIdentifier));
    const issueFeatureConfiguration = useRecoilValue<IssueFeatureConfiguration>(
        issueFeatureConfigurationByContextAtomFamily(stateContext)
    );
    const childrenExpandState = useRecoilValue<ChildrenExpandState>(childrenExpandStateByDesignElementIdentifierAtomFamily(issueIdentifier));
    const isExpanded = childrenExpandState === ChildrenExpandState.EXPAND_CHILDREN;
    const issueNameRef = useRef<HTMLInputElement>(null);

    const [note, setNote] = useState(issue?.note ?? "");
    const { appendToIssueNoteInFocus } = useAppendToIssueNoteQuickAction();

    const globalClasses = globalStyle();

    const meetingStatus = useMeetingStatus();
    const isMeetingStarted = meetingStatus === MeetingStatus.Succeeded || meetingStatus === MeetingStatus.Reconnecting;

    /* issue form states */
    const isEditMode = useRecoilValue<boolean>(isEditModeByDesignElementIdentifierSelectorFamily(issueIdentifier));
    const resetIsEditMode = useResetRecoilState(isEditModeByDesignElementIdentifierSelectorFamily(issueIdentifier));

    const isScreenSizeSm = useMediaQuery(theme.breakpoints.down("md"));
    const updateIssue = getUpdateIssueCallback();
    const isAiGeneratingIssue = useRecoilValue<boolean>(isAiResourceGeneratingAtomFamily(issueId));

    useEffect(() => {
        setNote(issue?.note ?? "");
    }, [issue]);

    useEffect(() => {
        if (isEditMode) {
            if (issueNameRef.current) {
                issueNameRef.current.focus();
            }
        }
    }, [isEditMode]);

    useEffect(() => () => {
        const resetRecoilState = () => {
            resetIsEditMode();
        };
        resetRecoilState();
    }, []);

    useEffect(() => {
        if (issueNameRef.current) {
            issueNameRef.current.value = issue?.name ?? "";
        }
    }, [issue?.name]);

    return (
        <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} px={1}>
                <Box display="flex" alignItems="center" gap={1} flex={1} minWidth="0px"> {/* minWidth="0px" is needed to prevent overflow */}
                    {isEditMode && isExpanded ? (
                        <TextField
                            fullWidth
                            placeholder="Issue Name"
                            defaultValue={issue?.name ?? ""}
                            onBlur={(e) => {
                                const newIssueName = e.target.value;
                                if (newIssueName === issue?.name) {
                                    return;
                                }
                                const updatedIssue: Issue = {
                                    ...issue!,
                                    name: e.target.value
                                };
                                updateIssue(updatedIssue, stateContext);
                            }}
                            variant="outlined"
                            size="small"
                            inputRef={issueNameRef}
                            onFocus={(e) => e.target.select()}
                            tabIndex={1}
                            disabled={isAiGeneratingIssue}
                        />
                    ) : (
                        <Box display="flex" flexDirection="column" minWidth="0px">
                            <ExpandableDisplayText
                                description={issue?.name ?? ""}
                                linesToDisplayWhenCollapsed={1}
                                typographyProps={{
                                    variant: "h6"
                                }}
                            />
                            {issue?.note && (
                                <ExpandableDisplayText
                                    description={`Note: ${issue.note}`}
                                    linesToDisplayWhenCollapsed={1}
                                    typographyProps={{
                                        variant: "caption",
                                        color: "text.secondary"
                                    }}
                                />
                            )}
                        </Box>
                    )}
                </Box>
                <Box display="flex" flex={0} alignSelf="flex-start">
                    {issueFeatureConfiguration.displayContextMenu && (
                        <ViewIssueMenu
                            stateContext={stateContext}
                            issueId={issueId}
                            size={isScreenSizeSm ? "small" : "medium"}
                        />
                    )}
                </Box>
            </Box>
            {isEditMode && isExpanded && (
                <>
                    <Box width="100%" px={1} display="flex" gap={1} flex={0} alignSelf="flex-start">
                        <TextField
                            placeholder="Note"
                            fullWidth
                            defaultValue={issue?.note ?? ""}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            onBlur={(e) => {
                                const newNote = e.target.value;
                                if (newNote === issue?.note) {
                                    return;
                                }
                                const updatedIssue: Issue = {
                                    ...issue!,
                                    note: e.target.value
                                };
                                updateIssue(updatedIssue, stateContext);
                            }}
                            variant="outlined"
                            size="small"
                            inputProps={{
                                sx: {
                                    height: "40px",
                                    py: 0,
                                }
                            }}
                            multiline
                            disabled={isAiGeneratingIssue}
                            maxRows={10}
                            tabIndex={2}
                        />
                        {isMeetingStarted &&
                            <Box display="flex" flex={0} alignSelf="flex-start">
                                {issueFeatureConfiguration.displayContextMenu && (
                                    <IconButton
                                        className={`${globalClasses.outlinedMenuButton} ${globalClasses.mediumSquareButton}`}
                                        title="Add to description"
                                        onClick={() => {
                                            appendToIssueNoteInFocus();
                                        }}
                                    >
                                        <PlaylistAddIcon />
                                    </IconButton>
                                )}
                            </Box>
                        }
                    </Box>
                </>
            )}
        </Box>
    );
};

export default IssueCardHeader;
