import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m18.854 9.104-7.208 7.207-7.207-7.207 1.415-1.415 5.792 5.793 5.793-5.793z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(ChevronDownIcon);
