import {
    DataStoreClass,
    ProducerModelPredicate
} from "@aws-amplify/datastore";

import ClientLogger from "../../../logging/ClientLogger";
import { CreateDimensionError } from "../error/CreateDimensionError";
import { DataStore } from "aws-amplify";
import { Dimension } from "../../../../models";
import DimensionDAO from "../DimensionDAO";
import DimensionV2 from "../../DimensionV2";
import DimensionV2Factory from "../../DimensionV2Factory";
import Envelope from "../../../envelope/Envelope";
import EnvelopeFactory from "../../../envelope/EnvelopeFactory";
import { GetDimensionError } from "../error/GetDimensionError";
import { MeasurementUnit } from "../../../../models";
import { UpdateDimensionError } from "../error/UpdateDimensionError";
import _ from "lodash";

export default class DataStoreDimensionDAO implements DimensionDAO {
    private static readonly GET_ATTEMPT_METRIC_NAME = "DataStoreDimensionDAO.Get.Attempt";
    private static readonly GET_FAILURE_METRIC_NAME = "DataStoreDimensionDAO.Get.Failure";
    private static readonly CREATE_ATTEMPT_METRIC_NAME = "DataStoreDimensionDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "DataStoreDimensionDAO.Create.Failure";
    private static readonly UPDATE_ATTEMPT_METRIC_NAME = "DataStoreDimensionDAO.Update.Attempt";
    private static readonly UPDATE_FAILURE_METRIC_NAME = "DataStoreDimensionDAO.Update.Failure";

    private logger: ClientLogger;
    private readonly dataStore: DataStoreClass;

    constructor(
        logger: ClientLogger,
        dataStore: DataStoreClass,
    ) {
        this.logger = logger;
        this.dataStore = dataStore;
    }

    public async getDimensionById(dimensionId: string): Promise<Dimension> {
        this.logger.info(
            `Getting Dimension record with id=${dimensionId}`,
            {},
            [DataStoreDimensionDAO.GET_ATTEMPT_METRIC_NAME]
        );
        try {
            const result = await DataStore.query(Dimension, dimensionId);
            if (result == null) {
                throw new Error("Dimension Record not found");
            }
            return result;
        } catch (error) {
            this.logger.error(
                `Failed to get dimension with id: ${dimensionId}`,
                error,
                [DataStoreDimensionDAO.GET_FAILURE_METRIC_NAME]
            );
            throw new GetDimensionError(`Failed to get dimension with id: ${dimensionId}`);
        }
    }

    public async create(
        record: Dimension
    ): Promise<Dimension> {
        this.logger.info(
            `Creating Dimension record with id=${record.id}`,
            record,
            [DataStoreDimensionDAO.CREATE_ATTEMPT_METRIC_NAME]
        );
        try {
            const dimensionToSave = new Dimension({
                ...record,
                localLastUpdatedTime: Date.now()
            });
            return DataStore.save(dimensionToSave);
        } catch (error) {
            const errorMessage = `Unable to create Dimension record with id=${record.id}`;
            this.logger.error(
                errorMessage,
                error,
                [DataStoreDimensionDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreateDimensionError(errorMessage, {
                cause: error as Error
            });
        }
    }

    public async createDimensionFromV2Envelope(
        envelope: Envelope,
        propertyId: string,
        parentId: string
    ): Promise<Envelope> {
        const dimensionEnvelopeBody: DimensionV2 = envelope.body;
        const dimensionArguments = {
            propertyId: propertyId,
            parentId: parentId,
            description: dimensionEnvelopeBody.description,
            length: dimensionEnvelopeBody.length,
            lengthUnit: this.getV1UnitFromV2Unit(dimensionEnvelopeBody.measurementUnit),
            width: dimensionEnvelopeBody.width,
            widthUnit: dimensionEnvelopeBody.width ? this.getV1UnitFromV2Unit(dimensionEnvelopeBody.measurementUnit) : null,
        }
        const createdDimension = await DataStore.save(new Dimension(dimensionArguments));
        const dimensionWithUpdatedSchema: DimensionV2 = DimensionV2Factory.fromV1Dimension(createdDimension);
        const result = EnvelopeFactory.createFromExistingRecord(
            "Dimension",
            2,
            dimensionWithUpdatedSchema,
            createdDimension.id
        );
        return result;
    }

    public async getDimensionsByParentId(parentId: string): Promise<Dimension[]> {
        return await DataStore.query(Dimension, dimension => dimension.parentId("eq", parentId));
    }

    public async getDimensionsByParentIdWithLimit(
        // This type needs to be abstracted - see the TODO comment on the interface.
        predicate: ProducerModelPredicate<Dimension>,
        limit: number
    ): Promise<Dimension[]> {
        return await this.dataStore.query(
            Dimension,
            predicate,
            {
                limit: limit
            }
        );
    }

    public async getDimensionCountByParentId(parentId: string): Promise<number> {
        const dimensions: Array<Dimension> = await this.getDimensionsByParentId(parentId);
        return dimensions.length;
    }

    public async updateDimension(
        dimensionId: string,
        dimension: Dimension
    ): Promise<Dimension> {
        this.logger.info(
            `Attempting to update dimension with id: ${dimensionId}`,
            {},
            [DataStoreDimensionDAO.UPDATE_ATTEMPT_METRIC_NAME]
        );
        try {
            const existingDimension = await this.getDimensionById(dimensionId);
            if (_.isEqual(existingDimension, dimension)) {
                return dimension;
            }
            return await DataStore.save(Dimension.copyOf(existingDimension, (updated) => {
                updated.measurement = dimension.measurement;
                updated.description = dimension.description;
                updated.propertyId = dimension.propertyId;
                updated.localLastUpdatedTime = Date.now();
            }));
        } catch (error) {
            this.logger.error(
                `Failed to update dimension with id: ${dimensionId}`,
                error,
                [DataStoreDimensionDAO.UPDATE_FAILURE_METRIC_NAME]
            );
            throw new UpdateDimensionError(`Failed to update dimension with id: ${dimensionId}`, error as Error);
        }
    }

    public async updateDimensionByEnvelope(
        dimensionId: string,
        dimension: Envelope
    ): Promise<Envelope> {
        const body: DimensionV2 = dimension.body;

        const existingRecord = await this.getDimensionEnvelopeById(dimensionId);
        if (existingRecord === undefined) {
            this.logger.info(
                "Record does not exist in datastore and will not be updated: " + dimensionId,
                {},
                []
            );
            return dimension;
        }
        if (_.isEqual(existingRecord.body, body)) {
            this.logger.info(
                `No changes detected to dimension ${dimensionId}, skipping update`,
                {},
                []
            );
            return dimension;
        }

        const dimensions: Dimension[] = await DataStore.query(Dimension, d => d.id("eq", dimensionId));
        const result: Dimension = await DataStore.save(
            Dimension.copyOf(dimensions[0], (updated) => {
                updated.length = body.length;
                updated.lengthUnit = this.getV1UnitFromV2Unit(body.measurementUnit);
                if (body.width) {
                    updated.width = body.width;
                } else {
                    updated.width = undefined;
                }
                updated.widthUnit = this.getV1UnitFromV2Unit(body.measurementUnit);
                updated.description = body.description;
            })
        );

        const dimensionWithUpdatedSchema: DimensionV2 = DimensionV2Factory.fromV1Dimension(result);
        return EnvelopeFactory.update(
            dimension,
            dimension.header.dataType,
            dimension.header.version,
            dimensionWithUpdatedSchema
        );
    }

    public async deleteDimension(dimensionId: string): Promise<void> {
        try {
            DataStore.delete(Dimension, dimension => dimension.id("eq", dimensionId));
        } catch (error) {
            this.logger.info(
                `dimension ${dimensionId} does not exist in datastore, deletion is a no-op`,
                {},
                []
            );
            console.log("Warning, attempted to delete non-existant dimension");
        }
    }

    public async getDimensionEnvelopeById(dimensionId: string): Promise<Envelope | undefined> {
        const dimensions: Dimension[] = await DataStore.query(Dimension, d => d.id("eq", dimensionId));
        if (dimensions.length === 0) {
            return;
        }
        const dimensionWithUpdatedSchema: DimensionV2 = DimensionV2Factory.fromV1Dimension(dimensions[0]);
        const result = EnvelopeFactory.createFromExistingRecord(
            "Dimension",
            2,
            dimensionWithUpdatedSchema,
            dimensionId
        );

        return result;
    }

    private getV1UnitFromV2Unit(unit: MeasurementUnit | undefined) {
        switch (unit) {
            case MeasurementUnit.CENTIMETER:
                return "centimeters";
            case MeasurementUnit.METER:
                return "meters"
            case MeasurementUnit.INCH:
                return "inches";
            case MeasurementUnit.FOOT:
            default:
                return "feet";
        }
    }
}
