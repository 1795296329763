import {
    Box,
    alpha
} from "@mui/material";
import {
    areVisibleIssuesValidByContextSelectorFamily,
    isValidationEnabledAtom
} from "../../lib/design/document/state/DocumentValidationState";
import {
    useEffect,
    useMemo
} from "react";
import {
    useRecoilState,
    useRecoilValue,
    useResetRecoilState
} from "recoil";

import { DrawerListItemButton } from "./DrawerListItemButton";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { ValidateIcon } from "../icons";
import { stateContextInFocusAtom } from "../../lib/ui/InFocusRecoilStates";
import theme from "../../assets/style/theme";

type ValidationDrawerListItemProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

export const ValidationDrawerListItem = (props: ValidationDrawerListItemProps) => {
    const { isOpen, setIsOpen } = props;
    const context = useRecoilValue<StateContext>(stateContextInFocusAtom);
    const areVisibleIssuesValid = useRecoilValue(areVisibleIssuesValidByContextSelectorFamily(context));
    const [isValidationEnabled, setIsValidationEnabled] = useRecoilState<boolean>(isValidationEnabledAtom);

    const resetIsValidationEnabled = useResetRecoilState(isValidationEnabledAtom);

    const validationColor: string = useMemo(() => {
        if (!isValidationEnabled) {
            return theme.palette.primary.main;
        }
        return areVisibleIssuesValid ? theme.palette.success.main : theme.palette.error.main;
    }, [isValidationEnabled, areVisibleIssuesValid]);

    useEffect(() => {
        return () => {
            resetIsValidationEnabled();
        };
    }, [context]);

    return (
        <Box sx={{ backgroundColor: isValidationEnabled ? alpha(validationColor, 0.2) : undefined }}>
            <DrawerListItemButton
                title="Validate"
                icon={<ValidateIcon accent={isValidationEnabled && !areVisibleIssuesValid ? "error" : "primary"} />}
                onClick={() => {
                    setIsValidationEnabled(prev => !prev)
                    setIsOpen(false);
                }}
                isOpen={isOpen}
            />
        </Box>
    );
};