import AttachmentUploadQueue from "./AttachmentUploadRequestQueueImpl";
import { AttachmentUploadRecoveryHandler } from "../manager/AttachmentUploadRecoveryHandler";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import DataStoreAttachmentRecordDAOFactory from "../../record/datastore/DataStoreAttachmentRecordDAOFactory";
import LocalForageAttachmentContentDAOFactory from "../../content/localforage/LocalForageAttachmentContentDAOFactory";
import { LocalForageAttachmentRecordDAOFactory } from "../../record/localforage/LocalForageAttachmentRecordDAOFactory";
import { LocalForageAttachmentUploadStatusDAOFactory } from "../status/LocalForageAttachmentUploadStatusDAOFactory";

export default class AttachmentUploadQueueFactory {
    private static readonly ATTACHMENT_UPLOAD_QUEUE = new AttachmentUploadQueue(
        LocalForageAttachmentUploadStatusDAOFactory.getInstance(),
        DataStoreAttachmentRecordDAOFactory.getInstance(),
        LocalForageAttachmentRecordDAOFactory.getInstance(),
        LocalForageAttachmentContentDAOFactory.getInstance(),
        new AttachmentUploadRecoveryHandler(
            LocalForageAttachmentRecordDAOFactory.getInstance(),
            DataStoreAttachmentRecordDAOFactory.getInstance(),
            ClientLoggerFactory.getClientLogger("AttachmentUploadRecoveryHandler")
        ),
        ClientLoggerFactory.getClientLogger("AttachmentUploadQueue")
    );

    public static getInstance() {
        return this.ATTACHMENT_UPLOAD_QUEUE
    }
}
