import {
    PermissionEntityType,
    PermissionResourceType
} from "../../../API";

import ClientLogger from "../../logging/ClientLogger";
import { DataStoreClass } from "@aws-amplify/datastore";
import { EntityResourcePermission } from "../../../models";
import { EntityResourcePermissionDAO } from "./EntityResourcePermissionDAO";
import { ListEntityResourcePermissionError } from "../errors/ListEntityResourcePermissionError";

export class DataStoreEntityResourcePermissionDAO implements EntityResourcePermissionDAO {
    private static LIST_BY_ENTITY_AND_RESOURCE_ATTEMPT = "ListEntityResourcePermissionsByEntityAndResourceIdAttempt"
    private static LIST_BY_ENTITY_AND_RESOURCE_FAILURE = "ListEntityResourcePermissionsByEntityAndResourceIdFailure"

    private logger: ClientLogger;
    private readonly dataStore: DataStoreClass;

    constructor(
        logger: ClientLogger,
        dataStore: DataStoreClass,
    ) {
        this.logger = logger;
        this.dataStore = dataStore;
    }

    public async listByEntityAndResource(
        entityType: PermissionEntityType,
        entityId: string,
        resourceType: PermissionResourceType,
        resourceId: string,
        isGranted: boolean
    ): Promise<EntityResourcePermission[]> {
        this.logger.info(
            `Listing EntityResourcePermission Records with entityType: ${entityType}, entityId: ${entityId}, resourceType: ${resourceType}, resourceId: ${resourceId}, isGranted: ${isGranted}`,
            undefined,
            [DataStoreEntityResourcePermissionDAO.LIST_BY_ENTITY_AND_RESOURCE_ATTEMPT]
        );
        try {
            return await this.dataStore.query(
                EntityResourcePermission, 
                erp => erp.entityType('eq', entityType)
                          .entityId('eq', entityId)
                          .resourceType('eq', resourceType)
                          .resourceId('eq', resourceId)
                          .isGranted('eq', isGranted)
                );
        } catch (error) {
            const errorMessage: string = `Unable to list EntityResourcePermission Records with entityType: ${entityType}, entityId: ${entityId}, resourceType: ${resourceType}, resourceId: ${resourceId}, isGranted: ${isGranted}`;
            this.logger.error(
                errorMessage,
                error,
                [DataStoreEntityResourcePermissionDAO.LIST_BY_ENTITY_AND_RESOURCE_FAILURE]
            );
            throw new ListEntityResourcePermissionError(errorMessage, error as Error);
        }
    }
}