import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const ShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18 .5c-2.21 0-4 1.79-4 4 0 .46.1.9.24 1.32L7.32 9.28c.19.27.34.58.45.9l6.91-3.46A3.99 3.99 0 0 0 18 8.5c2.21 0 4-1.79 4-4s-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm2 9c-1.38 0-2.61.71-3.32 1.78l-8.91-4.46c-.11.32-.26.63-.45.9l8.92 4.46c-.14.42-.24.86-.24 1.32 0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M7.77 10.18c-.11-.32-.26-.63-.45-.9A3.953 3.953 0 0 0 4 7.5c-2.21 0-4 1.79-4 4s1.79 4 4 4c1.39 0 2.61-.7 3.32-1.78.19-.27.34-.58.45-.9.15-.41.23-.86.23-1.32 0-.46-.08-.91-.23-1.32zM5.5 12.81c-.36.42-.9.69-1.5.69-1.1 0-2-.9-2-2s.9-2 2-2c.6 0 1.14.27 1.5.69.23.25.38.55.46.89.02.13.04.28.04.42s-.02.29-.04.42c-.08.34-.23.64-.46.89z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(ShareIcon);
