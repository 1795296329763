import { UuidGenerator } from "./UuidGenerator";
import UuidV4Generator from "./UuidV4Generator";

export default class UuidGeneratorFactory {
    private static readonly INSTANCE: UuidGenerator = new UuidV4Generator();

    public static getInstance(): UuidGenerator {
        return UuidGeneratorFactory.INSTANCE;
    }
}
