import {
    atom,
    atomFamily
} from "recoil";

import WorkTypeDTO from "../DTO/WorkTypeDTO";

export const workTypeIdsAtom = atom<Array<string>>({
    key: "workTypeIdsAtom",
    default: []
});

export const workTypeByWorkTypeIdAtomFamily = atomFamily<WorkTypeDTO | undefined, string>({
    key: "workTypeByWorkTypeIdAtomFamily",
    default: undefined
});