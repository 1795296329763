import {
    API,
    graphqlOperation
} from "aws-amplify";

import ClientLogger from "../logging/ClientLogger";
import { GetUserOnboardingProgressByUserIdQuery } from "../../API";
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { IDTokenSupplier } from "../auth/IDTokenSupplier";
import { UserOnboardingProgress } from "../../models";
import UserOnboardingProgressDAO from "./UserOnboardingProgressDAO";
import UserOnboardingProgressRetrievalError from "./UserOnboardingProgressRetrievalError";
import { getUserOnboardingProgressByUserId } from "../../graphql/queries";

export default class GraphQLUserOnboardingProgressDAO implements UserOnboardingProgressDAO {
    public static readonly GET_USER_ONBOARDING_PROGRESS_BY_USERID_ATTEMPT = "GetUserOnboardingProgressByUserIdAttempt";
    public static readonly GET_USER_ONBOARDING_PROGRESS_BY_USERID_FAILURE = "GetUserOnboardingProgressByUserIdFailure";

    private readonly api: typeof API;
    private readonly gqlOperation: typeof graphqlOperation;
    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier
    }

    public async getUserOnboardingProgressByUserId(userId: string): Promise<UserOnboardingProgress | undefined> {
        try {
            this.logger.info(
                `Attempting to get userOnboardingProgress from cloud for user: ${userId}`,
                undefined,
                [GraphQLUserOnboardingProgressDAO.GET_USER_ONBOARDING_PROGRESS_BY_USERID_ATTEMPT]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const existingUserOnboardingProgressList = await this.api.graphql(
                this.gqlOperation(
                    getUserOnboardingProgressByUserId,
                    {
                        userId: userId
                    },
                    authToken
                )
            ) as GraphQLResult<GetUserOnboardingProgressByUserIdQuery>;
            return existingUserOnboardingProgressList.data?.getUserOnboardingProgressByUserId?.items[0] as UserOnboardingProgress;
        } catch (error) {
            this.logger.error(
                `Failed to get userOnboardingProgress from cloud for user: ${userId}`,
                error,
                [GraphQLUserOnboardingProgressDAO.GET_USER_ONBOARDING_PROGRESS_BY_USERID_FAILURE]
            );
            throw new UserOnboardingProgressRetrievalError("Failed to get userOnboardingProgress");
        }
    }

    public async createUserOnboardingProgress(
        userId: string,
        hasUserBeenAssociatedWithOrganization: boolean
    ): Promise<UserOnboardingProgress> {
        throw new Error("Method not implemented.");
    }

}