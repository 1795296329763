import {
    AddIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from "../icons";
import {
    Box,
    Collapse,
    Divider,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import {
    H1_TYPOGRAPHY_STYLE,
    H2_TYPOGRAPHY_STYLE
} from "./TypographyConstants";
import {
    proposalAdjustmentAmountByContextSelectorFamily,
    proposalAdjustmentItemsByContextAtomFamily,
    proposalPriceAfterAdjustmentByContextSelectorFamily,
    proposalPriceBeforeAdjustmentByContextSelectorFamily
} from "../../lib/design/bidding/state/v2/ProposalStates";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import AdjustmentItem from "../../lib/design/element/adjustment/AdjustmentItem";
import { AdjustmentType } from "../../lib/design/element/adjustment/AdjustmentType";
import { ButtonConfirmationPrompt } from "../general/button/ButtonConfirmationPrompt";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { NegativePriceNumberMask } from "../design/document/element/wss/NegativePriceNumberMask";
import NumberStringConverter from "../../lib/util/NumberStringConverter";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { grey } from "@mui/material/colors";
import { useState } from "react";

export interface ProposalSummaryFormProps {
    context: StateContext;
}

const defaultAdjustmentItem: AdjustmentItem = {
    type: AdjustmentType.ADDITION
};

export const ProposalSummaryForm = ({ context }: ProposalSummaryFormProps) => {
    const proposalPriceBeforeAdjustment = useRecoilValue<number | undefined>(proposalPriceBeforeAdjustmentByContextSelectorFamily(context));
    const proposalPriceAfterAdjustment = useRecoilValue<number | undefined>(proposalPriceAfterAdjustmentByContextSelectorFamily(context));
    const [adjustmentItems, setAdjustmentItems] = useRecoilState<Array<AdjustmentItem>>(proposalAdjustmentItemsByContextAtomFamily(context));
    const totalAdjustmentAmount = useRecoilValue<number | undefined>(proposalAdjustmentAmountByContextSelectorFamily(context));

    const [areAdjustmentItemsExpanded, setAreAdjustmentItemsExpanded] = useState<boolean>(false);
    const [newAdjustmentItem, setNewAdjustmentItem] = useState<AdjustmentItem>(defaultAdjustmentItem);
    const [newAdjustmentItemAmountText, setNewAdjustmentItemAmountText] = useState<string>("");

    const onAdjustmentItemChange = (key: keyof AdjustmentItem, value: string | number | undefined) => {
        setNewAdjustmentItem((original) => {
            return {
                ...original,
                [key]: value
            };
        });
    };

    const onAdjustmentAmountChange = (value: string, save: boolean) => {
        const valueWithoutFormatting: string = value.replace("$", "").replaceAll(",", "");

        setNewAdjustmentItemAmountText(valueWithoutFormatting);
        if (save) {
            const valueAsNumber = parseFloat(valueWithoutFormatting);
            if (isNaN(valueAsNumber)) {
                setNewAdjustmentItemAmountText("");
                onAdjustmentItemChange("amount", undefined);
                return;
            }
            setNewAdjustmentItemAmountText(valueAsNumber.toString());
            onAdjustmentItemChange("amount", valueAsNumber);
        }
    };

    const addAdjustmentItem = () => {
        if (newAdjustmentItem.amount == null || newAdjustmentItem.name == null || newAdjustmentItem.type == null) {
            return;
        }
        setAdjustmentItems((original) => {
            return [...original, newAdjustmentItem];
        });
        setNewAdjustmentItem(defaultAdjustmentItem);
        setNewAdjustmentItemAmountText("");
    };

    const removeAdjustmentItem = (adjustmentItemToRemove: AdjustmentItem) => {
        setAdjustmentItems((original) => {
            const index = original.indexOf(adjustmentItemToRemove);
            if (index === -1) {
                return original;
            }
            const newAdjustmentItems = [...original];
            newAdjustmentItems.splice(index, 1);
            return newAdjustmentItems;
        });
    };

    return (
        <>
            <Box data-cy="proposal-summary-form">
                <Typography {...H1_TYPOGRAPHY_STYLE}>
                    Proposal Summary
                </Typography>
                <Box mt={2} display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                            <Typography {...H2_TYPOGRAPHY_STYLE}>
                                Price Before Adjustment:
                            </Typography>
                        </Box>
                        <Box>
                            <Typography align="right" {...H1_TYPOGRAPHY_STYLE}>
                                ${NumberStringConverter.numberToString(proposalPriceBeforeAdjustment, 1, true)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                        <Box onClick={() => setAreAdjustmentItemsExpanded(original => !original)} sx={{ cursor: "pointer" }}>
                            <Box display="flex" alignItems="center">
                                <Typography {...H1_TYPOGRAPHY_STYLE}>
                                    Adjustments:
                                </Typography>
                                <Box data-cy="expand-adjustment-items" display="flex">
                                    {areAdjustmentItemsExpanded ?
                                        <ChevronUpIcon primary="primary" /> :
                                        <ChevronDownIcon primary="primary" />
                                    }
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Typography align="right" {...H1_TYPOGRAPHY_STYLE}>
                                ${NumberStringConverter.numberToString(totalAdjustmentAmount, 1, true)}
                            </Typography>
                        </Box>
                    </Box>
                    <Collapse in={areAdjustmentItemsExpanded}>
                        <Box mt={1} display="flex" flexDirection="column">
                            {
                                adjustmentItems.map((item) => (
                                    <Box display="flex" alignItems="center" key={item.name} mb={1}>
                                        <ButtonConfirmationPrompt
                                            onConfirmButtonClicked={() => removeAdjustmentItem(item)}
                                            promptMessage="Really?"
                                            promptStyles={{
                                                color: "error",
                                                variant: "contained"
                                            }}
                                        >
                                            {(onClick, ref) => (
                                                <IconButton onClick={onClick} ref={ref} size="small">
                                                    <DeleteOutlineIcon color="error" fontSize="small" />
                                                </IconButton>
                                            )}
                                        </ButtonConfirmationPrompt>
                                        <Box ml={1} display="flex" gap={2} justifyContent="space-between" width="100%">
                                            <Typography {...H2_TYPOGRAPHY_STYLE}>
                                                {item.name}
                                            </Typography>
                                            <Typography>
                                                ${NumberStringConverter.numberToString(item.amount, 1, true)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))
                            }
                            <Box display="flex" gap={2} justifyContent="space-between" alignItems="flex-end">
                                <Box flex={1}>
                                    <Typography {...H2_TYPOGRAPHY_STYLE}>
                                        Adjustment Name
                                    </Typography>
                                    <TextField
                                        id="adjustmentItemName"
                                        size="small"
                                        fullWidth
                                        inputProps={{
                                            inputMode: "text",
                                            style: { fontSize: "0.875rem" }
                                        }}
                                        value={newAdjustmentItem.name || ""}
                                        variant="outlined"
                                        onChange={(event) => onAdjustmentItemChange("name", event.target.value)}
                                    />
                                </Box>
                                <Box flex={1}>
                                    <Typography {...H2_TYPOGRAPHY_STYLE}>
                                        Adjustment Amount
                                    </Typography>
                                    <TextField
                                        id="adjustmentItemAmount"
                                        size="small"
                                        fullWidth
                                        inputProps={{
                                            inputMode: "decimal",
                                            style: { fontSize: "0.875rem" }
                                        }}
                                        InputProps={{
                                            inputComponent: NegativePriceNumberMask,
                                        }}
                                        value={newAdjustmentItemAmountText}
                                        variant="outlined"
                                        onChange={(event) => onAdjustmentAmountChange(event.target.value, false)}
                                        onBlur={(event) => onAdjustmentAmountChange(event.target.value, true)}
                                    />
                                </Box>
                                <IconButton
                                    onClick={addAdjustmentItem}
                                    data-cy="add-adjustment-item-button"
                                    size="small"
                                    sx={{
                                        borderRadius: "4px",
                                        backgroundColor: grey[200],
                                        border: `1px solid ${grey[300]}`,
                                        color: grey[900],
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Collapse>
                    <Box mt={1}>
                        <Divider />
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                        <Box>
                            <Typography {...H2_TYPOGRAPHY_STYLE}>
                                Total Price:
                            </Typography>
                        </Box>
                        <Box>
                            <Typography align="right" {...H1_TYPOGRAPHY_STYLE}>
                                ${NumberStringConverter.numberToString(proposalPriceAfterAdjustment, 1, true)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
