import {
    useEffect,
    useState
} from "react";
import {
    FormikValues,
    useFormik
} from "formik";

import { LinkAction } from "../../models";
import { IssueFormVariablesForm } from "./IssueFormVariablesForm";
import { ExternalReportVariablesForm } from "./report/ExternalReportVariablesForm";
import { Button } from "@mui/material";

type VariablesFormProps<T extends FormikValues> = {
    formik: ReturnType<typeof useFormik<T>>;
};

export const VariablesForm = <T extends FormikValues>(props: VariablesFormProps<T>) => {
    const { formik } = props;
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        handleLinkActionChange();
    }, [formik.values.linkAction]);

    const handleLinkActionChange = async () => {
        setIsInitialized(true);
        setIsLoading(true);
        // Reset variables when link action changes
        if (isInitialized) {
            await formik.setFieldValue("variables", {});
        }
        setIsLoading(false);
    }

    if (isLoading) {
        return <></>
    }

    switch (formik.values.linkAction) {
        case LinkAction.ISSUE_FORM:
            return (
                <IssueFormVariablesForm
                    formik={formik}
                />
            );
        case LinkAction.EXTERNAL_REPORT:
            return (
                <ExternalReportVariablesForm
                    formik={formik}
                />
            );
        default:
            return null;
    }
};
