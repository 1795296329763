import { PermissionType } from "../../../models";
import { resourcePermissionAtomFamily } from "../../../lib/permission/state/ResourcePermissionRecoilState";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";

export type PermissionRestrictedControlProps = {
    resourceId: string;
    requiredPermission: PermissionType | undefined;
    permissionOverridden?: boolean;
    children: (disabled: boolean) => JSX.Element;
};

export const PermissionRestrictedControl = (props: PermissionRestrictedControlProps) => {
    const { resourceId, requiredPermission, permissionOverridden, children } = props;

    const resourcePermissions = useRecoilValue<Set<PermissionType>>(resourcePermissionAtomFamily(resourceId));

    const isEnabled = useMemo(() => {
        if (permissionOverridden || !requiredPermission) {
            return true;
        }
        return resourcePermissions.has(requiredPermission);
    }, [permissionOverridden, resourcePermissions, requiredPermission]);

    return (
        children(!isEnabled)
    );
};