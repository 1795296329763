import {
    OnCreateWorkSpecificationSubscription,
    OnDeleteWorkSpecificationSubscription,
    OnUpdateWorkSpecificationSubscription
} from '../../API';
import {
    PermissionResourceType,
    WorkSpecification
} from '../../models';
import {
    isEditModeByDesignElementIdentifierAtomFamily,
    workSpecificationByWorkSpecificationIdentifierSelectorFamily,
} from '../design/document/state/DocumentState';
import {
    onCreateWorkSpecification,
    onDeleteWorkSpecification,
    onUpdateWorkSpecification,
} from '../../graphql/subscriptions';

import { API } from 'aws-amplify';
import { AuthIDTokenSupplierFactory } from '../auth/AuthIDTokenSupplierFactory';
import AuthUsernameSupplierFactory from '../auth/AuthUsernameSupplierFactory';
import { ContextAwareIdentifier } from '../design/document/ContextAwareIdentifier';
import { GraphQLSubscription } from '@aws-amplify/api';
import { ModelType } from '../design/document/ModelType';
import { StateContext } from '../design/document/state/StateContext';
import { createDefaultProposalItem } from '../design/bidding/state/v2/ProposalItemStates';
import { fetchPermissionsForResource } from '../permission/state/ResourcePermissionRecoilState';
import { useRecoilCallback } from 'recoil';
import { useUpdateWorkSpecification } from '../design/workscopespecification/hook/useUpdateWorkSpecification';

let createSub: ZenObservable.Subscription | undefined;
let updateSub: ZenObservable.Subscription | undefined;
let deleteSub: ZenObservable.Subscription | undefined;
const tokenSupplier = AuthIDTokenSupplierFactory.getInstance()
const usernameSupplier = AuthUsernameSupplierFactory.getInstance()

export const useWorkSpecificationSubscription = () => {
    const { updateWorkSpecification } = useUpdateWorkSpecification(StateContext.INSPECTION)
    const subscribe = async (propertyId: string) => {
        const username = await usernameSupplier.get();
        const authCode = await tokenSupplier.get();

        const filterVariables = {
            canView: {
                contains: username
            },
            propertyId: {
                eq: propertyId
            }
        }
        createSub = API.graphql<GraphQLSubscription<OnCreateWorkSpecificationSubscription>>({
            query: onCreateWorkSpecification,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handlWorkSpecificationUpdate(value.data?.onCreateWorkSpecification as WorkSpecification, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
        updateSub = API.graphql<GraphQLSubscription<OnUpdateWorkSpecificationSubscription>>({
            query: onUpdateWorkSpecification,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handlWorkSpecificationUpdate(value.data?.onUpdateWorkSpecification as WorkSpecification, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
        deleteSub = API.graphql<GraphQLSubscription<OnDeleteWorkSpecificationSubscription>>({
            query: onDeleteWorkSpecification,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handleWorkSpecificationDelete(value.data?.onDeleteWorkSpecification as WorkSpecification, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
    }

    const handlWorkSpecificationUpdate = useRecoilCallback(({ set, snapshot }) => async (workSpec: WorkSpecification, context: StateContext) => {
        const wsIdentifier = new ContextAwareIdentifier(workSpec.id, context, ModelType.WORK_SPECIFICATION);
        const existingWs = await snapshot.getPromise(workSpecificationByWorkSpecificationIdentifierSelectorFamily(wsIdentifier));
        if (existingWs == null) {
            await fetchPermissionsForResource(workSpec.id, PermissionResourceType.WORK_SPECIFICATION, set);
            set(workSpecificationByWorkSpecificationIdentifierSelectorFamily(wsIdentifier), workSpec);
            set(isEditModeByDesignElementIdentifierAtomFamily(wsIdentifier), true);
            createDefaultProposalItem(set, context, workSpec.id);
            return workSpec;
        } else {
            if (existingWs?.localLastUpdatedTime! >= workSpec.localLastUpdatedTime!) {
                // Ignore updates that are older than the current state
                return;
            }
            updateWorkSpecification(workSpec, false);
            return workSpec;
        }
    });

    const handleWorkSpecificationDelete = useRecoilCallback(({ set, reset, snapshot }) => async (workSpec: WorkSpecification, context: StateContext) => {
        const wsIdentifier = new ContextAwareIdentifier(workSpec.id, context, ModelType.WORK_SPECIFICATION);
        reset(workSpecificationByWorkSpecificationIdentifierSelectorFamily(wsIdentifier));
        return workSpec;
    });

    const unsubscribe = () => {
        createSub?.unsubscribe();
        updateSub?.unsubscribe();
        deleteSub?.unsubscribe();
    }

    return { subscribe, unsubscribe }
}