import { BrowserRouter } from "react-router-dom";
import { ComponentType } from "react";

export function withBrowserRouter<T>(
    Component: ComponentType<T>
) {
    const WithBrowserRouter = ({
        ...props
    }: any) => {
        return (
            <BrowserRouter>
                <Component {...props} />
            </BrowserRouter>
        );
    }
    return WithBrowserRouter
}