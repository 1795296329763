import DataStoreWorkScopeSpecificationDAOFactory from "../../../design/workscopespecification/dao/datastore/DataStoreWorkScopeSpecificationDAOFactory";
import { GenericIngressCompletionChecker } from "../GenericIngressCompletionChecker";
import { WorkSpecificationDAOToResourcePermissionMetadataDAOAdapter } from "../adapter/WorkSpecificationDAOToResourcePermissionMetadataDAOAdapter";

export class WorkSpecificationIngressCompletionCheckerFactory {
    private static INSTANCE = new GenericIngressCompletionChecker(
        new WorkSpecificationDAOToResourcePermissionMetadataDAOAdapter(
            DataStoreWorkScopeSpecificationDAOFactory.getInstance()
        )
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}