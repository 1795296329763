import { Fragment } from "react";
import { LineBreak } from "./clipboard/LineBreak";
import { StateContext } from "../../../lib/design/document/state/StateContext";
import { Typography } from "@mui/material";
import { ViewWorkTypeSummary } from "./ViewWorkTypeSummary";
import { WORD_TITLE } from "./ViewSolutionStyles";
import { useRecoilValue } from "recoil";
import { workTypeIdToUnitCountMapByContextSelectorFamily } from "../../../lib/design/document/state/DocumentState";

export const ViewWorkTypeSummaries = () => {
    const workTypeIdToUnitCountMap = useRecoilValue(workTypeIdToUnitCountMapByContextSelectorFamily(StateContext.SOLUTION_VIEWING));

    return (
        <>
            <Typography style={WORD_TITLE} component="h1">
                Work Type Used
            </Typography>
            <LineBreak />
            {Array.from(workTypeIdToUnitCountMap.keys()).map((workTypeId, index) => (
                <Fragment key={workTypeId}>
                    <ViewWorkTypeSummary
                        workTypeId={workTypeId}
                        unitCount={workTypeIdToUnitCountMap.get(workTypeId) ?? 0}
                    />
                    {index < workTypeIdToUnitCountMap.size - 1 && <LineBreak />}
                </Fragment>
            ))}
        </>
    );
};