import CreateDefaultWorkTypePricingError from "../error/CreateDefaultWorkTypePricingError";
import { DefaultWorkTypePricing } from "../../../../models";
import DefaultWorkTypePricingDAO from "../../defaultPricing/DefaultWorkTypePricingDAO";
import SetDefaultPricingForWorkTypeGroupError from "./error/SetDefaultPricingForWorkTypeGroupError";
import SetDefaultPricingForWorkTypeGroupOrchestrator from "./SetDefaultPricingForWorkTypeGroupOrchestrator";

export default class SetDefaultPricingForWorkTypeGroupOrchestratorImpl implements SetDefaultPricingForWorkTypeGroupOrchestrator {
    private readonly defaultWorkTypePricingDAO: DefaultWorkTypePricingDAO;

    constructor(
        defaultWorkTypePricingDAO: DefaultWorkTypePricingDAO
    ) {
        this.defaultWorkTypePricingDAO = defaultWorkTypePricingDAO;
    }

    public async orchestrate(
        workTypeGroupId: string,
        unitCost?: number,
        unitPrice?: number
    ): Promise<DefaultWorkTypePricing> {
        const defaultWorkTypePricingToSet: DefaultWorkTypePricing = {
            id: workTypeGroupId,
            unitCost: unitCost,
            unitPrice: unitPrice
        };
        try {
            return await this.defaultWorkTypePricingDAO.create(defaultWorkTypePricingToSet);
        } catch (error) {
            if (error instanceof CreateDefaultWorkTypePricingError) {
                try {
                    return await this.defaultWorkTypePricingDAO.update(defaultWorkTypePricingToSet);
                } catch (updateError) {
                    error = updateError;
                }
            }
            throw new SetDefaultPricingForWorkTypeGroupError(
                `Error occurred while setting default workType pricing for work type groupId: ${workTypeGroupId}`,
                error as Error
            );
        }
    }
}
