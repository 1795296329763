import {
    Attachment,
    EagerAttachment
} from "../../../../models";

import AttachmentRecordDAO from "../AttachmentRecordDAO";
import AttachmentRecordMetricNames from "../AttachmentRecordMetricNames";
import ClientLogger from "../../../logging/ClientLogger";
import CreateAttachmentRecordError from "../errors/CreateAttachmentRecordError";
import DeleteAttachmentRecordError from "../errors/DeleteAttachmentRecordError";
import GetAttachmentRecordByIdError from "../errors/GetAttachmentRecordByIdError";

export class LocalForageAttachmentRecordDAO implements AttachmentRecordDAO {
    private readonly localForage: LocalForage;
    private readonly logger: ClientLogger;

    constructor(
        localForage: LocalForage,
        logger: ClientLogger,
    ) {
        this.localForage = localForage;
        this.logger = logger;
    }

    public async create(record: Attachment): Promise<Attachment> {
        try {
            this.logger.info(
                `Creating Attachment Record: ${record.id}`,
                undefined,
                [AttachmentRecordMetricNames.LF_CREATE_ATTEMPT]
            );
            await this.localForage.setItem(record.id, record);
            return record;
        } catch (error) {
            const message = "Unable to create Attachment Record";
            this.logger.error(
                message,
                error,
                [AttachmentRecordMetricNames.LF_CREATE_FAILURE]
            );
            throw new CreateAttachmentRecordError(message);
        }
    }

    public async delete(id: string): Promise<void> {
        try {
            this.logger.info(
                `Deleting Attachment Record: ${id}`,
                undefined,
                [AttachmentRecordMetricNames.LF_DELETE_ATTEMPT]
            );
            await this.localForage.removeItem(id);
        } catch (error) {
            const message = "Unable to delete Attachment Record";
            this.logger.error(
                message,
                error,
                [AttachmentRecordMetricNames.LF_DELETE_FAILURE]
            );
            throw new DeleteAttachmentRecordError(message);
        }
    }

    public async getById(id: string): Promise<Attachment> {
        try {
            this.logger.info(
                `Getting Attachment Record: ${id}`,
                undefined,
                [AttachmentRecordMetricNames.LF_GET_BY_ID_ATTEMPT]
            );
            const attachmentRecord: Attachment | null = await this.localForage.getItem(id);
            if (!attachmentRecord) {
                throw new Error("Attachment Record not found");
            }
            return attachmentRecord;
        } catch (error) {
            const message = "Unable to get Attachment Record";
            this.logger.error(
                message,
                error,
                [AttachmentRecordMetricNames.LF_GET_BY_ID_FAILURE]
            );
            throw new GetAttachmentRecordByIdError(message);
        }
    }

    public async listByParentId(parentId: string): Promise<EagerAttachment[]> {
        throw new Error("Method not implemented.");
    }
    public async listMostRecentByParentId(parentId: string): Promise<EagerAttachment[]> {
        throw new Error("Method not implemented.");
    }
    public async listMostRecentByParentIdWithLimit(parentId: string, limit: number): Promise<EagerAttachment[]> {
        throw new Error("Method not implemented.");
    }
}