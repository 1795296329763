import {
    Box,
    Typography
} from "@mui/material";

import { AutoCalculatePriceCheckBox } from "./AutoCalculatePriceCheckBox";
import { Measurement } from "../../models";
import PriceBeforeAdjustmentField from "./PriceBeforeAdjustmentField";
import ProposalItemPriceExplanation from "./ProposalItemPriceExplanation";
import ProposalItemPriceSummary from "./ProposalItemPriceSummary";
import { ReactNode } from "react";
import { StateContext } from "../../lib/design/document/state/StateContext";

type EditProposalItemProps = {
    proposalItemId: string;
    context: StateContext;
    measurement: Measurement;
    deleteProposalItemButton: ReactNode;
    size?: "small" | "medium";
};

export const EditProposalItem = (props: EditProposalItemProps) => {
    const { proposalItemId, context, measurement, deleteProposalItemButton, size } = props;

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" px={{ xs: 1 }} pl={{ sm: 2, md: 0 }} gap={0.25}>
            <Box display="flex" gap={1} alignItems="center">
                <Box flex={1} display="flex">
                    <Typography variant="body2">
                        Price before Adjustment:
                    </Typography>
                </Box>
                <Box display="flex" width="110px">
                    <PriceBeforeAdjustmentField
                        size={size}
                        context={context}
                        proposalItemId={proposalItemId}
                    />
                </Box>
                {deleteProposalItemButton}
            </Box>
            <Box display="flex" alignItems="center">
                <Box flex={1} display="flex">
                    <Typography variant="body2">
                        Auto-Calculate the price based on the work type
                    </Typography>
                </Box>
                <Box>
                    <AutoCalculatePriceCheckBox
                        context={context}
                        proposalItemId={proposalItemId}
                    />
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" >
                <ProposalItemPriceExplanation
                    context={context}
                    proposalItemId={proposalItemId}
                    measurement={measurement}
                />
                <Box display="flex" justifyContent="flex-end">
                    <ProposalItemPriceSummary
                        context={context}
                        proposalItemId={proposalItemId}
                    />
                </Box>
            </Box>
        </Box>
    );
};
