import AttachmentUploadStatusNotFoundError from "../errors/AttachmentUploadStatusNotFoundError";
import { AttachmentUploadStatus } from "./AttachmentUploadStatus";
import AttachmentUploadStatusDAO from "./AttachmentUploadStatusDAO";

export default class LocalForageAttachmentUploadStatusDAO implements AttachmentUploadStatusDAO {
    private localForage: LocalForage;

    constructor(
        localForage: LocalForage,
    ) {
        this.localForage = localForage;
    }

    public async getUploadStatusForAllAttachments(): Promise<Map<string, AttachmentUploadStatus>> {
        const result = new Map<string, AttachmentUploadStatus>();
        await this.localForage.iterate((value: AttachmentUploadStatus, key: string) => {
            result.set(key, value);
        });
        return result;
    }

    public async getAttachmentUploadStatus(
        attachmentId: string
    ): Promise<AttachmentUploadStatus> {
        const status: AttachmentUploadStatus | null = await this.localForage.getItem(attachmentId);
        if (status == null) {
            throw new AttachmentUploadStatusNotFoundError(`Status not found for attachment: ${attachmentId}`);
        }
        return status;
    }

    public async setAttachmentUploadStatus(
        attachmentId: string,
        status: AttachmentUploadStatus
    ): Promise<void> {
        await this.localForage.setItem(attachmentId, status);
    }

    public async removeAttachmentUploadStatus(
        attachmentId: string
    ): Promise<void> {
        await this.localForage.removeItem(attachmentId);
    }
}
