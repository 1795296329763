import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const HoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="M6.146 11.146 5 12.293l-1.146-1.147-.708.708L4.293 13l-1.147 1.146.708.708L5 13.707l1.146 1.147.708-.708L5.707 13l1.147-1.146z"
        className="primary"
      />
      <path
        fill="currentColor"
        d="M9.765 21H1V1h14v7.069A8.047 8.047 0 0 1 16 8V0H0v22h10.726a8.037 8.037 0 0 1-.961-1z"
        className="primary"
      />
      <path
        fill="currentColor"
        d="M8 3h6v1H8zM8 7h6v1H8zM8 13h.589a8 8 0 0 1 .493-1H8v1zM4.5 3.793l-.646-.647-.708.708L4.5 5.207l2.354-2.353-.708-.708zM3 7h3v1H3zM3 17h3v1H3z"
        className="primary"
      />
      <path
        fill="currentColor"
        d="M16 9a7 7 0 1 0 0 14 7 7 0 0 0 0-14zm-3 3h2v8h-2v-8zm6 8h-2v-8h2v8z"
        className="accent"
      />
    </g>
  </svg>
);
export default withCustomIcon(HoldIcon);
