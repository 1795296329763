import {
    atomFamily,
    selectorFamily
} from "recoil";

import { ChildExpansionMode } from "./enum/ChildExpansionMode";
import { ChildrenExpandState } from "./enum/ChildrenExpandState";
import { ContextAwareIdentifier } from "../ContextAwareIdentifier";
import { ModelType } from "../ModelType";
import { StateContext } from "./StateContext";
import { locationIdsByContextAtomFamily } from "./DocumentState";

export const childExpansionModeByContextAtomFamily = atomFamily<ChildExpansionMode, StateContext>({
    key: "childExpansionModeByContextAtomFamily",
    default: ChildExpansionMode.FOCUS
});

export const childrenExpandStateByDesignElementIdentifierAtomFamily = atomFamily<ChildrenExpandState, ContextAwareIdentifier>({
    key: "childrenExpandStateByDesignElementIdentifierAtomFamily",
    default: ChildrenExpandState.COLLAPSE_CHILDREN
});

export const areAllLocationsExpandedSelectorFamily = selectorFamily<boolean, StateContext>({
    key: "areAllLocationsExpandedSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const locationIds: Array<string> = get(locationIdsByContextAtomFamily(context));
        for (const locationId of locationIds) {
            const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
            const locationChildrenExpandState: ChildrenExpandState = get(childrenExpandStateByDesignElementIdentifierAtomFamily(locationIdentifier));
            if (locationChildrenExpandState !== ChildrenExpandState.EXPAND_CHILDREN) {
                return false;
            }
        }
        return true;
    }
});

export const areAllLocationsCollapsedSelectorFamily = selectorFamily<boolean, StateContext>({
    key: "areAllLocationsCollapsedSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const locationIds: Array<string> = get(locationIdsByContextAtomFamily(context));
        for (const locationId of locationIds) {
            const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
            const locationChildrenExpandState: ChildrenExpandState = get(childrenExpandStateByDesignElementIdentifierAtomFamily(locationIdentifier));
            if (locationChildrenExpandState !== ChildrenExpandState.COLLAPSE_CHILDREN) {
                return false;
            }
        }
        return true;
    }
});