import * as yup from "yup";

import {
    Box,
    Button,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {
    Template,
    TemplateEntityType
} from "../../models";

import { DefaultTemplateDAOFactory } from "../../lib/template/DefaultTemplateDAOFactory";
import LoadingButton from "@mui/lab/LoadingButton";
import { MimeType } from "../../lib/googleDrive/type/MimeType";
import { TemplateDAO } from "../../lib/template/TemplateDAO";
import { TemplateInput } from "./TemplateInput";
import { TemplateType } from "../../models";
import { useFormik } from "formik";
import { useRecoilValue } from "recoil";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useUserAssociatedTeams } from "../../lib/team/useUserAssociatedTeams";
import { userIdAtom } from "../../lib/userSession/state/UserSessionState";

const REQUIRED_FIELD_TEXT = "Field cannot be empty";

const createTemplateFormSchema = yup.object({
    name: yup.string().required(REQUIRED_FIELD_TEXT),
    entityType: yup.mixed<TemplateEntityType>().oneOf(Object.values(TemplateEntityType)).required(REQUIRED_FIELD_TEXT),
    entityId: yup.string().required(REQUIRED_FIELD_TEXT),
    content: yup.mixed<File>().test("hasFile", "Please upload a file", (value) => {
        return value != null && value.type === MimeType.WORD_DOCUMENT && value.size > 0;
    })
});

type CreateTemplateFormData = yup.InferType<typeof createTemplateFormSchema>;

const templateDAO: TemplateDAO = DefaultTemplateDAOFactory.getInstance();

type CreateTemplateFormProps = {
    onCreatedTemplate?: (template: Template) => (Promise<void> | void);
    onCanceled: () => (Promise<void> | void);
};

export const CreateTemplateForm = (props: CreateTemplateFormProps) => {
    const { onCreatedTemplate, onCanceled } = props;

    const [isCreatingTemplate, setIsCreatingTemplate] = useState<boolean>(false);
    const userId = useRecoilValue<string | undefined>(userIdAtom);
    const snackbar = useSnackbar();
    const { userAssociatedTeams } = useUserAssociatedTeams();

    const onSubmitForm = async (data: CreateTemplateFormData) => {
        try {
            setIsCreatingTemplate(true);
            const createdTemplate: Template = await templateDAO.create(
                {
                    name: data.name,
                    type: TemplateType.SOLUTION,
                    ownerId: data.entityId,
                    ownerType: data.entityType,
                },
                data.content!
            );
            onCreatedTemplate?.(createdTemplate);
        } catch (error) {
            snackbar.enqueueSnackbar(
                `Failed to create template: ${(error as Error).message}`,
                { variant: "error" }
            );
        } finally {
            setIsCreatingTemplate(false);
        }
    };

    const formik = useFormik<CreateTemplateFormData>({
        initialValues: {
            name: "",
            entityType: TemplateEntityType.USER,
            entityId: userId ?? "",
            content: undefined
        },
        validationSchema: createTemplateFormSchema,
        onSubmit: onSubmitForm
    });

    return (
        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }} maxWidth="md" width="100%">
            <Box
                display="grid"
                gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)"
                }}
                gap={{ xs: 1, sm: 2 }}
                width="100%"
            >
                <Box>
                    <Typography>
                        New Template Name:
                    </Typography>
                    <TextField
                        size="small"
                        required
                        fullWidth
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        autoComplete="off"
                    />
                </Box>
                <Box>
                    <Typography>
                        Create for:
                    </Typography>
                    <TextField
                        size="small"
                        select
                        required
                        fullWidth
                        id="entityType"
                        name="entityType"
                        onChange={(e) => {
                            formik.handleChange(e);
                            if (e.target.value === TemplateEntityType.USER) {
                                formik.setFieldValue("entityId", userId);
                                return;
                            }
                            if (e.target.value === TemplateEntityType.TEAM) {
                                formik.setFieldValue("entityId", userAssociatedTeams?.[0]?.id ?? "");
                                return;
                            }
                        }}
                        value={formik.values.entityType}
                        error={formik.touched.entityType && Boolean(formik.errors.entityType)}
                        helperText={formik.touched.entityType && formik.errors.entityType}
                    >
                        <MenuItem key={TemplateEntityType.USER} value={TemplateEntityType.USER}>
                            User (Yourself)
                        </MenuItem>
                        <MenuItem
                            key={TemplateEntityType.TEAM}
                            value={TemplateEntityType.TEAM}
                            disabled={!userAssociatedTeams || userAssociatedTeams.length < 1}
                        >
                            Team ({userAssociatedTeams && userAssociatedTeams.length > 0 ?
                                userAssociatedTeams[0].legalName : "Team not found"})
                        </MenuItem>
                    </TextField>
                </Box>
                <Box
                    gridColumn="1/-1"
                    minWidth="0%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    sx={{ aspectRatio: "4/1" }}
                >
                    {formik.touched.content && formik.errors.content && (
                        <Typography variant="body2" color="error.main">
                            {formik.errors.content}
                        </Typography>
                    )}
                    <Box minHeight="220px" maxWidth="md" width="100%">
                        <TemplateInput
                            setFile={(file) => {
                                formik.setFieldTouched("content", true);
                                formik.setFieldValue("content", file);
                            }}
                            uploadedTemplate={formik.values.content}
                        />
                    </Box>
                </Box>
                <Box>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        loading={isCreatingTemplate}
                        startIcon={<></>}
                        loadingPosition="start"
                        disabled={!formik.isValid}
                    >
                        Create
                    </LoadingButton>
                </Box>
                <Box>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => onCanceled?.()}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Box >
    );
};