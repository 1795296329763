import {
    ComponentType,
    SVGProps
} from "react";
import {
    SvgIcon,
    SxProps
} from "@mui/material";

type SvgColor = "primary" | "secondary" | "action" | "disabled" | "error" | "inherit" | "info" | "success" | "warning";

function interpretSvgColor(color: SvgColor) {
    switch (color) {
        case "primary":
            return "primary.main";
        case "secondary":
            return "secondary.main";
        case "action":
            return "action.active";
        case "disabled":
            return "action.disabled";
        case "error":
            return "error.main";
        case "inherit":
            return "inherit";
        case "info":
            return "info.main";
        case "success":
            return "success.main";
        case "warning":
            return "warning.main";
        default:
            return color;
    }
};

export type CustomIconProps = {
    primary?: SvgColor;
    accent?: SvgColor;
    fontSize?: "inherit" | "small" | "medium" | "large";
    sx?: SxProps;
};

export function withCustomIcon(
    Component: ComponentType<SVGProps<SVGSVGElement>>
) {
    const WithCustomIcon = ({
        ...props
    }: CustomIconProps) => {
        const { primary, accent = "primary", fontSize, sx } = props;

        return (
            <SvgIcon
                sx={{
                    "& .primary": {
                        color: primary ? interpretSvgColor(primary) : undefined
                    },
                    "& .accent": {
                        color: interpretSvgColor(accent)
                    },
                    width: "1em",
                    height: "1em",
                    ...sx
                }}
                fontSize={fontSize}
            >
                <Component />
            </SvgIcon>
        );
    };
    return WithCustomIcon;
}