import { AttachmentParent } from "../../../../componentsV2/image/AttachmentParent";
import { ModelType } from "../../../../lib/design/document/ModelType";
import { PermissionResourceType } from "../../../../models";
import { idInFocusByModelTypeAtomFamily } from "../../../../lib/ui/InFocusRecoilStates";
import { useCaptureMeeting } from "../useCaptureMeeting";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useQueryClient } from "react-query";
import { useRecoilCallback } from "recoil";
import { useSnackbar } from "notistack";
import { useUploadImages } from "../../../../componentsV2/image/carouselComponent/useUploadImage";

export const useTakeSnapshotQuickAction = () => {
    const meetingManager = useMeetingManager();
    const snackbar = useSnackbar();
    const queryClient = useQueryClient();
    const [uploadImages] = useUploadImages();
    const { captureMeeting: captureVideo } = useCaptureMeeting();
    
    const takeSnapshotQuickAction = useRecoilCallback(({ snapshot }) => async () => {
        const issueIdInFocus = await snapshot.getPromise(idInFocusByModelTypeAtomFamily(ModelType.ISSUE));
        const locationIdInFocus = await snapshot.getPromise(idInFocusByModelTypeAtomFamily(ModelType.LOCATION));
        if (!issueIdInFocus && !locationIdInFocus) {
            snackbar.enqueueSnackbar('No location or issue in focus', { variant: 'error' });
            return;
        }
        const attachmentParent: AttachmentParent = issueIdInFocus ?
            { id: issueIdInFocus, type: PermissionResourceType.ISSUE } : { id: locationIdInFocus!, type: PermissionResourceType.LOCATION };
        const blob: Blob | undefined = await captureVideo();
        if (!blob) {
            snackbar.enqueueSnackbar('Failed to take snapshot', { variant: 'error' });
            return;
        }
        await uploadImages([blob], { shouldResize: false }, attachmentParent);
        snackbar.enqueueSnackbar('Snapshot taken and uploaded', { variant: 'success' });
        queryClient.invalidateQueries(["attachments", attachmentParent]);
    }, [snackbar, meetingManager]);

    return { takeSnapshotQuickAction };
}