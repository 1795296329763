import DataGroup from "./DataGroup";
import DataGrouper from "./DataGrouper";

/**
 * Groups item array into an array of subset {@link DataGroup}.
 * 
 * Uses {@link defaultValue} to group items that don't have the groupByProperty defined.
 */
export default class StringKeyDataGrouper<V extends object> implements DataGrouper<string, V> {

    private readonly defaultValue: string;

    constructor(defaultValue: string = "Default") {
        this.defaultValue = defaultValue;
    }

    public group(dataItems: Array<V>, groupByProperty: string): Array<DataGroup<string, V>> {
        const dataGroupsByKey: Map<string, DataGroup<string, V>> = new Map<string, DataGroup<string, V>>();
        for (const item of dataItems) {
            const key: string = Reflect.get(item, groupByProperty) as string || this.defaultValue;
            const dataGroup = dataGroupsByKey.get(key);
            if (dataGroup) {
                dataGroup.dataItems.push(item);
            } else {
                dataGroupsByKey.set(key, { key: key, dataItems: [item] });
            }
        }
        return Array.from(dataGroupsByKey.values());
    }
}
