import ClientLogger from "../../logging/ClientLogger";
import { LoggedInUserIdDAO } from "./LoggedInUserIdDAO";

export class LocalForageLoggedInUserIdDAO implements LoggedInUserIdDAO{
    private static GET_USERID_ATTEMPT = "LocalForageLoggedInUserIdDAO.get.attempt";
    private static GET_USERID_FAILURE = "LocalForageLoggedInUserIdDAO.get.failure";
    private static UPDATE_USERID_ATTEMPT = "LocalForageLoggedInUserIdDAO.update.attempt";
    private static UPDATE_USERID_FAILURE = "LocalForageLoggedInUserIdDAO.update.failure";

    private logger: ClientLogger;
    private localForage: LocalForage;
    private entryKey: string;

    constructor(
        logger: ClientLogger,
        localForage: LocalForage,
        entryKey: string
    ) {
        this.localForage = localForage;
        this.entryKey = entryKey;
        this.logger = logger;
    }

    public async get(): Promise<string | undefined> {
        try {
            this.logger.info(
                `Attempting to get user id ${this.entryKey}  from localForage`,
                [],
                [LocalForageLoggedInUserIdDAO.GET_USERID_ATTEMPT]
            );
            const userIdEntry = await this.localForage.getItem(this.entryKey) as string;
            if (userIdEntry == undefined) {
                return;
            }
            return userIdEntry;
        } catch (error) {
            this.logger.error(
                "Failed to get user id from localForage",
                error,
                [LocalForageLoggedInUserIdDAO.GET_USERID_FAILURE]
            );
        }
    }

    public async update(userId: string): Promise<void> {
        try {
            this.logger.info(
                `Attempting to update user id ${this.entryKey} to ${userId} in localForage`,
                [],
                [LocalForageLoggedInUserIdDAO.UPDATE_USERID_ATTEMPT]
            );
            await this.localForage.setItem(this.entryKey, userId);
        } catch (error) {
            this.logger.error(
                "Failed to update user id in localForage",
                error,
                [LocalForageLoggedInUserIdDAO.UPDATE_USERID_FAILURE]
            );
        }
    }
}