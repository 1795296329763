import {
    atom,
    selector
} from "recoil";

import { Property } from "../../../models";

export const propertyFormValueAtom = atom<Property | undefined>({
    key: "propertyFormValueAtom",
    default: undefined
});

export const propertyFormValidationMapAtom = atom<Map<keyof Property, boolean>>({
    key: "propertyFormValidationMapAtom",
    default: new Map<keyof Property, boolean>()
});

export const propertyFormIsValidSelector = selector<boolean>({
    key: "propertyFormIsValidSelector",
    get: ({ get }) => {
        const currentPropertyFromValidationMap: Map<keyof Property, boolean> = get(propertyFormValidationMapAtom);
        return currentPropertyFromValidationMap.get("address") === true;
    }
});
