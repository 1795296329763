import {
    SyntheticEvent,
    useCallback,
    useState
} from "react";

import Box from "@mui/material/Box";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { Paper } from "@mui/material";
import { SignInForm } from "./SignInForm";
import { SignUpConfirmationForm } from "./SignUpConfirmationForm";
import { SignUpForm } from "./SignUpForm";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

export const SignInPage = () => {
    const [value, setValue] = useState(0);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [showConfirmationPage, setShowConfirmationPage] = useState<boolean>(false);
    const [showForgotPasswordPage, setShowForgotPasswordPage] = useState<boolean>(false);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const assignEmailAndShowConfirmationPage = useCallback((email: string) => {
        //ensure the user redirects to landing page for initial sign in
        window.history.pushState({}, "", "/");
        setShowConfirmationPage(true);
        setEmail(email);
    }, []);


    return (
        <Box
            height="100vh"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Paper sx={{ p: 1, m: 2 }} variant="outlined">
                {showConfirmationPage && email != undefined ?
                    <SignUpConfirmationForm
                        email={email!}
                    /> :
                    showForgotPasswordPage ?
                        <ForgotPasswordForm
                            onBackToSignInPage={() => setShowForgotPasswordPage(false)}
                        /> :
                        <>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab sx={{ width: "50%" }} label="Sign In" value={0} />
                                <Tab sx={{ width: "50%" }} label="Create Account" value={1} />
                            </Tabs>
                            {value === 0 &&
                                <SignInForm
                                    onUserNotConfirmed={assignEmailAndShowConfirmationPage}
                                    onForgotPassword={() => setShowForgotPasswordPage(true)}
                                />
                            }
                            {value === 1 &&
                                <SignUpForm
                                    onAccountCreated={assignEmailAndShowConfirmationPage}
                                />
                            }
                        </>
                }
            </Paper>
        </Box>
    );
};