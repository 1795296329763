import AttachmentContentDAO from "../AttachmentContentDAO";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { LocalForageAttachmentContentDAO } from "./LocalForageAttachmentContentDAO"
import { MetricLoggingLocalForageAttachmentContentDAODecorator } from "./MetricLoggingLocalForageAttachmentContentDAODecorator";
import localforage from "localforage";

export default class LocalForageAttachmentContentDAOFactory {
    private static INSTANCE: AttachmentContentDAO = new MetricLoggingLocalForageAttachmentContentDAODecorator(
        new LocalForageAttachmentContentDAO(
            // For backward compatibility we will currently store images content in the general localForage instance
            // localforage.createInstance({ name: "attachment_content" })
            localforage
        ),
        ClientLoggerFactory.getClientLogger("LocalForageAttachmentContentDAO")
    )

    public static getInstance(): AttachmentContentDAO {
        return this.INSTANCE;
    }
}