import DataStorePropertyDAOFactory from "../../../property/dao/datastore/DataStorePropertyDAOFactory";
import { GenericIngressCompletionChecker } from "../GenericIngressCompletionChecker";
import { PropertyDAOToResourcePermissionMetadataDAOAdapter } from "../adapter/PropertyDAOToResourcePermissionMetadataDAOAdapter";

export class PropertyIngressCompletionCheckerFactory {
    private static INSTANCE = new GenericIngressCompletionChecker(
        new PropertyDAOToResourcePermissionMetadataDAOAdapter(
            DataStorePropertyDAOFactory.getInstance()
        )
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}