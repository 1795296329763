import {
    Box,
    Typography
} from "@mui/material";
import {
    DimensionType,
    MeasurementUnit,
    WorkSpecification
} from "../../../../models";
import {
    WORD_ATTRIBUTE_LABEL,
    WORD_ATTRIBUTE_VALUE,
    WORD_WORK_SPECIFICATION_TITLE
} from "../ViewSolutionStyles";
import {
    isSolutionSoleSourceSelectorFamily,
    workSpecificationByWorkSpecificationIdentifierSelectorFamily
} from "../../../../lib/design/document/state/DocumentState";
import {
    useMemo,
    useRef
} from "react";

import { ContextAwareIdentifier } from "../../../../lib/design/document/ContextAwareIdentifier";
import DefaultUnitCountCalculatorFactory from "../../../../lib/dimension/v2/DefaultUnitCountCalculatorFactory";
import DimensionTypeAbbreviationFactory from "../../../../lib/util/dimension/DimensionTypeAbbreviationFactory";
import MeasurementUnitAbbreviationFactory from "../../../../lib/util/dimension/MeasurementUnitAbbreviationFactory";
import { ModelType } from "../../../../lib/design/document/ModelType";
import NumberStringConverter from "../../../../lib/util/NumberStringConverter";
import ProposalItem from "../../../../lib/design/bidding/ProposalItem.d";
import { StateContext } from "../../../../lib/design/document/state/StateContext";
import UnitCountCalculator from "../../../../lib/dimension/v2/UnitCountCalculator";
import WorkTypeDTO from "../../../../lib/worktype/DTO/WorkTypeDTO";
import { hidePricesInUIAtom } from "../../../../lib/design/bidding/state/v2/ProposalStates";
import { proposalItemByWorkSpecificationIdentifierSelectorFamily } from "../../../../lib/design/bidding/state/v2/ProposalItemStates";
import { useRecoilValue } from "recoil";
import { workTypeByWorkTypeIdAtomFamily } from "../../../../lib/worktype/state/WorkTypeRecoilState";

type ClipboardSolutionWorkSpecificationProps = {
    readonly context: StateContext,
    readonly workSpecificationId: string;
    readonly index: number;
    readonly isForClipboard?: boolean;
};

const ClipboardSolutionWorkSpecification = ({ context, workSpecificationId, index, isForClipboard = false }: ClipboardSolutionWorkSpecificationProps) => {
    const workSpecificationIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(workSpecificationId, context, ModelType.DIMENSION);
    }, []);
    const workSpecification = useRecoilValue<WorkSpecification | null>(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier));
    const proposalItem = useRecoilValue<ProposalItem | undefined>(proposalItemByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier));
    const workType = useRecoilValue<WorkTypeDTO | undefined>(workTypeByWorkTypeIdAtomFamily(workSpecification?.workTypeId ?? ""));
    const isSoleSource = useRecoilValue<boolean>(isSolutionSoleSourceSelectorFamily(StateContext.SOLUTION_VIEWING));

    const measurementUnitString = useMemo(() => {
        if (workSpecification && workSpecification.measurement) {
            return MeasurementUnitAbbreviationFactory.getAbbreviation(workSpecification.measurement.measurementUnit as MeasurementUnit);
        }
        return "";
    }, [workSpecification?.measurement]);

    const dimensionTypeAndMeasurementUnitString = useMemo(() => {
        if (workSpecification && workSpecification.measurement) {
            const dimensionTypeString: string = DimensionTypeAbbreviationFactory.getAbbreviation(workSpecification.measurement.dimensionType as DimensionType);
            const measurementUnitString: string = MeasurementUnitAbbreviationFactory.getAbbreviation(workSpecification.measurement.measurementUnit as MeasurementUnit);
            return `${dimensionTypeString} ${measurementUnitString}`;
        }
        return "";
    }, [workSpecification?.measurement]);

    const hidePrices = useRecoilValue<boolean>(hidePricesInUIAtom);

    const unitCountCalculatorRef = useRef<UnitCountCalculator>(DefaultUnitCountCalculatorFactory.getInstance());

    return (
        <>
            <Typography style={WORD_WORK_SPECIFICATION_TITLE}>
                &emsp;{`${index + 1}. ${workType?.name}`}
            </Typography>
            {workSpecification?.description && (
                <Box component="p" style={{ textAlign: "left" }}>
                    <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                        &emsp;&emsp;&emsp;&emsp;Note:
                    </Typography>
                    <Typography component="span" style={WORD_ATTRIBUTE_VALUE}>
                        &nbsp;{workSpecification?.description}
                    </Typography>
                </Box>
            )}
            {
                isSoleSource &&
                <Box component="p" style={{ textAlign: "right" }}>
                    <Typography component="span" style={WORD_ATTRIBUTE_VALUE}>
                        {hidePrices ? (
                            `$ -`
                        ) : (
                            `$ ${NumberStringConverter.numberToString(proposalItem?.unitPriceAfterAdjustment, 2, true)}`
                        )}
                    </Typography>
                    <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                        &nbsp;/&nbsp;{dimensionTypeAndMeasurementUnitString}
                    </Typography>
                    <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                        &emsp;Sub Total:
                    </Typography>
                    <Typography component="span" style={WORD_ATTRIBUTE_VALUE}>
                        &nbsp;{hidePrices ? (
                            `$ -`
                        ) : (
                            `$ ${NumberStringConverter.numberToString(proposalItem?.priceAfterAdjustment, 2, true)}`
                        )}
                    </Typography>
                </Box>
            }
            <Box component="p" style={{ textAlign: isSoleSource ? "left" : "right" }}>
                <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                    &emsp;&emsp;&emsp;&emsp;L
                </Typography>
                <Typography component="span" style={WORD_ATTRIBUTE_VALUE}>
                    &nbsp;{workSpecification?.measurement?.length}
                </Typography>
                {(workSpecification?.measurement?.dimensionType === DimensionType.SQUARE) ? (
                    <>
                        <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                            &emsp;&emsp;W
                        </Typography>
                        <Typography component="span" style={WORD_ATTRIBUTE_VALUE}>
                            &nbsp;{workSpecification.measurement.width}
                        </Typography>
                        <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                            &nbsp;{measurementUnitString}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                            &nbsp;{measurementUnitString}
                        </Typography>
                        <Typography component="span" style={WORD_ATTRIBUTE_VALUE}>
                            &emsp;&emsp;&emsp;&emsp;
                        </Typography>
                    </>
                )}
                <Typography component="span" style={WORD_ATTRIBUTE_VALUE}>
                    &emsp;&emsp;{workSpecification?.measurement ? unitCountCalculatorRef.current.calculate(workSpecification?.measurement) : "N/A"}
                </Typography>
                <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                    &nbsp;{dimensionTypeAndMeasurementUnitString}
                </Typography>
            </Box>
        </>
    );
};

export default ClipboardSolutionWorkSpecification;
