import AttachmentDAO from "./AttachmentDAO";
import AttachmentUploadQueueFactory from "./upload/request/AttachmentUploadQueueFactory";
import CachingAttachmentContentDAOFactory from "./content/cache/CachingAttachmentContentDAOFactory";
import ClientLoggerFactory from "../logging/ClientLoggerFactory";
import DataStoreAttachmentRecordDAOFactory from "./record/datastore/DataStoreAttachmentRecordDAOFactory";

export default class AttachmentDAOFactory {
    private static readonly INSTANCE = new AttachmentDAO(
        ClientLoggerFactory.getClientLogger("AttachmentDAO"),
        CachingAttachmentContentDAOFactory.getInstance(),
        DataStoreAttachmentRecordDAOFactory.getInstance(),
        AttachmentUploadQueueFactory.getInstance()
    );
    
    static getAttachmentDAO() {
        return this.INSTANCE;
    }
}