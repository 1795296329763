import AttachmentUploadRequest from "../request/AttachmentUploadRequest";
import AttachmentUploadWorker from "./AttachmentUploadWorker";
import AttachmentUploadWorkerFactory from "./AttachmentUploadWorkerFactory";
import DataStoreAttachmentRecordDAOFactory from '../../record/datastore/DataStoreAttachmentRecordDAOFactory';
import { GraphQLAttachmentRecordDAOFactory } from "../../record/graphql/GraphQLAttachmentRecordDAOFactory";
import { LocalForageAttachmentUploadStatusDAOFactory } from '../status/LocalForageAttachmentUploadStatusDAOFactory';
import { S3ImageAttachmentContentDAOFactory } from "../../content/s3/S3AttachmentContentDAOFactory";
import Task from "../../../util/concurrency/Task";

export default class AttachmentUploadWorkerFactoryImpl implements AttachmentUploadWorkerFactory {
    public createWorker(
        attachmentUploadRequest: AttachmentUploadRequest
    ): Task<string> {
        return new AttachmentUploadWorker(
            attachmentUploadRequest,
            S3ImageAttachmentContentDAOFactory.getInstance(),
            DataStoreAttachmentRecordDAOFactory.getInstance(),
            GraphQLAttachmentRecordDAOFactory.getInstance(),
            LocalForageAttachmentUploadStatusDAOFactory.getInstance()
        );
    }
}
