import {
    Dimension,
    WorkSpecification
} from "../../../../models";
import {
    dimensionByDimensionIdentifierSelectorFamily,
    recentlyCreatedRecordIds,
    recordPersistenceConfigurationAtom,
    workSpecToDimensionMeasurementPropagationBehavior,
    workSpecificationByWorkSpecificationIdentifierSelectorFamily
} from "../../document/state/DocumentState";
import {
    proposalItemIdByWorkSpecificationIdentifierAtomFamily,
    updateProposalItemConfiguredUnitPrice
} from "../../bidding/state/v2/ProposalItemStates";

import { ContextAwareIdentifier } from "../../document/ContextAwareIdentifier";
import DataStoreWorkScopeSpecificationDAOFactory from "../dao/datastore/DataStoreWorkScopeSpecificationDAOFactory";
import DataStoreWorkTypeDAOFactory from "../../../worktype/dataStore/DataStoreWorkTypeDAOFactory";
import { MeasurementPropagationBehavior } from "../../../measurement/MeasurementPropagationBehavior";
import { ModelType } from "../../document/ModelType";
import { RecordPersistenceConfiguration } from "../../document/state/RecordPersistenceConfiguration";
import { RecordPersistenceMode } from "../../document/state/RecordPersistenceMode";
import { StateContext } from "../../document/state/StateContext";
import WorkScopeSpecificationDAO from "../dao/WorkScopeSpecificationDAO";
import WorkTypeDAO from "../../../worktype/WorkTypeDAO";
import WorkTypeDTO from "../../../worktype/DTO/WorkTypeDTO";
import { useRecoilCallback } from "recoil";
import { useUpdateDimension } from "../../../dimension/hook/useUpdateDimension";

const workTypeDao: WorkTypeDAO = DataStoreWorkTypeDAOFactory.getInstance();
const workSpecificationDAO: WorkScopeSpecificationDAO = DataStoreWorkScopeSpecificationDAOFactory.getInstance();

export const useUpdateWorkSpecification = (context: StateContext) => {
    const { updateDimension } = useUpdateDimension(context);

    const updateWorkSpecification = useRecoilCallback(({ snapshot, set }) => async (
        workSpecification: WorkSpecification,
        shouldPersist: boolean = true
    ) => {
        const release = snapshot.retain();
        try {
            const recordPersistenceConfiguration = snapshot.getLoadable(recordPersistenceConfigurationAtom).contents as RecordPersistenceConfiguration;
            let updatedWorkSpecification: WorkSpecification = workSpecification;
            if (shouldPersist) {
                if (recordPersistenceConfiguration.update === RecordPersistenceMode.ALWAYS_PERSIST) {
                    updatedWorkSpecification = await workSpecificationDAO.update(workSpecification);
                }
                if (recordPersistenceConfiguration.update === RecordPersistenceMode.PERSIST_RECENTLY_CREATED) {
                    const recentRecordIds = snapshot.getLoadable(recentlyCreatedRecordIds).contents as Set<string>;
                    if (recentRecordIds.has(workSpecification.id)) {
                        updatedWorkSpecification = await workSpecificationDAO.update(workSpecification);
                    }
                }
            }

            const workSpecificationIdentifier = new ContextAwareIdentifier(updatedWorkSpecification.id, context, ModelType.WORK_SPECIFICATION);
            const proposalItemId: string = snapshot.getLoadable(proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier)).contents;
            const isWorkTypeChangedPromise: Promise<boolean> = new Promise((resolve) => {
                set(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier), (prevWorkSpecification) => {
                    //update configured unit price only if work type or measurement unit changes
                    if (prevWorkSpecification?.workTypeId === updatedWorkSpecification?.workTypeId &&
                        prevWorkSpecification?.measurement?.measurementUnit === updatedWorkSpecification?.measurement?.measurementUnit) {
                        resolve(false);
                        return updatedWorkSpecification;
                    }
                    if (!proposalItemId) {
                        resolve(false);
                        return updatedWorkSpecification;
                    }
                    resolve(true);
                    return updatedWorkSpecification;
                });
            });

            if (await isWorkTypeChangedPromise) {
                const updateProposalItemPrice = async () => {
                    const selectedWorkType: WorkTypeDTO | undefined = updatedWorkSpecification.workTypeId == undefined ? undefined :
                        await workTypeDao.getById(updatedWorkSpecification.workTypeId);
                    updateProposalItemConfiguredUnitPrice(snapshot, set, context, proposalItemId, selectedWorkType?.groupId, workSpecification.measurement!);
                };
                await updateProposalItemPrice();
            }

            const measurementPropagationBehaviour = snapshot.getLoadable<MeasurementPropagationBehavior>(
                workSpecToDimensionMeasurementPropagationBehavior(workSpecificationIdentifier)
            ).contents;
            if (measurementPropagationBehaviour === MeasurementPropagationBehavior.COPY) {
                const parentDimensionIdentifier: ContextAwareIdentifier = new ContextAwareIdentifier(workSpecification.dimensionId!, context, ModelType.DIMENSION);
                const currentDimensionValue: Dimension = snapshot.getLoadable<Dimension | null>(
                    dimensionByDimensionIdentifierSelectorFamily(parentDimensionIdentifier)
                ).contents;
                const updatedDimensionValue: Dimension = {
                    ...currentDimensionValue,
                    measurement: workSpecification.measurement
                };
                await updateDimension(updatedDimensionValue);
            }
        } finally {
            release();
        }
    }, [context]);

    return { updateWorkSpecification };
};