import AdjustmentItem from "../element/adjustment/AdjustmentItem";
import { AdjustmentType } from "../element/adjustment/AdjustmentType";
import ProposalContent from "./ProposalContent";
import ProposalItem from "./ProposalItem";
import { ProposalType } from "./ProposalType";

export default class ProposalContentBuilder {
    private _proposal: ProposalContent;

    constructor(proposal?: ProposalContent) {
        if (proposal) {
            this._proposal = { ...proposal };
        } else {
            this._proposal = {};
        }
    }

    public proposalType(value: ProposalType): ProposalContentBuilder {
        this._proposal = {
            ...this._proposal,
            proposalType: value
        };
        return this;
    }

    public designDocumentId(value: string): ProposalContentBuilder {
        this._proposal = {
            ...this._proposal,
            designDocumentId: value
        };
        return this;
    }

    public adjustmentItems(value: Array<AdjustmentItem>): ProposalContentBuilder {
        this._proposal = {
            ...this._proposal,
            adjustmentItems: value
        };
        return this;
    }

    public wssIdToProposalItemMap(value: Map<string, ProposalItem> | undefined) {
        this._proposal = {
            ...this._proposal,
            wssIdToProposalItemMap: value
        };
        return this;
    }

    public priceBeforeAdjustment(value: number | undefined): ProposalContentBuilder {
        this._proposal = {
            ...this._proposal,
            priceBeforeAdjustment: value
        };
        return this;
    }

    public priceAfterAdjustment(value: number | undefined): ProposalContentBuilder {
        this._proposal = {
            ...this._proposal,
            priceAfterAdjustment: value
        };
        return this;
    }

    public build(): ProposalContent {
        return this._proposal;
    }
}
