import DataStoreEventLoggingHandler from "./DataStoreEventLoggingHandler";
import { SetterOrUpdater } from "recoil";

export default class DataStoreEventLoggingHandlerFactory {
    public static create(
        setSystemReadyStateProgressText: SetterOrUpdater<Array<string>>
    ) {
        return new DataStoreEventLoggingHandler(
            setSystemReadyStateProgressText
        );
    }
}
