import { Fragment } from "react";
import { LineBreak } from "./clipboard/LineBreak";
import { StateContext } from "../../../lib/design/document/state/StateContext";
import { ViewLocationSummary } from "./ViewLocationSummary";
import { filteredLocationIdsByContextSelectorFamily } from "../../../lib/design/document/state/DocumentState";
import { useRecoilValue } from "recoil";

export const ViewLocationSummaries = () => {
    const locationIds = useRecoilValue<Array<string>>(filteredLocationIdsByContextSelectorFamily(StateContext.SOLUTION_VIEWING));

    return (
        <>
            {locationIds.map((locationId, index) => (
                <Fragment key={locationId}>
                    <ViewLocationSummary
                        locationId={locationId}
                    />
                    {index < locationIds.length - 1 && <LineBreak />}
                </Fragment>
            ))}
        </>
    );
};
