import { FetchGoogleDriveFileDAOFactory } from "../dao/FetchGoogleDriveFileDAOFactory";
import { GApiGoogleDriveFolderDAOFactory } from "../dao/GApiGoogleDriveFolderDAOFactory";
import { GenerateReportClient } from "../../design/document/export/GenerateReportClient";
import { GenerateReportResourceType } from "../../../models";
import { GoogleDriveFileDAO } from "../dao/GoogleDriveFileDAO";
import { GoogleDriveFolder } from "../type/GoogleDriveFolder";
import { GoogleDriveFolderDAO } from "../dao/GoogleDriveFolderDAO";
import { GraphQLGenerateReportClientFactory } from "../../design/document/export/GraphQLGenerateReportClientFactory";
import { LIMIT_TO_APP_RELATED_DRIVE_DATA_SCOPE_URL } from "../GApiConstants";
import { MimeType } from "../type/MimeType";
import { SolutionGenerationParams } from "../type/SolutionGenerationParams";
import { TENERA_FOLDER_NAME } from "../GoogleDriveConstants";
import { UnauthenticatedError } from "../error/UnauthenticatedError";
import { useCallback } from "react";
import { useGoogleLogin } from "./useGoogleLogin";
import { useRecoilCallback } from "recoil";

const fileDAO: GoogleDriveFileDAO = FetchGoogleDriveFileDAOFactory.getInstance();
const folderDAO: GoogleDriveFolderDAO = GApiGoogleDriveFolderDAOFactory.getInstance();
const generateReportClient: GenerateReportClient = GraphQLGenerateReportClientFactory.getInstance();

export type UploadToGoogleDriveHandler = (
    documentName: string,
    file: Blob,
    containerFolder?: GoogleDriveFolder
) => Promise<gapi.client.drive.File>;

export const useExportToGoogleDocs = () => {
    const { loginToGoogle } = useGoogleLogin();

    const exportFromTemplate = useCallback(async (
        proposalId: string,
        templateId: string,
        params: SolutionGenerationParams
    ) => {
        const generatedReport = await generateReportClient.export({
            resourceId: proposalId,
            templateId,
            resourceType: GenerateReportResourceType.SOLE_SOURCE,
            parameters: JSON.stringify(params)
        });
        return generatedReport;
    }, []);

    const uploadToGoogleDrive: UploadToGoogleDriveHandler = useRecoilCallback(() => async (
        documentName: string,
        file: Blob,
        containerFolder?: GoogleDriveFolder
    ) => {
        let parentFolder: gapi.client.drive.File | undefined;
        if (containerFolder) {
            parentFolder = await folderDAO.createIfNotExists(containerFolder);
        }
        return await fileDAO.create(
            {
                name: documentName,
                parents: parentFolder ? [parentFolder.id!] : undefined,
                mimeType: MimeType.GOOGLE_DOCUMENT
            },
            file,
            MimeType.WORD_DOCUMENT
        );
    }, []);

    const getUploadToGoogleDrive: () => Promise<UploadToGoogleDriveHandler> = useCallback(async () => {
        try {
            // Preflight check
            await folderDAO.getByName(TENERA_FOLDER_NAME);
            return uploadToGoogleDrive;
        } catch (error) {
            if (error instanceof UnauthenticatedError) {
                await loginToGoogle({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
                    scope: LIMIT_TO_APP_RELATED_DRIVE_DATA_SCOPE_URL
                });
                return await getUploadToGoogleDrive();
            }
            throw error;
        }
    }, []);

    return { exportFromTemplate, getUploadToGoogleDrive };
};
