import {
    Box,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {
    Dispatch,
    SetStateAction
} from "react";
import {
    Template,
    TemplateType
} from "../../models";

import { TemplateInput } from "./TemplateInput";
import { grey } from "@mui/material/colors";

type EditTemplateProps = {
    templateForm: Template;
    setTemplateForm: Dispatch<SetStateAction<Template>>;
    newTemplateFile: File | undefined;
    setNewTemplateFile: Dispatch<SetStateAction<File | undefined>>;
};

export const EditTemplate = (props: EditTemplateProps) => {
    const { templateForm, setTemplateForm, newTemplateFile, setNewTemplateFile } = props;

    return (
        <Box
            width="100%"
            height="100%"
            display="grid"
            gridTemplateColumns={{
                xs: "1fr",
                sm: "1fr 1fr"
            }}
            alignItems="center"
            gap={1}
        >
            <Box display="flex" flexDirection="column">
                <Typography color={grey[500]} variant="caption">
                    Name:
                </Typography>
                <TextField
                    size="small"
                    value={templateForm.name}
                    fullWidth
                    onChange={(e) => {
                        setTemplateForm(prev => ({
                            ...prev,
                            name: e.target.value
                        }));
                    }}
                />
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography color={grey[500]} variant="caption">
                    Template Type:
                </Typography>
                <TextField
                    size="small"
                    select
                    value={templateForm.type}
                    fullWidth
                    onChange={(e) => {
                        setTemplateForm(prev => ({
                            ...prev,
                            type: e.target.value as TemplateType
                        }));
                    }}
                    disabled={Object.values(TemplateType).length < 2}
                >
                    {Object.values(TemplateType).map((type) => (
                        <MenuItem key={type} value={type}>
                            {type}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
                <Typography color={grey[500]} variant="caption">
                    New Template File:
                </Typography>
            </Box>
            <Box sx={{
                aspectRatio: {
                    xs: "2/1",
                    md: "3/1"
                }
            }}>
                <TemplateInput
                    setFile={setNewTemplateFile}
                    uploadedTemplate={newTemplateFile}
                />
            </Box>
        </Box>
    );
};
