import {
    DropEvent,
    FileRejection
} from 'react-dropzone';

import { Box } from '@mui/material';
import { CustomDropZone } from '../../../image/CustomDropZone';
import { EmptyImagePlaceholder } from '../../../../componentsV2/image/carouselComponent/EmptyImagePlaceholder';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

interface LogoUploadStagingAreaProps {
    existingImage?: string;
    onImageStaged: (image: File) => void;
}

export const LogoUploadStagingArea = (props: LogoUploadStagingAreaProps) => {
    const {existingImage, onImageStaged} = props;
    const [logoImage, setLogoImage] = useState<string | undefined>(existingImage);
    const snackbar = useSnackbar();

    const onDrop = (acceptedFiles: Array<File>, fileRejections: Array<FileRejection>, event: DropEvent) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            const result = reader.result as string;
            setLogoImage(result);
        };
        reader.onerror = () => {
            snackbar.enqueueSnackbar("Error loading image, please try again", { variant: "error" });
        };
        reader.readAsDataURL(file);
        onImageStaged(file);
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
            }}
        >
            <CustomDropZone
                onDrop={onDrop}
                noClick={true}
                noKeyboard={true}
                accept={{
                    'image/*': ['.jpeg', '.png']
                }}
                multiple={false}
            >
                {({ open: openDropzoneInput }) => (
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%", 
                            maxHeight: "400px",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column", 
                            alignItems: "center",
                        }}
                    >
                        <>
                            {
                                logoImage ? (
                                    <img src={logoImage} alt="Logo" style={{ maxWidth: '100%', maxHeight: "100%" }} />
                                ) : (
                                    <EmptyImagePlaceholder
                                        allowDropImages={true}
                                        openDropzoneInput={openDropzoneInput}
                                    />
                                )
                            }
                        </>
                    </Box>
                )}
            </CustomDropZone>
        </Box>
    );
};