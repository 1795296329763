import ClientLogger from "../logging/ClientLogger";
import HubEventHandler from "../event/HubEventHandler";
import { HubPayload } from "@aws-amplify/core";
import NON_SYNC_MODEL_NAMES from "../../models/NonSyncableModelNames";

export default class DataStoreSyncQueriesStartedEventHandler implements HubEventHandler {
    private static readonly SYNC_QUERIES_EVENT = "syncQueriesStarted";
    private static readonly SYNCABLE_MODEL_CONFLICT = "DataStoreSyncQueriesStartedEventHandler.SyncableModelConflict";
    private readonly delegate: HubEventHandler;
    private readonly clientLogger: ClientLogger;

    constructor(
        delegate: HubEventHandler,
        clientLogger: ClientLogger
    ) {
        this.delegate = delegate;
        this.clientLogger = clientLogger;
    }

    public async handle(payload: HubPayload): Promise<void> {
        if (payload.event !== DataStoreSyncQueriesStartedEventHandler.SYNC_QUERIES_EVENT) {
            return this.delegate.handle(payload);
        }
        const models = payload.data.models;
        if (this.hasIntersection(models, NON_SYNC_MODEL_NAMES)) {
            this.clientLogger.info(
                "Detected a non-syncable model in sync metadata. Removing metadata to trigger a full sync.",
                {
                    nonSyncableModels: NON_SYNC_MODEL_NAMES,
                    modelsAttemptingSync: models
                },
                [DataStoreSyncQueriesStartedEventHandler.SYNCABLE_MODEL_CONFLICT]
            )

            const request = indexedDB.open("amplify-datastore", 3);
            request.onerror = function (event) {
                console.error("Failed to open IndexedDB:", event);
            };

            request.onsuccess = function (event) {
                // It seems this type of event does not have TS support
                //@ts-ignore
                const db = event.target.result;

                // Start a transaction with read/write access
                const transaction = db.transaction(["sync_ModelMetadata"], "readwrite");

                // Access the "sync_ModelMetadata" object store
                const objectStore = transaction.objectStore("sync_ModelMetadata");

                // Clear the entire object store
                const clearRequest = objectStore.clear();

                clearRequest.onsuccess = function () {
                    console.log("Cleared sync_ModelMetadata object store");
                    window.location.reload();
                };

                clearRequest.onerror = function () {
                    console.error("Failed to clear object store:", clearRequest.error);
                };
            };
        }
    }

    private hasIntersection(arr1: Array<string>, arr2: Array<string>): boolean {
        for (const item of arr1) {
            if (arr2.includes(item)) {
                return true;
            }
        }
        return false;
    }
}
