export default class AttachmentContentKeyWithVariantFactory {
    public static createKey(
        key: string,
        variant?: string,
        directory?: string
    ): string {
        const fileName: string = variant ? (key + variant) : key;
        return directory ? (directory + "/" + fileName) : fileName;
    }
}
