import Comparator from "../../util/data/sort/Comparator";
import { ComparisonResult } from "../../util/data/sort/ComparisonResult";
import { Property } from "../../../models";
import { SortDirection } from "../../sorting/SortDirection";

export class ByAddressComparator implements Comparator<Property> {
    private readonly sortDirection: SortDirection;

    constructor(sortDirection: SortDirection) {
        this.sortDirection = sortDirection;
    }

    public compare(a: Property, b: Property): ComparisonResult {
        if (a.address == null && b.address == null) {
            return ComparisonResult.EQUAL;
        }
        let result = 0;
        // Property with no address comes before property with address.
        if (a.address == null) {
            result = -1;
        }
        if (b.address == null) {
            result = 1;
        }
        if (a.address != null && b.address != null) {
            result = a.address.localeCompare(b.address);
        }
        if (this.sortDirection === SortDirection.DESCENDING) {
            result = -result;
        }
        if (result < 0) {
            return ComparisonResult.LESS_THAN;
        }
        if (result > 0) {
            return ComparisonResult.GREATER_THAN;
        }
        return ComparisonResult.EQUAL;
    }
}