export default class WorkTypeGroupMetricNames {
    static CREATE_ATTEMPT = "CreateWorkTypeGroupAttempt";
    static CREATE_FAILURE = "CreateWorkTypeGroupFailure";
    static GET_BY_ID_ATTEMPT = "GetWorkTypeGroupByIdAttempt";
    static GET_BY_ID_FAILURE = "GetWorkTypeGroupByIdFailure";
    static LIST_ALL_ATTEMPT = "ListAllWorkTypeGroupsAttempt";
    static LIST_ALL_FAILURE = "ListAllWorkTypeGroupsFailure";
    static LIST_DISCOVERABLE_ATTEMPT = "ListDiscoverableWorkTypeGroupsAttempt";
    static LIST_DISCOVERABLE_FAILURE = "ListDiscoverableWorkTypeGroupsFailure";
    static UPDATE_ATTEMPT = "UpdateWorkTypeGroupAttempt";
    static UPDATE_FAILURE = "UpdateWorkTypeGroupFailure";
}
