import CachingAttachmentContentDAOFactory from '../content/cache/CachingAttachmentContentDAOFactory';
import DataStoreAttachmentRecordDAOFactory from '../record/datastore/DataStoreAttachmentRecordDAOFactory';
import { ListImageURLsByParentIDOperation } from './ListImageURLsByParentIDOperation';
import { ListImageURLsByParentIDOperationImpl } from './ListImageURLsByParentIDOperationImpl';

export class ListImageURLsByParentIDOperationFactory {
    private static readonly INSTANCE: ListImageURLsByParentIDOperation = new ListImageURLsByParentIDOperationImpl(
        DataStoreAttachmentRecordDAOFactory.getInstance(),
        CachingAttachmentContentDAOFactory.getInstance()
    );

    public static getInstance(): ListImageURLsByParentIDOperation {
        return ListImageURLsByParentIDOperationFactory.INSTANCE;
    }
}
