import { APPBAR_ID } from "../../../components/sidebar/Sidebar";
import { StateContext } from "../../design/document/state/StateContext";
import { VERTICAL_QUICK_ACCESS_KEYS_ID } from "../../../components/quickAccess/QuickAccessKeys";
import { areAllLocationsExpandedSelectorFamily } from "../../design/document/state/ComponentExpandState";
import { useRecoilCallback } from "recoil";

export const useScrollResourceIntoView = (context: StateContext) => {

    const scrollResourceIntoView = useRecoilCallback(({ snapshot }) => (
        element: Element | undefined | null,
        align: "top" | "bottom"
    ) => {
        if (!element) {
            return;
        }
        const areAllLocationExpanded: boolean = snapshot.getLoadable(areAllLocationsExpandedSelectorFamily(context)).contents;
        if (areAllLocationExpanded) {
            return;
        }
        const appBarElement: HTMLElement | null = document.getElementById(APPBAR_ID);
        const appBarHeight: number = appBarElement?.offsetHeight ?? 0;
        const elementTop: number = element.getBoundingClientRect().top;
        if (align === "top") {
            window.scrollTo({
                top: elementTop + window.scrollY - appBarHeight,
                //@ts-ignore
                behavior: "instant"
            });
            return;
        }

        const elementBottom: number = element.getBoundingClientRect().bottom;
        const quickAccessKeysElement: HTMLElement | null = document.getElementById(VERTICAL_QUICK_ACCESS_KEYS_ID);
        const quickAccessKeysTop: number = quickAccessKeysElement?.getBoundingClientRect()?.top ?? window.innerHeight;
        const quickAccessKeysHeightFromBottomOfPage: number = window.innerHeight - quickAccessKeysTop;
        
        if (elementBottom > window.innerHeight - quickAccessKeysHeightFromBottomOfPage || elementBottom < quickAccessKeysHeightFromBottomOfPage) {
            window.scrollTo({
                top: elementBottom + window.scrollY - window.innerHeight + quickAccessKeysHeightFromBottomOfPage,
                //@ts-ignore
                behavior: "instant"
            });
        }
    }, [context]);

    return { scrollResourceIntoView };
};