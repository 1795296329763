import {
    Box,
    Typography
} from "@mui/material";
import {
    WORD_ATTRIBUTE_VALUE,
    WORD_PROPOSAL_METADATA,
    WORD_TITLE
} from "./ViewSolutionStyles";
import {
    hideAdjustmentsInUIAtom,
    hidePricesInUIAtom,
    proposalContentByContextSelectorFamily
} from "../../../lib/design/bidding/state/v2/ProposalStates";

import { LineBreak } from "./clipboard/LineBreak";
import NumberStringConverter from '../../../lib/util/NumberStringConverter';
import { StateContext } from "../../../lib/design/document/state/StateContext";
import { ViewLocationSummaries } from "./ViewLocationSummaries";
import { useRecoilValue } from "recoil";

export const ViewProposalSummary = () => {

    const proposalContent = useRecoilValue(proposalContentByContextSelectorFamily(StateContext.SOLUTION_VIEWING));
    const hidePrices = useRecoilValue<boolean>(hidePricesInUIAtom);
    const hideAdjustments = useRecoilValue<boolean>(hideAdjustmentsInUIAtom);

    return (
        <>
            <Typography style={WORD_TITLE} component="h1">
                Proposal Summary
            </Typography>
            <LineBreak />
            <ViewLocationSummaries />
            {
                !hideAdjustments && <>
                    <LineBreak />
                    <Typography component="span" style={WORD_PROPOSAL_METADATA}>
                        Adjustment:
                    </Typography>
                    <Box component="p" style={{ textAlign: "right" }}>
                        <Typography component="span" style={WORD_PROPOSAL_METADATA}>
                            {hidePrices ? (
                                `$ -`
                            ) : (
                                `$ ${NumberStringConverter.numberToString(
                                    proposalContent.priceAfterAdjustment! - proposalContent.priceBeforeAdjustment!,
                                    0,
                                    true
                                )}`
                            )}
                        </Typography>
                    </Box>
                </>
            }
            <LineBreak />
            <Typography style={{ ...WORD_PROPOSAL_METADATA, fontWeight: "bold" }} >
                Total price:
            </Typography>
            <Box component="p" style={{ textAlign: "right" }}>
                <Typography component="span" style={{ ...WORD_PROPOSAL_METADATA, fontWeight: "bold" }}>
                    {hidePrices ? (
                        `$ -`
                    ) : (
                        `$ ${NumberStringConverter.numberToString(proposalContent.priceAfterAdjustment,
                            0,
                            true
                        )}`
                    )}
                </Typography>
            </Box>
        </>
    );
};
