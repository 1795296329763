import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    Divider,
    LinearProgress,
    TextField,
    Typography,
} from "@mui/material"
import {
    useEffect,
    useState
} from "react"
import {
    FormikValues,
    useFormik
} from "formik";

import { CreateBrandingDialog } from "./CreateBrandingDialog";
import { useExternalReportBrandingDAO } from "./hook/useExternalReportBrandingDAO";
import { ExternalReportBranding } from "../../../../models";
import { useRecoilValue } from "recoil";
import { userIdAtom } from "../../../../lib/userSession/state/UserSessionState";

interface BrandingAutoCompleteOption {
    id: string;
    label: string;
    sideEffect?: () => void;
}

type BrandingSelectorProps<T extends FormikValues> = {
    formik: ReturnType<typeof useFormik<T>>;
}

const DEFAULT_TENERA_BRANDING: BrandingAutoCompleteOption = { id: "0", label: "Default Tenera Branding" };

export const BrandingSelector = <T extends FormikValues>(props: BrandingSelectorProps<T>) => {
    const { formik } = props;

    const signedInUserId = useRecoilValue<string | undefined>(userIdAtom);

    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [value, setValue] = useState<BrandingAutoCompleteOption>(DEFAULT_TENERA_BRANDING);
    const [inputValue, setInputValue] = useState<string>(DEFAULT_TENERA_BRANDING.label);
    const [options, setOptions] = useState<Array<BrandingAutoCompleteOption>>([]);

    const { listBrandings } = useExternalReportBrandingDAO();

    useEffect(() => {
        if (!formik.values.variables.brandingId) {
            formik.setFieldValue("variables.brandingId", DEFAULT_TENERA_BRANDING.id);
        }
        populateBrandingOptions();
    }, [signedInUserId]);

    const populateBrandingOptions = async () => {
        if (!signedInUserId) {
            return;
        }
        const brandings: Array<ExternalReportBranding> = await listBrandings(signedInUserId);
        const brandingOptions: Array<BrandingAutoCompleteOption> = brandings.map((branding) => {
            return {
                id: branding.id!,
                label: branding.name || "Unnamed Branding",
            }
        });
        setOptions(brandingOptions);
        if(brandingOptions.length > 0 && formik.values.variables.brandingId != undefined) {
            setExistingBrandingOption(formik.values.variables.brandingId, brandingOptions);
        }
        setIsLoading(false);
    };

    const setExistingBrandingOption = (brandingId: string, brandings: Array<BrandingAutoCompleteOption>) => {
        setIsLoading(true);
        const branding = brandings.find((option) => option.id == brandingId);
        if (branding) {
            setValue(branding);
            setInputValue(branding.label);
        }
        setIsLoading(false);
    }

    const handleBrandSelection = (branding: BrandingAutoCompleteOption) => {
        if (branding.sideEffect) {
            branding.sideEffect();
        } else {
            setValue(branding);
            setInputValue(branding.label);
            formik.setFieldValue("variables.brandingId", branding.id);
        }
    };

    return (
        <>
            {isLoading ?
                <LinearProgress />
            :
                <>
                    <Autocomplete
                        defaultValue={DEFAULT_TENERA_BRANDING}
                        blurOnSelect
                        disableClearable
                        renderInput={(params: AutocompleteRenderInputParams) => {
                            return <TextField
                                {...params}
                                variant="outlined"
                                label={"Branding"}
                                required
                            />
                        }}
                        onInputChange={(event, value, reason) => {
                            if (reason == "input") {
                                setInputValue(value);
                            }
                        }}
                        onBlur={() => {
                            if (inputValue != value.label) {
                                setInputValue(value.label);
                            }
                        }}
                        value={value}
                        inputValue={inputValue}
                        renderOption={(props, option: { id: string, label: string, sideEffect?: () => void }, state) => {
                            return (
                                <Box key={`${option.id}`}>
                                    <li {...props}>
                                        <Typography
                                            sx={option.sideEffect ? { fontWeight: 'bold' } : {}}
                                        >
                                            {option.label}
                                        </Typography>
                                    </li>
                                    <Divider />
                                </Box>
                            )
                        }}
                        onChange={(event, newValue) => {
                            handleBrandSelection(newValue);
                        }}
                        options={[
                            { id: "-1", label: "+ Create New", sideEffect: () => setIsCreateDialogOpen(true) },
                            DEFAULT_TENERA_BRANDING,
                            ...options,
                        ]}
                    />
                    <CreateBrandingDialog
                        isOpen={isCreateDialogOpen}
                        onCreate={(branding: ExternalReportBranding): void => {
                            const newBrandingOption: BrandingAutoCompleteOption = {
                                id: branding.id,
                                label: branding.name!,
                            };
                            setOptions([newBrandingOption, ...options]);
                            setIsCreateDialogOpen(false)
                            handleBrandSelection(newBrandingOption);
                        }}
                        onCancel={(): void => {
                            setIsCreateDialogOpen(false)
                        }}
                    />
                </>
            }
        </>
    );
};