import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const TakePhotoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8 7a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"
      className="primary"
    />
    <circle
      cx={4}
      cy={6}
      r={1}
      fill="currentColor"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M16 6h4v2h-4z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M22 2h-2c0-.623-.505-1.128-1.128-1.128h-1.744C16.505.872 16 1.377 16 2h-3.952l-1.905-2H5.857L3.952 2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h9c0-.34.033-.672.08-1H2c-.551 0-1-.449-1-1V4c0-.551.449-1 1-1h20c.551 0 1 .449 1 1v9.11c.383.392.716.83 1 1.303V4a2 2 0 0 0-2-2z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M18 12a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm2.667 6.667h-2v2a.666.666 0 0 1-1.333 0v-2h-2a.667.667 0 0 1 0-1.333h2v-2a.667.667 0 0 1 1.333 0v2h2a.666.666 0 1 1 0 1.333z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(TakePhotoIcon);
