import ProposalItem from "./ProposalItem";
import ProposalItemBuilder from "./ProposalItemBuilder";
import ProposalItemPriceAfterAdjustmentCalculationError from "./errors/ProposalItemPriceAfterAdjustmentCalculationError";
import ProposalItemPriceAfterAdjustmentCalculator from "./ProposalItemPriceAfterAdjustmentCalculator";
import UnitPriceCalculator from "./UnitPriceCalculator";

export default class DefaultProposalItemPriceAfterAdjustmentCalculator implements ProposalItemPriceAfterAdjustmentCalculator {
    private readonly unitPriceCalculator : UnitPriceCalculator;
    
    constructor(
        unitPriceCalculator: UnitPriceCalculator
    ) {
        this.unitPriceCalculator = unitPriceCalculator;
    }
    
    calculate(
        proposalItem: ProposalItem,
        adjustmentAmount: number
    ): ProposalItem {
        if (adjustmentAmount == null) {
            throw new ProposalItemPriceAfterAdjustmentCalculationError(`Unable to adjust proposalItem prices due to invalid input: adjustmentAmount: ${adjustmentAmount}`);
        }
        if (proposalItem.priceBeforeAdjustment == null || proposalItem.unitCount == null) {
            throw new ProposalItemPriceAfterAdjustmentCalculationError(`Unable to adjust proposalItem prices due to missing fields: priceBeforeAdjustment: ${proposalItem.priceBeforeAdjustment}, unitCount: ${proposalItem.unitCount}.`)
        }
        const priceAfterAdjustmentToBeSet = proposalItem.priceBeforeAdjustment + adjustmentAmount;
        const unitPriceAfterAdjustmentToBeSet = this.unitPriceCalculator.calculate(priceAfterAdjustmentToBeSet, proposalItem.unitCount);
        return new ProposalItemBuilder(proposalItem)
            .priceAfterAdjustment(priceAfterAdjustmentToBeSet)
            .unitPriceAfterAdjustment(unitPriceAfterAdjustmentToBeSet)
            .build();
    }
}
