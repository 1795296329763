import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { DataStore } from "aws-amplify";
import DataStoreIssueDAO from "./DataStoreIssueDAO";
import DataStoreQueryIssuesByLocationIdPredicateFactory from "./query/filter/DataStoreQueryIssuesByLocationIdPredicateFactory";
import IssueCreationDateSortDirectionAndLimitFactory from "./query/pagination/IssueCreationDateSortDirectionAndLimitFactory";
import { IssueDAO } from "../IssueDAO";

export default class DataStoreIssueDAOFactory {
    private static readonly INSTANCE: IssueDAO = new DataStoreIssueDAO(
        DataStore,
        new DataStoreQueryIssuesByLocationIdPredicateFactory(),
        ClientLoggerFactory.getClientLogger("DataStoreIssueDAO"),
        new IssueCreationDateSortDirectionAndLimitFactory()
    );

    public static getInstance(): IssueDAO {
        return DataStoreIssueDAOFactory.INSTANCE;
    }
}
