import {
    useRecoilValue,
    useSetRecoilState
} from "recoil";

import { AddIssueIcon } from "../icons";
import { BLACK_OUTLINED_BUTTON } from "../general/button/ButtonStyles";
import { ISSUE_PAGINATION_GROUP_ID_PREFIX } from "../../lib/pagination/Constants";
import { IssueFeatureConfiguration } from "../../lib/design/document/state/IssueFeatureConfiguration";
import LoadingButton from "@mui/lab/LoadingButton";
import { PermissionType } from "../../models";
import { SolutionAuthoringEnabledPermissionRestrictedControl } from "../general/button/SolutionAuthoringEnabledPermissionRestrictedControl";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { currentPageByGroupIdAtomFamily } from "../../lib/pagination/PaginationBarState";
import { issueFeatureConfigurationByContextAtomFamily } from "../../lib/design/document/state/DocumentState";
import { useCreateIssue } from "../../lib/issue/hook/useCreateIssue";
import { useParams } from "react-router-dom";
import { useState } from "react";

type CreateIssueButtonProps = {
    stateContext: StateContext;
    locationId: string;
};

export const CreateIssueButton = (props: CreateIssueButtonProps) => {
    const { propertyId } = useParams<{ propertyId: string; }>();
    const { stateContext, locationId } = props;

    const { createDefaultIssue } = useCreateIssue(stateContext);

    const issueFeatureConfiguration = useRecoilValue<IssueFeatureConfiguration>(issueFeatureConfigurationByContextAtomFamily(stateContext));
    const [isCreatingIssue, setIsCreatingIssue] = useState<boolean>(false);
    const setCurrentPage = useSetRecoilState(currentPageByGroupIdAtomFamily(ISSUE_PAGINATION_GROUP_ID_PREFIX + locationId));

    const onCreateButtonClick = async () => {
        setIsCreatingIssue(true);
        setCurrentPage(0);
        await createDefaultIssue(propertyId, locationId);
        setIsCreatingIssue(false);
    };

    return <>
        {issueFeatureConfiguration.displayCreateButton && (
            <SolutionAuthoringEnabledPermissionRestrictedControl
                context={stateContext}
                requiredPermission={PermissionType.CREATE_CHILDREN}
                resourceId={locationId}
            >
                {(isDisabled) => (
                    <LoadingButton
                        startIcon={<AddIssueIcon accent={isDisabled ? "disabled" : "primary"} />}
                        variant="outlined"
                        disabled={isDisabled}
                        loading={isCreatingIssue}
                        sx={{
                            ...BLACK_OUTLINED_BUTTON,
                            fontWeight: "400"
                        }}
                        onClick={onCreateButtonClick}
                    >
                        Add New Issue
                    </LoadingButton>
                )}
            </SolutionAuthoringEnabledPermissionRestrictedControl>
        )}
    </>;
};