import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
    alpha
} from "@mui/material";

import { ReactComponent as AddPropertyIcon } from "../../../public/add-property.svg";
import theme from "../../assets/style/theme";
import { useHistory } from "react-router-dom";

const CreatePropertyCard = () => {
    const history = useHistory();

    const navigate = () => {
        history.push("/properties/create");
    };

    return (
        <Card
            sx={{
                backgroundColor: alpha(theme.palette.primary.light, 0.20),
                border: "lightgray 1px solid",
                borderRadius: "0px",
                boxShadow: "none",
            }}
        >
            <CardActionArea onClick={navigate} component="span">
                <CardMedia
                    sx={{
                        p: 1,
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            border: "solid 1px lightgray",
                            backgroundColor: "rgba(0,0,0,0.05)",
                            aspectRatio: "16/9",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "& .accent": {
                                color: theme.palette.primary.main
                            }
                        }}
                    >
                        <AddPropertyIcon
                            height="50%"
                            width="50%"
                            fill={theme.palette.primary.main}
                        />
                    </Box>
                </CardMedia>
                <CardContent
                    sx={{
                        aspectRatio: "6/1",
                        py: 0,
                        px: 1
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                    >
                        <Box>
                            <Typography
                                variant="h6"
                                color="primary"
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                            >
                                Add New Property
                            </Typography>
                        </Box>
                        <Box ml={1} mb={1}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                            >
                                Click to add
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default CreatePropertyCard;
