import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const CancelIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.806 2.075c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10m0-1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="m16.048 9.247-1.414-1.415-2.828 2.829-2.829-2.829-1.414 1.415 2.829 2.828-2.829 2.828 1.414 1.415 2.829-2.829 2.828 2.829 1.414-1.415-2.828-2.828z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(CancelIcon);
