import { IssueDAO } from "../../../issue/dao/IssueDAO";
import { ResourcePermissionMetadata } from "../ResourcePermissionMetadata";
import { ResourcePermissionMetadataDAO } from "../../dao/ResourcePermissionMetadataDAO";

export class IssueDAOToResourcePermissionMetadataDAOAdapter implements ResourcePermissionMetadataDAO{
    private issueDAO: IssueDAO;

    constructor (
        issueDAO: IssueDAO
    ) {
        this.issueDAO = issueDAO;
    }

    getResourcePermissionMetadata(resourceId: string): Promise<ResourcePermissionMetadata> {
        return this.issueDAO.getIssueById(resourceId);
    }
}