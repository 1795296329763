import {
    API,
    graphqlOperation
} from "aws-amplify";
import {
    CreatePropertyMutation,
    GetPropertyQuery,
    ListPropertiesQuery
} from "../../../../API";
import { getProperty, listProperties } from "../../../../graphql/queries";

import ClientLogger from "../../../logging/ClientLogger";
import { CreatePropertyError } from "../error/CreatePropertyError";
import { GetPropertyError } from "../error/GetPropertyError";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { GraphQLSearchPropertiesOperation } from "../../operation/GraphQLSearchPropertiesOperation";
import { IDTokenSupplier } from "../../../auth/IDTokenSupplier";
import { ListPropertiesQueryVariables } from "../../../../API";
import { Property } from "../../../../models";
import PropertyDAO from "../PropertyDAO";
import PropertyRecordAlreadyExistsError from "../error/PropertyRecordAlreadyExistsError";
import { SearchResponse } from "../../../util/search/SearchResponse";
import { createProperty } from "../../../../graphql/mutations";

export default class GraphQLPropertyDAO implements PropertyDAO, GraphQLSearchPropertiesOperation {
    private static readonly CREATE_ATTEMPT_METRIC_NAME = "GraphQLPropertyDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "GraphQLPropertyDAO.Create.Failure";
    private static readonly GET_BY_ID_ATTEMPT_METRIC_NAME = "GraphQLPropertyDAO.GetById.Attempt";
    private static readonly GET_BY_ID_FAILURE_METRIC_NAME = "GraphQLPropertyDAO.GetById.Failure";
    private static readonly LIST_PROPERTIES_ATTEMPT_METRIC_NAME = "GraphQLPropertyDAO.ListProperties.Attempt";
    private static readonly LIST_PROPERTIES_FAILURE_METRIC_NAME = "GraphQLPropertyDAO.ListProperties.Failure";

    private api: typeof API;
    private gqlOperation: typeof graphqlOperation;
    private logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async getById(
        id: string
    ): Promise<Property> {
        try {
            this.logger.info(
                `Retrieving Property with id=${id}`,
                undefined,
                [GraphQLPropertyDAO.GET_BY_ID_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<GetPropertyQuery> = await this.api.graphql(
                this.gqlOperation(
                    getProperty,
                    { id },
                    authToken
                )
            ) as GraphQLResult<GetPropertyQuery>;
            return result.data?.getProperty as Property;
        } catch (error) {
            const errorMessage = `Failed to retrieve Property with id=${id}`;
            this.logger.warn(
                errorMessage,
                error,
                [GraphQLPropertyDAO.GET_BY_ID_FAILURE_METRIC_NAME]
            );
            throw new GetPropertyError(errorMessage, {
                cause: error as Error
            });
        }
    }

    public async create(
        record: Property
    ): Promise<Property> {
        this.logger.info(
            `Creating Property record with id=${record.id}`,
            record,
            [GraphQLPropertyDAO.CREATE_ATTEMPT_METRIC_NAME]
        );
        try {
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<CreatePropertyMutation> = await this.api.graphql(
                this.gqlOperation(
                    createProperty,
                    { input: record },
                    authToken
                )
            ) as GraphQLResult<CreatePropertyMutation>;
            return result.data?.createProperty as Property;
        } catch (error) {
            if ((error as any)?.errors && (error as any)?.errors[0].errorType === "ConditionalCheckFailedException") {
                const errorMessage = `Property record already exists: ${record.id}`;
                this.logger.warn(
                    errorMessage,
                    error,
                    [GraphQLPropertyDAO.CREATE_FAILURE_METRIC_NAME]
                );
                throw new PropertyRecordAlreadyExistsError(errorMessage);
            }
            const errorMessage = `Unable to create Property record with id=${record.id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLPropertyDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreatePropertyError(errorMessage, {
                cause: error as Error
            });
        }
    }

    public async editProperty(
        propertyId: string,
        updatedProperty: Property
    ): Promise<Property> {
        throw new Error("Method not implemented.");
    }

    public async listProperties(
        limit: number,
        nextToken?: string
    ): Promise<Array<Property>> {
        throw new Error("Method not implemented.");
    }

    public async searchProperties(
        searchValue: string,
        limit?: number,
        nextToken?: string
    ): Promise<SearchResponse<Property>> {
        try {
            const authToken: string = await this.idTokenSupplier.get();
            const variables: ListPropertiesQueryVariables = {
                filter: {
                    or: [
                        {
                            address: {
                                contains: searchValue
                            }
                        },
                        {
                            name: {
                                contains: searchValue
                            }
                        }
                    ]
                },
                limit: limit,
                nextToken: nextToken
            };
            const result: GraphQLResult<ListPropertiesQuery> = await this.api.graphql(
                this.gqlOperation(
                    listProperties,
                    variables,
                    authToken
                )
            ) as GraphQLResult<ListPropertiesQuery>;
            let objects: Array<Property> = (result.data?.listProperties?.items ?? []) as Array<Property>;
            objects = objects.sort((a, b) => {
                const aDisplayName = a.name ?? a.address;
                const bDisplayName = b.name ?? b.address;
                if (aDisplayName == undefined || bDisplayName == undefined) {
                    return 0;
                }
                return aDisplayName.localeCompare(bDisplayName);
            });
            return {
                objects: objects,
                nextToken: result.data?.listProperties?.nextToken ?? undefined,
                total: result.data?.listProperties?.items?.length ?? 0
            };
        } catch (error) {
            const errorMessage = `Unable to list Properties`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLPropertyDAO.LIST_PROPERTIES_FAILURE_METRIC_NAME]
            );
            throw new Error(errorMessage);
        }
    }
}
