import {
    associatedWorkTypeOptionsSelector,
    getAssociateWorkTypeCallback
} from "../../lib/worktype/state/WorkTypeState";
import {
    memo,
    useEffect,
    useState
} from "react";

import { AutocompleteOption } from "../../lib/util/ui/autocomplete/AutocompleteOption";
import { CreateWorkTypeDialog } from "./CreateWorkTypeDialog";
import DataStoreWorkTypeDAOFactory from "../../lib/worktype/dataStore/DataStoreWorkTypeDAOFactory";
import RecentlyUsedAccordionAutocomplete from "./RecentlyUsedAccordionAutocomplete";
import RecentlyUsedWorktypeDAOFactory from "../../lib/util/recent/RecentlyUsedItemsDAOFactory";
import { TextField } from "@mui/material";
import WorkTypeDAO from "../../lib/worktype/WorkTypeDAO";
import WorkTypeDTO from "../../lib/worktype/DTO/WorkTypeDTO";
import { actingAsEntityIdAtom } from "../../lib/userSession/state/UserSessionState";
import { useRecoilValue } from "recoil";

export interface WorkTypeAutoCompleteProps {
    selectedWorkTypeId?: string;
    label?: string;
    placeholder?: string;
    defaultOpen?: boolean;
    onSelectWorkType: (id: string | undefined) => void;
    size?: "small" | "medium";
}

const workTypeDAO: WorkTypeDAO = DataStoreWorkTypeDAOFactory.getInstance();

/**
 * An AutoComplete component to be used with WorkTypes. Options are filtered by typing into the Autocomplete's TextField.
 * 
 * @param props 
 *   workTypeGroups: The workTypes to be displayed in groups.
 *   selectedWorkTypeId: The currently selected WorkTypeId to display in the selector.
 *   defaultText: Text to show if no WorkType is selected
 *   onSelectWorkType: Callback to be triggered when workType is selected
 */
const WorkTypeAutoComplete = (props: WorkTypeAutoCompleteProps) => {
    const notAssociatedGroupKey = "Not associated with team";

    const associatedWorkTypeOptions = useRecoilValue<Array<AutocompleteOption>>(associatedWorkTypeOptionsSelector);
    const [selectedOption, setSelectedOption] = useState<AutocompleteOption | undefined>(undefined);

    // Create new work type dependencies
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [newWorkTypeName, setNewWorkTypeName] = useState<string>("");
    const actingAsEntityId = useRecoilValue<string>(actingAsEntityIdAtom);
    const associateWorkTypeToEntity = getAssociateWorkTypeCallback();


    useEffect(() => {
        updateSelectedOption(props.selectedWorkTypeId);
    }, [props.selectedWorkTypeId]);

    const updateSelectedOption = async (selectedWorkTypeId?: string) => {
        let currentlySelectedOption: AutocompleteOption | undefined = associatedWorkTypeOptions.find((option: AutocompleteOption) => option.id === selectedWorkTypeId);
        if (!currentlySelectedOption && selectedWorkTypeId) {
            const optionalWorkType: WorkTypeDTO | undefined = await workTypeDAO.getById(selectedWorkTypeId);
            if (optionalWorkType) {
                currentlySelectedOption = {
                    label: optionalWorkType.name,
                    group: notAssociatedGroupKey,
                    id: optionalWorkType.id
                };
            }
        }
        setSelectedOption(currentlySelectedOption);
    };

    return (
        associatedWorkTypeOptions.length === 0 ? (
            <TextField
                disabled
                variant="outlined"
                defaultValue={"No available worktypes"}
            />
        ) : (
            <>
                <RecentlyUsedAccordionAutocomplete
                    selectedOption={selectedOption}
                    label={props.label}
                    placeholder={props.placeholder}
                    options={associatedWorkTypeOptions}
                    recentlyUsedOptionsDAO={RecentlyUsedWorktypeDAOFactory.create()}
                    size={props.size || "medium"}
                    defaultOpen={props.defaultOpen}
                    onChange={(option) => {
                        if (!option?.newCreated) {
                            props.onSelectWorkType(option?.id || undefined);
                            return;
                        }
                        setNewWorkTypeName(option.label);
                        setShowDialog(true);
                    }}
                />
                <CreateWorkTypeDialog
                    newWorkTypeName={newWorkTypeName}
                    open={showDialog}
                    setOpen={setShowDialog}
                    onCreatedWorkType={async (workType: WorkTypeDTO) => {
                        setShowDialog(false);
                        setSelectedOption({
                            id: workType.id,
                            label: workType.name,
                            group: workType.categoryName
                        });
                        props.onSelectWorkType(workType.id);
                    }}
                />
            </>
        )
    );
};

export default memo(WorkTypeAutoComplete);
