import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { DataStore } from "aws-amplify";
import DataStoreWorkTypeDAO from "./DataStoreWorkTypeDAO";
import JSONDeserializerFactory from "../../util/data/serialization/JSONDeserializerFactory";
import JSONSerializerFactory from "../../util/data/serialization/JSONSerializerFactory";
import WorkTypeDAO from "../WorkTypeDAO";

export default class DataStoreWorkTypeDAOFactory {
    private static readonly INSTANCE: WorkTypeDAO = new DataStoreWorkTypeDAO(
        JSONDeserializerFactory.getInstance(),
        JSONSerializerFactory.getInstance(),
        ClientLoggerFactory.getClientLogger("DataStoreWorkTypeDAO"),
        DataStore
    );

    public static getInstance() {
        return DataStoreWorkTypeDAOFactory.INSTANCE;
    }
}
