import {
    Box,
    Divider,
    List
} from "@mui/material";

import DataGroup from "../../lib/util/data/group/DataGroup";
import NoItemsPlaceholder from "../../components/NoItemsPlaceholder";
import React from "react";
import WorkTypeDTO from "../../lib/worktype/DTO/WorkTypeDTO";
import { WorkTypeFetchWrapper } from "../../components/WorkTypeFetchWrapper";
import { WorkTypeManagementCategoryListItem } from "./WorkTypeManagementCategoryListItem";
import { associatedWorkTypeDataGroupsSelector } from "../../lib/worktype/state/WorkTypeState";
import globalStyle from "../../assets/style/globalStyle";
import { useRecoilValue } from "recoil";

const WorkTypeManagementList = () => {
    /* Internal page states */
    const workTypeCategories: Array<DataGroup<string, WorkTypeDTO>> = useRecoilValue(associatedWorkTypeDataGroupsSelector);

    /* Dependencies */
    const globalClasses = globalStyle();

    return (
        <WorkTypeFetchWrapper>
            {workTypeCategories.length === 0 ? (
            <Box className={globalClasses.containerWithBorder}>
                <NoItemsPlaceholder text="No WorkTypes associated with this Organization." />
            </Box>
            ) : (
            <List disablePadding>
                {workTypeCategories?.map((workTypeCategory, index) => {
                    return (
                        <React.Fragment key={workTypeCategory.key}>
                            <WorkTypeManagementCategoryListItem
                                workTypeCategory={workTypeCategory}
                            />
                            {index !== workTypeCategories.length - 1 && <Divider />}
                        </React.Fragment>
                    );
                })}
            </List>
            )}
        </WorkTypeFetchWrapper>
    );
};

export default WorkTypeManagementList;
