import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemProps,
    ListItemText
} from "@mui/material";

import { Attachment } from "../../models";
import { Storage } from "@aws-amplify/storage";
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { format } from "date-fns";
import { useCallback } from "react";

type RecordingListItemProps = ListItemProps & {
    attachment: Attachment;
};

export const RecordingListItem = ({ attachment, ...props }: RecordingListItemProps) => {
    const onClick = useCallback(async () => {
        const presignedUrl = await Storage.get(attachment.key!);
        window.open(presignedUrl, "_blank");
    }, [attachment]);

    return (
        <ListItem {...props}>
            <ListItemButton onClick={onClick}>
                <ListItemIcon>
                    <VideocamOutlinedIcon />
                </ListItemIcon>
                <ListItemText>
                    {format(new Date(attachment.createdAt!), "MMMM do, yyyy h:mm a")}
                </ListItemText>
            </ListItemButton>
        </ListItem>
    );
};