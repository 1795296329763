import {
    FabProps,
    useMediaQuery
} from "@mui/material";
import {
    darkBlue,
    green
} from "../../assets/style/colors";

import { ZoomAnimatedFab } from "../general/fab/ZoomAnimatedFab";
import { grey } from "@mui/material/colors";
import theme from "../../assets/style/theme";

type QuickAccessKeyProps = FabProps & { in: boolean; };

export const QUICK_ACCESS_KEY_XS_SIZE = 40;
export const QUICK_ACCESS_KEY_SM_SIZE = 48;
export const QUICK_ACCESS_KEY_MD_SIZE = 56;

export const QuickAccessKey = (props: QuickAccessKeyProps) => {
    const { children, in: show, ...fabProps } = props;
    const isLandscape = useMediaQuery(`(orientation: landscape)`);

    const isScreenXSHeight = useMediaQuery(`(max-height: ${theme.breakpoints.values.sm}px)`);
    const isScreenSMHeight = useMediaQuery(`(max-height: ${theme.breakpoints.values.md}px)`);
    const landScapeSize = isScreenXSHeight ? QUICK_ACCESS_KEY_XS_SIZE :
        isScreenSMHeight ? QUICK_ACCESS_KEY_SM_SIZE :
            QUICK_ACCESS_KEY_MD_SIZE;
            
    return (
        <ZoomAnimatedFab
            {...fabProps}
            in={show}
            sx={{
                pointerEvents: "all",
                width: isLandscape ? `${landScapeSize * 1.25}px` : {
                    xs: `${QUICK_ACCESS_KEY_XS_SIZE}px`,
                    sm: `${QUICK_ACCESS_KEY_SM_SIZE}px`,
                    md: `${QUICK_ACCESS_KEY_MD_SIZE}px`,
                },
                height: isLandscape ? `${landScapeSize}px` : {
                    xs: `${QUICK_ACCESS_KEY_XS_SIZE}px`,
                    sm: `${QUICK_ACCESS_KEY_SM_SIZE}px`,
                    md: `${QUICK_ACCESS_KEY_MD_SIZE}px`,
                },
                borderRadius: isLandscape ? `${landScapeSize / 2}px 0px 0px ${landScapeSize / 2}px` : "50%",
                backgroundColor: darkBlue[900],
                border: `1px solid ${green[200]}`,
                "svg": {
                    color: theme => theme.palette.secondary.main
                },
                "@media (hover: hover)": {
                    "&:hover": {
                        backgroundColor: darkBlue[800],
                        border: `1px solid ${green[300]}`
                    }
                },
                "@media (hover: none)": {
                    "&:hover": {
                        backgroundColor: darkBlue[900],
                        border: `1px solid ${green[200]}`
                    }
                },
                "&.Mui-disabled": {
                    backgroundColor: darkBlue[900],
                    "svg": {
                        color: grey[800]
                    }
                }
            }}
        >
            {children}
        </ZoomAnimatedFab>
    );
};