import { LOCAL_FORAGE_OFFLINE_PROPERTY_TABLE_NAME } from "../../../localforage/LocalForageConstant";
import { LocalForageBackedFifoPropertyListManager } from "./LocalForageBackedFifoPropertyListManager";
import { MAX_OFFLINE_PROPERTIES } from "../DataStoreSynchronizationConstants";
import { PropertyListManager } from "./PropertyListManager";
import localforage from "localforage";

export class OfflinePropertyListManagerFactory {
    private static readonly INSTANCE: LocalForageBackedFifoPropertyListManager = new LocalForageBackedFifoPropertyListManager(
        localforage.createInstance({name: LOCAL_FORAGE_OFFLINE_PROPERTY_TABLE_NAME}),
        MAX_OFFLINE_PROPERTIES
    );

    public static getInstance(): PropertyListManager {
        return this.INSTANCE;
    }
}