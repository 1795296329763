import SearchCriteria from "./SearchCriteria";

export default class SearchCriteriaFactory {

    private static readonly ALL_RESULTS_CRITERIA = {
        globalSearch: false,
        keywords: ""
    };

    public static getAllResultsCriteria(): SearchCriteria {
        return this.ALL_RESULTS_CRITERIA;
    } 

    public static create(
        globalSearch: boolean,
        keywords: string
    ): SearchCriteria {
        return {
            globalSearch: globalSearch,
            keywords: keywords
        };
    }
}