import {
    Box,
    CircularProgress,
    IconButton,
    LinearProgress,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import {
    Location,
    PermissionType
} from "../../models";
import {
    getUpdateLocationCallback,
    isEditModeByDesignElementIdentifierSelectorFamily,
    isSolutionSoleSourceSelectorFamily,
    locationByLocationIdentifierSelectorFamily,
    locationFeatureConfigurationByContextAtomFamily
} from "../../lib/design/document/state/DocumentState";
import {
    locationFormIsValidSelectorFamily,
    locationFormValidationMapAtomFamily
} from "../../lib/location/state/LocationFormRecoilState";
import {
    useEffect,
    useMemo,
    useRef
} from "react";
import {
    useRecoilState,
    useRecoilValue,
    useResetRecoilState
} from "recoil";

import { ComponentModificationMode } from "../../lib/design/document/state/ComponentModificationMode";
import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { EditNameIcon } from "../../components/icons";
import { ExpandableDisplayText } from "../misc/ExpandableDisplayText";
import { LocationFeatureConfiguration } from "../../lib/design/document/state/LocationFeatureConfiguration";
import { ModelType } from "../../lib/design/document/ModelType";
import NumberStringConverter from "../../lib/util/NumberStringConverter";
import SaveIcon from "@mui/icons-material/Save";
import { SolutionAuthoringEnabledPermissionRestrictedControl } from "../../components/general/button/SolutionAuthoringEnabledPermissionRestrictedControl";
import { StateContext } from "../../lib/design/document/state/StateContext";
import ViewLocationMenu from "./menu/ViewLocationMenu";
import { isAiResourceGeneratingAtomFamily } from "../../components/chime/hooks/state/AISummarizationStates";
import { proposalItemPriceTotalByLocationIdentifierSelectorFamily } from "../../lib/design/bidding/state/v2/ProposalItemStates";
import theme from "../../assets/style/theme";

const NAME_FIELD_LENGTH = 200;

type LocationCardHeaderProps = {
    readonly locationId: string;
    readonly stateContext: StateContext;
};

const LocationCardHeader = (props: LocationCardHeaderProps) => {
    const { locationId, stateContext } = props;
    const locationIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(locationId, stateContext, ModelType.LOCATION);
    }, [locationId, stateContext]);
    const [location, setLocation] = useRecoilState<Location | null>(locationByLocationIdentifierSelectorFamily(locationIdentifier));
    const locationFeatureConfiguration = useRecoilValue<LocationFeatureConfiguration>(
        locationFeatureConfigurationByContextAtomFamily(stateContext)
    );
    const locationPriceTotal = useRecoilValue<number>(proposalItemPriceTotalByLocationIdentifierSelectorFamily(locationIdentifier));
    const isSoleSource = useRecoilValue<boolean>(isSolutionSoleSourceSelectorFamily(stateContext));
    const groupNameRef = useRef<HTMLInputElement>(null);

    /* location form states */
    const [isEditMode, setIsEditMode] = useRecoilState<boolean>(isEditModeByDesignElementIdentifierSelectorFamily(locationIdentifier));
    const isFormValid = useRecoilValue<boolean>(locationFormIsValidSelectorFamily(locationId));
    const [formValidationMap, setFormValidationMap] = useRecoilState<Map<keyof Location, boolean>>(locationFormValidationMapAtomFamily(locationId));
    const resetIsEditMode = useResetRecoilState(isEditModeByDesignElementIdentifierSelectorFamily(locationIdentifier));
    const resetFormValidationMap = useResetRecoilState(locationFormValidationMapAtomFamily(locationId));

    const isScreenSizeSm = useMediaQuery(theme.breakpoints.down("md"));
    const updateLocation = getUpdateLocationCallback();
    const isAiGeneratingLocation = useRecoilValue<boolean>(isAiResourceGeneratingAtomFamily(locationId));

    useEffect(() => {
        if (isEditMode) {
            if (groupNameRef.current) {
                groupNameRef.current.focus();
            }
        }
    }, [isEditMode]);

    useEffect(() => {
        setFormValidationMap((original) => {
            const updated: Map<keyof Location, boolean> = new Map(original);
            updated.set("name", !!location?.name);
            return updated;
        });
    }, []);

    useEffect(() => () => {
        const resetRecoilState = () => {
            resetIsEditMode();
            resetFormValidationMap();
        };
        resetRecoilState();
    }, []);

    const onFieldChange = (key: keyof Location, value: string): void => {
        const updatedLocation: Location = {
            ...location,
            [key]: value
        } as Location;
        setLocation(updatedLocation);
        setFormValidationMap((original) => {
            const updated: Map<keyof Location, boolean> = new Map(original);
            updated.set("name", !!updatedLocation.name);
            return updated;
        });
    };

    const updateIfValid = () => {
        if (location && isFormValid) {
            updateLocation(location, stateContext);
        }
    };

    const onSave = () => {
        if (location && isFormValid) {
            setIsEditMode(false);
        }
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={1} minWidth="0px"> {/* minWidth="0px" is needed to prevent overflow */}
                {!isAiGeneratingLocation && isEditMode && locationFeatureConfiguration.componentModificationMode === ComponentModificationMode.COMPONENT_DECIDES && (
                    <IconButton
                        onClick={onSave}
                        disabled={!isFormValid}
                        title="Save"
                    >
                        <SaveIcon color="primary" />
                    </IconButton>
                )}
                {(!isAiGeneratingLocation && !isEditMode && locationFeatureConfiguration.displayViewEditToggle) &&
                    <SolutionAuthoringEnabledPermissionRestrictedControl
                        requiredPermission={PermissionType.EDIT}
                        resourceId={locationId}
                        context={stateContext}
                    >
                        {(isDisabled) => (
                            <IconButton
                                onClick={() => setIsEditMode(true)}
                                disabled={isDisabled}
                                title="Edit"
                            >
                                <EditNameIcon accent={isDisabled ? "disabled" : "primary"} />
                            </IconButton>
                        )}
                    </SolutionAuthoringEnabledPermissionRestrictedControl>
                }
                {isAiGeneratingLocation ?
                    <Box px={2}>
                        <CircularProgress size={24} />
                    </Box> :
                    isEditMode ? (
                        <TextField
                            placeholder="Group Name"
                            fullWidth
                            variant="outlined"
                            size="small"
                            required
                            error={formValidationMap.get("name") === false}
                            inputProps={{ maxLength: NAME_FIELD_LENGTH }}
                            value={location?.name}
                            onChange={(e) => onFieldChange("name", e.target.value)}
                            onBlur={updateIfValid}
                            inputRef={groupNameRef}
                            onFocus={(e) => e.target.select()}
                        />
                    ) : (
                        <ExpandableDisplayText
                            description={location?.name ?? ""}
                            linesToDisplayWhenCollapsed={1}
                            typographyProps={{
                                variant: "h6"
                            }}
                        />
                    )}
            </Box>
            <Box display="flex" alignItems="center" gap={1} flex={0}>
                {
                    isSoleSource &&
                    <Box display="flex" alignItems="center">
                        <Typography component="span" fontSize="0.875rem">
                            $&nbsp;
                        </Typography>
                        <Typography variant="h6" component="span" whiteSpace="pre-wrap">
                            {locationPriceTotal == null ? "N/A" : `${NumberStringConverter.numberToString(locationPriceTotal, 0, true).padEnd(10, " ")}`}
                        </Typography>
                    </Box>
                }
                {locationFeatureConfiguration.displayContextMenu && (
                    <ViewLocationMenu
                        stateContext={stateContext}
                        locationId={locationId}
                        size={isScreenSizeSm ? "small" : "medium"}
                    />
                )}
            </Box>
        </Box>
    );
};

export default LocationCardHeader;
