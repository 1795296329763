import {
    EntityResourcePermission,
    PermissionType,
} from '../../models';
import {
    OnCreateEntityResourcePermissionSubscription,
    OnUpdateEntityResourcePermissionSubscription
} from '../../API';
import {
    onCreateEntityResourcePermission,
    onUpdateEntityResourcePermission,
} from '../../graphql/subscriptions';

import { API } from 'aws-amplify';
import { AuthIDTokenSupplierFactory } from '../auth/AuthIDTokenSupplierFactory';
import AuthUsernameSupplierFactory from '../auth/AuthUsernameSupplierFactory';
import { GraphQLSubscription } from '@aws-amplify/api';
import { StateContext } from '../design/document/state/StateContext';
import { resourcePermissionAtomFamily } from '../permission/state/ResourcePermissionRecoilState';
import { useRecoilCallback } from 'recoil';

let createSub: ZenObservable.Subscription | undefined;
let updateSub: ZenObservable.Subscription | undefined;
const tokenSupplier = AuthIDTokenSupplierFactory.getInstance()
const usernameSupplier = AuthUsernameSupplierFactory.getInstance()

export const usePermissionsSubscription = () => {
    const subscribe = async (propertyId: string) => {
        const username = await usernameSupplier.get();
        const authCode = await tokenSupplier.get();

        const filterVariables = {
            entityId: {
                eq: username
            },
            propertyId: {
                eq: propertyId
            }
        }
        createSub = API.graphql<GraphQLSubscription<OnCreateEntityResourcePermissionSubscription>>({
            query: onCreateEntityResourcePermission,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handlPermissionUpdate(value.data?.onCreateEntityResourcePermission as EntityResourcePermission, StateContext.INSPECTION)
            },
        });
        updateSub = API.graphql<GraphQLSubscription<OnUpdateEntityResourcePermissionSubscription>>({
            query: onUpdateEntityResourcePermission,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handlPermissionUpdate(value.data?.onUpdateEntityResourcePermission as EntityResourcePermission, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
    }

    const handlPermissionUpdate = useRecoilCallback(({ set, snapshot }) => async (entityResourcePermission: EntityResourcePermission, context: StateContext) => {
        if (entityResourcePermission?.isGranted == false) {
            set(resourcePermissionAtomFamily(entityResourcePermission.resourceId!), (old: Set<PermissionType>) => {
                const newSet = new Set(old);
                newSet.delete(entityResourcePermission.permissionType! as PermissionType);
                return newSet;
            });
            return;
        }
        set(resourcePermissionAtomFamily(entityResourcePermission.resourceId!), (old: Set<PermissionType>) => {
            const newSet = new Set(old);
            newSet.add(entityResourcePermission.permissionType! as PermissionType);
            return newSet;
        });
    });

    const unsubscribe = () => {
        createSub?.unsubscribe();
        updateSub?.unsubscribe();
    }

    return { subscribe, unsubscribe }
}