import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Popover,
    Select
} from "@mui/material";
import {
    MeetingLinkType,
    NotificationChannel
} from "../../models";
import {
    MeetingStatus,
    SecondaryButton,
    useMeetingStatus
} from "amazon-chime-sdk-component-library-react";
import PhoneNumberInput, {
    isPossiblePhoneNumber
} from "react-phone-number-input/input";
import {
    useMemo,
    useState
} from "react";

import InsertLinkIcon from '@mui/icons-material/InsertLink';
import PhoneInputTextField from "../PhoneInputTextField";
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { graphQLShareMeetingLinkClient } from "../../lib/notification/GraphQLShareMeetingLinkClient";
import { meetingIdAtom } from "./state/ChimeStates";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useSnackbar } from "notistack";

export const ShareMeetingButton = () => {
    const { propertyId } = useParams<{ propertyId: string; }>();
    const meetingId = useRecoilValue(meetingIdAtom);
    const snackbar = useSnackbar();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [linkType, setLinkType] = useState<MeetingLinkType | undefined>();
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
    const meetingStatus = useMeetingStatus();
    const isMeetingActive = meetingStatus === MeetingStatus.Succeeded;
    const open = Boolean(anchorEl);

    const isPhoneNumberValid = useMemo(() => {
        if (!phoneNumber) {
            return true;
        }
        return isPossiblePhoneNumber(phoneNumber);
    }, [phoneNumber]);

    const closeMenu = () => {
        setAnchorEl(null);
        setLinkType(undefined);
        setPhoneNumber(undefined);
    };

    const getLink = (type: MeetingLinkType) => {
        let url;
        switch (type) {
            case MeetingLinkType.AGENT:
                url = new URL(`${window.location.origin}${window.location.pathname}`);
                if (meetingId) {
                    url.searchParams.set('meetingId', meetingId);
                }
                return url;
            case MeetingLinkType.INSPECTOR:
                url = new URL(`${window.location.origin}/remote/inspection`);
                if (meetingId) {
                    url.searchParams.set('meetingId', meetingId);
                }
                return url;
        }
    };

    return (
        <>
            <Button
                onClick={(e) => setAnchorEl(e.currentTarget)}
                disabled={!isMeetingActive}
                fullWidth
                variant="outlined"
            >
                Share Meeting
            </Button>
            <Popover
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box display="flex" flexDirection="column" gap={1} p={1} width="210px">
                    <FormControl fullWidth size="small">
                        <InputLabel id="link-type-label" sx={{ fontSize: 14 }}>Share to</InputLabel>
                        <Select
                            labelId="link-type-label"
                            value={linkType ?? ""}
                            label="Share to"
                            onChange={(e) => setLinkType(e.target.value as MeetingLinkType)}
                            sx={{ fontSize: 14 }}
                        >
                            <MenuItem value={MeetingLinkType.AGENT}>Agent</MenuItem>
                            <MenuItem value={MeetingLinkType.INSPECTOR}>Inspector</MenuItem>
                        </Select>
                    </FormControl>
                    <PhoneNumberInput
                        defaultCountry="US"
                        fullWidth
                        inputComponent={PhoneInputTextField}
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        error={!isPhoneNumberValid}
                        helperText={isPhoneNumberValid ? '' : 'Invalid phone number'}
                        variant="outlined"
                        size="small"
                        InputProps={{ sx: { fontSize: 14 } }}
                        InputLabelProps={{ sx: { fontSize: 14 } }}
                        placeholder="Enter phone number"
                    />
                    <Button
                        fullWidth
                        variant="outlined"
                        size="small"
                        onClick={async () => {
                            if (!phoneNumber || !isPhoneNumberValid || !linkType || !meetingId) {
                                return;
                            }
                            graphQLShareMeetingLinkClient.shareMeeting({
                                channel: NotificationChannel.SMS,
                                linkType,
                                meetingId,
                                recipients: [phoneNumber],
                                propertyId
                            }).then(() => {
                                snackbar.enqueueSnackbar('SMS sent', { variant: 'success' });
                            }).catch(() => {
                                snackbar.enqueueSnackbar('Failed to send SMS', { variant: 'error' });
                            });
                            snackbar.enqueueSnackbar('Sending SMS...', { variant: 'info' });
                            closeMenu();
                        }}
                        disabled={!phoneNumber || !isPhoneNumberValid}
                        startIcon={<SmsOutlinedIcon color={!phoneNumber || !isPhoneNumberValid ? 'disabled' : 'primary'} />}
                    >
                        Send SMS
                    </Button>
                    <Button
                        fullWidth
                        variant="outlined"
                        size="small"
                        onClick={(e) => {
                            if (!linkType) {
                                return;
                            }
                            const url = getLink(linkType);
                            navigator.clipboard.writeText(url.toString());
                            snackbar.enqueueSnackbar('Meeting URL copied to clipboard', { variant: 'success' });
                            closeMenu();
                        }}
                        disabled={!linkType}
                        startIcon={<InsertLinkIcon color={!linkType ? 'disabled' : 'primary'} />}
                    >
                        Copy Link
                    </Button>
                </Box>
            </Popover>
        </>
    );
};