import { makeStyles } from "@mui/styles";

const globalStyle = makeStyles((theme) => ({
    hidden: {
        display: "none"
    },
    /* Containers */
    managerLayout: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    containerWithBorder: {
        border: "1px solid #DDDDDD",
        borderRadius: "5px",
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        padding: "1em",
        textAlign: "center"
    },
    listContainerWithBorder: {
        border: "1px solid #ddd",
        borderRadius: "4px",
        backgroundColor: "#fff"
    },
    /* Buttons */
    imageCarouselButton: {
        color: "rgba(0,0,0,0.5)",
        backgroundColor: "rgba(255,255,255,0.8)",
        boxShadow: "0px 0px 2px 0px lightgrey",
        zIndex: 1,
        borderRadius: "5px",
        "&:hover": {
            color: "rgba(0,0,0,0.7)",
            backgroundColor: "white"
        }
    },
    squareIconButton: {
        color: "rgba(0,0,0,0.3)",
        backgroundColor: "rgba(0,0,0,0.05)",
        zIndex: 1,
        borderRadius: 5,
        "&:hover": {
            color: "rgba(0,0,0,0.3)",
            backgroundColor: "rgba(0,0,0,0.1)"
        }
    },
    smallSquareButton: {
        height: "36px",
        width: "36px",
    },
    mediumSquareButton: {
        height: "36.5px",
        width: "36.5px",
    },
    outlinedMenuButton: {
        color: "rgba(0,0,0,1)",
        borderColor: "rgba(200, 200, 200, 1)",
        borderWidth: "1px",
        borderStyle: "solid",
        backgroundColor: "rgba(240, 240, 240, 1)",
        zIndex: 1,
        borderRadius: "5px",
        "&:hover": {
            color: "rgba(0,0,0,0.7)",
            backgroundColor: "rgba(230, 230, 230, 1)",
        }
    },
    /* Texts */
    twoLiner: {
        lineHeight: "1.2em",
        height: "2.4em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical"
    },
    /* Icons */
    coloredIcon: {
        color: theme.palette.primary.main
    }
}));

export default globalStyle;
