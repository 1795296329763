import { GenericIngressCompletionChecker } from "../GenericIngressCompletionChecker";
import GraphQLProposalRecordDAOFactory from "../../../proposal/dao/record/GraphQLProposalRecordDAOFactory";
import { ProposalDAOToResourcePermissionMetadataDAOAdapter } from "../adapter/ProposalDAOToResourcePermissionMetadataDAOAdapter";

export class ProposalIngressCompletionCheckerFactory {
    private static INSTANCE = new GenericIngressCompletionChecker(
        new ProposalDAOToResourcePermissionMetadataDAOAdapter(
            GraphQLProposalRecordDAOFactory.getInstance()
        )
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}