import {
    atomFamily,
    selectorFamily
} from "recoil";
import { Location } from "../../../models";

export const locationFormValueAtomFamily = atomFamily<Location | undefined, string>({
    key: "locationFormValueAtom",
    default: undefined
});

export const locationFormValidationMapAtomFamily = atomFamily<Map<keyof Location, boolean>, string>({
    key: "locationFormValidationMapAtomFamily",
    default: new Map<keyof Location, boolean>()
});

export const locationFormIsValidSelectorFamily = selectorFamily<boolean, string>({
    key: "locationFormIsValidSelectorFamily",
    get: (id: string) => ({ get }) => {
        const currentLocationFromValidationMap: Map<keyof Location, boolean> = get(locationFormValidationMapAtomFamily(id));
        return currentLocationFromValidationMap.get("name") === true;
    }
});
