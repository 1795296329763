import {
    TYPOGRAPHY_ATTRIBUTE_LABEL,
    TYPOGRAPHY_ATTRIBUTE_VALUE
} from "../../general/typography/TypographyStyles";

import { grey } from "@mui/material/colors";
import theme from "../../../assets/style/theme";

/* Font styles */

export const WORD_FONT = {
    // MUI theme default fontFamily is "Roboto", which does not exist in Outlook
    fontFamily: "Arial",
    color: grey[800]
};

// MUI theme default is in 'rem' units, which do not copy over to Word
export const WORD_TITLE = {
    ...WORD_FONT,
    color: theme.palette.primary.dark,
    fontSize: "25pt" // ~33px
};

export const WORD_SUBTITLE1 = {
    ...WORD_FONT,
    fontSize: "20pt" // ~27px
};

export const WORD_SUBTITLE2 = {
    ...WORD_FONT,
    fontSize: "14pt" // ~19px
};

export const WORD_ISSUE_TITLE = {
    ...WORD_SUBTITLE1,
    color: theme.palette.primary.dark
};

export const WORD_ISSUE_PRICE = {
    ...WORD_SUBTITLE2,
    color: theme.palette.primary.dark
};

export const WORD_WORK_SPECIFICATION_TITLE = {
    ...WORD_SUBTITLE2,
    color: theme.palette.primary.main
};

export const WORD_TENDER_DETAIL_VALUE = {
    ...WORD_FONT,
    fontSize: "10pt"
};

export const WORD_PROPOSAL_METADATA = {
    ...WORD_FONT,
    fontSize: "11pt",
    color: grey[600]
};

export const WORD_ATTRIBUTE_LABEL = {
    ...TYPOGRAPHY_ATTRIBUTE_LABEL,
    fontFamily: "Calibri, sans-serif",
};

export const WORD_ATTRIBUTE_VALUE = {
    ...TYPOGRAPHY_ATTRIBUTE_VALUE,
    ...WORD_FONT
    // Using color from WORD_FONT_FAMILY
};
