import {
    FormikValues,
    useFormik
} from "formik";
import {
    Grid,
    Typography
} from "@mui/material";

import LocationSearchBox from "../../../componentsV2/location/LocationSearchBox";
import PropertySearchBox from "../../../componentsV2/property/PropertySearchBox";
import { BrandingSelector } from "../theme/brand/BrandingSelector";

type ExternalReportVariablesFormProps<T extends FormikValues> = {
    formik: ReturnType<typeof useFormik<T>>;
};

export const ExternalReportVariablesForm = <T extends FormikValues>(props: ExternalReportVariablesFormProps<T>) => {
    const { formik } = props;

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6">Configuration</Typography>
            </Grid>
            <Grid item xs={12}>
                <PropertySearchBox
                    name="variables.propertyId"
                    id="variables.propertyId"
                    label="Link to Property"
                    onClear={() => {
                        formik.setFieldValue("variables.propertyId", undefined);
                        formik.setFieldValue("variables.propertyAddress", undefined);
                    }}
                    onSelect={(property) => {
                        formik.setFieldValue("variables.propertyId", property?.id);
                        formik.setFieldValue("variables.propertyAddress", property?.address);
                    }}
                    initialPropertyId={formik.values.variables?.propertyId}
                    error={Boolean((formik.errors.variables as any)?.propertyId)}
                    helperText={(formik.errors.variables as any)?.propertyId}
                />
            </Grid>
            <Grid item xs={12}>
                <LocationSearchBox
                    name="variables.locationId"
                    id="variables.locationId"
                    propertyId={formik.values.variables?.propertyId}
                    label="Link to Group (Optional)"
                    onClear={() => {
                        formik.setFieldValue("variables.locationId", undefined);
                        formik.setFieldValue("variables.locationName", undefined);
                    }}
                    onSelect={(location) => {
                        formik.setFieldValue("variables.locationId", location?.id);
                        formik.setFieldValue("variables.locationName", location?.name);
                    }}
                    disabled={formik.values.variables?.propertyId == undefined}
                    initialLocationId={formik.values.variables?.locationId}
                    error={Boolean((formik.errors.variables as any)?.locationId)}
                    helperText={(formik.errors.variables as any)?.locationId}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Branding</Typography>
            </Grid>
            <Grid item xs={12}>
                <BrandingSelector
                    formik={formik}
                />
            </Grid>
        </>
    );
};