import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    Menu,
    Switch,
    Tooltip,
    Typography
} from "@mui/material";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import { BLACK_OUTLINED_BUTTON } from "../../../components/general/button/ButtonStyles";
import { ChildExpansionMode } from "../../../lib/design/document/state/enum/ChildExpansionMode";
import { ChildrenExpandState } from "../../../lib/design/document/state/enum/ChildrenExpandState";
import { ExpandAllSwitch } from "../../location/ExpandAllSwitch";
import { HamburgerIcon } from "../../../components/icons";
import { IssueFeatureConfiguration } from "../../../lib/design/document/state/IssueFeatureConfiguration";
import { IssueFilter } from "./IssueFilter";
import { SortDirection } from "../../../lib/sorting/SortDirection";
import { StateContext } from "../../../lib/design/document/state/StateContext";
import { ValidationSwitch } from "../../location/ValidationSwitch";
import { childExpansionModeByContextAtomFamily } from "../../../lib/design/document/state/ComponentExpandState";
import { documentSortDirectionAtom } from "../../../lib/design/document/state/DocumentSortState";
import { isFilterAppliedAtom } from "../../../lib/issue/state/IssueFilterRecoilState";
import { issueFeatureConfigurationByContextAtomFamily } from "../../../lib/design/document/state/DocumentState";
import { useSetChildrenExpandState } from "../../../lib/design/hooks/useSetChildrenExpandState";
import { useState } from "react";

type IssueSettingsProps = {
    size?: "small" | "medium" | "large";
    context: StateContext;
};

const IssueSettings = (props: IssueSettingsProps) => {
    /* Internal page states */
    const { size, context } = props;
    const isFilterApplied = useRecoilValue<boolean>(isFilterAppliedAtom);
    const issueFeatureConfig = useRecoilValue<IssueFeatureConfiguration>(issueFeatureConfigurationByContextAtomFamily(context));
    const [sortDirection, setSortDirection] = useRecoilState<SortDirection>(documentSortDirectionAtom)

    /* Menu states */
    const [childExpansionMode, setChildExpansionMode] = useRecoilState<ChildExpansionMode>(childExpansionModeByContextAtomFamily(context));
    const { setChildrenExpandStateForAllLocations } = useSetChildrenExpandState(context);

    /* Menu operations */
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const onCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                size={size ?? "medium"}
                onClick={onOpenMenu}
                startIcon={<HamburgerIcon primary="primary" />}
                variant={issueFeatureConfig.allowFiltering && isFilterApplied ? "contained" : "outlined"}
                sx={{ ...BLACK_OUTLINED_BUTTON }}
            >
                Settings
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={onCloseMenu}
            >
                <Box p={1} pt={0}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                        <Typography variant="h6">View settings</Typography>
                    </Box>
                    <Box ml={1} display="flex" flexDirection="column">
                        <ExpandAllSwitch context={context} />
                        {issueFeatureConfig.allowValidationToggle && <ValidationSwitch context={context} />}
                        <FormControlLabel
                            sx={{
                                justifyContent: "space-between",
                                ml: 0
                            }}
                            control={(
                                <Switch
                                    checked={childExpansionMode === ChildExpansionMode.FOCUS}
                                    onChange={(event, checked) => {
                                        setChildExpansionMode(checked ? ChildExpansionMode.FOCUS : ChildExpansionMode.FREE);
                                        if (checked) {
                                            setChildrenExpandStateForAllLocations(ChildrenExpandState.COLLAPSE_CHILDREN);
                                        }
                                    }}
                                />
                            )}
                            label={(
                                <Tooltip title={"Expand one thing at a time."} placement="right-start">
                                    <Typography>
                                        Focus Mode
                                    </Typography>
                                </Tooltip>
                            )}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            sx={{
                                justifyContent: "space-between",
                                ml: 0
                            }}
                            control={(
                                <Switch
                                    checked={sortDirection === SortDirection.ASCENDING}
                                    onChange={(event, checked) => {
                                        setSortDirection(checked ? SortDirection.ASCENDING : SortDirection.DESCENDING);
                                    }}
                                />
                            )}
                            label={(
                                <Tooltip title={"Order issues and locations in reverse order."} placement="right-start">
                                    <Typography>
                                        Reverse Issue & Group Order
                                    </Typography>
                                </Tooltip>
                            )}
                            labelPlacement="start"
                        />
                    </Box>
                </Box>
                {issueFeatureConfig.allowFiltering && (
                    <>
                        <Divider />
                        <Box p={1}>
                            <Typography variant="h6">Filter settings</Typography>
                            <Box ml={1} mt={1}>
                                <IssueFilter />
                            </Box>
                        </Box>
                    </>
                )}
            </Menu>
        </>
    );
};

export default IssueSettings;
