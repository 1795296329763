import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="m22.439 19.439-7.413-7.413a7.5 7.5 0 1 0-3 3l7.413 7.413c.414.414.957.621 1.5.621s1.086-.207 1.5-.621a2.12 2.12 0 0 0 0-3zm-14-4.5c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm6.031-2.055 1.576 1.576-1.586 1.586-1.576-1.576a7.547 7.547 0 0 0 1.586-1.586zm7.262 8.848c-.212.212-.493.328-.793.328s-.581-.117-.793-.328l-5.087-5.087 1.586-1.586 5.087 5.087c.212.212.328.493.328.793s-.116.581-.328.793z"
        className="primary"
      />
      <path
        fill="currentColor"
        d="M8.439 2.939a5.5 5.5 0 1 0 0 11 5.5 5.5 0 1 0 0-11zm0 10c-2.481 0-4.5-2.019-4.5-4.5s2.019-4.5 4.5-4.5 4.5 2.019 4.5 4.5-2.018 4.5-4.5 4.5z"
        className="primary"
      />
    </g>
  </svg>
);
export default withCustomIcon(SearchIcon);
