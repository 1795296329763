export default class ResourceNotFoundError extends Error {

    private resourceType: string;

    constructor(resourceType: string) {
        super(resourceType + " was not found");
        this.resourceType = resourceType;
        Object.setPrototypeOf(this, ResourceNotFoundError.prototype);
    }

    getResourceType(): string {
        return this.resourceType;
    }
}