import {
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    List,
    ListRowRenderer,
    WindowScroller
} from "react-virtualized";
import {
    Box,
    useMediaQuery
} from "@mui/material";
import {
    offlinePropertyIdListAtom,
    propertyIdsToDisplaySelector,
    recentlyCreatedPropertyIdListAtom
} from "../../lib/property/state/PropertyRecoilState";

import CreatePropertyCard from "./CreatePropertyCard";
import PropertyCard from "./PropertyCard";
import { shouldPinOfflinePropertiesAtom } from "../../lib/property/state/PropertySortRecoilState";
import theme from "../../assets/style/theme";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";

const ListProperty = () => {
    const propertyIds = useRecoilValue<Array<string>>(propertyIdsToDisplaySelector);

    const isLargerThanSM = useMediaQuery(theme.breakpoints.up("sm"));
    const isLargerThanMD = useMediaQuery(theme.breakpoints.up("md"));
    const isLargerThanLG = useMediaQuery(theme.breakpoints.up("lg"));
    const offlinePropertyIds = useRecoilValue<Array<string>>(offlinePropertyIdListAtom);
    const recentlyCreatedPropertyIds = useRecoilValue<Array<string>>(recentlyCreatedPropertyIdListAtom);
    const shouldPinOfflineProperties = useRecoilValue<boolean>(shouldPinOfflinePropertiesAtom);

    const availablePropertyIds = useMemo(() => {
        return [...offlinePropertyIds, ...recentlyCreatedPropertyIds];
    }, [offlinePropertyIds, recentlyCreatedPropertyIds]);

    const itemWidth = useMemo(() => {
        if (isLargerThanLG) {
            return 440;
        }
        if (isLargerThanMD) {
            return 440;
        }
        if (isLargerThanSM) {
            return 350;
        }
        return 350;
    }, [isLargerThanLG, isLargerThanMD, isLargerThanSM]);

    const getMaxItemsPerRow = (rowWidth: number) => {
        //rowWidth can be smaller than item width, in that case always render one item per row
        return Math.max(Math.floor(rowWidth / itemWidth), 1);
    };

    const getRowCount = (rowWidth: number) => {
        return Math.ceil((propertyIds.length + 1) / getMaxItemsPerRow(rowWidth)); // +1 to account for create property card
    };

    const cellMeasurerCache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 255,
    });

    const getPropertyIdsForRow = (rowWidth: number, rowIndex: number) => {
        let propertyIdsToDisplay: Array<string> = [];
        // Need to ensure that the Synchronized properties are in the "PropertyIds" list so that Search filters apply to them
        if (shouldPinOfflineProperties) {
            const availablePropertyIdsInPropertyList = propertyIds.filter(id => availablePropertyIds.includes(id));
            const unavailablePropertyIds = propertyIds.filter(id => !availablePropertyIds.includes(id));
            propertyIdsToDisplay = [...availablePropertyIdsInPropertyList, ...unavailablePropertyIds];
        } else {
            propertyIdsToDisplay = propertyIds;
        }

        const maxItemsPerRow = getMaxItemsPerRow(rowWidth);
        const startIndex = Math.max(rowIndex * maxItemsPerRow - 1, 0); // -1 to encounter create property card
        const endIndex = Math.min(startIndex + maxItemsPerRow - (rowIndex === 0 ? 1 : 0), propertyIdsToDisplay.length);
        const result = [];
        for (let i = startIndex; i < endIndex; i++) {
            result.push(i);
        }
        return result.map(index => propertyIdsToDisplay[index]);
    };

    const renderRow: (width: number) => ListRowRenderer = (width: number) => {
        return ({ index, style, key, parent }) => {
            const propertyIdsForRow = getPropertyIdsForRow(width, index);
            const maxItemsPerRow = getMaxItemsPerRow(width);
            return (
                <CellMeasurer
                    key={key}
                    cache={cellMeasurerCache}
                    parent={parent}
                    rowIndex={index}
                    columnIndex={0}
                >
                    <Box
                        style={style}
                        key={key}
                        display="flex"
                        justifyContent="space-around"
                        alignItems="center"
                        gap={1.5}
                        pb={2}
                    >
                        {index === 0 && (
                            <Box width={itemWidth}>
                                <CreatePropertyCard />
                            </Box>
                        )}
                        {propertyIdsForRow.map((id) => (
                            <Box
                                key={id}
                                width={itemWidth}
                            >
                                <PropertyCard
                                    key={`${availablePropertyIds.includes(id)}`} // key is needed to force rerender when property becomes available
                                    isAvailable={availablePropertyIds.includes(id)}
                                    isRecentlyCreated={recentlyCreatedPropertyIds.includes(id)}
                                    propertyId={id}
                                />
                            </Box>
                        ))}
                        {/** Render empty boxes to fit the layout */}
                        {Array(maxItemsPerRow - propertyIdsForRow.length - (index === 0 ? 1 : 0)).fill(undefined).map((value, index) => (
                            <Box
                                key={index}
                                width={itemWidth}
                                visibility="hidden"
                            />
                        ))}
                    </Box>
                </CellMeasurer>
            );
        };
    };

    return (
        <AutoSizer>
            {({ width }) => (
                <WindowScroller>
                    {({ height, scrollTop }) => {
                        return (
                            <List
                                autoHeight
                                height={height}
                                width={width}
                                scrollTop={scrollTop}
                                rowHeight={cellMeasurerCache.rowHeight}
                                rowRenderer={renderRow(width)}
                                rowCount={getRowCount(width)}
                            />
                        );
                    }}
                </WindowScroller>
            )}
        </AutoSizer>
    );
};

export default ListProperty;
