import { Attachment } from "../../../models";
import { AttachmentContent } from "../content/AttachmentContent";
import AttachmentContentDAO from "../content/AttachmentContentDAO";
import CachingAttachmentContentDAOFactory from "../content/cache/CachingAttachmentContentDAOFactory";
import { encode as arrayBufferToBase64 } from "base64-arraybuffer";

const attachmentContentDAO: AttachmentContentDAO = CachingAttachmentContentDAOFactory.getInstance();

export const useFetchAttachmentImageUrl = () => {
    const fetchAttachmentImageUrl = async (attachment: Attachment) => {
        return fetchAttachmentImageUrlByAttachmentKey(attachment.key!)
    };

    const fetchAttachmentImageUrlByAttachmentKey = async (attachmentKey: string) => {
        const attachmentContent: AttachmentContent = await attachmentContentDAO.getAttachmentContentByAttachmentKey(attachmentKey);
        return `data:image/png;base64,${arrayBufferToBase64(attachmentContent.data)}`;
    }

    return { fetchAttachmentImageUrl, fetchAttachmentImageUrlByAttachmentKey};
}

