export default class AttachmentRecordMetricNames {
    static CREATE_ATTEMPT = "AttachmentRecordCreateAttempt";
    static CREATE_FAILURE ="AttachmentRecordCreateFailure";
    static DELETE_ATTEMPT = "AttachmentRecordDeleteAttempt";
    static DELETE_FAILURE ="AttachmentRecordDeleteFailure";
    static GET_BY_ID_ATTEMPT = "GetAttachmentRecordByIdAttempt";
    static GET_BY_ID_FAILURE ="GetAttachmentRecordByIdFailure";
    static LIST_BY_PARENT_ID_ATTEMPT = "ListAttachmentRecordsByParentIdAttempt";
    static LIST_BY_PARENT_ID_FAILURE = "ListAttachmentRecordsByParentIdFailure";
    static LIST_MOST_RECENT_BY_PARENT_ID_ATTEMPT = "ListMostRecentAttachmentRecordsByParentIdAttempt";
    static LIST_MOST_RECENT_BY_PARENT_ID_FAILURE = "ListMostRecentAttachmentRecordsByParentIdFailure";
    static LIST_MOST_RECENT_BY_PARENT_ID_WITH_LIMIT_ATTEMPT = "ListMostRecentAttachmentRecordsByParentIdWithLimitAttempt";
    static LIST_MOST_RECENT_BY_PARENT_ID_WITH_LIMIT_FAILURE = "ListMostRecentAttachmentRecordsByParentIdWithLimitFailure";
    static LF_CREATE_ATTEMPT = "LocalForageAttachmentRecordCreateAttempt";
    static LF_CREATE_FAILURE ="LocalForageAttachmentRecordCreateFailure";
    static LF_DELETE_ATTEMPT = "LocalForageAttachmentRecordDeleteAttempt";
    static LF_DELETE_FAILURE ="LocalForageAttachmentRecordDeleteFailure";
    static LF_GET_BY_ID_ATTEMPT = "LocalForageGetAttachmentRecordByIdAttempt";
    static LF_GET_BY_ID_FAILURE ="LocalForageGetAttachmentRecordByIdFailure";
}
