import {
    PermissionRestrictedControl,
    PermissionRestrictedControlProps
} from "./PermissionRestrictedControl";

import { StateContext } from "../../../lib/design/document/state/StateContext";
import { useMemo } from "react";

type SolutionAuthoringEnabledPermissionRestrictedControlProps = Omit<PermissionRestrictedControlProps, "permissionOverridden"> & {
    context: StateContext;
};

export const SolutionAuthoringEnabledPermissionRestrictedControl = (props: SolutionAuthoringEnabledPermissionRestrictedControlProps) => {
    const { context, children, ...permissionRestrictedControlProps } = props;

    const isPermittedToEditSolution = useMemo(() => {
        if (context === StateContext.SOLUTION_AUTHORING_CONTENT) {
            return true;
        }
    }, []);

    return (
        <PermissionRestrictedControl
            {...permissionRestrictedControlProps}
            permissionOverridden={isPermittedToEditSolution}
        >
            {children}
        </PermissionRestrictedControl>
    );
};