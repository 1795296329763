import { DataStore } from "aws-amplify";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import PropertyDAO from "../PropertyDAO";
import DataStorePropertyDAO from "./DataStorePropertyDAO";

export default class DataStorePropertyDAOFactory {
    private static readonly INSTANCE: PropertyDAO = new DataStorePropertyDAO(
        DataStore,
        ClientLoggerFactory.getClientLogger("DataStorePropertyDAO"),
    );

    public static getInstance(): PropertyDAO {
        return this.INSTANCE;
    }
}
