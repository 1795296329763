import {
    Box,
    useMediaQuery
} from "@mui/material";
import {
    Dispatch,
    SetStateAction
} from "react";

import { ISSUE_PAGINATION_GROUP_ID_PREFIX } from "../../lib/pagination/Constants";
import { PaginationBar } from "../general/pagination/PaginationBar";
import { issuesPerPageAtom } from "../../lib/design/document/state/DocumentState";
import theme from "../../assets/style/theme";
import { useRecoilValue } from "recoil";

type IssueManagerPaginationControlProps = {
    readonly locationId: string;
    readonly issueIds: Array<string>;
    readonly setIssueIdsToDisplay: Dispatch<SetStateAction<Array<string>>>;
};

export const IssueManagerPaginationControl = (props: IssueManagerPaginationControlProps) => {
    const { locationId, issueIds, setIssueIdsToDisplay } = props;
    const isScreenSizeXs = useMediaQuery(theme.breakpoints.down("sm"));
    const issuesPerPage = useRecoilValue<number>(issuesPerPageAtom);

    return (
        <Box
            sx={{
                transform: {
                    sm: "translate(-50%)",
                },
                position: {
                    sm: "absolute",
                },
                left: {
                    sm: "50%",
                }
            }}
        >
            <PaginationBar
                size={isScreenSizeXs ? "small" : "medium"}
                groupId={ISSUE_PAGINATION_GROUP_ID_PREFIX + locationId}
                items={issueIds}
                itemsPerPage={issuesPerPage}
                setItemsToDisplay={setIssueIdsToDisplay}
            />
        </Box>
    );
};