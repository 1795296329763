import {
    Issue,
    SolutionTenderingType,
    WorkSpecification
} from "../../../../models";
import {
    RecoilValue,
    atom,
    selector,
    selectorFamily
} from "recoil";
import {
    filteredIssuesByContextSelectorFamily,
    issueIdsByLocationIdentifierAtomFamily,
    locationIdsByContextAtomFamily,
    solutionMetadataAtom,
    workSpecificationByWorkSpecificationIdentifierSelectorFamily,
    workSpecificationIdsByIssueIdentifierSelectorFamily
} from "./DocumentState";
import {
    proposalItemByProposalItemIdentifierSelectorFamily,
    proposalItemIdByWorkSpecificationIdentifierAtomFamily
} from "../../bidding/state/v2/ProposalItemStates";

import { ContextAwareIdentifier } from "../ContextAwareIdentifier";
import DefaultMeasurementValidatorFactory from "../../../dimension/v2/DefaultMeasurementValidatorFactory";
import MeasurementValidator from "../../../dimension/v2/MeasurementValidator";
import { ModelType } from "../ModelType";
import ProposalItem from "../../bidding/ProposalItem";
import { SOLUTION_METADATA_VALIDATOR } from "../../../solution/metadata/SolutionMetadataValidator";
import { StateContext } from "./StateContext";
import ValidationResult from "../../../util/validation/ValidationResult";

const STATE_CONTEXTS_TO_VALIDATE: Array<StateContext> = [StateContext.SOLUTION_AUTHORING_CONTENT];

const measurementValidator: MeasurementValidator = DefaultMeasurementValidatorFactory.getInstance();

export const isDocumentValidSelector = selector<boolean>({
    key: "isDocumentValidSelector",
    get: ({ get }) => {
        const solutionMetadata = get(solutionMetadataAtom);
        const isSolutionMetadataValid = SOLUTION_METADATA_VALIDATOR.isValidSync(solutionMetadata);
        const areLocationsValid = get(areLocationsValidByContextSelectorFamily(StateContext.SOLUTION_AUTHORING_CONTENT));
        const locationIds: Array<string> = get(locationIdsByContextAtomFamily(StateContext.SOLUTION_AUTHORING_CONTENT));
        return isSolutionMetadataValid && locationIds.length > 0 && areLocationsValid;
    }
});

export const isValidationEnabledAtom = atom<boolean>({
    key: "isValidationEnabledAtom",
    default: false
})

//Use for finding overall validation result of design elements and its children
export const validationResultByDesignElementIdentifierSelectorFamily: (param: ContextAwareIdentifier) => RecoilValue<ValidationResult> = selectorFamily<ValidationResult, ContextAwareIdentifier>({
    key: "validationResultByDesignElementIdentifierSelectorFamily",
    get: (identifier: ContextAwareIdentifier) => ({ get }) => {
        const solutionTenderingType: SolutionTenderingType = get(solutionMetadataAtom).tenderingType as SolutionTenderingType;
        if (!STATE_CONTEXTS_TO_VALIDATE.includes(identifier.context) && !get(isValidationEnabledAtom)) {
            return { isValid: true };
        }

        if (identifier.modelType === ModelType.PROPOSAL_ITEM) {
            if (solutionTenderingType == SolutionTenderingType.TENDERED) {
                return { isValid: true };
            }
            const proposalItem: ProposalItem | undefined = get(proposalItemByProposalItemIdentifierSelectorFamily(identifier));
            if (!proposalItem) {
                return { isValid: true };
            }
            const isPriceValid: boolean = proposalItem.priceBeforeAdjustment != null && proposalItem.priceBeforeAdjustment >= 0;
            return {
                isValid: isPriceValid,
                message: isPriceValid ? "" : "Price must be positive."
            };
        }

        if (identifier.modelType === ModelType.WORK_SPECIFICATION) {
            const workSpecification: WorkSpecification | null = get(workSpecificationByWorkSpecificationIdentifierSelectorFamily(identifier));
            if (!workSpecification) {
                return { isValid: true };
            }
            const isWorkSpecificationValid = workSpecification.measurement && measurementValidator.isValid(workSpecification.measurement);
            if (!isWorkSpecificationValid) {
                return {
                    isValid: false,
                    message: "Measurement is not valid."
                };
            }
            if (solutionTenderingType == SolutionTenderingType.TENDERED) {
                return { isValid: true };
            }
            const proposalItemId: string | null = get(proposalItemIdByWorkSpecificationIdentifierAtomFamily(identifier));
            if (!proposalItemId) {
                return { isValid: true };
            }
            const proposalItemIdentifier = new ContextAwareIdentifier(proposalItemId, identifier.context, ModelType.PROPOSAL_ITEM);
            return get(validationResultByDesignElementIdentifierSelectorFamily(proposalItemIdentifier));
        }

        if (identifier.modelType === ModelType.ISSUE) {
            const workSpecificationIds: Array<string> = get(workSpecificationIdsByIssueIdentifierSelectorFamily(identifier));
            const haveChildrenElements: boolean = !!workSpecificationIds && workSpecificationIds.length > 0;
            if (!haveChildrenElements) {
                return {
                    isValid: false,
                    message: "Issue must have at least one work specification."
                };
            }
            const areWorkSpecificationsValid: boolean = workSpecificationIds!.reduce((prev: boolean, workSpecificationId) => {
                const dimensionIdentifier = new ContextAwareIdentifier(workSpecificationId, identifier.context, ModelType.WORK_SPECIFICATION);
                return prev && get(validationResultByDesignElementIdentifierSelectorFamily(dimensionIdentifier)).isValid;
            }, true);
            return {
                isValid: areWorkSpecificationsValid,
                message: areWorkSpecificationsValid ? "" : "There is at least one invalid work specification."
            };
        }

        if (identifier.modelType === ModelType.LOCATION) {
            const issueIds: Array<string> = get(issueIdsByLocationIdentifierAtomFamily(identifier));
            const haveChildrenElements: boolean = !!issueIds && issueIds.length > 0;
            if (!haveChildrenElements) {
                return {
                    isValid: false,
                    message: "Groups must have at least one issue."
                };
            }

            const areIssuesValid: boolean = issueIds!.reduce((prev: boolean, issueId) => {
                const issueIdentifier = new ContextAwareIdentifier(issueId, identifier.context, ModelType.ISSUE);
                return prev && get(validationResultByDesignElementIdentifierSelectorFamily(issueIdentifier)).isValid;
            }, true);
            return {
                isValid: areIssuesValid,
                message: areIssuesValid ? "" : "There is at least one invalid issue."
            };
        }

        return { isValid: true };
    }
});

export const areVisibleIssuesValidByContextSelectorFamily = selectorFamily<boolean, StateContext>({
    key: "areVisibleIssuesValidByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const visibleIssueIds: Array<Issue> = get(filteredIssuesByContextSelectorFamily(context))
        return visibleIssueIds.map(issue => issue.id).reduce((prev: boolean, issueId: string) => {
            const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
            return prev && get(validationResultByDesignElementIdentifierSelectorFamily(issueIdentifier)).isValid
        }, true)
    }
})

export const areLocationsValidByContextSelectorFamily = selectorFamily<boolean, StateContext>({
    key: "areLocationsValidByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const locationIds: Array<string> = get(locationIdsByContextAtomFamily(context));
        return locationIds.reduce((prev: boolean, locationId) => {
            const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
            return prev && get(validationResultByDesignElementIdentifierSelectorFamily(locationIdentifier)).isValid;
        }, true);
    }
});
