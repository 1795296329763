import { atom } from "recoil";

export const isUserAdminAtom = atom<boolean>({
    key: "isUserAdminAtom",
    default: false
});

export const tokenExpireEpochMillisAtom = atom<number | undefined>({
    key: "tokenExpireEpochMillisAtom",
    default: undefined
})
