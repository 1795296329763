import ClientLogger from "../../../logging/ClientLogger";
import { DataStoreSyncResult } from "../DataStoreSynchronizationConstants";
import { HubCapsule } from "@aws-amplify/core";
import { HubClass } from "@aws-amplify/core/lib-esm/Hub";

export class DataStoreCompletionEventListener {
    private static DATASTORE_CHANNEL = "datastore";
    private static DATASTORE_READY_EVENT = "ready";
    private static DATASTORE_NETWORK_STATUS_EVENT = "networkStatus";
    private static DATASTORE_NETWORK_EVENT = "DataStoreNetworkEvent";
    private static DATASTORE_SYNC_SUCCESS = "DataStoreSyncSuccess";
    private static DATASTORE_SYNC_FAILURE = "DataStoreSyncFailure";
    private hub: HubClass;
    private logger: ClientLogger;

    constructor(
        hub: HubClass,
        logger: ClientLogger
    ) {
        this.hub = hub;
        this.logger = logger;
    }

    public async waitForDataStoreReady(): Promise<DataStoreSyncResult> {
        const result = new Promise<DataStoreSyncResult>((resolve, reject) => {
            const endListener = this.hub.listen(DataStoreCompletionEventListener.DATASTORE_CHANNEL, (data: HubCapsule) => {
                this.logger.warn(
                    "DataStoreCompletionEventListener received event: " + data.payload.event,
                    data.payload.data,
                    [DataStoreCompletionEventListener.DATASTORE_NETWORK_EVENT]
                )
                if(data.payload.event === DataStoreCompletionEventListener.DATASTORE_READY_EVENT) {
                    resolve(DataStoreSyncResult.SUCCESS);
                    this.logger.warn(
                        "DataStoreCompletionEventListener Sync Success",
                        {},
                        [DataStoreCompletionEventListener.DATASTORE_SYNC_SUCCESS]
                    )
                    endListener();
                }
                if(data.payload.event === DataStoreCompletionEventListener.DATASTORE_NETWORK_STATUS_EVENT) {
                    if (!data.payload.data.active) {
                        this.logger.warn(
                            "DataStoreCompletionEventListener Sync Failure",
                            {},
                            [DataStoreCompletionEventListener.DATASTORE_SYNC_FAILURE]
                        )
                        resolve(DataStoreSyncResult.FAILURE);
                        endListener();
                    }
                }
            });
        });
        return result;
    }
}