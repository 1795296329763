import { ResourcePermissionMetadata } from "../ResourcePermissionMetadata";
import { ResourcePermissionMetadataDAO } from "../../dao/ResourcePermissionMetadataDAO";
import SolutionDAO from "../../../solution/majorVersion/dao/SolutionDAO";

export class SolutionDAOToResourcePermissionMetadataDAOAdapter implements ResourcePermissionMetadataDAO{
    private solutionDAO: SolutionDAO;

    constructor (
        solutionDAO: SolutionDAO
    ) {
        this.solutionDAO = solutionDAO;
    }

    getResourcePermissionMetadata(resourceId: string): Promise<ResourcePermissionMetadata> {
        return this.solutionDAO.getById(resourceId);
    }
}