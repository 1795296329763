import {
    ControlBarButton,
    Microphone,
    MicrophoneActivity,
    PopOverItem,
    PopOverSeparator,
    Spinner,
    isOptionActive,
    useAudioInputs,
    useAudioVideo,
    useMeetingManager,
    useToggleLocalMute,
    useVoiceFocus
} from "amazon-chime-sdk-component-library-react";
import {
    useEffect,
    useState
} from "react";

import { BaseSdkProps } from "amazon-chime-sdk-component-library-react/lib/components/sdk/Base";
import { DeviceType } from "amazon-chime-sdk-component-library-react/lib/types";
import { VoiceFocusTransformDevice } from "amazon-chime-sdk-js";
import _ from "lodash";

type CustomAudioInputVFControlProps = BaseSdkProps & {
    /** The label that will be shown when microphone is muted, it defaults to `Mute`. */
    muteLabel?: string;
    /** The label that will be shown when microphone is unmuted, it defaults to `Unmute`. */
    unmuteLabel?: string;
    /** Title attribute for the icon when muted, it defaults to `Muted microphone` in <Microphone />. */
    mutedIconTitle?: string;
    /** Title attribute for the icon when unmuted, it defaults to `Microphone` in <Microphone />. */
    unmutedIconTitle?: string;
    /** The label that will be shown when the current input audio is an Amazon Voice Focus device,
     *  it defaults to `Amazon Voice Focus enabled`. */
    voiceFocusOnLabel?: string;
    /** The label that will be shown when the current input audio is not an Amazon Voice Focus device,
     *  it defaults to `Enable Amazon Voice Focus`. */
    voiceFocusOffLabel?: string;
};

export const CustomAudioInputVFControl = (props: CustomAudioInputVFControlProps) => {
    const {
        muteLabel = 'Mute',
        unmuteLabel = 'Unmute',
        mutedIconTitle,
        unmutedIconTitle,
        voiceFocusOnLabel = 'Amazon Voice Focus enabled',
        voiceFocusOffLabel = 'Enable Amazon Voice Focus',
        ...rest
    } = props;
    const audioVideo = useAudioVideo();
    const meetingManager = useMeetingManager();
    const [isLoading, setIsLoading] = useState(false);
    // When the user click on Amazon Voice Focus option, the state will change.
    const [isVoiceFocusChecked, setIsVoiceFocusChecked] = useState(false);
    // Only when the current input audio device is an Amazon Voice Focus device,
    // the state will be true. Otherwise, it will be false.
    const [isVoiceFocusEnabled, setIsVoiceFocusEnabled] = useState(false);
    const [dropdownWithVFOptions, setDropdownWithVFOptions] = useState<JSX.Element[] | null>(null);
    const { muted, toggleMute } = useToggleLocalMute();
    const { isVoiceFocusSupported, addVoiceFocus } = useVoiceFocus();
    const { devices, selectedDevice } = useAudioInputs();
    const [audioInputDevices, setAudioInputDevices] = useState<Array<DeviceType>>(devices);

    useEffect(() => {
        setAudioInputDevices(prev => {
            if (_.isEqual(prev, devices)) {
                return prev;
            }
            return devices;
        });
    }, [devices]);

    useEffect(() => {
        console.info(`Amazon Voice Focus is ${isVoiceFocusEnabled ? 'enabled' : 'disabled'}.`);
    }, [isVoiceFocusEnabled]);

    useEffect(() => {
        // Only when the current input audio device is an Amazon Voice Focus transform device,
        // Amazon Voice Focus button will be checked.
        if (selectedDevice instanceof VoiceFocusTransformDevice) {
            setIsVoiceFocusEnabled(true);
        }
        else {
            setIsVoiceFocusEnabled(false);
        }
        return () => {
            if (selectedDevice instanceof VoiceFocusTransformDevice) {
                selectedDevice.stop();
            }
        };
    }, [selectedDevice]);

    useEffect(() => {
        if (!audioVideo) {
            return;
        }
        if (selectedDevice instanceof VoiceFocusTransformDevice &&
            isVoiceFocusEnabled) {
            selectedDevice.observeMeetingAudio(audioVideo);
        }
    }, [audioVideo, isVoiceFocusEnabled, selectedDevice]);

    useEffect(() => {
        const handleClick = (deviceId: string) => async () => {
            try {
                if (isVoiceFocusChecked && !isLoading) {
                    setIsLoading(true);
                    const receivedDevice = deviceId;
                    const currentDevice = await addVoiceFocus(receivedDevice);
                    await meetingManager.startAudioInputDevice(currentDevice);
                }
                else {
                    await meetingManager.startAudioInputDevice(deviceId);
                }
            }
            catch (error) {
                console.error('AudioInputVFControl failed to select audio input device');
            }
            finally {
                setIsLoading(false);
            }
        };
        const getDropdownWithVFOptions = async () => {
            const dropdownOptions = await Promise.all(audioInputDevices.map(async (device) => {
                return (
                    <PopOverItem
                        key={device.deviceId}
                        checked={await isOptionActive(selectedDevice, device.deviceId)}
                        onClick={handleClick(device.deviceId)}
                    >
                        <span>{device.label}</span>
                    </PopOverItem>
                );
            }));

            if (isVoiceFocusSupported) {
                const vfOption = (
                    <PopOverItem
                        key="voicefocus"
                        checked={isVoiceFocusEnabled}
                        disabled={isLoading}
                        onClick={() => {
                            setIsLoading(true);
                            setIsVoiceFocusChecked((current) => !current);
                        }}
                    >
                        <>
                            {isLoading && <Spinner width="1.5rem" height="1.5rem" />}
                            {isVoiceFocusEnabled ? voiceFocusOnLabel : voiceFocusOffLabel}
                        </>
                    </PopOverItem>
                );
                if (dropdownOptions) {
                    dropdownOptions.push(<PopOverSeparator key="separator" />);
                    dropdownOptions.push(vfOption);
                }
            }
            setDropdownWithVFOptions(dropdownOptions);
        };
        getDropdownWithVFOptions();
    }, [
        // The contents of this dropdown depends, of course, on the current selected device,
        // but also on the Voice Focus state, including `addVoiceFocus` which is used inside
        // the click handler.
        addVoiceFocus,
        meetingManager,
        meetingManager.startAudioInputDevice,
        audioInputDevices,
        isLoading,
        isVoiceFocusEnabled,
        isVoiceFocusChecked,
        isVoiceFocusSupported,
        selectedDevice,
    ]);

    useEffect(() => {
        const onVFCheckboxChange = async () => {
            if (!selectedDevice) {
                return;
            }
            try {
                let current = selectedDevice;
                if (isVoiceFocusChecked) {
                    console.info('User turned on Amazon Voice Focus.');
                    if (typeof selectedDevice === 'string') {
                        current = await addVoiceFocus(selectedDevice);
                    }
                    console.info('Amazon Voice Focus is enabled for the selected device:', current);
                }
                else {
                    console.info('Amazon Voice Focus is off by default or user turned off Amazon Voice Focus.');
                    if (selectedDevice instanceof VoiceFocusTransformDevice) {
                        current = selectedDevice.getInnerDevice();
                    }
                }
                await meetingManager.startAudioInputDevice(current);
            }
            catch (error) {
                console.error('AudioInputVFControl failed to select audio input device onVFCheckboxChange change', error);
            }
            setIsLoading(false);
        };
        onVFCheckboxChange();
    }, [isVoiceFocusChecked]);

    const userAttendeeId = meetingManager.meetingSession?.configuration.credentials?.attendeeId;

    return (
        <ControlBarButton
            icon={
                userAttendeeId ?
                    <MicrophoneActivity attendeeId={userAttendeeId} /> :
                    <Microphone muted={muted} mutedTitle={mutedIconTitle} unmutedTitle={unmutedIconTitle} />
            }
            onClick={toggleMute}
            label={muted ? unmuteLabel : muteLabel}
            {...rest}
        >
            {dropdownWithVFOptions}
        </ControlBarButton>
    );
};
