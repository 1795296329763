import DataStoreUserOrganizationAssociationDAOFactory from "../organization/DataStoreUserOrganizationAssociationDAOFactory";
import GraphQLOrganizationDAOFactory from "../organization/graphQL/GraphQLOrganizationDAOFactory";
import { Organization } from "../../models";
import OrganizationDAO from "../organization/OrganizationDAO";
import UserOrganizationAssociationDAO from "../organization/UserOrganizationAssociationDAO";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { userIdAtom } from "../userSession/state/UserSessionState";

const userOrganizationDAO: UserOrganizationAssociationDAO = DataStoreUserOrganizationAssociationDAOFactory.getInstance();
const organizationDAO: OrganizationDAO = GraphQLOrganizationDAOFactory.getInstance();

export const useUserAssociatedTeams = () => {
    const userId: string | undefined = useRecoilValue(userIdAtom);

    const getUserAssociatedTeams = async (userId: string): Promise<Array<Organization>> => {
        const userOrganizationAssociations = await userOrganizationDAO.listUserOrganizationAssociationsByUserId(userId);
        const uniqueOrganizationIds: Array<string> = userOrganizationAssociations.reduce((organizationIds: Array<string>, userOrganizationAssociation) => {
            if (!organizationIds.includes(userOrganizationAssociation.organizationId)) {
                organizationIds.push(userOrganizationAssociation.organizationId);
            }
            return organizationIds;
        }, []);
        return await Promise.all(uniqueOrganizationIds.map(async (organizationId) => {
            return await organizationDAO.getById(organizationId);
        }));
    };

    const { data: userAssociatedTeams } = useQuery({
        queryKey: ["userAssociatedTeams", userId],
        queryFn: () => {
            if (!userId) {
                return [];
            }
            return getUserAssociatedTeams(userId);
        },
        staleTime: Infinity
    });

    return { userAssociatedTeams };
};