import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="m14.79 3.58.56-2L10.22.26l-.59 2.11L3.81 1l-1 3.97 17 4.03 1-4-6.02-1.42zm-3.87-2.1 3.19.81-.12.44-3.19-.82.12-.43zm8.16 6.32L4.02 4.23l.52-2.03 15.05 3.54-.51 2.06zM5.806 24h12l2-15h-16l2 15zm11.124-1H6.681L4.948 10h13.716L16.93 23z"
        className="primary"
      />
      <path
        fill="currentColor"
        d="m7.538 11.198.995-.104 1.074 10.283-.994.104zM13.984 21.378l1.07-10.283.996.103-1.072 10.284zM11.294 11.146h1v10.283h-1z"
        className="primary"
      />
    </g>
  </svg>
);
export default withCustomIcon(TrashIcon);
