import { SurveyQuestionMultipleText } from "survey-react-ui";
import TeamSearchBox from "../../componentsV2/team/TeamSearchBox";

export class MUISurveyTeamSearchBox extends SurveyQuestionMultipleText {
    constructor(props: any) {
        super(props);
    }

    renderElement() {
        return (
            <TeamSearchBox
                onClear={() => this.question.value = undefined}
                onSelect={(team) => this.question.value = team}
                selectedTeam={this.question.value}
                multiple={false}
                freeSolo={true}
                textFieldStyle={{
                    InputLabelProps: {
                        sx: {
                            display: "none"
                        }
                    },
                    variant: "standard",
                }}
            />
        );
    }
}
