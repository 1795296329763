import { Mutex } from "async-mutex";
import TokenBucket from "./TokenBucket";
import TokenBucketRefiller from "./TokenBucketRefiller";
import TokenBucketRefillerImpl from "./TokenBucketRefillerImpl";
import TokenRefillStrategy from "./TokenRefillStrategy";

export default class TokenBucketRefillerImplFactory {
    public static create(
        tokenRefillStrategy: TokenRefillStrategy,
        tokenBucket: TokenBucket
    ): TokenBucketRefiller {
        return new TokenBucketRefillerImpl(
            new Mutex(),
            tokenRefillStrategy,
            tokenBucket
        );
    }
}