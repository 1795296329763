import RateLimiter from "./RateLimiter";
import TokenBucket from "./TokenBucket";
import TokenBucketBasedRateLimiter from "./TokenBucketBasedRateLimiter";
import TokenBucketImplFactory from "./TokenBucketImplFactory";
import TokenBucketRefillerImplFactory from "./TokenBucketRefillerImplFactory";
import TokenRefillStrategy from "./TokenRefillStrategy";

export default class TokenBucketBasedRateLimiterFactory {
    public static create(
        capacity: number,
        tokenRefillStrategy: TokenRefillStrategy
    ): RateLimiter {
        const tokenBucket: TokenBucket = TokenBucketImplFactory.create(capacity);
        return new TokenBucketBasedRateLimiter(
            tokenBucket,
            TokenBucketRefillerImplFactory.create(
                tokenRefillStrategy,
                tokenBucket
            )
        );
    }
}