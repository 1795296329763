import {
    API,
    graphqlOperation
} from "aws-amplify";

import ClientLogger from "../../../../logging/ClientLogger";
import CreateWorkScopeSpecificationError from "../error/CreateWorkScopeSpecificationError";
import { CreateWorkSpecificationMutation } from "../../../../../API";
import Envelope from "../../../../envelope/Envelope";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { IDTokenSupplier } from "../../../../auth/IDTokenSupplier";
import Serializer from "../../../../util/data/serialization/Serializer";
import WorkScopeSpecificationDAO from "../WorkScopeSpecificationDAO";
import WorkScopeSpecificationRecordAlreadyExistsError from "../error/WorkScopeSpecificationRecordAlreadyExistsError";
import { WorkSpecification } from "../../../../../models";
import { createWorkSpecification } from "../../../../../graphql/mutations";

export default class GraphQLWorkScopeSpecificationDAO implements WorkScopeSpecificationDAO {

    private static readonly CREATE_ATTEMPT_METRIC_NAME = "GraphQLWorkScopeSpecificationDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "GraphQLWorkScopeSpecificationDAO.Create.Failure";

    private readonly api: typeof API;
    private readonly gqlOperation: typeof graphqlOperation;
    private readonly serializer: Serializer<any, string>;
    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        serializer: Serializer<any, string>,
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.serializer = serializer;
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async create(workSpecification: WorkSpecification): Promise<WorkSpecification> {
        this.logger.info(
            `Creating WorkSpecification with id=${workSpecification.id}`,
            workSpecification,
            [GraphQLWorkScopeSpecificationDAO.CREATE_ATTEMPT_METRIC_NAME]
        );
        try {
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<CreateWorkSpecificationMutation> = await this.api.graphql(
                this.gqlOperation(
                    createWorkSpecification,
                    { input: workSpecification },
                    authToken
                )
            ) as GraphQLResult<CreateWorkSpecificationMutation>;
            return result.data?.createWorkSpecification!;
        } catch (error) {
            if ((error as any)?.errors && (error as any)?.errors[0].errorType === "ConditionalCheckFailedException") {
                const errorMessage = `WorkScopeSpecification record already exists: ${workSpecification.id}`;
                this.logger.warn(
                    errorMessage,
                    error,
                    [GraphQLWorkScopeSpecificationDAO.CREATE_FAILURE_METRIC_NAME]
                );
                throw new WorkScopeSpecificationRecordAlreadyExistsError(
                    errorMessage
                );
            }
            const errorMessage = `Unable to create WorkSpecification with id=${workSpecification.id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLWorkScopeSpecificationDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreateWorkScopeSpecificationError(errorMessage, {
                cause: error as Error
            });
        }
    }

    public async createWorkScopeSpecification(workScopeSpecification: Envelope, dimensionId: string, issueId: string): Promise<WorkSpecification> {
        this.logger.info(
            `Creating WorkSpecification with id=${workScopeSpecification.header.id}`,
            workScopeSpecification,
            [GraphQLWorkScopeSpecificationDAO.CREATE_ATTEMPT_METRIC_NAME]
        );
        try {
            const serializedWSS: string = typeof workScopeSpecification.body === "string" ? workScopeSpecification.body :
                this.serializer.serialize(workScopeSpecification.body);
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<CreateWorkSpecificationMutation> = await this.api.graphql(
                this.gqlOperation(createWorkSpecification, {
                    input: {
                        id: workScopeSpecification.header.id,
                        dimensionId: dimensionId,
                        issueId: issueId,
                        version: workScopeSpecification.header.version,
                        body: serializedWSS
                    },
                    authToken
                })
            ) as GraphQLResult<CreateWorkSpecificationMutation>;
            return result.data?.createWorkSpecification!;
        } catch (error) {
            const errorMessage = `Unable to create WorkSpecification with id=${workScopeSpecification.header.id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLWorkScopeSpecificationDAO.CREATE_FAILURE_METRIC_NAME]
            );
            if ((error as any)?.errors && (error as any)?.errors[0].errorType === "ConditionalCheckFailedException") {
                throw new WorkScopeSpecificationRecordAlreadyExistsError(
                    `WorkScopeSpecification record already exists: ${workScopeSpecification.header.id}`
                );
            }
            throw new CreateWorkScopeSpecificationError(errorMessage, {
                cause: error as Error
            });
        }
    }

    getById(workSpecificationId: string): Promise<WorkSpecification> {
        throw new Error("Method not implemented.");
    }

    listByIssueId(issueId: string): Promise<WorkSpecification[]> {
        throw new Error("Method not implemented.");
    }

    listByDimensionId(dimensionId: string): Promise<WorkSpecification[]> {
        throw new Error("Method not implemented.");
    }

    update(workSpecification: WorkSpecification): Promise<WorkSpecification> {
        throw new Error("Method not implemented.");
    }

    delete(workSpecificationId: string): Promise<void> {
        throw new Error("Method not implemented.");
    }

    queryWorkScopeSpecificationsByIssueId(issueId: string): Promise<Envelope[]> {
        throw new Error("Method not implemented.");
    }

    queryWorkScopeSpecificationsByDimensionId(dimensionId: string): Promise<Envelope[]> {
        throw new Error("Method not implemented.");
    }

    editWorkScopeSpecification(workScopeSpecification: Envelope): Promise<WorkSpecification> {
        throw new Error("Method not implemented.");
    }

    deleteWorkScopeSpecification(workScopeSpecification: Envelope): Promise<void> {
        throw new Error("Method not implemented.");
    }

}
