import {
    DISCARD,
    PersistentModel,
    PersistentModelConstructor,
    SyncConflict
} from "@aws-amplify/datastore";
import {
    ResolvableModels,
    ResolvableType
} from "./ResolvableType";

interface Indexable {
    [key: string]: any;
}

export class DataStoreConflictResolver {
    public static resolve(
        conflict: SyncConflict
    ): PersistentModel | typeof DISCARD {
        if (!ResolvableModels.includes(conflict.modelConstructor as any)) {
            return DISCARD;
        }
        const localModel = conflict.localModel as ResolvableType;
        const remoteModel: ResolvableType = conflict.remoteModel as ResolvableType;
        const modelConstructor: PersistentModelConstructor<ResolvableType> = conflict.modelConstructor;
        
        // Always accept remote canViewMetadata from remote model
        const mergedModel = modelConstructor.copyOf(remoteModel, (rm: Indexable) => {
            const localUpdateTime = localModel.localLastUpdatedTime ?? 0;
            const remoteUpdateTime = remoteModel.localLastUpdatedTime ?? 0;
            // Accept the most recently updated record
            if (localUpdateTime > remoteUpdateTime) {
                Object.entries(localModel).forEach(([key, value]) => {
                    rm[key] = value;
                });
            }

            // Accept remote version, canViewMeta to update
            rm._version = remoteModel._version;
            rm.canViewList = remoteModel.canViewList;
            rm.canViewEntityVersionMap = remoteModel.canViewEntityVersionMap;

            // Workaround for bug in amplify.  The return of this function is translated into an Update query, which does not include createdAt/updatedAt,
            // including these fields cause the update to fail.
            rm.createdAt = undefined;
            rm.updatedAt = undefined;
        });
        return mergedModel;
    }
}
