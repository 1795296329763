import { Attachment } from "../../../../models";
import AttachmentRecordDAO from "../../record/AttachmentRecordDAO";
import AttachmentRecordRecoveryError from "../errors/AttachmentRecordRecoveryError";
import ClientLogger from "../../../logging/ClientLogger";

export class AttachmentUploadRecoveryHandler {
    private localForageAttachmentDAO: AttachmentRecordDAO;
    private datastoreAttachmentDAO: AttachmentRecordDAO;
    private logger: ClientLogger;

    constructor(
        localForageAttachmentDAO: AttachmentRecordDAO,
        datastoreAttachmentDAO: AttachmentRecordDAO,
        logger: ClientLogger
    ) {
        this.localForageAttachmentDAO = localForageAttachmentDAO;
        this.datastoreAttachmentDAO = datastoreAttachmentDAO;
        this.logger = logger;
    }

    public async attemptRecovery(id: string): Promise<Attachment | undefined> {
        try {
            const attachmentRecord = await this.localForageAttachmentDAO.getById(id);
            if (!attachmentRecord) {
                throw new Error(`Attachment record with id: ${id} not found in localForage.`);
            }
            const recoveredRecord = await this.datastoreAttachmentDAO.create(attachmentRecord);
            return recoveredRecord;
        } catch (error) {
            this.logger.error(
                `Failed to recover attachment record with id: ${id}`,
                error,
                ['AttachmentRecordRecoveryFailure']
            )
            throw new AttachmentRecordRecoveryError(`Failed to recover attachment record with id: ${id}`);
        }
    }
}