import {
    Box,
    Grid,
    Link as MUILink,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";

import ClientLogger from "../lib/logging/ClientLogger";
import ClientLoggerFactory from "../lib/logging/ClientLoggerFactory";
import { Link } from "react-router-dom";
import LinkData from "../lib/util/link/LinkData";
import LinkDataAdoptingBreadcrumbs from "./LinkDataAdoptingBreadcrumbs";

interface ResponsiveBreadcrumbsProps {
    links: LinkData[];
    currentPageText: string;
}

const ResponsiveBreadcrumbs = (props: ResponsiveBreadcrumbsProps) => {
    const COMPONENT_NAME = "ResponsiveBreadcrumbs";

    /* Internal page state */
    const { links, currentPageText } = props;
    const [backToParentPageLink, setBackToParentPageLink] = useState<string>("");
    const [error, setError] = useState<string>("");

    /* Dependencies */
    const loggerRef = useRef<ClientLogger>(ClientLoggerFactory.getClientLogger(COMPONENT_NAME));

    const theme = useTheme();
    const isScreenSizeXs = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        try {
            const backLink = getBackLink();
            setBackToParentPageLink(backLink);
        } catch (error) {
            if (error instanceof Error) {
                loggerRef.current.warn(
                    "Failed to get breadcrumb links",
                    error,
                    ["BreadcrumbLinksFailure"]
                );
                setError(error.message);
            }
        }
    }, [links]);

    const getBackLink = () => {
        if (links.length === 0) {
            throw new Error("Invalid input: at least one link must be provided!");
        }
        const backLink = links[links.length - 1].url;
        return backLink;
    };

    return (
        error ? null : (
            isScreenSizeXs ? (
                <Grid container item justifyContent="flex-start">
                    <MUILink
                        component={Link}
                        to={`${backToParentPageLink}`}
                        style={{
                            fontSize: "16px"
                        }}
                    >
                        ‹ Back
                    </MUILink>
                </Grid>
            ) : (
                <Box>
                    <LinkDataAdoptingBreadcrumbs
                        links={links}
                        currentPageText={currentPageText}
                    />
                </Box>
            )
        )
    );
};

export default ResponsiveBreadcrumbs;
