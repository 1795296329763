import {
    GenerateReportGroup,
    ReportGenerationIssue,
    ReportGenerationData,
    ReportGenerationWorkSpecification,
    ReportGenerationWorkTypeSummary
} from "../../types/GenerateReportTypes";
import {
    Issue,
    Location,
    WorkSpecification
} from "../../../../models";
import {
    hideAdjustmentsInUIAtom,
    hidePricesInUIAtom,
    proposalContentByContextSelectorFamily
} from "../../bidding/state/v2/ProposalStates";
import {
    issueByIssueIdentifierSelectorFamily,
    issueIdsByLocationIdentifierAtomFamily,
    locationByLocationIdentifierSelectorFamily,
    locationIdsByContextAtomFamily,
    propertyImageUrlAtom,
    proposalRecordAtom,
    solutionMetadataAtom,
    workSpecificationByWorkSpecificationIdentifierSelectorFamily,
    workSpecificationIdsByIssueIdentifierSelectorFamily,
    workTypeIdToUnitCountMapByContextSelectorFamily
} from "./DocumentState";
import {
    proposalItemByWorkSpecificationIdentifierSelectorFamily,
    proposalItemPriceTotalByIssueIdentifierSelectorFamily,
    proposalItemPriceTotalByLocationIdentifierSelectorFamily
} from "../../bidding/state/v2/ProposalItemStates";

import { ContextAwareIdentifier } from "../ContextAwareIdentifier";
import { MeasurementUnit } from "../../../../models";
import MeasurementUnitAbbreviationFactory from "../../../util/dimension/MeasurementUnitAbbreviationFactory";
import { ModelType } from "../ModelType";
import NumberStringConverter from "../../../util/NumberStringConverter";
import ProposalItem from "../../bidding/ProposalItem";
import { StateContext } from "./StateContext";
import WorkTypeDTO from "../../../worktype/DTO/WorkTypeDTO";
import { selectorFamily } from "recoil";
import { workTypeByWorkTypeIdAtomFamily } from "../../../worktype/state/WorkTypeRecoilState";

export const reportGenerationWorkSpecificationListByIssueIdentifierSelectorFamily = selectorFamily<Array<ReportGenerationWorkSpecification>, ContextAwareIdentifier>({
    key: "reportGenerationWorkSpecificationListByIssueIdentifierSelectorFamily",
    get: (identifier: ContextAwareIdentifier) => ({ get }) => {
        const workSpecificationIds: Array<string> = get(workSpecificationIdsByIssueIdentifierSelectorFamily(identifier));
        return workSpecificationIds.map(workSpecificationId => {
            const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, identifier.context, ModelType.WORK_SPECIFICATION);
            const workSpecification: WorkSpecification | null = get(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier));
            const proposalItem: ProposalItem | undefined = get(proposalItemByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier));
            const workType: WorkTypeDTO | undefined = get(workTypeByWorkTypeIdAtomFamily(workSpecification?.workTypeId!));
            const hidePrice: boolean = get(hidePricesInUIAtom);
            return {
                id: workSpecificationId,
                length: NumberStringConverter.numberToString(workSpecification?.measurement?.length ?? 0, 0, true),
                width: workSpecification?.measurement?.width ? NumberStringConverter.numberToString(workSpecification.measurement.width, 0, true) : undefined,
                work_type_name: workType?.name ?? "",
                work_specification_total: NumberStringConverter.numberToString(proposalItem?.priceAfterAdjustment ?? 0, 0, true),
                unit_price: hidePrice ? "-" : NumberStringConverter.numberToString(proposalItem?.unitPriceAfterAdjustment ?? 0, 2, true),
                unit: MeasurementUnitAbbreviationFactory.getAbbreviation(MeasurementUnit[workSpecification?.measurement?.measurementUnit!]),
                unit_count: NumberStringConverter.numberToString(proposalItem?.unitCount ?? 0, 0, true),
                work_specification_note: workSpecification?.description || undefined,
            };
        });
    }
});

export const reportGenerationIssueListByLocationIdentifierSelectorFamily = selectorFamily<Array<ReportGenerationIssue>, ContextAwareIdentifier>({
    key: "reportGenerationIssueListByLocationIdentifierSelectorFamily",
    get: (identifier: ContextAwareIdentifier) => ({ get }) => {
        const issueIds: Array<string> = get(issueIdsByLocationIdentifierAtomFamily(identifier));
        return issueIds.map(issueId => {
            const issueIdentifier = new ContextAwareIdentifier(issueId, identifier.context, ModelType.ISSUE);
            const issue: Issue | null = get(issueByIssueIdentifierSelectorFamily(issueIdentifier));
            const issueTotal: number = get(proposalItemPriceTotalByIssueIdentifierSelectorFamily(issueIdentifier));
            const workSpecifications: Array<ReportGenerationWorkSpecification> = get(reportGenerationWorkSpecificationListByIssueIdentifierSelectorFamily(issueIdentifier));
            const hidePrice: boolean = get(hidePricesInUIAtom);
            return {
                id: issueId,
                issue_name: issue?.name ?? "",
                issue_total: hidePrice? "-" :NumberStringConverter.numberToString(issueTotal, 0, true),
                images_html: "",
                work_specifications: workSpecifications,
            };
        });
    }
});

export const reportGenerationLocationListByContextSelectorFamily = selectorFamily<Array<GenerateReportGroup>, StateContext>({
    key: "reportGenerationLocationListByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const locationIds = get(locationIdsByContextAtomFamily(context));
        return locationIds.map(locationId => {
            const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
            const location: Location | null = get(locationByLocationIdentifierSelectorFamily(locationIdentifier));
            const locationTotal: number = get(proposalItemPriceTotalByLocationIdentifierSelectorFamily(locationIdentifier));
            const issues: Array<ReportGenerationIssue> = get(reportGenerationIssueListByLocationIdentifierSelectorFamily(locationIdentifier));
            const hidePrice: boolean = get(hidePricesInUIAtom);
            return {
                id: locationId,
                group_name: location?.name ?? "",
                group_total: hidePrice? "-" :NumberStringConverter.numberToString(locationTotal, 0, true),
                issues: issues,
            };
        });
    }
});

export const reportGenerationWorkTypeSummaryListByContextSelectorFamily = selectorFamily<Array<ReportGenerationWorkTypeSummary>, StateContext>({
    key: "reportGenerationWorkTypeSummaryListByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const workTypeIdToUnitCountMap: Map<string, number> = get(workTypeIdToUnitCountMapByContextSelectorFamily(context));
        return Array.from(workTypeIdToUnitCountMap.entries()).map(([workTypeId, unitCount]) => {
            const workType = get(workTypeByWorkTypeIdAtomFamily(workTypeId));
            return {
                work_type_name: workType?.name ?? "",
                unit_count: NumberStringConverter.numberToString(unitCount, 0, true),
                unit: MeasurementUnitAbbreviationFactory.getAbbreviation(MeasurementUnit.FOOT)
            };
        });
    }
});

export const reportGenerationDataSelectorByContextSelectorFamily = selectorFamily<ReportGenerationData, StateContext>({
    key: "reportGenerationDataSelectorByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const proposalRecord = get(proposalRecordAtom);
        const proposalContent = get(proposalContentByContextSelectorFamily(context));
        const solutionMetadata = get(solutionMetadataAtom);
        const exportLocations: Array<GenerateReportGroup> = get(reportGenerationLocationListByContextSelectorFamily(context));
        const exportWorkTypeSummaries: Array<ReportGenerationWorkTypeSummary> = get(reportGenerationWorkTypeSummaryListByContextSelectorFamily(context));
        const propertyImageUrl: string | undefined = get(propertyImageUrlAtom);
        const hidePrice: boolean = get(hidePricesInUIAtom);
        const hideAdjustments: boolean = get(hideAdjustmentsInUIAtom);
        const adjustmentAmount: number = proposalContent.priceAfterAdjustment! - proposalContent.priceBeforeAdjustment!;

        return {
            property_image: propertyImageUrl || undefined,
            company_name: "",
            consultant_email: solutionMetadata.consultantEmail!,
            consultant_name: solutionMetadata.consultantName!,
            consultant_phone_number: solutionMetadata.consultantPhoneNumber!,
            date: new Date().toDateString(),
            groups: exportLocations,
            solution_name: solutionMetadata.name!,
            property_address: solutionMetadata.propertyAddress!,
            property_contact_name: solutionMetadata.propertyContactName!,
            proposal_number: proposalRecord?.proposalNumber?.toString() ?? "",
            adjustment: hideAdjustments ? undefined : hidePrice ? "-" : NumberStringConverter.numberToString(adjustmentAmount, 0, true),
            total: hidePrice ? "-" : NumberStringConverter.numberToString(proposalContent.priceAfterAdjustment ?? 0, 0, true),
            work_type_summaries: exportWorkTypeSummaries
        } satisfies ReportGenerationData;
    }
});
