import {
    Solution,
    Tender
} from "../../../models";

import { DefaultTenderDAOFactory } from "../dao/DefaultTenderDAOFactory";
import { TenderDAO } from "../dao/TenderDAO";
import { useSnackbar } from "notistack";

const tenderDAO: TenderDAO = DefaultTenderDAOFactory.getInstance();
const TENDERING_APP_URL = process.env.REACT_APP_TENDERING_APP_URL || "http://localhost:3002";

export const useTendering = () => {
    const snackbar = useSnackbar();

    const createNewTender = async (
        solution: Solution
    ) => {
        try {
            const tender: Tender | undefined = await getTenderIfExist(solution.serialNumber!);
            if (tender) {
                return tender;
            }
            return await tenderDAO.create(solution);
        } catch (error) {
            snackbar.enqueueSnackbar("Failed to create tender", { variant: "error" });
        }
    }

    const getTenderIfExist = async (
        projectNumber: number
    ) => {
        try {
            return await tenderDAO.getByProjectNumber(projectNumber);
        } catch (error) {
            return undefined;
        }
    }

    const getTenderByProjectNumber = async (
        projectNumber: number
    ) => {
        try {
            return await tenderDAO.getByProjectNumber(projectNumber);
        } catch (error) {
            return undefined;
        }
    }

    const openTenderPage = async (
        tenderId: string
    ) => {
        window.open(`${TENDERING_APP_URL}/tenders/${tenderId}/overview`);
    }

    return { createNewTender, getTenderByProjectNumber, openTenderPage }
}