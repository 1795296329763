import DataStoreEntityWorkTypeAssociationDAOFactory from "../../relationship/dataStore/DataStoreEntityWorkTypeAssociationDAOFactory";
import DataStoreWorkTypeDAOFactory from "../../dataStore/DataStoreWorkTypeDAOFactory";
import DataStoreWorkTypeGroupDAOFactory from "../../group/dataStore/DataStoreWorkTypeGroupDAOFactory";
import ListEntityAssociatedWorkTypesHandler from "./ListEntityAssociatedWorkTypesHandler";
import ListEntityAssociatedWorkTypesHandlerImpl from "./ListEntityAssociatedWorkTypesHandlerImpl";

export default class ListEntityAssociatedWorkTypesHandlerFactory {
  private static readonly INSTANCE: ListEntityAssociatedWorkTypesHandler = new ListEntityAssociatedWorkTypesHandlerImpl(
    DataStoreWorkTypeDAOFactory.getInstance(),
    DataStoreEntityWorkTypeAssociationDAOFactory.getInstance(),
    DataStoreWorkTypeGroupDAOFactory.getInstance()
  );

  public static getInstance(): ListEntityAssociatedWorkTypesHandler {
    return this.INSTANCE;
  }
}
