import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const AddLocationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    style={{
      //@ts-ignore
      enableBackground: "new 0 0 24 24",
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 22.6c-.2-.3-.5-.7-.7-1.1-.9-1.4-1.8-2.8-2.6-4.1-.6-1-1-1.8-1.4-2.6C3.8 12 3 9.9 3 8.5 3 4.4 6.1 1 10 1s7 3.4 7 7.5c0 .7-.2 1.5-.5 2.5.3 0 .7.1 1 .1.3-1 .5-1.9.5-2.7C18 3.8 14.4 0 10 0S2 3.8 2 8.5c0 1.6.8 3.9 2.4 6.8.4.8.9 1.7 1.5 2.6.8 1.4 1.7 2.8 2.6 4.1.3.5.5 1 .8 1.3.2.2.3.4.3.5.1.1.3.2.4.2s.3-.1.4-.2c.1-.1.2-.2.3-.5.1-.1.2-.3.3-.4-.2-.2-.5-.5-.7-.8-.1.2-.2.3-.3.5zM10 4C7.8 4 6 5.8 6 8s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 7c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M18 12a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm2.667 6.667h-2v2a.666.666 0 0 1-1.333 0v-2h-2a.667.667 0 0 1 0-1.333h2v-2a.667.667 0 0 1 1.333 0v2h2a.666.666 0 1 1 0 1.333z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(AddLocationIcon);
