import { atomFamily } from "recoil";

export const currentPageByGroupIdAtomFamily = atomFamily<number, string>({
    key: "currentPageByGroupIdAtomFamily",
    default: 0
});

export const numberOfPagesByGroupIdAtomFamily = atomFamily<number, string>({
    key: "numberOfPagesByGroupIdAtomFamily",
    default: 1
});