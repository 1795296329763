import ClientLogger from "../../logging/ClientLogger";
import CreateWorkTypeError from "../errors/CreateWorkTypeError";
import { DataStoreClass } from "@aws-amplify/datastore";
import Deserializer from "../../util/data/serialization/Deserializer";
import GetWorkTypeByGroupIdAndVersionError from "../errors/GetWorkTypeByGroupIdAndVersionError";
import GetWorkTypeByIdError from "../errors/GetWorkTypeByIdError";
import ResourceNotFoundError from "../../ResourceNotFoundError";
import Serializer from "../../util/data/serialization/Serializer";
import { TemporaryWorkType } from "../../../models";
import WorkTypeBody from "../DTO/WorkTypeBody";
import WorkTypeDAO from "../WorkTypeDAO";
import WorkTypeDTO from "../DTO/WorkTypeDTO";
import WorkTypeMetricNames from "../WorkTypeMetricNames";

export default class DataStoreWorkTypeDAO implements WorkTypeDAO {
    private readonly WORK_TYPE_RESOURCE_NAME = "WorkType";

    private jsonDeserializer: Deserializer<string, WorkTypeBody>;
    private jsonSerializer: Serializer<WorkTypeBody, string>;
    private logger: ClientLogger;
    private dataStore: DataStoreClass;

    constructor(
        jsonDeserializer: Deserializer<string, WorkTypeBody>,
        jsonSerializer: Serializer<WorkTypeBody, string>,
        logger: ClientLogger,
        dataStore: DataStoreClass,
    ) {
        this.jsonDeserializer = jsonDeserializer;
        this.jsonSerializer = jsonSerializer;
        this.logger = logger;
        this.dataStore = dataStore;
    }

    public async create(
        workTypeDTO: WorkTypeDTO
    ): Promise<WorkTypeDTO> {
        try {
            this.logger.info(
                `Creating workType: ${workTypeDTO.groupId} version: ${workTypeDTO.version} name: ${workTypeDTO.name}`,
                undefined,
                [WorkTypeMetricNames.CREATE_ATTEMPT]
            );
            const objectToCreate : TemporaryWorkType = new TemporaryWorkType({
                workTypeGroupId: workTypeDTO.groupId,
                body: this.fromWorkTypeDTOToEnvelopeBodyString(workTypeDTO),
                version: workTypeDTO.version,
                schemaVersion: 0
            });
            const result: TemporaryWorkType = await this.dataStore.save(objectToCreate);
            return this.fromTemporaryWorkTypeToWorkTypeDTO(result);
        } catch (error) {
            const errorMessage: string = `Unable to create workType: ${workTypeDTO.groupId} version: ${workTypeDTO.version} name: ${workTypeDTO.name}`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeMetricNames.CREATE_FAILURE]
            );
            throw new CreateWorkTypeError(errorMessage, error as Error);
        }
    }

    public async getById (
        workTypeId: string
    ): Promise<WorkTypeDTO | undefined> {
        try {
            this.logger.info(
                `Getting workType id: ${workTypeId}`,
                undefined,
                [WorkTypeMetricNames.GET_BY_ID_ATTEMPT]
            );
            const workType: TemporaryWorkType | undefined = await this.dataStore.query(TemporaryWorkType, workTypeId);
            if (!workType) {
                return undefined;
            }
            return this.fromTemporaryWorkTypeToWorkTypeDTO(workType);
        } catch (error) {
            const errorMessage: string = `Unable to get workType id: ${workTypeId}`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeMetricNames.GET_BY_ID_FAILURE]
            )
            throw new GetWorkTypeByIdError(errorMessage, error as Error);
        }
    }

    public async getByGroupIdAndVersion(
        groupId: string,
        version: number
    ): Promise<WorkTypeDTO> {
        try {
            this.logger.info(
                `Getting workType: ${groupId} version ${version}`,
                undefined,
                [WorkTypeMetricNames.GET_BY_GROUP_ID_AND_VERSION_ATTEMPT]
            );
            const workTypes: Array<TemporaryWorkType> = await this.dataStore.query(
                TemporaryWorkType,
                workType => workType.workTypeGroupId("eq", groupId).version("eq", version)
            );

            if (workTypes.length === 0) {
                throw new ResourceNotFoundError(this.WORK_TYPE_RESOURCE_NAME);
            }
            const workTypeRecord: TemporaryWorkType = workTypes[0];
            return this.fromTemporaryWorkTypeToWorkTypeDTO(workTypeRecord);
        } catch (error) {
            const errorMessage: string = `Unable to get workType: ${groupId} version ${version}`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypeMetricNames.GET_BY_GROUP_ID_AND_VERSION_FAILURE]
            )
            throw new GetWorkTypeByGroupIdAndVersionError(errorMessage, error as Error);
        }
    }

    private fromTemporaryWorkTypeToWorkTypeDTO(
        temporaryWorkType: TemporaryWorkType
    ): WorkTypeDTO {
        const workTypeBody: WorkTypeBody = this.jsonDeserializer.deserialize(temporaryWorkType.body);

        return {
            id: temporaryWorkType.id,
            groupId: temporaryWorkType.workTypeGroupId,
            version: temporaryWorkType.version,
            ...workTypeBody
        } as WorkTypeDTO;
    }

    private fromWorkTypeDTOToEnvelopeBodyString(
        workTypeDTO: WorkTypeDTO
    ): string {
        const workTypeBody: WorkTypeBody = {
            name: workTypeDTO.name,
            categoryName: workTypeDTO.categoryName,
            shortDescription: workTypeDTO.shortDescription,
            longDescription: workTypeDTO.longDescription,
            dimensionType: workTypeDTO.dimensionType
        };
        return this.jsonSerializer.serialize(workTypeBody);
    }
}
