import {
    Autocomplete,
    AutocompleteRenderGroupParams,
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    TextField,
    createFilterOptions
} from "@mui/material";
import {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";

import { AutocompleteOption } from "../../lib/util/ui/autocomplete/AutocompleteOption";
import ClearIcon from '@mui/icons-material/Clear';
import CollapsingAutocompleteOptionGroup from "../../components/general/autocomplete/CollapsingAutocompleteOptionGroup";
import RecentlyUsedItemsDAO from "../../lib/util/recent/RecentlyUsedItemsDAO";
import { isDesktop } from "react-device-detect";

export interface RecentlyUsedAccordionAutocompleteProps {
    selectedOption: AutocompleteOption | undefined;
    label: string | undefined;
    placeholder?: string;
    options: AutocompleteOption[];
    recentlyUsedOptionsDAO: RecentlyUsedItemsDAO<AutocompleteOption>;
    size?: "small" | "medium";
    defaultOpen?: boolean;
    onChange: onChangeEvent;
}
export interface onChangeEvent {
    (newValue: AutocompleteOption | null): void;
}
const RecentlyUsedAccordionAutocomplete = (props: RecentlyUsedAccordionAutocompleteProps) => {
    const { selectedOption, defaultOpen, recentlyUsedOptionsDAO, onChange, label, placeholder, size = "medium" } = props;
    const recentlyUsedGroupKey = "Recently Used";
    const CREATE_NEW_OPTION_GROUP = "Create New Work Type";

    const [value, setValue] = useState<AutocompleteOption | undefined>(selectedOption);
    const [inputValue, setInputValue] = useState<string>(selectedOption?.label || "");
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(defaultOpen || false);
    const filterOptions = createFilterOptions<AutocompleteOption>();
    const [recentlyUsedOptions, setRecentlyUsedOptions] = useState<Array<AutocompleteOption>>([]);
    const options = useMemo(() => {
        const optionIds: Array<string> = props.options.map(option => option.id);
        const availableRecentlyUsed: Array<AutocompleteOption> = recentlyUsedOptions.filter(option => optionIds.includes(option.id));
        return [...availableRecentlyUsed, ...props.options];
    }, [props.options, recentlyUsedOptions]);

    useEffect(() => {
        fetchRecentlyUsed();
    }, []);

    useEffect(() => {
        setValue(selectedOption);
        setInputValue(selectedOption?.label || "");
    }, [selectedOption]);

    const fetchRecentlyUsed = async (): Promise<void> => {
        const recentlyUsed: Array<AutocompleteOption> = await recentlyUsedOptionsDAO.getRecentlyUsed();
        setRecentlyUsedOptions(recentlyUsed);
    };

    const appendRecentlyUsed = (
        selectedOption: AutocompleteOption
    ): void => {
        const recentlyUsedOption = {
            id: selectedOption.id,
            label: selectedOption.label,
            group: recentlyUsedGroupKey
        };
        recentlyUsedOptionsDAO.addRecentlyUsed(recentlyUsedOption);
    };

    const shouldInterceptAutocomplete: boolean = useMemo(() => {
        return !isDesktop && !isOpen;
    }, [isOpen]);

    const interceptAutocompleteOpening: () => Promise<void> = useCallback(async () => {
        if (shouldInterceptAutocomplete) {
            await fetchRecentlyUsed();
            setIsOpen(true);
        }
    }, [shouldInterceptAutocomplete]);

    return (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <Box
                sx={{
                    position: "relative"
                }}
            >
                <div
                    onClick={interceptAutocompleteOpening}
                    style={{ cursor: 'pointer' }}
                >
                    <Autocomplete
                        blurOnSelect
                        disableClearable // For hiding the default clear button
                        value={value}
                        onChange={(event, newValue: AutocompleteOption) => {
                            setValue(newValue);
                            onChange(newValue);
                            if (!newValue.newCreated) {
                                appendRecentlyUsed(newValue);
                                setInputValue(newValue.label);
                                return;
                            }
                            // When creating a new work type, keep the selected value for display
                            setInputValue(value?.label || "");
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, value, reason) => {
                            if (reason == "input") {
                                setInputValue(value);
                            }
                        }}
                        filterOptions={(options, state) => {
                            if (!isSearching) {
                                state.inputValue = "";
                            }
                            const filteredOptions = filterOptions(options, state);
                            if (state.inputValue !== "") {
                                return [...filteredOptions, {
                                    id: "NEW_WORK_TYPE",
                                    label: state.inputValue,
                                    group: CREATE_NEW_OPTION_GROUP,
                                    newCreated: true
                                }];
                            }
                            return filteredOptions;
                        }}
                        groupBy={option => option.group}
                        onBlur={() => {
                            setInputValue(selectedOption?.label || "");
                            setIsSearching(false);
                        }}
                        open={isOpen}
                        onOpen={async () => {
                            await fetchRecentlyUsed();
                            setIsOpen(true);
                        }}
                        onClose={() => setIsOpen(false)}
                        options={options}
                        renderGroup={(params: AutocompleteRenderGroupParams) => {
                            return (
                                <Box key={params.key} fontWeight={params.group === CREATE_NEW_OPTION_GROUP ? "500" : undefined}>
                                    <CollapsingAutocompleteOptionGroup
                                        isSearching={isSearching}
                                        isOpenByDefault={params.group === recentlyUsedGroupKey}
                                        params={params}
                                    />
                                </Box>
                            );
                        }}
                        renderOption={(props, option: AutocompleteOption, state) => {
                            return (
                                <Box key={`${option.group}_${option.id}`} fontWeight="400" sx={{ textDecoration: option.newCreated ? "underline" : undefined }}>
                                    <li {...props}>
                                        {`${option.label}`}
                                    </li>
                                    <Divider />
                                </Box>
                            );
                        }}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={label}
                                    placeholder={placeholder}
                                    onChange={(event) => {
                                        setIsSearching(event.target.value !== "");
                                    }}
                                />
                            );
                        }}
                        //pointerEvent: 'none' disables autocomplete onClick functionality
                        style={{ pointerEvents: shouldInterceptAutocomplete ? 'none' : 'auto' }}
                        size={size}
                    />
                </div>
                {
                    value &&
                    <IconButton
                        size="medium"
                        sx={{
                            position: "absolute",
                            height: "28px",
                            width: "28px",
                            top: 'calc(50% - 14px)',
                            right: "34px",
                        }}
                        onClick={() => {
                            setValue(undefined);
                            setInputValue("");
                            onChange(null);
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                }
            </Box>
        </ClickAwayListener>
    );
};

export default RecentlyUsedAccordionAutocomplete;
