import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { GApiGoogleDriveFolderDAO } from "./GApiGoogleDriveFolderDAO";
import { GoogleDriveFolderDAO } from "./GoogleDriveFolderDAO";

export class GApiGoogleDriveFolderDAOFactory {
    private static INSTANCE: GoogleDriveFolderDAO = new GApiGoogleDriveFolderDAO(
        ClientLoggerFactory.getClientLogger("GApiGoogleDriveFolderDAO")
    );

    public static getInstance(): GoogleDriveFolderDAO {
        return GApiGoogleDriveFolderDAOFactory.INSTANCE;
    }
}