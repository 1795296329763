import {
    AddPropertyIcon,
    CircleCrossIcon,
    CircleTickIcon,
    LogoutIcon,
    PropertiesIcon,
    TeamIcon
} from "../icons";
import {
    Box,
    CircularProgress,
    Divider,
    List,
    ListItem,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {
    Dispatch,
    SetStateAction,
    memo,
    useMemo
} from "react";
import {
    eligibilityCheckErrorAtom,
    isLiveInspectionDrawerOpenAtom,
    isLogoutWarningPromptOpenAtom,
    showUserOnboardingWizardAtom,
    showVideoCallAssistantSelector,
    sidebarDrawerConfigurationsAtom
} from "../../lib/sidebar/SidebarRecoilStates";
import {
    useRecoilValue,
    useSetRecoilState
} from "recoil";

import { ConsolidatedDataSyncStatus } from "../../lib/datastore/ConsolidatedDataSyncStatus";
import { DrawerListItemButton } from "./DrawerListItemButton";
import { IssueFilterDrawerListItem } from "./IssueFilterDrawerListItem";
import { SOFTWARE_VERSION } from "../../lib/util/troubleshooting/SoftwareVersion";
import { SidebarDrawerConfiguration } from "../../lib/sidebar/SidebarDrawerConfiguration";
import TuneIcon from '@mui/icons-material/Tune';
import { ValidationDrawerListItem } from "./ValidationDrawerListItem";
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';
import { consolidatedDataSyncStatusSelector } from "../../lib/datastore/state/ConsolidatedDataSyncState";
import { isSystemReadySelector } from "../../lib/system/state/SystemState";
import theme from "../../assets/style/theme";

type SidebarDrawerContentsProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const SidebarDrawerContents = memo((props: SidebarDrawerContentsProps) => {
    const { isOpen, setIsOpen } = props;
    const dataSyncStatus = useRecoilValue<ConsolidatedDataSyncStatus>(consolidatedDataSyncStatusSelector);
    const isSystemReady = useRecoilValue<boolean>(isSystemReadySelector);
    const showUserOnboardingWizard = useRecoilValue(showUserOnboardingWizardAtom);
    const eligibilityCheckError = useRecoilValue(eligibilityCheckErrorAtom);
    const setLogoutWarningPromptOpen = useSetRecoilState(isLogoutWarningPromptOpenAtom);
    const sidebarDrawerConfiguration = useRecoilValue<SidebarDrawerConfiguration>(sidebarDrawerConfigurationsAtom);
    const showVideoCallAssistant = useRecoilValue<boolean>(showVideoCallAssistantSelector);
    const setIsLiveInspectionDrawerOpen = useSetRecoilState(isLiveInspectionDrawerOpenAtom);

    const dataSyncBackgroundColor = useMemo(() => {
        if (!isSystemReady) {
            return theme.palette.error.main;
        }
        switch (dataSyncStatus) {
            case ConsolidatedDataSyncStatus.SYNCING_FROM_CLOUD:
                return theme.palette.error.main;
            case ConsolidatedDataSyncStatus.SYNCING_TO_CLOUD:
                return theme.palette.warning.main;
            case ConsolidatedDataSyncStatus.NOTHING_TO_SYNC:
                return theme.palette.success.main;
            default:
                return "white";
        }
    }, [dataSyncStatus, isSystemReady]);

    const dataSyncStatusTooltip = useMemo(() => {
        if (!isSystemReady) {
            return (
                <Tooltip title="System is not ready.">
                    <CircleCrossIcon primary="secondary" />
                </Tooltip>
            );
        }
        switch (dataSyncStatus) {
            case ConsolidatedDataSyncStatus.SYNCING_FROM_CLOUD:
                return (
                    <Tooltip title="System is not ready.">
                        <CircleCrossIcon primary="secondary" />
                    </Tooltip>
                );
            case ConsolidatedDataSyncStatus.SYNCING_TO_CLOUD:
                return (
                    <Tooltip title="Data sync in progress.">
                        <CircularProgress size={24} color="secondary" />
                    </Tooltip>
                );
            case ConsolidatedDataSyncStatus.NOTHING_TO_SYNC:
                return (
                    <Tooltip title="System is ready.">
                        <CircleTickIcon primary="secondary" />
                    </Tooltip>
                );
            default:
                return null;
        }
    }, [dataSyncStatus, isSystemReady]);

    const drawerListItems = useMemo(() => {
        return (
            <Box>
                <List
                    disablePadding
                    sx={
                        showUserOnboardingWizard || eligibilityCheckError ? {
                            display: "none"
                        } : {
                            display: "block"
                        }
                    }
                >
                    <DrawerListItemButton
                        key="view-properties"
                        icon={<PropertiesIcon />}
                        title="View Properties"
                        isOpen={isOpen}
                        linkTo="/properties"
                        onClick={() => setIsOpen(false)}
                    />
                    <Divider />
                    <Box
                        pt={6}
                    />
                    <Divider />
                    <DrawerListItemButton
                        key="add-properties"
                        icon={<AddPropertyIcon />}
                        title="Add Property"
                        isOpen={isOpen}
                        linkTo="/properties/create"
                        onClick={() => setIsOpen(false)}
                    />
                    <Divider />
                    <DrawerListItemButton
                        key="team"
                        icon={<TeamIcon />}
                        title="Team"
                        isOpen={isOpen}
                        linkTo="/team"
                        onClick={() => setIsOpen(false)}
                    />
                    {sidebarDrawerConfiguration.displayValidationButton && (
                        <>
                            <Divider />
                            <ValidationDrawerListItem
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                            />
                        </>
                    )}
                    {sidebarDrawerConfiguration.displayIssueFilteringButton && (
                        <>
                            <Divider />
                            <IssueFilterDrawerListItem
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                            />
                        </>
                    )}
                    <Divider />
                    <Box
                        pt={6}
                    />
                    {showVideoCallAssistant && (
                        <>
                            <Divider />
                            <DrawerListItemButton
                                onClick={() => {
                                    setIsOpen(false);
                                    setIsLiveInspectionDrawerOpen(true);
                                }}
                                icon={<VideoCallOutlinedIcon color='primary' />}
                                title={'Remote Inspection'}
                                isOpen={isOpen}
                            />
                            <Divider />
                        </>
                    )}
                </List>
            </Box>
        );
    }, [isOpen, setIsOpen, showUserOnboardingWizard, eligibilityCheckError, sidebarDrawerConfiguration]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%"
            }}
        >
            <Toolbar
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    px: [1],
                }}
            >
            </Toolbar>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    mt: 3
                }}
            >
                {drawerListItems}
                <Box>
                    <DrawerListItemButton
                        key="settings"
                        icon={<TuneIcon />}
                        title="Settings"
                        isOpen={isOpen}
                        linkTo="/settings"
                        onClick={() => setIsOpen(false)}
                    />
                    <Divider />
                    {isOpen && (
                        <>
                            <DrawerListItemButton
                                key="logout"
                                icon={<LogoutIcon />}
                                title="Log Out"
                                isOpen={isOpen}
                                onClick={() => setLogoutWarningPromptOpen(true)}
                            />
                            <Divider />
                        </>
                    )}
                    <ListItem
                        key="datastoreReadinessStatus"
                        sx={{
                            py: 0.5,
                            px: 0,
                            backgroundColor: isOpen ? dataSyncBackgroundColor : theme.palette.text.primary
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                            alignItems="center"
                        >
                            {isOpen && dataSyncStatusTooltip}
                            <Typography variant="caption" color="secondary">
                                v{SOFTWARE_VERSION}
                            </Typography>
                        </Box>
                    </ListItem>
                </Box>
            </Box>
        </Box>
    );
});