/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCompleteEvent = /* GraphQL */ `
  subscription OnCompleteEvent($executionId: ID!) {
    onCompleteEvent(executionId: $executionId) {
      executionId
      status
      result
    }
  }
`;
export const onCreateAttachment = /* GraphQL */ `
  subscription OnCreateAttachment(
    $filter: ModelSubscriptionAttachmentFilterInput
  ) {
    onCreateAttachment(filter: $filter) {
      id
      type
      parentId
      parentType
      propertyId
      key
      localCreatedAtTime
      status
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAttachment = /* GraphQL */ `
  subscription OnUpdateAttachment(
    $filter: ModelSubscriptionAttachmentFilterInput
  ) {
    onUpdateAttachment(filter: $filter) {
      id
      type
      parentId
      parentType
      propertyId
      key
      localCreatedAtTime
      status
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAttachment = /* GraphQL */ `
  subscription OnDeleteAttachment(
    $filter: ModelSubscriptionAttachmentFilterInput
  ) {
    onDeleteAttachment(filter: $filter) {
      id
      type
      parentId
      parentType
      propertyId
      key
      localCreatedAtTime
      status
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBiddingContact = /* GraphQL */ `
  subscription OnCreateBiddingContact(
    $filter: ModelSubscriptionBiddingContactFilterInput
  ) {
    onCreateBiddingContact(filter: $filter) {
      id
      email
      ownerEntityId
      contactEntityId
      name
      privateDescription
      publicDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBiddingContact = /* GraphQL */ `
  subscription OnUpdateBiddingContact(
    $filter: ModelSubscriptionBiddingContactFilterInput
  ) {
    onUpdateBiddingContact(filter: $filter) {
      id
      email
      ownerEntityId
      contactEntityId
      name
      privateDescription
      publicDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBiddingContact = /* GraphQL */ `
  subscription OnDeleteBiddingContact(
    $filter: ModelSubscriptionBiddingContactFilterInput
  ) {
    onDeleteBiddingContact(filter: $filter) {
      id
      email
      ownerEntityId
      contactEntityId
      name
      privateDescription
      publicDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBidInvitation = /* GraphQL */ `
  subscription OnCreateBidInvitation(
    $filter: ModelSubscriptionBidInvitationFilterInput
  ) {
    onCreateBidInvitation(filter: $filter) {
      id
      biddingContactId
      tenderId
      ownerEntityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBidInvitation = /* GraphQL */ `
  subscription OnUpdateBidInvitation(
    $filter: ModelSubscriptionBidInvitationFilterInput
  ) {
    onUpdateBidInvitation(filter: $filter) {
      id
      biddingContactId
      tenderId
      ownerEntityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBidInvitation = /* GraphQL */ `
  subscription OnDeleteBidInvitation(
    $filter: ModelSubscriptionBidInvitationFilterInput
  ) {
    onDeleteBidInvitation(filter: $filter) {
      id
      biddingContactId
      tenderId
      ownerEntityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBid = /* GraphQL */ `
  subscription OnCreateBid($filter: ModelSubscriptionBidFilterInput) {
    onCreateBid(filter: $filter) {
      id
      tenderId
      latestMinorVersion
      latestAcceptedMinorVersion
      latestSubmittedMinorVersion
      status
      bidNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBid = /* GraphQL */ `
  subscription OnUpdateBid($filter: ModelSubscriptionBidFilterInput) {
    onUpdateBid(filter: $filter) {
      id
      tenderId
      latestMinorVersion
      latestAcceptedMinorVersion
      latestSubmittedMinorVersion
      status
      bidNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBid = /* GraphQL */ `
  subscription OnDeleteBid($filter: ModelSubscriptionBidFilterInput) {
    onDeleteBid(filter: $filter) {
      id
      tenderId
      latestMinorVersion
      latestAcceptedMinorVersion
      latestSubmittedMinorVersion
      status
      bidNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBidMinorVersion = /* GraphQL */ `
  subscription OnCreateBidMinorVersion(
    $filter: ModelSubscriptionBidMinorVersionFilterInput
  ) {
    onCreateBidMinorVersion(filter: $filter) {
      id
      bidId
      minorVersion
      tenderId
      tenderMinorVersion
      proposalId
      status
      metadata {
        bidderContactName
        bidderContactEmail
        bidderCompanyName
        bidderContactAddress
        bidderContactPhoneNumber
        bidderWebsite
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBidMinorVersion = /* GraphQL */ `
  subscription OnUpdateBidMinorVersion(
    $filter: ModelSubscriptionBidMinorVersionFilterInput
  ) {
    onUpdateBidMinorVersion(filter: $filter) {
      id
      bidId
      minorVersion
      tenderId
      tenderMinorVersion
      proposalId
      status
      metadata {
        bidderContactName
        bidderContactEmail
        bidderCompanyName
        bidderContactAddress
        bidderContactPhoneNumber
        bidderWebsite
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBidMinorVersion = /* GraphQL */ `
  subscription OnDeleteBidMinorVersion(
    $filter: ModelSubscriptionBidMinorVersionFilterInput
  ) {
    onDeleteBidMinorVersion(filter: $filter) {
      id
      bidId
      minorVersion
      tenderId
      tenderMinorVersion
      proposalId
      status
      metadata {
        bidderContactName
        bidderContactEmail
        bidderCompanyName
        bidderContactAddress
        bidderContactPhoneNumber
        bidderWebsite
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateClientLog = /* GraphQL */ `
  subscription OnCreateClientLog(
    $filter: ModelSubscriptionClientLogFilterInput
  ) {
    onCreateClientLog(filter: $filter) {
      id
      userId
      userAgent
      severityLevel
      componentName
      message
      details
      tags
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateClientLog = /* GraphQL */ `
  subscription OnUpdateClientLog(
    $filter: ModelSubscriptionClientLogFilterInput
  ) {
    onUpdateClientLog(filter: $filter) {
      id
      userId
      userAgent
      severityLevel
      componentName
      message
      details
      tags
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteClientLog = /* GraphQL */ `
  subscription OnDeleteClientLog(
    $filter: ModelSubscriptionClientLogFilterInput
  ) {
    onDeleteClientLog(filter: $filter) {
      id
      userId
      userAgent
      severityLevel
      componentName
      message
      details
      tags
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCounter = /* GraphQL */ `
  subscription OnCreateCounter($filter: ModelSubscriptionCounterFilterInput) {
    onCreateCounter(filter: $filter) {
      id
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCounter = /* GraphQL */ `
  subscription OnUpdateCounter($filter: ModelSubscriptionCounterFilterInput) {
    onUpdateCounter(filter: $filter) {
      id
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCounter = /* GraphQL */ `
  subscription OnDeleteCounter($filter: ModelSubscriptionCounterFilterInput) {
    onDeleteCounter(filter: $filter) {
      id
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDesignDocument = /* GraphQL */ `
  subscription OnCreateDesignDocument(
    $filter: ModelSubscriptionDesignDocumentFilterInput
  ) {
    onCreateDesignDocument(filter: $filter) {
      id
      designDocumentGroupId
      propertyId
      projectId
      name
      status
      lastEvaluatedValidityStatus
      uploadStatus
      documentKey
      lastWorkScopeSpecificationItemNumber
      version
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDesignDocument = /* GraphQL */ `
  subscription OnUpdateDesignDocument(
    $filter: ModelSubscriptionDesignDocumentFilterInput
  ) {
    onUpdateDesignDocument(filter: $filter) {
      id
      designDocumentGroupId
      propertyId
      projectId
      name
      status
      lastEvaluatedValidityStatus
      uploadStatus
      documentKey
      lastWorkScopeSpecificationItemNumber
      version
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDesignDocument = /* GraphQL */ `
  subscription OnDeleteDesignDocument(
    $filter: ModelSubscriptionDesignDocumentFilterInput
  ) {
    onDeleteDesignDocument(filter: $filter) {
      id
      designDocumentGroupId
      propertyId
      projectId
      name
      status
      lastEvaluatedValidityStatus
      uploadStatus
      documentKey
      lastWorkScopeSpecificationItemNumber
      version
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDesignDocumentGroup = /* GraphQL */ `
  subscription OnCreateDesignDocumentGroup(
    $filter: ModelSubscriptionDesignDocumentGroupFilterInput
  ) {
    onCreateDesignDocumentGroup(filter: $filter) {
      id
      projectId
      propertyId
      name
      latestDocumentVersion
      documentContentKey
      solutionGroupNumber
      isLocked
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDesignDocumentGroup = /* GraphQL */ `
  subscription OnUpdateDesignDocumentGroup(
    $filter: ModelSubscriptionDesignDocumentGroupFilterInput
  ) {
    onUpdateDesignDocumentGroup(filter: $filter) {
      id
      projectId
      propertyId
      name
      latestDocumentVersion
      documentContentKey
      solutionGroupNumber
      isLocked
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDesignDocumentGroup = /* GraphQL */ `
  subscription OnDeleteDesignDocumentGroup(
    $filter: ModelSubscriptionDesignDocumentGroupFilterInput
  ) {
    onDeleteDesignDocumentGroup(filter: $filter) {
      id
      projectId
      propertyId
      name
      latestDocumentVersion
      documentContentKey
      solutionGroupNumber
      isLocked
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDimension = /* GraphQL */ `
  subscription OnCreateDimension(
    $filter: ModelSubscriptionDimensionFilterInput
  ) {
    onCreateDimension(filter: $filter) {
      id
      parentId
      propertyId
      createdInSolution
      description
      length
      lengthUnit
      width
      widthUnit
      dimensionType
      measurementUnit
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDimension = /* GraphQL */ `
  subscription OnUpdateDimension(
    $filter: ModelSubscriptionDimensionFilterInput
  ) {
    onUpdateDimension(filter: $filter) {
      id
      parentId
      propertyId
      createdInSolution
      description
      length
      lengthUnit
      width
      widthUnit
      dimensionType
      measurementUnit
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDimension = /* GraphQL */ `
  subscription OnDeleteDimension(
    $filter: ModelSubscriptionDimensionFilterInput
  ) {
    onDeleteDimension(filter: $filter) {
      id
      parentId
      propertyId
      createdInSolution
      description
      length
      lengthUnit
      width
      widthUnit
      dimensionType
      measurementUnit
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDiscoverableWorkTypes = /* GraphQL */ `
  subscription OnCreateDiscoverableWorkTypes(
    $filter: ModelSubscriptionDiscoverableWorkTypesFilterInput
  ) {
    onCreateDiscoverableWorkTypes(filter: $filter) {
      id
      workTypeGroupId
      defaultVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDiscoverableWorkTypes = /* GraphQL */ `
  subscription OnUpdateDiscoverableWorkTypes(
    $filter: ModelSubscriptionDiscoverableWorkTypesFilterInput
  ) {
    onUpdateDiscoverableWorkTypes(filter: $filter) {
      id
      workTypeGroupId
      defaultVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDiscoverableWorkTypes = /* GraphQL */ `
  subscription OnDeleteDiscoverableWorkTypes(
    $filter: ModelSubscriptionDiscoverableWorkTypesFilterInput
  ) {
    onDeleteDiscoverableWorkTypes(filter: $filter) {
      id
      workTypeGroupId
      defaultVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateEntityResourcePermission = /* GraphQL */ `
  subscription OnCreateEntityResourcePermission(
    $filter: ModelSubscriptionEntityResourcePermissionFilterInput
  ) {
    onCreateEntityResourcePermission(filter: $filter) {
      id
      propertyId
      entityId
      entityType
      resourceId
      resourceType
      permissionType
      isGranted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEntityResourcePermission = /* GraphQL */ `
  subscription OnUpdateEntityResourcePermission(
    $filter: ModelSubscriptionEntityResourcePermissionFilterInput
  ) {
    onUpdateEntityResourcePermission(filter: $filter) {
      id
      propertyId
      entityId
      entityType
      resourceId
      resourceType
      permissionType
      isGranted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEntityResourcePermission = /* GraphQL */ `
  subscription OnDeleteEntityResourcePermission(
    $filter: ModelSubscriptionEntityResourcePermissionFilterInput
  ) {
    onDeleteEntityResourcePermission(filter: $filter) {
      id
      propertyId
      entityId
      entityType
      resourceId
      resourceType
      permissionType
      isGranted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateEntityWorkTypeAssociation = /* GraphQL */ `
  subscription OnCreateEntityWorkTypeAssociation(
    $filter: ModelSubscriptionEntityWorkTypeAssociationFilterInput
  ) {
    onCreateEntityWorkTypeAssociation(filter: $filter) {
      id
      entityId
      entityType
      workTypeGroupId
      workTypeVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEntityWorkTypeAssociation = /* GraphQL */ `
  subscription OnUpdateEntityWorkTypeAssociation(
    $filter: ModelSubscriptionEntityWorkTypeAssociationFilterInput
  ) {
    onUpdateEntityWorkTypeAssociation(filter: $filter) {
      id
      entityId
      entityType
      workTypeGroupId
      workTypeVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEntityWorkTypeAssociation = /* GraphQL */ `
  subscription OnDeleteEntityWorkTypeAssociation(
    $filter: ModelSubscriptionEntityWorkTypeAssociationFilterInput
  ) {
    onDeleteEntityWorkTypeAssociation(filter: $filter) {
      id
      entityId
      entityType
      workTypeGroupId
      workTypeVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateInspection = /* GraphQL */ `
  subscription OnCreateInspection(
    $filter: ModelSubscriptionInspectionFilterInput
  ) {
    onCreateInspection(filter: $filter) {
      id
      propertyId
      createdByOrganizationId
      createdByUserId
      startTime
      endTime
      status
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateInspection = /* GraphQL */ `
  subscription OnUpdateInspection(
    $filter: ModelSubscriptionInspectionFilterInput
  ) {
    onUpdateInspection(filter: $filter) {
      id
      propertyId
      createdByOrganizationId
      createdByUserId
      startTime
      endTime
      status
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteInspection = /* GraphQL */ `
  subscription OnDeleteInspection(
    $filter: ModelSubscriptionInspectionFilterInput
  ) {
    onDeleteInspection(filter: $filter) {
      id
      propertyId
      createdByOrganizationId
      createdByUserId
      startTime
      endTime
      status
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateIssue = /* GraphQL */ `
  subscription OnCreateIssue($filter: ModelSubscriptionIssueFilterInput) {
    onCreateIssue(filter: $filter) {
      id
      locationId
      propertyId
      inspectionId
      createdInSolution
      name
      description
      note
      tags
      status
      clientCreationDate
      localLastUpdatedTime
      createdBy
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateIssue = /* GraphQL */ `
  subscription OnUpdateIssue($filter: ModelSubscriptionIssueFilterInput) {
    onUpdateIssue(filter: $filter) {
      id
      locationId
      propertyId
      inspectionId
      createdInSolution
      name
      description
      note
      tags
      status
      clientCreationDate
      localLastUpdatedTime
      createdBy
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteIssue = /* GraphQL */ `
  subscription OnDeleteIssue($filter: ModelSubscriptionIssueFilterInput) {
    onDeleteIssue(filter: $filter) {
      id
      locationId
      propertyId
      inspectionId
      createdInSolution
      name
      description
      note
      tags
      status
      clientCreationDate
      localLastUpdatedTime
      createdBy
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation($filter: ModelSubscriptionLocationFilterInput) {
    onCreateLocation(filter: $filter) {
      id
      propertyId
      createdInSolution
      name
      tags
      localCreationTime
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation($filter: ModelSubscriptionLocationFilterInput) {
    onUpdateLocation(filter: $filter) {
      id
      propertyId
      createdInSolution
      name
      tags
      localCreationTime
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation($filter: ModelSubscriptionLocationFilterInput) {
    onDeleteLocation(filter: $filter) {
      id
      propertyId
      createdInSolution
      name
      tags
      localCreationTime
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNote = /* GraphQL */ `
  subscription OnCreateNote($filter: ModelSubscriptionNoteFilterInput) {
    onCreateNote(filter: $filter) {
      id
      parentId
      propertyId
      note
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNote = /* GraphQL */ `
  subscription OnUpdateNote($filter: ModelSubscriptionNoteFilterInput) {
    onUpdateNote(filter: $filter) {
      id
      parentId
      propertyId
      note
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNote = /* GraphQL */ `
  subscription OnDeleteNote($filter: ModelSubscriptionNoteFilterInput) {
    onDeleteNote(filter: $filter) {
      id
      parentId
      propertyId
      note
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateOrganization = /* GraphQL */ `
  subscription OnCreateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onCreateOrganization(filter: $filter) {
      id
      legalName
      type
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onUpdateOrganization(filter: $filter) {
      id
      legalName
      type
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteOrganization = /* GraphQL */ `
  subscription OnDeleteOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onDeleteOrganization(filter: $filter) {
      id
      legalName
      type
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($filter: ModelSubscriptionProjectFilterInput) {
    onCreateProject(filter: $filter) {
      id
      propertyId
      name
      tenderNumber
      customer
      address
      propertyType
      dueDate
      contactName
      contactNumber
      contactEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($filter: ModelSubscriptionProjectFilterInput) {
    onUpdateProject(filter: $filter) {
      id
      propertyId
      name
      tenderNumber
      customer
      address
      propertyType
      dueDate
      contactName
      contactNumber
      contactEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject($filter: ModelSubscriptionProjectFilterInput) {
    onDeleteProject(filter: $filter) {
      id
      propertyId
      name
      tenderNumber
      customer
      address
      propertyType
      dueDate
      contactName
      contactNumber
      contactEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateProjectCounter = /* GraphQL */ `
  subscription OnCreateProjectCounter(
    $filter: ModelSubscriptionProjectCounterFilterInput
  ) {
    onCreateProjectCounter(filter: $filter) {
      id
      propertyId
      latestProjectNumber
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateProjectCounter = /* GraphQL */ `
  subscription OnUpdateProjectCounter(
    $filter: ModelSubscriptionProjectCounterFilterInput
  ) {
    onUpdateProjectCounter(filter: $filter) {
      id
      propertyId
      latestProjectNumber
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteProjectCounter = /* GraphQL */ `
  subscription OnDeleteProjectCounter(
    $filter: ModelSubscriptionProjectCounterFilterInput
  ) {
    onDeleteProjectCounter(filter: $filter) {
      id
      propertyId
      latestProjectNumber
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateProperty = /* GraphQL */ `
  subscription OnCreateProperty($filter: ModelSubscriptionPropertyFilterInput) {
    onCreateProperty(filter: $filter) {
      id
      name
      description
      address
      ownerOrganizationId
      primaryContactNumber
      primaryContactEmail
      primaryContactName
      propertyNumber
      locations {
        items {
          id
          propertyId
          createdInSolution
          name
          tags
          localCreationTime
          localLastUpdatedTime
          opaqueSyncReconciliationToken
          canView
          canEdit
          canDelete
          createdBy
          canViewList
          canViewEntityVersionMap
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdBy
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canCreateSolutions
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateProperty = /* GraphQL */ `
  subscription OnUpdateProperty($filter: ModelSubscriptionPropertyFilterInput) {
    onUpdateProperty(filter: $filter) {
      id
      name
      description
      address
      ownerOrganizationId
      primaryContactNumber
      primaryContactEmail
      primaryContactName
      propertyNumber
      locations {
        items {
          id
          propertyId
          createdInSolution
          name
          tags
          localCreationTime
          localLastUpdatedTime
          opaqueSyncReconciliationToken
          canView
          canEdit
          canDelete
          createdBy
          canViewList
          canViewEntityVersionMap
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdBy
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canCreateSolutions
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteProperty = /* GraphQL */ `
  subscription OnDeleteProperty($filter: ModelSubscriptionPropertyFilterInput) {
    onDeleteProperty(filter: $filter) {
      id
      name
      description
      address
      ownerOrganizationId
      primaryContactNumber
      primaryContactEmail
      primaryContactName
      propertyNumber
      locations {
        items {
          id
          propertyId
          createdInSolution
          name
          tags
          localCreationTime
          localLastUpdatedTime
          opaqueSyncReconciliationToken
          canView
          canEdit
          canDelete
          createdBy
          canViewList
          canViewEntityVersionMap
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdBy
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canCreateSolutions
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePropertyCounter = /* GraphQL */ `
  subscription OnCreatePropertyCounter(
    $filter: ModelSubscriptionPropertyCounterFilterInput
  ) {
    onCreatePropertyCounter(filter: $filter) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePropertyCounter = /* GraphQL */ `
  subscription OnUpdatePropertyCounter(
    $filter: ModelSubscriptionPropertyCounterFilterInput
  ) {
    onUpdatePropertyCounter(filter: $filter) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePropertyCounter = /* GraphQL */ `
  subscription OnDeletePropertyCounter(
    $filter: ModelSubscriptionPropertyCounterFilterInput
  ) {
    onDeletePropertyCounter(filter: $filter) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateProposal = /* GraphQL */ `
  subscription OnCreateProposal($filter: ModelSubscriptionProposalFilterInput) {
    onCreateProposal(filter: $filter) {
      id
      schemaVersion
      designDocumentGroupId
      designDocumentVersion
      proposalKey
      proposalNumber
      status
      author
      createdAt
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateProposal = /* GraphQL */ `
  subscription OnUpdateProposal($filter: ModelSubscriptionProposalFilterInput) {
    onUpdateProposal(filter: $filter) {
      id
      schemaVersion
      designDocumentGroupId
      designDocumentVersion
      proposalKey
      proposalNumber
      status
      author
      createdAt
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteProposal = /* GraphQL */ `
  subscription OnDeleteProposal($filter: ModelSubscriptionProposalFilterInput) {
    onDeleteProposal(filter: $filter) {
      id
      schemaVersion
      designDocumentGroupId
      designDocumentVersion
      proposalKey
      proposalNumber
      status
      author
      createdAt
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateProposalCounter = /* GraphQL */ `
  subscription OnCreateProposalCounter(
    $filter: ModelSubscriptionProposalCounterFilterInput
  ) {
    onCreateProposalCounter(filter: $filter) {
      id
      solutionGroupId
      solutionVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateProposalCounter = /* GraphQL */ `
  subscription OnUpdateProposalCounter(
    $filter: ModelSubscriptionProposalCounterFilterInput
  ) {
    onUpdateProposalCounter(filter: $filter) {
      id
      solutionGroupId
      solutionVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteProposalCounter = /* GraphQL */ `
  subscription OnDeleteProposalCounter(
    $filter: ModelSubscriptionProposalCounterFilterInput
  ) {
    onDeleteProposalCounter(filter: $filter) {
      id
      solutionGroupId
      solutionVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateResourceUserPermissions = /* GraphQL */ `
  subscription OnCreateResourceUserPermissions(
    $filter: ModelSubscriptionResourceUserPermissionsFilterInput
  ) {
    onCreateResourceUserPermissions(filter: $filter) {
      id
      resourceId
      userId
      userEmail
      userFullName
      resourceAndUserId
      permissions
      resourceType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateResourceUserPermissions = /* GraphQL */ `
  subscription OnUpdateResourceUserPermissions(
    $filter: ModelSubscriptionResourceUserPermissionsFilterInput
  ) {
    onUpdateResourceUserPermissions(filter: $filter) {
      id
      resourceId
      userId
      userEmail
      userFullName
      resourceAndUserId
      permissions
      resourceType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteResourceUserPermissions = /* GraphQL */ `
  subscription OnDeleteResourceUserPermissions(
    $filter: ModelSubscriptionResourceUserPermissionsFilterInput
  ) {
    onDeleteResourceUserPermissions(filter: $filter) {
      id
      resourceId
      userId
      userEmail
      userFullName
      resourceAndUserId
      permissions
      resourceType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSolution = /* GraphQL */ `
  subscription OnCreateSolution($filter: ModelSubscriptionSolutionFilterInput) {
    onCreateSolution(filter: $filter) {
      id
      propertyId
      projectId
      name
      status
      latestMinorVersion
      latestContentKey
      serialNumber
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
        tenderingType
      }
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSolution = /* GraphQL */ `
  subscription OnUpdateSolution($filter: ModelSubscriptionSolutionFilterInput) {
    onUpdateSolution(filter: $filter) {
      id
      propertyId
      projectId
      name
      status
      latestMinorVersion
      latestContentKey
      serialNumber
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
        tenderingType
      }
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSolution = /* GraphQL */ `
  subscription OnDeleteSolution($filter: ModelSubscriptionSolutionFilterInput) {
    onDeleteSolution(filter: $filter) {
      id
      propertyId
      projectId
      name
      status
      latestMinorVersion
      latestContentKey
      serialNumber
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
        tenderingType
      }
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSolutionMinorVersion = /* GraphQL */ `
  subscription OnCreateSolutionMinorVersion(
    $filter: ModelSubscriptionSolutionMinorVersionFilterInput
  ) {
    onCreateSolutionMinorVersion(filter: $filter) {
      id
      solutionId
      propertyId
      name
      contentKey
      minorVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSolutionMinorVersion = /* GraphQL */ `
  subscription OnUpdateSolutionMinorVersion(
    $filter: ModelSubscriptionSolutionMinorVersionFilterInput
  ) {
    onUpdateSolutionMinorVersion(filter: $filter) {
      id
      solutionId
      propertyId
      name
      contentKey
      minorVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSolutionMinorVersion = /* GraphQL */ `
  subscription OnDeleteSolutionMinorVersion(
    $filter: ModelSubscriptionSolutionMinorVersionFilterInput
  ) {
    onDeleteSolutionMinorVersion(filter: $filter) {
      id
      solutionId
      propertyId
      name
      contentKey
      minorVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSolutionGroupCounter = /* GraphQL */ `
  subscription OnCreateSolutionGroupCounter(
    $filter: ModelSubscriptionSolutionGroupCounterFilterInput
  ) {
    onCreateSolutionGroupCounter(filter: $filter) {
      id
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSolutionGroupCounter = /* GraphQL */ `
  subscription OnUpdateSolutionGroupCounter(
    $filter: ModelSubscriptionSolutionGroupCounterFilterInput
  ) {
    onUpdateSolutionGroupCounter(filter: $filter) {
      id
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSolutionGroupCounter = /* GraphQL */ `
  subscription OnDeleteSolutionGroupCounter(
    $filter: ModelSubscriptionSolutionGroupCounterFilterInput
  ) {
    onDeleteSolutionGroupCounter(filter: $filter) {
      id
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUserGroup = /* GraphQL */ `
  subscription OnCreateUserGroup(
    $filter: ModelSubscriptionUserGroupFilterInput
  ) {
    onCreateUserGroup(filter: $filter) {
      id
      resourceId
      groupType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUserGroup = /* GraphQL */ `
  subscription OnUpdateUserGroup(
    $filter: ModelSubscriptionUserGroupFilterInput
  ) {
    onUpdateUserGroup(filter: $filter) {
      id
      resourceId
      groupType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUserGroup = /* GraphQL */ `
  subscription OnDeleteUserGroup(
    $filter: ModelSubscriptionUserGroupFilterInput
  ) {
    onDeleteUserGroup(filter: $filter) {
      id
      resourceId
      groupType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUserGroupMember = /* GraphQL */ `
  subscription OnCreateUserGroupMember(
    $filter: ModelSubscriptionUserGroupMemberFilterInput
  ) {
    onCreateUserGroupMember(filter: $filter) {
      id
      resourceId
      resourceAndUserId
      userId
      userGroupId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUserGroupMember = /* GraphQL */ `
  subscription OnUpdateUserGroupMember(
    $filter: ModelSubscriptionUserGroupMemberFilterInput
  ) {
    onUpdateUserGroupMember(filter: $filter) {
      id
      resourceId
      resourceAndUserId
      userId
      userGroupId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUserGroupMember = /* GraphQL */ `
  subscription OnDeleteUserGroupMember(
    $filter: ModelSubscriptionUserGroupMemberFilterInput
  ) {
    onDeleteUserGroupMember(filter: $filter) {
      id
      resourceId
      resourceAndUserId
      userId
      userGroupId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUserOrganizationAssociation = /* GraphQL */ `
  subscription OnCreateUserOrganizationAssociation(
    $filter: ModelSubscriptionUserOrganizationAssociationFilterInput
  ) {
    onCreateUserOrganizationAssociation(filter: $filter) {
      id
      userId
      organizationId
      associationType
      permissions
      role
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUserOrganizationAssociation = /* GraphQL */ `
  subscription OnUpdateUserOrganizationAssociation(
    $filter: ModelSubscriptionUserOrganizationAssociationFilterInput
  ) {
    onUpdateUserOrganizationAssociation(filter: $filter) {
      id
      userId
      organizationId
      associationType
      permissions
      role
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUserOrganizationAssociation = /* GraphQL */ `
  subscription OnDeleteUserOrganizationAssociation(
    $filter: ModelSubscriptionUserOrganizationAssociationFilterInput
  ) {
    onDeleteUserOrganizationAssociation(filter: $filter) {
      id
      userId
      organizationId
      associationType
      permissions
      role
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateWorkSpecification = /* GraphQL */ `
  subscription OnCreateWorkSpecification(
    $filter: ModelSubscriptionWorkSpecificationFilterInput
  ) {
    onCreateWorkSpecification(filter: $filter) {
      id
      dimensionId
      issueId
      propertyId
      createdInSolution
      body
      description
      workTypeId
      itemNumber
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      version
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateWorkSpecification = /* GraphQL */ `
  subscription OnUpdateWorkSpecification(
    $filter: ModelSubscriptionWorkSpecificationFilterInput
  ) {
    onUpdateWorkSpecification(filter: $filter) {
      id
      dimensionId
      issueId
      propertyId
      createdInSolution
      body
      description
      workTypeId
      itemNumber
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      version
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteWorkSpecification = /* GraphQL */ `
  subscription OnDeleteWorkSpecification(
    $filter: ModelSubscriptionWorkSpecificationFilterInput
  ) {
    onDeleteWorkSpecification(filter: $filter) {
      id
      dimensionId
      issueId
      propertyId
      createdInSolution
      body
      description
      workTypeId
      itemNumber
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      version
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateWorkType = /* GraphQL */ `
  subscription OnCreateWorkType($filter: ModelSubscriptionWorkTypeFilterInput) {
    onCreateWorkType(filter: $filter) {
      id
      name
      shortDescription
      longDescription
      groupName
      unitPrice
      unitCost
      dimensionType
      measurementUnit
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateWorkType = /* GraphQL */ `
  subscription OnUpdateWorkType($filter: ModelSubscriptionWorkTypeFilterInput) {
    onUpdateWorkType(filter: $filter) {
      id
      name
      shortDescription
      longDescription
      groupName
      unitPrice
      unitCost
      dimensionType
      measurementUnit
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteWorkType = /* GraphQL */ `
  subscription OnDeleteWorkType($filter: ModelSubscriptionWorkTypeFilterInput) {
    onDeleteWorkType(filter: $filter) {
      id
      name
      shortDescription
      longDescription
      groupName
      unitPrice
      unitCost
      dimensionType
      measurementUnit
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateWorkTypeGroup = /* GraphQL */ `
  subscription OnCreateWorkTypeGroup(
    $filter: ModelSubscriptionWorkTypeGroupFilterInput
  ) {
    onCreateWorkTypeGroup(filter: $filter) {
      id
      latestWorkTypeVersion
      environmentAgnosticWorkTypeGroupId
      isDiscoverable
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateWorkTypeGroup = /* GraphQL */ `
  subscription OnUpdateWorkTypeGroup(
    $filter: ModelSubscriptionWorkTypeGroupFilterInput
  ) {
    onUpdateWorkTypeGroup(filter: $filter) {
      id
      latestWorkTypeVersion
      environmentAgnosticWorkTypeGroupId
      isDiscoverable
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteWorkTypeGroup = /* GraphQL */ `
  subscription OnDeleteWorkTypeGroup(
    $filter: ModelSubscriptionWorkTypeGroupFilterInput
  ) {
    onDeleteWorkTypeGroup(filter: $filter) {
      id
      latestWorkTypeVersion
      environmentAgnosticWorkTypeGroupId
      isDiscoverable
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateWorkTypePricing = /* GraphQL */ `
  subscription OnCreateWorkTypePricing(
    $filter: ModelSubscriptionWorkTypePricingFilterInput
  ) {
    onCreateWorkTypePricing(filter: $filter) {
      id
      entityId
      workTypeGroupId
      body
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateWorkTypePricing = /* GraphQL */ `
  subscription OnUpdateWorkTypePricing(
    $filter: ModelSubscriptionWorkTypePricingFilterInput
  ) {
    onUpdateWorkTypePricing(filter: $filter) {
      id
      entityId
      workTypeGroupId
      body
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteWorkTypePricing = /* GraphQL */ `
  subscription OnDeleteWorkTypePricing(
    $filter: ModelSubscriptionWorkTypePricingFilterInput
  ) {
    onDeleteWorkTypePricing(filter: $filter) {
      id
      entityId
      workTypeGroupId
      body
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDefaultWorkTypePricing = /* GraphQL */ `
  subscription OnCreateDefaultWorkTypePricing(
    $filter: ModelSubscriptionDefaultWorkTypePricingFilterInput
  ) {
    onCreateDefaultWorkTypePricing(filter: $filter) {
      id
      unitCost
      unitPrice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDefaultWorkTypePricing = /* GraphQL */ `
  subscription OnUpdateDefaultWorkTypePricing(
    $filter: ModelSubscriptionDefaultWorkTypePricingFilterInput
  ) {
    onUpdateDefaultWorkTypePricing(filter: $filter) {
      id
      unitCost
      unitPrice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDefaultWorkTypePricing = /* GraphQL */ `
  subscription OnDeleteDefaultWorkTypePricing(
    $filter: ModelSubscriptionDefaultWorkTypePricingFilterInput
  ) {
    onDeleteDefaultWorkTypePricing(filter: $filter) {
      id
      unitCost
      unitPrice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTemporaryWorkType = /* GraphQL */ `
  subscription OnCreateTemporaryWorkType(
    $filter: ModelSubscriptionTemporaryWorkTypeFilterInput
  ) {
    onCreateTemporaryWorkType(filter: $filter) {
      id
      workTypeGroupId
      environmentAgnosticWorkTypeGroupId
      body
      version
      schemaVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTemporaryWorkType = /* GraphQL */ `
  subscription OnUpdateTemporaryWorkType(
    $filter: ModelSubscriptionTemporaryWorkTypeFilterInput
  ) {
    onUpdateTemporaryWorkType(filter: $filter) {
      id
      workTypeGroupId
      environmentAgnosticWorkTypeGroupId
      body
      version
      schemaVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTemporaryWorkType = /* GraphQL */ `
  subscription OnDeleteTemporaryWorkType(
    $filter: ModelSubscriptionTemporaryWorkTypeFilterInput
  ) {
    onDeleteTemporaryWorkType(filter: $filter) {
      id
      workTypeGroupId
      environmentAgnosticWorkTypeGroupId
      body
      version
      schemaVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTender = /* GraphQL */ `
  subscription OnCreateTender($filter: ModelSubscriptionTenderFilterInput) {
    onCreateTender(filter: $filter) {
      id
      solutionId
      status
      latestVersion
      awardedVersion
      projectNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTender = /* GraphQL */ `
  subscription OnUpdateTender($filter: ModelSubscriptionTenderFilterInput) {
    onUpdateTender(filter: $filter) {
      id
      solutionId
      status
      latestVersion
      awardedVersion
      projectNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTender = /* GraphQL */ `
  subscription OnDeleteTender($filter: ModelSubscriptionTenderFilterInput) {
    onDeleteTender(filter: $filter) {
      id
      solutionId
      status
      latestVersion
      awardedVersion
      projectNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTenderMinorVersion = /* GraphQL */ `
  subscription OnCreateTenderMinorVersion(
    $filter: ModelSubscriptionTenderMinorVersionFilterInput
  ) {
    onCreateTenderMinorVersion(filter: $filter) {
      id
      tenderId
      version
      solutionId
      solutionMinorVersion
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
      }
      contentKey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTenderMinorVersion = /* GraphQL */ `
  subscription OnUpdateTenderMinorVersion(
    $filter: ModelSubscriptionTenderMinorVersionFilterInput
  ) {
    onUpdateTenderMinorVersion(filter: $filter) {
      id
      tenderId
      version
      solutionId
      solutionMinorVersion
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
      }
      contentKey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTenderMinorVersion = /* GraphQL */ `
  subscription OnDeleteTenderMinorVersion(
    $filter: ModelSubscriptionTenderMinorVersionFilterInput
  ) {
    onDeleteTenderMinorVersion(filter: $filter) {
      id
      tenderId
      version
      solutionId
      solutionMinorVersion
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
      }
      contentKey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUserOnboardingProgress = /* GraphQL */ `
  subscription OnCreateUserOnboardingProgress(
    $filter: ModelSubscriptionUserOnboardingProgressFilterInput
  ) {
    onCreateUserOnboardingProgress(filter: $filter) {
      id
      userId
      hasUserBeenAssociatedWithOrganization
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUserOnboardingProgress = /* GraphQL */ `
  subscription OnUpdateUserOnboardingProgress(
    $filter: ModelSubscriptionUserOnboardingProgressFilterInput
  ) {
    onUpdateUserOnboardingProgress(filter: $filter) {
      id
      userId
      hasUserBeenAssociatedWithOrganization
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUserOnboardingProgress = /* GraphQL */ `
  subscription OnDeleteUserOnboardingProgress(
    $filter: ModelSubscriptionUserOnboardingProgressFilterInput
  ) {
    onDeleteUserOnboardingProgress(filter: $filter) {
      id
      userId
      hasUserBeenAssociatedWithOrganization
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTemplate = /* GraphQL */ `
  subscription OnCreateTemplate($filter: ModelSubscriptionTemplateFilterInput) {
    onCreateTemplate(filter: $filter) {
      id
      type
      name
      s3Key
      ownerId
      ownerType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTemplate = /* GraphQL */ `
  subscription OnUpdateTemplate($filter: ModelSubscriptionTemplateFilterInput) {
    onUpdateTemplate(filter: $filter) {
      id
      type
      name
      s3Key
      ownerId
      ownerType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTemplate = /* GraphQL */ `
  subscription OnDeleteTemplate($filter: ModelSubscriptionTemplateFilterInput) {
    onDeleteTemplate(filter: $filter) {
      id
      type
      name
      s3Key
      ownerId
      ownerType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTemporaryAuthorizationCode = /* GraphQL */ `
  subscription OnCreateTemporaryAuthorizationCode(
    $filter: ModelSubscriptionTemporaryAuthorizationCodeFilterInput
  ) {
    onCreateTemporaryAuthorizationCode(filter: $filter) {
      id
      code
      userId
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTemporaryAuthorizationCode = /* GraphQL */ `
  subscription OnUpdateTemporaryAuthorizationCode(
    $filter: ModelSubscriptionTemporaryAuthorizationCodeFilterInput
  ) {
    onUpdateTemporaryAuthorizationCode(filter: $filter) {
      id
      code
      userId
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTemporaryAuthorizationCode = /* GraphQL */ `
  subscription OnDeleteTemporaryAuthorizationCode(
    $filter: ModelSubscriptionTemporaryAuthorizationCodeFilterInput
  ) {
    onDeleteTemporaryAuthorizationCode(filter: $filter) {
      id
      code
      userId
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateLinkRegistry = /* GraphQL */ `
  subscription OnCreateLinkRegistry(
    $filter: ModelSubscriptionLinkRegistryFilterInput
  ) {
    onCreateLinkRegistry(filter: $filter) {
      id
      action
      variables
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLinkRegistry = /* GraphQL */ `
  subscription OnUpdateLinkRegistry(
    $filter: ModelSubscriptionLinkRegistryFilterInput
  ) {
    onUpdateLinkRegistry(filter: $filter) {
      id
      action
      variables
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLinkRegistry = /* GraphQL */ `
  subscription OnDeleteLinkRegistry(
    $filter: ModelSubscriptionLinkRegistryFilterInput
  ) {
    onDeleteLinkRegistry(filter: $filter) {
      id
      action
      variables
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateExternalReportBranding = /* GraphQL */ `
  subscription OnCreateExternalReportBranding(
    $filter: ModelSubscriptionExternalReportBrandingFilterInput
  ) {
    onCreateExternalReportBranding(filter: $filter) {
      id
      name
      logoKey
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateExternalReportBranding = /* GraphQL */ `
  subscription OnUpdateExternalReportBranding(
    $filter: ModelSubscriptionExternalReportBrandingFilterInput
  ) {
    onUpdateExternalReportBranding(filter: $filter) {
      id
      name
      logoKey
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteExternalReportBranding = /* GraphQL */ `
  subscription OnDeleteExternalReportBranding(
    $filter: ModelSubscriptionExternalReportBrandingFilterInput
  ) {
    onDeleteExternalReportBranding(filter: $filter) {
      id
      name
      logoKey
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateMeeting = /* GraphQL */ `
  subscription OnCreateMeeting($filter: ModelSubscriptionMeetingFilterInput) {
    onCreateMeeting(filter: $filter) {
      id
      status
      data
      createdBy
      mediaPipelineId
      recordingKey
      recordingAttachmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMeeting = /* GraphQL */ `
  subscription OnUpdateMeeting($filter: ModelSubscriptionMeetingFilterInput) {
    onUpdateMeeting(filter: $filter) {
      id
      status
      data
      createdBy
      mediaPipelineId
      recordingKey
      recordingAttachmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMeeting = /* GraphQL */ `
  subscription OnDeleteMeeting($filter: ModelSubscriptionMeetingFilterInput) {
    onDeleteMeeting(filter: $filter) {
      id
      status
      data
      createdBy
      mediaPipelineId
      recordingKey
      recordingAttachmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAttendee = /* GraphQL */ `
  subscription OnCreateAttendee($filter: ModelSubscriptionAttendeeFilterInput) {
    onCreateAttendee(filter: $filter) {
      id
      meetingId
      entityId
      name
      role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAttendee = /* GraphQL */ `
  subscription OnUpdateAttendee($filter: ModelSubscriptionAttendeeFilterInput) {
    onUpdateAttendee(filter: $filter) {
      id
      meetingId
      entityId
      name
      role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAttendee = /* GraphQL */ `
  subscription OnDeleteAttendee($filter: ModelSubscriptionAttendeeFilterInput) {
    onDeleteAttendee(filter: $filter) {
      id
      meetingId
      entityId
      name
      role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
