import {
    CURRENT_LOGGED_IN_USER_ID_LOCALFORAGE_KEY,
    LOGGED_IN_USER_ID_LOCALFORAGE_TABLE
} from "./LastLoggedInUserConstants";

import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { LocalForageLoggedInUserIdDAO } from "./LocalForageLoggedInUserIdDAO";
import localforage from "localforage";

export class LocalForageCurrentLoggedInUserIdDAOFactory {
    private static INSTANCE: LocalForageLoggedInUserIdDAO = new LocalForageLoggedInUserIdDAO(
        ClientLoggerFactory.getClientLogger("LocalForageCurrentLoggedInUserIdDAO"),
        localforage.createInstance({name: LOGGED_IN_USER_ID_LOCALFORAGE_TABLE}),
        CURRENT_LOGGED_IN_USER_ID_LOCALFORAGE_KEY
    );

    public static getInstance(): LocalForageLoggedInUserIdDAO {
        return LocalForageCurrentLoggedInUserIdDAOFactory.INSTANCE;
    }
}