import { SxProps } from "@mui/material";

export const BLACK_OUTLINED_BUTTON: SxProps = {
    color: "text.primary",
    borderColor: "lightgrey",
    "@media (hover: hover)": {
        "&:hover": {
            borderColor: "grey !important",
            backgroundColor: "rgba(0, 0, 0, 0.04)"
        }
    }
};