import ClientLogger from "../../../../../logging/ClientLogger";
import DeleteSolutionMinorVersionContentError from "../error/DeleteSolutionMinorVersionContentError";
import Deserializer from "../../../../../util/data/serialization/Deserializer";
import GetSolutionMinorVersionContentError from "../error/GetSolutionMinorVersionContentError";
import SaveSolutionMinorVersionContentError from "../error/SaveSolutionMinorVersionContentError";
import Serializer from "../../../../../util/data/serialization/Serializer";
import SolutionContent from "../../../../../design/types/SolutionContent";
import SolutionMinorVersionContentDAO from "./SolutionMinorVersionContentDAO";
import { Storage } from "aws-amplify";

export default class S3SolutionMinorVersionContentDAO implements SolutionMinorVersionContentDAO {

    private static readonly GET_ATTEMPT_METRIC_NAME = "S3SolutionMinorVersionContentDAO.Get.Attempt";
    private static readonly GET_FAILURE_METRIC_NAME = "S3SolutionMinorVersionContentDAO.Get.Failure";
    private static readonly CREATE_ATTEMPT_METRIC_NAME = "S3SolutionMinorVersionContentDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "S3SolutionMinorVersionContentDAO.Create.Failure";
    private static readonly DELETE_ATTEMPT_METRIC_NAME = "S3SolutionMinorVersionContentDAO.Delete.Attempt";
    private static readonly DELETE_FAILURE_METRIC_NAME = "S3SolutionMinorVersionContentDAO.Delete.Failure";

    private static readonly SOLUTION_S3_BUCKET = "DesignDocuments";

    private readonly designDocumentContentSerializer: Serializer<SolutionContent, string>;
    private readonly designDocumentContentDeserializer: Deserializer<string, SolutionContent>;
    private readonly storage: typeof Storage;
    private readonly logger: ClientLogger;

    constructor(
        designDocumentContentSerializer: Serializer<SolutionContent, string>,
        designDocumentContentDeserializer: Deserializer<string, SolutionContent>,
        storage: typeof Storage,
        logger: ClientLogger
    ) {
        this.designDocumentContentSerializer = designDocumentContentSerializer;
        this.designDocumentContentDeserializer = designDocumentContentDeserializer;
        this.storage = storage;
        this.logger = logger;
    }
    
    public async get(
        key: string
    ): Promise<SolutionContent> {
        try {
            this.logger.info(
                `Getting Solution Minor Version Content with key=${key}`,
                key,
                [S3SolutionMinorVersionContentDAO.GET_ATTEMPT_METRIC_NAME]
            );  
            const response: any = await this.storage.get(key, {download: true});
            const serializedDesignDocumentContent: string = await response.Body.text();
            return this.designDocumentContentDeserializer.deserialize(serializedDesignDocumentContent);
        } catch (error) {
            this.logger.error(
                `Failed to get Solution Minor Version Content with key=${key}`,
                error,
                [S3SolutionMinorVersionContentDAO.GET_FAILURE_METRIC_NAME]
            );
            throw new GetSolutionMinorVersionContentError(`Failed to get SolutionMinorVersion content with key=${key}`,
                {cause: error as Error}
            );
        }
    }

    public async save(
        solutionId: string,
        minorVersion: number,
        value: SolutionContent
    ): Promise<string> {
        try {
            this.logger.info(
                `Saving Solution Minor Version Content with solutionId=${solutionId} and minorVersion=${minorVersion}`,
                undefined,
                [S3SolutionMinorVersionContentDAO.CREATE_ATTEMPT_METRIC_NAME]
            );
            const key: string = this.createKey(solutionId, minorVersion);
            const serializedSolutionContent: string = this.designDocumentContentSerializer.serialize(value);
            await this.storage.put(key, serializedSolutionContent, { contentType: "document/json" });
            return key;
        } catch (error) {
            this.logger.error(
                `Failed to save Solution Minor Version Content with solutionId=${solutionId} and minorVersion=${minorVersion}`,
                error,
                [S3SolutionMinorVersionContentDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new SaveSolutionMinorVersionContentError(
                `Failed to save SolutionMinorVersion content with solutionId=${solutionId} and minorVersion=${minorVersion}`,
                {cause: error as Error}
            );
        }
    }

    public async delete(
        key: string
    ): Promise<void> {
        try {
            this.logger.info(
                `Deleting Solution Minor Version Content with key=${key}`,
                undefined,
                [S3SolutionMinorVersionContentDAO.DELETE_ATTEMPT_METRIC_NAME]
            );
            await this.storage.remove(key);
        } catch (error) {
            this.logger.error(
                `Failed to delete Solution Minor Version Content with key=${key}`,
                error,
                [S3SolutionMinorVersionContentDAO.DELETE_FAILURE_METRIC_NAME]
            );
            throw new DeleteSolutionMinorVersionContentError(`Failed to delete SolutionMinorVersion content with key=${key}`,
                {cause: error as Error}
            );
        }
    }

    private createKey(
        solutionId: string,
        minorVersion: number
    ) {
        return `${S3SolutionMinorVersionContentDAO.SOLUTION_S3_BUCKET}/${solutionId}_V${minorVersion}`;
    }
}
