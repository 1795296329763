import ClientLogger from "../logging/ClientLogger";
import { MimeType } from "../googleDrive/type/MimeType";
import { Storage } from "aws-amplify";
import { TemplateContentDAO } from "./TemplateContentDAO";

export class S3TemplateContentDAO implements TemplateContentDAO {
    public static readonly GET_ATTEMPT_METRIC_NAME = "S3TemplateDAO.Get.Attempt";
    public static readonly GET_FAILURE_METRIC_NAME = "S3TemplateDAO.Get.Failure";
    public static readonly SAVE_ATTEMPT_METRIC_NAME = "S3TemplateDAO.Save.Attempt";
    public static readonly SAVE_FAILURE_METRIC_NAME = "S3TemplateDAO.Save.Failure";
    public static readonly DELETE_ATTEMPT_METRIC_NAME = "S3TemplateDAO.Delete.Attempt";
    public static readonly DELETE_FAILURE_METRIC_NAME = "S3TemplateDAO.Delete.Failure";

    private readonly logger: ClientLogger;
    private readonly storage: typeof Storage;

    constructor(
        storage: typeof Storage,
        logger: ClientLogger
    ) {
        this.storage = storage;
        this.logger = logger;
    }

    public async get(key: string): Promise<Blob> {
        try {
            this.logger.info(
                `Getting Template with key=${key}`,
                key,
                [S3TemplateContentDAO.GET_ATTEMPT_METRIC_NAME]
            );
            return (await this.storage.get(`${key}`, { download: true })).Body as Blob;
        } catch (error) {
            this.logger.error(
                `Failed to retrieve template with key=${key}`,
                error,
                [S3TemplateContentDAO.GET_FAILURE_METRIC_NAME]
            );
            throw new Error(`Failed to retrieve template with key=${key}`);
        }
    }

    public async save(
        key: string,
        content: Blob
    ): Promise<string> {
        try {
            this.logger.info(
                `Saving Template`,
                undefined,
                [S3TemplateContentDAO.SAVE_ATTEMPT_METRIC_NAME]
            );
            await this.storage.put(
                key,
                content,
                {
                    contentType: MimeType.WORD_DOCUMENT
                }
            );
            return key;
        } catch (error) {
            this.logger.error(
                `Failed to save template`,
                error,
                [S3TemplateContentDAO.SAVE_FAILURE_METRIC_NAME]
            );
            throw new Error(`Failed to save template`);
        }
    }

public async delete(key: string): Promise<void> {
        try {
            this.logger.info(
                `Deleting Template with key=${key}`,
                key,
                [S3TemplateContentDAO.DELETE_ATTEMPT_METRIC_NAME]
            );
            await this.storage.remove(key);
        } catch (error) {
            this.logger.error(
                `Failed to delete template with key=${key}`,
                error,
                [S3TemplateContentDAO.DELETE_FAILURE_METRIC_NAME]
            );
            throw new Error(`Failed to delete template with key=${key}`);
        }
    }
}