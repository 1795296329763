import ClientLoggerFactory from "../logging/ClientLoggerFactory";
import DefaultImageResizer from "./DefaultImageResizer";
import ImageResizer from "./ImageResizer";
import ReactImageFileResizerFactory from "./ReactImageFileResizerFactory";

export default class DefaultImageResizerFactory {
    private static readonly INSTANCE: ImageResizer = new DefaultImageResizer(
        ReactImageFileResizerFactory.getInstance(),
        ClientLoggerFactory.getClientLogger("DefaultImageResizer")
    );

    public static getInstance(): ImageResizer {
        return DefaultImageResizerFactory.INSTANCE;
    }
}
