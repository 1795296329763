import {
    getUpdateIsEditModeCallback,
    isEditModeByDesignElementIdentifierAtomFamily
} from "../../lib/design/document/state/DocumentState";
import {
    idInFocusByModelTypeAtomFamily,
    stateContextInFocusAtom
} from "../../lib/ui/InFocusRecoilStates";

import { ChildrenExpandState } from "../../lib/design/document/state/enum/ChildrenExpandState";
import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { ModelType } from "../../lib/design/document/ModelType";
import { QuickAccessKey } from "./QuickAccessKey";
import { SaveCloseIcon } from "../icons";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { childrenExpandStateByDesignElementIdentifierAtomFamily } from "../../lib/design/document/state/ComponentExpandState";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { useSetChildrenExpandState } from "../../lib/design/hooks/useSetChildrenExpandState";

export const QuickSaveKey = () => {
    const contextInFocus = useRecoilValue<StateContext>(stateContextInFocusAtom);
    const issueIdInFocus = useRecoilValue<string | null>(idInFocusByModelTypeAtomFamily(ModelType.ISSUE));
    const locationIdInFocus = useRecoilValue<string | null>(idInFocusByModelTypeAtomFamily(ModelType.LOCATION));
    const issueIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(issueIdInFocus ?? "", contextInFocus, ModelType.ISSUE);
    }, [issueIdInFocus, contextInFocus]);
    const locationIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(locationIdInFocus ?? "", contextInFocus, ModelType.LOCATION);
    }, [locationIdInFocus, contextInFocus]);
    const locationChildrenExpandState = useRecoilValue<ChildrenExpandState>(childrenExpandStateByDesignElementIdentifierAtomFamily(locationIdentifier));
    const issueChildrenExpandState = useRecoilValue<ChildrenExpandState>(childrenExpandStateByDesignElementIdentifierAtomFamily(issueIdentifier));
    const isIssueInEditMode = useRecoilValue<boolean>(isEditModeByDesignElementIdentifierAtomFamily(issueIdentifier));
    const isLocationInEditMode = useRecoilValue<boolean>(isEditModeByDesignElementIdentifierAtomFamily(locationIdentifier));

    const isLocationExpanded = locationChildrenExpandState === ChildrenExpandState.EXPAND_CHILDREN;
    const isIssueExpanded = issueChildrenExpandState === ChildrenExpandState.EXPAND_CHILDREN;

    const setIsEditMode = getUpdateIsEditModeCallback();
    const { setModelChildrenExpandState } = useSetChildrenExpandState(contextInFocus);
    const disabled = !isIssueInEditMode && !isLocationInEditMode && !isIssueExpanded && !isLocationExpanded;


    return (
        <QuickAccessKey
            disabled={disabled}
            onClick={() => {
                if (issueIdInFocus && locationIdInFocus) {
                    setIsEditMode(ModelType.LOCATION, locationIdInFocus, false, contextInFocus);
                    setModelChildrenExpandState(ModelType.ISSUE, issueIdInFocus, ChildrenExpandState.COLLAPSE_CHILDREN);
                    return;
                }
                if (locationIdInFocus) {
                    setIsEditMode(ModelType.LOCATION, locationIdInFocus, false, contextInFocus);
                    setModelChildrenExpandState(ModelType.LOCATION, locationIdInFocus, ChildrenExpandState.COLLAPSE_CHILDREN);
                    return;
                }
            }}
            in
        >
            <SaveCloseIcon accent={disabled ? "disabled" : "primary"} />
        </QuickAccessKey>
    );
};