import {
    Alert,
    Box,
    LinearProgress,
    List
} from '@mui/material';
import {
    PermissionResourceType,
    PermissionType,
    Proposal,
    Solution,
    SolutionTenderingType
} from '../../models';
import {
    useEffect,
    useMemo,
    useRef
} from 'react';

import { AlertMessage } from '../../lib/util/AlertMessage';
import GraphQLProposalRecordDAOFactory from '../../lib/proposal/dao/record/GraphQLProposalRecordDAOFactory';
import GraphQLSolutionDAOFactory from '../../lib/solution/majorVersion/dao/GraphQLSolutionDAOFactory';
import NoItemsPlaceholder from '../NoItemsPlaceholder';
import ProposalContent from '../../lib/design/bidding/ProposalContent';
import ProposalContentDAO from '../../lib/proposal/dao/content/ProposalContentDAO';
import { ResourcePermissionProvider } from '../../lib/permission/provider/ResourcePermissionProvider';
import { ResourcePermissionProviderFactory } from '../../lib/permission/provider/ResourcePermissionProviderFactory';
import S3ProposalContentDAOFactory from '../../lib/proposal/dao/content/S3ProposalContentDAOFactory';
import SolutionDAO from '../../lib/solution/majorVersion/dao/SolutionDAO';
import { SolutionListItem } from './SolutionListItem';
import { SolutionStatus } from '../../models';
import { isSavingSolutionAtom } from '../../lib/design/document/state/DocumentState';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';

export interface ListSolutionsProps {
    readonly propertyId: string;
}

const proposalDAO = GraphQLProposalRecordDAOFactory.getInstance();
const proposalContentDAO: ProposalContentDAO = S3ProposalContentDAOFactory.getInstance();

const ListSolutions = (props: ListSolutionsProps) => {
    const { propertyId } = props;

    const isSavingSolution = useRecoilValue<boolean>(isSavingSolutionAtom);
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const [solutions, setSolutions] = useState<Array<Solution>>([]);
    const [solutionIdToProposalPriceMap, setSolutionIdToProposalPriceMap] = useState<Map<string, number | undefined>>(new Map());
    const [solutionIdToProposalMap, setSolutionIdToProposalMap] = useState<Map<string, Proposal>>(new Map());
    const solutionDAORef = useRef<SolutionDAO>(GraphQLSolutionDAOFactory.getInstance());
    const resourcePermissionProviderRef = useRef<ResourcePermissionProvider>(ResourcePermissionProviderFactory.getInstance());
    const [solutionIdToAccessPermittedMap, setSolutionIdToAccessPermittedMap] = useState<Map<string, boolean>>(new Map());
    const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();

    useEffect(() => {
        const initializePage = async () => {
            try {
                await fetchDesignDocuments();
            } catch (error) {
                setAlertMessage({
                    severity: "error",
                    message: "Failed to fetch solutions, please check your internet connection and try again."
                });
            } finally {
                setIsPageLoading(false);
            }
        };
        if (!isSavingSolution) {
            initializePage();
        }
    }, [isSavingSolution]);

    const fetchDesignDocuments = async () => {
        const fetchedSolutions: Array<Solution> = await solutionDAORef.current.listByPropertyId(propertyId);
        const orderedSolutions: Array<Solution> = fetchedSolutions.sort((a, b) => {
            return (a as any)._lastChangedAt > (b as any)._lastChangedAt! ? -1 : 1;
        });
        await fetchSolutionPermissions(fetchedSolutions);
        await fetchProposalsAndPrices(fetchedSolutions);
        setSolutions(orderedSolutions);
    };

    const fetchProposalsAndPrices = async (solutions: Array<Solution>) => {
        const proposalPrices = new Map<string, number | undefined>();
        const proposals = new Map<string, Proposal>();
        await Promise.allSettled(solutions.map(async (solution) => {
            if (solution.status === SolutionStatus.PENDING_REVIEW && solution.metadata?.tenderingType !== SolutionTenderingType.TENDERED) {
                const proposal: Array<Proposal> = await proposalDAO.listBySolutionIdAndMinorVersion(solution.id, solution.latestMinorVersion!);
                if (proposal.length === 0) {
                    return;
                }
                const proposalContent: ProposalContent = await proposalContentDAO.get(proposal[0].proposalKey);
                proposals.set(solution.id, proposal[0]);
                proposalPrices.set(solution.id, proposalContent.priceAfterAdjustment);
            }
        }));
        setSolutionIdToProposalPriceMap(proposalPrices);
        setSolutionIdToProposalMap(proposals);
    };

    const fetchSolutionPermissions = async (fetchedSolutions: Array<Solution>) => {
        const solutionIdToAccessPermittedMap = new Map<string, boolean>();
        await Promise.all(fetchedSolutions.map(async (solution) => {
            const permissions = await resourcePermissionProviderRef.current.getCurrentUserPermissionsForResource(solution.id, PermissionResourceType.SOLUTION);
            if (solution.status === SolutionStatus.DRAFT) {
                solutionIdToAccessPermittedMap.set(solution.id, permissions.has(PermissionType.EDIT));
            }
            if (solution.status === SolutionStatus.PENDING_REVIEW) {
                solutionIdToAccessPermittedMap.set(solution.id, permissions.has(PermissionType.VIEW));
            }
        }));
        setSolutionIdToAccessPermittedMap(solutionIdToAccessPermittedMap);
    };

    const processedSolutions: Array<Solution> = useMemo(() => {
        // projectNumberToSolutionsMap contains at most one solution with each status for each proposal number
        const projectNumberToSolutionsMap = solutions.reduce((accumulatorMap, solution) => {
            const projectNumber: number = solution.serialNumber!;
            const solutions: Array<Solution> = accumulatorMap.get(projectNumber) ?? [];
            const indexOfSolutionWithSameStatus: number = solutions.findIndex((s) => s.status === solution.status);
            if (indexOfSolutionWithSameStatus === -1) {
                solutions.push(solution);
                accumulatorMap.set(projectNumber, solutions);
                return accumulatorMap;
            }
            const existingSolutionWithSameStatus: Solution = solutions[indexOfSolutionWithSameStatus];
            if (existingSolutionWithSameStatus.updatedAt! > solution.updatedAt!) {
                return accumulatorMap;
            }
            solutions[indexOfSolutionWithSameStatus] = solution;
            return accumulatorMap;
        }, new Map<number, Array<Solution>>());
        return Array.from(projectNumberToSolutionsMap.values()).flat();
    }, [solutions]);

    return (
        isPageLoading ? (
            <LinearProgress />
        ) : alertMessage != undefined ? (
            <Box>
                <Alert severity={alertMessage.severity}> {alertMessage.message}</Alert>
            </Box>
        ) : (
            <Box>
                <List disablePadding>
                    {processedSolutions.length === 0 && (
                        <Box
                            p={2}
                            style={{ textAlign: "center" }}
                            data-cy="test-no-images-placeholder"
                        >
                            <NoItemsPlaceholder text="No solutions found." />
                        </Box>
                    )}
                    {processedSolutions.map((solution, index) => (
                        <SolutionListItem
                            key={solution.id}
                            solution={solution}
                            propertyId={propertyId}
                            divider={index < solutions.length - 1}
                            disabled={!solutionIdToAccessPermittedMap.get(solution.id)}
                            onRemovedSolution={fetchDesignDocuments}
                            proposalPrice={solutionIdToProposalPriceMap.get(solution.id)}
                            proposal={solutionIdToProposalMap.get(solution.id)}
                        />
                    ))}
                </List>
            </Box>
        )
    );
};

export default ListSolutions;
