import { atom } from "recoil";
import { localForageMigrateToCookiesEffect } from "../design/state/RecoilEffect";

export const quickCreateLocationHotKeysAtom = atom<string>({
    key: 'quickCreateLocationHotKeysAtom',
    default: "F1",
    effects: [localForageMigrateToCookiesEffect("quickCreateLocationHotKeysAtom")]
});

export const quickCreateIssueHotKeysAtom = atom<string>({
    key: 'quickCreateIssueHotKeysAtom',
    default: "F2",
    effects: [localForageMigrateToCookiesEffect("quickCreateIssueHotKeysAtom")]
});

export const quickCreateWorkSpecificationHotKeysAtom = atom<string>({
    key: 'quickCreateWorkSpecificationHotKeysAtom',
    default: "F3",
    effects: [localForageMigrateToCookiesEffect("quickCreateWorkSpecificationHotKeysAtom")]
});

export const takeSnapshotHotKeysAtom = atom<string>({
    key: 'takeSnapshotHotKeysAtom',
    default: "F4",
    effects: [localForageMigrateToCookiesEffect("takeSnapshotHotKeysAtom")]
});

export const toggleMuteHotKeysAtom = atom<string>({
    key: 'toggleMuteHotKeysAtom',
    default: "Shift + s",
    effects: [localForageMigrateToCookiesEffect("toggleMuteHotKeysAtom")]
});

export const isHotKeyEnabledAtom = atom<boolean>({
    key: 'isHotKeyEnabledAtom',
    default: true,
});

export const isSubscribedToNewResourcesAtom = atom<boolean>({
    key: 'isSubscribedToNewResourcesAtom',
    default: false,
});