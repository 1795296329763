import {
    Collapse,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {
    Property,
    SolutionMetadata
} from "../../models";
import {
    TENDER_DETAILS_FORM_COMPONENT,
    componentReadyToRenderStateByComponentIdAtomFamily
} from "../../lib/design/document/state/DocumentInitializationState";
import {
    useEffect,
    useRef
} from "react";
import {
    useRecoilState,
    useSetRecoilState
} from "recoil";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddressFallbackPropertyNameFactory from "../../lib/util/property/AddressFallbackPropertyNameFactory";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { ComponentReadyToRenderState } from "../../lib/design/document/state/ComponentReadyToRenderState";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { H2_TYPOGRAPHY_STYLE } from "./TypographyConstants";
import { LocalizationProvider } from "@mui/x-date-pickers";
import MUI5TextField from "@mui/material/TextField";
import PhoneInputTextField from "../PhoneInputTextField";
import PhoneNumberInput from "react-phone-number-input/input";
import { SOLUTION_METADATA_VALIDATOR } from "../../lib/solution/metadata/SolutionMetadataValidator";
import { SolutionTenderingType } from "../../models";
import { solutionMetadataAtom } from "../../lib/design/document/state/DocumentState";
import { useFormik } from "formik";

export interface TenderDetailsFormProps {
    readonly property: Property;
    readonly showTenantDetails: boolean;
}

export const TenderDetailsForm = (props: TenderDetailsFormProps) => {
    /* Internal state */
    const { showTenantDetails, property } = props;
    const [solutionMetadata, setSolutionMetadata] = useRecoilState<SolutionMetadata>(solutionMetadataAtom);
    const setComponentReadyToRender = useSetRecoilState<ComponentReadyToRenderState>(componentReadyToRenderStateByComponentIdAtomFamily(TENDER_DETAILS_FORM_COMPONENT));

    /* Dependencies */
    const addressFallbackPropertyNameFactoryRef = useRef(new AddressFallbackPropertyNameFactory());

    useEffect(() => {
        if (Object.keys(solutionMetadata).length !== 0) {
            // This will force formik to evaluate fields in the case the document has been edited before
            formik.handleSubmit();
        }
        fetchUserDetailMetadata();
        return (() => {
            setComponentReadyToRender(ComponentReadyToRenderState.NOT_READY);
        });
    }, []);

    const fetchUserDetailMetadata = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
                user.getUserAttributes((error, result) => {
                    const phoneNumber = result?.find((attribute) => attribute.Name === "phone_number")?.Value || "";
                    const email = result?.find((attribute) => attribute.Name === "email")?.Value || "";
                    if (solutionMetadata.consultantEmail == null) {
                        formik.setFieldValue("consultantEmail", email);
                    }
                    if (solutionMetadata.consultantPhoneNumber == null) {
                        formik.setFieldValue("consultantPhoneNumber", phoneNumber);
                    }
                    setComponentReadyToRender(ComponentReadyToRenderState.READY);
                    resolve();
                });
            });
        });
    };

    const formik = useFormik<SolutionMetadata>({
        initialValues: {
            name: solutionMetadata.name || addressFallbackPropertyNameFactoryRef.current.getName(property),
            propertyContactName: solutionMetadata.propertyContactName || property.primaryContactName || "",
            propertyAddress: solutionMetadata.propertyAddress || property.address || "",
            propertyType: solutionMetadata.propertyType || "",
            solutionDueDate: solutionMetadata.solutionDueDate || null,
            consultantName: solutionMetadata.consultantName || "",
            consultantPhoneNumber: solutionMetadata.consultantPhoneNumber || "",
            consultantEmail: solutionMetadata.consultantEmail || "",
            tenderingType: solutionMetadata.tenderingType || SolutionTenderingType.SOLE_SOURCE
        },
        validationSchema: SOLUTION_METADATA_VALIDATOR,
        onSubmit: () => { }
    });

    useEffect(() => {
        setSolutionMetadata(formik.values);
    }, [formik.values]);

    return (
        <Collapse in={showTenantDetails}>
            <Grid container rowSpacing={0.5} columnSpacing={2}>
                <Grid item xs={12}>
                    <Typography {...H2_TYPOGRAPHY_STYLE}>
                        Document Name (Required)
                    </Typography>
                    <TextField
                        size="small"
                        fullWidth
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography {...H2_TYPOGRAPHY_STYLE}>
                        Property Address (Required)
                    </Typography>
                    <TextField
                        size="small"
                        fullWidth
                        id="propertyAddress"
                        name="propertyAddress"
                        value={formik.values.propertyAddress}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.propertyAddress && Boolean(formik.errors.propertyAddress)}
                        helperText={formik.touched.propertyAddress && formik.errors.propertyAddress}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography {...H2_TYPOGRAPHY_STYLE}>
                        Solution Tender Type
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            id="tenderingType"
                            name="tenderingType"
                            value={formik.values.tenderingType}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.tenderingType && Boolean(formik.errors.tenderingType)}
                            size="small"
                        >
                            <MenuItem value={SolutionTenderingType.SOLE_SOURCE}>Sole Source</MenuItem>
                            <MenuItem value={SolutionTenderingType.TENDERED}>Tendered</MenuItem>
                        </Select>
                        {
                            formik.touched.tenderingType && formik.errors.tenderingType && (
                                <FormHelperText error>
                                    {formik.touched.tenderingType && formik.errors.tenderingType}
                                </FormHelperText>
                            )
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                        >
                            <Typography {...H2_TYPOGRAPHY_STYLE}>
                                Bid Due Date
                            </Typography>
                            <DatePicker
                                disablePast
                                renderInput={(params) => {
                                    return (
                                        <MUI5TextField
                                            id="solutionDueDate"
                                            name="solutionDueDate"
                                            error={formik.touched.solutionDueDate && Boolean(formik.errors.solutionDueDate)}
                                            helperText={formik.touched.solutionDueDate && formik.errors.solutionDueDate}
                                            onBlur={formik.handleBlur}
                                            size="small"
                                            {...params}
                                        />
                                    );
                                }}
                                value={formik.values.solutionDueDate ? new Date(parseInt(formik.values.solutionDueDate)) : null}
                                onChange={(dateToSet) => {
                                    formik.setFieldValue("solutionDueDate", dateToSet?.getTime().toString());
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography {...H2_TYPOGRAPHY_STYLE}>
                        Customer Name (Required)
                    </Typography>
                    <TextField
                        size="small"
                        fullWidth
                        id="propertyContactName"
                        name="propertyContactName"
                        value={formik.values.propertyContactName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.propertyContactName && Boolean(formik.errors.propertyContactName)}
                        helperText={formik.touched.propertyContactName && formik.errors.propertyContactName}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography {...H2_TYPOGRAPHY_STYLE}>
                        Contact Name
                    </Typography>
                    <TextField
                        size="small"
                        fullWidth
                        id="consultantName"
                        name="consultantName"
                        value={formik.values.consultantName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.consultantName && Boolean(formik.errors.consultantName)}
                        helperText={formik.touched.consultantName && formik.errors.consultantName}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography {...H2_TYPOGRAPHY_STYLE}>
                        Contact Email
                    </Typography>
                    <TextField
                        size="small"
                        fullWidth
                        id="consultantEmail"
                        name="consultantEmail"
                        value={formik.values.consultantEmail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.consultantEmail && Boolean(formik.errors.consultantEmail)}
                        helperText={formik.touched.consultantEmail && formik.errors.consultantEmail}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography {...H2_TYPOGRAPHY_STYLE}>
                        Contact Number
                    </Typography>
                    <PhoneNumberInput
                        defaultCountry="US"
                        fullWidth
                        inputComponent={PhoneInputTextField as any}
                        id="consultantPhoneNumber"
                        name="consultantPhoneNumber"
                        value={formik.values.consultantPhoneNumber!}
                        onChange={(phoneNumber) => {
                            formik.setFieldValue("consultantPhoneNumber", phoneNumber ?? "");
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched.consultantPhoneNumber && Boolean(formik.errors.consultantPhoneNumber)}
                        helperText={formik.touched.consultantPhoneNumber && formik.errors.consultantPhoneNumber}
                        variant="outlined"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography {...H2_TYPOGRAPHY_STYLE}>
                        Property Type
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            id="propertyType"
                            name="propertyType"
                            value={formik.values.propertyType}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.propertyType && Boolean(formik.errors.propertyType)}
                            size="small"
                        >
                            <MenuItem value={"Commercial"}>Commercial</MenuItem>
                            <MenuItem value={"Residential"}>Residential</MenuItem>
                        </Select>
                        {
                            formik.touched.propertyType && formik.errors.propertyType && (
                                <FormHelperText error>
                                    {formik.touched.propertyType && formik.errors.propertyType}
                                </FormHelperText>
                            )
                        }
                    </FormControl>
                </Grid>
            </Grid>
        </Collapse >
    );
};
