import {
    CreateExternalReportBrandingMutationVariables,
    GetExternalReportBrandingQuery,
    GetExternalReportBrandingQueryVariables,
    ListExternalReportBrandingsByCreatedByQuery,
    ListExternalReportBrandingsByCreatedByQueryVariables,
} from "../../API";
import {
    GraphQLResult,
    graphqlOperation
} from "@aws-amplify/api-graphql";
import {
    getExternalReportBranding,
    listExternalReportBrandingsByCreatedBy
} from "../../graphql/queries";

import { API } from "aws-amplify";
import ClientLogger from "../logging/ClientLogger";
import { CreateExternalReportBrandingError } from "./error/CreateExternalReportBrandingError";
import { ExternalReportBranding } from "../../models";
import { ExternalReportBrandingDAO } from "./ExternalReportBrandingDAO";
import { GetExternalReportBrandingError } from "./error/GetExternalReportBrandingError";
import { IDTokenSupplier } from "../auth/IDTokenSupplier";
import { ListExternalReportBrandingsError } from "./error/ListExternalReportBrandingsError";
import { createExternalReportBranding } from "../../graphql/mutations";

export class GraphQLExternalReportBrandingDAO implements ExternalReportBrandingDAO {
    private static readonly GET_ATTEMPT = "GraphQLExternalReportBrandingDAO.get.Attempt";
    private static readonly GET_FAILURE = "GraphQLExternalReportBrandingDAO.get.Failure";
    private static readonly CREATE_ATTEMPT = "GraphQLExternalReportBrandingDAO.create.Attempt";
    private static readonly CREATE_FAILURE = "GraphQLExternalReportBrandingDAO.create.Failure";
    private static readonly LIST_ATTEMPT = "GraphQLExternalReportBrandingDAO.list.Attempt";
    private static readonly LIST_FAILURE = "GraphQLExternalReportBrandingDAO.list.Failure";

    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async get(id: string): Promise<ExternalReportBranding> {
        this.logger.info(
            `Retrieving ExternalReportBranding with id=${id}`,
            undefined,
            [GraphQLExternalReportBrandingDAO.GET_ATTEMPT]
        );
        try {
            const authToken: string = await this.idTokenSupplier.get();
            const variables: GetExternalReportBrandingQueryVariables = { id };
            const result = await API.graphql<GraphQLResult<GetExternalReportBrandingQuery>>(
                graphqlOperation(getExternalReportBranding, variables, authToken)
            ) as GraphQLResult<GetExternalReportBrandingQuery>;
            return result.data!.getExternalReportBranding as ExternalReportBranding;
        } catch (error) {
            const message = `Failed to retrieve ExternalReportBranding with id=${id}`;
            this.logger.error(
                message,
                error,
                [GraphQLExternalReportBrandingDAO.GET_FAILURE]
            );
            throw new GetExternalReportBrandingError(message); 
        }
    }

    public async create(externalReportBranding: ExternalReportBranding): Promise<void> {
        this.logger.info(
            `Creating ExternalReportBranding`,
            undefined,
            [GraphQLExternalReportBrandingDAO.CREATE_ATTEMPT]
        );
        try {
            const authToken: string = await this.idTokenSupplier.get();
            const variables: CreateExternalReportBrandingMutationVariables = {
                input: externalReportBranding
            };
            await API.graphql(
                graphqlOperation(createExternalReportBranding, variables, authToken)
            );
        } catch (error) {
            const message = `Failed to create ExternalReportBranding`;
            this.logger.error(
                message,
                error,
                [GraphQLExternalReportBrandingDAO.CREATE_FAILURE]
            );
            throw new CreateExternalReportBrandingError(message); 
        }
    }
    
    public async listByCreatedBy(createdBy: string, limit?: number, nextToken?: string): Promise<Array<ExternalReportBranding>> {
        this.logger.info(
            `Listing ExternalReportBranding`,
            undefined,
            [GraphQLExternalReportBrandingDAO.LIST_ATTEMPT]
        );
        try {
            const authToken: string = await this.idTokenSupplier.get();
            const variables: ListExternalReportBrandingsByCreatedByQueryVariables = { createdBy, limit, nextToken };
            const result = await API.graphql(
                graphqlOperation(listExternalReportBrandingsByCreatedBy, variables, authToken)
            ) as GraphQLResult<ListExternalReportBrandingsByCreatedByQuery>;
            return result.data!.listExternalReportBrandingsByCreatedBy!.items as Array<ExternalReportBranding>;
        } catch (error) {
            const message = `Failed to list ExternalReportBranding`;
            this.logger.error(
                message,
                error,
                [GraphQLExternalReportBrandingDAO.LIST_FAILURE]
            );
            throw new ListExternalReportBrandingsError(message); 
        }
    }
}