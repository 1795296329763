import RecentlyUsedItemsDAO from "./RecentlyUsedItemsDAO";
import localforage from "localforage";

export default class LocalForageBackedRecentlyUsedItemsDAO<T> implements RecentlyUsedItemsDAO<T> {
    private readonly recentlyUsedTableKey: string;
    private readonly maxItemsToKeep: number;

    constructor(recentlyUsedTableKey: string, maxItemsToKeep: number) {
        this.recentlyUsedTableKey = recentlyUsedTableKey;
        this.maxItemsToKeep = maxItemsToKeep;
    }

    public async getRecentlyUsed(): Promise<Array<T>> {
        const result: Array<T> = await localforage.getItem(this.recentlyUsedTableKey) || new Array<T>();
        return result;
    }

    public async addRecentlyUsed(
        itemToAdd: T
    ): Promise<void> {
        const recentlyUsed: Array<T> = await localforage.getItem(this.recentlyUsedTableKey) || new Array<T>();

        const index: number = recentlyUsed.findIndex((item: T) => JSON.stringify(item) === JSON.stringify(itemToAdd));
        if (index !== -1) {
            recentlyUsed.splice(index, 1);
            recentlyUsed.unshift(itemToAdd);
            localforage.setItem(this.recentlyUsedTableKey, recentlyUsed);
            return;
        }

        if (recentlyUsed.length >= this.maxItemsToKeep) {
            recentlyUsed.pop();
        }
        recentlyUsed.unshift(itemToAdd);
        localforage.setItem(this.recentlyUsedTableKey, recentlyUsed);
    }
}