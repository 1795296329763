import {
     API,
     graphqlOperation
} from "aws-amplify";

import { AuthIDTokenSupplierFactory } from "../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../logging/ClientLoggerFactory";
import GraphQLUserOnboardingProgressDAO from "./GraphQLUserOnboardingProgressDAO";

export default class GraphQLUserOnboardingProgressDAOFactory {
    private static readonly INSTANCE = new GraphQLUserOnboardingProgressDAO(
        API,
        graphqlOperation,
        ClientLoggerFactory.getClientLogger("GraphQLUserOnboardingProgressDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );
    public static getInstance() {
        return this.INSTANCE;
    }
}