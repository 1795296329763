import {
     DataStore,
     SortDirection
} from "aws-amplify";
import {
    useEffect,
    useState
} from "react";

import DataStorePropertyDAOFactory from "../../lib/property/dao/datastore/DataStorePropertyDAOFactory";
import { Property } from "../../models";
import PropertyDAO from "../../lib/property/dao/PropertyDAO";
import { SearchBox } from "../misc/SearchBox";
import { TextFieldProps } from "@mui/material";

type PropertySearchBoxProps = {
    readonly id?: string;
    readonly onSelect: (option: Property | null) => void;
    readonly onClear: () => void;
    readonly label: string;
    readonly initialPropertyId: string | undefined;
} & Omit<TextFieldProps, "onSelect" | "value">;

const propertyDAO: PropertyDAO= DataStorePropertyDAOFactory.getInstance();

export const PropertySearchBox = (props: PropertySearchBoxProps) => {
    const { id, onSelect, onClear, label, initialPropertyId, ...textFieldProps } = props;
    const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);

    useEffect(() => {
        const initializeProperty = async () => {
            if (initialPropertyId) {
                const property: Property = await propertyDAO.getById(initialPropertyId);
                setSelectedProperty(property);
            }
        };
        initializeProperty();
    }, []);

    return (
        <SearchBox
            label={label}
            labelFields={["name", "address"]}
            onClear={() => {
                setSelectedProperty(null);
                onClear();
            }}
            onSelect={(option: Property | null) => {
                onSelect(option);
                setSelectedProperty(option);
            }}
            search={async (searchValue, nextToken) => {
                const items:Array<Property> = await DataStore.query(
                    Property,
                    p => p.or(p => p.address("contains", searchValue).name("contains", searchValue)),
                    {sort: s => s.localLastUpdatedTime(SortDirection.DESCENDING).updatedAt(SortDirection.DESCENDING)}
                )
                return {
                    objects: items,
                    total: items.length
                }
            }}
            value={selectedProperty}
            typeName="Property"
            textFieldStyle={textFieldProps}
            searchWithoutSearchTerm={true}
        />
    );
};

export default PropertySearchBox;
