import ClientLoggerFactory from "../../../../../logging/ClientLoggerFactory";
import JSONDeserializerFactory from "../../../../../util/data/serialization/JSONDeserializerFactory";
import JSONSerializerFactory from "../../../../../util/data/serialization/JSONSerializerFactory";
import S3SolutionMinorVersionContentDAO from "./S3SolutionMinorVersionContentDAO";
import SolutionMinorVersionContentDAO from "./SolutionMinorVersionContentDAO";
import { Storage } from "aws-amplify";

export default class S3SolutionMinorVersionContentDAOFactory {
    private static readonly INSTANCE: SolutionMinorVersionContentDAO = new S3SolutionMinorVersionContentDAO(
        JSONSerializerFactory.getInstance(),
        JSONDeserializerFactory.getInstance(),
        Storage,
        ClientLoggerFactory.getClientLogger("S3SolutionMinorVersionContentDAO")
    );

    public static getInstance(): SolutionMinorVersionContentDAO {
        return S3SolutionMinorVersionContentDAOFactory.INSTANCE;
    }
}
