import DataStoreEntityWorkTypeAssociationDAOFactory from "../../relationship/dataStore/DataStoreEntityWorkTypeAssociationDAOFactory";
import DissociateWorkTypeWithEntityHandler from "./DissociateWorkTypeWithEntityHandler";
import DissociateWorkTypeWithEntityHandlerImpl from "./DissociateWorkTypeWithEntityHandlerImpl";

export default class DissociateWorkTypeWithEntityHandlerFactory {
    private static readonly INSTANCE: DissociateWorkTypeWithEntityHandler = new DissociateWorkTypeWithEntityHandlerImpl(
        DataStoreEntityWorkTypeAssociationDAOFactory.getInstance()
    );

    public static getInstance(): DissociateWorkTypeWithEntityHandler {
        return this.INSTANCE;
    }
}
