import { PermissionDifference } from "./PermissionDifference";
import { PermissionType } from "../../../API";

export class PermissionDifferenceCalculator {
    public getDifference(
        currentPermissions: Array<PermissionType>,
        newPermissions: Array<PermissionType>
    ): PermissionDifference {
        const permissionsToAdd = newPermissions.filter(permission => !currentPermissions.includes(permission));
        const permissionsToRemove = currentPermissions.filter(permission => !newPermissions.includes(permission));
        return {permissionsToAdd, permissionsToRemove}
    }
}