import { AuthIDTokenSupplierFactory } from "../../../auth/AuthIDTokenSupplierFactory";
import AuthUsernameSupplierFactory from "../../../auth/AuthUsernameSupplierFactory";
import { DataStoreConfigurationManager } from "./DataStoreConfigurationManager";
import { HierarcicalDataStoreConfigurationManager } from "./HierarcicalDataStoreConfigurationManager";

export class HierarchicalLegacyDataStoreConfigurationManagerFactory {
    private static INSTANCE = new HierarcicalDataStoreConfigurationManager(
        AuthIDTokenSupplierFactory.getInstance(),
        AuthUsernameSupplierFactory.getInstance()
    );

    public static getInstance(): DataStoreConfigurationManager {
        return this.INSTANCE;
    }
}