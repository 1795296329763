import { DataStoreEntityResourcePermissionDAOFactory } from "../dao/DataStoreEntityResourcePermissionDAOFactory"
import { EntityResourcePermissionDAO } from "../dao/EntityResourcePermissionDAO"
import { GraphQLEntityResourcePermissionDAOFactory } from "../dao/GraphQLEntityResourcePermissionDAOFactory"
import { PermissionResourceType } from "../../../models"

export class ResourceTypeToEntityResourceDAOMapFactory {
    private static INSTANCE = new Map<PermissionResourceType, EntityResourcePermissionDAO>([
        [PermissionResourceType.PROPERTY, DataStoreEntityResourcePermissionDAOFactory.getInstance()],
        [PermissionResourceType.LOCATION, DataStoreEntityResourcePermissionDAOFactory.getInstance()],
        [PermissionResourceType.ISSUE, DataStoreEntityResourcePermissionDAOFactory.getInstance()],
        [PermissionResourceType.DIMENSION, DataStoreEntityResourcePermissionDAOFactory.getInstance()],
        [PermissionResourceType.WORK_SPECIFICATION, DataStoreEntityResourcePermissionDAOFactory.getInstance()],
        [PermissionResourceType.SOLUTION, GraphQLEntityResourcePermissionDAOFactory.getInstance()],
        [PermissionResourceType.PROPOSAL, GraphQLEntityResourcePermissionDAOFactory.getInstance()],
    ]);

    public static getInstance() {
        return this.INSTANCE;
    }
}