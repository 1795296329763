import {
    ReactNode,
    useEffect,
    useState
} from "react";

import { LinearProgress } from "@mui/material";
import { actingAsEntityIdAtom } from "../lib/userSession/state/UserSessionState";
import { useFetchEntityWorkTypes } from "../lib/worktype/hooks/useFetchEntityWorkTypes";
import { useRecoilValue } from "recoil";

type WithWorkTypeFetchProps = {
    children: ReactNode;
};

export const WorkTypeFetchWrapper = ({ children }: WithWorkTypeFetchProps) => {
    const actingAsEntityId = useRecoilValue<string>(actingAsEntityIdAtom);
    const [isFetchingWorkTypes, setIsFetchingWorkTypes] = useState<boolean>(true);
    const { fetchEntityWorkTypes } = useFetchEntityWorkTypes();

    useEffect(() => {
        const fetchWorkType = async () => {
            await fetchEntityWorkTypes(actingAsEntityId);
            setIsFetchingWorkTypes(false);
        };
        fetchWorkType();
    }, []);

    return (
        <>
            {isFetchingWorkTypes ?
                <LinearProgress /> :
                children
            }
        </>
    );
};
