import { AuthIDTokenSupplierFactory } from "../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { GraphQLResourceAggregateUserPermissionDAO } from "./GraphQLResourceAggregateUserPermissionDAO";
import { ResourceAggregateUserPermissionDAO } from "./ResourceAggregateUserPermissionDAO";

export class GraphQLResourceAggregateUserPermissionDAOFactory {
    private static readonly INSTANCE: ResourceAggregateUserPermissionDAO = new GraphQLResourceAggregateUserPermissionDAO(
        ClientLoggerFactory.getClientLogger("GraphQLResourceAggregateUserPermissionDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance(): ResourceAggregateUserPermissionDAO {
        return this.INSTANCE;
    }
}