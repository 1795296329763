import { AttachmentContent } from "../AttachmentContent";
import AttachmentContentDAO from "../AttachmentContentDAO";
import ClientLogger from "../../../logging/ClientLogger";
import { S3AttachmentContentDAO } from "./S3AttachmentContentDAO";

export class MetricLoggingS3AttachmentContentDAODecorator implements AttachmentContentDAO {
    public static GET_ATTACHMENT_URL_S3_ATTEMPT = "getAttachmentURLByAttachmentKeyS3Attempt"
    public static GET_ATTACHMENT_URL_S3_FAILURE = "getAttachmentURLByAttachmentKeyS3Failure"
    public static SAVE_ATTACHMENT_S3_ATTEMPT = "saveAttachmentS3Attempt";
    public static SAVE_ATTACHMENT_S3_FAILURE = "saveAttachmentS3Failure";

    private delegate: S3AttachmentContentDAO;
    private logger: ClientLogger;

    constructor(
        delegate: S3AttachmentContentDAO,
        logger: ClientLogger
    ){
        this.delegate = delegate;
        this.logger = logger;
    }

    public async getAttachmentContentByAttachmentKey(key: string, variant?: any): Promise<AttachmentContent> {
        try {
            this.logger.info(
                `Attempting to get Attachment URL from S3 for AttachmentKey: ${key} variant: ${variant}`,
                undefined,
                [MetricLoggingS3AttachmentContentDAODecorator.GET_ATTACHMENT_URL_S3_ATTEMPT]
            )
            return await this.delegate.getAttachmentContentByAttachmentKey(key, variant);
        } catch (error) {
            this.logger.error(
                `Failed to get item from S3 for AttachmentKey: ${key} variant: ${variant}`,
                error,
                [MetricLoggingS3AttachmentContentDAODecorator.GET_ATTACHMENT_URL_S3_FAILURE]
            )
            throw error;
        }
    }

    public async listAttachmentContentKeys(): Promise<string[]> {
        throw new Error("Method not implemented.");
    }

    public async saveAttachment(attachmentKey: string, attachmentContent: AttachmentContent): Promise<void> {
        try {
            this.logger.info(
                `Attempting to save attachment to S3 ${attachmentKey}`,
                undefined,
                [MetricLoggingS3AttachmentContentDAODecorator.SAVE_ATTACHMENT_S3_ATTEMPT]
            )
            return await this.delegate.saveAttachment(attachmentKey, attachmentContent);
        } catch (error) {
            this.logger.error(
                `Failed to save attachment ${attachmentKey} to S3`,
                error,
                [MetricLoggingS3AttachmentContentDAODecorator.SAVE_ATTACHMENT_S3_FAILURE]
            )
            throw error;
        }
    }
}