import {
    idInFocusByModelTypeAtomFamily,
    propertyIdInFocusAtom,
    stateContextInFocusAtom
} from "../../lib/ui/InFocusRecoilStates";

import { AddWorktypeIcon } from "../icons";
import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { ModelType } from "../../lib/design/document/ModelType";
import { PermissionType } from "../../models";
import { QuickAccessKey } from "./QuickAccessKey";
import { SolutionAuthoringEnabledPermissionRestrictedControl } from "../general/button/SolutionAuthoringEnabledPermissionRestrictedControl";
import { StateContext } from '../../lib/design/document/state/StateContext';
import { dimensionIdsByIssueIdentifierAtomFamily } from "../../lib/design/document/state/DocumentState";
import { getEndOfIssueRefId } from "../issue/name/getEndOfIssueRefId";
import { useCreateWorkSpecification } from "../../lib/design/workscopespecification/hook/useCreateWorkSpecification";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { useScrollResourceIntoView } from "../../lib/ui/hooks/useScrollResourceIntoView";

export const QuickCreateWorkSpecificationKey = () => {
    const contextInFocus = useRecoilValue<StateContext>(stateContextInFocusAtom);
    const propertyIdInFocus = useRecoilValue<string | null>(propertyIdInFocusAtom);
    const issueIdInFocus = useRecoilValue<string | null>(idInFocusByModelTypeAtomFamily(ModelType.ISSUE));
    const issueIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(issueIdInFocus ?? "", contextInFocus, ModelType.ISSUE);
    }, [issueIdInFocus, contextInFocus]);
    const dimensionIds = useRecoilValue(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier));

    const endOfWsSectionId = useMemo<string>(() => {
        if (!issueIdInFocus) {
            return "";
        }
        return getEndOfIssueRefId(issueIdInFocus)
    }, [issueIdInFocus]);

    const { createDefaultWorkSpecification } = useCreateWorkSpecification(contextInFocus);
    const { scrollResourceIntoView } = useScrollResourceIntoView(contextInFocus);

    return (
        <SolutionAuthoringEnabledPermissionRestrictedControl
            context={contextInFocus}
            requiredPermission={PermissionType.CREATE_CHILDREN}
            resourceId={issueIdInFocus ?? ""}
        >
            {(disabled) => (
                <QuickAccessKey
                    disabled={disabled || !issueIdInFocus || !propertyIdInFocus}
                    onClick={async () => {
                        if (!issueIdInFocus || !propertyIdInFocus) {
                            return;
                        }
                        await createDefaultWorkSpecification(dimensionIds[0], issueIdInFocus, propertyIdInFocus, true);
                        const lastWorkSpecificationElement = document.getElementById(endOfWsSectionId);
                        scrollResourceIntoView(lastWorkSpecificationElement, "bottom");
                    }}
                    in
                >
                    <AddWorktypeIcon accent={disabled ? "disabled" : "primary"} />
                </QuickAccessKey>
            )}
        </SolutionAuthoringEnabledPermissionRestrictedControl>
    );
};