import Envelope from "./Envelope";
import uuidv4 from "../util/UuidGenerator";

export default class EnvelopeFactory {

    public static createEmpty(dataType: string): Envelope {
        return {
            header: {
                id: uuidv4(),
                dataType: dataType,
                variant: "Empty",
                version: 1
            },
            body: null
        }
    }

    public static createFromExistingRecord<T>(
        dataType: string,
        version: number,
        body: T,
        id: string,
        variant: string = "Default"
    ) {
        return {
            header: {
                id: id,
                dataType: dataType,
                variant: variant,
                version: version,
            },
            body: body
        }
    };

    public static create<T>(
        dataType: string,
        version: number,
        body: T,
        variant: string = "Default"
    ): Envelope {
        return {
            header: {
                id: uuidv4(),
                dataType: dataType,
                variant: variant,
                version: version,
            },
            body: body
        }
    }

    public static update<T>(
        existingEnvelope: Envelope,
        dataType: string,
        version: number,
        body: T,
        variant: string = "Default"
    ): Envelope {
        return {
            header: {
                id: existingEnvelope.header.id,
                dataType: dataType,
                variant: variant,
                version: version
            },
            body: body
        }
    }

    public static updateBody<T>(
        existingEnvelope: Envelope,
        body: T
    ): Envelope {
        return {
            ...existingEnvelope,
            body: body
        };
    }
}
