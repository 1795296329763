import { PermissionTemplate } from "./PermissionTemplate";
import { PermissionType } from "../../../API";
import { TEMPLATE_TYPE_TO_PERMISSION_MAP } from "./PermissionTemplateConstants";

export class PermissionsToPermissionTemplateConverter {
    public static convert(permissions: Array<PermissionType>): PermissionTemplate {
        for (const entry of TEMPLATE_TYPE_TO_PERMISSION_MAP.entries()) {
            if (this.areListsEqual(entry[1], permissions)) {
                return entry[0];
            }
        }
        return PermissionTemplate.CUSTOM;
    };

    private static areListsEqual(listA: Array<PermissionType>, listB: Array<PermissionType>): boolean {
        if (listA.length !== listB.length) {
            return false;
        }
        const sortedListA = listA.slice().sort();
        const sortedListB = listB.slice().sort();
        return sortedListA.every((item, index) => item === sortedListB[index]);
    }
}