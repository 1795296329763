import * as yup from "yup";

import {
    Alert,
    Box,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {
    LinkAction,
    LinkRegistry
} from "../../models";
import {
    useMemo,
    useState
} from "react";

import { AlertMessage } from "../../lib/util/AlertMessage";
import { GraphQLLinkRegistryDAOFactory } from "../../lib/linkRegistry/dao/GraphQLLinkRegistryDAOFactory";
import { LinkRegistryDAO } from "../../lib/linkRegistry/dao/LinkRegistryDAO";
import LoadingButton from "@mui/lab/LoadingButton";
import { VariablesForm } from "./VariablesForm";
import _ from "lodash";
import { linkRegistryFormSchema } from "../../lib/linkRegistry/schema/LinkRegistryFormSchema";
import theme from "../../assets/style/theme";
import { useFormik } from "formik";

type LinkRegistryFormProps = {
    id: string;
    linkRegistry: LinkRegistry | undefined;
    onSaved: () => (void | Promise<void>);
};

type LinkRegistryFormData = yup.InferType<typeof linkRegistryFormSchema>;

const linkRegistryDAO: LinkRegistryDAO = GraphQLLinkRegistryDAOFactory.getInstance();

export const LinkRegistryForm = (props: LinkRegistryFormProps) => {
    const { id, linkRegistry, onSaved } = props;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();

    const initialValues = useMemo<LinkRegistryFormData>(() => ({
        linkAction: (linkRegistry?.action ?? LinkAction.UNASSIGNED) as LinkAction,
        variables: linkRegistry?.variables ? JSON.parse(linkRegistry.variables) : {}
    }), [linkRegistry]);

    const onSubmitForm = async (data: LinkRegistryFormData) => {
        try {
            setIsSubmitting(true);
            setAlertMessage(undefined);
            if (linkRegistry) {
                await linkRegistryDAO.update({
                    id: id,
                    action: data.linkAction as LinkAction,
                    variables: JSON.stringify(data.variables),
                });
                setAlertMessage({
                    severity: "success",
                    message: "Link configuration updated successfully"
                });
                await onSaved();
                return;
            }
            await linkRegistryDAO.create({
                id: id,
                action: data.linkAction as LinkAction,
                variables: JSON.stringify(data.variables)
            });
            setAlertMessage({
                severity: "success",
                message: "Link configured successfully"
            });
            await onSaved();
        } catch (error) {
            setAlertMessage({
                severity: "error",
                message: `Failed to ${linkRegistry ? "update" : "create"} link configuration: ${(error as Error).message}`
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const formik = useFormik<LinkRegistryFormData>({
        initialValues: initialValues,
        validationSchema: linkRegistryFormSchema,
        onSubmit: onSubmitForm
    });

    const hasFormChanged = useMemo(() => {
        const areEqual = _.isEqual(formik.values, initialValues);
        return !areEqual;
    }, [formik.values, initialValues]);

    return (
        <Container component="main">
            <Box
                my={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                    width="100%"
                    maxWidth={theme.breakpoints.values["md"]}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="linkId"
                                label="Link ID"
                                name="linkId"
                                value={id}
                                disabled
                            />
                        </Grid>
                        <Grid container item xs={12} spacing={2} alignContent="flex-start">
                            <Grid item xs={12}>
                                <Typography variant="h6">Link Behaviour</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                    error={formik.touched.linkAction && Boolean(formik.errors.linkAction)}
                                >
                                    <InputLabel id="linkAction-label">Link to</InputLabel>
                                    <Select
                                        id="linkAction"
                                        labelId="linkAction-label"
                                        label="Link To"
                                        name="linkAction"
                                        value={formik.values.linkAction}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                    >
                                        <MenuItem value={LinkAction.UNASSIGNED}>Unassigned</MenuItem>
                                        <MenuItem value={LinkAction.ISSUE_FORM}>Issue Submission form</MenuItem>
                                        <MenuItem value={LinkAction.PROPERTY_FORM}>Property Sign-Up form</MenuItem>
                                        <MenuItem value={LinkAction.EXTERNAL_REPORT}>Custom Issue Submission form</MenuItem>
                                    </Select>
                                    <FormHelperText>{formik.touched.linkAction && formik.errors.linkAction}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <VariablesForm
                                formik={formik}
                            />
                        </Grid>
                        {alertMessage && (
                            <Grid item xs={12}>
                                <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                loading={isSubmitting}
                                startIcon={<></>}
                                loadingPosition="start"
                                disabled={!formik.isValid || !hasFormChanged}
                            >
                                Submit
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

