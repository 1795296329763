import {
    API,
    graphqlOperation
} from "aws-amplify";

import { AuthIDTokenSupplierFactory } from "../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../logging/ClientLoggerFactory";
import CounterAdoptingSerialNumberGenerator from "./CounterAdoptingSerialNumberGenerator";
import SerialNumberGenerator from "./SerialNumberGenerator";

export default class CounterAdoptingSerialNumberGeneratorFactory {
    private static readonly INSTANCE: SerialNumberGenerator = new CounterAdoptingSerialNumberGenerator(
        ClientLoggerFactory.getClientLogger("CounterAdoptingSerialNumberGenerator"),
        API,
        graphqlOperation,
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance(): SerialNumberGenerator {
        return CounterAdoptingSerialNumberGeneratorFactory.INSTANCE;
    }
}
