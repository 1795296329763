import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const RejectedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="M6.146 6.146 5 7.293 3.854 6.146l-.708.708L4.293 8 3.146 9.146l.708.708L5 8.707l1.146 1.147.708-.708L5.707 8l1.147-1.146zM6.146 11.146 5 12.293l-1.146-1.147-.708.708L4.293 13l-1.147 1.146.708.708L5 13.707l1.146 1.147.708-.708L5.707 13l1.147-1.146zM6.146 16.146 5 17.293l-1.146-1.147-.708.708L4.293 18l-1.147 1.146.708.708L5 18.707l1.146 1.147.708-.708L5.707 18l1.147-1.146z"
        className="primary"
      />
      <path
        fill="currentColor"
        d="M0 0v22h10.726a8.037 8.037 0 0 1-.961-1H1V1h14v7.069A8.047 8.047 0 0 1 16 8V0H0z"
        className="primary"
      />
      <path
        fill="currentColor"
        d="M8 3h6v1H8zM8 7h6v1H8zM9.082 12H8v1h.589a8 8 0 0 1 .493-1zM6.146 2.146 4.5 3.793l-.646-.647-.708.708L4.5 5.207l2.354-2.353z"
        className="primary"
      />
    </g>
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="M16 12c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm2.054 5.347-.707.707L16 16.707l-1.347 1.347-.707-.707L15.293 16l-1.347-1.347.707-.707L16 15.293l1.347-1.347.707.707L16.707 16l1.347 1.347z"
        className="accent"
      />
      <path
        fill="currentColor"
        d="M16 9a7 7 0 1 0 0 14 7 7 0 0 0 0-14zm0 12a5 5 0 1 1 .001-10.001A5 5 0 0 1 16 21z"
        className="accent"
      />
    </g>
  </svg>
);
export default withCustomIcon(RejectedIcon);
