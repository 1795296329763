import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const ReportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="M3 0v24h18V0H3zm17 23H4V1h16v22z"
        className="primary"
      />
      <path
        fill="currentColor"
        d="M9 17H5v4h4v-4zm-1 3H6v-2h2v2zM10 21h4v-4h-4v4zm1-3h2v2h-2v-2zM15 21h4v-4h-4v4zm1-3h2v2h-2v-2z"
        className="accent"
      />
      <path
        fill="currentColor"
        d="M5 15h7v1H5zM5 12h5v1H5zM5 10h9v1H5zM11 8h8v1h-8zM8 6h8v1H8zM11 12h3v1h-3zM15 10h4v1h-4zM17 4H8V3h9V2H7v3h10z"
        className="primary"
      />
    </g>
  </svg>
);
export default withCustomIcon(ReportIcon);
