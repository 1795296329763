import {
    attachmentRecordsByIdentifierAtomFamily,
    issueIdToIssueMapByContextSelectorFamily,
    resizedImageUrlByAttachmentKeyAtomFamily
} from "../../design/document/state/DocumentState";

import { Attachment } from "../../../models";
import AttachmentContentDAO from "../../attachment/content/AttachmentContentDAO";
import AttachmentRecordDAO from "../../attachment/record/AttachmentRecordDAO";
import CachingAttachmentContentDAOFactory from "../../attachment/content/cache/CachingAttachmentContentDAOFactory";
import { ContextAwareIdentifier } from "../../design/document/ContextAwareIdentifier";
import DataStoreAttachmentRecordDAOFactory from "../../attachment/record/datastore/DataStoreAttachmentRecordDAOFactory";
import Jimp from "jimp";
import { ModelType } from "../../design/document/ModelType";
import SolutionContent from "../../design/types/SolutionContent";
import { StateContext } from "../../design/document/state/StateContext";
import { encode as arrayBufferToBase64 } from "base64-arraybuffer";
import { clipboardImageDimensionsMap } from "../image/ViewSolutionImageConstants";
import { useRecoilCallback } from "recoil";

const attachmentRecordDAO: AttachmentRecordDAO = DataStoreAttachmentRecordDAOFactory.getInstance();
const attachmentContentDAO: AttachmentContentDAO = CachingAttachmentContentDAOFactory.getInstance();
export const EMPTY_IMAGE = "EMPTY_IMAGE";

export const useFetchSolutionImages = (context: StateContext) => {

    const resizeImage = async (
        imageUrl: string,
        width: number,
        height: number
    ) => {
        const jimpImage = await Jimp.read(imageUrl);
        jimpImage.cover(width, height);
        return await jimpImage.getBase64Async(Jimp.MIME_JPEG);
    };

    const fetchSolutionAttachments = useRecoilCallback(({ set }) => async (solution: SolutionContent) => {
        const issueIds: Array<string> = Array.from(solution.issueIdToIssueMap?.keys() ?? []);
        return await Promise.all(issueIds.map(async (issueId: string) => {
            const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
            let attachments: Array<Attachment>;
            if(solution.resourceToAttachmentsMap) {
                attachments = solution.resourceToAttachmentsMap.get(issueId) ?? [];
            } else {
                attachments = await attachmentRecordDAO.listMostRecentByParentId(issueId);
            }
            set(attachmentRecordsByIdentifierAtomFamily(issueIdentifier), attachments);
            return attachments;
        }));
    });

    const fetchSolutionImages = useRecoilCallback(({ set }) => async (attachmentsList: Array<Array<Attachment>>) => {
        await Promise.all(attachmentsList.map(async (attachments: Array<Attachment>) => {
            const resizeDimensionForClipboard: { width: number; height: number; } = clipboardImageDimensionsMap.get(Math.min(4, attachments.length))!;
            await Promise.all(attachments.map(async (attachment: Attachment) => {
                try {
                    const attachmentContent = await attachmentContentDAO.getAttachmentContentByAttachmentKey(attachment.key!);
                    const imageUrl = `data:image/png;base64,${arrayBufferToBase64(attachmentContent.data)}`;
                    const resizedImageUrlForClipboard = await resizeImage(imageUrl, resizeDimensionForClipboard.width, resizeDimensionForClipboard.height);
                    set(resizedImageUrlByAttachmentKeyAtomFamily(attachment.key!), resizedImageUrlForClipboard);
                } catch (error) {
                    set(resizedImageUrlByAttachmentKeyAtomFamily(attachment.key!), EMPTY_IMAGE);
                }
            }));
        }));
    }, [context]);

    const resetSolutionImageStates = useRecoilCallback(({ snapshot, reset }) => async () => {
        const issueIds: Array<string> = Array.from((await snapshot.getPromise(issueIdToIssueMapByContextSelectorFamily(context))).keys());
        await Promise.all(issueIds.map(async (issueId: string) => {
            const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
            const attachments: Array<Attachment> = await snapshot.getPromise(attachmentRecordsByIdentifierAtomFamily(issueIdentifier));
            attachments.forEach((attachment: Attachment) => {
                reset(resizedImageUrlByAttachmentKeyAtomFamily(attachment.key!));
            });
            reset(attachmentRecordsByIdentifierAtomFamily(issueIdentifier));
        }));
    }, [context]);

    return { fetchSolutionAttachments, fetchSolutionImages, resetSolutionImageStates };
};