import {
    ResourceType,
    WorkTypeGroup
} from "../../../../models";

import SerialNumberGenerator from "../../../serialNumber/SerialNumberGenerator";
import UpdateWorkTypeError from "./error/UpdateWorkTypeError";
import UpdateWorkTypeHandler from "./UpdateWorkTypeHandler";
import UpdateWorkTypeResponse from "./UpdateWorkTypeResponse";
import WorkTypeDAO from "../../WorkTypeDAO";
import WorkTypeDTO from "../../DTO/WorkTypeDTO";
import WorkTypeGroupDAO from "../../group/WorkTypeGroupDAO";
import _ from "lodash";

export default class UpdateWorkTypeHandlerImpl implements UpdateWorkTypeHandler {
    private readonly serialNumberGenerator: SerialNumberGenerator;
    private readonly workTypeDAO: WorkTypeDAO;
    private readonly workTypeGroupDAO: WorkTypeGroupDAO;
  
    constructor(
        serialNumberGenerator: SerialNumberGenerator,
        workTypeDAO: WorkTypeDAO,
        workTypeGroupDAO: WorkTypeGroupDAO
    ) {
        this.serialNumberGenerator = serialNumberGenerator;
        this.workTypeDAO = workTypeDAO;
        this.workTypeGroupDAO = workTypeGroupDAO;
    }
  
    public async handle(
        workTypeDto: WorkTypeDTO
    ): Promise<UpdateWorkTypeResponse> {
        try {
            const groupId = workTypeDto.groupId;
            const existingWorkTypeDto: WorkTypeDTO = await this.workTypeDAO.getByGroupIdAndVersion(workTypeDto.groupId, workTypeDto.version);
            if (_.isEqual(workTypeDto, existingWorkTypeDto)) {
                const existingWorkTypeGroup: WorkTypeGroup = await this.workTypeGroupDAO.getById(groupId);
                return {
                    workType: existingWorkTypeDto,
                    workTypeGroup: existingWorkTypeGroup
                } as UpdateWorkTypeResponse;
            }
            
            const serialNumber: number = await this.serialNumberGenerator.generate(groupId, ResourceType.WORKTYPE);
            const workTypeDtoToCreate: WorkTypeDTO = {
                ...workTypeDto,
                version: serialNumber
            };
            const workTypeCreated: WorkTypeDTO = await this.workTypeDAO.create(workTypeDtoToCreate);
            const workTypeGroupToUpdate: WorkTypeGroup = {
                id: groupId,
                latestWorkTypeVersion: workTypeCreated.version,
            };
            const updatedWorkTypeGroup: WorkTypeGroup = await this.workTypeGroupDAO.update(groupId, workTypeGroupToUpdate);
            return {
                workType: workTypeCreated,
                workTypeGroup: updatedWorkTypeGroup
            } as UpdateWorkTypeResponse;;
        } catch (error) {
            throw new UpdateWorkTypeError(
                `Error occurred while updating workType with groupId: ${workTypeDto.groupId}`, 
                error as Error
            );
        }
    }
}
