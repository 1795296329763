import ProposalRecordDAO from "../../../proposal/dao/record/ProposalRecordDAO";
import { ResourcePermissionMetadata } from "../ResourcePermissionMetadata";
import { ResourcePermissionMetadataDAO } from "../../dao/ResourcePermissionMetadataDAO";

export class ProposalDAOToResourcePermissionMetadataDAOAdapter implements ResourcePermissionMetadataDAO{
    private proposalDAO: ProposalRecordDAO;

    constructor (
        proposalDAO: ProposalRecordDAO
    ) {
        this.proposalDAO = proposalDAO;
    }

    getResourcePermissionMetadata(resourceId: string): Promise<ResourcePermissionMetadata> {
        return this.proposalDAO.getById(resourceId);
    }
}