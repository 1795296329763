import LocationDAO from "../../../location/dao/LocationDAO";
import { ResourcePermissionMetadata } from "../ResourcePermissionMetadata";
import { ResourcePermissionMetadataDAO } from "../../dao/ResourcePermissionMetadataDAO";

export class LocationDAOToResourcePermissionMetadataDAOAdapter implements ResourcePermissionMetadataDAO{
    private locationDAO: LocationDAO;

    constructor (
        locationDAO: LocationDAO
    ) {
        this.locationDAO = locationDAO;
    }

    getResourcePermissionMetadata(resourceId: string): Promise<ResourcePermissionMetadata> {
        return this.locationDAO.getLocationById(resourceId);
    }
}