import FileInputValidator from "./FileInputValidator";
import ImageValidationError from "./error/ImageValidationError";

export default class DefaultFileInputValidator implements FileInputValidator {
    private readonly maxNumberOfImages: number;
    private readonly maxImageSize: number;

    constructor(
        maxNumberOfImages: number,
        maxImageSize: number
    ) {
        this.maxNumberOfImages = maxNumberOfImages;
        this.maxImageSize = maxImageSize;
    }

    public validate(
        files: Array<Blob>
    ): void {
        this.validateNumberOfFiles(files);
        this.validateFileSize(files);
    }

    private validateNumberOfFiles(
        files: Array<Blob>
    ): void {
        if (files.length > this.maxNumberOfImages) {
            throw new ImageValidationError(`Too many files! Can only upload ${this.maxNumberOfImages} at a time.`);
        }
    }

    private validateFileSize(
        files: Array<Blob>
    ): void {
        let totalFileSize = 0;
        for (const file of files) {
            totalFileSize += file.size;
        }
        if (totalFileSize > this.maxImageSize) {
            throw new ImageValidationError(`The files are too large! Can only upload ${(this.maxImageSize / 1000 / 1000).toFixed(2)} MB at a time.`);
        }
    }
}
