import Predicate from "../../util/predicate/Predicate";
import WorkTypeDTO from "../DTO/WorkTypeDTO";
import { associatedWorkTypeGroupIdsAtom } from "./WorkTypeState";
import { selector } from "recoil";

export const notAssociatedPredicateSelector = selector<Predicate<WorkTypeDTO>>({
    key: "notAssociatedPredicateSelector",
    get: ({ get }) => {
        const associatedWorkTypes = get(associatedWorkTypeGroupIdsAtom);
        return new Predicate<WorkTypeDTO>((workType: WorkTypeDTO) => {
            return (associatedWorkTypes.indexOf(workType.groupId) === -1);
        });
    }
});
