import {
    Box,
    Button,
    Typography,
    useMediaQuery
} from "@mui/material";

import theme from "../../assets/style/theme";

const UserOnboardingNotFoundErrorPage = () => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box
                mt={4}
                mx={isSmallScreen ? 3 : "auto"}
                style={{ maxWidth: "600px" }}
        >
            <Box>
                <Typography
                        variant="h5"
                        align="justify"
                >
                    Sorry, we are not able to retrieve your preferences.
                </Typography>
                <Typography
                        variant="h5"
                        align="justify"
                >
                    Please make sure that you are connected to the internet and try again.
                </Typography>
            </Box>
            <Box mt={2} display="flex">
                <Button
                        variant="contained"
                        color="primary"
                        onClick={() => window.location.reload()}
                        style={{ marginLeft: "auto" }}
                >
                    Try Again
                </Button>
            </Box>
        </Box>
    );
};

export default UserOnboardingNotFoundErrorPage;
