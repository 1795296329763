import { AttachmentUploadStatus } from "./AttachmentUploadStatus";
import AttachmentUploadStatusChangeEventHandler from "./AttachmentUploadStatusChangeEventHandler";
import AttachmentUploadStatusRemoveEventHandler from "./AttachmentUploadStatusRemoveEventHandler";
import { SetterOrUpdater } from "recoil";

export default class AttachmentUploadStatusUpdatingHubEventHandlerFactory {
    public static create(
        setIncompleteAttachmentIdToAttachmentUploadStatus: SetterOrUpdater<Map<string, AttachmentUploadStatus>>,
        setUploadCompletedAttachmentIds: SetterOrUpdater<Array<string>>
    ) {
        const attachmentUploadStatusChangeEventHandler = new AttachmentUploadStatusChangeEventHandler(
            setIncompleteAttachmentIdToAttachmentUploadStatus,
            setUploadCompletedAttachmentIds
        );
        return new AttachmentUploadStatusRemoveEventHandler(
            attachmentUploadStatusChangeEventHandler,
            setIncompleteAttachmentIdToAttachmentUploadStatus
        );
    }
}