import {
    Issue,
    Location
} from "../../../../models";
import {
    atom,
    selector
} from "recoil";

import ByClientCreationDateComparator from "../../../issue/sort/ByClientCreationDateComparator";
import ByLocalCreationTimeComparator from "../../../location/sort/ByLocalCreationTimeComparator";
import DataSorter from "../../../util/data/sort/DataSorter";
import MergeDataSorterFactory from "../../../util/data/sort/MergeDataSorterFactory";
import { SortDirection } from "../../../sorting/SortDirection";

export const documentSortDirectionAtom = atom<SortDirection>({
    key: "documentSortDirectionAtom",
    default: SortDirection.DESCENDING,
});

export const locationSorterSelector = selector<DataSorter<Location>>({
    key: "locationSorterSelector",
    get: ({ get }) => {
        const sortDirection = get(documentSortDirectionAtom);
        return MergeDataSorterFactory.createInstance<Location>(new ByLocalCreationTimeComparator(sortDirection));
    },
});

export const issueSorterSelector = selector<DataSorter<Issue>>({
    key: "issueSorterSelector",
    get: ({ get }) => {
        const sortDirection = get(documentSortDirectionAtom);
        return MergeDataSorterFactory.createInstance<Issue>(new ByClientCreationDateComparator(sortDirection));
    },
});