import DefaultLocalForageCleanupHandler from "./DefaultLocalForageCleanupHandler";
import LocalForageCleanupHandler from "./LocalForageCleanupHandler";

export default class DefaultLocalForageCleanupHandlerFactory {
    private static readonly INSTANCE = new DefaultLocalForageCleanupHandler();

    public static getInstance(): LocalForageCleanupHandler {
        return this.INSTANCE;
    }

}