import {
    Box,
    CardMedia,
    Skeleton,
    Stack,
    Typography
} from '@mui/material';

import { CalendarIcon } from '../../../components/icons';
import { Property } from "csstype";
import format from "date-fns/format";
import { useQuery } from 'react-query';

type LazyLoadImageProps<T> = {
    readonly imageKey: string;
    readonly imageDTO: T;
    readonly isActive: boolean;
    readonly timestamp?: number;
    readonly timestampPosition?: "bottomLeft" | "bottomRight";
    readonly objectFit?: Property.ObjectFit;
    readonly getImageUrl: (imageDTO: T) => (string | Promise<string>);
};

export const LazyLoadImage = <T,>(props: LazyLoadImageProps<T>) => {
    const { imageKey, imageDTO, isActive, timestamp, timestampPosition, objectFit = "cover", getImageUrl } = props;
    const { data: imageUrl, status } = useQuery({
        queryKey: ["imageUrl", imageKey],
        queryFn: () => getImageUrl(imageDTO),
        enabled: isActive
    });

    return (
        <Box width="100%" height="100%">
            {status === "loading" ? (
                <Skeleton variant="rectangular" width="100%" height="100%" />
            ) : status === "error" ? (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    width="100%"
                    sx={{
                        backgroundColor: "#EEEEEE",
                    }}
                >
                    <Typography variant="body1">Error loading image</Typography>
                </Box>
            ) : (
                <>
                    <CardMedia
                        sx={{
                            height: "100%",
                            width: "100%",
                            objectFit: objectFit
                        }}
                        loading='lazy'
                        component="img"
                        src={imageUrl}
                    />
                    {timestamp && (
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            color="white"
                            position="absolute"
                            bottom="4%"
                            sx={{
                                [timestampPosition === "bottomLeft" ? "left" : "right"]: "4%"
                            }}
                        >
                            <CalendarIcon accent='inherit' fontSize="small" />
                            <Typography variant="body2">
                                {format(timestamp, "dd-MMM-yy")}
                            </Typography>
                        </Stack>
                    )}
                </>
            )}
        </Box>
    );
};