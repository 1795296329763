import ProposalItem from "./ProposalItem";
import ProposalItemBuilder from "./ProposalItemBuilder";
import ProposalItemChildrenPriceAdjustmentError from "./errors/ProposalItemChildrenPriceAdjustmentError";
import ProposalItemChildrenPriceAdjustmentHandler from "./ProposalItemChildrenPriceAdjustmentHandler";
import ProposalItemPriceAfterAdjustmentCalculator from './ProposalItemPriceAfterAdjustmentCalculator';

export default class PercentageProposalItemChildrenPriceAdjustmentHandler implements ProposalItemChildrenPriceAdjustmentHandler {
    private readonly proposalItemPriceAfterAdjustmentCalculator: ProposalItemPriceAfterAdjustmentCalculator;

    constructor(
        proposalItemPriceAfterAdjustmentCalculator: ProposalItemPriceAfterAdjustmentCalculator
    ) {
        this.proposalItemPriceAfterAdjustmentCalculator = proposalItemPriceAfterAdjustmentCalculator;
    }

    handle(
        proposalItemIdToProposalItemMap: Map<string, ProposalItem>,
        proposalPriceBeforeAdjustment: number | undefined,
        proposalAdjustmentAmount: number | undefined
    ): Map<string, ProposalItem> {
        if (!proposalAdjustmentAmount) {
            return this.backFillPriceAfterAdjustmentForZeroAdjustmentAmount(proposalItemIdToProposalItemMap);
        }
        if (proposalPriceBeforeAdjustment == null) {
            throw new ProposalItemChildrenPriceAdjustmentError(`Unable to adjust proposal children prices due to invalid input: proposalPriceBeforeAdjustment: ${proposalPriceBeforeAdjustment}, proposalAdjustmentAmount: ${proposalAdjustmentAmount}`);
        }
        const adjustmentPercentage: number = proposalAdjustmentAmount / proposalPriceBeforeAdjustment;

        const adjustedProposalItemIdToProposalItemMap: Map<string, ProposalItem> = new Map<string, ProposalItem>();

        for (const [proposalId, proposalItem] of proposalItemIdToProposalItemMap) {
            if (proposalItem.priceBeforeAdjustment == null) {
                adjustedProposalItemIdToProposalItemMap.set(proposalId, proposalItem);
                continue;
            }

            try {
                const adjustmentAmount: number = proposalItem.priceBeforeAdjustment * adjustmentPercentage;
                const adjustedProposalItem: ProposalItem = this.proposalItemPriceAfterAdjustmentCalculator.calculate(proposalItem, adjustmentAmount);
                adjustedProposalItemIdToProposalItemMap.set(proposalId, adjustedProposalItem);
            } catch (error) {
                adjustedProposalItemIdToProposalItemMap.set(proposalId, proposalItem);
            }
        }
        return adjustedProposalItemIdToProposalItemMap;
    }

    //Skip calculation and copy price before adjustment to price after adjustment fields since adjustment amount is 0
    public backFillPriceAfterAdjustmentForZeroAdjustmentAmount(
        proposalItemIdToProposalItemMap: Map<string, ProposalItem>
    ): Map<string, ProposalItem> {
        const newProposalItemIdToProposalItemMap: Map<string, ProposalItem> = new Map<string, ProposalItem>();

        for (const [proposalId, proposalItem] of proposalItemIdToProposalItemMap) {
            const newProposalItem = new ProposalItemBuilder(proposalItem)
                .priceAfterAdjustment(proposalItem.priceBeforeAdjustment)
                .unitPriceAfterAdjustment(proposalItem.unitPriceBeforeAdjustment)
                .build();
            newProposalItemIdToProposalItemMap.set(proposalId, newProposalItem);
        }
        return newProposalItemIdToProposalItemMap;
    }
}
