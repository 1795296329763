import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { DataStore } from "aws-amplify";
import { DataStoreEntityResourcePermissionDAO } from "./DataStoreEntityResourcePermissionDAO";

export class DataStoreEntityResourcePermissionDAOFactory {
    private static INSTANCE = new DataStoreEntityResourcePermissionDAO(
        ClientLoggerFactory.getClientLogger("DataStoreEntityResourcePermissionDAO"),
        DataStore
    )

    public static getInstance() {
        return this.INSTANCE;
    }
}