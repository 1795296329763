import { Property } from "../../../../models";
import PropertyNameFactory from "../PropertyNameFactory";

export default class AddressFallbackPropertyNameFactory implements PropertyNameFactory {
    public getName(property: Property): string {
        if (property.name) {
            return property.name;
        }
        if (property.address) {
            return property.address;
        }
        return "";
    }
}
