import { DimensionType } from "../../../models";
import { Measurement } from "../../../models";
import UnitCountCalculationError from "../errors/UnitCountCalculationError";
import UnitCountCalculator from "./UnitCountCalculator";

export default class DefaultUnitCountCalculator implements UnitCountCalculator {
    public calculate(measurement: Measurement): number {
        if (measurement.dimensionType === DimensionType.LINEAR && measurement.length != null) {
            return (measurement.length as number);
        }
        if (measurement.dimensionType === DimensionType.SQUARE && measurement.length != null && measurement.width != null) {
            return (measurement.length as number) * (measurement.width as number);
        }
        throw new UnitCountCalculationError(
            `Unable to calculate unitCount with measurementType: ${measurement.dimensionType}, ` +
            `length: ${measurement.length}, width: ${measurement.width}`
        );
    }
}
