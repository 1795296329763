import {
    API,
    graphqlOperation
} from 'aws-amplify';

import { AuthIDTokenSupplierFactory } from '../../../../auth/AuthIDTokenSupplierFactory';
import ClientLoggerFactory from '../../../../logging/ClientLoggerFactory';
import GraphQLSolutionMinorVersionRecordDAO from "./GraphQLSolutionMinorVersionRecordDAO";
import SolutionMinorVersionRecordDAO from "./SolutionMinorVersionRecordDAO";

export default class GraphQLSolutionMinorVersionRecordDAOFactory {
    private static readonly INSTANCE: SolutionMinorVersionRecordDAO = new GraphQLSolutionMinorVersionRecordDAO(
        API,
        graphqlOperation,
        AuthIDTokenSupplierFactory.getInstance(),
        ClientLoggerFactory.getClientLogger("GraphQLSolutionMinorVersionRecordDAO")
    );

    public static getInstance(): SolutionMinorVersionRecordDAO {
        return GraphQLSolutionMinorVersionRecordDAOFactory.INSTANCE;
    }
}
