import { LastLoggedInUserIdManager } from "./LastLoggedInUserIdManager";
import { LocalForageCurrentLoggedInUserIdDAOFactory } from "./LocalForageCurrentLoggedInUserIdDAOFactory";
import { LocalForageLastLoggedInUserIdDAOFactory } from "./LocalForageLastLoggedInUserIdDAOFactory";

export class LastLoggedInUserIdManagerFactory {
    private static INSTANCE: LastLoggedInUserIdManager = new LastLoggedInUserIdManager(
        LocalForageLastLoggedInUserIdDAOFactory.getInstance(),
        LocalForageCurrentLoggedInUserIdDAOFactory.getInstance()
    );

    public static getInstance(): LastLoggedInUserIdManager {
        return this.INSTANCE;
    }
} 