import AttachmentUploadEntity from "../../attachment/AttachmentUploadEntity";
import { atomFamily } from "recoil";

export const parentIdToStagedAttachmentUploadEntitiesAtomFamily = atomFamily<Map<string, AttachmentUploadEntity>, string>({
    key: "parentIdToStagedAttachmentUploadEntitiesAtomFamily",
    default: new Map<string, AttachmentUploadEntity>()
});

export const attachmentDisplayReadinessStateByParentIdAtomFamily = atomFamily<boolean, string>({
    key: "attachmentDisplayReadinessStateByParentIdAtomFamily",
    default: true
});
