import {
  Button,
  FormGroup,
  Grid,
  Link,
  TextField,
  Typography
} from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  hiddenTab: {
      display: "none"
  },
  displayTab: {
      display: "block"
  },
  tabs: {
      backgroundColor: "#fff"
  },
  root: {
      maxWidth:"700px",
      display:"flex",
      marginLeft: "5px"
      // top: "50%",
      // left: "50%",
      // right: "auto",
      // bottom: "auto",
      // transform: "translate(-50%,-50%)",
      // position: "absolute"
  },
  loginForm: {
      maxWidth: "300px",
      border: "1px solid #A6A6A6",
      borderRadius: "15px",
      padding: "30px"
  },
  // LoginLogo: {
  //     marginTop: "80px",

  // }

});

const preventDefault = (event) => event.preventDefault();
const ViewLogin = () => {
  const classes = useStyles();

  return (

      <Grid container direction="column" alignItems="center" className={classes.root} noValidate autoComplete="off">
        <Grid item xs={12}  style={{marginTop: 80}}>
          <Typography variant="h3">Logo</Typography>
          <Typography style={{paddingTop: 15, marginBottom:50 }}>Tagline - Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>


        {/* </Grid> */}

        {/* <Grid item xs={12} sm={6} className={classes.loginInfo}> */}
          <FormGroup className={classes.loginForm}>
            <TextField
                    id="outlined-basic"
                    inputProps={{inputMode: "email"}}
                    style={{paddingBottom: 15}}
                    label="Email"
                    variant="outlined"
            />
            <TextField
                    id="outlined-basic"
                    style={{paddingBottom: 6}}
                    label="Password"
                    variant="outlined"
            />
            <Link href="#" onClick={preventDefault} style={{paddingBottom: 20, fontSize: 10}}>Forgot password?</Link>
            <Button variant="contained" color="primary" style={{paddingTop: 10}}>Log In</Button>
          </FormGroup>
        </Grid>
      </Grid>



  );
}

export default ViewLogin;
