import ClientLogger from "../logging/ClientLogger";
import ImageResizeMetricNames from "./ImageResizeMetricNames";
import ImageResizer from "./ImageResizer";
import ReactImageFileResizer from "./ReactImageFileResizer";

export default class DefaultImageResizer implements ImageResizer {
    private static readonly MAX_WIDTH: number = 1280;
    private static readonly MAX_HEIGHT: number = 768;
    private static readonly COMPRESS_FORMAT: string = "JPEG";
    private static readonly QUALITY: number = 95;
    private static readonly ROTATION_DEGREES: number = 0;

    private readonly resizerLibrary: ReactImageFileResizer;
    private logger: ClientLogger;

    constructor(
        resizerLibrary: ReactImageFileResizer,
        logger: ClientLogger
    ) {
        this.resizerLibrary = resizerLibrary;
        this.logger = logger;
    }

    public async resize(
        imageBlob: Blob
    ): Promise<Blob> {
        this.logger.info(
            `Resizing image with library for ${(imageBlob as File).name}, size: ${(imageBlob as File).size}`,
            undefined,
            [ImageResizeMetricNames.RESIZE_ATTEMPT]
        );
        return new Promise((resolve, reject) => {
            this.resizerLibrary.createResizedImage(
                imageBlob as File,
                DefaultImageResizer.MAX_WIDTH,
                DefaultImageResizer.MAX_HEIGHT,
                DefaultImageResizer.COMPRESS_FORMAT,
                DefaultImageResizer.QUALITY,
                DefaultImageResizer.ROTATION_DEGREES,
                (resizedImageBlob, error) => {
                    if (error) {
                        reject(error);
                    }
                    resolve(resizedImageBlob as Blob);
                },
                "blob"
            );
        });
    }
}
