import {
    FormControlLabel,
    Switch,
    Tooltip,
    Typography
} from '@mui/material';
import {
    areVisibleIssuesValidByContextSelectorFamily,
    isValidationEnabledAtom
} from '../../lib/design/document/state/DocumentValidationState';
import {
    useRecoilState,
    useRecoilValue
} from 'recoil';

import { StateContext } from '../../lib/design/document/state/StateContext';
import { useMemo } from 'react';

type ValidationSwitchProps = {
    context: StateContext;
};

export const ValidationSwitch = ({ context }: ValidationSwitchProps) => {
    const areVisibleIssuesValid = useRecoilValue(areVisibleIssuesValidByContextSelectorFamily(context));
    const [isValidationEnabled, setIsValidationEnabled] = useRecoilState<boolean>(isValidationEnabledAtom);
    const switchColor: "primary" | "error" | "success" = useMemo(() => {
        if (!isValidationEnabled) {
            return "primary";
        }
        return areVisibleIssuesValid ? "success" : "error";
    }, [isValidationEnabled, areVisibleIssuesValid]);

    return (
        <FormControlLabel
            sx={{
                justifyContent: "space-between",
                ml: 0
            }}
            control={(
                <Switch
                    color={switchColor}
                    checked={isValidationEnabled}
                    onChange={(event, checked) => {
                        setIsValidationEnabled(checked);
                    }}
                />
            )}
            label={(
                <Tooltip title={"Show invalid locations and issues."} placement="right-start">
                    <Typography>
                        Validation Mode
                    </Typography>
                </Tooltip>
            )}
            labelPlacement="start"
        />
    );
};
