import { Attachment } from "../../../../models";
import AttachmentRecordDAO from "../AttachmentRecordDAO";
import AttachmentRecordMetricNames from "../AttachmentRecordMetricNames";
import ClientLogger from "../../../logging/ClientLogger";
import CreateAttachmentRecordError from "../errors/CreateAttachmentRecordError";
import { DataStoreClass } from "@aws-amplify/datastore";
import GetAttachmentRecordByIdError from "../errors/GetAttachmentRecordByIdError";
import ListAttachmentRecordsByParentIdError from "../errors/ListAttachmentRecordsByParentIdError";
import ListMostRecentAttachmentRecordsByParentIdError from "../errors/ListMostRecentAttachmentRecordsByParentIdError";
import ListMostRecentAttachmentRecordsByParentIdWithLimitError from "../errors/ListMostRecentAttachmentRecordsByParentIdWithLimitError";
import { SortDirection } from "aws-amplify";

export class DataStoreAttachmentRecordDAO implements AttachmentRecordDAO {
    private logger: ClientLogger;
    private readonly dataStore: DataStoreClass;

    constructor(
        logger: ClientLogger,
        dataStore: DataStoreClass,
    ) {
        this.logger = logger;
        this.dataStore = dataStore;
    }

    public async create(record: Attachment): Promise<Attachment> {
        this.logger.info(
            `Creating Attachment Record: ${record.id}`,
            undefined,
            [AttachmentRecordMetricNames.CREATE_ATTEMPT]
        );
        try {
            return await this.dataStore.save(new Attachment ({...record}));
        } catch (error) {
            const errorMessage: string = `Unable to create Attachment Record: ${record.id}`;
            this.logger.error(
                errorMessage,
                error,
                [AttachmentRecordMetricNames.CREATE_FAILURE]
            );
            throw new CreateAttachmentRecordError(errorMessage, error as Error);
        }
    }
    
    public async delete(id: string): Promise<void> {
        this.logger.info(
            `Deleting Attachment Record: ${id}`,
            undefined,
            [AttachmentRecordMetricNames.DELETE_ATTEMPT]
        );
        try {
            const attachmentRecord: Attachment | undefined = await this.dataStore.query(Attachment, id);
            if (!attachmentRecord) {
                throw new Error('Attachment Record not found');
            }
            await this.dataStore.delete(attachmentRecord);
        } catch (error) {
            const errorMessage: string = `Unable to delete Attachment Record: ${id}`;
            this.logger.error(
                errorMessage,
                error,
                [AttachmentRecordMetricNames.DELETE_FAILURE]
            );
            throw new Error(errorMessage);
        }
    }

    public async getById(id: string): Promise<Attachment> {
        this.logger.info(
            `Getting Attachment Record: ${id}`,
            undefined,
            [AttachmentRecordMetricNames.GET_BY_ID_ATTEMPT]
        );
        try {
            const result: Attachment | undefined = await this.dataStore.query(Attachment, id);
            if (!result) {
                throw new Error('Attachment Record not found');
            }
            return result;
        } catch (error) {
            const errorMessage: string = `Unable to get Attachment Record: ${id}`;
            this.logger.error(
                errorMessage,
                error,
                [AttachmentRecordMetricNames.GET_BY_ID_FAILURE]
            );
            throw new GetAttachmentRecordByIdError(errorMessage, error as Error);
        }
    }

    public async listByParentId(parentId: string): Promise<Array<Attachment>> {
        this.logger.info(
            `Listing Attachment Records with parentId: ${parentId}`,
            undefined,
            [AttachmentRecordMetricNames.LIST_BY_PARENT_ID_ATTEMPT]
        );
        try {
            return await this.dataStore.query(Attachment, attachment => attachment.parentId("eq", parentId));
        } catch (error) {
            const errorMessage: string = `Unable to list Attachment Records with parentId: ${parentId}`;
            this.logger.error(
                errorMessage,
                error,
                [AttachmentRecordMetricNames.LIST_BY_PARENT_ID_FAILURE]
            );
            throw new ListAttachmentRecordsByParentIdError(errorMessage, error as Error);
        }
    }

    public async listMostRecentByParentId(
        parentId: string
    ): Promise<Array<Attachment>> {
        this.logger.info(
            `Listing Attachment Records with parentId: ${parentId}`,
            undefined,
            [AttachmentRecordMetricNames.LIST_MOST_RECENT_BY_PARENT_ID_ATTEMPT]
        );
        try {
            return await this.dataStore.query(Attachment, attachment => attachment.parentId("eq", parentId), {
                sort: s => s.localCreatedAtTime(SortDirection.DESCENDING).createdAt(SortDirection.DESCENDING)
            });
        } catch (error) {
            const errorMessage: string = `Unable to list Attachment Records with parentId: ${parentId}`;
            this.logger.error(
                errorMessage,
                error,
                [AttachmentRecordMetricNames.LIST_MOST_RECENT_BY_PARENT_ID_FAILURE]
            );
            throw new ListMostRecentAttachmentRecordsByParentIdError(errorMessage, error as Error);
        }
    }

    public async listMostRecentByParentIdWithLimit(
        parentId: string,
        limit: number
    ): Promise<Array<Attachment>> {
        this.logger.info(
            `Listing Attachment Records with parentId: ${parentId}`,
            undefined,
            [AttachmentRecordMetricNames.LIST_MOST_RECENT_BY_PARENT_ID_WITH_LIMIT_ATTEMPT]
        );
        try {
            return await this.dataStore.query(Attachment, attachment => attachment.parentId("eq", parentId), {
                sort: s => s.localCreatedAtTime(SortDirection.DESCENDING).createdAt(SortDirection.DESCENDING),
                limit: limit
            });
        } catch (error) {
            const errorMessage: string = `Unable to list Attachment Records with parentId: ${parentId}`;
            this.logger.error(
                errorMessage,
                error,
                [AttachmentRecordMetricNames.LIST_MOST_RECENT_BY_PARENT_ID_WITH_LIMIT_FAILURE]
            );
            throw new ListMostRecentAttachmentRecordsByParentIdWithLimitError(errorMessage, error as Error);
        }
    }
}
