import API from "@aws-amplify/api";
import ClientLogger from "../../logging/ClientLogger";
import CreateSolutionEventError from "./error/CreateSolutionEventError";
import { CreateSolutionEventInput } from '../../../API';
import { IDTokenSupplier } from "../../auth/IDTokenSupplier";
import SolutionEventClient from "./SolutionEventClient";
import { SolutionOperationType } from "../../../models";
import UsernameSupplier from "../../auth/UsernameSupplier";
import { createSolutionEvent } from "../../../graphql/mutations";
import { graphqlOperation } from "aws-amplify";

export default class GraphQLSolutionEventClient implements SolutionEventClient {
    private static readonly CREATE_ATTEMPT_METRIC_NAME = "GraphQLSolutionEventClient.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "GraphQLSolutionEventClient.Create.Failure";

    private readonly logger: ClientLogger;
    private readonly api: typeof API;
    private readonly gqlOperation: typeof graphqlOperation;
    private readonly usernameSupplier: UsernameSupplier;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        usernameSupplier: UsernameSupplier,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.usernameSupplier = usernameSupplier;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async create(
        solutionId: string,
        solutionMinorVersion: number,
        proposalId: string | null | undefined,
        solutionOperationType: SolutionOperationType
    ): Promise<void> {
        try {
            this.logger.info(
                `Calling createSolutionEvent API for solutionId=${solutionId} minorVersion:${solutionMinorVersion} proposalId=${proposalId} solutionOperationType=${solutionOperationType}`,
                undefined,
                [GraphQLSolutionEventClient.CREATE_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const input: CreateSolutionEventInput = {
                eventTime: Date.now(),
                userId: await this.usernameSupplier.get(),
                solutionId: solutionId,
                solutionMinorVersion: solutionMinorVersion,
                proposalId: proposalId,
                solutionOperationType: solutionOperationType
            } 
            await this.api.graphql(
                this.gqlOperation(
                    createSolutionEvent,
                    { input },
                    authToken
                )
            );
        } catch (error) {
            const errorMessage: string = `Unable to call createSolutionEvent API for solutionId=${solutionId} minorVersion:${solutionMinorVersion} proposalId=${proposalId} solutionOperationType=${solutionOperationType}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLSolutionEventClient.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreateSolutionEventError(errorMessage, error as Error);
        }
    }
}
