import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const ApprovedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="M9.765 21H1V1h14v7.069A8.047 8.047 0 0 1 16 8V0H0v22h10.726a8.037 8.037 0 0 1-.961-1z"
        className="primary"
      />
      <path
        fill="currentColor"
        d="M8 3h6v1H8zM8 7h6v1H8zM8 13h.589a8 8 0 0 1 .493-1H8v1zM4.5 3.793l-.646-.647-.708.708L4.5 5.207l2.354-2.353-.708-.708zM4.5 7.793l-.646-.647-.708.708L4.5 9.207l2.354-2.353-.708-.708zM4.5 12.793l-.646-.647-.708.708L4.5 14.207l2.354-2.353-.708-.708zM4.5 17.793l-.646-.647-.708.708L4.5 19.207l2.354-2.353-.708-.708z"
        className="primary"
      />
    </g>
    <path
      fill="currentColor"
      d="M16 9a7 7 0 1 0 0 14 7 7 0 0 0 0-14zm-.619 10.492-.745.016-2.334-2.5.731-.682 1.953 2.091 4.635-5.409.76.65-5 5.834z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(ApprovedIcon);
