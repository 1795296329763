const NON_SYNC_MODEL_NAMES = [
    "BiddingContact",
    "BidInvitation",
    "DesignDocument",
    "DesignDocumentGroup",
    "Note",
    "UserGroup",
    "UserGroupMember",
    "User",
    "Counter",
    "DiscoverableWorkTypes",
    "Inspection",
    "Organization",
    "Project",
    "ProjectCounter",
    "PropertyCounter",
    "ResourceUserPermissions",
    "Solution",
    "SolutionCounter",
    "SolutionMinorVersion",
    "SolutionGroupCounter",
    "DefaultWorkTypePricing",
    "WorkType",
    "Proposal",
    "ProposalCounter",
    "LinkRegistry",
    "ExternalReportBranding",
    "Template",
    "Tender",
    "TenderMinorVersion",
    "Bid",
    "BidMinorVersion",
    "TemporaryAuthorizationCode",
    "Attendee",
    "Meeting"
];
module.exports = NON_SYNC_MODEL_NAMES;