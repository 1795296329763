import {
    E_ALREADY_LOCKED,
    Mutex,
    tryAcquire
} from "async-mutex";

import { AttachmentUploadManager } from "./AttachmentUploadManager";
import AttachmentUploadRequest from "../request/AttachmentUploadRequest";
import AttachmentUploadRequestQueue from "../request/AttachmentUploadRequestQueue";
import Task from "../../../util/concurrency/Task";

export class DefaultAttachmentUploadManager implements AttachmentUploadManager{
    private attachmentUploadRequestQueue: AttachmentUploadRequestQueue;
    private attachmentUploadScheduler: Task<void>;
    private attachmentCycleLock = new Mutex();

    constructor(
        attachmentUploadRequestQueue: AttachmentUploadRequestQueue,
        attachmentUploadScheduler: Task<void>
    ) {
        this.attachmentUploadRequestQueue = attachmentUploadRequestQueue;
        this.attachmentUploadScheduler = attachmentUploadScheduler;
    }

    public async queueAttachmentForUpload(
        attachmentUploadRequest: AttachmentUploadRequest
    ): Promise<void> {
        await this.attachmentUploadRequestQueue.addAttachmentToUploadQueue(attachmentUploadRequest);
    }

    public async initiateUploadCycle(): Promise<void> {
        // TryAcquire will fail fast if mutex is still locked from previous cycle
        await tryAcquire(this.attachmentCycleLock)
            .runExclusive(async () => {
                await this.attachmentUploadScheduler.run();
            })
            .catch(error => {
                // If tryAcquire fails because mutex is already locked, we swallow the exception and continue
                if (error !== E_ALREADY_LOCKED) {
                    throw error;
                }
            });
    }
}