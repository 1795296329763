import { SelectionFeatureConfiguration } from "./SelectionFeatureConfiguration";
import { StateContext } from "./StateContext";
import { atomFamily } from "recoil";

export const isSelectionModeAtomFamily = atomFamily<boolean, StateContext>({
    key: "isSelectionModeAtomFamily",
    default: false
});

export const selectionFeatureConfigurationByContextAtomFamily = atomFamily<SelectionFeatureConfiguration, StateContext>({
    key: 'selectionFeatureConfigurationByContextAtomFamily',
    default: {
        permissionChecking: false
    }
});