import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const EditNameIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m18.228 1-2.983 2.983-.596.597-9.545 9.545v4.772h4.772l9.545-9.545L23 5.772 18.228 1zm0 2.828 1.944 1.944-1.568 1.568-1.944-1.944 1.568-1.568zm-9.18 13.068H7.104v-1.944l8.959-8.959 1.944 1.944-8.959 8.959z"
      className="accent"
    />
    <path
      fill="currentColor"
      d="M18 22H2V6h8V5H1.5l-.5.5v17l.5.5h17l.5-.5V15h-1z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(EditNameIcon);
