import React, {
    useMemo
} from "react";

import { Box } from "@mui/material";
import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { ModelType } from "../../lib/design/document/ModelType";
import NoItemsPlaceholder from "../../components/NoItemsPlaceholder";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { WorkSpecificationSummary } from "../wss/WorkSpecificationSummary";
import { orange } from "../../assets/style/colors";
import { useRecoilValue } from "recoil";
import { workSpecificationIdsByIssueIdentifierSelectorFamily } from "../../lib/design/document/state/DocumentState";

type WorkSpecificationSummaryByIssueProps = {
    readonly issueId: string;
    readonly stateContext: StateContext;
};

const WorkSpecificationSummaryByIssue = ({ issueId, stateContext }: WorkSpecificationSummaryByIssueProps) => {
    const issueIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(issueId, stateContext, ModelType.ISSUE);
    }, [issueId, stateContext]);
    const workSpecificationIds = useRecoilValue<Array<string>>(workSpecificationIdsByIssueIdentifierSelectorFamily(issueIdentifier));

    return (
        <Box
            display="flex"
            flexDirection="column"
            flex="1 1 auto"
            overflow="auto"
            gap={1}
            mt={1}
        >
            {workSpecificationIds.length === 0 ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    p={1}
                    borderBottom={`1px solid ${orange[100]}`}
                >
                    <NoItemsPlaceholder text="No work specification under issue." />
                </Box>
            ) :
                workSpecificationIds.map((workSpecificationId, index) => (
                    <React.Fragment key={workSpecificationId}>
                        <Box pr={2} pb={1} borderBottom={`1px solid ${orange[100]}`}>
                            <WorkSpecificationSummary
                                workSpecificationId={workSpecificationId}
                                stateContext={stateContext}
                                index={index}
                            />
                        </Box>
                    </React.Fragment>
                ))
            }
        </Box>
    );
};

export default WorkSpecificationSummaryByIssue;
