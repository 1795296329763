import {
    ConverseCommandInput,
    ConverseCommandOutput
} from "@aws-sdk/client-bedrock-runtime";
import GraphQLAPI, {
    GraphQLResult
} from "@aws-amplify/api-graphql";
import {
    HandleConverseCommandQuery,
    HandleConverseCommandQueryVariables
} from "../../API";

import { AuthIDTokenSupplierFactory } from "../auth/AuthIDTokenSupplierFactory";
import ClientLogger from "../logging/ClientLogger";
import ClientLoggerFactory from "../logging/ClientLoggerFactory";
import { HandleConverseCommandClient } from "./HandleConverseCommandClient";
import { IDTokenSupplier } from "../auth/IDTokenSupplier";
import { handleConverseCommand } from "../../graphql/queries";

export class GraphQLHandleConverseCommandClient implements HandleConverseCommandClient {
    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;
    private readonly CLASS_METRIC_NAME = "GraphQLHandleConverseCommandClient";
    private readonly HANDLE_CONVERSE_COMMAND_QUERY_METRIC_NAME = "handleConverseCommand";

    constructor(
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    async handleConverseCommand(command: ConverseCommandInput): Promise<ConverseCommandOutput> {
        try {
            this.logger.info(
                `Attempting to handleConverseCommand: ${JSON.stringify(command)}`,
                undefined,
                [this.CLASS_METRIC_NAME.concat(this.HANDLE_CONVERSE_COMMAND_QUERY_METRIC_NAME).concat(".Attempt")]
            );
            const idToken = await this.idTokenSupplier.get();
            const variables: HandleConverseCommandQueryVariables = {
                input: {
                    converseCommand: JSON.stringify(command)
                },
            };
            const response: GraphQLResult<HandleConverseCommandQuery> = await GraphQLAPI.graphql({
                query: handleConverseCommand,
                variables: variables,
                authToken: idToken
            }) as GraphQLResult<HandleConverseCommandQuery>;

            const responseString = response.data?.handleConverseCommand;
            if (!responseString) {
                throw new Error("Received empty response from handleConverseCommand");
            }
            const parsedResponse = JSON.parse(responseString) as ConverseCommandOutput;
            this.logger.info(
                `handleConverseCommand handled successfully`,
                undefined,
                [this.CLASS_METRIC_NAME.concat(this.HANDLE_CONVERSE_COMMAND_QUERY_METRIC_NAME).concat(".Success")]
            );
            return parsedResponse;
        } catch (error) {
            this.logger.error(
                `Error handling ConverseCommand: ${JSON.stringify(command)}`,
                error,
                [this.CLASS_METRIC_NAME.concat(this.HANDLE_CONVERSE_COMMAND_QUERY_METRIC_NAME).concat(".Error")]
            );
            throw new Error("Failed to handleConverseCommand");
        }
    }
}

export const handleConverseCommandClient: HandleConverseCommandClient = new GraphQLHandleConverseCommandClient(
    ClientLoggerFactory.getClientLogger("GraphQLHandleConverseCommandClient"),
    AuthIDTokenSupplierFactory.getInstance()
);