import PropertyDAO from "../../../property/dao/PropertyDAO";
import { ResourcePermissionMetadata } from "../ResourcePermissionMetadata";
import { ResourcePermissionMetadataDAO } from "../../dao/ResourcePermissionMetadataDAO";

export class PropertyDAOToResourcePermissionMetadataDAOAdapter implements ResourcePermissionMetadataDAO{
    private propertyDAO: PropertyDAO;

    constructor (
        propertyDAO: PropertyDAO
    ) {
        this.propertyDAO = propertyDAO;
    }

    getResourcePermissionMetadata(resourceId: string): Promise<ResourcePermissionMetadata> {
        return this.propertyDAO.getById(resourceId);
    }
}