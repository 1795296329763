import TokenRefillStrategy from "./TokenRefillStrategy";

export default class FixedDelayTokenRefillStrategy implements TokenRefillStrategy {

    private readonly delayInMilliseconds: number;

    constructor(
        delayInMilliseconds: number
    ) {
        this.delayInMilliseconds = delayInMilliseconds;
    }

    public getNextTokenRefillDelay(): number {
        return this.delayInMilliseconds;
    }

    public async reportSuccess(): Promise<void> {
        return;
    }

    public async reportFailure(): Promise<void> {
        return;
    }
}
