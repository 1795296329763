import {
    ApprovedIcon,
    ArchiveIcon,
    ChevronDownIcon,
    HoldIcon,
    NoStatusIcon,
    RejectedIcon,
    ReviewIcon
} from '../icons';
import {
    Box,
    ListItemIcon,
    alpha,
    useTheme
} from '@mui/material';
import {
    IssueStatus,
    PermissionType
} from '../../models';
import {
    getUpdateIssueStatusCallback,
    issueStatusForIssueIdAtomFamily
} from '../../lib/design/document/state/DocumentState';
import {
    useMemo,
    useRef,
    useState
} from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { PermissionRestrictedButton } from '../general/button/PermissionRestrictedButton';
import Popper from '@mui/material/Popper';
import { useRecoilValue } from 'recoil';

type StatusDetails = {
    icon: JSX.Element;
    text: string;
    color: any;
};

const STATUS_TO_DETAIL_MAP = new Map<IssueStatus, StatusDetails>(
    [
        [
            IssueStatus.NONE,
            {
                icon: <NoStatusIcon primary="inherit" accent="inherit" />,
                text: "No Status",
                color: "#999999"
            }
        ],
        [
            IssueStatus.ARCHIVED,
            {
                icon: <ArchiveIcon primary="inherit" accent="inherit" />,
                text: "Archived",
                color: "#999999"
            }
        ],
        [
            IssueStatus.UNDER_REVIEW,
            {
                icon: <ReviewIcon primary="inherit" accent="inherit" />,
                text: "Under Review",
                color: "#f57c00"
            }
        ],
        [
            IssueStatus.APPROVED,
            {
                icon: <ApprovedIcon primary="inherit" accent="inherit" />,
                text: "Approved",
                color: "#388e3c"
            }
        ],
        [
            IssueStatus.REJECTED,
            {
                icon: <RejectedIcon primary="inherit" accent="inherit" />,
                text: "Rejected",
                color: "#545454"
            }
        ],
        [
            IssueStatus.ON_HOLD,
            {
                icon: <HoldIcon primary="inherit" accent="inherit" />,
                text: "On Hold",
                color: "#F6DA51"
            }
        ],
        [
            IssueStatus.COMPLETED,
            {
                icon: <CheckCircleOutlineIcon />,
                text: "Completed",
                color: "#388e3c"
            }
        ]
    ]
);

interface IssueStatusSelectMenuProps {
    readonly issueId: string;
    readonly size: "small" | "medium" | "large";
}

export const IssueStatusSelectMenu = ({ issueId, size }: IssueStatusSelectMenuProps) => {
    /* Internal Page State */
    const anchorRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const issueStatus: IssueStatus = useRecoilValue<IssueStatus>(issueStatusForIssueIdAtomFamily(issueId));

    /* Dependencies */
    const theme = useTheme();
    const updateIssueStatus = getUpdateIssueStatusCallback();

    const buttonDetails: StatusDetails = useMemo(() => {
        return STATUS_TO_DETAIL_MAP.get(issueStatus)!;
    }, [issueStatus]);

    const handleMenuItemClick = (status: IssueStatus) => {
        updateIssueStatus(issueId, status);
        setIsOpen(false);
    };

    return (
        <>
            <Box
                ref={anchorRef}
            >
                <PermissionRestrictedButton
                    size={size}
                    onClick={() => setIsOpen(true)}
                    startIcon={buttonDetails.icon}
                    sx={{
                        color: buttonDetails.color,
                        borderColor: buttonDetails.color,
                        backgroundColor: "transparent",
                        "&:hover": {
                            color: alpha(buttonDetails.color, 0.9),
                            borderColor: buttonDetails.color,
                        }
                    }}
                    resourceId={issueId}
                    requiredPermission={PermissionType.EDIT} //Issue status is part of the issue record, so it must be restricted by EDIT permission and not MANAGE_STATE
                >
                    {buttonDetails.text}
                    <ChevronDownIcon />
                </PermissionRestrictedButton>
            </Box>
            <Popper
                style={{
                    zIndex: 2 // Force it to be displayed "on top" of the text field labels.
                }}
                open={isOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom"
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {Array.from(STATUS_TO_DETAIL_MAP.entries()).map(([status, details]) => (
                                        <MenuItem
                                            key={status}
                                            selected={status === issueStatus}
                                            onClick={() => handleMenuItemClick(status)}
                                        >
                                            <ListItemIcon>
                                                {details.icon}
                                            </ListItemIcon>
                                            {details.text}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

IssueStatusSelectMenu.defaultProps = {
    size: "medium"
};
