import { AttendeeRole } from "../../../models";
import { Auth } from "aws-amplify";
import { ChimeMeetingDTO } from '../../../lib/meeting/chime/type/ChimeMeetingDTO';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import { graphQLMeetingClient } from '../../../lib/meeting/chime/GraphQLMeetingClient';
import { meetingSessionAtom } from "../state/ChimeStates";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useRecoilCallback } from "recoil";

export const useJoinMeeting = () => {
    const meetingManager = useMeetingManager();

    const isUserLoggedIn = async (): Promise<boolean> => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return user != undefined;
        } catch (error) {
            return false;
        }
    };

    const joinMeeting = useRecoilCallback(({ set, snapshot }) => async (
        meetingId: string,
        attendeeName: string,
        role: AttendeeRole
    ): Promise<void> => {
        if (!meetingId) {
            return undefined;
        }
        // Check if a meeting is already active
        if (meetingManager.meetingSession) {
            return;
        }
        try {
            const existingMeetingSession = await snapshot.getPromise(meetingSessionAtom);
            let meetingSessionConfiguration: MeetingSessionConfiguration;
            // Reuse the existing meeting session if the meetingId and attendeeName are the same
            if (existingMeetingSession?.meetingInfo.MeetingId === meetingId && existingMeetingSession.attendeeName === attendeeName) {
                meetingSessionConfiguration = new MeetingSessionConfiguration(
                    existingMeetingSession.meetingInfo,
                    existingMeetingSession.attendeeInfo
                );
            } else {
                let joinMeetingResult: ChimeMeetingDTO;
                const isAuthenticated = await isUserLoggedIn();
                if (isAuthenticated) {
                    joinMeetingResult = await graphQLMeetingClient.joinMeeting(
                        meetingId,
                        attendeeName,
                        role
                    );
                } else {
                    joinMeetingResult = await graphQLMeetingClient.joinMeetingUnauthenticated(
                        meetingId,
                        attendeeName,
                        role
                    );
                }
                meetingSessionConfiguration = new MeetingSessionConfiguration(
                    joinMeetingResult.meetingInfo,
                    joinMeetingResult.attendeeInfo
                );
                set(meetingSessionAtom, {
                    attendeeInfo: joinMeetingResult.attendeeInfo,
                    meetingInfo: joinMeetingResult.meetingInfo,
                    attendeeName
                });
            }
            await meetingManager.join(meetingSessionConfiguration, {
                enableWebAudio: true // required for voice focus
            });
            await meetingManager.start();
        } catch (error) {
            console.log(error);
            throw error;
        }
    });

    return { joinMeeting };
};