import {
    atom,
    selector
} from "recoil";

import { AttachmentUploadStatus } from "../AttachmentUploadStatus";
import { ConsolidatedAttachmentUploadStatus } from "../ConsolidatedAttachmentUploadStatus";

export const incompleteAttachmentIdToAttachmentUploadStatusMapAtom = atom<Map<string, AttachmentUploadStatus>>({
    key: "incompleteAttachmentIdToAttachmentUploadStatusMapAtom",
    default: new Map<string, AttachmentUploadStatus>()
});

export const uploadCompletedAttachmentIdsAtom = atom<Array<string>>({
    key: "uploadCompletedAttachmentIdsAtom",
    default: new Array<string>()
});

export const consolidatedAttachmentUploadStatusSelector = selector<ConsolidatedAttachmentUploadStatus>({
    key: "consolidatedAttachmentUploadStatusSelector",
    get: ({ get }) => {
        const incompleteAttachmentIdToAttachmentUploadStatusMap = get(incompleteAttachmentIdToAttachmentUploadStatusMapAtom);
        return incompleteAttachmentIdToAttachmentUploadStatusMap.size > 0 ?
            ConsolidatedAttachmentUploadStatus.SYNC_IN_PROGRESS : ConsolidatedAttachmentUploadStatus.SYNC_COMPLETED;
    }
});