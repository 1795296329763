import DataStoreEventLoggingHandlerFactory from "./DataStoreEventLoggingHandlerFactory";
import { Hub } from "aws-amplify";
import HubBackedEventListener from "../event/HubBackedEventListener";
import { HubCapsule } from "@aws-amplify/core";
import { SetterOrUpdater } from "recoil";

export default class HubBackedDataStoreEventLoggingListenerFactory {
    private static readonly DATASTORE_CHANNEL = "datastore";
    
    public static create(
        setSystemReadyStateProgressText: SetterOrUpdater<Array<string>>
    ) {
        const hubEventHandler = DataStoreEventLoggingHandlerFactory.create(
            setSystemReadyStateProgressText
        );
        return new HubBackedEventListener(
            HubBackedDataStoreEventLoggingListenerFactory.DATASTORE_CHANNEL,
            Hub,
            (data: HubCapsule) => {
                hubEventHandler.handle(data.payload);
            }
        );
    }
}
