import { PermissionDifference } from "./PermissionDifference";
import { PermissionDifferenceCalculator } from "./PermissionDifferenceCalculator";
import { PermissionTemplate } from "./PermissionTemplate";
import { PermissionType } from "../../../models";

export class AssignablePermissionTemplateMapFilter {
    private readonly permissionDifferenceCalculator: PermissionDifferenceCalculator;

    constructor(
        permissionDifferenceCalculator: PermissionDifferenceCalculator
    ) {
        this.permissionDifferenceCalculator = permissionDifferenceCalculator;
    }

    public filter(
        permissionTemplateMap: Map<PermissionTemplate, Array<PermissionType>>,
        loggedInUserPermissions: Array<PermissionType>,
        targetUserPermissions: Array<PermissionType>
    ): Map<PermissionTemplate, Array<PermissionType>> {
        const permissionTemplateEntryList: Array<[PermissionTemplate, Array<PermissionType>]> = Array.from(permissionTemplateMap.entries());
        const targetUserTemplateToTemplateDifferenceMap: Map<PermissionTemplate, PermissionDifference> = permissionTemplateEntryList.reduce(
            (map, [template, templatePermissions]) => {
                return map.set(template, this.permissionDifferenceCalculator.getDifference(targetUserPermissions, templatePermissions));
            }, new Map<PermissionTemplate, PermissionDifference>()
        );

        const templateToTemplateDifferenceEntryList: Array<[PermissionTemplate, PermissionDifference]> = Array.from(targetUserTemplateToTemplateDifferenceMap.entries());
        const assignableTemplates: Array<PermissionTemplate> = templateToTemplateDifferenceEntryList
            .filter(([, templateDifference]) =>
                this.canAddPermissions(loggedInUserPermissions, templateDifference) && this.canRemovePermissions(loggedInUserPermissions, templateDifference)
            )
            .map(([template,]) => template);

        return assignableTemplates.reduce((map, template) => {
            return map.set(template, permissionTemplateMap.get(template)!);
        }, new Map<PermissionTemplate, Array<PermissionType>>());
    }

    private canRemovePermissions(
        userPermissions: Array<PermissionType>,
        permissionDifference: PermissionDifference
    ): boolean {
        const noPermissionsToRemove = permissionDifference.permissionsToRemove.length === 0;
        const canRemovePermissions =
            userPermissions.includes(PermissionType.REMOVE_PERMISSION) &&
            permissionDifference.permissionsToRemove.every(permissionToRemove => userPermissions.includes(permissionToRemove));
        return noPermissionsToRemove || canRemovePermissions;
    }

    private canAddPermissions(
        userPermissions: Array<PermissionType>,
        permissionDifference: PermissionDifference
    ): boolean {
        const noPermissionsToAdd: boolean = permissionDifference.permissionsToAdd.length === 0;
        const canSharePermissions: boolean = userPermissions.includes(PermissionType.SHARE_PERMISSION) &&
            permissionDifference.permissionsToAdd.every(permissionToAdd => userPermissions.includes(permissionToAdd));
        return noPermissionsToAdd || canSharePermissions;
    }
}