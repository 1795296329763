import {
    Box,
    Grid,
    LinearProgress,
    Typography
} from '@mui/material';

import ClientLogger from "../../lib/logging/ClientLogger";
import ClientLoggerFactory from '../../lib/logging/ClientLoggerFactory';
import DataStorePropertyDAOFactory from '../../lib/property/dao/datastore/DataStorePropertyDAOFactory';
import ListProperty from '../../componentsV2/property/ListProperty';
import LoggerCommonTags from '../../lib/logging/LoggerCommonTags';
import { Property } from "../../models";
import PropertyDAO from '../../lib/property/dao/PropertyDAO';
import { PropertySortMenu } from '../../components/general/sorting/PropertySortMenu';
import { StartPropertiesChildrenSyncWrapper } from '../../components/sync/StartPropertiesChildrenSyncWrapper';
import globalStyle from '../../assets/style/globalStyle';
import { propertyIdToPropertySelectorFamily } from '../../lib/property/state/PropertyRecoilState';
import { useEffect } from "react";
import { usePageDefaultStyle } from "../../assets/style/usePageDefaultStyle";
import { useRecoilCallback } from 'recoil';
import { useState } from 'react';

const propertyDAO: PropertyDAO = DataStorePropertyDAOFactory.getInstance();
const logger: ClientLogger = ClientLoggerFactory.getClientLogger("PropertiesPage");

const PropertiesPage = () => {

    /* Internal page state */
    const [isLoading, setIsLoading] = useState<boolean>(true);

    /* Dependencies */
    const { pageDefaultStyle } = usePageDefaultStyle();
    const globalClasses = globalStyle();

    const fetchProperties = useRecoilCallback((({ set, reset }) => {
        return async () => {
            try {
                setIsLoading(true);
                const properties: Array<Property> = await propertyDAO.listProperties();
                for (const property of properties) {
                    set(propertyIdToPropertySelectorFamily(property.id), property);
                }
            } catch (error) {
                logger.error(
                    "Failed to retrieve user properties",
                    error,
                    [LoggerCommonTags.PROPERTY_LIST_RETRIEVAL_FAILURE]
                );
            } finally {
                setIsLoading(false);
            }
        };
    }), []);

    useEffect(() => {
        fetchProperties();
    }, []);

    return (
        <StartPropertiesChildrenSyncWrapper>
            {isLoading ? (
                <Box sx={pageDefaultStyle}>
                    <LinearProgress style={{ width: "100%" }} />
                </Box >
            ) : (
                <Box sx={pageDefaultStyle}>
                    <Box className={globalClasses.managerLayout}>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item>
                                <Typography variant="h6">
                                    Properties
                                </Typography>
                            </Grid>
                            <Grid item>
                                <PropertySortMenu />
                            </Grid>
                        </Grid>
                    </Box>
                    <ListProperty />
                </Box>
            )}
        </StartPropertiesChildrenSyncWrapper>
    );
};

export default PropertiesPage;
