import {
    FormHelperText,
    Grid
} from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    hidden: {
        display: "none"
    },
}));

/*
 * FormFieldSummary props:
 *   characterLimit - if supplied, used to display the character limit.
 *   fieldText - used to calculate the number of characters remaining.
 *   isValid - if true, displays nothing, otherwise displays the errorText.
 *   errorText - error text to display if field value is invalid.
 */
const FormFieldSummary = (props) => {
    const classes = useStyles();

    return (
        <Grid container justifyContent="space-between">
            <Grid container item xs={props.characterLimit ? 6 : 12}>
                <FormHelperText
                        style={{color: "#F44336"}}
                        className={props.isValid ? classes.hidden : "" }
                >
                    {props.errorText}
                </FormHelperText>
            </Grid>
            <Grid container item xs justifyContent="flex-end">
                <FormHelperText>
                    { props.characterLimit ? (
                            props.fieldText ? (
                                (props.characterLimit - props.fieldText.length) + " characters remaining."
                            ) : (
                                props.characterLimit + " characters remaining"
                            )
                        ) : ""
                    }
                </FormHelperText>
            </Grid>
        </Grid>
    );
};

export default FormFieldSummary;
