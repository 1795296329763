import * as yup from "yup";

import Alert from '@mui/material/Alert';
import { AlertMessage } from '../../lib/util/AlertMessage';
import { Auth } from "aws-amplify";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LoadingButton from "@mui/lab/LoadingButton";
import { PasswordField } from "./PasswordField";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from "formik";
import { useState } from "react";

const REQUIRED_FIELD_TEXT = "Field cannot be empty";

const resetPasswordFormSchema = yup.object({
    code: yup.string().required(REQUIRED_FIELD_TEXT),
    password: yup
        .string()
        .min(8, 'Password must have at least 8 characters')
        .matches(/[0-9]/, 'Password must contains at least one number')
        .matches(/[a-z]/, 'Password must contains at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contains at least one uppercase letter')
        .matches(/[^\w_]/, 'Password must contains at least one special character')
        .required(REQUIRED_FIELD_TEXT),
    confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Your passwords must match").required()
});

type ResetPasswordFormData = yup.InferType<typeof resetPasswordFormSchema>;

type ResetPasswordFormProps = {
    email: string;
};
export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
    const { email } = props;
    const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
    const [isResettingPassword, setIsResettingPassword] = useState<boolean>(false);
    const [isResendingCode, setIsResendingCode] = useState<boolean>(false);

    const resetPassword = async (data: ResetPasswordFormData) => {
        try {
            setIsResettingPassword(true);
            setAlertMessage(undefined);
            await Auth.forgotPasswordSubmit(email, data.code, data.password);
            window.location.reload();
        } catch (error) {
            setAlertMessage({
                severity: "error",
                message: (error as Error).message
            });
        } finally {
            setIsResettingPassword(false);
        }
    };

    const formik = useFormik<ResetPasswordFormData>({
        initialValues: {
            code: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: resetPasswordFormSchema,
        onSubmit: resetPassword
    });

    const resendCode = async () => {
        try {
            setIsResendingCode(true);
            await Auth.forgotPassword(email);
            setAlertMessage({
                severity: "info",
                message: `Code resent.`
            });
        } catch (error) {
            setAlertMessage({
                severity: "error",
                message: (error as Error).message
            });
        } finally {
            setIsResendingCode(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                mt={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" fontWeight="500">
                                Reset your password
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="code"
                                label="Code"
                                name="code"
                                onChange={formik.handleChange}
                                value={formik.values.code}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordField
                                id="password"
                                name="password"
                                label="Password"
                                autoComplete="new-password"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordField
                                id="confirmPassword"
                                name="confirmPassword"
                                label="Confirm Password"
                                autoComplete="new-password"
                                onChange={formik.handleChange}
                                value={formik.values.confirmPassword}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />
                        </Grid>
                        {alertMessage &&
                            <Grid item xs={12}>
                                <Alert severity={alertMessage.severity}> {alertMessage.message}</Alert>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                loading={isResettingPassword}
                                startIcon={<></>}
                                loadingPosition="start"
                            >
                                Submit
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                fullWidth
                                variant="outlined"
                                onClick={resendCode}
                                loading={isResendingCode}
                                startIcon={<></>}
                                loadingPosition="start"
                            >
                                Resend Code
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};