import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const FavIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 24 24"
    className="tenera-icon"
    {...props}
  >
    <path
      fill="#FAC233"
      d="m21.86 10.133-4.4 4.476c-.1.125-.15.3-.125.475l1.025 6.327c.075.425-.35.725-.7.525l-5.425-2.976a.477.477 0 0 0-.45 0L6.36 21.936c-.375.2-.775-.1-.725-.525l1.05-6.327a.553.553 0 0 0-.15-.475L2.16 10.133c-.3-.3-.15-.825.25-.875l6.075-.925c.15-.025.3-.125.375-.275l2.7-5.777a.48.48 0 0 1 .875 0l2.725 5.777c.075.15.2.25.35.275l6.075.925c.4.05.55.575.275.875z"
      className="primary"
    />
    <path
      fill="#F9B52E"
      d="M17.895 23.001c-.25 0-.502-.065-.731-.196l-5.155-2.827-5.169 2.835a1.494 1.494 0 0 1-1.553-.069 1.527 1.527 0 0 1-.646-1.449l1.02-6.148-4.217-4.314a1.53 1.53 0 0 1-.36-1.582 1.456 1.456 0 0 1 1.201-.985l5.785-.882 2.583-5.526A1.47 1.47 0 0 1 11.996 1a1.47 1.47 0 0 1 1.344.859l2.608 5.53 5.786.881a1.46 1.46 0 0 1 1.169.957 1.547 1.547 0 0 1-.306 1.583l-.025.026-4.219 4.292.992 6.125a1.486 1.486 0 0 1-1.45 1.748zm-.522-1.428a.05.05 0 0 0 .002.012l-.002-.012zm.754-.521.014.008a.04.04 0 0 0-.014-.008zM3.524 10.099l3.725 3.811c.337.338.495.82.425 1.315l-.885 5.335 4.515-2.477c.382-.231.925-.245 1.379-.017l4.524 2.48-.859-5.303a1.64 1.64 0 0 1 .333-1.263l3.809-3.88-5.13-.781a1.485 1.485 0 0 1-1.094-.815L11.999 3.7 9.765 8.481c-.226.454-.64.759-1.118.838l-5.123.78zm8.776 9.718-.045.025a.337.337 0 0 0 .045-.025zm-6.481-4.509.007.008-.007-.008zm15.615-5.062.026.004-.026-.004zm-18.852-.003-.022.003.022-.003zm.284-.817.007.007-.007-.007zm8.664-6.718.001.003-.001-.003z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(FavIcon);
