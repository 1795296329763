import {
    API,
    graphqlOperation
} from "aws-amplify";
import {
    CustomSearchUsersQuery,
    CustomSearchUsersQueryVariables,
    SearchableUserFilterInput
} from "../../API.d";

import ClientLogger from "../logging/ClientLogger";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { IDTokenSupplier } from "../auth/IDTokenSupplier";
import { SearchResponse } from "../util/search/SearchResponse";
import { SearchUserError } from "./errors/SearchUserError";
import { User } from "../../models";
import { UserDAO } from "./UserDAO";
import { customSearchUsers } from "../../graphql/queries";

export class GraphQLUserDAO implements UserDAO {
    public static readonly SEARCH_ATTEMPT = "GraphQLUserDAO.searchByUsernameOrEmail.Attempt";
    public static readonly SEARCH_FAILURE = "GraphQLUserDAO.searchByUsernameOrEmail.Failure";

    private readonly api: typeof API;
    private readonly gqlOperation: typeof graphqlOperation;
    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async searchByUsernameOrEmail(
        phrase: string,
        userIdsToExclude: Array<string> = [],
        nextToken?: string | undefined,
        limit?: number | undefined
    ): Promise<SearchResponse<User>> {
        try {
            this.logger.info(
                `Searching users: ${phrase}. Limit: ${limit}, NextToken: ${nextToken}`,
                undefined,
                [GraphQLUserDAO.SEARCH_ATTEMPT]
            );
            const userIdsToExcludeFilter: Array<SearchableUserFilterInput> = userIdsToExclude.map(userId => (
                {
                    id: {
                        ne: userId
                    }
                }
            ));
            const variables: CustomSearchUsersQueryVariables = {
                input: {
                    limit: limit,
                    nextToken: nextToken,
                    filter: {
                        and: [
                            {
                                or: [
                                    {
                                        name: {
                                            contains: phrase
                                        }
                                    },
                                    {
                                        email: {
                                            eq: phrase
                                        }
                                    }
                                ]
                            },
                            ...userIdsToExcludeFilter
                        ]
                    }
                }
            };
            const authToken: string = await this.idTokenSupplier.get();
            const gqlOperation = this.gqlOperation(
                customSearchUsers,
                variables,
                authToken
            );
            const queryResult = await this.api.graphql(gqlOperation) as GraphQLResult<CustomSearchUsersQuery>;
            const objects: Array<User> = (queryResult.data?.customSearchUsers?.items ?? []) as Array<User>;
            return {
                objects: objects,
                nextToken: queryResult.data?.customSearchUsers?.nextToken ?? undefined,
                total: objects.length
            } as SearchResponse<User>;
        } catch (error) {
            const errorMessage: string = `Unable to search users: ${phrase}. Limit: ${limit}, NextToken: ${nextToken}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLUserDAO.SEARCH_FAILURE]
            );
            throw new SearchUserError(errorMessage, error as Error);
        }
    }
}