import {
    Box,
    Button,
    ButtonProps,
    Popover
} from "@mui/material";
import {
    MutableRefObject,
    useRef,
    useState
} from "react";

type ButtonConfirmationPromptProps = {
    readonly promptMessage: string;
    readonly promptStyles?: ButtonProps;
    readonly children: (onClick: () => void, ref: MutableRefObject<HTMLButtonElement | null>) => JSX.Element;
    readonly onConfirmButtonClicked: () => (Promise<void> | void);
};

export const ButtonConfirmationPrompt = (props: ButtonConfirmationPromptProps) => {
    const { promptMessage, promptStyles, children, onConfirmButtonClicked } = props;
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const anchorRef = useRef<HTMLButtonElement | null>(null);

    return (
        <Box>
            {children(() => setShowMessage(prev => !prev), anchorRef)}
            <Popover
                open={showMessage}
                onClose={() => setShowMessage(false)}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Button
                    onClick={async () => {
                        await onConfirmButtonClicked();
                        setShowMessage(false);
                    }}
                    {...promptStyles}
                >
                    {promptMessage}
                </Button>
            </Popover>
        </Box>
    );
};
