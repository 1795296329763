import { ATTACHMENT_UPLOAD_CHANNEL } from "../AttachmentUploadConstant";
import { AttachmentUploadStatus } from "./AttachmentUploadStatus";
import AttachmentUploadStatusUpdatingHubEventHandlerFactory from "./AttachmentUploadStatusUpdatingHubEventHandlerFactory";
import { Hub } from "aws-amplify";
import HubBackedEventListener from "../../../event/HubBackedEventListener";
import { HubCapsule } from "@aws-amplify/core";
import { SetterOrUpdater } from "recoil";

export default class HubBackedAttachmentUploadEventListenerFactory {
    public static create(
        setIncompleteAttachmentIdToAttachmentUploadStatus: SetterOrUpdater<Map<string, AttachmentUploadStatus>>,
        setUploadCompletedAttachmentIds: SetterOrUpdater<Array<string>>
    ) {
        const hubEventHandler = AttachmentUploadStatusUpdatingHubEventHandlerFactory.create(
            setIncompleteAttachmentIdToAttachmentUploadStatus,
            setUploadCompletedAttachmentIds
        );
        return new HubBackedEventListener(
            ATTACHMENT_UPLOAD_CHANNEL,
            Hub,
            (data: HubCapsule) => {
                hubEventHandler.handle(data.payload);
            }
        );
    }
}