import {
    useAudioVideo,
    useLocalAudioInputActivity,
    useToggleLocalMute
} from "amazon-chime-sdk-component-library-react";
import {
    useCallback,
    useEffect,
    useRef
} from "react";

import _ from "lodash";
import { useSnackbar } from "notistack";

const snackbarKey = "speaking-on-mute";
const MOST_RECENT_AUDIO_INPUT_LEVELS_SIZE = 5;
const AUDIO_LEVEL_THRESHOLD = 0.2;

export const SpeakingOnMuteSnackbar = () => {
    const audioVideo = useAudioVideo();
    const snackbar = useSnackbar();
    const { muted } = useToggleLocalMute();

    const mostRecentAudioInputLevelsRef = useRef<Array<number>>([]);
    const mutedRef = useRef<boolean>(muted);
    mutedRef.current = muted;

    const localAudioInputActivityCallback = useCallback((decimal: number) => {
        mostRecentAudioInputLevelsRef.current.push(decimal);
        if (mostRecentAudioInputLevelsRef.current.length > MOST_RECENT_AUDIO_INPUT_LEVELS_SIZE) {
            mostRecentAudioInputLevelsRef.current.shift();
        }
        const averageAudioInputLevel = _.mean(mostRecentAudioInputLevelsRef.current);
        const showSnackbar = mutedRef.current && averageAudioInputLevel > AUDIO_LEVEL_THRESHOLD;
        if (showSnackbar) {
            snackbar.enqueueSnackbar("You're on Mute. Please unmute your microphone.", {
                key: snackbarKey,
                variant: "warning",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                },
                autoHideDuration: 3000,
                preventDuplicate: true
            });
        }
    }, []);

    useLocalAudioInputActivity(localAudioInputActivityCallback);

    useEffect(() => {
        if (!audioVideo) {
            return;
        }
        return () => {
            snackbar.closeSnackbar(snackbarKey);
        };
    }, [audioVideo]);

    useEffect(() => {
        if (!muted) {
            snackbar.closeSnackbar(snackbarKey);
        }
    }, [muted]);

    return <></>;
};