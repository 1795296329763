import {
    Box,
    Button,
    Divider,
    Fade,
    IconButton,
    Paper,
    Typography
} from "@mui/material";
import {
    RefCallback,
    useCallback,
    useMemo,
    useRef
} from "react";

import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { MessageSource } from "../../lib/meeting/message/MessageSource";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { TranscriptContextWindow } from "../../lib/meeting/transcript/TranscriptContextWindow";
import { TranscriptContextWindowBehaviour } from "../../lib/meeting/transcript/TranscriptContextWindowBehaviour";
import { TranscriptionMessage } from "../../lib/meeting/message/TranscriptionMessage";
import { transcriptContextWindowAtom } from "./hooks/state/AISummarizationStates";
import { useChangeTranscriptContextWindow } from "./hooks/useChangeTranscriptContextWindow";
import { useCustomRosterState } from "./customized/CustomRosterProvider";
import { useRecoilValue } from "recoil";

type TranscriptionDisplayProps = {
    messages: TranscriptionMessage[],
    messagesEndRef: RefCallback<HTMLDivElement | null>;
    isLastMessageInView: boolean;
};

export const TranscriptionDisplay = (props: TranscriptionDisplayProps) => {
    const contextWindow = useRecoilValue<TranscriptContextWindow>(transcriptContextWindowAtom);
    const { setContextWindowEdge, resetContextWindow } = useChangeTranscriptContextWindow();
    const { messages, messagesEndRef, isLastMessageInView } = props;
    const ref = useRef<HTMLDivElement>();
    const { roster } = useCustomRosterState();

    const setRef = useCallback((node: HTMLDivElement) => {
        ref.current = node;
        messagesEndRef(node);
    }, [messagesEndRef]);

    const completedMessages = useMemo(() => messages.filter(message => !message.isPartial), [messages]);
    const messagesInProgress = useMemo(() => messages.filter(message => message.isPartial), [messages]);

    const effectiveContextWindow = useMemo(() => {
        if (contextWindow.behaviour === TranscriptContextWindowBehaviour.TAILING) {
            // if the number of messages is smaller than the context window, include all messages
            const startIndex = completedMessages.length < contextWindow.windowSize ?
                0 :
                completedMessages.length - contextWindow.windowSize;
            return {
                startIndex: startIndex,
                endIndex: completedMessages.length
            };
        }
        return contextWindow;
    }, [contextWindow, completedMessages]);

    const clearContextButton = useMemo(() => {
        if (contextWindow.behaviour !== TranscriptContextWindowBehaviour.TAILING) {
            return (
                <IconButton
                    size="small"
                    onClick={() => {
                        resetContextWindow();
                    }}
                >
                    <CancelIcon />
                </IconButton>
            );
        }
        return null;
    }, [contextWindow.behaviour, resetContextWindow]);

    const contextWindowEdge = (message: string) => {
        return <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                mt: -1,
                width: '100%'
            }}
        >
            <Divider sx={{ flexGrow: 1 }}>
                <Typography
                    variant="subtitle2"
                    fontSize={14}
                    sx={{ fontStyle: 'italic' }}
                >
                    {message}
                    {clearContextButton}
                </Typography>
            </Divider>
        </Box>
    }


    return (
        <Box
            sx={{
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: "100%",
                padding: '1rem',
                whiteSpace: 'normal',
                position: 'relative'
            }}
        >
            {completedMessages.map((message, index) => {
                const backgroundColor = message.source === MessageSource.Self ? 'lightgoldenrodyellow' : message.source === MessageSource.Other ? 'lightgreen' : 'lightblue';
                return (
                    <>
                        {index === effectiveContextWindow.startIndex && (
                            contextWindowEdge("AI Context start")
                        )}
                        <Paper
                            key={index}
                            sx={{
                                width: '100%',
                                marginBottom: '1rem',
                                padding: '1rem',
                                backgroundColor,
                                borderRadius: '1rem',
                                display: 'flex',
                                alignItems: 'center',
                                border: message.isUsed ? '2px solid black' : undefined,
                                "&:hover": {
                                    border: "2px solid lightblue",
                                }
                            }}
                            onClick={() => {
                                setContextWindowEdge(index);
                            }}
                            ref={messagesInProgress.length === 0 && index === completedMessages.length - 1 ? setRef : undefined}
                        >
                            {roster[message.attendeeId] && (
                                <Typography variant="body1" sx={{ marginRight: '0.5rem' }} fontWeight={500}>
                                    {roster[message.attendeeId].name}:
                                </Typography>
                            )}
                            {message.source === MessageSource.Bot && <SmartToyIcon sx={{ marginRight: '0.5rem' }} />}
                            <Typography variant="body1">{message.message}</Typography>
                        </Paper>
                        {index === effectiveContextWindow.endIndex - 1 && contextWindow.behaviour !== TranscriptContextWindowBehaviour.TAILING &&
                            contextWindowEdge("AI Context end")
                        }
                    </>
                );
            })}
            {messagesInProgress.map((messageInProgress, index) => {
                const backgroundColor = messageInProgress.source === MessageSource.Other ? "green" : messageInProgress.source === MessageSource.Self ? "orange" : "blue";
                return (
                    <Paper
                        key={`inProgress-${index}`}
                        sx={{
                            width: '100%',
                            marginBottom: '1rem',
                            padding: '1rem',
                            backgroundColor,
                            borderRadius: '1rem',
                            display: 'flex',
                            alignItems: 'center',
                            border: messageInProgress.isUsed ? '2px solid black' : undefined
                        }}
                        ref={index === messagesInProgress.length - 1 ? setRef : undefined}
                    >
                        {roster[messageInProgress.attendeeId] && (
                            <Typography variant="body1" sx={{ marginRight: '0.5rem' }} fontWeight={500}>
                                {roster[messageInProgress.attendeeId].name}:
                            </Typography>
                        )}
                        <Typography variant="body1">{messageInProgress.message}</Typography>
                    </Paper>
                );
            })}
            <Fade in={contextWindow.behaviour !== TranscriptContextWindowBehaviour.TAILING}>
                <Button
                    variant="contained"
                    sx={{ position: 'fixed', bottom: 40, zIndex: theme => theme.zIndex.drawer + 1 }}
                    onClick={() => {
                        resetContextWindow();
                    }}
                >
                    Reset AI Context Window
                </Button>
            </Fade>
            <Fade in={messages.length > 0 && !isLastMessageInView}>
                <IconButton
                    onClick={() => {
                        ref.current?.scrollIntoView({ behavior: "smooth" });
                    }}
                    size="small"
                    sx={{ position: 'fixed', bottom: 2, backgroundColor: 'white', zIndex: theme => theme.zIndex.drawer + 1 }}
                >
                    <KeyboardDoubleArrowDownIcon />
                </IconButton>
            </Fade>
        </Box>
    );
};