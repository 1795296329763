import {
    Auth,
    CognitoUser
} from "@aws-amplify/auth";

import HubEventHandler from "../event/HubEventHandler";
import { HubPayload } from "@aws-amplify/core";
import { SetterOrUpdater } from "recoil";

export default class AuthTokenRefreshEventHandler implements HubEventHandler {
    private static readonly TOKEN_REFRESH_EVENT = "tokenRefresh";
    private readonly setTokenExpireEpochMillis: SetterOrUpdater<number | undefined>;

    constructor(
        setTokenExpireEpochMillis: SetterOrUpdater<number | undefined>
    ) {
        this.setTokenExpireEpochMillis = setTokenExpireEpochMillis;
    }

    public async handle(payload: HubPayload): Promise<void> {
        if (payload.event !== AuthTokenRefreshEventHandler.TOKEN_REFRESH_EVENT) {
            return;
        }
        const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser()
        const expireEpochSeconds = cognitoUser.getSignInUserSession()?.getAccessToken().getExpiration();
        this.setTokenExpireEpochMillis(expireEpochSeconds == null ? undefined : expireEpochSeconds * 1000);
    }

}