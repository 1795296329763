import { S3ImageAttachmentContentDAOFactory } from "../../../../lib/attachment/content/s3/S3AttachmentContentDAOFactory";
import { useSnackbar } from "notistack";
import uuidv4 from "../../../../lib/util/UuidGenerator";

export const useUploadLogo = () => {
    const contentDAO = S3ImageAttachmentContentDAOFactory.getInstance("logo");
    const snackbar = useSnackbar();

    const uploadLogo = async (
        key: string = uuidv4(),
        content: ArrayBuffer
    ) => {
        try {
            await contentDAO.saveAttachment(key, {data: content});
            return true;
        } catch (error) {
            snackbar.enqueueSnackbar(`Failed to upload Image: ${error}`, { variant: "error", });
            return false;
        }
    }

    return {uploadLogo}
}