import {
    atomFamily,
    selectorFamily
} from "recoil";

import DataStoreLocationDAOFactory from "../../../location/dao/datastore/DataStoreLocationDAOFactory";
import LocationDAO from "../../../location/dao/LocationDAO";
import LocationDefaultNameFactory from "../../../location/name/LocationDefaultNameFactory";
import { StateContext } from "./StateContext";
import { propertyIdInFocusAtom } from "../../../ui/InFocusRecoilStates";

const INSPECTION_DEFAULT_LOCATION_NAME = "Group";
const SOLUTION_DEFAULT_LOCATION_NAME = "Solution Group";

const locationDao: LocationDAO = DataStoreLocationDAOFactory.getInstance();

export const numberOfLocationsCreatedAtomFamily = atomFamily<number, StateContext>({
    key: "numberOfLocationsCreatedAtomFamily",
    default: 0
});

export const locationDefaultNameFactorySelectorFamily = selectorFamily<LocationDefaultNameFactory | undefined, StateContext>({
    key: "locationDefaultNameFactorySelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        if (context === StateContext.INSPECTION) {
            const propertyId = get(propertyIdInFocusAtom)!;
            return new LocationDefaultNameFactory(INSPECTION_DEFAULT_LOCATION_NAME, async () => {
                const locations = await locationDao.getLocationsByPropertyId(propertyId);
                return locations.length + 1;
            });
        }
        if (context === StateContext.SOLUTION_AUTHORING_CONTENT) {
            const numberOfLocationsCreated = get(numberOfLocationsCreatedAtomFamily(context));
            return new LocationDefaultNameFactory(SOLUTION_DEFAULT_LOCATION_NAME, async () => {
                return numberOfLocationsCreated + 1;
            });
        }
        return undefined;
    }
});
