import '@pqina/pintura/pintura.css';

import {
    PinturaDefaultImageWriterResult,
    createMarkupEditorToolStyle,
    createMarkupEditorToolStyles,
    getEditorDefaults
} from '@pqina/pintura';

import { Box } from '@mui/material';
import { PinturaEditor } from '@pqina/react-pintura';

const editorConfig = getEditorDefaults(
    {
        annotateTools: [
            ["sharpie", "Marker", { disabled: false }],
            ["arrow", "Arrow", { disabled: false }],
            ["rectangle", "Rectangle", { disabled: false }],
            ["text", "Text", { disabled: false }]
        ],
        utils: [
            "annotate"
        ],
        markupEditorToolStyles: createMarkupEditorToolStyles({
            "sharpie": createMarkupEditorToolStyle("path", {
                strokeWidth: 5,
                strokeColor: [1, 0, 0, 0.5] // translucent red
            }),
            "rectangle": createMarkupEditorToolStyle("rectangle", {
                strokeWidth: 5,
                strokeColor: [1, 0, 0, 0.5], // translucent red
                backgroundColor: [0, 0, 0, 0], // transparent
            }),
            "arrow": createMarkupEditorToolStyle("line", {
                strokeWidth: 5,
                lineEnd: "arrow-solid",
                strokeColor: [1, 0, 0, 1] // solid red
            }),
            "text": createMarkupEditorToolStyle("text", {
                fontSize: 20,
                fontFamily: "Arial",
                color: [1, 0, 0, 1] // solid red
            })
        }),
    },
);

interface ImageEditorProps {
    source: Blob | string;
    onImageUpload: (image: File) => void;
    onClose: () => void;
}

export const ImageEditor = (props: ImageEditorProps) => {
    const {source, onImageUpload, onClose} = props;
    return (
        <Box style={{ height: "100%", width: "100%" }}>
            <PinturaEditor
                {...editorConfig}
                onProcess={(imageState: PinturaDefaultImageWriterResult) => {
                    onImageUpload(imageState.dest);
                    onClose();
                }}
                src={source}
                enableButtonClose={true}
                onClose={onClose}
                enableButtonRevert={false}
            />
        </Box>
    );
};
