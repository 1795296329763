import {
    childExpansionModeByContextAtomFamily,
    childrenExpandStateByDesignElementIdentifierAtomFamily
} from "../document/state/ComponentExpandState";
import {
    dimensionIdsByIssueIdentifierAtomFamily,
    issueByIssueIdentifierSelectorFamily,
    issueIdsByLocationIdentifierAtomFamily,
    locationIdsByContextAtomFamily,
    workSpecificationIdsByDimensionIdentifierAtomFamily
} from "../document/state/DocumentState";

import { ChildExpansionMode } from "../document/state/enum/ChildExpansionMode";
import { ChildrenExpandState } from "../document/state/enum/ChildrenExpandState";
import { ContextAwareIdentifier } from "../document/ContextAwareIdentifier";
import { Issue } from "../../../models";
import { ModelType } from "../document/ModelType";
import { StateContext } from "../document/state/StateContext";
import { idInFocusByModelTypeAtomFamily } from "../../ui/InFocusRecoilStates";
import { useRecoilCallback } from "recoil";

export const useSetChildrenExpandState = (context: StateContext) => {
    const setModelChildrenExpandState = useRecoilCallback(({ snapshot, set, reset }) => (
        modelType: ModelType,
        id: string,
        childrenExpandState: ChildrenExpandState,
    ) => {
        const childExpansionMode: ChildExpansionMode = snapshot.getLoadable(childExpansionModeByContextAtomFamily(context)).contents;
        const elementIdentifier = new ContextAwareIdentifier(id, context, modelType);
        set(childrenExpandStateByDesignElementIdentifierAtomFamily(elementIdentifier), childrenExpandState);

        if (childrenExpandState === ChildrenExpandState.COLLAPSE_CHILDREN) {
            if (context === StateContext.INSPECTION || context === StateContext.SOLUTION_AUTHORING_CONTENT) {
                const existingIdInFocus = snapshot.getLoadable(idInFocusByModelTypeAtomFamily(modelType)).contents;
                if (existingIdInFocus === id) {
                    reset(idInFocusByModelTypeAtomFamily(modelType));
                }
                const issueIdentifier = new ContextAwareIdentifier(id, context, ModelType.ISSUE);
                const dimensionIds: Array<string> = snapshot.getLoadable((dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier))).contents;
                dimensionIds.forEach(dimensionId => {
                    const dimensionIdentifier = new ContextAwareIdentifier(dimensionId, context, ModelType.DIMENSION);
                    const workSpecificationIds: Array<string> = snapshot.getLoadable(workSpecificationIdsByDimensionIdentifierAtomFamily(dimensionIdentifier)).contents;
                    workSpecificationIds.forEach(workSpecificationId => setModelChildrenExpandState(ModelType.WORK_SPECIFICATION, workSpecificationId, ChildrenExpandState.COLLAPSE_CHILDREN));
                });
            }
            if (modelType === ModelType.LOCATION) {
                const locationIdentifier = new ContextAwareIdentifier(id, context, ModelType.LOCATION);
                set(childrenExpandStateByDesignElementIdentifierAtomFamily(locationIdentifier), ChildrenExpandState.COLLAPSE_CHILDREN);
                const issueIds: Array<string> = snapshot.getLoadable(issueIdsByLocationIdentifierAtomFamily(locationIdentifier)).contents;
                issueIds.forEach(issueId => setModelChildrenExpandState(ModelType.ISSUE, issueId, ChildrenExpandState.COLLAPSE_CHILDREN));
            }
            return;
        }

        if (childExpansionMode === ChildExpansionMode.FREE) {
            return;
        }

        // Focus mode logic
        if (context === StateContext.INSPECTION || context === StateContext.SOLUTION_AUTHORING_CONTENT) {
            set(idInFocusByModelTypeAtomFamily(modelType), id);
        }
        if (modelType === ModelType.LOCATION) {
            const locationIds: Array<string> = snapshot.getLoadable(locationIdsByContextAtomFamily(context)).contents;
            locationIds
                .filter(locationId => locationId !== id)
                .forEach(locationId => setModelChildrenExpandState(ModelType.LOCATION, locationId, ChildrenExpandState.COLLAPSE_CHILDREN));
            return;
        }
        if (modelType === ModelType.ISSUE) {
            const issue: Issue = snapshot.getLoadable(issueByIssueIdentifierSelectorFamily(elementIdentifier)).contents;
            const locationIdentifier = new ContextAwareIdentifier(issue.locationId!, context, ModelType.LOCATION);
            setModelChildrenExpandState(ModelType.LOCATION, issue.locationId!, childrenExpandState);
            const issueIds: Array<string> = snapshot.getLoadable(issueIdsByLocationIdentifierAtomFamily(locationIdentifier)).contents;
            issueIds
                .filter(issueId => issueId !== id)
                .forEach(issueId => setModelChildrenExpandState(ModelType.ISSUE, issueId, ChildrenExpandState.COLLAPSE_CHILDREN));
            return;
        }
    }, [context]);

    const setChildrenExpandStateForAllLocations = useRecoilCallback(({ snapshot, set, reset }) => (
        childrenExpandState: ChildrenExpandState,
        propagateToIssue: boolean = true
    ) => {
        if (context === StateContext.INSPECTION || context === StateContext.SOLUTION_AUTHORING_CONTENT) {
            reset(idInFocusByModelTypeAtomFamily(ModelType.LOCATION));
            reset(idInFocusByModelTypeAtomFamily(ModelType.ISSUE));
        }
        const locationIds: Array<string> = snapshot.getLoadable(locationIdsByContextAtomFamily(context)).contents;
        locationIds.forEach(locationId => {
            const locationIdentifier = new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
            set(childrenExpandStateByDesignElementIdentifierAtomFamily(locationIdentifier), childrenExpandState);
            if (propagateToIssue) {
                const issueIds: Array<string> = snapshot.getLoadable(issueIdsByLocationIdentifierAtomFamily(locationIdentifier)).contents;
                issueIds.forEach(issueId => {
                    const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
                    set(childrenExpandStateByDesignElementIdentifierAtomFamily(issueIdentifier), childrenExpandState);
                });
            }
        });
    }, [context]);

    const resetChildrenExpandState = useRecoilCallback(({ snapshot, reset }) => (
        modelType: ModelType,
        id: string,
    ) => {
        const elementIdentifier = new ContextAwareIdentifier(id, context, modelType);
        reset(childrenExpandStateByDesignElementIdentifierAtomFamily(elementIdentifier));
        const existingIdInFocus = snapshot.getLoadable(idInFocusByModelTypeAtomFamily(modelType)).contents;
        if (existingIdInFocus === id && (context === StateContext.INSPECTION || context === StateContext.SOLUTION_AUTHORING_CONTENT)) {
            reset(idInFocusByModelTypeAtomFamily(modelType));
        }
        if (modelType === ModelType.LOCATION) {
            const issueIds: Array<string> = snapshot.getLoadable(issueIdsByLocationIdentifierAtomFamily(elementIdentifier)).contents;
            issueIds.forEach(issueId => resetChildrenExpandState(ModelType.ISSUE, issueId));
            return;
        }
        if (modelType === ModelType.ISSUE) {
            const dimensionIds: Array<string> = snapshot.getLoadable((dimensionIdsByIssueIdentifierAtomFamily(elementIdentifier))).contents;
            dimensionIds.forEach(dimensionId => resetChildrenExpandState(ModelType.DIMENSION, dimensionId));
            return;
        }
        if (modelType === ModelType.DIMENSION) {
            const workSpecificationIds: Array<string> = snapshot.getLoadable(workSpecificationIdsByDimensionIdentifierAtomFamily(elementIdentifier)).contents;
            workSpecificationIds.forEach(workSpecificationId => resetChildrenExpandState(ModelType.WORK_SPECIFICATION, workSpecificationId));
            return;
        }
    }, [context]);

    return { setChildrenExpandStateForAllLocations, setModelChildrenExpandState, resetChildrenExpandState };
};