import {
    Button,
    ButtonProps
} from "@mui/material";

import { PermissionType } from "../../../models";
import { resourcePermissionAtomFamily } from "../../../lib/permission/state/ResourcePermissionRecoilState";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";

interface PermissionRestrictedButtonProps extends ButtonProps {
    resourceId: string;
    requiredPermission: PermissionType;
    permissionOverridden?: boolean;
} 

export const PermissionRestrictedButton = (props: PermissionRestrictedButtonProps) => {
    const {resourceId, requiredPermission, permissionOverridden, ...buttonProps} = props;
    const resourcePermissions = useRecoilValue<Set<PermissionType>>(resourcePermissionAtomFamily(resourceId));

    const isEnabled = useMemo(() => {
        if (props.disabled) {
            return false;
        }
        if (permissionOverridden) {
            return true;
        }
        return resourcePermissions.has(requiredPermission);
    }, [props.disabled, permissionOverridden, resourcePermissions, requiredPermission])

    return (
        <Button
            {...buttonProps}
            disabled={!isEnabled}
        >
            {props.children}
        </Button>
    );
}