import AuthUsernameSupplier from "../auth/UsernameSupplier";
import EligibilityCheckError from "../ui/EligibilityCheckError";
import EligibilityChecker from "../ui/EligibilityChecker";
import { UserOnboardingProgress } from "../../models";
import UserOnboardingProgressDAO from "./UserOnboardingProgressDAO";
import { or } from "../util/data/predicate/predicate";

export default class UserOnboardingEligibilityChecker implements EligibilityChecker {

    private readonly authUsernameProvider: AuthUsernameSupplier;
    private readonly cloudUserOnboardingProgressDAO: UserOnboardingProgressDAO;
    private readonly localUserOnboardingProgressDAO: UserOnboardingProgressDAO;

    constructor(
        authUsernameProvider: AuthUsernameSupplier,
        cloudUserOnboardingProgressDAO: UserOnboardingProgressDAO,
        localUserOnboardingProgressDAO: UserOnboardingProgressDAO
    ) {
        this.authUsernameProvider = authUsernameProvider;
        this.cloudUserOnboardingProgressDAO = cloudUserOnboardingProgressDAO;
        this.localUserOnboardingProgressDAO = localUserOnboardingProgressDAO;
    }

    public async checkEligibility(): Promise<boolean> {
        const username: string = await this.authUsernameProvider.get();
        try {
            const cloudRecord = await this.cloudUserOnboardingProgressDAO.getUserOnboardingProgressByUserId(username);
            if (!cloudRecord) {
                return true;
            }
            if (this.anyMissingFieldsInRecord(cloudRecord)) {
                return true;
            }
            return false;
        } catch (error) {
            const localRecord = await this.localUserOnboardingProgressDAO.getUserOnboardingProgressByUserId(username);
            if (localRecord) {
                return false;
            }
            throw new EligibilityCheckError("Error occurred while looking up user onboarding progress");
        }
    }

    private anyMissingFieldsInRecord(userOnboardingProgress: UserOnboardingProgress): boolean {
        //if there are more fields to track, we can use or(this.isMissingOrganization, this.isMissing..., ...)
        return this.isMissingOrganization(userOnboardingProgress);
    }

    private isMissingOrganization(userOnboardingProgress: UserOnboardingProgress): boolean {
        return !userOnboardingProgress.hasUserBeenAssociatedWithOrganization;
    }
}