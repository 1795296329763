import {
    atom,
    selectorFamily
} from "recoil";

export const selectedWorkTypeGroupIdsAtom = atom<Array<string>>({
    key: "selectedWorkTypeGroupIdsAtom",
    default: []
});

export const isWorkTypeSelectedByWorkTypeGroupIdSelectorFamily = selectorFamily<boolean, string>({
    key: "isWorkTypeSelectedByWorkTypeGroupIdSelectorFamily",
    get: (groupId: string) => ({ get }) => {
        const selectedWorkTypeGroupIds: Array<string> = get(selectedWorkTypeGroupIdsAtom);
        return selectedWorkTypeGroupIds.includes(groupId);
    },
    set: (groupId: string) => ({ get, set }, isSelected) => {
        const selectedWorkTypeGroupIds: Array<string> = get(selectedWorkTypeGroupIdsAtom);
        const workTypeIndex = selectedWorkTypeGroupIds.findIndex((workTypeGroupId) => workTypeGroupId === groupId);
        if (isSelected && workTypeIndex === -1) {
            set(selectedWorkTypeGroupIdsAtom, (original) => {
                const updated = [...original];
                updated.push(groupId);
                return updated;
            });
        }
        if (!isSelected && workTypeIndex !== -1) {
            set(selectedWorkTypeGroupIdsAtom, (original) => {
                const updated = [...original];
                updated.splice(workTypeIndex, 1);
                return updated;
            });
        }
    }
});
