import {
    Box,
    Typography
} from "@mui/material";

import { StartPropertiesChildrenSyncWrapper } from "../../components/sync/StartPropertiesChildrenSyncWrapper";
import WorkTypeAdminManagement from "../../componentsV2/worktype/adminManagement/WorkTypeAdminManagement";
import globalStyle from "../../assets/style/globalStyle";
import { isUserAdminAtom } from "../../lib/auth/state/AuthRecoilState";
import { useEffect } from "react";
import { usePageDefaultStyle } from "../../assets/style/usePageDefaultStyle";
import { useSetRecoilState } from "recoil";

const AdminManagement = () => {
    const setIsUserAdmin = useSetRecoilState<boolean>(isUserAdminAtom);

    /* Dependencies */
    const globalClasses = globalStyle();
    const { pageDefaultStyle } = usePageDefaultStyle();

    useEffect(() => {
        setIsUserAdmin(true);
        return () => (
            setIsUserAdmin(false)
        );
    }, []);

    return (
        <StartPropertiesChildrenSyncWrapper>
            <Box sx={pageDefaultStyle}>
                <Box className={globalClasses.managerLayout}>
                    <Typography variant="h4">
                        Admin Control Panel
                    </Typography>
                    <WorkTypeAdminManagement />
                </Box>
            </Box>
        </StartPropertiesChildrenSyncWrapper>
    );
};

export default AdminManagement;
