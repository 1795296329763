export const green = {
    50: "#E9F6E9",
    100: "#C9E9C8",
    200: "#A7DBA5",
    300: "#83CD81",
    400: "#67C265",
    500: "#4DB749",
    600: "#43A840",
    700: "#379535",
    800: "#2C842A",
    900: "#136517",
}

export const orange = {
    50: "#FEF8E3",
    100: "#FDECB8",
    200: "#FCE08A",
    300: "#FBD55E",
    400: "#FBCB41",
    500: "#FAC233",
    600: "#F9B52E",
    700: "#F8A32A",
    800: "#F69327",
    900: "#F47723",
}

export const darkBlue = {
    50: "#F4F9FF",
    100: "#EEF3FF",
    200: "#E6EBFE",
    300: "#D7DBEF",
    400: "#B3B8CB",
    500: "#9499AB",
    600: "#6B7081",
    700: "#585C6D",
    800: "#393E4D",
    900: "#181D2B",
}