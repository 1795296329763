import {
    AggregateUserPermissions,
    PermissionResourceType
} from "../../models";

import { List } from "@mui/material";
import { ManageUserPermissionsListItem } from "./ManageUserPermissionsListItem";
import { PermissionType } from "../../API";

type ManageUserPermissionsListProps = {
    userIdToAggregateUserPermissionsMap: Map<string, AggregateUserPermissions> | undefined;
    loggedInUserAggregateUserPermission: AggregateUserPermissions | undefined;
    isUpdatingData: boolean;
    resourceId: string;
    resourceType: PermissionResourceType;
    onRemoveUserPermissions: (userId: string) => Promise<void>;
    onUpdateUserPermissions: (userId: string, newPermissions: Array<PermissionType>) => Promise<void>;
};

export const ManageUserPermissionsList = (props: ManageUserPermissionsListProps) => {
    const { userIdToAggregateUserPermissionsMap, loggedInUserAggregateUserPermission, isUpdatingData, resourceId, resourceType, onRemoveUserPermissions, onUpdateUserPermissions } = props;

    return (
        userIdToAggregateUserPermissionsMap == undefined || loggedInUserAggregateUserPermission == undefined ? <></> :
            <List>
                <ManageUserPermissionsListItem
                    key={loggedInUserAggregateUserPermission.userId}
                    aggregateUserPermission={loggedInUserAggregateUserPermission}
                    disabled={isUpdatingData}
                    sx={{ py: 2 }}
                    divider
                    isLoggedInUser
                    loggedInUserPermissions={loggedInUserAggregateUserPermission.permissions as Array<PermissionType>}
                    resourceId={resourceId}
                    resourceType={resourceType}
                    onRemoveUserPermissions={onRemoveUserPermissions}
                    onUpdateUserPermissions={onUpdateUserPermissions}
                />
                {Array.from(userIdToAggregateUserPermissionsMap.values())
                    .filter(aggregateUserPermission => aggregateUserPermission.userId !== loggedInUserAggregateUserPermission.userId)
                    .map((aggregateUserPermissions: AggregateUserPermissions, index) => (
                        <ManageUserPermissionsListItem
                            key={aggregateUserPermissions.userId}
                            aggregateUserPermission={aggregateUserPermissions}
                            disabled={isUpdatingData}
                            sx={{ py: 2 }}
                            divider={index !== userIdToAggregateUserPermissionsMap.size - 1}
                            loggedInUserPermissions={loggedInUserAggregateUserPermission.permissions as Array<PermissionType>}
                            resourceId={resourceId}
                            resourceType={resourceType}
                            onRemoveUserPermissions={onRemoveUserPermissions}
                            onUpdateUserPermissions={onUpdateUserPermissions}
                        />
                    ))
                }
            </List>
    );
};