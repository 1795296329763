import ClientLogger from "../logging/ClientLogger";
import { DataStoreClass } from "@aws-amplify/datastore";
import { UserOnboardingProgress } from "../../models";
import UserOnboardingProgressDAO from "./UserOnboardingProgressDAO";
import UserOnboardingProgressRetrievalError from "./UserOnboardingProgressRetrievalError";

export default class DataStoreUserOnboardingProgressDAO implements UserOnboardingProgressDAO {

    public static readonly GET_USER_ONBOARDING_PROGRESS_BY_USERID_ATTEMPT = "GetUserOnboardingProgressByUserIdAttempt";
    public static readonly GET_USER_ONBOARDING_PROGRESS_BY_USERID_FAILURE = "GetUserOnboardingProgressByUserIdFailure";
    public static readonly USER_ONBOARDING_PROGRESS_CREATION_ATTEMPT = "UserOnboardingProgressCreationAttempt";
    public static readonly USER_ONBOARDING_PROGRESS_CREATION_FAILURE = "UserOnboardingProgressCreationFailure";

    private readonly logger: ClientLogger;
    private readonly dataStore: DataStoreClass;

    constructor(
        logger: ClientLogger,
        dataStore: DataStoreClass,
    ) {
        this.logger = logger;
        this.dataStore = dataStore;
    }

    public async getUserOnboardingProgressByUserId(userId: string): Promise<UserOnboardingProgress | undefined> {
        try {
            this.logger.info(
                `Attempting to get userOnboardingProgress from dataStore for user: ${userId}`,
                undefined,
                [DataStoreUserOnboardingProgressDAO.GET_USER_ONBOARDING_PROGRESS_BY_USERID_ATTEMPT]
            );
            const existingUserOnboardingProgressList = await this.dataStore.query(
                UserOnboardingProgress,
                userOnboardingProgress => userOnboardingProgress.userId("eq", userId)
            );
            return existingUserOnboardingProgressList[0];
        } catch (error) {
            this.logger.error(
                `Failed to get userOnboardingProgress from dataStore for user: ${userId}`,
                error,
                [DataStoreUserOnboardingProgressDAO.GET_USER_ONBOARDING_PROGRESS_BY_USERID_FAILURE]
            );
            throw new UserOnboardingProgressRetrievalError("Failed to get userOnboardingProgress");
        }
    }

    public async createUserOnboardingProgress(
        userId: string,
        hasUserBeenAssociatedWithOrganization: boolean
    ): Promise<UserOnboardingProgress> {
        try {
            this.logger.info(
                `Attempting to create userOnboardingProgress in dataStore for user: ${userId}`,
                undefined,
                [DataStoreUserOnboardingProgressDAO.USER_ONBOARDING_PROGRESS_CREATION_ATTEMPT]
            );
            const existingUserOnboardingProgress = await this.getUserOnboardingProgressByUserId(userId);
            if (existingUserOnboardingProgress) {
                throw new Error("UserOnboaringProgress already exists.");
            }
            const userOnboardingProgressToCreate: UserOnboardingProgress = new UserOnboardingProgress({
                userId: userId,
                hasUserBeenAssociatedWithOrganization: hasUserBeenAssociatedWithOrganization
            });
            return await this.dataStore.save(userOnboardingProgressToCreate);
        } catch (error) {
            this.logger.error(
                `Failed to create userOnboardingProgress in dataStore for user: ${userId}`,
                error,
                [DataStoreUserOnboardingProgressDAO.USER_ONBOARDING_PROGRESS_CREATION_FAILURE]
            );
            throw error;
        }
    }
}