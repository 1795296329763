import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const TeamIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.973 9.743a3.261 3.261 0 0 0-2.318-5.552 3.254 3.254 0 0 0-2.927 1.847c.284.28.514.607.712.955a2.265 2.265 0 0 1 2.214-1.803 2.263 2.263 0 0 1 2.261 2.261 2.263 2.263 0 0 1-2.261 2.261c-.65 0-1.232-.279-1.644-.719.001.047.014.09.014.137a4.299 4.299 0 0 1-.263 1.438c.139-.079.276-.161.417-.224.445.228.942.368 1.476.368.52 0 1.004-.133 1.44-.349 1.414.736 2.524 2.478 2.689 4.419h-4.541c.211.32.406.652.577 1h4.486l.5-.5c.001-2.296-1.195-4.446-2.832-5.539zM1.826 14.783c.148-1.797 1.074-3.632 2.452-4.407.431.21.908.338 1.419.338a3.22 3.22 0 0 0 1.434-.346c.174.092.337.212.5.333a4.265 4.265 0 0 1-.161-.518 4.332 4.332 0 0 1-.142-1.051c0-.047.012-.091.014-.137a2.25 2.25 0 0 1-1.644.719 2.263 2.263 0 0 1-2.261-2.261 2.263 2.263 0 0 1 2.261-2.261c1.09 0 2.001.775 2.214 1.803.198-.35.428-.677.712-.956a3.252 3.252 0 0 0-2.927-1.848 3.261 3.261 0 0 0-2.308 5.564C1.786 10.895.806 13.14.806 15.283l.5.5h4.227c.17-.348.366-.68.577-1H1.826z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M13.82 12.893a4.301 4.301 0 0 1-2.144.587 4.301 4.301 0 0 1-2.144-.587c-2.048 1.046-3.672 3.467-3.86 6.066H17.68c-.188-2.599-1.812-5.02-3.86-6.066z"
      className="accent"
    />
    <circle
      cx={11.676}
      cy={9.132}
      r={3.348}
      fill="currentColor"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(TeamIcon);
