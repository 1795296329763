import {
    Attachment,
    AttachmentType
} from "../../../models";

import { AttachmentParent } from "../../../componentsV2/image/AttachmentParent";
import { AttachmentUploadManager } from "../../attachment/upload/manager/AttachmentUploadManager";
import AttachmentUploadRequest from "../../attachment/upload/request/AttachmentUploadRequest";
import { AttachmentUploadStatus } from "../../attachment/upload/status/AttachmentUploadStatus";
import ClientLogger from "../../logging/ClientLogger";
import FileInputValidator from "../validation/FileInputValidator";
import ImageResizer from "../ImageResizer";
import ImageSelectionHandler from "./ImageSelectionHandler";
import UsernameSupplier from "../../auth/UsernameSupplier";
import uuidv4 from "../../util/UuidGenerator";

export default class DefaultImageSelectionHandler implements ImageSelectionHandler {
    private readonly logger: ClientLogger;
    private readonly imageValidator: FileInputValidator;
    private readonly imageResizer: ImageResizer;
    private readonly attachmentUploadManager: AttachmentUploadManager;
    private readonly authUsernameSupplier: UsernameSupplier;

    constructor(
        logger: ClientLogger,
        imageValidator: FileInputValidator,
        imageResizer: ImageResizer,
        attachmentUploadHandler: AttachmentUploadManager,
        authUsernameSupplier: UsernameSupplier
    ) {
        this.logger = logger;
        this.imageValidator = imageValidator;
        this.imageResizer = imageResizer;
        this.attachmentUploadManager = attachmentUploadHandler;
        this.authUsernameSupplier = authUsernameSupplier;
    }

    public async handle(
        files: Array<Blob>,
        attachmentParent: AttachmentParent,
        propertyId: string,
        shouldResize: boolean = true
    ): Promise<void> {
        this.imageValidator.validate(files);
        for (const file of files) {
            this.logger.info(
                `Handling file: ${this.getFileNameOrDefault(file)}, size: ${file.size})`,
                undefined,
                ["ImageSelectionAttempt"]
            );
            let resizedFileBlob: Blob = file;
            if (shouldResize) {
                resizedFileBlob = await this.imageResizer.resize(file);
            }
            this.logger.info(
                `Handling file: ${this.getFileNameOrDefault(file)}, new size: ${(resizedFileBlob as File).size})`,
                undefined,
                ["ImageSelectionInProcess"]
            );
            const arrayBuffer: ArrayBuffer = await resizedFileBlob.arrayBuffer();
            const username: string = await this.authUsernameSupplier.get();

            const attachment = new Attachment({
                key: uuidv4(),
                parentId: attachmentParent.id,
                parentType: attachmentParent.type,
                type: AttachmentType.IMAGE,
                propertyId: propertyId,
                localCreatedAtTime: Date.now(),
                canView: username,
                canEdit: username,
                canDelete: username
            });

            const request: AttachmentUploadRequest = {
                content: {
                    data: arrayBuffer
                },
                record: attachment,
                uploadStatus: AttachmentUploadStatus.PENDING_UPLOAD
            };
            await this.attachmentUploadManager.queueAttachmentForUpload(request);
        }
        this.attachmentUploadManager.initiateUploadCycle();
    }

    private getFileNameOrDefault(data: Blob) {
        if (data instanceof File) {
            return data.name;
        }
        return "NonFileImage";
    }
}
