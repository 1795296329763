import {
    DefaultWorkTypePricing,
    MeasurementUnit,
    WorkTypeGroup
} from "../../../models";
import {
    Grid,
    Stack,
    Switch,
    Typography
} from "@mui/material";
import {
    TYPOGRAPHY_ATTRIBUTE_LABEL,
    TYPOGRAPHY_ATTRIBUTE_VALUE
} from "../../../components/general/typography/TypographyStyles";
import {
    defaultWorkTypePricingByGroupIdAtomFamily,
    workTypeGroupByIdAtomFamily
} from "../../../lib/worktype/state/WorkTypeState";
import {
    useEffect,
    useMemo,
    useRef
} from "react";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import DefaultWorkTypePricingDAO from "../../../lib/worktype/defaultPricing/DefaultWorkTypePricingDAO";
import DimensionTypeAbbreviationFactory from "../../../lib/util/dimension/DimensionTypeAbbreviationFactory";
import GraphQLDefaultWorkTypePricingDAOFactory from "../../../lib/worktype/defaultPricing/graphql/GraphQLDefaultWorkTypePricingDAOFactory";
import MeasurementUnitAbbreviationFactory from "../../../lib/util/dimension/MeasurementUnitAbbreviationFactory";
import WorkTypeDTO from "../../../lib/worktype/DTO/WorkTypeDTO";

type ViewWorkTypeDetailProps = {
    readonly workType: WorkTypeDTO;
};

const ViewWorkTypeDetail = (props: ViewWorkTypeDetailProps) => {
    /* Internal page state */
    const { workType } = props;
    const workTypeGroup = useRecoilValue<WorkTypeGroup | null>(workTypeGroupByIdAtomFamily(workType.groupId));
    const [defaultWorkTypePricing, setDefaultWorkTypePricing] = useRecoilState<DefaultWorkTypePricing | null>(defaultWorkTypePricingByGroupIdAtomFamily(workType.groupId));

    const defaultUnitPriceString = useMemo(() => {
        const dimensionTypeString: string = DimensionTypeAbbreviationFactory.getAbbreviation(workType.dimensionType);
        const measurementUnitString: string = MeasurementUnitAbbreviationFactory.getAbbreviation(MeasurementUnit.FOOT);
        return `$${defaultWorkTypePricing?.unitPrice ?? ""} /${dimensionTypeString} ${measurementUnitString}`;
    }, [workType.dimensionType, defaultWorkTypePricing]);

    const defaultUnitCostString = useMemo(() => {
        const dimensionTypeString: string = DimensionTypeAbbreviationFactory.getAbbreviation(workType.dimensionType);
        const measurementUnitString: string = MeasurementUnitAbbreviationFactory.getAbbreviation(MeasurementUnit.FOOT);
        return `$${defaultWorkTypePricing?.unitCost ?? ""} /${dimensionTypeString} ${measurementUnitString}`;
    }, [workType.dimensionType, defaultWorkTypePricing]);

    /* Dependencies */
    const defaultWorkTypePricingDAORef = useRef<DefaultWorkTypePricingDAO>(GraphQLDefaultWorkTypePricingDAOFactory.getInstance());

    useEffect(() => {
        const fetchDefaultWorkTypePricing = async () => {
            const optionalDefaultPricing: DefaultWorkTypePricing | undefined = await defaultWorkTypePricingDAORef.current.getById(workType.groupId);
            setDefaultWorkTypePricing(optionalDefaultPricing ?? null);
        };
        if (!defaultWorkTypePricing) {
            fetchDefaultWorkTypePricing();
        }
    }, [defaultWorkTypePricing]);

    return (
        <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="baseline"
            xs={12}
            style={{
                width: "100%"
            }}
        >
            <Grid item xs={4} pr={3}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                    Name:
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>
                    {workType.name}
                </Typography>
            </Grid>
            <Grid item xs={4} pr={3}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                    Dimension Type:
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>
                    {workType.dimensionType}
                </Typography>
            </Grid>
            <Grid item xs={4} pr={3}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                    Category:
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>
                    {workType.categoryName}
                </Typography>
            </Grid>
            <Grid item xs={4} pr={3}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                    Default Unit Price:
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>
                    {defaultUnitPriceString}
                </Typography>
            </Grid>
            <Grid item xs={4} pr={3}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                    Default Unit Cost:
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>
                    {defaultUnitCostString}
                </Typography>
            </Grid>
            <Grid item xs={4} pr={3}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                    Short Description:
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>
                    {workType.shortDescription}
                </Typography>
            </Grid>
            <Grid item xs={4} pr={3}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                    Long Description:
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>
                    {workType.longDescription}
                </Typography>
            </Grid>
            <Grid item container xs={12}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Typography sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>Discoverable</Typography>
                    <Switch
                        disabled
                        defaultChecked={!!(workTypeGroup?.isDiscoverable)}
                        color="primary"
                    />
                </Stack>
                <Grid item xs={12}>
                    <Typography component="span" sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                        This Work Type
                    </Typography>
                    <Typography component="span" sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>
                        &nbsp;is {workTypeGroup?.isDiscoverable ? "" : "not"} discoverable
                    </Typography>
                    <Typography component="span" sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                        &nbsp;for all users
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ViewWorkTypeDetail;
