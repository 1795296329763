import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const HamburgerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="M5 6h14v2H5zM5 11h14v2H5zM5 16h14v2H5z"
        className="primary"
      />
    </g>
  </svg>
);
export default withCustomIcon(HamburgerIcon);
