import {
    Box,
    Button,
    Typography,
    useMediaQuery
} from "@mui/material";
import {
    PermissionResourceType,
    PermissionType,
    Property
} from "../../models";
import {
    isDesktop,
    useMobileOrientation
} from "react-device-detect";
import {
    propertyFormIsValidSelector,
    propertyFormValidationMapAtom,
    propertyFormValueAtom
} from "../../lib/property/state/PropertyFormRecoilState";
import {
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    useRecoilState,
    useRecoilValue,
    useResetRecoilState,
    useSetRecoilState
} from "recoil";

import { AttachmentImageCarousel } from "../image/AttachmentImageCarousel";
import { BLACK_OUTLINED_BUTTON } from "../../components/general/button/ButtonStyles";
import ClientLogger from "../../lib/logging/ClientLogger";
import ClientLoggerFactory from "../../lib/logging/ClientLoggerFactory";
import DataStorePropertyDAOFactory from "../../lib/property/dao/datastore/DataStorePropertyDAOFactory";
import { ManageResourcePermissionDialog } from "../permission/ManageResourcePermissionDialog";
import { PermissionRestrictedButton } from "../../components/general/button/PermissionRestrictedButton";
import { PermissionRestrictedControl } from "../../components/general/button/PermissionRestrictedControl";
import PropertyAttribute from "./PropertyAttribute";
import PropertyAttributeForm from "./PropertyAttributeForm";
import PropertyDAO from "../../lib/property/dao/PropertyDAO";
import { RecordingList } from "../../components/chime/RecordingList";
import { ShareIcon } from "../../components/icons";
import _ from "lodash";
import globalStyle from "../../assets/style/globalStyle";
import { propertyIdToPropertySelectorFamily } from "../../lib/property/state/PropertyRecoilState";
import theme from "../../assets/style/theme";

type PropertyInfoProps = {
    readonly propertyId: string;
};

const PropertyInfo = (props: PropertyInfoProps) => {
    const COMPONENT_NAME = "PropertyInfo";
    /* Internal page state */
    const { propertyId } = props;
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isOpenShareDialog, setIsOpenShareDialog] = useState<boolean>(false);

    const [property, setProperty] = useRecoilState<Property | null>(propertyIdToPropertySelectorFamily(propertyId));
    const [formState, setFormState] = useRecoilState<Property | undefined>(propertyFormValueAtom);
    const setFormValidationMap = useSetRecoilState<Map<keyof Property, boolean>>(propertyFormValidationMapAtom);
    const isFormValid = useRecoilValue<boolean>(propertyFormIsValidSelector);
    const resetFormState = useResetRecoilState(propertyFormValueAtom);
    const resetFormValidationMap = useResetRecoilState(propertyFormValidationMapAtom);

    /* Dependencies */
    const propertyDaoRef = useRef<PropertyDAO>(DataStorePropertyDAOFactory.getInstance());
    const loggerRef = useRef<ClientLogger>(ClientLoggerFactory.getClientLogger(COMPONENT_NAME));
    const globalClasses = globalStyle();
    const isScreenSizeSmallerThanMD = useMediaQuery(theme.breakpoints.down("md"));
    const { isPortrait } = useMobileOrientation();

    const isOneColumnLayout = useMemo(() => {
        if (isDesktop) {
            return isScreenSizeSmallerThanMD;
        }
        return isPortrait;
    }, [isDesktop, isPortrait, isScreenSizeSmallerThanMD]);

    useEffect(() => () => {
        resetRecoilStates();
    }, []);

    const resetRecoilStates = () => {
        resetFormState();
        resetFormValidationMap();
    };

    const onEdit = () => {
        setFormState(property!);
        setFormValidationMap((original) => {
            const updated: Map<keyof Property, boolean> = new Map(original);
            updated.set("address", !!property?.address);
            return updated;
        });
        setIsEditMode(true);
    };

    const onSave = async () => {
        const existingProperty: Property = property!;
        try {
            if (_.isEqual(existingProperty, formState)) {
                setIsEditMode(false);
                return;
            }
            if (formState) {
                const updatedProperty = await propertyDaoRef.current.editProperty(propertyId, formState);
                setProperty(updatedProperty);
                setFormState(updatedProperty);
            }
            setIsEditMode(false);
        } catch (error) {
            loggerRef.current.error(
                "Failed to update the property",
                error,
                ["PropertyUpdateFailure"]
            );
            setProperty(existingProperty);
            setFormState(existingProperty);
            setIsEditMode(false);
        }
    };

    return (
        <>
            <Box
                display="grid"
                gridTemplateColumns={isOneColumnLayout ? "1fr" : `repeat(2, minmax(0, ${theme.breakpoints.values.md}px))`}
                justifyContent="space-around"
                gap={2}
            >
                <Box height={'100%'} display="flex" flexDirection="column" gap={1}>
                    <Box flexShrink={0} height={theme.spacing(4)}>
                        <Typography variant="h6">
                            Property Image
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            height: {
                                xs: "200px",
                                sm: "370px"
                            },
                            aspectRatio: "16/9",
                            overflow: "hidden"
                        }}
                    >
                        <AttachmentImageCarousel attachmentParent={{ id: propertyId, type: PermissionResourceType.PROPERTY }} />
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={{ xs: 1, sm: 2 }}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h6">
                            Property Information
                        </Typography>
                        <PermissionRestrictedControl
                            requiredPermission={PermissionType.SHARE_PERMISSION}
                            resourceId={propertyId}
                        >
                            {(disabled) => (
                                <Button
                                    onClick={() => setIsOpenShareDialog(true)}
                                    variant="outlined"
                                    sx={{ ...BLACK_OUTLINED_BUTTON }}
                                    startIcon={<ShareIcon accent={disabled ? "disabled" : "primary"} />}
                                    disabled={disabled}
                                >
                                    Share
                                </Button>
                            )}
                        </PermissionRestrictedControl>
                    </Box>
                    {isEditMode ? (
                        <PropertyAttributeForm />
                    ) : (
                        <PropertyAttribute propertyId={propertyId} />
                    )}
                </Box>
            </Box>
            <Box
                justifyContent="flex-end"
                paddingTop={3}
            >
                {isEditMode ? (
                    <Button
                        id="btnCreateProperty"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={onSave}
                        disabled={!isFormValid}
                    >
                        Save
                    </Button>
                ) : (
                    <PermissionRestrictedButton
                        id="btnCreateProperty"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={onEdit}
                        resourceId={propertyId}
                        requiredPermission={PermissionType.EDIT}
                    >
                        Edit
                    </PermissionRestrictedButton>
                )}
            </Box>
            <Box py={2}>
                <RecordingList />
            </Box>
            <ManageResourcePermissionDialog
                onClose={() => setIsOpenShareDialog(false)}
                open={isOpenShareDialog}
                resourceId={propertyId}
                resourceType={PermissionResourceType.PROPERTY}
            />
        </>
    );
};

export default PropertyInfo;
