import { API } from 'aws-amplify';
import { AuthIDTokenSupplierFactory } from '../../auth/AuthIDTokenSupplierFactory';
import AuthUsernameSupplierFactory from '../../auth/AuthUsernameSupplierFactory';
import ClientLoggerFactory from '../../logging/ClientLoggerFactory';
import GraphQLSolutionEventClient from "./GraphQLSolutionEventClient";
import SolutionEventClient from "./SolutionEventClient";
import { graphqlOperation } from 'aws-amplify';

export default class GraphQLSolutionEventClientFactory {
    private static readonly INSTANCE: SolutionEventClient = new GraphQLSolutionEventClient(
        ClientLoggerFactory.getClientLogger("GraphQLSolutionEventClient"),
        API,
        graphqlOperation,
        AuthUsernameSupplierFactory.getInstance(),
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance(): SolutionEventClient {
        return GraphQLSolutionEventClientFactory.INSTANCE;
    }
}
