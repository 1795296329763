import {
    Box,
    Typography
} from "@mui/material";
import {
    DimensionType,
    MeasurementUnit
} from "../../../models";

import DimensionTypeAbbreviationFactory from "../../../lib/util/dimension/DimensionTypeAbbreviationFactory";
import MeasurementUnitAbbreviationFactory from "../../../lib/util/dimension/MeasurementUnitAbbreviationFactory";
import NumberStringConverter from "../../../lib/util/NumberStringConverter";
import { WORD_PROPOSAL_METADATA } from "./ViewSolutionStyles";
import WorkTypeDTO from "../../../lib/worktype/DTO/WorkTypeDTO";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { workTypeByWorkTypeIdAtomFamily } from "../../../lib/worktype/state/WorkTypeRecoilState";

type ViewWorkTypeSummaryProps = {
    readonly workTypeId: string;
    readonly unitCount: number;
};

export const ViewWorkTypeSummary = (props: ViewWorkTypeSummaryProps) => {
    const { workTypeId } = props;
    const workType = useRecoilValue<WorkTypeDTO | undefined>(workTypeByWorkTypeIdAtomFamily(workTypeId));
    const unitCountString = useMemo(() => {
        if (!workType?.dimensionType) {
            return "";
        }
        return `${NumberStringConverter.numberToString(props.unitCount, 0, true)} ` +
            `${DimensionTypeAbbreviationFactory.getAbbreviation(workType.dimensionType as DimensionType)} ` +
            `${MeasurementUnitAbbreviationFactory.getAbbreviation(MeasurementUnit.FOOT)}`;
    }, [workType?.dimensionType, props.unitCount]);

    return (
        <>
            <Typography style={WORD_PROPOSAL_METADATA} >
                {workType?.name}:
            </Typography>
            <Box component="p" style={{ textAlign: "right" }}>
                <Typography component="span" style={WORD_PROPOSAL_METADATA}>
                    {unitCountString}
                </Typography>
            </Box>
        </>
    );
};