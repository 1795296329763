import {
    Attachment,
    Dimension,
    EntityResourcePermission,
    Issue,
    Location,
    Property,
    UserOrganizationAssociation,
    WorkSpecification
} from "../../../../models";
import {
    AuthModeStrategyType,
    DataStoreConfig,
    ModelPredicate,
    Predicates,
    syncExpression
} from "@aws-amplify/datastore";

import { DataStoreConfigurationManager } from "./DataStoreConfigurationManager";
import { DataStoreConflictResolver } from "../../conflict/DataStoreConflictResolver";
import { IDTokenSupplier } from "../../../auth/IDTokenSupplier";
import { MAX_OFFLINE_PROPERTIES } from "../DataStoreSynchronizationConstants";
import UsernameSupplier from "../../../auth/UsernameSupplier";

type PropertyChildModel = Location | Issue | Attachment | Dimension | WorkSpecification | EntityResourcePermission;
export class HierarcicalDataStoreConfigurationManager implements DataStoreConfigurationManager {
    private idTokenSupplier: IDTokenSupplier;
    private usernameSupplier: UsernameSupplier;

    private propertyIds: Array<string> = new Array(MAX_OFFLINE_PROPERTIES).fill('');

    constructor(
        idTokenSupplier: IDTokenSupplier,
        usernameSupplier: UsernameSupplier
    ) {
        this.idTokenSupplier = idTokenSupplier;
        this.usernameSupplier = usernameSupplier;
    }

    public async getConfiguration(): Promise<DataStoreConfig> {
        const username = await this.usernameSupplier.get();
        return {
            authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
            authProviders: {
                functionAuthProvider: async () => {
                    const authToken = await this.idTokenSupplier.get();
                    return {
                        token: authToken
                    }
                },
            },
            fullSyncInterval: 10080, // One week in minutes
            maxRecordsToSync: 1000000, // Max number of records to download per model type
            syncExpressions: [
                syncExpression(Property, () => Predicates.ALL),
                syncExpression(Location, (location) => this.buildPredicate(location as ModelPredicate<PropertyChildModel>) as ModelPredicate<Location>),
                syncExpression(Issue, (issue) => this.buildPredicate(issue as ModelPredicate<PropertyChildModel>) as ModelPredicate<Issue>),
                syncExpression(Dimension, (dimension) => this.buildPredicate(dimension as ModelPredicate<PropertyChildModel>) as ModelPredicate<Dimension>),
                syncExpression(WorkSpecification, (ws) => this.buildPredicate(ws as ModelPredicate<PropertyChildModel>) as ModelPredicate<WorkSpecification>),
                syncExpression(Attachment, (attachment) => this.buildPredicate(attachment as ModelPredicate<PropertyChildModel>) as ModelPredicate<Attachment>),
                syncExpression(EntityResourcePermission, (erp) => this.buildPredicate(erp as ModelPredicate<PropertyChildModel>) as ModelPredicate<EntityResourcePermission>),
                syncExpression(UserOrganizationAssociation, uoa => uoa!.userId("eq", username))
            ],
            conflictHandler: (conflict) => {
                return DataStoreConflictResolver.resolve(conflict);
            }
        }
    }

    private buildPredicate(predicate: ModelPredicate<PropertyChildModel>): ModelPredicate<PropertyChildModel> {
        predicate!.or((model) => {
            for (let i = 0; i < MAX_OFFLINE_PROPERTIES; i++) {
                // if the user doens't have enough properties to fill the array, use empty strings as no-ops
                model.propertyId("eq", this.propertyIds[i] || "");
            }
            return model;
        });
        return predicate;
    }

    public setPropertyIds(propertyIds: Array<string>): void {
        this.propertyIds = propertyIds;
    }
}