import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
    alpha
} from "@mui/material";
import {
    PermissionResourceType,
    Property
} from "../../models";

import { AttachmentImageCarousel } from "../image/AttachmentImageCarousel";
import DownloadIcon from '@mui/icons-material/GetApp';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import globalStyle from "../../assets/style/globalStyle";
import { propertyIdToPropertySelectorFamily } from "../../lib/property/state/PropertyRecoilState";
import theme from "../../assets/style/theme";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

export interface PropertyCardProps {
    readonly propertyId: string;
    readonly isAvailable?: boolean;
    readonly isRecentlyCreated?: boolean;
}

const PropertyCard = (props: PropertyCardProps) => {
    /* Internal page states */
    const { propertyId, isAvailable = true, isRecentlyCreated = false } = props;
    const property: Property | null = useRecoilValue(propertyIdToPropertySelectorFamily(propertyId ?? ""));

    /* Dependencies */
    const globalClasses = globalStyle();
    const history = useHistory();

    const navigate = () => {
        history.push(`/properties/${propertyId}/issues`);
    };

    return (
        <Card
            sx={{
                backgroundColor: alpha(theme.palette.primary.light, 0.20),
                border: "lightgray 1px solid",
                borderRadius: "0px",
                boxShadow: "none",
            }}
        >
            <CardActionArea onClick={navigate} component="span">
                <CardMedia
                    sx={{
                        p: 1,
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            aspectRatio: "16/9",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <AttachmentImageCarousel
                            attachmentParent={{ id: propertyId, type: PermissionResourceType.PROPERTY }}
                            thumbnailImageCarouselConfiguration={{
                                showAddButton: false,
                                showContextMenu: false,
                                showTimestamp: false,
                                enableDropZone: false,
                                showNavigation: false,
                                showEditButton: false,
                                swiperProps: {
                                    pagination: {
                                        dynamicBullets: true,
                                    }
                                }
                            }}
                            expandable={false}
                        />
                    </Box>
                    {!isAvailable && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: "rgba(128, 128, 128, 0.5)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: 1
                            }}
                        >
                            <DownloadIcon fontSize="large" color="action" />
                        </Box>
                    )}
                    {isRecentlyCreated && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: 1
                            }}
                        >
                            <FileUploadIcon fontSize="large" color="info" />
                        </Box>
                    )}
                </CardMedia>
                <CardContent
                    sx={{
                        aspectRatio: "6/1",
                        py: 0,
                        px: 1
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                    >
                        <Box>
                            <Typography
                                variant="h6"
                                color="primary"
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                                noWrap
                            >
                                {property?.name}
                            </Typography>
                        </Box>
                        <Box ml={1} mb={1}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                                noWrap
                            >
                                {property?.address}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default PropertyCard;
