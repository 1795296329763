import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const SyncIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.883 18.251a5.492 5.492 0 0 1-4.053-1.78C.838 15.388.356 13.997.508 12.656c.27-2.384 2.459-4.27 5-4.403a2.53 2.53 0 0 1 1.248-1.937c.659-.378 1.313-.359 1.79-.255.117-1.033.484-1.934 1.077-2.635.959-1.135 2.537-1.786 4.329-1.786h.013c1.806.003 3.5.688 4.423 1.787.932 1.11 1.387 2.998 1.444 4.479a5.569 5.569 0 0 1 3.672 4.804 5.561 5.561 0 0 1-3.297 5.495l-.404-.914a4.556 4.556 0 0 0 2.703-4.505 4.563 4.563 0 0 0-3.304-4.031l-.363-.504c-.008-1.358-.392-3.197-1.217-4.181-.738-.879-2.141-1.427-3.659-1.43h-.011c-1.497 0-2.797.521-3.565 1.431-.718.849-.876 1.928-.882 2.682l-.739.435c-.032-.017-.787-.42-1.513-.005a1.547 1.547 0 0 0-.751 1.514l-.518.552c-2.263-.083-4.252 1.485-4.482 3.519-.119 1.055.269 2.158 1.065 3.028a4.456 4.456 0 0 0 3.426 1.453l.023 1a5.726 5.726 0 0 1-.133.002z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M9.712 9.042H8.298l-3 3 1.414 1.414 1.293-1.293v5.586h2v-5.586l1.293 1.293 1.414-1.414zM17.311 18.028l-1.306 1.259v-6.538h-2v6.538l-1.307-1.259-1.387 1.442 3 2.889h1.387l3-2.889z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(SyncIcon);
