import {
    Proposal,
    Solution,
    SolutionMinorVersion
} from "../../../../models";

import GraphQLProposalRecordDAOFactory from "../../../proposal/dao/record/GraphQLProposalRecordDAOFactory";
import GraphQLSolutionDAOFactory from "../../../solution/majorVersion/dao/GraphQLSolutionDAOFactory";
import GraphQLSolutionMinorVersionRecordDAOFactory from "../../../solution/minorVersion/dao/record/GraphQLSolutionMinorVersionRecordDAOFactory";
import ProposalContentDAO from "../../../proposal/dao/content/ProposalContentDAO";
import ProposalRecordDAO from "../../../proposal/dao/record/ProposalRecordDAO";
import S3ProposalContentDAOFactory from "../../../proposal/dao/content/S3ProposalContentDAOFactory";
import S3SolutionMinorVersionContentDAOFactory from "../../../solution/minorVersion/dao/content/v2/S3SolutionMinorVersionContentDAOFactory";
import SolutionDAO from "../../../solution/majorVersion/dao/SolutionDAO";
import SolutionMinorVersionContentDAO from "../../../solution/minorVersion/dao/content/v2/SolutionMinorVersionContentDAO";
import SolutionMinorVersionRecordDAO from "../../../solution/minorVersion/dao/record/SolutionMinorVersionRecordDAO";
import { useSnackbar } from "notistack";

const solutionRecordDAO: SolutionDAO = GraphQLSolutionDAOFactory.getInstance();
const solutionContentDAO: SolutionMinorVersionContentDAO = S3SolutionMinorVersionContentDAOFactory.getInstance();
const solutionMinorVersionRecordDAO: SolutionMinorVersionRecordDAO = GraphQLSolutionMinorVersionRecordDAOFactory.getInstance();
const proposalRecordDAO: ProposalRecordDAO = GraphQLProposalRecordDAOFactory.getInstance();
const proposalContentDAO: ProposalContentDAO = S3ProposalContentDAOFactory.getInstance();

export const useRemoveSolutionAndProposal = () => {
    const snackbar = useSnackbar();

    const removeProposalBySolutionId = async (solutionId: string) => {
        const proposalRecords: Array<Proposal> = await proposalRecordDAO.listBySolutionIdAndMinorVersion(solutionId);

        // Remove all the related proposals records
        const removedProposalRecordContentKeyPromises: Array<Promise<string>> = proposalRecords.map(async (proposalRecord: Proposal) => {
            await proposalRecordDAO.deleteByIdAndVersion(proposalRecord.id, (proposalRecord as any)._version);
            return proposalRecord.proposalKey;
        });
        const removedProposalContentKeySettledResult: Array<PromiseSettledResult<string>> = await Promise.allSettled(removedProposalRecordContentKeyPromises);

        // Remove all the related proposal contents
        // Commented out since permission of removing content from S3 is not configured yet
        /** 
        const removeProposalContentPromises: Array<Promise<void>> = removedProposalContentKeySettledResult
            .filter((settledResult: PromiseSettledResult<string>) => settledResult.status === "fulfilled")
            .map((settledResult) => (settledResult as PromiseFulfilledResult<string>).value)
            .map(async (proposalContentKey: string) => {
                return await proposalContentDAO.delete(proposalContentKey);
            });
        await Promise.allSettled(removeProposalContentPromises);
        */
    };


    const removeSolutionAndProposal = async (solutionId: string, solutionMajorRecordVersion?: number) => {
        try {
            // Remove the solution major content first; in case it fails, we will not advertise the record to the user
            const existingSolution: Solution = await solutionRecordDAO.getById(solutionId); 
            await solutionRecordDAO.deleteByIdAndVersion(solutionId, solutionMajorRecordVersion ?? (existingSolution as any)._version);

            const solutionMinorVersions: Array<SolutionMinorVersion> = await solutionMinorVersionRecordDAO.listBySolutionId(solutionId);

            // Remove all the related minor versions
            const removedSolutionMinorVersionContentKeyPromises: Array<Promise<string | null | undefined>> = solutionMinorVersions.map(async (solutionMinorVersion: SolutionMinorVersion) => {
                await solutionMinorVersionRecordDAO.deleteByIdAndVersion(solutionMinorVersion.id, (solutionMinorVersion as any)._version);
                return solutionMinorVersion.contentKey;
            });
            const removedSolutionMinorVersionContentKeySettledResults: Array<PromiseSettledResult<string | null | undefined>> = await Promise.allSettled(removedSolutionMinorVersionContentKeyPromises);

            // Remove all the related minor version content
            // Commented out since permission of removing content from S3 is not configured yet
            /** 
            const removeSolutionMinorVersionContentPromises: Array<Promise<void>> = removedSolutionMinorVersionContentKeySettledResults
                .filter((settledResult: PromiseSettledResult<string | null | undefined>) => settledResult.status === "fulfilled")
                .map((settledResult) => (settledResult as PromiseFulfilledResult<string | null | undefined>).value)
                .map(async (solutionMinorVersionContentKey: string | null | undefined) => {
                    if (solutionMinorVersionContentKey) {
                        return await solutionContentDAO.delete(solutionMinorVersionContentKey);
                    }
                });
            await Promise.allSettled(removeSolutionMinorVersionContentPromises);
            */

            // Remove all the related proposals
            await removeProposalBySolutionId(solutionId);
        } catch (error) {
            console.error(`Failed to remove solution ${solutionId} with version ${solutionMajorRecordVersion}: `, error);
            snackbar.enqueueSnackbar("Failed to remove the solution, please try again later", { variant: "error" });
        }
    };

    return { removeSolutionAndProposal };
};