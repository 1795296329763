import {
    Box,
    Skeleton
} from "@mui/material";
import {
    attachmentRecordsByIdentifierAtomFamily,
    resizedImageUrlsByIdentifierSelectorFamily
} from "../../../../lib/design/document/state/DocumentState";

import { Attachment } from "../../../../models";
import { ContextAwareIdentifier } from "../../../../lib/design/document/ContextAwareIdentifier";
import { EMPTY_IMAGE } from "../../../../lib/solution/hooks/useFetchSolutionImages";
import { ModelType } from "../../../../lib/design/document/ModelType";
import { StateContext } from "../../../../lib/design/document/state/StateContext";
import { clipboardImageDimensionsMap } from "../../../../lib/solution/image/ViewSolutionImageConstants";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";

export interface ClipboardImageListProps {
    readonly context: StateContext;
    readonly parentId: string;
    readonly parentType: ModelType;
    readonly isForClipboard: boolean;
}

export const ClipboardImageList = ({ context, parentId, parentType, isForClipboard = false }: ClipboardImageListProps) => {

    const contextAwareIdentifier = useMemo(() => {
        return new ContextAwareIdentifier(parentId, context, parentType);
    }, []);
    const attachments = useRecoilValue<Array<Attachment>>(attachmentRecordsByIdentifierAtomFamily(contextAwareIdentifier));
    const resizedImageUrls = useRecoilValue<Array<string>>(resizedImageUrlsByIdentifierSelectorFamily(contextAwareIdentifier));

    const imageSize: { width: number; height: number; } = useMemo(() => {
        return clipboardImageDimensionsMap.get(Math.min(3, attachments.length))!;
    }, [attachments]);

    if (attachments.length === 0) {
        return <></>;
    }

    return (
        <Box
            component="p"
            style={{ textAlign: "center" }}
            sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
            gap={0.5}
            id={`clipboard-image-list-${parentId}`}
        >
            {attachments.map((attachment, index) => {
                switch (resizedImageUrls[index]) {
                    case EMPTY_IMAGE:
                        return <></>;
                    case null:
                        return (
                            <Box key={attachment.id}>
                                <Skeleton key={attachment.id} variant="rectangular" width={imageSize.width} height={imageSize.height} />
                            </Box>
                        );
                    default:
                        return (
                            <span key={attachment.id}>
                                <img src={resizedImageUrls[index]!} />
                            </span>
                        );
                }
            })}
        </Box>
    );
};
