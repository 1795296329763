import {
    QUICK_ACCESS_KEY_MD_SIZE,
    QUICK_ACCESS_KEY_SM_SIZE,
    QUICK_ACCESS_KEY_XS_SIZE
} from "../../components/quickAccess/QuickAccessKey";
import {
    SxProps,
    useMediaQuery
} from "@mui/material";

import { showQuickAccessKeysSelector } from "../../lib/sidebar/SidebarRecoilStates";
import { useRecoilValue } from "recoil";

const VERTICAL_QUICK_ACCESS_KEY_BUFFER = 1.25;

export const usePageDefaultStyle = () => {
    const isHorizontalLayout = useMediaQuery("(orientation: landscape)");
    const showQuickAccessKeys = useRecoilValue<boolean>(showQuickAccessKeysSelector);
    const showHorizontalQuickAccessKeys = isHorizontalLayout && showQuickAccessKeys;
    const showVerticalQuickAccessKeys = !isHorizontalLayout && showQuickAccessKeys;

    const maxWidth = {
        xs: "98%",
        sm: "95%",
        xl: "min(95%, 1536px)"
    };

    const pageDefaultStyle: SxProps = {
        maxWidth: maxWidth,
        mx: "auto",
        mt: 2,
        backgroundColor: "transparent",
        pr: {
            xs: showHorizontalQuickAccessKeys ? `${QUICK_ACCESS_KEY_XS_SIZE}px` : 0,
            sm: showHorizontalQuickAccessKeys ? `${QUICK_ACCESS_KEY_SM_SIZE}px` : 0,
            md: showHorizontalQuickAccessKeys ? `${QUICK_ACCESS_KEY_MD_SIZE}px` : 0,
        },
        pb: {
            xs: showVerticalQuickAccessKeys ? `${QUICK_ACCESS_KEY_XS_SIZE * VERTICAL_QUICK_ACCESS_KEY_BUFFER}px` : 0,
            sm: showVerticalQuickAccessKeys ? `${QUICK_ACCESS_KEY_SM_SIZE * VERTICAL_QUICK_ACCESS_KEY_BUFFER}px` : 0,
            md: showVerticalQuickAccessKeys ? `${QUICK_ACCESS_KEY_MD_SIZE * VERTICAL_QUICK_ACCESS_KEY_BUFFER}px` : 0,
        }
    };

    return { maxWidth, pageDefaultStyle };
};