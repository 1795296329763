import {
    Box,
    Typography
} from "@mui/material";
import {
    DimensionType,
    MeasurementUnit,
    WorkSpecification
} from "../../models";
import { isSolutionSoleSourceSelectorFamily, workSpecificationByWorkSpecificationIdentifierSelectorFamily } from "../../lib/design/document/state/DocumentState";
import {
    priceAfterAdjustmentByProposalItemIdentifierSelectorFamily,
    proposalItemIdByWorkSpecificationIdentifierAtomFamily,
    unitCountByProposalItemIdentifierSelectorFamily,
    unitPriceAfterAdjustmentByProposalItemIdentifierSelectorFamily
} from "../../lib/design/bidding/state/v2/ProposalItemStates";
import {
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";

import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import DataStoreWorkTypeDAOFactory from "../../lib/worktype/dataStore/DataStoreWorkTypeDAOFactory";
import DimensionTypeAbbreviationFactory from "../../lib/util/dimension/DimensionTypeAbbreviationFactory";
import { ExpandableDisplayText } from "../misc/ExpandableDisplayText";
import MeasurementUnitAbbreviationFactory from "../../lib/util/dimension/MeasurementUnitAbbreviationFactory";
import { ModelType } from "../../lib/design/document/ModelType";
import NumberStringConverter from "../../lib/util/NumberStringConverter";
import { StateContext } from "../../lib/design/document/state/StateContext";
import WorkTypeDAO from "../../lib/worktype/WorkTypeDAO";
import WorkTypeDTO from "../../lib/worktype/DTO/WorkTypeDTO";
import { useRecoilValue } from "recoil";

type WorkSpecificationSummaryProps = {
    readonly workSpecificationId: string;
    readonly stateContext: StateContext;
    readonly index: number;
};

const DESCRIPTION_FONT_SIZE: string = "0.625rem";
const VALUE_FONT_SIZE: string = "0.75rem";

export const WorkSpecificationSummary = (props: WorkSpecificationSummaryProps) => {
    const { workSpecificationId, stateContext, index } = props;
    const workSpecificationIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(workSpecificationId, stateContext, ModelType.WORK_SPECIFICATION);
    }, [workSpecificationId, stateContext]);
    const workSpecification = useRecoilValue<WorkSpecification | null>(
        workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier)
    );
    const [workType, setWorkType] = useState<WorkTypeDTO | undefined>(undefined);
    const proposalItemId: string | null = useRecoilValue(proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier));
    const proposalItemIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(proposalItemId!, stateContext, ModelType.PROPOSAL_ITEM);
    }, [proposalItemId, stateContext]);
    const priceAfterAdjustment = useRecoilValue<number | undefined>(priceAfterAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    const unitPriceAfterAdjustment = useRecoilValue<number | undefined>(unitPriceAfterAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    const unitCount = useRecoilValue<number | undefined>(unitCountByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    const isSoleSource = useRecoilValue<boolean>(isSolutionSoleSourceSelectorFamily(stateContext));
    const dimensionTypeString: string = useMemo(() => {
        if (!workSpecification?.measurement?.dimensionType) {
            return "";
        }
        return DimensionTypeAbbreviationFactory.getAbbreviation(workSpecification?.measurement?.dimensionType as DimensionType);
    }, [workSpecification?.measurement?.dimensionType]);
    const measurementUnitString: string = useMemo(() => {
        if (!workSpecification?.measurement?.measurementUnit) {
            return "";
        }
        return MeasurementUnitAbbreviationFactory.getAbbreviation(workSpecification?.measurement?.measurementUnit as MeasurementUnit);
    }, [workSpecification?.measurement?.measurementUnit]);
    /* Dependencies */
    const workTypeDaoRef = useRef<WorkTypeDAO>(DataStoreWorkTypeDAOFactory.getInstance());

    useEffect(() => {
        const fetchWorkType = async () => {
            if (workSpecification?.workTypeId) {
                const workTypeToSet: WorkTypeDTO | undefined = await workTypeDaoRef.current.getById(workSpecification.workTypeId);
                setWorkType(workTypeToSet);
            }
        };
        fetchWorkType();
    }, [workSpecification?.workTypeId]);

    return (
        <Box display="grid" gridTemplateColumns="1fr max-content" columnGap={1} rowGap={0.5} overflow="auto">
            <Box display="flex" alignItems="center">
                <Typography fontSize={DESCRIPTION_FONT_SIZE} color="text.secondary">
                    {index + 1}&nbsp;&nbsp;
                </Typography>
                <ExpandableDisplayText
                    description={workType?.name || "N/A"}
                    linesToDisplayWhenCollapsed={1}
                    typographyProps={{
                        fontSize: "1rem"
                    }}
                />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                {
                    isSoleSource &&
                    <>
                        <Typography fontSize={DESCRIPTION_FONT_SIZE}>
                            $&nbsp;
                        </Typography>
                        <Typography fontSize="0.875rem" lineHeight="125%">
                            {priceAfterAdjustment ? NumberStringConverter.numberToString(priceAfterAdjustment, 0, true) : 0}
                        </Typography>
                    </>
                }
            </Box>
            <Box display="flex" alignItems="center" ml={2} gap={0.5}>
                <Box display="flex" gap={0.5} alignItems="center">
                    <Typography fontSize={DESCRIPTION_FONT_SIZE} color="text.secondary">
                        L
                    </Typography>
                    <Typography fontSize={VALUE_FONT_SIZE}>
                        {workSpecification?.measurement?.length ?? "N/A"}
                    </Typography>
                </Box>
                {workSpecification?.measurement?.dimensionType === DimensionType.SQUARE ? (
                    <Box display="flex" gap={0.5} alignItems="center">
                        <Typography fontSize={DESCRIPTION_FONT_SIZE} color="text.secondary">
                            x
                        </Typography>
                        <Typography fontSize={DESCRIPTION_FONT_SIZE} color="text.secondary">
                            W
                        </Typography>
                        <Typography fontSize={VALUE_FONT_SIZE}>
                            {workSpecification?.measurement?.width ?? "N/A"}
                        </Typography>
                        <Typography fontSize={DESCRIPTION_FONT_SIZE} color="text.secondary">
                            &nbsp;{measurementUnitString}
                        </Typography>
                        <Typography fontSize={VALUE_FONT_SIZE}>
                            &nbsp;&nbsp;&nbsp;&nbsp;{unitCount ?? "N/A"}
                        </Typography>
                        <Typography fontSize={DESCRIPTION_FONT_SIZE} color="text.secondary" noWrap>
                            {dimensionTypeString} {measurementUnitString}
                        </Typography>
                    </Box>
                ) : (
                    <Typography fontSize={DESCRIPTION_FONT_SIZE} color="text.secondary">
                        &nbsp;{measurementUnitString}
                    </Typography>
                )}
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                {
                    isSoleSource &&
                    <>
                        <Typography fontSize={DESCRIPTION_FONT_SIZE} color="text.secondary">
                            $&nbsp;
                        </Typography>
                        <Typography fontSize={VALUE_FONT_SIZE} color="text.secondary">
                            {unitPriceAfterAdjustment ? NumberStringConverter.numberToString(unitPriceAfterAdjustment, 2, true) : "N/A"}&nbsp;
                        </Typography>
                        <Typography fontSize={DESCRIPTION_FONT_SIZE} color="text.secondary">
                            / {dimensionTypeString} {measurementUnitString}
                        </Typography>
                    </>
                }
            </Box>
        </Box>
    );
};
