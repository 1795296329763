import {
    GetAttendeeQuery,
    GetAttendeeQueryVariables
} from "../../../../API";
import GraphQLAPI, {
    GraphQLResult
} from "@aws-amplify/api-graphql";

import { Attendee } from "../../../../models";
import { AttendeeDAO } from "./AttendeeDAO";
import { AuthIDTokenSupplierFactory } from "../../../auth/AuthIDTokenSupplierFactory";
import ClientLogger from "../../../logging/ClientLogger";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { IDTokenSupplier } from "../../../auth/IDTokenSupplier";
import { getAttendee } from "../../../../graphql/queries";

export class GraphQLAttendeeDAO implements AttendeeDAO {
    private static readonly GET_ATTEMPT_METRIC_NAME = "GraphQLAttendeeDAO.Get.Attempt";
    private static readonly GET_SUCCESS_METRIC_NAME = "GraphQLAttendeeDAO.Get.Success";
    private static readonly GET_FAILURE_METRIC_NAME = "GraphQLAttendeeDAO.Get.Failure";

    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async getById(id: string): Promise<Attendee> {
        try {
            this.logger.info(
                `Fetching attendee with id: ${id}`,
                undefined,
                [GraphQLAttendeeDAO.GET_ATTEMPT_METRIC_NAME]
            );
            const result: GraphQLResult<GetAttendeeQuery> = await GraphQLAPI.graphql({
                query: getAttendee,
                variables: { id } satisfies GetAttendeeQueryVariables,
                authMode: "API_KEY"
            }) as GraphQLResult<GetAttendeeQuery>;
            const attendee: Attendee | undefined = result.data?.getAttendee ?? undefined;
            if (!attendee) {
                throw new Error(`Attendee with id: ${id} not found`);
            }
            this.logger.info(
                `Successfully fetched attendee with id: ${id}`,
                undefined,
                [GraphQLAttendeeDAO.GET_SUCCESS_METRIC_NAME]
            );
            return attendee;
        } catch (error) {
            this.logger.error(
                `Failed to fetch attendee with id: ${id}`,
                error,
                [GraphQLAttendeeDAO.GET_FAILURE_METRIC_NAME]
            );
            throw error;
        }
    }
}

export const graphQLAttendeeDAO: AttendeeDAO = new GraphQLAttendeeDAO(
    ClientLoggerFactory.getClientLogger("GraphQLAttendeeDAO"),
    AuthIDTokenSupplierFactory.getInstance()
);