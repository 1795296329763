import {
  EntityWorkTypeAssociation,
  WorkTypeGroup
} from "../../../../models";

import EntityWorkTypeAssociationDAO from "../../relationship/EntityWorkTypeAssociationDAO";
import ListEntityAssociatedWorkTypesError from "./error/ListEntityAssociatedWorkTypesError";
import ListEntityAssociatedWorkTypesHandler from "./ListEntityAssociatedWorkTypesHandler";
import ListEntityAssociatedWorkTypesRequest from "./ListEntityAssociatedWorkTypesRequest";
import ListEntityAssociatedWorkTypesResponse from "./ListEntityAssociatedWorkTypesResponse";
import WorkTypeDAO from "../../WorkTypeDAO";
import WorkTypeDTO from "../../DTO/WorkTypeDTO";
import WorkTypeGroupDAO from "../../group/WorkTypeGroupDAO";

export default class ListEntityAssociatedWorkTypesHandlerImpl implements ListEntityAssociatedWorkTypesHandler {
  private readonly workTypeDAO: WorkTypeDAO;
  private readonly entityWorkTypeAssociationDAO: EntityWorkTypeAssociationDAO;
  private readonly workTypeGroupDAO: WorkTypeGroupDAO;

  constructor(
    workTypeDAO: WorkTypeDAO,
    entityWorkTypeDAO: EntityWorkTypeAssociationDAO,
    workTypeGroupDAO: WorkTypeGroupDAO
  ) {
      this.workTypeDAO = workTypeDAO;
      this.entityWorkTypeAssociationDAO = entityWorkTypeDAO;
      this.workTypeGroupDAO = workTypeGroupDAO;
  }

  public async handle(
    request: ListEntityAssociatedWorkTypesRequest
  ): Promise<ListEntityAssociatedWorkTypesResponse> {
      try {
        const userWorkTypeAssociationList = await this.entityWorkTypeAssociationDAO.listByEntityId(request.entityId);
        const workTypePromises: Array<Promise<WorkTypeDTO>> = userWorkTypeAssociationList.map(async (association: EntityWorkTypeAssociation) => {
        const workTypeGroup: WorkTypeGroup = await this.workTypeGroupDAO.getById(association.workTypeGroupId);
        return this.workTypeDAO.getByGroupIdAndVersion(association.workTypeGroupId, workTypeGroup.latestWorkTypeVersion); 
      });
      const workTypePromiseSettledResultList: Array<PromiseSettledResult<WorkTypeDTO>> = await Promise.allSettled(workTypePromises);
      const workTypes: Array<WorkTypeDTO> = workTypePromiseSettledResultList
        .filter(result => result.status === "fulfilled")
        .map(result => (result as PromiseFulfilledResult<WorkTypeDTO>).value);
      const response: ListEntityAssociatedWorkTypesResponse = {
        entityAssociatedWorkTypes: workTypes
      };
      return response;
    } catch (error) {
      throw new ListEntityAssociatedWorkTypesError(
        `Error occurred while listing workTypes associated with entityId: ${request.entityId}`, 
        error as Error
      );
    }
  }
}