import {
    Dialog,
    DialogContent,
    DialogTitle
} from "@mui/material";
import {
    DimensionType,
    MeasurementUnit,
    WorkSpecification
} from "../../models";
import {
    dimensionByDimensionIdentifierAtomFamily,
    dimensionIdsByIssueIdentifierAtomFamily
} from "../../lib/design/document/state/DocumentState";
import {
    idInFocusByModelTypeAtomFamily,
    propertyIdInFocusAtom
} from "../../lib/ui/InFocusRecoilStates";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { ModelType } from "../../lib/design/document/ModelType";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { WorkScopeSpecificationQuickCreateForm } from "./WorkScopeSpecificationQuickCreateForm";
import WorkTypeDTO from "../../lib/worktype/DTO/WorkTypeDTO";
import { isCreateWorkSpecificationDialogOpen } from "../../lib/ui/states/DialogOpenStates";
import { useCreateWorkSpecification } from "../../lib/design/workscopespecification/hook/useCreateWorkSpecification";
import { useMemo } from "react";

export const WorkScopeSpecificationQuickCreateDialog = () => {
    const [isOpened, setIsOpened] = useRecoilState(isCreateWorkSpecificationDialogOpen);
    const { createWorkSpecification } = useCreateWorkSpecification(StateContext.INSPECTION);
    const propertyIdInFocus = useRecoilValue(propertyIdInFocusAtom);
    const issueIdInFocus = useRecoilValue(idInFocusByModelTypeAtomFamily(ModelType.ISSUE));
    const issueIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(issueIdInFocus ?? "", StateContext.INSPECTION, ModelType.ISSUE);
    }, [issueIdInFocus ]);
    const dimensionIds = useRecoilValue(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier));
    const defaultDimensionId: string = dimensionIds[0];
    const defaultDimensionIdentifier = useMemo(() => {
        return new ContextAwareIdentifier(defaultDimensionId, StateContext.INSPECTION, ModelType.DIMENSION);
    }, [defaultDimensionId]);
    const defaultDimension = useRecoilValue(dimensionByDimensionIdentifierAtomFamily(defaultDimensionIdentifier));

    const handleSubmit = async (workType?: WorkTypeDTO, width?: number, length?: number) => {
        const workSpecification = new WorkSpecification({
            workTypeId: workType?.id,
            propertyId: propertyIdInFocus,
            dimensionId: defaultDimensionId,
            issueId: issueIdInFocus,
            measurement: {
                width: width,
                length: length,
                measurementUnit: MeasurementUnit.FOOT,
                dimensionType: workType?.dimensionType ?? DimensionType.SQUARE,
            }
        });
        await createWorkSpecification(workSpecification);
        setIsOpened(false);
    }

    return (
        <Dialog
            open={isOpened}
            onClose={() => {
                setIsOpened(false);
            }}
            fullWidth
            maxWidth="md"
            keepMounted={false}
        >
            <DialogTitle>
                Create Work Scope Specification
            </DialogTitle>
            <DialogContent>
                <WorkScopeSpecificationQuickCreateForm
                    isOpened={isOpened}
                    onCancel={function (): void {
                        setIsOpened(false);
                    }}
                    onSubmit={function (
                        workType?: WorkTypeDTO,
                        width?: number,
                        length?: number
                    ): void {
                        handleSubmit(workType, width, length);
                    }}
                    defaultMeasurement={defaultDimension?.measurement || undefined}
                />
            </DialogContent>
        </Dialog>
    );
}