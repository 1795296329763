import AttachmentContentDAO from "../AttachmentContentDAO";
import CachingAttachmentContentDAO from "./CachingAttachmentContentDAO";
import LocalForageAttachmentContentDAOFactory from "../localforage/LocalForageAttachmentContentDAOFactory";
import { S3ImageAttachmentContentDAOFactory } from "../s3/S3AttachmentContentDAOFactory";

export default class CachingAttachmentContentDAOFactory {
    private static readonly INSTANCE: AttachmentContentDAO = new CachingAttachmentContentDAO(
        S3ImageAttachmentContentDAOFactory.getInstance(),
        LocalForageAttachmentContentDAOFactory.getInstance()
    );

    public static getInstance(): AttachmentContentDAO {
        return CachingAttachmentContentDAOFactory.INSTANCE;
    }
}
