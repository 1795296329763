import DataStoreIssueDAOFactory from "../../../issue/dao/datastore/DataStoreIssueDAOFactory";
import { GenericIngressCompletionChecker } from "../GenericIngressCompletionChecker";
import { IssueDAOToResourcePermissionMetadataDAOAdapter } from "../adapter/IssueDAOToResourcePermissionMetadataDAOAdapter";

export class IssueIngressCompletionCheckerFactory {
    private static INSTANCE = new GenericIngressCompletionChecker(
        new IssueDAOToResourcePermissionMetadataDAOAdapter(
            DataStoreIssueDAOFactory.getInstance()
        )
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}