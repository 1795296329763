import {
    API,
    graphqlOperation
} from "aws-amplify";

import AttachmentRecordDAO from "../AttachmentRecordDAO";
import { AuthIDTokenSupplierFactory } from "../../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import GraphQLAttachmentRecordDAO from "./GraphQLAttachmentRecordDAO";

export class GraphQLAttachmentRecordDAOFactory {
    private static readonly INSTANCE: AttachmentRecordDAO = new GraphQLAttachmentRecordDAO(
        ClientLoggerFactory.getClientLogger("GraphQLAttachmentRecordDAO"),
        API,
        graphqlOperation,
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance(): AttachmentRecordDAO {
        return this.INSTANCE
    }
}
