import { DefaultTemplateDAOFactory } from "../DefaultTemplateDAOFactory";
import { Template } from "../../../models";
import { TemplateDAO } from "../TemplateDAO";
import { TemplateEntityType } from "../../../models";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { useUserAssociatedTeams } from "../../team/useUserAssociatedTeams";
import { userIdAtom } from "../../userSession/state/UserSessionState";

const templateDAO: TemplateDAO = DefaultTemplateDAOFactory.getInstance();

export const useUserAssociatedTemplates = () => {
    const userId: string | undefined = useRecoilValue(userIdAtom);
    const { userAssociatedTeams } = useUserAssociatedTeams();

    const { data: entityNameToTemplatesMap, refetch, status } = useQuery({
        queryKey: ["entityNameToTemplatesMap", userId, userAssociatedTeams],
        queryFn: async () => {
            let templateMap: Map<string, Array<Template>> = new Map();
            if (userId) {
                const userTemplates: Array<Template> = await templateDAO.listByEntity(userId, TemplateEntityType.USER);
                const nonDeletedUserTemplates: Array<Template> = userTemplates.filter((template) => !(template as any)._deleted);
                if (nonDeletedUserTemplates.length > 0) {
                    templateMap.set("User (You)", nonDeletedUserTemplates);
                }
            }
            await Promise.all(userAssociatedTeams?.map(async (team) => {
                const teamTemplates: Array<Template> = await templateDAO.listByEntity(team.id, TemplateEntityType.TEAM);
                const nonDeletedTeamTemplates: Array<Template> = teamTemplates.filter((template) => !(template as any)._deleted);
                if (nonDeletedTeamTemplates.length > 0) {
                    templateMap.set(team.legalName, nonDeletedTeamTemplates);
                }
            }) ?? []);
            return templateMap;
        },
        retry: false
    });

    return { entityNameToTemplatesMap, refetch, status };
};