import {
    TranscriptEvent,
    TranscriptionStatus
} from "amazon-chime-sdk-js";
import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState
} from "react";

import { MessageSource } from "../../../lib/meeting/message/MessageSource";
import { TranscriptContextWindowBehaviour } from "../../../lib/meeting/transcript/TranscriptContextWindowBehaviour";
import { TranscriptionMessage } from "../../../lib/meeting/message/TranscriptionMessage";
import { transcriptContextWindowAtom } from "../hooks/state/AISummarizationStates";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useRecoilValue } from "recoil";

type TranscriptionSubscriber = (event: TranscriptionMessage) => void | Promise<void>;

type TranscriptionContextType = {
    transcripts: Array<TranscriptionMessage>;
    getMessagesInContextWindow: () => Array<TranscriptionMessage>;
    markTranscriptAsUsed: (messages: Array<string>) => void;
    addTranscriptEventListener: (callback: TranscriptionSubscriber) => () => void;
    startTranscriptSubscription: () => () => void;
};

const TranscriptionContext = createContext<TranscriptionContextType | null>(null);

export const TranscriptionProvider = ({ children }: { children: React.ReactNode }) => {
    const meetingManager = useMeetingManager();
    const subscribersRef = useRef<Array<TranscriptionSubscriber>>([]);
    const [completedTranscripts, setCompletedTranscripts] = useState<Array<TranscriptionMessage>>([]);
    const [attendeeIdToTranscriptInProgressMap, setAttendeeIdToTranscriptInProgressMap] = useState<Map<string, TranscriptionMessage>>(new Map());
    const transcriptContextWindow = useRecoilValue(transcriptContextWindowAtom);
    
    const getMessagesInContextWindow = useCallback(() => {
        if (transcriptContextWindow.behaviour === TranscriptContextWindowBehaviour.TAILING) {
            return completedTranscripts.slice(completedTranscripts.length - transcriptContextWindow.windowSize, completedTranscripts.length);
        }
        if (transcriptContextWindow.behaviour === TranscriptContextWindowBehaviour.START_DEFINED) {
            return completedTranscripts.slice(transcriptContextWindow.startIndex, completedTranscripts.length);
        }
        return completedTranscripts.slice(transcriptContextWindow.startIndex, transcriptContextWindow.endIndex);
    }, [completedTranscripts, transcriptContextWindow]);

    const transcripts = useMemo(() => {
        return [...completedTranscripts, ...Array.from(attendeeIdToTranscriptInProgressMap.values())];
    }, [completedTranscripts, attendeeIdToTranscriptInProgressMap]);

    const markTranscriptAsUsed = useCallback((messages: Array<string>) => {
        setCompletedTranscripts((prev) => {
            return prev.map((message) => {
                if (messages.includes(message.message)) {
                    return { ...message, isUsed: true };
                }
                return message;
            });
        });
        setAttendeeIdToTranscriptInProgressMap((prev) => {
            const newMap = new Map(prev);
            messages.forEach((message) => {
                newMap.forEach((value, key) => {
                    if (value.message === message) {
                        newMap.set(key, { ...value, isUsed: true });
                    }
                });
            });
            return newMap;
        });
    }, []);

    const addTranscriptEventListener = useCallback((callback: TranscriptionSubscriber) => {
        subscribersRef.current.push(callback);
        return () => {
            subscribersRef.current = subscribersRef.current.filter((subscriber) => subscriber !== callback);
        };
    }, []);

    const publish = useCallback((message: TranscriptionMessage) => {
        subscribersRef.current.forEach((subscriber) => {
            subscriber(message);
        });
    }, []);

    const handleIncomingTranscript = useCallback(async (event: TranscriptEvent) => {
        if (event instanceof TranscriptionStatus) {
            return;
        }
        const isPartial = event.results[0].isPartial;
        const attendeeId = event.results[0].alternatives[0].items[0].attendee.attendeeId;
        const isSelf = attendeeId === meetingManager?.meetingSessionConfiguration?.credentials?.attendeeId;
        const message: TranscriptionMessage = {
            message: event.results[0].alternatives[0].transcript,
            attendeeId: attendeeId,
            source: isSelf ? MessageSource.Self : MessageSource.Other,
            isPartial: isPartial
        };
        if (!isPartial) {
            const attendeeIdToMessageInProgress = await new Promise<Map<string, TranscriptionMessage>>((resolve) => {
                setAttendeeIdToTranscriptInProgressMap(prev => {
                    resolve(prev);
                    const newMap = new Map(prev);
                    newMap.delete(attendeeId);
                    return newMap;
                });
            });
            setCompletedTranscripts?.((prev) => [...prev, {
                ...message,
                isUsed: attendeeIdToMessageInProgress.get(attendeeId)?.isUsed ?? false
            }]);
        } else {
            setAttendeeIdToTranscriptInProgressMap(prev => {
                const newMap = new Map(prev);
                newMap.set(attendeeId, {
                    ...message,
                    isUsed: prev.get(attendeeId)?.isUsed ?? false
                });
                return newMap;
            });
        }
        publish(message);
    }, []);

    const startTranscriptSubscription = useCallback(() => {
        meetingManager.audioVideo?.transcriptionController?.subscribeToTranscriptEvent(handleIncomingTranscript);
        return () => {
            meetingManager.audioVideo?.transcriptionController?.unsubscribeFromTranscriptEvent(handleIncomingTranscript);
            setCompletedTranscripts([]);
            setAttendeeIdToTranscriptInProgressMap(new Map());
            subscribersRef.current = [];
        }
    },  []);

    return (
        <TranscriptionContext.Provider value={{ transcripts, getMessagesInContextWindow, markTranscriptAsUsed, addTranscriptEventListener, startTranscriptSubscription }}>
            {children}
        </TranscriptionContext.Provider>
    )
}

export const useTranscriptionContext = () => {
    const context = useContext(TranscriptionContext);
    if (!context) {
        throw new Error("useTranscriptionContext must be used within TranscriptionProvider");
    }
    return context;
}