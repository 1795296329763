import {
    Box,
    Collapse,
    Divider,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Stack
} from "@mui/material";
import React, {
    useMemo,
    useState
} from "react";

import DataGroup from "../../lib/util/data/group/DataGroup";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkTypeAdminManagementListItem from "./adminManagement/WorkTypeAdminManagementListItem";
import WorkTypeDTO from "../../lib/worktype/DTO/WorkTypeDTO";
import { WorkTypeManagementListItem } from "./WorkTypeManagementListItem";
import { isUserAdminAtom } from "../../lib/auth/state/AuthRecoilState";
import { useRecoilValue } from "recoil";

interface WorkTypeManagementCategoryListItemProps {
    readonly workTypeCategory: DataGroup<string, WorkTypeDTO>;
}

export const WorkTypeManagementCategoryListItem = (props: WorkTypeManagementCategoryListItemProps) => {
    const { workTypeCategory } = props;
    /* Internal page states */
    const isUserAdmin = useRecoilValue<boolean>(isUserAdminAtom);
    const workTypeCategoryName: string = useMemo(() => {
        return workTypeCategory.key;
    }, [workTypeCategory]);
    const [isExpanded, setIsExpanded] = useState<boolean>();

    return (
        <>
            <ListItem
                sx={{ minHeight: "5em" }}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <ListItemText primary={workTypeCategoryName} />
                <ListItemSecondaryAction>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                    >
                        <Box
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Box>
                    </Stack>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={isExpanded} timeout="auto">
                {
                    workTypeCategory.dataItems.map((workType) => {
                        return (
                            <React.Fragment key={workType.id}>
                                <Divider />
                                { isUserAdmin ? (
                                    <WorkTypeAdminManagementListItem
                                        key={workType.id}
                                        workTypeGroupId={workType.groupId}
                                    />
                                ) : (
                                    <WorkTypeManagementListItem
                                        key={workType.id}
                                        workTypeGroupId={workType.groupId}
                                    />
                                )}
                            </React.Fragment>
                        )
                    })
                }
            </Collapse>
        </>
    );
};
