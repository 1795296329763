import {
    filteredIssueIdsByLocationIdentifierSelectorFamily,
    filteredLocationIdsByContextSelectorFamily,
    issueByIssueIdentifierSelectorFamily,
    locationIdsByContextAtomFamily
} from "../../../../lib/design/document/state/DocumentState";

import { ChildrenExpandState } from "../../../../lib/design/document/state/enum/ChildrenExpandState";
import { ContextAwareIdentifier } from "../../../../lib/design/document/ContextAwareIdentifier";
import { ModelType } from "../../../../lib/design/document/ModelType";
import { StateContext } from "../../../../lib/design/document/state/StateContext";
import { idInFocusByModelTypeAtomFamily } from "../../../../lib/ui/InFocusRecoilStates";
import { useRecoilCallback } from "recoil";
import { useSetChildrenExpandState } from "../../../../lib/design/hooks/useSetChildrenExpandState";
import { useSnackbar } from "notistack";

enum NavigationDirection {
    UP,
    DOWN
}

export const useNavigationQuickActions = (context: StateContext) => {
    const snackbar = useSnackbar();
    const { setModelChildrenExpandState } = useSetChildrenExpandState(context);

    const moveFocusUp = () => {
        moveFocus(NavigationDirection.UP)
    }

    const moveFocusDown = () => {
        moveFocus(NavigationDirection.DOWN)
    }
    
    const moveFocus = useRecoilCallback(({ snapshot }) => async (direction: NavigationDirection) => {
        const increment: number = direction === NavigationDirection.UP ? -1 : 1;
        const issueIdInFocus = await snapshot.getPromise(idInFocusByModelTypeAtomFamily(ModelType.ISSUE));
        const locationIdInFocus = await snapshot.getPromise(idInFocusByModelTypeAtomFamily(ModelType.LOCATION));
        if (issueIdInFocus) {
            const issueIdentifier = new ContextAwareIdentifier(issueIdInFocus, context, ModelType.ISSUE);
            const issue = await snapshot.getPromise(issueByIssueIdentifierSelectorFamily(issueIdentifier));
            const locationIdentifier = new ContextAwareIdentifier(issue!.locationId!, context, ModelType.LOCATION);
            const issueIds = await snapshot.getPromise(filteredIssueIdsByLocationIdentifierSelectorFamily(locationIdentifier));
            const nextIssueId = issueIds[issueIds.indexOf(issueIdInFocus) + increment];
            if (!nextIssueId) {
                 snackbar.enqueueSnackbar('No next issue', { variant: 'error' });
                 return;
            }
            setModelChildrenExpandState(ModelType.ISSUE, nextIssueId, ChildrenExpandState.EXPAND_CHILDREN);
            return;
        } else if (locationIdInFocus) {
            const locationIds = await snapshot.getPromise(filteredLocationIdsByContextSelectorFamily(context));
            const nextLocationId = locationIds[locationIds.indexOf(locationIdInFocus) + increment];
            if (!nextLocationId) {
                snackbar.enqueueSnackbar('No next location', { variant: 'error' });
                return;
            }
            setModelChildrenExpandState(ModelType.LOCATION, nextLocationId, ChildrenExpandState.EXPAND_CHILDREN);
            return;
        } 
        snackbar.enqueueSnackbar('No location or issue in focus', { variant: 'error' });
    }, [snackbar]);

    const moveFocusIn = useRecoilCallback(({  snapshot }) => async () => {
        const issueIdInFocus = await snapshot.getPromise(idInFocusByModelTypeAtomFamily(ModelType.ISSUE));
        const locationIdInFocus = await snapshot.getPromise(idInFocusByModelTypeAtomFamily(ModelType.LOCATION));
        if (issueIdInFocus) {
            return;
        }
        if (locationIdInFocus) {
            const locationIdentifier = new ContextAwareIdentifier(locationIdInFocus, context, ModelType.LOCATION);
            const issueIds = await snapshot.getPromise(filteredIssueIdsByLocationIdentifierSelectorFamily(locationIdentifier));
            if (issueIds.length > 0) {
                setModelChildrenExpandState(ModelType.ISSUE, issueIds[0], ChildrenExpandState.EXPAND_CHILDREN);
            }
            return;
        }
        const locationIds = await snapshot.getPromise(filteredLocationIdsByContextSelectorFamily(context));
        if (locationIds.length > 0) {
            setModelChildrenExpandState(ModelType.LOCATION, locationIds[0], ChildrenExpandState.EXPAND_CHILDREN);
            return;
        }
        snackbar.enqueueSnackbar('No Location to focus on', { variant: 'error' });
    }, [snackbar]);

    const moveFocusOut = useRecoilCallback(({ snapshot }) => async () => {
        const issueIdInFocus = await snapshot.getPromise(idInFocusByModelTypeAtomFamily(ModelType.ISSUE));
        const locationIdInFocus = await snapshot.getPromise(idInFocusByModelTypeAtomFamily(ModelType.LOCATION));
        if (issueIdInFocus) {
            setModelChildrenExpandState(ModelType.ISSUE, issueIdInFocus, ChildrenExpandState.COLLAPSE_CHILDREN);
            return;
        }
        if (locationIdInFocus) {
            setModelChildrenExpandState(ModelType.LOCATION, locationIdInFocus, ChildrenExpandState.COLLAPSE_CHILDREN);
            // scroll to the top of the window
            window.scrollTo(0, 0);
            return;
        }
        snackbar.enqueueSnackbar('Nothing in focus', { variant: 'error' });
    }, [snackbar]);

    return { moveFocusUp, moveFocusDown, moveFocusIn, moveFocusOut}
}
