import TextField, { TextFieldProps } from '@mui/material/TextField';

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from "react";

type PasswordFieldProps = TextFieldProps;

export const PasswordField = (props: PasswordFieldProps) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <TextField
            required
            fullWidth
            type={showPassword ? "text" : "password"}
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        <IconButton size='small' onClick={() => setShowPassword(prev => !prev)}>
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>,
            }}
            {...props}
        />
    );
};