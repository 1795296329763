import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const AddPhotoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    style={{
      //@ts-ignore
      enableBackground: "new 0 0 24 24",
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13 8c0 1.7 1.3 3 3 3s3-1.3 3-3-1.3-3-3-3-3 1.3-3 3zm4 0c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1zm-2.5 4-.4-.5-1.6 1.6-3.4-4.7L3 15.5V3h18v8.7c.4.2.7.4 1 .6.4.3.7.6 1.1.9L22 10V2h-2.7L19 1l-3.2 1H2v4.4L0 7l2 6.4V18h1.4L5 23l6.5-2.4c-.1-.3-.2-.6-.3-1l-5.6 2L4.5 18H11c0-.3 0-.7.1-1H4.3l4.6-5.4 2.6 3.7c.1-.2.2-.3.2-.5.7-1.2 1.6-2.2 2.8-2.8zM2 10l-.7-2.3.7-.3V10z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M18 12a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm2.667 6.667h-2v2a.666.666 0 0 1-1.333 0v-2h-2a.667.667 0 0 1 0-1.333h2v-2a.667.667 0 0 1 1.333 0v2h2a.666.666 0 1 1 0 1.333z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(AddPhotoIcon);
