import GraphQLSolutionDAOFactory from "../../majorVersion/dao/GraphQLSolutionDAOFactory";
import PublishSolutionHandler from "./PublishSolutionHandler";
import PublishSolutionHandlerImpl from "./PublishSolutionHandlerImpl";

export default class PublishSolutionHandlerFactory {
    private static readonly INSTANCE: PublishSolutionHandler = new PublishSolutionHandlerImpl(
        GraphQLSolutionDAOFactory.getInstance(),
    );

    public static getInstance(): PublishSolutionHandler {
        return PublishSolutionHandlerFactory.INSTANCE;
    }
}
