import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m15 5 3-3 4 4-3 3zM2 18v4h4l12-12-4-4z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(EditIcon);
