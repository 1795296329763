import {
    Dispatch,
    SetStateAction
} from "react";
import {
    ListItemIcon,
    ListItemText,
    MenuItem
} from "@mui/material";
import {
    Solution,
    SolutionStatus
} from "../../models";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DropDownMenu from "../menu/DropDownMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TenderIcon from '@mui/icons-material/Plagiarism';
import { useCopySolutionAndProposal } from "../../lib/solution/hooks/useCopySolutionAndProposal";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTendering } from "../../lib/tender/hook/useTendering";

export interface SolutionListItemMenuProps {
    readonly solution: Solution;
    readonly setCopyingSolution: Dispatch<SetStateAction<boolean>>;
    readonly setShowDeleteConfirmation: Dispatch<SetStateAction<boolean>>;
    readonly size?: "small" | "medium";
}

const SolutionListItemMenu = (props: SolutionListItemMenuProps) => {
    /* Internal page states */
    const { solution, setCopyingSolution, setShowDeleteConfirmation, size } = props;
    const { createNewTender, openTenderPage } = useTendering();

    const { copySolutionAndProposal } = useCopySolutionAndProposal();
    const history = useHistory();
    const snackbar = useSnackbar();

    const onCopyButtonClicked = async () => {
        try {
            setCopyingSolution(true);
            const newSolution = await copySolutionAndProposal(solution);
            const copiedSolutionUrl = `/properties/${newSolution.propertyId}/solutions/${newSolution.id}/edit`;
            history.push(copiedSolutionUrl);
        } catch (error) {
            console.error(error);
            snackbar.enqueueSnackbar("Failed to copy solution", { variant: "error" });
        } finally {
            setCopyingSolution(false);
        }
    };

    return (
        <>
            <DropDownMenu
                menuIcon={<MoreVertIcon />}
                menuIconSize={size}
                menuItems={[
                    <MenuItem
                        onClick={onCopyButtonClicked}
                    >
                        <ListItemIcon>
                            <ContentCopyIcon color='primary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Duplicate
                        </ListItemText>
                    </MenuItem>,
                    <MenuItem
                        onClick={async () => {
                            const tender = await createNewTender(solution);
                            if(tender) {
                                openTenderPage(tender.id!);
                            }
                        }}
                        disabled={solution.status === SolutionStatus.DRAFT}
                    >
                        <ListItemIcon>
                            <TenderIcon color='primary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Create Tender
                        </ListItemText>
                    </MenuItem>,
                    <MenuItem
                        onClick={() => setShowDeleteConfirmation(true)}
                        sx={{ color: "error.main" }}
                        disabled={solution.status !== SolutionStatus.DRAFT}
                    >
                        <ListItemIcon sx={{ color: "error.main" }}>
                            <DeleteForeverOutlinedIcon color='error' />
                        </ListItemIcon>
                        <ListItemText>
                            Delete
                        </ListItemText>
                    </MenuItem>
                ]}
            />
        </>
    );
};

export default SolutionListItemMenu;
