import {
    Issue,
    IssueStatus
} from "../../../models";
import {
    RecoilState,
    Snapshot,
    atom,
    selector,
    selectorFamily,
    useRecoilCallback
} from "recoil";
import {
    issueIdIssueStatusMapSelector,
    issueIdToIssueMapByContextSelectorFamily
} from "../../design/document/state/DocumentState";

import Predicate from "../../util/predicate/Predicate";
import ResetIssueFilterStatusError from "./ResetIssueFilterStatusError";
import { StateContext } from "../../design/document/state/StateContext";

export const isFilterAppliedAtom = atom<boolean>({
    key: "isFilterAppliedAtom",
    default: false
});

export const filterCreatedAtStartDateAtom = atom<number | null>({
    key: "filterCreatedAtStartDateAtom",
    default: null
});

export const createdAtStartDatePredicateSelector = selector<Predicate<Issue>>({
    key: "createdAtStartDatePredicateSelector",
    get: ({ get }) => {
        const filterCreatedAtStartDate = get(filterCreatedAtStartDateAtom);
        return new Predicate<Issue>((issue: Issue) => {
            if (!filterCreatedAtStartDate || !issue.clientCreationDate) {
                return true;
            }
            const issueDateNumberWithoutTime: number = (new Date(issue.clientCreationDate).setHours(0, 0, 0, 0));
            return issueDateNumberWithoutTime >= filterCreatedAtStartDate;
        });
    }
});

export const filterCreatedAtEndDateAtom = atom<number | null>({
    key: "filterCreatedAtEndDateAtom",
    default: null
});

export const createdAtEndDatePredicateSelector = selector<Predicate<Issue>>({
    key: "createdAtEndDatePredicateSelector",
    get: ({ get }) => {
        const filterCreatedAtEndDate = get(filterCreatedAtEndDateAtom);
        return new Predicate<Issue>((issue: Issue) => {
            if (!filterCreatedAtEndDate || !issue.clientCreationDate) {
                return true;
            }
            const issueDateNumberWithoutTime: number = (new Date(issue.clientCreationDate).setHours(0, 0, 0, 0));
            return issueDateNumberWithoutTime <= filterCreatedAtEndDate;
        });
    }
});

export const createdAtPredicateSelector = selector<Predicate<Issue>>({
    key: "combinedIssueFilterPredicateSelector",
    get: ({ get }) => {
        const issueCreatedAtDateGreaterThanOrEqualsPredicate = get(createdAtStartDatePredicateSelector);
        const issueCreatedAtDateLessThanOrEqualsPredicate = get(createdAtEndDatePredicateSelector);
        return issueCreatedAtDateGreaterThanOrEqualsPredicate.and(
            issueCreatedAtDateLessThanOrEqualsPredicate
        );
    }
});

export const notInSolutionPredicateSelector = selector<Predicate<Issue>>({
    key: "notInSolutionPredicateSelector",
    get: ({ get }) => {
        const documentIssues = get(issueIdToIssueMapByContextSelectorFamily(StateContext.SOLUTION_AUTHORING_CONTENT));
        return new Predicate<Issue>((issue: Issue) => {
            if (documentIssues.has(issue.id)) {
                return false;
            }
            return true;
        });
    }
});

export const issuePickerFilterPredicateSelector = selector<Predicate<Issue>>({
    key: "issuePickerFilterPredicateSelector",
    get: ({ get }) => {
        const createdAtPredicate = get(createdAtPredicateSelector);
        const notInSolutionPredicate = get(notInSolutionPredicateSelector);
        const issueStatusPredicate = get(issueStatusFilterPredicateSelectorFamily(StateContext.SOLUTION_AUTHORING_ISSUE_PICKER));
        return issueStatusPredicate.and(
            createdAtPredicate.and(
                notInSolutionPredicate
            )
        );
    }
});

export const inspectionFilterPredicateSelector = selector<Predicate<Issue>>({
    key: "inspectionFilterPredicateSelector",
    get: ({ get }) => {
        const createdAtPredicate = get(createdAtPredicateSelector);
        const issueStatusPredicate = get(issueStatusFilterPredicateSelectorFamily(StateContext.INSPECTION));
        return issueStatusPredicate.and(
            createdAtPredicate
        );
    }
});

export const emptyIssueFilterPredicateSelector = selector<Predicate<Issue>>({
    key: "emptyIssueFilterPredicateSelector",
    get: ({ get }) => {
        return new Predicate<Issue>((issue: Issue) => {
            return true;
        });
    }
});

export const issueStatusFilterConfigAtom = atom<Map<IssueStatus, boolean>>({
    key: "issueStatusFilterConfigAtom",
    default: new Map([
        [IssueStatus.NONE, true],
        [IssueStatus.ARCHIVED, false],
        [IssueStatus.UNDER_REVIEW, true],
        [IssueStatus.APPROVED, true],
        [IssueStatus.REJECTED, true],
        [IssueStatus.ON_HOLD, true],
        [IssueStatus.COMPLETED, false]
    ])
});

export const issueStatusFilterPredicateSelectorFamily = selectorFamily<Predicate<Issue>, StateContext>({
    key: "issueStatusFilterPredicateSelector",
    get: (context) => ({ get }) => {
        const issueStatusFilterConfig = get(issueStatusFilterConfigAtom);
        const issueIdIssueStatusMap = get(issueIdIssueStatusMapSelector(context));
        return new Predicate<Issue>((issue: Issue) => {
            const issueStatus = issueIdIssueStatusMap.get(issue.id)!;
            return issueStatusFilterConfig.get(issueStatus)!;
        });
    }
});

export const issueFilterPredicateByStateContextSelectorFamily = selectorFamily<Predicate<Issue>, StateContext>({
    key: "issueFilterStrategyByStateContextSelectorFamily",
    get: (context: StateContext) => ({get}) => {
        if (context === StateContext.INSPECTION) {
            return get(inspectionFilterPredicateSelector);
        }
        if (context === StateContext.SOLUTION_AUTHORING_ISSUE_PICKER) {
            return get(issuePickerFilterPredicateSelector);
        }
        return get(emptyIssueFilterPredicateSelector);
    }
});

export const getResetIssueFilter = () => useRecoilCallback(({ snapshot, reset }) => {
    return () => {
        try {
            resetIssueFilter(snapshot, reset);
        } catch (error) {
            throw new ResetIssueFilterStatusError(
                "Error occurred while trying to reset the issue filter",
                { cause: error as Error }
            );
        }
    };
});

const resetIssueFilter = async (
    snapshot: Snapshot,
    reset: (recoilVal: RecoilState<any>) => void
) => {
    reset(issueStatusFilterConfigAtom);
    reset(filterCreatedAtStartDateAtom);
    reset(filterCreatedAtEndDateAtom);
    reset(isFilterAppliedAtom);
};
