import {
    Box,
    Tab,
    Tabs,
    alpha
} from "@mui/material";

import { PropertyTab } from "../../lib/property/v2/PropertyTab";
import { SyntheticEvent } from "react";
import { activePropertyTabAtom } from "../../lib/property/state/PropertyTabsRecoilState";
import { grey } from "@mui/material/colors";
import theme from "../../assets/style/theme";
import { useRecoilState } from "recoil";

type PropertyInfoProps = {
    readonly propertyId: string;
};

const PropertyTabs = (props: PropertyInfoProps) => {
    const { propertyId } = props;
    const [activeTab, setActiveTab] = useRecoilState<PropertyTab>(activePropertyTabAtom);

    const handleChange = (event: SyntheticEvent, newValue: PropertyTab) => {
        setActiveTab(newValue);
        if (newValue === PropertyTab.DETAILS) {
            window.history.replaceState(null, "", `/properties/${propertyId}${window.location.search}`);
        }
        if (newValue === PropertyTab.ISSUES) {
            window.history.replaceState(null, "", `/properties/${propertyId}/issues${window.location.search}`);
        }
        if (newValue === PropertyTab.SOLUTIONS) {
            window.history.replaceState(null, "", `/properties/${propertyId}/solutions${window.location.search}`);
        }
        if (newValue === PropertyTab.TENDERING) {
            window.history.replaceState(null, "", `/properties/${propertyId}/tendering${window.location.search}`);
        }
    };

    return (
        <Box
            justifyContent="center"
            alignItems="center"
            sx={{
                backgroundColor: "white",
                "& .MuiTab-root": {
                    border: `${grey[300]} 1px solid`, // Invisible border to prevent tab from jumping when selected
                    backgroundColor: grey[300],
                },
                "& .Mui-selected": {
                    border: `${theme.palette.primary.light} 1px solid`,
                    backgroundColor: alpha(theme.palette.primary.light, 0.2),
                    color: theme.palette.primary.main
                },
            }}
        >
            <Tabs
                value={activeTab}
                onChange={handleChange}
                variant="fullWidth"
                TabIndicatorProps={{
                    sx: {
                        display: "none"
                    }
                }}
            >
                <Tab
                    value={PropertyTab.DETAILS}
                    label={"Property"}
                    sx={{ fontSize: theme.typography.h6.fontSize }}
                />
                <Tab
                    value={PropertyTab.ISSUES}
                    label="Issues"
                    sx={{ fontSize: theme.typography.h6.fontSize }}
                />
                <Tab
                    value={PropertyTab.SOLUTIONS}
                    label="Solutions"
                    sx={{ fontSize: theme.typography.h6.fontSize }}
                />
                <Tab
                    value={PropertyTab.TENDERING}
                    label="Tendering"
                    sx={{ fontSize: theme.typography.h6.fontSize }}
                />
            </Tabs>
        </Box>
    );
};

export default PropertyTabs;
