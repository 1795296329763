import {
    Button,
    ButtonGroup,
    ButtonProps,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from "@mui/material";
import {
    Fragment,
    MouseEvent,
    MouseEventHandler,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type ButtonOption = {
    label: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
};

type SplitButtonProps = Omit<ButtonProps, "onClick"> & {
    options: Array<ButtonOption>;
};

export const SplitButton = ({ options, ...buttonProps }: SplitButtonProps) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        // Reset selected index when options change
        setSelectedIndex(0);
    }, [options]);
    
    const selectedOption = useMemo(() => {
        return options[Math.min(selectedIndex, options.length - 1)];
    }, [selectedIndex, options]);

    const handleMenuItemClick = (
        event: MouseEvent<HTMLLIElement>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setOpen(false);
    };

    return (
        <Fragment>
            <ButtonGroup
                variant="contained"
                ref={anchorRef}
            >
                <Button
                    {...buttonProps}
                    onClick={selectedOption.onClick}
                >
                    {selectedOption.label}
                </Button>
                {options.length > 1 && (
                    <Button
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleToggle}
                        disabled={buttonProps.disabled}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                )}
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={index}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Fragment>
    );
};