import {
    Box,
    Collapse,
    Divider,
    List
} from "@mui/material";
import {
    Draggable,
    Droppable
} from "react-beautiful-dnd";

import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { ModelType } from "../../lib/design/document/ModelType";
import NoItemsPlaceholder from "../../components/NoItemsPlaceholder";
import { PermissionType } from "../../models";
import { SolutionAuthoringEnabledPermissionRestrictedControl } from "../../components/general/button/SolutionAuthoringEnabledPermissionRestrictedControl";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { TransitionGroup } from "react-transition-group";
import { WorkSpecificationCard } from "./WorkSpecificationCard";
import { darkBlue } from "../../assets/style/colors";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { workSpecificationIdsByIssueIdentifierSelectorFamily } from "../../lib/design/document/state/DocumentState";

type WorkSpecificationByIssueProps = {
    readonly issueId: string;
    readonly stateContext: StateContext;
};

const WorkSpecificationByIssue = (props: WorkSpecificationByIssueProps) => {
    const { issueId, stateContext } = props;
    const issueIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(issueId, stateContext, ModelType.ISSUE);
    }, [issueId, stateContext]);
    const workSpecificationIds = useRecoilValue<Array<string>>(workSpecificationIdsByIssueIdentifierSelectorFamily(issueIdentifier));

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            sx={{
                backgroundImage: `linear-gradient(to right, #FFFFFF, ${darkBlue[50]})`,
            }}
        >
            {workSpecificationIds.length === 0 && (
                <Box
                    display="flex"
                    justifyContent="center"
                    p={2}
                >
                    <NoItemsPlaceholder text="No work specification under issue." />
                </Box>
            )}
            <SolutionAuthoringEnabledPermissionRestrictedControl
                resourceId={issueId}
                requiredPermission={PermissionType.CREATE_CHILDREN}
                context={stateContext}
            >
                {(disabled) => (
                    <Droppable droppableId={issueId} type={ModelType.WORK_SPECIFICATION} isDropDisabled={disabled}>
                        {(provided, snapshot) => (
                            <List
                                disablePadding
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                <TransitionGroup>
                                    {workSpecificationIds.map((workSpecificationId, index) => {
                                        return (
                                            <Collapse key={workSpecificationId}>
                                                <Draggable key={workSpecificationId} draggableId={workSpecificationId} index={index}>
                                                    {(provided, snapshot) => (
                                                        <>
                                                            <Box
                                                                my={1}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                            >
                                                                <WorkSpecificationCard
                                                                    workSpecificationId={workSpecificationId}
                                                                    stateContext={stateContext}
                                                                    dragHandleProps={provided.dragHandleProps}
                                                                />
                                                            </Box>
                                                            <Divider />
                                                        </>
                                                    )}
                                                </Draggable>
                                            </Collapse>
                                        );
                                    })}
                                    {provided.placeholder}
                                </TransitionGroup>
                            </List>
                        )}
                    </Droppable>
                )}
            </SolutionAuthoringEnabledPermissionRestrictedControl>
        </Box>
    );
};

export default WorkSpecificationByIssue;
