import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import JSONDeserializerFactory from "../../../util/data/serialization/JSONDeserializerFactory";
import JSONSerializerFactory from "../../../util/data/serialization/JSONSerializerFactory";
import ProposalContentDAO from "./ProposalContentDAO";
import S3ProposalContentDAO from "./S3ProposalContentDAO";
import { Storage } from "aws-amplify";

export default class S3ProposalContentDAOFactory {
    private static readonly INSTANCE: ProposalContentDAO = new S3ProposalContentDAO(
        JSONSerializerFactory.getInstance(),
        JSONDeserializerFactory.getInstance(),
        Storage,
        ClientLoggerFactory.getClientLogger("S3ProposalContentDAO")
    );

    public static getInstance(): ProposalContentDAO {
        return S3ProposalContentDAOFactory.INSTANCE;
    }
}
