import CounterAdoptingSerialNumberGeneratorFactory from "../../../serialNumber/CounterAdoptingSerialNumberGeneratorFactory";
import DataStoreWorkTypeDAOFactory from "../../dataStore/DataStoreWorkTypeDAOFactory";
import GraphQLWorkTypeGroupDAOFactory from "../../group/graphql/GraphQLWorkTypeGroupDAOFactory";
import UpdateWorkTypeHandler from "./UpdateWorkTypeHandler";
import UpdateWorkTypeHandlerImpl from "./UpdateWorkTypeHandlerImpl";

export default class UpdateWorkTypeHandlerFactory {
    private static readonly INSTANCE: UpdateWorkTypeHandler = new UpdateWorkTypeHandlerImpl(
        CounterAdoptingSerialNumberGeneratorFactory.getInstance(),
        DataStoreWorkTypeDAOFactory.getInstance(),
        GraphQLWorkTypeGroupDAOFactory.getInstance()
    );

    public static getInstance(): UpdateWorkTypeHandler {
        return this.INSTANCE;
    }
}
