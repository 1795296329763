import {
    AggregateUserPermissions,
    PermissionResourceType,
    PermissionType
} from "../../models";
import {
    Box,
    ListItem,
    ListItemProps,
    Typography
} from "@mui/material";
import {
    useMemo,
    useRef,
    useState
} from "react";

import { AssignablePermissionTemplateMapFilter } from "../../lib/permission/template/AssignablePermissionTemplateMapFilter";
import { AssignablePermissionTemplateMapFilterFactory } from "../../lib/permission/template/AssignablePermissionTemplateMapFilterFactory";
import { PermissionTemplate } from "../../lib/permission/template/PermissionTemplate";
import { PermissionTemplateSelect } from "./PermissionTemplateSelect";
import { PermissionsToPermissionTemplateConverter } from "../../lib/permission/template/PermissionsToPermissionTemplateConverter";
import { TEMPLATE_TYPE_TO_PERMISSION_MAP } from "../../lib/permission/template/PermissionTemplateConstants";

type ManageUserPermissionsListItemProps = {
    aggregateUserPermission: AggregateUserPermissions;
    disabled?: boolean;
    resourceId: string;
    resourceType: PermissionResourceType;
    isLoggedInUser?: boolean;
    loggedInUserPermissions: Array<PermissionType>;
    onRemoveUserPermissions: (userId: string) => Promise<void>;
    onUpdateUserPermissions: (userId: string, newPermissions: Array<PermissionType>) => Promise<void>;
} & ListItemProps;

export const ManageUserPermissionsListItem = (props: ManageUserPermissionsListItemProps) => {
    const { aggregateUserPermission, disabled, resourceId, resourceType, isLoggedInUser, loggedInUserPermissions, onRemoveUserPermissions, onUpdateUserPermissions, ...listItemProps } = props;
    const permissionTemplateMapFilterRef = useRef<AssignablePermissionTemplateMapFilter>(AssignablePermissionTemplateMapFilterFactory.getInstance());
    const [isHandlingChange, setIsHandlingChange] = useState<boolean>(false);

    const currentUserPermissions = useMemo<Array<PermissionType>>(() => {
        return aggregateUserPermission.permissions as Array<PermissionType>;
    }, [aggregateUserPermission]);

    const selectedPermissionTemplate = useMemo<PermissionTemplate>(() => {
        return PermissionsToPermissionTemplateConverter.convert(currentUserPermissions);
    }, [currentUserPermissions]);

    const assignablePermissionTemplateMap = useMemo<Map<PermissionTemplate, Array<PermissionType>>>(() => {
        if (TEMPLATE_TYPE_TO_PERMISSION_MAP.has(selectedPermissionTemplate)) {
            return permissionTemplateMapFilterRef.current.filter(
                TEMPLATE_TYPE_TO_PERMISSION_MAP,
                loggedInUserPermissions,
                currentUserPermissions as Array<PermissionType>
            );
        }
        const map = new Map(TEMPLATE_TYPE_TO_PERMISSION_MAP);
        map.set(selectedPermissionTemplate, currentUserPermissions as Array<PermissionType>);
        return permissionTemplateMapFilterRef.current.filter(
            map,
            loggedInUserPermissions,
            currentUserPermissions as Array<PermissionType>
        );
    }, [selectedPermissionTemplate, currentUserPermissions, loggedInUserPermissions]);

    const onSelectedPermissionTemplateChanged = async (selectedTemplate: PermissionTemplate) => {
        setIsHandlingChange(true);
        if (selectedTemplate === PermissionTemplate.NONE) {
            await onRemoveUserPermissions(aggregateUserPermission.userId!);
            return;
        }
        await onUpdateUserPermissions(aggregateUserPermission.userId!, assignablePermissionTemplateMap.get(selectedTemplate)!);
        setIsHandlingChange(false);
    };

    return (
        <ListItem {...listItemProps}>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Typography>{aggregateUserPermission.username || "Unknown User"}{isLoggedInUser ? " \(You\)" : ""}</Typography>
                <PermissionTemplateSelect
                    variant="standard"
                    displayRemoveUser={true}
                    selectedTemplate={selectedPermissionTemplate ?? ""}
                    onSelectedTemplateChange={onSelectedPermissionTemplateChanged}
                    permissionTemplateOptions={Array.from(assignablePermissionTemplateMap.keys())}
                    disabled={isHandlingChange || disabled || isLoggedInUser}
                />
            </Box>
        </ListItem>
    );
};