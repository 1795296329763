import { AuthIDTokenSupplierFactory } from "../../../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../../../logging/ClientLoggerFactory";
import { GraphQLTenderMajorVersionDAO } from "./GraphQLTenderMajorVersionDAO";
import { TenderMajorVersionDAO } from "../TenderMajorVersionDAO";

export class GraphQLTenderMajorVersionDAOFactory {
    private static INSTANCE: TenderMajorVersionDAO = new GraphQLTenderMajorVersionDAO(
        ClientLoggerFactory.getClientLogger("GraphQLTenderDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}