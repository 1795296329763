import * as yup from "yup";

import {
    Autocomplete,
    Box,
    Grid,
    InputAdornment,
    MenuItem,
    TextField
} from "@mui/material";
import {
    DimensionType,
    MeasurementUnit
} from "../../models";
import {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo
} from "react";
import {
    associatedWorkTypeDataGroupsSelector,
    getAssociateWorkTypeCallback,
    getUpdateWorkTypePricingCallback
} from "../../lib/worktype/state/WorkTypeState";

import ClientLogger from "../../lib/logging/ClientLogger";
import ClientLoggerFactory from "../../lib/logging/ClientLoggerFactory";
import DataGroup from "../../lib/util/data/group/DataGroup";
import DimensionTypeAbbreviationFactory from "../../lib/util/dimension/DimensionTypeAbbreviationFactory";
import MeasurementUnitAbbreviationFactory from "../../lib/util/dimension/MeasurementUnitAbbreviationFactory";
import WorkTypeDTO from "../../lib/worktype/DTO/WorkTypeDTO";
import { actingAsEntityIdAtom } from "../../lib/userSession/state/UserSessionState";
import { useCreateWorkType } from "../../lib/worktype/hooks/useCreateWorkType";
import { useFormik } from "formik";
import { useRecoilValue } from "recoil";

type CreateEntityWorkTypeFormProps = {
    defaultWorkTypeName: string;
    setFormik?: Dispatch<SetStateAction<ReturnType<typeof useFormik> | undefined>>;
};

const createEntityWorkTypeFormSchema = yup.object({
    name: yup.string().required(),
    shortDescription: yup.string(),
    longDescription: yup.string(),
    dimensionType: yup.mixed().oneOf([DimensionType.LINEAR, DimensionType.SQUARE]).required(),
    category: yup.string(),
    unitPrice: yup.number().required(),
    unitCost: yup.number()
});

type CreateEntityWorkTypeFormData = yup.InferType<typeof createEntityWorkTypeFormSchema>;
const logger: ClientLogger = ClientLoggerFactory.getClientLogger("CreateEntityWorkTypeForm");

export const CreateEntityWorkTypeForm = (props: CreateEntityWorkTypeFormProps) => {
    const { defaultWorkTypeName, setFormik } = props;
    const { createNewWorkType } = useCreateWorkType();
    const updateWorkTypePricing = getUpdateWorkTypePricingCallback();
    const actingAsEntityId = useRecoilValue(actingAsEntityIdAtom);
    const associateWorkType = getAssociateWorkTypeCallback();

    const associatedWorkTypeDataGroups: Array<DataGroup<string, WorkTypeDTO>> = useRecoilValue(associatedWorkTypeDataGroupsSelector);
    const categoryOptions: Array<string> = useMemo(() => {
        return associatedWorkTypeDataGroups.map(dataGroup => dataGroup.key);
    }, [associatedWorkTypeDataGroups]);

    const formik = useFormik<CreateEntityWorkTypeFormData>({
        initialValues: {
            name: defaultWorkTypeName,
            shortDescription: "",
            longDescription: "",
            dimensionType: DimensionType.LINEAR,
            category: undefined,
            unitPrice: 0,
            unitCost: undefined
        },
        validationSchema: createEntityWorkTypeFormSchema,
        onSubmit: async (data) => {
            const workType: Partial<WorkTypeDTO> = {
                categoryName: data.category,
                name: data.name,
                shortDescription: data.shortDescription,
                longDescription: data.longDescription,
                dimensionType: data.dimensionType,
                version: 0
            };
            const createdWorkType: WorkTypeDTO = await createNewWorkType(workType as WorkTypeDTO);
            await associateWorkType(actingAsEntityId, createdWorkType.groupId, false);
            await updateWorkTypePricing({
                entityId: actingAsEntityId,
                workTypeGroupId: createdWorkType.groupId,
                unitPrice: data.unitPrice || undefined,
                unitCost: data.unitCost || undefined
            });
            return createdWorkType;
        }
    });

    const unitString: string = useMemo(() => {
        const dimensionTypeString = formik.values.dimensionType === DimensionType.LINEAR ? DimensionTypeAbbreviationFactory.getAbbreviation(DimensionType.LINEAR) : DimensionTypeAbbreviationFactory.getAbbreviation(DimensionType.SQUARE);
        return `/${dimensionTypeString} ${MeasurementUnitAbbreviationFactory.getAbbreviation(MeasurementUnit.FOOT)}`;
    }, [formik.values.dimensionType]);

    useEffect(() => {
        formik.setFieldValue("name", defaultWorkTypeName);
    }, [defaultWorkTypeName]);

    useEffect(() => {
        setFormik?.(formik as any);
    }, []);

    return (
        <Box component="form" onSubmit={formik.handleSubmit}>
            <Grid container spacing={0.5}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="normal"
                        id="name"
                        label="Name"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        options={categoryOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                margin="normal"
                                id="category"
                                label="Category"
                                name="category"
                            />
                        )}
                        value={formik.values.category ?? ""}
                        onChange={(event, newValue) => {
                            formik.setFieldValue("category", newValue ?? undefined);
                        }}
                        onInputChange={(event, newInputValue) => {
                            formik.setFieldValue("category", newInputValue ?? undefined);
                        }}
                        freeSolo
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="normal"
                        id="dimensionType"
                        label="Dimension Type"
                        name="dimensionType"
                        onChange={formik.handleChange}
                        value={formik.values.dimensionType}
                        error={formik.touched.dimensionType && Boolean(formik.errors.dimensionType)}
                        select
                    >
                        <MenuItem value={DimensionType.LINEAR}>
                            {DimensionType.LINEAR}
                        </MenuItem>
                        <MenuItem value={DimensionType.SQUARE}>
                            {DimensionType.SQUARE}
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="normal"
                        id="unitPrice"
                        label="Unit Price"
                        name="unitPrice"
                        onChange={formik.handleChange}
                        value={formik.values.unitPrice}
                        error={formik.touched.unitPrice && Boolean(formik.errors.unitPrice)}
                        inputProps={{ inputMode: "decimal" }}
                        InputProps={{
                            type: "number",
                            startAdornment:
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>,
                            endAdornment:
                                <InputAdornment position="end">
                                    {unitString}
                                </InputAdornment>
                        }}
                        helperText={formik.errors.unitPrice}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="normal"
                        id="unitCost"
                        label="Unit Cost"
                        name="unitCost"
                        onChange={formik.handleChange}
                        value={formik.values.unitCost}
                        error={formik.touched.unitCost && Boolean(formik.errors.unitCost)}
                        inputProps={{ inputMode: "decimal" }}
                        InputProps={{
                            type: "number",
                            startAdornment:
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>,
                            endAdornment:
                                <InputAdornment position="end">
                                    {unitString}
                                </InputAdornment>
                        }}
                        helperText={formik.errors.unitCost}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="normal"
                        id="shortDescription"
                        label="Short Description"
                        name="shortDescription"
                        onChange={formik.handleChange}
                        value={formik.values.shortDescription}
                        error={formik.touched.shortDescription && Boolean(formik.errors.shortDescription)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        margin="normal"
                        id="longDescription"
                        label="Long Description"
                        name="longDescription"
                        onChange={formik.handleChange}
                        value={formik.values.longDescription}
                        error={formik.touched.longDescription && Boolean(formik.errors.longDescription)}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};