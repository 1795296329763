import ClientLogger from '../../../../logging/ClientLogger';
import CreateWorkTypePricingError from '../error/CreateWorkTypePricingError';
import { DataStoreClass } from '@aws-amplify/datastore';
import Deserializer from '../../../../util/data/serialization/Deserializer';
import GetWorkTypePricingByWorkTypeGroupIdAndEntityIdError from '../error/GetWorkTypePricingByWorkTypeGroupIdAndEntityIdError';
import Serializer from '../../../../util/data/serialization/Serializer';
import UpdateWorkTypePricingError from '../error/UpdateWorkTypePricingError';
import { WorkTypePricing } from '../../../../../models';
import WorkTypePricingDAO from "../WorkTypePricingDAO";
import WorkTypePricingDTO from '../DTO/WorkTypePricingDTO';
import WorkTypePricingMetricNames from '../WorkTypePricingMetricNames';
import WorkTypePricingV1 from '../WorkTypePricingV1';
import _ from 'lodash';

export default class DataStoreWorkTypePricingDAO implements WorkTypePricingDAO {
    private readonly deserializer: Deserializer<string, WorkTypePricingV1>;
    private readonly serializer: Serializer<WorkTypePricingV1, string>;
    private logger: ClientLogger;
    private dataStore: DataStoreClass;

    constructor(
        deserializer: Deserializer<string, WorkTypePricingV1>,
        serializer: Serializer<WorkTypePricingV1, string>,
        logger: ClientLogger,
        dataStore: DataStoreClass
    ) {
        this.deserializer = deserializer;
        this.serializer = serializer;
        this.logger = logger;
        this.dataStore = dataStore;
    }

    public async create(
        workTypePricingDto: WorkTypePricingDTO
    ): Promise<WorkTypePricingDTO> {
        try {
            this.logger.info(
                `Creating WorkTypePricing for workType ${workTypePricingDto.workTypeGroupId} and entity ${workTypePricingDto.entityId}`,
                undefined,
                [WorkTypePricingMetricNames.CREATE_ATTEMPT]
            );
            const optionalWorkTypePricingDto: WorkTypePricingDTO | undefined = await this.getByWorkTypeGroupIdAndEntityId(workTypePricingDto.workTypeGroupId, workTypePricingDto.entityId);
            if (optionalWorkTypePricingDto) {
                return optionalWorkTypePricingDto;
            }
            const workTypeBody: WorkTypePricingV1 = {
                unitPrice: workTypePricingDto.unitPrice,
                unitCost: workTypePricingDto.unitCost
            }
            const workTypePricingToCreate: WorkTypePricing = new WorkTypePricing({
                entityId: workTypePricingDto.entityId,
                workTypeGroupId: workTypePricingDto.workTypeGroupId,
                body: this.serializer.serialize(workTypeBody),
            })
            const result: WorkTypePricing = await this.dataStore.save(workTypePricingToCreate);
            const resultWorkTypePricingV1: WorkTypePricingV1 = this.deserializer.deserialize(result.body);
            return {
                id: result.id,
                 workTypeGroupId: result.workTypeGroupId,
                 entityId: result.entityId,
                unitPrice: resultWorkTypePricingV1.unitPrice,
                unitCost: resultWorkTypePricingV1.unitCost
            } as WorkTypePricingDTO;
        } catch (error) {
            const errorMessage: string = `Unable to Create WorkTypePricing for workTypeGroup: ${workTypePricingDto.workTypeGroupId} and entity ${workTypePricingDto.entityId}`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypePricingMetricNames.CREATE_FAILURE]
            );
            throw new CreateWorkTypePricingError('Method not implemented.');
        }
    }

    public async getByWorkTypeGroupIdAndEntityId(
        workTypeGroupId: string,
        entityId: string
    ): Promise<WorkTypePricingDTO | undefined> {
        try {
            this.logger.info(
                `Getting WorkTypePricing of workType ${workTypeGroupId} and entity ${entityId}`,
                undefined,
                [WorkTypePricingMetricNames.GET_BY_WORK_TYPE_GROUP_ID_AND_ENTITY_ID_ATTEMPT]
            );
            const optionalWorkTypePricing: WorkTypePricing | undefined = await this.getWorkTypePricing(workTypeGroupId, entityId);
            if (!optionalWorkTypePricing) {
                return undefined;
            }
            const workTypePricingV1: WorkTypePricingV1 = this.deserializer.deserialize(optionalWorkTypePricing.body);
            return {
                id: optionalWorkTypePricing.id,
                workTypeGroupId: optionalWorkTypePricing.workTypeGroupId,
                entityId: optionalWorkTypePricing.entityId,
                unitPrice: workTypePricingV1.unitPrice,
                unitCost: workTypePricingV1.unitCost
            } as WorkTypePricingDTO;
        } catch (error) {
            const errorMessage: string = `Unable to get WorkTypePricing of workType ${workTypeGroupId} and entity ${entityId}`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypePricingMetricNames.GET_BY_WORK_TYPE_GROUP_ID_AND_ENTITY_ID_FAILURE]
            );
            throw new GetWorkTypePricingByWorkTypeGroupIdAndEntityIdError(errorMessage, error as Error);
        }
    }

    public async update(
        workTypeGroupId: string, 
        entityId: string,
        workTypePricingDto: WorkTypePricingDTO
    ): Promise<WorkTypePricingDTO> {
        try {
            this.logger.info(
                `Updating WorkTypePricing of workType ${workTypeGroupId} and entity ${entityId}`,
                undefined,
                [WorkTypePricingMetricNames.UPDATE_ATTEMPT]
            );
            const optionalWorkTypePricing: WorkTypePricing | undefined = await this.getWorkTypePricing(workTypeGroupId, entityId);
            if (!optionalWorkTypePricing) {
                throw new Error("WorkTypePricing not found");
            }
            const workTypePricingV1ToUpdate: WorkTypePricingV1 = {
                unitPrice: workTypePricingDto.unitPrice,
                unitCost: workTypePricingDto.unitCost
            }
            const workTypePricingToUpdate: WorkTypePricing = WorkTypePricing.copyOf(optionalWorkTypePricing, workTypePricingToUpdate => {
                workTypePricingToUpdate.body = this.serializer.serialize(workTypePricingV1ToUpdate);
            });
            let result: WorkTypePricing = optionalWorkTypePricing;
            if (!_.isEqual(optionalWorkTypePricing, workTypePricingToUpdate)) {
                result = await this.dataStore.save(workTypePricingToUpdate);
            }
            const resultWorkTypePricingV1: WorkTypePricingV1 = this.deserializer.deserialize(result.body);
            return {
                id: result.id,
                workTypeGroupId: result.workTypeGroupId,
                entityId: result.entityId,
                unitPrice: resultWorkTypePricingV1.unitPrice,
                unitCost: resultWorkTypePricingV1.unitCost
            } as WorkTypePricingDTO;
        } catch (error) {
            const errorMessage: string = `Unable to update WorkTypePricing of workType ${workTypeGroupId} and entity ${entityId}`;
            this.logger.error(
                errorMessage,
                error,
                [WorkTypePricingMetricNames.UPDATE_FAILURE]
            );
            throw new UpdateWorkTypePricingError(errorMessage, error as Error);
        }
    }

    private async getWorkTypePricing(
        workTypeGroupId: string,
        entityId: string
    ): Promise<WorkTypePricing | undefined> {
        const workTypePricingItems: Array<WorkTypePricing> = await this.dataStore.query(
            WorkTypePricing,
            wtp => wtp.entityId("eq", entityId).workTypeGroupId("eq", workTypeGroupId)
        );
        if (workTypePricingItems.length === 0) {
            return undefined;
        }
        return workTypePricingItems[0];
    }
}

