import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef
} from "react";

import { EventObserver } from "amazon-chime-sdk-js";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";

type AudioInjectionContextType = {
    playSound: (element: HTMLAudioElement) => void;
};

const AudioInjectionContext = createContext<AudioInjectionContextType | null>(null);

export const AudioInjectionProvider = ({ children }: { children: ReactNode; }) => {
    const meetingManager = useMeetingManager();
    const audioContextRef = useRef<AudioContext | null>(null);
    const destinationRef = useRef<MediaStreamAudioDestinationNode | null>(null);

    useEffect(() => {
        const init = () => {
            const audioContext = new AudioContext();
            audioContextRef.current = audioContext;
            const destination = audioContext.createMediaStreamDestination();
            destinationRef.current = destination;
        }
        const eventHandler: EventObserver["eventDidReceive"] = async (name, attributes) => {
            switch (name) {
                case "meetingStartRequested":
                    init();
                    const stream = destinationRef.current!.stream;
                    meetingManager.audioVideo?.startContentShare(stream);
                    break;
                case "meetingEnded":
                    meetingManager.audioVideo?.stopContentShare();
                    break;
            }
        };
        meetingManager.subscribeToEventDidReceive(eventHandler);
        return () => {
            meetingManager.audioVideo?.stopContentShare();
            meetingManager.unsubscribeFromEventDidReceive(eventHandler);
        };
    }, []);

    const addTrackToStream = useCallback((audioElement: HTMLAudioElement) => {
        if (!audioContextRef.current || !destinationRef.current) {
            return;
        }
        const audioNode = audioContextRef.current.createMediaElementSource(audioElement);
        audioNode.connect(destinationRef.current);
        /** 
         * Uncomment the following line to play the audio on the local device 
         * audioNode.connect(audioContextRef.current.destination);
         */
    }, []);

    const playSound = useCallback((element: HTMLAudioElement) => {
        addTrackToStream(element);
        element.play();
    }, []);

    return (
        <AudioInjectionContext.Provider value={{ playSound }}>
            {children}
        </AudioInjectionContext.Provider>
    );
};

export const useAudioInjection = () => {
    const context = useContext(AudioInjectionContext);
    if (!context) {
        throw new Error("useAudioInjection must be used within a AudioInjectionProvider");
    }
    return context;
};