import { ExternalReportBranding } from "../../../../../models";
import { GraphQLExternalReportBrandingDAOFactory } from "../../../../../lib/branding/GraphQLExternalReportBrandingDAOFactory";
import { useRecoilValue } from "recoil";
import { useSnackbar } from "notistack";
import { userIdAtom } from "../../../../../lib/userSession/state/UserSessionState";

export const useExternalReportBrandingDAO = () => {
    const brandingDAO = GraphQLExternalReportBrandingDAOFactory.getInstance();
    const signedInUserId = useRecoilValue<string | undefined>(userIdAtom);
    const snackbar = useSnackbar();

    const createBranding = async (branding: ExternalReportBranding) => {
        try {
            if (!signedInUserId) {
                throw new Error('User is not signed in.')
            }
            await brandingDAO.create({
                ...branding,
                createdBy: signedInUserId
            });
            snackbar.enqueueSnackbar('Branding created successfully.', { variant: 'success' });
            return true;
        } catch (error) {
            snackbar.enqueueSnackbar(`Failed to create branding: ${error}`, { variant: "error", });
            return false;
        }
    }

    const getBranding = async (id: string) => {
        try {
            return await brandingDAO.get(id);
        } catch (error) {
            snackbar.enqueueSnackbar(`Failed to get branding: ${error}`, { variant: "error", });
            return null;
        }
    }

    const listBrandings = async (
        createdBy: string,
        limit?: number,
        nextToken?: string
    ): Promise<Array<ExternalReportBranding>> => {
        try {
            return await brandingDAO.listByCreatedBy(createdBy, limit, nextToken);
        } catch (error) {
            snackbar.enqueueSnackbar(`Failed to list brandings: ${error}`, { variant: "error", });
            return [];
        }
    }

    return {
        createBranding,
        getBranding,
        listBrandings,
    };
}
