import {
    IconButton,
    Tooltip
} from "@mui/material";

import { MouseEventHandler } from "react";
import globalStyle from "../../../assets/style/globalStyle";

type ImageCarouselButtonProps = {
    readonly icon: React.ReactNode;
    readonly tooltip: string;
    readonly onClick?: MouseEventHandler<HTMLLabelElement>;
    readonly disabled?: boolean;
};

const ImageCarouselButton = (props: ImageCarouselButtonProps) => {
    const { icon, tooltip, onClick, disabled } = props;
    const globalClasses = globalStyle();

    return (
        <Tooltip title={tooltip}>
            <IconButton
                onClick={onClick}
                component="label"
                className={globalClasses.imageCarouselButton}
                size="small"
                disabled={disabled}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
};

export default ImageCarouselButton;
