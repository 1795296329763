import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const LocationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <circle cx={12} cy={8} r={3} fill="none" />
    <path
      fill="none"
      d="M12 1C8.14 1 5 4.365 5 8.5c0 1.35.758 3.482 2.254 6.336.4.766.871 1.612 1.442 2.59a93.064 93.064 0 0 0 2.579 4.09c.259.395.501.751.725 1.076.227-.331.467-.683.72-1.07a92.94 92.94 0 0 0 2.587-4.102 61.755 61.755 0 0 0 1.438-2.583C18.242 11.982 19 9.85 19 8.5 19 4.365 15.86 1 12 1zm0 11c-2.21 0-4-1.783-4-4 0-2.206 1.79-4 4-4s4 1.794 4 4c0 2.217-1.79 4-4 4z"
    />
    <path
      fill="currentColor"
      d="M12 0C7.58 0 4 3.81 4 8.5c0 1.59.849 3.9 2.368 6.8.438.84.932 1.72 1.464 2.63.83 1.4 1.725 2.81 2.611 4.14.308.47.597.89.858 1.27.158.22.27.38.326.47.093.13.224.19.373.19s.28-.06.373-.19c.056-.09.168-.25.326-.47.261-.38.55-.8.858-1.27a94.287 94.287 0 0 0 2.611-4.14c.531-.91 1.026-1.79 1.464-2.63C19.151 12.4 20 10.09 20 8.5 20 3.81 16.42 0 12 0zm4.745 14.837c-.399.764-.87 1.611-1.438 2.583a92.94 92.94 0 0 1-2.587 4.102c-.253.386-.493.738-.72 1.07a58.766 58.766 0 0 1-.725-1.076 92.133 92.133 0 0 1-2.579-4.09 62.358 62.358 0 0 1-1.442-2.59C5.758 11.982 5 9.85 5 8.5 5 4.365 8.14 1 12 1s7 3.365 7 7.5c0 1.35-.758 3.482-2.255 6.337z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M12 4C9.79 4 8 5.794 8 8c0 2.217 1.79 4 4 4s4-1.783 4-4c0-2.206-1.79-4-4-4zm0 7c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(LocationIcon);
