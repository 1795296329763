import {
    Autocomplete,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {
    DefaultWorkTypePricing,
    DimensionType,
    MeasurementUnit
} from "../../../models";
import {
    TYPOGRAPHY_ATTRIBUTE_LABEL,
    TYPOGRAPHY_ATTRIBUTE_VALUE
} from "../../../components/general/typography/TypographyStyles";
import {
    allWorkTypeDataGroupsSelector,
    workTypeDisplayModeAtomFamily,
    workTypeGroupByIdAtomFamily
} from "../../../lib/worktype/state/WorkTypeState";
import {
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    useRecoilValue,
    useSetRecoilState
} from "recoil";

import CancelIcon from '@mui/icons-material/Cancel';
import DataGroup from "../../../lib/util/data/group/DataGroup";
import DefaultWorkTypePricingDAO from "../../../lib/worktype/defaultPricing/DefaultWorkTypePricingDAO";
import DimensionTypeAbbreviationFactory from "../../../lib/util/dimension/DimensionTypeAbbreviationFactory";
import GraphQLDefaultWorkTypePricingDAOFactory from "../../../lib/worktype/defaultPricing/graphql/GraphQLDefaultWorkTypePricingDAOFactory";
import MeasurementUnitAbbreviationFactory from "../../../lib/util/dimension/MeasurementUnitAbbreviationFactory";
import NumberStringConverter from "../../../lib/util/NumberStringConverter";
import { PriceNumberMask } from "../../../components/design/document/element/wss/PriceNumberMask";
import SaveIcon from '@mui/icons-material/Save';
import WorkTypeDTO from "../../../lib/worktype/DTO/WorkTypeDTO";
import { WorkTypeDisplayMode } from "../../../lib/worktype/state/WorkTypeDisplayMode";
import { WorkTypeGroup } from "../../../models";
import globalStyle from "../../../assets/style/globalStyle";

type WorkTypeManagementFormProps = {
    readonly workType: Partial<WorkTypeDTO>;
    readonly onFormSave: (workTypeForm: WorkTypeDTO, unitCost: number, unitPrice: number, isDiscoverable: boolean) => Promise<void>;
    readonly onFormCancel: () => void;
};

const WorkTypeManagementForm = (props: WorkTypeManagementFormProps) => {
    /* Internal page states */
    const { workType, onFormSave, onFormCancel } = props;
    const [workTypeForm, setWorkTypeForm] = useState<Partial<WorkTypeDTO>>(workType);
    const [defaultUnitCost, setDefaultUnitCost] = useState<number>(0);
    const [defaultUnitPrice, setDefaultUnitPrice] = useState<number>(0);
    const workTypeGroup = useRecoilValue<WorkTypeGroup | null>(workTypeGroupByIdAtomFamily(workType.groupId ?? ""));
    const [isWorkTypeGroupDiscoverable, isSetWorkTypeGroupDiscoverable] = useState<boolean>(!!workTypeGroup?.isDiscoverable);
    const setDisplayMode = useSetRecoilState<WorkTypeDisplayMode>(workTypeDisplayModeAtomFamily(workType.groupId ?? ""));
    const unitString: string = useMemo(() => {
        const dimensionTypeString = workTypeForm?.dimensionType === DimensionType.LINEAR ?
            DimensionTypeAbbreviationFactory.getAbbreviation(DimensionType.LINEAR) :
            DimensionTypeAbbreviationFactory.getAbbreviation(DimensionType.SQUARE);
        return `/${dimensionTypeString} ${MeasurementUnitAbbreviationFactory.getAbbreviation(MeasurementUnit.FOOT)}`;
    }, [workTypeForm?.dimensionType]);

    const allWorkTypeDataGroups: Array<DataGroup<string, WorkTypeDTO>> = useRecoilValue(allWorkTypeDataGroupsSelector);
    const categoryOptions: Array<string> = useMemo(() => {
        return allWorkTypeDataGroups.map(dataGroup => dataGroup.key);
    }, [allWorkTypeDataGroups]);

    /* Dependencies */
    const defaultWorkTypePricingDAORef = useRef<DefaultWorkTypePricingDAO>(GraphQLDefaultWorkTypePricingDAOFactory.getInstance());
    const globalClasses = globalStyle();

    useEffect(() => {
        const setDefaultPricing = async () => {
            if (workType.groupId) {
                const optionalDefaultPricing: DefaultWorkTypePricing | undefined = await defaultWorkTypePricingDAORef.current.getById(workType.groupId);
                setDefaultUnitPrice(optionalDefaultPricing?.unitPrice ?? 0);
                setDefaultUnitCost(optionalDefaultPricing?.unitCost ?? 0);
            }
        };
        setDefaultPricing();
    }, [workType]);

    const onFieldChange = (key: keyof WorkTypeDTO, value: string): void => {
        const updatedWorkType: Partial<WorkTypeDTO> = {
            ...workTypeForm,
            [key]: value
        };
        setWorkTypeForm(updatedWorkType);
    };

    const onPriceChange = (
        name: keyof DefaultWorkTypePricing,
        value: string
    ) => {
        const valueWithoutFormatting: string = value.replace("$", "").replaceAll(",", "");
        const price: number = NumberStringConverter.stringToNumber(valueWithoutFormatting) as number;
        if (name === "unitCost") {
            setDefaultUnitCost(price);
        }
        if (name === "unitPrice") {
            setDefaultUnitPrice(price);
        }
    };

    const onSave = async () => {
        if (workTypeForm.name && workTypeForm.dimensionType) {
            await onFormSave(workTypeForm as WorkTypeDTO, defaultUnitCost, defaultUnitPrice, isWorkTypeGroupDiscoverable);
            setDisplayMode(WorkTypeDisplayMode.VIEW);
        }
    };

    useEffect(() => {
        setWorkTypeForm(workType);
    }, [workType]);

    return (
        <Grid
            container
            wrap="nowrap"
            alignItems="flex-start"
            direction="row"
            justifyContent="space-between"
        >
            <Grid
                item
                container
                spacing={1}
                p={2}
            >
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        label="Name"
                        fullWidth
                        required
                        value={workTypeForm.name}
                        error={!workTypeForm.name}
                        onChange={(e) => onFieldChange("name", e.target.value)}
                    />
                </Grid>
                <Grid item sm={6} />
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        label="Dimension Type"
                        fullWidth
                        required
                        select
                        value={workTypeForm.dimensionType}
                        error={!workTypeForm.dimensionType}
                        onChange={(e) => onFieldChange("dimensionType", e.target.value)}
                    >
                        <MenuItem key={DimensionType.LINEAR} value={DimensionType.LINEAR}>
                            {DimensionType.LINEAR}
                        </MenuItem>
                        <MenuItem key={DimensionType.SQUARE} value={DimensionType.SQUARE}>
                            {DimensionType.SQUARE}
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        options={categoryOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Category"
                                fullWidth
                            />
                        )}
                        value={workTypeForm.categoryName ?? ""}
                        onChange={(event, newValue) => {
                            onFieldChange("categoryName", newValue || "");
                        }}
                        onInputChange={(event, newInputValue) => {
                            onFieldChange("categoryName", newInputValue || "");
                        }}
                        freeSolo
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        label="Default Unit Price"
                        fullWidth
                        value={defaultUnitPrice}
                        onChange={(e) => onPriceChange("unitPrice", e.target.value)}
                        inputProps={{ inputMode: "decimal" }}
                        InputProps={{
                            inputComponent: PriceNumberMask,
                            endAdornment:
                                <InputAdornment position="end">
                                    {unitString}
                                </InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        label="Default Unit Cost"
                        fullWidth
                        value={defaultUnitCost}
                        onChange={(e) => onPriceChange("unitCost", e.target.value)}
                        inputProps={{ inputMode: "decimal" }}
                        InputProps={{
                            inputComponent: PriceNumberMask,
                            endAdornment:
                                <InputAdornment position="end">
                                    {unitString}
                                </InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        label="Short Description"
                        fullWidth
                        value={workTypeForm.shortDescription}
                        onChange={(e) => onFieldChange("shortDescription", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        label="Long Description"
                        fullWidth
                        value={workTypeForm.longDescription}
                        onChange={(e) => onFieldChange("longDescription", e.target.value)}
                    />
                </Grid>
                <Grid item container xs={12}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Typography sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>Discoverable</Typography>
                        <Switch
                            onChange={() => isSetWorkTypeGroupDiscoverable(origin => !origin)}
                            checked={isWorkTypeGroupDiscoverable}
                            color="primary"
                        />
                    </Stack>
                    <Grid item xs={12}>
                        <Typography component="span" sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                            This Work Type
                        </Typography>
                        <Typography component="span" sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>
                            &nbsp;is {isWorkTypeGroupDiscoverable ? "" : "not"} discoverable
                        </Typography>
                        <Typography component="span" sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                            &nbsp;for all users
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item alignItems="center">
                <Stack
                    minWidth="100%"
                    spacing={1}
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="flex-end"
                >
                    <IconButton
                        className={globalClasses.squareIconButton}
                        onClick={onSave}
                        size="small"
                    >
                        <SaveIcon />
                    </IconButton>
                    <IconButton
                        className={globalClasses.squareIconButton}
                        size="small"
                        onClick={onFormCancel}
                    >
                        <CancelIcon />
                    </IconButton>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default WorkTypeManagementForm;
