import AuthUsernameSupplierFactory from "../../auth/AuthUsernameSupplierFactory";
import { IngressCompletionCheckerMapFactory } from "../ingress/IngressCompletionCheckerMapFactory";
import { ResourcePermissionProviderImpl } from "./ResourcePermissionProviderImpl";
import { ResourceTypeToEntityResourceDAOMapFactory } from "./ResourceTypeToEntityResourceDAOMapFactory";

export class ResourcePermissionProviderFactory {
    private static INSTANCE = new ResourcePermissionProviderImpl(
        AuthUsernameSupplierFactory.getInstance(),
        ResourceTypeToEntityResourceDAOMapFactory.getInstance(),
        IngressCompletionCheckerMapFactory.getInstance()
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}