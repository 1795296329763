import {
    Attendee,
    AttendeeRole
} from "../../../models";
import {
    defaultAudioEnabledAtom,
    defaultAudioInputDeviceAtom,
    defaultAudioOutputDeviceIdAtom,
    defaultVideoEnabledAtom,
    defaultVideoInputDeviceAtom,
    meetingIdAtom
} from "../state/ChimeStates";
import {
    useLocalVideo,
    useMeetingManager
} from "amazon-chime-sdk-component-library-react";
import {
    useRecoilCallback,
    useRecoilValue
} from "recoil";

import { graphQLAttendeeDAO } from "../../../lib/meeting/attendee/dao/GraphQLAttendeeDAO";
import { useRef } from "react";

export const useOnMeetingStartRequested = () => {
    const meetingManager = useMeetingManager();
    const { isVideoEnabled, toggleVideo } = useLocalVideo();

    const defaultVideoInputDevice = useRecoilValue(defaultVideoInputDeviceAtom);
    const defaultVideoInputDeviceRef = useRef(defaultVideoInputDevice);
    defaultVideoInputDeviceRef.current = defaultVideoInputDevice;

    const defaultAudioInputDevice = useRecoilValue(defaultAudioInputDeviceAtom);
    const defaultAudioInputDeviceRef = useRef(defaultAudioInputDevice);
    defaultAudioInputDeviceRef.current = defaultAudioInputDevice;

    const defaultAudioOutputDeviceId = useRecoilValue(defaultAudioOutputDeviceIdAtom);
    const defaultAudioOutputDeviceIdRef = useRef(defaultAudioOutputDeviceId);
    defaultAudioOutputDeviceIdRef.current = defaultAudioOutputDeviceId;

    const isVideoEnabledRef = useRef(isVideoEnabled);
    isVideoEnabledRef.current = isVideoEnabled;

    const toggleVideoRef = useRef(toggleVideo);
    toggleVideoRef.current = toggleVideo;

    const onMeetingStartRequested = useRecoilCallback(({ set, snapshot }) => async () => {
        // Persist meetingId in URL
        if (meetingManager.meetingSession?.configuration.meetingId) {
            set(meetingIdAtom, meetingManager.meetingSession.configuration.meetingId);
        }
        // Update attendee name retrieval function
        meetingManager.getAttendee = async (chimeAttendeeId) => {
            const userAttendeeId = meetingManager?.meetingSession?.configuration.credentials?.attendeeId;
            const attendee: Attendee = await graphQLAttendeeDAO.getById(chimeAttendeeId);
            if (userAttendeeId === chimeAttendeeId) {
                return {
                    name: attendee.name ? `${attendee.name} (Me)` : 'Me'
                };
            }
            const role: AttendeeRole | undefined = attendee.role ? AttendeeRole[attendee.role] : undefined;
            return {
                name: `${attendee.name ?? "Unknown"} ${role ? ` (${role})` : ''}`
            };
        };

        // Set the video input quality
        meetingManager.audioVideo?.chooseVideoInputQuality(1280, 720, 15);

        // Set the default video input device
        if (defaultVideoInputDeviceRef.current && meetingManager.videoInputDevices?.some((device) => device.deviceId === defaultVideoInputDeviceRef.current)) {
            meetingManager.selectVideoInputDevice(defaultVideoInputDeviceRef.current);
        }
        if (defaultAudioInputDeviceRef.current && meetingManager.audioInputDevices?.some((device) => device.deviceId === defaultAudioInputDeviceRef.current)) {
            meetingManager.startAudioInputDevice(defaultAudioInputDeviceRef.current);
        }
        if (defaultAudioOutputDeviceIdRef.current && meetingManager.audioOutputDevices?.some((device) => device.deviceId === defaultAudioOutputDeviceIdRef.current)) {
            meetingManager.startAudioOutputDevice(defaultAudioOutputDeviceIdRef.current);
        }

        // Mute audio and video if necessary
        const enableAudio = await snapshot.getPromise(defaultAudioEnabledAtom);
        const enableVideo = await snapshot.getPromise(defaultVideoEnabledAtom);
        if (!enableAudio) {
            meetingManager.audioVideo?.realtimeMuteLocalAudio();
        }
        if (!isVideoEnabledRef.current && enableVideo) {
            toggleVideoRef.current();
        }
    }, []);

    return { onMeetingStartRequested };
};