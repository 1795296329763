import {
    Box,
    IconButton,
    Typography
} from "@mui/material";
import {
    MouseEventHandler,
    forwardRef
} from "react";

import { CustomIconProps } from "../../../components/icons/withCustomIcon";

type ImageCarouselOptionProps = {
    readonly IconComponent: React.ComponentType<CustomIconProps>;
    readonly iconProps?: CustomIconProps;
    readonly tooltip: string;
    readonly onClick?: MouseEventHandler<HTMLButtonElement>;
    readonly disabled?: boolean;
};

const ImageCarouselOption = forwardRef((props: ImageCarouselOptionProps, ref) => {
    const { IconComponent, iconProps, tooltip, onClick, disabled } = props;

    return (
        <Box
            ref={ref}
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="background.paper"
            borderRight="0.5px solid lightgray"
            borderBottom="0.5px solid lightgray"
        >
            <Box
                maxWidth="100%"
                maxHeight="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ aspectRatio: "1/1" }}
            >
                <IconButton
                    onClick={onClick}
                    disabled={disabled}
                    sx={{
                        width: "60%",
                        height: "60%",

                    }}
                >
                    <IconComponent
                        {...iconProps}
                        sx={{
                            width: "100%",
                            height: "100%",
                            ...iconProps?.sx
                        }}
                    />
                </IconButton>
                <Typography
                    variant="body2"
                    color="gray"
                    sx={{textAlign: "center"}}
                >
                    {tooltip}
                </Typography>
            </Box>
        </Box>
    );
});

export default ImageCarouselOption;
