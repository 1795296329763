import { GraphQLManageResourcePermissionClientFactory } from "../GraphQLManageResourcePermissionClientFactory";
import { PermissionChangeHandler } from "./PermissionChangeHandler";
import { PermissionChangeHandlerImpl } from "./PermissionChangeHandlerImpl";
import { PermissionDifferenceCalculatorFactory } from "./PermissionDifferenceCalculatorFactory";
import { RevokeResourcePermissionRequestBuilderFactory } from "./RevokeResourcePermissionRequestBuilderFactory";
import { ShareResourcePermissionRequestBuilderFactory } from "./ShareResourcePermissionRequestBuilderFactory";

export class PermissionChangeHandlerFactory {
    private static readonly INSTANCE: PermissionChangeHandler = new PermissionChangeHandlerImpl(
        PermissionDifferenceCalculatorFactory.getInstance(),
        GraphQLManageResourcePermissionClientFactory.getInstance(),
        RevokeResourcePermissionRequestBuilderFactory.getInstance(),
        ShareResourcePermissionRequestBuilderFactory.getInstance()
    );
    public static getInstance(): PermissionChangeHandler {
        return this.INSTANCE;
    }
}