import { Box, Typography } from "@mui/material";
import {
    priceAfterAdjustmentByProposalItemIdentifierSelectorFamily,
    priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily,
    proposalItemFeatureConfigurationByContextAtomFamily
} from "../../lib/design/bidding/state/v2/ProposalItemStates";

import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { ModelType } from "../../lib/design/document/ModelType";
import NumberStringConverter from "../../lib/util/NumberStringConverter";
import { ProposalItemFeatureConfiguration } from "../../lib/design/document/state/ProposalItemFeatureConfiguration";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";

type ProposalItemPriceSummaryProps = {
    proposalItemId: string;
    context: StateContext;
};

const ProposalItemPriceSummary = ({ proposalItemId, context }: ProposalItemPriceSummaryProps) => {
    const proposalItemIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(proposalItemId, context, ModelType.PROPOSAL_ITEM);
    }, [proposalItemId, context]);
    const priceAfterAdjustment = useRecoilValue<number | undefined>(priceAfterAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    const priceBeforeAdjustment = useRecoilValue<number | undefined>(priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    const proposalItemFeatureConfiguration = useRecoilValue<ProposalItemFeatureConfiguration>(proposalItemFeatureConfigurationByContextAtomFamily(context));
    const price = useMemo<number | undefined>(() => {
        return proposalItemFeatureConfiguration.calculateAdjustedPrice ? priceAfterAdjustment : priceBeforeAdjustment;
    }, [proposalItemFeatureConfiguration.calculateAdjustedPrice, priceAfterAdjustment, priceBeforeAdjustment]);

    return (
        <Box display="flex">
            <Typography component="span" fontWeight="500">
                Total:&nbsp;
            </Typography>
            <Typography component="span" fontWeight="500">
                {price == null ? "N/A" : `$${NumberStringConverter.numberToString(price, 0, true)}`}
            </Typography>
        </Box>
    );
};
export default ProposalItemPriceSummary;
