import { DimensionType } from "../../../models";
import DimensionUnitConverter from "./DimensionUnitConverter";
import { MeasurementUnit } from "../../../models/index";
import _ from "lodash";
export default class UnitPriceConverter {

    public static convertUnitPrice(
        dimensionType: DimensionType,
        sourceUnit: MeasurementUnit,
        targetUnit: MeasurementUnit,
        sourceUnitPrice: number
    ): number {
        const factor: number = DimensionUnitConverter.convertUnitMeasurement(
            dimensionType,
            sourceUnit,
            targetUnit,
            1
        );
        return _.round(sourceUnitPrice / factor, 12);
    }
}