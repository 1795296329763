import {
    ListItemIcon,
    ListItemText,
    MenuItem
} from "@mui/material";
import {
    PermissionResourceType,
    PermissionType
} from "../../../models";
import {
    getRemoveLocationCallback,
    locationFeatureConfigurationByContextAtomFamily
} from "../../../lib/design/document/state/DocumentState";
import {
    useMemo,
    useState
} from "react";

import ConfirmationPrompt from "../../../components/general/ConfirmationPrompt";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DropDownMenu from "../../../components/menu/DropDownMenu";
import { LocationFeatureConfiguration } from "../../../lib/design/document/state/LocationFeatureConfiguration";
import { ManageResourcePermissionDialog } from "../../permission/ManageResourcePermissionDialog";
import { PermissionRestrictedControl } from "../../../components/general/button/PermissionRestrictedControl";
import { ShareIcon } from "../../../components/icons";
import { SolutionAuthoringEnabledPermissionRestrictedControl } from "../../../components/general/button/SolutionAuthoringEnabledPermissionRestrictedControl";
import { StateContext } from "../../../lib/design/document/state/StateContext";
import globalStyle from "../../../assets/style/globalStyle";
import { useRecoilValue } from "recoil";

export interface ViewLocationMenuProps {
    readonly locationId: string;
    readonly stateContext: StateContext;
    readonly size?: "small" | "medium";
}

const ViewLocationMenu = (props: ViewLocationMenuProps) => {
    /* Internal page states */
    const { locationId, stateContext, size } = props;
    const globalClasses = globalStyle();
    /* Dependencies */
    const removeLocation = getRemoveLocationCallback();
    const locationFeatureConfiguration = useRecoilValue<LocationFeatureConfiguration>(
        locationFeatureConfigurationByContextAtomFamily(stateContext)
    );
    const [isManagePermissionDialogOpen, setIsManagePermissionDialogOpen] = useState<boolean>(false);
    const [isDeletePromptOpen, setIsDeletePromptOpen] = useState<boolean>(false);

    const menuItems = useMemo(() => {
        const result = [
            <SolutionAuthoringEnabledPermissionRestrictedControl
                requiredPermission={PermissionType.DELETE}
                resourceId={locationId}
                context={stateContext}
            >
                {(isDisabled) => (
                    <MenuItem
                        onClick={() => {
                            setIsDeletePromptOpen(true);
                        }}
                        sx={{ color: "error.main" }}
                        disabled={isDisabled}
                    >
                        <ListItemIcon sx={{ color: "error.main" }}>
                            <DeleteOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Delete
                        </ListItemText>
                    </MenuItem>
                )}
            </SolutionAuthoringEnabledPermissionRestrictedControl>
        ];
        if (locationFeatureConfiguration.allowShare) {
            result.unshift(
                <PermissionRestrictedControl
                    resourceId={locationId}
                    requiredPermission={PermissionType.SHARE_PERMISSION}
                >
                    {(isDisabled) => (
                        <MenuItem
                            onClick={() => setIsManagePermissionDialogOpen(true)}
                            disabled={isDisabled}
                        >
                            <ListItemIcon>
                                <ShareIcon accent={isDisabled ? "disabled" : "primary"} />
                            </ListItemIcon>
                            <ListItemText>
                                Share
                            </ListItemText>
                        </MenuItem>
                    )}
                </PermissionRestrictedControl>
            );
        }
        return result;
    }, [locationId, locationFeatureConfiguration, stateContext]);

    return (
        <>
            <DropDownMenu
                menuIconSize={size}
                menuItems={menuItems}
                menuIconVariant={globalClasses.outlinedMenuButton}
            />
            <ConfirmationPrompt
                isVisible={isDeletePromptOpen}
                promptTitle="Delete Group"
                promptMessage="Are you sure you want to Delete this Group?"
                onCancelButtonClicked={() => {
                    setIsDeletePromptOpen(false);
                }}
                onConfirmButtonClicked={() => {
                    removeLocation(locationId, stateContext);
                }}
            />
            {locationFeatureConfiguration.allowShare && (
                <ManageResourcePermissionDialog
                    onClose={() => setIsManagePermissionDialogOpen(false)}
                    open={isManagePermissionDialogOpen}
                    resourceId={locationId}
                    resourceType={PermissionResourceType.LOCATION}
                />
            )}
        </>
    );
};

export default ViewLocationMenu;
