import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    LinearProgress,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {
    dimensionFeatureConfigurationByContextAtomFamily,
    getCleanUpPropertyStates,
    getFetchLocationCallback,
    issueFeatureConfigurationByContextAtomFamily,
    locationFeatureConfigurationByContextAtomFamily,
    workSpecificationFeatureConfigurationByContextAtomFamily
} from "../../lib/design/document/state/DocumentState";
import {
    useEffect,
    useRef,
    useState
} from "react";
import {
    useRecoilState,
    useRecoilValue,
    useSetRecoilState
} from "recoil";

import { CheckBoxValue } from "../../lib/ui/checkbox/CheckBoxValue";
import { ChildrenExpandState } from "../../lib/design/document/state/enum/ChildrenExpandState";
import { ComponentModificationMode } from "../../lib/design/document/state/ComponentModificationMode";
import DataStoreLocationDAOFactory from "../../lib/location/dao/datastore/DataStoreLocationDAOFactory";
import { DimensionFeatureConfiguration } from "../../lib/design/document/state/DimensionFeatureConfiguration";
import { IssueFeatureConfiguration } from "../../lib/design/document/state/IssueFeatureConfiguration";
import IssueSettings from "../../componentsV2/issue/filter/IssueSettings";
import { ListLocations } from "../../componentsV2/location/ListLocations";
import { Location } from "../../models";
import LocationDAO from "../../lib/location/dao/LocationDAO";
import { LocationFeatureConfiguration } from "../../lib/design/document/state/LocationFeatureConfiguration";
import { SelectionBehavior } from "../../lib/ui/SelectionBehavior";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { WorkSpecificationFeatureConfiguration } from "../../lib/design/document/state/WorkSpecificationFeatureConfiguration";
import { getResetIssueFilter } from "../../lib/issue/state/IssueFilterRecoilState";
import grey from "@mui/material/colors/grey";
import { isSelectionModeAtomFamily } from "../../lib/design/document/state/SelectionModeState";
import { propertyIdInFocusAtom } from "../../lib/ui/InFocusRecoilStates";
import { selectAllCheckboxByContextSelectorFamily } from "../../lib/design/document/state/IssuePickerState";
import { useSetChildrenExpandState } from "../../lib/design/hooks/useSetChildrenExpandState";

interface IssuePickerProps {
    open: boolean;
    onIssuesSelected: () => void;
}

export const IssuePicker = (props: IssuePickerProps) => {
    /* Constants */
    const ISSUE_PICKER_LOCATION_FEATURE_CONFIGURATION: LocationFeatureConfiguration = {
        displayContextMenu: false,
        displayViewEditToggle: false,
        allowShare: false,
        selectionBehavior: SelectionBehavior.PROPAGATE_TO_CHILDREN,
        componentModificationMode: ComponentModificationMode.ALWAYS_VIEW,
        displayEmptyLocation: false
    };
    const ISSUE_PICKER_ISSUE_FEATURE_CONFIGURATION: IssueFeatureConfiguration = {
        displayContextMenu: false,
        displayViewEditToggle: false,
        displayCreateButton: false,
        displayStatusSelector: true,
        displaySettingsMenu: true,
        allowShare: false,
        selectionBehavior: SelectionBehavior.SELF_ONLY,
        allowFiltering: true,
        allowValidationToggle: false,
        componentModificationMode: ComponentModificationMode.ALWAYS_VIEW
    };
    const ISSUE_PICKER_DIMENSION_FEATURE_CONFIGURATION: DimensionFeatureConfiguration = {
        displayContextMenu: false,
        displayViewEditToggle: false,
        componentModificationMode: ComponentModificationMode.ALWAYS_VIEW
    };
    const ISSUE_PICKER_WORK_SPECIFICATION_FEATURE_CONFIGURATION: WorkSpecificationFeatureConfiguration = {
        displayContextMenu: false,
        displayViewEditToggle: false,
        displayAddButton: false,
        componentModificationMode: ComponentModificationMode.ALWAYS_VIEW
    };

    /* Internal page states */
    const { open, onIssuesSelected } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [isPageInitialized, setIsPageInitialized] = useState<boolean>(false);

    const setLocationFeatureConfigurationForIssuePickerContext = useSetRecoilState(locationFeatureConfigurationByContextAtomFamily(StateContext.SOLUTION_AUTHORING_ISSUE_PICKER));
    const [issueFeatureConfiguration, setIssueFeatureConfiguration] = useRecoilState(issueFeatureConfigurationByContextAtomFamily(StateContext.SOLUTION_AUTHORING_ISSUE_PICKER));
    const setDimensionFeatureConfigurationForIssuePickerContext = useSetRecoilState(dimensionFeatureConfigurationByContextAtomFamily(StateContext.SOLUTION_AUTHORING_ISSUE_PICKER));
    const setWorkSpecificationFeatureConfigurationForIssuePickerContext = useSetRecoilState(workSpecificationFeatureConfigurationByContextAtomFamily(StateContext.SOLUTION_AUTHORING_ISSUE_PICKER));
    const propertyId = useRecoilValue(propertyIdInFocusAtom);
    const [selectAllCheckBoxState, setSelectAllCheckBoxState] = useRecoilState(selectAllCheckboxByContextSelectorFamily(StateContext.SOLUTION_AUTHORING_ISSUE_PICKER));

    /* Dependencies */
    const locationDaoRef = useRef<LocationDAO>(DataStoreLocationDAOFactory.getInstance());
    const fetchLocation = getFetchLocationCallback();
    const cleanUpPropertyStates = getCleanUpPropertyStates(StateContext.SOLUTION_AUTHORING_ISSUE_PICKER);
    const resetIssueFilter = getResetIssueFilter();
    const { setChildrenExpandStateForAllLocations } = useSetChildrenExpandState(StateContext.SOLUTION_AUTHORING_ISSUE_PICKER);
    const setIsSelectionMode = useSetRecoilState<boolean>(isSelectionModeAtomFamily(StateContext.SOLUTION_AUTHORING_ISSUE_PICKER));

    useEffect(() => {
        const initialize = async () => {
            setLocationFeatureConfigurationForIssuePickerContext(ISSUE_PICKER_LOCATION_FEATURE_CONFIGURATION);
            setIssueFeatureConfiguration(ISSUE_PICKER_ISSUE_FEATURE_CONFIGURATION);
            setDimensionFeatureConfigurationForIssuePickerContext(ISSUE_PICKER_DIMENSION_FEATURE_CONFIGURATION);
            setWorkSpecificationFeatureConfigurationForIssuePickerContext(ISSUE_PICKER_WORK_SPECIFICATION_FEATURE_CONFIGURATION);
            setIsSelectionMode(true);
            await fetchPropertyLocations(propertyId!);
            setIsPageInitialized(true);
        };
        initialize();
        return () => {
            cleanUpPropertyStates();
            resetIssueFilter();
        };
    }, [propertyId]);

    useEffect(() => {
        onShowAllIssues();
    }, [open]);

    const fetchPropertyLocations = async (propertyId: string) => {
        const locations: Array<Location> = await locationDaoRef.current.getLocationsByPropertyId(propertyId);
        await Promise.all(locations.map(location => fetchLocation(location.id, StateContext.SOLUTION_AUTHORING_ISSUE_PICKER)));
    };

    const onShowAllIssues = () => {
        setChildrenExpandStateForAllLocations(ChildrenExpandState.EXPAND_CHILDREN, false);
    };

    const onHideAllIssues = () => {
        setChildrenExpandStateForAllLocations(ChildrenExpandState.COLLAPSE_CHILDREN, false);
    };

    return (
        <Dialog
            open={open}
            scroll="paper"
            fullWidth
            fullScreen={fullScreen}
            maxWidth="lg"
            PaperProps={{
                sx: {
                    backgroundColor: grey[50],
                    height: {
                        md: "80vh"
                    }
                }
            }}
        >
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Box width="100%" sx={{ display: "flex", flexDirection: "row" }}>
                        <DialogTitle>
                            Select Issues
                        </DialogTitle>
                    </Box>
                </Grid>
                <Grid item display="flex" gap={2}>
                    <Box
                        py={2}
                    >
                        <Button variant="outlined" onClick={onShowAllIssues}>
                            Show All Issues
                        </Button>
                    </Box>
                    <Box
                        py={2}
                    >
                        <Button variant="outlined" onClick={onHideAllIssues}>
                            Hide All Issues
                        </Button>
                    </Box>
                    {
                        issueFeatureConfiguration.allowFiltering && (
                            <Box
                                py={2}
                                pr={2}
                            >
                                <IssueSettings context={StateContext.SOLUTION_AUTHORING_ISSUE_PICKER} />
                            </Box>
                        )
                    }
                </Grid>
            </Grid>
            <Box pl={3} mt={-2}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectAllCheckBoxState === CheckBoxValue.CHECKED}
                            indeterminate={selectAllCheckBoxState === CheckBoxValue.INDETERMINATE}
                            onChange={(event, isChecked) => {
                                setSelectAllCheckBoxState(isChecked ? CheckBoxValue.CHECKED : CheckBoxValue.UNCHECKED);
                            }}
                        />
                    }
                    label="Select All"
                />
            </Box>
            <DialogContent
                dividers
                sx={{
                    paddingLeft: {
                        xs: 0,
                        sm: 1
                    },
                    paddingRight: {
                        xs: 0,
                        sm: 1
                    },
                    paddingBottom: 1
                }}
            >
                {!isPageInitialized ? (
                    <LinearProgress />
                ) : (
                    <ListLocations
                        stateContext={StateContext.SOLUTION_AUTHORING_ISSUE_PICKER}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() => onIssuesSelected()}
                >
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};
