import {
    E_ALREADY_LOCKED,
    Mutex,
    tryAcquire
} from "async-mutex";

import { SetterOrUpdater } from "recoil";
import { SyncReconciliationManager } from "./SyncReconciliationManager";
import { SyncReconciliationStatus } from "./SyncReconciliationStatus";
import Task from "../../util/concurrency/Task";

export class DefaultSyncReconciliationManager implements SyncReconciliationManager {
    private readonly setSyncReconciliationStatus: SetterOrUpdater<SyncReconciliationStatus>;
    private readonly syncReconciliationWorker: Task<void>;
    private readonly mutex = new Mutex();

    constructor(
        setSyncReconciliationStatus: SetterOrUpdater<SyncReconciliationStatus>,
        syncReconciliationWorker: Task<void>
    ) {
        this.setSyncReconciliationStatus = setSyncReconciliationStatus;
        this.syncReconciliationWorker = syncReconciliationWorker;
    }

    public async handle(): Promise<void> {
        await tryAcquire(this.mutex)
            .runExclusive(async () => {
                let success = false;
                while (!success) {
                    try {
                        await this.syncReconciliationWorker.run();
                        this.setSyncReconciliationStatus(SyncReconciliationStatus.SYNC_COMPLETED);
                        success = true;
                    } catch (error) {
                        success = false;
                    }
                }
            })
            .catch(error => {
                if (error !== E_ALREADY_LOCKED) {
                    throw error;
                }
            });
    }
}