import {
    Dispatch,
    SetStateAction
} from "react";

import { CognitoUser } from "@aws-amplify/auth";
import HubEventHandler from "../event/HubEventHandler";
import { HubPayload } from "@aws-amplify/core";

export default class AuthSignOutEventHandler implements HubEventHandler {
    private static readonly SIGN_OUT_EVENT = "signOut";
    private readonly setUser: Dispatch<SetStateAction<CognitoUser | undefined>>;
    private readonly delegate: HubEventHandler;

    constructor(
        setUser: Dispatch<SetStateAction<CognitoUser | undefined>>,
        delegate: HubEventHandler
    ) {
        this.setUser = setUser;
        this.delegate = delegate;
    }

    public async handle(payload: HubPayload): Promise<void> {
        if (payload.event !== AuthSignOutEventHandler.SIGN_OUT_EVENT) {
            return this.delegate.handle(payload);
        }
        this.setUser(undefined);
    }

}