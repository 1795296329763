import DefaultProposalItemPriceAfterAdjustmentCalculatorFactory from "./DefaultProposalItemPriceAfterAdjustmentCalculatorFactory";
import PercentageProposalItemChildrenPriceAdjustmentHandler from "./PercentageProposalItemChildrenPriceAdjustmentHandler";

export default class PercentageProposalItemChildrenPriceAdjustmentHandlerFactory {
    private static readonly INSTANCE = new PercentageProposalItemChildrenPriceAdjustmentHandler(
        DefaultProposalItemPriceAfterAdjustmentCalculatorFactory.getInstance()
    );
    public static getInstance() {
        return this.INSTANCE;
    }
}
