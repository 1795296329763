import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { DataStore } from "aws-amplify";
import DataStoreLocationDAO from "./DataStoreLocationDAO";
import LocationDAO from "../LocationDAO";

export default class DataStoreLocationDAOFactory {
    private static INSTANCE: LocationDAO = new DataStoreLocationDAO(
        DataStore,
        ClientLoggerFactory.getClientLogger("DataStoreLocationDAO")
    );
    static getInstance(): LocationDAO {
        return this.INSTANCE;
    }
}
