import {
    ListItem,
    ListItemButton,
    ListItemButtonProps,
    ListItemIcon,
    ListItemText,
    Tooltip
} from "@mui/material";
import {
    ReactElement,
    memo
} from "react";

import { Link } from "react-router-dom";

type DrawerListItemButtonProps = {
    title: string;
    icon: ReactElement;
    isOpen: boolean;
    linkTo?: string;
} & ListItemButtonProps;

export const DrawerListItemButton = memo((props: DrawerListItemButtonProps) => {
    const { title, icon, isOpen, linkTo, ...listItemButtonProps } = props;

    return (
        <ListItem disablePadding>
            <ListItemButton
                {...linkTo ? {
                    component: Link,
                    to: linkTo,
                    ...listItemButtonProps
                } : listItemButtonProps}
                sx={{
                    minHeight: 48,
                    justifyContent: isOpen ? 'initial' : 'center',
                    px: 2.5,
                    ...listItemButtonProps.sx
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: isOpen ? 3 : 0,
                        justifyContent: 'center',
                    }}
                >
                    <Tooltip title={title}>
                        {icon}
                    </Tooltip>
                </ListItemIcon>
                <ListItemText primary={title} sx={{ display: isOpen ? "inherit" : "none" }} />
            </ListItemButton>
        </ListItem>
    );
});
