import {
    MeetingStatus,
    useMeetingManager,
    useMeetingStatus
} from "amazon-chime-sdk-component-library-react";
import {
    RefCallback,
    useCallback,
    useEffect,
    useRef
} from "react";

import { TranscriptionMessage } from "../../../lib/meeting/message/TranscriptionMessage";
import { useInView } from "react-intersection-observer";
import { useTranscriptionContext } from "../transcription/ChimeTranscriptionContext";

export const useTranscription = () => {
    const meetingStatus = useMeetingStatus();
    const isMeetingStarted = meetingStatus === MeetingStatus.Succeeded || meetingStatus === MeetingStatus.Reconnecting;
    const meetingManager = useMeetingManager();
    const { addTranscriptEventListener, startTranscriptSubscription, transcripts: messages } = useTranscriptionContext();

    const lastMessageRefObject = useRef<HTMLDivElement | null>(null);
    const { inView: isLastMessageInView, ref: setIsLastMessageInViewRef } = useInView();
    const isLastMessageInViewRef = useRef<boolean>(isLastMessageInView);
    isLastMessageInViewRef.current = isLastMessageInView;

    const messagesEndRef: RefCallback<HTMLDivElement | null> = useCallback((node: HTMLDivElement | null) => {
        lastMessageRefObject.current = node;
        setIsLastMessageInViewRef(node);
    }, []);

    const handleTranscriptMessage = useCallback(async (transcriptionMessage: TranscriptionMessage) => {
        if (lastMessageRefObject.current && isLastMessageInViewRef.current) {
            lastMessageRefObject.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [meetingManager]);


    useEffect(() => {
        if (isMeetingStarted) {
            const stopTranscriptSubscription = startTranscriptSubscription();
            addTranscriptEventListener(handleTranscriptMessage);
            return () => {
                stopTranscriptSubscription();
            };
        }
    }, [isMeetingStarted]);

    return {
        messages,
        messagesEndRef,
        isLastMessageInView
    };
};