import {
    ClientLog,
    LogSeverityLevel
} from "../../models";
import {
    useEffect,
    useRef,
    useState
} from "react";

import { API } from 'aws-amplify';
import AuthUsernameSupplierFactory from '../../lib/auth/AuthUsernameSupplierFactory';
import { Box } from "@mui/material";
import DataStoreClientLogDAO from '../../lib/logging/DataStoreClientLogDAO';
import Observable from 'zen-observable-ts';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { onCreateClientLog } from '../../graphql/subscriptions';

const SyncTest = () => {
    const [querySuccess, setQuerySuccess] = useState<boolean>(false);
    const [queryPending, setQueryPending] = useState<boolean>(true);
    const authUsernameSupplier = useRef(AuthUsernameSupplierFactory.getInstance());
    
    const clientLogDAO = new DataStoreClientLogDAO();

    useEffect(() => {
        testSync();
    }, []);

    async function testSync() {
        const clientLog = new ClientLog({
            componentName: "SyncTestComponent",
            message: "TestSync",
            severityLevel: LogSeverityLevel.INFO
        });
        const datastoreResult = await clientLogDAO.save(clientLog);
        const username = await authUsernameSupplier.current.get();
        
        //Have to cast this to Observable<object> since API.graphql returns '{GraphQLResult<> | Observable<object>}'
        const pubSubClient = API.graphql(
            graphqlOperation(onCreateClientLog, { id: datastoreResult.id, owner: username })
        ) as Observable<object>;

        const subscription = pubSubClient.subscribe({
            next: (eventData: any) => {
                setQuerySuccess(true);
                setQueryPending(false);
            }
        });
        
        setTimeout(() => {
            subscription.unsubscribe();
            setQueryPending(false);
        }, 5000)
    }

    return (
        <Box>
            {
                queryPending ?
                    ("Waiting for result from backend...") :
                    (querySuccess ?
                        ("Successfully read synced data from backend") :
                        ("Could not retrieve data from backend. Make sure you're online and retry."))
            }
        </Box>
    )
}

export default SyncTest;
