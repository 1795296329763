import LocalForageUserEmailCache from "./AuthUserEmailCache";
import localforage from "localforage";

export default class LocalForageUserEmailCacheFactory {
    private static INSTANCE: LocalForageUserEmailCache = new LocalForageUserEmailCache(
        localforage
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}