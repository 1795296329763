import { PropertyListManager } from "./PropertyListManager";

export class LocalForageBackedFifoPropertyListManager implements PropertyListManager {
    private readonly OFFLINE_PROPERTY_LIST_KEY = "offlinePropertyIdList";

    private readonly localForage: LocalForage;
    private readonly numberOfPropertiesToSync: number | undefined;
    
    constructor(
        localForage: LocalForage,
        numberOfPropertiesToSync?: number
    ) {
        this.numberOfPropertiesToSync = numberOfPropertiesToSync;
        this.localForage = localForage;
    }

    public async isInitialized(): Promise<boolean> {
        const offlinePropertyIdList: Array<string> = await this.getList();
        return offlinePropertyIdList != null && offlinePropertyIdList.length > 0;
    }

    public async addPropertyId(propertyId: string): Promise<void> {
        const offlinePropertyIdList: Array<string> = await this.getList();
        offlinePropertyIdList.push(propertyId);
        if (this.numberOfPropertiesToSync && offlinePropertyIdList.length > this.numberOfPropertiesToSync) {
            offlinePropertyIdList.shift();
        }
        await this.localForage.setItem(this.OFFLINE_PROPERTY_LIST_KEY, offlinePropertyIdList);
    }

    public async removePropertyId(propertyId: string): Promise<void> {
        const offlinePropertyIdList: Array<string> = await this.getList();
        offlinePropertyIdList.splice(offlinePropertyIdList.indexOf(propertyId), 1);
        await this.localForage.setItem(this.OFFLINE_PROPERTY_LIST_KEY, offlinePropertyIdList);
    }

    public async getList(): Promise<string[]> {
        return await this.localForage.getItem(this.OFFLINE_PROPERTY_LIST_KEY) || [];
    }

    public async clear(): Promise<void> {
        await this.localForage.removeItem(this.OFFLINE_PROPERTY_LIST_KEY);
    }
}