import {
    Dimension,
    DimensionType,
    MeasurementUnit
} from "../../../models";
import {
    defaultRecordToPropertyAssociationModeAtom,
    dimensionByDimensionIdentifierSelectorFamily,
    isEditModeByDesignElementIdentifierAtomFamily,
    recentlyCreatedRecordIds
} from "../../design/document/state/DocumentState";

import { ContextAwareIdentifier } from "../../design/document/ContextAwareIdentifier";
import { CreateDimensionStateError } from "../../design/document/state/error/dimension/CreateDimensionStateError";
import DataStoreDimensionDAOFactory from "../dao/datastore/DataStoreDimensionDAOFactory";
import { ModelType } from "../../design/document/ModelType";
import { RecordToPropertyAssociationMode } from "../../design/document/state/RecordToPropertyAssociationMode";
import { StateContext } from "../../design/document/state/StateContext";
import { useCreateWorkSpecification } from "../../design/workscopespecification/hook/useCreateWorkSpecification";
import { useRecoilCallback } from "recoil";

const dimensionDAO = DataStoreDimensionDAOFactory.getDimensionDAO();

export const useCreateDimension = (context: StateContext) => {
    const { createDefaultWorkSpecification } = useCreateWorkSpecification(context);

    const createDimension = useRecoilCallback(({ snapshot, set }) => async (
        parentId: string,
        propertyId: string,
        createChild: boolean = true
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            const createdInSolution: boolean = context === StateContext.SOLUTION_AUTHORING_CONTENT
            let dimensionToCreate = new Dimension({
                parentId: parentId,
                propertyId: propertyId,
                createdInSolution: createdInSolution,
                measurement: {
                    measurementUnit: MeasurementUnit.FOOT,
                    dimensionType: DimensionType.SQUARE,
                },
            });
            // Remove propertyId association, if the issue is created outside of the context of inspection
            // (e.g. solution authoring)
            const recordToPropertyAssociationMode = snapshot.getLoadable(defaultRecordToPropertyAssociationModeAtom).contents as RecordToPropertyAssociationMode;
            if (recordToPropertyAssociationMode === RecordToPropertyAssociationMode.DO_NOT_ASSOCIATE) {
                dimensionToCreate = { ...dimensionToCreate, propertyId: undefined };
            }
            const createdDimension = await dimensionDAO.create(dimensionToCreate);
            const dimensionIdentifier = new ContextAwareIdentifier(createdDimension.id, context, ModelType.DIMENSION);
            set(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier), createdDimension);
            set(recentlyCreatedRecordIds, (prevValue: Set<string>) => {
                const copy = new Set(prevValue);
                copy.add(createdDimension.id);
                return copy;
            });
            set(isEditModeByDesignElementIdentifierAtomFamily(dimensionIdentifier), true);
            if (createChild) {
                await createDefaultWorkSpecification(createdDimension.id, createdDimension.parentId, createdDimension.propertyId);
            }
            return createdDimension;
        } catch (error) {
            throw new CreateDimensionStateError("Error occurred while trying to Create Dimension", error as Error);
        } finally {
            releaseSnapshot();
        }
    }, [context]);

    return { createDimension };
};
