import {
    Box,
    SxProps,
    Theme,
    useMediaQuery
} from "@mui/material";

import { APPBAR_ID } from "../../sidebar/Sidebar";
import AttachmentsUploadStatusDrawer from "../../attachment/AttachmentsUploadStatusDrawer";
import { QuickAccessKeys } from "../../quickAccess/QuickAccessKeys";
import { showQuickAccessKeysSelector } from "../../../lib/sidebar/SidebarRecoilStates";
import { useRecoilValue } from "recoil";

export const FABManager = () => {
    const showQuickAccessKeys = useRecoilValue<boolean>(showQuickAccessKeysSelector);
    const isLandscape = useMediaQuery("(orientation: landscape)");
    const appBarHeight = document.getElementById(APPBAR_ID)?.offsetHeight!;

    const verticalSxProps: SxProps<Theme> = {
        bottom: theme => theme.spacing(3),
        left: 0,
        flexDirection: "column",
        width: "100%"
    };

    const horizontalSxProps: SxProps<Theme> = {
        right: 0,
        top: `${appBarHeight}px`,
        flexDirection: "row",
        height: `calc(100% - ${appBarHeight}px)`,
        pb: 1
    };

    return (
        <Box
            display="flex"
            gap={1}
            sx={{
                position: 'fixed',
                zIndex: theme => theme.zIndex.drawer + 1,
                pointerEvents: "none",
                ...(isLandscape ? horizontalSxProps : verticalSxProps)
            }}
        >
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end" mx={2}>
                <Box sx={{ pointerEvents: "auto" }}>
                    <AttachmentsUploadStatusDrawer />
                </Box>
            </Box>
            {showQuickAccessKeys && (
                <Box sx={{ pointerEvents: "none" }}>
                    <QuickAccessKeys />
                </Box>
            )}
        </Box >
    );
};