import { Proposal, ProposalStatus } from "../../../../models";

import ProposalRecordDAO from "../../dao/record/ProposalRecordDAO";
import SubmitProposalHandler from "./SubmitProposalHandler";
import SubmitProposalHandlerError from './error/SubmitProposalHandlerError';

export default class SubmitProposalHandlerImpl implements SubmitProposalHandler {

    private readonly proposalRecordDAO: ProposalRecordDAO;

    constructor(
        proposalRecordDAO: ProposalRecordDAO
    ) {
        this.proposalRecordDAO = proposalRecordDAO;
    }

    public async handle(
        id: string
    ): Promise<Proposal> {
        try {
            return await this.proposalRecordDAO.updateStatus(id, ProposalStatus.SUBMITTED);
        } catch (error) {
            // TODO logger
            throw new SubmitProposalHandlerError(
                `Failed to submit proposal with id=${id}`,
                {cause: error as Error}
            );
        }
    }
}
