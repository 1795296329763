import {
    API,
    graphqlOperation
} from "aws-amplify";

import { AuthIDTokenSupplierFactory } from "../../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import GraphQLWorkTypeGroupDAO from "./GraphQLWorkTypeGroupDAO";
import WorkTypeGroupDAO from "../WorkTypeGroupDAO";

export default class GraphQLWorkTypeGroupDAOFactory {
    private static readonly INSTANCE: WorkTypeGroupDAO = new GraphQLWorkTypeGroupDAO(
        ClientLoggerFactory.getClientLogger("GraphQLWorkTypeGroupDAO"),
        API,
        graphqlOperation,
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance(): WorkTypeGroupDAO {
        return this.INSTANCE
    }
}
