import {
    Dispatch,
    SetStateAction,
    useCallback
} from "react";

import { ExpandableImageCarousel } from "./ExpandableImageCarousel";
import { TemporaryImage } from "./TemporaryImage";
import { encode as arrayBufferToBase64 } from "base64-arraybuffer";
import uuidv4 from "../../lib/util/UuidGenerator";

type CreatePropertyImageCarouselProps = {
    readonly imageDTOs: Array<TemporaryImage>;
    readonly setImageDTOs: Dispatch<SetStateAction<Array<TemporaryImage>>>;
};

export const CreatePropertyImageCarousel = ({ imageDTOs, setImageDTOs }: CreatePropertyImageCarouselProps) => {
    const blobToUrl = async (image: Blob) => {
        const arrayBuffer = await image.arrayBuffer();
        return `data:image/png;base64,${arrayBufferToBase64(arrayBuffer)}`;
    };

    const onImageUpload = useCallback(async (images: Array<Blob>, resize: boolean) => {
        const newImages: Array<TemporaryImage> = await Promise.all(images.map(async image => ({
            id: uuidv4(),
            dataURL: await blobToUrl(image),
            file: image
        })));
        setImageDTOs(prev => [...newImages, ...prev]);
    }, []);

    const onDeleteImage = useCallback(async (image: TemporaryImage) => {
        setImageDTOs(prev => prev.filter(imageDTO => imageDTO.id !== image.id));
    }, []);

    return (
        <ExpandableImageCarousel
            imageDTOs={imageDTOs}
            getImageKey={(imageDTO) => imageDTO?.id}
            getImageUrl={(imageDTO) => imageDTO?.dataURL}
            onImagesUpload={onImageUpload}
            onDeleteImage={onDeleteImage}
        />
    );
};