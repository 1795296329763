import {
    DataStoreClass,
    Predicates
} from "@aws-amplify/datastore";
import {
    EagerProperty,
    Property
} from "../../../../models";

import ClientLogger from "../../../logging/ClientLogger";
import { CreatePropertyError } from "../error/CreatePropertyError";
import { GetPropertyError } from "../error/GetPropertyError";
import PropertyDAO from "../PropertyDAO";
import PropertyMetricNames from "../../PropertyMetricNames";
import _ from "lodash";

export default class DataStorePropertyDAO implements PropertyDAO {
    private readonly dataStore: DataStoreClass;
    private logger: ClientLogger;

    constructor(
        dataStore: DataStoreClass,
        logger: ClientLogger
    ) {
        this.dataStore = dataStore;
        this.logger = logger;
    }

    public async getById(id: string): Promise<Property> {
        this.logger.info(
            `Getting Attachment Record: ${id}`,
            undefined,
            [PropertyMetricNames.GET_BY_ID_ATTEMPT]
        );
        try {
            const result: Property | undefined = await this.dataStore.query(Property, id);
            if (!result) {
                throw new Error("Property not found");
            }
            return result;
        } catch (error) {
            const errorMessage = `Unable to get Property: ${id}`;
            this.logger.error(
                errorMessage,
                error,
                [PropertyMetricNames.GET_BY_ID_FAILURE]
            );
            throw new GetPropertyError(errorMessage, error as Error);
        }
    }

    public async create(
        property: Property
    ): Promise<Property> {
        try {
            this.logger.info(
                `Creating Property name: ${property.name}, address: ${property.address}`,
                undefined,
                [PropertyMetricNames.CREATE_ATTEMPT]
            );
            const propertyToCreate: Property = new Property({
                ...property,
                localLastUpdatedTime: Date.now()
            });
            return this.dataStore.save(propertyToCreate);
        } catch (error) {
            const errorMessage = `Unable to create Property name: ${property.name}, address: ${property.address}`;
            this.logger.error(
                errorMessage,
                error,
                [PropertyMetricNames.CREATE_FAILURE]
            );
            throw new CreatePropertyError(errorMessage, error as Error);
        }
    }

    public async editProperty(
        propertyId: string,
        updatedProperty: Property
    ): Promise<Property> {
        try {
            const existingProperty: Property = await this.getById(propertyId);
            let result: Property = existingProperty;
            if (!_.isEqual(existingProperty, updatedProperty)) {
                result = await this.dataStore.save(
                    Property.copyOf(existingProperty, (propertyToUpdate) => {
                        propertyToUpdate.name = updatedProperty.name;
                        propertyToUpdate.description = updatedProperty.description;
                        propertyToUpdate.address = updatedProperty.address;
                        propertyToUpdate.primaryContactName = updatedProperty.primaryContactName;
                        propertyToUpdate.primaryContactNumber = updatedProperty.primaryContactNumber;
                        propertyToUpdate.primaryContactEmail = updatedProperty.primaryContactEmail;
                        propertyToUpdate.localLastUpdatedTime = Date.now();
                    })
                );
            }
            return result;
        } catch (error) {
            throw new Error("Failed to update the property");
        }
    }

    public async listProperties(
        limit?: number,
        nextToken?: string
    ): Promise<Array<Property>> {
        return await this.dataStore.query(Property, Predicates.ALL, {
            sort: (a) => (a.localLastUpdatedTime("DESCENDING")),
            limit:limit
        });
    }
}
