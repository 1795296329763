import {
    Box,
    Switch,
    Typography
} from "@mui/material";
import {
    MeetingStatus,
    useMeetingStatus
} from "amazon-chime-sdk-component-library-react";
import {
    defaultAudioEnabledAtom,
    defaultVideoEnabledAtom,
    isJoiningMeetingAtom
} from "./state/ChimeStates";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';

export const JoinMeetingSettings = () => {
    const meetingStatus = useMeetingStatus();
    const isMeetingActive = meetingStatus === MeetingStatus.Succeeded || meetingStatus === MeetingStatus.Reconnecting;
    const isJoiningMeeting = useRecoilValue(isJoiningMeetingAtom);
    const [audioEnabled, setAudioEnabled] = useRecoilState(defaultAudioEnabledAtom);
    const [videoEnabled, setVideoEnabled] = useRecoilState(defaultVideoEnabledAtom);

    return (
        <Box display="flex" flexDirection="column" gap={2} p={1}>
            <Box display="flex" gap={1} alignItems="center" justifyContent="space-around">
                <Box display="flex" gap={1} alignItems="center">
                    <VolumeUpOutlinedIcon color="primary" />
                    <Typography>
                        Audio
                    </Typography>
                </Box>
                <Switch
                    size="small"
                    checked={audioEnabled}
                    onChange={(e) => setAudioEnabled(e.target.checked)}
                    disabled={isMeetingActive || isJoiningMeeting}
                />
            </Box>
            <Box display="flex" gap={1} alignItems="center" justifyContent="space-around">
                <Box display="flex" gap={1} alignItems="center">
                    <VideocamOutlinedIcon color="primary" />
                    <Typography>
                        Video
                    </Typography>
                </Box>
                <Switch
                    size="small"
                    checked={videoEnabled}
                    onChange={(e) => setVideoEnabled(e.target.checked)}
                    disabled={isMeetingActive || isJoiningMeeting}
                />
            </Box>
        </Box>
    );
};