import {
    API,
    graphqlOperation
} from "aws-amplify";
import {
    ListEntityResourcePermissionByEntityIdByResourceIdQuery,
    PermissionEntityType,
    PermissionResourceType
} from "../../../API";

import ClientLogger from "../../logging/ClientLogger";
import { EntityResourcePermission } from "../../../models";
import { EntityResourcePermissionDAO } from "./EntityResourcePermissionDAO";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { IDTokenSupplier } from "../../auth/IDTokenSupplier";
import { ListEntityResourcePermissionError } from "../errors/ListEntityResourcePermissionError";
import { listEntityResourcePermissionByEntityIdByResourceId } from "../../../graphql/queries";

export class GraphQLEntityResourcePermissionDAO implements EntityResourcePermissionDAO {
    private static LIST_BY_ENTITY_AND_RESOURCE_ATTEMPT = "ListEntityResourcePermissionsByEntityAndResourceIdAttempt"
    private static LIST_BY_ENTITY_AND_RESOURCE_FAILURE = "ListEntityResourcePermissionsByEntityAndResourceIdFailure"
    
    private api: typeof API;
    private gqlOperation: typeof graphqlOperation
    private logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async listByEntityAndResource(
        entityType: PermissionEntityType,
        entityId: string,
        resourceType: PermissionResourceType,
        resourceId: string,
        isGranted: boolean
    ): Promise<Array<EntityResourcePermission>> {
        try {
            this.logger.info(
                `Listing EntityResourcePermission Records with entityType: ${entityType}, entityId: ${entityId}, resourceType: ${resourceType}, resourceId: ${resourceId}, isGranted: ${isGranted}`,
                undefined,
                [GraphQLEntityResourcePermissionDAO.LIST_BY_ENTITY_AND_RESOURCE_ATTEMPT]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<ListEntityResourcePermissionByEntityIdByResourceIdQuery> = await this.api.graphql(
                this.gqlOperation(
                    listEntityResourcePermissionByEntityIdByResourceId,
                    { 
                        entityId,
                        resourceId: {"eq": resourceId}
                    },
                    authToken
                )
            ) as GraphQLResult<ListEntityResourcePermissionByEntityIdByResourceIdQuery>;
            return result.data?.listEntityResourcePermissionByEntityIdByResourceId?.items as Array<EntityResourcePermission>;
        } catch (error) {
            const errorMessage: string = `Unable to list EntityResourcePermission Records with entityType: ${entityType}, entityId: ${entityId}, resourceType: ${resourceType}, resourceId: ${resourceId}, isGranted: ${isGranted}`;
            this.logger.warn(
                errorMessage,
                error,
                [GraphQLEntityResourcePermissionDAO.LIST_BY_ENTITY_AND_RESOURCE_FAILURE]
            );
            throw new ListEntityResourcePermissionError(errorMessage, {
                cause: error as Error
            });
        }
    }

}