import {
    useRecoilCallback,
    useRecoilState
} from "recoil";

import { ModelType } from "../../../../lib/design/document/ModelType";
import { idInFocusByModelTypeAtomFamily } from "../../../../lib/ui/InFocusRecoilStates";
import { isCreateWorkSpecificationDialogOpen } from "../../../../lib/ui/states/DialogOpenStates";
import { useSnackbar } from "notistack";

export const useCreateWorkSpecificationQuickAction = () => {
    const [isOpened, setIsOpened] = useRecoilState(isCreateWorkSpecificationDialogOpen);
    const snackbar = useSnackbar();

    const createWorkSpecificationQuickAction = useRecoilCallback(({ snapshot }) => async () => {
        const issueIdInFocus = await snapshot.getPromise(idInFocusByModelTypeAtomFamily(ModelType.ISSUE));
        if (!issueIdInFocus) {
            snackbar.enqueueSnackbar("Please select an issue to create a work specification", { variant: "error" });
            return;
        }
        if (isOpened) {
            setIsOpened(false);
            return;
        }
        setIsOpened(true);
    }, [isOpened]);


    return { createWorkSpecificationQuickAction };
}