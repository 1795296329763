import ClientLoggerFactory from "../../../../logging/ClientLoggerFactory";
import { DataStore } from "aws-amplify";
import DataStoreWorkScopeSpecificationDAO from "./DataStoreWorkScopeSpecificationDAO";
import JSONDeserializerFactory from "../../../../util/data/serialization/JSONDeserializerFactory";
import JSONSerializerFactory from "../../../../util/data/serialization/JSONSerializerFactory";
import WorkScopeSpecificationDAO from "../WorkScopeSpecificationDAO";

export default class DataStoreWorkScopeSpecificationDAOFactory {
    private static readonly INSTANCE: DataStoreWorkScopeSpecificationDAO = new DataStoreWorkScopeSpecificationDAO(
        JSONSerializerFactory.getInstance(),
        JSONDeserializerFactory.getInstance(),
        DataStore,
        ClientLoggerFactory.getClientLogger("DataStoreWorkScopeSpecificationDAO")
    );

    public static getInstance(): WorkScopeSpecificationDAO {
        return DataStoreWorkScopeSpecificationDAOFactory.INSTANCE;
    }
}
