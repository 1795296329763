import {
    ResourceType,
    Solution,
    Tender,
    TenderMetadata,
    TenderMinorVersion,
    TenderStatus
} from "../../../models";

import SerialNumberGenerator from "../../serialNumber/SerialNumberGenerator";
import { TenderDAO } from "./TenderDAO";
import { TenderMajorVersionDAO } from "../majorVersion/dao/TenderMajorVersionDAO";
import { TenderMinorVersionDAO } from "../minorversion/dao/TenderMinorVersionDAO";
import { TenderNotFoundError } from "../majorVersion/dao/error/TenderNotFoundError";
import { UuidGenerator } from "../../util/UuidGenerator";

export class DefaultTenderDAO implements TenderDAO {
    private tenderMajorVersionDAO: TenderMajorVersionDAO;
    private tenderMinorVersionDAO: TenderMinorVersionDAO;
    private uuidGenerator: UuidGenerator;
    private serialNumberGenerator: SerialNumberGenerator;

    constructor(
        tenderMajorVersionDAO: TenderMajorVersionDAO,
        tenderMinorVersionDAO: TenderMinorVersionDAO,
        uuidGenerator: UuidGenerator,
        serialNumberGenerator: SerialNumberGenerator
    ) {
        this.tenderMajorVersionDAO = tenderMajorVersionDAO;
        this.tenderMinorVersionDAO = tenderMinorVersionDAO;
        this.uuidGenerator = uuidGenerator;
        this.serialNumberGenerator = serialNumberGenerator;
    }

    public async getByProjectNumber(projectNumber: number): Promise<Tender> {
        return await this.tenderMajorVersionDAO.getByProjectNumber(projectNumber);
    }

    public async create(
        solution: Solution,
        ): Promise<Tender> {
        try {
            //check if tender already exists for Solution. If so, throw error.
            const existingTender = await this.getTenderByProjectNumberIfExists(solution.serialNumber!);
            if (existingTender) {
                throw new Error("Tender already exists for this solution");
            }
            // Create TenderMinorVersionMetaData from SolutionMetadata
            const solutionMetadata = solution.metadata;
            const tenderMetadata: TenderMetadata = {
                name: solutionMetadata?.name,
                propertyContactName: solutionMetadata?.propertyContactName,
                propertyAddress: solutionMetadata?.propertyAddress,
                propertyType: solutionMetadata?.propertyType,
                solutionDueDate: solutionMetadata?.solutionDueDate,
                consultantName: solutionMetadata?.consultantName,
                consultantPhoneNumber: solutionMetadata?.consultantPhoneNumber,
                consultantEmail: solutionMetadata?.consultantEmail,
            }
            const tenderId: string = this.uuidGenerator.generate();

            // Create TenderMinorVersion from Solution
            const tenderMV: Omit<TenderMinorVersion, 'id'> = {
                tenderId: tenderId,
                solutionId: solution.id,
                solutionMinorVersion: solution.latestMinorVersion,
                metadata: tenderMetadata,
                version: 0
            }
            await this.tenderMinorVersionDAO.create(tenderMV);
            // If successful, create TenderMajorVersion accordingly
            const tender: Tender = {
                id: tenderId,
                solutionId: solution.id,
                status: TenderStatus.PUBLISHED,
                latestVersion: 0,
                projectNumber: solution.serialNumber,
            }
            return await this.tenderMajorVersionDAO.create(tender);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    
    public async update(
        projectNumber: number,
        solution: Solution
    ): Promise<Tender> {
        // there needs to be more here, like how does a minor version get created?
        try {
            const tender = await this.tenderMajorVersionDAO.getByProjectNumber(projectNumber);
            if (!tender) {
                throw new TenderNotFoundError(`Tender with projectNumber=${projectNumber} not found`);
            }
            const serialNumber = await this.serialNumberGenerator.generate(
                tender.id!,
                ResourceType.TENDER
            );
            const tenderMetadata: TenderMetadata = {
                name: solution.metadata?.name,
                propertyContactName: solution.metadata?.propertyContactName,
                propertyAddress: solution.metadata?.propertyAddress,
                propertyType: solution.metadata?.propertyType,
                solutionDueDate: solution.metadata?.solutionDueDate,
                consultantName: solution.metadata?.consultantName,
                consultantPhoneNumber: solution.metadata?.consultantPhoneNumber,
                consultantEmail: solution.metadata?.consultantEmail,
            }
            //create new minor version
            const newMinorVersion: Omit<TenderMinorVersion, "id"> = {
                tenderId: tender.id,
                solutionId: solution.id,
                version: serialNumber,
                solutionMinorVersion: solution.latestMinorVersion,
                metadata: tenderMetadata
            }
            await this.tenderMinorVersionDAO.create(newMinorVersion);
            const updatedTender: Tender = {
                id: tender.id,
                solutionId: newMinorVersion.solutionId,
                status: TenderStatus.PUBLISHED,
                latestVersion: newMinorVersion.version,
            }
            // Update major version with minor version number
            return await this.tenderMajorVersionDAO.update(tender.id, updatedTender);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    private async getTenderByProjectNumberIfExists(projectNumber: number): Promise<Tender | undefined> {
        try {
            return await this.tenderMajorVersionDAO.getByProjectNumber(projectNumber);
        } catch (error) {
            if (error instanceof TenderNotFoundError) {
                return undefined;
            }
            throw error;
        }
    }
}