import {
    Box,
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    MenuList,
    Paper,
    Popper,
    useTheme
} from "@mui/material";
import {
    ReactElement,
    useRef,
    useState
} from "react";

import { MoreIcon } from "../icons";
import globalStyle from "../../assets/style/globalStyle";

export interface DropDownMenuProps {
    menuIcon?: ReactElement;
    menuIconVariant?: string;
    menuIconSize?: "small" | "medium" | undefined;
    menuItems: Array<ReactElement<any, any>>;
}

const DropDownMenu = (props: DropDownMenuProps) => {
    const { menuIcon, menuIconVariant, menuIconSize, menuItems } = props;
    const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState<boolean>(false);
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const theme = useTheme();
    const globalClasses = globalStyle();

    function handleCloseSettingsMenu(event: any) {
        if (anchorRef.current && anchorRef.current.contains(event.currentTarget)) {
            return;
        }
        setIsSettingsMenuOpen(false);
    }

    return (
        <>
            <IconButton
                aria-label="settings"
                ref={anchorRef}
                onClick={() => setIsSettingsMenuOpen((original) => !original)}
                className={`${menuIconVariant} ${menuIconSize === "small" ? globalClasses.smallSquareButton : globalClasses.mediumSquareButton}`}
                tabIndex={-1}
            >
                {menuIcon}
            </IconButton>
            {/* @ts-ignore */}
            <Popper
                style={{
                    zIndex: theme.zIndex.tooltip
                }}
                open={isSettingsMenuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
            >
                {({
                    TransitionProps, placement
                }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseSettingsMenu}>
                                <MenuList
                                    id="split-button-menu"
                                    onClick={handleCloseSettingsMenu}
                                >
                                    {
                                        menuItems.map((menuItem, index) => (
                                            <Box key={index}>
                                                {menuItem}
                                                {(index !== menuItems.length - 1) && <Divider />}
                                            </Box>
                                        ))
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

DropDownMenu.defaultProps = {
    menuIcon: <MoreIcon />,
    menuIconVariant: "",
    menuIconSize: "medium"
};

export default DropDownMenu;
