import {
    DefaultWorkTypePricing,
    WorkTypeGroup
} from "../../../models";
import {
    allWorkTypeGroupIdsAtom,
    defaultWorkTypePricingByGroupIdAtomFamily,
    latestWorkTypeByGroupIdAtomFamily,
    workTypeGroupByIdAtomFamily
} from "../state/WorkTypeState";

import CreateWorkTypeHandler from "../handler/create/CreateWorkTypeHandler";
import CreateWorkTypeHandlerFactory from "../handler/create/CreateWorkTypeHandlerFactory";
import CreateWorkTypeResponse from "../handler/create/CreateWorkTypeResponse";
import SetDefaultPricingForWorkTypeGroupOrchestrator from "../defaultPricing/orchestrator/SetDefaultPricingForWorkTypeGroupOrchestrator";
import SetDefaultPricingForWorkTypeGroupOrchestratorFactory from "../defaultPricing/orchestrator/SetDefaultPricingForWorkTypeGroupOrchestratorFactory";
import WorkTypeDTO from "../DTO/WorkTypeDTO";
import { useRecoilCallback } from "recoil";

const createWorkTypeHandler: CreateWorkTypeHandler = CreateWorkTypeHandlerFactory.getInstance();
const setDefaultWorkTypePricingOrchestrator: SetDefaultPricingForWorkTypeGroupOrchestrator = SetDefaultPricingForWorkTypeGroupOrchestratorFactory.getInstance();

export const useCreateWorkType = () => {

    const createNewWorkType = useRecoilCallback(({ set }) => async (
        workType: WorkTypeDTO,
        isDiscoverable: boolean = false
    ): Promise<WorkTypeDTO> => {
        const createWorkTypeResponse: CreateWorkTypeResponse = await createWorkTypeHandler.handle({ workType, isDiscoverable });
        const workTypeCreated: WorkTypeDTO = createWorkTypeResponse.workType;
        const workTypeGroupCreated: WorkTypeGroup = createWorkTypeResponse.workTypeGroup;
        set(workTypeGroupByIdAtomFamily(workTypeCreated.groupId), workTypeGroupCreated);
        set(latestWorkTypeByGroupIdAtomFamily(workTypeCreated.groupId), workTypeCreated);
        set(allWorkTypeGroupIdsAtom, (original) => {
            const updated = [...original];
            updated.push(workTypeCreated.groupId);
            return updated;
        });
        return workTypeCreated;
    }, []);

    const createNewWorkTypeWithDefaultPricing = useRecoilCallback(({ set }) => async (
        workType: WorkTypeDTO,
        unitPrice: number,
        unitCost?: number,
        isDiscoverable: boolean = false
    ): Promise<WorkTypeDTO> => {
        const workTypeCreated: WorkTypeDTO = await createNewWorkType(workType, isDiscoverable);
        const defaultWorkTypePricing: DefaultWorkTypePricing = await setDefaultWorkTypePricingOrchestrator.orchestrate(workTypeCreated.groupId, unitCost, unitPrice);
        set(defaultWorkTypePricingByGroupIdAtomFamily(workTypeCreated.groupId), defaultWorkTypePricing);
        return workTypeCreated;
    }, []);

    return { createNewWorkType, createNewWorkTypeWithDefaultPricing };
};
