import ClientLogger from "../../../logging/ClientLogger";
import { DeleteProposalContentError } from "./error/DeleteProposalContentError";
import Deserializer from "../../../util/data/serialization/Deserializer";
import GetProposalContentError from "./error/GetProposalContentError";
import ProposalContent from "../../../design/bidding/ProposalContent";
import ProposalContentDAO from "./ProposalContentDAO";
import SaveProposalContentError from "./error/SaveProposalContentError";
import Serializer from "../../../util/data/serialization/Serializer";
import { Storage } from "aws-amplify";
import uuidv4 from "../../../util/UuidGenerator";

export default class S3ProposalContentDAO implements ProposalContentDAO {
    private static readonly PROPOSAL_S3_BUCKET = "Proposals";

    private static readonly GET_ATTEMPT_METRIC_NAME = "S3ProposalContentDAO.Get.Attempt";
    private static readonly GET_FAILURE_METRIC_NAME = "S3ProposalContentDAO.Get.Failure";
    private static readonly CREATE_ATTEMPT_METRIC_NAME = "S3ProposalContentDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "S3ProposalContentDAO.Create.Failure";
    private static readonly DELETE_ATTEMPT_METRIC_NAME = "S3ProposalContentDAO.Delete.Attempt";
    private static readonly DELETE_FAILURE_METRIC_NAME = "S3ProposalContentDAO.Delete.Failure";

    private readonly contentSerializer: Serializer<ProposalContent, string>;
    private readonly contentDeserializer: Deserializer<string, ProposalContent>;
    private readonly storage: typeof Storage;
    private readonly logger: ClientLogger;

    constructor(
        contentSerializer: Serializer<ProposalContent, string>,
        contentDeserializer: Deserializer<string, ProposalContent>,
        storage: typeof Storage,
        logger: ClientLogger
    ) {
        this.contentSerializer = contentSerializer;
        this.contentDeserializer = contentDeserializer;
        this.storage = storage;
        this.logger = logger;
    }

    public async get(
        key: string
    ): Promise<ProposalContent> {
        try {
            this.logger.info(
                `Getting Proposal Content with key=${key}`,
                key,
                [S3ProposalContentDAO.GET_ATTEMPT_METRIC_NAME]
            );  
            const response: any = await this.storage.get(key, { download: true });
            const serializedProposalContent: string = await response.Body.text();
            return this.contentDeserializer.deserialize(serializedProposalContent);
        } catch (error) {
            this.logger.error(
                `Failed to retrieve proposal content with key=${key}`,
                error,
                [S3ProposalContentDAO.GET_FAILURE_METRIC_NAME]
            );
            throw new GetProposalContentError(
                `Failed to retrieve proposal content with key=${key}`,
                { cause: error as Error }
            );
        }
    }

    public async save(
        value: ProposalContent
    ): Promise<string> {
        const key: string = this.createContentKey(uuidv4());
        try {
            this.logger.info(
                `Saving Proposal Content with key=${key}`,
                value,
                [S3ProposalContentDAO.CREATE_ATTEMPT_METRIC_NAME]
            );
            const serializedProposalContent: string = this.contentSerializer.serialize(value);
            await this.storage.put(key, serializedProposalContent, { contentType: "document/json" });
            return key;
        } catch (error) {
            this.logger.error(
                `Failed to save proposal content with key=${key}`,
                error,
                [S3ProposalContentDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new SaveProposalContentError(
                `Failed to save proposal content with key=${key}`,
                { cause: error as Error }
            );
        }
    }

    public async delete(
        key: string
    ): Promise<void> {
        try {
            this.logger.info(
                `Deleting Proposal Content with key=${key}`,
                key,
                [S3ProposalContentDAO.DELETE_ATTEMPT_METRIC_NAME]
            );
            await this.storage.remove(key);
        } catch (error) {
            this.logger.error(
                `Failed to delete proposal content with key=${key}`,
                error,
                [S3ProposalContentDAO.DELETE_FAILURE_METRIC_NAME]
            );
            throw new DeleteProposalContentError(
                `Failed to delete proposal content with key=${key}`,
                { cause: error as Error }
            );
        }
    }

    private createContentKey(
        proposalId: string
    ) {
        return `${S3ProposalContentDAO.PROPOSAL_S3_BUCKET}/${proposalId}`;
    }
}
