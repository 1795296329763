import {
    Attachment,
    AttachmentType
} from "../../models";
import {
    ExpandableImageCarousel,
    ImageCarouselInstanceProps
} from "./ExpandableImageCarousel";
import {
    useCallback,
    useEffect,
    useMemo
} from "react";

import { AttachmentParent } from "./AttachmentParent";
import { ImageCarousel } from "./ImageCarousel";
import { RefetchNotifier } from "./RefetchNotifier";
import { useDeleteAttachment } from "./carouselComponent/useDeleteAttachment";
import { useFetchAttachmentImageUrl } from "../../lib/attachment/hooks/useFetchAttachmentImageUrl";
import { useFetchAttachments } from "../../lib/attachment/hooks/useFetchAttachments";
import { useUploadImages } from "./carouselComponent/useUploadImage";

type AttachmentImageCarouselProps = {
    readonly attachmentParent: AttachmentParent;
    readonly thumbnailImageCarouselConfiguration?: ImageCarouselInstanceProps<Attachment>;
    readonly fullScreenImageCarouselConfiguration?: ImageCarouselInstanceProps<Attachment>;
    readonly reverseButtonOrder?: boolean;
    readonly expandable?: boolean;
};

export const AttachmentImageCarousel = (props: AttachmentImageCarouselProps) => {
    const { attachmentParent, fullScreenImageCarouselConfiguration, thumbnailImageCarouselConfiguration, reverseButtonOrder, expandable = true } = props;
    const [uploadImages] = useUploadImages();
    const { attachments, status, refetch } = useFetchAttachments(attachmentParent);
    const { fetchAttachmentImageUrl } = useFetchAttachmentImageUrl();
    const { deleteAttachment } = useDeleteAttachment();

    const imageDTOs = useMemo<Array<Attachment> | undefined>(() => {
        // undefined attachment type means it is an image
        return attachments?.filter((attachment) => !attachment.type || attachment.type === AttachmentType.IMAGE);
    }, [attachments]);

    const onImagesUpload = useCallback(async (images: Array<Blob>, resize: boolean) => {
        await uploadImages(images, { shouldResize: resize }, attachmentParent);
        await refetch();
    }, [attachmentParent]);

    const onDeleteImage = useCallback(async (attachment: Attachment) => {
        await deleteAttachment(attachment.id);
        await refetch();
    }, []);

    useEffect(() => {
        RefetchNotifier.subscribe(attachmentParent.id, refetch);
        return () => {
            RefetchNotifier.unsubscribe(attachmentParent.id);
        };
    }, [attachmentParent.id]);

    return (
        expandable ? (
            <ExpandableImageCarousel
                imageDTOs={imageDTOs}
                fetchStatus={status}
                getImageKey={(attachment) => attachment.id}
                getImageTimestamp={(attachment) => (attachment.localCreatedAtTime ?? undefined)}
                getImageUrl={fetchAttachmentImageUrl}
                onDeleteImage={onDeleteImage}
                onImagesUpload={onImagesUpload}
                fullScreenImageCarouselConfiguration={fullScreenImageCarouselConfiguration}
                thumbnailImageCarouselConfiguration={thumbnailImageCarouselConfiguration}
                reverseButtonOrder={reverseButtonOrder}
            />
        ) : (
            <ImageCarousel
                imageDTOs={imageDTOs}
                fetchStatus={status}
                getImageKey={(attachment) => attachment.id}
                getImageTimestamp={(attachment) => (attachment.localCreatedAtTime ?? undefined)}
                getImageUrl={fetchAttachmentImageUrl}
                onDeleteImage={onDeleteImage}
                onImagesUpload={onImagesUpload}
                swiper={null}
                reverseButtonOrder={reverseButtonOrder}
                {...thumbnailImageCarouselConfiguration}
            />
        )
    );
};