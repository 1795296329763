import {
    memo,
    useEffect,
    useMemo
} from "react";
import {
    useRecoilState,
    useRecoilValue
} from 'recoil';

import { Pagination } from "@mui/material";
import { SortDirection } from "../../../lib/sorting/SortDirection";
import { currentPageByGroupIdAtomFamily } from '../../../lib/pagination/PaginationBarState';
import { documentSortDirectionAtom } from "../../../lib/design/document/state/DocumentSortState";
import { useResetRecoilState } from 'recoil';

type PaginationBarProps = {
    groupId: string; // ID of the group to which the pages belong, e.g. if pagination is for issues, group ID would be `issues_by_location_${locationId}`
    items: Array<unknown>;
    itemsPerPage: number;
    setItemsToDisplay: (items: Array<any>) => void;
    size?: "small" | "medium" | "large";
};

export const PaginationBar = memo((props: PaginationBarProps) => {
    const { groupId, items, itemsPerPage, setItemsToDisplay, size } = props;
    const [currentPage, setCurrentPage] = useRecoilState(currentPageByGroupIdAtomFamily(groupId));
    const resetCurrentPage = useResetRecoilState(currentPageByGroupIdAtomFamily(groupId));
    const documentSortDirection = useRecoilValue(documentSortDirectionAtom)

    const numberOfPages: number = useMemo(() => {
        return Math.ceil(items.length / itemsPerPage);
    }, [items, itemsPerPage]);

    //This is the 'page number' after accounting for sort direction. I.e. page 0 is the last page when the sortDirection is ASCENDING
    const directionTranslatedCurrentPage = useMemo(() => {
        if (documentSortDirection === SortDirection.ASCENDING) {
            return numberOfPages - currentPage;
        }
        return currentPage + 1;
    }, [currentPage, documentSortDirection, numberOfPages]);


    const itemsToDisplay: Array<unknown> = useMemo(() => {
        const start: number = (directionTranslatedCurrentPage - 1) * itemsPerPage;
        return items.slice(start, start + itemsPerPage);
    }, [itemsPerPage, directionTranslatedCurrentPage, items]);

    useEffect(() => {
        const isPageOutOfRange: boolean = numberOfPages > 0 && currentPage >= numberOfPages;
        if (isPageOutOfRange) {
            setCurrentPage(numberOfPages - 1);
        }
    }, [numberOfPages, currentPage]);

    useEffect(() => {
        setItemsToDisplay(itemsToDisplay);
    }, [itemsToDisplay]);

    useEffect(() => {
        return () => {
            resetCurrentPage();
        };
    }, []);

    return (
        <>
            {
                numberOfPages > 1 &&
                <Pagination
                    size={size || "medium"}
                    shape="rounded"
                    color="primary"
                    count={numberOfPages}
                    siblingCount={size === "small" ? 0 : 1}
                    onChange={(e, page) => {
                        if (documentSortDirection === SortDirection.ASCENDING) {
                            setCurrentPage(numberOfPages - page)
                            return
                        }
                        setCurrentPage(page - 1)
                    }}
                    page={directionTranslatedCurrentPage}
                />
            }
        </>
    );
});
