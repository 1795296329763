import {
    AggregateUserPermissions,
    Organization,
    PermissionResourceType,
    PermissionType,
    User
} from "../../models";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Typography,
    useMediaQuery
} from "@mui/material";
import {
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";

import { AssignablePermissionTemplateMapFilter } from "../../lib/permission/template/AssignablePermissionTemplateMapFilter";
import { AssignablePermissionTemplateMapFilterFactory } from "../../lib/permission/template/AssignablePermissionTemplateMapFilterFactory";
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from "@mui/lab/LoadingButton";
import { ManageUserPermissionsList } from "./ManageUserPermissionsList";
import { PermissionEntityType } from "../../models";
import { PermissionTemplate } from "../../lib/permission/template/PermissionTemplate";
import { PermissionTemplateSelect } from "./PermissionTemplateSelect";
import { TEMPLATE_TYPE_TO_PERMISSION_MAP } from "../../lib/permission/template/PermissionTemplateConstants";
import TeamSearchBox from "../team/TeamSearchBox";
import UserSearchBox from "../../components/user/UserSearchBox";
import theme from "../../assets/style/theme";
import { useManageResourcePermissions } from "./useManageResourcePermissions";
import { useRecoilValue } from "recoil";
import { userIdAtom } from "../../lib/userSession/state/UserSessionState";

type ManageResourcePermissionDialogProps = {
    resourceId: string;
    resourceType: PermissionResourceType;
    open: boolean;
    onClose: () => void;
};

export const ManageResourcePermissionDialog = (props: ManageResourcePermissionDialogProps) => {
    const { open, resourceId, resourceType, onClose } = props;

    const [selectedUsers, setSelectedUsers] = useState<Array<User>>([]);
    const [selectedTeams, setSelectedTeams] = useState<Array<Organization>>([]);
    const [isSharingResource, setIsSharingResource] = useState<boolean>(false);
    const [selectedPermissionTemplate, setSelectedPermissionTemplate] = useState<PermissionTemplate | undefined>();
    const [shareToEntityType, setShareToEntityType] = useState<PermissionEntityType>(PermissionEntityType.USER);
    const { userIdToAggregateUserPermissionsMap, isUpdatingData, onSharePermissions, onRemoveUserPermissions, onUpdateUserPermissions, refetch } = useManageResourcePermissions(resourceId, resourceType);

    const isScreenSizeXs = useMediaQuery(theme.breakpoints.down("sm"));
    const permissionTemplateMapFilterRef = useRef<AssignablePermissionTemplateMapFilter>(AssignablePermissionTemplateMapFilterFactory.getInstance());
    const loggedInUserId = useRecoilValue<string | undefined>(userIdAtom);

    const loggedInUserAggregateUserPermission = useMemo<AggregateUserPermissions | undefined>(() => {
        if (loggedInUserId == undefined) {
            return undefined;
        }
        return userIdToAggregateUserPermissionsMap?.get(loggedInUserId);
    }, [userIdToAggregateUserPermissionsMap, loggedInUserId]);

    const permissionTemplateMap = useMemo<Map<PermissionTemplate, Array<PermissionType>>>(() => {
        return permissionTemplateMapFilterRef.current.filter(
            TEMPLATE_TYPE_TO_PERMISSION_MAP,
            loggedInUserAggregateUserPermission?.permissions as Array<PermissionType> ?? [],
            []
        );
    }, [loggedInUserAggregateUserPermission]);

    const handleShareBtnClicked = async () => {
        setIsSharingResource(true);
        switch (shareToEntityType) {
            case PermissionEntityType.USER:
                await onSharePermissions(PermissionEntityType.USER, selectedUsers, permissionTemplateMap.get(selectedPermissionTemplate!)!);
                break;
            case PermissionEntityType.TEAM:
                await onSharePermissions(PermissionEntityType.TEAM, selectedTeams, permissionTemplateMap.get(selectedPermissionTemplate!)!);
                break;
        }
        setSelectedPermissionTemplate(undefined);
        setSelectedUsers([]);
        setSelectedTeams([]);
        setIsSharingResource(false);
    };

    const disableShareButton: boolean = useMemo(() => {
        if (selectedPermissionTemplate == undefined) {
            return true;
        }
        switch (shareToEntityType) {
            case PermissionEntityType.USER:
                return selectedUsers.length === 0;
            case PermissionEntityType.TEAM:
                return selectedTeams.length === 0;
        }
    }, [selectedPermissionTemplate, selectedUsers, selectedTeams, shareToEntityType]);

    useEffect(() => {
        if (open) {
            refetch();
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={isScreenSizeXs}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle sx={{ py: 0.5 }}>
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                        <Typography variant="h5" fontWeight={500}>Manage Permissions</Typography>
                    </Box>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ px: isScreenSizeXs ? 0 : 2 }}>
                <Grid
                    container
                    p={1}
                    spacing={0.5}
                    alignItems="flex-end"
                >
                    <Grid item xs={12} md={6}>
                        <FormControl size="small" fullWidth>
                            <RadioGroup row value={shareToEntityType} onChange={(e) => {
                                setSelectedTeams([]);
                                setSelectedUsers([]);
                                setShareToEntityType((e.target.value as PermissionEntityType));
                            }}>
                                <FormControlLabel value={PermissionEntityType.USER} control={<Radio size="small" />} label="User" />
                                <FormControlLabel value={PermissionEntityType.TEAM} control={<Radio size="small" />} label="Team" />
                            </RadioGroup>
                            {shareToEntityType === PermissionEntityType.TEAM && (
                                <TeamSearchBox
                                    selectedTeam={selectedTeams}
                                    multiple={true}
                                    onClear={() => setSelectedTeams([])}
                                    onSelect={(teams: Array<Organization>) => setSelectedTeams(teams)}
                                    label={"Share to Team"}
                                />
                            )}
                            {shareToEntityType === PermissionEntityType.USER && (
                                <UserSearchBox
                                    onSelect={(users: Array<User>) => setSelectedUsers(users)}
                                    onClear={() => setSelectedUsers([])}
                                    userIdsToExclude={userIdToAggregateUserPermissionsMap == undefined ? [] : Array.from(userIdToAggregateUserPermissionsMap.keys())}
                                    selectedUsers={selectedUsers}
                                    label={"Share to User"}
                                />
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={8} md={3}>
                        <PermissionTemplateSelect
                            permissionTemplateOptions={Array.from(permissionTemplateMap.keys())}
                            selectedTemplate={selectedPermissionTemplate}
                            onSelectedTemplateChange={setSelectedPermissionTemplate}
                            variant="outlined"
                            fullWidth
                            label="Permissions"
                        />
                    </Grid>
                    <Grid item xs={4} md={3} display="flex">
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            loading={isSharingResource}
                            startIcon={<></>}
                            loadingPosition="start"
                            onClick={handleShareBtnClicked}
                            sx={{ height: theme.mixins.toolbar.minHeight }}
                            disabled={disableShareButton}
                        >
                            Share
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12}>
                        <ManageUserPermissionsList
                            isUpdatingData={isUpdatingData}
                            userIdToAggregateUserPermissionsMap={userIdToAggregateUserPermissionsMap}
                            loggedInUserAggregateUserPermission={loggedInUserAggregateUserPermission}
                            resourceId={resourceId}
                            resourceType={resourceType}
                            onRemoveUserPermissions={onRemoveUserPermissions}
                            onUpdateUserPermissions={onUpdateUserPermissions}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};