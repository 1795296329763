export const imageDimensionsMap = new Map([
    [1, { width: 360, height: 200 }],
    [2, { width: 300, height: 200 }],
    [3, { width: 220, height: 200 }]
]);

export const clipboardImageDimensionsMap = new Map([
    [1, { width: 360, height: 202.5 }],
    [2, { width: 297, height: 167 }],
    [3, { width: 197, height: 185 }],
    [4, { width: 147, height: 140 }]
]);