import {
    CircularProgress,
    Divider,
    Drawer,
    IconButton,
    Typography
} from '@mui/material';
import {
    MeetingStatus,
    VideoTileGrid,
    useMeetingManager,
    useMeetingStatus
} from 'amazon-chime-sdk-component-library-react';
import {
    isLiveInspectionDrawerOpenAtom,
    isMeetingDrawerOpenAtom
} from '../../lib/sidebar/SidebarRecoilStates';
import {
    useEffect,
    useState
} from 'react';

import { AttendeeList } from './AttendeeList';
import Box from '@mui/material/Box';
import { ExternalWindowPopUp } from '../general/popout/ExternalWindowPopUp';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { LiveInspectionSettings } from './LiveInspectionSetting';
import { MeetingControlBar } from './MeetingControlBar';
import { MeetingEventManager } from './MeetingEventManager';
import { MeetingForm } from './MeetingForm';
import PleaseStandByLogo from "../../assets/images/please_stand_by.png";
import { Prompt } from 'react-router-dom';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { SpeakingOnMuteSnackbar } from './hooks/SpeakingOnMuteSnackbar';
import { TranscriptionDisplay } from './TranscriptionDisplay';
import { useRecoilState } from 'recoil';
import { useTranscription } from './hooks/useTranscription';

export const MEETING_DRAWER_WIDTH = 400;

export const LiveInspectionDrawer = () => {
    const [isOpenDrawer, setIsOpenDrawer] = useRecoilState(isLiveInspectionDrawerOpenAtom);

    const meetingStatus = useMeetingStatus();
    const isMeetingStarted = meetingStatus === MeetingStatus.Succeeded || meetingStatus === MeetingStatus.Reconnecting;
    const meetingManager = useMeetingManager();
    const [isVideoPoppedOut, setIsVideoPoppedOut] = useState(false);

    const { messages, messagesEndRef, isLastMessageInView } = useTranscription();
    const [isTranscriptionPoppedOut, setIsTranscriptionPoppedOut] = useState(false);
    const [isMeetingDrawerOpen, setIsMeetingDrawerOpen] = useRecoilState(isMeetingDrawerOpenAtom);

    useEffect(() => {
        setIsMeetingDrawerOpen(isMeetingStarted && (!isVideoPoppedOut || !isTranscriptionPoppedOut));
    }, [isMeetingStarted, isVideoPoppedOut, isTranscriptionPoppedOut]);

    useEffect(() => {
        const cleanup = () => {
            meetingManager.leave();
            setIsMeetingDrawerOpen(false);
        };
        return cleanup;
    }, []);

    return (
        <>
            <Drawer
                anchor="left"
                variant="temporary"
                keepMounted
                open={isOpenDrawer}
                onClose={() => setIsOpenDrawer(false)}
                PaperProps={{
                    sx: {
                        overflow: 'visible',
                        width: '20%',
                        minWidth: '300px',
                        pt: 9,
                        px: 2,
                        overflowY: 'auto',
                    },
                }}
            >
                {isMeetingStarted ? (
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="h6">
                            Meeting in Progress
                        </Typography>
                        <RadioButtonCheckedIcon fontSize="small" color="error" />
                    </Box>
                ) : (
                    <Typography variant="h6">
                        Meeting
                    </Typography>
                )}
                <MeetingForm />
                <MeetingEventManager />
                <SpeakingOnMuteSnackbar />
                {isMeetingStarted && (
                    <Box my={2} px={0.5}>
                        <Typography variant="h6">Attendees</Typography>
                        <AttendeeList />
                    </Box>
                )}
                <LiveInspectionSettings />
            </Drawer>
            {isMeetingStarted && (
                <>
                    <MeetingControlBar inspectionMode />
                    <Drawer
                        anchor="right"
                        variant="persistent"
                        open={isMeetingDrawerOpen}
                        PaperProps={{
                            sx: {
                                overflow: 'hidden',
                                width: `${MEETING_DRAWER_WIDTH}px`,
                                pt: 8,
                            },
                        }}
                        keepMounted
                    >
                        {isVideoPoppedOut ? (
                            <ExternalWindowPopUp onClose={() => setIsVideoPoppedOut(false)}>
                                {meetingStatus === MeetingStatus.Reconnecting && (
                                    <Box
                                        position="absolute"
                                        width="100%"
                                        height="100%"
                                        bgcolor="rgba(0,0,0,0.5)"
                                        color="white"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        zIndex={theme => theme.zIndex.modal}
                                    >
                                        <Typography>Reconnecting...</Typography>
                                        <CircularProgress color="inherit" />
                                    </Box>
                                )}
                                <VideoTileGrid
                                    noRemoteVideoView={
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} width="100%" height="100%">
                                            <img src={PleaseStandByLogo} style={{ maxWidth: "100dvw", maxHeight: "100dvh", objectFit: "contain" }} />
                                        </Box>
                                    }
                                />
                            </ExternalWindowPopUp>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: "250px",
                                        position: "relative"
                                    }}
                                >
                                    {meetingStatus === MeetingStatus.Reconnecting && (
                                        <Box
                                            position="absolute"
                                            width="100%"
                                            height="100%"
                                            bgcolor="rgba(0,0,0,0.5)"
                                            color="white"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            zIndex={theme => theme.zIndex.modal}
                                        >
                                            <Typography>Reconnecting...</Typography>
                                            <CircularProgress color="inherit" />
                                        </Box>
                                    )}
                                    <VideoTileGrid
                                        noRemoteVideoView={
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} width="100%" height="100%">
                                                <img src={PleaseStandByLogo} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                            </Box>
                                        }
                                    />
                                    <IconButton
                                        size="small"
                                        onClick={() => setIsVideoPoppedOut(true)}
                                        sx={{
                                            position: "absolute",
                                            bottom: 0,
                                            right: 0,
                                            backgroundColor: "rgba(255,255,255,0.8)"
                                        }}
                                    >
                                        <LaunchOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                                <Divider />
                            </>
                        )}
                        {isTranscriptionPoppedOut ? (
                            <ExternalWindowPopUp onClose={() => setIsTranscriptionPoppedOut(false)}>
                                <TranscriptionDisplay
                                    messages={messages}
                                    messagesEndRef={messagesEndRef}
                                    isLastMessageInView={isLastMessageInView}
                                />
                            </ExternalWindowPopUp>
                        ) : (
                            <Box
                                sx={{
                                    height: isVideoPoppedOut ? "100vh" : "calc(100vh - 300px)",
                                    width: "100%",
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                            >
                                <TranscriptionDisplay
                                    messages={messages}
                                    messagesEndRef={messagesEndRef}
                                    isLastMessageInView={isLastMessageInView}
                                />
                                <Box position="sticky" bottom={0} mt="auto" display="flex" justifyContent="end">
                                    <IconButton
                                        size="small"
                                        onClick={() => setIsTranscriptionPoppedOut(true)}
                                        sx={{
                                            backgroundColor: "rgba(255,255,255,0.8)",
                                            ml: "auto"
                                        }}
                                    >
                                        <LaunchOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}
                    </Drawer>
                </>
            )}
            <Prompt
                when={isMeetingStarted}
                message="Leaving the page will end the meeting. Are you sure you want to leave?"
            />
        </>
    );
};
