import AssociateWorkTypeWithEntityHandler from "./AssociateWorkTypeWithEntityHandler";
import AssociateWorkTypeWithEntityHandlerImpl from "./AssociateWorkTypeWithEntityHandlerImpl";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import DataStoreEntityWorkTypeAssociationDAOFactory from "../../relationship/dataStore/DataStoreEntityWorkTypeAssociationDAOFactory";
import DataStoreWorkTypePricingDAOFactory from "../../../design/worktype/pricing/dataStore/DataStoreWorkTypePricingDAOFactory";
import GraphQLDefaultWorkTypePricingDAOFactory from "../../defaultPricing/graphql/GraphQLDefaultWorkTypePricingDAOFactory";

export default class AssociateWorkTypeWithEntityHandlerFactory {
    private static readonly INSTANCE: AssociateWorkTypeWithEntityHandler = new AssociateWorkTypeWithEntityHandlerImpl(
        DataStoreEntityWorkTypeAssociationDAOFactory.getInstance(),
        DataStoreWorkTypePricingDAOFactory.getInstance(),
        GraphQLDefaultWorkTypePricingDAOFactory.getInstance(),
        ClientLoggerFactory.getClientLogger("AssociateWorkTypeWithEntityHandlerImpl")
    );

    public static getInstance(): AssociateWorkTypeWithEntityHandler {
        return this.INSTANCE;
    }
}
