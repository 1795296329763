import {
    API,
    graphqlOperation
} from "aws-amplify";
import { AuthIDTokenSupplierFactory } from "../../../auth/AuthIDTokenSupplierFactory";

import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import DefaultWorkTypePricingDAO from "../DefaultWorkTypePricingDAO";
import GraphQLDefaultWorkTypePricingDAO from "./GraphQLDefaultWorkTypePricingDAO";

export default class GraphQLDefaultWorkTypePricingDAOFactory {
    private static readonly INSTANCE: DefaultWorkTypePricingDAO = new GraphQLDefaultWorkTypePricingDAO(
        ClientLoggerFactory.getClientLogger("GraphQLDefaultWorkTypePricingDAO"),
        API,
        graphqlOperation,
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance(): DefaultWorkTypePricingDAO {
        return this.INSTANCE
    }
}
