import { PropertySortByField } from "../sorting/PropertySortByField";
import { SortDirection } from "../../sorting/SortDirection";
import { atom } from "recoil";

export const propertySortByFieldAtom = atom<PropertySortByField>({
    key: "propertySortByFieldAtom",
    default: PropertySortByField.UPDATED_AT
});

export const propertySortDirectionAtom = atom<SortDirection>({
    key: "propertySortDirectionAtom",
    default: SortDirection.DESCENDING
});

export const shouldPinOfflinePropertiesAtom = atom<boolean>({
    key: "shouldPinOfflinePropertiesAtom",
    default: true
});
