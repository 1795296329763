import DimensionDAO from "../../../dimension/dao/DimensionDAO";
import { ResourcePermissionMetadata } from "../ResourcePermissionMetadata";
import { ResourcePermissionMetadataDAO } from "../../dao/ResourcePermissionMetadataDAO";

export class DimensionDAOToResourcePermissionMetadataDAOAdapter implements ResourcePermissionMetadataDAO{
    private dimensionDAO: DimensionDAO;

    constructor (
        dimensionDAO: DimensionDAO
    ) {
        this.dimensionDAO = dimensionDAO;
    }

    getResourcePermissionMetadata(resourceId: string): Promise<ResourcePermissionMetadata> {
        return this.dimensionDAO.getDimensionById(resourceId);
    }
}