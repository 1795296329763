import {
    UserOrganizationAssociation,
    UserOrganizationAssociationType,
    UserOrganizationRole
} from "../../models";

import ClientLogger from "../logging/ClientLogger";
import { DataStoreClass } from "@aws-amplify/datastore";
import UserOrganizationAssociationDAO from "./UserOrganizationAssociationDAO";

export default class DataStoreUserOrganizationAssociationDAO implements UserOrganizationAssociationDAO {
    public static USER_ORGANIZATION_ASSOCIATION_CREATION_ATTEMPT = "UserOrganizationAssociationCreationAttempt";
    public static USER_ORGANIZATION_ASSOCIATION_CREATION_FAILURE = "UserOrganizationAssociationCreationFailure";
    public static USER_ORGANIZATION_ASSOCIATION_RETRIEVAL_ATTEMPT = "UserOrganizationAssociationRetrievalAttempt";
    public static USER_ORGANIZATION_ASSOCIATION_RETRIEVAL_FAILURE = "UserOrganizationAssociationRetrievalFailure";
    
    private readonly logger: ClientLogger;
    private readonly dataStore: DataStoreClass;

    constructor(
        logger: ClientLogger,
        dataStore: DataStoreClass,
    ) {
        this.logger = logger;
        this.dataStore = dataStore;
    }

    public async listUserOrganizationAssociationsByUserId(
        userId: string
    ): Promise<UserOrganizationAssociation[]> {
        try {
            this.logger.info(
                `Attempting to retrieve userOrganizationAssociation by userId: ${userId}`,
                undefined,
                [DataStoreUserOrganizationAssociationDAO.USER_ORGANIZATION_ASSOCIATION_RETRIEVAL_ATTEMPT]
            );
            return await this.dataStore.query(
                UserOrganizationAssociation,
                userOrganizationAssociation => userOrganizationAssociation.userId("eq", userId)
            );
        } catch (error) {
            this.logger.error(
                `Failed to retrieve userOrganizationAssociation by userId: ${userId}`,
                error,
                [DataStoreUserOrganizationAssociationDAO.USER_ORGANIZATION_ASSOCIATION_RETRIEVAL_FAILURE]
            );
            throw new Error("Failed to retrieve UserOrganizationAssociations");
        }
    }

    public async createUserOrganizationAssociation(
        userId: string,
        organizationId: string,
        associationType: UserOrganizationAssociationType,
        role?: UserOrganizationRole | undefined
    ): Promise<UserOrganizationAssociation> {
        try {
            this.logger.info(
                `Attempting to create userOrganizationAssociation for user ${userId} and organization ${organizationId}`,
                undefined,
                [DataStoreUserOrganizationAssociationDAO.USER_ORGANIZATION_ASSOCIATION_CREATION_ATTEMPT]
            );
            const userOrganizationAssociationToCreate: UserOrganizationAssociation = new UserOrganizationAssociation({
                userId: userId,
                organizationId: organizationId,
                associationType: associationType,
                role: role
            });
            return await this.dataStore.save(userOrganizationAssociationToCreate);
        } catch (error) {
            this.logger.error(
                `Failed to create userOrganizationAssociation for user ${userId} and organization ${organizationId}`,
                error,
                [DataStoreUserOrganizationAssociationDAO.USER_ORGANIZATION_ASSOCIATION_CREATION_FAILURE]
            );
            throw new Error("Failed to create userOrganizationAssociation");
        }
    }
}
