import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { FetchGoogleDriveFileDAO } from "./FetchGoogleDriveFileDAO";
import { GoogleDriveFileDAO } from "./GoogleDriveFileDAO";

export class FetchGoogleDriveFileDAOFactory {
    private static INSTANCE: GoogleDriveFileDAO = new FetchGoogleDriveFileDAO(
        ClientLoggerFactory.getClientLogger("FetchGoogleDriveFileDAO")
    );

    public static getInstance(): GoogleDriveFileDAO {
        return FetchGoogleDriveFileDAOFactory.INSTANCE;
    }
}