import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const ArchiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="M23 2H1v6h1v14h20V8h1V2zm-2 19H3V8h18v13zm1-14H2V3h20v4z"
        className="primary"
      />
      <path d="M7 10h10v2H7z" className="primary" />
    </g>
  </svg>
);
export default withCustomIcon(ArchiveIcon);
