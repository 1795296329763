import { ResourcePermissionMetadata } from "../ResourcePermissionMetadata";
import { ResourcePermissionMetadataDAO } from "../../dao/ResourcePermissionMetadataDAO";
import WorkScopeSpecificationDAO from "../../../design/workscopespecification/dao/WorkScopeSpecificationDAO";

export class WorkSpecificationDAOToResourcePermissionMetadataDAOAdapter implements ResourcePermissionMetadataDAO{
    private workSpecificationDAO: WorkScopeSpecificationDAO;

    constructor (
        workSpecificationDAO: WorkScopeSpecificationDAO
    ) {
        this.workSpecificationDAO = workSpecificationDAO;
    }

    getResourcePermissionMetadata(resourceId: string): Promise<ResourcePermissionMetadata> {
        return this.workSpecificationDAO.getById(resourceId);
    }
}