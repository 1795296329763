import {
    Backdrop,
    Box,
    CircularProgress,
    Typography
} from '@mui/material';
import {
    MeetingStatus,
    useMeetingManager,
    useMeetingStatus
} from 'amazon-chime-sdk-component-library-react';
import {
    useEffect,
    useMemo,
    useState
} from 'react';

import { InspectorMeetingView } from '../../components/chime/InspectorMeetingView';
import { MeetingEventManager } from '../../components/chime/MeetingEventManager';
import { MeetingSession } from '../../lib/meeting/chime/type/MeetingSession';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import { RemoteInspectorMeetingForm } from '../../components/chime/RemoteInspectorMeetingForm';
import localforage from 'localforage';
import { useHistory } from 'react-router-dom';
import { useSearchParams } from '../../lib/util/hooks/useSearchParams';

export const VideoCall = () => {
    const { params } = useSearchParams();
    const meetingStatus = useMeetingStatus();
    const isMeetingStarted = meetingStatus === MeetingStatus.Succeeded || meetingStatus === MeetingStatus.Reconnecting;
    const meetingManager = useMeetingManager();
    const history = useHistory();
    const [isRejoining, setIsRejoining] = useState(false);

    useEffect(() => {
        const joinExistingMeeting = async () => {
            const meetingSession: MeetingSession | null = await localforage.getItem("meetingSessionAtom");
            if (meetingSession) {
                setIsRejoining(true);
                const meetingSessionConfiguration = new MeetingSessionConfiguration(
                    meetingSession.meetingInfo,
                    meetingSession.attendeeInfo
                );
                await meetingManager.join(meetingSessionConfiguration);
                await meetingManager.start();
                const url = new URL(window.location.href);
                url.searchParams.delete("rejoin");
                history.replace({ pathname: url.pathname, search: url.search });
                setIsRejoining(false);
            }
        };
        if (params.get("rejoin") === "true") {
            joinExistingMeeting();
        }
    }, []);

    return (
        <Box
            width="80%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100dvh"
            margin="0 auto"
        >
            <MeetingEventManager />
            {!isMeetingStarted && (
                <RemoteInspectorMeetingForm />
            )}
            {meetingStatus === MeetingStatus.Succeeded && (
                <InspectorMeetingView />
            )}
            {meetingStatus === MeetingStatus.Reconnecting && (
                <Backdrop
                    sx={{ color: '#fff' }}
                    open
                >
                    <Typography variant="h6">Reconnecting...</Typography>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            {isRejoining && (
                <Backdrop
                    sx={{ color: '#fff' }}
                    open
                >
                    <Typography variant="h6">Rejoining...</Typography>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </Box>
    );
};