import { Attachment } from "../../../../../models";
import AttachmentRecordDAO from "../../../../attachment/record/AttachmentRecordDAO";
import { SolutionAttachmentPopulator } from "./SolutionAttachmentPopulator";
import SolutionContent from "../../../../design/types/SolutionContent";
import SolutionContentBuilder from "../../../../design/types/SolutionContentBuilder";

export class SolutionAttachmentPopulatorImpl implements SolutionAttachmentPopulator {
    private attachmentRecordDAO: AttachmentRecordDAO;

    constructor(
        attachmentRecordDAO: AttachmentRecordDAO
    ) {
        this.attachmentRecordDAO = attachmentRecordDAO;
    }

    public async populateAttachments(solutionContent: SolutionContent): Promise<SolutionContent> {
        const resourceToAttachmentsMap = new Map<string, Array<Attachment>>();
        const issues = Array.from(solutionContent.issueIdToIssueMap!.values());
        const locations = Array.from(solutionContent.locationIdToLocationMap!.values());
        const { propertyId } = solutionContent;
        if (propertyId) {
            const propertyAttachments: Array<Attachment> = await this.attachmentRecordDAO.listByParentId(propertyId);
            resourceToAttachmentsMap.set(propertyId, propertyAttachments.length > 0 ? [propertyAttachments[0]] : []);
        }
        for (const issue of issues) {
            const attachments: Array<Attachment> = await this.attachmentRecordDAO.listByParentId(issue.id);
            resourceToAttachmentsMap.set(issue.id, attachments);
        }
        for (const location of locations) {
            const attachments: Array<Attachment> = await this.attachmentRecordDAO.listByParentId(location.id);
            resourceToAttachmentsMap.set(location.id, attachments);
        }
        return new SolutionContentBuilder(solutionContent).resourceToAttachmentsMap(resourceToAttachmentsMap).build();
    }
}