import {
    GenerateReportMutation,
    GenerateReportMutationVariables,
    GenerateReportRequest,
    OnCompleteEventSubscription
} from "../../../../API";
import GraphQLAPI, {
    GraphQLResult
} from "@aws-amplify/api-graphql";

import { AWSAppSyncRealTimeProvider } from '@aws-amplify/pubsub';
import { AuthIDTokenSupplier } from "../../../auth/AuthIDTokenSupplier";
import ClientLogger from "../../../logging/ClientLogger";
import { CompleteEventStatus } from "../../../../models";
import { GenerateReportClient } from "./GenerateReportClient";
import { GenerateReportError } from "./errors/GenerateReportError";
import Observable from 'zen-observable-ts';
import { Storage } from "aws-amplify";
import { generateReport } from "../../../../graphql/mutations";
import { onCompleteEvent } from "../../../../graphql/subscriptions";
import uuidv4 from "../../../util/UuidGenerator";

export class GraphQLGenerateReportClient implements GenerateReportClient {
    public static readonly EXPORT_ATTEMPT = "ExportDocumentAttempt";
    public static readonly EXPORT_SUCCESS = "ExportDocumentSuccess";
    public static readonly EXPORT_FAILURE = "ExportDocumentFailure";

    private readonly idTokenSupplier: AuthIDTokenSupplier;
    private readonly logger: ClientLogger;

    constructor(
        logger: ClientLogger,
        idTokenSupplier: AuthIDTokenSupplier
    ) {
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async export(
        input: GenerateReportRequest
    ): Promise<Blob> {
        try {
            this.logger.info(
                `Attempting to export document`,
                undefined,
                [GraphQLGenerateReportClient.EXPORT_ATTEMPT]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const executionId: string = uuidv4();
            const fileKey: string = await new Promise<string>(async (resolve, reject) => {
                const observable = GraphQLAPI.graphql({
                    query: onCompleteEvent,
                    variables: { executionId },
                    authToken
                }) as Observable<{ provider: AWSAppSyncRealTimeProvider; value: GraphQLResult<OnCompleteEventSubscription>; }>;

                const subscription = observable.subscribe({
                    next: ({ provider, value }) => {
                        subscription.unsubscribe();
                        if (value.data?.onCompleteEvent?.result && value.data?.onCompleteEvent?.status === CompleteEventStatus.SUCCESS) {
                            resolve(value.data.onCompleteEvent.result);
                            return;
                        }
                        reject(new Error("Failed to export document"));
                    },
                    error: (error) => {
                        subscription.unsubscribe();
                        reject(error);
                    }
                });

                try {
                    const variables: GenerateReportMutationVariables = {
                        executionId,
                        input: input
                    };
                    await GraphQLAPI.graphql({
                        query: generateReport,
                        variables,
                        authToken
                    }) as GraphQLResult<GenerateReportMutation>;
                } catch (error) {
                    const errors: Array<Error> = (error as GraphQLResult).errors ?? [];
                    const executionTimeout: boolean = errors.some(error => error.message === "Execution timed out.");
                    if (!executionTimeout) {
                        subscription.unsubscribe();
                        reject(error);
                    }
                }
            });
            return (await Storage.get(fileKey, { download: true })).Body as Blob;
        } catch (error) {
            this.logger.error(
                `Failed to export document`,
                error,
                [GraphQLGenerateReportClient.EXPORT_FAILURE]
            );
            throw new GenerateReportError("Failed to export document", error as Error);
        }
    }
}