import { DataStoreStatus } from "./DataStoreStatus";
import DataStoreStatusUpdatingHubEventHandlerFactory from "./DataStoreStatusHubEventHandlerFactory";
import { Hub } from "aws-amplify";
import HubBackedEventListener from "../event/HubBackedEventListener";
import { HubCapsule } from "@aws-amplify/core";
import { SetterOrUpdater } from "recoil";
import { SyncReconciliationManager } from "./reconciliation/SyncReconciliationManager";
import { SyncReconciliationStatus } from "./reconciliation/SyncReconciliationStatus";

export default class HubBackedDataStoreEventListenerFactory {
    private static readonly DATASTORE_CHANNEL = "datastore";

    public static create(
        setDataStoreStatus: SetterOrUpdater<DataStoreStatus>,
        setSyncReconciliationStatus: SetterOrUpdater<SyncReconciliationStatus>,
        syncReconciliationManager: SyncReconciliationManager
    ) {
        const hubEventHandler = DataStoreStatusUpdatingHubEventHandlerFactory.create(
            setDataStoreStatus,
            setSyncReconciliationStatus,
            syncReconciliationManager
        );
        return new HubBackedEventListener(
            HubBackedDataStoreEventListenerFactory.DATASTORE_CHANNEL,
            Hub,
            (data: HubCapsule) => {
                hubEventHandler.handle(data.payload);
            }
        );
    }
}
