import Comparator from "../../util/data/sort/Comparator";
import { ComparisonResult } from "../../util/data/sort/ComparisonResult";
import { Issue } from "../../../models";
import { SortDirection } from "../../sorting/SortDirection";

/**
 * Compares two {@link Issue} by clientCreationDate.
 */
export default class ByClientCreationDateComparator implements Comparator<Issue> {
    private sortDirection: SortDirection;

    constructor(
        sortDirection: SortDirection = SortDirection.DESCENDING
    ) {
        this.sortDirection = sortDirection;
    }

    public compare(a: Issue, b: Issue): ComparisonResult {
        if (this.sortDirection === SortDirection.ASCENDING) {
            return this.sortDescending(b, a);
        }
        return this.sortDescending(a, b);
    }

    private sortDescending(a: Issue, b: Issue): ComparisonResult {
        if (a.clientCreationDate! < b.clientCreationDate!) {
            return ComparisonResult.GREATER_THAN;
        }
        if (a.clientCreationDate! > b.clientCreationDate!) {
            return ComparisonResult.LESS_THAN;
        }
        return ComparisonResult.EQUAL;
    }
}
