import DataStoreDimensionDAOFactory from "../../../dimension/dao/datastore/DataStoreDimensionDAOFactory";
import { DimensionDAOToResourcePermissionMetadataDAOAdapter } from "../adapter/DimensionDAOToResourcePermissionMetadataDAOAdapter";
import { GenericIngressCompletionChecker } from "../GenericIngressCompletionChecker";

export class DimensionIngressCompletionCheckerFactory {
    private static INSTANCE = new GenericIngressCompletionChecker(
        new DimensionDAOToResourcePermissionMetadataDAOAdapter(
            DataStoreDimensionDAOFactory.getDimensionDAO()
        )
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}