import { MeasurementUnit } from "../../../models";

export default class MeasurementUnitAbbreviationFactory {
    public static getAbbreviation(measurementUnit: MeasurementUnit): string {
        if (measurementUnit === MeasurementUnit.CENTIMETER) {
            return "cm";
        }
        if (measurementUnit === MeasurementUnit.FOOT) {
            return "ft";
        }
        if (measurementUnit === MeasurementUnit.INCH) {
            return "in";
        }
        if (measurementUnit === MeasurementUnit.METER) {
            return "m";
        }
        return "";
    }
}
