import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { CognitoUserAttribute } from "./CognitoUserAttribute";
import UserEmailCache from "./UserEmailCache";

export default class LocalForageUserEmailCache implements UserEmailCache {
    private static USER_EMAIL_KEY = "userEmail";

    private localForage: LocalForage;

    constructor(
        localForage: LocalForage
    ) {
        this.localForage = localForage;
    }

    public async populate(): Promise<void> {
        const user: CognitoUser = await Auth.currentAuthenticatedUser();
        user.getUserAttributes((error, result) => { 
            const userEmail: string = result?.find((attribute: CognitoUserAttribute) => attribute.Name === "email")?.Value || ""
            this.localForage.setItem(LocalForageUserEmailCache.USER_EMAIL_KEY, userEmail);
        });
    }

    public async get(): Promise<string | null | undefined> {
        return this.localForage.getItem(LocalForageUserEmailCache.USER_EMAIL_KEY);
    }
}
