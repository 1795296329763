import API, {
    GraphQLResult
} from "@aws-amplify/api";
import {
    Counter,
    CreateCounterMutation,
    GetCounterQuery,
    ResourceType,
    UpdateCounterMutation
} from "../../API";
import {
    createCounter,
    updateCounter
} from "../../graphql/mutations";

import ClientLogger from "../logging/ClientLogger";
import CreateCounterError from "./error/CreateCounterError";
import GetCounterByPartitionKeyAndResourceTypeError from "./error/GetCounterByPartitionKeyAndResourceTypeError";
import { IDTokenSupplier } from "../auth/IDTokenSupplier";
import SerialNumberGenerationError from "./error/SerialNumberGenerationError";
import SerialNumberGenerator from "./SerialNumberGenerator";
import UpdateCounterError from "./error/UpdateCounterError";
import { getCounter } from "../../graphql/queries";
import { graphqlOperation } from "aws-amplify";

export default class CounterAdoptingSerialNumberGenerator implements SerialNumberGenerator {
    private static readonly CREATE_ATTEMPT_METRIC_NAME = "CounterAdoptingSerialNumberGenerator.create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "CounterAdoptingSerialNumberGenerator.create.Failure";
    private static readonly UPDATE_ATTEMPT_METRIC_NAME = "CounterAdoptingSerialNumberGenerator.update.Attempt";
    private static readonly UPDATE_FAILURE_METRIC_NAME = "CounterAdoptingSerialNumberGenerator.update.Failure";
    private static readonly GET_BY_PARTITION_KEY_AND_RESOURCE_TYPE_ATTEMPT_METRIC_NAME = "CounterAdoptingSerialNumberGenerator.getByPartitionKeyAndResourceType.Attempt";
    private static readonly GET_BY_PARTITION_KEY_AND_RESOURCE_TYPE_FAILURE_METRIC_NAME = "CounterAdoptingSerialNumberGenerator.getByPartitionKeyAndResourceType.Failure";
    
    private logger: ClientLogger;
    private readonly api: typeof API;
    private readonly gqlOperation: typeof graphqlOperation;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.idTokenSupplier = idTokenSupplier;
    }

    /**
     * Create Counter 
     * @throws {CreateCounterError}
    */
    private async create(
        partitionKey: string,
        resourceType: ResourceType
    ): Promise<Counter> {
        try {
            this.logger.info(
                `Creating Counter for partitionKey: ${partitionKey}, resourceType: ${resourceType}`,
                undefined,
                [CounterAdoptingSerialNumberGenerator.CREATE_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const createCounterResponse: GraphQLResult<CreateCounterMutation> = (await this.api.graphql(
                this.gqlOperation(
                    createCounter,
                    {
                        input: {
                            id: partitionKey,
                            type: resourceType
                        }
                    },
                    authToken
                )
            )) as GraphQLResult<CreateCounterMutation>;
            return createCounterResponse.data?.createCounter as Counter;
        } catch (error) {
            const errorMessage: string = `Unable to create Counter for partitionKey: ${partitionKey}, resourceType: ${resourceType}`;
            this.logger.warn(
                errorMessage,
                error,
                [CounterAdoptingSerialNumberGenerator.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreateCounterError(errorMessage, error as Error);
        }
    }

    /**
     * Update Counter 
     * @throws {UpdateCounterError}
    */
    private async update(
        partitionKey: string,
        resourceType: ResourceType
    ): Promise<Counter> {
        try {
            this.logger.info(
                `Updating Counter for partitionKey: ${partitionKey}, resourceType: ${resourceType}`,
                undefined,
                [CounterAdoptingSerialNumberGenerator.UPDATE_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const currentCounter: Counter = await this.getByPartitionKeyAndResourceType(partitionKey, resourceType);
            const updateCounterResponse: GraphQLResult<UpdateCounterMutation> = (await this.api.graphql(
                this.gqlOperation(
                    updateCounter,
                    {
                        input: {
                            id: partitionKey,
                            _version: (currentCounter as any)._version
                        }
                    },
                    authToken
                )
            )) as GraphQLResult<UpdateCounterMutation>;
            return updateCounterResponse.data?.updateCounter as Counter;
        } catch (error) {
            const errorMessage: string = `Unable to update Counter for partitionKey: ${partitionKey}, resourceType: ${resourceType}`;
            this.logger.error(
                errorMessage,
                error,
                [CounterAdoptingSerialNumberGenerator.UPDATE_FAILURE_METRIC_NAME]
            );
            throw new UpdateCounterError(errorMessage, error as Error);
        }
    }

    /*
     * Attempts to increment the counter matching partition ID or creates one if it does not exist.
     */
    public async generate(
        partitionKey: string,
        resourceType: ResourceType
    ): Promise<number> {
        try {
            return await this._generate(partitionKey, resourceType);
        } catch (error) {
            throw new SerialNumberGenerationError(
                `Failed to generate serial number with partitionKey=${partitionKey} and resourceType=${resourceType}`,
                {cause: error as Error}
            )
        }
    }

    private async _generate(
        partitionKey: string,
        resourceType: ResourceType
    ): Promise<number> {
        try {
            const createdCounter: Counter = await this.create(partitionKey, resourceType);
            return createdCounter._version;
        } catch (error) {
            const updatedCounter: Counter = await this.update(partitionKey, resourceType);
            return updatedCounter._version;
        }
    }

    /**
     * Get Counter by partitionKey and resourceType≈
     * @throws {GetCounterByPartitionKeyAndResourceTypeError}
    */
    private async getByPartitionKeyAndResourceType(
        partitionKey: string,
        resourceType: ResourceType
    ): Promise<Counter> {
        try {
            this.logger.info(
                `Getting counter by partitionKey: ${partitionKey}, resourceType: ${resourceType}`,
                undefined,
                [CounterAdoptingSerialNumberGenerator.GET_BY_PARTITION_KEY_AND_RESOURCE_TYPE_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const queryResult = await this.api.graphql(
                this.gqlOperation(
                    getCounter,
                    {
                        id: partitionKey,
                        type: resourceType
                    },
                    authToken
                )
            ) as GraphQLResult<GetCounterQuery>;
            return queryResult.data?.getCounter as Counter;
        } catch (error) {
            const errorMessage: string = `Unable to get counter by partitionKey: ${partitionKey}, resourceType: ${resourceType}`;
            this.logger.error(
                errorMessage,
                error,
                [CounterAdoptingSerialNumberGenerator.GET_BY_PARTITION_KEY_AND_RESOURCE_TYPE_FAILURE_METRIC_NAME]
            );
            throw new GetCounterByPartitionKeyAndResourceTypeError(errorMessage, error as Error);
        }
    }
}
