import {
    Box,
    Button,
    Checkbox,
    Collapse,
    ThemeProvider,
    Typography
} from "@mui/material";
import {
    ChevronDownIcon,
    ChevronUpIcon
} from "../../components/icons";
import {
    isSelectionModeAtomFamily,
    selectionFeatureConfigurationByContextAtomFamily
} from "../../lib/design/document/state/SelectionModeState";
import {
    memo,
    useEffect,
    useMemo,
    useRef
} from "react";
import {
    useRecoilValue,
    useResetRecoilState
} from "recoil";

import { AttachmentImageCarousel } from "../image/AttachmentImageCarousel";
import { CHECK_BOX_WIDTH } from "../../lib/ui/Constants";
import { ChildrenExpandState } from "../../lib/design/document/state/enum/ChildrenExpandState";
import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { ISSUE_PAGINATION_GROUP_ID_PREFIX } from "../../lib/pagination/Constants";
import IssueManager from "../../components/issue/IssueManager";
import { IssueSummaryByLocation } from "../issue/IssueSummaryByLocation";
import LocationCardHeader from "./LocationCardHeader";
import { LocationFeatureConfiguration } from "../../lib/design/document/state/LocationFeatureConfiguration";
import { ModelType } from "../../lib/design/document/ModelType";
import { PermissionResourceType } from "../../models";
import { PermissionRestrictedControl } from "../../components/general/button/PermissionRestrictedControl";
import { Resource } from "../permission/type/Resource";
import { SelectionBehavior } from "../../lib/ui/SelectionBehavior";
import { SelectionFeatureConfiguration } from "../../lib/design/document/state/SelectionFeatureConfiguration";
import { StateContext } from "../../lib/design/document/state/StateContext";
import ValidationResult from "../../lib/util/validation/ValidationResult";
import { childrenExpandStateByDesignElementIdentifierAtomFamily } from "../../lib/design/document/state/ComponentExpandState";
import { currentPageByGroupIdAtomFamily } from "../../lib/pagination/PaginationBarState";
import { elementFocusManager } from "../../lib/ui/ElementFocusManager";
import { green } from "../../assets/style/colors";
import { isLocationSelectedByLocationIdentifierSelectorFamily } from "../../lib/design/document/state/IssuePickerState";
import { isUpdatingPermissionByResourceAtomFamily } from "../permission/state/ManagePermissionState";
import { issueTheme } from "../../assets/style/issueTheme";
import { locationFeatureConfigurationByContextAtomFamily } from "../../lib/design/document/state/DocumentState";
import { useScrollResourceIntoView } from "../../lib/ui/hooks/useScrollResourceIntoView";
import { useSelectResource } from "../../lib/design/document/hooks/useSelectResource";
import { useSetChildrenExpandState } from "../../lib/design/hooks/useSetChildrenExpandState";
import { useTheme } from "@mui/styles";
import { validationResultByDesignElementIdentifierSelectorFamily } from "../../lib/design/document/state/DocumentValidationState";

const responsiveCardHeights = {
    xs: "245px",
    sm: "270px",
    lg: "300px"
};

export interface LocationCardProps {
    readonly locationId: string;
    readonly stateContext: StateContext;
}

const LocationCard = (props: LocationCardProps) => {
    /* Internal page states */
    const { locationId, stateContext } = props;
    const locationIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(locationId, stateContext, ModelType.LOCATION);
    }, [locationId, stateContext]);
    const resource = useMemo<Resource>(() => {
        return new Resource(locationId, PermissionResourceType.LOCATION);
    }, [locationId]);

    const resetLocationIssueCurrentPage = useResetRecoilState(currentPageByGroupIdAtomFamily(ISSUE_PAGINATION_GROUP_ID_PREFIX + locationId));
    const childrenExpandState = useRecoilValue<ChildrenExpandState>(childrenExpandStateByDesignElementIdentifierAtomFamily(locationIdentifier));
    const isValidLocation = useRecoilValue<ValidationResult>(validationResultByDesignElementIdentifierSelectorFamily(locationIdentifier));
    const locationFeatureConfiguration = useRecoilValue<LocationFeatureConfiguration>(locationFeatureConfigurationByContextAtomFamily(stateContext));
    const isLocationSelected = useRecoilValue<boolean>(isLocationSelectedByLocationIdentifierSelectorFamily(locationIdentifier));
    const selectionFeatureConfiguration = useRecoilValue<SelectionFeatureConfiguration>(selectionFeatureConfigurationByContextAtomFamily(stateContext));
    const isSelectionMode = useRecoilValue<boolean>(isSelectionModeAtomFamily(stateContext));
    const isUpdatingResourcePermission = useRecoilValue<boolean>(isUpdatingPermissionByResourceAtomFamily(resource));
    const allowToSelectLocation = locationFeatureConfiguration.selectionBehavior !== SelectionBehavior.DISABLED;
    const { selectLocation } = useSelectResource(stateContext);

    /* Dependencies */
    const theme = useTheme();
    const scrollElementRef = useRef<Element>();
    const { scrollResourceIntoView } = useScrollResourceIntoView(stateContext);
    const { setModelChildrenExpandState, resetChildrenExpandState } = useSetChildrenExpandState(stateContext);
    const setChildrenExpandState = (expandState: ChildrenExpandState) => {
        if (expandState === ChildrenExpandState.COLLAPSE_CHILDREN) {
            elementFocusManager.resetLocationElement();
        }
        if (expandState === ChildrenExpandState.EXPAND_CHILDREN && scrollElementRef.current) {
            elementFocusManager.setLocationElement(scrollElementRef.current);
        } 
        setModelChildrenExpandState(ModelType.LOCATION, locationId, expandState);
    };

    useEffect(() => {
        if (isExpanded) {
            scrollResourceIntoView(scrollElementRef.current, "top");
        }
        return () => {
            resetChildrenExpandState(ModelType.LOCATION, locationId);
        };
    }, []);

    const isExpanded = childrenExpandState === ChildrenExpandState.EXPAND_CHILDREN;

    useEffect(() => {
        if (isExpanded) {
            if (scrollElementRef.current) {
                elementFocusManager.setLocationElement(scrollElementRef.current);
            }
        }
    }, [isExpanded]);

    return (
        <Box
            sx={{
                border: theme => `1px solid ${isValidLocation.isValid ? "transparent" : theme.palette.error.main}`,
                borderTop: isValidLocation.isValid ? isExpanded ? `4px solid ${theme.palette.primary.main}` : "1px solid black" : undefined,
                backgroundColor: isExpanded ? green[50] : undefined,
            }}
            display="flex"
            flexDirection="column"
            gap={1}
            ref={scrollElementRef}
        >
            <Typography variant="caption" color="red">{isValidLocation.message}</Typography>
            <LocationCardHeader
                locationId={locationId}
                stateContext={stateContext}
            />
            <Box
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="stretch"
            >
                {allowToSelectLocation && isSelectionMode && (
                    <Box width={CHECK_BOX_WIDTH} display="flex">
                        <PermissionRestrictedControl
                            requiredPermission={selectionFeatureConfiguration.requiredPermission}
                            permissionOverridden={!selectionFeatureConfiguration.permissionChecking}
                            resourceId={locationId}
                        >
                            {(disabled) => (
                                <Checkbox
                                    checked={isLocationSelected}
                                    onChange={(e) => {
                                        selectLocation(locationId, e.target.checked);
                                    }}
                                    disabled={disabled || isUpdatingResourcePermission}
                                />
                            )}
                        </PermissionRestrictedControl>
                    </Box>
                )}
                <Box
                    display="flex"
                    width={allowToSelectLocation && isSelectionMode ? `calc(100% - ${CHECK_BOX_WIDTH})` : "100%"}
                    sx={{
                        flexDirection: {
                            xs: "column",
                            sm: "row"
                        }
                    }}
                >
                    <Box
                        sx={{
                            height: responsiveCardHeights,
                            aspectRatio: "16/9",
                            maxWidth: {
                                sm: "60%"
                            }
                        }}
                        flex="0 1 auto"
                        minWidth={0}
                    >
                        <AttachmentImageCarousel attachmentParent={{ id: locationId, type: PermissionResourceType.LOCATION }} />
                    </Box>
                    <Box
                        sx={{
                            maxHeight: responsiveCardHeights,
                            pl: {
                                xs: 0,
                                sm: 1
                            },
                            pt: {
                                xs: 1,
                                sm: 0
                            }
                        }}
                        flex={1}
                        minWidth={0}
                    >
                        <IssueSummaryByLocation
                            locationId={locationId}
                            stateContext={stateContext}
                        />
                    </Box>
                </Box>
            </Box>
            <ThemeProvider theme={isExpanded ? issueTheme : theme}>
                <Button
                    fullWidth
                    onClick={() => {
                        if (!isExpanded) {
                            setChildrenExpandState(ChildrenExpandState.EXPAND_CHILDREN);
                            return;
                        }
                        setChildrenExpandState(ChildrenExpandState.COLLAPSE_CHILDREN);
                        resetLocationIssueCurrentPage();
                    }}
                    color="primary"
                    variant="contained"
                    sx={{
                        borderRadius: 0,
                        mt: 1,
                        boxShadow: "none !important",
                        "&:hover": {
                            boxShadow: "none !important"
                        }
                    }}
                    startIcon={!isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
                >
                    {!isExpanded ? "Show Issues" : "Hide Issues"}
                </Button>
                <Box width="100%" mt={-1}>
                    <Collapse
                        in={isExpanded}
                        onEntered={() => scrollResourceIntoView(scrollElementRef.current, "top")}
                        timeout={0}
                    >
                        <Box
                            width="100%"
                        >
                            <IssueManager
                                locationId={locationId}
                                stateContext={stateContext}
                            />
                        </Box>
                        <Button
                            fullWidth
                            onClick={() => {
                                if (!isExpanded) {
                                    setChildrenExpandState(ChildrenExpandState.EXPAND_CHILDREN);
                                    return;
                                }
                                setChildrenExpandState(ChildrenExpandState.COLLAPSE_CHILDREN);
                                scrollResourceIntoView(scrollElementRef.current, "top");
                            }}
                            color="primary"
                            variant="contained"
                            sx={{
                                borderRadius: 0,
                                boxShadow: "none !important",
                                "&:hover": {
                                    boxShadow: "none !important"
                                }
                            }}
                            startIcon={!isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
                        >
                            {!isExpanded ? "Show Issues" : "Hide Issues"}
                        </Button>
                    </Collapse>
                </Box>
            </ThemeProvider>
        </Box>
    );
};

export default memo(LocationCard);
