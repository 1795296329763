import CreateWorkTypeHandler from "./CreateWorkTypeHandler";
import CreateWorkTypeHandlerImpl from "./CreateWorkTypeHandlerImpl";
import DataStoreWorkTypeDAOFactory from "../../dataStore/DataStoreWorkTypeDAOFactory";
import DataStoreWorkTypeGroupDAOFactory from "../../group/dataStore/DataStoreWorkTypeGroupDAOFactory";

export default class CreateWorkTypeHandlerFactory {
    private static readonly INSTANCE: CreateWorkTypeHandler = new CreateWorkTypeHandlerImpl(
        DataStoreWorkTypeDAOFactory.getInstance(),
        DataStoreWorkTypeGroupDAOFactory.getInstance()
    );

    public static getInstance(): CreateWorkTypeHandler {
        return this.INSTANCE;
    }
}
