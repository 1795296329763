import { AuthIDTokenSupplierFactory } from "../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../logging/ClientLoggerFactory";
import { GraphQLTemplateRecordDAO } from "./GraphQLTemplateRecordDAO";
import { S3TemplateContentDAO } from "./S3TemplateContentDAO";
import { Storage } from "aws-amplify";
import { TemplateContentDAO } from "./TemplateContentDAO";
import { TemplateDAO } from "./TemplateDAO";
import { TemplateDAOImpl } from "./TemplateDAOImpl";
import { TemplateRecordDAO } from "./TemplateRecordDAO";

export class DefaultTemplateDAOFactory {
    private static readonly TEMPLATE_RECORD_DAO: TemplateRecordDAO = new GraphQLTemplateRecordDAO(
        ClientLoggerFactory.getClientLogger("GraphQLTemplateRecordDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );
    
    private static readonly TEMPLATE_CONTENT_DAO: TemplateContentDAO = new S3TemplateContentDAO(
        Storage,
        ClientLoggerFactory.getClientLogger("S3TemplateDAO")
    );
    
    private static readonly INSTANCE: TemplateDAO = new TemplateDAOImpl(
        DefaultTemplateDAOFactory.TEMPLATE_RECORD_DAO,
        DefaultTemplateDAOFactory.TEMPLATE_CONTENT_DAO
    );

    public static getInstance(): TemplateDAO {
        return DefaultTemplateDAOFactory.INSTANCE;
    }
}