import {
    Autocomplete,
    Box,
    IconButton,
    TextField
} from "@mui/material";
import {
    MeetingStatus,
    useMeetingStatus
} from "amazon-chime-sdk-component-library-react";
import {
    useEffect,
    useMemo,
    useState
} from "react";

import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { ModelType } from "../../lib/design/document/ModelType";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { StateContext } from "../../lib/design/document/state/StateContext";
import { WorkSpecification } from "../../models";
import globalStyle from "../../assets/style/globalStyle";
import { isAiResourceGeneratingAtomFamily } from "../../components/chime/hooks/state/AISummarizationStates";
import { useAppendToWSDescriptionQuickAction } from "../../components/chime/hooks/actions/useAppendToWSDescriptionQuickAction";
import { useRecoilValue } from "recoil";
import { workSpecificationByWorkSpecificationIdentifierSelectorFamily } from "../../lib/design/document/state/DocumentState";

interface DescriptionFieldProps {
    workSpecificationId: string;
    stateContext: StateContext;
    onSaveDescription: (description: string) => void;
    workTypeDescriptionOptions?: Array<string>;
    size?: "small" | "medium";
}

const DescriptionField = (props: DescriptionFieldProps) => {
    const { workSpecificationId, stateContext } = props;
    const workSpecificationIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(workSpecificationId, stateContext, ModelType.WORK_SPECIFICATION);
    }, [workSpecificationId, stateContext]);
    const workSpecification = useRecoilValue<WorkSpecification | null>(
        workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier)
    );
    const isAIGenerating = useRecoilValue<boolean>(isAiResourceGeneratingAtomFamily(workSpecificationId));
    const DESCRIPTION_FIELD_LENGTH = 1000;
    const [descriptionText, setDescriptionText] = useState<string>(workSpecification?.description ?? "");
    const globalClasses = globalStyle();
    const { appendToWSDescription } = useAppendToWSDescriptionQuickAction();
    const meetingStatus = useMeetingStatus();
    const isMeetingStarted = meetingStatus === MeetingStatus.Succeeded || meetingStatus === MeetingStatus.Reconnecting;

    useEffect(() => {
        setDescriptionText(workSpecification?.description ?? "");
    }, [workSpecification?.description]);

    return (
        <Autocomplete
            freeSolo
            options={props.workTypeDescriptionOptions ?? []}
            fullWidth
            size={props.size || "medium"}
            renderInput={(params) => (
                <TextField
                    multiline
                    label="Description"
                    variant="outlined"
                    onChange={(event) => setDescriptionText(event.target.value)}
                    onBlur={(event) => props.onSaveDescription(descriptionText)}
                    {...params}
                    inputProps={{ ...params.inputProps, maxLength: DESCRIPTION_FIELD_LENGTH }}
                    InputProps={{
                        endAdornment: isMeetingStarted ? (
                            <Box display="flex" flex={0} alignSelf="flex-start">
                                <IconButton
                                    className={`${globalClasses.outlinedMenuButton} ${globalClasses.mediumSquareButton}`}
                                    title="Add to description"
                                    onClick={() => {
                                        appendToWSDescription(workSpecificationId);
                                    }}
                                >
                                    <PlaylistAddIcon />
                                </IconButton>
                            </Box>
                        ) : undefined
                    }}
                    disabled={isAIGenerating}
                />
            )}
            onChange={(event, value) => setDescriptionText(value)}
            onBlur={(event) => props.onSaveDescription(descriptionText)}
            value={descriptionText}
            groupBy={() => "Import from Work Type"}
            disableClearable
        />
    );
};
export default DescriptionField;
