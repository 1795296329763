import {
    Box,
    Typography
} from "@mui/material";

import { ContextAwareIdentifier } from "../../../lib/design/document/ContextAwareIdentifier";
import { Location } from "../../../models";
import { ModelType } from "../../../lib/design/document/ModelType";
import NumberStringConverter from "../../../lib/util/NumberStringConverter";
import { StateContext } from "../../../lib/design/document/state/StateContext";
import { WORD_PROPOSAL_METADATA } from "./ViewSolutionStyles";
import { hidePricesInUIAtom } from "../../../lib/design/bidding/state/v2/ProposalStates";
import { locationByLocationIdentifierSelectorFamily } from "../../../lib/design/document/state/DocumentState";
import { proposalItemPriceTotalByLocationIdentifierSelectorFamily } from "../../../lib/design/bidding/state/v2/ProposalItemStates";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";

type ViewLocationSummaryProps = {
    readonly locationId: string;
};

export const ViewLocationSummary = (props: ViewLocationSummaryProps) => {
    const { locationId } = props;
    const locationIdentifier = useMemo(() => {
        return new ContextAwareIdentifier(locationId, StateContext.SOLUTION_VIEWING, ModelType.LOCATION);
    }, [locationId]);
    const location = useRecoilValue<Location | null>(locationByLocationIdentifierSelectorFamily(locationIdentifier));
    const totalPrice = useRecoilValue<number>(proposalItemPriceTotalByLocationIdentifierSelectorFamily(locationIdentifier));
    const hidePrices = useRecoilValue<boolean>(hidePricesInUIAtom);

    return (
        <>
            <Typography style={WORD_PROPOSAL_METADATA} >
                {location?.name}:
            </Typography>
            <Box component="p" style={{ textAlign: "right" }}>
                <Typography component="span" style={WORD_PROPOSAL_METADATA}>
                    {hidePrices ? (
                        `$ -`
                    ) : (
                        `$ ${NumberStringConverter.numberToString(totalPrice,
                            0,
                            true
                        )}`
                    )}
                </Typography>
            </Box>
        </>
    );
};