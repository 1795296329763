import Task from "./Task";
import RateLimiter from "../ratelimit/RateLimiter";

export default class RateLimitedTaskDecorator<T> implements Task<T> {
    private readonly rateLimiter: RateLimiter;
    private readonly delegate: Task<T>;

    constructor(
        rateLimiter: RateLimiter,
        delegate: Task<T>
    ) {
        this.rateLimiter = rateLimiter;
        this.delegate = delegate;
    }

    public async run(): Promise<T> {
        await this.rateLimiter.acquire();
        try {
            const result = await this.delegate.run();
            this.rateLimiter.reportSuccess();
            return result;
        } catch (error) {
            this.rateLimiter.reportFailure();
            throw error;
        }
    }
}