import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { DataStoreCompletionEventListenerFactory } from "./lifecycle/DataStoreCompletionEventListenerFactory";
import { DataStoreLifecycleController } from "./lifecycle/DataStoreLifecycleController";
import DataStorePropertyDAOFactory from "../../property/dao/datastore/DataStorePropertyDAOFactory";
import { DataStoreSynchronizationOrchestrator } from "./DataStoreSynchronizationOrchestrator";
import { HierarchicalDataStoreSynchronizationOrchestrator } from "./HierarchicalDataStoreSynchronizationOrchestrator";
import { HierarchicalLegacyDataStoreConfigurationManagerFactory } from "./configuration/HierarcicalLegacyDataStoreConfigurationManagerFactory";
import { MAX_OFFLINE_PROPERTIES } from "./DataStoreSynchronizationConstants";
import { OfflinePropertyListManagerFactory } from "./offline/OfflinePropertyListManagerFactory";
import { RecentlyCreatedPropertyListManagerFactory } from "./offline/RecentlyCreatedPropertyListManagerFactory";
import { SetterOrUpdater } from "recoil";

export class HierarchicalDataStoreSynchronizationOrchestratorFactory {
    private static configManager = HierarchicalLegacyDataStoreConfigurationManagerFactory.getInstance();
    private static INSTANCE: DataStoreSynchronizationOrchestrator | undefined;

    public static getInstance(
        setIsDataStoreStopped: SetterOrUpdater<boolean>
    ): DataStoreSynchronizationOrchestrator {
        if (this.INSTANCE) {
            return this.INSTANCE;
        }
        this.INSTANCE = new HierarchicalDataStoreSynchronizationOrchestrator(
            this.configManager,
            new DataStoreLifecycleController(
                this.configManager,
                DataStoreCompletionEventListenerFactory.getInstance(),
                setIsDataStoreStopped
            ),
            OfflinePropertyListManagerFactory.getInstance(),
            RecentlyCreatedPropertyListManagerFactory.getInstance(),
            DataStorePropertyDAOFactory.getInstance(),
            MAX_OFFLINE_PROPERTIES,
            ClientLoggerFactory.getClientLogger("HierarchicalDataStoreSynchronizationOrchestratorFactory")
        );
        return this.INSTANCE;
    }
}