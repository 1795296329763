import {
    Location,
    PermissionType
} from "../../models";
import {
    getCleanUpPropertyStates,
    getFetchLocationCallback,
    recentlyCreatedRecordIds,
} from "../../lib/design/document/state/DocumentState";
import {
    isSelectionModeAtomFamily,
    selectionFeatureConfigurationByContextAtomFamily
} from "../../lib/design/document/state/SelectionModeState";
import {
    useEffect,
    useRef,
    useState
} from "react";
import {
    useResetRecoilState,
    useSetRecoilState
} from "recoil";

import DataStoreLocationDAOFactory from "../../lib/location/dao/datastore/DataStoreLocationDAOFactory";
import { LinearProgress } from "@mui/material";
import LocationDAO from "../../lib/location/dao/LocationDAO";
import LocationManager from "../location/LocationManager";
import { SelectionFeatureConfiguration } from "../../lib/design/document/state/SelectionFeatureConfiguration";
import { SidebarDrawerConfiguration } from "../../lib/sidebar/SidebarDrawerConfiguration";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { WorkScopeSpecificationQuickCreateDialog } from "../../components/wss/WorkScopeSpecificationQuickCreateDialog";
import { getCleanUpProposalStates } from "../../lib/design/bidding/state/v2/ProposalStates";
import { getResetIssueFilter } from "../../lib/issue/state/IssueFilterRecoilState";
import loadable from "@loadable/component";
import { sidebarDrawerConfigurationsAtom } from "../../lib/sidebar/SidebarRecoilStates";
import { stateContextInFocusAtom } from "../../lib/ui/InFocusRecoilStates";
import { useSelectResource } from "../../lib/design/document/hooks/useSelectResource";

const INSPECTION_SIDEBAR_DRAWER_CONFIGURATION: SidebarDrawerConfiguration = {
    displayValidationButton: true,
    displayIssueFilteringButton: true,
};

type InspectionProps = {
    readonly propertyId: string;
};

const LiveInspectionDrawer = loadable(async () => {
    const { LiveInspectionDrawer } = await import("../../components/chime/LiveInspectionDrawer");
    return LiveInspectionDrawer;
});

const Inspection = (props: InspectionProps) => {
    /* Constants */
    const INSPECTION_STATE_CONTEXT: StateContext = StateContext.INSPECTION;
    const INSPECTION_SELECTION_FEATURE_CONFIGURATION: SelectionFeatureConfiguration = {
        permissionChecking: true,
        requiredPermission: PermissionType.SHARE_PERMISSION
    };
    /* Internal page state */
    const { propertyId } = props;
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const resetRecentlyCreatedRecordIds = useResetRecoilState(recentlyCreatedRecordIds);
    const setSelectionFeatureConfiguration = useSetRecoilState<SelectionFeatureConfiguration>(selectionFeatureConfigurationByContextAtomFamily(INSPECTION_STATE_CONTEXT));
    const setSidebarMainContext = useSetRecoilState<StateContext>(stateContextInFocusAtom);
    const setSidebarDrawerConfiguration = useSetRecoilState<SidebarDrawerConfiguration>(sidebarDrawerConfigurationsAtom);

    /* Dependencies */
    const cleanUpPropertyStates: () => void = getCleanUpPropertyStates(INSPECTION_STATE_CONTEXT);
    const cleanUpProposalStates: () => void = getCleanUpProposalStates(INSPECTION_STATE_CONTEXT);
    const locationDaoRef = useRef<LocationDAO>(DataStoreLocationDAOFactory.getInstance());
    const resetIssueFilter = getResetIssueFilter();
    const fetchLocation = getFetchLocationCallback();
    const { resetSelectedResources } = useSelectResource(INSPECTION_STATE_CONTEXT);
    const resetIsSelectionMode = useResetRecoilState(isSelectionModeAtomFamily(INSPECTION_STATE_CONTEXT));
    const resetSidebarMainContext = useResetRecoilState(stateContextInFocusAtom);
    const resetSidebarDrawerConfiguration = useResetRecoilState(sidebarDrawerConfigurationsAtom);

    useEffect(() => {
        const initializePageData = async () => {
            setSelectionFeatureConfiguration(INSPECTION_SELECTION_FEATURE_CONFIGURATION);
            await fetchPropertyLocations();
            setSidebarMainContext(INSPECTION_STATE_CONTEXT);
            setSidebarDrawerConfiguration(INSPECTION_SIDEBAR_DRAWER_CONFIGURATION);
            setIsPageLoading(false);
        };
        initializePageData();
        return () => {
            cleanUpPropertyStates();
            cleanUpProposalStates();
            resetIssueFilter();
            resetRecentlyCreatedRecordIds();
            resetSelectedResources();
            resetIsSelectionMode();
            resetSidebarMainContext();
            resetSidebarDrawerConfiguration();
        };
    }, []);

    const fetchPropertyLocations = async () => {
        const locations: Array<Location> = await locationDaoRef.current.getLocationsByPropertyId(propertyId);
        await Promise.all(locations.map(location => fetchLocation(location.id, StateContext.INSPECTION)));
    };

    return (
        isPageLoading ? (
            <LinearProgress />
        ) : (
            <>
                <LocationManager stateContext={INSPECTION_STATE_CONTEXT} propertyId={propertyId} />
                <WorkScopeSpecificationQuickCreateDialog />
                <LiveInspectionDrawer />
            </>
        )
    );
};

export default Inspection;
