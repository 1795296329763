import {
    API,
    graphqlOperation
} from "aws-amplify";
import {
    CreateDefaultWorkTypePricingMutation,
    GetDefaultWorkTypePricingQuery,
    UpdateDefaultWorkTypePricingMutation
} from "../../../../API";
import {
    createDefaultWorkTypePricing,
    updateDefaultWorkTypePricing
} from './../../../../graphql/mutations';

import ClientLogger from "../../../logging/ClientLogger";
import CreateDefaultWorkTypePricingError from "../error/CreateDefaultWorkTypePricingError";
import { DefaultWorkTypePricing } from "../../../../models";
import DefaultWorkTypePricingDAO from "../DefaultWorkTypePricingDAO";
import GetDefaultWorkTypePricingByIdError from "../error/GetDefaultWorkTypePricingByIdError";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { IDTokenSupplier } from "../../../auth/IDTokenSupplier";
import UpdateDefaultWorkTypePricingError from "../error/UpdateDefaultWorkTypePricingError";
import { getDefaultWorkTypePricing } from './../../../../graphql/queries';

export default class GraphQLDefaultWorkTypePricingDAO implements DefaultWorkTypePricingDAO {
    private static readonly CREATE_ATTEMPT_METRIC_NAME = "GraphQLDefaultWorkTypePricingDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "GraphQLDefaultWorkTypePricingDAO.Create.Failure";
    private static readonly UPDATE_ATTEMPT_METRIC_NAME = "GraphQLDefaultWorkTypePricingDAO.Update.Attempt";
    private static readonly UPDATE_FAILURE_METRIC_NAME = "GraphQLDefaultWorkTypePricingDAO.Update.Failure";
    private static readonly GET_BY_ID_ATTEMPT_METRIC_NAME = "GraphQLDefaultWorkTypePricingDAO.GetById.Attempt";
    private static readonly GET_BY_ID_FAILURE_METRIC_NAME = "GraphQLDefaultWorkTypePricingDAO.GetById.Failure";

    private logger: ClientLogger;
    private gqlOperation: typeof graphqlOperation;
    private api: typeof API;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async create(
        defaultWorkTypePricing: DefaultWorkTypePricing
    ): Promise<DefaultWorkTypePricing> {
        try {
            this.logger.info(
                `Creating defaultWorkTypePricing`,
                undefined,
                [GraphQLDefaultWorkTypePricingDAO.CREATE_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<CreateDefaultWorkTypePricingMutation> = await this.api.graphql(
                this.gqlOperation(
                    createDefaultWorkTypePricing,
                    {
                        input: {
                            id: defaultWorkTypePricing.id,
                            unitPrice: defaultWorkTypePricing.unitPrice,
                            unitCost: defaultWorkTypePricing.unitCost
                        }
                    },
                    authToken
                )
            ) as GraphQLResult<CreateDefaultWorkTypePricingMutation>;
            return result.data?.createDefaultWorkTypePricing as DefaultWorkTypePricing;
        } catch (error) {
            const errorMessage: string = `Unable to create defaultWorkTypePricing`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLDefaultWorkTypePricingDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreateDefaultWorkTypePricingError(errorMessage, error as Error);
        }
    }

    public async update(
        updatedPricing: DefaultWorkTypePricing
    ): Promise<DefaultWorkTypePricing> {
        try {
            this.logger.info(
                `Updating defaultWorkTypePricing: ${updatedPricing.id}`,
                undefined,
                [GraphQLDefaultWorkTypePricingDAO.UPDATE_ATTEMPT_METRIC_NAME]
            );
            const currentDefaultWorkTypePricing: DefaultWorkTypePricing | undefined = await this.getById(updatedPricing.id);
            if (!currentDefaultWorkTypePricing) {
                throw new Error("DefaultWorkTypePricing not found")
            }

            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<UpdateDefaultWorkTypePricingMutation> = await this.api.graphql(
                this.gqlOperation(
                    updateDefaultWorkTypePricing,
                    {
                        input: {
                            id: updatedPricing.id,
                            unitPrice: updatedPricing.unitPrice,
                            unitCost: updatedPricing.unitCost,
                            _version: (currentDefaultWorkTypePricing as any)._version,
                        }
                    },
                    authToken
                )
            ) as GraphQLResult<UpdateDefaultWorkTypePricingMutation>;
            return result.data?.updateDefaultWorkTypePricing as DefaultWorkTypePricing;
        } catch (error) {
            const errorMessage: string = `Unable to update defaultWorkTypePricing: ${updatedPricing.id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLDefaultWorkTypePricingDAO.UPDATE_FAILURE_METRIC_NAME]
            );
            throw new UpdateDefaultWorkTypePricingError(errorMessage, error as Error);
        }
    }

    public async getById(
        id: string
    ): Promise<DefaultWorkTypePricing | undefined> {
        try {
            this.logger.info(
                `Getting DefaultWorkTypePricing: ${id}`,
                undefined,
                [GraphQLDefaultWorkTypePricingDAO.GET_BY_ID_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const queryResult = await this.api.graphql(
                this.gqlOperation(
                    getDefaultWorkTypePricing,
                    { id: id },
                    authToken
                )
            ) as GraphQLResult<GetDefaultWorkTypePricingQuery>;
            const result = queryResult.data?.getDefaultWorkTypePricing as DefaultWorkTypePricing;
            if (!result) {
                return undefined;
            }
            return result;
        } catch (error) {
            const errorMessage: string = `Unable to get DefaultWorkTypePricing: ${id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLDefaultWorkTypePricingDAO.GET_BY_ID_FAILURE_METRIC_NAME]
            );
            throw new GetDefaultWorkTypePricingByIdError(errorMessage, error as Error);
        }
    }
}
