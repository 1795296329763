import {
    Box,
    Card,
    Typography
} from "@mui/material";
import {
    quickCreateIssueHotKeysAtom,
    quickCreateLocationHotKeysAtom,
    quickCreateWorkSpecificationHotKeysAtom,
    takeSnapshotHotKeysAtom,
    toggleMuteHotKeysAtom
} from "../../lib/settings/SettingStates";
import {
    useRecoilState,
    useResetRecoilState
} from "recoil";

import { UpdateHotKeyItem } from "./UpdateHotKeyItem";

export const HotKeySettings = () => {
    const [quickCreateLocationHotKeys, setQuickCreateLocationHotKeys] = useRecoilState(quickCreateLocationHotKeysAtom);
    const [quickCreateIssueHotKeys, setQuickCreateIssueHotKeys] = useRecoilState(quickCreateIssueHotKeysAtom);
    const [quickCreateWorkSpecificationHotKeys, setQuickCreateWorkSpecificationHotKeys] = useRecoilState(quickCreateWorkSpecificationHotKeysAtom);
    const [takeSnapshotHotKeys, setTakeSnapshotHotKeys] = useRecoilState(takeSnapshotHotKeysAtom);
    const [toggleMuteHotKeys, setToggleMuteHotKeys] = useRecoilState(toggleMuteHotKeysAtom);

    const resetQuickCreateLocationHotKeys = useResetRecoilState(quickCreateLocationHotKeysAtom);
    const resetQuickCreateIssueHotKeys = useResetRecoilState(quickCreateIssueHotKeysAtom);
    const resetQuickCreateWorkSpecificationHotKeys = useResetRecoilState(quickCreateWorkSpecificationHotKeysAtom);
    const resetTakeSnapshotHotKeys = useResetRecoilState(takeSnapshotHotKeysAtom);
    const resetToggleMuteHotKeys = useResetRecoilState(toggleMuteHotKeysAtom);

    return (
        <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            gap={1}
        >
            <Typography variant="h6" component="h2" fontWeight="400">
                Hot Key Settings
            </Typography>
            <Card sx={{ p: 2 }}>
                <Box display="grid" gridTemplateColumns="30px repeat(2, 1fr)" gap={1} alignItems="center">
                    <Box />
                    <Typography fontWeight={500}>
                        Command
                    </Typography>
                    <Typography fontWeight={500}>
                        Keybinding
                    </Typography>
                    <UpdateHotKeyItem
                        hotKey={quickCreateLocationHotKeys}
                        setHotKeys={setQuickCreateLocationHotKeys}
                        resetHotKeys={resetQuickCreateLocationHotKeys}
                        commandName="Create Location"
                    />
                    <UpdateHotKeyItem
                        hotKey={quickCreateIssueHotKeys}
                        setHotKeys={setQuickCreateIssueHotKeys}
                        resetHotKeys={resetQuickCreateIssueHotKeys}
                        commandName="Create Issue"
                    />
                    <UpdateHotKeyItem
                        hotKey={quickCreateWorkSpecificationHotKeys}
                        setHotKeys={setQuickCreateWorkSpecificationHotKeys}
                        resetHotKeys={resetQuickCreateWorkSpecificationHotKeys}
                        commandName="Create Work Specification"
                    />
                    <UpdateHotKeyItem
                        hotKey={takeSnapshotHotKeys}
                        setHotKeys={setTakeSnapshotHotKeys}
                        resetHotKeys={resetTakeSnapshotHotKeys}
                        commandName="Take Snapshot"
                    />
                    <UpdateHotKeyItem
                        hotKey={toggleMuteHotKeys}
                        setHotKeys={setToggleMuteHotKeys}
                        resetHotKeys={resetToggleMuteHotKeys}
                        commandName="Toggle Mute"
                    />
                </Box>
            </Card>
        </Box>
    );
};