import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Typography,
    useMediaQuery
} from "@mui/material";
import {
    Organization,
    PermissionResourceType,
    User
} from "../../models";
import {
    selectedIssueIdsByContextAtomFamily,
    selectedLocationIdsByContextAtomFamily
} from "../../lib/design/document/state/IssuePickerState";
import {
    useMemo,
    useState
} from "react";

import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from "@mui/lab/LoadingButton";
import { PermissionEntityType } from "../../models";
import { PermissionTemplate } from "../../lib/permission/template/PermissionTemplate";
import { PermissionTemplateSelect } from "./PermissionTemplateSelect";
import { Resource } from "./type/Resource";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { TEMPLATE_TYPE_TO_PERMISSION_MAP } from "../../lib/permission/template/PermissionTemplateConstants";
import TeamSearchBox from "../team/TeamSearchBox";
import UserSearchBox from "../../components/user/UserSearchBox";
import { propertyIdInFocusAtom } from "../../lib/ui/InFocusRecoilStates";
import theme from "../../assets/style/theme";
import { useRecoilValue } from "recoil";
import { useShareMultipleResources } from "./useShareMultipleResources";
import { userIdAtom } from "../../lib/userSession/state/UserSessionState";

type ShareMultipleResourcesDialogProps = {
    context: StateContext;
    open: boolean;
    onClose: () => void;
    onShareButtonClick: () => void;
};

export const ShareMultipleResourcesDialog = (props: ShareMultipleResourcesDialogProps) => {
    const { context, open, onClose, onShareButtonClick } = props;

    const [selectedUsers, setSelectedUsers] = useState<Array<User>>([]);
    const [isSharingResource, setIsSharingResource] = useState<boolean>(false);
    const [selectedPermissionTemplate, setSelectedPermissionTemplate] = useState<PermissionTemplate | undefined>();
    const [selectedTeams, setSelectedTeams] = useState<Array<Organization>>([]);
    const [shareToEntityType, setShareToEntityType] = useState<PermissionEntityType>(PermissionEntityType.USER);
    const { shareMultipleResources, revokeMultipleResources } = useShareMultipleResources();
    const selectedLocationIds = useRecoilValue<Array<string>>(selectedLocationIdsByContextAtomFamily(context));
    const selectedIssueIds = useRecoilValue<Array<string>>(selectedIssueIdsByContextAtomFamily(context));
    const propertyId = useRecoilValue<string | null>(propertyIdInFocusAtom);

    const isScreenSizeXs = useMediaQuery(theme.breakpoints.down("sm"));
    const loggedInUserId = useRecoilValue<string | undefined>(userIdAtom);

    const handleShareBtnClicked = () => {
        setIsSharingResource(true);
        const isRevokeRequest: boolean = selectedPermissionTemplate === PermissionTemplate.NONE;
        const selectedEntities: Array<User> | Array<Organization> = shareToEntityType === PermissionEntityType.USER ? selectedUsers : selectedTeams;
        const selectedLocationResources: Array<Resource> = selectedLocationIds.map(locationId => new Resource(locationId, PermissionResourceType.LOCATION));
        const selectedIssueResources: Array<Resource> = selectedIssueIds.map(issueId => new Resource(issueId, PermissionResourceType.ISSUE));
        let resourcesToShare: Array<Resource> = [...selectedLocationResources, ...selectedIssueResources];
        if (!isRevokeRequest) {
            const propertyResource: Resource = new Resource(propertyId!, PermissionResourceType.PROPERTY);
            resourcesToShare = [propertyResource, ...resourcesToShare];
            shareMultipleResources(shareToEntityType, selectedEntities, resourcesToShare, TEMPLATE_TYPE_TO_PERMISSION_MAP.get(selectedPermissionTemplate!)!);
        } else {
            revokeMultipleResources(shareToEntityType, selectedEntities, resourcesToShare);
        }
        setSelectedPermissionTemplate(undefined);
        setSelectedUsers([]);
        setSelectedTeams([]);
        setIsSharingResource(false);
        onShareButtonClick();
    };

    const disableShareButton: boolean = useMemo(() => {
        if (selectedPermissionTemplate == undefined) {
            return true;
        }
        switch (shareToEntityType) {
            case PermissionEntityType.USER:
                return selectedUsers.length === 0;
            case PermissionEntityType.TEAM:
                return selectedTeams.length === 0;
        }
    }, [selectedPermissionTemplate, selectedUsers, selectedTeams, shareToEntityType]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={isScreenSizeXs}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle sx={{ py: 0.5 }}>
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                        <Typography variant="h5" fontWeight={500}>Share Permissions</Typography>
                    </Box>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ padding: isScreenSizeXs ? 0 : 2 }}>
                <Grid
                    container
                    p={1}
                    spacing={0.5}
                    alignItems="flex-end"
                >
                    <Grid item xs={12} md={6}>
                        <FormControl size="small" fullWidth>
                            <RadioGroup row value={shareToEntityType} onChange={(e) => {
                                setSelectedTeams([]);
                                setSelectedUsers([]);
                                setShareToEntityType((e.target.value as PermissionEntityType));
                            }}>
                                <FormControlLabel value={PermissionEntityType.USER} control={<Radio size="small" />} label="User" />
                                <FormControlLabel value={PermissionEntityType.TEAM} control={<Radio size="small" />} label="Team" />
                            </RadioGroup>
                            {shareToEntityType === PermissionEntityType.TEAM && (
                                <TeamSearchBox
                                    selectedTeam={selectedTeams}
                                    multiple={true}
                                    onClear={() => setSelectedTeams([])}
                                    onSelect={(teams: Array<Organization>) => setSelectedTeams(teams)}
                                    label={"Share to Team"}
                                />
                            )}
                            {shareToEntityType === PermissionEntityType.USER && (
                                <UserSearchBox
                                    onSelect={(users: Array<User>) => setSelectedUsers(users)}
                                    onClear={() => setSelectedUsers([])}
                                    userIdsToExclude={[loggedInUserId!]}
                                    selectedUsers={selectedUsers}
                                    label={"Share to User"}
                                />
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={8} md={3}>
                        <PermissionTemplateSelect
                            permissionTemplateOptions={Array.from(TEMPLATE_TYPE_TO_PERMISSION_MAP.keys())}
                            selectedTemplate={selectedPermissionTemplate}
                            onSelectedTemplateChange={setSelectedPermissionTemplate}
                            variant="outlined"
                            fullWidth
                            label="Permissions"
                            displayRemoveUser={true}
                        />
                    </Grid>
                    <Grid item xs={4} md={3} display="flex">
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            loading={isSharingResource}
                            startIcon={<></>}
                            loadingPosition="start"
                            onClick={handleShareBtnClicked}
                            sx={{ height: theme.mixins.toolbar.minHeight }}
                            disabled={disableShareButton}
                        >
                            Share
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};