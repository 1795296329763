
import DefaultProposalItemPriceAfterAdjustmentCalculator from "./DefaultProposalItemPriceAfterAdjustmentCalculator";
import DefaultUnitPriceCalculatorFactory from "./DefaultUnitPriceCalculatorFactory";
import ProposalItemPriceAfterAdjustmentCalculator from "./ProposalItemPriceAfterAdjustmentCalculator";

export default class DefaultProposalItemPriceAfterAdjustmentCalculatorFactory {
    private static readonly INSTANCE: ProposalItemPriceAfterAdjustmentCalculator = new DefaultProposalItemPriceAfterAdjustmentCalculator(
        DefaultUnitPriceCalculatorFactory.getInstance()
    );
    public static getInstance() {
        return this.INSTANCE;
    }
}
