import { ComponentType } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../assets/style/theme";

export function withThemeProvider<T>(
    Component: ComponentType<T>
) {
    const WithThemeProvider = ({
        ...props
    }: any) => {
        return (
            <ThemeProvider theme={theme}>
                    <Component {...props}/>
            </ThemeProvider>
        );
    };
    return WithThemeProvider;
}