import {
    Dispatch,
    SetStateAction
} from "react";
import {
    Drawer,
    useMediaQuery
} from "@mui/material";

import { SidebarDrawerContents } from "./SidebarDrawerContent";
import theme from "../../assets/style/theme";

type SidebarDrawerProps = {
    drawerWidth: number;
    open: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const SidebarDrawer = (props: SidebarDrawerProps) => {
    const { drawerWidth, open, setIsOpen } = props;
    const isSmallerThanMd = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Drawer
            open={open}
            onClose={() => setIsOpen(false)}
            variant={isSmallerThanMd ? "temporary" : "permanent"}
            ModalProps={{
                keepMounted: isSmallerThanMd ? true : undefined,
            }}
            sx={
                isSmallerThanMd ? {
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth
                    }
                } : {
                    "& .MuiDrawer-paper": {
                        position: "fixed", // This is what keeps the sidebar overlayed "on top" of the content instead of shifting it.
                        whiteSpace: "nowrap",
                        width: drawerWidth,
                        transition: theme => theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        boxSizing: "border-box",
                        ...(!open && {
                            overflowX: "hidden",
                            transition: theme => theme.transitions.create('width', {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.leavingScreen,
                            }),
                            width: theme => ({ xs: theme.spacing(7), md: theme.spacing(9) }),
                        })
                    }
                }
            }
        >
            <SidebarDrawerContents
                isOpen={open}
                setIsOpen={setIsOpen}
            />
        </Drawer>
    );
};
