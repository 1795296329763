import {
    Box,
    Button,
    LinearProgress,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {
    availableAudioOutputDevicesSelector,
    defaultAudioOutputDeviceIdAtom
} from "../state/ChimeStates";
import {
    useEffect,
    useRef,
    useState
} from "react";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import { useAudioVolumeMonitor } from "../../../lib/util/hooks/useAudioVolumeMonitor";

export const AudioOutputDeviceTest = () => {
    const availableAudioOutputDevices = useRecoilValue(availableAudioOutputDevicesSelector);
    const [selectedAudioOutputDeviceId, setSelectedAudioOutputDeviceId] = useRecoilState(defaultAudioOutputDeviceIdAtom);
    const { monitorAudio, volume } = useAudioVolumeMonitor();
    const [isPlaying, setIsPlaying] = useState(false);
    const audioElementRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        return () => {
            audioElementRef.current?.pause();
        };
    }, []);

    const onTestBtnClicked = async () => {
        if (isPlaying) {
            audioElementRef.current?.pause();
            setIsPlaying(false);
            return;
        }
        const audio = new Audio("/audios/test.mp3");
        audio.loop = true;
        audioElementRef.current = audio;
        const cleanUpMonitoring = await monitorAudio(audio, true, selectedAudioOutputDeviceId);
        audio.onended = () => {
            cleanUpMonitoring();
            setIsPlaying(false);
        };
        setIsPlaying(true);
        audio.play();
    };

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={1} alignItems="center">
                <Select
                    value={selectedAudioOutputDeviceId ?? 'default'}
                    onChange={(e) => {
                        setSelectedAudioOutputDeviceId(e.target.value as string);
                    }}
                    size="small"
                    disabled={isPlaying}
                    fullWidth
                    sx={{ overflow: "auto" }}
                >
                    {availableAudioOutputDevices.length > 0 ? (
                        availableAudioOutputDevices.map((device) => (
                            <MenuItem key={device.deviceId} value={device.deviceId}>{device.label}</MenuItem>
                        ))
                    ) : (
                        <MenuItem value="default">Default</MenuItem>
                    )}
                </Select>
            </Box>
            <Button
                variant="outlined"
                onClick={onTestBtnClicked}
                size="small"
            >
                {isPlaying ? 'Stop' : 'Test Speaker'}
            </Button>
            <Box display="flex" alignItems="center" gap={1}>
                <Typography>Output Level: </Typography>
                <Box flex={1}>
                    <LinearProgress variant="determinate" value={volume * 100} />
                </Box>
            </Box>
        </Box>
    );
};