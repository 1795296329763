import {
    DRIVE_API_DISCOVERY_DOC_URL,
    LIMIT_TO_APP_RELATED_DRIVE_DATA_SCOPE_URL
} from "../GApiConstants";

import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { gapi } from "gapi-script";
import { isGApiInitializedAtom } from "../GApiStates";
import { useRecoilCallback } from "recoil";
import { useSnackbar } from "notistack";

const clientLogger = ClientLoggerFactory.getClientLogger("useInitializeGApi");

export const useInitializeGApi = () => {
    const snackbar = useSnackbar();

    const initializeGApi = useRecoilCallback(({ snapshot, set }) => async (
        apiKey: string,
        clientId: string,
    ) => {
        const isGApiInitialized = await snapshot.getPromise(isGApiInitializedAtom);
        if (isGApiInitialized) {
            return;
        }
        gapi.load('client:auth2', async () => {
            try {
                await gapi.client.init({
                    apiKey: apiKey,
                    clientId: clientId,
                    scope: LIMIT_TO_APP_RELATED_DRIVE_DATA_SCOPE_URL,
                    discoveryDocs: [DRIVE_API_DISCOVERY_DOC_URL]
                });
                set(isGApiInitializedAtom, true);
            } catch (e) {
                snackbar.enqueueSnackbar("Google API initialization failed, please contact support.", { variant: "error" });
                clientLogger.error(
                    "Google API initialization failed.",
                    e,
                    ["GOOGLE_API_INITIALIZATION_FAILURE"]
                );
                return;
            }
        });
    }, []);

    return { initializeGApi };
};