import {
    Box,
    Card,
    IconButton,
    Typography
} from "@mui/material";

import { CancelIcon } from "../icons";
import DescriptionIcon from '@mui/icons-material/Description';
import { useByteNumberToHumanReadable } from "../../lib/util/ui/useByteNumberToHumanReadable";

type FileCardProps = {
    file: File;
    removeFile: (file: File) => void;
};

export const FileCard = (props: FileCardProps) => {
    const { file, removeFile } = props;
    const { bytesToHumanReadable } = useByteNumberToHumanReadable();

    return (
        <Card sx={{ width: "100%", height: "100%" }}>
            <Box display="flex" flexDirection="column" gap={1} p={1} justifyContent="space-between" height="100%" width="100%">
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                    <IconButton onClick={() => removeFile(file)}>
                        <CancelIcon primary="error" accent="error" fontSize="small" />
                    </IconButton>
                </Box>
                <Box display="flex" justifyContent="center">
                    <DescriptionIcon />
                </Box>
                <Box>
                    <Typography overflow="hidden" textOverflow="ellipsis" noWrap>
                        {file.name}
                    </Typography>
                    <Typography variant="caption">
                        {bytesToHumanReadable(file.size)}
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
};
