import {
    Breadcrumbs,
    Link as MUILink,
    Typography
} from "@mui/material";

import { Link } from "react-router-dom";
import LinkData from "../lib/util/link/LinkData";

interface LinkDataAdoptingBreadcrumbsProps {
    links: LinkData[];
    currentPageText: string;
}

const LinkDataAdoptingBreadcrumbs = (props: LinkDataAdoptingBreadcrumbsProps) => {
    const { links, currentPageText } = props;

    return (
        <Breadcrumbs>
            {
                links.map((link, index) => {
                    return (
                        <MUILink
                            key={index}
                            component={Link}
                            to={link.url}
                            underline="none"
                        >
                            <Typography
                                noWrap
                                style={{
                                    maxWidth: "30vw"
                                }}
                            >
                                {link.displayText}
                            </Typography>
                        </MUILink>
                    );
                })
            }
            <Typography
                color="text.primary"
                noWrap
                style={{
                    fontWeight: "bold",
                    maxWidth: "30vw"
                }}
            >
                {currentPageText}
            </Typography>
        </Breadcrumbs>
    );
};

export default LinkDataAdoptingBreadcrumbs;
