import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export const useSearchParams = () => {
    const { search } = useLocation();

    const params = useMemo(() => {
        return new URLSearchParams(search);
    }, [search]);

    return { params };
};