import {
    Proposal,
    SolutionStatus
} from "../../../models";

import GraphQLProposalRecordDAOFactory from "../../proposal/dao/record/GraphQLProposalRecordDAOFactory";
import ProposalContent from "../../design/bidding/ProposalContent";
import ProposalContentDAO from "../../proposal/dao/content/ProposalContentDAO";
import ProposalRecordDAO from "../../proposal/dao/record/ProposalRecordDAO";
import S3ProposalContentDAOFactory from "../../proposal/dao/content/S3ProposalContentDAOFactory";
import S3SolutionMinorVersionContentDAOFactory from "../minorVersion/dao/content/v2/S3SolutionMinorVersionContentDAOFactory";
import SaveProposalHandler from "../../proposal/operation/save/SaveProposalHandler";
import SaveProposalHandlerFactory from "../../proposal/operation/save/SaveProposalHandlerFactory";
import SaveSolutionHandler from "../operation/save/v2/SaveSolutionHandler";
import SaveSolutionHandlerFactory from "../operation/save/v2/SaveSolutionHandlerFactory";
import { Solution } from "../../../models";
import SolutionContent from "../../design/types/SolutionContent";
import SolutionMinorVersionContentDAO from "../minorVersion/dao/content/v2/SolutionMinorVersionContentDAO";

const solutionMinorVersionContentDAO: SolutionMinorVersionContentDAO = S3SolutionMinorVersionContentDAOFactory.getInstance();
const saveSolutionHandler: SaveSolutionHandler = SaveSolutionHandlerFactory.getInstance();

const proposalRecordDAO: ProposalRecordDAO = GraphQLProposalRecordDAOFactory.getInstance();
const proposalContentDAO: ProposalContentDAO = S3ProposalContentDAOFactory.getInstance();
const saveProposalHandler: SaveProposalHandler = SaveProposalHandlerFactory.getInstance();

export const useCopySolutionAndProposal = () => {
    const copySolution = async (solutionToCopy: Solution) => {
        const solutionContentToCopy: SolutionContent = await solutionMinorVersionContentDAO.get(solutionToCopy.latestContentKey!);
        const newSolutionRecordToSave: Solution = {
            id: "temporarySolutionId",
            propertyId: solutionToCopy.propertyId,
            name: `${solutionToCopy.name} (copy)`,
            metadata: {
                ...solutionToCopy.metadata,
                name: `${solutionToCopy.metadata?.name} (copy)`
            },
            status: SolutionStatus.NEW_DOCUMENT
        };
        return await saveSolutionHandler.handle(newSolutionRecordToSave, solutionContentToCopy);
    };

    const copyProposal = async (
        solutionToCopy: Solution,
        newSolution: Solution
    ) => {
        const proposalRecords: Array<Proposal> = await proposalRecordDAO.listBySolutionIdAndMinorVersion(solutionToCopy.id, solutionToCopy.latestMinorVersion!);
        const proposalToCopy: Proposal = proposalRecords[0];
        const proposalContentToCopy: ProposalContent = await proposalContentDAO.get(proposalToCopy.proposalKey!);
        return await saveProposalHandler.handle(newSolution.id, newSolution.latestMinorVersion!, proposalContentToCopy);
    };

    const copySolutionAndProposal = async (
        solutionToCopy: Solution
    ) => {
        const newSolution: Solution = await copySolution(solutionToCopy);
        await copyProposal(solutionToCopy, newSolution);
        return newSolution;
    };

    return { copySolutionAndProposal };
};