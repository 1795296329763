import {
    atom,
    selector
} from "recoil";

import { DataStoreStatus } from "../DataStoreStatus";

export const dataStorePropertySyncStatusAtom = atom<DataStoreStatus>({
    key: "dataStoreSyncStatusAtom",
    default: DataStoreStatus.NOT_READY
});

export const dataStoreStatusAtom = atom<DataStoreStatus>({
    key: "dataStoreStatusAtom",
    default: DataStoreStatus.NOT_READY
});

export const isDataStoreReadySelector = selector<boolean>({
    key: "isDataStoreReadySelector",
    get: ({ get }) => {
        const datastoreStatus = get(dataStoreStatusAtom);
        return datastoreStatus !== DataStoreStatus.NOT_READY;
    }
});

export const isDataStoreStoppedAtom = atom<boolean>({
    key: "isDataStoreStoppedAtom",
    default: true
});
