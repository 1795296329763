import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const CommentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6 11h14v2H6zM6 7h8v2H6zM6 15h11v2H6z"
      className="accent"
    />
    <path
      fill="currentColor"
      d="M12 1c6.065 0 11 4.935 11 11 0 1.861-.138 3.075-.89 4.544l-.1.597.811 5.68-5.68-.811-.597.1c-1.469.752-2.683.89-4.544.89-6.065 0-11-4.935-11-11S5.935 1 12 1m0-1C5.37 0 0 5.37 0 12s5.37 12 12 12c1.97 0 3.36-.16 5-1l7 1-1-7c.84-1.64 1-3.03 1-5 0-6.63-5.37-12-12-12z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(CommentIcon);
