import { AssignablePermissionTemplateMapFilter } from "./AssignablePermissionTemplateMapFilter";
import { PermissionDifferenceCalculatorFactory } from "./PermissionDifferenceCalculatorFactory";

export class AssignablePermissionTemplateMapFilterFactory {
    private static readonly INSTANCE: AssignablePermissionTemplateMapFilter = new AssignablePermissionTemplateMapFilter(
        PermissionDifferenceCalculatorFactory.getInstance()
    );
    public static getInstance(): AssignablePermissionTemplateMapFilter {
        return this.INSTANCE;
    }
}