import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";

import { AlertMessage } from "../../lib/util/AlertMessage";
import LoadingButton from "@mui/lab/LoadingButton";
import { NoLatestProposalRecordFoundError } from "../../lib/proposal/hooks/errors/NoLatestProposalRecordFoundError";
import { useRecoverProposal } from "../../lib/proposal/hooks/useRecoverProposal";
import { useRemoveSolutionAndProposal } from "../../lib/design/document/hooks/useRemoveSolutionAndProposal";
import { useState } from "react";

type RecoverProposalDialogProps = {
    solutionId: string;
    open: boolean;
    onCancel: () => (Promise<void> | void);
    onRecovered: () => (Promise<void> | void);
    onRemoved: () => (Promise<void> | void);
};

export const RecoverProposalDialog = (props: RecoverProposalDialogProps) => {
    const { solutionId, open, onCancel, onRecovered, onRemoved } = props;
    const { recoverProposalBySolutionId } = useRecoverProposal();
    const { removeSolutionAndProposal } = useRemoveSolutionAndProposal();
    const [recovering, setRecovering] = useState<boolean>(false);
    const [removing, setRemoving] = useState<boolean>(false);
    const [disableRecoverBtn, setDisableRecoverBtn] = useState<boolean>(false);
    const [noLatestProposalRecordFound, setNoLatestProposalRecordFound] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>();

    const onRecoverBtnClicked = async () => {
        try {
            setRecovering(true);
            setAlertMessage(undefined);
            await recoverProposalBySolutionId(solutionId);
            await onRecovered();
        } catch (e) {
            if (e instanceof NoLatestProposalRecordFoundError) {
                setNoLatestProposalRecordFound(true);
                setDisableRecoverBtn(true);
                return;
            }
            setAlertMessage({
                severity: "error",
                message: (e as Error).message
            });
        } finally {
            setRecovering(false);
        }
    };

    const onRemoveBtnClicked = async () => {
        try {
            setRemoving(true);
            setAlertMessage(undefined);
            await removeSolutionAndProposal(solutionId);
            await onRemoved();
        } catch (e) {
            setAlertMessage({
                severity: "error",
                message: (e as Error).message
            });
        } finally {
            setRemoving(false);
        }
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Recover Solution</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {!noLatestProposalRecordFound && "Error occurred while loading solution, recover latest saved version?"}
                    {noLatestProposalRecordFound && (
                        <Alert severity="error">
                            No previous saved version found, remove this solution and create a new one?
                        </Alert>
                    )}
                    {alertMessage && (
                        <Alert severity={alertMessage.severity}>
                            {alertMessage.message}
                        </Alert>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    disabled={recovering}
                >
                    Cancel
                </Button>
                {!noLatestProposalRecordFound && <LoadingButton
                    loading={recovering}
                    disabled={disableRecoverBtn}
                    onClick={onRecoverBtnClicked}
                    startIcon={<></>}
                    loadingPosition="start"
                    variant="contained"
                >
                    Recover
                </LoadingButton>}
                {noLatestProposalRecordFound && <LoadingButton
                    loading={removing}
                    onClick={onRemoveBtnClicked}
                    startIcon={<></>}
                    loadingPosition="start"
                    variant="contained"
                    color="error"
                >
                    Remove
                </LoadingButton>}
            </DialogActions>
        </Dialog>
    );
};
