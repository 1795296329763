import {
    AutocompleteRenderGroupParams,
    Collapse,
    Divider
} from "@mui/material";

import { Box } from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";

interface CollapsingAutocompleteOptionGroupProps {
    isSearching: boolean;
    isOpenByDefault: boolean;
    params: AutocompleteRenderGroupParams;
}

const CollapsingAutocompleteOptionGroup = (props: CollapsingAutocompleteOptionGroupProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(props.isOpenByDefault);
    return (
        <>
            <Box
                sx={{
                    backgroundColor: '#f7f7f7',
                    height: '45px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 16px',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: '#e0e0e0',
                    },
                }}
                onClick={() => {
                    if (!props.isSearching) {
                        setIsOpen(!isOpen)
                    }
                }}
            >
                {props.params.group}
                {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
            <Divider/>
            <Collapse in={isOpen || props.isSearching}>
                {props.params.children}
            </Collapse>
        </>
    )
}

export default CollapsingAutocompleteOptionGroup;
