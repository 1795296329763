import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.4 23.5C5.952 23.5.9 18.448.9 12 .9 5.551 5.952.5 12.4.5c3.28 0 5.943 1.327 8.38 4.175l-.76.65C17.756 2.68 15.407 1.5 12.4 1.5 6.512 1.5 1.9 6.112 1.9 12s4.612 10.5 10.5 10.5c3.005 0 5.355-1.18 7.62-3.825l.76.65c-2.439 2.849-5.102 4.175-8.38 4.175z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M23.1 11.28v1.44l-3.65 3.56-1.39-1.43L19.95 13H11.4v-2h8.55l-1.89-1.85 1.39-1.43z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(LogoutIcon);
