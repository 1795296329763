import {
     AutocompleteValue,
     TextFieldProps
} from "@mui/material";

import GraphQLOrganizationDAOFactory from "../../lib/organization/graphQL/GraphQLOrganizationDAOFactory";
import { Organization } from "../../models";
import { SearchBox } from "../misc/SearchBox";
import SearchByLegalNameOperation from "../../lib/organization/SearchByLegalNameOperation";
import { useRef } from "react";

interface TeamSearchBoxProps<Multiple extends boolean | undefined> {
    readonly onSelect: (team: AutocompleteValue<Organization, Multiple, undefined, undefined>) => void;
    readonly onClear: () => void;
    readonly selectedTeam: AutocompleteValue<Organization, Multiple, undefined, undefined>;
    readonly multiple?: Multiple;
    readonly freeSolo?: boolean;
    readonly label?: string;
    readonly textFieldStyle?: TextFieldProps;
}

const TeamSearchBox = <Multiple extends boolean | undefined = undefined>(props: TeamSearchBoxProps<Multiple>) => {
    /* Internal page state */
    const { onSelect, onClear, selectedTeam, multiple, freeSolo, label, textFieldStyle } = props;

    /* Dependencies */
    const organizationDaoRef = useRef<SearchByLegalNameOperation>(GraphQLOrganizationDAOFactory.getInstance() as SearchByLegalNameOperation);
    return (
        <SearchBox
            label={label ?? "Search for teams"}
            onSelect={onSelect}
            onClear={onClear}
            search={(searchValue, nextToken, limit) => organizationDaoRef.current.searchByLegalNameWithLimit(searchValue, limit, nextToken)}
            value={selectedTeam}
            multiple={multiple}
            labelFields={["legalName"]}
            typeName="Team"
            textFieldStyle={textFieldStyle}
            freeSolo={freeSolo}
        />
    );
};

export default TeamSearchBox;
