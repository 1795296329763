import {
    OptionsObject,
    useSnackbar
} from "notistack";
import {
    useCallback,
    useEffect,
    useState
} from "react";

import { RealtimeAttendeePositionInFrame } from "amazon-chime-sdk-js";
import { useCustomRosterState } from "../customized/CustomRosterProvider";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";

type AttendeePresenceEvent = {
    attendeeId: string;
    present: boolean;
    options?: OptionsObject;
};

export const useOnAttendeePresenceReceived = () => {
    const meetingManager = useMeetingManager();
    const { enqueueSnackbar } = useSnackbar();
    const [attendeePresenceEvents, setAttendeePresenceEvents] = useState<Array<AttendeePresenceEvent>>([]);
    const { roster } = useCustomRosterState();

    useEffect(() => {
        let processedEvents: Array<AttendeePresenceEvent> = [];
        attendeePresenceEvents.map((snackbar) => {
            const attendee = roster[snackbar.attendeeId];
            // Do not show snackbar until attendee name is available
            if (!attendee || !attendee.name) {
                return;
            }
            const message = `${attendee.name} has ${snackbar.present ? 'joined' : 'left'} the meeting`;
            if ('speechSynthesis' in window) {
                speechSynthesis.speak(new SpeechSynthesisUtterance(message));
            }
            enqueueSnackbar(message, snackbar.options);
            processedEvents.push(snackbar);
        });
        if (processedEvents.length === 0) {
            return;
        }
        setAttendeePresenceEvents(prev => {
            if (processedEvents.length === 0) {
                return prev;
            }
            const unprocessedEvents = [...prev.filter((event) => {
                const isProcessed = processedEvents.some((processedEvent) => {
                    return processedEvent.attendeeId === event.attendeeId && processedEvent.present === event.present;
                });
                return !isProcessed;
            })];
            return unprocessedEvents;
        });
    }, [roster, attendeePresenceEvents]);

    const onAttendeePresenceReceived = useCallback((
        attendeeId: string,
        present: boolean,
        externalUserId?: string,
        dropped?: boolean,
        posInFrame?: RealtimeAttendeePositionInFrame | null
    ) => {
        if (attendeeId === meetingManager.meetingSession?.configuration.credentials?.attendeeId ||
            externalUserId?.startsWith("aws:MediaPipeline")
        ) {
            return;
        }
        setAttendeePresenceEvents((prev) => {
            return [...prev, {
                attendeeId,
                present,
            }];
        });
    }, []);

    const subscribeToAttendeePresence = () => {
        meetingManager.audioVideo?.realtimeSubscribeToAttendeeIdPresence(onAttendeePresenceReceived);
    };

    const unsubscribeFromAttendeePresence = () => {
        meetingManager.audioVideo?.realtimeUnsubscribeToAttendeeIdPresence(onAttendeePresenceReceived);
    };

    return { subscribeToAttendeePresence, unsubscribeFromAttendeePresence };
};