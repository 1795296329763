import {
    EntityResourcePermission,
    Organization,
    PermissionEntityType,
    PermissionType,
    User
} from "../../models";
import {
    useRecoilCallback,
    useRecoilValue
} from "recoil";

import { AppSyncExecutionTimeoutError } from "../../lib/AppSyncExecutionTimeoutError";
import { DataStoreEntityResourcePermissionDAOFactory } from "../../lib/permission/dao/DataStoreEntityResourcePermissionDAOFactory";
import { EntityResourcePermissionDAO } from "../../lib/permission/dao/EntityResourcePermissionDAO";
import { GraphQLManageResourcePermissionClientFactory } from "../../lib/permission/GraphQLManageResourcePermissionClientFactory";
import { ManageResourcePermissionClient } from "../../lib/permission/ManageResourcePermissionClient";
import { ManageResourcePermissionInput } from "../../API";
import { PermissionTemplate } from "../../lib/permission/template/PermissionTemplate";
import { Resource } from "./type/Resource";
import { TEMPLATE_TYPE_TO_PERMISSION_MAP } from "../../lib/permission/template/PermissionTemplateConstants";
import { isUpdatingPermissionByResourceAtomFamily } from "./state/ManagePermissionState";
import { propertyIdInFocusAtom } from "../../lib/ui/InFocusRecoilStates";
import { useSnackbar } from "notistack";
import { userIdAtom } from "../../lib/userSession/state/UserSessionState";

const entityResourcePermissionDAO: EntityResourcePermissionDAO = DataStoreEntityResourcePermissionDAOFactory.getInstance();
const manageResourcePermissionClient: ManageResourcePermissionClient = GraphQLManageResourcePermissionClientFactory.getInstance();

type ManageResourcePermissionInputTemplate = Omit<ManageResourcePermissionInput, "entityId" | "entityType">;

export const useShareMultipleResources = () => {
    const signedInUserId = useRecoilValue<string | undefined>(userIdAtom);
    const propertyId = useRecoilValue(propertyIdInFocusAtom);
    const snackbar = useSnackbar();

    async function buildAssignableShareRequestTemplate(
        resource: Resource,
        permissionTypesToShare: Array<PermissionType>
    ): Promise<Array<ManageResourcePermissionInputTemplate>> {
        if (!signedInUserId) {
            throw new Error("User ID atom is not initialized");
        }
        const entityResourcePermissions: Array<EntityResourcePermission> = await entityResourcePermissionDAO.listByEntityAndResource(PermissionEntityType.USER, signedInUserId, resource.resourceType, resource.resourceId, true);
        if (!entityResourcePermissions.some(erp => erp.permissionType === PermissionType.SHARE_PERMISSION)) {
            return [];
        }
        const shareablePermissions: Array<PermissionType> = permissionTypesToShare.filter(permissionToShare => entityResourcePermissions.some(erp => erp.permissionType === permissionToShare));
        return shareablePermissions.map(permissionType => ({
            permissionType: permissionType,
            resourceId: resource.resourceId,
            resourceType: resource.resourceType
        }));
    };

    async function buildRevokableRequestTemplate(
        resource: Resource
    ): Promise<Array<ManageResourcePermissionInputTemplate>> {
        if (!signedInUserId) {
            throw new Error("User ID atom is not initialized");
        }
        const entityResourcePermissions: Array<EntityResourcePermission> = await entityResourcePermissionDAO.listByEntityAndResource(
            PermissionEntityType.USER,
            signedInUserId,
            resource.resourceType,
            resource.resourceId,
            true
        );
        if (!entityResourcePermissions.some(erp => erp.permissionType === PermissionType.REMOVE_PERMISSION)) {
            return [];
        }
        const allPermissionTypes: Array<PermissionType> = TEMPLATE_TYPE_TO_PERMISSION_MAP.get(PermissionTemplate.OWNER) ?? [];
        return allPermissionTypes.map(permissionType => ({
            permissionType: permissionType,
            resourceId: resource.resourceId,
            resourceType: resource.resourceType
        }));
    };

    const shareMultipleResources = useRecoilCallback(({ set }) => async (
        entityType: PermissionEntityType,
        entitiesToShare: Array<User | Organization>,
        resources: Array<Resource>,
        permissionTypes: Array<PermissionType>
    ) => {
        const entitiesDisplayName: string = entitiesToShare.map(entity => {
            if (entityType === PermissionEntityType.USER) {
                return (entity as User).name;
            }
            return (entity as Organization).legalName;
        }).join(", ");
        snackbar.enqueueSnackbar(`Sharing resources to ${entitiesDisplayName}, it might take a few minutes.`, { variant: "info" });
        const sharePermissionsTemplates: Array<ManageResourcePermissionInputTemplate> = (await Promise.all(resources.map(resource => buildAssignableShareRequestTemplate(resource, permissionTypes)))).flat();
        resources.forEach(resource => set(isUpdatingPermissionByResourceAtomFamily(resource), true));
        if (sharePermissionsTemplates.length === 0) {
            snackbar.enqueueSnackbar(`No permission to share resources.`, { variant: "error" });
            return;
        }
        try {
            if (!propertyId) {
                throw new Error("No property is in focus");
            }
            await Promise.all(entitiesToShare.map(entity => {
                const shareRequests: Array<ManageResourcePermissionInput> = sharePermissionsTemplates.map(template => ({
                    ...template,
                    entityId: entity.id,
                    entityType: entityType,
                    propertyId: propertyId
                })).flat();
                return manageResourcePermissionClient.share(shareRequests);
            }));
            snackbar.enqueueSnackbar(`Resources shared to ${entitiesDisplayName}.`, { variant: "success" });
        } catch (error) {
            if (error instanceof AppSyncExecutionTimeoutError) {
                snackbar.enqueueSnackbar("Sharing is taking longer than expected, please check after a few minutes.", { variant: "warning" });
                return;
            }
            snackbar.enqueueSnackbar(`Something went wrong when sharing resources to ${entitiesDisplayName}: ${(error as Error).message}`, { variant: "error" });
        } finally {
            resources.forEach(resource => set(isUpdatingPermissionByResourceAtomFamily(resource), false));
        }
    }, []);

    const revokeMultipleResources = useRecoilCallback(({ set }) => async (
        entityType: PermissionEntityType,
        entitiesToShare: Array<User | Organization>,
        resources: Array<Resource>
    ) => {
        const entitiesDisplayName: string = entitiesToShare.map(entity => {
            if (entityType === PermissionEntityType.USER) {
                return (entity as User).name;
            }
            return (entity as Organization).legalName;
        }).join(", ");
        snackbar.enqueueSnackbar(`Revoking resources from ${entitiesDisplayName}, it might take a few minutes.`, { variant: "info" });
        const revokablePermissionTemplates: Array<ManageResourcePermissionInputTemplate> = (await Promise.all(resources.map(resource => buildRevokableRequestTemplate(resource)))).flat();
        resources.forEach(resource => set(isUpdatingPermissionByResourceAtomFamily(resource), true));
        if (revokablePermissionTemplates.length === 0) {
            snackbar.enqueueSnackbar(`No permission to revoke resources.`, { variant: "error" });
            return;
        }
        try {
            await Promise.all(entitiesToShare.map(entity => {
                const revokeRequests: Array<ManageResourcePermissionInput> = revokablePermissionTemplates.map(template => ({
                    ...template,
                    entityId: entity.id,
                    entityType: entityType
                })).flat();
                return manageResourcePermissionClient.revoke(revokeRequests);
            }));
            snackbar.enqueueSnackbar(`Resources revoked from ${entitiesDisplayName}.`, { variant: "success" });
        } catch (error) {
            if (error instanceof AppSyncExecutionTimeoutError) {
                snackbar.enqueueSnackbar("Revoke permissions is taking longer than expected, please check after a few minutes.", { variant: "warning" });
                return;
            }
            snackbar.enqueueSnackbar(`Something went wrong when revoking resources from ${entitiesDisplayName}: ${(error as Error).message}`, { variant: "error" });
        } finally {
            resources.forEach(resource => set(isUpdatingPermissionByResourceAtomFamily(resource), false));
        }
    }, []);

    return { shareMultipleResources, revokeMultipleResources };
};