export default class LoggerCommonTags {
    static DIMENSION_LIST_RETRIEVAL_FAILURE = "DimensionRetrievalFailure";
    static DIMENSION_LIST_RETRIEVAL_ATTEMPT = "DimensionRetrievalAttempt";
    static DIMENSION_CREATION_ATTEMPT = "DimensionCreationAttempt";
    static DIMENSION_CREATION_FAILURE = "DimensionCreationFailure";
    static ISSUE_LIST_RETRIEVAL_FAILURE = "IssueListRetrievalFailure";
    static ISSUE_LIST_RETRIEVAL_ATTEMPT = "IssueListRetrievalAttempt";
    static ISSUE_LIST_RETRIEVAL_BY_LOCATION_ID_ATTEMPT = "IssueListRetrievalByLocationIdAttempt";
    static ISSUE_LIST_RETRIEVAL_WITHOUT_FILTER_ATTEMPT= "IssueListRetrievalWithoutFilterAttempt";
    static LOCATION_LIST_RETRIEVAL_ATTEMPT = "LocationListRetrievalAttempt";
    static LOCATION_LIST_RETRIEVAL_FAILURE = "LocationListRetrievalFailure";
    static PROPERTY_LIST_RETRIEVAL_FAILURE = "PropertyListRetrievalFailure";
    static PROPERTY_RETRIEVAL_ATTEMPT = "PropertyRetrievalAttempt";
    static PROPERTY_RETRIEVAL_FAILURE = "PropertyRetrievalFailure";
    static NOTE_CREATION_FAILURE = "NoteCreationFailure";
    static NOTE_LIST_RETRIEVAL_ATTEMPT = "NoteListRetrievalAttempt";
    static NOTE_LIST_RETRIEVAL_FAILURE = "NoteListRetrievalFailure";
    static ATTACHMENT_UPLOAD_FAILURE = "AttachmentUploadFailure";
    static INSPECTION_PAUSE_FAILURE = "InspectionPauseFailure";
    static INSPECTION_RESUME_FAILURE = "InspectionResumeFailure";
    static INSPECTION_COMPLETE_FAILURE = "InspectionCompleteFailure";
    static WORKTYPE_PRICE_CALCULATION_FAILURE = "workTypePriceCalculationFailure";
}
