import {
    Box,
    Button,
    ButtonProps,
    ClickAwayListener,
    Paper
} from "@mui/material";

import { Link } from "react-router-dom";
import { useState } from "react";

type EditSolutionButtonProps = Omit<ButtonProps, "onClick" | "property"> & {
    readonly propertyId: string;
    readonly solutionId: string;
};

export const EditSolutionButton = (props: EditSolutionButtonProps) => {
    const { propertyId, solutionId, ...buttonProps } = props;
    const [isClicked, setIsClicked] = useState<boolean>(false);

    return (
        <ClickAwayListener onClickAway={() => setIsClicked(false)}>
            <Box>
                <Button
                    onClick={() => setIsClicked(prev => !prev)}
                    {...buttonProps}
                >
                    Edit Document
                </Button>
                {isClicked && (
                    <Paper sx={{ position: "absolute", p: 1, zIndex: 1 }} elevation={4}>
                        <Box display="flex" flexDirection="column" alignItems="flex-start" gap={0.5}>
                            <Button
                                component={Link}
                                to={`/properties/${propertyId}/solutions/${solutionId}/edit?sameProject=true`}
                            >
                                As Same Project
                            </Button>
                            <Button
                                component={Link}
                                to={`/properties/${propertyId}/solutions/${solutionId}/edit`}
                            >
                                As New Project
                            </Button>
                        </Box>
                    </Paper>
                )}
            </Box>
        </ClickAwayListener>
    );
};