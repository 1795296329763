import ClientLoggerFactory from "../logging/ClientLoggerFactory";
import { DataStore } from "aws-amplify";
import DataStoreUserOnboardingProgressDAO from "./DataStoreUserOnboardingProgressDAO";

export default class DataStoreUserOnboardingProgressDAOFactory {
    private static readonly INSTANCE = new DataStoreUserOnboardingProgressDAO(
        ClientLoggerFactory.getClientLogger("DataStoreUserOnboardingProgressDAO"),
        DataStore
    );
    public static getInstance() {
        return this.INSTANCE;
    }
}