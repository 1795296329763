import { DataStoreStatus } from "./DataStoreStatus";
import HubEventHandler from "../event/HubEventHandler";
import { HubPayload } from "@aws-amplify/core";
import { SetterOrUpdater } from "recoil";
import { SyncReconciliationManager } from "./reconciliation/SyncReconciliationManager";

export default class DataStoreReadyEventHandler implements HubEventHandler {
    private static readonly READY_EVENT = "ready";
    private readonly delegate: HubEventHandler;
    private readonly setDataStoreStatus: SetterOrUpdater<DataStoreStatus>;
    private readonly syncReconciliationManager: SyncReconciliationManager;

    constructor(
        delegate: HubEventHandler,
        setDataStoreStatus: SetterOrUpdater<DataStoreStatus>,
        syncReconciliationManager: SyncReconciliationManager
    ) {
        this.delegate = delegate;
        this.setDataStoreStatus = setDataStoreStatus;
        this.syncReconciliationManager = syncReconciliationManager;
    }

    public async handle(payload: HubPayload): Promise<void> {
        if (payload.event === DataStoreReadyEventHandler.READY_EVENT) {
            this.syncReconciliationManager.handle();
            this.setDataStoreStatus(DataStoreStatus.SYNC_COMPLETED);
        } else {
            this.delegate.handle(payload);
        }
    }

}