import API from "@aws-amplify/api";
import { AuthIDTokenSupplierFactory } from "../../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { GraphQLSearchPropertiesOperation } from "../../operation/GraphQLSearchPropertiesOperation";
import GraphQlPropertyDAO from "./GraphQLPropertyDAO";
import PropertyDAO from "../PropertyDAO";
import { graphqlOperation } from "aws-amplify";

export default class GraphQLPropertyDAOFactory {
    private static readonly INSTANCE: PropertyDAO & GraphQLSearchPropertiesOperation = new GraphQlPropertyDAO(
        API,
        graphqlOperation,
        ClientLoggerFactory.getClientLogger("GraphQlPropertyDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance(): PropertyDAO & GraphQLSearchPropertiesOperation {
        return this.INSTANCE;
    }
}
