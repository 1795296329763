import {
    Box,
    Typography
} from "@mui/material";
import {
    memo,
    useMemo
} from "react";

import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { ModelType } from "../../lib/design/document/ModelType";
import { StateContext } from "../../lib/design/document/state/StateContext";
import ValidationResult from "../../lib/util/validation/ValidationResult";
import { WSSForm } from "./WSSForm";
import { useRecoilValue } from "recoil";
import { validationResultByDesignElementIdentifierSelectorFamily } from "../../lib/design/document/state/DocumentValidationState";

type WorkSpecificationProps = {
    readonly workSpecificationId: string;
    readonly stateContext: StateContext;
    readonly dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
};

export const WorkSpecificationCard = memo((props: WorkSpecificationProps) => {
    const { workSpecificationId, stateContext, dragHandleProps } = props;
    const workSpecificationIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(workSpecificationId, stateContext, ModelType.WORK_SPECIFICATION);
    }, [workSpecificationId, stateContext]);
    const workSpecificationValidationResult = useRecoilValue<ValidationResult>(validationResultByDesignElementIdentifierSelectorFamily(workSpecificationIdentifier));

    return (
        <>
            {!workSpecificationValidationResult.isValid && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={1}
                >
                    <Typography color="red">{workSpecificationValidationResult.message}</Typography>
                </Box>
            )}
            <WSSForm
                workSpecificationId={workSpecificationId}
                stateContext={stateContext}
                dragHandleProps={dragHandleProps}
            />
        </>
    );
});
