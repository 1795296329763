import ClientLogger from "../../../../logging/ClientLogger";
import CreateWorkScopeSpecificationError from "../error/CreateWorkScopeSpecificationError";
import { DataStore } from "aws-amplify";
import { DataStoreClass } from "@aws-amplify/datastore";
import DeleteWorkScopeSpecificationError from "../error/DeleteWorkScopeSpecificationError";
import Deserializer from "../../../../util/data/serialization/Deserializer";
import Envelope from "../../../../envelope/Envelope";
import GetWorkScopeSpecificationByIdError from "../error/GetWorkScopeSpecificationByIdError";
import ResourceNotFoundError from "../../../../ResourceNotFoundError";
import Serializer from "../../../../util/data/serialization/Serializer";
import UpdateWorkScopeSpecificationError from "../error/UpdateWorkScopeSpecificationError";
import WorkScopeSpecificationDAO from "../WorkScopeSpecificationDAO";
import { WorkSpecification } from "../../../../../models";
import _ from "lodash";

export default class DataStoreWorkScopeSpecificationDAO implements WorkScopeSpecificationDAO {

    private static readonly GET_BY_ID_ATTEMPT_METRIC_NAME = "DataStoreWorkScopeSpecificationDAO.GetById.Attempt";
    private static readonly GET_BY_ID_FAILURE_METRIC_NAME = "DataStoreWorkScopeSpecificationDAO.GetById.Failure";
    private static readonly CREATE_ATTEMPT_METRIC_NAME = "DataStoreWorkScopeSpecificationDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "DataStoreWorkScopeSpecificationDAO.Create.Failure";
    private static readonly UPDATE_ATTEMPT_METRIC_NAME = "DataStoreWorkScopeSpecificationDAO.Update.Attempt";
    private static readonly UPDATE_FAILURE_METRIC_NAME = "DataStoreWorkScopeSpecificationDAO.Update.Failure";
    private static readonly DELETE_ATTEMPT_METRIC_NAME = "DataStoreWorkScopeSpecificationDAO.Delete.Attempt";
    private static readonly DELETE_FAILURE_METRIC_NAME = "DataStoreWorkScopeSpecificationDAO.Delete.Failure";

    private readonly serializer: Serializer<Envelope, string>;
    private readonly deserializer: Deserializer<string, Envelope>;
    private readonly dataStore: DataStoreClass;
    private readonly logger: ClientLogger;

    constructor(
        serializer: Serializer<Envelope, string>,
        deserializer: Deserializer<string, Envelope>,
        dataStore: DataStoreClass,
        logger: ClientLogger
    ) {
        this.serializer = serializer;
        this.deserializer = deserializer;
        this.dataStore = dataStore;
        this.logger = logger;
    }

    public async listByIssueId(issueId: string): Promise<WorkSpecification[]> {
        return await this.dataStore.query(WorkSpecification, wss => wss.issueId("eq", issueId));
    }

    public async listByDimensionId(dimensionId: string): Promise<WorkSpecification[]> {
        return await DataStore.query(WorkSpecification, wss => wss.dimensionId("eq", dimensionId));
    }

    public async getById(id: string): Promise<WorkSpecification> {
        try {
            this.logger.info(
                `Getting WorkSpecification with id=${id}`,
                id,
                [DataStoreWorkScopeSpecificationDAO.GET_BY_ID_ATTEMPT_METRIC_NAME]
            );
            const result: WorkSpecification | undefined = await DataStore.query(WorkSpecification, id);
            if (!result) {
                throw new ResourceNotFoundError("WorkSpecification");
            }
            return result;
        } catch (error) {
            const errorMessage = `Unable to get WorkSpecification with id=${id}`;
            this.logger.error(
                errorMessage,
                error,
                [DataStoreWorkScopeSpecificationDAO.GET_BY_ID_FAILURE_METRIC_NAME]
            );
            throw new GetWorkScopeSpecificationByIdError(errorMessage, {
                cause: error as Error
            });
        }
    }

    public async create(workSpecification: WorkSpecification): Promise<WorkSpecification> {
        try {
            this.logger.info(
                `Creating WorkSpecification with id=${workSpecification.id}`,
                workSpecification,
                [DataStoreWorkScopeSpecificationDAO.CREATE_ATTEMPT_METRIC_NAME]
            );
            const workSpecificationToCreate = new WorkSpecification({
                ...workSpecification,
                localLastUpdatedTime: Date.now()
            });
            return await DataStore.save(workSpecificationToCreate);
        } catch (error) {
            const errorMessage = `Unable to create WorkSpecification with id=${workSpecification.id}`;
            this.logger.error(
                errorMessage,
                error,
                [DataStoreWorkScopeSpecificationDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreateWorkScopeSpecificationError(errorMessage, {
                cause: error as Error
            });
        }
    }

    public async update(workSpecification: WorkSpecification): Promise<WorkSpecification> {
        try {
            const existingWorkSpecification: WorkSpecification = await this.getById(workSpecification.id);
            if (_.isEqual(existingWorkSpecification, workSpecification)) {
                this.logger.info(
                    `No changes detected in workSpecification ${workSpecification.id}, skipping update`,
                    workSpecification,
                    [DataStoreWorkScopeSpecificationDAO.UPDATE_ATTEMPT_METRIC_NAME]
                );
                return workSpecification;
            }
            this.logger.info(
                `Updating WorkSpecification with id=${workSpecification.id}`,
                workSpecification,
                [DataStoreWorkScopeSpecificationDAO.UPDATE_ATTEMPT_METRIC_NAME]
            );
            const result = await DataStore.save(
                WorkSpecification.copyOf(existingWorkSpecification, workSpecificationToUpdate => {
                    workSpecificationToUpdate.measurement = workSpecification.measurement;
                    workSpecificationToUpdate.workTypeId = workSpecification.workTypeId;
                    workSpecificationToUpdate.description = workSpecification.description;
                    workSpecificationToUpdate.itemNumber = workSpecification.itemNumber;
                    workSpecificationToUpdate.localLastUpdatedTime = Date.now();
                    workSpecificationToUpdate.issueId = workSpecification.issueId;
                    workSpecificationToUpdate.dimensionId = workSpecification.dimensionId;
                })
            );
            return result;
        } catch (error) {
            const errorMessage = `Unable to update WorkSpecification with id=${workSpecification.id}`;
            this.logger.error(
                errorMessage,
                error,
                [DataStoreWorkScopeSpecificationDAO.UPDATE_FAILURE_METRIC_NAME]
            );
            console.log("error: ", error);
            throw new UpdateWorkScopeSpecificationError(errorMessage, {
                cause: error as Error
            });
        }
    }

    public async delete(workSpecificationId: string): Promise<void> {
        try {
            this.logger.info(
                `Deleting WorkSpecification with id=${workSpecificationId}`,
                workSpecificationId,
                [DataStoreWorkScopeSpecificationDAO.DELETE_ATTEMPT_METRIC_NAME]
            );
            DataStore.delete(WorkSpecification, workSpecificationId);
        } catch (error) {
            const errorMessage = `Unable to delete WorkSpecification with id=${workSpecificationId}`;
            this.logger.error(
                errorMessage,
                error,
                [DataStoreWorkScopeSpecificationDAO.DELETE_FAILURE_METRIC_NAME]
            );
            throw new DeleteWorkScopeSpecificationError(errorMessage, {
                cause: error as Error
            });
        }
    }

    public async queryWorkScopeSpecificationsByIssueId(issueId: string): Promise<Envelope[]> {
        const workScopeSpecificationRecords: WorkSpecification[] = await this.dataStore.query(WorkSpecification, wss => wss.issueId("eq", issueId));
        const result: Envelope[] = [];
        workScopeSpecificationRecords.forEach((wssRecord, index) => {
            const wssEnvelope: Envelope = {
                header: {
                    dataType: "WSS",
                    id: wssRecord.id,
                    variant: "Default",
                    version: wssRecord.version!
                },
                body: this.deserializer.deserialize(wssRecord.body!),
            };
            result.push(wssEnvelope);
        });
        return result;
    }

    public async queryWorkScopeSpecificationsByDimensionId(dimensionId: string): Promise<Envelope[]> {
        const workScopeSpecificationRecords: WorkSpecification[] = await DataStore.query(WorkSpecification, wss => wss.dimensionId("eq", dimensionId));
        const result: Envelope[] = [];
        workScopeSpecificationRecords.forEach((wssRecord, index) => {
            const wssEnvelope: Envelope = {
                header: {
                    dataType: "WSS",
                    id: wssRecord.id,
                    variant: "Default",
                    version: wssRecord.version!
                },
                body: this.deserializer.deserialize(wssRecord.body!),
            };
            result.push(wssEnvelope);
        });
        return result;
    }

    public async createWorkScopeSpecification(workScopeSpecification: Envelope, dimensionId: string, issueId: string): Promise<WorkSpecification> {
        const serializedWSS: string = this.serializer.serialize(workScopeSpecification.body);
        const wssRecord: WorkSpecification = new WorkSpecification({
            dimensionId: dimensionId,
            issueId,
            version: workScopeSpecification.header.version,
            body: serializedWSS
        });
        return await DataStore.save(wssRecord);
    }

    public async editWorkScopeSpecification(workScopeSpecification: Envelope): Promise<WorkSpecification> {
        const serializedWSSBody: string = this.serializer.serialize(workScopeSpecification.body);
        const originalWSS: WorkSpecification = await this.getById(workScopeSpecification.header.id);
        if (_.isEqual(serializedWSSBody, originalWSS.body)) {
            return originalWSS;
        }
        return await DataStore.save(
            WorkSpecification.copyOf(originalWSS, wssToSave => {
                wssToSave.body = serializedWSSBody;
            })
        );
    }

    public async deleteWorkScopeSpecification(workScopeSpecification: Envelope): Promise<void> {
        const wssToDelete: WorkSpecification = await this.getById(workScopeSpecification.header.id);
        await DataStore.delete(wssToDelete);
    }
}
