import ReactImageFileResizer from "./ReactImageFileResizer";
import ClientLoggerFactory from "../logging/ClientLoggerFactory";

export default class ReactImageFileResizerFactory {
    private static readonly INSTANCE: ReactImageFileResizer = new ReactImageFileResizer(
        ClientLoggerFactory.getClientLogger("ReactImageFileResizer")
    );

    public static getInstance(): ReactImageFileResizer {
        return ReactImageFileResizerFactory.INSTANCE;
    }
}
