import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import PercentageProposalItemPriceExplanationGenerator from "./PercentageProposalItemPriceExplanationGenerator";

export default class PercentageProposalItemPriceExplanationGeneratorFactory {
    private static readonly INSTANCE = new PercentageProposalItemPriceExplanationGenerator(
        ClientLoggerFactory.getClientLogger("PercentageProposalItemPriceExplanationGenerator")
    );
    public static getInstance() {
        return this.INSTANCE;
    }
}
