import {
    workTypeByWorkTypeIdAtomFamily,
    workTypeIdsAtom
} from "../state/WorkTypeRecoilState";

import DataStoreWorkTypeDAOFactory from "../dataStore/DataStoreWorkTypeDAOFactory";
import WorkTypeDAO from "../WorkTypeDAO";
import { useRecoilCallback } from "recoil";

const workTypeDAO: WorkTypeDAO = DataStoreWorkTypeDAOFactory.getInstance();

export const useFetchWorkTypes = () => {
    const fetchWorkTypes = useRecoilCallback(({ set }) => async (workTypeIds: Array<string>) => {
        const fetchWorkTypesPromises: Array<Promise<void>> = workTypeIds.map(async (workTypeId: string) => {
            const workType = await workTypeDAO.getById(workTypeId);
            set(workTypeByWorkTypeIdAtomFamily(workTypeId), workType);
        });
        set(workTypeIdsAtom, workTypeIds);
        await Promise.all(fetchWorkTypesPromises);
    });

    const resetWorkTypeStates = useRecoilCallback(({ snapshot, reset }) => () => {
        const workTypeIds: Array<string> = snapshot.getLoadable(workTypeIdsAtom).contents;
        workTypeIds.forEach((workTypeId: string) => {
            reset(workTypeByWorkTypeIdAtomFamily(workTypeId));
        });
        reset(workTypeIdsAtom);
    });
    return { fetchWorkTypes, resetWorkTypeStates };
};