import AttachmentUploadStatusDAO from "./AttachmentUploadStatusDAO";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import EventDispatchingAttachmentUploadStatusDAODecorator from "./EventDispatchingAttachmentUploadStatusDAODecorator";
import { Hub } from "aws-amplify";
import { LOCAL_FORAGE_UPLOAD_STATUS_TABLE_NAME } from "../../../localforage/LocalForageConstant";
import LocalForageAttachmentUploadStatusDAO from "./LocalForageAttachmentUploadStatusDAO";
import MetricsLoggingAttachmentUploadStatusDAODecorator from "./MetricsLoggingAttachmentUploadStatusDAODecorator";
import localforage from "localforage";

export class LocalForageAttachmentUploadStatusDAOFactory {
    private static readonly LOCALFORAGE_TABLE = localforage.createInstance({ name: LOCAL_FORAGE_UPLOAD_STATUS_TABLE_NAME });

    private static readonly DELEGATE_INSTANCE: AttachmentUploadStatusDAO = new MetricsLoggingAttachmentUploadStatusDAODecorator(
        new EventDispatchingAttachmentUploadStatusDAODecorator(
            new LocalForageAttachmentUploadStatusDAO(
                LocalForageAttachmentUploadStatusDAOFactory.LOCALFORAGE_TABLE
            ),
            Hub
        ),
        ClientLoggerFactory.getClientLogger("LocalForageAttachmentUploadStatusDAO")
    );

    public static getInstance(): AttachmentUploadStatusDAO {
        return LocalForageAttachmentUploadStatusDAOFactory.DELEGATE_INSTANCE;
    }
}
