import Serializer from "./Serializer";

export default class JSONSerializer implements Serializer<any, string> {

    public serialize(value: any): string {
        return JSON.stringify(value, this.replacer);
    }

    private replacer(key: any, value: any) {
        if (value instanceof Map) {
            return {
                dataType: "Map",
                value: Array.from(value.entries())
            }
        } else 
        if (value instanceof Set) {
            return {
                dataType: "Set",
                value: Array.from(value)
            }
        } else {
            return value;
        }
    }
}
