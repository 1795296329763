import UnitPriceCalculationError from "./errors/UnitPriceCalculationError";
import UnitPriceCalculator from "./UnitPriceCalculator";

export default class DefaultUnitPriceCalculator implements UnitPriceCalculator {
    calculate(
        totalPrice: number,
        unitCount: number
    ): number {
        if (totalPrice == null || !unitCount) {
            throw new UnitPriceCalculationError(`Invalid input: unitCount: ${unitCount}, totalPrice: ${totalPrice}`);
        }
        return totalPrice / unitCount;
    }
}
