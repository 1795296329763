import {
    Box,
    Typography
} from "@mui/material";

import { HotKeySettings } from "../../components/chime/HotKeysSetting";
import { StartPropertiesChildrenSyncWrapper } from "../../components/sync/StartPropertiesChildrenSyncWrapper";
import { TemplateSettings } from "../../components/template/TemplateSettings";
import { usePageDefaultStyle } from "../../assets/style/usePageDefaultStyle";

export const SettingsPage = () => {
    const { pageDefaultStyle } = usePageDefaultStyle();

    return (
        <StartPropertiesChildrenSyncWrapper>
            <Box sx={pageDefaultStyle} display="flex" flexDirection="column" gap={2}>
                <Typography variant="h4" component="h1">
                    Settings
                </Typography>
                <Box mx={1} display="flex" flexDirection="column" gap={1}>
                    <TemplateSettings />
                    <HotKeySettings />
                </Box>
            </Box>
        </StartPropertiesChildrenSyncWrapper>
    );
};