import { PriceCalculationType } from './PriceCalculationType';
import ProposalItem from "./ProposalItem";
import uuidv4 from '../../util/UuidGenerator';

export default class ProposalItemBuilder {
    private _proposalItem: ProposalItem;

    constructor(proposalItem?: ProposalItem) {
        if (proposalItem) {
            this._proposalItem = { ...proposalItem };
        } else {
            this._proposalItem = {
                id: uuidv4()
            };
        }
    }

    public id(value: string): ProposalItemBuilder {
        this._proposalItem = {
            ...this._proposalItem,
            id: value
        };
        return this;
    }

    public priceBeforeAdjustment(value: number | undefined): ProposalItemBuilder {
        this._proposalItem = {
            ...this._proposalItem,
            priceBeforeAdjustment: value
        };
        return this;
    }

    public priceAfterAdjustment(value: number | undefined): ProposalItemBuilder {
        this._proposalItem = {
            ...this._proposalItem,
            priceAfterAdjustment: value
        };
        return this;
    }

    public priceCalculationType(value: PriceCalculationType | undefined) {
        this._proposalItem = {
            ...this._proposalItem,
            priceCalculationType: value
        };
        return this;
    }

    public unitCount(value: number | undefined) {
        this._proposalItem = {
            ...this._proposalItem,
            unitCount: value
        };
        return this;
    }

    public unitPriceBeforeAdjustment(value: number | undefined) {
        this._proposalItem = {
            ...this._proposalItem,
            unitPriceBeforeAdjustment: value
        };
        return this;
    }

    public unitPriceAfterAdjustment(value: number | undefined) {
        this._proposalItem = {
            ...this._proposalItem,
            unitPriceAfterAdjustment: value
        };
        return this;
    }

    public build(): ProposalItem {
        return this._proposalItem;
    }
}
