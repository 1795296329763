import {
    atom,
    atomFamily
} from "recoil";

import { ModelType } from "../design/document/ModelType";
import { StateContext } from "../design/document/state/StateContext";

export const stateContextInFocusAtom = atom<StateContext>({
    key: "stateContextInFocusAtom",
    default: StateContext.UNDEFINED
});

export const propertyIdInFocusAtom = atom<string | null>({
    key: "propertyIdInFocusAtom",
    default: null
});

export const idInFocusByModelTypeAtomFamily = atomFamily<string | null, ModelType>({
    key: "idInFocusByModelTypeAtomFamily",
    default: null
});