import { AttachmentParent } from "../AttachmentParent";
import ClientLoggerFactory from "../../../lib/logging/ClientLoggerFactory";
import ImageSelectionHandlerFactory from "../../../lib/image/selection/ImageSelectionHandlerFactory";
import { PermissionResourceType } from "../../../models";
import { propertyIdInFocusAtom } from "../../../lib/ui/InFocusRecoilStates";
import { useRecoilCallback } from "recoil";
import { useSnackbar } from "notistack";

interface uploadImagesOptions {
    shouldResize: boolean;
}

const logger = ClientLoggerFactory.getClientLogger("useUploadImages");
const imageSelectionHandler = ImageSelectionHandlerFactory.getInstance();

export const useUploadImages = () => {
    const snackbar = useSnackbar();

    const uploadImages = useRecoilCallback(({ snapshot }) => async (files: Array<Blob>, options: uploadImagesOptions, attachmentParent: AttachmentParent) => {
        try {
            const propertyId: string | null = attachmentParent.type === PermissionResourceType.PROPERTY ?
                attachmentParent.id : await snapshot.getPromise(propertyIdInFocusAtom);
            if (!propertyId) {
                throw new Error("No propertyId in focus when uploading images");
            }
            await imageSelectionHandler.handle(files, attachmentParent, propertyId!, options.shouldResize);
        } catch (error) {
            snackbar.enqueueSnackbar(`Failed to upload image: ${error}`, { variant: "error", });
            logger.warn(
                "Failed to select images",
                error,
                ["ImageSelectionFailure"]
            );
        }
    }, []);

    return [uploadImages];
};