export async function imageDataToBlob(imageData: ImageData): Promise<Blob | null> {
    let w = imageData.width;
    let h = imageData.height;
    let canvas = document.createElement("canvas");
    canvas.width = w;
    canvas.height = h;
    let ctx = canvas.getContext("2d");
    ctx?.putImageData(imageData, 0, 0);

    return await new Promise((resolve, reject) => {
        canvas.toBlob((blob) => resolve(blob));
    });
}