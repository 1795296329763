import AttachmentContentDAO from "../AttachmentContentDAO";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { MetricLoggingS3AttachmentContentDAODecorator } from "./MetricLoggingS3AttachmentContentDAODecorator";
import { S3AttachmentContentDAO } from "./S3AttachmentContentDAO"

export class S3ImageAttachmentContentDAOFactory {
    private static directoryToAttachmentContentDAOMap: Map<string, AttachmentContentDAO> = new Map<string, S3AttachmentContentDAO>();
    
    private static CONTENT_TYPE: string = "image/png";
    private static DEFAULT_INSTANCE = new MetricLoggingS3AttachmentContentDAODecorator(
        new S3AttachmentContentDAO(
            this.CONTENT_TYPE
        ),
        ClientLoggerFactory.getClientLogger("S3AttachmentContentDAO")
    )

    public static getInstance(directory?: string): AttachmentContentDAO {
        if (directory) {
            if (!this.directoryToAttachmentContentDAOMap.has(directory)) {
                this.directoryToAttachmentContentDAOMap.set(directory, new MetricLoggingS3AttachmentContentDAODecorator(
                    new S3AttachmentContentDAO(
                        this.CONTENT_TYPE,
                        directory
                    ),
                    ClientLoggerFactory.getClientLogger("S3AttachmentContentDAO")
                ));
            }
            return this.directoryToAttachmentContentDAOMap.get(directory)!;
        }
        return this.DEFAULT_INSTANCE;
    }
}