import {
    API,
    graphqlOperation
} from "aws-amplify";

import { AuthIDTokenSupplierFactory } from "../../../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../../../logging/ClientLoggerFactory";
import GraphQLWorkScopeSpecificationDAO from "./GraphQLWorkScopeSpecificationDAO";
import JSONSerializerFactory from "../../../../util/data/serialization/JSONSerializerFactory";
import WorkScopeSpecificationDAO from "../WorkScopeSpecificationDAO";

export default class GraphQLWorkScopeSpecificationDAOFactory {
    private static readonly INSTANCE: WorkScopeSpecificationDAO = new GraphQLWorkScopeSpecificationDAO(
        API,
        graphqlOperation,
        JSONSerializerFactory.getInstance(),
        ClientLoggerFactory.getClientLogger("GraphQLWorkScopeSpecificationDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance(): WorkScopeSpecificationDAO {
        return GraphQLWorkScopeSpecificationDAOFactory.INSTANCE;
    }
}
