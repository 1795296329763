import {
    dimensionIdsByIssueIdentifierAtomFamily,
    workSpecificationByWorkSpecificationIdentifierSelectorFamily,
    workSpecificationIdsByDimensionIdentifierAtomFamily
} from "../../design/document/state/DocumentState";

import ClientLogger from "../../logging/ClientLogger";
import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { ContextAwareIdentifier } from "../../design/document/ContextAwareIdentifier";
import { ModelType } from "../../design/document/ModelType";
import { OnDragEndResponder } from "react-beautiful-dnd";
import { StateContext } from "../../design/document/state/StateContext";
import { WorkSpecification } from "../../../models";
import { useCallback } from "react";
import { useRecoilCallback } from "recoil";
import { useSnackbar } from "notistack";
import { useUpdateWorkSpecification } from "../../design/workscopespecification/hook/useUpdateWorkSpecification";

const clientLogger: ClientLogger = ClientLoggerFactory.getClientLogger("useOnDragEnd");
const DRAG_WORK_SPECIFICATION_FAILED = "DRAG_WORK_SPECIFICATION_FAILED";

export const useOnDragEnd = (context: StateContext) => {
    const { updateWorkSpecification } = useUpdateWorkSpecification(context);
    const snackbar = useSnackbar();

    const onWorkSpecificationDragEnd: OnDragEndResponder = useRecoilCallback(({ set, snapshot }) => async (result) => {
        const release = snapshot.retain();
        try {
            const { source, destination, draggableId: sourceWorkSpecificationId } = result;
            if (!destination || !source) {
                return;
            }
            const sourceIssueId: string = source.droppableId;
            const destinationIssueId: string = destination?.droppableId;
            const workSpecificationIdentifier = new ContextAwareIdentifier(sourceWorkSpecificationId, context, ModelType.WORK_SPECIFICATION);
            const sourceWorkScopeSpecification: WorkSpecification | null = await snapshot.getPromise(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier));
            const destinationIssueIdentifier = new ContextAwareIdentifier(destinationIssueId, context, ModelType.ISSUE);
            const dimensionIdsUnderDestinationIssue: Array<string> = await snapshot.getPromise(dimensionIdsByIssueIdentifierAtomFamily(destinationIssueIdentifier));
            const sourceIssueIdentifier = new ContextAwareIdentifier(sourceIssueId, context, ModelType.ISSUE);
            const dimensionIdsUnderSourceIssue: Array<string> = await snapshot.getPromise(dimensionIdsByIssueIdentifierAtomFamily(sourceIssueIdentifier));

            if (!sourceWorkScopeSpecification || dimensionIdsUnderDestinationIssue.length === 0 || dimensionIdsUnderSourceIssue.length === 0) {
                throw new Error("Failed to move work specification");
            }
            const targetDimensionIdentifier = new ContextAwareIdentifier(dimensionIdsUnderDestinationIssue[0], context, ModelType.DIMENSION);
            const originDimensionIdentifier = new ContextAwareIdentifier(sourceWorkScopeSpecification.dimensionId!, context, ModelType.DIMENSION);

            if (sourceIssueId === destinationIssueId && source.index === destination.index) {
                return;
            }

            if (sourceIssueId !== destinationIssueId) {
                // remove work specification id from the origin dimension
                set(workSpecificationIdsByDimensionIdentifierAtomFamily(originDimensionIdentifier), (prev) => {
                    return prev.filter((id) => id !== sourceWorkSpecificationId);
                });
            }
            // reorder work specification ids in the target dimension
            const newWorkSpecificationIds = await new Promise<Array<string>>((resolve) => {
                set(workSpecificationIdsByDimensionIdentifierAtomFamily(targetDimensionIdentifier), (prev) => {
                    const newWorkSpecificationIds = [...prev];
                    const indexOfSourceWorkSpecificationId = newWorkSpecificationIds.indexOf(sourceWorkSpecificationId);
                    if (indexOfSourceWorkSpecificationId !== -1) {
                        newWorkSpecificationIds.splice(indexOfSourceWorkSpecificationId, 1);
                    }
                    newWorkSpecificationIds.splice(destination.index, 0, sourceWorkSpecificationId);
                    resolve(newWorkSpecificationIds);
                    return newWorkSpecificationIds;
                });
            });

            // persist the new item number for each work specification
            await Promise.all(newWorkSpecificationIds.map(async (workSpecificationId, index) => {
                if (workSpecificationId === sourceWorkSpecificationId) {
                    await updateWorkSpecification({
                        ...sourceWorkScopeSpecification,
                        ...(sourceIssueId !== destinationIssueId ? {
                            issueId: destinationIssueId,
                            dimensionId: dimensionIdsUnderDestinationIssue[0]
                        } : undefined),
                        itemNumber: destination.index
                    });
                    return;
                }
                const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, context, ModelType.WORK_SPECIFICATION);
                const workScopeSpecification: WorkSpecification | null = await snapshot.getPromise(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier));
                if (!workScopeSpecification) {
                    throw new Error("Failed to move work specification");
                }
                if (workScopeSpecification.itemNumber !== index) {
                    await updateWorkSpecification({
                        ...workScopeSpecification,
                        itemNumber: index
                    });
                }
            }));
        } catch (error) {
            snackbar.enqueueSnackbar("Failed to move work specification", { variant: "error" });
            clientLogger.error(
                "Failed to move work specification",
                error,
                [DRAG_WORK_SPECIFICATION_FAILED]
            );
        } finally {
            release();
        }
    }, [context]);

    const onDragEnd: OnDragEndResponder = useCallback(async (result, provided) => {
        switch (result.type) {
            case ModelType.WORK_SPECIFICATION: {
                return onWorkSpecificationDragEnd(result, provided);
            }
            default: {
                throw new Error("Unsupported drag type");
            }
        }
    }, [onWorkSpecificationDragEnd]);

    return { onDragEnd };
};