import {
    ATTACHMENT_UPLOAD_CHANNEL,
    ATTACHMENT_UPLOAD_STATUS_CHANGE_EVENT,
    ATTACHMENT_UPLOAD_STATUS_REMOVE_EVENT
} from "../AttachmentUploadConstant";

import { AttachmentUploadStatus } from "./AttachmentUploadStatus";
import AttachmentUploadStatusDAO from "./AttachmentUploadStatusDAO";
import { HubClass } from "@aws-amplify/core/lib-esm/Hub";

export default class EventDispatchingAttachmentUploadStatusDAODecorator implements AttachmentUploadStatusDAO {
    private readonly delegate: AttachmentUploadStatusDAO;
    private readonly hub: HubClass;

    constructor(
        delegate: AttachmentUploadStatusDAO,
        hub: HubClass
    ) {
        this.delegate = delegate;
        this.hub = hub;
    }
    
    public async getUploadStatusForAllAttachments(): Promise<Map<string, AttachmentUploadStatus>> {
        return await this.delegate.getUploadStatusForAllAttachments();
    }
    
    public async getAttachmentUploadStatus(attachmentId: string): Promise<AttachmentUploadStatus> {
        return await this.delegate.getAttachmentUploadStatus(attachmentId);
    }

    public async setAttachmentUploadStatus(
        attachmentId: string,
        status: AttachmentUploadStatus
    ): Promise<void> {
        await this.delegate.setAttachmentUploadStatus(attachmentId, status);
        this.hub.dispatch(
            ATTACHMENT_UPLOAD_CHANNEL,
            {
                event: ATTACHMENT_UPLOAD_STATUS_CHANGE_EVENT,
                data: {
                    attachmentId: attachmentId,
                    status: status
                }
            }
        );
    }

    public async removeAttachmentUploadStatus(attachmentId: string): Promise<void> {
        await this.delegate.removeAttachmentUploadStatus(attachmentId);
        this.hub.dispatch(
            ATTACHMENT_UPLOAD_CHANNEL,
            {
                event: ATTACHMENT_UPLOAD_STATUS_REMOVE_EVENT,
                data: {
                    attachmentId: attachmentId,
                }
            }
        );
    }
}
