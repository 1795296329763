import {
    API,
    graphqlOperation
} from 'aws-amplify';

import { AuthIDTokenSupplierFactory } from '../../../auth/AuthIDTokenSupplierFactory';
import ClientLoggerFactory from '../../../logging/ClientLoggerFactory';
import DimensionDAO from '../DimensionDAO.d';
import { GraphQLDimensionDAO } from './GraphQLDimensionDAO';

export class GraphQLDimensionDAOFactory {
    private static readonly INSTANCE: DimensionDAO = new GraphQLDimensionDAO(
        API,
        graphqlOperation,
        ClientLoggerFactory.getClientLogger("GraphQLDimensionDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance(): DimensionDAO {
        return GraphQLDimensionDAOFactory.INSTANCE;
    }
}
