import {
    useCallback,
    useRef,
    useState
} from 'react';

export const useAudioVolumeMonitor = () => {
    const [volume, setVolume] = useState(0); // Volume level between 0 and 1
    const animationFrameIdRef = useRef<number | null>(null);

    const monitorAudio = useCallback(async (
        audio: MediaStream | HTMLAudioElement,
        playback: boolean = false,
        destinationDeviceId?: string
    ) => {
        // Initialize the audio context and analyser if not already done
        const audioContext = new (window.AudioContext)();
        if (destinationDeviceId && destinationDeviceId !== 'default') {
            try {
                // @ts-ignore
                await audioContext.setSinkId?.(destinationDeviceId);
            } catch (e) {
                console.error('Failed to set audio output device:', e);
                // Fallback to default device
            }
        }
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 256;
        const dataArray = new Uint8Array(analyser.frequencyBinCount);

        // Create a media stream source from the provided stream
        let source: MediaStreamAudioSourceNode | MediaElementAudioSourceNode;
        if (audio instanceof MediaStream) {
            source = audioContext.createMediaStreamSource(audio);
        } else if (audio instanceof HTMLAudioElement) {
            source = audioContext.createMediaElementSource(audio);
        } else {
            throw new Error('Invalid audio source');
        }
        source.connect(analyser);
        if (playback) {
            // Connect the analyser to the destination if playback is enabled
            analyser.connect(audioContext.destination);
        }

        // Start monitoring the audio volume
        const updateVolume = () => {
            analyser?.getByteFrequencyData(dataArray!);
            const max = Math.max(...dataArray);
            setVolume(max / 255);
            animationFrameIdRef.current = requestAnimationFrame(updateVolume);
        };
        updateVolume();

        return () => {
            if (audioContext) {
                audioContext.close();
            }
            if (animationFrameIdRef.current) {
                cancelAnimationFrame(animationFrameIdRef.current);
            }
            setVolume(0);
        };
    }, []);

    return { monitorAudio, volume };
};

