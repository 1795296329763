import {
    DataGrid,
    GridColDef,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton
} from "@mui/x-data-grid";
import {
    Grid,
    LinearProgress
} from "@mui/material";
import {
    getSetUpTableRowsCallback,
    rowAtom
} from "../../../../lib/solution/state/SolutionDetailsState";
import {
    useEffect,
    useState
} from "react";

import { SolutionTenderingType } from "../../../../models";
import { hidePricesInUIAtom } from "../../../../lib/design/bidding/state/v2/ProposalStates";
import { useRecoilValue } from "recoil";

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <Grid container justifyContent="space-between">
                <GridToolbarExport />
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </Grid>
        </GridToolbarContainer>
    );
};

type SolutionDetailsTableProps = {
    tenderingType?: SolutionTenderingType;
};

export const SolutionDetailsTable = (props: SolutionDetailsTableProps) => {
    const { tenderingType = SolutionTenderingType.SOLE_SOURCE } = props;
    const [isPageInitialized, setIsPageInitialized] = useState<boolean>(false);
    const arePricesHidden = useRecoilValue<boolean>(hidePricesInUIAtom);
    const rows = useRecoilValue<Array<object>>(rowAtom);

    const setUpRows = getSetUpTableRowsCallback();

    useEffect(() => {
        const initialize = async () => {
            await setUpRows();
            setIsPageInitialized(true);
        };
        initialize();
    }, []);

    const tableColumns: GridColDef[] = [
        {
            field: 'locationName',
            headerName: 'Group Name',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'issueName',
            headerName: 'Issue Name',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'length',
            headerName: 'Length',
            type: "number",
            flex: 1,
            minWidth: 50,
        },
        {
            field: 'width',
            headerName: 'Width',
            type: "number",
            flex: 1,
            minWidth: 50,
        },
        {
            field: 'dimensionDescription',
            headerName: 'Dimension Description',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'workType',
            headerName: 'Work Type',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'longDescription',
            headerName: 'Long Description',
            description: 'Long Description',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: "number",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "unitType",
            headerName: 'Unit Type',
            description: 'Unit',
            align: "right",
            flex: 1,
            minWidth: 100,
        },
    ];

    const priceColumns: GridColDef[] = [
        {
            field: 'pricePerUnit',
            headerName: 'Price Per Unit',
            type: "number",
            hideable: arePricesHidden ? true : false,
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'totalPrice',
            headerName: 'Total Price',
            type: "number",
            hideable: arePricesHidden ? true : false,
            flex: 1,
            minWidth: 100,
        }
    ];

    return (
        !isPageInitialized ? (
            <LinearProgress />
        ) : (
            <DataGrid
                autoHeight
                columns={tenderingType == SolutionTenderingType.TENDERED ? tableColumns : [...tableColumns, ...priceColumns]}
                localeText={{ toolbarColumns: "Select columns" }}
                rows={rows}
                components={{ Toolbar: CustomToolbar }}
            />
        )
    );
};
