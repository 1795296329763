import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

/**
 * This hook is written very precisely to work around a special behaviour in Safari.
 * As a saftey measure, Safari will only allow access to the clipboard in response to user input.
 * This is done by only allowing a navigator accessed withing the context of a user event to access the clipboard.
 * 
 * This hook accepts the navigator object as a parameter passed from a button's onclick.
 * 
 * Additionally the ClipboardItem must be built using a Promise<Blob> as a parameter. If not, clipboard.write will 
 * fail with an access denied error.
 * 
 * Tl:dr; test this hook in Safari thoroughly when making changes.
 */
const useCopyImageToClipboard = () => {

    const snackbar = useSnackbar();

    const getImageBlob = async (imageURL: string) => {
        const response = await fetch(imageURL);
        return await response.blob();
    }

    const copyImageToClipboard = useCallback(async (imageURL: string, nav: Navigator) => {
        nav.clipboard.write([new ClipboardItem({ 'image/png': getImageBlob(imageURL) })]).then(() => {;
            snackbar.enqueueSnackbar('Image copied to clipboard', { variant: 'success' });
        }).catch((error) => {
            snackbar.enqueueSnackbar(`Failed to copy image to clipboard: ${error}`, { variant: 'error' });
        });
    }, []);

    return copyImageToClipboard;
};

export default useCopyImageToClipboard;
