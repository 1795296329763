import {
    RecoilState,
    Snapshot,
    useRecoilCallback
} from "recoil";
import {
    associatedWorkTypeGroupIdsAtom,
    latestWorkTypeByGroupIdAtomFamily,
    workTypeGroupByIdAtomFamily,
    workTypePricingByGroupIdAtomFamily
} from "../state/WorkTypeState";

import DataStoreWorkTypeGroupDAOFactory from "../group/dataStore/DataStoreWorkTypeGroupDAOFactory";
import DataStoreWorkTypePricingDAOFactory from "../../design/worktype/pricing/dataStore/DataStoreWorkTypePricingDAOFactory";
import { FetchWorkTypeError } from "../state/error/FetchWorkTypeError";
import ListEntityAssociatedWorkTypesHandler from "../handler/listEntityAssociatedWorkTypes/ListEntityAssociatedWorkTypesHandler";
import ListEntityAssociatedWorkTypesHandlerFactory from "../handler/listEntityAssociatedWorkTypes/ListEntityAssociatedWorkTypesHandlerFactory";
import ListEntityAssociatedWorkTypesResponse from "../handler/listEntityAssociatedWorkTypes/ListEntityAssociatedWorkTypesResponse";
import WorkTypeDTO from "../DTO/WorkTypeDTO";
import { WorkTypeGroup } from "../../../models";
import WorkTypeGroupDAO from "../group/WorkTypeGroupDAO";
import WorkTypePricingDAO from "../../design/worktype/pricing/WorkTypePricingDAO";
import WorkTypePricingDTO from "../../design/worktype/pricing/DTO/WorkTypePricingDTO";
import { workTypeByWorkTypeIdAtomFamily } from "../state/WorkTypeRecoilState";

const workTypePricingDAO: WorkTypePricingDAO = DataStoreWorkTypePricingDAOFactory.getInstance();
const listEntityAssociatedWorkTypeHandler: ListEntityAssociatedWorkTypesHandler = ListEntityAssociatedWorkTypesHandlerFactory.getInstance();
const workTypeGroupDAO: WorkTypeGroupDAO = DataStoreWorkTypeGroupDAOFactory.getInstance();

export const useFetchEntityWorkTypes = () => {
    const fetchEntityWorkTypes = useRecoilCallback(({ set, snapshot }) => {
        return async (entityId: string) => {
            const releaseSnapshot = snapshot.retain();
            try {
                return await fetchWorkTypesForEntity(snapshot, set, entityId);
            } catch (error) {
                throw new FetchWorkTypeError(
                    `Error occurred while trying to fetch workTypes for entity id: ${entityId}`,
                    { cause: error as Error }
                );
            }
            finally {
                releaseSnapshot();
            }
        };
    });

    const fetchWorkTypesForEntity = async (
        snapshot: Snapshot,
        set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
        entityId: string
    ): Promise<void> => {
        const associatedWorkTypesResult: ListEntityAssociatedWorkTypesResponse = await listEntityAssociatedWorkTypeHandler.handle({ entityId: entityId });
        const workTypes: Array<WorkTypeDTO> = associatedWorkTypesResult.entityAssociatedWorkTypes;
        const workTypeGroupIds: Array<string> = Array.from(workTypes.map(wt => wt.groupId));
        set(associatedWorkTypeGroupIdsAtom, workTypeGroupIds);
        const pricingFetchPromises = workTypes.map(async workType => {
            set(latestWorkTypeByGroupIdAtomFamily(workType.groupId), workType);
            set(workTypeByWorkTypeIdAtomFamily(workType.id), workType);
            try {
                await fetchWorkTypePricingByWorkTypeGroupIdAndEntityId(
                    snapshot,
                    set,
                    entityId,
                    workType.groupId
                );
            } catch (error) {
                // WorkTypePricing is not guaranteed to exist, in which case default atom value is used.
                // Do nothing, just catch the error to prevent promise rejection
            }
        });
        await Promise.all(pricingFetchPromises);
        const workTypeGroupPromises = workTypeGroupIds.map(async workTypeGroupId => {
            const workTypeGroup: WorkTypeGroup = await workTypeGroupDAO.getById(workTypeGroupId);
            set(workTypeGroupByIdAtomFamily(workTypeGroupId), workTypeGroup);
        });
        await Promise.all(workTypeGroupPromises);
    };

    const fetchWorkTypePricingByWorkTypeGroupIdAndEntityId = async (
        snapshot: Snapshot,
        set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
        entityId: string,
        workTypGroupId: string
    ): Promise<void> => {
        const optionalWorkTypePricingDto: WorkTypePricingDTO | undefined = await workTypePricingDAO.getByWorkTypeGroupIdAndEntityId(workTypGroupId, entityId);
        set(workTypePricingByGroupIdAtomFamily(workTypGroupId), optionalWorkTypePricingDto ?? null);
    };

    return { fetchEntityWorkTypes };
};