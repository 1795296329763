import {
    AudioVideoObserver
} from "amazon-chime-sdk-js";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useMemo } from "react";
import { useSnackbar } from "notistack";

export const RECONNECTING_SNACKBAR_KEY = "RECONNECTING_SNACKBAR_KEY";
export const POOR_NETWORK_SNACKBAR_KEY = "POOR_NETWORK_SNACKBAR_KEY";

export const useConnectivityMonitor = () => {
    const snackbar = useSnackbar();
    const meetingManager = useMeetingManager();

    const audioVideoObserver = useMemo<AudioVideoObserver>(() => {
        return {
            /**
             * connectionDidSuggestStopVideo and connectionDidBecomePoor are mutually exclusive.
             * Both represent a degradation in call quality, only difference is whether the user
             * has shared video or not.
             * */
            connectionDidSuggestStopVideo: () => {
                snackbar.enqueueSnackbar(
                    "Poor network. Call disruptions or disconnection possible. Please check your connection.",
                    {
                        preventDuplicate: true,
                        variant: 'warning',
                        persist: true,
                        key: POOR_NETWORK_SNACKBAR_KEY
                    }
                );
            },
            connectionDidBecomePoor: () => {
                snackbar.enqueueSnackbar(
                    "Poor network. Call disruptions or disconnection possible. Please check your connection.",
                    {
                        preventDuplicate: true,
                        variant: 'warning',
                        persist: true,
                        key: POOR_NETWORK_SNACKBAR_KEY
                    }
                );
            },
            connectionDidBecomeGood: () => {
                snackbar.closeSnackbar(POOR_NETWORK_SNACKBAR_KEY);
            },
            audioVideoDidStartConnecting: (reconnecting: boolean) => {
                if (reconnecting) {
                    snackbar.enqueueSnackbar('Reconnecting...', {
                        key: RECONNECTING_SNACKBAR_KEY,
                        variant: 'info'
                    });
                }
            }
        };
    }, []);

    const subscribeToConnectivityEvents = () => {
        meetingManager.audioVideo?.addObserver(audioVideoObserver);
    };

    const unsubscribeFromConnectivityEvents = () => {
        meetingManager.audioVideo?.removeObserver(audioVideoObserver);
        snackbar.closeSnackbar(RECONNECTING_SNACKBAR_KEY);
        snackbar.closeSnackbar(POOR_NETWORK_SNACKBAR_KEY);
    };

    return {
        subscribeToConnectivityEvents,
        unsubscribeFromConnectivityEvents
    };
};