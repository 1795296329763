import {
    Dimension,
    PermissionResourceType,
} from '../../models';
import {
    OnCreateDimensionSubscription,
    OnDeleteDimensionSubscription,
    OnUpdateDimensionSubscription
} from '../../API';
import {
    onCreateDimension,
    onDeleteDimension,
    onUpdateDimension,
} from '../../graphql/subscriptions';

import { API } from 'aws-amplify';
import { AuthIDTokenSupplierFactory } from '../auth/AuthIDTokenSupplierFactory';
import AuthUsernameSupplierFactory from '../auth/AuthUsernameSupplierFactory';
import { ContextAwareIdentifier } from '../design/document/ContextAwareIdentifier';
import { GraphQLSubscription } from '@aws-amplify/api';
import { ModelType } from '../design/document/ModelType';
import { StateContext } from '../design/document/state/StateContext';
import { dimensionByDimensionIdentifierSelectorFamily } from '../design/document/state/DocumentState';
import { fetchPermissionsForResource } from '../permission/state/ResourcePermissionRecoilState';
import { useRecoilCallback } from 'recoil';

let createSub: ZenObservable.Subscription | undefined;
let updateSub: ZenObservable.Subscription | undefined;
let deleteSub: ZenObservable.Subscription | undefined;
const tokenSupplier = AuthIDTokenSupplierFactory.getInstance()
const usernameSupplier = AuthUsernameSupplierFactory.getInstance()

export const useDimensionSubscription = () => {
    const subscribe = async (propertyId: string) => {
        const username = await usernameSupplier.get();
        const authCode = await tokenSupplier.get();

        const filterVariables = {
            canView: {
                contains: username
            },
            propertyId: {
                eq: propertyId
            }
        }
        createSub = API.graphql<GraphQLSubscription<OnCreateDimensionSubscription>>({
            query: onCreateDimension,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handlWorkSpecificationUpdate(value.data?.onCreateDimension as Dimension, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
        updateSub = API.graphql<GraphQLSubscription<OnUpdateDimensionSubscription>>({
            query: onUpdateDimension,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handlWorkSpecificationUpdate(value.data?.onUpdateDimension as Dimension, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
        deleteSub = API.graphql<GraphQLSubscription<OnDeleteDimensionSubscription>>({
            query: onDeleteDimension,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handleWorkSpecificationDelete(value.data?.onDeleteDimension as Dimension, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
    }

    const handlWorkSpecificationUpdate = useRecoilCallback(({ set, snapshot }) => async (dimension: Dimension, context: StateContext) => {
        const dimensionIdentifier = new ContextAwareIdentifier(dimension.id, context, ModelType.DIMENSION);
        const existingDimension = await snapshot.getPromise(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier));
        if (existingDimension == null) {
            await fetchPermissionsForResource(dimension.id, PermissionResourceType.DIMENSION, set);
            set(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier), dimension);
            return dimension;
        } else {
            if (existingDimension?.localLastUpdatedTime! >= dimension.localLastUpdatedTime!) {
                // Ignore updates that are older than the current state
                return;
            }
            set(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier), dimension);
            return dimension;
        }
    });

    const handleWorkSpecificationDelete = useRecoilCallback(({ set, reset, snapshot }) => async (dimension: Dimension, context: StateContext) => {
        const dimensionIdentifier = new ContextAwareIdentifier(dimension.id, context, ModelType.DIMENSION);
        reset(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier));
        return dimension;
    });

    const unsubscribe = () => {
        createSub?.unsubscribe();
        updateSub?.unsubscribe();
        deleteSub?.unsubscribe();
    }

    return { subscribe, unsubscribe }
}