import { TypographyProps } from "@mui/material";

export const H1_TYPOGRAPHY_STYLE: TypographyProps = {
    fontSize: "1.25rem",
    fontWeight: 500
};

export const H2_TYPOGRAPHY_STYLE: TypographyProps = {
    fontSize: "1rem",
    fontWeight: 400,
    color: "text.secondary"
};