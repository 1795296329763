import { GenericIngressCompletionChecker } from "../GenericIngressCompletionChecker";
import GraphQLSolutionDAOFactory from "../../../solution/majorVersion/dao/GraphQLSolutionDAOFactory";
import { SolutionDAOToResourcePermissionMetadataDAOAdapter } from "../adapter/SolutionDAOToResourcePermissionMetadataDAOAdapter";

export class SolutionIngressCompletionCheckerFactory {
    private static INSTANCE = new GenericIngressCompletionChecker(
        new SolutionDAOToResourcePermissionMetadataDAOAdapter(
            GraphQLSolutionDAOFactory.getInstance()
        )
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}