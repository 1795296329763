import GraphQLDefaultWorkTypePricingDAOFactory from "../../defaultPricing/graphql/GraphQLDefaultWorkTypePricingDAOFactory";
import SetDefaultPricingForWorkTypeGroupOrchestrator from "./SetDefaultPricingForWorkTypeGroupOrchestrator";
import SetDefaultPricingForWorkTypeGroupOrchestratorImpl from "./SetDefaultPricingForWorkTypeGroupOrchestratorImpl";

export default class SetDefaultPricingForWorkTypeGroupOrchestratorFactory {
    private static readonly INSTANCE: SetDefaultPricingForWorkTypeGroupOrchestrator = new SetDefaultPricingForWorkTypeGroupOrchestratorImpl(
        GraphQLDefaultWorkTypePricingDAOFactory.getInstance()
    );

    public static getInstance(): SetDefaultPricingForWorkTypeGroupOrchestrator {
        return this.INSTANCE;
    }
}
