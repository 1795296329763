import {
    PermissionResourceType,
    Property
} from "../../../models";

import DataStorePropertyDAOFactory from "../dao/datastore/DataStorePropertyDAOFactory";
import PropertyDAO from "../dao/PropertyDAO";
import { fetchPermissionsForResource } from "../../permission/state/ResourcePermissionRecoilState";
import { propertyIdInFocusAtom } from "../../ui/InFocusRecoilStates";
import { propertyIdToPropertySelectorFamily } from "../state/PropertyRecoilState";
import { useRecoilCallback } from "recoil";

const propertyDao: PropertyDAO = DataStorePropertyDAOFactory.getInstance();

export const useSetUpProperty = () => {

    const setUpProperty = useRecoilCallback(({ snapshot, set }) => async (propertyId: string) => {
        const property: Property = await propertyDao.getById(propertyId);
        await fetchPermissionsForResource(propertyId, PermissionResourceType.PROPERTY, set);
        set(propertyIdToPropertySelectorFamily(propertyId), property);
        set(propertyIdInFocusAtom, propertyId);
    }, []);

    const resetProperty = useRecoilCallback(({snapshot, reset }) => () => {
        const propertyId = snapshot.getLoadable(propertyIdInFocusAtom).contents;
        reset(propertyIdToPropertySelectorFamily(propertyId));
        reset(propertyIdInFocusAtom);
        //TODO: reset permissions
    }, []);

    return { setUpProperty, resetProperty };
};