import * as yup from "yup";

import { isPossiblePhoneNumber } from "react-phone-number-input";

const REQUIRED_FIELD_TEXT = "This is a required field";

export const SOLUTION_METADATA_VALIDATOR = yup.object({
    name: yup.string().required(REQUIRED_FIELD_TEXT),
    propertyContactName: yup.string().required(REQUIRED_FIELD_TEXT),
    propertyAddress: yup.string().required(REQUIRED_FIELD_TEXT),
    propertyType: yup.string(),
    solutionDueDate: yup.date().nullable()
        .typeError("Must be a valid date")
        .transform((value, originalValue, context) => {
            // Date is persisted as a String, so this transform is necessary to compare against Date()s
            if (context.isType(value)) {
                return value;
            }
            return value ? new Date(parseInt(originalValue)) : new Date();
        })
        .min(new Date().setHours(0, 0, 0, 0), "Date must not be in the past"),
    consultantName: yup.string(),
    consultantPhoneNumber: yup.string().nullable().test("consultantPhoneTest", "Must be a valid phone number", (value) => {
        if (!value) {
            return true;
        }
        return isPossiblePhoneNumber(value);
    }),
    consultantEmail: yup.string().email("Must be a valid email address")
});
