import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const ShowAllIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="M10.429 1v9.429H1V1h9.429m1-1H0v11.429h11.429V0zM23 1v9.429h-9.429V1H23m1-1H12.571v11.429H24V0zM10.429 13.571V23H1v-9.429h9.429m1-1H0V24h11.429V12.571zM23 13.571V23h-9.429v-9.429H23m1-1H12.571V24H24V12.571z"
        className="primary"
      />
    </g>
  </svg>
);
export default withCustomIcon(ShowAllIcon);
