import DataStoreLocationDAOFactory from "../../../location/dao/datastore/DataStoreLocationDAOFactory";
import { GenericIngressCompletionChecker } from "../GenericIngressCompletionChecker";
import { LocationDAOToResourcePermissionMetadataDAOAdapter } from "../adapter/LocationDAOToResourcePermissionMetadataDAOAdapter";

export class LocationIngressCompletionCheckerFactory {
    private static INSTANCE = new GenericIngressCompletionChecker(
        new LocationDAOToResourcePermissionMetadataDAOAdapter(
            DataStoreLocationDAOFactory.getInstance()
        )
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}