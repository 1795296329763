import {
    Solution,
    SolutionStatus
} from "../../../../models";

import PublishSolutionHandler from "./PublishSolutionHandler";
import PublishSolutionHandlerError from "./error/PublishSolitionHandlerError";
import SolutionDAO from "../../majorVersion/dao/SolutionDAO";

export default class PublishSolutionHandlerImpl implements PublishSolutionHandler {

    private readonly solutionDAO: SolutionDAO;

    constructor(
        solutionDAO: SolutionDAO,
    ) {
        this.solutionDAO = solutionDAO;
    }

    public async handle(
        id: string
    ): Promise<Solution> {
        try {
            const publishedSolution: Solution = await this.solutionDAO.updateStatus(
                id,
                SolutionStatus.PENDING_REVIEW
            );
            return publishedSolution;
        } catch (error) {
            throw new PublishSolutionHandlerError(
                `Error occurred while publishing the Solution with ID=${id}`,
                { cause: error as Error }
            );
        }
    }
}
