import {
    atomFamily,
    selectorFamily
} from "recoil";

import DataStoreIssueDAOFactory from "../../../issue/dao/datastore/DataStoreIssueDAOFactory";
import { IssueDAO } from "../../../issue/dao/IssueDAO";
import { IssueDefaultNameFactory } from "../../../../components/issue/name/IssueDefaultNameFactory";
import { StateContext } from "./StateContext";
import { propertyIdInFocusAtom } from "../../../ui/InFocusRecoilStates";

const INSPECTION_DEFAULT_ISSUE_NAME = "Issue";
const SOLUTION_DEFAULT_ISSUE_NAME = "Solution Issue";

const issueDao: IssueDAO = DataStoreIssueDAOFactory.getInstance();

export const numberOfIssuesCreatedAtomFamily = atomFamily<number, StateContext>({
    key: "numberOfIssuesCreatedAtomFamily",
    default: 0
});

export const issueDefaultNameFactorySelectorFamily = selectorFamily<IssueDefaultNameFactory | undefined, StateContext>({
    key: "issueDefaultNameFactorySelectorFamily",
    get: (context: StateContext) => ({get}) => {
        if (context === StateContext.INSPECTION) {
            const propertyId = get(propertyIdInFocusAtom);
            return new IssueDefaultNameFactory(INSPECTION_DEFAULT_ISSUE_NAME, async () => {
                const issues = await issueDao.getIssuesByPropertyId(propertyId!);
                return issues.length + 1;
            });
        }
        if (context === StateContext.SOLUTION_AUTHORING_CONTENT) {
            const numberOfIssuesCreated = get(numberOfIssuesCreatedAtomFamily(context));
            return new IssueDefaultNameFactory(SOLUTION_DEFAULT_ISSUE_NAME, async () => {
                return numberOfIssuesCreated + 1;
            });
        }
        return undefined;
    }
});
