import ClientLoggerFactory from "../logging/ClientLoggerFactory";
import { DataStore } from "aws-amplify";
import DataStoreUserOrganizationAssociationDAO from "./DataStoreUserOrganizationAssociationDAO";
import UserOrganizationAssociationDAO from "./UserOrganizationAssociationDAO";

export default class DataStoreUserOrganizationAssociationDAOFactory {
    private static readonly INSTANCE: UserOrganizationAssociationDAO = new DataStoreUserOrganizationAssociationDAO(
        ClientLoggerFactory.getClientLogger("DataStoreUserOrganizationAssociationDAO"),
        DataStore
    );
    public static getInstance() {
        return this.INSTANCE;
    }
}