import { DimensionType } from "../../../models";

export default class DimensionTypeAbbreviationFactory {
    public static getAbbreviation(dimensionType: DimensionType): string {
        if (dimensionType === DimensionType.LINEAR) {
            return "";
        }
        if (dimensionType === DimensionType.SQUARE) {
            return "sq";
        }
        throw new Error("Unknown dimension type.");
    }
}
