import {
    Box,
    Button,
    Stack,
    Typography
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { RoundedCard } from "../../componentsV2/misc/RoundedCard";
import { StartPropertiesChildrenSyncWrapper } from "../../components/sync/StartPropertiesChildrenSyncWrapper";
import { WorkTypeFetchWrapper } from "../../components/WorkTypeFetchWrapper";
import WorkTypeManagementList from "../../componentsV2/worktype/WorkTypeManagementList";
import WorkTypePicker from "../../componentsV2/worktype/WorkTypePicker";
import globalStyle from "../../assets/style/globalStyle";
import { usePageDefaultStyle } from "../../assets/style/usePageDefaultStyle";
import { useState } from "react";

export const OrganizationManagementPage = () => {
    /* Internal page states */
    const [isWorkTypePickerOpen, setIsWorkTypePickerOpen] = useState<boolean>(false);

    /* Dependencies */
    const globalClasses = globalStyle();
    const { pageDefaultStyle } = usePageDefaultStyle();

    return (
        <StartPropertiesChildrenSyncWrapper>
            <Box sx={pageDefaultStyle}>
                <Box className={globalClasses.managerLayout}>
                    <WorkTypeFetchWrapper>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h4">
                                Team Work Types
                            </Typography>
                            <Button
                                variant="outlined"
                                size="medium"
                                startIcon={<AddIcon />}
                                onClick={() => setIsWorkTypePickerOpen(true)}
                            >
                                Add
                            </Button>
                        </Stack>
                        <WorkTypePicker
                            isOpen={isWorkTypePickerOpen}
                            onClose={() => setIsWorkTypePickerOpen(false)}
                        />
                        <RoundedCard>
                            <WorkTypeManagementList />
                        </RoundedCard>
                    </WorkTypeFetchWrapper>
                </Box>
            </Box>
        </StartPropertiesChildrenSyncWrapper>
    );
};
