import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import {
    Resetter,
    SetterOrUpdater
} from "recoil";

import { EditIcon } from "../icons";
import { useRecordHotkeys } from "react-hotkeys-hook";
import { useState } from "react";

type UpdateHotKeyItemProps = {
    commandName: string;
    hotKey: string;
    setHotKeys: SetterOrUpdater<string>;
    resetHotKeys: Resetter;
};

export const UpdateHotKeyItem = (props: UpdateHotKeyItemProps) => {
    const { commandName, hotKey, setHotKeys, resetHotKeys } = props;
    const [keys, { start, stop, resetKeys }] = useRecordHotkeys();
    const [openRecordingDialog, setOpenRecordingDialog] = useState<boolean>(false);

    return (
        <>
            <IconButton
                size="small"
                onClick={() => {
                    setOpenRecordingDialog(true);
                    start();
                }}
            >
                <EditIcon fontSize="small" />
            </IconButton>
            <Typography>
                {commandName}
            </Typography>
            <Box display="flex" gap={1} alignItems="center" justifyContent="space-between">
                <Typography>
                    {hotKey}
                </Typography>
                <Button size="small" onClick={resetHotKeys} variant="outlined">
                    Reset to default
                </Button>
            </Box>
            <Dialog open={openRecordingDialog}>
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <DialogContentText>
                            Press the key you want to use for this command
                        </DialogContentText>
                        <Box display="flex" gap={1} alignItems="center" justifyContent="space-between">
                            <TextField
                                fullWidth
                                size="small"
                                onClick={() => {
                                    resetKeys();
                                    start();
                                }}
                                value={Array.from(keys).join(" + ")}
                                autoComplete="off"
                            />
                            <Button
                                size="small"
                                onClick={() => {
                                    resetKeys();
                                }}
                            >
                                Clear
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            stop();
                            resetKeys();
                            setOpenRecordingDialog(false);
                        }}
                        color="error"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            stop();
                            setOpenRecordingDialog(false);
                            setHotKeys(Array.from(keys).join(" + "));
                        }}
                        color="primary"
                        variant="outlined"
                        disabled={keys.size === 0}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
