import AuthTokenRefreshEventHandler from "./AuthTokenRefreshEventHandler";
import { SetterOrUpdater } from "recoil";

export default class AuthHubEventHandlerFactory {
    public static create(
        setTokenExpireEpochMillis: SetterOrUpdater<number | undefined>
    ) {
        return new AuthTokenRefreshEventHandler(
            setTokenExpireEpochMillis
        );
    }
}
