import {
    DefaultValue,
    RecoilState,
    Snapshot,
    atom,
    atomFamily,
    selector,
    selectorFamily,
    useRecoilCallback
} from "recoil";
import {
    PermissionResourceType,
    Property
} from "../../../models";
import {
    propertySortByFieldAtom,
    propertySortDirectionAtom
} from "./PropertySortRecoilState";

import { ByAddressComparator } from "../sort/ByAddressComparator";
import { ByRecentlyCreatedComparator } from "../sort/ByRecentlyCreatedComparator";
import { ByRecentlyUpdatedComparator } from "../sort/ByRecentlyUpdatedComparator";
import ClientLogger from "../../logging/ClientLogger";
import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import DataSorter from "../../util/data/sort/DataSorter";
import DataStorePropertyDAOFactory from "../dao/datastore/DataStorePropertyDAOFactory";
import MergeDataSorterFactory from "../../util/data/sort/MergeDataSorterFactory";
import PropertyDAO from "../dao/PropertyDAO";
import { PropertySortByField } from "../sorting/PropertySortByField";
import SearchCriteria from "../../search/SearchCriteria";
import { SetUpPropertyStateError } from "./error/SetUpPropertyStateError";
import { SortDirection } from "../../sorting/SortDirection";
import { fetchPermissionsForResource } from "../../permission/state/ResourcePermissionRecoilState";
import { propertyIdInFocusAtom } from "../../ui/InFocusRecoilStates";
import { searchCriteriaState } from "../../search/SearchCriteriaState";

const propertyDao: PropertyDAO = DataStorePropertyDAOFactory.getInstance();
const logger: ClientLogger = ClientLoggerFactory.getClientLogger("PropertyRecoilState");
const byAddressAscendingSorter = MergeDataSorterFactory.createInstance(new ByAddressComparator(SortDirection.ASCENDING));
const byAddressDescendingSorter = MergeDataSorterFactory.createInstance(new ByAddressComparator(SortDirection.DESCENDING));
const byRecentlyCreatedAscendingSorter = MergeDataSorterFactory.createInstance(new ByRecentlyCreatedComparator(SortDirection.ASCENDING));
const byRecentlyCreatedDescendingSorter = MergeDataSorterFactory.createInstance(new ByRecentlyCreatedComparator(SortDirection.DESCENDING));
const byRecentlyUpdatedAscendingSorter = MergeDataSorterFactory.createInstance(new ByRecentlyUpdatedComparator(SortDirection.ASCENDING));
const byRecentlyUpdatedDescendingSorter = MergeDataSorterFactory.createInstance(new ByRecentlyUpdatedComparator(SortDirection.DESCENDING));

export const getSetUpPropertyStateCallback = () => useRecoilCallback(({ snapshot, set }) => {
    return async (
        propertyId: string
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            await setUpPropertyState(snapshot, set, propertyId);
        } catch (error) {
            logger.error(
                `Error occurred while trying to set up State for Property with id: ${propertyId}`,
                error,
                []
            );
            throw new SetUpPropertyStateError(
                `Error occurred while trying to set up State for Property with id: ${propertyId}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

const setUpPropertyState = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    propertyId: string
): Promise<void> => {
    const property: Property = await propertyDao.getById(propertyId);
    await fetchPermissionsForResource(propertyId, PermissionResourceType.PROPERTY, set);
    set(propertyIdToPropertySelectorFamily(propertyId), property);
    set(propertyIdInFocusAtom, propertyId);
};

/* Use this atom for ALL persisted property ID keys */
export const propertyIdsAtom = atom<Array<string>>({
    key: "propertyIdsAtom",
    default: []
});

/* Internal Property state storage, do not reference from components */
export const propertyByPropertyIdAtomFamily = atomFamily<Property | null, string>({
    key: "propertyByPropertyIdAtomFamily",
    default: null
});

/* Use this selector family for CRUD operations on Location state */
export const propertyIdToPropertySelectorFamily = selectorFamily<Property | null, string>({
    key: "propertyIdToPropertySelectorFamily",
    get: (id: string) => ({ get }) => {
        return get(propertyByPropertyIdAtomFamily(id));
    },
    set: (id: string) => ({ get, set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(propertyIdsAtom, (prevValue: Array<string>) => {
                const updatedValue: Array<string> = [...prevValue];
                const index: number = updatedValue.indexOf(id);
                updatedValue.splice(index, 1);
                return updatedValue;
            });
            reset(propertyByPropertyIdAtomFamily(id));
            return;
        }

        set(propertyIdsAtom, (prevValue: Array<string>) => {
            if (prevValue.indexOf(id) !== -1) {
                return prevValue;
            }
            return [...prevValue, id];
        });
        set(propertyByPropertyIdAtomFamily(id), newValue);
    }
});

export const recentlyCreatedPropertyIdListAtom = atom<Array<string>>({
    key: "recentlyCreatedPropertyIdListAtom",
    default: []
});

export const offlinePropertyIdListAtom = atom<Array<string>>({
    key: "offlinePropertyIdListAtom",
    default: []
});

export const propertyIdsToDisplaySelector = selector<Array<string>>({
    key: "propertyIdsToDisplaySelector",
    get: ({ get }) => {
        let propertyIds = get(propertyIdsAtom);
        const sortByField: PropertySortByField = get(propertySortByFieldAtom);
        const sortDirection: SortDirection = get(propertySortDirectionAtom);
        const searchCriteria: SearchCriteria = get(searchCriteriaState);
        let properties: Array<Property> = propertyIds.map((propertyId: string) => {
            return get(propertyIdToPropertySelectorFamily(propertyId));
        }).filter(property => property != null) as Array<Property>;

        if (searchCriteria.keywords) {
            properties = properties.filter((property: Property) => {
                const matchPropertyName = property.name?.toLowerCase().includes(searchCriteria.keywords.toLowerCase());
                const matchPropertyAddress = property.address?.toLowerCase().includes(searchCriteria.keywords.toLowerCase());
                return matchPropertyName || matchPropertyAddress;
            });
        }

        let sorter: DataSorter<Property> | null = null;
        switch (sortByField) {
            case PropertySortByField.ADDRESS:
                if (sortDirection === SortDirection.DESCENDING) {
                    sorter = byAddressDescendingSorter;
                } else {
                    sorter = byAddressAscendingSorter;
                }
                break;
            case PropertySortByField.CREATED_AT:
                if (sortDirection === SortDirection.DESCENDING) {
                    sorter = byRecentlyCreatedDescendingSorter;
                } else {
                    sorter = byRecentlyCreatedAscendingSorter;
                }
                break;
            case PropertySortByField.UPDATED_AT:
                if (sortDirection === SortDirection.DESCENDING) {
                    sorter = byRecentlyUpdatedDescendingSorter;
                } else {
                    sorter = byRecentlyUpdatedAscendingSorter;
                }
                break;
            default:
                throw new Error(`Unknown sort property ${sortByField}`);
        }
        properties = sorter ? sorter.sort(properties) : properties;
        return properties.map((property: Property) => property.id);
    }
});


