/**
 * CHECKED - Indicates that an individual checkbox is set to true, or that a parent checkbox's children are all set to true
 * INDETERMINATE - Indicates that a parent checkbox has some children Checked and some Unchecked
 * UNCHECKED - Indicates that an individual checkbox is set to false, or that all of a parent checkbox's children are set to false
 */
export enum CheckBoxValue {
    CHECKED,
    INDETERMINATE,
    UNCHECKED
}
