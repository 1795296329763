import './index.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { Amplify } from '@aws-amplify/core';
import App from './App';
import LogRocket from 'logrocket';
import React from 'react';
import ReactDOM from 'react-dom';
import awsExports from "./aws-exports";
import reportWebVitals from './reportWebVitals';

Amplify.configure({
    ...awsExports,
    Auth: {
        cookieStorage: {
            domain: window.location.hostname,
            expire: 365,
            path: "/",
            secure: window.location.protocol === "https:" ? true : false
        }
    }
});

const REACT_APP_ENABLE_LOGROCKET = process.env.REACT_APP_ENABLE_LOGROCKET || "false";
if (REACT_APP_ENABLE_LOGROCKET === "true") {
    LogRocket.init('aihsar/tenera');
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
