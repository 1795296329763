import CounterAdoptingSerialNumberGeneratorFactory from "../../serialNumber/CounterAdoptingSerialNumberGeneratorFactory";
import { DefaultTenderDAO } from "./DefaultTenderDAO";
import { GraphQLTenderMajorVersionDAOFactory } from "../majorVersion/dao/graphql/GraphQLTenderMajorVersionDAOFactory";
import { GraphQLTenderMinorVersionDAOFactory } from "../minorversion/dao/graphql/GraphQLTenderMinorVersionDAOFactory";
import { TenderDAO } from "./TenderDAO";
import UuidGeneratorFactory from "../../util/UuidGeneratorFactory";

export class DefaultTenderDAOFactory {
    private static INSTANCE: TenderDAO = new DefaultTenderDAO(
        GraphQLTenderMajorVersionDAOFactory.getInstance(),
        GraphQLTenderMinorVersionDAOFactory.getInstance(),
        UuidGeneratorFactory.getInstance(),
        CounterAdoptingSerialNumberGeneratorFactory.getInstance()
    )

    public static getInstance() {
        return this.INSTANCE;
    }
}