import { WorkSpecification } from "../../../models";
import Comparator from "../../util/data/sort/Comparator";
import { ComparisonResult } from "../../util/data/sort/ComparisonResult";

export class WorkSpecificationItemNumberComparatorAsc implements Comparator<WorkSpecification> {

    public compare(a: WorkSpecification, b: WorkSpecification): ComparisonResult {
        // work specification with item number always come before those without
        if (a.itemNumber != null && b.itemNumber == null) {
            return ComparisonResult.LESS_THAN;
        }
        if (a.itemNumber == null && b.itemNumber != null) {
            return ComparisonResult.GREATER_THAN;
        }
        // if both have item number, compare them
        if (a.itemNumber != null && b.itemNumber != null) {
            if (a.itemNumber < b.itemNumber) {
                return ComparisonResult.LESS_THAN;
            }
            if (a.itemNumber > b.itemNumber) {
                return ComparisonResult.GREATER_THAN;
            }
        }
        // if both don't have item number, work specification with createdAt always come before those without
        if (a.createdAt && !b.createdAt) {
            return ComparisonResult.LESS_THAN;
        }
        if (!a.createdAt && b.createdAt) {
            return ComparisonResult.GREATER_THAN;
        }
        // if both don't have item number, compare by created at
        if (a.createdAt && b.createdAt) {
            return a.createdAt.localeCompare(b.createdAt);
        }
        // if both don't have item number and created at, compare by localLastUpdatedAt
        if (a.localLastUpdatedTime && b.localLastUpdatedTime) {
            if (a.localLastUpdatedTime < b.localLastUpdatedTime) {
                return ComparisonResult.LESS_THAN;
            }
            if (a.localLastUpdatedTime > b.localLastUpdatedTime) {
                return ComparisonResult.GREATER_THAN;
            }
        }
        return ComparisonResult.EQUAL;
    }
};
