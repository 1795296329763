import {
    API,
    graphqlOperation
} from "aws-amplify";

import { AuthIDTokenSupplierFactory } from "../../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import { GraphQLIssueDAO } from "./GraphQLIssueDAO";
import { IssueDAO } from "../IssueDAO";

export class GraphQLIssueDAOFactory {
    private static INSTANCE: IssueDAO = new GraphQLIssueDAO(
        API,
        graphqlOperation,
        ClientLoggerFactory.getClientLogger("GraphQLIssueDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );
    
    static getInstance(): IssueDAO {
        return this.INSTANCE;
    }
}
