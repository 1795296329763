import DefaultUnitCountCalculator from "./DefaultUnitCountCalculator";
import UnitCountCalculator from "./UnitCountCalculator";

export default class DefaultUnitCountCalculatorFactory {
    private static readonly INSTANCE: UnitCountCalculator = new DefaultUnitCountCalculator();

    public static getInstance(): UnitCountCalculator {
        return this.INSTANCE;
    }
}
