import {
    Template,
    TemplateEntityType
} from "../../models";

import { S3_TEMPLATE_FOLDER_NAME } from "./TemplateConstants";
import { TemplateContentDAO } from "./TemplateContentDAO";
import { TemplateDAO } from "./TemplateDAO";
import { TemplateRecordDAO } from "./TemplateRecordDAO";
import _ from "lodash";
import uuidv4 from "../util/UuidGenerator";

export class TemplateDAOImpl implements TemplateDAO {
    private readonly templateRecordDAO: TemplateRecordDAO;
    private readonly templateContentDAO: TemplateContentDAO;

    constructor(
        templateRecordDAO: TemplateRecordDAO,
        templateContentDAO: TemplateContentDAO
    ) {
        this.templateRecordDAO = templateRecordDAO;
        this.templateContentDAO = templateContentDAO;
    }

    public async getRecord(id: string): Promise<Template> {
        return await this.templateRecordDAO.get(id);
    };

    public async getContent(key: string): Promise<Blob> {
        return await this.templateContentDAO.get(key);
    }

    public async create(
        record: Omit<Template, "id">,
        content: Blob
    ): Promise<Template> {
        const templateFileKey: string = `${S3_TEMPLATE_FOLDER_NAME}/${uuidv4()}`;
        const templateContentS3Key: string = await this.templateContentDAO.save(templateFileKey, content);
        return await this.templateRecordDAO.create({
            ...record,
            s3Key: templateContentS3Key
        });
    };

    public async update(
        id: string,
        updatedRecord?: Template,
        updatedContent?: Blob
    ): Promise<Template> {
        let templateRecord: Template | undefined = await this.templateRecordDAO.get(id);
        if (updatedRecord && !_.isEqual(updatedRecord, templateRecord)) {
            templateRecord = await this.templateRecordDAO.update(updatedRecord);
        }
        if (updatedContent) {
            await this.templateContentDAO.save(templateRecord.s3Key!, updatedContent);
        }
        return templateRecord;
    };

    public async delete(
        id: string,
        version: number
    ): Promise<void> {
        await this.templateRecordDAO.delete(id, version);
        // TODO: Uncomment this when we fix s3 delete permission
        // const templateRecord: Template = await this.templateRecordDAO.get(id);
        // if (templateRecord.s3Key) {
        //     await this.templateContentDAO.delete(templateRecord.s3Key);
        // }
    };

    public async listByEntity(
        entityId: string,
        entityType?: TemplateEntityType
    ): Promise<Array<Template>> {
        return await this.templateRecordDAO.listByEntity(entityId, entityType);
    }
}