import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const UnfavIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.895 23.001c-.25 0-.501-.064-.731-.196l-5.155-2.827-5.169 2.835a1.496 1.496 0 0 1-1.554-.069 1.53 1.53 0 0 1-.646-1.45l1.019-6.148-4.217-4.313a1.532 1.532 0 0 1-.359-1.583 1.456 1.456 0 0 1 1.201-.984l5.786-.882 2.583-5.526c.246-.529.762-.858 1.344-.858s1.097.329 1.344.859l2.608 5.53 5.786.881c.518.064.976.432 1.168.956a1.54 1.54 0 0 1-.306 1.583l-.024.026-4.219 4.292.992 6.125a1.485 1.485 0 0 1-1.451 1.749zM3.524 10.1l3.725 3.81c.339.339.496.822.424 1.318l-.885 5.332 4.515-2.477c.383-.229.923-.244 1.378-.018l4.525 2.481-.859-5.303a1.633 1.633 0 0 1 .333-1.263l3.809-3.88-5.13-.781a1.485 1.485 0 0 1-1.094-.815L11.998 3.7 9.765 8.481a1.527 1.527 0 0 1-1.117.838l-5.124.781z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(UnfavIcon);
