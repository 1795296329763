import { Attachment } from "../../../../models";
import { AttachmentContent } from "../../content/AttachmentContent";
import AttachmentContentDAO from "../../content/AttachmentContentDAO";
import AttachmentRecordDAO from "../../record/AttachmentRecordDAO";
import { AttachmentUploadRecoveryHandler } from "../manager/AttachmentUploadRecoveryHandler";
import AttachmentUploadRequest from "./AttachmentUploadRequest";
import AttachmentUploadRequestError from "../errors/AttachmentUploadRequestError";
import AttachmentUploadRequestQueue from "./AttachmentUploadRequestQueue";
import { AttachmentUploadStatus } from "../status/AttachmentUploadStatus";
import AttachmentUploadStatusDAO from "../status/AttachmentUploadStatusDAO";
import ClientLogger from "../../../logging/ClientLogger";
import GetAttachmentRecordByIdError from "../../record/errors/GetAttachmentRecordByIdError";

export default class AttachmentUploadRequestQueueImpl implements AttachmentUploadRequestQueue {

    private readonly attachmentUploadStatusDAO: AttachmentUploadStatusDAO;
    private readonly synchronizingAttachmentRecordDAO: AttachmentRecordDAO;
    private readonly localAttachmentRecordDAO: AttachmentRecordDAO;
    private readonly temporaryStorageAttachmentContentDAO: AttachmentContentDAO;
    private readonly attachmentRecordRecoveryHandler: AttachmentUploadRecoveryHandler;
    private readonly logger: ClientLogger;

    constructor(
        attachmentUploadStatusDAO: AttachmentUploadStatusDAO,
        synchronizingAttachmentRecordDAO: AttachmentRecordDAO,
        localAttachmentRecordDAO: AttachmentRecordDAO,
        temporaryStorageAttachmentContentDAO: AttachmentContentDAO,
        attachmentRecordRecoveryHandler: AttachmentUploadRecoveryHandler,
        logger: ClientLogger
    ) {
        this.attachmentUploadStatusDAO = attachmentUploadStatusDAO;
        this.synchronizingAttachmentRecordDAO = synchronizingAttachmentRecordDAO;
        this.localAttachmentRecordDAO = localAttachmentRecordDAO;
        this.temporaryStorageAttachmentContentDAO = temporaryStorageAttachmentContentDAO;
        this.attachmentRecordRecoveryHandler = attachmentRecordRecoveryHandler;
        this.logger = logger;
    }

    public async addAttachmentToUploadQueue(
        attachmentUploadRequest: AttachmentUploadRequest
    ): Promise<void> {
        try {
            const attachmentContentKey: string = attachmentUploadRequest.record.key!;
            const attachmentRecord: Attachment = await this.synchronizingAttachmentRecordDAO.create(attachmentUploadRequest.record);
            await this.localAttachmentRecordDAO.create(attachmentRecord);
            await this.temporaryStorageAttachmentContentDAO.saveAttachment(attachmentContentKey, attachmentUploadRequest.content);
            await this.attachmentUploadStatusDAO.setAttachmentUploadStatus(attachmentRecord.id, AttachmentUploadStatus.PENDING_UPLOAD);
        } catch (error) {
            this.logger.error(
                `Unable to add AttachmentUploadRequest to queue: ${attachmentUploadRequest.record.id}`,
                error,
                ['AddAttachmentToUploadQueueFailure']
            );
            throw new AttachmentUploadRequestError("Unable to add AttachmentUploadRequest to queue.");
        }
    }

    public async getNext(
        attachmentIdsToExclude: Set<string>
    ): Promise<AttachmentUploadRequest | null> {
        const attachmentIdToStatusMap: Map<string, AttachmentUploadStatus> = await this.attachmentUploadStatusDAO.getUploadStatusForAllAttachments();
        for (const [attachmentId, attachmentUploadStatus] of attachmentIdToStatusMap.entries()) {
            if (attachmentIdsToExclude.has(attachmentId)) {
                continue;
            }
            try {
                const attachmentRecord: Attachment = await this.synchronizingAttachmentRecordDAO.getById(attachmentId);
                const attachmentContent: AttachmentContent = await this.temporaryStorageAttachmentContentDAO.getAttachmentContentByAttachmentKey(attachmentRecord.key!);
                return {
                    content: attachmentContent,
                    record: attachmentRecord,
                    uploadStatus: attachmentUploadStatus
                };
            } catch (error) {
                if (error instanceof GetAttachmentRecordByIdError) {
                    this.logger.warn(
                        `Attempting to recover record for attachmentId: ${attachmentId}`,
                        error,
                        ['AttachmentRecordRecoveryAttempt']
                    )
                    await this.recoverRecord(attachmentId);
                    continue;
                }
                this.logger.warn(
                    `Ignoring error while getting AttachmentUploadRequest for attachmentId: ${attachmentId}`,
                    error,
                    ['GetAttachmentUploadRequestFailure']
                );
            }
        }
        return null;
    }

    public async removeAttachmentFromUploadQueue(
        attachmentId: string
    ): Promise<void> {
        this.attachmentUploadStatusDAO.removeAttachmentUploadStatus(attachmentId);
        this.localAttachmentRecordDAO.delete(attachmentId);
    }

    private async recoverRecord(attachmentId: string): Promise<void> {
        try {
            await this.attachmentRecordRecoveryHandler.attemptRecovery(attachmentId);
        } catch (error) {
            // If the record cannot be recovered, remove it from the queue
            this.logger.error(
                `Unable to recover record for attachmentId: ${attachmentId}, removing from queue.`,
                error,
                ['AttachmentRecordRecoveryFailure']
            );
            await this.removeAttachmentFromUploadQueue(attachmentId);
        }
    }
}
