import {
    Box,
    Grid,
    IconButton,
    InputAdornment,
    ListItem,
    ListItemText,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import {
    TYPOGRAPHY_ATTRIBUTE_LABEL,
    TYPOGRAPHY_ATTRIBUTE_VALUE
} from "../../components/general/typography/TypographyStyles";
import {
    getCreateWorkTypePricingCallback,
    getDissociateWorkTypeCallback,
    latestWorkTypeByGroupIdAtomFamily,
    workTypePricingByGroupIdAtomFamily
} from "../../lib/worktype/state/WorkTypeState";
import {
    useMemo,
    useState
} from "react";

import ConfirmationPrompt from "../../components/general/ConfirmationPrompt";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DimensionType } from "../../models";
import EditIcon from '@mui/icons-material/Edit';
import NumberStringConverter from "../../lib/util/NumberStringConverter";
import { PriceNumberMask } from "../../components/design/document/element/wss/PriceNumberMask";
import SaveIcon from "@mui/icons-material/Save";
import WorkTypeDTO from "../../lib/worktype/DTO/WorkTypeDTO";
import WorkTypePricingDTO from "../../lib/design/worktype/pricing/DTO/WorkTypePricingDTO";
import { actingAsEntityIdAtom } from "../../lib/userSession/state/UserSessionState";
import { getUpdateWorkTypePricingCallback } from "../../lib/worktype/state/WorkTypeState";
import globalStyle from "../../assets/style/globalStyle";
import theme from "../../assets/style/theme";
import { useRecoilValue } from "recoil";

interface WorkTypeManagementListItemProps {
    readonly workTypeGroupId: string;
}

export const WorkTypeManagementListItem = (props: WorkTypeManagementListItemProps) => {
    const { workTypeGroupId } = props;
    /* Internal page states */
    const isScreenSizeSm = useMediaQuery(theme.breakpoints.down("sm"));
    const [isEditModeActive, setIsEditModeActive] = useState<boolean>(false);
    const actingAsEntityId = useRecoilValue<string>(actingAsEntityIdAtom);
    const workType = useRecoilValue<WorkTypeDTO | null>(latestWorkTypeByGroupIdAtomFamily(workTypeGroupId));
    const workTypePricing = useRecoilValue<WorkTypePricingDTO | null>(workTypePricingByGroupIdAtomFamily(workTypeGroupId));
    const [isRemoveWorkTypePromptOpen, setIsRemoveWorkTypePromptOpen] = useState<boolean>(false);

    const priceText = useMemo(() => {
        if (workTypePricing?.unitPrice == null) {
            return "";
        }
        return NumberStringConverter.numberToString(workTypePricing.unitPrice, 2, false);
    }, [workTypePricing?.unitPrice]);
    const costText = useMemo(() => {
        if (workTypePricing?.unitCost == null) {
            return "";
        }
        return NumberStringConverter.numberToString(workTypePricing.unitCost, 2, false);
    }, [workTypePricing?.unitCost]);
    const dimensionTypeText: string = useMemo(() => {
        return workType?.dimensionType === DimensionType.LINEAR ? "/ft" : "/sqft";
    }, [workType]);

    /* Dependencies */
    const globalClasses = globalStyle();
    const updateWorkTypePricing = getUpdateWorkTypePricingCallback();
    const createWorkTypePricing = getCreateWorkTypePricingCallback();
    const dissociateWorkType = getDissociateWorkTypeCallback();

    // Fix so we can do unit cost as well
    const onChange = async (key: keyof WorkTypePricingDTO, value: string, save: boolean) => {
        const valueWithoutFormatting: string = value.replace("$", "").replaceAll(",", "");
        if (save) {
            const valueAsNumber: number = parseFloat(valueWithoutFormatting);
            if (isNaN(valueAsNumber)) {
                if (workTypePricing) {
                    const updated: WorkTypePricingDTO = {
                        ...workTypePricing,
                        [key]: undefined
                    };
                    await updateWorkTypePricing(updated);
                    return;
                }
            }
            if (!workTypePricing) {
                const workTypePricingToCreate: WorkTypePricingDTO = {
                    workTypeGroupId: workTypeGroupId,
                    entityId: actingAsEntityId,
                    [key]: valueAsNumber
                };
                await createWorkTypePricing(workTypePricingToCreate);
                return;
            }
            const updated = {
                ...workTypePricing,
                [key]: valueAsNumber
            };
            await updateWorkTypePricing(updated);
        }
    };

    const onDissociateWorkType = async () => {
        await dissociateWorkType(actingAsEntityId, workTypeGroupId);
    };

    return (
        <>
            <ListItem disablePadding sx={{ minHeight: "5em" }}>
                <Box pl={3} pr={3} width="100%">
                    <Grid
                        container
                        wrap="nowrap"
                        alignItems={isScreenSizeSm ? "stretch" : "center"}
                        direction={isScreenSizeSm ? "column" : "row"}
                        justifyContent="space-between"
                    >
                        <Grid item alignItems="center">
                            <ListItemText
                                primary={
                                    <Typography variant="body1">
                                        {workType?.name || "N/A"}
                                    </Typography>
                                }
                            />
                        </Grid>
                        <Grid item alignItems="center" >
                            <Stack
                                minWidth={"100%"}
                                spacing={1}
                                direction="row"
                                alignItems="center"
                                justifyContent={isScreenSizeSm ? "space-between" : "flex-end"}
                            >
                                <Stack direction={isScreenSizeSm ? "column" : "row"}>
                                    <Box pr={3} pb={1}>
                                        {
                                            isEditModeActive ?
                                                <TextField
                                                    variant="outlined"
                                                    label="Unit Price"
                                                    value={priceText}
                                                    onBlur={(event) => onChange("unitPrice", event.target.value, true)}
                                                    onChange={(event) => onChange("unitPrice", event.target.value, false)}
                                                    inputProps={{ inputMode: "decimal" }}
                                                    InputProps={{
                                                        inputComponent: PriceNumberMask,
                                                        endAdornment:
                                                            <InputAdornment position="end">
                                                                {dimensionTypeText}
                                                            </InputAdornment>
                                                    }}
                                                    size="small"
                                                /> :
                                                <ListItemText
                                                    primary={
                                                        <>
                                                            <Typography sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                                                                Unit Price
                                                            </Typography>
                                                            <Typography sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>
                                                                {
                                                                    workTypePricing?.unitPrice !== undefined ?
                                                                        `$ ${NumberStringConverter.numberToString(
                                                                            workTypePricing?.unitPrice,
                                                                            2,
                                                                            true
                                                                        )} ${dimensionTypeText}`
                                                                        : "N/A"}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                        }
                                    </Box>
                                    <Box pr={3}>
                                        {
                                            isEditModeActive ?
                                                <TextField
                                                    variant="outlined"
                                                    label="Unit Cost"
                                                    value={costText}
                                                    onBlur={(event) => onChange("unitCost", event.target.value, true)}
                                                    onChange={(event) => onChange("unitCost", event.target.value, false)}
                                                    inputProps={{ inputMode: "decimal" }}
                                                    InputProps={{
                                                        inputComponent: PriceNumberMask,
                                                        endAdornment:
                                                            <InputAdornment position="end">
                                                                {dimensionTypeText}
                                                            </InputAdornment>
                                                    }}
                                                    size="small"
                                                /> :
                                                <ListItemText
                                                    primary={
                                                        <>
                                                            <Typography sx={TYPOGRAPHY_ATTRIBUTE_LABEL}>
                                                                Unit Cost
                                                            </Typography>
                                                            <Typography sx={TYPOGRAPHY_ATTRIBUTE_VALUE}>
                                                                {
                                                                    workTypePricing?.unitCost !== undefined ?
                                                                        `$ ${NumberStringConverter.numberToString(
                                                                            workTypePricing?.unitCost,
                                                                            2,
                                                                            true
                                                                        )} ${dimensionTypeText}`
                                                                        : "N/A"}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                        }
                                    </Box>
                                </Stack>
                                <Stack
                                    spacing={1}
                                    direction="row"
                                    alignItems={isScreenSizeSm ? "space-between" : "center"}
                                >
                                    <IconButton
                                        className={globalClasses.squareIconButton}
                                        onClick={() => setIsEditModeActive(!isEditModeActive)}
                                        size="small"
                                    >
                                        {isEditModeActive ? <SaveIcon /> : <EditIcon />}
                                    </IconButton>
                                    <IconButton
                                        className={globalClasses.squareIconButton}
                                        size="small"
                                        onClick={() => setIsRemoveWorkTypePromptOpen(true)}
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </ListItem>
            <ConfirmationPrompt
                isVisible={isRemoveWorkTypePromptOpen}
                promptTitle="Remove Work Type from your organization"
                promptMessage="You can only add it again if it is set publicly discoverable by Tenera admins. Are you sure you want to remove it from your organization now?"
                onCancelButtonClicked={() => setIsRemoveWorkTypePromptOpen(false)}
                onConfirmButtonClicked={onDissociateWorkType}
            />
        </>
    );
};
