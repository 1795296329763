import {
    Box,
    Button,
    Typography
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

type EmptyImagePlaceholderProps = {
    allowDropImages?: boolean;
    error?: boolean;
    openDropzoneInput?: () => void; 
};

export const EmptyImagePlaceholder = (props: EmptyImagePlaceholderProps) => {
    const { allowDropImages, error, openDropzoneInput } = props;
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
            border="solid 1px lightgray"
            borderRadius={0}
            px={1}
        >
            {
                !error && allowDropImages ? (
                    <Box display="flex" flexDirection="column" gap={1} alignItems="center">
                        <Typography variant="body2">
                            Drag your images here to start uploading
                        </Typography>
                        <Typography>
                            <Typography component="span" color="primary">
                                &#11835;&nbsp;
                            </Typography>
                            <Typography component="span" color="text.primary">
                                or
                            </Typography>
                            <Typography component="span" color="primary">
                                &nbsp;&#11835;
                            </Typography>
                        </Typography>
                        <Button variant="contained" startIcon={<AddIcon />} onClick={openDropzoneInput}>
                            <Typography>
                                Browse Files
                            </Typography>
                        </Button>
                    </Box>
                ) : (
                    <Typography variant="body1" align="justify">
                        {
                            error ? "Error loading images" : "There are no images"
                        }
                    </Typography>
                )
            }
        </Box>
    );
};