import { AuthIDTokenSupplierFactory } from "../../../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../../../logging/ClientLoggerFactory";
import { GraphQLTenderMinorVersionDAO } from "./GraphQLTenderMinorVersionDAO";
import { TenderMinorVersionDAO } from "../TenderMinorVersionDAO";

export class GraphQLTenderMinorVersionDAOFactory {
    private static INSTANCE: TenderMinorVersionDAO = new GraphQLTenderMinorVersionDAO(
        ClientLoggerFactory.getClientLogger("GraphQLTenderMinorVersionDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}