import {
    DefaultWorkTypePricing,
    WorkTypeGroup
} from "../../../models";
import {
    RecoilState,
    Snapshot,
    atom,
    atomFamily,
    selector,
    useRecoilCallback
} from "recoil";

import { AssociateWorkTypeError } from "./error/AssociateWorkTypeError";
import AssociateWorkTypeWithEntityHandler from "../handler/association/AssociateWorkTypeWithEntityHandler";
import AssociateWorkTypeWithEntityHandlerFactory from "../handler/association/AssociateWorkTypeWithEntityHandlerFactory";
import AssociateWorkTypeWithEntityResponse from "../handler/association/AssociateWorkTypeWithEntityResponse";
import { AutocompleteOption } from "../../util/ui/autocomplete/AutocompleteOption";
import { CreateWorkTypePricingError } from "./error/CreateWorkTypePricingError";
import DataGroup from "../../util/data/group/DataGroup";
import DataGroupStringKeyComparatorAsc from "../../util/data/group/StringKeyDataGroupComparatorAsc";
import DataStoreWorkTypeDAOFactory from "../dataStore/DataStoreWorkTypeDAOFactory";
import DataStoreWorkTypeGroupDAOFactory from "../group/dataStore/DataStoreWorkTypeGroupDAOFactory";
import DataStoreWorkTypePricingDAOFactory from "../../design/worktype/pricing/dataStore/DataStoreWorkTypePricingDAOFactory";
import DissociateWorkTypeWithEntityHandler from "../handler/dissociation/DissociateWorkTypeWithEntityHandler";
import DissociateWorkTypeWithEntityHandlerFactory from "../handler/dissociation/DissociateWorkTypeWithEntityHandlerFactory";
import { FetchDiscoverableWorkTypesError } from './error/FetchDiscoverableWorkTypesError';
import { FetchWorkTypeError } from "./error/FetchWorkTypeError";
import GraphQLWorkTypeGroupDAOFactory from "../group/graphql/GraphQLWorkTypeGroupDAOFactory";
import MergeDataSorterFactory from "../../util/data/sort/MergeDataSorterFactory";
import Predicate from "../../util/predicate/Predicate";
import SearchCriteria from "../../search/SearchCriteria";
import StringKeyDataGrouper from "../../util/data/group/StringKeyDataGrouper";
import { UpdateWorkTypeError } from "./error/UpdateWorkTypeError";
import { UpdateWorkTypeGroupError } from './error/UpdateWorkTypeGroupError';
import UpdateWorkTypeHandler from "../handler/updateWorkType/UpdateWorkTypeHandler";
import UpdateWorkTypeHandlerFactory from "../handler/updateWorkType/UpdateWorkTypeHandlerFactory";
import { UpdateWorkTypePricingError } from "./error/UpdateWorkTypePricingError";
import UpdateWorkTypeResponse from '../handler/updateWorkType/UpdateWorkTypeResponse';
import { WorkTypeAssociationScopeEntity } from './../../../API.d';
import WorkTypeDAO from "../WorkTypeDAO";
import WorkTypeDTO from "../DTO/WorkTypeDTO";
import WorkTypeDTONameAscComparator from "../sort/WorkTypeDTONameAscComparator";
import { WorkTypeDisplayMode } from "./WorkTypeDisplayMode";
import WorkTypeGroupDAO from "../group/WorkTypeGroupDAO";
import WorkTypePricingDAO from "../../design/worktype/pricing/WorkTypePricingDAO";
import WorkTypePricingDTO from "../../design/worktype/pricing/DTO/WorkTypePricingDTO";
import { notAssociatedPredicateSelector } from "./WorkTypeFilterState";
import { searchCriteriaState } from "../../search/SearchCriteriaState";

const workTypeGrouper = new StringKeyDataGrouper<WorkTypeDTO>();
const workTypeSorter = MergeDataSorterFactory.createInstance<WorkTypeDTO>(new WorkTypeDTONameAscComparator());
const workTypeGroupSorter = MergeDataSorterFactory.createInstance<DataGroup<string, WorkTypeDTO>>(new DataGroupStringKeyComparatorAsc<WorkTypeDTO>);
const workTypeDAO: WorkTypeDAO = DataStoreWorkTypeDAOFactory.getInstance();
const workTypeGroupDAO: WorkTypeGroupDAO = DataStoreWorkTypeGroupDAOFactory.getInstance();
const workTypeGroupUpdateOperation: WorkTypeGroupDAO = GraphQLWorkTypeGroupDAOFactory.getInstance();
const workTypePricingDAO: WorkTypePricingDAO = DataStoreWorkTypePricingDAOFactory.getInstance();

const associateWorkTypeHandler: AssociateWorkTypeWithEntityHandler = AssociateWorkTypeWithEntityHandlerFactory.getInstance();
const dissociateWorkTypeHandler: DissociateWorkTypeWithEntityHandler = DissociateWorkTypeWithEntityHandlerFactory.getInstance();
const updateWorkTypeHandler: UpdateWorkTypeHandler = UpdateWorkTypeHandlerFactory.getInstance();

/* WorkType Operations */
export const getFetchAllWorkTypesCallback = () => useRecoilCallback(({ set, snapshot }) => {
    return async () => {
        const releaseSnapshot = snapshot.retain();
        try {
            return await fetchAllWorkTypes(snapshot, set);
        } catch (error) {
            throw new FetchWorkTypeError(
                "Error occurred while trying to fetch all workTypes",
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

const fetchAllWorkTypes = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void
): Promise<void> => {
    const allWorkTypeGroup: Array<WorkTypeGroup> = await workTypeGroupDAO.listAll();
    const workTypeGroupIds: Array<string> = Array.from(allWorkTypeGroup.map(wtg => wtg.id));
    set(allWorkTypeGroupIdsAtom, workTypeGroupIds);
    for (const workTypeGroup of allWorkTypeGroup) {
        try {
            set(workTypeGroupByIdAtomFamily(workTypeGroup.id), workTypeGroup);
            const workType: WorkTypeDTO = await workTypeDAO.getByGroupIdAndVersion(workTypeGroup.id, workTypeGroup.latestWorkTypeVersion);
            set(latestWorkTypeByGroupIdAtomFamily(workTypeGroup.id), workType);
        } catch (error) {
            continue;
        }
    }
};

export const getFetchDiscoverableWorkTypesCallback = () => useRecoilCallback(({ set, snapshot }) => {
    return async () => {
        const releaseSnapshot = snapshot.retain();
        try {
            return await fetchDiscoverableWorkTypes(snapshot, set);
        } catch (error) {
            throw new FetchDiscoverableWorkTypesError(
                "Error occurred while trying to fetch discoverable workTypes",
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

const fetchDiscoverableWorkTypes = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void
): Promise<void> => {
    try {
        const discoverableWorkTypeGroups: Array<WorkTypeGroup> = await workTypeGroupDAO.listDiscoverable();
        const discoverableWorkTypeGroupIds = Array.from(discoverableWorkTypeGroups.map(wtg => wtg.id));
        set(discoverableWorkTypeGroupIdsAtom, discoverableWorkTypeGroupIds);
        for (const workTypeGroup of discoverableWorkTypeGroups) {
            try {
                set(workTypeGroupByIdAtomFamily(workTypeGroup.id), workTypeGroup);
                const workType: WorkTypeDTO = await workTypeDAO.getByGroupIdAndVersion(workTypeGroup.id, workTypeGroup.latestWorkTypeVersion);
                set(latestWorkTypeByGroupIdAtomFamily(workTypeGroup.id), workType);
            } catch (error) {
                continue;
            }
        }
    } catch (error) {
        throw new FetchDiscoverableWorkTypesError(
            "Error occurred while trying to fetch discoverable workTypes",
            { cause: error as Error }
        );
    }
};

export const getUpdateWorkTypeCallback = () => useRecoilCallback(({ set, snapshot }) => {
    return async (workType: WorkTypeDTO) => {
        const releaseSnapshot = snapshot.retain();
        try {
            return await updateWorkType(snapshot, set, workType);
        } catch (error) {
            throw new UpdateWorkTypeError(
                `Error occurred while trying to update workType with groupId: ${workType.groupId}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

const updateWorkType = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    workType: WorkTypeDTO
): Promise<void> => {
    const updatedWorkTypeResponse: UpdateWorkTypeResponse = await updateWorkTypeHandler.handle(workType);
    const workTypeUpdated: WorkTypeDTO = updatedWorkTypeResponse.workType;
    const workTypeGroupUpdated: WorkTypeGroup = updatedWorkTypeResponse.workTypeGroup;
    set(workTypeGroupByIdAtomFamily(workTypeUpdated.groupId), workTypeGroupUpdated);
    set(latestWorkTypeByGroupIdAtomFamily(workTypeUpdated.groupId), workTypeUpdated);
};

export const getUpdateWorkTypeGroupCallback = () => useRecoilCallback(({ set, snapshot }) => {
    return async (workTypeGroup: WorkTypeGroup) => {
        const releaseSnapshot = snapshot.retain();
        try {
            return await updateWorkTypeGroup(snapshot, set, workTypeGroup);
        } catch (error) {
            throw new UpdateWorkTypeGroupError(
                `Error occurred while trying to update workTypeGroup: ${workTypeGroup.id}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

const updateWorkTypeGroup = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    workTypeGroup: WorkTypeGroup
): Promise<void> => {
    const updatedWorkTypeGroup: WorkTypeGroup = await workTypeGroupUpdateOperation.update(workTypeGroup.id, workTypeGroup);
    set(workTypeGroupByIdAtomFamily(workTypeGroup.id), updatedWorkTypeGroup);
};

export const getAssociateWorkTypeCallback = () => useRecoilCallback(({ set, snapshot }) => {
    return async (
        entityId: string,
        workTypeGroupId: string,
        inheritDefaultPricing: boolean = true
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            return await associateWorkType(snapshot, set, entityId, workTypeGroupId, inheritDefaultPricing);
        } catch (error) {
            throw new AssociateWorkTypeError(
                `Error occurred while trying to update workType with groupId: ${workTypeGroupId}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

const associateWorkType = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    entityId: string,
    workTypeGroupId: string,
    inheritDefaultPricing: boolean
): Promise<void> => {
    const workType: WorkTypeDTO = snapshot.getLoadable(latestWorkTypeByGroupIdAtomFamily(workTypeGroupId)).contents as WorkTypeDTO;
    const associateWorkTypeWithEntityResponse: AssociateWorkTypeWithEntityResponse = await associateWorkTypeHandler.handle(entityId, WorkTypeAssociationScopeEntity.ORGANIZATION, workType, inheritDefaultPricing);
    set(workTypePricingByGroupIdAtomFamily(workType.groupId), associateWorkTypeWithEntityResponse.workTypePricingDto);

    set(associatedWorkTypeGroupIdsAtom, (original) => {
        const updated = [...original];
        updated.push(workTypeGroupId);
        return updated;
    });
    const workTypeGroup: WorkTypeGroup = await workTypeGroupDAO.getById(workTypeGroupId);
    set(workTypeGroupByIdAtomFamily(workTypeGroupId), workTypeGroup);
};

export const getDissociateWorkTypeCallback = () => useRecoilCallback(({ set, snapshot }) => {
    return async (entityId: string, workTypeGroupId: string) => {
        const releaseSnapshot = snapshot.retain();
        try {
            return await dissociateWorkType(snapshot, set, entityId, workTypeGroupId);
        } catch (error) {
            throw new AssociateWorkTypeError(
                `Error occurred while trying to update workType with groupId: ${workTypeGroupId}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

const dissociateWorkType = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    entityId: string,
    workTypeGroupId: string
): Promise<void> => {
    const workType: WorkTypeDTO = snapshot.getLoadable(latestWorkTypeByGroupIdAtomFamily(workTypeGroupId)).contents as WorkTypeDTO;
    await dissociateWorkTypeHandler.handle(entityId, WorkTypeAssociationScopeEntity.ORGANIZATION, workType);

    const associatedWorkTypeGroupIds: Array<string> = snapshot.getLoadable(associatedWorkTypeGroupIdsAtom).contents as Array<string>;
    const workTypeGroupIndex: number = associatedWorkTypeGroupIds.findIndex((associatedWorkTypeGroupId) => associatedWorkTypeGroupId === workTypeGroupId);
    set(associatedWorkTypeGroupIdsAtom, (original) => {
        const updated = [...original];
        updated.splice(workTypeGroupIndex, 1);
        return updated;
    });
};

/* WorkType State */

export const workTypeDisplayModeAtomFamily = atomFamily<WorkTypeDisplayMode, string>({
    key: "workTypeDisplayModeAtomFamily",
    default: WorkTypeDisplayMode.VIEW
});

const groupAndSortWorkTypes = (workTypes: Array<WorkTypeDTO>): Array<DataGroup<string, WorkTypeDTO>> => {
    const groupedWorkTypes: Array<DataGroup<string, WorkTypeDTO>> = workTypeGrouper.group(workTypes, "categoryName");
    for (const workTypes of groupedWorkTypes) {
        workTypes.dataItems = workTypeSorter.sort(workTypes.dataItems);
    }
    return workTypeGroupSorter.sort(groupedWorkTypes);
};

export const allWorkTypeDataGroupsSelector = selector<Array<DataGroup<string, WorkTypeDTO>>>({
    key: "allWorkTypeDataGroupsSelector",
    get: ({ get }) => {
        const allWorkTypeGroupIds = get(allWorkTypeGroupIdsAtom);
        let workTypes: Array<WorkTypeDTO> = [];
        for (const groupId of allWorkTypeGroupIds) {
            const workType = get(latestWorkTypeByGroupIdAtomFamily(groupId));
            if (workType) {
                workTypes.push(workType);
            }
        }
        const searchCriteria: SearchCriteria = get(searchCriteriaState);
        if (searchCriteria.keywords.length > 0) {
            const searchKeywordRegExp = new RegExp(searchCriteria.keywords, "i");
            workTypes = workTypes.filter((workType: WorkTypeDTO) => {
                return searchKeywordRegExp.test(workType.name) ||
                    searchKeywordRegExp.test(workType.longDescription) ||
                    searchKeywordRegExp.test(workType.shortDescription) ||
                    searchKeywordRegExp.test(workType.categoryName);
            });
        }
        return groupAndSortWorkTypes(workTypes);
    }
});

export const associatedWorkTypeDataGroupsSelector = selector<Array<DataGroup<string, WorkTypeDTO>>>({
    key: "associatedWorkTypeDataGroupsSelector",
    get: ({ get }) => {
        const associatedWorkTypeGroupIds = get(associatedWorkTypeGroupIdsAtom);
        let workTypes: Array<WorkTypeDTO> = [];
        for (const groupId of associatedWorkTypeGroupIds) {
            const workType = get(latestWorkTypeByGroupIdAtomFamily(groupId));
            if (workType) {
                workTypes.push(workType);
            }
        }
        const searchCriteria: SearchCriteria = get(searchCriteriaState);
        if (searchCriteria.keywords.length > 0) {
            const searchKeywordRegExp = new RegExp(searchCriteria.keywords, "i");
            workTypes = workTypes.filter((workType: WorkTypeDTO) => {
                return searchKeywordRegExp.test(workType.name) ||
                    searchKeywordRegExp.test(workType.longDescription) ||
                    searchKeywordRegExp.test(workType.shortDescription) ||
                    searchKeywordRegExp.test(workType.categoryName);
            });
        }
        return groupAndSortWorkTypes(workTypes);
    }
});

export const associatedWorkTypeOptionsSelector = selector<Array<AutocompleteOption>>({
    key: "associatedWorkTypeOptionsSelector",
    get: ({ get }) => {
        const associatedWorkTypeDataGroups: Array<DataGroup<string, WorkTypeDTO>> = get(associatedWorkTypeDataGroupsSelector);
        return associatedWorkTypeDataGroups.reduce((accumulator, group) => {
            const groupItems: Array<AutocompleteOption> = group.dataItems.map(workType => {
                return {
                    label: workType.name,
                    group: group.key,
                    id: workType.id,
                };
            });
            return accumulator.concat(groupItems);
        }, new Array<AutocompleteOption>());
    }
});

export const filteredWorkTypeDataGroupsSelector = selector<Array<DataGroup<string, WorkTypeDTO>>>({
    key: "filteredWorkTypeDataGroupsSelector",
    get: ({ get }) => {
        const filteredDiscoverableWorkTypeGroupIds = get(filteredDiscoverableWorkTypeGroupIdsSelector);
        const workTypes: Array<WorkTypeDTO> = [];
        for (const groupId of filteredDiscoverableWorkTypeGroupIds) {
            const workType = get(latestWorkTypeByGroupIdAtomFamily(groupId));
            if (workType) {
                workTypes.push(workType);
            }
        }
        return groupAndSortWorkTypes(workTypes);
    }
});

export const defaultWorkTypePricingByGroupIdAtomFamily = atomFamily<DefaultWorkTypePricing | null, string>({
    key: "defaultWorkTypePricingByGroupIdAtomFamily",
    default: null
});

export const workTypeGroupByIdAtomFamily = atomFamily<WorkTypeGroup | null, string>({
    key: "workTypeGroupByIdAtomFamily",
    default: null
});

export const latestWorkTypeByGroupIdAtomFamily = atomFamily<WorkTypeDTO | null, string>({
    key: "latestWorkTypeByGroupIdAtomFamily",
    default: null
});

export const allWorkTypeGroupIdsAtom = atom<Array<string>>({
    key: "allWorkTypeGroupIdsAtom",
    default: []
});

export const associatedWorkTypeGroupIdsAtom = atom<Array<string>>({
    key: "associatedWorkTypeGroupIdsAtom",
    default: []
});

export const discoverableWorkTypeGroupIdsAtom = atom<Array<string>>({
    key: "discoverableWorkTypeGroupIdsAtom",
    default: []
});

export const filteredDiscoverableWorkTypeGroupIdsSelector = selector<Array<string>>({
    key: "filteredDiscoverableWorkTypeGroupIdsSelector",
    get: ({ get }) => {
        const predicates: Predicate<WorkTypeDTO> = get(notAssociatedPredicateSelector);
        const discoverableWorkTypeGroupIds: Array<string> = get(discoverableWorkTypeGroupIdsAtom);
        const associatedWorkTypeGroupIds: Array<string> = get(associatedWorkTypeGroupIdsAtom);
        const filteredDiscoverableWorkTypeGroupIds: Array<string> = [];
        for (const workTypeGroupId of discoverableWorkTypeGroupIds) {
            if (!associatedWorkTypeGroupIds.includes(workTypeGroupId)) {
                const workType: WorkTypeDTO | null = get(latestWorkTypeByGroupIdAtomFamily(workTypeGroupId));
                if (workType && predicates.apply(workType)) {
                    filteredDiscoverableWorkTypeGroupIds.push(workTypeGroupId);
                }
            }
        }
        return filteredDiscoverableWorkTypeGroupIds;
    }
});

/* WorkType Pricing Operations*/

export const getCreateWorkTypePricingCallback = () => useRecoilCallback(({ set, snapshot }) => {
    return async (
        workTypePricing: WorkTypePricingDTO
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            return await createWorkTypePricing(set, workTypePricing);
        } catch (error) {
            throw new CreateWorkTypePricingError(
                `Error occurred while trying to create WorkTypePricing with id: ${workTypePricing.id}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});

export const getUpdateWorkTypePricingCallback = () => useRecoilCallback(({ set, snapshot }) => {
    return async (
        workTypePricing: WorkTypePricingDTO
    ) => {
        const releaseSnapshot = snapshot.retain();
        try {
            return await updateWorkTypePricing(set, workTypePricing);
        } catch (error) {
            throw new UpdateWorkTypePricingError(
                `Error occurred while trying to update WorkTypePricing with id: ${workTypePricing.id}`,
                { cause: error as Error }
            );
        } finally {
            releaseSnapshot();
        }
    };
});


const createWorkTypePricing = async (
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    workTypePricing: WorkTypePricingDTO,
): Promise<void> => {
    const createdWorkTypePricing = await workTypePricingDAO.create(workTypePricing);
    set(workTypePricingByGroupIdAtomFamily(workTypePricing.workTypeGroupId), createdWorkTypePricing);
};

const updateWorkTypePricing = async (
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    workTypePricing: WorkTypePricingDTO,
): Promise<void> => {
    const updatedWorkTypePricing = await workTypePricingDAO.update(workTypePricing.workTypeGroupId, workTypePricing.entityId, workTypePricing);
    set(workTypePricingByGroupIdAtomFamily(workTypePricing.workTypeGroupId), updatedWorkTypePricing);
};

/* WorkType Pricing State*/

export const workTypePricingByGroupIdAtomFamily = atomFamily<WorkTypePricingDTO | null, string>({
    key: "workTypePricingByGroupIdAtomFamily",
    default: null
});
