import {
    Issue,
    PermissionResourceType
} from '../../models';
import {
    OnCreateIssueSubscription,
    OnDeleteIssueSubscription,
    OnUpdateIssueSubscription
} from '../../API';
import {
    isEditModeByDesignElementIdentifierAtomFamily,
    issueByIssueIdentifierSelectorFamily
} from '../design/document/state/DocumentState';
import {
    onCreateIssue,
    onDeleteIssue,
    onUpdateIssue
} from '../../graphql/subscriptions';

import { API } from 'aws-amplify';
import { AuthIDTokenSupplierFactory } from '../auth/AuthIDTokenSupplierFactory';
import AuthUsernameSupplierFactory from '../auth/AuthUsernameSupplierFactory';
import { ContextAwareIdentifier } from '../design/document/ContextAwareIdentifier';
import { GraphQLSubscription } from '@aws-amplify/api';
import { ModelType } from '../design/document/ModelType';
import { StateContext } from '../design/document/state/StateContext';
import { elementFocusManager } from '../ui/ElementFocusManager';
import { fetchPermissionsForResource } from '../permission/state/ResourcePermissionRecoilState';
import { useRecoilCallback } from 'recoil';

let createSub: ZenObservable.Subscription | undefined;
let updateSub: ZenObservable.Subscription | undefined;
let deleteSub: ZenObservable.Subscription | undefined;
const tokenSupplier = AuthIDTokenSupplierFactory.getInstance()
const usernameSupplier = AuthUsernameSupplierFactory.getInstance()

export const useIssueSubscription = () => {
    const subscribe = async (propertyId: string) => {
        const username = await usernameSupplier.get();
        const authCode = await tokenSupplier.get();

        const filterVariables = {
            canView: {
                contains: username
            },
            propertyId: {
                eq: propertyId
            }
        }
        createSub = API.graphql<GraphQLSubscription<OnCreateIssueSubscription>>({
            query: onCreateIssue,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handleIssueUpdate(value.data?.onCreateIssue as Issue, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
        updateSub = API.graphql<GraphQLSubscription<OnUpdateIssueSubscription>>({
            query: onUpdateIssue,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handleIssueUpdate(value.data?.onUpdateIssue as Issue, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
        deleteSub = API.graphql<GraphQLSubscription<OnDeleteIssueSubscription>>({
            query: onDeleteIssue,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handleIssueDelete(value.data?.onDeleteIssue as Issue, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
    }

    const handleIssueUpdate = useRecoilCallback(({ set, snapshot }) => async (issue: Issue, context: StateContext) => {
        const issueIdentifier = new ContextAwareIdentifier(issue.id, context, ModelType.ISSUE);
        const existingIssue = await snapshot.getPromise(issueByIssueIdentifierSelectorFamily(issueIdentifier));
        if (existingIssue == null) {
            await fetchPermissionsForResource(issue.id, PermissionResourceType.ISSUE, set);
            set(issueByIssueIdentifierSelectorFamily(issueIdentifier), issue);
            set(isEditModeByDesignElementIdentifierAtomFamily(issueIdentifier), false);
            elementFocusManager.scrollToElement();
            return issue;
        } else {
            if (existingIssue?.localLastUpdatedTime! >= issue.localLastUpdatedTime!) {
                // Ignore updates that are older than the current state
                return;
            }
            set(issueByIssueIdentifierSelectorFamily(issueIdentifier), issue);
            return issue;
        }
    });

    const handleIssueDelete = useRecoilCallback(({ set, reset, snapshot }) => async (issue: Issue, context: StateContext) => {
        const issueIdentifier = new ContextAwareIdentifier(issue.id, context, ModelType.ISSUE);
        reset(issueByIssueIdentifierSelectorFamily(issueIdentifier));
        return issue;
    });

    const unsubscribe = () => {
        createSub?.unsubscribe();
        updateSub?.unsubscribe();
        deleteSub?.unsubscribe();
    }

    return { subscribe, unsubscribe }
}