import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const AddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 1C5.924 1 1 5.925 1 12s4.924 11 11 11 11-4.925 11-11S18.075 1 12 1zm4.889 12.223h-3.667v3.667a1.223 1.223 0 0 1-2.445 0v-3.667H7.11a1.223 1.223 0 0 1 0-2.445h3.667V7.112a1.223 1.223 0 0 1 2.445 0v3.667h3.667a1.221 1.221 0 1 1 0 2.444z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(AddIcon);
