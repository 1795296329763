import FixedDelayTokenRefillStrategyFactory from "../../util/ratelimit/FixedDelayTokenRefillStrategyFactory";
import RateLimitedTaskDecorator from "../../util/concurrency/RateLimitedTaskDecorator";
import RateLimiter from "../../util/ratelimit/RateLimiter";
import { SyncReconciliationWorkerFactory } from "./SyncReconciliationWorkerFactory";
import TokenBucketBasedRateLimiterFactory from "../../util/ratelimit/TokenBucketBasedRateLimiterFactory";

export class RateLimitedSyncReconciliationWorkerDecoratorFactory {
    private static readonly TOKEN_BUCKET_CAPACITY = 1;
    private static readonly MIN_DELAY_IN_MILLISECOND = 1000;

    private static readonly RATE_LIMITER: RateLimiter = TokenBucketBasedRateLimiterFactory.create(
        this.TOKEN_BUCKET_CAPACITY,
        FixedDelayTokenRefillStrategyFactory.create(this.MIN_DELAY_IN_MILLISECOND)
    );

    private static readonly INSTANCE = new RateLimitedTaskDecorator<void>(
        this.RATE_LIMITER,
        SyncReconciliationWorkerFactory.getInstance()
    );

    public static getInstance(): RateLimitedTaskDecorator<void> {
        return this.INSTANCE;
    }
}