import { AuthIDTokenSupplierFactory } from "../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { GraphQLLinkRegistryDAO } from "./GraphQLLinkRegistryDAO";
import { LinkRegistryDAO } from "./LinkRegistryDAO";

export class GraphQLLinkRegistryDAOFactory {
    private static instance: LinkRegistryDAO = new GraphQLLinkRegistryDAO(
        ClientLoggerFactory.getClientLogger("GraphQLLinkRegistryDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );
    
    public static getInstance(): LinkRegistryDAO {
        return this.instance;
    }
}