import { forwardRef } from "react";
import MaskedInput from "react-text-mask";
import { createPriceNumberMask } from "./createPriceNumberMask";

const numberMask = createPriceNumberMask(false);

export const PriceNumberMask = forwardRef((props: any, ref: any) => {
    const { mask, ...other } = props;
    return (
        <div 
            ref={ref} 
            style={{
                width: "100%"
            }}
        >
            <MaskedInput
                {...other}
                mask={mask ?? numberMask}
            />
        </div>
    );
});
