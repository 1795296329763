import AuthUsernameSupplierFactory from "../auth/AuthUsernameSupplierFactory";
import DataStoreUserOnboardingProgressDAOFactory from "./DataStoreUserOnboardingProgressDAOFactory";
import GraphQLUserOnboardingProgressDAOFactory from "./GraphQLUserOnboardingProgressDAOFactory";
import UserOnboardingEligibilityChecker from "./UserOnboardingEligibilityChecker";

export default class UserOnboardingEligibilityCheckerFactory {

    private static readonly INSTANCE = new UserOnboardingEligibilityChecker(
        AuthUsernameSupplierFactory.getInstance(),
        GraphQLUserOnboardingProgressDAOFactory.getInstance(),
        DataStoreUserOnboardingProgressDAOFactory.getInstance()
    );

    public static getInstance() {
        return this.INSTANCE;
    }
}