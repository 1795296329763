import {
    DefaultValue,
    RecoilState,
    Snapshot,
    atomFamily,
    selectorFamily
} from "recoil";
import {
    DimensionType,
    Measurement,
    MeasurementUnit,
    WorkSpecification
} from "../../../../../models";
import {
    dimensionIdToWorkSpecificationIdsMapByContextSelectorFamily,
    dimensionIdsByIssueIdentifierAtomFamily,
    filteredIssueIdsByLocationIdentifierSelectorFamily,
    filteredLocationIdsByContextSelectorFamily,
    workSpecificationByWorkSpecificationIdentifierSelectorFamily,
    workSpecificationIdsByDimensionIdentifierAtomFamily
} from "../../../document/state/DocumentState";
import {
    proposalAdjustmentAmountByContextSelectorFamily,
    proposalPriceBeforeAdjustmentByContextSelectorFamily,
    wssIdToProposalItemMapByContextSelectorFamily
} from "./ProposalStates";

import { ComponentModificationMode } from "../../../document/state/ComponentModificationMode";
import { ContextAwareIdentifier } from "../../../document/ContextAwareIdentifier";
import DataStoreWorkTypeDAOFactory from "../../../../worktype/dataStore/DataStoreWorkTypeDAOFactory";
import DataStoreWorkTypePricingDAOFactory from "../../../worktype/pricing/dataStore/DataStoreWorkTypePricingDAOFactory";
import DefaultUnitCountCalculatorFactory from "../../../../dimension/v2/DefaultUnitCountCalculatorFactory";
import DefaultUnitPriceCalculatorFactory from "../../DefaultUnitPriceCalculatorFactory";
import { ModelType } from "../../../document/ModelType";
import PercentageProposalItemChildrenPriceAdjustmentHandlerFactory from "../../PercentageProposalItemChildrenPriceAdjustmentHandlerFactory";
import { PriceCalculationType } from "../../PriceCalculationType";
import ProposalItem from "../../ProposalItem";
import ProposalItemBuilder from "../../ProposalItemBuilder";
import ProposalItemChildrenPriceAdjustmentHandler from "../../ProposalItemChildrenPriceAdjustmentHandler";
import { ProposalItemFeatureConfiguration } from "../../../document/state/ProposalItemFeatureConfiguration";
import { StateContext } from "../../../document/state/StateContext";
import UnitCountCalculator from "../../../../dimension/v2/UnitCountCalculator";
import UnitPriceCalculator from "../../UnitPriceCalculator";
import UnitPriceConverter from "../../../../util/dimension/UnitPriceConverter";
import WorkTypeDAO from "../../../../worktype/WorkTypeDAO";
import WorkTypeDTO from "../../../../worktype/DTO/WorkTypeDTO";
import WorkTypePricingDTO from "../../../worktype/pricing/DTO/WorkTypePricingDTO";
import { actingAsEntityIdAtom } from "../../../../userSession/state/UserSessionState";
import uuidv4 from "../../../../util/UuidGenerator";
import { workTypeByWorkTypeIdAtomFamily } from "../../../../worktype/state/WorkTypeRecoilState";

const unitPriceCalculator: UnitPriceCalculator = DefaultUnitPriceCalculatorFactory.getInstance();
const workTypeDAO: WorkTypeDAO = DataStoreWorkTypeDAOFactory.getInstance();
const workTypePricingDAO = DataStoreWorkTypePricingDAOFactory.getInstance();

export const unitCountByProposalItemIdentifierSelectorFamily = selectorFamily<number | undefined, ContextAwareIdentifier>({
    key: "unitCountByProposalItemIdentifierSelectorFamily",
    get: (proposalItemIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const proposalItemIdToWorkSpecificationIdMap: Map<string, string> =
            get(proposalItemIdToWorkSpecificationIdMapByContextSelectorFamily(proposalItemIdentifier.context));
        const workSpecificationId: string | undefined = proposalItemIdToWorkSpecificationIdMap.get(proposalItemIdentifier.id);
        if (!workSpecificationId) {
            return undefined;
        }
        const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, proposalItemIdentifier.context, ModelType.WORK_SPECIFICATION);
        const workSpecification: WorkSpecification | null = get(workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier));
        if (!workSpecification || !workSpecification.measurement) {
            return undefined;
        }
        const unitCountCalculator: UnitCountCalculator = DefaultUnitCountCalculatorFactory.getInstance();
        try {
            return unitCountCalculator.calculate(workSpecification.measurement);
        } catch (error) {
            return undefined;
        }
    }
});

export const priceCalculationTypeByProposalItemIdentifierAtomFamily = atomFamily<PriceCalculationType | undefined, ContextAwareIdentifier>({
    key: "priceCalculationTypeByProposalItemIdentifierAtomFamily",
    default: undefined
});

const autoCalculatedPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily = selectorFamily<number | undefined, ContextAwareIdentifier>({
    key: "autoCalculatedPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily",
    get: (proposalItemIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const configuredUnitPriceBeforeAdjustment: number | undefined = get(configuredUnitPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
        const unitCount: number | undefined = get(unitCountByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
        if (configuredUnitPriceBeforeAdjustment && unitCount) {
            return configuredUnitPriceBeforeAdjustment * unitCount;
        }
        return undefined;
    }
});

const manualPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily = atomFamily<number | undefined, ContextAwareIdentifier>({
    key: "manualPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily",
    default: undefined
});

export const priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily = selectorFamily<number | undefined, ContextAwareIdentifier>({
    key: "priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily",
    get: (proposalItemIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const priceCalculationType: PriceCalculationType | undefined = get(priceCalculationTypeByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
        if (!priceCalculationType) {
            return undefined;
        }
        if (priceCalculationType === PriceCalculationType.MANUAL) {
            return get(manualPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
        }
        if (priceCalculationType === PriceCalculationType.WORK_TYPE_UNIT_PRICING) {
            return get(autoCalculatedPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
        }
        return undefined;
    },
    set: (proposalItemIdentifier: ContextAwareIdentifier) => ({ set, get, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            reset(manualPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
            return;
        }
        const priceCalculationType: PriceCalculationType | undefined = get(priceCalculationTypeByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
        if (!priceCalculationType || priceCalculationType === PriceCalculationType.WORK_TYPE_UNIT_PRICING) {
            return;
        }
        if (priceCalculationType === PriceCalculationType.MANUAL) {
            set(manualPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily(proposalItemIdentifier), newValue);
        }
    }
});

export const configuredUnitPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily = atomFamily<number | undefined, ContextAwareIdentifier>({
    key: "configuredUnitPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily",
    default: undefined
});

export const manualUnitPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily = selectorFamily<number | undefined, ContextAwareIdentifier>({
    key: "manualUnitPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily",
    get: (proposalItemIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const manualPriceBeforeAdjustment: number | undefined = get(manualPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
        const unitCount: number | undefined = get(unitCountByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
        if (manualPriceBeforeAdjustment && unitCount) {
            return unitPriceCalculator.calculate(manualPriceBeforeAdjustment, unitCount);
        }
        return undefined;
    }
});

export const unitPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily = selectorFamily<number | undefined, ContextAwareIdentifier>({
    key: "unitPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily",
    get: (proposalItemIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const priceCalculationType: PriceCalculationType | undefined = get(priceCalculationTypeByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
        if (!priceCalculationType) {
            return undefined;
        }
        if (priceCalculationType === PriceCalculationType.MANUAL) {
            return get(manualUnitPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
        }
        if (priceCalculationType === PriceCalculationType.WORK_TYPE_UNIT_PRICING) {
            return get(configuredUnitPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
        }
        return undefined;
    },
    set: (proposalItemIdentifier: ContextAwareIdentifier) => ({ set, get, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            reset(configuredUnitPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
            return;
        }
        const priceCalculationType: PriceCalculationType | undefined = get(priceCalculationTypeByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
        if (!priceCalculationType || priceCalculationType === PriceCalculationType.MANUAL) {
            return;
        }
        if (priceCalculationType === PriceCalculationType.WORK_TYPE_UNIT_PRICING) {
            set(configuredUnitPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily(proposalItemIdentifier), newValue);
        }
    }
});

/**
 * This state is used for two things: 
 * 1. As input for calculating price after adjustment for each proposal item
 * 2. For importing proposal items
 */
export const proposalItemBeforeAdjustmentByProposalItemIdentifierSelectorFamily = selectorFamily<ProposalItem, ContextAwareIdentifier>({
    key: "proposalItemBeforeAdjustmentByProposalItemIdentifierSelectorFamily",
    get: (proposalItemIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const unitCount: number | undefined = get(unitCountByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
        const priceCalculationType: PriceCalculationType | undefined = get(priceCalculationTypeByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
        const priceBeforeAdjustment: number | undefined = get(priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
        const unitPriceBeforeAdjustment: number | undefined = get(unitPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
        return new ProposalItemBuilder()
            .id(proposalItemIdentifier.id)
            .unitCount(unitCount)
            .priceCalculationType(priceCalculationType)
            .priceBeforeAdjustment(priceBeforeAdjustment)
            .unitPriceBeforeAdjustment(unitPriceBeforeAdjustment)
            .build();
    },
    set: (proposalItemIdentifier: ContextAwareIdentifier) => ({ set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            reset(priceCalculationTypeByProposalItemIdentifierAtomFamily(proposalItemIdentifier));
            reset(priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
            reset(unitPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
            return;
        }
        // Set Case
        set(priceCalculationTypeByProposalItemIdentifierAtomFamily(proposalItemIdentifier), newValue.priceCalculationType);
        if (newValue.priceCalculationType === PriceCalculationType.MANUAL) {
            set(manualPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily(proposalItemIdentifier), newValue.priceBeforeAdjustment);
        }
        if (newValue.priceCalculationType === PriceCalculationType.WORK_TYPE_UNIT_PRICING) {
            set(configuredUnitPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily(proposalItemIdentifier), newValue.unitPriceBeforeAdjustment);
        }
    }
});

export const proposalItemIdByWorkSpecificationIdentifierAtomFamily = atomFamily<string | null, ContextAwareIdentifier>({
    key: "proposalItemIdByWorkSpecificationIdentifierAtomFamily",
    default: null
});

export const workSpecificationIdToProposalItemIdMapByContextSelectorFamily = selectorFamily<Map<string, string>, StateContext>({
    key: "workSpecificationIdToProposalItemIdMapByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const dimensionIdToWorkSpecificationIdsMap: Map<string, Array<string>> = get(dimensionIdToWorkSpecificationIdsMapByContextSelectorFamily(context));
        const workSpecificationIds: Array<string> = Array.from(dimensionIdToWorkSpecificationIdsMap.values()).flat();
        return workSpecificationIds.reduce((map: Map<string, string>, workSpecificationId: string) => {
            const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, context, ModelType.WORK_SPECIFICATION);
            const proposalItemId: string | null = get(proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier));
            if (!proposalItemId) {
                return map;
            }
            return map.set(workSpecificationId, proposalItemId);
        }, new Map<string, string>());
    },
    set: (context: StateContext) => ({ get, set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            return;
        }
        for (const [workSpecificationId, proposalItemId] of newValue.entries()) {
            const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, context, ModelType.WORK_SPECIFICATION);
            set(proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier), proposalItemId);
        }
    }
});

export const proposalItemIdToWorkSpecificationIdMapByContextSelectorFamily = selectorFamily<Map<string, string>, StateContext>({
    key: "proposalItemIdToWorkSpecificationIdMapByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const workSpecificationIdToProposalItemIdMap: Map<string, string> = get(workSpecificationIdToProposalItemIdMapByContextSelectorFamily(context));
        const workSpecificationIds: Array<string> = Array.from(workSpecificationIdToProposalItemIdMap.keys());
        return workSpecificationIds.reduce((map, workSpecificationId) => {
            return map.set(workSpecificationIdToProposalItemIdMap.get(workSpecificationId)!, workSpecificationId);
        }, new Map<string, string>());
    }
});

export const proposalItemIdToProposalItemBeforeAdjustmentMapByContextSelectorFamily = selectorFamily<Map<string, ProposalItem>, StateContext>({
    key: "proposalItemIdToProposalItemBeforeAdjustmentMapByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const workSpecificationIdToProposalItemIdMap: Map<string, string> = get(workSpecificationIdToProposalItemIdMapByContextSelectorFamily(context));
        const proposalItemIds: Array<string> = Array.from(workSpecificationIdToProposalItemIdMap.values());
        return proposalItemIds.reduce((map: Map<string, ProposalItem>, proposalItemId) => {
            const proposalItemIdentifier: ContextAwareIdentifier = new ContextAwareIdentifier(proposalItemId, context, ModelType.PROPOSAL_ITEM);
            const proposalItem: ProposalItem = get(proposalItemBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
            return map.set(proposalItemId, proposalItem);
        }, new Map<string, ProposalItem>());
    },
    set: (context: StateContext) => ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            return;
        }
        for (const [proposalItemId, proposalItem] of newValue.entries()) {
            const proposalItemIdentifier = new ContextAwareIdentifier(proposalItemId, context, ModelType.PROPOSAL_ITEM);
            set(proposalItemBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier), proposalItem);
        }
    }
});

// With price after adjustment calculated
export const proposalItemIdToProposalItemMapByContextSelectorFamily = selectorFamily<Map<string, ProposalItem>, StateContext>({
    key: "proposalItemIdToProposalItemMapByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const proposalItemIdToProposalItemBeforeAdjustmentMap: Map<string, ProposalItem> =
            get(proposalItemIdToProposalItemBeforeAdjustmentMapByContextSelectorFamily(context));
        const proposalPriceBeforeAdjustment: number | undefined = get(proposalPriceBeforeAdjustmentByContextSelectorFamily(context));
        const proposalAdjustmentAmount: number | undefined = get(proposalAdjustmentAmountByContextSelectorFamily(context));
        const proposalItemChildrenPriceAdjustmentHandler: ProposalItemChildrenPriceAdjustmentHandler =
            PercentageProposalItemChildrenPriceAdjustmentHandlerFactory.getInstance();
        try {
            return proposalItemChildrenPriceAdjustmentHandler.handle(
                proposalItemIdToProposalItemBeforeAdjustmentMap,
                proposalPriceBeforeAdjustment,
                proposalAdjustmentAmount
            );
        } catch (error) {
            return proposalItemIdToProposalItemBeforeAdjustmentMap;
        }
    },
    set: (context: StateContext) => ({ set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            reset(proposalItemIdToProposalItemBeforeAdjustmentMapByContextSelectorFamily(context));
            return;
        }
        set(proposalItemIdToProposalItemBeforeAdjustmentMapByContextSelectorFamily(context), newValue);
    }
});

export const proposalItemByProposalItemIdentifierSelectorFamily = selectorFamily<ProposalItem | undefined, ContextAwareIdentifier>({
    key: "proposalItemByProposalItemIdentifierSelectorFamily",
    get: (proposalItemIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const proposalItemIdToProposalItemMap: Map<string, ProposalItem> = get(proposalItemIdToProposalItemMapByContextSelectorFamily(proposalItemIdentifier.context));
        return proposalItemIdToProposalItemMap.get(proposalItemIdentifier.id);
    }
});

export const priceAfterAdjustmentByProposalItemIdentifierSelectorFamily = selectorFamily<number | undefined, ContextAwareIdentifier>({
    key: "priceAfterAdjustmentByProposalItemIdentifierSelectorFamily",
    get: (proposalItemIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const proposalItem = get(proposalItemByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
        return proposalItem?.priceAfterAdjustment;
    }
});

export const unitPriceAfterAdjustmentByProposalItemIdentifierSelectorFamily = selectorFamily<number | undefined, ContextAwareIdentifier>({
    key: "unitPriceAfterAdjustmentByProposalItemIdentifierSelectorFamily",
    get: (proposalItemIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const proposalItem = get(proposalItemByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
        return proposalItem?.unitPriceAfterAdjustment;
    }
});

export const createProposalItem = async (
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    context: StateContext,
    workSpecification: WorkSpecification,
    snapshot: Snapshot
) => {
    if (!workSpecification.workTypeId) {
        createDefaultProposalItem(set, context, workSpecification.id);
        return;
    }
    const actingAsEntityId = snapshot.getLoadable(actingAsEntityIdAtom).getValue();
    const workType = await workTypeDAO.getById(workSpecification.workTypeId!);
    const optionalWorkTypePricingDto: WorkTypePricingDTO | undefined = await workTypePricingDAO.getByWorkTypeGroupIdAndEntityId(
        workType?.groupId!,
        actingAsEntityId
    );
    const proposalItemId = uuidv4();
    const defaultProposalItem = new ProposalItemBuilder()
        .id(proposalItemId)
        .priceCalculationType(PriceCalculationType.WORK_TYPE_UNIT_PRICING)
        .unitPriceBeforeAdjustment(optionalWorkTypePricingDto?.unitPrice)
        .build();
    const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecification.id, context, ModelType.WORK_SPECIFICATION);
    set(proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier), proposalItemId);
    const proposalItemIdentifier = new ContextAwareIdentifier(proposalItemId, context, ModelType.PROPOSAL_ITEM);
    set(proposalItemBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier), defaultProposalItem);
    return defaultProposalItem;
};

export const createDefaultProposalItem = (
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    context: StateContext,
    workSpecificationId: string
) => {
    const proposalItemId = uuidv4();
    const defaultProposalItem = new ProposalItemBuilder()
        .id(proposalItemId)
        .priceCalculationType(PriceCalculationType.WORK_TYPE_UNIT_PRICING)
        .build();
    const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, context, ModelType.WORK_SPECIFICATION);
    set(proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier), proposalItemId);
    const proposalItemIdentifier = new ContextAwareIdentifier(proposalItemId, context, ModelType.PROPOSAL_ITEM);
    set(proposalItemBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier), defaultProposalItem);
    return defaultProposalItem;
};

export const removeProposalItemByWorkSpecificationId = async (
    snapshot: Snapshot,
    reset: (recoilVal: RecoilState<any>) => void,
    context: StateContext,
    workSpecificationId: string
) => {
    const workSpecificationIdToProposalItemIdMap: Map<string, string> = await snapshot.getPromise(
        workSpecificationIdToProposalItemIdMapByContextSelectorFamily(context)
    );
    const proposalItemId: string | undefined = workSpecificationIdToProposalItemIdMap.get(workSpecificationId);
    if (!proposalItemId) {
        return;
    }
    const proposalItemIdentifier = new ContextAwareIdentifier(proposalItemId, context, ModelType.PROPOSAL_ITEM);
    reset(proposalItemBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, context, ModelType.WORK_SPECIFICATION);
    reset(proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier));
};

export const copyProposalItemToContext = (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    proposalItemId: string,
    workSpecificationId: string,
    fromContext: StateContext,
    toContext: StateContext
) => {
    const proposalItemIdAndFromContextIdentifier = new ContextAwareIdentifier(proposalItemId, fromContext, ModelType.PROPOSAL_ITEM);
    const proposalItemIdAndToContextIdentifier = new ContextAwareIdentifier(proposalItemId, toContext, ModelType.PROPOSAL_ITEM);
    const workSpecificationIdAndToContextIdentifier = new ContextAwareIdentifier(workSpecificationId, toContext, ModelType.WORK_SPECIFICATION);
    const proposalItemToCopy: ProposalItem = snapshot.getLoadable(
        proposalItemBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdAndFromContextIdentifier)
    ).contents;
    set(proposalItemBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdAndToContextIdentifier), proposalItemToCopy);
    set(proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdAndToContextIdentifier), proposalItemId);
};

/**
 * Proposal Item Auto Calculation Unit Price is updated when work specification updates.
 */
export const updateProposalItemConfiguredUnitPrice = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    context: StateContext,
    proposalItemId: string,
    workTypeGroupId: string | null | undefined,
    measurement: Measurement,
) => {
    const actingAsEntityId = snapshot.getLoadable(actingAsEntityIdAtom).getValue();
    let workTypeUnitPrice: Number | undefined = undefined;
    if (workTypeGroupId != null) {
        const optionalWorkTypePricingDto: WorkTypePricingDTO | undefined = await workTypePricingDAO.getByWorkTypeGroupIdAndEntityId(
            workTypeGroupId!,
            actingAsEntityId
        );
        workTypeUnitPrice = optionalWorkTypePricingDto?.unitPrice;
    }
    const proposalItemIdentifier = new ContextAwareIdentifier(proposalItemId, context, ModelType.PROPOSAL_ITEM);
    if (workTypeUnitPrice == undefined || !measurement.dimensionType || !measurement.measurementUnit) {
        set(configuredUnitPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily(proposalItemIdentifier), undefined);
        return;
    }
    const unitPrice: number = UnitPriceConverter.convertUnitPrice(
        measurement.dimensionType as DimensionType,
        MeasurementUnit.FOOT,
        measurement.measurementUnit as MeasurementUnit,
        workTypeUnitPrice.valueOf()
    );
    set(configuredUnitPriceBeforeAdjustmentByProposalItemIdentifierAtomFamily(proposalItemIdentifier), unitPrice);
};

export const proposalItemByWorkSpecificationIdentifierSelectorFamily = selectorFamily<ProposalItem | undefined, ContextAwareIdentifier>({
    key: "proposalItemByWorkSpecificationIdSelectorFamily",
    get: (workSpecificationIdentifier: ContextAwareIdentifier) => ({ get }) => {
        return get(wssIdToProposalItemMapByContextSelectorFamily(workSpecificationIdentifier.context)).get(workSpecificationIdentifier.id);
    }
});

export const proposalItemPriceTotalByDimensionIdentifierSelectorFamily = selectorFamily<number, ContextAwareIdentifier>({
    key: "proposalItemSubtotalByDimensionIdSelectorFamily",
    get: (dimensionIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const workSpecificationIds = get(workSpecificationIdsByDimensionIdentifierAtomFamily(dimensionIdentifier));
        let total: number = 0;
        for (const workSpecificationId of workSpecificationIds) {
            const workSpecificationIdentifier = new ContextAwareIdentifier(
                workSpecificationId,
                dimensionIdentifier.context,
                ModelType.WORK_SPECIFICATION
            );
            const subTotal = get(proposalItemByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier))?.priceAfterAdjustment;
            if (subTotal) {
                total += subTotal;
            }
        }
        return total;
    }
});

export const proposalItemPriceTotalByIssueIdentifierSelectorFamily = selectorFamily<number, ContextAwareIdentifier>({
    key: "proposalItemPriceTotalByIssueIdentifierSelectorFamily",
    get: (issueIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const dimensionIds = get(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier));
        let total: number = 0;
        for (const dimensionId of dimensionIds) {
            const dimensionIdentifier = new ContextAwareIdentifier(
                dimensionId,
                issueIdentifier.context,
                ModelType.DIMENSION
            );
            const subTotal = get(proposalItemPriceTotalByDimensionIdentifierSelectorFamily(dimensionIdentifier));
            total += subTotal;
        }
        return total;
    }
});


export const proposalItemPriceTotalByLocationIdentifierSelectorFamily = selectorFamily<number, ContextAwareIdentifier>({
    key: "proposalItemPriceTotalByLocationIdentifierSelectorFamily",
    get: (locationIdentifier: ContextAwareIdentifier) => ({ get }) => {
        const issueIds = get(filteredIssueIdsByLocationIdentifierSelectorFamily(locationIdentifier));
        let total: number = 0;
        for (const issueId of issueIds) {
            const issueIdentifier = new ContextAwareIdentifier(
                issueId,
                locationIdentifier.context,
                ModelType.ISSUE
            );
            const subTotal = get(proposalItemPriceTotalByIssueIdentifierSelectorFamily(issueIdentifier));
            total += subTotal;
        }
        return total;
    }
});

export const proposalItemPriceTotalByContextSelectorFamily = selectorFamily<number, StateContext>({
    key: "proposalItemPriceTotalByContextSelectorFamily",
    get: (context: StateContext) => ({ get }) => {
        const locationIds = get(filteredLocationIdsByContextSelectorFamily(context));
        let total: number = 0;
        for (const locationId of locationIds) {
            const locationIdentifier = new ContextAwareIdentifier(
                locationId,
                context,
                ModelType.LOCATION
            );
            const subTotal = get(proposalItemPriceTotalByLocationIdentifierSelectorFamily(locationIdentifier));
            total += subTotal;
        }
        return total;
    }
});

export const proposalItemFeatureConfigurationByContextAtomFamily = atomFamily<ProposalItemFeatureConfiguration, StateContext>({
    key: "proposalItemFeatureConfigurationByContextAtomFamily",
    default: {
        calculateAdjustedPrice: false,
        componentModificationMode: ComponentModificationMode.ALWAYS_VIEW
    }
});

export const initializeProposalItemByWorkSpecification = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    workSpecification: WorkSpecification,
    context: StateContext
) => {
    const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecification.id, context, ModelType.WORK_SPECIFICATION);
    let proposalItemId: string | null = snapshot.getLoadable(
        proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier)
    ).contents;
    if (!proposalItemId) {
        const proposalItem = createDefaultProposalItem(set, context, workSpecification.id);
        set(proposalItemIdByWorkSpecificationIdentifierAtomFamily(workSpecificationIdentifier), proposalItem.id);
        proposalItemId = proposalItem.id;
    }
    if (workSpecification.workTypeId) {
        let selectedWorkType: WorkTypeDTO | undefined = snapshot.getLoadable(workTypeByWorkTypeIdAtomFamily(workSpecification.workTypeId)).contents;
        if (!selectedWorkType) {
            selectedWorkType = await workTypeDAO.getById(workSpecification.workTypeId);
            if (selectedWorkType) {
                set(workTypeByWorkTypeIdAtomFamily(workSpecification.workTypeId), selectedWorkType);
            }
        }
        if (selectedWorkType && selectedWorkType.groupId) {
            await updateProposalItemConfiguredUnitPrice(snapshot, set, context, proposalItemId, selectedWorkType.groupId, workSpecification.measurement!);
        }
    }
};
