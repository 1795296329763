import {
     API,
     graphqlOperation
} from "aws-amplify";

import { AuthIDTokenSupplierFactory } from "../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../logging/ClientLoggerFactory";
import { GraphQLUserDAO } from "./GraphQLUserDAO";
import { UserDAO } from "./UserDAO";

export class GraphQLUserDAOFactory {
    private static readonly INSTANCE: UserDAO = new GraphQLUserDAO(
        API,
        graphqlOperation,
        ClientLoggerFactory.getClientLogger("GraphQLUserDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );

    public static getInstance(): UserDAO {
        return this.INSTANCE;
    }
}