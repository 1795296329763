import { grey } from "@mui/material/colors";
import theme from "../../../assets/style/theme";

export const TYPOGRAPHY_ATTRIBUTE_LABEL = {
    fontSize: "12px",
    color: grey[500]
};

export const TYPOGRAPHY_ATTRIBUTE_VALUE = {
    fontSize: "14px",
    color: `${theme.palette.text.primary}`
};
