import {
    Box,
    Typography
} from "@mui/material";
import {
    priceAfterAdjustmentByProposalItemIdentifierSelectorFamily,
    priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily,
    unitCountByProposalItemIdentifierSelectorFamily,
    unitPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily
} from "../../lib/design/bidding/state/v2/ProposalItemStates";
import {
    useMemo,
    useRef
} from "react";

import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { Measurement } from "../../models";
import { ModelType } from "../../lib/design/document/ModelType";
import PercentageProposalItemPriceExplanationGeneratorFactory from "../../lib/proposal/explanation/PercentageProposalItemPriceExplanationGeneratorFactory";
import ProposalItem from "../../lib/design/bidding/ProposalItem";
import ProposalItemPriceExplanationGenerator from "../../lib/proposal/explanation/ProposalItemPriceExplanationGenerator";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { useRecoilValue } from "recoil";

type ProposalItemPriceExplanationProps = {
    proposalItemId: string;
    context: StateContext;
    measurement: Measurement;
};

const ProposalItemPriceExplanation = ({ proposalItemId, context, measurement }: ProposalItemPriceExplanationProps) => {
    const proposalItemIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(proposalItemId, context, ModelType.PROPOSAL_ITEM);
    }, [proposalItemId, context]);
    const unitCount = useRecoilValue<number | undefined>(unitCountByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    const priceBeforeAdjustment = useRecoilValue<number | undefined>(priceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    const unitPriceBeforeAdjustment = useRecoilValue<number | undefined>(unitPriceBeforeAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    const priceAfterAdjustment = useRecoilValue<number | undefined>(priceAfterAdjustmentByProposalItemIdentifierSelectorFamily(proposalItemIdentifier));
    const proposalItemPriceExplanationGenerator = useRef<ProposalItemPriceExplanationGenerator>(PercentageProposalItemPriceExplanationGeneratorFactory.getInstance());

    const priceCalculationExplanation = useMemo(() => {
        const proposalItem: Partial<ProposalItem> = {
            unitCount: unitCount,
            priceBeforeAdjustment: priceBeforeAdjustment,
            unitPriceBeforeAdjustment: unitPriceBeforeAdjustment,
            priceAfterAdjustment: priceAfterAdjustment
        };
        return proposalItemPriceExplanationGenerator.current.generate(proposalItem, measurement);
    }, [unitCount, priceBeforeAdjustment, unitPriceBeforeAdjustment, priceAfterAdjustment, measurement]);

    return (
        <Box display="flex" justifyContent="space-between">
            <Typography variant="body2">
                Explanation:&nbsp;
            </Typography>
            <Typography
                variant="body2"
                whiteSpace="pre-line"
                textAlign="end"
                sx={{
                    wordBreak: "break-word"
                }}
            >
                {priceCalculationExplanation.explanation}
            </Typography>
        </Box>
    );
};
export default ProposalItemPriceExplanation;
