import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const AddWorktypeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m9.123 16.993-.26.96A4.004 4.004 0 0 1 5 23c-2.206 0-4-1.794-4-4a4.004 4.004 0 0 1 5.047-3.863l.96-.26 7.87-7.87.26-.96a4.006 4.006 0 0 1 3.217-4.995l-1.241 1.241-.276.892.49 2.61.801.799 2.6.48.889-.276 1.359-1.359a4.006 4.006 0 0 1-5.023 3.424l-.961.26-3.823 3.823a6.987 6.987 0 0 1 3.146-1.732L17.7 9.83A5.002 5.002 0 0 0 24 5c0-.6-.1-1.17-.3-1.7l-2.79 2.79-2.6-.48L17.82 3 20.57.25a5.002 5.002 0 0 0-6.4 6.05L6.3 14.17A5.002 5.002 0 0 0 0 19c0 2.76 2.24 5 5 5a5.002 5.002 0 0 0 4.83-6.3l1.383-1.383a6.99 6.99 0 0 1 1.732-3.147l-3.822 3.823z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M5 16.402a2.597 2.597 0 1 0 0 5.196 2.597 2.597 0 0 0 0-5.196zm1.544 3.012a1.6 1.6 0 0 1-2.674.716 1.6 1.6 0 0 1 2.26-2.26c.402.402.561.994.414 1.544z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="M18 12a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm2.667 6.667h-2v2a.666.666 0 0 1-1.333 0v-2h-2a.667.667 0 0 1 0-1.333h2v-2a.667.667 0 0 1 1.333 0v2h2a.666.666 0 1 1 0 1.333z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(AddWorktypeIcon);
