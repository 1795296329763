import {
    Box,
    Typography,
    useTheme
} from "@mui/material";

import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { CreateIssueButton } from "../../components/issue/CreateIssueButton";
import { IssueSummary } from "./IssueSummary";
import { ModelType } from "../../lib/design/document/ModelType";
import NoItemsPlaceholder from "../../components/NoItemsPlaceholder";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { filteredIssueIdsByLocationIdentifierSelectorFamily } from "../../lib/design/document/state/DocumentState";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";

type IssueSummaryByLocationProps = {
    readonly locationId: string;
    readonly stateContext: StateContext;
};

export const IssueSummaryByLocation = (props: IssueSummaryByLocationProps) => {
    const { locationId, stateContext } = props;
    const locationIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(locationId, stateContext, ModelType.LOCATION);
    }, [locationId, stateContext]);
    const issueIds = useRecoilValue<Array<string>>(filteredIssueIdsByLocationIdentifierSelectorFamily(locationIdentifier));

    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h6">
                    Issues:
                </Typography>
                <CreateIssueButton
                    stateContext={stateContext}
                    locationId={locationId}
                />
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                flex="1 1 auto"
                overflow="auto"
                gap={1}
                mt={1}
            >
                {issueIds.length === 0 && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        p={1}
                        borderBottom={`1px solid ${theme.palette.primary.light}`}
                    >
                        <NoItemsPlaceholder text="No issues under location." />
                    </Box>
                )}
                {issueIds.map((issueId, index) => (
                    <Box key={issueId} pb={1} borderBottom={`1px solid ${theme.palette.primary.light}`}>
                        <IssueSummary
                            key={issueId}
                            issueId={issueId}
                            stateContext={stateContext}
                            index={index}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};
