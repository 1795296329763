import { Authenticator } from "./Authenticator";
import { ComponentType } from "react";

export function withSignInPortal<T>(
    Component: ComponentType<T>
) {
    const WithSignInPortal = ({
        ...props
    }: any) => {
        return (
            <Authenticator>
                {({ user }) => (
                    <Component {...props} user={user} />
                )}
            </Authenticator>
        );
    };
    return WithSignInPortal;
}