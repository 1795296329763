import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    TextField,
    Typography
} from "@mui/material";
import {
    useEffect,
    useMemo,
    useState
} from "react";

import { ExternalReportBranding } from "../../../../models";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { LogoUploadStagingArea } from "../logo/LogoUploadStagingArea";
import { useExternalReportBrandingDAO } from "./hook/useExternalReportBrandingDAO";
import { useUploadLogo } from "../logo/useUploadLogo";
import uuidv4 from "../../../../lib/util/UuidGenerator";

interface CreateBrandingDialogProps {
    isOpen: boolean;
    onCreate: (branding: ExternalReportBranding) => void;
    onCancel: () => void;
}

export const CreateBrandingDialog = (props: CreateBrandingDialogProps) => {
    const { isOpen, onCreate, onCancel } = props;
    
    const [name, setName] = useState<string>("");
    const [stagedLogo, setStagedLogo] = useState<File | undefined>(undefined);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const enableSaveButton = useMemo(() => {
        return stagedLogo !== undefined;
    }, [stagedLogo]);

    const { uploadLogo } = useUploadLogo();
    const { createBranding } = useExternalReportBrandingDAO();

    useEffect(() => {
        return () => {
            clearDialog();
        }
    }, [])

    const clearDialog = () => {
        setName("");
        setStagedLogo(undefined);
        setIsSaving(false);
    }

    const handleBrandingCreation = async () => {
        setIsSaving(true);
        if (!stagedLogo) {
            return;
        }
        const key: string = uuidv4();
        const isSuccessful: boolean = await uploadLogo(key, await stagedLogo.arrayBuffer());
        const branding: ExternalReportBranding = {
            id: key,
            name: name,
            logoKey: key,
        }
        await createBranding(branding);
        if (isSuccessful) {
            onCreate(branding);
        }
        setIsSaving(false);
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            PaperProps={{ sx: { height: window.innerHeight * 0.8 } }}
            open={isOpen}
        >
            <DialogTitle sx={{ flexGrow: 0 }}>
                Create new branding
            </DialogTitle>
            <Divider />
            <Box sx={{ flexGrow: 1, overflow: "auto", height: "100%", display: "flex", flexDirection: "column", flexWrap: "nowrap"}} p={2}>
                <Typography
                    variant="h6"
                    pb={2}
                >
                    Company Name
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    label="Company Name"
                    onChange={(event) => setName(event.target.value)}
                />
                <Typography
                    variant="h6"
                    py={2}
                >
                    Company Logo
                </Typography>
                <LogoUploadStagingArea 
                    onImageStaged={setStagedLogo}
                />
            </Box>
            <Divider />
            <DialogActions sx={{ flexGrow: 0 }}>
                <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                        onCancel();
                        clearDialog();
                    }}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    disabled={!enableSaveButton}
                    fullWidth
                    onClick={() => {
                        handleBrandingCreation();
                        clearDialog();
                    }}
                    loading={isSaving}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog >
    )
}
