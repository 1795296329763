import {
    Box,
    Button,
    MenuItem,
    Select
} from "@mui/material";
import {
    availableVideoInputDevicesSelector,
    defaultVideoInputDeviceAtom
} from "../state/ChimeStates";
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import { VideoInputDevice } from "amazon-chime-sdk-js";

export const VideoInputPreview = () => {
    const availableVideoInputDevices = useRecoilValue(availableVideoInputDevicesSelector);
    const [selectedVideoInputDevice, setSelectedVideoInputDevice] = useRecoilState(defaultVideoInputDeviceAtom);
    const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
    const videoStreamRef = useRef<MediaStream | null>(videoStream);
    videoStreamRef.current = videoStream;

    const startPreview = useCallback(async (device: VideoInputDevice) => {
        videoStreamRef.current?.getTracks().forEach((track) => track.stop());
        const stream = await navigator.mediaDevices.getUserMedia({
            video: device ? { deviceId: device as string } : true
        });
        setVideoStream(stream);
    }, []);

    const stopPreview = useCallback(() => {
        videoStreamRef.current?.getTracks().forEach((track) => track.stop());
        setVideoStream(null);
    }, []);

    const facingMode = useMemo(() => {
        return videoStream?.getTracks()[0].getSettings().facingMode;
    }, [videoStream]);

    useEffect(() => {
        if (!videoStreamRef.current || !selectedVideoInputDevice) {
            return;
        }
        startPreview(selectedVideoInputDevice);
    }, [selectedVideoInputDevice]);

    useEffect(() => {
        return () => {
            videoStreamRef.current?.getTracks().forEach((track) => track.stop());
        };
    }, []);

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={1} alignItems="center">
                <Select
                    value={selectedVideoInputDevice ?? "default"}
                    onChange={(e) => {
                        setSelectedVideoInputDevice(e.target.value as string);
                    }}
                    size="small"
                    fullWidth
                    sx={{ overflow: "auto" }}
                >
                    {availableVideoInputDevices.length > 0 ? (
                        availableVideoInputDevices.map((device) => (
                            <MenuItem key={device.deviceId} value={device.deviceId}>{device.label}</MenuItem>
                        ))
                    ) : (
                        <MenuItem value="default">Default</MenuItem>
                    )}
                </Select>
            </Box>
            <Box display="flex" gap={1} alignItems="center" justifyContent="space-around">
                <Button
                    size="small"
                    onClick={() => startPreview(selectedVideoInputDevice!)}
                    disabled={!selectedVideoInputDevice || !!videoStream}
                    variant="outlined"
                    fullWidth
                >
                    Start Preview
                </Button>
                <Button
                    size="small"
                    onClick={stopPreview}
                    disabled={!videoStream}
                    variant="outlined"
                    fullWidth
                >
                    Stop Preview
                </Button>
            </Box>
            {videoStream && (
                <Box>
                    <video
                        autoPlay
                        playsInline
                        ref={(video) => {
                            if (video) {
                                video.srcObject = videoStreamRef.current;
                            }
                        }}
                        style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "contain",
                            transform: facingMode !== "environment" ? "rotateY(180deg)" : undefined
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};