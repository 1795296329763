import {
    Dimension,
    MeasurementUnit
} from "../../../models";
import {
    dimensionByDimensionIdentifierSelectorFamily,
    dimensionIdsByIssueIdentifierAtomFamily,
    workSpecificationByWorkSpecificationIdentifierSelectorFamily,
    workSpecificationIdsByIssueIdentifierSelectorFamily
} from "../../design/document/state/DocumentState";

import { ContextAwareIdentifier } from "../../design/document/ContextAwareIdentifier";
import { ModelType } from "../../design/document/ModelType";
import { StateContext } from "../../design/document/state/StateContext";
import { useRecoilCallback } from "recoil";
import { useUpdateDimension } from "../../dimension/hook/useUpdateDimension";
import { useUpdateWorkSpecification } from "../../design/workscopespecification/hook/useUpdateWorkSpecification";

export const useUpdateChildMeasurementUnit = (context: StateContext) => {
    const { updateDimension } = useUpdateDimension(context);
    const { updateWorkSpecification } = useUpdateWorkSpecification(context);

    const updateChildMeasurementUnit = useRecoilCallback(({ snapshot }) => async (
        issueId: string,
        measurementUnit: MeasurementUnit,
    ) => {
        const release = snapshot.retain();
        try {
            const issueIdentifier = new ContextAwareIdentifier(issueId, context, ModelType.ISSUE);
            const dimensionIds: Array<string> = snapshot.getLoadable(dimensionIdsByIssueIdentifierAtomFamily(issueIdentifier)).contents;
            if (dimensionIds.length > 0) {
                const dimensionIdentifier = new ContextAwareIdentifier(dimensionIds[0], context, ModelType.DIMENSION);
                const existingDimension: Dimension | null = snapshot.getLoadable(
                    dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier)
                ).contents;
                if (existingDimension) {
                    const updatedDimension = {
                        ...existingDimension,
                        measurement: {
                            ...existingDimension.measurement,
                            measurementUnit
                        }
                    };
                    await updateDimension(updatedDimension);
                }
            }
            const workSpecificationIds: Array<string> = snapshot.getLoadable(workSpecificationIdsByIssueIdentifierSelectorFamily(issueIdentifier)).contents;
            if (workSpecificationIds.length > 0) {
                await Promise.all(workSpecificationIds.map(async (workSpecificationId) => {
                    const workSpecificationIdentifier = new ContextAwareIdentifier(workSpecificationId, context, ModelType.WORK_SPECIFICATION);
                    const existingWorkSpecification = snapshot.getLoadable(
                        workSpecificationByWorkSpecificationIdentifierSelectorFamily(workSpecificationIdentifier)
                    ).contents;
                    if (existingWorkSpecification) {
                        const updatedWorkSpecification = {
                            ...existingWorkSpecification,
                            measurement: {
                                ...existingWorkSpecification.measurement,
                                measurementUnit
                            }
                        };
                        await updateWorkSpecification(updatedWorkSpecification);
                    }
                }));
            };
        } finally {
            release();
        }
    }, [context, updateDimension, updateWorkSpecification]);

    return { updateChildMeasurementUnit };
};

