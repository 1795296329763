import {
    Dispatch,
    SetStateAction
} from "react";

import { CognitoUser } from "@aws-amplify/auth";
import HubEventHandler from "../event/HubEventHandler";
import { HubPayload } from "@aws-amplify/core";

export default class AuthSignInEventHandler implements HubEventHandler {
    private readonly setUser: Dispatch<SetStateAction<CognitoUser | undefined>>;
    private static readonly SIGN_IN_EVENT = "signIn";
    private static readonly AUTO_SIGN_IN_EVENT = "autoSignIn";

    constructor(
        setUser: Dispatch<SetStateAction<CognitoUser | undefined>>,
    ) {
        this.setUser = setUser;
    }

    public async handle(payload: HubPayload): Promise<void> {
        const shouldProcess = payload.event === AuthSignInEventHandler.AUTO_SIGN_IN_EVENT || payload.event === AuthSignInEventHandler.SIGN_IN_EVENT;
        if (!shouldProcess) {
            return;
        }
        // Reload the page on sign-in to reset DataStore state.
        window.location.reload();
    }
}