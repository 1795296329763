import {
    PermissionResourceType,
    PermissionType
} from "../../../API"
import {
    RecoilState,
    atomFamily,
} from "recoil"

import { ResourcePermissionProvider } from "../provider/ResourcePermissionProvider";
import { ResourcePermissionProviderFactory } from "../provider/ResourcePermissionProviderFactory";

const resourcePermissionProvider: ResourcePermissionProvider = ResourcePermissionProviderFactory.getInstance();

export const resourcePermissionAtomFamily = atomFamily<Set<PermissionType>, string>({
    key: "resourcePermissionAtomFamily",
    default: new Set()
})

export const fetchPermissionsForResource = async (
    resourceId: string,
    resourceType: PermissionResourceType,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void
) => {
    const permissions = await resourcePermissionProvider.getCurrentUserPermissionsForResource(resourceId, resourceType);
    set(resourcePermissionAtomFamily(resourceId), permissions);
}