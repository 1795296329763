import { imageDataToBlob } from "../../../lib/util/data/blob/imageDataToBlob";
import { useAudioInjection } from "../sound/AudioInjectionProvider";
import { useCallback } from "react";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";

export const useCaptureMeeting = () => {
    const meetingManager = useMeetingManager();
    const { playSound } = useAudioInjection();

    const captureMeeting = useCallback(async () => {
        const tile = meetingManager.meetingSession?.audioVideo.getAllRemoteVideoTiles()[0]; //Assuming only one video source
        const imageData: ImageData | undefined = tile?.capture() ?? undefined;
        if (!imageData) {
            throw new Error("No image data found");
        }
        const blob: Blob | null = await imageDataToBlob(imageData);
        if (!blob) {
            throw new Error("Failed to convert image data to blob");
        }
        playSound(new Audio("/audios/camera-shutter.mp3"));
        return blob;
    }, [meetingManager]);

    return { captureMeeting };
};