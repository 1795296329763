import { Box } from "@mui/system";
import { TenderingList } from "../../components/tendering/TenderingList";
import { Typography } from "@mui/material";
import globalStyle from '../../assets/style/globalStyle';

const TenderingTab = () => {
    const globalClasses = globalStyle();

    return (
            <Box className={globalClasses.managerLayout}>
                <Typography variant="h6">
                    Tendering Page
                </Typography>
                <Box marginTop={1}>
                    <TenderingList />
                </Box>
            </Box>
    );
};

export default TenderingTab;
