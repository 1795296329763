import { ATTACHMENT_UPLOAD_STATUS_CHANGE_EVENT } from "../AttachmentUploadConstant";
import { AttachmentUploadStatus } from "./AttachmentUploadStatus";
import HubEventHandler from "../../../event/HubEventHandler";
import { HubPayload } from "@aws-amplify/core";
import { SetterOrUpdater } from "recoil";

export default class AttachmentUploadStatusChangeEventHandler implements HubEventHandler {
    private readonly setIncompleteAttachmentIdToAttachmentUploadStatus: SetterOrUpdater<Map<string, AttachmentUploadStatus>>;
    private readonly setUploadCompletedAttachmentIds: SetterOrUpdater<string[]>;

    constructor(
        setIncompleteAttachmentIdToAttachmentUploadStatus: SetterOrUpdater<Map<string, AttachmentUploadStatus>>,
        setUploadCompletedAttachmentIds: SetterOrUpdater<string[]>
    ) {
        this.setIncompleteAttachmentIdToAttachmentUploadStatus = setIncompleteAttachmentIdToAttachmentUploadStatus;
        this.setUploadCompletedAttachmentIds = setUploadCompletedAttachmentIds;
    }

    public async handle(payload: HubPayload): Promise<void> {
        if (payload.event === ATTACHMENT_UPLOAD_STATUS_CHANGE_EVENT && payload.data) {
            if (payload.data.status === AttachmentUploadStatus.COMPLETE) {
                this.setUploadCompletedAttachmentIds(original => {
                    const newState = [...original];
                    newState.push(payload.data.attachmentId);
                    return newState;
                });
                return;
            }
            this.setIncompleteAttachmentIdToAttachmentUploadStatus(original => {
                const newState = new Map<string, AttachmentUploadStatus>(original);
                newState.set(payload.data.attachmentId, payload.data.status);
                return newState;
            });
        }
    }

}