import {
    API,
    graphqlOperation
} from "aws-amplify";

import { AuthIDTokenSupplierFactory } from "../../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../../logging/ClientLoggerFactory";
import GraphQLLocationDAO from "./GraphQLLocationDAO";

export default class GraphQLLocationDAOFactory {
    private static INSTANCE = new GraphQLLocationDAO(
        API,
        graphqlOperation,
        ClientLoggerFactory.getClientLogger("GraphQLLocationDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );
    static getInstance() {
        return this.INSTANCE;
    }
}