import {
    Box,
    Collapse,
    FormControlLabel,
    Switch,
    Typography
} from "@mui/material";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { isAiAssistedNamingEnabledAtom } from "./hooks/state/AISummarizationStates";
import { useEnableQuickActions } from "./hooks/useEnableQuickActions";
import { useLiveSubscription } from "../../lib/subscription/useLiveSubscription";
import { useRecoilState } from "recoil";
import { useState } from "react";

export const LiveInspectionSettings = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isAiEnabled, setIsAiEnabled] = useRecoilState(isAiAssistedNamingEnabledAtom);

    const { enableQuickActions, disableQuickActions, isHotKeyEnabled } = useEnableQuickActions();
    const { isSubscribed, toggleSubscription } = useLiveSubscription();

    const toggleHotKeys = () => {
        if (isHotKeyEnabled) {
            disableQuickActions();
        } else {
            enableQuickActions();
        }
    };

    const toggleAiNaming = () => {
        setIsAiEnabled(prev => !prev);
    };

    return (
        <>
            <Box sx={{ cursor: "pointer" }} onClick={() => setIsExpanded(prev => !prev)} display="flex" alignItems="center" gap={1}>
                <Typography variant="h6">Settings</Typography>
                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Box>
            <Collapse in={isExpanded}>
                <Box pt={2} px={0.5}>
                    <FormControlLabel
                        sx={{ width: "100%", m: 0, mx: 0.5 }}
                        control={
                            <Switch
                                sx={{ ml: "auto" }}
                                checked={isSubscribed}
                                onChange={() => toggleSubscription()}
                            />
                        }
                        label="Live Inspection"
                        labelPlacement="start"
                    />
                    <Typography variant="body2" px={2} color="grey">See other users' changes in real time</Typography>
                    <FormControlLabel
                        sx={{ width: "100%", m: 0, mx: 0.5 }}
                        control={
                            <Switch
                                sx={{ ml: "auto" }}
                                checked={isHotKeyEnabled}
                                onChange={() => toggleHotKeys()}
                            />
                        }
                        label="Quick Action Mode"
                        labelPlacement="start"
                    />
                    <Typography variant="body2" px={2} color="grey">Enable hotkey controls</Typography>
                    <FormControlLabel
                        sx={{ width: "100%", m: 0, mx: 0.5 }}
                        control={
                            <Switch
                                sx={{ ml: "auto" }}
                                checked={isAiEnabled}
                                onChange={() => toggleAiNaming()}
                            />
                        }
                        label="AI-Assisted Naming"
                        labelPlacement="start"
                    />
                    <Typography variant="body2" px={2} color="grey">Let AI name issues based on the call transcript</Typography>
                </Box>
            </Collapse>
        </>
    );
};