import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const PropertiesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    style={{
      //@ts-ignore
      enableBackground: "new 0 0 24 24",
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 0v8.26l6.6 4.94a1 1 0 0 1 .4.8v6h7V0H10zm6 6h-4V2h4v4zm6 12h-4v-4h4v4zm0-6h-4V8h4v4zm0-6h-4V2h4v4z"
      className="accent"
    />
    <path
      fill="currentColor"
      d="M0 14v10h6v-6h4v6h6V14L8.01 8.02 0 14zm15 .501V23h-4v-5l-1-1H6l-1 1v5H1v-8.499l7.009-5.233 3.397 2.542L15 14.501z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(PropertiesIcon);
