import * as yup from "yup";

import { ExternalReportLinkRegistryFormVariables } from "@tenera/linkregistrymodels";
import { REQUIRED_FIELD_TEXT } from "../../LinkRegistryConstants";

export const externalReportLinkRegistryVariablesSchema : yup.SchemaOf<ExternalReportLinkRegistryFormVariables> = yup.object({
    brandingId: yup.string().required(REQUIRED_FIELD_TEXT),
    propertyId: yup.string().required(REQUIRED_FIELD_TEXT),
    propertyAddress: yup.string(),
    locationId: yup.string(),
    locationName: yup.string()
});
