import { AttachmentContent } from "../AttachmentContent";
import AttachmentContentDAO from "../AttachmentContentDAO";
import ClientLogger from "../../../logging/ClientLogger";

export class MetricLoggingLocalForageAttachmentContentDAODecorator implements AttachmentContentDAO {
    public static GET_ATTACHMENT_URL_LOCALFORAGE_ATTEMPT = "getAttachmentURLByAttachmentKeyLocalForageAttempt"
    public static GET_ATTACHMENT_URL_LOCALFORAGE_MISS = "getAttachmentURLByAttachmentKeyLocalForageNotFound"
    public static GET_ATTACHMENT_URL_LOCALFORAGE_FAILURE = "getAttachmentURLByAttachmentKeyLocalForageFailure"
    public static LIST_ATTACHMENT_KEY_LOCALFORAGE_ATTEMPT = "listAttachmentKeyLocalForageAttempt";
    public static LIST_ATTACHMENT_KEY_LOCALFORAGE_FAILURE = "listAttachmentKeyLocalForageFailure";
    public static SAVE_ATTACHMENT_LOCALFORAGE_ATTEMPT = "saveAttachmentLocalForageAttempt";
    public static SAVE_ATTACHMENT_LOCALFORAGE_FAILURE = "saveAttachmentLocalForageFailure";

    private delegate: AttachmentContentDAO;
    private logger: ClientLogger;

    constructor(
        delegate: AttachmentContentDAO,
        logger: ClientLogger
    ) {
        this.delegate = delegate;
        this.logger = logger;
    }

    public async getAttachmentContentByAttachmentKey(key: string, variant?: string): Promise<AttachmentContent> {
        try {
            this.logger.info(
                `Attempting to get Attachment URL from Localforage for AttachmentKey: ${key} and variant: ${variant}`,
                undefined,
                [MetricLoggingLocalForageAttachmentContentDAODecorator.GET_ATTACHMENT_URL_LOCALFORAGE_ATTEMPT]
            )
            const result = await this.delegate.getAttachmentContentByAttachmentKey(key, variant);
            return result;
        } catch (error) {
            this.logger.warn(
                `Failed to get item from LocalForage for AttachmentKey: ${key} and variant: ${variant}`,
                error,
                [MetricLoggingLocalForageAttachmentContentDAODecorator.GET_ATTACHMENT_URL_LOCALFORAGE_FAILURE]
            )
            throw error;
        }
    }

    public async listAttachmentContentKeys(): Promise<string[]> {
        try {
            this.logger.info(
                `Attempting to list keys from LocalForage`,
                undefined,
                [MetricLoggingLocalForageAttachmentContentDAODecorator.LIST_ATTACHMENT_KEY_LOCALFORAGE_ATTEMPT]
            )
            return await this.delegate.listAttachmentContentKeys();
        } catch (error) {
            this.logger.error(
                `Failed to list keys from LocalForage`,
                error,
                [MetricLoggingLocalForageAttachmentContentDAODecorator.LIST_ATTACHMENT_KEY_LOCALFORAGE_FAILURE]
            )
            throw error;
        }
    }

    public async saveAttachment(
        attachmentKey: string,
        attachmentContent: AttachmentContent,
        variant?: string
    ): Promise<void> {
        try {
            this.logger.info(
                `Attempting to save attachment to localForage ${attachmentKey}${variant}`,
                undefined,
                [MetricLoggingLocalForageAttachmentContentDAODecorator.SAVE_ATTACHMENT_LOCALFORAGE_ATTEMPT]
            )
            return await this.delegate.saveAttachment(attachmentKey, attachmentContent, variant);
        } catch (error) {
            this.logger.error(
                `Failed to save attachment ${attachmentKey}${variant} to localForage`,
                error,
                [MetricLoggingLocalForageAttachmentContentDAODecorator.SAVE_ATTACHMENT_LOCALFORAGE_FAILURE]
            )
            throw error;
        }
    }
}