import * as yup from "yup";

import { LinkAction } from "../../../models";
import { LinkRegistryFormOutput } from "./LinkRegistryFormOutput";
import { REQUIRED_FIELD_TEXT } from "../LinkRegistryConstants";
import { externalReportLinkRegistryVariablesSchema } from "./variables/ExternalReportLinkRegistryVariablesSchema";

export const linkRegistryFormSchema: yup.SchemaOf<LinkRegistryFormOutput> = yup.object({
    linkAction: yup.mixed<LinkAction>().oneOf(Object.values(LinkAction)).required(REQUIRED_FIELD_TEXT), 
    variables: yup.object()
        .when("linkAction", {
            is: LinkAction.EXTERNAL_REPORT,
            then: externalReportLinkRegistryVariablesSchema
        })
        .when("linkAction", {
            is: LinkAction.ISSUE_FORM,
            then: yup.object({
                propertyId: yup.string().required(REQUIRED_FIELD_TEXT),
                locationId: yup.string()
            })
        })
        .default(yup.object({}))
});