import Deserializer from "./Deserializer";

export default class JSONDeserializer implements Deserializer<string, any> {

    public deserialize(value: string): any {
        return JSON.parse(value, this.reviver);
    }

    private reviver(key: any, value: any) {
        if (typeof value === "object" && value !== null) {
            if (value.dataType === "Map") {
                return new Map(value.value);
            }
            if (value.dataType === "Set") {
                return new Set(value.value);
            }
        }
        return value;
    }
}
