import ClientLog from "./ClientLog";
import ClientLogDAO from "./ClientLogDAO";
import { LogSeverityLevel } from "./LogSeverityLevel";

export default class ConsoleClientLogDAO implements ClientLogDAO {
    private severityFilter: LogSeverityLevel[] = [
        LogSeverityLevel.DEBUG,
        LogSeverityLevel.INFO,
        LogSeverityLevel.WARN,
        LogSeverityLevel.ERROR
    ];
    private readonly delegate: ClientLogDAO;

    constructor(
        delegate: ClientLogDAO,
        severityFilter?: Array<LogSeverityLevel>
    ) {
        this.delegate = delegate;
        if (severityFilter !== undefined) {
            this.severityFilter = severityFilter;
        }
    }

    public async save(
        clientLog: ClientLog
    ): Promise<void> {
        if (this.severityFilter.includes(clientLog.severityLevel)) {
            this.logToConsole(clientLog);
        }
        this.delegate.save(clientLog);
    }

    private logToConsole(
        clientLog: ClientLog
    ) {
        if (clientLog.severityLevel === LogSeverityLevel.DEBUG) {
            console.debug(clientLog);
        }
        if (clientLog.severityLevel === LogSeverityLevel.INFO) {
            console.log(`${clientLog.message}, `, clientLog);
        }
        if (clientLog.severityLevel === LogSeverityLevel.WARN) {
            console.warn(`${clientLog.message}, `, clientLog);
        }
        if (clientLog.severityLevel === LogSeverityLevel.ERROR) {
            console.error(`${clientLog.message}, `, clientLog);
        }
    }
}
