import {
    API,
    graphqlOperation
} from "aws-amplify";
import {
    CreateLocationMutation,
    GetLocationQuery,
    ListLocationsByPropertyQuery,
    ListLocationsByPropertyQueryVariables
} from "../../../../API";
import {
    getLocation,
    listLocationsByProperty
} from "../../../../graphql/queries";

import ClientLogger from "../../../logging/ClientLogger";
import CreateLocationError from "../error/CreateLocationError";
import GetLocationByIdError from "../error/GetLocationByIdError";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { GraphQLSearchLocationByPropertyIdOperation } from "../GraphQLSearchLocationByPropertyIdOperation";
import { IDTokenSupplier } from "../../../auth/IDTokenSupplier";
import { Location } from "../../../../models";
import LocationDAO from "../LocationDAO";
import LocationRecordAlreadyExistsError from "../error/LocationRecordAlreadyExistsError";
import { SearchResponse } from "../../../util/search/SearchResponse";
import { createLocation } from "../../../../graphql/mutations";

export default class GraphQLLocationDAO implements LocationDAO, GraphQLSearchLocationByPropertyIdOperation {
    private static readonly CREATE_ATTEMPT_METRIC_NAME = "GraphQLLocationDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "GraphQLLocationDAO.Create.Failure";
    private static readonly GET_BY_ID_ATTEMPT_METRIC_NAME = "GraphQLLocationDAO.GetById.Attempt";
    private static readonly GET_BY_ID_FAILURE_METRIC_NAME = "GraphQLLocationDAO.GetById.Failure";
    private static readonly SEARCH_LOCATIONS_ATTEMPT_METRIC_NAME = "GraphQLLocationDAO.SearchLocations.Attempt";
    private static readonly SEARCH_LOCATIONS_FAILURE_METRIC_NAME = "GraphQLLocationDAO.SearchLocations.Failure";

    private api: typeof API;
    private gqlOperation: typeof graphqlOperation;
    private logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async getLocationById(id: string): Promise<Location> {
        try {
            this.logger.info(
                `Retrieving Location with id=${id}`,
                undefined,
                [GraphQLLocationDAO.GET_BY_ID_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<GetLocationQuery> = await this.api.graphql(
                this.gqlOperation(
                    getLocation,
                    { id },
                    authToken
                )
            ) as GraphQLResult<GetLocationQuery>;
            return result.data?.getLocation!;
        } catch (error) {
            const errorMessage = `Failed to retrieve Location with id=${id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLLocationDAO.GET_BY_ID_FAILURE_METRIC_NAME]
            );
            throw new GetLocationByIdError(
                errorMessage, {
                cause: error as Error
            });
        }
    }

    public async create(formState: Location): Promise<Location> {
        this.logger.info(
            `Creating Location with id=${formState.id}`,
            formState,
            [GraphQLLocationDAO.CREATE_ATTEMPT_METRIC_NAME]
        );
        try {
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<CreateLocationMutation> = await this.api.graphql(
                this.gqlOperation(
                    createLocation,
                    { input: formState },
                    authToken
                )
            ) as GraphQLResult<CreateLocationMutation>;
            return result.data?.createLocation as Location;
        } catch (error) {
            if ((error as any)?.errors && (error as any)?.errors[0].errorType === "ConditionalCheckFailedException") {
                const errorMessage = `Location record already exists: ${formState.id}`;
                this.logger.warn(
                    errorMessage,
                    error,
                    [GraphQLLocationDAO.CREATE_FAILURE_METRIC_NAME]
                );
                throw new LocationRecordAlreadyExistsError(
                    errorMessage
                );
            }
            const errorMessage = `Unable to create Location with id=${formState.id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLLocationDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreateLocationError(errorMessage, {
                cause: error as Error
            });
        }
    }

    public async getLocationsByPropertyId(propertyId: string): Promise<Location[]> {
        throw new Error("Method not implemented.");
    }

    public async getLocationCountByParentId(parentId: string): Promise<number> {
        throw new Error("Method not implemented.");
    }

    public async editLocation(locationId: string, formState: Location): Promise<Location> {
        throw new Error("Method not implemented.");
    }

    public async deleteLocation(locationId: string): Promise<void> {
        throw new Error("Method not implemented.");
    }

    public async searchLocationsByPropertyId(
        propertyId: string,
        searchValue: string,
        nextToken?: string
    ): Promise<SearchResponse<Location>> {
        try {
            this.logger.info(
                `Searching for Locations with searchValue=${searchValue}`,
                undefined,
                [GraphQLLocationDAO.SEARCH_LOCATIONS_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: ListLocationsByPropertyQueryVariables = {
                propertyId: propertyId,
                filter: {
                    name: {
                        contains: searchValue
                    }
                },
                nextToken: nextToken
            };
            const result: GraphQLResult<ListLocationsByPropertyQuery> = await this.api.graphql(
                this.gqlOperation(
                    listLocationsByProperty,
                    variables,
                    authToken
                )
            ) as GraphQLResult<ListLocationsByPropertyQuery>;
            let objects: Array<Location> = (result.data?.listLocationsByProperty?.items ?? []) as Array<Location>;
            objects = objects.sort((a, b) => {
                if (a.name == undefined || b.name == undefined) {
                    return 0;
                }
                return a.name.localeCompare(b.name);
            });
            return {
                objects: objects,
                nextToken: result.data?.listLocationsByProperty?.nextToken ?? undefined,
                total: result.data?.listLocationsByProperty?.items?.length ?? 0
            };
        } catch (error) {
            const errorMessage = `Failed to search for Locations with searchValue=${searchValue}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLLocationDAO.SEARCH_LOCATIONS_FAILURE_METRIC_NAME]
            );
            throw new GetLocationByIdError(
                errorMessage, {
                cause: error as Error
            });
        }
    }
}
