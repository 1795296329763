/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateSolutionEventInput = {
  eventTime?: number | null,
  solutionId: string,
  solutionMinorVersion?: number | null,
  proposalId?: string | null,
  userId?: string | null,
  solutionOperationType?: SolutionOperationType | null,
};

export enum SolutionOperationType {
  SAVE = "SAVE",
  PUBLISH = "PUBLISH",
}


export type CreateBidInvitationEventInput = {
  biddingContactId: string,
  tenderId: string,
};

export type ManageResourcePermissionInput = {
  entityType?: PermissionEntityType | null,
  entityId?: string | null,
  resourceType?: PermissionResourceType | null,
  resourceId?: string | null,
  propertyId?: string | null,
  permissionType?: PermissionType | null,
};

export enum PermissionEntityType {
  USER = "USER",
  TEAM = "TEAM",
}


export enum PermissionResourceType {
  PROPERTY = "PROPERTY",
  LOCATION = "LOCATION",
  ISSUE = "ISSUE",
  DIMENSION = "DIMENSION",
  WORK_SPECIFICATION = "WORK_SPECIFICATION",
  ATTACHMENT = "ATTACHMENT",
  QUOTE = "QUOTE",
  SOLUTION = "SOLUTION",
  PROPOSAL = "PROPOSAL",
}


export enum PermissionType {
  VIEW = "VIEW",
  EDIT = "EDIT",
  DELETE = "DELETE",
  CREATE_CHILDREN = "CREATE_CHILDREN",
  MANAGE_STATE = "MANAGE_STATE",
  SHARE_PERMISSION = "SHARE_PERMISSION",
  REMOVE_PERMISSION = "REMOVE_PERMISSION",
}


export type CompleteEvent = {
  __typename: "CompleteEvent",
  executionId: string,
  status: CompleteEventStatus,
  result?: string | null,
};

export enum CompleteEventStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}


export type ResendTemporaryCredentialsRequest = {
  userEmail: string,
};

export enum AttendeeRole {
  INSPECTOR = "INSPECTOR",
  AGENT = "AGENT",
}


export type ChimeMeetingOutput = {
  __typename: "ChimeMeetingOutput",
  meetingInfo: string,
  attendeeInfo: string,
};

export type CreateAttachmentInput = {
  id?: string | null,
  type?: AttachmentType | null,
  parentId: string,
  parentType?: PermissionResourceType | null,
  propertyId?: string | null,
  key?: string | null,
  localCreatedAtTime?: number | null,
  status?: string | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export enum AttachmentType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}


export type ModelAttachmentConditionInput = {
  type?: ModelAttachmentTypeInput | null,
  parentId?: ModelIDInput | null,
  parentType?: ModelPermissionResourceTypeInput | null,
  propertyId?: ModelIDInput | null,
  key?: ModelStringInput | null,
  localCreatedAtTime?: ModelIntInput | null,
  status?: ModelStringInput | null,
  opaqueSyncReconciliationToken?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelAttachmentConditionInput | null > | null,
  or?: Array< ModelAttachmentConditionInput | null > | null,
  not?: ModelAttachmentConditionInput | null,
};

export type ModelAttachmentTypeInput = {
  eq?: AttachmentType | null,
  ne?: AttachmentType | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelPermissionResourceTypeInput = {
  eq?: PermissionResourceType | null,
  ne?: PermissionResourceType | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Attachment = {
  __typename: "Attachment",
  id: string,
  type?: AttachmentType | null,
  parentId: string,
  parentType?: PermissionResourceType | null,
  propertyId?: string | null,
  key?: string | null,
  localCreatedAtTime?: number | null,
  status?: string | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAttachmentInput = {
  id: string,
  type?: AttachmentType | null,
  parentId?: string | null,
  parentType?: PermissionResourceType | null,
  propertyId?: string | null,
  key?: string | null,
  localCreatedAtTime?: number | null,
  status?: string | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export type DeleteAttachmentInput = {
  id: string,
  _version?: number | null,
};

export type CreateBiddingContactInput = {
  id?: string | null,
  email: string,
  ownerEntityId?: string | null,
  contactEntityId?: string | null,
  name?: string | null,
  privateDescription?: string | null,
  publicDescription?: string | null,
  _version?: number | null,
};

export type ModelBiddingContactConditionInput = {
  email?: ModelStringInput | null,
  ownerEntityId?: ModelStringInput | null,
  contactEntityId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  privateDescription?: ModelStringInput | null,
  publicDescription?: ModelStringInput | null,
  and?: Array< ModelBiddingContactConditionInput | null > | null,
  or?: Array< ModelBiddingContactConditionInput | null > | null,
  not?: ModelBiddingContactConditionInput | null,
};

export type BiddingContact = {
  __typename: "BiddingContact",
  id: string,
  email: string,
  ownerEntityId?: string | null,
  contactEntityId?: string | null,
  name?: string | null,
  privateDescription?: string | null,
  publicDescription?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateBiddingContactInput = {
  id: string,
  email?: string | null,
  ownerEntityId?: string | null,
  contactEntityId?: string | null,
  name?: string | null,
  privateDescription?: string | null,
  publicDescription?: string | null,
  _version?: number | null,
};

export type DeleteBiddingContactInput = {
  id: string,
  _version?: number | null,
};

export type CreateBidInvitationInput = {
  id?: string | null,
  biddingContactId?: string | null,
  tenderId?: string | null,
  ownerEntityId?: string | null,
  _version?: number | null,
};

export type ModelBidInvitationConditionInput = {
  biddingContactId?: ModelStringInput | null,
  tenderId?: ModelStringInput | null,
  ownerEntityId?: ModelStringInput | null,
  and?: Array< ModelBidInvitationConditionInput | null > | null,
  or?: Array< ModelBidInvitationConditionInput | null > | null,
  not?: ModelBidInvitationConditionInput | null,
};

export type BidInvitation = {
  __typename: "BidInvitation",
  id: string,
  biddingContactId?: string | null,
  tenderId?: string | null,
  ownerEntityId?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateBidInvitationInput = {
  id: string,
  biddingContactId?: string | null,
  tenderId?: string | null,
  ownerEntityId?: string | null,
  _version?: number | null,
};

export type DeleteBidInvitationInput = {
  id: string,
  _version?: number | null,
};

export type CreateBidInput = {
  id?: string | null,
  tenderId?: string | null,
  latestMinorVersion?: number | null,
  latestAcceptedMinorVersion?: number | null,
  latestSubmittedMinorVersion?: number | null,
  status?: BidStatus | null,
  bidNumber?: number | null,
  createdBy?: string | null,
  _version?: number | null,
};

export enum BidStatus {
  AWARDED = "AWARDED",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
}


export type ModelBidConditionInput = {
  tenderId?: ModelStringInput | null,
  latestMinorVersion?: ModelIntInput | null,
  latestAcceptedMinorVersion?: ModelIntInput | null,
  latestSubmittedMinorVersion?: ModelIntInput | null,
  status?: ModelBidStatusInput | null,
  bidNumber?: ModelIntInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelBidConditionInput | null > | null,
  or?: Array< ModelBidConditionInput | null > | null,
  not?: ModelBidConditionInput | null,
};

export type ModelBidStatusInput = {
  eq?: BidStatus | null,
  ne?: BidStatus | null,
};

export type Bid = {
  __typename: "Bid",
  id: string,
  tenderId?: string | null,
  latestMinorVersion?: number | null,
  latestAcceptedMinorVersion?: number | null,
  latestSubmittedMinorVersion?: number | null,
  status?: BidStatus | null,
  bidNumber?: number | null,
  createdBy?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateBidInput = {
  id: string,
  tenderId?: string | null,
  latestMinorVersion?: number | null,
  latestAcceptedMinorVersion?: number | null,
  latestSubmittedMinorVersion?: number | null,
  status?: BidStatus | null,
  bidNumber?: number | null,
  createdBy?: string | null,
  _version?: number | null,
};

export type DeleteBidInput = {
  id: string,
  _version?: number | null,
};

export type CreateBidMinorVersionInput = {
  id?: string | null,
  bidId?: string | null,
  minorVersion?: number | null,
  tenderId?: string | null,
  tenderMinorVersion?: number | null,
  proposalId?: string | null,
  status?: DocumentStatus | null,
  metadata?: BidMetadataInput | null,
  _version?: number | null,
};

export enum DocumentStatus {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}


export type BidMetadataInput = {
  bidderContactName?: string | null,
  bidderContactEmail?: string | null,
  bidderCompanyName?: string | null,
  bidderContactAddress?: string | null,
  bidderContactPhoneNumber?: string | null,
  bidderWebsite?: string | null,
};

export type ModelBidMinorVersionConditionInput = {
  bidId?: ModelStringInput | null,
  minorVersion?: ModelIntInput | null,
  tenderId?: ModelStringInput | null,
  tenderMinorVersion?: ModelIntInput | null,
  proposalId?: ModelStringInput | null,
  status?: ModelDocumentStatusInput | null,
  and?: Array< ModelBidMinorVersionConditionInput | null > | null,
  or?: Array< ModelBidMinorVersionConditionInput | null > | null,
  not?: ModelBidMinorVersionConditionInput | null,
};

export type ModelDocumentStatusInput = {
  eq?: DocumentStatus | null,
  ne?: DocumentStatus | null,
};

export type BidMinorVersion = {
  __typename: "BidMinorVersion",
  id: string,
  bidId?: string | null,
  minorVersion?: number | null,
  tenderId?: string | null,
  tenderMinorVersion?: number | null,
  proposalId?: string | null,
  status?: DocumentStatus | null,
  metadata?: BidMetadata | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type BidMetadata = {
  __typename: "BidMetadata",
  bidderContactName?: string | null,
  bidderContactEmail?: string | null,
  bidderCompanyName?: string | null,
  bidderContactAddress?: string | null,
  bidderContactPhoneNumber?: string | null,
  bidderWebsite?: string | null,
};

export type UpdateBidMinorVersionInput = {
  id: string,
  bidId?: string | null,
  minorVersion?: number | null,
  tenderId?: string | null,
  tenderMinorVersion?: number | null,
  proposalId?: string | null,
  status?: DocumentStatus | null,
  metadata?: BidMetadataInput | null,
  _version?: number | null,
};

export type DeleteBidMinorVersionInput = {
  id: string,
  _version?: number | null,
};

export type CreateClientLogInput = {
  id?: string | null,
  userId?: string | null,
  userAgent?: string | null,
  severityLevel: LogSeverityLevel,
  componentName: string,
  message?: string | null,
  details?: string | null,
  tags?: string | null,
  _version?: number | null,
};

export enum LogSeverityLevel {
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}


export type ModelClientLogConditionInput = {
  userId?: ModelStringInput | null,
  userAgent?: ModelStringInput | null,
  severityLevel?: ModelLogSeverityLevelInput | null,
  componentName?: ModelStringInput | null,
  message?: ModelStringInput | null,
  details?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  and?: Array< ModelClientLogConditionInput | null > | null,
  or?: Array< ModelClientLogConditionInput | null > | null,
  not?: ModelClientLogConditionInput | null,
};

export type ModelLogSeverityLevelInput = {
  eq?: LogSeverityLevel | null,
  ne?: LogSeverityLevel | null,
};

export type ClientLog = {
  __typename: "ClientLog",
  id: string,
  userId?: string | null,
  userAgent?: string | null,
  severityLevel: LogSeverityLevel,
  componentName: string,
  message?: string | null,
  details?: string | null,
  tags?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateClientLogInput = {
  id: string,
  userId?: string | null,
  userAgent?: string | null,
  severityLevel?: LogSeverityLevel | null,
  componentName?: string | null,
  message?: string | null,
  details?: string | null,
  tags?: string | null,
  _version?: number | null,
};

export type DeleteClientLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateCounterInput = {
  id?: string | null,
  type: ResourceType,
  _version?: number | null,
};

export enum ResourceType {
  PROPERTY = "PROPERTY",
  PROPOSAL = "PROPOSAL",
  SOLUTION = "SOLUTION",
  SOLUTION_MINOR_VERSION = "SOLUTION_MINOR_VERSION",
  WORKTYPE = "WORKTYPE",
  EXTERNAL_REPORT = "EXTERNAL_REPORT",
  TENDER = "TENDER",
  TENDER_MINOR_VERSION = "TENDER_MINOR_VERSION",
  BID = "BID",
  BID_MINOR_VERSION = "BID_MINOR_VERSION",
}


export type ModelCounterConditionInput = {
  type?: ModelResourceTypeInput | null,
  and?: Array< ModelCounterConditionInput | null > | null,
  or?: Array< ModelCounterConditionInput | null > | null,
  not?: ModelCounterConditionInput | null,
};

export type ModelResourceTypeInput = {
  eq?: ResourceType | null,
  ne?: ResourceType | null,
};

export type Counter = {
  __typename: "Counter",
  id: string,
  type: ResourceType,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCounterInput = {
  id: string,
  type?: ResourceType | null,
  _version?: number | null,
};

export type DeleteCounterInput = {
  id: string,
  _version?: number | null,
};

export type CreateDesignDocumentInput = {
  id?: string | null,
  designDocumentGroupId?: string | null,
  propertyId: string,
  projectId: string,
  name: string,
  status?: DesignDocumentStatus | null,
  lastEvaluatedValidityStatus?: boolean | null,
  uploadStatus?: string | null,
  documentKey?: string | null,
  lastWorkScopeSpecificationItemNumber?: number | null,
  version?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export enum DesignDocumentStatus {
  AUTOSAVE_DRAFT = "AUTOSAVE_DRAFT",
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  APPROVED = "APPROVED",
}


export type ModelDesignDocumentConditionInput = {
  designDocumentGroupId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  projectId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelDesignDocumentStatusInput | null,
  lastEvaluatedValidityStatus?: ModelBooleanInput | null,
  uploadStatus?: ModelStringInput | null,
  documentKey?: ModelStringInput | null,
  lastWorkScopeSpecificationItemNumber?: ModelIntInput | null,
  version?: ModelIntInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelDesignDocumentConditionInput | null > | null,
  or?: Array< ModelDesignDocumentConditionInput | null > | null,
  not?: ModelDesignDocumentConditionInput | null,
};

export type ModelDesignDocumentStatusInput = {
  eq?: DesignDocumentStatus | null,
  ne?: DesignDocumentStatus | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type DesignDocument = {
  __typename: "DesignDocument",
  id: string,
  designDocumentGroupId?: string | null,
  propertyId: string,
  projectId: string,
  name: string,
  status?: DesignDocumentStatus | null,
  lastEvaluatedValidityStatus?: boolean | null,
  uploadStatus?: string | null,
  documentKey?: string | null,
  lastWorkScopeSpecificationItemNumber?: number | null,
  version?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDesignDocumentInput = {
  id: string,
  designDocumentGroupId?: string | null,
  propertyId?: string | null,
  projectId?: string | null,
  name?: string | null,
  status?: DesignDocumentStatus | null,
  lastEvaluatedValidityStatus?: boolean | null,
  uploadStatus?: string | null,
  documentKey?: string | null,
  lastWorkScopeSpecificationItemNumber?: number | null,
  version?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type DeleteDesignDocumentInput = {
  id: string,
  _version?: number | null,
};

export type CreateDesignDocumentGroupInput = {
  id?: string | null,
  projectId: string,
  propertyId: string,
  name: string,
  latestDocumentVersion: number,
  documentContentKey?: string | null,
  solutionGroupNumber?: number | null,
  isLocked: boolean,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type ModelDesignDocumentGroupConditionInput = {
  projectId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  latestDocumentVersion?: ModelIntInput | null,
  documentContentKey?: ModelStringInput | null,
  solutionGroupNumber?: ModelIntInput | null,
  isLocked?: ModelBooleanInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelDesignDocumentGroupConditionInput | null > | null,
  or?: Array< ModelDesignDocumentGroupConditionInput | null > | null,
  not?: ModelDesignDocumentGroupConditionInput | null,
};

export type DesignDocumentGroup = {
  __typename: "DesignDocumentGroup",
  id: string,
  projectId: string,
  propertyId: string,
  name: string,
  latestDocumentVersion: number,
  documentContentKey?: string | null,
  solutionGroupNumber?: number | null,
  isLocked: boolean,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDesignDocumentGroupInput = {
  id: string,
  projectId?: string | null,
  propertyId?: string | null,
  name?: string | null,
  latestDocumentVersion?: number | null,
  documentContentKey?: string | null,
  solutionGroupNumber?: number | null,
  isLocked?: boolean | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type DeleteDesignDocumentGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateDimensionInput = {
  id?: string | null,
  parentId?: string | null,
  propertyId?: string | null,
  createdInSolution?: boolean | null,
  description?: string | null,
  length?: number | null,
  lengthUnit?: string | null,
  width?: number | null,
  widthUnit?: string | null,
  dimensionType?: DimensionType | null,
  measurementUnit?: MeasurementUnit | null,
  measurement?: MeasurementInput | null,
  localLastUpdatedTime?: number | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export enum DimensionType {
  LINEAR = "LINEAR",
  SQUARE = "SQUARE",
}


export enum MeasurementUnit {
  METER = "METER",
  CENTIMETER = "CENTIMETER",
  FOOT = "FOOT",
  INCH = "INCH",
}


export type MeasurementInput = {
  length?: number | null,
  width?: number | null,
  dimensionType?: DimensionType | null,
  measurementUnit?: MeasurementUnit | null,
};

export type ModelDimensionConditionInput = {
  parentId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  createdInSolution?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  length?: ModelFloatInput | null,
  lengthUnit?: ModelStringInput | null,
  width?: ModelFloatInput | null,
  widthUnit?: ModelStringInput | null,
  dimensionType?: ModelDimensionTypeInput | null,
  measurementUnit?: ModelMeasurementUnitInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  opaqueSyncReconciliationToken?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelDimensionConditionInput | null > | null,
  or?: Array< ModelDimensionConditionInput | null > | null,
  not?: ModelDimensionConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelDimensionTypeInput = {
  eq?: DimensionType | null,
  ne?: DimensionType | null,
};

export type ModelMeasurementUnitInput = {
  eq?: MeasurementUnit | null,
  ne?: MeasurementUnit | null,
};

export type Dimension = {
  __typename: "Dimension",
  id: string,
  parentId?: string | null,
  propertyId?: string | null,
  createdInSolution?: boolean | null,
  description?: string | null,
  length?: number | null,
  lengthUnit?: string | null,
  width?: number | null,
  widthUnit?: string | null,
  dimensionType?: DimensionType | null,
  measurementUnit?: MeasurementUnit | null,
  measurement?: Measurement | null,
  localLastUpdatedTime?: number | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Measurement = {
  __typename: "Measurement",
  length?: number | null,
  width?: number | null,
  dimensionType?: DimensionType | null,
  measurementUnit?: MeasurementUnit | null,
};

export type UpdateDimensionInput = {
  id: string,
  parentId?: string | null,
  propertyId?: string | null,
  createdInSolution?: boolean | null,
  description?: string | null,
  length?: number | null,
  lengthUnit?: string | null,
  width?: number | null,
  widthUnit?: string | null,
  dimensionType?: DimensionType | null,
  measurementUnit?: MeasurementUnit | null,
  measurement?: MeasurementInput | null,
  localLastUpdatedTime?: number | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export type DeleteDimensionInput = {
  id: string,
  _version?: number | null,
};

export type CreateDiscoverableWorkTypesInput = {
  id?: string | null,
  workTypeGroupId: string,
  defaultVersion: number,
  _version?: number | null,
};

export type ModelDiscoverableWorkTypesConditionInput = {
  workTypeGroupId?: ModelIDInput | null,
  defaultVersion?: ModelIntInput | null,
  and?: Array< ModelDiscoverableWorkTypesConditionInput | null > | null,
  or?: Array< ModelDiscoverableWorkTypesConditionInput | null > | null,
  not?: ModelDiscoverableWorkTypesConditionInput | null,
};

export type DiscoverableWorkTypes = {
  __typename: "DiscoverableWorkTypes",
  id: string,
  workTypeGroupId: string,
  defaultVersion: number,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDiscoverableWorkTypesInput = {
  id: string,
  workTypeGroupId?: string | null,
  defaultVersion?: number | null,
  _version?: number | null,
};

export type DeleteDiscoverableWorkTypesInput = {
  id: string,
  _version?: number | null,
};

export type CreateEntityResourcePermissionInput = {
  id?: string | null,
  propertyId?: string | null,
  entityId?: string | null,
  entityType?: PermissionEntityType | null,
  resourceId?: string | null,
  resourceType?: PermissionResourceType | null,
  permissionType?: PermissionType | null,
  isGranted?: boolean | null,
  _version?: number | null,
};

export type ModelEntityResourcePermissionConditionInput = {
  propertyId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  entityType?: ModelPermissionEntityTypeInput | null,
  resourceId?: ModelIDInput | null,
  resourceType?: ModelPermissionResourceTypeInput | null,
  permissionType?: ModelPermissionTypeInput | null,
  isGranted?: ModelBooleanInput | null,
  and?: Array< ModelEntityResourcePermissionConditionInput | null > | null,
  or?: Array< ModelEntityResourcePermissionConditionInput | null > | null,
  not?: ModelEntityResourcePermissionConditionInput | null,
};

export type ModelPermissionEntityTypeInput = {
  eq?: PermissionEntityType | null,
  ne?: PermissionEntityType | null,
};

export type ModelPermissionTypeInput = {
  eq?: PermissionType | null,
  ne?: PermissionType | null,
};

export type EntityResourcePermission = {
  __typename: "EntityResourcePermission",
  id: string,
  propertyId?: string | null,
  entityId?: string | null,
  entityType?: PermissionEntityType | null,
  resourceId?: string | null,
  resourceType?: PermissionResourceType | null,
  permissionType?: PermissionType | null,
  isGranted?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateEntityResourcePermissionInput = {
  id: string,
  propertyId?: string | null,
  entityId?: string | null,
  entityType?: PermissionEntityType | null,
  resourceId?: string | null,
  resourceType?: PermissionResourceType | null,
  permissionType?: PermissionType | null,
  isGranted?: boolean | null,
  _version?: number | null,
};

export type DeleteEntityResourcePermissionInput = {
  id: string,
  _version?: number | null,
};

export type CreateEntityWorkTypeAssociationInput = {
  id?: string | null,
  entityId: string,
  entityType: WorkTypeAssociationScopeEntity,
  workTypeGroupId: string,
  workTypeVersion?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export enum WorkTypeAssociationScopeEntity {
  PROPERTY = "PROPERTY",
  USER = "USER",
  ORGANIZATION = "ORGANIZATION",
}


export type ModelEntityWorkTypeAssociationConditionInput = {
  entityId?: ModelIDInput | null,
  entityType?: ModelWorkTypeAssociationScopeEntityInput | null,
  workTypeGroupId?: ModelIDInput | null,
  workTypeVersion?: ModelIntInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelEntityWorkTypeAssociationConditionInput | null > | null,
  or?: Array< ModelEntityWorkTypeAssociationConditionInput | null > | null,
  not?: ModelEntityWorkTypeAssociationConditionInput | null,
};

export type ModelWorkTypeAssociationScopeEntityInput = {
  eq?: WorkTypeAssociationScopeEntity | null,
  ne?: WorkTypeAssociationScopeEntity | null,
};

export type EntityWorkTypeAssociation = {
  __typename: "EntityWorkTypeAssociation",
  id: string,
  entityId: string,
  entityType: WorkTypeAssociationScopeEntity,
  workTypeGroupId: string,
  workTypeVersion?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateEntityWorkTypeAssociationInput = {
  id: string,
  entityId?: string | null,
  entityType?: WorkTypeAssociationScopeEntity | null,
  workTypeGroupId?: string | null,
  workTypeVersion?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type DeleteEntityWorkTypeAssociationInput = {
  id: string,
  _version?: number | null,
};

export type CreateInspectionInput = {
  id?: string | null,
  propertyId: string,
  createdByOrganizationId: string,
  createdByUserId: string,
  startTime: number,
  endTime?: number | null,
  status: InspectionStatus,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export enum InspectionStatus {
  IN_PROGRESS = "IN_PROGRESS",
  PAUSED = "PAUSED",
  COMPLETED = "COMPLETED",
  ABANDONED = "ABANDONED",
}


export type ModelInspectionConditionInput = {
  propertyId?: ModelIDInput | null,
  createdByOrganizationId?: ModelStringInput | null,
  createdByUserId?: ModelStringInput | null,
  startTime?: ModelIntInput | null,
  endTime?: ModelIntInput | null,
  status?: ModelInspectionStatusInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelInspectionConditionInput | null > | null,
  or?: Array< ModelInspectionConditionInput | null > | null,
  not?: ModelInspectionConditionInput | null,
};

export type ModelInspectionStatusInput = {
  eq?: InspectionStatus | null,
  ne?: InspectionStatus | null,
};

export type Inspection = {
  __typename: "Inspection",
  id: string,
  propertyId: string,
  createdByOrganizationId: string,
  createdByUserId: string,
  startTime: number,
  endTime?: number | null,
  status: InspectionStatus,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateInspectionInput = {
  id: string,
  propertyId?: string | null,
  createdByOrganizationId?: string | null,
  createdByUserId?: string | null,
  startTime?: number | null,
  endTime?: number | null,
  status?: InspectionStatus | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type DeleteInspectionInput = {
  id: string,
  _version?: number | null,
};

export type CreateIssueInput = {
  id?: string | null,
  locationId?: string | null,
  propertyId?: string | null,
  inspectionId?: string | null,
  createdInSolution?: boolean | null,
  name?: string | null,
  description?: string | null,
  note?: string | null,
  tags?: string | null,
  status?: IssueStatus | null,
  clientCreationDate?: number | null,
  localLastUpdatedTime?: number | null,
  createdBy?: string | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export enum IssueStatus {
  NONE = "NONE",
  ARCHIVED = "ARCHIVED",
  UNDER_REVIEW = "UNDER_REVIEW",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  ON_HOLD = "ON_HOLD",
  COMPLETED = "COMPLETED",
}


export type ModelIssueConditionInput = {
  locationId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  inspectionId?: ModelIDInput | null,
  createdInSolution?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  note?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  status?: ModelIssueStatusInput | null,
  clientCreationDate?: ModelIntInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  createdBy?: ModelStringInput | null,
  opaqueSyncReconciliationToken?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelIssueConditionInput | null > | null,
  or?: Array< ModelIssueConditionInput | null > | null,
  not?: ModelIssueConditionInput | null,
};

export type ModelIssueStatusInput = {
  eq?: IssueStatus | null,
  ne?: IssueStatus | null,
};

export type Issue = {
  __typename: "Issue",
  id: string,
  locationId?: string | null,
  propertyId?: string | null,
  inspectionId?: string | null,
  createdInSolution?: boolean | null,
  name?: string | null,
  description?: string | null,
  note?: string | null,
  tags?: string | null,
  status?: IssueStatus | null,
  clientCreationDate?: number | null,
  localLastUpdatedTime?: number | null,
  createdBy?: string | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateIssueInput = {
  id: string,
  locationId?: string | null,
  propertyId?: string | null,
  inspectionId?: string | null,
  createdInSolution?: boolean | null,
  name?: string | null,
  description?: string | null,
  note?: string | null,
  tags?: string | null,
  status?: IssueStatus | null,
  clientCreationDate?: number | null,
  localLastUpdatedTime?: number | null,
  createdBy?: string | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export type DeleteIssueInput = {
  id: string,
  _version?: number | null,
};

export type CreateLocationInput = {
  id?: string | null,
  propertyId?: string | null,
  createdInSolution?: boolean | null,
  name?: string | null,
  tags?: string | null,
  localCreationTime?: number | null,
  localLastUpdatedTime?: number | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export type ModelLocationConditionInput = {
  propertyId?: ModelIDInput | null,
  createdInSolution?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  localCreationTime?: ModelIntInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  opaqueSyncReconciliationToken?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelLocationConditionInput | null > | null,
  or?: Array< ModelLocationConditionInput | null > | null,
  not?: ModelLocationConditionInput | null,
};

export type Location = {
  __typename: "Location",
  id: string,
  propertyId?: string | null,
  createdInSolution?: boolean | null,
  name?: string | null,
  tags?: string | null,
  localCreationTime?: number | null,
  localLastUpdatedTime?: number | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateLocationInput = {
  id: string,
  propertyId?: string | null,
  createdInSolution?: boolean | null,
  name?: string | null,
  tags?: string | null,
  localCreationTime?: number | null,
  localLastUpdatedTime?: number | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export type DeleteLocationInput = {
  id: string,
  _version?: number | null,
};

export type CreateNoteInput = {
  id?: string | null,
  parentId?: string | null,
  propertyId?: string | null,
  note?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type ModelNoteConditionInput = {
  parentId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  note?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelNoteConditionInput | null > | null,
  or?: Array< ModelNoteConditionInput | null > | null,
  not?: ModelNoteConditionInput | null,
};

export type Note = {
  __typename: "Note",
  id: string,
  parentId?: string | null,
  propertyId?: string | null,
  note?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateNoteInput = {
  id: string,
  parentId?: string | null,
  propertyId?: string | null,
  note?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type DeleteNoteInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrganizationInput = {
  id?: string | null,
  legalName: string,
  type?: OrganizationType | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export enum OrganizationType {
  CONSULTANT = "CONSULTANT",
  PROPERTY_MANAGEMENT = "PROPERTY_MANAGEMENT",
  SOLE_SOURCE = "SOLE_SOURCE",
  VENDOR = "VENDOR",
}


export type ModelOrganizationConditionInput = {
  legalName?: ModelStringInput | null,
  type?: ModelOrganizationTypeInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
};

export type ModelOrganizationTypeInput = {
  eq?: OrganizationType | null,
  ne?: OrganizationType | null,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  legalName: string,
  type?: OrganizationType | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateOrganizationInput = {
  id: string,
  legalName?: string | null,
  type?: OrganizationType | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type DeleteOrganizationInput = {
  id: string,
  _version?: number | null,
};

export type CreateProjectInput = {
  id?: string | null,
  propertyId: string,
  name: string,
  tenderNumber?: string | null,
  customer?: string | null,
  address?: string | null,
  propertyType?: string | null,
  dueDate?: string | null,
  contactName?: string | null,
  contactNumber?: string | null,
  contactEmail?: string | null,
  _version?: number | null,
};

export type ModelProjectConditionInput = {
  propertyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  tenderNumber?: ModelStringInput | null,
  customer?: ModelStringInput | null,
  address?: ModelStringInput | null,
  propertyType?: ModelStringInput | null,
  dueDate?: ModelStringInput | null,
  contactName?: ModelStringInput | null,
  contactNumber?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  and?: Array< ModelProjectConditionInput | null > | null,
  or?: Array< ModelProjectConditionInput | null > | null,
  not?: ModelProjectConditionInput | null,
};

export type Project = {
  __typename: "Project",
  id: string,
  propertyId: string,
  name: string,
  tenderNumber?: string | null,
  customer?: string | null,
  address?: string | null,
  propertyType?: string | null,
  dueDate?: string | null,
  contactName?: string | null,
  contactNumber?: string | null,
  contactEmail?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateProjectInput = {
  id: string,
  propertyId?: string | null,
  name?: string | null,
  tenderNumber?: string | null,
  customer?: string | null,
  address?: string | null,
  propertyType?: string | null,
  dueDate?: string | null,
  contactName?: string | null,
  contactNumber?: string | null,
  contactEmail?: string | null,
  _version?: number | null,
};

export type DeleteProjectInput = {
  id: string,
  _version?: number | null,
};

export type CreateProjectCounterInput = {
  id?: string | null,
  propertyId: string,
  latestProjectNumber?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type ModelProjectCounterConditionInput = {
  propertyId?: ModelIDInput | null,
  latestProjectNumber?: ModelIntInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelProjectCounterConditionInput | null > | null,
  or?: Array< ModelProjectCounterConditionInput | null > | null,
  not?: ModelProjectCounterConditionInput | null,
};

export type ProjectCounter = {
  __typename: "ProjectCounter",
  id: string,
  propertyId: string,
  latestProjectNumber?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateProjectCounterInput = {
  id: string,
  propertyId?: string | null,
  latestProjectNumber?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type DeleteProjectCounterInput = {
  id: string,
  _version?: number | null,
};

export type CreatePropertyInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  address?: string | null,
  ownerOrganizationId?: string | null,
  primaryContactNumber?: string | null,
  primaryContactEmail?: string | null,
  primaryContactName?: string | null,
  propertyNumber?: string | null,
  createdBy?: string | null,
  localLastUpdatedTime?: number | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canCreateSolutions?: string | null,
  canDelete?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export type ModelPropertyConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  address?: ModelStringInput | null,
  ownerOrganizationId?: ModelStringInput | null,
  primaryContactNumber?: ModelStringInput | null,
  primaryContactEmail?: ModelStringInput | null,
  primaryContactName?: ModelStringInput | null,
  propertyNumber?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  opaqueSyncReconciliationToken?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canCreateSolutions?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelPropertyConditionInput | null > | null,
  or?: Array< ModelPropertyConditionInput | null > | null,
  not?: ModelPropertyConditionInput | null,
};

export type Property = {
  __typename: "Property",
  id: string,
  name?: string | null,
  description?: string | null,
  address?: string | null,
  ownerOrganizationId?: string | null,
  primaryContactNumber?: string | null,
  primaryContactEmail?: string | null,
  primaryContactName?: string | null,
  propertyNumber?: string | null,
  locations?: ModelLocationConnection | null,
  createdBy?: string | null,
  localLastUpdatedTime?: number | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canCreateSolutions?: string | null,
  canDelete?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelLocationConnection = {
  __typename: "ModelLocationConnection",
  items:  Array<Location | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UpdatePropertyInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  address?: string | null,
  ownerOrganizationId?: string | null,
  primaryContactNumber?: string | null,
  primaryContactEmail?: string | null,
  primaryContactName?: string | null,
  propertyNumber?: string | null,
  createdBy?: string | null,
  localLastUpdatedTime?: number | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canCreateSolutions?: string | null,
  canDelete?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export type DeletePropertyInput = {
  id: string,
  _version?: number | null,
};

export type CreatePropertyCounterInput = {
  id?: string | null,
  _version?: number | null,
};

export type ModelPropertyCounterConditionInput = {
  and?: Array< ModelPropertyCounterConditionInput | null > | null,
  or?: Array< ModelPropertyCounterConditionInput | null > | null,
  not?: ModelPropertyCounterConditionInput | null,
};

export type PropertyCounter = {
  __typename: "PropertyCounter",
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePropertyCounterInput = {
  id: string,
  _version?: number | null,
};

export type DeletePropertyCounterInput = {
  id: string,
  _version?: number | null,
};

export type CreateProposalInput = {
  id?: string | null,
  schemaVersion?: number | null,
  designDocumentGroupId: string,
  designDocumentVersion: number,
  proposalKey: string,
  proposalNumber?: number | null,
  status?: ProposalStatus | null,
  author?: string | null,
  createdAt?: string | null,
  localLastUpdatedTime?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export enum ProposalStatus {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
}


export type ModelProposalConditionInput = {
  schemaVersion?: ModelIntInput | null,
  designDocumentGroupId?: ModelIDInput | null,
  designDocumentVersion?: ModelIntInput | null,
  proposalKey?: ModelStringInput | null,
  proposalNumber?: ModelIntInput | null,
  status?: ModelProposalStatusInput | null,
  author?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelProposalConditionInput | null > | null,
  or?: Array< ModelProposalConditionInput | null > | null,
  not?: ModelProposalConditionInput | null,
};

export type ModelProposalStatusInput = {
  eq?: ProposalStatus | null,
  ne?: ProposalStatus | null,
};

export type Proposal = {
  __typename: "Proposal",
  id: string,
  schemaVersion?: number | null,
  designDocumentGroupId: string,
  designDocumentVersion: number,
  proposalKey: string,
  proposalNumber?: number | null,
  status?: ProposalStatus | null,
  author?: string | null,
  createdAt?: string | null,
  localLastUpdatedTime?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateProposalInput = {
  id: string,
  schemaVersion?: number | null,
  designDocumentGroupId?: string | null,
  designDocumentVersion?: number | null,
  proposalKey?: string | null,
  proposalNumber?: number | null,
  status?: ProposalStatus | null,
  author?: string | null,
  createdAt?: string | null,
  localLastUpdatedTime?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export type DeleteProposalInput = {
  id: string,
  _version?: number | null,
};

export type CreateProposalCounterInput = {
  id?: string | null,
  solutionGroupId?: string | null,
  solutionVersion?: number | null,
  _version?: number | null,
};

export type ModelProposalCounterConditionInput = {
  solutionGroupId?: ModelIDInput | null,
  solutionVersion?: ModelIntInput | null,
  and?: Array< ModelProposalCounterConditionInput | null > | null,
  or?: Array< ModelProposalCounterConditionInput | null > | null,
  not?: ModelProposalCounterConditionInput | null,
};

export type ProposalCounter = {
  __typename: "ProposalCounter",
  id: string,
  solutionGroupId?: string | null,
  solutionVersion?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateProposalCounterInput = {
  id: string,
  solutionGroupId?: string | null,
  solutionVersion?: number | null,
  _version?: number | null,
};

export type DeleteProposalCounterInput = {
  id: string,
  _version?: number | null,
};

export type CreateResourceUserPermissionsInput = {
  id?: string | null,
  resourceId: string,
  userId?: string | null,
  userEmail?: string | null,
  userFullName?: string | null,
  resourceAndUserId?: string | null,
  permissions: string,
  resourceType?: ResourceType | null,
  _version?: number | null,
};

export type ModelResourceUserPermissionsConditionInput = {
  resourceId?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  userFullName?: ModelStringInput | null,
  resourceAndUserId?: ModelStringInput | null,
  permissions?: ModelStringInput | null,
  resourceType?: ModelResourceTypeInput | null,
  and?: Array< ModelResourceUserPermissionsConditionInput | null > | null,
  or?: Array< ModelResourceUserPermissionsConditionInput | null > | null,
  not?: ModelResourceUserPermissionsConditionInput | null,
};

export type ResourceUserPermissions = {
  __typename: "ResourceUserPermissions",
  id: string,
  resourceId: string,
  userId?: string | null,
  userEmail?: string | null,
  userFullName?: string | null,
  resourceAndUserId?: string | null,
  permissions: string,
  resourceType?: ResourceType | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateResourceUserPermissionsInput = {
  id: string,
  resourceId?: string | null,
  userId?: string | null,
  userEmail?: string | null,
  userFullName?: string | null,
  resourceAndUserId?: string | null,
  permissions?: string | null,
  resourceType?: ResourceType | null,
  _version?: number | null,
};

export type DeleteResourceUserPermissionsInput = {
  id: string,
  _version?: number | null,
};

export type CreateSolutionInput = {
  id?: string | null,
  propertyId?: string | null,
  projectId?: string | null,
  name?: string | null,
  status?: SolutionStatus | null,
  latestMinorVersion?: number | null,
  latestContentKey?: string | null,
  serialNumber?: number | null,
  metadata?: SolutionMetadataInput | null,
  localLastUpdatedTime?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export enum SolutionStatus {
  NEW_DOCUMENT = "NEW_DOCUMENT",
  DRAFT = "DRAFT",
  PENDING_REVIEW = "PENDING_REVIEW",
  APPROVED = "APPROVED",
  CANCELED = "CANCELED",
}


export type SolutionMetadataInput = {
  name?: string | null,
  propertyContactName?: string | null,
  propertyAddress?: string | null,
  propertyType?: string | null,
  solutionDueDate?: string | null,
  consultantName?: string | null,
  consultantPhoneNumber?: string | null,
  consultantEmail?: string | null,
  tenderingType?: SolutionTenderingType | null,
};

export enum SolutionTenderingType {
  SOLE_SOURCE = "SOLE_SOURCE",
  TENDERED = "TENDERED",
}


export type ModelSolutionConditionInput = {
  propertyId?: ModelIDInput | null,
  projectId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelSolutionStatusInput | null,
  latestMinorVersion?: ModelIntInput | null,
  latestContentKey?: ModelStringInput | null,
  serialNumber?: ModelIntInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelSolutionConditionInput | null > | null,
  or?: Array< ModelSolutionConditionInput | null > | null,
  not?: ModelSolutionConditionInput | null,
};

export type ModelSolutionStatusInput = {
  eq?: SolutionStatus | null,
  ne?: SolutionStatus | null,
};

export type Solution = {
  __typename: "Solution",
  id: string,
  propertyId?: string | null,
  projectId?: string | null,
  name?: string | null,
  status?: SolutionStatus | null,
  latestMinorVersion?: number | null,
  latestContentKey?: string | null,
  serialNumber?: number | null,
  metadata?: SolutionMetadata | null,
  localLastUpdatedTime?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type SolutionMetadata = {
  __typename: "SolutionMetadata",
  name?: string | null,
  propertyContactName?: string | null,
  propertyAddress?: string | null,
  propertyType?: string | null,
  solutionDueDate?: string | null,
  consultantName?: string | null,
  consultantPhoneNumber?: string | null,
  consultantEmail?: string | null,
  tenderingType?: SolutionTenderingType | null,
};

export type UpdateSolutionInput = {
  id: string,
  propertyId?: string | null,
  projectId?: string | null,
  name?: string | null,
  status?: SolutionStatus | null,
  latestMinorVersion?: number | null,
  latestContentKey?: string | null,
  serialNumber?: number | null,
  metadata?: SolutionMetadataInput | null,
  localLastUpdatedTime?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export type DeleteSolutionInput = {
  id: string,
  _version?: number | null,
};

export type CreateSolutionMinorVersionInput = {
  id?: string | null,
  solutionId?: string | null,
  propertyId?: string | null,
  name?: string | null,
  contentKey?: string | null,
  minorVersion?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type ModelSolutionMinorVersionConditionInput = {
  solutionId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  contentKey?: ModelStringInput | null,
  minorVersion?: ModelIntInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelSolutionMinorVersionConditionInput | null > | null,
  or?: Array< ModelSolutionMinorVersionConditionInput | null > | null,
  not?: ModelSolutionMinorVersionConditionInput | null,
};

export type SolutionMinorVersion = {
  __typename: "SolutionMinorVersion",
  id: string,
  solutionId?: string | null,
  propertyId?: string | null,
  name?: string | null,
  contentKey?: string | null,
  minorVersion?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateSolutionMinorVersionInput = {
  id: string,
  solutionId?: string | null,
  propertyId?: string | null,
  name?: string | null,
  contentKey?: string | null,
  minorVersion?: number | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type DeleteSolutionMinorVersionInput = {
  id: string,
  _version?: number | null,
};

export type CreateSolutionGroupCounterInput = {
  id?: string | null,
  propertyId?: string | null,
  _version?: number | null,
};

export type ModelSolutionGroupCounterConditionInput = {
  propertyId?: ModelIDInput | null,
  and?: Array< ModelSolutionGroupCounterConditionInput | null > | null,
  or?: Array< ModelSolutionGroupCounterConditionInput | null > | null,
  not?: ModelSolutionGroupCounterConditionInput | null,
};

export type SolutionGroupCounter = {
  __typename: "SolutionGroupCounter",
  id: string,
  propertyId?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateSolutionGroupCounterInput = {
  id: string,
  propertyId?: string | null,
  _version?: number | null,
};

export type DeleteSolutionGroupCounterInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserGroupInput = {
  id?: string | null,
  resourceId: string,
  groupType: string,
  _version?: number | null,
};

export type ModelUserGroupConditionInput = {
  resourceId?: ModelIDInput | null,
  groupType?: ModelStringInput | null,
  and?: Array< ModelUserGroupConditionInput | null > | null,
  or?: Array< ModelUserGroupConditionInput | null > | null,
  not?: ModelUserGroupConditionInput | null,
};

export type UserGroup = {
  __typename: "UserGroup",
  id: string,
  resourceId: string,
  groupType: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserGroupInput = {
  id: string,
  resourceId?: string | null,
  groupType?: string | null,
  _version?: number | null,
};

export type DeleteUserGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserGroupMemberInput = {
  id?: string | null,
  resourceId: string,
  resourceAndUserId: string,
  userId: string,
  userGroupId: string,
  _version?: number | null,
};

export type ModelUserGroupMemberConditionInput = {
  resourceId?: ModelIDInput | null,
  resourceAndUserId?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  userGroupId?: ModelIDInput | null,
  and?: Array< ModelUserGroupMemberConditionInput | null > | null,
  or?: Array< ModelUserGroupMemberConditionInput | null > | null,
  not?: ModelUserGroupMemberConditionInput | null,
};

export type UserGroupMember = {
  __typename: "UserGroupMember",
  id: string,
  resourceId: string,
  resourceAndUserId: string,
  userId: string,
  userGroupId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserGroupMemberInput = {
  id: string,
  resourceId?: string | null,
  resourceAndUserId?: string | null,
  userId?: string | null,
  userGroupId?: string | null,
  _version?: number | null,
};

export type DeleteUserGroupMemberInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserOrganizationAssociationInput = {
  id?: string | null,
  userId: string,
  organizationId: string,
  associationType: UserOrganizationAssociationType,
  permissions?: string | null,
  role?: UserOrganizationRole | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export enum UserOrganizationAssociationType {
  MEMBER_OF = "MEMBER_OF",
}


export enum UserOrganizationRole {
  ESTIMATOR = "ESTIMATOR",
  ENGINEER = "ENGINEER",
  PROPERTY_MANAGER = "PROPERTY_MANAGER",
  OWNER = "OWNER",
}


export type ModelUserOrganizationAssociationConditionInput = {
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  associationType?: ModelUserOrganizationAssociationTypeInput | null,
  permissions?: ModelStringInput | null,
  role?: ModelUserOrganizationRoleInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelUserOrganizationAssociationConditionInput | null > | null,
  or?: Array< ModelUserOrganizationAssociationConditionInput | null > | null,
  not?: ModelUserOrganizationAssociationConditionInput | null,
};

export type ModelUserOrganizationAssociationTypeInput = {
  eq?: UserOrganizationAssociationType | null,
  ne?: UserOrganizationAssociationType | null,
};

export type ModelUserOrganizationRoleInput = {
  eq?: UserOrganizationRole | null,
  ne?: UserOrganizationRole | null,
};

export type UserOrganizationAssociation = {
  __typename: "UserOrganizationAssociation",
  id: string,
  userId: string,
  organizationId: string,
  associationType: UserOrganizationAssociationType,
  permissions?: string | null,
  role?: UserOrganizationRole | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserOrganizationAssociationInput = {
  id: string,
  userId?: string | null,
  organizationId?: string | null,
  associationType?: UserOrganizationAssociationType | null,
  permissions?: string | null,
  role?: UserOrganizationRole | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type DeleteUserOrganizationAssociationInput = {
  id: string,
  _version?: number | null,
};

export type CreateWorkSpecificationInput = {
  id?: string | null,
  dimensionId?: string | null,
  issueId?: string | null,
  propertyId?: string | null,
  createdInSolution?: boolean | null,
  body?: string | null,
  description?: string | null,
  workTypeId?: string | null,
  itemNumber?: number | null,
  measurement?: MeasurementInput | null,
  version?: number | null,
  localLastUpdatedTime?: number | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export type ModelWorkSpecificationConditionInput = {
  dimensionId?: ModelIDInput | null,
  issueId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  createdInSolution?: ModelBooleanInput | null,
  body?: ModelStringInput | null,
  description?: ModelStringInput | null,
  workTypeId?: ModelIDInput | null,
  itemNumber?: ModelIntInput | null,
  version?: ModelIntInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  opaqueSyncReconciliationToken?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelWorkSpecificationConditionInput | null > | null,
  or?: Array< ModelWorkSpecificationConditionInput | null > | null,
  not?: ModelWorkSpecificationConditionInput | null,
};

export type WorkSpecification = {
  __typename: "WorkSpecification",
  id: string,
  dimensionId?: string | null,
  issueId?: string | null,
  propertyId?: string | null,
  createdInSolution?: boolean | null,
  body?: string | null,
  description?: string | null,
  workTypeId?: string | null,
  itemNumber?: number | null,
  measurement?: Measurement | null,
  version?: number | null,
  localLastUpdatedTime?: number | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateWorkSpecificationInput = {
  id: string,
  dimensionId?: string | null,
  issueId?: string | null,
  propertyId?: string | null,
  createdInSolution?: boolean | null,
  body?: string | null,
  description?: string | null,
  workTypeId?: string | null,
  itemNumber?: number | null,
  measurement?: MeasurementInput | null,
  version?: number | null,
  localLastUpdatedTime?: number | null,
  opaqueSyncReconciliationToken?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdBy?: string | null,
  canViewList?: Array< string | null > | null,
  canViewEntityVersionMap?: string | null,
  _version?: number | null,
};

export type DeleteWorkSpecificationInput = {
  id: string,
  _version?: number | null,
};

export type CreateWorkTypeInput = {
  id?: string | null,
  name: string,
  shortDescription?: string | null,
  longDescription?: string | null,
  groupName?: string | null,
  unitPrice?: number | null,
  unitCost?: number | null,
  dimensionType?: DimensionType | null,
  measurementUnit?: MeasurementUnit | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type ModelWorkTypeConditionInput = {
  name?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  longDescription?: ModelStringInput | null,
  groupName?: ModelStringInput | null,
  unitPrice?: ModelFloatInput | null,
  unitCost?: ModelFloatInput | null,
  dimensionType?: ModelDimensionTypeInput | null,
  measurementUnit?: ModelMeasurementUnitInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelWorkTypeConditionInput | null > | null,
  or?: Array< ModelWorkTypeConditionInput | null > | null,
  not?: ModelWorkTypeConditionInput | null,
};

export type WorkType = {
  __typename: "WorkType",
  id: string,
  name: string,
  shortDescription?: string | null,
  longDescription?: string | null,
  groupName?: string | null,
  unitPrice?: number | null,
  unitCost?: number | null,
  dimensionType?: DimensionType | null,
  measurementUnit?: MeasurementUnit | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateWorkTypeInput = {
  id: string,
  name?: string | null,
  shortDescription?: string | null,
  longDescription?: string | null,
  groupName?: string | null,
  unitPrice?: number | null,
  unitCost?: number | null,
  dimensionType?: DimensionType | null,
  measurementUnit?: MeasurementUnit | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type DeleteWorkTypeInput = {
  id: string,
  _version?: number | null,
};

export type CreateWorkTypeGroupInput = {
  id?: string | null,
  latestWorkTypeVersion: number,
  environmentAgnosticWorkTypeGroupId?: string | null,
  isDiscoverable?: boolean | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type ModelWorkTypeGroupConditionInput = {
  latestWorkTypeVersion?: ModelIntInput | null,
  environmentAgnosticWorkTypeGroupId?: ModelIDInput | null,
  isDiscoverable?: ModelBooleanInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelWorkTypeGroupConditionInput | null > | null,
  or?: Array< ModelWorkTypeGroupConditionInput | null > | null,
  not?: ModelWorkTypeGroupConditionInput | null,
};

export type WorkTypeGroup = {
  __typename: "WorkTypeGroup",
  id: string,
  latestWorkTypeVersion: number,
  environmentAgnosticWorkTypeGroupId?: string | null,
  isDiscoverable?: boolean | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateWorkTypeGroupInput = {
  id: string,
  latestWorkTypeVersion?: number | null,
  environmentAgnosticWorkTypeGroupId?: string | null,
  isDiscoverable?: boolean | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type DeleteWorkTypeGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateWorkTypePricingInput = {
  id?: string | null,
  entityId: string,
  workTypeGroupId: string,
  body: string,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type ModelWorkTypePricingConditionInput = {
  entityId?: ModelIDInput | null,
  workTypeGroupId?: ModelIDInput | null,
  body?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelWorkTypePricingConditionInput | null > | null,
  or?: Array< ModelWorkTypePricingConditionInput | null > | null,
  not?: ModelWorkTypePricingConditionInput | null,
};

export type WorkTypePricing = {
  __typename: "WorkTypePricing",
  id: string,
  entityId: string,
  workTypeGroupId: string,
  body: string,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateWorkTypePricingInput = {
  id: string,
  entityId?: string | null,
  workTypeGroupId?: string | null,
  body?: string | null,
  canView?: string | null,
  canEdit?: string | null,
  canDelete?: string | null,
  _version?: number | null,
};

export type DeleteWorkTypePricingInput = {
  id: string,
  _version?: number | null,
};

export type CreateDefaultWorkTypePricingInput = {
  id?: string | null,
  unitCost?: number | null,
  unitPrice?: number | null,
  _version?: number | null,
};

export type ModelDefaultWorkTypePricingConditionInput = {
  unitCost?: ModelFloatInput | null,
  unitPrice?: ModelFloatInput | null,
  and?: Array< ModelDefaultWorkTypePricingConditionInput | null > | null,
  or?: Array< ModelDefaultWorkTypePricingConditionInput | null > | null,
  not?: ModelDefaultWorkTypePricingConditionInput | null,
};

export type DefaultWorkTypePricing = {
  __typename: "DefaultWorkTypePricing",
  id: string,
  unitCost?: number | null,
  unitPrice?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDefaultWorkTypePricingInput = {
  id: string,
  unitCost?: number | null,
  unitPrice?: number | null,
  _version?: number | null,
};

export type DeleteDefaultWorkTypePricingInput = {
  id: string,
  _version?: number | null,
};

export type CreateTemporaryWorkTypeInput = {
  id?: string | null,
  workTypeGroupId: string,
  environmentAgnosticWorkTypeGroupId?: string | null,
  body: string,
  version: number,
  schemaVersion: number,
  _version?: number | null,
};

export type ModelTemporaryWorkTypeConditionInput = {
  workTypeGroupId?: ModelIDInput | null,
  environmentAgnosticWorkTypeGroupId?: ModelIDInput | null,
  body?: ModelStringInput | null,
  version?: ModelIntInput | null,
  schemaVersion?: ModelIntInput | null,
  and?: Array< ModelTemporaryWorkTypeConditionInput | null > | null,
  or?: Array< ModelTemporaryWorkTypeConditionInput | null > | null,
  not?: ModelTemporaryWorkTypeConditionInput | null,
};

export type TemporaryWorkType = {
  __typename: "TemporaryWorkType",
  id: string,
  workTypeGroupId: string,
  environmentAgnosticWorkTypeGroupId?: string | null,
  body: string,
  version: number,
  schemaVersion: number,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTemporaryWorkTypeInput = {
  id: string,
  workTypeGroupId?: string | null,
  environmentAgnosticWorkTypeGroupId?: string | null,
  body?: string | null,
  version?: number | null,
  schemaVersion?: number | null,
  _version?: number | null,
};

export type DeleteTemporaryWorkTypeInput = {
  id: string,
  _version?: number | null,
};

export type CreateTenderInput = {
  id?: string | null,
  solutionId?: string | null,
  status?: TenderStatus | null,
  latestVersion?: number | null,
  awardedVersion?: number | null,
  projectNumber?: number | null,
  createdBy?: string | null,
  _version?: number | null,
};

export enum TenderStatus {
  PUBLISHED = "PUBLISHED",
  AWARDED = "AWARDED",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
  CLOSED = "CLOSED",
}


export type ModelTenderConditionInput = {
  solutionId?: ModelStringInput | null,
  status?: ModelTenderStatusInput | null,
  latestVersion?: ModelIntInput | null,
  awardedVersion?: ModelIntInput | null,
  projectNumber?: ModelIntInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelTenderConditionInput | null > | null,
  or?: Array< ModelTenderConditionInput | null > | null,
  not?: ModelTenderConditionInput | null,
};

export type ModelTenderStatusInput = {
  eq?: TenderStatus | null,
  ne?: TenderStatus | null,
};

export type Tender = {
  __typename: "Tender",
  id: string,
  solutionId?: string | null,
  status?: TenderStatus | null,
  latestVersion?: number | null,
  awardedVersion?: number | null,
  projectNumber?: number | null,
  createdBy?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTenderInput = {
  id: string,
  solutionId?: string | null,
  status?: TenderStatus | null,
  latestVersion?: number | null,
  awardedVersion?: number | null,
  projectNumber?: number | null,
  createdBy?: string | null,
  _version?: number | null,
};

export type DeleteTenderInput = {
  id: string,
  _version?: number | null,
};

export type CreateTenderMinorVersionInput = {
  id?: string | null,
  tenderId?: string | null,
  version?: number | null,
  solutionId?: string | null,
  solutionMinorVersion?: number | null,
  metadata?: TenderMetadataInput | null,
  contentKey?: string | null,
  _version?: number | null,
};

export type TenderMetadataInput = {
  name?: string | null,
  propertyContactName?: string | null,
  propertyAddress?: string | null,
  propertyType?: string | null,
  solutionDueDate?: string | null,
  consultantName?: string | null,
  consultantPhoneNumber?: string | null,
  consultantEmail?: string | null,
};

export type ModelTenderMinorVersionConditionInput = {
  tenderId?: ModelStringInput | null,
  version?: ModelIntInput | null,
  solutionId?: ModelStringInput | null,
  solutionMinorVersion?: ModelIntInput | null,
  contentKey?: ModelStringInput | null,
  and?: Array< ModelTenderMinorVersionConditionInput | null > | null,
  or?: Array< ModelTenderMinorVersionConditionInput | null > | null,
  not?: ModelTenderMinorVersionConditionInput | null,
};

export type TenderMinorVersion = {
  __typename: "TenderMinorVersion",
  id: string,
  tenderId?: string | null,
  version?: number | null,
  solutionId?: string | null,
  solutionMinorVersion?: number | null,
  metadata?: TenderMetadata | null,
  contentKey?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type TenderMetadata = {
  __typename: "TenderMetadata",
  name?: string | null,
  propertyContactName?: string | null,
  propertyAddress?: string | null,
  propertyType?: string | null,
  solutionDueDate?: string | null,
  consultantName?: string | null,
  consultantPhoneNumber?: string | null,
  consultantEmail?: string | null,
};

export type UpdateTenderMinorVersionInput = {
  id: string,
  tenderId?: string | null,
  version?: number | null,
  solutionId?: string | null,
  solutionMinorVersion?: number | null,
  metadata?: TenderMetadataInput | null,
  contentKey?: string | null,
  _version?: number | null,
};

export type DeleteTenderMinorVersionInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserOnboardingProgressInput = {
  id?: string | null,
  userId: string,
  hasUserBeenAssociatedWithOrganization: boolean,
  _version?: number | null,
};

export type ModelUserOnboardingProgressConditionInput = {
  userId?: ModelIDInput | null,
  hasUserBeenAssociatedWithOrganization?: ModelBooleanInput | null,
  and?: Array< ModelUserOnboardingProgressConditionInput | null > | null,
  or?: Array< ModelUserOnboardingProgressConditionInput | null > | null,
  not?: ModelUserOnboardingProgressConditionInput | null,
};

export type UserOnboardingProgress = {
  __typename: "UserOnboardingProgress",
  id: string,
  userId: string,
  hasUserBeenAssociatedWithOrganization: boolean,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserOnboardingProgressInput = {
  id: string,
  userId?: string | null,
  hasUserBeenAssociatedWithOrganization?: boolean | null,
  _version?: number | null,
};

export type DeleteUserOnboardingProgressInput = {
  id: string,
  _version?: number | null,
};

export type CreateTemplateInput = {
  id?: string | null,
  type?: TemplateType | null,
  name?: string | null,
  s3Key?: string | null,
  ownerId?: string | null,
  ownerType?: TemplateEntityType | null,
  _version?: number | null,
};

export enum TemplateType {
  SOLUTION = "SOLUTION",
}


export enum TemplateEntityType {
  USER = "USER",
  TEAM = "TEAM",
}


export type ModelTemplateConditionInput = {
  type?: ModelTemplateTypeInput | null,
  name?: ModelStringInput | null,
  s3Key?: ModelStringInput | null,
  ownerId?: ModelStringInput | null,
  ownerType?: ModelTemplateEntityTypeInput | null,
  and?: Array< ModelTemplateConditionInput | null > | null,
  or?: Array< ModelTemplateConditionInput | null > | null,
  not?: ModelTemplateConditionInput | null,
};

export type ModelTemplateTypeInput = {
  eq?: TemplateType | null,
  ne?: TemplateType | null,
};

export type ModelTemplateEntityTypeInput = {
  eq?: TemplateEntityType | null,
  ne?: TemplateEntityType | null,
};

export type Template = {
  __typename: "Template",
  id: string,
  type?: TemplateType | null,
  name?: string | null,
  s3Key?: string | null,
  ownerId?: string | null,
  ownerType?: TemplateEntityType | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTemplateInput = {
  id: string,
  type?: TemplateType | null,
  name?: string | null,
  s3Key?: string | null,
  ownerId?: string | null,
  ownerType?: TemplateEntityType | null,
  _version?: number | null,
};

export type DeleteTemplateInput = {
  id: string,
  _version?: number | null,
};

export type CreateTemporaryAuthorizationCodeInput = {
  id?: string | null,
  code?: string | null,
  userId?: string | null,
  expiresAt?: number | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelTemporaryAuthorizationCodeConditionInput = {
  code?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  expiresAt?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTemporaryAuthorizationCodeConditionInput | null > | null,
  or?: Array< ModelTemporaryAuthorizationCodeConditionInput | null > | null,
  not?: ModelTemporaryAuthorizationCodeConditionInput | null,
};

export type TemporaryAuthorizationCode = {
  __typename: "TemporaryAuthorizationCode",
  id: string,
  code?: string | null,
  userId?: string | null,
  expiresAt?: number | null,
  createdAt?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTemporaryAuthorizationCodeInput = {
  id: string,
  code?: string | null,
  userId?: string | null,
  expiresAt?: number | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteTemporaryAuthorizationCodeInput = {
  id: string,
  _version?: number | null,
};

export type CreateLinkRegistryInput = {
  id?: string | null,
  action?: LinkAction | null,
  variables?: string | null,
  createdBy?: string | null,
  _version?: number | null,
};

export enum LinkAction {
  ISSUE_FORM = "ISSUE_FORM",
  PROPERTY_FORM = "PROPERTY_FORM",
  EXTERNAL_REPORT = "EXTERNAL_REPORT",
  UNASSIGNED = "UNASSIGNED",
}


export type ModelLinkRegistryConditionInput = {
  action?: ModelLinkActionInput | null,
  variables?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  and?: Array< ModelLinkRegistryConditionInput | null > | null,
  or?: Array< ModelLinkRegistryConditionInput | null > | null,
  not?: ModelLinkRegistryConditionInput | null,
};

export type ModelLinkActionInput = {
  eq?: LinkAction | null,
  ne?: LinkAction | null,
};

export type LinkRegistry = {
  __typename: "LinkRegistry",
  id: string,
  action?: LinkAction | null,
  variables?: string | null,
  createdBy?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateLinkRegistryInput = {
  id: string,
  action?: LinkAction | null,
  variables?: string | null,
  createdBy?: string | null,
  _version?: number | null,
};

export type DeleteLinkRegistryInput = {
  id: string,
  _version?: number | null,
};

export type CreateExternalReportBrandingInput = {
  id?: string | null,
  name?: string | null,
  logoKey?: string | null,
  createdBy?: string | null,
  _version?: number | null,
};

export type ModelExternalReportBrandingConditionInput = {
  name?: ModelStringInput | null,
  logoKey?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  and?: Array< ModelExternalReportBrandingConditionInput | null > | null,
  or?: Array< ModelExternalReportBrandingConditionInput | null > | null,
  not?: ModelExternalReportBrandingConditionInput | null,
};

export type ExternalReportBranding = {
  __typename: "ExternalReportBranding",
  id: string,
  name?: string | null,
  logoKey?: string | null,
  createdBy?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateExternalReportBrandingInput = {
  id: string,
  name?: string | null,
  logoKey?: string | null,
  createdBy?: string | null,
  _version?: number | null,
};

export type DeleteExternalReportBrandingInput = {
  id: string,
  _version?: number | null,
};

export type CreateMeetingInput = {
  id?: string | null,
  status?: MeetingStatus | null,
  data: string,
  createdBy?: string | null,
  mediaPipelineId?: string | null,
  recordingKey?: string | null,
  recordingAttachmentId?: string | null,
  _version?: number | null,
};

export enum MeetingStatus {
  ACTIVE = "ACTIVE",
  ENDED = "ENDED",
}


export type ModelMeetingConditionInput = {
  status?: ModelMeetingStatusInput | null,
  data?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  mediaPipelineId?: ModelStringInput | null,
  recordingKey?: ModelStringInput | null,
  recordingAttachmentId?: ModelStringInput | null,
  and?: Array< ModelMeetingConditionInput | null > | null,
  or?: Array< ModelMeetingConditionInput | null > | null,
  not?: ModelMeetingConditionInput | null,
};

export type ModelMeetingStatusInput = {
  eq?: MeetingStatus | null,
  ne?: MeetingStatus | null,
};

export type Meeting = {
  __typename: "Meeting",
  id: string,
  status?: MeetingStatus | null,
  data: string,
  createdBy?: string | null,
  mediaPipelineId?: string | null,
  recordingKey?: string | null,
  recordingAttachmentId?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateMeetingInput = {
  id: string,
  status?: MeetingStatus | null,
  data?: string | null,
  createdBy?: string | null,
  mediaPipelineId?: string | null,
  recordingKey?: string | null,
  recordingAttachmentId?: string | null,
  _version?: number | null,
};

export type DeleteMeetingInput = {
  id: string,
  _version?: number | null,
};

export type CreateAttendeeInput = {
  id?: string | null,
  meetingId: string,
  entityId?: string | null,
  name?: string | null,
  role?: AttendeeRole | null,
  _version?: number | null,
};

export type ModelAttendeeConditionInput = {
  meetingId?: ModelIDInput | null,
  entityId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  role?: ModelAttendeeRoleInput | null,
  and?: Array< ModelAttendeeConditionInput | null > | null,
  or?: Array< ModelAttendeeConditionInput | null > | null,
  not?: ModelAttendeeConditionInput | null,
};

export type ModelAttendeeRoleInput = {
  eq?: AttendeeRole | null,
  ne?: AttendeeRole | null,
};

export type Attendee = {
  __typename: "Attendee",
  id: string,
  meetingId: string,
  entityId?: string | null,
  name?: string | null,
  role?: AttendeeRole | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAttendeeInput = {
  id: string,
  meetingId?: string | null,
  entityId?: string | null,
  name?: string | null,
  role?: AttendeeRole | null,
  _version?: number | null,
};

export type DeleteAttendeeInput = {
  id: string,
  _version?: number | null,
};

export type CreateTemporaryAuthorizationTokenEventInput = {
  userId: string,
  expiresAt: number,
};

export type CreateTemporaryAuthorizationTokenResponse = {
  __typename: "CreateTemporaryAuthorizationTokenResponse",
  status: ResponseStatus,
  authCode?: string | null,
  expiresAt?: number | null,
};

export enum ResponseStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}


export type ExternalReportSubmission = {
  type?: ExternalReportType | null,
  summary?: string | null,
  description?: string | null,
  imageKeys?: Array< string | null > | null,
  propertyId?: string | null,
  locationId?: string | null,
  locationName?: string | null,
  measurement?: ExternalReportSubmissionMeasurement | null,
  submissionTime?: number | null,
  contactInfo?: ExternalReportSubmissionContactInfo | null,
};

export enum ExternalReportType {
  ISSUE = "ISSUE",
  ASSESSMENT = "ASSESSMENT",
}


export type ExternalReportSubmissionMeasurement = {
  length?: number | null,
  width?: number | null,
  depth?: number | null,
  dimensionType?: DimensionType | null,
  measurementUnit?: MeasurementUnit | null,
};

export type ExternalReportSubmissionContactInfo = {
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  emailOptIn?: boolean | null,
  phoneNumberOptIn?: boolean | null,
};

export type GenerateReportRequest = {
  resourceId: string,
  resourceType: GenerateReportResourceType,
  templateId: string,
  parameters?: string | null,
};

export enum GenerateReportResourceType {
  SOLE_SOURCE = "SOLE_SOURCE",
  BID = "BID",
  TENDER_SUMMARY = "TENDER_SUMMARY",
}


export type sendNotificationToUsersInput = {
  recipients: Array< string | null >,
  title?: string | null,
  body: string,
  channel?: NotificationChannel | null,
};

export enum NotificationChannel {
  EMAIL = "EMAIL",
  SMS = "SMS",
}


export type UpdateBidStatusInput = {
  bidId: string,
  bidMinorVersion: number,
  tenderId: string,
  tenderMinorVersion: number,
  status: BidStatus,
};

export type ShareMeetingLinkInput = {
  linkType: MeetingLinkType,
  meetingId: string,
  channel: NotificationChannel,
  recipients: Array< string | null >,
  propertyId?: string | null,
};

export enum MeetingLinkType {
  INSPECTOR = "INSPECTOR",
  AGENT = "AGENT",
}


export type ListResourceAggregateUserPermissionsOutput = {
  __typename: "ListResourceAggregateUserPermissionsOutput",
  items?:  Array<AggregateUserPermissions | null > | null,
  nextToken?: string | null,
};

export type AggregateUserPermissions = {
  __typename: "AggregateUserPermissions",
  userId?: string | null,
  username?: string | null,
  permissions?: Array< PermissionType | null > | null,
};

export type HandleConverseCommandInput = {
  converseCommand: string,
};

export type ModelAttachmentFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelAttachmentTypeInput | null,
  parentId?: ModelIDInput | null,
  parentType?: ModelPermissionResourceTypeInput | null,
  propertyId?: ModelIDInput | null,
  key?: ModelStringInput | null,
  localCreatedAtTime?: ModelIntInput | null,
  status?: ModelStringInput | null,
  opaqueSyncReconciliationToken?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelAttachmentFilterInput | null > | null,
  or?: Array< ModelAttachmentFilterInput | null > | null,
  not?: ModelAttachmentFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAttachmentConnection = {
  __typename: "ModelAttachmentConnection",
  items:  Array<Attachment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBiddingContactFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  ownerEntityId?: ModelStringInput | null,
  contactEntityId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  privateDescription?: ModelStringInput | null,
  publicDescription?: ModelStringInput | null,
  and?: Array< ModelBiddingContactFilterInput | null > | null,
  or?: Array< ModelBiddingContactFilterInput | null > | null,
  not?: ModelBiddingContactFilterInput | null,
};

export type ModelBiddingContactConnection = {
  __typename: "ModelBiddingContactConnection",
  items:  Array<BiddingContact | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBidInvitationFilterInput = {
  id?: ModelIDInput | null,
  biddingContactId?: ModelStringInput | null,
  tenderId?: ModelStringInput | null,
  ownerEntityId?: ModelStringInput | null,
  and?: Array< ModelBidInvitationFilterInput | null > | null,
  or?: Array< ModelBidInvitationFilterInput | null > | null,
  not?: ModelBidInvitationFilterInput | null,
};

export type ModelBidInvitationConnection = {
  __typename: "ModelBidInvitationConnection",
  items:  Array<BidInvitation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBidFilterInput = {
  id?: ModelIDInput | null,
  tenderId?: ModelStringInput | null,
  latestMinorVersion?: ModelIntInput | null,
  latestAcceptedMinorVersion?: ModelIntInput | null,
  latestSubmittedMinorVersion?: ModelIntInput | null,
  status?: ModelBidStatusInput | null,
  bidNumber?: ModelIntInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelBidFilterInput | null > | null,
  or?: Array< ModelBidFilterInput | null > | null,
  not?: ModelBidFilterInput | null,
};

export type ModelBidConnection = {
  __typename: "ModelBidConnection",
  items:  Array<Bid | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBidMinorVersionFilterInput = {
  id?: ModelIDInput | null,
  bidId?: ModelStringInput | null,
  minorVersion?: ModelIntInput | null,
  tenderId?: ModelStringInput | null,
  tenderMinorVersion?: ModelIntInput | null,
  proposalId?: ModelStringInput | null,
  status?: ModelDocumentStatusInput | null,
  and?: Array< ModelBidMinorVersionFilterInput | null > | null,
  or?: Array< ModelBidMinorVersionFilterInput | null > | null,
  not?: ModelBidMinorVersionFilterInput | null,
};

export type ModelBidMinorVersionConnection = {
  __typename: "ModelBidMinorVersionConnection",
  items:  Array<BidMinorVersion | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelClientLogFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  userAgent?: ModelStringInput | null,
  severityLevel?: ModelLogSeverityLevelInput | null,
  componentName?: ModelStringInput | null,
  message?: ModelStringInput | null,
  details?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  and?: Array< ModelClientLogFilterInput | null > | null,
  or?: Array< ModelClientLogFilterInput | null > | null,
  not?: ModelClientLogFilterInput | null,
};

export type ModelClientLogConnection = {
  __typename: "ModelClientLogConnection",
  items:  Array<ClientLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCounterFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelResourceTypeInput | null,
  and?: Array< ModelCounterFilterInput | null > | null,
  or?: Array< ModelCounterFilterInput | null > | null,
  not?: ModelCounterFilterInput | null,
};

export type ModelCounterConnection = {
  __typename: "ModelCounterConnection",
  items:  Array<Counter | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDesignDocumentFilterInput = {
  id?: ModelIDInput | null,
  designDocumentGroupId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  projectId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelDesignDocumentStatusInput | null,
  lastEvaluatedValidityStatus?: ModelBooleanInput | null,
  uploadStatus?: ModelStringInput | null,
  documentKey?: ModelStringInput | null,
  lastWorkScopeSpecificationItemNumber?: ModelIntInput | null,
  version?: ModelIntInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelDesignDocumentFilterInput | null > | null,
  or?: Array< ModelDesignDocumentFilterInput | null > | null,
  not?: ModelDesignDocumentFilterInput | null,
};

export type ModelDesignDocumentConnection = {
  __typename: "ModelDesignDocumentConnection",
  items:  Array<DesignDocument | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelDesignDocumentGroupFilterInput = {
  id?: ModelIDInput | null,
  projectId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  latestDocumentVersion?: ModelIntInput | null,
  documentContentKey?: ModelStringInput | null,
  solutionGroupNumber?: ModelIntInput | null,
  isLocked?: ModelBooleanInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelDesignDocumentGroupFilterInput | null > | null,
  or?: Array< ModelDesignDocumentGroupFilterInput | null > | null,
  not?: ModelDesignDocumentGroupFilterInput | null,
};

export type ModelDesignDocumentGroupConnection = {
  __typename: "ModelDesignDocumentGroupConnection",
  items:  Array<DesignDocumentGroup | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDimensionFilterInput = {
  id?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  createdInSolution?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  length?: ModelFloatInput | null,
  lengthUnit?: ModelStringInput | null,
  width?: ModelFloatInput | null,
  widthUnit?: ModelStringInput | null,
  dimensionType?: ModelDimensionTypeInput | null,
  measurementUnit?: ModelMeasurementUnitInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  opaqueSyncReconciliationToken?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelDimensionFilterInput | null > | null,
  or?: Array< ModelDimensionFilterInput | null > | null,
  not?: ModelDimensionFilterInput | null,
};

export type ModelDimensionConnection = {
  __typename: "ModelDimensionConnection",
  items:  Array<Dimension | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDiscoverableWorkTypesFilterInput = {
  id?: ModelIDInput | null,
  workTypeGroupId?: ModelIDInput | null,
  defaultVersion?: ModelIntInput | null,
  and?: Array< ModelDiscoverableWorkTypesFilterInput | null > | null,
  or?: Array< ModelDiscoverableWorkTypesFilterInput | null > | null,
  not?: ModelDiscoverableWorkTypesFilterInput | null,
};

export type ModelDiscoverableWorkTypesConnection = {
  __typename: "ModelDiscoverableWorkTypesConnection",
  items:  Array<DiscoverableWorkTypes | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelEntityResourcePermissionFilterInput = {
  id?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  entityType?: ModelPermissionEntityTypeInput | null,
  resourceId?: ModelIDInput | null,
  resourceType?: ModelPermissionResourceTypeInput | null,
  permissionType?: ModelPermissionTypeInput | null,
  isGranted?: ModelBooleanInput | null,
  and?: Array< ModelEntityResourcePermissionFilterInput | null > | null,
  or?: Array< ModelEntityResourcePermissionFilterInput | null > | null,
  not?: ModelEntityResourcePermissionFilterInput | null,
};

export type ModelEntityResourcePermissionConnection = {
  __typename: "ModelEntityResourcePermissionConnection",
  items:  Array<EntityResourcePermission | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelEntityWorkTypeAssociationFilterInput = {
  id?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  entityType?: ModelWorkTypeAssociationScopeEntityInput | null,
  workTypeGroupId?: ModelIDInput | null,
  workTypeVersion?: ModelIntInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelEntityWorkTypeAssociationFilterInput | null > | null,
  or?: Array< ModelEntityWorkTypeAssociationFilterInput | null > | null,
  not?: ModelEntityWorkTypeAssociationFilterInput | null,
};

export type ModelEntityWorkTypeAssociationConnection = {
  __typename: "ModelEntityWorkTypeAssociationConnection",
  items:  Array<EntityWorkTypeAssociation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInspectionFilterInput = {
  id?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  createdByOrganizationId?: ModelStringInput | null,
  createdByUserId?: ModelStringInput | null,
  startTime?: ModelIntInput | null,
  endTime?: ModelIntInput | null,
  status?: ModelInspectionStatusInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelInspectionFilterInput | null > | null,
  or?: Array< ModelInspectionFilterInput | null > | null,
  not?: ModelInspectionFilterInput | null,
};

export type ModelInspectionConnection = {
  __typename: "ModelInspectionConnection",
  items:  Array<Inspection | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIssueFilterInput = {
  id?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  inspectionId?: ModelIDInput | null,
  createdInSolution?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  note?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  status?: ModelIssueStatusInput | null,
  clientCreationDate?: ModelIntInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  createdBy?: ModelStringInput | null,
  opaqueSyncReconciliationToken?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelIssueFilterInput | null > | null,
  or?: Array< ModelIssueFilterInput | null > | null,
  not?: ModelIssueFilterInput | null,
};

export type ModelIssueConnection = {
  __typename: "ModelIssueConnection",
  items:  Array<Issue | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLocationFilterInput = {
  id?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  createdInSolution?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  localCreationTime?: ModelIntInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  opaqueSyncReconciliationToken?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelLocationFilterInput | null > | null,
  or?: Array< ModelLocationFilterInput | null > | null,
  not?: ModelLocationFilterInput | null,
};

export type ModelNoteFilterInput = {
  id?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  note?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelNoteFilterInput | null > | null,
  or?: Array< ModelNoteFilterInput | null > | null,
  not?: ModelNoteFilterInput | null,
};

export type ModelNoteConnection = {
  __typename: "ModelNoteConnection",
  items:  Array<Note | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  legalName?: ModelStringInput | null,
  type?: ModelOrganizationTypeInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelProjectFilterInput = {
  id?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  tenderNumber?: ModelStringInput | null,
  customer?: ModelStringInput | null,
  address?: ModelStringInput | null,
  propertyType?: ModelStringInput | null,
  dueDate?: ModelStringInput | null,
  contactName?: ModelStringInput | null,
  contactNumber?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  and?: Array< ModelProjectFilterInput | null > | null,
  or?: Array< ModelProjectFilterInput | null > | null,
  not?: ModelProjectFilterInput | null,
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection",
  items:  Array<Project | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelProjectCounterFilterInput = {
  id?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  latestProjectNumber?: ModelIntInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelProjectCounterFilterInput | null > | null,
  or?: Array< ModelProjectCounterFilterInput | null > | null,
  not?: ModelProjectCounterFilterInput | null,
};

export type ModelProjectCounterConnection = {
  __typename: "ModelProjectCounterConnection",
  items:  Array<ProjectCounter | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPropertyFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  address?: ModelStringInput | null,
  ownerOrganizationId?: ModelStringInput | null,
  primaryContactNumber?: ModelStringInput | null,
  primaryContactEmail?: ModelStringInput | null,
  primaryContactName?: ModelStringInput | null,
  propertyNumber?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  opaqueSyncReconciliationToken?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canCreateSolutions?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelPropertyFilterInput | null > | null,
  or?: Array< ModelPropertyFilterInput | null > | null,
  not?: ModelPropertyFilterInput | null,
};

export type ModelPropertyConnection = {
  __typename: "ModelPropertyConnection",
  items:  Array<Property | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPropertyCounterFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelPropertyCounterFilterInput | null > | null,
  or?: Array< ModelPropertyCounterFilterInput | null > | null,
  not?: ModelPropertyCounterFilterInput | null,
};

export type ModelPropertyCounterConnection = {
  __typename: "ModelPropertyCounterConnection",
  items:  Array<PropertyCounter | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelProposalFilterInput = {
  id?: ModelIDInput | null,
  schemaVersion?: ModelIntInput | null,
  designDocumentGroupId?: ModelIDInput | null,
  designDocumentVersion?: ModelIntInput | null,
  proposalKey?: ModelStringInput | null,
  proposalNumber?: ModelIntInput | null,
  status?: ModelProposalStatusInput | null,
  author?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelProposalFilterInput | null > | null,
  or?: Array< ModelProposalFilterInput | null > | null,
  not?: ModelProposalFilterInput | null,
};

export type ModelProposalConnection = {
  __typename: "ModelProposalConnection",
  items:  Array<Proposal | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelProposalCounterFilterInput = {
  id?: ModelIDInput | null,
  solutionGroupId?: ModelIDInput | null,
  solutionVersion?: ModelIntInput | null,
  and?: Array< ModelProposalCounterFilterInput | null > | null,
  or?: Array< ModelProposalCounterFilterInput | null > | null,
  not?: ModelProposalCounterFilterInput | null,
};

export type ModelProposalCounterConnection = {
  __typename: "ModelProposalCounterConnection",
  items:  Array<ProposalCounter | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelResourceUserPermissionsFilterInput = {
  id?: ModelIDInput | null,
  resourceId?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  userFullName?: ModelStringInput | null,
  resourceAndUserId?: ModelStringInput | null,
  permissions?: ModelStringInput | null,
  resourceType?: ModelResourceTypeInput | null,
  and?: Array< ModelResourceUserPermissionsFilterInput | null > | null,
  or?: Array< ModelResourceUserPermissionsFilterInput | null > | null,
  not?: ModelResourceUserPermissionsFilterInput | null,
};

export type ModelResourceUserPermissionsConnection = {
  __typename: "ModelResourceUserPermissionsConnection",
  items:  Array<ResourceUserPermissions | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSolutionFilterInput = {
  id?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  projectId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelSolutionStatusInput | null,
  latestMinorVersion?: ModelIntInput | null,
  latestContentKey?: ModelStringInput | null,
  serialNumber?: ModelIntInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelSolutionFilterInput | null > | null,
  or?: Array< ModelSolutionFilterInput | null > | null,
  not?: ModelSolutionFilterInput | null,
};

export type ModelSolutionConnection = {
  __typename: "ModelSolutionConnection",
  items:  Array<Solution | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSolutionMinorVersionFilterInput = {
  id?: ModelIDInput | null,
  solutionId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  contentKey?: ModelStringInput | null,
  minorVersion?: ModelIntInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelSolutionMinorVersionFilterInput | null > | null,
  or?: Array< ModelSolutionMinorVersionFilterInput | null > | null,
  not?: ModelSolutionMinorVersionFilterInput | null,
};

export type ModelSolutionMinorVersionConnection = {
  __typename: "ModelSolutionMinorVersionConnection",
  items:  Array<SolutionMinorVersion | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSolutionGroupCounterFilterInput = {
  id?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  and?: Array< ModelSolutionGroupCounterFilterInput | null > | null,
  or?: Array< ModelSolutionGroupCounterFilterInput | null > | null,
  not?: ModelSolutionGroupCounterFilterInput | null,
};

export type ModelSolutionGroupCounterConnection = {
  __typename: "ModelSolutionGroupCounterConnection",
  items:  Array<SolutionGroupCounter | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserGroupFilterInput = {
  id?: ModelIDInput | null,
  resourceId?: ModelIDInput | null,
  groupType?: ModelStringInput | null,
  and?: Array< ModelUserGroupFilterInput | null > | null,
  or?: Array< ModelUserGroupFilterInput | null > | null,
  not?: ModelUserGroupFilterInput | null,
};

export type ModelUserGroupConnection = {
  __typename: "ModelUserGroupConnection",
  items:  Array<UserGroup | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserGroupMemberFilterInput = {
  id?: ModelIDInput | null,
  resourceId?: ModelIDInput | null,
  resourceAndUserId?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  userGroupId?: ModelIDInput | null,
  and?: Array< ModelUserGroupMemberFilterInput | null > | null,
  or?: Array< ModelUserGroupMemberFilterInput | null > | null,
  not?: ModelUserGroupMemberFilterInput | null,
};

export type ModelUserGroupMemberConnection = {
  __typename: "ModelUserGroupMemberConnection",
  items:  Array<UserGroupMember | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserOrganizationAssociationFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  associationType?: ModelUserOrganizationAssociationTypeInput | null,
  permissions?: ModelStringInput | null,
  role?: ModelUserOrganizationRoleInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelUserOrganizationAssociationFilterInput | null > | null,
  or?: Array< ModelUserOrganizationAssociationFilterInput | null > | null,
  not?: ModelUserOrganizationAssociationFilterInput | null,
};

export type ModelUserOrganizationAssociationConnection = {
  __typename: "ModelUserOrganizationAssociationConnection",
  items:  Array<UserOrganizationAssociation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelWorkSpecificationFilterInput = {
  id?: ModelIDInput | null,
  dimensionId?: ModelIDInput | null,
  issueId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  createdInSolution?: ModelBooleanInput | null,
  body?: ModelStringInput | null,
  description?: ModelStringInput | null,
  workTypeId?: ModelIDInput | null,
  itemNumber?: ModelIntInput | null,
  version?: ModelIntInput | null,
  localLastUpdatedTime?: ModelIntInput | null,
  opaqueSyncReconciliationToken?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  canViewList?: ModelStringInput | null,
  canViewEntityVersionMap?: ModelStringInput | null,
  and?: Array< ModelWorkSpecificationFilterInput | null > | null,
  or?: Array< ModelWorkSpecificationFilterInput | null > | null,
  not?: ModelWorkSpecificationFilterInput | null,
};

export type ModelWorkSpecificationConnection = {
  __typename: "ModelWorkSpecificationConnection",
  items:  Array<WorkSpecification | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelWorkTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  longDescription?: ModelStringInput | null,
  groupName?: ModelStringInput | null,
  unitPrice?: ModelFloatInput | null,
  unitCost?: ModelFloatInput | null,
  dimensionType?: ModelDimensionTypeInput | null,
  measurementUnit?: ModelMeasurementUnitInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelWorkTypeFilterInput | null > | null,
  or?: Array< ModelWorkTypeFilterInput | null > | null,
  not?: ModelWorkTypeFilterInput | null,
};

export type ModelWorkTypeConnection = {
  __typename: "ModelWorkTypeConnection",
  items:  Array<WorkType | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelWorkTypeGroupFilterInput = {
  id?: ModelIDInput | null,
  latestWorkTypeVersion?: ModelIntInput | null,
  environmentAgnosticWorkTypeGroupId?: ModelIDInput | null,
  isDiscoverable?: ModelBooleanInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelWorkTypeGroupFilterInput | null > | null,
  or?: Array< ModelWorkTypeGroupFilterInput | null > | null,
  not?: ModelWorkTypeGroupFilterInput | null,
};

export type ModelWorkTypeGroupConnection = {
  __typename: "ModelWorkTypeGroupConnection",
  items:  Array<WorkTypeGroup | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelWorkTypePricingFilterInput = {
  id?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  workTypeGroupId?: ModelIDInput | null,
  body?: ModelStringInput | null,
  canView?: ModelStringInput | null,
  canEdit?: ModelStringInput | null,
  canDelete?: ModelStringInput | null,
  and?: Array< ModelWorkTypePricingFilterInput | null > | null,
  or?: Array< ModelWorkTypePricingFilterInput | null > | null,
  not?: ModelWorkTypePricingFilterInput | null,
};

export type ModelWorkTypePricingConnection = {
  __typename: "ModelWorkTypePricingConnection",
  items:  Array<WorkTypePricing | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDefaultWorkTypePricingFilterInput = {
  id?: ModelIDInput | null,
  unitCost?: ModelFloatInput | null,
  unitPrice?: ModelFloatInput | null,
  and?: Array< ModelDefaultWorkTypePricingFilterInput | null > | null,
  or?: Array< ModelDefaultWorkTypePricingFilterInput | null > | null,
  not?: ModelDefaultWorkTypePricingFilterInput | null,
};

export type ModelDefaultWorkTypePricingConnection = {
  __typename: "ModelDefaultWorkTypePricingConnection",
  items:  Array<DefaultWorkTypePricing | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTemporaryWorkTypeFilterInput = {
  id?: ModelIDInput | null,
  workTypeGroupId?: ModelIDInput | null,
  environmentAgnosticWorkTypeGroupId?: ModelIDInput | null,
  body?: ModelStringInput | null,
  version?: ModelIntInput | null,
  schemaVersion?: ModelIntInput | null,
  and?: Array< ModelTemporaryWorkTypeFilterInput | null > | null,
  or?: Array< ModelTemporaryWorkTypeFilterInput | null > | null,
  not?: ModelTemporaryWorkTypeFilterInput | null,
};

export type ModelTemporaryWorkTypeConnection = {
  __typename: "ModelTemporaryWorkTypeConnection",
  items:  Array<TemporaryWorkType | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTenderFilterInput = {
  id?: ModelIDInput | null,
  solutionId?: ModelStringInput | null,
  status?: ModelTenderStatusInput | null,
  latestVersion?: ModelIntInput | null,
  awardedVersion?: ModelIntInput | null,
  projectNumber?: ModelIntInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelTenderFilterInput | null > | null,
  or?: Array< ModelTenderFilterInput | null > | null,
  not?: ModelTenderFilterInput | null,
};

export type ModelTenderConnection = {
  __typename: "ModelTenderConnection",
  items:  Array<Tender | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTenderMinorVersionFilterInput = {
  id?: ModelIDInput | null,
  tenderId?: ModelStringInput | null,
  version?: ModelIntInput | null,
  solutionId?: ModelStringInput | null,
  solutionMinorVersion?: ModelIntInput | null,
  contentKey?: ModelStringInput | null,
  and?: Array< ModelTenderMinorVersionFilterInput | null > | null,
  or?: Array< ModelTenderMinorVersionFilterInput | null > | null,
  not?: ModelTenderMinorVersionFilterInput | null,
};

export type ModelTenderMinorVersionConnection = {
  __typename: "ModelTenderMinorVersionConnection",
  items:  Array<TenderMinorVersion | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserOnboardingProgressFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  hasUserBeenAssociatedWithOrganization?: ModelBooleanInput | null,
  and?: Array< ModelUserOnboardingProgressFilterInput | null > | null,
  or?: Array< ModelUserOnboardingProgressFilterInput | null > | null,
  not?: ModelUserOnboardingProgressFilterInput | null,
};

export type ModelUserOnboardingProgressConnection = {
  __typename: "ModelUserOnboardingProgressConnection",
  items:  Array<UserOnboardingProgress | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type User = {
  __typename: "User",
  id: string,
  name?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTemplateFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelTemplateTypeInput | null,
  name?: ModelStringInput | null,
  s3Key?: ModelStringInput | null,
  ownerId?: ModelStringInput | null,
  ownerType?: ModelTemplateEntityTypeInput | null,
  and?: Array< ModelTemplateFilterInput | null > | null,
  or?: Array< ModelTemplateFilterInput | null > | null,
  not?: ModelTemplateFilterInput | null,
};

export type ModelTemplateConnection = {
  __typename: "ModelTemplateConnection",
  items:  Array<Template | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTemporaryAuthorizationCodeFilterInput = {
  id?: ModelIDInput | null,
  code?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  expiresAt?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTemporaryAuthorizationCodeFilterInput | null > | null,
  or?: Array< ModelTemporaryAuthorizationCodeFilterInput | null > | null,
  not?: ModelTemporaryAuthorizationCodeFilterInput | null,
};

export type ModelTemporaryAuthorizationCodeConnection = {
  __typename: "ModelTemporaryAuthorizationCodeConnection",
  items:  Array<TemporaryAuthorizationCode | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLinkRegistryFilterInput = {
  id?: ModelIDInput | null,
  action?: ModelLinkActionInput | null,
  variables?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  and?: Array< ModelLinkRegistryFilterInput | null > | null,
  or?: Array< ModelLinkRegistryFilterInput | null > | null,
  not?: ModelLinkRegistryFilterInput | null,
};

export type ModelLinkRegistryConnection = {
  __typename: "ModelLinkRegistryConnection",
  items:  Array<LinkRegistry | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelExternalReportBrandingFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  logoKey?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  and?: Array< ModelExternalReportBrandingFilterInput | null > | null,
  or?: Array< ModelExternalReportBrandingFilterInput | null > | null,
  not?: ModelExternalReportBrandingFilterInput | null,
};

export type ModelExternalReportBrandingConnection = {
  __typename: "ModelExternalReportBrandingConnection",
  items:  Array<ExternalReportBranding | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMeetingFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelMeetingStatusInput | null,
  data?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  mediaPipelineId?: ModelStringInput | null,
  recordingKey?: ModelStringInput | null,
  recordingAttachmentId?: ModelStringInput | null,
  and?: Array< ModelMeetingFilterInput | null > | null,
  or?: Array< ModelMeetingFilterInput | null > | null,
  not?: ModelMeetingFilterInput | null,
};

export type ModelMeetingConnection = {
  __typename: "ModelMeetingConnection",
  items:  Array<Meeting | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAttendeeFilterInput = {
  id?: ModelIDInput | null,
  meetingId?: ModelIDInput | null,
  entityId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  role?: ModelAttendeeRoleInput | null,
  and?: Array< ModelAttendeeFilterInput | null > | null,
  or?: Array< ModelAttendeeFilterInput | null > | null,
  not?: ModelAttendeeFilterInput | null,
};

export type ModelAttendeeConnection = {
  __typename: "ModelAttendeeConnection",
  items:  Array<Attendee | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type GetPreSignedUploadURLRequest = {
  executionId: string,
};

export type GetPreSignedDownloadRequest = {
  executionId: string,
  key: string,
  location: S3DownloadLocation,
};

export enum S3DownloadLocation {
  LOGO = "LOGO",
}


export type GetAccountClaimStatusResponse = {
  __typename: "GetAccountClaimStatusResponse",
  status?: AccountClaimStatus | null,
};

export enum AccountClaimStatus {
  CLAIMED = "CLAIMED",
  UNCLAIMED = "UNCLAIMED",
}


export type SearchUsersVariables = {
  filter?: SearchableUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SearchableUserFilterInput = {
  id?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  phoneNumber?: SearchableStringFilterInput | null,
  and?: Array< SearchableUserFilterInput | null > | null,
  or?: Array< SearchableUserFilterInput | null > | null,
  not?: SearchableUserFilterInput | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  eq?: string | null,
  contains?: string | null,
};

export type SearchUsersOutput = {
  __typename: "SearchUsersOutput",
  items?:  Array<User | null > | null,
  nextToken?: string | null,
};

export type SearchOrganizationsVariables = {
  filter?: SearchableOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SearchableOrganizationFilterInput = {
  id?: SearchableStringFilterInput | null,
  legalName?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  and?: Array< SearchableOrganizationFilterInput | null > | null,
  or?: Array< SearchableOrganizationFilterInput | null > | null,
  not?: SearchableOrganizationFilterInput | null,
};

export type SearchOrganizationsOutput = {
  __typename: "SearchOrganizationsOutput",
  items?:  Array<Organization | null > | null,
  nextToken?: string | null,
};

export type ModelSubscriptionAttachmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  parentId?: ModelSubscriptionIDInput | null,
  parentType?: ModelSubscriptionStringInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  key?: ModelSubscriptionStringInput | null,
  localCreatedAtTime?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  opaqueSyncReconciliationToken?: ModelSubscriptionStringInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  canViewList?: ModelSubscriptionStringInput | null,
  canViewEntityVersionMap?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBiddingContactFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  ownerEntityId?: ModelSubscriptionStringInput | null,
  contactEntityId?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  privateDescription?: ModelSubscriptionStringInput | null,
  publicDescription?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBiddingContactFilterInput | null > | null,
  or?: Array< ModelSubscriptionBiddingContactFilterInput | null > | null,
};

export type ModelSubscriptionBidInvitationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  biddingContactId?: ModelSubscriptionStringInput | null,
  tenderId?: ModelSubscriptionStringInput | null,
  ownerEntityId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBidInvitationFilterInput | null > | null,
  or?: Array< ModelSubscriptionBidInvitationFilterInput | null > | null,
};

export type ModelSubscriptionBidFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenderId?: ModelSubscriptionStringInput | null,
  latestMinorVersion?: ModelSubscriptionIntInput | null,
  latestAcceptedMinorVersion?: ModelSubscriptionIntInput | null,
  latestSubmittedMinorVersion?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  bidNumber?: ModelSubscriptionIntInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBidFilterInput | null > | null,
  or?: Array< ModelSubscriptionBidFilterInput | null > | null,
};

export type ModelSubscriptionBidMinorVersionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  bidId?: ModelSubscriptionStringInput | null,
  minorVersion?: ModelSubscriptionIntInput | null,
  tenderId?: ModelSubscriptionStringInput | null,
  tenderMinorVersion?: ModelSubscriptionIntInput | null,
  proposalId?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBidMinorVersionFilterInput | null > | null,
  or?: Array< ModelSubscriptionBidMinorVersionFilterInput | null > | null,
};

export type ModelSubscriptionClientLogFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionStringInput | null,
  userAgent?: ModelSubscriptionStringInput | null,
  severityLevel?: ModelSubscriptionStringInput | null,
  componentName?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  details?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClientLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionClientLogFilterInput | null > | null,
};

export type ModelSubscriptionCounterFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCounterFilterInput | null > | null,
  or?: Array< ModelSubscriptionCounterFilterInput | null > | null,
};

export type ModelSubscriptionDesignDocumentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  designDocumentGroupId?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  lastEvaluatedValidityStatus?: ModelSubscriptionBooleanInput | null,
  uploadStatus?: ModelSubscriptionStringInput | null,
  documentKey?: ModelSubscriptionStringInput | null,
  lastWorkScopeSpecificationItemNumber?: ModelSubscriptionIntInput | null,
  version?: ModelSubscriptionIntInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDesignDocumentFilterInput | null > | null,
  or?: Array< ModelSubscriptionDesignDocumentFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionDesignDocumentGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  latestDocumentVersion?: ModelSubscriptionIntInput | null,
  documentContentKey?: ModelSubscriptionStringInput | null,
  solutionGroupNumber?: ModelSubscriptionIntInput | null,
  isLocked?: ModelSubscriptionBooleanInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDesignDocumentGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionDesignDocumentGroupFilterInput | null > | null,
};

export type ModelSubscriptionDimensionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  parentId?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  createdInSolution?: ModelSubscriptionBooleanInput | null,
  description?: ModelSubscriptionStringInput | null,
  length?: ModelSubscriptionFloatInput | null,
  lengthUnit?: ModelSubscriptionStringInput | null,
  width?: ModelSubscriptionFloatInput | null,
  widthUnit?: ModelSubscriptionStringInput | null,
  dimensionType?: ModelSubscriptionStringInput | null,
  measurementUnit?: ModelSubscriptionStringInput | null,
  localLastUpdatedTime?: ModelSubscriptionIntInput | null,
  opaqueSyncReconciliationToken?: ModelSubscriptionStringInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  canViewList?: ModelSubscriptionStringInput | null,
  canViewEntityVersionMap?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDimensionFilterInput | null > | null,
  or?: Array< ModelSubscriptionDimensionFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionDiscoverableWorkTypesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  workTypeGroupId?: ModelSubscriptionIDInput | null,
  defaultVersion?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionDiscoverableWorkTypesFilterInput | null > | null,
  or?: Array< ModelSubscriptionDiscoverableWorkTypesFilterInput | null > | null,
};

export type ModelSubscriptionEntityResourcePermissionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  entityId?: ModelSubscriptionIDInput | null,
  entityType?: ModelSubscriptionStringInput | null,
  resourceId?: ModelSubscriptionIDInput | null,
  resourceType?: ModelSubscriptionStringInput | null,
  permissionType?: ModelSubscriptionStringInput | null,
  isGranted?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionEntityResourcePermissionFilterInput | null > | null,
  or?: Array< ModelSubscriptionEntityResourcePermissionFilterInput | null > | null,
};

export type ModelSubscriptionEntityWorkTypeAssociationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  entityId?: ModelSubscriptionIDInput | null,
  entityType?: ModelSubscriptionStringInput | null,
  workTypeGroupId?: ModelSubscriptionIDInput | null,
  workTypeVersion?: ModelSubscriptionIntInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEntityWorkTypeAssociationFilterInput | null > | null,
  or?: Array< ModelSubscriptionEntityWorkTypeAssociationFilterInput | null > | null,
};

export type ModelSubscriptionInspectionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  createdByOrganizationId?: ModelSubscriptionStringInput | null,
  createdByUserId?: ModelSubscriptionStringInput | null,
  startTime?: ModelSubscriptionIntInput | null,
  endTime?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInspectionFilterInput | null > | null,
  or?: Array< ModelSubscriptionInspectionFilterInput | null > | null,
};

export type ModelSubscriptionIssueFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  locationId?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  inspectionId?: ModelSubscriptionIDInput | null,
  createdInSolution?: ModelSubscriptionBooleanInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  clientCreationDate?: ModelSubscriptionIntInput | null,
  localLastUpdatedTime?: ModelSubscriptionIntInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  opaqueSyncReconciliationToken?: ModelSubscriptionStringInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  canViewList?: ModelSubscriptionStringInput | null,
  canViewEntityVersionMap?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIssueFilterInput | null > | null,
  or?: Array< ModelSubscriptionIssueFilterInput | null > | null,
};

export type ModelSubscriptionLocationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  createdInSolution?: ModelSubscriptionBooleanInput | null,
  name?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  localCreationTime?: ModelSubscriptionIntInput | null,
  localLastUpdatedTime?: ModelSubscriptionIntInput | null,
  opaqueSyncReconciliationToken?: ModelSubscriptionStringInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  canViewList?: ModelSubscriptionStringInput | null,
  canViewEntityVersionMap?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLocationFilterInput | null > | null,
  or?: Array< ModelSubscriptionLocationFilterInput | null > | null,
};

export type ModelSubscriptionNoteFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  parentId?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  note?: ModelSubscriptionStringInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNoteFilterInput | null > | null,
  or?: Array< ModelSubscriptionNoteFilterInput | null > | null,
};

export type ModelSubscriptionOrganizationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  legalName?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
};

export type ModelSubscriptionProjectFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  tenderNumber?: ModelSubscriptionStringInput | null,
  customer?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  propertyType?: ModelSubscriptionStringInput | null,
  dueDate?: ModelSubscriptionStringInput | null,
  contactName?: ModelSubscriptionStringInput | null,
  contactNumber?: ModelSubscriptionStringInput | null,
  contactEmail?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectFilterInput | null > | null,
};

export type ModelSubscriptionProjectCounterFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  latestProjectNumber?: ModelSubscriptionIntInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectCounterFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectCounterFilterInput | null > | null,
};

export type ModelSubscriptionPropertyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  ownerOrganizationId?: ModelSubscriptionStringInput | null,
  primaryContactNumber?: ModelSubscriptionStringInput | null,
  primaryContactEmail?: ModelSubscriptionStringInput | null,
  primaryContactName?: ModelSubscriptionStringInput | null,
  propertyNumber?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  localLastUpdatedTime?: ModelSubscriptionIntInput | null,
  opaqueSyncReconciliationToken?: ModelSubscriptionStringInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canCreateSolutions?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  canViewList?: ModelSubscriptionStringInput | null,
  canViewEntityVersionMap?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPropertyFilterInput | null > | null,
  or?: Array< ModelSubscriptionPropertyFilterInput | null > | null,
};

export type ModelSubscriptionPropertyCounterFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPropertyCounterFilterInput | null > | null,
  or?: Array< ModelSubscriptionPropertyCounterFilterInput | null > | null,
};

export type ModelSubscriptionProposalFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  schemaVersion?: ModelSubscriptionIntInput | null,
  designDocumentGroupId?: ModelSubscriptionIDInput | null,
  designDocumentVersion?: ModelSubscriptionIntInput | null,
  proposalKey?: ModelSubscriptionStringInput | null,
  proposalNumber?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  author?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  localLastUpdatedTime?: ModelSubscriptionIntInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  canViewList?: ModelSubscriptionStringInput | null,
  canViewEntityVersionMap?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProposalFilterInput | null > | null,
  or?: Array< ModelSubscriptionProposalFilterInput | null > | null,
};

export type ModelSubscriptionProposalCounterFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  solutionGroupId?: ModelSubscriptionIDInput | null,
  solutionVersion?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionProposalCounterFilterInput | null > | null,
  or?: Array< ModelSubscriptionProposalCounterFilterInput | null > | null,
};

export type ModelSubscriptionResourceUserPermissionsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  resourceId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionStringInput | null,
  userEmail?: ModelSubscriptionStringInput | null,
  userFullName?: ModelSubscriptionStringInput | null,
  resourceAndUserId?: ModelSubscriptionStringInput | null,
  permissions?: ModelSubscriptionStringInput | null,
  resourceType?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionResourceUserPermissionsFilterInput | null > | null,
  or?: Array< ModelSubscriptionResourceUserPermissionsFilterInput | null > | null,
};

export type ModelSubscriptionSolutionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  latestMinorVersion?: ModelSubscriptionIntInput | null,
  latestContentKey?: ModelSubscriptionStringInput | null,
  serialNumber?: ModelSubscriptionIntInput | null,
  localLastUpdatedTime?: ModelSubscriptionIntInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  canViewList?: ModelSubscriptionStringInput | null,
  canViewEntityVersionMap?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSolutionFilterInput | null > | null,
  or?: Array< ModelSubscriptionSolutionFilterInput | null > | null,
};

export type ModelSubscriptionSolutionMinorVersionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  solutionId?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  contentKey?: ModelSubscriptionStringInput | null,
  minorVersion?: ModelSubscriptionIntInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSolutionMinorVersionFilterInput | null > | null,
  or?: Array< ModelSubscriptionSolutionMinorVersionFilterInput | null > | null,
};

export type ModelSubscriptionSolutionGroupCounterFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionSolutionGroupCounterFilterInput | null > | null,
  or?: Array< ModelSubscriptionSolutionGroupCounterFilterInput | null > | null,
};

export type ModelSubscriptionUserGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  resourceId?: ModelSubscriptionIDInput | null,
  groupType?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserGroupFilterInput | null > | null,
};

export type ModelSubscriptionUserGroupMemberFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  resourceId?: ModelSubscriptionIDInput | null,
  resourceAndUserId?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionStringInput | null,
  userGroupId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionUserGroupMemberFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserGroupMemberFilterInput | null > | null,
};

export type ModelSubscriptionUserOrganizationAssociationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  associationType?: ModelSubscriptionStringInput | null,
  permissions?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserOrganizationAssociationFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserOrganizationAssociationFilterInput | null > | null,
};

export type ModelSubscriptionWorkSpecificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  dimensionId?: ModelSubscriptionIDInput | null,
  issueId?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  createdInSolution?: ModelSubscriptionBooleanInput | null,
  body?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  workTypeId?: ModelSubscriptionIDInput | null,
  itemNumber?: ModelSubscriptionIntInput | null,
  version?: ModelSubscriptionIntInput | null,
  localLastUpdatedTime?: ModelSubscriptionIntInput | null,
  opaqueSyncReconciliationToken?: ModelSubscriptionStringInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  canViewList?: ModelSubscriptionStringInput | null,
  canViewEntityVersionMap?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWorkSpecificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionWorkSpecificationFilterInput | null > | null,
};

export type ModelSubscriptionWorkTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  shortDescription?: ModelSubscriptionStringInput | null,
  longDescription?: ModelSubscriptionStringInput | null,
  groupName?: ModelSubscriptionStringInput | null,
  unitPrice?: ModelSubscriptionFloatInput | null,
  unitCost?: ModelSubscriptionFloatInput | null,
  dimensionType?: ModelSubscriptionStringInput | null,
  measurementUnit?: ModelSubscriptionStringInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWorkTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionWorkTypeFilterInput | null > | null,
};

export type ModelSubscriptionWorkTypeGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  latestWorkTypeVersion?: ModelSubscriptionIntInput | null,
  environmentAgnosticWorkTypeGroupId?: ModelSubscriptionIDInput | null,
  isDiscoverable?: ModelSubscriptionBooleanInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWorkTypeGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionWorkTypeGroupFilterInput | null > | null,
};

export type ModelSubscriptionWorkTypePricingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  entityId?: ModelSubscriptionIDInput | null,
  workTypeGroupId?: ModelSubscriptionIDInput | null,
  body?: ModelSubscriptionStringInput | null,
  canView?: ModelSubscriptionStringInput | null,
  canEdit?: ModelSubscriptionStringInput | null,
  canDelete?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWorkTypePricingFilterInput | null > | null,
  or?: Array< ModelSubscriptionWorkTypePricingFilterInput | null > | null,
};

export type ModelSubscriptionDefaultWorkTypePricingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  unitCost?: ModelSubscriptionFloatInput | null,
  unitPrice?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionDefaultWorkTypePricingFilterInput | null > | null,
  or?: Array< ModelSubscriptionDefaultWorkTypePricingFilterInput | null > | null,
};

export type ModelSubscriptionTemporaryWorkTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  workTypeGroupId?: ModelSubscriptionIDInput | null,
  environmentAgnosticWorkTypeGroupId?: ModelSubscriptionIDInput | null,
  body?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionIntInput | null,
  schemaVersion?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionTemporaryWorkTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionTemporaryWorkTypeFilterInput | null > | null,
};

export type ModelSubscriptionTenderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  solutionId?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  latestVersion?: ModelSubscriptionIntInput | null,
  awardedVersion?: ModelSubscriptionIntInput | null,
  projectNumber?: ModelSubscriptionIntInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTenderFilterInput | null > | null,
  or?: Array< ModelSubscriptionTenderFilterInput | null > | null,
};

export type ModelSubscriptionTenderMinorVersionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenderId?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionIntInput | null,
  solutionId?: ModelSubscriptionStringInput | null,
  solutionMinorVersion?: ModelSubscriptionIntInput | null,
  contentKey?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTenderMinorVersionFilterInput | null > | null,
  or?: Array< ModelSubscriptionTenderMinorVersionFilterInput | null > | null,
};

export type ModelSubscriptionUserOnboardingProgressFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  hasUserBeenAssociatedWithOrganization?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionUserOnboardingProgressFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserOnboardingProgressFilterInput | null > | null,
};

export type ModelSubscriptionTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  s3Key?: ModelSubscriptionStringInput | null,
  ownerId?: ModelSubscriptionStringInput | null,
  ownerType?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionTemplateFilterInput | null > | null,
};

export type ModelSubscriptionTemporaryAuthorizationCodeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  code?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionStringInput | null,
  expiresAt?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTemporaryAuthorizationCodeFilterInput | null > | null,
  or?: Array< ModelSubscriptionTemporaryAuthorizationCodeFilterInput | null > | null,
};

export type ModelSubscriptionLinkRegistryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  action?: ModelSubscriptionStringInput | null,
  variables?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionLinkRegistryFilterInput | null > | null,
  or?: Array< ModelSubscriptionLinkRegistryFilterInput | null > | null,
};

export type ModelSubscriptionExternalReportBrandingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  logoKey?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionExternalReportBrandingFilterInput | null > | null,
  or?: Array< ModelSubscriptionExternalReportBrandingFilterInput | null > | null,
};

export type ModelSubscriptionMeetingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  data?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  mediaPipelineId?: ModelSubscriptionStringInput | null,
  recordingKey?: ModelSubscriptionStringInput | null,
  recordingAttachmentId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMeetingFilterInput | null > | null,
  or?: Array< ModelSubscriptionMeetingFilterInput | null > | null,
};

export type ModelSubscriptionAttendeeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  meetingId?: ModelSubscriptionIDInput | null,
  entityId?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAttendeeFilterInput | null > | null,
  or?: Array< ModelSubscriptionAttendeeFilterInput | null > | null,
};

export type CreateSolutionEventMutationVariables = {
  input: CreateSolutionEventInput,
};

export type CreateSolutionEventMutation = {
  createSolutionEvent?: string | null,
};

export type CreateBidInvitationEventMutationVariables = {
  input: CreateBidInvitationEventInput,
};

export type CreateBidInvitationEventMutation = {
  createBidInvitationEvent?: string | null,
};

export type ShareResourcePermissionMutationVariables = {
  executionId: string,
  input: Array< ManageResourcePermissionInput | null >,
};

export type ShareResourcePermissionMutation = {
  shareResourcePermission?:  {
    __typename: "CompleteEvent",
    executionId: string,
    status: CompleteEventStatus,
    result?: string | null,
  } | null,
};

export type RevokeResourcePermissionMutationVariables = {
  executionId: string,
  input: Array< ManageResourcePermissionInput | null >,
};

export type RevokeResourcePermissionMutation = {
  revokeResourcePermission?:  {
    __typename: "CompleteEvent",
    executionId: string,
    status: CompleteEventStatus,
    result?: string | null,
  } | null,
};

export type ResendTemporaryCredentialsMutationVariables = {
  input?: ResendTemporaryCredentialsRequest | null,
};

export type ResendTemporaryCredentialsMutation = {
  resendTemporaryCredentials?: string | null,
};

export type CreateChimeMeetingMutationVariables = {
  name: string,
  role: AttendeeRole,
};

export type CreateChimeMeetingMutation = {
  createChimeMeeting?:  {
    __typename: "ChimeMeetingOutput",
    meetingInfo: string,
    attendeeInfo: string,
  } | null,
};

export type JoinChimeMeetingMutationVariables = {
  meetingId: string,
  name: string,
  role: AttendeeRole,
};

export type JoinChimeMeetingMutation = {
  joinChimeMeeting?:  {
    __typename: "ChimeMeetingOutput",
    meetingInfo: string,
    attendeeInfo: string,
  } | null,
};

export type EndChimeMeetingMutationVariables = {
  meetingId: string,
};

export type EndChimeMeetingMutation = {
  endChimeMeeting?: string | null,
};

export type StartChimeMeetingRecordingMutationVariables = {
  meetingId: string,
  attachmentParentResourceType?: PermissionResourceType | null,
  attachmentParentId?: string | null,
};

export type StartChimeMeetingRecordingMutation = {
  startChimeMeetingRecording?: string | null,
};

export type CreateAttachmentMutationVariables = {
  input: CreateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type CreateAttachmentMutation = {
  createAttachment?:  {
    __typename: "Attachment",
    id: string,
    type?: AttachmentType | null,
    parentId: string,
    parentType?: PermissionResourceType | null,
    propertyId?: string | null,
    key?: string | null,
    localCreatedAtTime?: number | null,
    status?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAttachmentMutationVariables = {
  input: UpdateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type UpdateAttachmentMutation = {
  updateAttachment?:  {
    __typename: "Attachment",
    id: string,
    type?: AttachmentType | null,
    parentId: string,
    parentType?: PermissionResourceType | null,
    propertyId?: string | null,
    key?: string | null,
    localCreatedAtTime?: number | null,
    status?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAttachmentMutationVariables = {
  input: DeleteAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type DeleteAttachmentMutation = {
  deleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    type?: AttachmentType | null,
    parentId: string,
    parentType?: PermissionResourceType | null,
    propertyId?: string | null,
    key?: string | null,
    localCreatedAtTime?: number | null,
    status?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBiddingContactMutationVariables = {
  input: CreateBiddingContactInput,
  condition?: ModelBiddingContactConditionInput | null,
};

export type CreateBiddingContactMutation = {
  createBiddingContact?:  {
    __typename: "BiddingContact",
    id: string,
    email: string,
    ownerEntityId?: string | null,
    contactEntityId?: string | null,
    name?: string | null,
    privateDescription?: string | null,
    publicDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBiddingContactMutationVariables = {
  input: UpdateBiddingContactInput,
  condition?: ModelBiddingContactConditionInput | null,
};

export type UpdateBiddingContactMutation = {
  updateBiddingContact?:  {
    __typename: "BiddingContact",
    id: string,
    email: string,
    ownerEntityId?: string | null,
    contactEntityId?: string | null,
    name?: string | null,
    privateDescription?: string | null,
    publicDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBiddingContactMutationVariables = {
  input: DeleteBiddingContactInput,
  condition?: ModelBiddingContactConditionInput | null,
};

export type DeleteBiddingContactMutation = {
  deleteBiddingContact?:  {
    __typename: "BiddingContact",
    id: string,
    email: string,
    ownerEntityId?: string | null,
    contactEntityId?: string | null,
    name?: string | null,
    privateDescription?: string | null,
    publicDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBidInvitationMutationVariables = {
  input: CreateBidInvitationInput,
  condition?: ModelBidInvitationConditionInput | null,
};

export type CreateBidInvitationMutation = {
  createBidInvitation?:  {
    __typename: "BidInvitation",
    id: string,
    biddingContactId?: string | null,
    tenderId?: string | null,
    ownerEntityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBidInvitationMutationVariables = {
  input: UpdateBidInvitationInput,
  condition?: ModelBidInvitationConditionInput | null,
};

export type UpdateBidInvitationMutation = {
  updateBidInvitation?:  {
    __typename: "BidInvitation",
    id: string,
    biddingContactId?: string | null,
    tenderId?: string | null,
    ownerEntityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBidInvitationMutationVariables = {
  input: DeleteBidInvitationInput,
  condition?: ModelBidInvitationConditionInput | null,
};

export type DeleteBidInvitationMutation = {
  deleteBidInvitation?:  {
    __typename: "BidInvitation",
    id: string,
    biddingContactId?: string | null,
    tenderId?: string | null,
    ownerEntityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBidMutationVariables = {
  input: CreateBidInput,
  condition?: ModelBidConditionInput | null,
};

export type CreateBidMutation = {
  createBid?:  {
    __typename: "Bid",
    id: string,
    tenderId?: string | null,
    latestMinorVersion?: number | null,
    latestAcceptedMinorVersion?: number | null,
    latestSubmittedMinorVersion?: number | null,
    status?: BidStatus | null,
    bidNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBidMutationVariables = {
  input: UpdateBidInput,
  condition?: ModelBidConditionInput | null,
};

export type UpdateBidMutation = {
  updateBid?:  {
    __typename: "Bid",
    id: string,
    tenderId?: string | null,
    latestMinorVersion?: number | null,
    latestAcceptedMinorVersion?: number | null,
    latestSubmittedMinorVersion?: number | null,
    status?: BidStatus | null,
    bidNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBidMutationVariables = {
  input: DeleteBidInput,
  condition?: ModelBidConditionInput | null,
};

export type DeleteBidMutation = {
  deleteBid?:  {
    __typename: "Bid",
    id: string,
    tenderId?: string | null,
    latestMinorVersion?: number | null,
    latestAcceptedMinorVersion?: number | null,
    latestSubmittedMinorVersion?: number | null,
    status?: BidStatus | null,
    bidNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBidMinorVersionMutationVariables = {
  input: CreateBidMinorVersionInput,
  condition?: ModelBidMinorVersionConditionInput | null,
};

export type CreateBidMinorVersionMutation = {
  createBidMinorVersion?:  {
    __typename: "BidMinorVersion",
    id: string,
    bidId?: string | null,
    minorVersion?: number | null,
    tenderId?: string | null,
    tenderMinorVersion?: number | null,
    proposalId?: string | null,
    status?: DocumentStatus | null,
    metadata?:  {
      __typename: "BidMetadata",
      bidderContactName?: string | null,
      bidderContactEmail?: string | null,
      bidderCompanyName?: string | null,
      bidderContactAddress?: string | null,
      bidderContactPhoneNumber?: string | null,
      bidderWebsite?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBidMinorVersionMutationVariables = {
  input: UpdateBidMinorVersionInput,
  condition?: ModelBidMinorVersionConditionInput | null,
};

export type UpdateBidMinorVersionMutation = {
  updateBidMinorVersion?:  {
    __typename: "BidMinorVersion",
    id: string,
    bidId?: string | null,
    minorVersion?: number | null,
    tenderId?: string | null,
    tenderMinorVersion?: number | null,
    proposalId?: string | null,
    status?: DocumentStatus | null,
    metadata?:  {
      __typename: "BidMetadata",
      bidderContactName?: string | null,
      bidderContactEmail?: string | null,
      bidderCompanyName?: string | null,
      bidderContactAddress?: string | null,
      bidderContactPhoneNumber?: string | null,
      bidderWebsite?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBidMinorVersionMutationVariables = {
  input: DeleteBidMinorVersionInput,
  condition?: ModelBidMinorVersionConditionInput | null,
};

export type DeleteBidMinorVersionMutation = {
  deleteBidMinorVersion?:  {
    __typename: "BidMinorVersion",
    id: string,
    bidId?: string | null,
    minorVersion?: number | null,
    tenderId?: string | null,
    tenderMinorVersion?: number | null,
    proposalId?: string | null,
    status?: DocumentStatus | null,
    metadata?:  {
      __typename: "BidMetadata",
      bidderContactName?: string | null,
      bidderContactEmail?: string | null,
      bidderCompanyName?: string | null,
      bidderContactAddress?: string | null,
      bidderContactPhoneNumber?: string | null,
      bidderWebsite?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateClientLogMutationVariables = {
  input: CreateClientLogInput,
  condition?: ModelClientLogConditionInput | null,
};

export type CreateClientLogMutation = {
  createClientLog?:  {
    __typename: "ClientLog",
    id: string,
    userId?: string | null,
    userAgent?: string | null,
    severityLevel: LogSeverityLevel,
    componentName: string,
    message?: string | null,
    details?: string | null,
    tags?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateClientLogMutationVariables = {
  input: UpdateClientLogInput,
  condition?: ModelClientLogConditionInput | null,
};

export type UpdateClientLogMutation = {
  updateClientLog?:  {
    __typename: "ClientLog",
    id: string,
    userId?: string | null,
    userAgent?: string | null,
    severityLevel: LogSeverityLevel,
    componentName: string,
    message?: string | null,
    details?: string | null,
    tags?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteClientLogMutationVariables = {
  input: DeleteClientLogInput,
  condition?: ModelClientLogConditionInput | null,
};

export type DeleteClientLogMutation = {
  deleteClientLog?:  {
    __typename: "ClientLog",
    id: string,
    userId?: string | null,
    userAgent?: string | null,
    severityLevel: LogSeverityLevel,
    componentName: string,
    message?: string | null,
    details?: string | null,
    tags?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCounterMutationVariables = {
  input: CreateCounterInput,
  condition?: ModelCounterConditionInput | null,
};

export type CreateCounterMutation = {
  createCounter?:  {
    __typename: "Counter",
    id: string,
    type: ResourceType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCounterMutationVariables = {
  input: UpdateCounterInput,
  condition?: ModelCounterConditionInput | null,
};

export type UpdateCounterMutation = {
  updateCounter?:  {
    __typename: "Counter",
    id: string,
    type: ResourceType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCounterMutationVariables = {
  input: DeleteCounterInput,
  condition?: ModelCounterConditionInput | null,
};

export type DeleteCounterMutation = {
  deleteCounter?:  {
    __typename: "Counter",
    id: string,
    type: ResourceType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDesignDocumentMutationVariables = {
  input: CreateDesignDocumentInput,
  condition?: ModelDesignDocumentConditionInput | null,
};

export type CreateDesignDocumentMutation = {
  createDesignDocument?:  {
    __typename: "DesignDocument",
    id: string,
    designDocumentGroupId?: string | null,
    propertyId: string,
    projectId: string,
    name: string,
    status?: DesignDocumentStatus | null,
    lastEvaluatedValidityStatus?: boolean | null,
    uploadStatus?: string | null,
    documentKey?: string | null,
    lastWorkScopeSpecificationItemNumber?: number | null,
    version?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDesignDocumentMutationVariables = {
  input: UpdateDesignDocumentInput,
  condition?: ModelDesignDocumentConditionInput | null,
};

export type UpdateDesignDocumentMutation = {
  updateDesignDocument?:  {
    __typename: "DesignDocument",
    id: string,
    designDocumentGroupId?: string | null,
    propertyId: string,
    projectId: string,
    name: string,
    status?: DesignDocumentStatus | null,
    lastEvaluatedValidityStatus?: boolean | null,
    uploadStatus?: string | null,
    documentKey?: string | null,
    lastWorkScopeSpecificationItemNumber?: number | null,
    version?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDesignDocumentMutationVariables = {
  input: DeleteDesignDocumentInput,
  condition?: ModelDesignDocumentConditionInput | null,
};

export type DeleteDesignDocumentMutation = {
  deleteDesignDocument?:  {
    __typename: "DesignDocument",
    id: string,
    designDocumentGroupId?: string | null,
    propertyId: string,
    projectId: string,
    name: string,
    status?: DesignDocumentStatus | null,
    lastEvaluatedValidityStatus?: boolean | null,
    uploadStatus?: string | null,
    documentKey?: string | null,
    lastWorkScopeSpecificationItemNumber?: number | null,
    version?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDesignDocumentGroupMutationVariables = {
  input: CreateDesignDocumentGroupInput,
  condition?: ModelDesignDocumentGroupConditionInput | null,
};

export type CreateDesignDocumentGroupMutation = {
  createDesignDocumentGroup?:  {
    __typename: "DesignDocumentGroup",
    id: string,
    projectId: string,
    propertyId: string,
    name: string,
    latestDocumentVersion: number,
    documentContentKey?: string | null,
    solutionGroupNumber?: number | null,
    isLocked: boolean,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDesignDocumentGroupMutationVariables = {
  input: UpdateDesignDocumentGroupInput,
  condition?: ModelDesignDocumentGroupConditionInput | null,
};

export type UpdateDesignDocumentGroupMutation = {
  updateDesignDocumentGroup?:  {
    __typename: "DesignDocumentGroup",
    id: string,
    projectId: string,
    propertyId: string,
    name: string,
    latestDocumentVersion: number,
    documentContentKey?: string | null,
    solutionGroupNumber?: number | null,
    isLocked: boolean,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDesignDocumentGroupMutationVariables = {
  input: DeleteDesignDocumentGroupInput,
  condition?: ModelDesignDocumentGroupConditionInput | null,
};

export type DeleteDesignDocumentGroupMutation = {
  deleteDesignDocumentGroup?:  {
    __typename: "DesignDocumentGroup",
    id: string,
    projectId: string,
    propertyId: string,
    name: string,
    latestDocumentVersion: number,
    documentContentKey?: string | null,
    solutionGroupNumber?: number | null,
    isLocked: boolean,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDimensionMutationVariables = {
  input: CreateDimensionInput,
  condition?: ModelDimensionConditionInput | null,
};

export type CreateDimensionMutation = {
  createDimension?:  {
    __typename: "Dimension",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    description?: string | null,
    length?: number | null,
    lengthUnit?: string | null,
    width?: number | null,
    widthUnit?: string | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDimensionMutationVariables = {
  input: UpdateDimensionInput,
  condition?: ModelDimensionConditionInput | null,
};

export type UpdateDimensionMutation = {
  updateDimension?:  {
    __typename: "Dimension",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    description?: string | null,
    length?: number | null,
    lengthUnit?: string | null,
    width?: number | null,
    widthUnit?: string | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDimensionMutationVariables = {
  input: DeleteDimensionInput,
  condition?: ModelDimensionConditionInput | null,
};

export type DeleteDimensionMutation = {
  deleteDimension?:  {
    __typename: "Dimension",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    description?: string | null,
    length?: number | null,
    lengthUnit?: string | null,
    width?: number | null,
    widthUnit?: string | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDiscoverableWorkTypesMutationVariables = {
  input: CreateDiscoverableWorkTypesInput,
  condition?: ModelDiscoverableWorkTypesConditionInput | null,
};

export type CreateDiscoverableWorkTypesMutation = {
  createDiscoverableWorkTypes?:  {
    __typename: "DiscoverableWorkTypes",
    id: string,
    workTypeGroupId: string,
    defaultVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDiscoverableWorkTypesMutationVariables = {
  input: UpdateDiscoverableWorkTypesInput,
  condition?: ModelDiscoverableWorkTypesConditionInput | null,
};

export type UpdateDiscoverableWorkTypesMutation = {
  updateDiscoverableWorkTypes?:  {
    __typename: "DiscoverableWorkTypes",
    id: string,
    workTypeGroupId: string,
    defaultVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDiscoverableWorkTypesMutationVariables = {
  input: DeleteDiscoverableWorkTypesInput,
  condition?: ModelDiscoverableWorkTypesConditionInput | null,
};

export type DeleteDiscoverableWorkTypesMutation = {
  deleteDiscoverableWorkTypes?:  {
    __typename: "DiscoverableWorkTypes",
    id: string,
    workTypeGroupId: string,
    defaultVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateEntityResourcePermissionMutationVariables = {
  input: CreateEntityResourcePermissionInput,
  condition?: ModelEntityResourcePermissionConditionInput | null,
};

export type CreateEntityResourcePermissionMutation = {
  createEntityResourcePermission?:  {
    __typename: "EntityResourcePermission",
    id: string,
    propertyId?: string | null,
    entityId?: string | null,
    entityType?: PermissionEntityType | null,
    resourceId?: string | null,
    resourceType?: PermissionResourceType | null,
    permissionType?: PermissionType | null,
    isGranted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateEntityResourcePermissionMutationVariables = {
  input: UpdateEntityResourcePermissionInput,
  condition?: ModelEntityResourcePermissionConditionInput | null,
};

export type UpdateEntityResourcePermissionMutation = {
  updateEntityResourcePermission?:  {
    __typename: "EntityResourcePermission",
    id: string,
    propertyId?: string | null,
    entityId?: string | null,
    entityType?: PermissionEntityType | null,
    resourceId?: string | null,
    resourceType?: PermissionResourceType | null,
    permissionType?: PermissionType | null,
    isGranted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteEntityResourcePermissionMutationVariables = {
  input: DeleteEntityResourcePermissionInput,
  condition?: ModelEntityResourcePermissionConditionInput | null,
};

export type DeleteEntityResourcePermissionMutation = {
  deleteEntityResourcePermission?:  {
    __typename: "EntityResourcePermission",
    id: string,
    propertyId?: string | null,
    entityId?: string | null,
    entityType?: PermissionEntityType | null,
    resourceId?: string | null,
    resourceType?: PermissionResourceType | null,
    permissionType?: PermissionType | null,
    isGranted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateEntityWorkTypeAssociationMutationVariables = {
  input: CreateEntityWorkTypeAssociationInput,
  condition?: ModelEntityWorkTypeAssociationConditionInput | null,
};

export type CreateEntityWorkTypeAssociationMutation = {
  createEntityWorkTypeAssociation?:  {
    __typename: "EntityWorkTypeAssociation",
    id: string,
    entityId: string,
    entityType: WorkTypeAssociationScopeEntity,
    workTypeGroupId: string,
    workTypeVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateEntityWorkTypeAssociationMutationVariables = {
  input: UpdateEntityWorkTypeAssociationInput,
  condition?: ModelEntityWorkTypeAssociationConditionInput | null,
};

export type UpdateEntityWorkTypeAssociationMutation = {
  updateEntityWorkTypeAssociation?:  {
    __typename: "EntityWorkTypeAssociation",
    id: string,
    entityId: string,
    entityType: WorkTypeAssociationScopeEntity,
    workTypeGroupId: string,
    workTypeVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteEntityWorkTypeAssociationMutationVariables = {
  input: DeleteEntityWorkTypeAssociationInput,
  condition?: ModelEntityWorkTypeAssociationConditionInput | null,
};

export type DeleteEntityWorkTypeAssociationMutation = {
  deleteEntityWorkTypeAssociation?:  {
    __typename: "EntityWorkTypeAssociation",
    id: string,
    entityId: string,
    entityType: WorkTypeAssociationScopeEntity,
    workTypeGroupId: string,
    workTypeVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInspectionMutationVariables = {
  input: CreateInspectionInput,
  condition?: ModelInspectionConditionInput | null,
};

export type CreateInspectionMutation = {
  createInspection?:  {
    __typename: "Inspection",
    id: string,
    propertyId: string,
    createdByOrganizationId: string,
    createdByUserId: string,
    startTime: number,
    endTime?: number | null,
    status: InspectionStatus,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInspectionMutationVariables = {
  input: UpdateInspectionInput,
  condition?: ModelInspectionConditionInput | null,
};

export type UpdateInspectionMutation = {
  updateInspection?:  {
    __typename: "Inspection",
    id: string,
    propertyId: string,
    createdByOrganizationId: string,
    createdByUserId: string,
    startTime: number,
    endTime?: number | null,
    status: InspectionStatus,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInspectionMutationVariables = {
  input: DeleteInspectionInput,
  condition?: ModelInspectionConditionInput | null,
};

export type DeleteInspectionMutation = {
  deleteInspection?:  {
    __typename: "Inspection",
    id: string,
    propertyId: string,
    createdByOrganizationId: string,
    createdByUserId: string,
    startTime: number,
    endTime?: number | null,
    status: InspectionStatus,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateIssueMutationVariables = {
  input: CreateIssueInput,
  condition?: ModelIssueConditionInput | null,
};

export type CreateIssueMutation = {
  createIssue?:  {
    __typename: "Issue",
    id: string,
    locationId?: string | null,
    propertyId?: string | null,
    inspectionId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    description?: string | null,
    note?: string | null,
    tags?: string | null,
    status?: IssueStatus | null,
    clientCreationDate?: number | null,
    localLastUpdatedTime?: number | null,
    createdBy?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateIssueMutationVariables = {
  input: UpdateIssueInput,
  condition?: ModelIssueConditionInput | null,
};

export type UpdateIssueMutation = {
  updateIssue?:  {
    __typename: "Issue",
    id: string,
    locationId?: string | null,
    propertyId?: string | null,
    inspectionId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    description?: string | null,
    note?: string | null,
    tags?: string | null,
    status?: IssueStatus | null,
    clientCreationDate?: number | null,
    localLastUpdatedTime?: number | null,
    createdBy?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteIssueMutationVariables = {
  input: DeleteIssueInput,
  condition?: ModelIssueConditionInput | null,
};

export type DeleteIssueMutation = {
  deleteIssue?:  {
    __typename: "Issue",
    id: string,
    locationId?: string | null,
    propertyId?: string | null,
    inspectionId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    description?: string | null,
    note?: string | null,
    tags?: string | null,
    status?: IssueStatus | null,
    clientCreationDate?: number | null,
    localLastUpdatedTime?: number | null,
    createdBy?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLocationMutationVariables = {
  input: CreateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type CreateLocationMutation = {
  createLocation?:  {
    __typename: "Location",
    id: string,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    tags?: string | null,
    localCreationTime?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type UpdateLocationMutation = {
  updateLocation?:  {
    __typename: "Location",
    id: string,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    tags?: string | null,
    localCreationTime?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLocationMutationVariables = {
  input: DeleteLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type DeleteLocationMutation = {
  deleteLocation?:  {
    __typename: "Location",
    id: string,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    tags?: string | null,
    localCreationTime?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNoteMutationVariables = {
  input: CreateNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type CreateNoteMutation = {
  createNote?:  {
    __typename: "Note",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    note?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNoteMutationVariables = {
  input: UpdateNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type UpdateNoteMutation = {
  updateNote?:  {
    __typename: "Note",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    note?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNoteMutationVariables = {
  input: DeleteNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type DeleteNoteMutation = {
  deleteNote?:  {
    __typename: "Note",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    note?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    legalName: string,
    type?: OrganizationType | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    legalName: string,
    type?: OrganizationType | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    legalName: string,
    type?: OrganizationType | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateProjectMutationVariables = {
  input: CreateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type CreateProjectMutation = {
  createProject?:  {
    __typename: "Project",
    id: string,
    propertyId: string,
    name: string,
    tenderNumber?: string | null,
    customer?: string | null,
    address?: string | null,
    propertyType?: string | null,
    dueDate?: string | null,
    contactName?: string | null,
    contactNumber?: string | null,
    contactEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type UpdateProjectMutation = {
  updateProject?:  {
    __typename: "Project",
    id: string,
    propertyId: string,
    name: string,
    tenderNumber?: string | null,
    customer?: string | null,
    address?: string | null,
    propertyType?: string | null,
    dueDate?: string | null,
    contactName?: string | null,
    contactNumber?: string | null,
    contactEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteProjectMutationVariables = {
  input: DeleteProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type DeleteProjectMutation = {
  deleteProject?:  {
    __typename: "Project",
    id: string,
    propertyId: string,
    name: string,
    tenderNumber?: string | null,
    customer?: string | null,
    address?: string | null,
    propertyType?: string | null,
    dueDate?: string | null,
    contactName?: string | null,
    contactNumber?: string | null,
    contactEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateProjectCounterMutationVariables = {
  input: CreateProjectCounterInput,
  condition?: ModelProjectCounterConditionInput | null,
};

export type CreateProjectCounterMutation = {
  createProjectCounter?:  {
    __typename: "ProjectCounter",
    id: string,
    propertyId: string,
    latestProjectNumber?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateProjectCounterMutationVariables = {
  input: UpdateProjectCounterInput,
  condition?: ModelProjectCounterConditionInput | null,
};

export type UpdateProjectCounterMutation = {
  updateProjectCounter?:  {
    __typename: "ProjectCounter",
    id: string,
    propertyId: string,
    latestProjectNumber?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteProjectCounterMutationVariables = {
  input: DeleteProjectCounterInput,
  condition?: ModelProjectCounterConditionInput | null,
};

export type DeleteProjectCounterMutation = {
  deleteProjectCounter?:  {
    __typename: "ProjectCounter",
    id: string,
    propertyId: string,
    latestProjectNumber?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePropertyMutationVariables = {
  input: CreatePropertyInput,
  condition?: ModelPropertyConditionInput | null,
};

export type CreatePropertyMutation = {
  createProperty?:  {
    __typename: "Property",
    id: string,
    name?: string | null,
    description?: string | null,
    address?: string | null,
    ownerOrganizationId?: string | null,
    primaryContactNumber?: string | null,
    primaryContactEmail?: string | null,
    primaryContactName?: string | null,
    propertyNumber?: string | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        propertyId?: string | null,
        createdInSolution?: boolean | null,
        name?: string | null,
        tags?: string | null,
        localCreationTime?: number | null,
        localLastUpdatedTime?: number | null,
        opaqueSyncReconciliationToken?: string | null,
        canView?: string | null,
        canEdit?: string | null,
        canDelete?: string | null,
        createdBy?: string | null,
        canViewList?: Array< string | null > | null,
        canViewEntityVersionMap?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy?: string | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canCreateSolutions?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePropertyMutationVariables = {
  input: UpdatePropertyInput,
  condition?: ModelPropertyConditionInput | null,
};

export type UpdatePropertyMutation = {
  updateProperty?:  {
    __typename: "Property",
    id: string,
    name?: string | null,
    description?: string | null,
    address?: string | null,
    ownerOrganizationId?: string | null,
    primaryContactNumber?: string | null,
    primaryContactEmail?: string | null,
    primaryContactName?: string | null,
    propertyNumber?: string | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        propertyId?: string | null,
        createdInSolution?: boolean | null,
        name?: string | null,
        tags?: string | null,
        localCreationTime?: number | null,
        localLastUpdatedTime?: number | null,
        opaqueSyncReconciliationToken?: string | null,
        canView?: string | null,
        canEdit?: string | null,
        canDelete?: string | null,
        createdBy?: string | null,
        canViewList?: Array< string | null > | null,
        canViewEntityVersionMap?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy?: string | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canCreateSolutions?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePropertyMutationVariables = {
  input: DeletePropertyInput,
  condition?: ModelPropertyConditionInput | null,
};

export type DeletePropertyMutation = {
  deleteProperty?:  {
    __typename: "Property",
    id: string,
    name?: string | null,
    description?: string | null,
    address?: string | null,
    ownerOrganizationId?: string | null,
    primaryContactNumber?: string | null,
    primaryContactEmail?: string | null,
    primaryContactName?: string | null,
    propertyNumber?: string | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        propertyId?: string | null,
        createdInSolution?: boolean | null,
        name?: string | null,
        tags?: string | null,
        localCreationTime?: number | null,
        localLastUpdatedTime?: number | null,
        opaqueSyncReconciliationToken?: string | null,
        canView?: string | null,
        canEdit?: string | null,
        canDelete?: string | null,
        createdBy?: string | null,
        canViewList?: Array< string | null > | null,
        canViewEntityVersionMap?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy?: string | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canCreateSolutions?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePropertyCounterMutationVariables = {
  input: CreatePropertyCounterInput,
  condition?: ModelPropertyCounterConditionInput | null,
};

export type CreatePropertyCounterMutation = {
  createPropertyCounter?:  {
    __typename: "PropertyCounter",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePropertyCounterMutationVariables = {
  input: UpdatePropertyCounterInput,
  condition?: ModelPropertyCounterConditionInput | null,
};

export type UpdatePropertyCounterMutation = {
  updatePropertyCounter?:  {
    __typename: "PropertyCounter",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePropertyCounterMutationVariables = {
  input: DeletePropertyCounterInput,
  condition?: ModelPropertyCounterConditionInput | null,
};

export type DeletePropertyCounterMutation = {
  deletePropertyCounter?:  {
    __typename: "PropertyCounter",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateProposalMutationVariables = {
  input: CreateProposalInput,
  condition?: ModelProposalConditionInput | null,
};

export type CreateProposalMutation = {
  createProposal?:  {
    __typename: "Proposal",
    id: string,
    schemaVersion?: number | null,
    designDocumentGroupId: string,
    designDocumentVersion: number,
    proposalKey: string,
    proposalNumber?: number | null,
    status?: ProposalStatus | null,
    author?: string | null,
    createdAt?: string | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateProposalMutationVariables = {
  input: UpdateProposalInput,
  condition?: ModelProposalConditionInput | null,
};

export type UpdateProposalMutation = {
  updateProposal?:  {
    __typename: "Proposal",
    id: string,
    schemaVersion?: number | null,
    designDocumentGroupId: string,
    designDocumentVersion: number,
    proposalKey: string,
    proposalNumber?: number | null,
    status?: ProposalStatus | null,
    author?: string | null,
    createdAt?: string | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteProposalMutationVariables = {
  input: DeleteProposalInput,
  condition?: ModelProposalConditionInput | null,
};

export type DeleteProposalMutation = {
  deleteProposal?:  {
    __typename: "Proposal",
    id: string,
    schemaVersion?: number | null,
    designDocumentGroupId: string,
    designDocumentVersion: number,
    proposalKey: string,
    proposalNumber?: number | null,
    status?: ProposalStatus | null,
    author?: string | null,
    createdAt?: string | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateProposalCounterMutationVariables = {
  input: CreateProposalCounterInput,
  condition?: ModelProposalCounterConditionInput | null,
};

export type CreateProposalCounterMutation = {
  createProposalCounter?:  {
    __typename: "ProposalCounter",
    id: string,
    solutionGroupId?: string | null,
    solutionVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateProposalCounterMutationVariables = {
  input: UpdateProposalCounterInput,
  condition?: ModelProposalCounterConditionInput | null,
};

export type UpdateProposalCounterMutation = {
  updateProposalCounter?:  {
    __typename: "ProposalCounter",
    id: string,
    solutionGroupId?: string | null,
    solutionVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteProposalCounterMutationVariables = {
  input: DeleteProposalCounterInput,
  condition?: ModelProposalCounterConditionInput | null,
};

export type DeleteProposalCounterMutation = {
  deleteProposalCounter?:  {
    __typename: "ProposalCounter",
    id: string,
    solutionGroupId?: string | null,
    solutionVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateResourceUserPermissionsMutationVariables = {
  input: CreateResourceUserPermissionsInput,
  condition?: ModelResourceUserPermissionsConditionInput | null,
};

export type CreateResourceUserPermissionsMutation = {
  createResourceUserPermissions?:  {
    __typename: "ResourceUserPermissions",
    id: string,
    resourceId: string,
    userId?: string | null,
    userEmail?: string | null,
    userFullName?: string | null,
    resourceAndUserId?: string | null,
    permissions: string,
    resourceType?: ResourceType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateResourceUserPermissionsMutationVariables = {
  input: UpdateResourceUserPermissionsInput,
  condition?: ModelResourceUserPermissionsConditionInput | null,
};

export type UpdateResourceUserPermissionsMutation = {
  updateResourceUserPermissions?:  {
    __typename: "ResourceUserPermissions",
    id: string,
    resourceId: string,
    userId?: string | null,
    userEmail?: string | null,
    userFullName?: string | null,
    resourceAndUserId?: string | null,
    permissions: string,
    resourceType?: ResourceType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteResourceUserPermissionsMutationVariables = {
  input: DeleteResourceUserPermissionsInput,
  condition?: ModelResourceUserPermissionsConditionInput | null,
};

export type DeleteResourceUserPermissionsMutation = {
  deleteResourceUserPermissions?:  {
    __typename: "ResourceUserPermissions",
    id: string,
    resourceId: string,
    userId?: string | null,
    userEmail?: string | null,
    userFullName?: string | null,
    resourceAndUserId?: string | null,
    permissions: string,
    resourceType?: ResourceType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSolutionMutationVariables = {
  input: CreateSolutionInput,
  condition?: ModelSolutionConditionInput | null,
};

export type CreateSolutionMutation = {
  createSolution?:  {
    __typename: "Solution",
    id: string,
    propertyId?: string | null,
    projectId?: string | null,
    name?: string | null,
    status?: SolutionStatus | null,
    latestMinorVersion?: number | null,
    latestContentKey?: string | null,
    serialNumber?: number | null,
    metadata?:  {
      __typename: "SolutionMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
      tenderingType?: SolutionTenderingType | null,
    } | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSolutionMutationVariables = {
  input: UpdateSolutionInput,
  condition?: ModelSolutionConditionInput | null,
};

export type UpdateSolutionMutation = {
  updateSolution?:  {
    __typename: "Solution",
    id: string,
    propertyId?: string | null,
    projectId?: string | null,
    name?: string | null,
    status?: SolutionStatus | null,
    latestMinorVersion?: number | null,
    latestContentKey?: string | null,
    serialNumber?: number | null,
    metadata?:  {
      __typename: "SolutionMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
      tenderingType?: SolutionTenderingType | null,
    } | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSolutionMutationVariables = {
  input: DeleteSolutionInput,
  condition?: ModelSolutionConditionInput | null,
};

export type DeleteSolutionMutation = {
  deleteSolution?:  {
    __typename: "Solution",
    id: string,
    propertyId?: string | null,
    projectId?: string | null,
    name?: string | null,
    status?: SolutionStatus | null,
    latestMinorVersion?: number | null,
    latestContentKey?: string | null,
    serialNumber?: number | null,
    metadata?:  {
      __typename: "SolutionMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
      tenderingType?: SolutionTenderingType | null,
    } | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSolutionMinorVersionMutationVariables = {
  input: CreateSolutionMinorVersionInput,
  condition?: ModelSolutionMinorVersionConditionInput | null,
};

export type CreateSolutionMinorVersionMutation = {
  createSolutionMinorVersion?:  {
    __typename: "SolutionMinorVersion",
    id: string,
    solutionId?: string | null,
    propertyId?: string | null,
    name?: string | null,
    contentKey?: string | null,
    minorVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSolutionMinorVersionMutationVariables = {
  input: UpdateSolutionMinorVersionInput,
  condition?: ModelSolutionMinorVersionConditionInput | null,
};

export type UpdateSolutionMinorVersionMutation = {
  updateSolutionMinorVersion?:  {
    __typename: "SolutionMinorVersion",
    id: string,
    solutionId?: string | null,
    propertyId?: string | null,
    name?: string | null,
    contentKey?: string | null,
    minorVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSolutionMinorVersionMutationVariables = {
  input: DeleteSolutionMinorVersionInput,
  condition?: ModelSolutionMinorVersionConditionInput | null,
};

export type DeleteSolutionMinorVersionMutation = {
  deleteSolutionMinorVersion?:  {
    __typename: "SolutionMinorVersion",
    id: string,
    solutionId?: string | null,
    propertyId?: string | null,
    name?: string | null,
    contentKey?: string | null,
    minorVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSolutionGroupCounterMutationVariables = {
  input: CreateSolutionGroupCounterInput,
  condition?: ModelSolutionGroupCounterConditionInput | null,
};

export type CreateSolutionGroupCounterMutation = {
  createSolutionGroupCounter?:  {
    __typename: "SolutionGroupCounter",
    id: string,
    propertyId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSolutionGroupCounterMutationVariables = {
  input: UpdateSolutionGroupCounterInput,
  condition?: ModelSolutionGroupCounterConditionInput | null,
};

export type UpdateSolutionGroupCounterMutation = {
  updateSolutionGroupCounter?:  {
    __typename: "SolutionGroupCounter",
    id: string,
    propertyId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSolutionGroupCounterMutationVariables = {
  input: DeleteSolutionGroupCounterInput,
  condition?: ModelSolutionGroupCounterConditionInput | null,
};

export type DeleteSolutionGroupCounterMutation = {
  deleteSolutionGroupCounter?:  {
    __typename: "SolutionGroupCounter",
    id: string,
    propertyId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserGroupMutationVariables = {
  input: CreateUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type CreateUserGroupMutation = {
  createUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    resourceId: string,
    groupType: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserGroupMutationVariables = {
  input: UpdateUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type UpdateUserGroupMutation = {
  updateUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    resourceId: string,
    groupType: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserGroupMutationVariables = {
  input: DeleteUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type DeleteUserGroupMutation = {
  deleteUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    resourceId: string,
    groupType: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserGroupMemberMutationVariables = {
  input: CreateUserGroupMemberInput,
  condition?: ModelUserGroupMemberConditionInput | null,
};

export type CreateUserGroupMemberMutation = {
  createUserGroupMember?:  {
    __typename: "UserGroupMember",
    id: string,
    resourceId: string,
    resourceAndUserId: string,
    userId: string,
    userGroupId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserGroupMemberMutationVariables = {
  input: UpdateUserGroupMemberInput,
  condition?: ModelUserGroupMemberConditionInput | null,
};

export type UpdateUserGroupMemberMutation = {
  updateUserGroupMember?:  {
    __typename: "UserGroupMember",
    id: string,
    resourceId: string,
    resourceAndUserId: string,
    userId: string,
    userGroupId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserGroupMemberMutationVariables = {
  input: DeleteUserGroupMemberInput,
  condition?: ModelUserGroupMemberConditionInput | null,
};

export type DeleteUserGroupMemberMutation = {
  deleteUserGroupMember?:  {
    __typename: "UserGroupMember",
    id: string,
    resourceId: string,
    resourceAndUserId: string,
    userId: string,
    userGroupId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserOrganizationAssociationMutationVariables = {
  input: CreateUserOrganizationAssociationInput,
  condition?: ModelUserOrganizationAssociationConditionInput | null,
};

export type CreateUserOrganizationAssociationMutation = {
  createUserOrganizationAssociation?:  {
    __typename: "UserOrganizationAssociation",
    id: string,
    userId: string,
    organizationId: string,
    associationType: UserOrganizationAssociationType,
    permissions?: string | null,
    role?: UserOrganizationRole | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserOrganizationAssociationMutationVariables = {
  input: UpdateUserOrganizationAssociationInput,
  condition?: ModelUserOrganizationAssociationConditionInput | null,
};

export type UpdateUserOrganizationAssociationMutation = {
  updateUserOrganizationAssociation?:  {
    __typename: "UserOrganizationAssociation",
    id: string,
    userId: string,
    organizationId: string,
    associationType: UserOrganizationAssociationType,
    permissions?: string | null,
    role?: UserOrganizationRole | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserOrganizationAssociationMutationVariables = {
  input: DeleteUserOrganizationAssociationInput,
  condition?: ModelUserOrganizationAssociationConditionInput | null,
};

export type DeleteUserOrganizationAssociationMutation = {
  deleteUserOrganizationAssociation?:  {
    __typename: "UserOrganizationAssociation",
    id: string,
    userId: string,
    organizationId: string,
    associationType: UserOrganizationAssociationType,
    permissions?: string | null,
    role?: UserOrganizationRole | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateWorkSpecificationMutationVariables = {
  input: CreateWorkSpecificationInput,
  condition?: ModelWorkSpecificationConditionInput | null,
};

export type CreateWorkSpecificationMutation = {
  createWorkSpecification?:  {
    __typename: "WorkSpecification",
    id: string,
    dimensionId?: string | null,
    issueId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    body?: string | null,
    description?: string | null,
    workTypeId?: string | null,
    itemNumber?: number | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    version?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWorkSpecificationMutationVariables = {
  input: UpdateWorkSpecificationInput,
  condition?: ModelWorkSpecificationConditionInput | null,
};

export type UpdateWorkSpecificationMutation = {
  updateWorkSpecification?:  {
    __typename: "WorkSpecification",
    id: string,
    dimensionId?: string | null,
    issueId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    body?: string | null,
    description?: string | null,
    workTypeId?: string | null,
    itemNumber?: number | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    version?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWorkSpecificationMutationVariables = {
  input: DeleteWorkSpecificationInput,
  condition?: ModelWorkSpecificationConditionInput | null,
};

export type DeleteWorkSpecificationMutation = {
  deleteWorkSpecification?:  {
    __typename: "WorkSpecification",
    id: string,
    dimensionId?: string | null,
    issueId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    body?: string | null,
    description?: string | null,
    workTypeId?: string | null,
    itemNumber?: number | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    version?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateWorkTypeMutationVariables = {
  input: CreateWorkTypeInput,
  condition?: ModelWorkTypeConditionInput | null,
};

export type CreateWorkTypeMutation = {
  createWorkType?:  {
    __typename: "WorkType",
    id: string,
    name: string,
    shortDescription?: string | null,
    longDescription?: string | null,
    groupName?: string | null,
    unitPrice?: number | null,
    unitCost?: number | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWorkTypeMutationVariables = {
  input: UpdateWorkTypeInput,
  condition?: ModelWorkTypeConditionInput | null,
};

export type UpdateWorkTypeMutation = {
  updateWorkType?:  {
    __typename: "WorkType",
    id: string,
    name: string,
    shortDescription?: string | null,
    longDescription?: string | null,
    groupName?: string | null,
    unitPrice?: number | null,
    unitCost?: number | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWorkTypeMutationVariables = {
  input: DeleteWorkTypeInput,
  condition?: ModelWorkTypeConditionInput | null,
};

export type DeleteWorkTypeMutation = {
  deleteWorkType?:  {
    __typename: "WorkType",
    id: string,
    name: string,
    shortDescription?: string | null,
    longDescription?: string | null,
    groupName?: string | null,
    unitPrice?: number | null,
    unitCost?: number | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateWorkTypeGroupMutationVariables = {
  input: CreateWorkTypeGroupInput,
  condition?: ModelWorkTypeGroupConditionInput | null,
};

export type CreateWorkTypeGroupMutation = {
  createWorkTypeGroup?:  {
    __typename: "WorkTypeGroup",
    id: string,
    latestWorkTypeVersion: number,
    environmentAgnosticWorkTypeGroupId?: string | null,
    isDiscoverable?: boolean | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWorkTypeGroupMutationVariables = {
  input: UpdateWorkTypeGroupInput,
  condition?: ModelWorkTypeGroupConditionInput | null,
};

export type UpdateWorkTypeGroupMutation = {
  updateWorkTypeGroup?:  {
    __typename: "WorkTypeGroup",
    id: string,
    latestWorkTypeVersion: number,
    environmentAgnosticWorkTypeGroupId?: string | null,
    isDiscoverable?: boolean | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWorkTypeGroupMutationVariables = {
  input: DeleteWorkTypeGroupInput,
  condition?: ModelWorkTypeGroupConditionInput | null,
};

export type DeleteWorkTypeGroupMutation = {
  deleteWorkTypeGroup?:  {
    __typename: "WorkTypeGroup",
    id: string,
    latestWorkTypeVersion: number,
    environmentAgnosticWorkTypeGroupId?: string | null,
    isDiscoverable?: boolean | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateWorkTypePricingMutationVariables = {
  input: CreateWorkTypePricingInput,
  condition?: ModelWorkTypePricingConditionInput | null,
};

export type CreateWorkTypePricingMutation = {
  createWorkTypePricing?:  {
    __typename: "WorkTypePricing",
    id: string,
    entityId: string,
    workTypeGroupId: string,
    body: string,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWorkTypePricingMutationVariables = {
  input: UpdateWorkTypePricingInput,
  condition?: ModelWorkTypePricingConditionInput | null,
};

export type UpdateWorkTypePricingMutation = {
  updateWorkTypePricing?:  {
    __typename: "WorkTypePricing",
    id: string,
    entityId: string,
    workTypeGroupId: string,
    body: string,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWorkTypePricingMutationVariables = {
  input: DeleteWorkTypePricingInput,
  condition?: ModelWorkTypePricingConditionInput | null,
};

export type DeleteWorkTypePricingMutation = {
  deleteWorkTypePricing?:  {
    __typename: "WorkTypePricing",
    id: string,
    entityId: string,
    workTypeGroupId: string,
    body: string,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDefaultWorkTypePricingMutationVariables = {
  input: CreateDefaultWorkTypePricingInput,
  condition?: ModelDefaultWorkTypePricingConditionInput | null,
};

export type CreateDefaultWorkTypePricingMutation = {
  createDefaultWorkTypePricing?:  {
    __typename: "DefaultWorkTypePricing",
    id: string,
    unitCost?: number | null,
    unitPrice?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDefaultWorkTypePricingMutationVariables = {
  input: UpdateDefaultWorkTypePricingInput,
  condition?: ModelDefaultWorkTypePricingConditionInput | null,
};

export type UpdateDefaultWorkTypePricingMutation = {
  updateDefaultWorkTypePricing?:  {
    __typename: "DefaultWorkTypePricing",
    id: string,
    unitCost?: number | null,
    unitPrice?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDefaultWorkTypePricingMutationVariables = {
  input: DeleteDefaultWorkTypePricingInput,
  condition?: ModelDefaultWorkTypePricingConditionInput | null,
};

export type DeleteDefaultWorkTypePricingMutation = {
  deleteDefaultWorkTypePricing?:  {
    __typename: "DefaultWorkTypePricing",
    id: string,
    unitCost?: number | null,
    unitPrice?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTemporaryWorkTypeMutationVariables = {
  input: CreateTemporaryWorkTypeInput,
  condition?: ModelTemporaryWorkTypeConditionInput | null,
};

export type CreateTemporaryWorkTypeMutation = {
  createTemporaryWorkType?:  {
    __typename: "TemporaryWorkType",
    id: string,
    workTypeGroupId: string,
    environmentAgnosticWorkTypeGroupId?: string | null,
    body: string,
    version: number,
    schemaVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTemporaryWorkTypeMutationVariables = {
  input: UpdateTemporaryWorkTypeInput,
  condition?: ModelTemporaryWorkTypeConditionInput | null,
};

export type UpdateTemporaryWorkTypeMutation = {
  updateTemporaryWorkType?:  {
    __typename: "TemporaryWorkType",
    id: string,
    workTypeGroupId: string,
    environmentAgnosticWorkTypeGroupId?: string | null,
    body: string,
    version: number,
    schemaVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTemporaryWorkTypeMutationVariables = {
  input: DeleteTemporaryWorkTypeInput,
  condition?: ModelTemporaryWorkTypeConditionInput | null,
};

export type DeleteTemporaryWorkTypeMutation = {
  deleteTemporaryWorkType?:  {
    __typename: "TemporaryWorkType",
    id: string,
    workTypeGroupId: string,
    environmentAgnosticWorkTypeGroupId?: string | null,
    body: string,
    version: number,
    schemaVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTenderMutationVariables = {
  input: CreateTenderInput,
  condition?: ModelTenderConditionInput | null,
};

export type CreateTenderMutation = {
  createTender?:  {
    __typename: "Tender",
    id: string,
    solutionId?: string | null,
    status?: TenderStatus | null,
    latestVersion?: number | null,
    awardedVersion?: number | null,
    projectNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTenderMutationVariables = {
  input: UpdateTenderInput,
  condition?: ModelTenderConditionInput | null,
};

export type UpdateTenderMutation = {
  updateTender?:  {
    __typename: "Tender",
    id: string,
    solutionId?: string | null,
    status?: TenderStatus | null,
    latestVersion?: number | null,
    awardedVersion?: number | null,
    projectNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTenderMutationVariables = {
  input: DeleteTenderInput,
  condition?: ModelTenderConditionInput | null,
};

export type DeleteTenderMutation = {
  deleteTender?:  {
    __typename: "Tender",
    id: string,
    solutionId?: string | null,
    status?: TenderStatus | null,
    latestVersion?: number | null,
    awardedVersion?: number | null,
    projectNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTenderMinorVersionMutationVariables = {
  input: CreateTenderMinorVersionInput,
  condition?: ModelTenderMinorVersionConditionInput | null,
};

export type CreateTenderMinorVersionMutation = {
  createTenderMinorVersion?:  {
    __typename: "TenderMinorVersion",
    id: string,
    tenderId?: string | null,
    version?: number | null,
    solutionId?: string | null,
    solutionMinorVersion?: number | null,
    metadata?:  {
      __typename: "TenderMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
    } | null,
    contentKey?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTenderMinorVersionMutationVariables = {
  input: UpdateTenderMinorVersionInput,
  condition?: ModelTenderMinorVersionConditionInput | null,
};

export type UpdateTenderMinorVersionMutation = {
  updateTenderMinorVersion?:  {
    __typename: "TenderMinorVersion",
    id: string,
    tenderId?: string | null,
    version?: number | null,
    solutionId?: string | null,
    solutionMinorVersion?: number | null,
    metadata?:  {
      __typename: "TenderMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
    } | null,
    contentKey?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTenderMinorVersionMutationVariables = {
  input: DeleteTenderMinorVersionInput,
  condition?: ModelTenderMinorVersionConditionInput | null,
};

export type DeleteTenderMinorVersionMutation = {
  deleteTenderMinorVersion?:  {
    __typename: "TenderMinorVersion",
    id: string,
    tenderId?: string | null,
    version?: number | null,
    solutionId?: string | null,
    solutionMinorVersion?: number | null,
    metadata?:  {
      __typename: "TenderMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
    } | null,
    contentKey?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserOnboardingProgressMutationVariables = {
  input: CreateUserOnboardingProgressInput,
  condition?: ModelUserOnboardingProgressConditionInput | null,
};

export type CreateUserOnboardingProgressMutation = {
  createUserOnboardingProgress?:  {
    __typename: "UserOnboardingProgress",
    id: string,
    userId: string,
    hasUserBeenAssociatedWithOrganization: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserOnboardingProgressMutationVariables = {
  input: UpdateUserOnboardingProgressInput,
  condition?: ModelUserOnboardingProgressConditionInput | null,
};

export type UpdateUserOnboardingProgressMutation = {
  updateUserOnboardingProgress?:  {
    __typename: "UserOnboardingProgress",
    id: string,
    userId: string,
    hasUserBeenAssociatedWithOrganization: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserOnboardingProgressMutationVariables = {
  input: DeleteUserOnboardingProgressInput,
  condition?: ModelUserOnboardingProgressConditionInput | null,
};

export type DeleteUserOnboardingProgressMutation = {
  deleteUserOnboardingProgress?:  {
    __typename: "UserOnboardingProgress",
    id: string,
    userId: string,
    hasUserBeenAssociatedWithOrganization: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTemplateMutationVariables = {
  input: CreateTemplateInput,
  condition?: ModelTemplateConditionInput | null,
};

export type CreateTemplateMutation = {
  createTemplate?:  {
    __typename: "Template",
    id: string,
    type?: TemplateType | null,
    name?: string | null,
    s3Key?: string | null,
    ownerId?: string | null,
    ownerType?: TemplateEntityType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTemplateMutationVariables = {
  input: UpdateTemplateInput,
  condition?: ModelTemplateConditionInput | null,
};

export type UpdateTemplateMutation = {
  updateTemplate?:  {
    __typename: "Template",
    id: string,
    type?: TemplateType | null,
    name?: string | null,
    s3Key?: string | null,
    ownerId?: string | null,
    ownerType?: TemplateEntityType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTemplateMutationVariables = {
  input: DeleteTemplateInput,
  condition?: ModelTemplateConditionInput | null,
};

export type DeleteTemplateMutation = {
  deleteTemplate?:  {
    __typename: "Template",
    id: string,
    type?: TemplateType | null,
    name?: string | null,
    s3Key?: string | null,
    ownerId?: string | null,
    ownerType?: TemplateEntityType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTemporaryAuthorizationCodeMutationVariables = {
  input: CreateTemporaryAuthorizationCodeInput,
  condition?: ModelTemporaryAuthorizationCodeConditionInput | null,
};

export type CreateTemporaryAuthorizationCodeMutation = {
  createTemporaryAuthorizationCode?:  {
    __typename: "TemporaryAuthorizationCode",
    id: string,
    code?: string | null,
    userId?: string | null,
    expiresAt?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTemporaryAuthorizationCodeMutationVariables = {
  input: UpdateTemporaryAuthorizationCodeInput,
  condition?: ModelTemporaryAuthorizationCodeConditionInput | null,
};

export type UpdateTemporaryAuthorizationCodeMutation = {
  updateTemporaryAuthorizationCode?:  {
    __typename: "TemporaryAuthorizationCode",
    id: string,
    code?: string | null,
    userId?: string | null,
    expiresAt?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTemporaryAuthorizationCodeMutationVariables = {
  input: DeleteTemporaryAuthorizationCodeInput,
  condition?: ModelTemporaryAuthorizationCodeConditionInput | null,
};

export type DeleteTemporaryAuthorizationCodeMutation = {
  deleteTemporaryAuthorizationCode?:  {
    __typename: "TemporaryAuthorizationCode",
    id: string,
    code?: string | null,
    userId?: string | null,
    expiresAt?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLinkRegistryMutationVariables = {
  input: CreateLinkRegistryInput,
  condition?: ModelLinkRegistryConditionInput | null,
};

export type CreateLinkRegistryMutation = {
  createLinkRegistry?:  {
    __typename: "LinkRegistry",
    id: string,
    action?: LinkAction | null,
    variables?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLinkRegistryMutationVariables = {
  input: UpdateLinkRegistryInput,
  condition?: ModelLinkRegistryConditionInput | null,
};

export type UpdateLinkRegistryMutation = {
  updateLinkRegistry?:  {
    __typename: "LinkRegistry",
    id: string,
    action?: LinkAction | null,
    variables?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLinkRegistryMutationVariables = {
  input: DeleteLinkRegistryInput,
  condition?: ModelLinkRegistryConditionInput | null,
};

export type DeleteLinkRegistryMutation = {
  deleteLinkRegistry?:  {
    __typename: "LinkRegistry",
    id: string,
    action?: LinkAction | null,
    variables?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateExternalReportBrandingMutationVariables = {
  input: CreateExternalReportBrandingInput,
  condition?: ModelExternalReportBrandingConditionInput | null,
};

export type CreateExternalReportBrandingMutation = {
  createExternalReportBranding?:  {
    __typename: "ExternalReportBranding",
    id: string,
    name?: string | null,
    logoKey?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateExternalReportBrandingMutationVariables = {
  input: UpdateExternalReportBrandingInput,
  condition?: ModelExternalReportBrandingConditionInput | null,
};

export type UpdateExternalReportBrandingMutation = {
  updateExternalReportBranding?:  {
    __typename: "ExternalReportBranding",
    id: string,
    name?: string | null,
    logoKey?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteExternalReportBrandingMutationVariables = {
  input: DeleteExternalReportBrandingInput,
  condition?: ModelExternalReportBrandingConditionInput | null,
};

export type DeleteExternalReportBrandingMutation = {
  deleteExternalReportBranding?:  {
    __typename: "ExternalReportBranding",
    id: string,
    name?: string | null,
    logoKey?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMeetingMutationVariables = {
  input: CreateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type CreateMeetingMutation = {
  createMeeting?:  {
    __typename: "Meeting",
    id: string,
    status?: MeetingStatus | null,
    data: string,
    createdBy?: string | null,
    mediaPipelineId?: string | null,
    recordingKey?: string | null,
    recordingAttachmentId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMeetingMutationVariables = {
  input: UpdateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type UpdateMeetingMutation = {
  updateMeeting?:  {
    __typename: "Meeting",
    id: string,
    status?: MeetingStatus | null,
    data: string,
    createdBy?: string | null,
    mediaPipelineId?: string | null,
    recordingKey?: string | null,
    recordingAttachmentId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMeetingMutationVariables = {
  input: DeleteMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type DeleteMeetingMutation = {
  deleteMeeting?:  {
    __typename: "Meeting",
    id: string,
    status?: MeetingStatus | null,
    data: string,
    createdBy?: string | null,
    mediaPipelineId?: string | null,
    recordingKey?: string | null,
    recordingAttachmentId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAttendeeMutationVariables = {
  input: CreateAttendeeInput,
  condition?: ModelAttendeeConditionInput | null,
};

export type CreateAttendeeMutation = {
  createAttendee?:  {
    __typename: "Attendee",
    id: string,
    meetingId: string,
    entityId?: string | null,
    name?: string | null,
    role?: AttendeeRole | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAttendeeMutationVariables = {
  input: UpdateAttendeeInput,
  condition?: ModelAttendeeConditionInput | null,
};

export type UpdateAttendeeMutation = {
  updateAttendee?:  {
    __typename: "Attendee",
    id: string,
    meetingId: string,
    entityId?: string | null,
    name?: string | null,
    role?: AttendeeRole | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAttendeeMutationVariables = {
  input: DeleteAttendeeInput,
  condition?: ModelAttendeeConditionInput | null,
};

export type DeleteAttendeeMutation = {
  deleteAttendee?:  {
    __typename: "Attendee",
    id: string,
    meetingId: string,
    entityId?: string | null,
    name?: string | null,
    role?: AttendeeRole | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTemporaryAuthorizationTokenEventMutationVariables = {
  input: CreateTemporaryAuthorizationTokenEventInput,
};

export type CreateTemporaryAuthorizationTokenEventMutation = {
  createTemporaryAuthorizationTokenEvent?:  {
    __typename: "CreateTemporaryAuthorizationTokenResponse",
    status: ResponseStatus,
    authCode?: string | null,
    expiresAt?: number | null,
  } | null,
};

export type SendCompleteEventMutationVariables = {
  executionId: string,
  status: CompleteEventStatus,
  result?: string | null,
};

export type SendCompleteEventMutation = {
  sendCompleteEvent?:  {
    __typename: "CompleteEvent",
    executionId: string,
    status: CompleteEventStatus,
    result?: string | null,
  } | null,
};

export type HandleExternalReportSubmissionMutationVariables = {
  input: ExternalReportSubmission,
};

export type HandleExternalReportSubmissionMutation = {
  handleExternalReportSubmission?: string | null,
};

export type GenerateReportMutationVariables = {
  executionId: string,
  input: GenerateReportRequest,
};

export type GenerateReportMutation = {
  generateReport?:  {
    __typename: "CompleteEvent",
    executionId: string,
    status: CompleteEventStatus,
    result?: string | null,
  } | null,
};

export type SendNotificationToUsersMutationVariables = {
  input: sendNotificationToUsersInput,
};

export type SendNotificationToUsersMutation = {
  sendNotificationToUsers?: string | null,
};

export type UpdateBidStatusMutationVariables = {
  input: UpdateBidStatusInput,
};

export type UpdateBidStatusMutation = {
  updateBidStatus?: string | null,
};

export type JoinChimeMeetingPublicMutationVariables = {
  meetingId: string,
  name: string,
  role: AttendeeRole,
};

export type JoinChimeMeetingPublicMutation = {
  joinChimeMeetingPublic?: string | null,
};

export type ShareMeetingLinkMutationVariables = {
  input: ShareMeetingLinkInput,
};

export type ShareMeetingLinkMutation = {
  shareMeetingLink?: string | null,
};

export type ListResourceAggregateUserPermissionsQueryVariables = {
  resourceType: PermissionResourceType,
  resourceId: string,
  nextToken?: string | null,
};

export type ListResourceAggregateUserPermissionsQuery = {
  listResourceAggregateUserPermissions?:  {
    __typename: "ListResourceAggregateUserPermissionsOutput",
    items?:  Array< {
      __typename: "AggregateUserPermissions",
      userId?: string | null,
      username?: string | null,
      permissions?: Array< PermissionType | null > | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type HandleConverseCommandQueryVariables = {
  input?: HandleConverseCommandInput | null,
};

export type HandleConverseCommandQuery = {
  handleConverseCommand?: string | null,
};

export type GetAttachmentQueryVariables = {
  id: string,
};

export type GetAttachmentQuery = {
  getAttachment?:  {
    __typename: "Attachment",
    id: string,
    type?: AttachmentType | null,
    parentId: string,
    parentType?: PermissionResourceType | null,
    propertyId?: string | null,
    key?: string | null,
    localCreatedAtTime?: number | null,
    status?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAttachmentsQueryVariables = {
  id?: string | null,
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAttachmentsQuery = {
  listAttachments?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      type?: AttachmentType | null,
      parentId: string,
      parentType?: PermissionResourceType | null,
      propertyId?: string | null,
      key?: string | null,
      localCreatedAtTime?: number | null,
      status?: string | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAttachmentsQueryVariables = {
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAttachmentsQuery = {
  syncAttachments?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      type?: AttachmentType | null,
      parentId: string,
      parentType?: PermissionResourceType | null,
      propertyId?: string | null,
      key?: string | null,
      localCreatedAtTime?: number | null,
      status?: string | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListAttachmentsByParentQueryVariables = {
  parentId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttachmentsByParentQuery = {
  listAttachmentsByParent?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      type?: AttachmentType | null,
      parentId: string,
      parentType?: PermissionResourceType | null,
      propertyId?: string | null,
      key?: string | null,
      localCreatedAtTime?: number | null,
      status?: string | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListAttachmentsByPropertyQueryVariables = {
  propertyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttachmentsByPropertyQuery = {
  listAttachmentsByProperty?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      type?: AttachmentType | null,
      parentId: string,
      parentType?: PermissionResourceType | null,
      propertyId?: string | null,
      key?: string | null,
      localCreatedAtTime?: number | null,
      status?: string | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBiddingContactQueryVariables = {
  id: string,
};

export type GetBiddingContactQuery = {
  getBiddingContact?:  {
    __typename: "BiddingContact",
    id: string,
    email: string,
    ownerEntityId?: string | null,
    contactEntityId?: string | null,
    name?: string | null,
    privateDescription?: string | null,
    publicDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBiddingContactsQueryVariables = {
  id?: string | null,
  filter?: ModelBiddingContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBiddingContactsQuery = {
  listBiddingContacts?:  {
    __typename: "ModelBiddingContactConnection",
    items:  Array< {
      __typename: "BiddingContact",
      id: string,
      email: string,
      ownerEntityId?: string | null,
      contactEntityId?: string | null,
      name?: string | null,
      privateDescription?: string | null,
      publicDescription?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBiddingContactsQueryVariables = {
  filter?: ModelBiddingContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBiddingContactsQuery = {
  syncBiddingContacts?:  {
    __typename: "ModelBiddingContactConnection",
    items:  Array< {
      __typename: "BiddingContact",
      id: string,
      email: string,
      ownerEntityId?: string | null,
      contactEntityId?: string | null,
      name?: string | null,
      privateDescription?: string | null,
      publicDescription?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBiddingContactsByOwnerEntityIdQueryVariables = {
  ownerEntityId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBiddingContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBiddingContactsByOwnerEntityIdQuery = {
  listBiddingContactsByOwnerEntityId?:  {
    __typename: "ModelBiddingContactConnection",
    items:  Array< {
      __typename: "BiddingContact",
      id: string,
      email: string,
      ownerEntityId?: string | null,
      contactEntityId?: string | null,
      name?: string | null,
      privateDescription?: string | null,
      publicDescription?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBidInvitationQueryVariables = {
  id: string,
};

export type GetBidInvitationQuery = {
  getBidInvitation?:  {
    __typename: "BidInvitation",
    id: string,
    biddingContactId?: string | null,
    tenderId?: string | null,
    ownerEntityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBidInvitationsQueryVariables = {
  id?: string | null,
  filter?: ModelBidInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBidInvitationsQuery = {
  listBidInvitations?:  {
    __typename: "ModelBidInvitationConnection",
    items:  Array< {
      __typename: "BidInvitation",
      id: string,
      biddingContactId?: string | null,
      tenderId?: string | null,
      ownerEntityId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBidInvitationsQueryVariables = {
  filter?: ModelBidInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBidInvitationsQuery = {
  syncBidInvitations?:  {
    __typename: "ModelBidInvitationConnection",
    items:  Array< {
      __typename: "BidInvitation",
      id: string,
      biddingContactId?: string | null,
      tenderId?: string | null,
      ownerEntityId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBidInvitationsByTenderIdQueryVariables = {
  tenderId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBidInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBidInvitationsByTenderIdQuery = {
  listBidInvitationsByTenderId?:  {
    __typename: "ModelBidInvitationConnection",
    items:  Array< {
      __typename: "BidInvitation",
      id: string,
      biddingContactId?: string | null,
      tenderId?: string | null,
      ownerEntityId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBidQueryVariables = {
  id: string,
};

export type GetBidQuery = {
  getBid?:  {
    __typename: "Bid",
    id: string,
    tenderId?: string | null,
    latestMinorVersion?: number | null,
    latestAcceptedMinorVersion?: number | null,
    latestSubmittedMinorVersion?: number | null,
    status?: BidStatus | null,
    bidNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBidsQueryVariables = {
  id?: string | null,
  filter?: ModelBidFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBidsQuery = {
  listBids?:  {
    __typename: "ModelBidConnection",
    items:  Array< {
      __typename: "Bid",
      id: string,
      tenderId?: string | null,
      latestMinorVersion?: number | null,
      latestAcceptedMinorVersion?: number | null,
      latestSubmittedMinorVersion?: number | null,
      status?: BidStatus | null,
      bidNumber?: number | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBidsQueryVariables = {
  filter?: ModelBidFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBidsQuery = {
  syncBids?:  {
    __typename: "ModelBidConnection",
    items:  Array< {
      __typename: "Bid",
      id: string,
      tenderId?: string | null,
      latestMinorVersion?: number | null,
      latestAcceptedMinorVersion?: number | null,
      latestSubmittedMinorVersion?: number | null,
      status?: BidStatus | null,
      bidNumber?: number | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBidsByTenderIdQueryVariables = {
  tenderId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBidFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBidsByTenderIdQuery = {
  listBidsByTenderId?:  {
    __typename: "ModelBidConnection",
    items:  Array< {
      __typename: "Bid",
      id: string,
      tenderId?: string | null,
      latestMinorVersion?: number | null,
      latestAcceptedMinorVersion?: number | null,
      latestSubmittedMinorVersion?: number | null,
      status?: BidStatus | null,
      bidNumber?: number | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBidsByCreatedByQueryVariables = {
  createdBy: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBidFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBidsByCreatedByQuery = {
  listBidsByCreatedBy?:  {
    __typename: "ModelBidConnection",
    items:  Array< {
      __typename: "Bid",
      id: string,
      tenderId?: string | null,
      latestMinorVersion?: number | null,
      latestAcceptedMinorVersion?: number | null,
      latestSubmittedMinorVersion?: number | null,
      status?: BidStatus | null,
      bidNumber?: number | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBidMinorVersionQueryVariables = {
  id: string,
};

export type GetBidMinorVersionQuery = {
  getBidMinorVersion?:  {
    __typename: "BidMinorVersion",
    id: string,
    bidId?: string | null,
    minorVersion?: number | null,
    tenderId?: string | null,
    tenderMinorVersion?: number | null,
    proposalId?: string | null,
    status?: DocumentStatus | null,
    metadata?:  {
      __typename: "BidMetadata",
      bidderContactName?: string | null,
      bidderContactEmail?: string | null,
      bidderCompanyName?: string | null,
      bidderContactAddress?: string | null,
      bidderContactPhoneNumber?: string | null,
      bidderWebsite?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBidMinorVersionsQueryVariables = {
  id?: string | null,
  filter?: ModelBidMinorVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBidMinorVersionsQuery = {
  listBidMinorVersions?:  {
    __typename: "ModelBidMinorVersionConnection",
    items:  Array< {
      __typename: "BidMinorVersion",
      id: string,
      bidId?: string | null,
      minorVersion?: number | null,
      tenderId?: string | null,
      tenderMinorVersion?: number | null,
      proposalId?: string | null,
      status?: DocumentStatus | null,
      metadata?:  {
        __typename: "BidMetadata",
        bidderContactName?: string | null,
        bidderContactEmail?: string | null,
        bidderCompanyName?: string | null,
        bidderContactAddress?: string | null,
        bidderContactPhoneNumber?: string | null,
        bidderWebsite?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBidMinorVersionsQueryVariables = {
  filter?: ModelBidMinorVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBidMinorVersionsQuery = {
  syncBidMinorVersions?:  {
    __typename: "ModelBidMinorVersionConnection",
    items:  Array< {
      __typename: "BidMinorVersion",
      id: string,
      bidId?: string | null,
      minorVersion?: number | null,
      tenderId?: string | null,
      tenderMinorVersion?: number | null,
      proposalId?: string | null,
      status?: DocumentStatus | null,
      metadata?:  {
        __typename: "BidMetadata",
        bidderContactName?: string | null,
        bidderContactEmail?: string | null,
        bidderCompanyName?: string | null,
        bidderContactAddress?: string | null,
        bidderContactPhoneNumber?: string | null,
        bidderWebsite?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBidMinorVersionsByBidIdQueryVariables = {
  bidId: string,
  minorVersion?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBidMinorVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBidMinorVersionsByBidIdQuery = {
  listBidMinorVersionsByBidId?:  {
    __typename: "ModelBidMinorVersionConnection",
    items:  Array< {
      __typename: "BidMinorVersion",
      id: string,
      bidId?: string | null,
      minorVersion?: number | null,
      tenderId?: string | null,
      tenderMinorVersion?: number | null,
      proposalId?: string | null,
      status?: DocumentStatus | null,
      metadata?:  {
        __typename: "BidMetadata",
        bidderContactName?: string | null,
        bidderContactEmail?: string | null,
        bidderCompanyName?: string | null,
        bidderContactAddress?: string | null,
        bidderContactPhoneNumber?: string | null,
        bidderWebsite?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetClientLogQueryVariables = {
  id: string,
};

export type GetClientLogQuery = {
  getClientLog?:  {
    __typename: "ClientLog",
    id: string,
    userId?: string | null,
    userAgent?: string | null,
    severityLevel: LogSeverityLevel,
    componentName: string,
    message?: string | null,
    details?: string | null,
    tags?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListClientLogsQueryVariables = {
  id?: string | null,
  filter?: ModelClientLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClientLogsQuery = {
  listClientLogs?:  {
    __typename: "ModelClientLogConnection",
    items:  Array< {
      __typename: "ClientLog",
      id: string,
      userId?: string | null,
      userAgent?: string | null,
      severityLevel: LogSeverityLevel,
      componentName: string,
      message?: string | null,
      details?: string | null,
      tags?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClientLogsQueryVariables = {
  filter?: ModelClientLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClientLogsQuery = {
  syncClientLogs?:  {
    __typename: "ModelClientLogConnection",
    items:  Array< {
      __typename: "ClientLog",
      id: string,
      userId?: string | null,
      userAgent?: string | null,
      severityLevel: LogSeverityLevel,
      componentName: string,
      message?: string | null,
      details?: string | null,
      tags?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListClientLogsByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClientLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientLogsByUserIdQuery = {
  listClientLogsByUserId?:  {
    __typename: "ModelClientLogConnection",
    items:  Array< {
      __typename: "ClientLog",
      id: string,
      userId?: string | null,
      userAgent?: string | null,
      severityLevel: LogSeverityLevel,
      componentName: string,
      message?: string | null,
      details?: string | null,
      tags?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCounterQueryVariables = {
  id: string,
};

export type GetCounterQuery = {
  getCounter?:  {
    __typename: "Counter",
    id: string,
    type: ResourceType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCountersQueryVariables = {
  filter?: ModelCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCountersQuery = {
  listCounters?:  {
    __typename: "ModelCounterConnection",
    items:  Array< {
      __typename: "Counter",
      id: string,
      type: ResourceType,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCountersQueryVariables = {
  filter?: ModelCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCountersQuery = {
  syncCounters?:  {
    __typename: "ModelCounterConnection",
    items:  Array< {
      __typename: "Counter",
      id: string,
      type: ResourceType,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDesignDocumentQueryVariables = {
  id: string,
};

export type GetDesignDocumentQuery = {
  getDesignDocument?:  {
    __typename: "DesignDocument",
    id: string,
    designDocumentGroupId?: string | null,
    propertyId: string,
    projectId: string,
    name: string,
    status?: DesignDocumentStatus | null,
    lastEvaluatedValidityStatus?: boolean | null,
    uploadStatus?: string | null,
    documentKey?: string | null,
    lastWorkScopeSpecificationItemNumber?: number | null,
    version?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDesignDocumentsQueryVariables = {
  id?: string | null,
  filter?: ModelDesignDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDesignDocumentsQuery = {
  listDesignDocuments?:  {
    __typename: "ModelDesignDocumentConnection",
    items:  Array< {
      __typename: "DesignDocument",
      id: string,
      designDocumentGroupId?: string | null,
      propertyId: string,
      projectId: string,
      name: string,
      status?: DesignDocumentStatus | null,
      lastEvaluatedValidityStatus?: boolean | null,
      uploadStatus?: string | null,
      documentKey?: string | null,
      lastWorkScopeSpecificationItemNumber?: number | null,
      version?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDesignDocumentsQueryVariables = {
  filter?: ModelDesignDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDesignDocumentsQuery = {
  syncDesignDocuments?:  {
    __typename: "ModelDesignDocumentConnection",
    items:  Array< {
      __typename: "DesignDocument",
      id: string,
      designDocumentGroupId?: string | null,
      propertyId: string,
      projectId: string,
      name: string,
      status?: DesignDocumentStatus | null,
      lastEvaluatedValidityStatus?: boolean | null,
      uploadStatus?: string | null,
      documentKey?: string | null,
      lastWorkScopeSpecificationItemNumber?: number | null,
      version?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListDesignDocumentsByGroupByVersionQueryVariables = {
  designDocumentGroupId: string,
  version?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDesignDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDesignDocumentsByGroupByVersionQuery = {
  listDesignDocumentsByGroupByVersion?:  {
    __typename: "ModelDesignDocumentConnection",
    items:  Array< {
      __typename: "DesignDocument",
      id: string,
      designDocumentGroupId?: string | null,
      propertyId: string,
      projectId: string,
      name: string,
      status?: DesignDocumentStatus | null,
      lastEvaluatedValidityStatus?: boolean | null,
      uploadStatus?: string | null,
      documentKey?: string | null,
      lastWorkScopeSpecificationItemNumber?: number | null,
      version?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListDesignDocumentsByGroupByStatusQueryVariables = {
  designDocumentGroupId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDesignDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDesignDocumentsByGroupByStatusQuery = {
  listDesignDocumentsByGroupByStatus?:  {
    __typename: "ModelDesignDocumentConnection",
    items:  Array< {
      __typename: "DesignDocument",
      id: string,
      designDocumentGroupId?: string | null,
      propertyId: string,
      projectId: string,
      name: string,
      status?: DesignDocumentStatus | null,
      lastEvaluatedValidityStatus?: boolean | null,
      uploadStatus?: string | null,
      documentKey?: string | null,
      lastWorkScopeSpecificationItemNumber?: number | null,
      version?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListDesignDocumentsByPropertyQueryVariables = {
  propertyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDesignDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDesignDocumentsByPropertyQuery = {
  listDesignDocumentsByProperty?:  {
    __typename: "ModelDesignDocumentConnection",
    items:  Array< {
      __typename: "DesignDocument",
      id: string,
      designDocumentGroupId?: string | null,
      propertyId: string,
      projectId: string,
      name: string,
      status?: DesignDocumentStatus | null,
      lastEvaluatedValidityStatus?: boolean | null,
      uploadStatus?: string | null,
      documentKey?: string | null,
      lastWorkScopeSpecificationItemNumber?: number | null,
      version?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListDesignDocumentsByProjectQueryVariables = {
  projectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDesignDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDesignDocumentsByProjectQuery = {
  listDesignDocumentsByProject?:  {
    __typename: "ModelDesignDocumentConnection",
    items:  Array< {
      __typename: "DesignDocument",
      id: string,
      designDocumentGroupId?: string | null,
      propertyId: string,
      projectId: string,
      name: string,
      status?: DesignDocumentStatus | null,
      lastEvaluatedValidityStatus?: boolean | null,
      uploadStatus?: string | null,
      documentKey?: string | null,
      lastWorkScopeSpecificationItemNumber?: number | null,
      version?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDesignDocumentGroupQueryVariables = {
  id: string,
};

export type GetDesignDocumentGroupQuery = {
  getDesignDocumentGroup?:  {
    __typename: "DesignDocumentGroup",
    id: string,
    projectId: string,
    propertyId: string,
    name: string,
    latestDocumentVersion: number,
    documentContentKey?: string | null,
    solutionGroupNumber?: number | null,
    isLocked: boolean,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDesignDocumentGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelDesignDocumentGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDesignDocumentGroupsQuery = {
  listDesignDocumentGroups?:  {
    __typename: "ModelDesignDocumentGroupConnection",
    items:  Array< {
      __typename: "DesignDocumentGroup",
      id: string,
      projectId: string,
      propertyId: string,
      name: string,
      latestDocumentVersion: number,
      documentContentKey?: string | null,
      solutionGroupNumber?: number | null,
      isLocked: boolean,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDesignDocumentGroupsQueryVariables = {
  filter?: ModelDesignDocumentGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDesignDocumentGroupsQuery = {
  syncDesignDocumentGroups?:  {
    __typename: "ModelDesignDocumentGroupConnection",
    items:  Array< {
      __typename: "DesignDocumentGroup",
      id: string,
      projectId: string,
      propertyId: string,
      name: string,
      latestDocumentVersion: number,
      documentContentKey?: string | null,
      solutionGroupNumber?: number | null,
      isLocked: boolean,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListDesignDocumentGroupsByProjectQueryVariables = {
  projectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDesignDocumentGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDesignDocumentGroupsByProjectQuery = {
  listDesignDocumentGroupsByProject?:  {
    __typename: "ModelDesignDocumentGroupConnection",
    items:  Array< {
      __typename: "DesignDocumentGroup",
      id: string,
      projectId: string,
      propertyId: string,
      name: string,
      latestDocumentVersion: number,
      documentContentKey?: string | null,
      solutionGroupNumber?: number | null,
      isLocked: boolean,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListDesignDocumentGroupsByPropertyQueryVariables = {
  propertyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDesignDocumentGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDesignDocumentGroupsByPropertyQuery = {
  listDesignDocumentGroupsByProperty?:  {
    __typename: "ModelDesignDocumentGroupConnection",
    items:  Array< {
      __typename: "DesignDocumentGroup",
      id: string,
      projectId: string,
      propertyId: string,
      name: string,
      latestDocumentVersion: number,
      documentContentKey?: string | null,
      solutionGroupNumber?: number | null,
      isLocked: boolean,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDimensionQueryVariables = {
  id: string,
};

export type GetDimensionQuery = {
  getDimension?:  {
    __typename: "Dimension",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    description?: string | null,
    length?: number | null,
    lengthUnit?: string | null,
    width?: number | null,
    widthUnit?: string | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDimensionsQueryVariables = {
  id?: string | null,
  filter?: ModelDimensionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDimensionsQuery = {
  listDimensions?:  {
    __typename: "ModelDimensionConnection",
    items:  Array< {
      __typename: "Dimension",
      id: string,
      parentId?: string | null,
      propertyId?: string | null,
      createdInSolution?: boolean | null,
      description?: string | null,
      length?: number | null,
      lengthUnit?: string | null,
      width?: number | null,
      widthUnit?: string | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
      measurement?:  {
        __typename: "Measurement",
        length?: number | null,
        width?: number | null,
        dimensionType?: DimensionType | null,
        measurementUnit?: MeasurementUnit | null,
      } | null,
      localLastUpdatedTime?: number | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDimensionsQueryVariables = {
  filter?: ModelDimensionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDimensionsQuery = {
  syncDimensions?:  {
    __typename: "ModelDimensionConnection",
    items:  Array< {
      __typename: "Dimension",
      id: string,
      parentId?: string | null,
      propertyId?: string | null,
      createdInSolution?: boolean | null,
      description?: string | null,
      length?: number | null,
      lengthUnit?: string | null,
      width?: number | null,
      widthUnit?: string | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
      measurement?:  {
        __typename: "Measurement",
        length?: number | null,
        width?: number | null,
        dimensionType?: DimensionType | null,
        measurementUnit?: MeasurementUnit | null,
      } | null,
      localLastUpdatedTime?: number | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListDimensionsByParentQueryVariables = {
  parentId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDimensionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDimensionsByParentQuery = {
  listDimensionsByParent?:  {
    __typename: "ModelDimensionConnection",
    items:  Array< {
      __typename: "Dimension",
      id: string,
      parentId?: string | null,
      propertyId?: string | null,
      createdInSolution?: boolean | null,
      description?: string | null,
      length?: number | null,
      lengthUnit?: string | null,
      width?: number | null,
      widthUnit?: string | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
      measurement?:  {
        __typename: "Measurement",
        length?: number | null,
        width?: number | null,
        dimensionType?: DimensionType | null,
        measurementUnit?: MeasurementUnit | null,
      } | null,
      localLastUpdatedTime?: number | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListDimensionsByPropertyQueryVariables = {
  propertyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDimensionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDimensionsByPropertyQuery = {
  listDimensionsByProperty?:  {
    __typename: "ModelDimensionConnection",
    items:  Array< {
      __typename: "Dimension",
      id: string,
      parentId?: string | null,
      propertyId?: string | null,
      createdInSolution?: boolean | null,
      description?: string | null,
      length?: number | null,
      lengthUnit?: string | null,
      width?: number | null,
      widthUnit?: string | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
      measurement?:  {
        __typename: "Measurement",
        length?: number | null,
        width?: number | null,
        dimensionType?: DimensionType | null,
        measurementUnit?: MeasurementUnit | null,
      } | null,
      localLastUpdatedTime?: number | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDiscoverableWorkTypesQueryVariables = {
  id: string,
};

export type GetDiscoverableWorkTypesQuery = {
  getDiscoverableWorkTypes?:  {
    __typename: "DiscoverableWorkTypes",
    id: string,
    workTypeGroupId: string,
    defaultVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDiscoverableWorkTypesQueryVariables = {
  id?: string | null,
  filter?: ModelDiscoverableWorkTypesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDiscoverableWorkTypesQuery = {
  listDiscoverableWorkTypes?:  {
    __typename: "ModelDiscoverableWorkTypesConnection",
    items:  Array< {
      __typename: "DiscoverableWorkTypes",
      id: string,
      workTypeGroupId: string,
      defaultVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDiscoverableWorkTypesQueryVariables = {
  filter?: ModelDiscoverableWorkTypesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDiscoverableWorkTypesQuery = {
  syncDiscoverableWorkTypes?:  {
    __typename: "ModelDiscoverableWorkTypesConnection",
    items:  Array< {
      __typename: "DiscoverableWorkTypes",
      id: string,
      workTypeGroupId: string,
      defaultVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetEntityResourcePermissionQueryVariables = {
  id: string,
};

export type GetEntityResourcePermissionQuery = {
  getEntityResourcePermission?:  {
    __typename: "EntityResourcePermission",
    id: string,
    propertyId?: string | null,
    entityId?: string | null,
    entityType?: PermissionEntityType | null,
    resourceId?: string | null,
    resourceType?: PermissionResourceType | null,
    permissionType?: PermissionType | null,
    isGranted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListEntityResourcePermissionsQueryVariables = {
  id?: string | null,
  filter?: ModelEntityResourcePermissionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEntityResourcePermissionsQuery = {
  listEntityResourcePermissions?:  {
    __typename: "ModelEntityResourcePermissionConnection",
    items:  Array< {
      __typename: "EntityResourcePermission",
      id: string,
      propertyId?: string | null,
      entityId?: string | null,
      entityType?: PermissionEntityType | null,
      resourceId?: string | null,
      resourceType?: PermissionResourceType | null,
      permissionType?: PermissionType | null,
      isGranted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEntityResourcePermissionsQueryVariables = {
  filter?: ModelEntityResourcePermissionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEntityResourcePermissionsQuery = {
  syncEntityResourcePermissions?:  {
    __typename: "ModelEntityResourcePermissionConnection",
    items:  Array< {
      __typename: "EntityResourcePermission",
      id: string,
      propertyId?: string | null,
      entityId?: string | null,
      entityType?: PermissionEntityType | null,
      resourceId?: string | null,
      resourceType?: PermissionResourceType | null,
      permissionType?: PermissionType | null,
      isGranted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListEntityResourcePermissionByEntityIdByResourceIdQueryVariables = {
  entityId: string,
  resourceId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEntityResourcePermissionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntityResourcePermissionByEntityIdByResourceIdQuery = {
  listEntityResourcePermissionByEntityIdByResourceId?:  {
    __typename: "ModelEntityResourcePermissionConnection",
    items:  Array< {
      __typename: "EntityResourcePermission",
      id: string,
      propertyId?: string | null,
      entityId?: string | null,
      entityType?: PermissionEntityType | null,
      resourceId?: string | null,
      resourceType?: PermissionResourceType | null,
      permissionType?: PermissionType | null,
      isGranted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListEntityResourcePermissionByResourceIdQueryVariables = {
  resourceId: string,
  resourceType?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEntityResourcePermissionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntityResourcePermissionByResourceIdQuery = {
  listEntityResourcePermissionByResourceId?:  {
    __typename: "ModelEntityResourcePermissionConnection",
    items:  Array< {
      __typename: "EntityResourcePermission",
      id: string,
      propertyId?: string | null,
      entityId?: string | null,
      entityType?: PermissionEntityType | null,
      resourceId?: string | null,
      resourceType?: PermissionResourceType | null,
      permissionType?: PermissionType | null,
      isGranted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetEntityWorkTypeAssociationQueryVariables = {
  id: string,
};

export type GetEntityWorkTypeAssociationQuery = {
  getEntityWorkTypeAssociation?:  {
    __typename: "EntityWorkTypeAssociation",
    id: string,
    entityId: string,
    entityType: WorkTypeAssociationScopeEntity,
    workTypeGroupId: string,
    workTypeVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListEntityWorkTypeAssociationsQueryVariables = {
  filter?: ModelEntityWorkTypeAssociationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntityWorkTypeAssociationsQuery = {
  listEntityWorkTypeAssociations?:  {
    __typename: "ModelEntityWorkTypeAssociationConnection",
    items:  Array< {
      __typename: "EntityWorkTypeAssociation",
      id: string,
      entityId: string,
      entityType: WorkTypeAssociationScopeEntity,
      workTypeGroupId: string,
      workTypeVersion?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEntityWorkTypeAssociationsQueryVariables = {
  filter?: ModelEntityWorkTypeAssociationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEntityWorkTypeAssociationsQuery = {
  syncEntityWorkTypeAssociations?:  {
    __typename: "ModelEntityWorkTypeAssociationConnection",
    items:  Array< {
      __typename: "EntityWorkTypeAssociation",
      id: string,
      entityId: string,
      entityType: WorkTypeAssociationScopeEntity,
      workTypeGroupId: string,
      workTypeVersion?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInspectionQueryVariables = {
  id: string,
};

export type GetInspectionQuery = {
  getInspection?:  {
    __typename: "Inspection",
    id: string,
    propertyId: string,
    createdByOrganizationId: string,
    createdByUserId: string,
    startTime: number,
    endTime?: number | null,
    status: InspectionStatus,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInspectionsQueryVariables = {
  id?: string | null,
  filter?: ModelInspectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInspectionsQuery = {
  listInspections?:  {
    __typename: "ModelInspectionConnection",
    items:  Array< {
      __typename: "Inspection",
      id: string,
      propertyId: string,
      createdByOrganizationId: string,
      createdByUserId: string,
      startTime: number,
      endTime?: number | null,
      status: InspectionStatus,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInspectionsQueryVariables = {
  filter?: ModelInspectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInspectionsQuery = {
  syncInspections?:  {
    __typename: "ModelInspectionConnection",
    items:  Array< {
      __typename: "Inspection",
      id: string,
      propertyId: string,
      createdByOrganizationId: string,
      createdByUserId: string,
      startTime: number,
      endTime?: number | null,
      status: InspectionStatus,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetIssueQueryVariables = {
  id: string,
};

export type GetIssueQuery = {
  getIssue?:  {
    __typename: "Issue",
    id: string,
    locationId?: string | null,
    propertyId?: string | null,
    inspectionId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    description?: string | null,
    note?: string | null,
    tags?: string | null,
    status?: IssueStatus | null,
    clientCreationDate?: number | null,
    localLastUpdatedTime?: number | null,
    createdBy?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListIssuesQueryVariables = {
  id?: string | null,
  filter?: ModelIssueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListIssuesQuery = {
  listIssues?:  {
    __typename: "ModelIssueConnection",
    items:  Array< {
      __typename: "Issue",
      id: string,
      locationId?: string | null,
      propertyId?: string | null,
      inspectionId?: string | null,
      createdInSolution?: boolean | null,
      name?: string | null,
      description?: string | null,
      note?: string | null,
      tags?: string | null,
      status?: IssueStatus | null,
      clientCreationDate?: number | null,
      localLastUpdatedTime?: number | null,
      createdBy?: string | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncIssuesQueryVariables = {
  filter?: ModelIssueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncIssuesQuery = {
  syncIssues?:  {
    __typename: "ModelIssueConnection",
    items:  Array< {
      __typename: "Issue",
      id: string,
      locationId?: string | null,
      propertyId?: string | null,
      inspectionId?: string | null,
      createdInSolution?: boolean | null,
      name?: string | null,
      description?: string | null,
      note?: string | null,
      tags?: string | null,
      status?: IssueStatus | null,
      clientCreationDate?: number | null,
      localLastUpdatedTime?: number | null,
      createdBy?: string | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListIssuesByLocationQueryVariables = {
  locationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIssueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIssuesByLocationQuery = {
  listIssuesByLocation?:  {
    __typename: "ModelIssueConnection",
    items:  Array< {
      __typename: "Issue",
      id: string,
      locationId?: string | null,
      propertyId?: string | null,
      inspectionId?: string | null,
      createdInSolution?: boolean | null,
      name?: string | null,
      description?: string | null,
      note?: string | null,
      tags?: string | null,
      status?: IssueStatus | null,
      clientCreationDate?: number | null,
      localLastUpdatedTime?: number | null,
      createdBy?: string | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListIssuesByPropertyQueryVariables = {
  propertyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIssueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIssuesByPropertyQuery = {
  listIssuesByProperty?:  {
    __typename: "ModelIssueConnection",
    items:  Array< {
      __typename: "Issue",
      id: string,
      locationId?: string | null,
      propertyId?: string | null,
      inspectionId?: string | null,
      createdInSolution?: boolean | null,
      name?: string | null,
      description?: string | null,
      note?: string | null,
      tags?: string | null,
      status?: IssueStatus | null,
      clientCreationDate?: number | null,
      localLastUpdatedTime?: number | null,
      createdBy?: string | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListIssuesByInspectionQueryVariables = {
  inspectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIssueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIssuesByInspectionQuery = {
  listIssuesByInspection?:  {
    __typename: "ModelIssueConnection",
    items:  Array< {
      __typename: "Issue",
      id: string,
      locationId?: string | null,
      propertyId?: string | null,
      inspectionId?: string | null,
      createdInSolution?: boolean | null,
      name?: string | null,
      description?: string | null,
      note?: string | null,
      tags?: string | null,
      status?: IssueStatus | null,
      clientCreationDate?: number | null,
      localLastUpdatedTime?: number | null,
      createdBy?: string | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLocationQueryVariables = {
  id: string,
};

export type GetLocationQuery = {
  getLocation?:  {
    __typename: "Location",
    id: string,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    tags?: string | null,
    localCreationTime?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLocationsQueryVariables = {
  id?: string | null,
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLocationsQuery = {
  listLocations?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      propertyId?: string | null,
      createdInSolution?: boolean | null,
      name?: string | null,
      tags?: string | null,
      localCreationTime?: number | null,
      localLastUpdatedTime?: number | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLocationsQueryVariables = {
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLocationsQuery = {
  syncLocations?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      propertyId?: string | null,
      createdInSolution?: boolean | null,
      name?: string | null,
      tags?: string | null,
      localCreationTime?: number | null,
      localLastUpdatedTime?: number | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListLocationsByPropertyQueryVariables = {
  propertyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationsByPropertyQuery = {
  listLocationsByProperty?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      propertyId?: string | null,
      createdInSolution?: boolean | null,
      name?: string | null,
      tags?: string | null,
      localCreationTime?: number | null,
      localLastUpdatedTime?: number | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNoteQueryVariables = {
  id: string,
};

export type GetNoteQuery = {
  getNote?:  {
    __typename: "Note",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    note?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNotesQueryVariables = {
  id?: string | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNotesQuery = {
  listNotes?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      parentId?: string | null,
      propertyId?: string | null,
      note?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNotesQueryVariables = {
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNotesQuery = {
  syncNotes?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      parentId?: string | null,
      propertyId?: string | null,
      note?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListNotesByParentQueryVariables = {
  parentId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotesByParentQuery = {
  listNotesByParent?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      parentId?: string | null,
      propertyId?: string | null,
      note?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListNotesByPropertyQueryVariables = {
  propertyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotesByPropertyQuery = {
  listNotesByProperty?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      parentId?: string | null,
      propertyId?: string | null,
      note?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    legalName: string,
    type?: OrganizationType | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  id?: string | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      legalName: string,
      type?: OrganizationType | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrganizationsQuery = {
  syncOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      legalName: string,
      type?: OrganizationType | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProjectQueryVariables = {
  id: string,
};

export type GetProjectQuery = {
  getProject?:  {
    __typename: "Project",
    id: string,
    propertyId: string,
    name: string,
    tenderNumber?: string | null,
    customer?: string | null,
    address?: string | null,
    propertyType?: string | null,
    dueDate?: string | null,
    contactName?: string | null,
    contactNumber?: string | null,
    contactEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListProjectsQueryVariables = {
  id?: string | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProjectsQuery = {
  listProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      propertyId: string,
      name: string,
      tenderNumber?: string | null,
      customer?: string | null,
      address?: string | null,
      propertyType?: string | null,
      dueDate?: string | null,
      contactName?: string | null,
      contactNumber?: string | null,
      contactEmail?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProjectsQueryVariables = {
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProjectsQuery = {
  syncProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      propertyId: string,
      name: string,
      tenderNumber?: string | null,
      customer?: string | null,
      address?: string | null,
      propertyType?: string | null,
      dueDate?: string | null,
      contactName?: string | null,
      contactNumber?: string | null,
      contactEmail?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListDesignProjectsByPropertyQueryVariables = {
  propertyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDesignProjectsByPropertyQuery = {
  listDesignProjectsByProperty?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      propertyId: string,
      name: string,
      tenderNumber?: string | null,
      customer?: string | null,
      address?: string | null,
      propertyType?: string | null,
      dueDate?: string | null,
      contactName?: string | null,
      contactNumber?: string | null,
      contactEmail?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProjectCounterQueryVariables = {
  id: string,
};

export type GetProjectCounterQuery = {
  getProjectCounter?:  {
    __typename: "ProjectCounter",
    id: string,
    propertyId: string,
    latestProjectNumber?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListProjectCountersQueryVariables = {
  id?: string | null,
  filter?: ModelProjectCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProjectCountersQuery = {
  listProjectCounters?:  {
    __typename: "ModelProjectCounterConnection",
    items:  Array< {
      __typename: "ProjectCounter",
      id: string,
      propertyId: string,
      latestProjectNumber?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProjectCountersQueryVariables = {
  filter?: ModelProjectCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProjectCountersQuery = {
  syncProjectCounters?:  {
    __typename: "ModelProjectCounterConnection",
    items:  Array< {
      __typename: "ProjectCounter",
      id: string,
      propertyId: string,
      latestProjectNumber?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProjectCounterByPropertyQueryVariables = {
  propertyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetProjectCounterByPropertyQuery = {
  getProjectCounterByProperty?:  {
    __typename: "ModelProjectCounterConnection",
    items:  Array< {
      __typename: "ProjectCounter",
      id: string,
      propertyId: string,
      latestProjectNumber?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPropertyQueryVariables = {
  id: string,
};

export type GetPropertyQuery = {
  getProperty?:  {
    __typename: "Property",
    id: string,
    name?: string | null,
    description?: string | null,
    address?: string | null,
    ownerOrganizationId?: string | null,
    primaryContactNumber?: string | null,
    primaryContactEmail?: string | null,
    primaryContactName?: string | null,
    propertyNumber?: string | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        propertyId?: string | null,
        createdInSolution?: boolean | null,
        name?: string | null,
        tags?: string | null,
        localCreationTime?: number | null,
        localLastUpdatedTime?: number | null,
        opaqueSyncReconciliationToken?: string | null,
        canView?: string | null,
        canEdit?: string | null,
        canDelete?: string | null,
        createdBy?: string | null,
        canViewList?: Array< string | null > | null,
        canViewEntityVersionMap?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy?: string | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canCreateSolutions?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPropertiesQueryVariables = {
  id?: string | null,
  filter?: ModelPropertyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPropertiesQuery = {
  listProperties?:  {
    __typename: "ModelPropertyConnection",
    items:  Array< {
      __typename: "Property",
      id: string,
      name?: string | null,
      description?: string | null,
      address?: string | null,
      ownerOrganizationId?: string | null,
      primaryContactNumber?: string | null,
      primaryContactEmail?: string | null,
      primaryContactName?: string | null,
      propertyNumber?: string | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdBy?: string | null,
      localLastUpdatedTime?: number | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canCreateSolutions?: string | null,
      canDelete?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPropertiesQueryVariables = {
  filter?: ModelPropertyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPropertiesQuery = {
  syncProperties?:  {
    __typename: "ModelPropertyConnection",
    items:  Array< {
      __typename: "Property",
      id: string,
      name?: string | null,
      description?: string | null,
      address?: string | null,
      ownerOrganizationId?: string | null,
      primaryContactNumber?: string | null,
      primaryContactEmail?: string | null,
      primaryContactName?: string | null,
      propertyNumber?: string | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdBy?: string | null,
      localLastUpdatedTime?: number | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canCreateSolutions?: string | null,
      canDelete?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPropertyCounterQueryVariables = {
  id: string,
};

export type GetPropertyCounterQuery = {
  getPropertyCounter?:  {
    __typename: "PropertyCounter",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPropertyCountersQueryVariables = {
  filter?: ModelPropertyCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPropertyCountersQuery = {
  listPropertyCounters?:  {
    __typename: "ModelPropertyCounterConnection",
    items:  Array< {
      __typename: "PropertyCounter",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPropertyCountersQueryVariables = {
  filter?: ModelPropertyCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPropertyCountersQuery = {
  syncPropertyCounters?:  {
    __typename: "ModelPropertyCounterConnection",
    items:  Array< {
      __typename: "PropertyCounter",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProposalQueryVariables = {
  id: string,
};

export type GetProposalQuery = {
  getProposal?:  {
    __typename: "Proposal",
    id: string,
    schemaVersion?: number | null,
    designDocumentGroupId: string,
    designDocumentVersion: number,
    proposalKey: string,
    proposalNumber?: number | null,
    status?: ProposalStatus | null,
    author?: string | null,
    createdAt?: string | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListProposalsQueryVariables = {
  filter?: ModelProposalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProposalsQuery = {
  listProposals?:  {
    __typename: "ModelProposalConnection",
    items:  Array< {
      __typename: "Proposal",
      id: string,
      schemaVersion?: number | null,
      designDocumentGroupId: string,
      designDocumentVersion: number,
      proposalKey: string,
      proposalNumber?: number | null,
      status?: ProposalStatus | null,
      author?: string | null,
      createdAt?: string | null,
      localLastUpdatedTime?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProposalsQueryVariables = {
  filter?: ModelProposalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProposalsQuery = {
  syncProposals?:  {
    __typename: "ModelProposalConnection",
    items:  Array< {
      __typename: "Proposal",
      id: string,
      schemaVersion?: number | null,
      designDocumentGroupId: string,
      designDocumentVersion: number,
      proposalKey: string,
      proposalNumber?: number | null,
      status?: ProposalStatus | null,
      author?: string | null,
      createdAt?: string | null,
      localLastUpdatedTime?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProposalByIdQueryVariables = {
  id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProposalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetProposalByIdQuery = {
  getProposalById?:  {
    __typename: "ModelProposalConnection",
    items:  Array< {
      __typename: "Proposal",
      id: string,
      schemaVersion?: number | null,
      designDocumentGroupId: string,
      designDocumentVersion: number,
      proposalKey: string,
      proposalNumber?: number | null,
      status?: ProposalStatus | null,
      author?: string | null,
      createdAt?: string | null,
      localLastUpdatedTime?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProposalsByDesignDocumentGroupIdQueryVariables = {
  designDocumentGroupId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProposalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProposalsByDesignDocumentGroupIdQuery = {
  listProposalsByDesignDocumentGroupId?:  {
    __typename: "ModelProposalConnection",
    items:  Array< {
      __typename: "Proposal",
      id: string,
      schemaVersion?: number | null,
      designDocumentGroupId: string,
      designDocumentVersion: number,
      proposalKey: string,
      proposalNumber?: number | null,
      status?: ProposalStatus | null,
      author?: string | null,
      createdAt?: string | null,
      localLastUpdatedTime?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProposalsByDesignDocumentGroupIdAndVersionQueryVariables = {
  designDocumentGroupId: string,
  designDocumentVersion?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProposalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProposalsByDesignDocumentGroupIdAndVersionQuery = {
  listProposalsByDesignDocumentGroupIdAndVersion?:  {
    __typename: "ModelProposalConnection",
    items:  Array< {
      __typename: "Proposal",
      id: string,
      schemaVersion?: number | null,
      designDocumentGroupId: string,
      designDocumentVersion: number,
      proposalKey: string,
      proposalNumber?: number | null,
      status?: ProposalStatus | null,
      author?: string | null,
      createdAt?: string | null,
      localLastUpdatedTime?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProposalsByAuthorQueryVariables = {
  author: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProposalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProposalsByAuthorQuery = {
  listProposalsByAuthor?:  {
    __typename: "ModelProposalConnection",
    items:  Array< {
      __typename: "Proposal",
      id: string,
      schemaVersion?: number | null,
      designDocumentGroupId: string,
      designDocumentVersion: number,
      proposalKey: string,
      proposalNumber?: number | null,
      status?: ProposalStatus | null,
      author?: string | null,
      createdAt?: string | null,
      localLastUpdatedTime?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProposalCounterQueryVariables = {
  id: string,
};

export type GetProposalCounterQuery = {
  getProposalCounter?:  {
    __typename: "ProposalCounter",
    id: string,
    solutionGroupId?: string | null,
    solutionVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListProposalCountersQueryVariables = {
  id?: string | null,
  filter?: ModelProposalCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProposalCountersQuery = {
  listProposalCounters?:  {
    __typename: "ModelProposalCounterConnection",
    items:  Array< {
      __typename: "ProposalCounter",
      id: string,
      solutionGroupId?: string | null,
      solutionVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProposalCountersQueryVariables = {
  filter?: ModelProposalCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProposalCountersQuery = {
  syncProposalCounters?:  {
    __typename: "ModelProposalCounterConnection",
    items:  Array< {
      __typename: "ProposalCounter",
      id: string,
      solutionGroupId?: string | null,
      solutionVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProposalCounterBySolutionByVersionQueryVariables = {
  solutionGroupId: string,
  solutionVersion?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProposalCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetProposalCounterBySolutionByVersionQuery = {
  getProposalCounterBySolutionByVersion?:  {
    __typename: "ModelProposalCounterConnection",
    items:  Array< {
      __typename: "ProposalCounter",
      id: string,
      solutionGroupId?: string | null,
      solutionVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetResourceUserPermissionsQueryVariables = {
  id: string,
};

export type GetResourceUserPermissionsQuery = {
  getResourceUserPermissions?:  {
    __typename: "ResourceUserPermissions",
    id: string,
    resourceId: string,
    userId?: string | null,
    userEmail?: string | null,
    userFullName?: string | null,
    resourceAndUserId?: string | null,
    permissions: string,
    resourceType?: ResourceType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListResourceUserPermissionsQueryVariables = {
  id?: string | null,
  filter?: ModelResourceUserPermissionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListResourceUserPermissionsQuery = {
  listResourceUserPermissions?:  {
    __typename: "ModelResourceUserPermissionsConnection",
    items:  Array< {
      __typename: "ResourceUserPermissions",
      id: string,
      resourceId: string,
      userId?: string | null,
      userEmail?: string | null,
      userFullName?: string | null,
      resourceAndUserId?: string | null,
      permissions: string,
      resourceType?: ResourceType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncResourceUserPermissionsQueryVariables = {
  filter?: ModelResourceUserPermissionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncResourceUserPermissionsQuery = {
  syncResourceUserPermissions?:  {
    __typename: "ModelResourceUserPermissionsConnection",
    items:  Array< {
      __typename: "ResourceUserPermissions",
      id: string,
      resourceId: string,
      userId?: string | null,
      userEmail?: string | null,
      userFullName?: string | null,
      resourceAndUserId?: string | null,
      permissions: string,
      resourceType?: ResourceType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListResourceUserPermissionsByResourceQueryVariables = {
  resourceId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelResourceUserPermissionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResourceUserPermissionsByResourceQuery = {
  listResourceUserPermissionsByResource?:  {
    __typename: "ModelResourceUserPermissionsConnection",
    items:  Array< {
      __typename: "ResourceUserPermissions",
      id: string,
      resourceId: string,
      userId?: string | null,
      userEmail?: string | null,
      userFullName?: string | null,
      resourceAndUserId?: string | null,
      permissions: string,
      resourceType?: ResourceType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListResourceUserPermissionsByUserIdAndResourceTypeQueryVariables = {
  userId: string,
  resourceType?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelResourceUserPermissionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResourceUserPermissionsByUserIdAndResourceTypeQuery = {
  listResourceUserPermissionsByUserIdAndResourceType?:  {
    __typename: "ModelResourceUserPermissionsConnection",
    items:  Array< {
      __typename: "ResourceUserPermissions",
      id: string,
      resourceId: string,
      userId?: string | null,
      userEmail?: string | null,
      userFullName?: string | null,
      resourceAndUserId?: string | null,
      permissions: string,
      resourceType?: ResourceType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListResourceUserPermissionsByUserEmailQueryVariables = {
  userEmail: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelResourceUserPermissionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResourceUserPermissionsByUserEmailQuery = {
  listResourceUserPermissionsByUserEmail?:  {
    __typename: "ModelResourceUserPermissionsConnection",
    items:  Array< {
      __typename: "ResourceUserPermissions",
      id: string,
      resourceId: string,
      userId?: string | null,
      userEmail?: string | null,
      userFullName?: string | null,
      resourceAndUserId?: string | null,
      permissions: string,
      resourceType?: ResourceType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListResourceUserPermissionsByResourceAndUserQueryVariables = {
  resourceAndUserId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelResourceUserPermissionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResourceUserPermissionsByResourceAndUserQuery = {
  listResourceUserPermissionsByResourceAndUser?:  {
    __typename: "ModelResourceUserPermissionsConnection",
    items:  Array< {
      __typename: "ResourceUserPermissions",
      id: string,
      resourceId: string,
      userId?: string | null,
      userEmail?: string | null,
      userFullName?: string | null,
      resourceAndUserId?: string | null,
      permissions: string,
      resourceType?: ResourceType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSolutionQueryVariables = {
  id: string,
};

export type GetSolutionQuery = {
  getSolution?:  {
    __typename: "Solution",
    id: string,
    propertyId?: string | null,
    projectId?: string | null,
    name?: string | null,
    status?: SolutionStatus | null,
    latestMinorVersion?: number | null,
    latestContentKey?: string | null,
    serialNumber?: number | null,
    metadata?:  {
      __typename: "SolutionMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
      tenderingType?: SolutionTenderingType | null,
    } | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSolutionsQueryVariables = {
  id?: string | null,
  filter?: ModelSolutionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSolutionsQuery = {
  listSolutions?:  {
    __typename: "ModelSolutionConnection",
    items:  Array< {
      __typename: "Solution",
      id: string,
      propertyId?: string | null,
      projectId?: string | null,
      name?: string | null,
      status?: SolutionStatus | null,
      latestMinorVersion?: number | null,
      latestContentKey?: string | null,
      serialNumber?: number | null,
      metadata?:  {
        __typename: "SolutionMetadata",
        name?: string | null,
        propertyContactName?: string | null,
        propertyAddress?: string | null,
        propertyType?: string | null,
        solutionDueDate?: string | null,
        consultantName?: string | null,
        consultantPhoneNumber?: string | null,
        consultantEmail?: string | null,
        tenderingType?: SolutionTenderingType | null,
      } | null,
      localLastUpdatedTime?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSolutionsQueryVariables = {
  filter?: ModelSolutionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSolutionsQuery = {
  syncSolutions?:  {
    __typename: "ModelSolutionConnection",
    items:  Array< {
      __typename: "Solution",
      id: string,
      propertyId?: string | null,
      projectId?: string | null,
      name?: string | null,
      status?: SolutionStatus | null,
      latestMinorVersion?: number | null,
      latestContentKey?: string | null,
      serialNumber?: number | null,
      metadata?:  {
        __typename: "SolutionMetadata",
        name?: string | null,
        propertyContactName?: string | null,
        propertyAddress?: string | null,
        propertyType?: string | null,
        solutionDueDate?: string | null,
        consultantName?: string | null,
        consultantPhoneNumber?: string | null,
        consultantEmail?: string | null,
        tenderingType?: SolutionTenderingType | null,
      } | null,
      localLastUpdatedTime?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListSolutionsByPropertyQueryVariables = {
  propertyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSolutionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSolutionsByPropertyQuery = {
  listSolutionsByProperty?:  {
    __typename: "ModelSolutionConnection",
    items:  Array< {
      __typename: "Solution",
      id: string,
      propertyId?: string | null,
      projectId?: string | null,
      name?: string | null,
      status?: SolutionStatus | null,
      latestMinorVersion?: number | null,
      latestContentKey?: string | null,
      serialNumber?: number | null,
      metadata?:  {
        __typename: "SolutionMetadata",
        name?: string | null,
        propertyContactName?: string | null,
        propertyAddress?: string | null,
        propertyType?: string | null,
        solutionDueDate?: string | null,
        consultantName?: string | null,
        consultantPhoneNumber?: string | null,
        consultantEmail?: string | null,
        tenderingType?: SolutionTenderingType | null,
      } | null,
      localLastUpdatedTime?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSolutionMinorVersionQueryVariables = {
  id: string,
};

export type GetSolutionMinorVersionQuery = {
  getSolutionMinorVersion?:  {
    __typename: "SolutionMinorVersion",
    id: string,
    solutionId?: string | null,
    propertyId?: string | null,
    name?: string | null,
    contentKey?: string | null,
    minorVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSolutionMinorVersionsQueryVariables = {
  id?: string | null,
  filter?: ModelSolutionMinorVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSolutionMinorVersionsQuery = {
  listSolutionMinorVersions?:  {
    __typename: "ModelSolutionMinorVersionConnection",
    items:  Array< {
      __typename: "SolutionMinorVersion",
      id: string,
      solutionId?: string | null,
      propertyId?: string | null,
      name?: string | null,
      contentKey?: string | null,
      minorVersion?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSolutionMinorVersionsQueryVariables = {
  filter?: ModelSolutionMinorVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSolutionMinorVersionsQuery = {
  syncSolutionMinorVersions?:  {
    __typename: "ModelSolutionMinorVersionConnection",
    items:  Array< {
      __typename: "SolutionMinorVersion",
      id: string,
      solutionId?: string | null,
      propertyId?: string | null,
      name?: string | null,
      contentKey?: string | null,
      minorVersion?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListSolutionMinorVersionsBySolutionByMinorVersionQueryVariables = {
  solutionId: string,
  minorVersion?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSolutionMinorVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSolutionMinorVersionsBySolutionByMinorVersionQuery = {
  listSolutionMinorVersionsBySolutionByMinorVersion?:  {
    __typename: "ModelSolutionMinorVersionConnection",
    items:  Array< {
      __typename: "SolutionMinorVersion",
      id: string,
      solutionId?: string | null,
      propertyId?: string | null,
      name?: string | null,
      contentKey?: string | null,
      minorVersion?: number | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSolutionGroupCounterQueryVariables = {
  id: string,
};

export type GetSolutionGroupCounterQuery = {
  getSolutionGroupCounter?:  {
    __typename: "SolutionGroupCounter",
    id: string,
    propertyId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSolutionGroupCountersQueryVariables = {
  id?: string | null,
  filter?: ModelSolutionGroupCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSolutionGroupCountersQuery = {
  listSolutionGroupCounters?:  {
    __typename: "ModelSolutionGroupCounterConnection",
    items:  Array< {
      __typename: "SolutionGroupCounter",
      id: string,
      propertyId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSolutionGroupCountersQueryVariables = {
  filter?: ModelSolutionGroupCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSolutionGroupCountersQuery = {
  syncSolutionGroupCounters?:  {
    __typename: "ModelSolutionGroupCounterConnection",
    items:  Array< {
      __typename: "SolutionGroupCounter",
      id: string,
      propertyId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSolutionGroupCounterByPropertyQueryVariables = {
  propertyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSolutionGroupCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSolutionGroupCounterByPropertyQuery = {
  getSolutionGroupCounterByProperty?:  {
    __typename: "ModelSolutionGroupCounterConnection",
    items:  Array< {
      __typename: "SolutionGroupCounter",
      id: string,
      propertyId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserGroupQueryVariables = {
  id: string,
};

export type GetUserGroupQuery = {
  getUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    resourceId: string,
    groupType: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelUserGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserGroupsQuery = {
  listUserGroups?:  {
    __typename: "ModelUserGroupConnection",
    items:  Array< {
      __typename: "UserGroup",
      id: string,
      resourceId: string,
      groupType: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserGroupsQueryVariables = {
  filter?: ModelUserGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserGroupsQuery = {
  syncUserGroups?:  {
    __typename: "ModelUserGroupConnection",
    items:  Array< {
      __typename: "UserGroup",
      id: string,
      resourceId: string,
      groupType: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListUserGroupsByResourceQueryVariables = {
  resourceId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserGroupsByResourceQuery = {
  listUserGroupsByResource?:  {
    __typename: "ModelUserGroupConnection",
    items:  Array< {
      __typename: "UserGroup",
      id: string,
      resourceId: string,
      groupType: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserGroupMemberQueryVariables = {
  id: string,
};

export type GetUserGroupMemberQuery = {
  getUserGroupMember?:  {
    __typename: "UserGroupMember",
    id: string,
    resourceId: string,
    resourceAndUserId: string,
    userId: string,
    userGroupId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserGroupMembersQueryVariables = {
  id?: string | null,
  filter?: ModelUserGroupMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserGroupMembersQuery = {
  listUserGroupMembers?:  {
    __typename: "ModelUserGroupMemberConnection",
    items:  Array< {
      __typename: "UserGroupMember",
      id: string,
      resourceId: string,
      resourceAndUserId: string,
      userId: string,
      userGroupId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserGroupMembersQueryVariables = {
  filter?: ModelUserGroupMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserGroupMembersQuery = {
  syncUserGroupMembers?:  {
    __typename: "ModelUserGroupMemberConnection",
    items:  Array< {
      __typename: "UserGroupMember",
      id: string,
      resourceId: string,
      resourceAndUserId: string,
      userId: string,
      userGroupId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListUserGroupMembersByResourceQueryVariables = {
  resourceId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserGroupMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserGroupMembersByResourceQuery = {
  listUserGroupMembersByResource?:  {
    __typename: "ModelUserGroupMemberConnection",
    items:  Array< {
      __typename: "UserGroupMember",
      id: string,
      resourceId: string,
      resourceAndUserId: string,
      userId: string,
      userGroupId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListUserGroupMembersByResourceAndUserQueryVariables = {
  resourceAndUserId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserGroupMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserGroupMembersByResourceAndUserQuery = {
  listUserGroupMembersByResourceAndUser?:  {
    __typename: "ModelUserGroupMemberConnection",
    items:  Array< {
      __typename: "UserGroupMember",
      id: string,
      resourceId: string,
      resourceAndUserId: string,
      userId: string,
      userGroupId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserOrganizationAssociationQueryVariables = {
  id: string,
};

export type GetUserOrganizationAssociationQuery = {
  getUserOrganizationAssociation?:  {
    __typename: "UserOrganizationAssociation",
    id: string,
    userId: string,
    organizationId: string,
    associationType: UserOrganizationAssociationType,
    permissions?: string | null,
    role?: UserOrganizationRole | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserOrganizationAssociationsQueryVariables = {
  id?: string | null,
  filter?: ModelUserOrganizationAssociationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserOrganizationAssociationsQuery = {
  listUserOrganizationAssociations?:  {
    __typename: "ModelUserOrganizationAssociationConnection",
    items:  Array< {
      __typename: "UserOrganizationAssociation",
      id: string,
      userId: string,
      organizationId: string,
      associationType: UserOrganizationAssociationType,
      permissions?: string | null,
      role?: UserOrganizationRole | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserOrganizationAssociationsQueryVariables = {
  filter?: ModelUserOrganizationAssociationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserOrganizationAssociationsQuery = {
  syncUserOrganizationAssociations?:  {
    __typename: "ModelUserOrganizationAssociationConnection",
    items:  Array< {
      __typename: "UserOrganizationAssociation",
      id: string,
      userId: string,
      organizationId: string,
      associationType: UserOrganizationAssociationType,
      permissions?: string | null,
      role?: UserOrganizationRole | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListUserOrganizationAssociationsByUserAndAssociationTypeQueryVariables = {
  userId: string,
  associationType?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserOrganizationAssociationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserOrganizationAssociationsByUserAndAssociationTypeQuery = {
  listUserOrganizationAssociationsByUserAndAssociationType?:  {
    __typename: "ModelUserOrganizationAssociationConnection",
    items:  Array< {
      __typename: "UserOrganizationAssociation",
      id: string,
      userId: string,
      organizationId: string,
      associationType: UserOrganizationAssociationType,
      permissions?: string | null,
      role?: UserOrganizationRole | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListUserOrganizationAssociationsByOrganizationAndAssociationTypeQueryVariables = {
  organizationId: string,
  associationType?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserOrganizationAssociationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserOrganizationAssociationsByOrganizationAndAssociationTypeQuery = {
  listUserOrganizationAssociationsByOrganizationAndAssociationType?:  {
    __typename: "ModelUserOrganizationAssociationConnection",
    items:  Array< {
      __typename: "UserOrganizationAssociation",
      id: string,
      userId: string,
      organizationId: string,
      associationType: UserOrganizationAssociationType,
      permissions?: string | null,
      role?: UserOrganizationRole | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWorkSpecificationQueryVariables = {
  id: string,
};

export type GetWorkSpecificationQuery = {
  getWorkSpecification?:  {
    __typename: "WorkSpecification",
    id: string,
    dimensionId?: string | null,
    issueId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    body?: string | null,
    description?: string | null,
    workTypeId?: string | null,
    itemNumber?: number | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    version?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWorkSpecificationsQueryVariables = {
  id?: string | null,
  filter?: ModelWorkSpecificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListWorkSpecificationsQuery = {
  listWorkSpecifications?:  {
    __typename: "ModelWorkSpecificationConnection",
    items:  Array< {
      __typename: "WorkSpecification",
      id: string,
      dimensionId?: string | null,
      issueId?: string | null,
      propertyId?: string | null,
      createdInSolution?: boolean | null,
      body?: string | null,
      description?: string | null,
      workTypeId?: string | null,
      itemNumber?: number | null,
      measurement?:  {
        __typename: "Measurement",
        length?: number | null,
        width?: number | null,
        dimensionType?: DimensionType | null,
        measurementUnit?: MeasurementUnit | null,
      } | null,
      version?: number | null,
      localLastUpdatedTime?: number | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncWorkSpecificationsQueryVariables = {
  filter?: ModelWorkSpecificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncWorkSpecificationsQuery = {
  syncWorkSpecifications?:  {
    __typename: "ModelWorkSpecificationConnection",
    items:  Array< {
      __typename: "WorkSpecification",
      id: string,
      dimensionId?: string | null,
      issueId?: string | null,
      propertyId?: string | null,
      createdInSolution?: boolean | null,
      body?: string | null,
      description?: string | null,
      workTypeId?: string | null,
      itemNumber?: number | null,
      measurement?:  {
        __typename: "Measurement",
        length?: number | null,
        width?: number | null,
        dimensionType?: DimensionType | null,
        measurementUnit?: MeasurementUnit | null,
      } | null,
      version?: number | null,
      localLastUpdatedTime?: number | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListWorkScopeSpecificationsByDimensionIdQueryVariables = {
  dimensionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkSpecificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkScopeSpecificationsByDimensionIdQuery = {
  listWorkScopeSpecificationsByDimensionId?:  {
    __typename: "ModelWorkSpecificationConnection",
    items:  Array< {
      __typename: "WorkSpecification",
      id: string,
      dimensionId?: string | null,
      issueId?: string | null,
      propertyId?: string | null,
      createdInSolution?: boolean | null,
      body?: string | null,
      description?: string | null,
      workTypeId?: string | null,
      itemNumber?: number | null,
      measurement?:  {
        __typename: "Measurement",
        length?: number | null,
        width?: number | null,
        dimensionType?: DimensionType | null,
        measurementUnit?: MeasurementUnit | null,
      } | null,
      version?: number | null,
      localLastUpdatedTime?: number | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListWorkScopeSpecificationsByIssueIdQueryVariables = {
  issueId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkSpecificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkScopeSpecificationsByIssueIdQuery = {
  listWorkScopeSpecificationsByIssueId?:  {
    __typename: "ModelWorkSpecificationConnection",
    items:  Array< {
      __typename: "WorkSpecification",
      id: string,
      dimensionId?: string | null,
      issueId?: string | null,
      propertyId?: string | null,
      createdInSolution?: boolean | null,
      body?: string | null,
      description?: string | null,
      workTypeId?: string | null,
      itemNumber?: number | null,
      measurement?:  {
        __typename: "Measurement",
        length?: number | null,
        width?: number | null,
        dimensionType?: DimensionType | null,
        measurementUnit?: MeasurementUnit | null,
      } | null,
      version?: number | null,
      localLastUpdatedTime?: number | null,
      opaqueSyncReconciliationToken?: string | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdBy?: string | null,
      canViewList?: Array< string | null > | null,
      canViewEntityVersionMap?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWorkTypeQueryVariables = {
  id: string,
};

export type GetWorkTypeQuery = {
  getWorkType?:  {
    __typename: "WorkType",
    id: string,
    name: string,
    shortDescription?: string | null,
    longDescription?: string | null,
    groupName?: string | null,
    unitPrice?: number | null,
    unitCost?: number | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWorkTypesQueryVariables = {
  id?: string | null,
  filter?: ModelWorkTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListWorkTypesQuery = {
  listWorkTypes?:  {
    __typename: "ModelWorkTypeConnection",
    items:  Array< {
      __typename: "WorkType",
      id: string,
      name: string,
      shortDescription?: string | null,
      longDescription?: string | null,
      groupName?: string | null,
      unitPrice?: number | null,
      unitCost?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncWorkTypesQueryVariables = {
  filter?: ModelWorkTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncWorkTypesQuery = {
  syncWorkTypes?:  {
    __typename: "ModelWorkTypeConnection",
    items:  Array< {
      __typename: "WorkType",
      id: string,
      name: string,
      shortDescription?: string | null,
      longDescription?: string | null,
      groupName?: string | null,
      unitPrice?: number | null,
      unitCost?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWorkTypeGroupQueryVariables = {
  id: string,
};

export type GetWorkTypeGroupQuery = {
  getWorkTypeGroup?:  {
    __typename: "WorkTypeGroup",
    id: string,
    latestWorkTypeVersion: number,
    environmentAgnosticWorkTypeGroupId?: string | null,
    isDiscoverable?: boolean | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWorkTypeGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelWorkTypeGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListWorkTypeGroupsQuery = {
  listWorkTypeGroups?:  {
    __typename: "ModelWorkTypeGroupConnection",
    items:  Array< {
      __typename: "WorkTypeGroup",
      id: string,
      latestWorkTypeVersion: number,
      environmentAgnosticWorkTypeGroupId?: string | null,
      isDiscoverable?: boolean | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncWorkTypeGroupsQueryVariables = {
  filter?: ModelWorkTypeGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncWorkTypeGroupsQuery = {
  syncWorkTypeGroups?:  {
    __typename: "ModelWorkTypeGroupConnection",
    items:  Array< {
      __typename: "WorkTypeGroup",
      id: string,
      latestWorkTypeVersion: number,
      environmentAgnosticWorkTypeGroupId?: string | null,
      isDiscoverable?: boolean | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWorkTypePricingQueryVariables = {
  id: string,
};

export type GetWorkTypePricingQuery = {
  getWorkTypePricing?:  {
    __typename: "WorkTypePricing",
    id: string,
    entityId: string,
    workTypeGroupId: string,
    body: string,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWorkTypePricingsQueryVariables = {
  id?: string | null,
  filter?: ModelWorkTypePricingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListWorkTypePricingsQuery = {
  listWorkTypePricings?:  {
    __typename: "ModelWorkTypePricingConnection",
    items:  Array< {
      __typename: "WorkTypePricing",
      id: string,
      entityId: string,
      workTypeGroupId: string,
      body: string,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncWorkTypePricingsQueryVariables = {
  filter?: ModelWorkTypePricingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncWorkTypePricingsQuery = {
  syncWorkTypePricings?:  {
    __typename: "ModelWorkTypePricingConnection",
    items:  Array< {
      __typename: "WorkTypePricing",
      id: string,
      entityId: string,
      workTypeGroupId: string,
      body: string,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListWorkTypePricingByEntityIdByWorkTypeGroupIdQueryVariables = {
  entityId: string,
  workTypeGroupId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkTypePricingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkTypePricingByEntityIdByWorkTypeGroupIdQuery = {
  listWorkTypePricingByEntityIdByWorkTypeGroupId?:  {
    __typename: "ModelWorkTypePricingConnection",
    items:  Array< {
      __typename: "WorkTypePricing",
      id: string,
      entityId: string,
      workTypeGroupId: string,
      body: string,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDefaultWorkTypePricingQueryVariables = {
  id: string,
};

export type GetDefaultWorkTypePricingQuery = {
  getDefaultWorkTypePricing?:  {
    __typename: "DefaultWorkTypePricing",
    id: string,
    unitCost?: number | null,
    unitPrice?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDefaultWorkTypePricingsQueryVariables = {
  id?: string | null,
  filter?: ModelDefaultWorkTypePricingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDefaultWorkTypePricingsQuery = {
  listDefaultWorkTypePricings?:  {
    __typename: "ModelDefaultWorkTypePricingConnection",
    items:  Array< {
      __typename: "DefaultWorkTypePricing",
      id: string,
      unitCost?: number | null,
      unitPrice?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDefaultWorkTypePricingsQueryVariables = {
  filter?: ModelDefaultWorkTypePricingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDefaultWorkTypePricingsQuery = {
  syncDefaultWorkTypePricings?:  {
    __typename: "ModelDefaultWorkTypePricingConnection",
    items:  Array< {
      __typename: "DefaultWorkTypePricing",
      id: string,
      unitCost?: number | null,
      unitPrice?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTemporaryWorkTypeQueryVariables = {
  id: string,
};

export type GetTemporaryWorkTypeQuery = {
  getTemporaryWorkType?:  {
    __typename: "TemporaryWorkType",
    id: string,
    workTypeGroupId: string,
    environmentAgnosticWorkTypeGroupId?: string | null,
    body: string,
    version: number,
    schemaVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTemporaryWorkTypesQueryVariables = {
  filter?: ModelTemporaryWorkTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemporaryWorkTypesQuery = {
  listTemporaryWorkTypes?:  {
    __typename: "ModelTemporaryWorkTypeConnection",
    items:  Array< {
      __typename: "TemporaryWorkType",
      id: string,
      workTypeGroupId: string,
      environmentAgnosticWorkTypeGroupId?: string | null,
      body: string,
      version: number,
      schemaVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTemporaryWorkTypesQueryVariables = {
  filter?: ModelTemporaryWorkTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTemporaryWorkTypesQuery = {
  syncTemporaryWorkTypes?:  {
    __typename: "ModelTemporaryWorkTypeConnection",
    items:  Array< {
      __typename: "TemporaryWorkType",
      id: string,
      workTypeGroupId: string,
      environmentAgnosticWorkTypeGroupId?: string | null,
      body: string,
      version: number,
      schemaVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWorkTypeByWorkTypeGroupIdAndVersionQueryVariables = {
  workTypeGroupId: string,
  version?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemporaryWorkTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetWorkTypeByWorkTypeGroupIdAndVersionQuery = {
  getWorkTypeByWorkTypeGroupIdAndVersion?:  {
    __typename: "ModelTemporaryWorkTypeConnection",
    items:  Array< {
      __typename: "TemporaryWorkType",
      id: string,
      workTypeGroupId: string,
      environmentAgnosticWorkTypeGroupId?: string | null,
      body: string,
      version: number,
      schemaVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWorkTypeByEnvironmentAgnosticWorkTypeGroupIdQueryVariables = {
  environmentAgnosticWorkTypeGroupId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemporaryWorkTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetWorkTypeByEnvironmentAgnosticWorkTypeGroupIdQuery = {
  getWorkTypeByEnvironmentAgnosticWorkTypeGroupId?:  {
    __typename: "ModelTemporaryWorkTypeConnection",
    items:  Array< {
      __typename: "TemporaryWorkType",
      id: string,
      workTypeGroupId: string,
      environmentAgnosticWorkTypeGroupId?: string | null,
      body: string,
      version: number,
      schemaVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTenderQueryVariables = {
  id: string,
};

export type GetTenderQuery = {
  getTender?:  {
    __typename: "Tender",
    id: string,
    solutionId?: string | null,
    status?: TenderStatus | null,
    latestVersion?: number | null,
    awardedVersion?: number | null,
    projectNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTendersQueryVariables = {
  filter?: ModelTenderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTendersQuery = {
  listTenders?:  {
    __typename: "ModelTenderConnection",
    items:  Array< {
      __typename: "Tender",
      id: string,
      solutionId?: string | null,
      status?: TenderStatus | null,
      latestVersion?: number | null,
      awardedVersion?: number | null,
      projectNumber?: number | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTendersQueryVariables = {
  filter?: ModelTenderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTendersQuery = {
  syncTenders?:  {
    __typename: "ModelTenderConnection",
    items:  Array< {
      __typename: "Tender",
      id: string,
      solutionId?: string | null,
      status?: TenderStatus | null,
      latestVersion?: number | null,
      awardedVersion?: number | null,
      projectNumber?: number | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTendersByProjectNumberQueryVariables = {
  projectNumber: number,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTendersByProjectNumberQuery = {
  listTendersByProjectNumber?:  {
    __typename: "ModelTenderConnection",
    items:  Array< {
      __typename: "Tender",
      id: string,
      solutionId?: string | null,
      status?: TenderStatus | null,
      latestVersion?: number | null,
      awardedVersion?: number | null,
      projectNumber?: number | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTenderMinorVersionQueryVariables = {
  id: string,
};

export type GetTenderMinorVersionQuery = {
  getTenderMinorVersion?:  {
    __typename: "TenderMinorVersion",
    id: string,
    tenderId?: string | null,
    version?: number | null,
    solutionId?: string | null,
    solutionMinorVersion?: number | null,
    metadata?:  {
      __typename: "TenderMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
    } | null,
    contentKey?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTenderMinorVersionsQueryVariables = {
  filter?: ModelTenderMinorVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenderMinorVersionsQuery = {
  listTenderMinorVersions?:  {
    __typename: "ModelTenderMinorVersionConnection",
    items:  Array< {
      __typename: "TenderMinorVersion",
      id: string,
      tenderId?: string | null,
      version?: number | null,
      solutionId?: string | null,
      solutionMinorVersion?: number | null,
      metadata?:  {
        __typename: "TenderMetadata",
        name?: string | null,
        propertyContactName?: string | null,
        propertyAddress?: string | null,
        propertyType?: string | null,
        solutionDueDate?: string | null,
        consultantName?: string | null,
        consultantPhoneNumber?: string | null,
        consultantEmail?: string | null,
      } | null,
      contentKey?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTenderMinorVersionsQueryVariables = {
  filter?: ModelTenderMinorVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTenderMinorVersionsQuery = {
  syncTenderMinorVersions?:  {
    __typename: "ModelTenderMinorVersionConnection",
    items:  Array< {
      __typename: "TenderMinorVersion",
      id: string,
      tenderId?: string | null,
      version?: number | null,
      solutionId?: string | null,
      solutionMinorVersion?: number | null,
      metadata?:  {
        __typename: "TenderMetadata",
        name?: string | null,
        propertyContactName?: string | null,
        propertyAddress?: string | null,
        propertyType?: string | null,
        solutionDueDate?: string | null,
        consultantName?: string | null,
        consultantPhoneNumber?: string | null,
        consultantEmail?: string | null,
      } | null,
      contentKey?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTenderMinorVersionsByTenderIdQueryVariables = {
  tenderId: string,
  version?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenderMinorVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenderMinorVersionsByTenderIdQuery = {
  listTenderMinorVersionsByTenderId?:  {
    __typename: "ModelTenderMinorVersionConnection",
    items:  Array< {
      __typename: "TenderMinorVersion",
      id: string,
      tenderId?: string | null,
      version?: number | null,
      solutionId?: string | null,
      solutionMinorVersion?: number | null,
      metadata?:  {
        __typename: "TenderMetadata",
        name?: string | null,
        propertyContactName?: string | null,
        propertyAddress?: string | null,
        propertyType?: string | null,
        solutionDueDate?: string | null,
        consultantName?: string | null,
        consultantPhoneNumber?: string | null,
        consultantEmail?: string | null,
      } | null,
      contentKey?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserOnboardingProgressQueryVariables = {
  id: string,
};

export type GetUserOnboardingProgressQuery = {
  getUserOnboardingProgress?:  {
    __typename: "UserOnboardingProgress",
    id: string,
    userId: string,
    hasUserBeenAssociatedWithOrganization: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserOnboardingProgressesQueryVariables = {
  id?: string | null,
  filter?: ModelUserOnboardingProgressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserOnboardingProgressesQuery = {
  listUserOnboardingProgresses?:  {
    __typename: "ModelUserOnboardingProgressConnection",
    items:  Array< {
      __typename: "UserOnboardingProgress",
      id: string,
      userId: string,
      hasUserBeenAssociatedWithOrganization: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserOnboardingProgressesQueryVariables = {
  filter?: ModelUserOnboardingProgressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserOnboardingProgressesQuery = {
  syncUserOnboardingProgresses?:  {
    __typename: "ModelUserOnboardingProgressConnection",
    items:  Array< {
      __typename: "UserOnboardingProgress",
      id: string,
      userId: string,
      hasUserBeenAssociatedWithOrganization: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserOnboardingProgressByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserOnboardingProgressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserOnboardingProgressByUserIdQuery = {
  getUserOnboardingProgressByUserId?:  {
    __typename: "ModelUserOnboardingProgressConnection",
    items:  Array< {
      __typename: "UserOnboardingProgress",
      id: string,
      userId: string,
      hasUserBeenAssociatedWithOrganization: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  id?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTemplateQueryVariables = {
  id: string,
};

export type GetTemplateQuery = {
  getTemplate?:  {
    __typename: "Template",
    id: string,
    type?: TemplateType | null,
    name?: string | null,
    s3Key?: string | null,
    ownerId?: string | null,
    ownerType?: TemplateEntityType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTemplatesQueryVariables = {
  id?: string | null,
  filter?: ModelTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTemplatesQuery = {
  listTemplates?:  {
    __typename: "ModelTemplateConnection",
    items:  Array< {
      __typename: "Template",
      id: string,
      type?: TemplateType | null,
      name?: string | null,
      s3Key?: string | null,
      ownerId?: string | null,
      ownerType?: TemplateEntityType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTemplatesQueryVariables = {
  filter?: ModelTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTemplatesQuery = {
  syncTemplates?:  {
    __typename: "ModelTemplateConnection",
    items:  Array< {
      __typename: "Template",
      id: string,
      type?: TemplateType | null,
      name?: string | null,
      s3Key?: string | null,
      ownerId?: string | null,
      ownerType?: TemplateEntityType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTemplatesByOwnerEntityIdQueryVariables = {
  ownerId: string,
  ownerType?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplatesByOwnerEntityIdQuery = {
  listTemplatesByOwnerEntityId?:  {
    __typename: "ModelTemplateConnection",
    items:  Array< {
      __typename: "Template",
      id: string,
      type?: TemplateType | null,
      name?: string | null,
      s3Key?: string | null,
      ownerId?: string | null,
      ownerType?: TemplateEntityType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTemporaryAuthorizationCodeQueryVariables = {
  id: string,
};

export type GetTemporaryAuthorizationCodeQuery = {
  getTemporaryAuthorizationCode?:  {
    __typename: "TemporaryAuthorizationCode",
    id: string,
    code?: string | null,
    userId?: string | null,
    expiresAt?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTemporaryAuthorizationCodesQueryVariables = {
  id?: string | null,
  filter?: ModelTemporaryAuthorizationCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTemporaryAuthorizationCodesQuery = {
  listTemporaryAuthorizationCodes?:  {
    __typename: "ModelTemporaryAuthorizationCodeConnection",
    items:  Array< {
      __typename: "TemporaryAuthorizationCode",
      id: string,
      code?: string | null,
      userId?: string | null,
      expiresAt?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTemporaryAuthorizationCodesQueryVariables = {
  filter?: ModelTemporaryAuthorizationCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTemporaryAuthorizationCodesQuery = {
  syncTemporaryAuthorizationCodes?:  {
    __typename: "ModelTemporaryAuthorizationCodeConnection",
    items:  Array< {
      __typename: "TemporaryAuthorizationCode",
      id: string,
      code?: string | null,
      userId?: string | null,
      expiresAt?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTemporaryAuthorizationCodesByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemporaryAuthorizationCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemporaryAuthorizationCodesByUserIdQuery = {
  listTemporaryAuthorizationCodesByUserId?:  {
    __typename: "ModelTemporaryAuthorizationCodeConnection",
    items:  Array< {
      __typename: "TemporaryAuthorizationCode",
      id: string,
      code?: string | null,
      userId?: string | null,
      expiresAt?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLinkRegistryQueryVariables = {
  id: string,
};

export type GetLinkRegistryQuery = {
  getLinkRegistry?:  {
    __typename: "LinkRegistry",
    id: string,
    action?: LinkAction | null,
    variables?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLinkRegistriesQueryVariables = {
  id?: string | null,
  filter?: ModelLinkRegistryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLinkRegistriesQuery = {
  listLinkRegistries?:  {
    __typename: "ModelLinkRegistryConnection",
    items:  Array< {
      __typename: "LinkRegistry",
      id: string,
      action?: LinkAction | null,
      variables?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLinkRegistriesQueryVariables = {
  filter?: ModelLinkRegistryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLinkRegistriesQuery = {
  syncLinkRegistries?:  {
    __typename: "ModelLinkRegistryConnection",
    items:  Array< {
      __typename: "LinkRegistry",
      id: string,
      action?: LinkAction | null,
      variables?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetExternalReportBrandingQueryVariables = {
  id: string,
};

export type GetExternalReportBrandingQuery = {
  getExternalReportBranding?:  {
    __typename: "ExternalReportBranding",
    id: string,
    name?: string | null,
    logoKey?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListExternalReportBrandingsQueryVariables = {
  id?: string | null,
  filter?: ModelExternalReportBrandingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListExternalReportBrandingsQuery = {
  listExternalReportBrandings?:  {
    __typename: "ModelExternalReportBrandingConnection",
    items:  Array< {
      __typename: "ExternalReportBranding",
      id: string,
      name?: string | null,
      logoKey?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncExternalReportBrandingsQueryVariables = {
  filter?: ModelExternalReportBrandingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncExternalReportBrandingsQuery = {
  syncExternalReportBrandings?:  {
    __typename: "ModelExternalReportBrandingConnection",
    items:  Array< {
      __typename: "ExternalReportBranding",
      id: string,
      name?: string | null,
      logoKey?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListExternalReportBrandingsByCreatedByQueryVariables = {
  createdBy: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExternalReportBrandingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExternalReportBrandingsByCreatedByQuery = {
  listExternalReportBrandingsByCreatedBy?:  {
    __typename: "ModelExternalReportBrandingConnection",
    items:  Array< {
      __typename: "ExternalReportBranding",
      id: string,
      name?: string | null,
      logoKey?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMeetingQueryVariables = {
  id: string,
};

export type GetMeetingQuery = {
  getMeeting?:  {
    __typename: "Meeting",
    id: string,
    status?: MeetingStatus | null,
    data: string,
    createdBy?: string | null,
    mediaPipelineId?: string | null,
    recordingKey?: string | null,
    recordingAttachmentId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMeetingsQueryVariables = {
  id?: string | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMeetingsQuery = {
  listMeetings?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      status?: MeetingStatus | null,
      data: string,
      createdBy?: string | null,
      mediaPipelineId?: string | null,
      recordingKey?: string | null,
      recordingAttachmentId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMeetingsQueryVariables = {
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMeetingsQuery = {
  syncMeetings?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      status?: MeetingStatus | null,
      data: string,
      createdBy?: string | null,
      mediaPipelineId?: string | null,
      recordingKey?: string | null,
      recordingAttachmentId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingsByMediaPipelineIdQueryVariables = {
  mediaPipelineId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsByMediaPipelineIdQuery = {
  listMeetingsByMediaPipelineId?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      status?: MeetingStatus | null,
      data: string,
      createdBy?: string | null,
      mediaPipelineId?: string | null,
      recordingKey?: string | null,
      recordingAttachmentId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAttendeeQueryVariables = {
  id: string,
};

export type GetAttendeeQuery = {
  getAttendee?:  {
    __typename: "Attendee",
    id: string,
    meetingId: string,
    entityId?: string | null,
    name?: string | null,
    role?: AttendeeRole | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAttendeesQueryVariables = {
  id?: string | null,
  filter?: ModelAttendeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAttendeesQuery = {
  listAttendees?:  {
    __typename: "ModelAttendeeConnection",
    items:  Array< {
      __typename: "Attendee",
      id: string,
      meetingId: string,
      entityId?: string | null,
      name?: string | null,
      role?: AttendeeRole | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAttendeesQueryVariables = {
  filter?: ModelAttendeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAttendeesQuery = {
  syncAttendees?:  {
    __typename: "ModelAttendeeConnection",
    items:  Array< {
      __typename: "Attendee",
      id: string,
      meetingId: string,
      entityId?: string | null,
      name?: string | null,
      role?: AttendeeRole | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListAttendeesByMeetingIdQueryVariables = {
  meetingId: string,
  entityId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAttendeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttendeesByMeetingIdQuery = {
  listAttendeesByMeetingId?:  {
    __typename: "ModelAttendeeConnection",
    items:  Array< {
      __typename: "Attendee",
      id: string,
      meetingId: string,
      entityId?: string | null,
      name?: string | null,
      role?: AttendeeRole | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPreSignedUploadURLQueryVariables = {
  input: GetPreSignedUploadURLRequest,
};

export type GetPreSignedUploadURLQuery = {
  getPreSignedUploadURL?: string | null,
};

export type GetPreSignedDownloadURLQueryVariables = {
  input: GetPreSignedDownloadRequest,
};

export type GetPreSignedDownloadURLQuery = {
  getPreSignedDownloadURL?: string | null,
};

export type GetAccountClaimStatusQuery = {
  getAccountClaimStatus?:  {
    __typename: "GetAccountClaimStatusResponse",
    status?: AccountClaimStatus | null,
  } | null,
};

export type CustomSearchUsersQueryVariables = {
  input: SearchUsersVariables,
};

export type CustomSearchUsersQuery = {
  customSearchUsers?:  {
    __typename: "SearchUsersOutput",
    items?:  Array< {
      __typename: "User",
      id: string,
      name?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type CustomSearchOrganizationsQueryVariables = {
  input: SearchOrganizationsVariables,
};

export type CustomSearchOrganizationsQuery = {
  customSearchOrganizations?:  {
    __typename: "SearchOrganizationsOutput",
    items?:  Array< {
      __typename: "Organization",
      id: string,
      legalName: string,
      type?: OrganizationType | null,
      canView?: string | null,
      canEdit?: string | null,
      canDelete?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnCompleteEventSubscriptionVariables = {
  executionId: string,
};

export type OnCompleteEventSubscription = {
  onCompleteEvent?:  {
    __typename: "CompleteEvent",
    executionId: string,
    status: CompleteEventStatus,
    result?: string | null,
  } | null,
};

export type OnCreateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnCreateAttachmentSubscription = {
  onCreateAttachment?:  {
    __typename: "Attachment",
    id: string,
    type?: AttachmentType | null,
    parentId: string,
    parentType?: PermissionResourceType | null,
    propertyId?: string | null,
    key?: string | null,
    localCreatedAtTime?: number | null,
    status?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnUpdateAttachmentSubscription = {
  onUpdateAttachment?:  {
    __typename: "Attachment",
    id: string,
    type?: AttachmentType | null,
    parentId: string,
    parentType?: PermissionResourceType | null,
    propertyId?: string | null,
    key?: string | null,
    localCreatedAtTime?: number | null,
    status?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnDeleteAttachmentSubscription = {
  onDeleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    type?: AttachmentType | null,
    parentId: string,
    parentType?: PermissionResourceType | null,
    propertyId?: string | null,
    key?: string | null,
    localCreatedAtTime?: number | null,
    status?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBiddingContactSubscriptionVariables = {
  filter?: ModelSubscriptionBiddingContactFilterInput | null,
};

export type OnCreateBiddingContactSubscription = {
  onCreateBiddingContact?:  {
    __typename: "BiddingContact",
    id: string,
    email: string,
    ownerEntityId?: string | null,
    contactEntityId?: string | null,
    name?: string | null,
    privateDescription?: string | null,
    publicDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBiddingContactSubscriptionVariables = {
  filter?: ModelSubscriptionBiddingContactFilterInput | null,
};

export type OnUpdateBiddingContactSubscription = {
  onUpdateBiddingContact?:  {
    __typename: "BiddingContact",
    id: string,
    email: string,
    ownerEntityId?: string | null,
    contactEntityId?: string | null,
    name?: string | null,
    privateDescription?: string | null,
    publicDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBiddingContactSubscriptionVariables = {
  filter?: ModelSubscriptionBiddingContactFilterInput | null,
};

export type OnDeleteBiddingContactSubscription = {
  onDeleteBiddingContact?:  {
    __typename: "BiddingContact",
    id: string,
    email: string,
    ownerEntityId?: string | null,
    contactEntityId?: string | null,
    name?: string | null,
    privateDescription?: string | null,
    publicDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBidInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionBidInvitationFilterInput | null,
};

export type OnCreateBidInvitationSubscription = {
  onCreateBidInvitation?:  {
    __typename: "BidInvitation",
    id: string,
    biddingContactId?: string | null,
    tenderId?: string | null,
    ownerEntityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBidInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionBidInvitationFilterInput | null,
};

export type OnUpdateBidInvitationSubscription = {
  onUpdateBidInvitation?:  {
    __typename: "BidInvitation",
    id: string,
    biddingContactId?: string | null,
    tenderId?: string | null,
    ownerEntityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBidInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionBidInvitationFilterInput | null,
};

export type OnDeleteBidInvitationSubscription = {
  onDeleteBidInvitation?:  {
    __typename: "BidInvitation",
    id: string,
    biddingContactId?: string | null,
    tenderId?: string | null,
    ownerEntityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBidSubscriptionVariables = {
  filter?: ModelSubscriptionBidFilterInput | null,
};

export type OnCreateBidSubscription = {
  onCreateBid?:  {
    __typename: "Bid",
    id: string,
    tenderId?: string | null,
    latestMinorVersion?: number | null,
    latestAcceptedMinorVersion?: number | null,
    latestSubmittedMinorVersion?: number | null,
    status?: BidStatus | null,
    bidNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBidSubscriptionVariables = {
  filter?: ModelSubscriptionBidFilterInput | null,
};

export type OnUpdateBidSubscription = {
  onUpdateBid?:  {
    __typename: "Bid",
    id: string,
    tenderId?: string | null,
    latestMinorVersion?: number | null,
    latestAcceptedMinorVersion?: number | null,
    latestSubmittedMinorVersion?: number | null,
    status?: BidStatus | null,
    bidNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBidSubscriptionVariables = {
  filter?: ModelSubscriptionBidFilterInput | null,
};

export type OnDeleteBidSubscription = {
  onDeleteBid?:  {
    __typename: "Bid",
    id: string,
    tenderId?: string | null,
    latestMinorVersion?: number | null,
    latestAcceptedMinorVersion?: number | null,
    latestSubmittedMinorVersion?: number | null,
    status?: BidStatus | null,
    bidNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBidMinorVersionSubscriptionVariables = {
  filter?: ModelSubscriptionBidMinorVersionFilterInput | null,
};

export type OnCreateBidMinorVersionSubscription = {
  onCreateBidMinorVersion?:  {
    __typename: "BidMinorVersion",
    id: string,
    bidId?: string | null,
    minorVersion?: number | null,
    tenderId?: string | null,
    tenderMinorVersion?: number | null,
    proposalId?: string | null,
    status?: DocumentStatus | null,
    metadata?:  {
      __typename: "BidMetadata",
      bidderContactName?: string | null,
      bidderContactEmail?: string | null,
      bidderCompanyName?: string | null,
      bidderContactAddress?: string | null,
      bidderContactPhoneNumber?: string | null,
      bidderWebsite?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBidMinorVersionSubscriptionVariables = {
  filter?: ModelSubscriptionBidMinorVersionFilterInput | null,
};

export type OnUpdateBidMinorVersionSubscription = {
  onUpdateBidMinorVersion?:  {
    __typename: "BidMinorVersion",
    id: string,
    bidId?: string | null,
    minorVersion?: number | null,
    tenderId?: string | null,
    tenderMinorVersion?: number | null,
    proposalId?: string | null,
    status?: DocumentStatus | null,
    metadata?:  {
      __typename: "BidMetadata",
      bidderContactName?: string | null,
      bidderContactEmail?: string | null,
      bidderCompanyName?: string | null,
      bidderContactAddress?: string | null,
      bidderContactPhoneNumber?: string | null,
      bidderWebsite?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBidMinorVersionSubscriptionVariables = {
  filter?: ModelSubscriptionBidMinorVersionFilterInput | null,
};

export type OnDeleteBidMinorVersionSubscription = {
  onDeleteBidMinorVersion?:  {
    __typename: "BidMinorVersion",
    id: string,
    bidId?: string | null,
    minorVersion?: number | null,
    tenderId?: string | null,
    tenderMinorVersion?: number | null,
    proposalId?: string | null,
    status?: DocumentStatus | null,
    metadata?:  {
      __typename: "BidMetadata",
      bidderContactName?: string | null,
      bidderContactEmail?: string | null,
      bidderCompanyName?: string | null,
      bidderContactAddress?: string | null,
      bidderContactPhoneNumber?: string | null,
      bidderWebsite?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateClientLogSubscriptionVariables = {
  filter?: ModelSubscriptionClientLogFilterInput | null,
};

export type OnCreateClientLogSubscription = {
  onCreateClientLog?:  {
    __typename: "ClientLog",
    id: string,
    userId?: string | null,
    userAgent?: string | null,
    severityLevel: LogSeverityLevel,
    componentName: string,
    message?: string | null,
    details?: string | null,
    tags?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateClientLogSubscriptionVariables = {
  filter?: ModelSubscriptionClientLogFilterInput | null,
};

export type OnUpdateClientLogSubscription = {
  onUpdateClientLog?:  {
    __typename: "ClientLog",
    id: string,
    userId?: string | null,
    userAgent?: string | null,
    severityLevel: LogSeverityLevel,
    componentName: string,
    message?: string | null,
    details?: string | null,
    tags?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteClientLogSubscriptionVariables = {
  filter?: ModelSubscriptionClientLogFilterInput | null,
};

export type OnDeleteClientLogSubscription = {
  onDeleteClientLog?:  {
    __typename: "ClientLog",
    id: string,
    userId?: string | null,
    userAgent?: string | null,
    severityLevel: LogSeverityLevel,
    componentName: string,
    message?: string | null,
    details?: string | null,
    tags?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCounterSubscriptionVariables = {
  filter?: ModelSubscriptionCounterFilterInput | null,
};

export type OnCreateCounterSubscription = {
  onCreateCounter?:  {
    __typename: "Counter",
    id: string,
    type: ResourceType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCounterSubscriptionVariables = {
  filter?: ModelSubscriptionCounterFilterInput | null,
};

export type OnUpdateCounterSubscription = {
  onUpdateCounter?:  {
    __typename: "Counter",
    id: string,
    type: ResourceType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCounterSubscriptionVariables = {
  filter?: ModelSubscriptionCounterFilterInput | null,
};

export type OnDeleteCounterSubscription = {
  onDeleteCounter?:  {
    __typename: "Counter",
    id: string,
    type: ResourceType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDesignDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDesignDocumentFilterInput | null,
};

export type OnCreateDesignDocumentSubscription = {
  onCreateDesignDocument?:  {
    __typename: "DesignDocument",
    id: string,
    designDocumentGroupId?: string | null,
    propertyId: string,
    projectId: string,
    name: string,
    status?: DesignDocumentStatus | null,
    lastEvaluatedValidityStatus?: boolean | null,
    uploadStatus?: string | null,
    documentKey?: string | null,
    lastWorkScopeSpecificationItemNumber?: number | null,
    version?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDesignDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDesignDocumentFilterInput | null,
};

export type OnUpdateDesignDocumentSubscription = {
  onUpdateDesignDocument?:  {
    __typename: "DesignDocument",
    id: string,
    designDocumentGroupId?: string | null,
    propertyId: string,
    projectId: string,
    name: string,
    status?: DesignDocumentStatus | null,
    lastEvaluatedValidityStatus?: boolean | null,
    uploadStatus?: string | null,
    documentKey?: string | null,
    lastWorkScopeSpecificationItemNumber?: number | null,
    version?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDesignDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDesignDocumentFilterInput | null,
};

export type OnDeleteDesignDocumentSubscription = {
  onDeleteDesignDocument?:  {
    __typename: "DesignDocument",
    id: string,
    designDocumentGroupId?: string | null,
    propertyId: string,
    projectId: string,
    name: string,
    status?: DesignDocumentStatus | null,
    lastEvaluatedValidityStatus?: boolean | null,
    uploadStatus?: string | null,
    documentKey?: string | null,
    lastWorkScopeSpecificationItemNumber?: number | null,
    version?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDesignDocumentGroupSubscriptionVariables = {
  filter?: ModelSubscriptionDesignDocumentGroupFilterInput | null,
};

export type OnCreateDesignDocumentGroupSubscription = {
  onCreateDesignDocumentGroup?:  {
    __typename: "DesignDocumentGroup",
    id: string,
    projectId: string,
    propertyId: string,
    name: string,
    latestDocumentVersion: number,
    documentContentKey?: string | null,
    solutionGroupNumber?: number | null,
    isLocked: boolean,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDesignDocumentGroupSubscriptionVariables = {
  filter?: ModelSubscriptionDesignDocumentGroupFilterInput | null,
};

export type OnUpdateDesignDocumentGroupSubscription = {
  onUpdateDesignDocumentGroup?:  {
    __typename: "DesignDocumentGroup",
    id: string,
    projectId: string,
    propertyId: string,
    name: string,
    latestDocumentVersion: number,
    documentContentKey?: string | null,
    solutionGroupNumber?: number | null,
    isLocked: boolean,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDesignDocumentGroupSubscriptionVariables = {
  filter?: ModelSubscriptionDesignDocumentGroupFilterInput | null,
};

export type OnDeleteDesignDocumentGroupSubscription = {
  onDeleteDesignDocumentGroup?:  {
    __typename: "DesignDocumentGroup",
    id: string,
    projectId: string,
    propertyId: string,
    name: string,
    latestDocumentVersion: number,
    documentContentKey?: string | null,
    solutionGroupNumber?: number | null,
    isLocked: boolean,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDimensionSubscriptionVariables = {
  filter?: ModelSubscriptionDimensionFilterInput | null,
};

export type OnCreateDimensionSubscription = {
  onCreateDimension?:  {
    __typename: "Dimension",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    description?: string | null,
    length?: number | null,
    lengthUnit?: string | null,
    width?: number | null,
    widthUnit?: string | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDimensionSubscriptionVariables = {
  filter?: ModelSubscriptionDimensionFilterInput | null,
};

export type OnUpdateDimensionSubscription = {
  onUpdateDimension?:  {
    __typename: "Dimension",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    description?: string | null,
    length?: number | null,
    lengthUnit?: string | null,
    width?: number | null,
    widthUnit?: string | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDimensionSubscriptionVariables = {
  filter?: ModelSubscriptionDimensionFilterInput | null,
};

export type OnDeleteDimensionSubscription = {
  onDeleteDimension?:  {
    __typename: "Dimension",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    description?: string | null,
    length?: number | null,
    lengthUnit?: string | null,
    width?: number | null,
    widthUnit?: string | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDiscoverableWorkTypesSubscriptionVariables = {
  filter?: ModelSubscriptionDiscoverableWorkTypesFilterInput | null,
};

export type OnCreateDiscoverableWorkTypesSubscription = {
  onCreateDiscoverableWorkTypes?:  {
    __typename: "DiscoverableWorkTypes",
    id: string,
    workTypeGroupId: string,
    defaultVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDiscoverableWorkTypesSubscriptionVariables = {
  filter?: ModelSubscriptionDiscoverableWorkTypesFilterInput | null,
};

export type OnUpdateDiscoverableWorkTypesSubscription = {
  onUpdateDiscoverableWorkTypes?:  {
    __typename: "DiscoverableWorkTypes",
    id: string,
    workTypeGroupId: string,
    defaultVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDiscoverableWorkTypesSubscriptionVariables = {
  filter?: ModelSubscriptionDiscoverableWorkTypesFilterInput | null,
};

export type OnDeleteDiscoverableWorkTypesSubscription = {
  onDeleteDiscoverableWorkTypes?:  {
    __typename: "DiscoverableWorkTypes",
    id: string,
    workTypeGroupId: string,
    defaultVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateEntityResourcePermissionSubscriptionVariables = {
  filter?: ModelSubscriptionEntityResourcePermissionFilterInput | null,
};

export type OnCreateEntityResourcePermissionSubscription = {
  onCreateEntityResourcePermission?:  {
    __typename: "EntityResourcePermission",
    id: string,
    propertyId?: string | null,
    entityId?: string | null,
    entityType?: PermissionEntityType | null,
    resourceId?: string | null,
    resourceType?: PermissionResourceType | null,
    permissionType?: PermissionType | null,
    isGranted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateEntityResourcePermissionSubscriptionVariables = {
  filter?: ModelSubscriptionEntityResourcePermissionFilterInput | null,
};

export type OnUpdateEntityResourcePermissionSubscription = {
  onUpdateEntityResourcePermission?:  {
    __typename: "EntityResourcePermission",
    id: string,
    propertyId?: string | null,
    entityId?: string | null,
    entityType?: PermissionEntityType | null,
    resourceId?: string | null,
    resourceType?: PermissionResourceType | null,
    permissionType?: PermissionType | null,
    isGranted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteEntityResourcePermissionSubscriptionVariables = {
  filter?: ModelSubscriptionEntityResourcePermissionFilterInput | null,
};

export type OnDeleteEntityResourcePermissionSubscription = {
  onDeleteEntityResourcePermission?:  {
    __typename: "EntityResourcePermission",
    id: string,
    propertyId?: string | null,
    entityId?: string | null,
    entityType?: PermissionEntityType | null,
    resourceId?: string | null,
    resourceType?: PermissionResourceType | null,
    permissionType?: PermissionType | null,
    isGranted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateEntityWorkTypeAssociationSubscriptionVariables = {
  filter?: ModelSubscriptionEntityWorkTypeAssociationFilterInput | null,
};

export type OnCreateEntityWorkTypeAssociationSubscription = {
  onCreateEntityWorkTypeAssociation?:  {
    __typename: "EntityWorkTypeAssociation",
    id: string,
    entityId: string,
    entityType: WorkTypeAssociationScopeEntity,
    workTypeGroupId: string,
    workTypeVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateEntityWorkTypeAssociationSubscriptionVariables = {
  filter?: ModelSubscriptionEntityWorkTypeAssociationFilterInput | null,
};

export type OnUpdateEntityWorkTypeAssociationSubscription = {
  onUpdateEntityWorkTypeAssociation?:  {
    __typename: "EntityWorkTypeAssociation",
    id: string,
    entityId: string,
    entityType: WorkTypeAssociationScopeEntity,
    workTypeGroupId: string,
    workTypeVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteEntityWorkTypeAssociationSubscriptionVariables = {
  filter?: ModelSubscriptionEntityWorkTypeAssociationFilterInput | null,
};

export type OnDeleteEntityWorkTypeAssociationSubscription = {
  onDeleteEntityWorkTypeAssociation?:  {
    __typename: "EntityWorkTypeAssociation",
    id: string,
    entityId: string,
    entityType: WorkTypeAssociationScopeEntity,
    workTypeGroupId: string,
    workTypeVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateInspectionSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionFilterInput | null,
};

export type OnCreateInspectionSubscription = {
  onCreateInspection?:  {
    __typename: "Inspection",
    id: string,
    propertyId: string,
    createdByOrganizationId: string,
    createdByUserId: string,
    startTime: number,
    endTime?: number | null,
    status: InspectionStatus,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInspectionSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionFilterInput | null,
};

export type OnUpdateInspectionSubscription = {
  onUpdateInspection?:  {
    __typename: "Inspection",
    id: string,
    propertyId: string,
    createdByOrganizationId: string,
    createdByUserId: string,
    startTime: number,
    endTime?: number | null,
    status: InspectionStatus,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInspectionSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionFilterInput | null,
};

export type OnDeleteInspectionSubscription = {
  onDeleteInspection?:  {
    __typename: "Inspection",
    id: string,
    propertyId: string,
    createdByOrganizationId: string,
    createdByUserId: string,
    startTime: number,
    endTime?: number | null,
    status: InspectionStatus,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateIssueSubscriptionVariables = {
  filter?: ModelSubscriptionIssueFilterInput | null,
};

export type OnCreateIssueSubscription = {
  onCreateIssue?:  {
    __typename: "Issue",
    id: string,
    locationId?: string | null,
    propertyId?: string | null,
    inspectionId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    description?: string | null,
    note?: string | null,
    tags?: string | null,
    status?: IssueStatus | null,
    clientCreationDate?: number | null,
    localLastUpdatedTime?: number | null,
    createdBy?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateIssueSubscriptionVariables = {
  filter?: ModelSubscriptionIssueFilterInput | null,
};

export type OnUpdateIssueSubscription = {
  onUpdateIssue?:  {
    __typename: "Issue",
    id: string,
    locationId?: string | null,
    propertyId?: string | null,
    inspectionId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    description?: string | null,
    note?: string | null,
    tags?: string | null,
    status?: IssueStatus | null,
    clientCreationDate?: number | null,
    localLastUpdatedTime?: number | null,
    createdBy?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteIssueSubscriptionVariables = {
  filter?: ModelSubscriptionIssueFilterInput | null,
};

export type OnDeleteIssueSubscription = {
  onDeleteIssue?:  {
    __typename: "Issue",
    id: string,
    locationId?: string | null,
    propertyId?: string | null,
    inspectionId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    description?: string | null,
    note?: string | null,
    tags?: string | null,
    status?: IssueStatus | null,
    clientCreationDate?: number | null,
    localLastUpdatedTime?: number | null,
    createdBy?: string | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnCreateLocationSubscription = {
  onCreateLocation?:  {
    __typename: "Location",
    id: string,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    tags?: string | null,
    localCreationTime?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnUpdateLocationSubscription = {
  onUpdateLocation?:  {
    __typename: "Location",
    id: string,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    tags?: string | null,
    localCreationTime?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnDeleteLocationSubscription = {
  onDeleteLocation?:  {
    __typename: "Location",
    id: string,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    name?: string | null,
    tags?: string | null,
    localCreationTime?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNoteSubscriptionVariables = {
  filter?: ModelSubscriptionNoteFilterInput | null,
};

export type OnCreateNoteSubscription = {
  onCreateNote?:  {
    __typename: "Note",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    note?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNoteSubscriptionVariables = {
  filter?: ModelSubscriptionNoteFilterInput | null,
};

export type OnUpdateNoteSubscription = {
  onUpdateNote?:  {
    __typename: "Note",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    note?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNoteSubscriptionVariables = {
  filter?: ModelSubscriptionNoteFilterInput | null,
};

export type OnDeleteNoteSubscription = {
  onDeleteNote?:  {
    __typename: "Note",
    id: string,
    parentId?: string | null,
    propertyId?: string | null,
    note?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    legalName: string,
    type?: OrganizationType | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    legalName: string,
    type?: OrganizationType | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    id: string,
    legalName: string,
    type?: OrganizationType | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnCreateProjectSubscription = {
  onCreateProject?:  {
    __typename: "Project",
    id: string,
    propertyId: string,
    name: string,
    tenderNumber?: string | null,
    customer?: string | null,
    address?: string | null,
    propertyType?: string | null,
    dueDate?: string | null,
    contactName?: string | null,
    contactNumber?: string | null,
    contactEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnUpdateProjectSubscription = {
  onUpdateProject?:  {
    __typename: "Project",
    id: string,
    propertyId: string,
    name: string,
    tenderNumber?: string | null,
    customer?: string | null,
    address?: string | null,
    propertyType?: string | null,
    dueDate?: string | null,
    contactName?: string | null,
    contactNumber?: string | null,
    contactEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnDeleteProjectSubscription = {
  onDeleteProject?:  {
    __typename: "Project",
    id: string,
    propertyId: string,
    name: string,
    tenderNumber?: string | null,
    customer?: string | null,
    address?: string | null,
    propertyType?: string | null,
    dueDate?: string | null,
    contactName?: string | null,
    contactNumber?: string | null,
    contactEmail?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateProjectCounterSubscriptionVariables = {
  filter?: ModelSubscriptionProjectCounterFilterInput | null,
};

export type OnCreateProjectCounterSubscription = {
  onCreateProjectCounter?:  {
    __typename: "ProjectCounter",
    id: string,
    propertyId: string,
    latestProjectNumber?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateProjectCounterSubscriptionVariables = {
  filter?: ModelSubscriptionProjectCounterFilterInput | null,
};

export type OnUpdateProjectCounterSubscription = {
  onUpdateProjectCounter?:  {
    __typename: "ProjectCounter",
    id: string,
    propertyId: string,
    latestProjectNumber?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteProjectCounterSubscriptionVariables = {
  filter?: ModelSubscriptionProjectCounterFilterInput | null,
};

export type OnDeleteProjectCounterSubscription = {
  onDeleteProjectCounter?:  {
    __typename: "ProjectCounter",
    id: string,
    propertyId: string,
    latestProjectNumber?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePropertySubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFilterInput | null,
};

export type OnCreatePropertySubscription = {
  onCreateProperty?:  {
    __typename: "Property",
    id: string,
    name?: string | null,
    description?: string | null,
    address?: string | null,
    ownerOrganizationId?: string | null,
    primaryContactNumber?: string | null,
    primaryContactEmail?: string | null,
    primaryContactName?: string | null,
    propertyNumber?: string | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        propertyId?: string | null,
        createdInSolution?: boolean | null,
        name?: string | null,
        tags?: string | null,
        localCreationTime?: number | null,
        localLastUpdatedTime?: number | null,
        opaqueSyncReconciliationToken?: string | null,
        canView?: string | null,
        canEdit?: string | null,
        canDelete?: string | null,
        createdBy?: string | null,
        canViewList?: Array< string | null > | null,
        canViewEntityVersionMap?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy?: string | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canCreateSolutions?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePropertySubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFilterInput | null,
};

export type OnUpdatePropertySubscription = {
  onUpdateProperty?:  {
    __typename: "Property",
    id: string,
    name?: string | null,
    description?: string | null,
    address?: string | null,
    ownerOrganizationId?: string | null,
    primaryContactNumber?: string | null,
    primaryContactEmail?: string | null,
    primaryContactName?: string | null,
    propertyNumber?: string | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        propertyId?: string | null,
        createdInSolution?: boolean | null,
        name?: string | null,
        tags?: string | null,
        localCreationTime?: number | null,
        localLastUpdatedTime?: number | null,
        opaqueSyncReconciliationToken?: string | null,
        canView?: string | null,
        canEdit?: string | null,
        canDelete?: string | null,
        createdBy?: string | null,
        canViewList?: Array< string | null > | null,
        canViewEntityVersionMap?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy?: string | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canCreateSolutions?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePropertySubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFilterInput | null,
};

export type OnDeletePropertySubscription = {
  onDeleteProperty?:  {
    __typename: "Property",
    id: string,
    name?: string | null,
    description?: string | null,
    address?: string | null,
    ownerOrganizationId?: string | null,
    primaryContactNumber?: string | null,
    primaryContactEmail?: string | null,
    primaryContactName?: string | null,
    propertyNumber?: string | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        propertyId?: string | null,
        createdInSolution?: boolean | null,
        name?: string | null,
        tags?: string | null,
        localCreationTime?: number | null,
        localLastUpdatedTime?: number | null,
        opaqueSyncReconciliationToken?: string | null,
        canView?: string | null,
        canEdit?: string | null,
        canDelete?: string | null,
        createdBy?: string | null,
        canViewList?: Array< string | null > | null,
        canViewEntityVersionMap?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy?: string | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canCreateSolutions?: string | null,
    canDelete?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePropertyCounterSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyCounterFilterInput | null,
};

export type OnCreatePropertyCounterSubscription = {
  onCreatePropertyCounter?:  {
    __typename: "PropertyCounter",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePropertyCounterSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyCounterFilterInput | null,
};

export type OnUpdatePropertyCounterSubscription = {
  onUpdatePropertyCounter?:  {
    __typename: "PropertyCounter",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePropertyCounterSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyCounterFilterInput | null,
};

export type OnDeletePropertyCounterSubscription = {
  onDeletePropertyCounter?:  {
    __typename: "PropertyCounter",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateProposalSubscriptionVariables = {
  filter?: ModelSubscriptionProposalFilterInput | null,
};

export type OnCreateProposalSubscription = {
  onCreateProposal?:  {
    __typename: "Proposal",
    id: string,
    schemaVersion?: number | null,
    designDocumentGroupId: string,
    designDocumentVersion: number,
    proposalKey: string,
    proposalNumber?: number | null,
    status?: ProposalStatus | null,
    author?: string | null,
    createdAt?: string | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateProposalSubscriptionVariables = {
  filter?: ModelSubscriptionProposalFilterInput | null,
};

export type OnUpdateProposalSubscription = {
  onUpdateProposal?:  {
    __typename: "Proposal",
    id: string,
    schemaVersion?: number | null,
    designDocumentGroupId: string,
    designDocumentVersion: number,
    proposalKey: string,
    proposalNumber?: number | null,
    status?: ProposalStatus | null,
    author?: string | null,
    createdAt?: string | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteProposalSubscriptionVariables = {
  filter?: ModelSubscriptionProposalFilterInput | null,
};

export type OnDeleteProposalSubscription = {
  onDeleteProposal?:  {
    __typename: "Proposal",
    id: string,
    schemaVersion?: number | null,
    designDocumentGroupId: string,
    designDocumentVersion: number,
    proposalKey: string,
    proposalNumber?: number | null,
    status?: ProposalStatus | null,
    author?: string | null,
    createdAt?: string | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateProposalCounterSubscriptionVariables = {
  filter?: ModelSubscriptionProposalCounterFilterInput | null,
};

export type OnCreateProposalCounterSubscription = {
  onCreateProposalCounter?:  {
    __typename: "ProposalCounter",
    id: string,
    solutionGroupId?: string | null,
    solutionVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateProposalCounterSubscriptionVariables = {
  filter?: ModelSubscriptionProposalCounterFilterInput | null,
};

export type OnUpdateProposalCounterSubscription = {
  onUpdateProposalCounter?:  {
    __typename: "ProposalCounter",
    id: string,
    solutionGroupId?: string | null,
    solutionVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteProposalCounterSubscriptionVariables = {
  filter?: ModelSubscriptionProposalCounterFilterInput | null,
};

export type OnDeleteProposalCounterSubscription = {
  onDeleteProposalCounter?:  {
    __typename: "ProposalCounter",
    id: string,
    solutionGroupId?: string | null,
    solutionVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateResourceUserPermissionsSubscriptionVariables = {
  filter?: ModelSubscriptionResourceUserPermissionsFilterInput | null,
};

export type OnCreateResourceUserPermissionsSubscription = {
  onCreateResourceUserPermissions?:  {
    __typename: "ResourceUserPermissions",
    id: string,
    resourceId: string,
    userId?: string | null,
    userEmail?: string | null,
    userFullName?: string | null,
    resourceAndUserId?: string | null,
    permissions: string,
    resourceType?: ResourceType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateResourceUserPermissionsSubscriptionVariables = {
  filter?: ModelSubscriptionResourceUserPermissionsFilterInput | null,
};

export type OnUpdateResourceUserPermissionsSubscription = {
  onUpdateResourceUserPermissions?:  {
    __typename: "ResourceUserPermissions",
    id: string,
    resourceId: string,
    userId?: string | null,
    userEmail?: string | null,
    userFullName?: string | null,
    resourceAndUserId?: string | null,
    permissions: string,
    resourceType?: ResourceType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteResourceUserPermissionsSubscriptionVariables = {
  filter?: ModelSubscriptionResourceUserPermissionsFilterInput | null,
};

export type OnDeleteResourceUserPermissionsSubscription = {
  onDeleteResourceUserPermissions?:  {
    __typename: "ResourceUserPermissions",
    id: string,
    resourceId: string,
    userId?: string | null,
    userEmail?: string | null,
    userFullName?: string | null,
    resourceAndUserId?: string | null,
    permissions: string,
    resourceType?: ResourceType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSolutionSubscriptionVariables = {
  filter?: ModelSubscriptionSolutionFilterInput | null,
};

export type OnCreateSolutionSubscription = {
  onCreateSolution?:  {
    __typename: "Solution",
    id: string,
    propertyId?: string | null,
    projectId?: string | null,
    name?: string | null,
    status?: SolutionStatus | null,
    latestMinorVersion?: number | null,
    latestContentKey?: string | null,
    serialNumber?: number | null,
    metadata?:  {
      __typename: "SolutionMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
      tenderingType?: SolutionTenderingType | null,
    } | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSolutionSubscriptionVariables = {
  filter?: ModelSubscriptionSolutionFilterInput | null,
};

export type OnUpdateSolutionSubscription = {
  onUpdateSolution?:  {
    __typename: "Solution",
    id: string,
    propertyId?: string | null,
    projectId?: string | null,
    name?: string | null,
    status?: SolutionStatus | null,
    latestMinorVersion?: number | null,
    latestContentKey?: string | null,
    serialNumber?: number | null,
    metadata?:  {
      __typename: "SolutionMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
      tenderingType?: SolutionTenderingType | null,
    } | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSolutionSubscriptionVariables = {
  filter?: ModelSubscriptionSolutionFilterInput | null,
};

export type OnDeleteSolutionSubscription = {
  onDeleteSolution?:  {
    __typename: "Solution",
    id: string,
    propertyId?: string | null,
    projectId?: string | null,
    name?: string | null,
    status?: SolutionStatus | null,
    latestMinorVersion?: number | null,
    latestContentKey?: string | null,
    serialNumber?: number | null,
    metadata?:  {
      __typename: "SolutionMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
      tenderingType?: SolutionTenderingType | null,
    } | null,
    localLastUpdatedTime?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSolutionMinorVersionSubscriptionVariables = {
  filter?: ModelSubscriptionSolutionMinorVersionFilterInput | null,
};

export type OnCreateSolutionMinorVersionSubscription = {
  onCreateSolutionMinorVersion?:  {
    __typename: "SolutionMinorVersion",
    id: string,
    solutionId?: string | null,
    propertyId?: string | null,
    name?: string | null,
    contentKey?: string | null,
    minorVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSolutionMinorVersionSubscriptionVariables = {
  filter?: ModelSubscriptionSolutionMinorVersionFilterInput | null,
};

export type OnUpdateSolutionMinorVersionSubscription = {
  onUpdateSolutionMinorVersion?:  {
    __typename: "SolutionMinorVersion",
    id: string,
    solutionId?: string | null,
    propertyId?: string | null,
    name?: string | null,
    contentKey?: string | null,
    minorVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSolutionMinorVersionSubscriptionVariables = {
  filter?: ModelSubscriptionSolutionMinorVersionFilterInput | null,
};

export type OnDeleteSolutionMinorVersionSubscription = {
  onDeleteSolutionMinorVersion?:  {
    __typename: "SolutionMinorVersion",
    id: string,
    solutionId?: string | null,
    propertyId?: string | null,
    name?: string | null,
    contentKey?: string | null,
    minorVersion?: number | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSolutionGroupCounterSubscriptionVariables = {
  filter?: ModelSubscriptionSolutionGroupCounterFilterInput | null,
};

export type OnCreateSolutionGroupCounterSubscription = {
  onCreateSolutionGroupCounter?:  {
    __typename: "SolutionGroupCounter",
    id: string,
    propertyId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSolutionGroupCounterSubscriptionVariables = {
  filter?: ModelSubscriptionSolutionGroupCounterFilterInput | null,
};

export type OnUpdateSolutionGroupCounterSubscription = {
  onUpdateSolutionGroupCounter?:  {
    __typename: "SolutionGroupCounter",
    id: string,
    propertyId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSolutionGroupCounterSubscriptionVariables = {
  filter?: ModelSubscriptionSolutionGroupCounterFilterInput | null,
};

export type OnDeleteSolutionGroupCounterSubscription = {
  onDeleteSolutionGroupCounter?:  {
    __typename: "SolutionGroupCounter",
    id: string,
    propertyId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserGroupSubscriptionVariables = {
  filter?: ModelSubscriptionUserGroupFilterInput | null,
};

export type OnCreateUserGroupSubscription = {
  onCreateUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    resourceId: string,
    groupType: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserGroupSubscriptionVariables = {
  filter?: ModelSubscriptionUserGroupFilterInput | null,
};

export type OnUpdateUserGroupSubscription = {
  onUpdateUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    resourceId: string,
    groupType: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserGroupSubscriptionVariables = {
  filter?: ModelSubscriptionUserGroupFilterInput | null,
};

export type OnDeleteUserGroupSubscription = {
  onDeleteUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    resourceId: string,
    groupType: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserGroupMemberSubscriptionVariables = {
  filter?: ModelSubscriptionUserGroupMemberFilterInput | null,
};

export type OnCreateUserGroupMemberSubscription = {
  onCreateUserGroupMember?:  {
    __typename: "UserGroupMember",
    id: string,
    resourceId: string,
    resourceAndUserId: string,
    userId: string,
    userGroupId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserGroupMemberSubscriptionVariables = {
  filter?: ModelSubscriptionUserGroupMemberFilterInput | null,
};

export type OnUpdateUserGroupMemberSubscription = {
  onUpdateUserGroupMember?:  {
    __typename: "UserGroupMember",
    id: string,
    resourceId: string,
    resourceAndUserId: string,
    userId: string,
    userGroupId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserGroupMemberSubscriptionVariables = {
  filter?: ModelSubscriptionUserGroupMemberFilterInput | null,
};

export type OnDeleteUserGroupMemberSubscription = {
  onDeleteUserGroupMember?:  {
    __typename: "UserGroupMember",
    id: string,
    resourceId: string,
    resourceAndUserId: string,
    userId: string,
    userGroupId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserOrganizationAssociationSubscriptionVariables = {
  filter?: ModelSubscriptionUserOrganizationAssociationFilterInput | null,
};

export type OnCreateUserOrganizationAssociationSubscription = {
  onCreateUserOrganizationAssociation?:  {
    __typename: "UserOrganizationAssociation",
    id: string,
    userId: string,
    organizationId: string,
    associationType: UserOrganizationAssociationType,
    permissions?: string | null,
    role?: UserOrganizationRole | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserOrganizationAssociationSubscriptionVariables = {
  filter?: ModelSubscriptionUserOrganizationAssociationFilterInput | null,
};

export type OnUpdateUserOrganizationAssociationSubscription = {
  onUpdateUserOrganizationAssociation?:  {
    __typename: "UserOrganizationAssociation",
    id: string,
    userId: string,
    organizationId: string,
    associationType: UserOrganizationAssociationType,
    permissions?: string | null,
    role?: UserOrganizationRole | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserOrganizationAssociationSubscriptionVariables = {
  filter?: ModelSubscriptionUserOrganizationAssociationFilterInput | null,
};

export type OnDeleteUserOrganizationAssociationSubscription = {
  onDeleteUserOrganizationAssociation?:  {
    __typename: "UserOrganizationAssociation",
    id: string,
    userId: string,
    organizationId: string,
    associationType: UserOrganizationAssociationType,
    permissions?: string | null,
    role?: UserOrganizationRole | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateWorkSpecificationSubscriptionVariables = {
  filter?: ModelSubscriptionWorkSpecificationFilterInput | null,
};

export type OnCreateWorkSpecificationSubscription = {
  onCreateWorkSpecification?:  {
    __typename: "WorkSpecification",
    id: string,
    dimensionId?: string | null,
    issueId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    body?: string | null,
    description?: string | null,
    workTypeId?: string | null,
    itemNumber?: number | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    version?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateWorkSpecificationSubscriptionVariables = {
  filter?: ModelSubscriptionWorkSpecificationFilterInput | null,
};

export type OnUpdateWorkSpecificationSubscription = {
  onUpdateWorkSpecification?:  {
    __typename: "WorkSpecification",
    id: string,
    dimensionId?: string | null,
    issueId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    body?: string | null,
    description?: string | null,
    workTypeId?: string | null,
    itemNumber?: number | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    version?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteWorkSpecificationSubscriptionVariables = {
  filter?: ModelSubscriptionWorkSpecificationFilterInput | null,
};

export type OnDeleteWorkSpecificationSubscription = {
  onDeleteWorkSpecification?:  {
    __typename: "WorkSpecification",
    id: string,
    dimensionId?: string | null,
    issueId?: string | null,
    propertyId?: string | null,
    createdInSolution?: boolean | null,
    body?: string | null,
    description?: string | null,
    workTypeId?: string | null,
    itemNumber?: number | null,
    measurement?:  {
      __typename: "Measurement",
      length?: number | null,
      width?: number | null,
      dimensionType?: DimensionType | null,
      measurementUnit?: MeasurementUnit | null,
    } | null,
    version?: number | null,
    localLastUpdatedTime?: number | null,
    opaqueSyncReconciliationToken?: string | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdBy?: string | null,
    canViewList?: Array< string | null > | null,
    canViewEntityVersionMap?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateWorkTypeSubscriptionVariables = {
  filter?: ModelSubscriptionWorkTypeFilterInput | null,
};

export type OnCreateWorkTypeSubscription = {
  onCreateWorkType?:  {
    __typename: "WorkType",
    id: string,
    name: string,
    shortDescription?: string | null,
    longDescription?: string | null,
    groupName?: string | null,
    unitPrice?: number | null,
    unitCost?: number | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateWorkTypeSubscriptionVariables = {
  filter?: ModelSubscriptionWorkTypeFilterInput | null,
};

export type OnUpdateWorkTypeSubscription = {
  onUpdateWorkType?:  {
    __typename: "WorkType",
    id: string,
    name: string,
    shortDescription?: string | null,
    longDescription?: string | null,
    groupName?: string | null,
    unitPrice?: number | null,
    unitCost?: number | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteWorkTypeSubscriptionVariables = {
  filter?: ModelSubscriptionWorkTypeFilterInput | null,
};

export type OnDeleteWorkTypeSubscription = {
  onDeleteWorkType?:  {
    __typename: "WorkType",
    id: string,
    name: string,
    shortDescription?: string | null,
    longDescription?: string | null,
    groupName?: string | null,
    unitPrice?: number | null,
    unitCost?: number | null,
    dimensionType?: DimensionType | null,
    measurementUnit?: MeasurementUnit | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateWorkTypeGroupSubscriptionVariables = {
  filter?: ModelSubscriptionWorkTypeGroupFilterInput | null,
};

export type OnCreateWorkTypeGroupSubscription = {
  onCreateWorkTypeGroup?:  {
    __typename: "WorkTypeGroup",
    id: string,
    latestWorkTypeVersion: number,
    environmentAgnosticWorkTypeGroupId?: string | null,
    isDiscoverable?: boolean | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateWorkTypeGroupSubscriptionVariables = {
  filter?: ModelSubscriptionWorkTypeGroupFilterInput | null,
};

export type OnUpdateWorkTypeGroupSubscription = {
  onUpdateWorkTypeGroup?:  {
    __typename: "WorkTypeGroup",
    id: string,
    latestWorkTypeVersion: number,
    environmentAgnosticWorkTypeGroupId?: string | null,
    isDiscoverable?: boolean | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteWorkTypeGroupSubscriptionVariables = {
  filter?: ModelSubscriptionWorkTypeGroupFilterInput | null,
};

export type OnDeleteWorkTypeGroupSubscription = {
  onDeleteWorkTypeGroup?:  {
    __typename: "WorkTypeGroup",
    id: string,
    latestWorkTypeVersion: number,
    environmentAgnosticWorkTypeGroupId?: string | null,
    isDiscoverable?: boolean | null,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateWorkTypePricingSubscriptionVariables = {
  filter?: ModelSubscriptionWorkTypePricingFilterInput | null,
};

export type OnCreateWorkTypePricingSubscription = {
  onCreateWorkTypePricing?:  {
    __typename: "WorkTypePricing",
    id: string,
    entityId: string,
    workTypeGroupId: string,
    body: string,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateWorkTypePricingSubscriptionVariables = {
  filter?: ModelSubscriptionWorkTypePricingFilterInput | null,
};

export type OnUpdateWorkTypePricingSubscription = {
  onUpdateWorkTypePricing?:  {
    __typename: "WorkTypePricing",
    id: string,
    entityId: string,
    workTypeGroupId: string,
    body: string,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteWorkTypePricingSubscriptionVariables = {
  filter?: ModelSubscriptionWorkTypePricingFilterInput | null,
};

export type OnDeleteWorkTypePricingSubscription = {
  onDeleteWorkTypePricing?:  {
    __typename: "WorkTypePricing",
    id: string,
    entityId: string,
    workTypeGroupId: string,
    body: string,
    canView?: string | null,
    canEdit?: string | null,
    canDelete?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDefaultWorkTypePricingSubscriptionVariables = {
  filter?: ModelSubscriptionDefaultWorkTypePricingFilterInput | null,
};

export type OnCreateDefaultWorkTypePricingSubscription = {
  onCreateDefaultWorkTypePricing?:  {
    __typename: "DefaultWorkTypePricing",
    id: string,
    unitCost?: number | null,
    unitPrice?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDefaultWorkTypePricingSubscriptionVariables = {
  filter?: ModelSubscriptionDefaultWorkTypePricingFilterInput | null,
};

export type OnUpdateDefaultWorkTypePricingSubscription = {
  onUpdateDefaultWorkTypePricing?:  {
    __typename: "DefaultWorkTypePricing",
    id: string,
    unitCost?: number | null,
    unitPrice?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDefaultWorkTypePricingSubscriptionVariables = {
  filter?: ModelSubscriptionDefaultWorkTypePricingFilterInput | null,
};

export type OnDeleteDefaultWorkTypePricingSubscription = {
  onDeleteDefaultWorkTypePricing?:  {
    __typename: "DefaultWorkTypePricing",
    id: string,
    unitCost?: number | null,
    unitPrice?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTemporaryWorkTypeSubscriptionVariables = {
  filter?: ModelSubscriptionTemporaryWorkTypeFilterInput | null,
};

export type OnCreateTemporaryWorkTypeSubscription = {
  onCreateTemporaryWorkType?:  {
    __typename: "TemporaryWorkType",
    id: string,
    workTypeGroupId: string,
    environmentAgnosticWorkTypeGroupId?: string | null,
    body: string,
    version: number,
    schemaVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTemporaryWorkTypeSubscriptionVariables = {
  filter?: ModelSubscriptionTemporaryWorkTypeFilterInput | null,
};

export type OnUpdateTemporaryWorkTypeSubscription = {
  onUpdateTemporaryWorkType?:  {
    __typename: "TemporaryWorkType",
    id: string,
    workTypeGroupId: string,
    environmentAgnosticWorkTypeGroupId?: string | null,
    body: string,
    version: number,
    schemaVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTemporaryWorkTypeSubscriptionVariables = {
  filter?: ModelSubscriptionTemporaryWorkTypeFilterInput | null,
};

export type OnDeleteTemporaryWorkTypeSubscription = {
  onDeleteTemporaryWorkType?:  {
    __typename: "TemporaryWorkType",
    id: string,
    workTypeGroupId: string,
    environmentAgnosticWorkTypeGroupId?: string | null,
    body: string,
    version: number,
    schemaVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTenderSubscriptionVariables = {
  filter?: ModelSubscriptionTenderFilterInput | null,
};

export type OnCreateTenderSubscription = {
  onCreateTender?:  {
    __typename: "Tender",
    id: string,
    solutionId?: string | null,
    status?: TenderStatus | null,
    latestVersion?: number | null,
    awardedVersion?: number | null,
    projectNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTenderSubscriptionVariables = {
  filter?: ModelSubscriptionTenderFilterInput | null,
};

export type OnUpdateTenderSubscription = {
  onUpdateTender?:  {
    __typename: "Tender",
    id: string,
    solutionId?: string | null,
    status?: TenderStatus | null,
    latestVersion?: number | null,
    awardedVersion?: number | null,
    projectNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTenderSubscriptionVariables = {
  filter?: ModelSubscriptionTenderFilterInput | null,
};

export type OnDeleteTenderSubscription = {
  onDeleteTender?:  {
    __typename: "Tender",
    id: string,
    solutionId?: string | null,
    status?: TenderStatus | null,
    latestVersion?: number | null,
    awardedVersion?: number | null,
    projectNumber?: number | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTenderMinorVersionSubscriptionVariables = {
  filter?: ModelSubscriptionTenderMinorVersionFilterInput | null,
};

export type OnCreateTenderMinorVersionSubscription = {
  onCreateTenderMinorVersion?:  {
    __typename: "TenderMinorVersion",
    id: string,
    tenderId?: string | null,
    version?: number | null,
    solutionId?: string | null,
    solutionMinorVersion?: number | null,
    metadata?:  {
      __typename: "TenderMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
    } | null,
    contentKey?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTenderMinorVersionSubscriptionVariables = {
  filter?: ModelSubscriptionTenderMinorVersionFilterInput | null,
};

export type OnUpdateTenderMinorVersionSubscription = {
  onUpdateTenderMinorVersion?:  {
    __typename: "TenderMinorVersion",
    id: string,
    tenderId?: string | null,
    version?: number | null,
    solutionId?: string | null,
    solutionMinorVersion?: number | null,
    metadata?:  {
      __typename: "TenderMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
    } | null,
    contentKey?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTenderMinorVersionSubscriptionVariables = {
  filter?: ModelSubscriptionTenderMinorVersionFilterInput | null,
};

export type OnDeleteTenderMinorVersionSubscription = {
  onDeleteTenderMinorVersion?:  {
    __typename: "TenderMinorVersion",
    id: string,
    tenderId?: string | null,
    version?: number | null,
    solutionId?: string | null,
    solutionMinorVersion?: number | null,
    metadata?:  {
      __typename: "TenderMetadata",
      name?: string | null,
      propertyContactName?: string | null,
      propertyAddress?: string | null,
      propertyType?: string | null,
      solutionDueDate?: string | null,
      consultantName?: string | null,
      consultantPhoneNumber?: string | null,
      consultantEmail?: string | null,
    } | null,
    contentKey?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserOnboardingProgressSubscriptionVariables = {
  filter?: ModelSubscriptionUserOnboardingProgressFilterInput | null,
};

export type OnCreateUserOnboardingProgressSubscription = {
  onCreateUserOnboardingProgress?:  {
    __typename: "UserOnboardingProgress",
    id: string,
    userId: string,
    hasUserBeenAssociatedWithOrganization: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserOnboardingProgressSubscriptionVariables = {
  filter?: ModelSubscriptionUserOnboardingProgressFilterInput | null,
};

export type OnUpdateUserOnboardingProgressSubscription = {
  onUpdateUserOnboardingProgress?:  {
    __typename: "UserOnboardingProgress",
    id: string,
    userId: string,
    hasUserBeenAssociatedWithOrganization: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserOnboardingProgressSubscriptionVariables = {
  filter?: ModelSubscriptionUserOnboardingProgressFilterInput | null,
};

export type OnDeleteUserOnboardingProgressSubscription = {
  onDeleteUserOnboardingProgress?:  {
    __typename: "UserOnboardingProgress",
    id: string,
    userId: string,
    hasUserBeenAssociatedWithOrganization: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionTemplateFilterInput | null,
};

export type OnCreateTemplateSubscription = {
  onCreateTemplate?:  {
    __typename: "Template",
    id: string,
    type?: TemplateType | null,
    name?: string | null,
    s3Key?: string | null,
    ownerId?: string | null,
    ownerType?: TemplateEntityType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionTemplateFilterInput | null,
};

export type OnUpdateTemplateSubscription = {
  onUpdateTemplate?:  {
    __typename: "Template",
    id: string,
    type?: TemplateType | null,
    name?: string | null,
    s3Key?: string | null,
    ownerId?: string | null,
    ownerType?: TemplateEntityType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionTemplateFilterInput | null,
};

export type OnDeleteTemplateSubscription = {
  onDeleteTemplate?:  {
    __typename: "Template",
    id: string,
    type?: TemplateType | null,
    name?: string | null,
    s3Key?: string | null,
    ownerId?: string | null,
    ownerType?: TemplateEntityType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTemporaryAuthorizationCodeSubscriptionVariables = {
  filter?: ModelSubscriptionTemporaryAuthorizationCodeFilterInput | null,
};

export type OnCreateTemporaryAuthorizationCodeSubscription = {
  onCreateTemporaryAuthorizationCode?:  {
    __typename: "TemporaryAuthorizationCode",
    id: string,
    code?: string | null,
    userId?: string | null,
    expiresAt?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTemporaryAuthorizationCodeSubscriptionVariables = {
  filter?: ModelSubscriptionTemporaryAuthorizationCodeFilterInput | null,
};

export type OnUpdateTemporaryAuthorizationCodeSubscription = {
  onUpdateTemporaryAuthorizationCode?:  {
    __typename: "TemporaryAuthorizationCode",
    id: string,
    code?: string | null,
    userId?: string | null,
    expiresAt?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTemporaryAuthorizationCodeSubscriptionVariables = {
  filter?: ModelSubscriptionTemporaryAuthorizationCodeFilterInput | null,
};

export type OnDeleteTemporaryAuthorizationCodeSubscription = {
  onDeleteTemporaryAuthorizationCode?:  {
    __typename: "TemporaryAuthorizationCode",
    id: string,
    code?: string | null,
    userId?: string | null,
    expiresAt?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLinkRegistrySubscriptionVariables = {
  filter?: ModelSubscriptionLinkRegistryFilterInput | null,
};

export type OnCreateLinkRegistrySubscription = {
  onCreateLinkRegistry?:  {
    __typename: "LinkRegistry",
    id: string,
    action?: LinkAction | null,
    variables?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLinkRegistrySubscriptionVariables = {
  filter?: ModelSubscriptionLinkRegistryFilterInput | null,
};

export type OnUpdateLinkRegistrySubscription = {
  onUpdateLinkRegistry?:  {
    __typename: "LinkRegistry",
    id: string,
    action?: LinkAction | null,
    variables?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLinkRegistrySubscriptionVariables = {
  filter?: ModelSubscriptionLinkRegistryFilterInput | null,
};

export type OnDeleteLinkRegistrySubscription = {
  onDeleteLinkRegistry?:  {
    __typename: "LinkRegistry",
    id: string,
    action?: LinkAction | null,
    variables?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateExternalReportBrandingSubscriptionVariables = {
  filter?: ModelSubscriptionExternalReportBrandingFilterInput | null,
};

export type OnCreateExternalReportBrandingSubscription = {
  onCreateExternalReportBranding?:  {
    __typename: "ExternalReportBranding",
    id: string,
    name?: string | null,
    logoKey?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateExternalReportBrandingSubscriptionVariables = {
  filter?: ModelSubscriptionExternalReportBrandingFilterInput | null,
};

export type OnUpdateExternalReportBrandingSubscription = {
  onUpdateExternalReportBranding?:  {
    __typename: "ExternalReportBranding",
    id: string,
    name?: string | null,
    logoKey?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteExternalReportBrandingSubscriptionVariables = {
  filter?: ModelSubscriptionExternalReportBrandingFilterInput | null,
};

export type OnDeleteExternalReportBrandingSubscription = {
  onDeleteExternalReportBranding?:  {
    __typename: "ExternalReportBranding",
    id: string,
    name?: string | null,
    logoKey?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingFilterInput | null,
};

export type OnCreateMeetingSubscription = {
  onCreateMeeting?:  {
    __typename: "Meeting",
    id: string,
    status?: MeetingStatus | null,
    data: string,
    createdBy?: string | null,
    mediaPipelineId?: string | null,
    recordingKey?: string | null,
    recordingAttachmentId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingFilterInput | null,
};

export type OnUpdateMeetingSubscription = {
  onUpdateMeeting?:  {
    __typename: "Meeting",
    id: string,
    status?: MeetingStatus | null,
    data: string,
    createdBy?: string | null,
    mediaPipelineId?: string | null,
    recordingKey?: string | null,
    recordingAttachmentId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingFilterInput | null,
};

export type OnDeleteMeetingSubscription = {
  onDeleteMeeting?:  {
    __typename: "Meeting",
    id: string,
    status?: MeetingStatus | null,
    data: string,
    createdBy?: string | null,
    mediaPipelineId?: string | null,
    recordingKey?: string | null,
    recordingAttachmentId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAttendeeSubscriptionVariables = {
  filter?: ModelSubscriptionAttendeeFilterInput | null,
};

export type OnCreateAttendeeSubscription = {
  onCreateAttendee?:  {
    __typename: "Attendee",
    id: string,
    meetingId: string,
    entityId?: string | null,
    name?: string | null,
    role?: AttendeeRole | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAttendeeSubscriptionVariables = {
  filter?: ModelSubscriptionAttendeeFilterInput | null,
};

export type OnUpdateAttendeeSubscription = {
  onUpdateAttendee?:  {
    __typename: "Attendee",
    id: string,
    meetingId: string,
    entityId?: string | null,
    name?: string | null,
    role?: AttendeeRole | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAttendeeSubscriptionVariables = {
  filter?: ModelSubscriptionAttendeeFilterInput | null,
};

export type OnDeleteAttendeeSubscription = {
  onDeleteAttendee?:  {
    __typename: "Attendee",
    id: string,
    meetingId: string,
    entityId?: string | null,
    name?: string | null,
    role?: AttendeeRole | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
