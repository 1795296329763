import {
    DimensionType,
    Measurement
} from "../../../models";

import MeasurementValidator from "./MeasurementValidator";

export default class DefaultMeasurementValidator implements MeasurementValidator {

    public isValid(measurement: Measurement): boolean {
        if (!measurement.measurementUnit) {
            return false;
        }
        if (measurement.dimensionType === DimensionType.LINEAR) {
            return !!measurement.length;
        }
        if (measurement.dimensionType === DimensionType.SQUARE) {
            return !!measurement.length && !!measurement.width;
        }
        return false;
    }
    
}
