import {
    Attachment,
    PermissionResourceType
} from '../../models';
import {
    OnCreateAttachmentSubscription,
    OnDeleteAttachmentSubscription,
    OnUpdateAttachmentSubscription,
} from '../../API';
import {
    onCreateAttachment,
    onDeleteAttachment,
    onUpdateAttachment
} from '../../graphql/subscriptions';

import { API } from 'aws-amplify';
import { AttachmentParent } from '../../componentsV2/image/AttachmentParent';
import { AuthIDTokenSupplierFactory } from '../auth/AuthIDTokenSupplierFactory';
import AuthUsernameSupplierFactory from '../auth/AuthUsernameSupplierFactory';
import { ContextAwareIdentifier } from '../design/document/ContextAwareIdentifier';
import { GraphQLSubscription } from '@aws-amplify/api';
import { ModelType } from '../design/document/ModelType';
import { StateContext } from '../design/document/state/StateContext';
import { attachmentRecordsByIdentifierAtomFamily } from '../design/document/state/DocumentState';
import { useQueryClient } from 'react-query';
import { useRecoilCallback } from 'recoil';

let createSub: ZenObservable.Subscription | undefined;
let updateSub: ZenObservable.Subscription | undefined;
let deleteSub: ZenObservable.Subscription | undefined;
const tokenSupplier = AuthIDTokenSupplierFactory.getInstance()
const usernameSupplier = AuthUsernameSupplierFactory.getInstance()

export const useAttachmentSubscription = () => {
    const queryClient = useQueryClient();

    const subscribe = async (propertyId: string) => {
        const username = await usernameSupplier.get();
        const authCode = await tokenSupplier.get();
        const filterVariables = {
            canView: {
                contains: username
            },
            propertyId: {
                eq: propertyId
            }
        }
        createSub = API.graphql<GraphQLSubscription<OnCreateAttachmentSubscription>>({
            query: onCreateAttachment,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handleAttachmentUpdate(value.data?.onCreateAttachment as Attachment, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
        updateSub = API.graphql<GraphQLSubscription<OnUpdateAttachmentSubscription>>({
            query: onUpdateAttachment,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handleAttachmentUpdate(value.data?.onUpdateAttachment as Attachment, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });
        deleteSub = API.graphql<GraphQLSubscription<OnDeleteAttachmentSubscription>>({
            query: onDeleteAttachment,
            variables: {
                filter: filterVariables
            },
            authToken: authCode
        }).subscribe({
            next: ({ provider, value }) => {
                handleAttachmentDelete(value.data?.onDeleteAttachment as Attachment, StateContext.INSPECTION)
            },
            error: (error) => console.warn(error)
        });

    }

    const handleAttachmentUpdate = useRecoilCallback(({ set, reset, snapshot }) => async (attachment: Attachment, context: StateContext) => {
        const parentIdentifier = new ContextAwareIdentifier(attachment.parentId, context, getModelTypeFromPermissionResourceType(attachment.parentType as PermissionResourceType))
        const attachmentParent: AttachmentParent = { id: parentIdentifier.id, type: attachment.parentType as PermissionResourceType };
        set(attachmentRecordsByIdentifierAtomFamily(parentIdentifier), (attachments) => { return [...attachments, attachment] })
        queryClient.invalidateQueries(["attachments", attachmentParent]);
    })

    const handleAttachmentDelete = useRecoilCallback(({ set, reset, snapshot }) => async (attachment: Attachment, context: StateContext) => {
        const parentIdentifier = new ContextAwareIdentifier(attachment.parentId, context, getModelTypeFromPermissionResourceType(attachment.parentType as PermissionResourceType))
        const attachmentParent: AttachmentParent = { id: parentIdentifier.id, type: attachment.parentType as PermissionResourceType };
        const attachments = await snapshot.getPromise(attachmentRecordsByIdentifierAtomFamily(parentIdentifier));
        const updatedAttachments = attachments.filter(a => a.id !== attachment.id);
        set(attachmentRecordsByIdentifierAtomFamily(parentIdentifier), updatedAttachments);
        queryClient.invalidateQueries(["attachments", attachmentParent]);
    })

    const getModelTypeFromPermissionResourceType = (type: PermissionResourceType): ModelType => {
        switch (type) {
            case PermissionResourceType.WORK_SPECIFICATION:
                return ModelType.WORK_SPECIFICATION;
            case PermissionResourceType.LOCATION:
                return ModelType.LOCATION;
            case PermissionResourceType.ISSUE:
                return ModelType.ISSUE;
            case PermissionResourceType.DIMENSION:
                return ModelType.DIMENSION;
            default:
                throw new Error(`Unknown PermissionResourceType: ${type}`);
        }
    }

    const unsubscribe = () => {
        createSub?.unsubscribe();
        updateSub?.unsubscribe();
        deleteSub?.unsubscribe();
    }

    return { subscribe, unsubscribe }
}