import { AttachmentContent } from "../AttachmentContent";
import AttachmentContentDAO from "../AttachmentContentDAO";
import AttachmentContentKeyWithVariantFactory from "../AttachmentContentKeyWithVariantFactory";
import { AttachmentContentNotFoundError } from "../AttachmentContentNotFoundError";

export class LocalForageAttachmentContentDAO implements AttachmentContentDAO {
    private localForage: LocalForage;

    constructor(
        localForage: LocalForage
    ) {
        this.localForage = localForage;
    }

    public async getAttachmentContentByAttachmentKey(
        key: string,
        variant?: string
    ): Promise<AttachmentContent> {
        const keyWithVariant = AttachmentContentKeyWithVariantFactory.createKey(key, variant);
        const localAttachmentValue: AttachmentContent | null = await this.localForage.getItem(keyWithVariant);
        if (localAttachmentValue === null) {
            throw new AttachmentContentNotFoundError("Attachment Content not found in LocalForage");
        }
        return localAttachmentValue;
    }

    public async listAttachmentContentKeys(): Promise<string[]> {
        return this.localForage.keys();
    }

    public async saveAttachment(
        key: string,
        content: AttachmentContent,
        variant?: string
    ): Promise<void> {
        const keyWithVariant: string = AttachmentContentKeyWithVariantFactory.createKey(key, variant);
        await this.localForage.setItem(keyWithVariant, content);
    }
}
