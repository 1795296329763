import {
    Box,
    Collapse,
    List,
    useMediaQuery
} from "@mui/material";
import {
    useMemo,
    useState
} from "react";

import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { CreateIssueButton } from "./CreateIssueButton";
import { IssueCard } from "./IssueCard";
import { IssueManagerPaginationControl } from "./IssueManagerPaginationControl";
import { IssuePaginationSizeForm } from "./IssuePaginationSizeForm";
import { ModelType } from "../../lib/design/document/ModelType";
import NoItemsPlaceholder from "../NoItemsPlaceholder";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { TransitionGroup } from "react-transition-group";
import { filteredIssueIdsByLocationIdentifierSelectorFamily } from "../../lib/design/document/state/DocumentState";
import { orange } from "../../assets/style/colors";
import theme from "../../assets/style/theme";
import { useRecoilValue } from "recoil";

type IssueManagerProps = {
    readonly locationId: string;
    readonly stateContext: StateContext;
};

const ISSUE_NUMBER_THRESHOLD_TO_DISPLAY_PAGINATION_CONTROL = 2;

const IssueManager = (props: IssueManagerProps) => {
    const { locationId, stateContext } = props;
    const isScreenSizeXs = useMediaQuery(theme.breakpoints.down("sm"));

    const locationIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(locationId, stateContext, ModelType.LOCATION);
    }, [locationId, stateContext]);

    const filteredIssueIds = useRecoilValue<Array<string>>(filteredIssueIdsByLocationIdentifierSelectorFamily(locationIdentifier));
    const [issueIdsToDisplay, setIssueIdsToDisplay] = useState<Array<string>>([]);

    return (
        <Box sx={{ backgroundColor: orange[50] }}>
            <Box
                display="flex"
                alignItems="left"
                justifyContent="space-between"
                width="100%"
                p={1}
                position="relative"
                sx={{
                    borderLeft: theme => `10px solid ${theme.palette.primary.main}`
                }}
                flexDirection="column"
            >
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                    flexDirection="row"
                >
                <CreateIssueButton
                    stateContext={stateContext}
                    locationId={locationId}
                />
                {filteredIssueIds.length >= ISSUE_NUMBER_THRESHOLD_TO_DISPLAY_PAGINATION_CONTROL &&
                    <>
                        <IssuePaginationSizeForm />
                        {!isScreenSizeXs &&
                            <IssueManagerPaginationControl
                                issueIds={filteredIssueIds}
                                setIssueIdsToDisplay={setIssueIdsToDisplay}
                                locationId={locationId}
                            />
                        }
                    </>
                }
                </Box>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    pt={1}
                >
                    {isScreenSizeXs &&
                        <IssueManagerPaginationControl
                            issueIds={filteredIssueIds}
                            setIssueIdsToDisplay={setIssueIdsToDisplay}
                            locationId={locationId}
                        />
                    }
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" width="100%">
                {issueIdsToDisplay.length === 0 && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        p={2}
                        sx={{
                            borderLeft: theme => `10px solid ${theme.palette.primary.main}`,
                        }}
                    >
                        <NoItemsPlaceholder text="No issues under location." />
                    </Box>
                )}
                <List disablePadding>
                    <TransitionGroup>
                        {issueIdsToDisplay.map((issueId) => (
                            <Collapse key={issueId} data-cy="issue-card">
                                <IssueCard
                                    issueId={issueId}
                                    stateContext={stateContext}
                                />
                            </Collapse>
                        ))}
                    </TransitionGroup>
                </List>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                p={1}
                position="relative"
                sx={{
                    borderLeft: theme => `10px solid ${theme.palette.primary.main}`
                }}
                flexDirection={{ xs: "column", sm: "row" }}
            >
                <IssueManagerPaginationControl
                    issueIds={filteredIssueIds}
                    setIssueIdsToDisplay={setIssueIdsToDisplay}
                    locationId={locationId}
                />
                <IssuePaginationSizeForm />
            </Box>
        </Box>
    );
};

export default IssueManager;
