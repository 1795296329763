import {
    Autocomplete,
    AutocompleteRenderInputParams,
    ClickAwayListener,
    TextField
} from '@mui/material';
import {
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';

import { isDesktop } from 'react-device-detect';

export interface MeasurementFieldProps {
    readonly label: string | undefined;
    readonly initialValue: string;
    onSave: (value: number) => void;
    size?: "small" | "medium";
}

// Up to 7 positive integer digits, up to 2 decimal digits.
const VALID_MEASUREMENT_REGEX = /^((?:\d{0,7})\b\.?(?:\d{1,2})?)$|^\d{0,7}$/g;
const numbers = Array.from(Array(200).keys());

export const MeasurementField = (props: MeasurementFieldProps) => {

    const { label, initialValue, onSave } = props;
    const [value, setValue] = useState<string>(initialValue === "0" ? "" : initialValue);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const shouldInterceptAutocomplete: boolean = useMemo(() => {
        return !isDesktop && !isOpen && !value;
    }, [isOpen, value]);

    const interceptAutocompleteOpening: () => Promise<void> = useCallback(async () => {
        if (shouldInterceptAutocomplete) {
            setIsOpen(true);
        }
    }, [shouldInterceptAutocomplete]);

    const handleSelect = (event: object, value: any) => {
        if (value === null) {
            // Display the drop down options when the value is cleared.
            setIsOpen(true);
        }
        updateValue(value, true);
    };

    const handleManualChange = (value: string, save: boolean = false) => {
        updateValue(value, save);
    };

    // Note: most of the logic here is needed because MUI autocomplete doesn't seem to support
    // text masks/input constraint props (e.g. min, max, maxLength, etc.)
    const updateValue = (input: string, save: boolean = false) => {
        const numericInput = parseFloat(input);

        // First, check if the value is not a number to guard against null pointer exceptions.
        // This should only happen for the empty field case.
        if (isNaN(numericInput)) {
            setValue("");
            if (save) {
                onSave(0);
            }
            return;
        }

        // Test against what we consider to be the valid measurement regex.
        // Because MUI Autocomplete component allows typing any values, we have to
        // explitly reset if we consider said value invalid.
        if (!input.match(VALID_MEASUREMENT_REGEX)) {
            setValue(prev => {
                return prev;
            });
            return;
        }

        // If we reached here, the value is valid, so we update it.
        setValue(input);
        if (save) {
            onSave(numericInput);
        }
    };

    useEffect(() => {
        setValue(initialValue === "0" ? "" : initialValue);
    }, [initialValue]);

    return (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            {/* This div is necessary to prevent initial auto-focusing on the TextField
                and opening the virtual keyboard on a mobile device. */}
            <div
                id="clickInterceptor"
                onClick={interceptAutocompleteOpening}
                style={{ cursor: "pointer" }}
            >
                <Autocomplete
                    disableClearable
                    inputValue={value}
                    onChange={handleSelect}
                    onClose={() => setIsOpen(false)}
                    onInputChange={(event, value) => handleManualChange(value, false)}
                    onBlur={() => handleManualChange(value, true)}
                    onOpen={() => setIsOpen(true)}
                    open={isOpen}
                    options={numbers.map((number) => (number + 1).toString())}
                    renderInput={(params: AutocompleteRenderInputParams) =>
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                // This does not limit the input to numeric characters. What it does is change
                                // the mobile keyboard type.
                                inputMode: "decimal"
                                // Note: attributes like min, max, maxLength, etc. that would normally go here
                                // are not well supported by the MUI Autocomplete.
                            }}
                            label={label}
                            onChange={(event) => updateValue(event.target.value)}
                            value={value}
                            variant="outlined"
                        />
                    }
                    style={{ pointerEvents: shouldInterceptAutocomplete ? "none" : "auto" }}
                    size={props.size || "medium"}
                    value={value}
                    fullWidth
                    sx={{
                        "& .MuiAutocomplete-inputRoot": {
                            paddingRight: "0px !important"
                        },
                        "& .MuiAutocomplete-endAdornment": {
                            right: "0px !important"
                        }
                    }}
                />
            </div>
        </ClickAwayListener>
    );
};
