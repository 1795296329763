import {
    Box,
    Card,
    CardContent,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemText
} from '@mui/material';
import {
    Solution,
    Tender
} from '../../models';
import {
    useEffect,
    useState
} from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GraphQLSolutionDAOFactory from '../../lib/solution/majorVersion/dao/GraphQLSolutionDAOFactory';
import NoItemsPlaceholder from '../NoItemsPlaceholder';
import SolutionDAO from '../../lib/solution/majorVersion/dao/SolutionDAO';
import globalStyle from '../../assets/style/globalStyle';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTendering } from '../../lib/tender/hook/useTendering';

const solutionDAO: SolutionDAO = GraphQLSolutionDAOFactory.getInstance();

export const TenderingList = () => {
    const snackbar = useSnackbar();
    const globalClasses = globalStyle();
    const { propertyId } = useParams<{ propertyId: string }>();
    const { getTenderByProjectNumber, openTenderPage } = useTendering();
    const [projectNumberToSolutionMap, setProjectNumberToSolutionMap] = useState<Map<number, Solution>>();
    const [tenders, setTenders] = useState<Array<Tender>>([]);
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    useEffect(() => {
        const getTenderingData = async () => {
            try  {
                const solutions: Array<Solution> = await solutionDAO.listByPropertyId(propertyId);
                const projectNumbers: Set<number> = solutions.reduce((accumulatorSet, solution) => {
                    accumulatorSet.add(solution.serialNumber!);
                    return accumulatorSet;
                }, new Set<number>());

                const possibleTenders = await Promise.all(Array.from(projectNumbers).map(async (projectNumber) => {
                    return await getTenderByProjectNumber(projectNumber);
                }, new Array<Tender>()))
                const tenders: Array<Tender> = possibleTenders.filter((tender) => tender !== undefined) as Array<Tender>;

                const projectNumberToSolutionsMap: Map<number, Solution> = tenders.reduce((accumulatorMap, tender) => {
                    const solution = solutions.find((solution) => solution.serialNumber === tender.projectNumber);
                    if (solution) {
                        accumulatorMap.set(tender.projectNumber!, solution);
                    }
                    return accumulatorMap;
                }, new Map<number, Solution>());
                setProjectNumberToSolutionMap(projectNumberToSolutionsMap);
                
                //sort tenders by createdAt date
                tenders.sort((a, b) => {
                    const aDate = new Date(a.createdAt!);
                    const bDate = new Date(b.createdAt!);
                    return bDate.getTime() - aDate.getTime();
                });
                setTenders(tenders);
            } catch (error) {
                snackbar.enqueueSnackbar('Failed to load tendering data', { variant: 'error' });
            } finally {
                setIsPageLoading(false);
            }
        }
        getTenderingData();
    }, [propertyId]);

    return (
        <>
            {isPageLoading && <LinearProgress />}
            {
                tenders.length === 0 && (
                    <Box
                        p={2}
                        style={{ textAlign: "center" }}
                        data-cy="test-no-images-placeholder"
                    >
                        <NoItemsPlaceholder text="No Tenders found." />
                    </Box>
                )
            }
            {!isPageLoading && tenders.length > 0 &&
                <Card className={globalClasses.listContainerWithBorder}>
                    <CardContent style={{ padding: 0 }}>
                        <List
                            disablePadding
                        >
                            {tenders && Array.from(tenders.values()).map((tender) => (
                                <ListItem
                                    secondaryAction={
                                        <IconButton
                                            onClick={() => openTenderPage(tender.id)}
                                        > 
                                            <ChevronRightIcon />
                                        </IconButton>
                                    }
                                    disablePadding
                                    key={tender.id}
                                    divider={tender !== tenders[tenders.length - 1]}
                                >
                                    <ListItemButton
                                        onClick={() => openTenderPage(tender.id)}
                                    >
                                        <ListItemText
                                            primary={projectNumberToSolutionMap?.get(tender.projectNumber!)?.name}
                                            secondary={`Project #${tender.projectNumber} - ${tender.status}`}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            }
        </>
    );
};
