import {
    Fragment,
    useMemo
} from "react";
import {
    issueIdsByLocationIdentifierAtomFamily,
    locationByLocationIdentifierSelectorFamily
} from "../../../../lib/design/document/state/DocumentState";

import ClipboardSolutionIssue from "./ClipboardSolutionIssue";
import { ContextAwareIdentifier } from "../../../../lib/design/document/ContextAwareIdentifier";
import { Location } from "../../../../models";
import { ModelType } from "../../../../lib/design/document/ModelType";
import { StateContext } from "../../../../lib/design/document/state/StateContext";
import { Typography } from "@mui/material";
import { WORD_TITLE } from "../ViewSolutionStyles";
import { useRecoilValue } from "recoil";

type ClipboardSolutionLocationProps = {
    readonly context: StateContext;
    readonly locationId: string;
    readonly isForClipboard?: boolean;
};

const ClipboardSolutionLocation = ({ locationId, context, isForClipboard = false }: ClipboardSolutionLocationProps) => {
    const locationIdentifier = useMemo(() => {
        return new ContextAwareIdentifier(locationId, context, ModelType.LOCATION);
    }, []);
    const location = useRecoilValue<Location | null>(locationByLocationIdentifierSelectorFamily(locationIdentifier));
    const issueIds = useRecoilValue<Array<string>>(issueIdsByLocationIdentifierAtomFamily(locationIdentifier));

    return (
        <>
            <Typography style={WORD_TITLE} component="h1">
                Group: {location?.name}
            </Typography>
            {issueIds.map((issueId, index) => (
                <Fragment key={issueId}>
                    <ClipboardSolutionIssue
                        context={context}
                        issueId={issueId}
                        isForClipboard={isForClipboard}
                    />
                </Fragment>
            ))}
        </>
    );
};

export default ClipboardSolutionLocation;
