import {
    idInFocusByModelTypeAtomFamily,
    propertyIdInFocusAtom,
    stateContextInFocusAtom
} from "../../lib/ui/InFocusRecoilStates";

import { AddIssueIcon } from "../icons";
import { ModelType } from "../../lib/design/document/ModelType";
import { PermissionType } from "../../models";
import { QuickAccessKey } from "./QuickAccessKey";
import { SolutionAuthoringEnabledPermissionRestrictedControl } from "../general/button/SolutionAuthoringEnabledPermissionRestrictedControl";
import { StateContext } from '../../lib/design/document/state/StateContext';
import { useCreateIssue } from "../../lib/issue/hook/useCreateIssue";
import { useRecoilValue } from "recoil";

export const QuickCreateIssueKey = () => {
    const contextInFocus = useRecoilValue<StateContext>(stateContextInFocusAtom);
    const propertyIdInFocus = useRecoilValue<string | null>(propertyIdInFocusAtom);
    const locationIdInFocus = useRecoilValue<string | null>(idInFocusByModelTypeAtomFamily(ModelType.LOCATION));
    const { createDefaultIssue } = useCreateIssue(contextInFocus);

    return (
        <SolutionAuthoringEnabledPermissionRestrictedControl
            context={contextInFocus}
            requiredPermission={PermissionType.CREATE_CHILDREN}
            resourceId={locationIdInFocus ?? ""}
        >
            {(disabled) => (
                <QuickAccessKey
                    disabled={disabled || !locationIdInFocus || !propertyIdInFocus}
                    onClick={() => {
                        if (!locationIdInFocus || !propertyIdInFocus) {
                            return;
                        }
                        createDefaultIssue(propertyIdInFocus, locationIdInFocus);
                    }}
                    in
                >
                    <AddIssueIcon accent={disabled ? "disabled" : "primary"} />
                </QuickAccessKey>
            )}
        </SolutionAuthoringEnabledPermissionRestrictedControl>

    );
};