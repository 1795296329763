import {
    useMediaQuery,
    useTheme
} from "@mui/material";

export const useSlidesPerView = () => {
    const theme = useTheme();
    const isScreenLargerThanSm = useMediaQuery(theme.breakpoints.up("sm"));

    if (isScreenLargerThanSm) {
        return 2;
    }
    return 1;
};