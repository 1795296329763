import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="currentColor" d="M0 3.5h8v1H0zM16 3.5h8v1h-8zM0 11.5h12v1H0z" />
    <path fill="none" d="M24 20" />
    <path
      fill="currentColor"
      d="M24 19.5H12v1h12V20z"
      className="primary"
    />
    <circle
      cx={12}
      cy={4}
      r={2}
      fill="currentColor"
      className="accent"
    />
    <path
      fill="currentColor"
      d="M20.95 12.5H24v-1h-3.05c.027.164.05.329.05.5 0 .171-.023.337-.05.5zM3.051 19.5H0v1h3.051A2.942 2.942 0 0 1 3 20c0-.171.023-.336.051-.5z"
      className="primary"
    />
    <circle
      cx={18}
      cy={12}
      r={2}
      fill="currentColor"
      className="accent"
    />
    <circle
      cx={6}
      cy={20}
      r={2}
      fill="currentColor"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(FilterIcon);
