import * as yup from "yup";

import {
    getCountries,
    getCountryCallingCode,
    isPossiblePhoneNumber
} from "react-phone-number-input";
import {
    useMemo,
    useState
} from "react";

import Alert from '@mui/material/Alert';
import { AlertMessage } from "../../lib/util/AlertMessage";
import { Auth } from "aws-amplify";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from '@mui/material/MenuItem';
import { PasswordField } from "./PasswordField";
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useFormik } from "formik";

const REQUIRED_FIELD_TEXT = "Field cannot be empty";

const signUpFormSchema = yup.object({
    name: yup.string().required(REQUIRED_FIELD_TEXT),
    email: yup.string().email().required(REQUIRED_FIELD_TEXT),
    password: yup
        .string()
        .min(8, 'Password must have at least 8 characters')
        .matches(/[0-9]/, 'Password must contains at least one number')
        .matches(/[a-z]/, 'Password must contains at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contains at least one uppercase letter')
        .matches(/[^\w_]/, 'Password must contains at least one special character')
        .required(REQUIRED_FIELD_TEXT),
    confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Your passwords must match").required(),
    telCountryCode: yup.string().required(REQUIRED_FIELD_TEXT),
    phoneNumber: yup.string().when("telCountryCode", (telCountryCode) => {
        return yup.string().required(REQUIRED_FIELD_TEXT).test("consultantPhoneTest", "Must be a valid phone number", (value) => {
            return isPossiblePhoneNumber(`${telCountryCode}${value}` || "");
        });
    })
});

type SignUpFormData = yup.InferType<typeof signUpFormSchema>;

type SignUpFormProps = {
    onAccountCreated: (email: string) => void;
};

export const SignUpForm = (props: SignUpFormProps) => {
    const { onAccountCreated } = props;
    const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();

    const countryCallingCodes = useMemo<Array<string>>(() => {
        const unsortedCountryCallingCodes: Set<string> = getCountries()
            .map(countryCode => getCountryCallingCode(countryCode))
            .reduce((set, code) => {
                return set.add(code);
            }, new Set<string>());
        const callingCodeCompareFunction: (a: string, b: string) => number = (a, b) => {
            if (a.length != b.length) {
                return a.length - b.length;
            }
            return a.localeCompare(b);
        };
        return Array.from(unsortedCountryCallingCodes).sort(callingCodeCompareFunction);
    }, []);

    const signUp = async (data: SignUpFormData) => {
        try {
            setIsCreatingAccount(true);
            setAlertMessage(undefined);
            await Auth.signUp({
                username: data.email,
                password: data.password,
                attributes: {
                    email: data.email,
                    phone_number: `${data.telCountryCode}${data.phoneNumber}`,
                    name: data.name
                },
                autoSignIn: {
                    enabled: true,
                }
            });
            onAccountCreated(data.email);
        } catch (error) {
            setAlertMessage({
                severity: "error",
                message: (error as Error).message
            });
        } finally {
            setIsCreatingAccount(false);
        }
    };

    const formik = useFormik<SignUpFormData>({
        initialValues: {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            telCountryCode: '+1',
            phoneNumber: ''
        },
        validationSchema: signUpFormSchema,
        onSubmit: signUp
    });

    return (
        <Container component="main" maxWidth="xs">
            <Box
                mt={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                name="name"
                                autoComplete="name"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordField
                                id="password"
                                name="password"
                                label="Password"
                                autoComplete="new-password"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordField
                                id="confirmPassword"
                                name="confirmPassword"
                                label="Confirm Password"
                                autoComplete="new-password"
                                onChange={formik.handleChange}
                                value={formik.values.confirmPassword}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex">
                                <Box width="33%">
                                    <Select
                                        id="telCountryCode"
                                        autoComplete="tel-country-code"
                                        name="telCountryCode"
                                        required
                                        fullWidth
                                        onChange={formik.handleChange}
                                        value={formik.values.telCountryCode}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                        }}
                                    >
                                        {countryCallingCodes.map(callingCode => (
                                            <MenuItem key={callingCode} value={`+${callingCode}`}>
                                                {`+${callingCode}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box width="67%">
                                    <TextField
                                        required
                                        fullWidth
                                        name="phoneNumber"
                                        label="Phone Number"
                                        type="tel"
                                        id="phoneNumber"
                                        autoComplete="tel"
                                        onChange={formik.handleChange}
                                        value={formik.values.phoneNumber}
                                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        {alertMessage &&
                            <Grid item xs={12}>
                                <Alert severity={alertMessage.severity}> {alertMessage.message}</Alert>
                            </Grid>
                        }
                    </Grid>
                    <LoadingButton
                        loading={isCreatingAccount}
                        loadingPosition="start"
                        startIcon={<></>}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Create Account
                    </LoadingButton>
                </Box>
            </Box>
        </Container>
    );
};