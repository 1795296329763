import API, {
    GraphQLResult
} from "@aws-amplify/api";
import {
    CreateSolutionMutation,
    DeleteSolutionMutation,
    DeleteSolutionMutationVariables,
    GetSolutionQuery,
    ListSolutionsByPropertyQuery,
    ListSolutionsByPropertyQueryVariables,
    UpdateSolutionMutation
} from "../../../../API";
import {
    ResourceType,
    Solution,
    SolutionStatus
} from "../../../../models";
import {
    createSolution,
    deleteSolution,
    updateSolution
} from "../../../../graphql/mutations";
import {
    getSolution,
    listSolutionsByProperty
} from "../../../../graphql/queries";

import ClientLogger from "../../../logging/ClientLogger";
import CreateSolutionError from "./error/CreateSolutionError";
import { DeleteSolutionError } from "./error/DeleteSolutionError";
import GetSolutionError from './error/GetSolutionError';
import { IDTokenSupplier } from "../../../auth/IDTokenSupplier";
import InvalidSolutionStateTransitionError from "./error/InvalidSolutionStateTransitionError";
import ListSolutionsByPropertyError from './error/ListSolutionsByPropertyError';
import SerialNumberGenerator from "../../../serialNumber/SerialNumberGenerator";
import SolutionDAO from "./SolutionDAO";
import UpdateSolutionError from "./error/UpdateSolutionError";
import { graphqlOperation } from "aws-amplify";

export default class GraphQLSolutionDAO implements SolutionDAO {

    private static readonly GET_BY_ID_ATTEMPT_METRIC_NAME = "GraphQLSolutionDAO.GetById.Attempt";
    private static readonly GET_BY_ID_FAILURE_METRIC_NAME = "GraphQLSolutionDAO.GetById.Failure";
    private static readonly CREATE_ATTEMPT_METRIC_NAME = "GraphQLSolutionDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "GraphQLSolutionDAO.Create.Failure";
    private static readonly UPDATE_ATTEMPT_METRIC_NAME = "GraphQLSolutionDAO.Update.Attempt";
    private static readonly UPDATE_FAILURE_METRIC_NAME = "GraphQLSolutionDAO.Update.Failure";
    private static readonly DELETE_ATTEMPT_METRIC_NAME = "GraphQLSolutionDAO.Delete.Attempt";
    private static readonly DELETE_FAILURE_METRIC_NAME = "GraphQLSolutionDAO.Delete.Failure";
    private static readonly LIST_BY_PROPERTY_ID_ATTEMPT_METRIC_NAME = "GraphQLSolutionDAO.ListByProperty.Attempt";
    private static readonly LIST_BY_PROPERTY_ID_FAILURE_METRIC_NAME = "GraphQLSolutionDAO.ListByProperty.Failure";

    private readonly serialNumberGenerator: SerialNumberGenerator;
    private readonly api: typeof API;
    private readonly gqlOperation: typeof graphqlOperation;
    private readonly idTokenSupplier: IDTokenSupplier;
    private readonly logger: ClientLogger;

    constructor(
        serialNumberGenerator: SerialNumberGenerator,
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        idTokenSupplier: IDTokenSupplier,
        logger: ClientLogger
    ) {
        this.serialNumberGenerator = serialNumberGenerator;
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.idTokenSupplier = idTokenSupplier;
        this.logger = logger;
    }

    public async create(
        solution: Solution
    ): Promise<Solution> {
        try {
            this.logger.info(
                `Creating solution with ID=${solution.id}`,
                solution,
                [GraphQLSolutionDAO.CREATE_ATTEMPT_METRIC_NAME]
            )
            // Every new solution major version must have a unique serial number
            const serialNumber: number = solution.serialNumber ?? await this.serialNumberGenerator.generate(ResourceType.SOLUTION.toString(), ResourceType.SOLUTION);
            const solutionWithSerialNumber: Solution = {
                ...solution,
                serialNumber: serialNumber,
                status: SolutionStatus.DRAFT,
                localLastUpdatedTime: Date.now()
            };
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<CreateSolutionMutation> = await this.api.graphql(
                this.gqlOperation(
                    createSolution,
                    { input: solutionWithSerialNumber },
                    authToken
                )
            ) as GraphQLResult<CreateSolutionMutation>;
            return result.data?.createSolution as Solution;
        } catch (error) {
            this.logger.error(
                `Unexpected error occurred while creating solution with ID=${solution.id}`,
                error,
                [GraphQLSolutionDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreateSolutionError(
                `Unexpected error occurred while creating solution with ID=${solution.id}`,
                { cause: error as Error }
            );
        }
    }

    public async update(
        solution: Solution
    ): Promise<Solution> {
        try {
            this.logger.info(
                `Updating solution with ID=${solution.id}`,
                solution,
                [GraphQLSolutionDAO.UPDATE_ATTEMPT_METRIC_NAME]
            );
            const currentSolution: Solution = await this.getById(solution.id);
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<UpdateSolutionMutation> = await this.api.graphql(
                this.gqlOperation(
                    updateSolution,
                    {
                        input: {
                            ...solution,
                            _version: (currentSolution as any)._version,
                            localLastUpdatedTime: Date.now()
                        },
                        condition: {
                            status: {
                                "eq": SolutionStatus.DRAFT
                            }
                        },
                    },
                    authToken
                )
            ) as GraphQLResult<UpdateSolutionMutation>;
            return result.data?.updateSolution as Solution;
        } catch (error) {
            this.logger.error(
                `Unexpected error occurred while updating solution with ID=${solution.id}`,
                error,
                [GraphQLSolutionDAO.UPDATE_FAILURE_METRIC_NAME]
            );
            if ((error as any)?.errors && (error as any)?.errors[0].errorType === "ConditionalCheckFailedException") {
                throw new InvalidSolutionStateTransitionError(
                    `Invalid Solution status state transition with ID=${solution.id}`
                );
            }
            throw new UpdateSolutionError(
                `Unexpected error occurred while updating Solution with ID=${solution.id}`
            );
        }
    }

    public async updateStatus(
        id: string,
        newStatus: SolutionStatus
    ): Promise<Solution> {
        try {
            this.logger.info(
                `Updating solution status with ID=${id} to ${newStatus}`,
                undefined,
                [GraphQLSolutionDAO.UPDATE_ATTEMPT_METRIC_NAME]
            );
            const currentSolution: Solution = await this.getById(id);

            let statusCondition: object = {};
            if (newStatus === SolutionStatus.DRAFT) {
                statusCondition = {
                    status: {
                        "eq": SolutionStatus.DRAFT
                    }
                };
            }
            if (newStatus === SolutionStatus.PENDING_REVIEW) {
                statusCondition = {
                    or: [
                        { status: { "eq": SolutionStatus.DRAFT } },
                        { status: { "eq": SolutionStatus.PENDING_REVIEW } },
                    ]
                };
            }
            if (newStatus === SolutionStatus.APPROVED) {
                statusCondition = {
                    or: [
                        { status: { "eq": SolutionStatus.PENDING_REVIEW } },
                        { status: { "eq": SolutionStatus.APPROVED } },
                    ]
                };
            }
            if (newStatus === SolutionStatus.CANCELED) {
                statusCondition = {
                    or: [
                        { status: { "eq": SolutionStatus.DRAFT } },
                        { status: { "eq": SolutionStatus.PENDING_REVIEW } },
                        { status: { "eq": SolutionStatus.APPROVED } },
                        { status: { "eq": SolutionStatus.CANCELED } }
                    ]
                };
            }

            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<UpdateSolutionMutation> = await this.api.graphql(
                this.gqlOperation(
                    updateSolution,
                    {
                        input: {
                            id: id,
                            status: newStatus,
                            _version: (currentSolution as any)._version,
                            localLastUpdatedTime: Date.now()
                        },
                        condition: statusCondition,
                    },
                    authToken
                )
            ) as GraphQLResult<UpdateSolutionMutation>;
            return result.data?.updateSolution as Solution;
        } catch (error) {
            this.logger.error(
                `Unexpected error occurred while updating solution status with ID=${id} to ${newStatus}`,
                error,
                [GraphQLSolutionDAO.UPDATE_FAILURE_METRIC_NAME]
            );
            if ((error as any)?.errors && (error as any)?.errors[0].errorType === "ConditionalCheckFailedException") {
                throw new InvalidSolutionStateTransitionError(
                    `Invalid Solution status state transition with ID=${id}`
                );
            }
            throw new UpdateSolutionError(
                `Unexpected error occurred while updating Solution status with ID=${id}`,
                { cause: error as Error }
            );
        }
    }

    public async getById(
        id: string
    ): Promise<Solution> {
        try {
            this.logger.info(
                `Getting solution with ID=${id}`,
                undefined,
                [GraphQLSolutionDAO.GET_BY_ID_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<GetSolutionQuery> = await this.api.graphql(
                this.gqlOperation(
                    getSolution,
                    { id: id },
                    authToken
                )
            ) as GraphQLResult<GetSolutionQuery>;
            const resultItem = result.data?.getSolution;
            if (!resultItem) {
                throw new Error(`Unable to find Solution with id=${id}`);
            }
            return resultItem as Solution;
        } catch (error) {
            this.logger.error(
                `Failed to get solution with id=${id}`,
                error,
                [GraphQLSolutionDAO.GET_BY_ID_FAILURE_METRIC_NAME]
            );
            throw new GetSolutionError(
                `Failed to get solution with id=${id}`,
                { cause: error as Error }
            );
        }
    }

    public async listByPropertyId(
        propertyId: string
    ): Promise<Array<Solution>> {
        try {
            this.logger.info(
                `Listing solutions with propertyId=${propertyId}`,
                undefined,
                [GraphQLSolutionDAO.LIST_BY_PROPERTY_ID_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: ListSolutionsByPropertyQueryVariables = {
                propertyId: propertyId,
            };
            const result: GraphQLResult<ListSolutionsByPropertyQuery> = await this.api.graphql(
                this.gqlOperation(
                    listSolutionsByProperty,
                    variables,
                    authToken
                )
            ) as GraphQLResult<ListSolutionsByPropertyQuery>;
            const solutions: Array<Solution> = result.data?.listSolutionsByProperty?.items as Array<Solution> ?? [];
            return solutions.filter(solution => (solution as any)._deleted !== true);
        } catch (error) {
            this.logger.error(
                `Failed to list solutions with propertyId=${propertyId}`,
                error,
                [GraphQLSolutionDAO.LIST_BY_PROPERTY_ID_FAILURE_METRIC_NAME]
            );
            throw new ListSolutionsByPropertyError(
                `Failed to list solutions with propertyId=${propertyId}`,
                { cause: error as Error }
            );
        }
    }

    public async deleteByIdAndVersion(
        id: string,
        version: number
    ): Promise<void> {
        try {
            this.logger.info(
                `Deleting solution with ID=${id} and version=${version}`,
                undefined,
                [GraphQLSolutionDAO.DELETE_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: DeleteSolutionMutationVariables = {
                input: {
                    id: id,
                    _version: version
                },
                condition: {
                    status: {
                        eq: SolutionStatus.DRAFT
                    }
                }
            };
            const result: GraphQLResult<DeleteSolutionMutation> = await this.api.graphql(
                this.gqlOperation(
                    deleteSolution,
                    variables,
                    authToken
                )
            ) as GraphQLResult<DeleteSolutionMutation>;
            if (!result.data?.deleteSolution) {
                throw new Error(`Unable to delete Solution with id=${id}`);
            }
        } catch (error) {
            this.logger.error(
                `Failed to delete solution with id=${id} and version=${version}`,
                error,
                [GraphQLSolutionDAO.DELETE_FAILURE_METRIC_NAME]
            );
            throw new DeleteSolutionError(
                `Failed to delete solution with id=${id} and version=${version}`,
                { cause: error as Error }
            );
        }
    }
}
