import {
    Auth,
    CognitoUser
} from "@aws-amplify/auth";
import {
    Button,
    Snackbar,
    Typography
} from "@mui/material";
import {
    HOUR_IN_MILLISECONDS,
    MINUTE_IN_MILLISECONDS,
    SECOND_IN_MILLISECONDS
} from "../../lib/util/time/constants";
import {
    ProviderContext,
    useSnackbar
} from "notistack";
import {
    clearInterval,
    clearTimeout,
    setInterval,
    setTimeout
} from "timers";
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

import { Box } from "@mui/system";
import ClientLogger from "../../lib/logging/ClientLogger";
import ClientLoggerFactory from "../../lib/logging/ClientLoggerFactory";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { ConsolidatedDataSyncStatus } from "../../lib/datastore/ConsolidatedDataSyncStatus";
import { consolidatedDataSyncStatusSelector } from "../../lib/datastore/state/ConsolidatedDataSyncState";
import { tokenExpireEpochMillisAtom } from '../../lib/auth/state/AuthRecoilState';
import { useRecoilValue } from 'recoil';

const clientLogger: ClientLogger = ClientLoggerFactory.getClientLogger("SessionExpiryNotificationSnackbar");
const REFRESH_TOKEN_ATTEMPT = "REFRESH_TOKEN_ATTEMPT";
const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";

const SessionExpiryNotificationSnackbar = () => {
    const snackbar: ProviderContext = useSnackbar();
    const EXPIRE_THRESHOLD_IN_MILLIS = 12 * HOUR_IN_MILLISECONDS;

    const tokenExpireEpochMillis = useRecoilValue<number | undefined>(tokenExpireEpochMillisAtom);
    const tokenExpireEpochMillisRef = useRef<number | undefined>(tokenExpireEpochMillis);
    tokenExpireEpochMillisRef.current = tokenExpireEpochMillis;

    const [millisBeforeDisplayThreshold, setMilliSecondsBeforeDisplayThreshold] = useState<number | undefined>(undefined);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const consolidatedDataSyncStatus = useRecoilValue<ConsolidatedDataSyncStatus>(consolidatedDataSyncStatusSelector);

    useEffect(() => {
        if (consolidatedDataSyncStatus !== ConsolidatedDataSyncStatus.SYNCING_FROM_CLOUD) {
            setMilliSecondsBeforeDisplayThreshold(tokenExpireEpochMillis == null ? undefined : tokenExpireEpochMillis - Date.now() - EXPIRE_THRESHOLD_IN_MILLIS);
        }
    }, [tokenExpireEpochMillis, consolidatedDataSyncStatus]);

    // Count down
    const [countDownInMillis, setCountDownInMilliSeconds] = useState<number | undefined>(undefined);
    const countDownHours = useMemo<number | undefined>(() => {
        if (countDownInMillis == null) {
            return undefined;
        }
        return Math.floor(countDownInMillis / (HOUR_IN_MILLISECONDS));
    }, [countDownInMillis]);
    const countDownMinutes = useMemo<number | undefined>(() => {
        if (countDownInMillis == null) {
            return undefined;
        }
        return Math.floor((countDownInMillis % (HOUR_IN_MILLISECONDS)) / (MINUTE_IN_MILLISECONDS));
    }, [countDownInMillis]);
    const countDownSeconds = useMemo<number | undefined>(() => {
        if (countDownInMillis == null) {
            return undefined;
        }
        return Math.floor((countDownInMillis % (MINUTE_IN_MILLISECONDS)) / SECOND_IN_MILLISECONDS);
    }, [countDownInMillis]);

    // For setting up callbacks to show snackbar after millisBeforeDisplayThreshold
    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (millisBeforeDisplayThreshold != null) {
            if (millisBeforeDisplayThreshold <= 0) {
                setShowSnackbar(true);
                return;
            }
            setShowSnackbar(false);
            timeout = setTimeout(() => {
                setShowSnackbar(true);
                setMilliSecondsBeforeDisplayThreshold(0);
            }, millisBeforeDisplayThreshold);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [millisBeforeDisplayThreshold]);

    // For setting up interval to updating count down millis every second
    useEffect(() => {
        let interval: NodeJS.Timer;
        if (showSnackbar) {
            setCountDownInMilliSeconds(() => {
                if (tokenExpireEpochMillis == null) {
                    return 0;
                }
                return Math.max(0, tokenExpireEpochMillis - Date.now());
            });
            interval = setInterval(() => {
                setCountDownInMilliSeconds(prev => {
                    // Force refresh token when session is expired
                    if (prev! <= 0) {
                        refreshToken(true);
                        return 0;
                    }
                    if (tokenExpireEpochMillisRef.current == null) {
                        return 0;
                    }
                    return Math.max(0, tokenExpireEpochMillisRef.current - Date.now());
                });
            }, 1000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [showSnackbar, tokenExpireEpochMillis]);

    const refreshToken = useCallback(async (forceRefresh: boolean = false) => {
        if (!navigator.onLine && !forceRefresh) {
            snackbar.enqueueSnackbar("Device is offline, please try again later.", { variant: "error" });
            return;
        }
        try {
            clientLogger.info(
                `Refreshing session, force refresh: ${forceRefresh}`,
                undefined,
                [REFRESH_TOKEN_ATTEMPT]
            );
            const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession: CognitoUserSession = await Auth.currentSession();
            cognitoUser.refreshSession(currentSession.getRefreshToken(), (error) => {
                if (error) {
                    // Sign out if token is expired
                    if (tokenExpireEpochMillisRef.current != null && tokenExpireEpochMillisRef.current <= Date.now()) {
                        clientLogger.error(
                            `Failed to refresh session, signing out. Error: ${error}`,
                            { error },
                            [REFRESH_TOKEN_FAILURE]
                        );
                        snackbar.enqueueSnackbar("Failed to refresh session, signing out.", { variant: "error" });
                        Auth.signOut();
                        return;
                    }
                    clientLogger.error(
                        `Failed to refresh session. Error: ${error}`,
                        { error },
                        [REFRESH_TOKEN_FAILURE]
                    );
                    snackbar.enqueueSnackbar("Failed to refresh session, please try again later.", { variant: "error" });
                    return;
                }
                clientLogger.info(
                    "Successfully refreshed session.",
                    undefined,
                    [REFRESH_TOKEN_SUCCESS]
                );
            });
        } catch (error) {
            clientLogger.error(
                `Failed to retrieve current user when refreshing session. Error: ${error}`,
                { error },
                [REFRESH_TOKEN_FAILURE]
            );
            snackbar.enqueueSnackbar("Failed to retrieve session, signing out.", { variant: "error" });
            Auth.signOut();
        }
    }, []);

    return (
        <Snackbar
            open={showSnackbar}
            message={
                <Box>
                    <Typography variant="body1">
                        Are you still there?
                    </Typography>
                    <Typography variant="caption">
                        Please confirm in the next {countDownHours?.toString().padStart(2, "0")}:{countDownMinutes?.toString().padStart(2, "0")}:{countDownSeconds?.toString().padStart(2, "0")}.
                    </Typography>
                </Box>
            }
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            action={
                <Button
                    color="primary"
                    onClick={() => refreshToken(false)}
                >
                    Yes
                </Button>
            }
        />
    );
};

export default SessionExpiryNotificationSnackbar;