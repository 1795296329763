import {
    useEffect,
    useRef,
    useState
} from "react";

import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import HubBackedEventListener from "../../lib/event/HubBackedEventListener";
import HubBackedUserEventListenerFactory from "../../lib/auth/HubBackedUserEventListenerFactory";
import { SignInPage } from "./SignInPage";

type AuthenticatorProps = {
    children: ((props: {
        user?: CognitoUser;
    }) => JSX.Element);
};
export const Authenticator = (props: AuthenticatorProps) => {

    const [user, setUser] = useState<CognitoUser | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const hubBackedAuthEventListenerRef = useRef<HubBackedEventListener>(HubBackedUserEventListenerFactory.create(setUser));

    useEffect(() => {
        hubBackedAuthEventListenerRef.current.listen();
        return () => {
            hubBackedAuthEventListenerRef.current.stopListening();
        };
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const existingUser: CognitoUser = await Auth.currentAuthenticatedUser();
                setUser(existingUser);
            } catch (error) {
                setUser(undefined);
            } finally {
                setIsLoading(false);
            }
        };
        fetchUser();
    }, []);

    return (
        <>
            {isLoading ? <></> :
                user == null ?
                    <SignInPage /> : props.children({ user: user })
            }
        </>
    );
};