import {
    isHotKeyEnabledAtom,
    quickCreateIssueHotKeysAtom,
    quickCreateLocationHotKeysAtom,
    quickCreateWorkSpecificationHotKeysAtom,
    takeSnapshotHotKeysAtom,
    toggleMuteHotKeysAtom
} from "../../../lib/settings/SettingStates";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import { StateContext } from "../../../lib/design/document/state/StateContext";
import { useCreateIssueQuickAction } from "./actions/useCreateIssueQuickAction";
import { useCreateLocationQuickAction } from "./actions/useCreateLocationQuickAction";
import { useCreateWorkSpecificationQuickAction } from "./actions/useCreateWorkSpecificationQuickAction";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigationQuickActions } from "./actions/useNavigationQuickActions";
import { useOnKeysDown } from "../../general/hooks/useOnKeysDown";
import { useOnKeysUp } from "../../general/hooks/useOnKeysUp";
import { useRef } from "react";
import { useTakeSnapshotQuickAction } from "./actions/useTakeSnapshotQuickAction";
import { useToggleLocalMute } from "amazon-chime-sdk-component-library-react";

export const useEnableQuickActions = () => {
    const [isEnabled, setIsEnabled] = useRecoilState(isHotKeyEnabledAtom);
    const { muted, toggleMute } = useToggleLocalMute();
    const mutedRef = useRef(muted);
    mutedRef.current = muted;
    const toggleMuteRef = useRef(toggleMute);
    toggleMuteRef.current = toggleMute;

    const { createLocationQuickAction } = useCreateLocationQuickAction();
    const { createIssueQuickAction } = useCreateIssueQuickAction();
    const { createWorkSpecificationQuickAction } = useCreateWorkSpecificationQuickAction();
    const { takeSnapshotQuickAction } = useTakeSnapshotQuickAction();
    const { moveFocusUp, moveFocusDown, moveFocusIn, moveFocusOut } = useNavigationQuickActions(StateContext.INSPECTION);

    const createLocationHotKeys = useRecoilValue(quickCreateLocationHotKeysAtom);
    const createIssueHotKeys = useRecoilValue(quickCreateIssueHotKeysAtom);
    const createWorkSpecificationHotKeys = useRecoilValue(quickCreateWorkSpecificationHotKeysAtom);
    const takeSnapshotHotKeys = useRecoilValue(takeSnapshotHotKeysAtom);
    const toggleMuteHotKeys = useRecoilValue(toggleMuteHotKeysAtom);

    useHotkeys(createLocationHotKeys, createLocationQuickAction, { enabled: isEnabled, enableOnFormTags: ["INPUT"] });
    useHotkeys(createIssueHotKeys, createIssueQuickAction, { enabled: isEnabled, enableOnFormTags: ["INPUT"] });
    useHotkeys(createWorkSpecificationHotKeys, createWorkSpecificationQuickAction, { enabled: isEnabled, enableOnFormTags: ["INPUT"] });
    useHotkeys(takeSnapshotHotKeys, takeSnapshotQuickAction, { enabled: isEnabled, enableOnFormTags: ["INPUT"] });

    useHotkeys('ArrowDown', moveFocusDown, { enabled: isEnabled, preventDefault: true });
    useHotkeys('ArrowUp', moveFocusUp, { enabled: isEnabled, preventDefault: true });
    useHotkeys('ArrowRight', moveFocusIn, { enabled: isEnabled, preventDefault: true });
    useHotkeys('ArrowLeft', moveFocusOut, { enabled: isEnabled, preventDefault: true });

    useOnKeysDown(new Set(toggleMuteHotKeys.replace(/\s/g, "").split("+")), () => {
        if (mutedRef.current) {
            toggleMuteRef.current();
        }
    }, { enabled: isEnabled });

    useOnKeysUp(new Set(toggleMuteHotKeys.replace(/\s/g, "").split("+")), () => {
        if (!mutedRef.current) {
            toggleMuteRef.current();
        }
    }, { enabled: isEnabled });

    const enableQuickActions = () => {
        setIsEnabled(true);
    };

    const disableQuickActions = () => {
        setIsEnabled(false);
    };

    return { enableQuickActions, disableQuickActions, isHotKeyEnabled: isEnabled };
}

