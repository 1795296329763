import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const ReviewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="M15 21H1V1h14v6.974c.345.066.678.165 1 .288V0H0v22h16v-2.535a5.916 5.916 0 0 1-1 .288V21z"
        className="primary"
      />
      <path
        fill="currentColor"
        d="M8 3h6v1H8zM8 7v1h4.597a6.012 6.012 0 0 1 1.267-.137c.046 0 .091.006.137.007V7H8zM8 18h1.524a6.083 6.083 0 0 1-.769-1H8v1zM4.5 3.793l-.646-.647-.708.708L4.5 5.207l2.354-2.353-.708-.708zM3 7h3v1H3zM3 12h3v1H3zM3 17h3v1H3z"
        className="primary"
      />
    </g>
    <path
      fill="currentColor"
      d="m23.527 21.243-4.438-4.438-.752-.752a4.948 4.948 0 0 0 .526-2.19 5 5 0 1 0-5 5c.79 0 1.526-.2 2.19-.526l.752.752 4.438 4.438a1.611 1.611 0 0 0 2.284 0 1.615 1.615 0 0 0 0-2.284zm-9.664-3.38c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm3.929-.941.699.699-.87.87-.699-.699c.326-.254.616-.544.87-.87zm5.028 5.898a.612.612 0 0 1-.87 0l-3.873-3.873.87-.87 3.873 3.873c.24.24.24.63 0 .87z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(ReviewIcon);
