import ProposalPriceAfterAdjustmentCalculationError from "./errors/ProposalPriceAfterAdjustmentCalculationError";
import ProposalPriceAfterAdjustmentCalculator from "./ProposalPriceAfterAdjustmentCalculator";

export default class DefaultProposalPriceAfterAdjustmentCalculator implements ProposalPriceAfterAdjustmentCalculator {
    calculate(
        priceBeforeAdjustment: number | undefined,
        adjustmentAmount: number | undefined
    ): number | undefined {
        if (priceBeforeAdjustment == null) {
            throw new ProposalPriceAfterAdjustmentCalculationError("Proposal price before adjustment is undefined.");
        }
        if (adjustmentAmount == null) {
            throw new ProposalPriceAfterAdjustmentCalculationError("Adjustment amount is undefined.");
        }
        return priceBeforeAdjustment + adjustmentAmount;
    }
}
