import GraphQLAPI, {
    GraphQLResult
} from "@aws-amplify/api-graphql";
import {
    GetMeetingQuery,
    GetMeetingQueryVariables
} from "../../../API";

import { getMeeting } from "../../../graphql/queries";
import { Meeting } from "../../../models";
import { AuthIDTokenSupplierFactory } from "../../auth/AuthIDTokenSupplierFactory";
import { IDTokenSupplier } from "../../auth/IDTokenSupplier";
import ClientLogger from "../../logging/ClientLogger";
import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { MeetingDAO } from "./MeetingDAO";
import { MeetingDTO } from "./type/MeetingDTO";

export class GraphQLMeetingDAO implements MeetingDAO {
    private static readonly GET_ATTEMPT_METRIC_NAME = "GraphQLMeetingDAO.Get.Attempt";
    private static readonly GET_SUCCESS_METRIC_NAME = "GraphQLMeetingDAO.Get.Success";
    private static readonly GET_FAILURE_METRIC_NAME = "GraphQLMeetingDAO.Get.Failure";

    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async getById(id: string): Promise<MeetingDTO> {
        try {
            this.logger.info(
                `Fetching meeting with id: ${id}`,
                undefined,
                [GraphQLMeetingDAO.GET_ATTEMPT_METRIC_NAME]
            );
            const authToken = await this.idTokenSupplier.get();
            const result: GraphQLResult<GetMeetingQuery> = await GraphQLAPI.graphql({
                query: getMeeting,
                variables: { id } satisfies GetMeetingQueryVariables,
                authToken
            }) as GraphQLResult<GetMeetingQuery>;
            const meeting: Meeting | undefined = result.data?.getMeeting ?? undefined;
            if (!meeting) {
                throw new Error(`Meeting with id: ${id} not found`);
            }
            this.logger.info(
                `Successfully fetched meeting with id: ${id}`,
                undefined,
                [GraphQLMeetingDAO.GET_SUCCESS_METRIC_NAME]
            );
            return {
                ...meeting,
                data: JSON.parse(meeting.data)
            };
        } catch (error) {
            this.logger.error(
                `Failed to fetch meeting with id: ${id}`,
                error,
                [GraphQLMeetingDAO.GET_FAILURE_METRIC_NAME]
            );
            throw new Error(`Failed to fetch meeting with id: ${id}`, { cause: error });
        }
    }
}

export const graphQLMeetingDAO: MeetingDAO = new GraphQLMeetingDAO(
    ClientLoggerFactory.getClientLogger("GraphQLMeetingDAO"),
    AuthIDTokenSupplierFactory.getInstance()
);
