import {
    DimensionType,
    Measurement,
    MeasurementUnit
} from "../../../models";

import ClientLogger from "../../logging/ClientLogger";
import DimensionTypeAbbreviationFactory from "../../util/dimension/DimensionTypeAbbreviationFactory";
import MeasurementUnitAbbreviationFactory from "../../util/dimension/MeasurementUnitAbbreviationFactory";
import NumberStringConverter from "../../util/NumberStringConverter";
import ProposalItem from "../../design/bidding/ProposalItem";
import ProposalItemPriceExplanationGenerator from "./ProposalItemPriceExplanationGenerator";
import { ProposalItemPriceExplanationResult } from "./ProposalItemPriceExplanationResult";
import { ProposalItemPriceExplanationStatus } from "./ProposalItemPriceExplanationStatus";

export default class PercentageProposalItemPriceExplanationGenerator implements ProposalItemPriceExplanationGenerator {
    private readonly UNABLE_TO_CALCULATE_MESSAGE: string = "Unable to calculate the unit price.";
    private readonly logger: ClientLogger;

    constructor(
        logger: ClientLogger
    ) {
        this.logger = logger;
    }

    public generate(
        proposalItem: Partial<ProposalItem>,
        measurement: Measurement
    ): ProposalItemPriceExplanationResult {
        try {
            const { unitCount, priceBeforeAdjustment, unitPriceBeforeAdjustment, priceAfterAdjustment } = proposalItem;
            if (!unitCount || unitPriceBeforeAdjustment == null || priceBeforeAdjustment == null) {
                return {
                    explanation: this.UNABLE_TO_CALCULATE_MESSAGE,
                    status: ProposalItemPriceExplanationStatus.ERROR
                };
            }
            const unitCountString = NumberStringConverter.numberToString(unitCount, 1, true);
            const dimensionTypeAndMeasurementUnit: string = this.getDimensionTypeAndMeasurementUnitMessage(measurement);
            const unitPriceBeforeAdjustmentString: string = NumberStringConverter.numberToString(unitPriceBeforeAdjustment, 1, true);
            const priceBeforeAdjustmentString: string = NumberStringConverter.numberToString(priceBeforeAdjustment, 1, true);
            let priceBeforeAdjustmentExplanation = `${unitCountString} ${dimensionTypeAndMeasurementUnit} x ` +
                `$${unitPriceBeforeAdjustmentString} per ${dimensionTypeAndMeasurementUnit}`;

            if (priceAfterAdjustment != null && priceBeforeAdjustment !== priceAfterAdjustment) {
                if (priceAfterAdjustment >= 0) {
                    priceBeforeAdjustmentExplanation += `= $${priceBeforeAdjustmentString}`;
                    const adjustmentPercentage: number = (priceAfterAdjustment - priceBeforeAdjustment) / priceBeforeAdjustment;
                    const adjustmentPercentageString = NumberStringConverter.numberToString(adjustmentPercentage * 100, 1, true);
                    const priceAfterAdjustmentExplanation = `\nPrice adjustment: ${adjustmentPercentageString}%`;
                    return {
                        explanation: priceBeforeAdjustmentExplanation + priceAfterAdjustmentExplanation,
                        status: ProposalItemPriceExplanationStatus.SUCCESS
                    };
                }
                return {
                    explanation: `${priceBeforeAdjustmentExplanation}\nPrice after adjustment is negative.`,
                    status: ProposalItemPriceExplanationStatus.ERROR
                };
            }
            return {
                explanation: priceBeforeAdjustmentExplanation,
                status: ProposalItemPriceExplanationStatus.SUCCESS
            };
        } catch (error) {
            this.logger.error(
                "Failed to generate explanation for proposal item.",
                error,
                ["ProposalItemPriceExplanationGeneratorFailure"]
            );
            return {
                explanation: this.UNABLE_TO_CALCULATE_MESSAGE,
                status: ProposalItemPriceExplanationStatus.ERROR
            };
        }
    }

    private getDimensionTypeAndMeasurementUnitMessage(measurement: Measurement): string {
        const dimensionTypeString: string = DimensionTypeAbbreviationFactory.getAbbreviation(measurement.dimensionType as DimensionType);
        const measurementUnitString: string = MeasurementUnitAbbreviationFactory.getAbbreviation(measurement.measurementUnit as MeasurementUnit);
        return `${dimensionTypeString} ${measurementUnitString}`;
    };
}