import { Mutex } from "async-mutex";

export class PersistantMutexProvider {
    private static KEY_TO_LOCK_MAP = new Map<string, Mutex>();

    public static getMutex(key: string): Mutex {
        if (this.KEY_TO_LOCK_MAP.has(key)) {
            return this.KEY_TO_LOCK_MAP.get(key) as Mutex;
        }
        const newMutex = new Mutex();
        this.KEY_TO_LOCK_MAP.set(key, newMutex);
        return newMutex;
    }
}