import DefaultFileInputValidator from "./DefaultFileInputValidator";
import FileInputValidator from "./FileInputValidator";

export default class FileInputValidatorFactory {
    private static readonly FILE_COUNT_LIMIT = 25;
    private static readonly TOTAL_FILE_SIZE_LIMIT = 500 * 1000 * 1000; // 500 MB

    private static readonly INSTANCE: FileInputValidator = new DefaultFileInputValidator(
        this.FILE_COUNT_LIMIT,
        this.TOTAL_FILE_SIZE_LIMIT
    );

    public static getInstance(): FileInputValidator {
        return this.INSTANCE;
    }
}
