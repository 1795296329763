import { Property } from "../../../models";
import PropertyNameFactory from "./PropertyNameFactory";

/** @deprecated */
export default class AddressFallbackPropertyNameFactory implements PropertyNameFactory {
    public getName(property: Property): string {
        if (property.name) {
            return property.name;
        }
        if (property.address) {
            return property.address.substring(0, 15);
        }
        return "";
    }
}
