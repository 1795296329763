import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    GlobalStyles,
    LinearProgress,
    Theme,
    Typography
} from "@mui/material";
import {
    Property,
    Solution,
    SolutionMetadata,
    SolutionTenderingType,
    Tender
} from "../../../models";
import {
    ProviderContext,
    useSnackbar
} from "notistack";
import {
    getCleanUpProposalStates,
    getInitializeProposal,
    hideAdjustmentsInUIAtom,
    hidePricesInUIAtom
} from "../../../lib/design/bidding/state/v2/ProposalStates";
import {
    getCleanUpSolutionStates,
    getInitializeSolutionBySolutionId,
    isSolutionSoleSourceSelectorFamily,
    numberOfAttachmentsByContextSelectorFamily,
    numberOfImagesResizedByContextSelectorFamily,
    solutionMetadataAtom,
    solutionRecordAtom
} from "../../../lib/design/document/state/DocumentState";
import {
    useEffect,
    useMemo,
    useState
} from "react";
import {
    useRecoilState,
    useRecoilValue,
    useSetRecoilState
} from "recoil";

import { AwaitPropertiesChildrenSyncedWrapper } from "../../sync/AwaitPropertiesChildrenSyncedWrapper";
import { BLACK_OUTLINED_BUTTON } from "../../general/button/ButtonStyles";
import CachedIcon from '@mui/icons-material/Cached';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ConfirmationPrompt from "../../general/ConfirmationPrompt";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DefaultTenderDAOFactory } from "../../../lib/tender/dao/DefaultTenderDAOFactory";
import { EditSolutionButton } from "./EditSolutionButton";
import { ExportSolutionButton } from "./ExportSolutionButton";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LinkData from "../../../lib/util/link/LinkData";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { PermissionRestrictedControl } from "../../general/button/PermissionRestrictedControl";
import { PermissionType } from "../../../models";
import PropertyListLinkFactory from "../../../lib/util/link/PropertyListLinkFactory";
import ResponsiveBreadcrumbs from "../../ResponsiveBreadcrumbs";
import SolutionDetailsForClipboard from "./clipboard/SolutionDetailsForClipboard";
import { SolutionDetailsTable } from './tabular/SolutionDetailsTable';
import SolutionLinkFactory from "../../../lib/util/link/SolutionLinkFactory";
import { StateContext } from "../../../lib/design/document/state/StateContext";
import { TenderDAO } from "../../../lib/tender/dao/TenderDAO";
import { makeStyles } from "@mui/styles";
import { propertyIdToPropertySelectorFamily } from "../../../lib/property/state/PropertyRecoilState";
import { stateContextInFocusAtom } from "../../../lib/ui/InFocusRecoilStates";
import { useFetchSolutionImages } from "../../../lib/solution/hooks/useFetchSolutionImages";
import { useFetchSolutionWorkTypes } from "../../../lib/solution/hooks/useFetchSolutionWorkTypes";
import { useInitializeGApi } from "../../../lib/googleDrive/hooks/useInitializeGApi";
import { usePageDefaultStyle } from "../../../assets/style/usePageDefaultStyle";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& table": {
            borderCollapse: "collapse",
            tableLayout: "fixed",
            "& tr": {
                padding: theme.spacing(1),
                borderCollapse: "collapse",
                wordBreak: "break-word",
            },
            "& td": {
                padding: theme.spacing(1),
                borderCollapse: "collapse",
                wordBreak: "break-word",
            }
        }
    }
}));

export type ViewSolutionParams = {
    readonly propertyId: string;
    readonly solutionId: string;
};

const tenderDAO: TenderDAO = DefaultTenderDAOFactory.getInstance();

const ViewSolution = () => {
    /* Internal page states */
    const { propertyId, solutionId } = useParams<ViewSolutionParams>();
    const property = useRecoilValue<Property | null>(propertyIdToPropertySelectorFamily(propertyId));
    const { fetchSolutionWorkTypes, resetSolutionWorkTypeStates } = useFetchSolutionWorkTypes();
    const setStateContextInFocus = useSetRecoilState(stateContextInFocusAtom);
    const [isPageLoading, setPageLoading] = useState<boolean>(true);
    const [hidePrice, setHidePrice] = useRecoilState<boolean>(hidePricesInUIAtom);
    const [hideAdjustments, setHideAdjustments] = useRecoilState<boolean>(hideAdjustmentsInUIAtom);
    const [tableView, setTableView] = useState<boolean>(false);
    const solutionMetadata = useRecoilValue<SolutionMetadata>(solutionMetadataAtom);
    const isSoleSource = useRecoilValue<boolean>(isSolutionSoleSourceSelectorFamily(StateContext.SOLUTION_VIEWING));
    const solutionRecord = useRecoilValue<Solution | undefined>(solutionRecordAtom);
    const [openUpdateTenderPrompt, setOpenUpdateTenderPrompt] = useState<boolean>(false);
    const [updatingTender, setUpdatingTender] = useState<boolean>(false);
    const [associatedTender, setAssociatedTender] = useState<Tender | undefined>();

    /* Dependencies */
    const initializeSolutionBySolutionId = getInitializeSolutionBySolutionId(StateContext.SOLUTION_VIEWING);
    const initializeProposal = getInitializeProposal(StateContext.SOLUTION_VIEWING);
    const cleanUpSolutionStates = getCleanUpSolutionStates(StateContext.SOLUTION_VIEWING);
    const cleanUpProposalStates = getCleanUpProposalStates(StateContext.SOLUTION_VIEWING);
    const classes = useStyles();
    const { pageDefaultStyle } = usePageDefaultStyle();
    const snackbar: ProviderContext = useSnackbar();

    // Export to Google Docs
    const { initializeGApi } = useInitializeGApi();
    const { fetchSolutionAttachments, fetchSolutionImages, resetSolutionImageStates } = useFetchSolutionImages(StateContext.SOLUTION_VIEWING);
    const numberOfAttachments = useRecoilValue<number>(numberOfAttachmentsByContextSelectorFamily(StateContext.SOLUTION_VIEWING));
    const numberOfImagesResized = useRecoilValue<number>(numberOfImagesResizedByContextSelectorFamily(StateContext.SOLUTION_VIEWING));
    const imageProgressInPercent = useMemo<number>(() => {
        if (numberOfAttachments === 0) {
            return 100;
        }
        return numberOfImagesResized / numberOfAttachments * 100;
    }, [numberOfAttachments, numberOfImagesResized]);
    const isExportEnabled = useMemo(() => {
        return imageProgressInPercent >= 100 && !isSoleSource;
    }, [imageProgressInPercent, isSoleSource]);

    useEffect(() => {
        initialize();
        return () => {
            cleanUp();
        };
    }, []);

    useEffect(() => {
        if (solutionRecord) {
            tenderDAO.getByProjectNumber(solutionRecord.serialNumber!)
                .then((tender) => setAssociatedTender(tender));
        }
    }, [solutionRecord]);

    const initialize = async () => {
        setStateContextInFocus(StateContext.SOLUTION_VIEWING);
        const {solutionContent, solutionRecord} = await initializeSolutionBySolutionId(solutionId);
        const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
        const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
        if (googleClientID && googleApiKey) {
            initializeGApi(googleApiKey, googleClientID);
        }
        const [attachmentList, ..._] = await Promise.all([
            fetchSolutionAttachments(solutionContent),
            fetchSolutionWorkTypes(solutionContent),
            solutionRecord.metadata?.tenderingType !== SolutionTenderingType.TENDERED ? initializeProposal(solutionRecord.id, solutionRecord.latestMinorVersion!) : Promise.resolve()
        ]);
        setPageLoading(false);
        // Fetch solution images after the page is loaded to avoid blocking the page loading
        fetchSolutionImages(attachmentList);
    };

    const cleanUp = () => {
        cleanUpSolutionStates();
        cleanUpProposalStates();
        resetSolutionWorkTypeStates();
        resetSolutionImageStates();
    };

    const breadCrumbs = useMemo(() => {
        if (!property) {
            return null;
        }
        if (!isPageLoading) {
            const links: Array<LinkData> = [];
            links.push(PropertyListLinkFactory.create());
            links.push(SolutionLinkFactory.createByProperty(property));

            return (
                <ResponsiveBreadcrumbs
                    links={links}
                    currentPageText={solutionMetadata.name || "View Solution"}
                />
            );
        }
    }, [property, solutionMetadata.name, isPageLoading]);

    const updateTender = async () => {
        try {
            if (!associatedTender || !solutionRecord) {
                return;
            }
            const updatedTender = await tenderDAO.update(associatedTender.projectNumber!, solutionRecord);
            setAssociatedTender(updatedTender);
            snackbar.enqueueSnackbar("Tender updated successfully!", { variant: "success" });
        } catch (error) {
            snackbar.enqueueSnackbar("Failed to update tender!", { variant: "error" });
        } finally {
            setUpdatingTender(false);
            setOpenUpdateTenderPrompt(false);
        }
    };

    const copyWithStyle = (text: any) => {
        const doc: any = document;
        let range: any;
        let selection: any;

        if (doc.body.createTextRange) {
            range = doc.body.createTextRange();
            range.moveToElement(text);
            range.select();
        } else if (window.getSelection) {
            selection = window.getSelection();
            range = doc.createRange();
            range.selectNodeContents(text);
            selection.removeAllRanges();
            selection.addRange(range);
        }

        document.execCommand("copy");
        (window as any).getSelection().removeAllRanges();
    };

    const copyContent = () => {
        const content = document.getElementById("documentContent") as HTMLInputElement;
        content.style.backgroundColor = "white";
        //"transparent" backgroundColor will fall back to the page color, setting to "white" for copying
        copyWithStyle(content);
        content.style.backgroundColor = "transparent";
        snackbar.enqueueSnackbar("Copied document contents to clipboard!", { variant: "success" });
    };

    return (
        <AwaitPropertiesChildrenSyncedWrapper>
            <GlobalStyles
                styles={{
                    p: { margin: 0 },
                    hr: { display: "none" }
                }}
            />
            <Box sx={pageDefaultStyle}>
                {breadCrumbs}
                <Box
                    className={classes.root}
                    sx={{
                        mx: "auto",
                        mb: 2,
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "left",
                        gap: (theme) => theme.spacing(3),
                        p: 3,
                        width: "100%"
                    }}
                >
                    <Box>
                        <Box display="flex" gap={1}>
                            {
                                isSoleSource &&
                                <>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={hidePrice}
                                                icon={<CheckBoxOutlineBlankIcon color="primary" />}
                                                onChange={(event) => setHidePrice(event.target.checked)}
                                            />
                                        }
                                        label={<Typography color="primary" fontSize="0.875rem">Hide prices</Typography>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={hideAdjustments}
                                                icon={<CheckBoxOutlineBlankIcon color="primary" />}
                                                onChange={(event) => setHideAdjustments(event.target.checked)}
                                            />
                                        }
                                        label={<Typography color="primary" fontSize="0.875rem">Hide adjustments</Typography>}
                                    />
                                </>
                            }
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={tableView}
                                        icon={<CheckBoxOutlineBlankIcon color="primary" />}
                                        onChange={(event) => setTableView(event.target.checked)}
                                    />
                                }
                                label={<Typography color="primary" fontSize="0.875rem">View as table</Typography>}
                            />
                            <PermissionRestrictedControl
                                resourceId={solutionId}
                                requiredPermission={PermissionType.EDIT}
                            >
                                {(disabled) => (
                                    <EditSolutionButton
                                        disabled={disabled}
                                        size="small"
                                        variant="outlined"
                                        sx={BLACK_OUTLINED_BUTTON}
                                        startIcon={<ModeEditOutlineOutlinedIcon color="primary" />}
                                        propertyId={propertyId}
                                        solutionId={solutionId}
                                    />
                                )}
                            </PermissionRestrictedControl>
                        </Box>
                    </Box>
                    {
                        isPageLoading ? (
                            <LinearProgress />
                        ) :
                            tableView ?
                                <SolutionDetailsTable tenderingType={solutionMetadata.tenderingType as SolutionTenderingType}/> :
                                <>
                                    {imageProgressInPercent < 100 && (
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">
                                                Downloading images...
                                            </Typography>
                                            <Box display="flex" alignItems="center">
                                                <Box width="100%" mr={1}>
                                                    <LinearProgress variant="determinate" value={imageProgressInPercent} />
                                                </Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    {`${Math.round(imageProgressInPercent)}%`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                    <Box display="flex" gap={1} justifyContent="flex-end">
                                        {associatedTender && solutionRecord && associatedTender.solutionId !== solutionRecord.id && (
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                onClick={() => setOpenUpdateTenderPrompt(true)}
                                                startIcon={<CachedIcon />}
                                                disabled={updatingTender}
                                            >
                                                Update Tender
                                            </Button>
                                        )}
                                        <ExportSolutionButton
                                            size="small"
                                            variant="outlined"
                                            disabled={isExportEnabled}
                                            startIcon={<FileDownloadOutlinedIcon />}
                                            propertyId={propertyId}
                                            solutionId={solutionId}
                                            property={property}
                                        />
                                        <Button
                                            disabled={imageProgressInPercent < 100}
                                            onClick={copyContent}
                                            size="small"
                                            variant="outlined"
                                            startIcon={<ContentCopyIcon />}
                                        >
                                            Copy Contents
                                        </Button>
                                    </Box>
                                    <Box>
                                        <SolutionDetailsForClipboard />
                                    </Box>
                                    <Box id="documentContent" style={{
                                        position: "absolute",
                                        left: "-9999px",
                                        top: "-9999px",
                                        opacity: 0,
                                        pointerEvents: "none"
                                    }}>
                                        <SolutionDetailsForClipboard isForClipboard={true} />
                                    </Box>
                                </>
                    }
                </Box>
            </Box>
            <ConfirmationPrompt
                isVisible={openUpdateTenderPrompt}
                promptTitle="Update Tender"
                promptMessage="Are you sure you want to update the tender with this solution?"
                onConfirmButtonClicked={updateTender}
                onCancelButtonClicked={() => setOpenUpdateTenderPrompt(false)}
            />
        </AwaitPropertiesChildrenSyncedWrapper>
    );
};

export default ViewSolution;
