import ClientLoggerFactory from "../../../../logging/ClientLoggerFactory";
import { DataStore } from "aws-amplify";
import DataStoreWorkTypePricingDAO from "./DataStoreWorkTypePricingDAO";
import JSONDeserializerFactory from "../../../../util/data/serialization/JSONDeserializerFactory";
import JSONSerializerFactory from "../../../../util/data/serialization/JSONSerializerFactory";
import WorkTypePricingDAO from "../WorkTypePricingDAO";

export default class DataStoreWorkTypePricingDAOFactory {
    private static readonly INSTANCE: WorkTypePricingDAO = new DataStoreWorkTypePricingDAO(
        JSONDeserializerFactory.getInstance(),
        JSONSerializerFactory.getInstance(),
        ClientLoggerFactory.getClientLogger("DataStoreWorkTypePricingDAO"),
        DataStore
    );

    public static getInstance(): WorkTypePricingDAO {
        return DataStoreWorkTypePricingDAOFactory.INSTANCE;
    }
}
