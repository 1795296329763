import Task from "../../../util/concurrency/Task";
import RateLimiter from "../../../util/ratelimit/RateLimiter";
import AttachmentUploadRequest from "../request/AttachmentUploadRequest";
import AttachmentUploadWorkerFactory from "./AttachmentUploadWorkerFactory";
import RateLimitedTaskDecorator from "../../../util/concurrency/RateLimitedTaskDecorator";

export default class RateLimitedAttachmentUploadWorkerDecoratorFactory implements AttachmentUploadWorkerFactory {
    private readonly rateLimiter: RateLimiter;
    private readonly delegateFactory: AttachmentUploadWorkerFactory;

    constructor(
        rateLimiter: RateLimiter,
        delegateFactory: AttachmentUploadWorkerFactory,
    ) {
        this.rateLimiter = rateLimiter;
        this.delegateFactory = delegateFactory;
    }

    public createWorker(
        attachmentUploadRequest: AttachmentUploadRequest
    ): Task<string> {
        const worker = this.delegateFactory.createWorker(attachmentUploadRequest);
        return new RateLimitedTaskDecorator<string>(this.rateLimiter, worker);
    }
}
