import {
    AudioInputDevice,
    VideoInputDevice,
    VoiceFocusTransformDevice
} from "amazon-chime-sdk-js";
import {
    defaultAudioEnabledAtom,
    defaultAudioInputDeviceAtom,
    defaultAudioOutputDeviceIdAtom,
    defaultVideoEnabledAtom,
    defaultVideoInputDeviceAtom
} from "../state/ChimeStates";
import {
    useCallback,
    useEffect,
    useState
} from "react";
import {
    useLocalVideo,
    useMeetingManager
} from "amazon-chime-sdk-component-library-react";
import {
    useRecoilCallback,
    useSetRecoilState
} from "recoil";

export const useDeviceChangeSubscription = () => {
    const meetingManager = useMeetingManager();
    const { isVideoEnabled } = useLocalVideo();
    const [isSubscribeToVideoInputEnabled, setIsSubscribeToVideoInputEnabled] = useState(false);
    const setDefaultVideoEnabled = useSetRecoilState(defaultVideoEnabledAtom);

    useEffect(() => {
        if (!isSubscribeToVideoInputEnabled || !meetingManager.audioVideo) {
            return;
        }
        setDefaultVideoEnabled(isVideoEnabled);
    }, [isVideoEnabled]);

    const videoInputDeviceChangeCallback = useRecoilCallback(({ set }) => (device: VideoInputDevice | undefined) => {
        set(defaultVideoInputDeviceAtom, device);
    }, []);

    const audioInputDeviceChangeCallback = useRecoilCallback(({ set }) => async (device: AudioInputDevice | undefined) => {
        if (device instanceof VoiceFocusTransformDevice) {
            set(defaultAudioInputDeviceAtom, device.getInnerDevice());
            return;
        }
        set(defaultAudioInputDeviceAtom, device);
    }, []);

    const audioOutputDeviceChangeCallback = useRecoilCallback(({ set }) => (deviceId: string | null) => {
        set(defaultAudioOutputDeviceIdAtom, deviceId ?? undefined);
    }, []);

    const muteAndUnmuteLocalAudioCallback = useRecoilCallback(({ set }) => (muted: boolean) => {
        set(defaultAudioEnabledAtom, !muted);
    }, []);


    const subscribeToDeviceChange = useCallback(() => {
        meetingManager.subscribeToSelectedVideoInputDevice(videoInputDeviceChangeCallback);
        meetingManager.subscribeToSelectedAudioInputDevice(audioInputDeviceChangeCallback);
        meetingManager.subscribeToSelectedAudioOutputDevice(audioOutputDeviceChangeCallback);
        meetingManager.audioVideo?.realtimeSubscribeToMuteAndUnmuteLocalAudio(muteAndUnmuteLocalAudioCallback);
        setIsSubscribeToVideoInputEnabled(true);
    }, []);

    const unsubscribeFromDeviceChange = useCallback(() => {
        meetingManager.unsubscribeFromSelectedVideoInputDevice(videoInputDeviceChangeCallback);
        meetingManager.unsubscribeFromSelectedAudioInputDevice(audioInputDeviceChangeCallback);
        meetingManager.unsubscribeFromSelectedAudioOutputDevice(audioOutputDeviceChangeCallback);
        meetingManager.audioVideo?.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(muteAndUnmuteLocalAudioCallback);
        setIsSubscribeToVideoInputEnabled(false);
    }, []);

    return { subscribeToDeviceChange, unsubscribeFromDeviceChange };
};