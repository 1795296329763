import LinkData from "./LinkData";

export default class PropertyListLinkFactory {

    static create(): LinkData {
        return {
            displayText: "All Properties",
            url: `/properties`
        }
    }
}