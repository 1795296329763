import {
    AttendeeRole,
    PermissionResourceType
} from '../../models';
import {
    ChangeEvent,
    MouseEventHandler,
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    FormField,
    Input,
    MeetingStatus,
    useMeetingManager,
    useMeetingStatus
} from 'amazon-chime-sdk-component-library-react';
import {
    isJoiningMeetingAtom,
    meetingIdAtom,
    meetingSessionAtom
} from './state/ChimeStates';
import {
    useRecoilCallback,
    useRecoilState,
    useSetRecoilState
} from 'recoil';

import { AudioVideoSettings } from './test/AudioVideoSettings';
import { Box } from '@mui/material';
import { ChimeMeetingDTO } from '../../lib/meeting/chime/type/ChimeMeetingDTO';
import { JoinMeetingSettings } from './JoinMeetingSettings';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import { ShareMeetingButton } from './ShareMeetingButton';
import { SplitButton } from '../general/button/SplitButton';
import { StartChimeMeetingRecordingMutationVariables } from '../../API';
import { graphQLMeetingClient } from '../../lib/meeting/chime/GraphQLMeetingClient';
import { isLiveInspectionDrawerOpenAtom } from '../../lib/sidebar/SidebarRecoilStates';
import { useJoinMeeting } from './hooks/useJoinMeeting';
import { useParams } from 'react-router-dom';
import { useSnackbar } from "notistack";

export const MeetingForm = () => {
    const { propertyId } = useParams<{ propertyId: string; }>();
    const meetingManager = useMeetingManager();
    const { joinMeeting } = useJoinMeeting();
    const [meetingId, setMeetingId] = useRecoilState(meetingIdAtom);
    const [attendeeName, setAttendeeName] = useState('');
    const snackbar = useSnackbar();
    const meetingStatus = useMeetingStatus();
    const [isJoiningMeeting, setIsJoiningMeeting] = useRecoilState(isJoiningMeetingAtom);
    const isMeetingActive = useMemo(() => {
        return meetingStatus === MeetingStatus.Succeeded || meetingStatus === MeetingStatus.Reconnecting;
    }, [meetingStatus]);
    const setIsLiveInspectionDrawerOpen = useSetRecoilState(isLiveInspectionDrawerOpenAtom);

    useEffect(() => {
        setIsLiveInspectionDrawerOpen(!!meetingId);
    }, []);

    const onJoinMeetingClicked: MouseEventHandler<HTMLButtonElement> = useRecoilCallback(({ set }) => async (event) => {
        event.preventDefault();
        if (!meetingId) {
            snackbar.enqueueSnackbar('Meeting Id not found, try refreshing the page and try again', { variant: 'error' });
            return;
        }
        if ("speechSynthesis" in window) {
            // Triggering a speech synthesis utterance to get around IOS browser's autoplay policy
            speechSynthesis.speak(new SpeechSynthesisUtterance(""));
        }
        try {
            setIsJoiningMeeting(true);
            await joinMeeting(meetingId, attendeeName, AttendeeRole.AGENT);
            await meetingManager.start();
        } catch (error) {
            console.log(error);
            snackbar.enqueueSnackbar('Failed to join meeting or meeting has ended', { variant: 'error' });
        } finally {
            setIsJoiningMeeting(false);
        }
    }, [meetingId, attendeeName]);

    const onStartMeetingClicked: MouseEventHandler<HTMLButtonElement> = useRecoilCallback(({ set }) => async (event) => {
        event.preventDefault();
        if ("speechSynthesis" in window) {
            // Triggering a speech synthesis utterance to get around IOS browser's autoplay policy
            speechSynthesis.speak(new SpeechSynthesisUtterance(""));
        }
        try {
            setMeetingId(undefined);
            setIsJoiningMeeting(true);
            if (!propertyId) {
                snackbar.enqueueSnackbar("Property Id not found, try refreshing the page and try again", { variant: 'error' });
                return;
            }
            const createMeetingResult: ChimeMeetingDTO = await graphQLMeetingClient.createMeeting(attendeeName, AttendeeRole.AGENT);
            const startRecordingVariables: StartChimeMeetingRecordingMutationVariables = {
                meetingId: createMeetingResult.meetingInfo.MeetingId!,
                attachmentParentId: propertyId,
                attachmentParentResourceType: PermissionResourceType.PROPERTY
            };
            await graphQLMeetingClient.startRecording(startRecordingVariables);
            const meetingSessionConfiguration = new MeetingSessionConfiguration(
                createMeetingResult.meetingInfo,
                createMeetingResult.attendeeInfo
            );
            set(meetingSessionAtom, {
                attendeeInfo: createMeetingResult.attendeeInfo,
                meetingInfo: createMeetingResult.meetingInfo,
                attendeeName
            });
            await meetingManager.join(meetingSessionConfiguration, {
                enableWebAudio: true // required for voice focus
            });
            await meetingManager.start();
        } catch (error) {
            console.log(error);
            snackbar.enqueueSnackbar('Failed to start meeting', { variant: 'error' });
        } finally {
            setIsJoiningMeeting(false);
        }
    }, [attendeeName, propertyId]);

    const splitButtonOptions = useMemo(() => {
        if (meetingId) {
            return [
                {
                    label: 'Join Meeting',
                    onClick: onJoinMeetingClicked
                },
                {
                    label: 'Start a Meeting',
                    onClick: onStartMeetingClicked
                }
            ];
        }
        return [
            {
                label: 'Start a Meeting',
                onClick: onStartMeetingClicked
            }
        ];
    }, [onJoinMeetingClicked, onStartMeetingClicked, meetingId]);

    return (
        <Box p={1} display="flex" flexDirection="column" gap={1}>
            {isMeetingActive ? (
                <Box display="flex" gap={1} justifyContent="center">
                    <ShareMeetingButton />
                </Box>
            ) : (
                <>
                    <FormField
                        field={Input}
                        label="Name"
                        value={attendeeName}
                        fieldProps={{
                            name: 'Name',
                            placeholder: 'Enter your Attendee Name',
                            disabled: isMeetingActive || isJoiningMeeting
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setAttendeeName(e.target.value);
                        }}
                    />
                    <JoinMeetingSettings />
                    <AudioVideoSettings />
                    <SplitButton
                        options={splitButtonOptions}
                        disabled={isMeetingActive || isJoiningMeeting}
                        fullWidth
                    />
                </>
            )}
        </Box>
    );
};
