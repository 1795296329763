import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const AnnotationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M22.998 22.503a.999.999 0 0 1-.948-.684l-.354-1.061-.866 1.299a1.002 1.002 0 0 1-1.727-.108l-.38-.759-1.019 1.019a.999.999 0 0 1-1.414 0l-1-1a.999.999 0 1 1 1.414-1.414l.293.293 1.293-1.293a1.001 1.001 0 0 1 1.601.26l.229.459 1.044-1.566a.997.997 0 0 1 1.78.238l1 3a1 1 0 0 1-.946 1.317z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="m20.002 1.497-11 11v4h4l11-11-4-4zm-7.42 14h-2.58v-2.58l7.7-7.71 2.59 2.59-7.71 7.7zm8.21-8.2-2.59-2.59 1.8-1.79 2.58 2.58-1.79 1.8z"
      className="accent"
    />
    <path
      fill="currentColor"
      d="M11.998 21.503h-9c-1.626 0-3-1.374-3-3 0-1.654 1.305-3 2.909-3h3.818a1 1 0 1 1 0 2H2.907c-.501 0-.909.448-.909 1 0 .523.477 1 1 1h9a1 1 0 1 1 0 2z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(AnnotationIcon);
