import {
    ADDRESS_FIELD_LENGTH,
    DESCRIPTION_FIELD_LENGTH,
    NAME_FIELD_LENGTH,
    PHONE_FIELD_LENGTH
} from "../../lib/property/PropertyConstants";
import {
    Box,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {
    propertyFormValidationMapAtom,
    propertyFormValueAtom
} from "../../lib/property/state/PropertyFormRecoilState";

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PhoneInputTextField from "../../components/PhoneInputTextField";
import PhoneNumberInput from "react-phone-number-input/input"; //eslint-disable-line
import { Property } from "../../models";
import theme from "../../assets/style/theme";
import { useRecoilState } from "recoil";

const PropertyAttributeForm = () => {
    /* PropertyAttributeForm state */
    const [formState, setFormState] = useRecoilState<Property | undefined>(propertyFormValueAtom);
    const [FormValidationMap, setFormValidationMap] = useRecoilState<Map<keyof Property, boolean>>(propertyFormValidationMapAtom);

    const onFieldChange = (key: keyof Property, value: string): void => {
        let updatedFormState: Property;
        if (formState === undefined) {
            updatedFormState = new Property({
                [key]: value
            });
        } else {
            updatedFormState = {
                ...formState,
                [key]: value
            };
        }

        setFormState(updatedFormState);
        setFormValidationMap((original) => {
            const updated: Map<keyof Property, boolean> = new Map(original);
            updated.set("address", !!updatedFormState.address);
            return updated;
        });
    };

    return (
        <Grid
            spacing={1}
            container
            flex={1}
            alignContent="space-between"
        >
            <Grid item xs={12}>
                <TextField
                    placeholder="Property Address"
                    fullWidth
                    required
                    error={FormValidationMap.get("address") === false}
                    inputProps={{ maxLength: ADDRESS_FIELD_LENGTH }}
                    value={formState?.address ?? ""}
                    onChange={(e) => onFieldChange("address", e.target.value)}
                    onBlur={(e) => {
                        if (!formState?.name) {
                            onFieldChange("name", e.target.value);
                        }
                    }}
                    variant="outlined"
                    size="small"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    placeholder="Property Name"
                    fullWidth
                    inputProps={{ maxLength: NAME_FIELD_LENGTH }}
                    value={formState?.name ?? ""}
                    onChange={(e) => onFieldChange("name", e.target.value)}
                    variant="outlined"
                    size="small"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    placeholder="Description"
                    fullWidth
                    multiline
                    rows={3}
                    inputProps={{ maxLength: DESCRIPTION_FIELD_LENGTH }}
                    value={formState?.description ?? ""}
                    onChange={(e) => onFieldChange("description", e.target.value)}
                    variant="outlined"
                    size="small"
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">
                    Primary Contact
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'stretch', gap: '8px' }}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        bgcolor="rgba(0,0,0,0.05)"
                        border="solid 1px lightgray"
                        sx={{ aspectRatio: '1/1' }}
                    >
                        <PersonOutlineIcon style={{ fontSize: 80, color: theme.palette.primary.light }} />
                    </Box>
                    <Grid container direction="row" spacing={1} width={"100%"}>
                        <Grid item xs={6}>
                            <TextField
                                placeholder="Contact Name"
                                fullWidth
                                value={formState?.primaryContactName ?? ""}
                                onChange={(e) => onFieldChange("primaryContactName", e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PhoneNumberInput
                                inputComponent={PhoneInputTextField as any}
                                fullWidth
                                value={formState?.primaryContactNumber || ""}
                                defaultCountry="CA"
                                placeholder="Contact Number"
                                inputProps={{ maxLength: PHONE_FIELD_LENGTH }}
                                onChange={(value) => onFieldChange("primaryContactNumber", value as string)}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                placeholder="Contact Email"
                                fullWidth
                                value={formState?.primaryContactEmail ?? ""}
                                onChange={(e) => onFieldChange("primaryContactEmail", e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default PropertyAttributeForm;