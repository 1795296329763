import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const TimeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 1c6.065 0 11 4.935 11 11s-4.935 11-11 11S1 18.065 1 12 5.935 1 12 1m0-1C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="m14.293 15.707-3-3L11 12V4h2v7.586l2.707 2.707z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(TimeIcon);
