import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import {
    ClassNameMap,
    makeStyles
} from "@mui/styles";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { PaletteColor } from "../../lib/util/ui/PaletteColor";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    dangerButton: {
        backgroundColor: theme.palette.error.main,
        "&:hover": {
            backgroundColor: theme.palette.error.dark
        },
        "&:disabled": {
            backgroundColor: theme.palette.error.light
        }
    },
    primaryButton: {
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark
        },
    }
}));

export interface ConfirmationPromptProps {
    readonly cancelButtonText?: string;
    readonly confirmButtonText?: string;
    readonly confirmButtonColor?: PaletteColor;
    readonly isVisible: boolean;
    readonly promptTitle?: string;
    readonly promptMessage: string;
    readonly onCancelButtonClicked: (() => Promise<void>) | (() => void);
    readonly onConfirmButtonClicked: (() => Promise<void>) | (() => void);
}

const ConfirmationPrompt = (props: ConfirmationPromptProps) => {

    const classes: ClassNameMap = useStyles();
    const [isCancelling, setIsCancelling] = useState<boolean>(false);
    const [isConfirming, setIsConfirming] = useState<boolean>(false);

    const onCancelButtonClicked = async () => {
        try {
            setIsCancelling(true);
            await props.onCancelButtonClicked();
        } catch (error) {
            console.error(error);
        } finally {
            setIsCancelling(false);
        }
    };

    const onConfirmButtonClicked = async () => {
        try {
            setIsConfirming(true);
            await props.onConfirmButtonClicked();
        } catch (error) {
            console.error(error);
        } finally {
            setIsConfirming(false);
        }
    };

    return (
        <>
            {
                !props.isVisible ? (
                    <></>
                ) : (
                    <Dialog
                        open={props.isVisible}
                        fullWidth={true}
                        data-cy="prompt-dialog"
                    >
                        {
                            props.promptTitle ? (
                                <DialogTitle
                                    data-cy="prompt-title"
                                >
                                    {props.promptTitle}
                                </DialogTitle>
                            ) : (
                                <></>
                            )
                        }
                        <DialogContent>
                            <DialogContentText
                                data-cy="prompt-message"
                            >
                                {props.promptMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Box
                                gap={2}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <LoadingButton
                                    variant="contained"
                                    onClick={onCancelButtonClicked}
                                    loading={isCancelling}
                                    startIcon={<></>}
                                    loadingPosition="start"
                                    sx={{px: 4}}
                                >
                                    {props.cancelButtonText || "Cancel"}
                                </LoadingButton>
                                <LoadingButton
                                    variant="contained"
                                    className={props.confirmButtonColor ? classes[props.confirmButtonColor] : classes.dangerButton}
                                    onClick={onConfirmButtonClicked}
                                    loading={isConfirming}
                                    startIcon={<></>}
                                    loadingPosition="start"
                                    sx={{px: 4}}
                                >
                                    {props.confirmButtonText || "Confirm"}
                                </LoadingButton>
                            </Box>
                        </DialogActions>
                    </Dialog>
                )
            }
        </>
    );
};

export default ConfirmationPrompt;
