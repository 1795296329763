import {
    Box,
    Button,
    Typography,
    useMediaQuery
} from "@mui/material";
import {
    PermissionResourceType,
    Property
} from "../../models";
import {
    propertyFormIsValidSelector,
    propertyFormValidationMapAtom,
    propertyFormValueAtom
} from "../../lib/property/state/PropertyFormRecoilState";
import {
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    useRecoilValue,
    useResetRecoilState
} from "recoil";

import { CREATE_PROPERTY_ID } from "../../lib/property/PropertyConstants";
import ClientLogger from "../../lib/logging/ClientLogger";
import ClientLoggerFactory from "../../lib/logging/ClientLoggerFactory";
import { CreatePropertyImageCarousel } from "../../componentsV2/image/CreatePropertyImageCarousel";
import DataStorePropertyDAOFactory from "../../lib/property/dao/datastore/DataStorePropertyDAOFactory";
import PropertyAttributeForm from "../../componentsV2/property/PropertyAttributeForm";
import PropertyDAO from "../../lib/property/dao/PropertyDAO";
import PropertyListLinkFactory from "../../lib/util/link/PropertyListLinkFactory";
import { PropertyListManager } from "../../lib/datastore/sync/offline/PropertyListManager";
import { RecentlyCreatedPropertyListManagerFactory } from "../../lib/datastore/sync/offline/RecentlyCreatedPropertyListManagerFactory";
import ResponsiveBreadcrumbs from "../../components/ResponsiveBreadcrumbs";
import { StartPropertiesChildrenSyncWrapper } from "../../components/sync/StartPropertiesChildrenSyncWrapper";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { TemporaryImage } from "../../componentsV2/image/TemporaryImage";
import { isDesktop } from "react-device-detect";
import { parentIdToStagedAttachmentUploadEntitiesAtomFamily } from "../../lib/image/state/AttachmentUploadState";
import theme from "../../assets/style/theme";
import { useCreateLocation } from "../../lib/location/hook/useCreateLocation";
import { useHistory } from "react-router-dom";
import { usePageDefaultStyle } from "../../assets/style/usePageDefaultStyle";
import { useUploadImages } from "../../componentsV2/image/carouselComponent/useUploadImage";

const CreateProperty = () => {
    const COMPONENT_NAME = "CreateProperty";

    /* Internal page state */
    const breadcrumbsLinks = [PropertyListLinkFactory.create()];

    /* PropertyImageSelector state */
    const resetStagedAttachmentUploadEntities = useResetRecoilState(parentIdToStagedAttachmentUploadEntitiesAtomFamily(CREATE_PROPERTY_ID));
    const [stagedImages, setStagedImages] = useState<Array<TemporaryImage>>([]);
    const [uploadImages] = useUploadImages();

    /* PropertyAttributeForm state */
    const formState = useRecoilValue<Property | undefined>(propertyFormValueAtom);
    const isFormValid = useRecoilValue<boolean>(propertyFormIsValidSelector);
    const resetFormState = useResetRecoilState(propertyFormValueAtom);
    const resetFormValidationMap = useResetRecoilState(propertyFormValidationMapAtom);

    /* Dependencies */
    const loggerRef = useRef<ClientLogger>(ClientLoggerFactory.getClientLogger(COMPONENT_NAME));
    const propertyDAORef = useRef<PropertyDAO>(DataStorePropertyDAOFactory.getInstance());
    const recentlyCreatedPropertyListManagerRef = useRef<PropertyListManager>(RecentlyCreatedPropertyListManagerFactory.getInstance());
    const {createDefaultLocation} = useCreateLocation(StateContext.INSPECTION);

    const { pageDefaultStyle } = usePageDefaultStyle();
    const history = useHistory();
    const isPortrait = useMediaQuery("(orientation: portrait)");
    const isScreenSizeSmallerThanMD = useMediaQuery(theme.breakpoints.down("md"));
    const attributeFormRef = useRef<HTMLDivElement>();

    const isOneColumnLayout = useMemo(() => {
        if (isDesktop) {
            return isScreenSizeSmallerThanMD;
        }
        return isPortrait;
    }, [isDesktop, isPortrait, isScreenSizeSmallerThanMD]);

    useEffect(() => () => {
        cleanUpRecoilState();
    }, []);

    const cleanUpRecoilState = () => {
        resetFormState();
        resetFormValidationMap();
        resetStagedAttachmentUploadEntities();
    };

    const handleCreateProperty = async () => {
        try {
            if (!isFormValid || !formState) {
                return;
            }
            const createdProperty: Property = await propertyDAORef.current.create(formState);
            const images: Array<Blob> = stagedImages.map((image) => image.file);
            await uploadImages(images, { shouldResize: true }, { id: createdProperty.id, type: PermissionResourceType.PROPERTY });
            await recentlyCreatedPropertyListManagerRef.current.addPropertyId(createdProperty.id);
            await createDefaultLocation(createdProperty.id);
            history.replace(`/properties/${createdProperty.id}/issues`, {});
        } catch (error) {
            loggerRef.current.error(
                "Error occurred creating the property",
                error,
                ["PropertyCreationFailure"]
            );
        }
    };

    return (
        <StartPropertiesChildrenSyncWrapper>
            <Box
                sx={pageDefaultStyle}
                display="flex"
                justifyContent="center"
            >
                <Box
                    pt={1}
                    display="grid"
                    gridTemplateColumns={isOneColumnLayout ? "1fr" : `repeat(2, minmax(0, ${theme.breakpoints.values.md}px))`}
                    justifyContent="space-around"
                    gap={2}
                >
                    <Box
                        mb={1}
                        gridColumn={"1/-1"} // Span all columns
                    >
                        <ResponsiveBreadcrumbs
                            currentPageText="Create New Property"
                            links={breadcrumbsLinks}
                        />
                    </Box>
                    <Box height={attributeFormRef.current?.clientHeight} display="flex" flexDirection="column" gap={1}>
                        <Box flexShrink={0} height={theme.spacing(4)}>
                            <Typography variant="h6">
                                Property Image
                            </Typography>
                        </Box>
                        <Box
                            height={`calc(100% - ${theme.spacing(5)})`}
                        >
                            <CreatePropertyImageCarousel
                                imageDTOs={stagedImages}
                                setImageDTOs={setStagedImages}
                            />
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        position="relative"
                        width="100%"
                    >
                        <Box display="flex" flexDirection="column" gap={1} ref={attributeFormRef}>
                            <Typography variant="h6">
                                Property
                            </Typography>
                            <PropertyAttributeForm />
                        </Box>
                        <Box
                            position="absolute"
                            bottom={`-${theme.spacing(8)}`}
                            width="100%"
                            pb={2}
                        >
                            <Button
                                id="btnCreateProperty"
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleCreateProperty}
                                disabled={!isFormValid}
                            >
                                Create Property
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </StartPropertiesChildrenSyncWrapper>
    );
};

export default CreateProperty;
