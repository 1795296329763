/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listResourceAggregateUserPermissions = /* GraphQL */ `
  query ListResourceAggregateUserPermissions(
    $resourceType: PermissionResourceType!
    $resourceId: String!
    $nextToken: String
  ) {
    listResourceAggregateUserPermissions(
      resourceType: $resourceType
      resourceId: $resourceId
      nextToken: $nextToken
    ) {
      items {
        userId
        username
        permissions
      }
      nextToken
    }
  }
`;
export const handleConverseCommand = /* GraphQL */ `
  query HandleConverseCommand($input: HandleConverseCommandInput) {
    handleConverseCommand(input: $input)
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      type
      parentId
      parentType
      propertyId
      key
      localCreatedAtTime
      status
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $id: ID
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAttachments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        parentId
        parentType
        propertyId
        key
        localCreatedAtTime
        status
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttachments = /* GraphQL */ `
  query SyncAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        parentId
        parentType
        propertyId
        key
        localCreatedAtTime
        status
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listAttachmentsByParent = /* GraphQL */ `
  query ListAttachmentsByParent(
    $parentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachmentsByParent(
      parentId: $parentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        parentId
        parentType
        propertyId
        key
        localCreatedAtTime
        status
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listAttachmentsByProperty = /* GraphQL */ `
  query ListAttachmentsByProperty(
    $propertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachmentsByProperty(
      propertyId: $propertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        parentId
        parentType
        propertyId
        key
        localCreatedAtTime
        status
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBiddingContact = /* GraphQL */ `
  query GetBiddingContact($id: ID!) {
    getBiddingContact(id: $id) {
      id
      email
      ownerEntityId
      contactEntityId
      name
      privateDescription
      publicDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBiddingContacts = /* GraphQL */ `
  query ListBiddingContacts(
    $id: ID
    $filter: ModelBiddingContactFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBiddingContacts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        ownerEntityId
        contactEntityId
        name
        privateDescription
        publicDescription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBiddingContacts = /* GraphQL */ `
  query SyncBiddingContacts(
    $filter: ModelBiddingContactFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBiddingContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        ownerEntityId
        contactEntityId
        name
        privateDescription
        publicDescription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBiddingContactsByOwnerEntityId = /* GraphQL */ `
  query ListBiddingContactsByOwnerEntityId(
    $ownerEntityId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBiddingContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBiddingContactsByOwnerEntityId(
      ownerEntityId: $ownerEntityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        ownerEntityId
        contactEntityId
        name
        privateDescription
        publicDescription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBidInvitation = /* GraphQL */ `
  query GetBidInvitation($id: ID!) {
    getBidInvitation(id: $id) {
      id
      biddingContactId
      tenderId
      ownerEntityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBidInvitations = /* GraphQL */ `
  query ListBidInvitations(
    $id: ID
    $filter: ModelBidInvitationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBidInvitations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        biddingContactId
        tenderId
        ownerEntityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBidInvitations = /* GraphQL */ `
  query SyncBidInvitations(
    $filter: ModelBidInvitationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBidInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        biddingContactId
        tenderId
        ownerEntityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBidInvitationsByTenderId = /* GraphQL */ `
  query ListBidInvitationsByTenderId(
    $tenderId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBidInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBidInvitationsByTenderId(
      tenderId: $tenderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        biddingContactId
        tenderId
        ownerEntityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBid = /* GraphQL */ `
  query GetBid($id: ID!) {
    getBid(id: $id) {
      id
      tenderId
      latestMinorVersion
      latestAcceptedMinorVersion
      latestSubmittedMinorVersion
      status
      bidNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBids = /* GraphQL */ `
  query ListBids(
    $id: ID
    $filter: ModelBidFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBids(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tenderId
        latestMinorVersion
        latestAcceptedMinorVersion
        latestSubmittedMinorVersion
        status
        bidNumber
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBids = /* GraphQL */ `
  query SyncBids(
    $filter: ModelBidFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBids(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenderId
        latestMinorVersion
        latestAcceptedMinorVersion
        latestSubmittedMinorVersion
        status
        bidNumber
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBidsByTenderId = /* GraphQL */ `
  query ListBidsByTenderId(
    $tenderId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBidFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBidsByTenderId(
      tenderId: $tenderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenderId
        latestMinorVersion
        latestAcceptedMinorVersion
        latestSubmittedMinorVersion
        status
        bidNumber
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBidsByCreatedBy = /* GraphQL */ `
  query ListBidsByCreatedBy(
    $createdBy: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBidFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBidsByCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenderId
        latestMinorVersion
        latestAcceptedMinorVersion
        latestSubmittedMinorVersion
        status
        bidNumber
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBidMinorVersion = /* GraphQL */ `
  query GetBidMinorVersion($id: ID!) {
    getBidMinorVersion(id: $id) {
      id
      bidId
      minorVersion
      tenderId
      tenderMinorVersion
      proposalId
      status
      metadata {
        bidderContactName
        bidderContactEmail
        bidderCompanyName
        bidderContactAddress
        bidderContactPhoneNumber
        bidderWebsite
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBidMinorVersions = /* GraphQL */ `
  query ListBidMinorVersions(
    $id: ID
    $filter: ModelBidMinorVersionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBidMinorVersions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        bidId
        minorVersion
        tenderId
        tenderMinorVersion
        proposalId
        status
        metadata {
          bidderContactName
          bidderContactEmail
          bidderCompanyName
          bidderContactAddress
          bidderContactPhoneNumber
          bidderWebsite
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBidMinorVersions = /* GraphQL */ `
  query SyncBidMinorVersions(
    $filter: ModelBidMinorVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBidMinorVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        bidId
        minorVersion
        tenderId
        tenderMinorVersion
        proposalId
        status
        metadata {
          bidderContactName
          bidderContactEmail
          bidderCompanyName
          bidderContactAddress
          bidderContactPhoneNumber
          bidderWebsite
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBidMinorVersionsByBidId = /* GraphQL */ `
  query ListBidMinorVersionsByBidId(
    $bidId: String!
    $minorVersion: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBidMinorVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBidMinorVersionsByBidId(
      bidId: $bidId
      minorVersion: $minorVersion
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bidId
        minorVersion
        tenderId
        tenderMinorVersion
        proposalId
        status
        metadata {
          bidderContactName
          bidderContactEmail
          bidderCompanyName
          bidderContactAddress
          bidderContactPhoneNumber
          bidderWebsite
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientLog = /* GraphQL */ `
  query GetClientLog($id: ID!) {
    getClientLog(id: $id) {
      id
      userId
      userAgent
      severityLevel
      componentName
      message
      details
      tags
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listClientLogs = /* GraphQL */ `
  query ListClientLogs(
    $id: ID
    $filter: ModelClientLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClientLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        userAgent
        severityLevel
        componentName
        message
        details
        tags
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientLogs = /* GraphQL */ `
  query SyncClientLogs(
    $filter: ModelClientLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        userAgent
        severityLevel
        componentName
        message
        details
        tags
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listClientLogsByUserId = /* GraphQL */ `
  query ListClientLogsByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClientLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientLogsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userAgent
        severityLevel
        componentName
        message
        details
        tags
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCounter = /* GraphQL */ `
  query GetCounter($id: ID!) {
    getCounter(id: $id) {
      id
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCounters = /* GraphQL */ `
  query ListCounters(
    $filter: ModelCounterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCounters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCounters = /* GraphQL */ `
  query SyncCounters(
    $filter: ModelCounterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCounters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDesignDocument = /* GraphQL */ `
  query GetDesignDocument($id: ID!) {
    getDesignDocument(id: $id) {
      id
      designDocumentGroupId
      propertyId
      projectId
      name
      status
      lastEvaluatedValidityStatus
      uploadStatus
      documentKey
      lastWorkScopeSpecificationItemNumber
      version
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDesignDocuments = /* GraphQL */ `
  query ListDesignDocuments(
    $id: ID
    $filter: ModelDesignDocumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDesignDocuments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        designDocumentGroupId
        propertyId
        projectId
        name
        status
        lastEvaluatedValidityStatus
        uploadStatus
        documentKey
        lastWorkScopeSpecificationItemNumber
        version
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDesignDocuments = /* GraphQL */ `
  query SyncDesignDocuments(
    $filter: ModelDesignDocumentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDesignDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        designDocumentGroupId
        propertyId
        projectId
        name
        status
        lastEvaluatedValidityStatus
        uploadStatus
        documentKey
        lastWorkScopeSpecificationItemNumber
        version
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDesignDocumentsByGroupByVersion = /* GraphQL */ `
  query ListDesignDocumentsByGroupByVersion(
    $designDocumentGroupId: ID!
    $version: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDesignDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDesignDocumentsByGroupByVersion(
      designDocumentGroupId: $designDocumentGroupId
      version: $version
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        designDocumentGroupId
        propertyId
        projectId
        name
        status
        lastEvaluatedValidityStatus
        uploadStatus
        documentKey
        lastWorkScopeSpecificationItemNumber
        version
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDesignDocumentsByGroupByStatus = /* GraphQL */ `
  query ListDesignDocumentsByGroupByStatus(
    $designDocumentGroupId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDesignDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDesignDocumentsByGroupByStatus(
      designDocumentGroupId: $designDocumentGroupId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        designDocumentGroupId
        propertyId
        projectId
        name
        status
        lastEvaluatedValidityStatus
        uploadStatus
        documentKey
        lastWorkScopeSpecificationItemNumber
        version
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDesignDocumentsByProperty = /* GraphQL */ `
  query ListDesignDocumentsByProperty(
    $propertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDesignDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDesignDocumentsByProperty(
      propertyId: $propertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        designDocumentGroupId
        propertyId
        projectId
        name
        status
        lastEvaluatedValidityStatus
        uploadStatus
        documentKey
        lastWorkScopeSpecificationItemNumber
        version
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDesignDocumentsByProject = /* GraphQL */ `
  query ListDesignDocumentsByProject(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDesignDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDesignDocumentsByProject(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        designDocumentGroupId
        propertyId
        projectId
        name
        status
        lastEvaluatedValidityStatus
        uploadStatus
        documentKey
        lastWorkScopeSpecificationItemNumber
        version
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDesignDocumentGroup = /* GraphQL */ `
  query GetDesignDocumentGroup($id: ID!) {
    getDesignDocumentGroup(id: $id) {
      id
      projectId
      propertyId
      name
      latestDocumentVersion
      documentContentKey
      solutionGroupNumber
      isLocked
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDesignDocumentGroups = /* GraphQL */ `
  query ListDesignDocumentGroups(
    $id: ID
    $filter: ModelDesignDocumentGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDesignDocumentGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        projectId
        propertyId
        name
        latestDocumentVersion
        documentContentKey
        solutionGroupNumber
        isLocked
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDesignDocumentGroups = /* GraphQL */ `
  query SyncDesignDocumentGroups(
    $filter: ModelDesignDocumentGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDesignDocumentGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectId
        propertyId
        name
        latestDocumentVersion
        documentContentKey
        solutionGroupNumber
        isLocked
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDesignDocumentGroupsByProject = /* GraphQL */ `
  query ListDesignDocumentGroupsByProject(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDesignDocumentGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDesignDocumentGroupsByProject(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        propertyId
        name
        latestDocumentVersion
        documentContentKey
        solutionGroupNumber
        isLocked
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDesignDocumentGroupsByProperty = /* GraphQL */ `
  query ListDesignDocumentGroupsByProperty(
    $propertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDesignDocumentGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDesignDocumentGroupsByProperty(
      propertyId: $propertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        propertyId
        name
        latestDocumentVersion
        documentContentKey
        solutionGroupNumber
        isLocked
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDimension = /* GraphQL */ `
  query GetDimension($id: ID!) {
    getDimension(id: $id) {
      id
      parentId
      propertyId
      createdInSolution
      description
      length
      lengthUnit
      width
      widthUnit
      dimensionType
      measurementUnit
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDimensions = /* GraphQL */ `
  query ListDimensions(
    $id: ID
    $filter: ModelDimensionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDimensions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        parentId
        propertyId
        createdInSolution
        description
        length
        lengthUnit
        width
        widthUnit
        dimensionType
        measurementUnit
        measurement {
          length
          width
          dimensionType
          measurementUnit
        }
        localLastUpdatedTime
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDimensions = /* GraphQL */ `
  query SyncDimensions(
    $filter: ModelDimensionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDimensions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        parentId
        propertyId
        createdInSolution
        description
        length
        lengthUnit
        width
        widthUnit
        dimensionType
        measurementUnit
        measurement {
          length
          width
          dimensionType
          measurementUnit
        }
        localLastUpdatedTime
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDimensionsByParent = /* GraphQL */ `
  query ListDimensionsByParent(
    $parentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDimensionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimensionsByParent(
      parentId: $parentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        propertyId
        createdInSolution
        description
        length
        lengthUnit
        width
        widthUnit
        dimensionType
        measurementUnit
        measurement {
          length
          width
          dimensionType
          measurementUnit
        }
        localLastUpdatedTime
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDimensionsByProperty = /* GraphQL */ `
  query ListDimensionsByProperty(
    $propertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDimensionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimensionsByProperty(
      propertyId: $propertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        propertyId
        createdInSolution
        description
        length
        lengthUnit
        width
        widthUnit
        dimensionType
        measurementUnit
        measurement {
          length
          width
          dimensionType
          measurementUnit
        }
        localLastUpdatedTime
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDiscoverableWorkTypes = /* GraphQL */ `
  query GetDiscoverableWorkTypes($id: ID!) {
    getDiscoverableWorkTypes(id: $id) {
      id
      workTypeGroupId
      defaultVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDiscoverableWorkTypes = /* GraphQL */ `
  query ListDiscoverableWorkTypes(
    $id: ID
    $filter: ModelDiscoverableWorkTypesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDiscoverableWorkTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        workTypeGroupId
        defaultVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDiscoverableWorkTypes = /* GraphQL */ `
  query SyncDiscoverableWorkTypes(
    $filter: ModelDiscoverableWorkTypesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDiscoverableWorkTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        workTypeGroupId
        defaultVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEntityResourcePermission = /* GraphQL */ `
  query GetEntityResourcePermission($id: ID!) {
    getEntityResourcePermission(id: $id) {
      id
      propertyId
      entityId
      entityType
      resourceId
      resourceType
      permissionType
      isGranted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEntityResourcePermissions = /* GraphQL */ `
  query ListEntityResourcePermissions(
    $id: ID
    $filter: ModelEntityResourcePermissionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEntityResourcePermissions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        propertyId
        entityId
        entityType
        resourceId
        resourceType
        permissionType
        isGranted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEntityResourcePermissions = /* GraphQL */ `
  query SyncEntityResourcePermissions(
    $filter: ModelEntityResourcePermissionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEntityResourcePermissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        propertyId
        entityId
        entityType
        resourceId
        resourceType
        permissionType
        isGranted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listEntityResourcePermissionByEntityIdByResourceId = /* GraphQL */ `
  query ListEntityResourcePermissionByEntityIdByResourceId(
    $entityId: ID!
    $resourceId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityResourcePermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityResourcePermissionByEntityIdByResourceId(
      entityId: $entityId
      resourceId: $resourceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyId
        entityId
        entityType
        resourceId
        resourceType
        permissionType
        isGranted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listEntityResourcePermissionByResourceId = /* GraphQL */ `
  query ListEntityResourcePermissionByResourceId(
    $resourceId: ID!
    $resourceType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityResourcePermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityResourcePermissionByResourceId(
      resourceId: $resourceId
      resourceType: $resourceType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyId
        entityId
        entityType
        resourceId
        resourceType
        permissionType
        isGranted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEntityWorkTypeAssociation = /* GraphQL */ `
  query GetEntityWorkTypeAssociation($id: ID!) {
    getEntityWorkTypeAssociation(id: $id) {
      id
      entityId
      entityType
      workTypeGroupId
      workTypeVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEntityWorkTypeAssociations = /* GraphQL */ `
  query ListEntityWorkTypeAssociations(
    $filter: ModelEntityWorkTypeAssociationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityWorkTypeAssociations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        entityType
        workTypeGroupId
        workTypeVersion
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEntityWorkTypeAssociations = /* GraphQL */ `
  query SyncEntityWorkTypeAssociations(
    $filter: ModelEntityWorkTypeAssociationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEntityWorkTypeAssociations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        entityId
        entityType
        workTypeGroupId
        workTypeVersion
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInspection = /* GraphQL */ `
  query GetInspection($id: ID!) {
    getInspection(id: $id) {
      id
      propertyId
      createdByOrganizationId
      createdByUserId
      startTime
      endTime
      status
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInspections = /* GraphQL */ `
  query ListInspections(
    $id: ID
    $filter: ModelInspectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInspections(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        propertyId
        createdByOrganizationId
        createdByUserId
        startTime
        endTime
        status
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspections = /* GraphQL */ `
  query SyncInspections(
    $filter: ModelInspectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        propertyId
        createdByOrganizationId
        createdByUserId
        startTime
        endTime
        status
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIssue = /* GraphQL */ `
  query GetIssue($id: ID!) {
    getIssue(id: $id) {
      id
      locationId
      propertyId
      inspectionId
      createdInSolution
      name
      description
      note
      tags
      status
      clientCreationDate
      localLastUpdatedTime
      createdBy
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listIssues = /* GraphQL */ `
  query ListIssues(
    $id: ID
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIssues(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        locationId
        propertyId
        inspectionId
        createdInSolution
        name
        description
        note
        tags
        status
        clientCreationDate
        localLastUpdatedTime
        createdBy
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIssues = /* GraphQL */ `
  query SyncIssues(
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIssues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        locationId
        propertyId
        inspectionId
        createdInSolution
        name
        description
        note
        tags
        status
        clientCreationDate
        localLastUpdatedTime
        createdBy
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listIssuesByLocation = /* GraphQL */ `
  query ListIssuesByLocation(
    $locationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIssuesByLocation(
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        propertyId
        inspectionId
        createdInSolution
        name
        description
        note
        tags
        status
        clientCreationDate
        localLastUpdatedTime
        createdBy
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listIssuesByProperty = /* GraphQL */ `
  query ListIssuesByProperty(
    $propertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIssuesByProperty(
      propertyId: $propertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        propertyId
        inspectionId
        createdInSolution
        name
        description
        note
        tags
        status
        clientCreationDate
        localLastUpdatedTime
        createdBy
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listIssuesByInspection = /* GraphQL */ `
  query ListIssuesByInspection(
    $inspectionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIssuesByInspection(
      inspectionId: $inspectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        propertyId
        inspectionId
        createdInSolution
        name
        description
        note
        tags
        status
        clientCreationDate
        localLastUpdatedTime
        createdBy
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      propertyId
      createdInSolution
      name
      tags
      localCreationTime
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $id: ID
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLocations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        propertyId
        createdInSolution
        name
        tags
        localCreationTime
        localLastUpdatedTime
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLocations = /* GraphQL */ `
  query SyncLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        propertyId
        createdInSolution
        name
        tags
        localCreationTime
        localLastUpdatedTime
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listLocationsByProperty = /* GraphQL */ `
  query ListLocationsByProperty(
    $propertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocationsByProperty(
      propertyId: $propertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyId
        createdInSolution
        name
        tags
        localCreationTime
        localLastUpdatedTime
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      parentId
      propertyId
      note
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $id: ID
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        parentId
        propertyId
        note
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotes = /* GraphQL */ `
  query SyncNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        parentId
        propertyId
        note
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listNotesByParent = /* GraphQL */ `
  query ListNotesByParent(
    $parentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByParent(
      parentId: $parentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        propertyId
        note
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listNotesByProperty = /* GraphQL */ `
  query ListNotesByProperty(
    $propertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByProperty(
      propertyId: $propertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        propertyId
        note
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      legalName
      type
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $id: ID
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        legalName
        type
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrganizations = /* GraphQL */ `
  query SyncOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        legalName
        type
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      propertyId
      name
      tenderNumber
      customer
      address
      propertyType
      dueDate
      contactName
      contactNumber
      contactEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $id: ID
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProjects(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        propertyId
        name
        tenderNumber
        customer
        address
        propertyType
        dueDate
        contactName
        contactNumber
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProjects = /* GraphQL */ `
  query SyncProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        propertyId
        name
        tenderNumber
        customer
        address
        propertyType
        dueDate
        contactName
        contactNumber
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDesignProjectsByProperty = /* GraphQL */ `
  query ListDesignProjectsByProperty(
    $propertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDesignProjectsByProperty(
      propertyId: $propertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyId
        name
        tenderNumber
        customer
        address
        propertyType
        dueDate
        contactName
        contactNumber
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProjectCounter = /* GraphQL */ `
  query GetProjectCounter($id: ID!) {
    getProjectCounter(id: $id) {
      id
      propertyId
      latestProjectNumber
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProjectCounters = /* GraphQL */ `
  query ListProjectCounters(
    $id: ID
    $filter: ModelProjectCounterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProjectCounters(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        propertyId
        latestProjectNumber
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProjectCounters = /* GraphQL */ `
  query SyncProjectCounters(
    $filter: ModelProjectCounterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProjectCounters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        propertyId
        latestProjectNumber
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProjectCounterByProperty = /* GraphQL */ `
  query GetProjectCounterByProperty(
    $propertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectCounterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProjectCounterByProperty(
      propertyId: $propertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyId
        latestProjectNumber
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProperty = /* GraphQL */ `
  query GetProperty($id: ID!) {
    getProperty(id: $id) {
      id
      name
      description
      address
      ownerOrganizationId
      primaryContactNumber
      primaryContactEmail
      primaryContactName
      propertyNumber
      locations {
        items {
          id
          propertyId
          createdInSolution
          name
          tags
          localCreationTime
          localLastUpdatedTime
          opaqueSyncReconciliationToken
          canView
          canEdit
          canDelete
          createdBy
          canViewList
          canViewEntityVersionMap
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdBy
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canCreateSolutions
      canDelete
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProperties = /* GraphQL */ `
  query ListProperties(
    $id: ID
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProperties(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        address
        ownerOrganizationId
        primaryContactNumber
        primaryContactEmail
        primaryContactName
        propertyNumber
        locations {
          nextToken
          startedAt
        }
        createdBy
        localLastUpdatedTime
        opaqueSyncReconciliationToken
        canView
        canEdit
        canCreateSolutions
        canDelete
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProperties = /* GraphQL */ `
  query SyncProperties(
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        address
        ownerOrganizationId
        primaryContactNumber
        primaryContactEmail
        primaryContactName
        propertyNumber
        locations {
          nextToken
          startedAt
        }
        createdBy
        localLastUpdatedTime
        opaqueSyncReconciliationToken
        canView
        canEdit
        canCreateSolutions
        canDelete
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPropertyCounter = /* GraphQL */ `
  query GetPropertyCounter($id: ID!) {
    getPropertyCounter(id: $id) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPropertyCounters = /* GraphQL */ `
  query ListPropertyCounters(
    $filter: ModelPropertyCounterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertyCounters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPropertyCounters = /* GraphQL */ `
  query SyncPropertyCounters(
    $filter: ModelPropertyCounterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPropertyCounters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProposal = /* GraphQL */ `
  query GetProposal($id: ID!) {
    getProposal(id: $id) {
      id
      schemaVersion
      designDocumentGroupId
      designDocumentVersion
      proposalKey
      proposalNumber
      status
      author
      createdAt
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProposals = /* GraphQL */ `
  query ListProposals(
    $filter: ModelProposalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProposals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schemaVersion
        designDocumentGroupId
        designDocumentVersion
        proposalKey
        proposalNumber
        status
        author
        createdAt
        localLastUpdatedTime
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProposals = /* GraphQL */ `
  query SyncProposals(
    $filter: ModelProposalFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProposals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        schemaVersion
        designDocumentGroupId
        designDocumentVersion
        proposalKey
        proposalNumber
        status
        author
        createdAt
        localLastUpdatedTime
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProposalById = /* GraphQL */ `
  query GetProposalById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProposalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProposalById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schemaVersion
        designDocumentGroupId
        designDocumentVersion
        proposalKey
        proposalNumber
        status
        author
        createdAt
        localLastUpdatedTime
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listProposalsByDesignDocumentGroupId = /* GraphQL */ `
  query ListProposalsByDesignDocumentGroupId(
    $designDocumentGroupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProposalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProposalsByDesignDocumentGroupId(
      designDocumentGroupId: $designDocumentGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schemaVersion
        designDocumentGroupId
        designDocumentVersion
        proposalKey
        proposalNumber
        status
        author
        createdAt
        localLastUpdatedTime
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listProposalsByDesignDocumentGroupIdAndVersion = /* GraphQL */ `
  query ListProposalsByDesignDocumentGroupIdAndVersion(
    $designDocumentGroupId: ID!
    $designDocumentVersion: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProposalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProposalsByDesignDocumentGroupIdAndVersion(
      designDocumentGroupId: $designDocumentGroupId
      designDocumentVersion: $designDocumentVersion
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schemaVersion
        designDocumentGroupId
        designDocumentVersion
        proposalKey
        proposalNumber
        status
        author
        createdAt
        localLastUpdatedTime
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listProposalsByAuthor = /* GraphQL */ `
  query ListProposalsByAuthor(
    $author: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProposalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProposalsByAuthor(
      author: $author
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schemaVersion
        designDocumentGroupId
        designDocumentVersion
        proposalKey
        proposalNumber
        status
        author
        createdAt
        localLastUpdatedTime
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProposalCounter = /* GraphQL */ `
  query GetProposalCounter($id: ID!) {
    getProposalCounter(id: $id) {
      id
      solutionGroupId
      solutionVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProposalCounters = /* GraphQL */ `
  query ListProposalCounters(
    $id: ID
    $filter: ModelProposalCounterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProposalCounters(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        solutionGroupId
        solutionVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProposalCounters = /* GraphQL */ `
  query SyncProposalCounters(
    $filter: ModelProposalCounterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProposalCounters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        solutionGroupId
        solutionVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProposalCounterBySolutionByVersion = /* GraphQL */ `
  query GetProposalCounterBySolutionByVersion(
    $solutionGroupId: ID!
    $solutionVersion: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProposalCounterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProposalCounterBySolutionByVersion(
      solutionGroupId: $solutionGroupId
      solutionVersion: $solutionVersion
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        solutionGroupId
        solutionVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getResourceUserPermissions = /* GraphQL */ `
  query GetResourceUserPermissions($id: ID!) {
    getResourceUserPermissions(id: $id) {
      id
      resourceId
      userId
      userEmail
      userFullName
      resourceAndUserId
      permissions
      resourceType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listResourceUserPermissions = /* GraphQL */ `
  query ListResourceUserPermissions(
    $id: ID
    $filter: ModelResourceUserPermissionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listResourceUserPermissions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        resourceId
        userId
        userEmail
        userFullName
        resourceAndUserId
        permissions
        resourceType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncResourceUserPermissions = /* GraphQL */ `
  query SyncResourceUserPermissions(
    $filter: ModelResourceUserPermissionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncResourceUserPermissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        resourceId
        userId
        userEmail
        userFullName
        resourceAndUserId
        permissions
        resourceType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listResourceUserPermissionsByResource = /* GraphQL */ `
  query ListResourceUserPermissionsByResource(
    $resourceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResourceUserPermissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResourceUserPermissionsByResource(
      resourceId: $resourceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceId
        userId
        userEmail
        userFullName
        resourceAndUserId
        permissions
        resourceType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listResourceUserPermissionsByUserIdAndResourceType = /* GraphQL */ `
  query ListResourceUserPermissionsByUserIdAndResourceType(
    $userId: String!
    $resourceType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceUserPermissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResourceUserPermissionsByUserIdAndResourceType(
      userId: $userId
      resourceType: $resourceType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceId
        userId
        userEmail
        userFullName
        resourceAndUserId
        permissions
        resourceType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listResourceUserPermissionsByUserEmail = /* GraphQL */ `
  query ListResourceUserPermissionsByUserEmail(
    $userEmail: String!
    $sortDirection: ModelSortDirection
    $filter: ModelResourceUserPermissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResourceUserPermissionsByUserEmail(
      userEmail: $userEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceId
        userId
        userEmail
        userFullName
        resourceAndUserId
        permissions
        resourceType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listResourceUserPermissionsByResourceAndUser = /* GraphQL */ `
  query ListResourceUserPermissionsByResourceAndUser(
    $resourceAndUserId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelResourceUserPermissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResourceUserPermissionsByResourceAndUser(
      resourceAndUserId: $resourceAndUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceId
        userId
        userEmail
        userFullName
        resourceAndUserId
        permissions
        resourceType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSolution = /* GraphQL */ `
  query GetSolution($id: ID!) {
    getSolution(id: $id) {
      id
      propertyId
      projectId
      name
      status
      latestMinorVersion
      latestContentKey
      serialNumber
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
        tenderingType
      }
      localLastUpdatedTime
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSolutions = /* GraphQL */ `
  query ListSolutions(
    $id: ID
    $filter: ModelSolutionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSolutions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        propertyId
        projectId
        name
        status
        latestMinorVersion
        latestContentKey
        serialNumber
        metadata {
          name
          propertyContactName
          propertyAddress
          propertyType
          solutionDueDate
          consultantName
          consultantPhoneNumber
          consultantEmail
          tenderingType
        }
        localLastUpdatedTime
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSolutions = /* GraphQL */ `
  query SyncSolutions(
    $filter: ModelSolutionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSolutions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        propertyId
        projectId
        name
        status
        latestMinorVersion
        latestContentKey
        serialNumber
        metadata {
          name
          propertyContactName
          propertyAddress
          propertyType
          solutionDueDate
          consultantName
          consultantPhoneNumber
          consultantEmail
          tenderingType
        }
        localLastUpdatedTime
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listSolutionsByProperty = /* GraphQL */ `
  query ListSolutionsByProperty(
    $propertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSolutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSolutionsByProperty(
      propertyId: $propertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyId
        projectId
        name
        status
        latestMinorVersion
        latestContentKey
        serialNumber
        metadata {
          name
          propertyContactName
          propertyAddress
          propertyType
          solutionDueDate
          consultantName
          consultantPhoneNumber
          consultantEmail
          tenderingType
        }
        localLastUpdatedTime
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSolutionMinorVersion = /* GraphQL */ `
  query GetSolutionMinorVersion($id: ID!) {
    getSolutionMinorVersion(id: $id) {
      id
      solutionId
      propertyId
      name
      contentKey
      minorVersion
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSolutionMinorVersions = /* GraphQL */ `
  query ListSolutionMinorVersions(
    $id: ID
    $filter: ModelSolutionMinorVersionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSolutionMinorVersions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        solutionId
        propertyId
        name
        contentKey
        minorVersion
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSolutionMinorVersions = /* GraphQL */ `
  query SyncSolutionMinorVersions(
    $filter: ModelSolutionMinorVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSolutionMinorVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        solutionId
        propertyId
        name
        contentKey
        minorVersion
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listSolutionMinorVersionsBySolutionByMinorVersion = /* GraphQL */ `
  query ListSolutionMinorVersionsBySolutionByMinorVersion(
    $solutionId: ID!
    $minorVersion: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSolutionMinorVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSolutionMinorVersionsBySolutionByMinorVersion(
      solutionId: $solutionId
      minorVersion: $minorVersion
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        solutionId
        propertyId
        name
        contentKey
        minorVersion
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSolutionGroupCounter = /* GraphQL */ `
  query GetSolutionGroupCounter($id: ID!) {
    getSolutionGroupCounter(id: $id) {
      id
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSolutionGroupCounters = /* GraphQL */ `
  query ListSolutionGroupCounters(
    $id: ID
    $filter: ModelSolutionGroupCounterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSolutionGroupCounters(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSolutionGroupCounters = /* GraphQL */ `
  query SyncSolutionGroupCounters(
    $filter: ModelSolutionGroupCounterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSolutionGroupCounters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSolutionGroupCounterByProperty = /* GraphQL */ `
  query GetSolutionGroupCounterByProperty(
    $propertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSolutionGroupCounterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSolutionGroupCounterByProperty(
      propertyId: $propertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserGroup = /* GraphQL */ `
  query GetUserGroup($id: ID!) {
    getUserGroup(id: $id) {
      id
      resourceId
      groupType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserGroups = /* GraphQL */ `
  query ListUserGroups(
    $id: ID
    $filter: ModelUserGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        resourceId
        groupType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserGroups = /* GraphQL */ `
  query SyncUserGroups(
    $filter: ModelUserGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        resourceId
        groupType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listUserGroupsByResource = /* GraphQL */ `
  query ListUserGroupsByResource(
    $resourceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserGroupsByResource(
      resourceId: $resourceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceId
        groupType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserGroupMember = /* GraphQL */ `
  query GetUserGroupMember($id: ID!) {
    getUserGroupMember(id: $id) {
      id
      resourceId
      resourceAndUserId
      userId
      userGroupId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserGroupMembers = /* GraphQL */ `
  query ListUserGroupMembers(
    $id: ID
    $filter: ModelUserGroupMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserGroupMembers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        resourceId
        resourceAndUserId
        userId
        userGroupId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserGroupMembers = /* GraphQL */ `
  query SyncUserGroupMembers(
    $filter: ModelUserGroupMemberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserGroupMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        resourceId
        resourceAndUserId
        userId
        userGroupId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listUserGroupMembersByResource = /* GraphQL */ `
  query ListUserGroupMembersByResource(
    $resourceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserGroupMembersByResource(
      resourceId: $resourceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceId
        resourceAndUserId
        userId
        userGroupId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listUserGroupMembersByResourceAndUser = /* GraphQL */ `
  query ListUserGroupMembersByResourceAndUser(
    $resourceAndUserId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserGroupMembersByResourceAndUser(
      resourceAndUserId: $resourceAndUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceId
        resourceAndUserId
        userId
        userGroupId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserOrganizationAssociation = /* GraphQL */ `
  query GetUserOrganizationAssociation($id: ID!) {
    getUserOrganizationAssociation(id: $id) {
      id
      userId
      organizationId
      associationType
      permissions
      role
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserOrganizationAssociations = /* GraphQL */ `
  query ListUserOrganizationAssociations(
    $id: ID
    $filter: ModelUserOrganizationAssociationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserOrganizationAssociations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        organizationId
        associationType
        permissions
        role
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserOrganizationAssociations = /* GraphQL */ `
  query SyncUserOrganizationAssociations(
    $filter: ModelUserOrganizationAssociationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserOrganizationAssociations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        organizationId
        associationType
        permissions
        role
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listUserOrganizationAssociationsByUserAndAssociationType = /* GraphQL */ `
  query ListUserOrganizationAssociationsByUserAndAssociationType(
    $userId: ID!
    $associationType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrganizationAssociationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserOrganizationAssociationsByUserAndAssociationType(
      userId: $userId
      associationType: $associationType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        organizationId
        associationType
        permissions
        role
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listUserOrganizationAssociationsByOrganizationAndAssociationType = /* GraphQL */ `
  query ListUserOrganizationAssociationsByOrganizationAndAssociationType(
    $organizationId: ID!
    $associationType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrganizationAssociationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserOrganizationAssociationsByOrganizationAndAssociationType(
      organizationId: $organizationId
      associationType: $associationType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        organizationId
        associationType
        permissions
        role
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkSpecification = /* GraphQL */ `
  query GetWorkSpecification($id: ID!) {
    getWorkSpecification(id: $id) {
      id
      dimensionId
      issueId
      propertyId
      createdInSolution
      body
      description
      workTypeId
      itemNumber
      measurement {
        length
        width
        dimensionType
        measurementUnit
      }
      version
      localLastUpdatedTime
      opaqueSyncReconciliationToken
      canView
      canEdit
      canDelete
      createdBy
      canViewList
      canViewEntityVersionMap
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWorkSpecifications = /* GraphQL */ `
  query ListWorkSpecifications(
    $id: ID
    $filter: ModelWorkSpecificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkSpecifications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        dimensionId
        issueId
        propertyId
        createdInSolution
        body
        description
        workTypeId
        itemNumber
        measurement {
          length
          width
          dimensionType
          measurementUnit
        }
        version
        localLastUpdatedTime
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkSpecifications = /* GraphQL */ `
  query SyncWorkSpecifications(
    $filter: ModelWorkSpecificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkSpecifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        dimensionId
        issueId
        propertyId
        createdInSolution
        body
        description
        workTypeId
        itemNumber
        measurement {
          length
          width
          dimensionType
          measurementUnit
        }
        version
        localLastUpdatedTime
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listWorkScopeSpecificationsByDimensionId = /* GraphQL */ `
  query ListWorkScopeSpecificationsByDimensionId(
    $dimensionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWorkSpecificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkScopeSpecificationsByDimensionId(
      dimensionId: $dimensionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dimensionId
        issueId
        propertyId
        createdInSolution
        body
        description
        workTypeId
        itemNumber
        measurement {
          length
          width
          dimensionType
          measurementUnit
        }
        version
        localLastUpdatedTime
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listWorkScopeSpecificationsByIssueId = /* GraphQL */ `
  query ListWorkScopeSpecificationsByIssueId(
    $issueId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWorkSpecificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkScopeSpecificationsByIssueId(
      issueId: $issueId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dimensionId
        issueId
        propertyId
        createdInSolution
        body
        description
        workTypeId
        itemNumber
        measurement {
          length
          width
          dimensionType
          measurementUnit
        }
        version
        localLastUpdatedTime
        opaqueSyncReconciliationToken
        canView
        canEdit
        canDelete
        createdBy
        canViewList
        canViewEntityVersionMap
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkType = /* GraphQL */ `
  query GetWorkType($id: ID!) {
    getWorkType(id: $id) {
      id
      name
      shortDescription
      longDescription
      groupName
      unitPrice
      unitCost
      dimensionType
      measurementUnit
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWorkTypes = /* GraphQL */ `
  query ListWorkTypes(
    $id: ID
    $filter: ModelWorkTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        shortDescription
        longDescription
        groupName
        unitPrice
        unitCost
        dimensionType
        measurementUnit
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkTypes = /* GraphQL */ `
  query SyncWorkTypes(
    $filter: ModelWorkTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        shortDescription
        longDescription
        groupName
        unitPrice
        unitCost
        dimensionType
        measurementUnit
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkTypeGroup = /* GraphQL */ `
  query GetWorkTypeGroup($id: ID!) {
    getWorkTypeGroup(id: $id) {
      id
      latestWorkTypeVersion
      environmentAgnosticWorkTypeGroupId
      isDiscoverable
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWorkTypeGroups = /* GraphQL */ `
  query ListWorkTypeGroups(
    $id: ID
    $filter: ModelWorkTypeGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkTypeGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        latestWorkTypeVersion
        environmentAgnosticWorkTypeGroupId
        isDiscoverable
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkTypeGroups = /* GraphQL */ `
  query SyncWorkTypeGroups(
    $filter: ModelWorkTypeGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkTypeGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        latestWorkTypeVersion
        environmentAgnosticWorkTypeGroupId
        isDiscoverable
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkTypePricing = /* GraphQL */ `
  query GetWorkTypePricing($id: ID!) {
    getWorkTypePricing(id: $id) {
      id
      entityId
      workTypeGroupId
      body
      canView
      canEdit
      canDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWorkTypePricings = /* GraphQL */ `
  query ListWorkTypePricings(
    $id: ID
    $filter: ModelWorkTypePricingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkTypePricings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        entityId
        workTypeGroupId
        body
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkTypePricings = /* GraphQL */ `
  query SyncWorkTypePricings(
    $filter: ModelWorkTypePricingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkTypePricings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        entityId
        workTypeGroupId
        body
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listWorkTypePricingByEntityIdByWorkTypeGroupId = /* GraphQL */ `
  query ListWorkTypePricingByEntityIdByWorkTypeGroupId(
    $entityId: ID!
    $workTypeGroupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkTypePricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkTypePricingByEntityIdByWorkTypeGroupId(
      entityId: $entityId
      workTypeGroupId: $workTypeGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        workTypeGroupId
        body
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDefaultWorkTypePricing = /* GraphQL */ `
  query GetDefaultWorkTypePricing($id: ID!) {
    getDefaultWorkTypePricing(id: $id) {
      id
      unitCost
      unitPrice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDefaultWorkTypePricings = /* GraphQL */ `
  query ListDefaultWorkTypePricings(
    $id: ID
    $filter: ModelDefaultWorkTypePricingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDefaultWorkTypePricings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        unitCost
        unitPrice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDefaultWorkTypePricings = /* GraphQL */ `
  query SyncDefaultWorkTypePricings(
    $filter: ModelDefaultWorkTypePricingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDefaultWorkTypePricings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        unitCost
        unitPrice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTemporaryWorkType = /* GraphQL */ `
  query GetTemporaryWorkType($id: ID!) {
    getTemporaryWorkType(id: $id) {
      id
      workTypeGroupId
      environmentAgnosticWorkTypeGroupId
      body
      version
      schemaVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTemporaryWorkTypes = /* GraphQL */ `
  query ListTemporaryWorkTypes(
    $filter: ModelTemporaryWorkTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemporaryWorkTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workTypeGroupId
        environmentAgnosticWorkTypeGroupId
        body
        version
        schemaVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTemporaryWorkTypes = /* GraphQL */ `
  query SyncTemporaryWorkTypes(
    $filter: ModelTemporaryWorkTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTemporaryWorkTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        workTypeGroupId
        environmentAgnosticWorkTypeGroupId
        body
        version
        schemaVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkTypeByWorkTypeGroupIdAndVersion = /* GraphQL */ `
  query GetWorkTypeByWorkTypeGroupIdAndVersion(
    $workTypeGroupId: ID!
    $version: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTemporaryWorkTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWorkTypeByWorkTypeGroupIdAndVersion(
      workTypeGroupId: $workTypeGroupId
      version: $version
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workTypeGroupId
        environmentAgnosticWorkTypeGroupId
        body
        version
        schemaVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkTypeByEnvironmentAgnosticWorkTypeGroupId = /* GraphQL */ `
  query GetWorkTypeByEnvironmentAgnosticWorkTypeGroupId(
    $environmentAgnosticWorkTypeGroupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTemporaryWorkTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWorkTypeByEnvironmentAgnosticWorkTypeGroupId(
      environmentAgnosticWorkTypeGroupId: $environmentAgnosticWorkTypeGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workTypeGroupId
        environmentAgnosticWorkTypeGroupId
        body
        version
        schemaVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTender = /* GraphQL */ `
  query GetTender($id: ID!) {
    getTender(id: $id) {
      id
      solutionId
      status
      latestVersion
      awardedVersion
      projectNumber
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTenders = /* GraphQL */ `
  query ListTenders(
    $filter: ModelTenderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        solutionId
        status
        latestVersion
        awardedVersion
        projectNumber
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTenders = /* GraphQL */ `
  query SyncTenders(
    $filter: ModelTenderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTenders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        solutionId
        status
        latestVersion
        awardedVersion
        projectNumber
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listTendersByProjectNumber = /* GraphQL */ `
  query ListTendersByProjectNumber(
    $projectNumber: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTenderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTendersByProjectNumber(
      projectNumber: $projectNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        solutionId
        status
        latestVersion
        awardedVersion
        projectNumber
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTenderMinorVersion = /* GraphQL */ `
  query GetTenderMinorVersion($id: ID!) {
    getTenderMinorVersion(id: $id) {
      id
      tenderId
      version
      solutionId
      solutionMinorVersion
      metadata {
        name
        propertyContactName
        propertyAddress
        propertyType
        solutionDueDate
        consultantName
        consultantPhoneNumber
        consultantEmail
      }
      contentKey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTenderMinorVersions = /* GraphQL */ `
  query ListTenderMinorVersions(
    $filter: ModelTenderMinorVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenderMinorVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenderId
        version
        solutionId
        solutionMinorVersion
        metadata {
          name
          propertyContactName
          propertyAddress
          propertyType
          solutionDueDate
          consultantName
          consultantPhoneNumber
          consultantEmail
        }
        contentKey
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTenderMinorVersions = /* GraphQL */ `
  query SyncTenderMinorVersions(
    $filter: ModelTenderMinorVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTenderMinorVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenderId
        version
        solutionId
        solutionMinorVersion
        metadata {
          name
          propertyContactName
          propertyAddress
          propertyType
          solutionDueDate
          consultantName
          consultantPhoneNumber
          consultantEmail
        }
        contentKey
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listTenderMinorVersionsByTenderId = /* GraphQL */ `
  query ListTenderMinorVersionsByTenderId(
    $tenderId: String!
    $version: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTenderMinorVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenderMinorVersionsByTenderId(
      tenderId: $tenderId
      version: $version
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenderId
        version
        solutionId
        solutionMinorVersion
        metadata {
          name
          propertyContactName
          propertyAddress
          propertyType
          solutionDueDate
          consultantName
          consultantPhoneNumber
          consultantEmail
        }
        contentKey
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserOnboardingProgress = /* GraphQL */ `
  query GetUserOnboardingProgress($id: ID!) {
    getUserOnboardingProgress(id: $id) {
      id
      userId
      hasUserBeenAssociatedWithOrganization
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserOnboardingProgresses = /* GraphQL */ `
  query ListUserOnboardingProgresses(
    $id: ID
    $filter: ModelUserOnboardingProgressFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserOnboardingProgresses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        hasUserBeenAssociatedWithOrganization
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserOnboardingProgresses = /* GraphQL */ `
  query SyncUserOnboardingProgresses(
    $filter: ModelUserOnboardingProgressFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserOnboardingProgresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        hasUserBeenAssociatedWithOrganization
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserOnboardingProgressByUserId = /* GraphQL */ `
  query GetUserOnboardingProgressByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserOnboardingProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserOnboardingProgressByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        hasUserBeenAssociatedWithOrganization
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      phoneNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        email
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      id
      type
      name
      s3Key
      ownerId
      ownerType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $id: ID
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTemplates(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        name
        s3Key
        ownerId
        ownerType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTemplates = /* GraphQL */ `
  query SyncTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        name
        s3Key
        ownerId
        ownerType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listTemplatesByOwnerEntityId = /* GraphQL */ `
  query ListTemplatesByOwnerEntityId(
    $ownerId: String!
    $ownerType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplatesByOwnerEntityId(
      ownerId: $ownerId
      ownerType: $ownerType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        s3Key
        ownerId
        ownerType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTemporaryAuthorizationCode = /* GraphQL */ `
  query GetTemporaryAuthorizationCode($id: ID!) {
    getTemporaryAuthorizationCode(id: $id) {
      id
      code
      userId
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTemporaryAuthorizationCodes = /* GraphQL */ `
  query ListTemporaryAuthorizationCodes(
    $id: ID
    $filter: ModelTemporaryAuthorizationCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTemporaryAuthorizationCodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        code
        userId
        expiresAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTemporaryAuthorizationCodes = /* GraphQL */ `
  query SyncTemporaryAuthorizationCodes(
    $filter: ModelTemporaryAuthorizationCodeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTemporaryAuthorizationCodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        code
        userId
        expiresAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listTemporaryAuthorizationCodesByUserId = /* GraphQL */ `
  query ListTemporaryAuthorizationCodesByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTemporaryAuthorizationCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemporaryAuthorizationCodesByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        userId
        expiresAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLinkRegistry = /* GraphQL */ `
  query GetLinkRegistry($id: ID!) {
    getLinkRegistry(id: $id) {
      id
      action
      variables
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLinkRegistries = /* GraphQL */ `
  query ListLinkRegistries(
    $id: ID
    $filter: ModelLinkRegistryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLinkRegistries(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        action
        variables
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLinkRegistries = /* GraphQL */ `
  query SyncLinkRegistries(
    $filter: ModelLinkRegistryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLinkRegistries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        action
        variables
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getExternalReportBranding = /* GraphQL */ `
  query GetExternalReportBranding($id: ID!) {
    getExternalReportBranding(id: $id) {
      id
      name
      logoKey
      createdBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listExternalReportBrandings = /* GraphQL */ `
  query ListExternalReportBrandings(
    $id: ID
    $filter: ModelExternalReportBrandingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExternalReportBrandings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        logoKey
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncExternalReportBrandings = /* GraphQL */ `
  query SyncExternalReportBrandings(
    $filter: ModelExternalReportBrandingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExternalReportBrandings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        logoKey
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listExternalReportBrandingsByCreatedBy = /* GraphQL */ `
  query ListExternalReportBrandingsByCreatedBy(
    $createdBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelExternalReportBrandingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExternalReportBrandingsByCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        logoKey
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMeeting = /* GraphQL */ `
  query GetMeeting($id: ID!) {
    getMeeting(id: $id) {
      id
      status
      data
      createdBy
      mediaPipelineId
      recordingKey
      recordingAttachmentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMeetings = /* GraphQL */ `
  query ListMeetings(
    $id: ID
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMeetings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        data
        createdBy
        mediaPipelineId
        recordingKey
        recordingAttachmentId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMeetings = /* GraphQL */ `
  query SyncMeetings(
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeetings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        status
        data
        createdBy
        mediaPipelineId
        recordingKey
        recordingAttachmentId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listMeetingsByMediaPipelineId = /* GraphQL */ `
  query ListMeetingsByMediaPipelineId(
    $mediaPipelineId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingsByMediaPipelineId(
      mediaPipelineId: $mediaPipelineId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        data
        createdBy
        mediaPipelineId
        recordingKey
        recordingAttachmentId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAttendee = /* GraphQL */ `
  query GetAttendee($id: ID!) {
    getAttendee(id: $id) {
      id
      meetingId
      entityId
      name
      role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAttendees = /* GraphQL */ `
  query ListAttendees(
    $id: ID
    $filter: ModelAttendeeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAttendees(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        meetingId
        entityId
        name
        role
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttendees = /* GraphQL */ `
  query SyncAttendees(
    $filter: ModelAttendeeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttendees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        meetingId
        entityId
        name
        role
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listAttendeesByMeetingId = /* GraphQL */ `
  query ListAttendeesByMeetingId(
    $meetingId: ID!
    $entityId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttendeesByMeetingId(
      meetingId: $meetingId
      entityId: $entityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meetingId
        entityId
        name
        role
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPreSignedUploadURL = /* GraphQL */ `
  query GetPreSignedUploadURL($input: GetPreSignedUploadURLRequest!) {
    getPreSignedUploadURL(input: $input)
  }
`;
export const getPreSignedDownloadURL = /* GraphQL */ `
  query GetPreSignedDownloadURL($input: GetPreSignedDownloadRequest!) {
    getPreSignedDownloadURL(input: $input)
  }
`;
export const getAccountClaimStatus = /* GraphQL */ `
  query GetAccountClaimStatus {
    getAccountClaimStatus {
      status
    }
  }
`;
export const customSearchUsers = /* GraphQL */ `
  query CustomSearchUsers($input: SearchUsersVariables!) {
    customSearchUsers(input: $input) {
      items {
        id
        name
        email
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;
export const customSearchOrganizations = /* GraphQL */ `
  query CustomSearchOrganizations($input: SearchOrganizationsVariables!) {
    customSearchOrganizations(input: $input) {
      items {
        id
        legalName
        type
        canView
        canEdit
        canDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;
