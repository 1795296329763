import {
    Box,
    LinearProgress,
    ListItem
} from "@mui/material";
import {
    latestWorkTypeByGroupIdAtomFamily,
    workTypeDisplayModeAtomFamily
} from "../../../lib/worktype/state/WorkTypeState";
import {
    useRecoilValue,
    useResetRecoilState
} from "recoil";

import AssociateWorkTypeManagementForm from "./AssociateWorkTypeManagementForm";
import EditWorkTypeManagementForm from "./EditWorkTypeManagementForm";
import ViewWorkTypeManagementListItem from "./ViewWorkTypeManagementListItem";
import WorkTypeDTO from "../../../lib/worktype/DTO/WorkTypeDTO";
import { WorkTypeDisplayMode } from "../../../lib/worktype/state/WorkTypeDisplayMode";
import { useEffect } from "react";

interface WorkTypeAdminManagementListItemProps {
    readonly workTypeGroupId: string;
}

const WorkTypeAdminManagementListItem = (props: WorkTypeAdminManagementListItemProps) => {
    const { workTypeGroupId } = props;
    /* Internal page states */
    const workType = useRecoilValue<WorkTypeDTO | null>(latestWorkTypeByGroupIdAtomFamily(workTypeGroupId));
    const displayMode = useRecoilValue<WorkTypeDisplayMode>(workTypeDisplayModeAtomFamily(workTypeGroupId));
    const resetWorkTypeDisplayMode = useResetRecoilState(workTypeDisplayModeAtomFamily(workTypeGroupId));

    useEffect(() => () => {
        resetWorkTypeDisplayMode();
    }, []);

    return (
        !workType ? (
            <LinearProgress />
        ) : (
            <ListItem disablePadding sx={{ minHeight: "5em" }}>
                <Box px={3} py={2} width="100%">
                    <Box display={displayMode !== WorkTypeDisplayMode.VIEW ? "none" : undefined}>
                        <ViewWorkTypeManagementListItem workType={workType} />
                    </Box>
                    <Box display={displayMode !== WorkTypeDisplayMode.EDIT ? "none" : undefined}>
                        <EditWorkTypeManagementForm workType={workType} />
                    </Box>
                    <Box display={displayMode !== WorkTypeDisplayMode.ASSOCIATION ? "none" : undefined}>
                        <AssociateWorkTypeManagementForm workType={workType} />
                    </Box>
                </Box>
            </ListItem>
        )
    );
};

export default WorkTypeAdminManagementListItem;
