import {
    atom,
    atomFamily
} from "recoil";

import { TranscriptContextWindow } from "../../../../lib/meeting/transcript/TranscriptContextWindow";
import { TranscriptContextWindowBehaviour } from "../../../../lib/meeting/transcript/TranscriptContextWindowBehaviour";

export const isAiAssistedNamingEnabledAtom = atom<boolean>({
    key: "isAiAssistedNamingEnabledAtom",
    default: true
});

export const isAiResourceGeneratingAtomFamily = atomFamily<boolean, string>({
    key: "isAiResourceGeneratingAtomFamily",
    default: false
});

export const transcriptContextWindowAtom = atom<TranscriptContextWindow>({
    key: "transcriptContextWindowAtom",
    default: {
        windowSize: 5,
        startIndex: 0,
        endIndex: 0,
        behaviour: TranscriptContextWindowBehaviour.TAILING
    }
});
