import {
    PermissionEntityType,
    PermissionResourceType,
    PermissionType
} from "../../../models";

import { ManageResourcePermissionInput } from "../../../API";
import { ManageResourcePermissionRequestBuilder } from "./ManageResourcePermissionRequestBuilder";

export class RevokeResourcePermissionRequestBuilder implements ManageResourcePermissionRequestBuilder {
    public async create(
        entityId: string,
        entityType: PermissionEntityType,
        resourceType: PermissionResourceType,
        resourceId: string,
        parentId: string,
        permissions: Array<PermissionType>
    ): Promise<Array<ManageResourcePermissionInput>> {
        return permissions.map((permission) => ({
            entityId: entityId,
            entityType: entityType,
            resourceId: resourceId,
            resourceType: resourceType,
            propertyId: parentId,
            permissionType: permission
        }));
    }
}