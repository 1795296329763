import CounterAdoptingSerialNumberGeneratorFactory from "../../../../serialNumber/CounterAdoptingSerialNumberGeneratorFactory";
import GraphQLSolutionDAOFactory from "../../../majorVersion/dao/GraphQLSolutionDAOFactory";
import GraphQLSolutionMinorVersionRecordDAOFactory from "../../../minorVersion/dao/record/GraphQLSolutionMinorVersionRecordDAOFactory";
import S3SolutionMinorVersionContentDAOFactory from "../../../minorVersion/dao/content/v2/S3SolutionMinorVersionContentDAOFactory";
import SaveSolutionHandler from "./SaveSolutionHandler";
import SaveSolutionHandlerImpl from "./SaveSolutionHandlerImpl";
import { SolutionAttachmentPopulatorFactory } from "../attachment/SolutionAttachmentPopulatorFactory";
import UuidGeneratorFactory from "../../../../util/UuidGeneratorFactory";

export default class SaveSolutionHandlerFactory {
    private static readonly INSTANCE: SaveSolutionHandler = new SaveSolutionHandlerImpl(
        GraphQLSolutionDAOFactory.getInstance(),
        CounterAdoptingSerialNumberGeneratorFactory.getInstance(),
        GraphQLSolutionMinorVersionRecordDAOFactory.getInstance(),
        S3SolutionMinorVersionContentDAOFactory.getInstance(),
        SolutionAttachmentPopulatorFactory.getInstance(),
        UuidGeneratorFactory.getInstance()
    );

    public static getInstance(): SaveSolutionHandler {
        return SaveSolutionHandlerFactory.INSTANCE;
    }
}
