import {
    dataStorePropertySyncStatusAtom,
    isDataStoreStoppedAtom
} from "../../state/DataStoreState";
import {
    offlinePropertyIdListAtom,
    recentlyCreatedPropertyIdListAtom
} from "../../../property/state/PropertyRecoilState";
import {
    useRecoilCallback,
    useSetRecoilState
} from "recoil";

import { DataStoreStatus } from "../../DataStoreStatus";
import { DataStoreSynchronizationOrchestrator } from "../DataStoreSynchronizationOrchestrator";
import { HierarchicalDataStoreSynchronizationOrchestratorFactory } from "../HierarchicalDataStoreSynchronizationOrchestratorFactory";
import { Mutex } from "async-mutex";
import { OfflinePropertyListManagerFactory } from "../offline/OfflinePropertyListManagerFactory";
import { PropertyListManager } from "../offline/PropertyListManager";
import { RecentlyCreatedPropertyListManagerFactory } from "../offline/RecentlyCreatedPropertyListManagerFactory";
import { useRef } from "react";

const mutex = new Mutex();
const recentlyCreatedPropertyListManager: PropertyListManager = RecentlyCreatedPropertyListManagerFactory.getInstance();
const offlinePropertyListManager: PropertyListManager = OfflinePropertyListManagerFactory.getInstance();

export const useDataStoreSynchronizer = () => {
    const setIsDataStoreStopped = useSetRecoilState<boolean>(isDataStoreStoppedAtom);
    const syncOrchestratorRef = useRef<DataStoreSynchronizationOrchestrator>(
        HierarchicalDataStoreSynchronizationOrchestratorFactory.getInstance(setIsDataStoreStopped)
    );

    const initialize = useRecoilCallback(({ set }) => async () => {
        await mutex.runExclusive(async () => {
            await syncOrchestratorRef.current.initialize();
            const recentProperties = await recentlyCreatedPropertyListManager.getList();
            const offlineProperties = await offlinePropertyListManager.getList();
            set(recentlyCreatedPropertyIdListAtom, recentProperties);
            set(offlinePropertyIdListAtom, offlineProperties);
        });
    }, []);

    const syncPropertiesChildren = useRecoilCallback(({ set }) => async () => {
        let success: boolean = false;
        await mutex.runExclusive(async () => {
            set(dataStorePropertySyncStatusAtom, DataStoreStatus.SYNC_IN_PROGRESS);
            success = await syncOrchestratorRef.current.syncPropertiesChildren();
            const recentProperties = await recentlyCreatedPropertyListManager.getList();
            const offlineProperties = await offlinePropertyListManager.getList();
            set(recentlyCreatedPropertyIdListAtom, recentProperties);
            set(offlinePropertyIdListAtom, offlineProperties);
            set(dataStorePropertySyncStatusAtom, DataStoreStatus.SYNC_COMPLETED);
        });
        return success;
    }, []);

    const addPropertyIdAndSync = useRecoilCallback(({ snapshot, set }) => async (propertyId: string) => {
        let success: boolean = false;
        const release = snapshot.retain();
        try {
            await mutex.runExclusive(async () => {
                const dataStorePropertySyncStatus = await snapshot.getPromise(dataStorePropertySyncStatusAtom);
                const fullSync = dataStorePropertySyncStatus === DataStoreStatus.NOT_READY;
                set(dataStorePropertySyncStatusAtom, DataStoreStatus.SYNC_IN_PROGRESS);
                success = await syncOrchestratorRef.current.addPropertyIdsAndSync([propertyId], fullSync);
                const recentProperties = await recentlyCreatedPropertyListManager.getList();
                const offlineProperties = await offlinePropertyListManager.getList();
                set(recentlyCreatedPropertyIdListAtom, recentProperties);
                set(offlinePropertyIdListAtom, offlineProperties);
                set(dataStorePropertySyncStatusAtom, DataStoreStatus.SYNC_COMPLETED);
            });
        }
        finally {
            release();
        }
        return success;
    }, []);

    return { initialize, syncPropertiesChildren, addPropertyIdAndSync };
};
