import {
    FormControlLabel,
    Switch,
    Tooltip,
    Typography
} from "@mui/material";

import { ChildrenExpandState } from "../../lib/design/document/state/enum/ChildrenExpandState";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { areAllLocationsExpandedSelectorFamily } from "../../lib/design/document/state/ComponentExpandState";
import { useRecoilValue } from "recoil";
import { useSetChildrenExpandState } from "../../lib/design/hooks/useSetChildrenExpandState";

type ExpandAllSwitchProps = {
    context: StateContext;
};

export const ExpandAllSwitch = ({ context }: ExpandAllSwitchProps) => {
    const areAllLocationsExpanded = useRecoilValue<boolean>(areAllLocationsExpandedSelectorFamily(context));
    const { setChildrenExpandStateForAllLocations } = useSetChildrenExpandState(context);

    return (
        <FormControlLabel
            sx={{
                justifyContent: "space-between",
                ml: 0
            }}
            control={(
                <Switch
                    checked={areAllLocationsExpanded}
                    onChange={(event, checked) => {
                        setChildrenExpandStateForAllLocations(checked ? ChildrenExpandState.EXPAND_CHILDREN : ChildrenExpandState.COLLAPSE_CHILDREN);
                    }}
                />
            )}
            label={(
                <Tooltip title={"Expand all locations and issues."} placement="right-start">
                    <Typography>
                        Expand All
                    </Typography>
                </Tooltip>
            )}
            labelPlacement="start"
        />
    );
};