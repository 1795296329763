import ClientLogger from "../logging/ClientLogger";
import ImageResizeMetricNames from "./ImageResizeMetricNames";

/**
 * Adapted from https://github.com/onurzorluer/react-image-file-resizer/blob/master/src/index.js with fix for memory leak described in:
 * https://stackoverflow.com/questions/72663368/filereader-page-memory-leak
 */
export default class ReactImageFileResizer {
    private readonly logger: ClientLogger;

    constructor(
        logger: ClientLogger
    ) {
        this.logger = logger;
    }

    public changeHeightWidth(
        height: number,
        maxHeight: number,
        width: number,
        maxWidth: number,
        minWidth: number | undefined,
        minHeight: number | undefined
    ): { height: number, width: number; } {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
        }
        if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
        }
        if (minWidth && width < minWidth) {
            height = Math.round((height * minWidth) / width);
            width = minWidth;
        }
        if (minHeight && height < minHeight) {
            width = Math.round((width * minHeight) / height);
            height = minHeight;
        }
        return { height, width };
    }

    private resizeAndRotateImage(
        image: HTMLImageElement,
        maxWidth: number,
        maxHeight: number,
        minWidth: number | undefined,
        minHeight: number | undefined,
        compressFormat: string = "jpeg",
        quality: number = 100,
        rotation: number = 0
    ): string {
        this.logger.info(
            `Resizing And Rotating image ${image.src}`,
            undefined,
            [ImageResizeMetricNames.REACT_IMAGE_RESIZE_AND_ROTATE_ATTEMPT]
        );
        const qualityDecimal: number = quality / 100;
        const canvas: HTMLCanvasElement = document.createElement("canvas");

        var width: number = image.width;
        var height: number = image.height;

        const newHeightWidth: { height: number, width: number } = this.changeHeightWidth(
            height,
            maxHeight,
            width,
            maxWidth,
            minWidth,
            minHeight
        );
        if (rotation && (rotation === 90 || rotation === 270)) {
            canvas.width = newHeightWidth.height;
            canvas.height = newHeightWidth.width;
        } else {
            canvas.width = newHeightWidth.width;
            canvas.height = newHeightWidth.height;
        }

        width = newHeightWidth.width;
        height = newHeightWidth.height;

        this.logger.info(
            `Creating CanvasRenderingContext2D for image: ${image.src}`,
            undefined,
            [ImageResizeMetricNames.REACT_IMAGE_RESIZE_AND_ROTATE_IN_PROCESS]
        );
        const ctx: CanvasRenderingContext2D = canvas.getContext("2d") as CanvasRenderingContext2D;
        ctx.fillStyle = "rgba(0, 0, 0, 0)";
        ctx.fillRect(0, 0, width, height);

        if (ctx.imageSmoothingEnabled && ctx.imageSmoothingQuality) {
            ctx.imageSmoothingQuality = 'high';
        }

        if (rotation) {
            ctx.rotate((rotation * Math.PI) / 180);
            if (rotation === 90) {
                ctx.translate(0, -canvas.width);
            } else if (rotation === 180) {
                ctx.translate(-canvas.width, -canvas.height);
            } else if (rotation === 270) {
                ctx.translate(-canvas.height, 0);
            } else if (rotation === 0 || rotation === 360) {
                ctx.translate(0, 0);
            }
        }
        this.logger.info(
            `Drawing CanvasRenderingContext2D for image ${image.src}`,
            undefined,
            [ImageResizeMetricNames.REACT_IMAGE_RESIZE_AND_ROTATE_IN_PROCESS]
        );
        ctx.drawImage(image, 0, 0, width, height);
        return canvas.toDataURL(`image/${compressFormat}`, qualityDecimal);
    }

    public b64toByteArrays(
        b64Data: string,
        contentType: string
    ): Array<Uint8Array> {
        contentType = contentType || "image/jpeg";
        const sliceSize = 512;

        const byteCharacters = atob(
            b64Data.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, "")
        );
        const byteArrays: Array<Uint8Array> = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice: string = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers: Array<number> = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray: Uint8Array = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        return byteArrays;
    }

    public b64toBlob(
        b64Data: string,
        contentType: string
    ): Blob {
        const byteArrays: Array<Uint8Array> = this.b64toByteArrays(b64Data, contentType);
        const blob: Blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    public b64toFile(
        b64Data: string,
        fileName: string,
        contentType: string
    ): File {
        const byteArrays: Array<Uint8Array> = this.b64toByteArrays(b64Data, contentType);
        const file: File = new File(byteArrays, fileName, { type: contentType });
        return file;
    }

    public createResizedImage(
        file: File,
        maxWidth: number,
        maxHeight: number,
        compressFormat: string,
        quality: number,
        rotation: number,
        responseUriFunc: (
            value?: string | Blob | File | ProgressEvent<FileReader>,
            error?: any
        ) => void,
        outputType?: string,
        minWidth?: number,
        minHeight?: number
    ): void {
        if (file) {
            this.logger.info(
                `Resizing file: ${file.name}, size: ${file.size}`,
                undefined,
                [ImageResizeMetricNames.REACT_IMAGE_RESIZE_ATTEMPT]
            );
            if (file.type && !file.type.includes("image")) {
                throw Error("File Is NOT Image!");
            } else {
                const fileReader: FileReader = new FileReader();
                const errorHandler = (error: ProgressEvent<FileReader>) => {
                    responseUriFunc(undefined, error.target?.error);
                }
                const resolveHandler = async () => {
                    fileReader.removeEventListener("error", errorHandler);
                    fileReader.removeEventListener("loadend", resolveHandler);
                    const image = new Image();
                    const imageOnLoad = () => {
                        image.removeEventListener("load", imageOnLoad);
                        const resizedDataUrl: string = this.resizeAndRotateImage(
                            image,
                            maxWidth,
                            maxHeight,
                            minWidth,
                            minHeight,
                            compressFormat,
                            quality,
                            rotation
                        );
                        const contentType = `image/${compressFormat}`;
                        this.logger.info(
                            `Resized file ${file.name}, originalSize: ${file.size}`,
                            undefined,
                            [ImageResizeMetricNames.REACT_IMAGE_RESIZE_IN_PROCESS]
                        );
                        switch (outputType) {
                            case "base64":
                                responseUriFunc(resizedDataUrl);
                                break;
                            case "blob":
                                const blob: Blob = this.b64toBlob(resizedDataUrl, contentType);
                                responseUriFunc(blob);
                                break;
                            case "file":
                                const fileName: string = file.name;
                                const fileNameWithoutFormat: string = fileName.toString().replace(/(png|jpeg|jpg|webp)$/i, "");
                                const newFileName: string = fileNameWithoutFormat.concat(compressFormat.toString());
                                const newFile: File = this.b64toFile(resizedDataUrl, newFileName, contentType);
                                responseUriFunc(newFile);
                                break;
                            default:
                                responseUriFunc(resizedDataUrl);
                        }
                    };
                    image.addEventListener("load", imageOnLoad);
                    image.src = fileReader.result as string;
                    this.logger.info(
                        `Reading file ${file.name}, size: ${file.size}`,
                        undefined,
                        [ImageResizeMetricNames.REACT_IMAGE_RESIZE_IN_PROCESS]
                    );
                };
                fileReader.addEventListener("loadend", resolveHandler);
                fileReader.addEventListener("error", errorHandler);
                fileReader.readAsDataURL(file);
            }
        } else {
            throw Error("File Not Found!");
        }
    }
}
