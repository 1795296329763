import {
    Box,
    Button,
    LinearProgress,
    Typography
} from "@mui/material";
import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import {
    isSystemReadySelector,
    systemReadyProgressBarHelperTextAtom,
    systemReadyProgressBarTextSelector
} from "../../lib/system/state/SystemState";

import { Route } from "react-router-dom";
import { SystemReadyState } from "../../lib/system/state/SystemReadyState";
import { TENERA_SUPPORT_EMAIL } from "../../components/general/ErrorFallback";
import { usePageDefaultStyle } from "../../assets/style/usePageDefaultStyle";
import { useRecoilValue } from "recoil";

type DataStoreAwaitingRouteProps = {
    component: React.ComponentType<any>;
    path: string;
    exact: boolean;
};

const SystemReadyRoute: React.FunctionComponent<DataStoreAwaitingRouteProps> = ({ component: Component, ...rest }) => {
    const isSystemReady = useRecoilValue<boolean>(isSystemReadySelector);
    const systemReadyProgressBarText = useRecoilValue<SystemReadyState>(systemReadyProgressBarTextSelector);
    const systemReadyProgressBarHelperText = useRecoilValue<Array<string>>(systemReadyProgressBarHelperTextAtom);
    const [showHelperText, setShowHelperText] = useState<boolean>(false);
    const { pageDefaultStyle } = usePageDefaultStyle();

    useEffect(() => {
        setTimeout(() => {
            setShowHelperText(true);
        }, 30000);
    }, []);

    /* Components */
    const loadingBarWithText = useMemo(() => {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap={1}
                sx={pageDefaultStyle}
            >
                <Typography variant="body2">
                    {systemReadyProgressBarText}
                </Typography>
                <Box width="100%">
                    <LinearProgress />
                </Box>
                {showHelperText && systemReadyProgressBarHelperText && (
                    <>
                        <Typography variant="body2">
                            It is taking longer than usual. Please help us improve by sending us a report.
                        </Typography>
                        <Button
                            color="primary"
                            variant="contained"
                            href={`mailto:${TENERA_SUPPORT_EMAIL}?subject=${new Date().toISOString()} Report&body=${systemReadyProgressBarHelperText.join('%0D%0A')}`}
                        >
                            Send Report
                        </Button>
                        <Box display="flex" flexDirection="column" alignItems="flex-start" gap={0}>
                            {systemReadyProgressBarHelperText.map((helperText, index) => (
                                <React.Fragment key={helperText}>
                                    <Typography variant="caption">
                                        {helperText}
                                    </Typography>
                                </React.Fragment>
                            ))}
                        </Box>
                    </>
                )}
            </Box>
        );
    }, [systemReadyProgressBarText, systemReadyProgressBarHelperText, showHelperText]);

    return (
        <Route {...rest} render={props => (
            !isSystemReady ?
                loadingBarWithText :
                <Component {...props} />
        )} />
    );
};

export default SystemReadyRoute;
