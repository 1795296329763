import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.806 21.325h-18l-.5-.5v-3h1v2.5h17v-2.5h1v3z"
      className="primary"
    />
    <path
      fill="currentColor"
      d="m15.206 11.025-2.4 1.8v-10h-2v10l-2.4-1.8-1.2 1.6 4 3h1.2l3.999-3z"
      className="accent"
    />
  </svg>
);
export default withCustomIcon(DownloadIcon);
