import {
    Box,
    Divider,
    List,
    Typography
} from "@mui/material";

import { DEFAULT_TEMPLATE } from "../../lib/template/TemplateConstants";
import { Fragment } from "react";
import { Template } from "../../models";
import { TemplateListItem } from "./TemplateListItem";

type TemplateListProps = {
    entityNameToTemplatesMap: Map<string, Array<Template>>;
    refetchTemplates: () => Promise<void>;
};

export const TemplateList = (props: TemplateListProps) => {
    const { entityNameToTemplatesMap, refetchTemplates } = props;

    return (
        <List
            sx={{
                backgroundColor: "background.paper",
                borderRadius: 2
            }}
        >
            <Box display="flex" flexDirection="column" gap={1} p={2}>
                <Typography variant="h6">
                    Tenera
                </Typography>
                <TemplateListItem
                    template={DEFAULT_TEMPLATE}
                    refetchTemplates={refetchTemplates}
                    isViewOnly
                />
            </Box>
            {entityNameToTemplatesMap.size > 0 && <Divider />}
            {Array.from(entityNameToTemplatesMap.entries()).map(([entityName, templates], mapIndex) => (
                <Fragment key={entityName}>
                    <Box display="flex" flexDirection="column" gap={1} p={2}>
                        <Typography variant="h6">
                            {entityName}
                        </Typography>
                        {templates.map((template, index) => (
                            <Fragment key={template.id}>
                                <TemplateListItem
                                    template={template}
                                    refetchTemplates={refetchTemplates}
                                />
                                {index !== templates.length - 1 && <Divider variant="middle" />}
                            </Fragment>
                        ))}
                    </Box>
                    {mapIndex !== entityNameToTemplatesMap.size - 1 && <Divider />}
                </Fragment>
            ))}
        </List>
    );
};