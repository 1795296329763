import ClientLoggerFactory from "../logging/ClientLoggerFactory";
import DataStoreOutboxStatusEventHandler from "./DataStoreOutboxStatusEventHandler";
import DataStoreReadyEventHandler from "./DataStoreReadyEventHandler";
import { DataStoreStatus } from "./DataStoreStatus";
import DataStoreSyncQueriesStartedEventHandler from "./DataStoreSyncQueriesStartedEventHandler";
import { SetterOrUpdater } from "recoil";
import { SyncReconciliationManager } from "./reconciliation/SyncReconciliationManager";
import { SyncReconciliationStatus } from "./reconciliation/SyncReconciliationStatus";

export default class DataStoreStatusUpdatingHubEventHandlerFactory {
    public static create(
        setDataStoreStatus: SetterOrUpdater<DataStoreStatus>,
        setSyncReconciliationStatus: SetterOrUpdater<SyncReconciliationStatus>,
        syncReconciliationManager: SyncReconciliationManager
    ) {
        const dataStoreOutboxStatusEventHandler = new DataStoreOutboxStatusEventHandler(
            setDataStoreStatus,
            setSyncReconciliationStatus,
            syncReconciliationManager
        );
        const dataStoreReadyEventHandler = new DataStoreReadyEventHandler(
            dataStoreOutboxStatusEventHandler,
            setDataStoreStatus,
            syncReconciliationManager
        );
        const dataStoreSyncQueryStartedEventHandler = new DataStoreSyncQueriesStartedEventHandler(
            dataStoreReadyEventHandler,
            ClientLoggerFactory.getClientLogger("DataStoreSyncQueriesStartedEventHandler")
        )
        return dataStoreSyncQueryStartedEventHandler;
    }
}
