import { Location } from "../../../models";
import { StateContext } from "../../design/document/state/StateContext";
import { TranscriptPromptType } from "../../../components/chime/hooks/prompts/TranscriptPromptType";
import { getUpdateLocationCallback } from "../../design/document/state/DocumentState";
import { isAiResourceGeneratingAtomFamily } from "../../../components/chime/hooks/state/AISummarizationStates";
import { useAISummarizer } from "../../../components/chime/hooks/useAISummarizer";
import { useRecoilCallback } from "recoil";

export const usePopulateLocationFromTranscript = (context: StateContext) => {
    const { generateFieldValue } = useAISummarizer();
    const updateLocation = getUpdateLocationCallback();

    const populateLocationFromTranscript = useRecoilCallback(({ set }) => async (
        location: Location,
    ): Promise<Location> => {
        set(isAiResourceGeneratingAtomFamily(location.id), true);
        const name = await generateFieldValue(TranscriptPromptType.GROUP_LOCATION_NAME);
        const updatedLocation: Location = new Location({
            ...location,
            name: name
        });
        await updateLocation(updatedLocation, context);
        set(isAiResourceGeneratingAtomFamily(location.id), false);
        return updatedLocation;
    }, [updateLocation, generateFieldValue]);

    return { populateLocationFromTranscript };
};