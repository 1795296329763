import {
    Alert,
    Box,
    Button,
    LinearProgress,
    Typography
} from "@mui/material";

import { AddIcon } from "../icons";
import { BLACK_OUTLINED_BUTTON } from "../general/button/ButtonStyles";
import { CreateTemplateForm } from "./CreateTemplateForm";
import { TemplateList } from "./TemplateList";
import { useState } from "react";
import { useUserAssociatedTemplates } from "../../lib/template/hooks/useUserAssociatedTemplates";

export const TemplateSettings = () => {
    const [createTemplate, setCreateTemplate] = useState<boolean>(false);
    const { entityNameToTemplatesMap, refetch: refetchTemplates, status: templateFetchStatus } = useUserAssociatedTemplates();

    return (
        <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            gap={1}
        >
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h6" component="h2" fontWeight="400">
                    Template Settings
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setCreateTemplate(true)}
                    disabled={templateFetchStatus !== "success" || createTemplate}
                    sx={BLACK_OUTLINED_BUTTON}
                    startIcon={<AddIcon primary="primary" />}
                >
                    Create Template
                </Button>
            </Box>
            {templateFetchStatus === "error" && (
                <Alert severity="error">
                    Error loading templates.
                </Alert>
            )}
            {templateFetchStatus === "loading" && (
                <LinearProgress />
            )}
            {(templateFetchStatus === "success" && (
                createTemplate ? (
                    <Box display="flex" justifyContent="center">
                        <CreateTemplateForm
                            onCreatedTemplate={() => {
                                setCreateTemplate(false);
                                refetchTemplates();
                            }}
                            onCanceled={() => setCreateTemplate(false)}
                        />
                    </Box>
                ) : (
                    <TemplateList
                        entityNameToTemplatesMap={entityNameToTemplatesMap ?? new Map()}
                        refetchTemplates={async () => { await refetchTemplates(); }}
                    />
                )
            ))}
        </Box>
    );
};