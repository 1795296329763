import {
    EditIcon,
    SaveCloseIcon,
    ShareIcon
} from "../../components/icons";
import {
    ListItemIcon,
    ListItemText,
    MenuItem
} from "@mui/material";
import {
    PermissionResourceType,
    PermissionType
} from "../../models";
import {
    getRemoveIssueCallback,
    isEditModeByDesignElementIdentifierAtomFamily,
    issueFeatureConfigurationByContextAtomFamily
} from "../../lib/design/document/state/DocumentState";
import {
    useMemo,
    useState
} from "react";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import ConfirmationPrompt from "../../components/general/ConfirmationPrompt";
import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DropDownMenu from "../../components/menu/DropDownMenu";
import { IssueFeatureConfiguration } from "../../lib/design/document/state/IssueFeatureConfiguration";
import { ManageResourcePermissionDialog } from "../permission/ManageResourcePermissionDialog";
import { ModelType } from "../../lib/design/document/ModelType";
import { PermissionRestrictedControl } from "../../components/general/button/PermissionRestrictedControl";
import { SolutionAuthoringEnabledPermissionRestrictedControl } from "../../components/general/button/SolutionAuthoringEnabledPermissionRestrictedControl";
import { StateContext } from "../../lib/design/document/state/StateContext";
import globalStyle from "../../assets/style/globalStyle";

export interface ViewIssueMenuProps {
    readonly issueId: string;
    readonly stateContext: StateContext;
    readonly size?: "small" | "medium";
}

const ViewIssueMenu = (props: ViewIssueMenuProps) => {
    /* Internal page states */
    const { issueId, stateContext, size } = props;
    const [isDeletePromptOpen, setIsDeletePromptOpen] = useState<boolean>(false);
    const globalClasses = globalStyle();
    const issueFeatureConfiguration = useRecoilValue<IssueFeatureConfiguration>(
        issueFeatureConfigurationByContextAtomFamily(stateContext)
    );
    const [isManagePermissionDialogOpen, setIsManagePermissionDialogOpen] = useState<boolean>(false);
    const issueIdentifier = useMemo(() => {
        return new ContextAwareIdentifier(issueId, stateContext, ModelType.ISSUE);
    }, [issueId, stateContext]);
    const [isEditMode, setIsEditMode] = useRecoilState(isEditModeByDesignElementIdentifierAtomFamily(issueIdentifier));

    /* Dependencies */
    const removeIssue = getRemoveIssueCallback();

    const menuItems = useMemo(() => {
        const result = [
            <SolutionAuthoringEnabledPermissionRestrictedControl
                requiredPermission={PermissionType.DELETE}
                resourceId={issueId}
                context={stateContext}
            >
                {(isDisabled) => (
                    <MenuItem
                        onClick={() => {
                            setIsDeletePromptOpen(true);
                        }}
                        sx={{ color: "error.main" }}
                        disabled={isDisabled}
                    >
                        <ListItemIcon sx={{ color: "error.main" }}>
                            <DeleteOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Delete
                        </ListItemText>
                    </MenuItem>
                )}
            </SolutionAuthoringEnabledPermissionRestrictedControl>
        ];
        if (issueFeatureConfiguration.allowShare) {
            result.unshift(
                <PermissionRestrictedControl
                    resourceId={issueId}
                    requiredPermission={PermissionType.SHARE_PERMISSION}
                >
                    {(isDisabled) => (
                        <MenuItem
                            onClick={() => setIsManagePermissionDialogOpen(true)}
                            disabled={isDisabled}
                        >
                            <ListItemIcon>
                                <ShareIcon accent={isDisabled ? "disabled" : "primary"} />
                            </ListItemIcon>
                            <ListItemText>
                                Share
                            </ListItemText>
                        </MenuItem>
                    )}
                </PermissionRestrictedControl>
            );
        }
        if (issueFeatureConfiguration.displayViewEditToggle) {
            result.unshift(
                <MenuItem onClick={() => setIsEditMode((prev) => !prev)}>
                    <ListItemIcon>
                        {isEditMode ? <SaveCloseIcon /> : <EditIcon />}
                    </ListItemIcon>
                    <ListItemText>
                        {isEditMode ? "Save" : "Edit"}
                    </ListItemText>
                </MenuItem>
            );
        }
        return result;
    }, [issueId, issueFeatureConfiguration, stateContext, isEditMode]);

    return (
        <>
            <DropDownMenu
                menuIconVariant={globalClasses.outlinedMenuButton}
                menuIconSize={size}
                menuItems={
                    menuItems
                }
            />
            <ConfirmationPrompt
                isVisible={isDeletePromptOpen}
                promptTitle="Delete Issue"
                promptMessage="Are you sure you want to Delete this Issue?"
                onCancelButtonClicked={() => {
                    setIsDeletePromptOpen(false);
                }}
                onConfirmButtonClicked={() => {
                    removeIssue(issueId, stateContext);
                }}
            />
            {issueFeatureConfiguration.allowShare && (
                <ManageResourcePermissionDialog
                    onClose={() => setIsManagePermissionDialogOpen(false)}
                    open={isManagePermissionDialogOpen}
                    resourceId={issueId}
                    resourceType={PermissionResourceType.ISSUE}
                />
            )}
        </>
    );
};

export default ViewIssueMenu;
