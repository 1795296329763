import {
    Alert,
    Box,
    Button,
    ButtonProps,
    ClickAwayListener,
    Paper,
    Snackbar,
    Typography
} from "@mui/material";
import {
    Fragment,
    useRef,
    useState
} from "react";
import {
    Property,
    Proposal,
    Template
} from "../../../models";
import {
    UploadToGoogleDriveHandler,
    useExportToGoogleDocs
} from "../../../lib/googleDrive/hooks/useExportToGoogleDocs";
import {
    hideAdjustmentsInUIAtom,
    hidePricesInUIAtom
} from "../../../lib/design/bidding/state/v2/ProposalStates";

import AddToDriveOutlinedIcon from '@mui/icons-material/AddToDriveOutlined';
import { DEFAULT_TEMPLATE } from "../../../lib/template/TemplateConstants";
import { ExportAs } from "../../../lib/template/type/ExportAs";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { ReportGenerationData } from "../../../lib/design/types/GenerateReportTypes";
import { StateContext } from "../../../lib/design/document/state/StateContext";
import { TENERA_FOLDER_NAME } from "../../../lib/googleDrive/GoogleDriveConstants";
import { grey } from "@mui/material/colors";
import { proposalRecordAtom } from "../../../lib/design/document/state/DocumentState";
import { reportGenerationDataSelectorByContextSelectorFamily } from "../../../lib/design/document/state/GenerateReportState";
import { useDownloadFile } from "../../general/hooks/useDownloadFile";
import { useRecoilCallback } from "recoil";
import { useSnackbar } from "notistack";
import { useUserAssociatedTemplates } from "../../../lib/template/hooks/useUserAssociatedTemplates";

type ExportSolutionButtonProps = Omit<ButtonProps, "onClick" | "property"> & {
    solutionId: string;
    propertyId: string;
    property: Property | null;
};

export const ExportSolutionButton = (props: ExportSolutionButtonProps) => {
    const { solutionId, propertyId, property, ...buttonProps } = props;
    const [isExportingDocument, setIsExportingDocument] = useState<boolean>(false);
    const [linkToExportedDocument, setLinkToExportedDocument] = useState<string | null>(null);
    const { entityNameToTemplatesMap } = useUserAssociatedTemplates();
    const [clickedExport, setClickedExport] = useState<boolean>(false);
    const [exportAs, setExportAs] = useState<ExportAs | undefined>();
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const snackbar = useSnackbar();
    const { exportFromTemplate, getUploadToGoogleDrive } = useExportToGoogleDocs();
    const { downloadFileFromBlob } = useDownloadFile();

    const handleExportToGoogleDocsClick = useRecoilCallback(({ snapshot }) => async (
        template: Template,
        exportAs: ExportAs
    ) => {
        const release = snapshot.retain();
        try {
            setIsExportingDocument(true);
            setLinkToExportedDocument(null);
            setClickedExport(false);
            const selectedExportAsOption = exportAs;
            setExportAs(undefined);
            let uploadToGoogleDrive: UploadToGoogleDriveHandler;
            if (selectedExportAsOption === ExportAs.GOOGLE_DOCS) {
                //Trigger authentication right after user clicks so popup is not blocked
                uploadToGoogleDrive = await getUploadToGoogleDrive();
            }
            const proposal: Proposal | undefined = await snapshot.getPromise(proposalRecordAtom);
            if (!proposal) {
                throw new Error("Failed to retrieve proposal data");
            }
            const solutionData: ReportGenerationData = await snapshot.getPromise(reportGenerationDataSelectorByContextSelectorFamily(StateContext.SOLUTION_VIEWING));
            const fileName = solutionData.solution_name ?? solutionId;
            const hidePrices: boolean = await snapshot.getPromise(hidePricesInUIAtom);
            const hideAdjustments: boolean = await snapshot.getPromise(hideAdjustmentsInUIAtom);
            const createdFile: Blob = await exportFromTemplate(
                proposal.id,
                template.id,
                {
                    hidePrices,
                    hideAdjustments
                }
            );
            if (selectedExportAsOption === ExportAs.WORD) {
                downloadFileFromBlob(createdFile, `${fileName}.docx`);
                return;
            }
            if (selectedExportAsOption === ExportAs.GOOGLE_DOCS) {
                const file: gapi.client.drive.File = await uploadToGoogleDrive!(
                    fileName,
                    createdFile,
                    {
                        name: property?.name ?? propertyId,
                        parentFolder: {
                            name: TENERA_FOLDER_NAME
                        }
                    }
                );
                setLinkToExportedDocument(`https://docs.google.com/document/d/${file?.id!}/edit`);
                return;
            }
        } catch (error) {
            snackbar.enqueueSnackbar(`Failed to export document as ${exportAs}, please try again later.`, { variant: "error" });
        } finally {
            setIsExportingDocument(false);
            release();
        }
    }, [property]);

    return (
        <>
            <ClickAwayListener
                onClickAway={() => {
                    setExportAs(undefined);
                    setClickedExport(false);
                }}
            >
                <Box>
                    <Button
                        ref={anchorRef}
                        onClick={() => setClickedExport(prev => !prev)}
                        {...buttonProps}
                        disabled={buttonProps.disabled || isExportingDocument}
                    >
                        Export
                    </Button>
                    {clickedExport && (
                        <Paper sx={{ position: "absolute", p: 1 }} elevation={4}>
                            {!exportAs && (
                                <Box display="flex" flexDirection="column" alignItems="flex-start" gap={0.5}>
                                    <Button
                                        onClick={() => setExportAs(ExportAs.WORD)}
                                        startIcon={<InsertDriveFileOutlinedIcon />}
                                    >
                                        Microsoft Word
                                    </Button>
                                    <Button
                                        onClick={() => setExportAs(ExportAs.GOOGLE_DOCS)}
                                        startIcon={<AddToDriveOutlinedIcon />}
                                    >
                                        Google Docs
                                    </Button>
                                </Box>
                            )}
                            {exportAs && (
                                <Box display="flex" flexDirection="column" gap={0.25}>
                                    <Box display="flex" gap={0.5} p={0.5} borderBottom={theme => `1px solid ${theme.palette.divider}`}>
                                        {exportAs === ExportAs.GOOGLE_DOCS && <AddToDriveOutlinedIcon color="primary" fontSize="small" />}
                                        {exportAs === ExportAs.WORD && <InsertDriveFileOutlinedIcon color="primary" fontSize="small" />}
                                        <Typography fontSize="0.875rem">
                                            Templates
                                        </Typography>
                                    </Box>
                                    <Box
                                        ml={0.5}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="flex-start"
                                        maxWidth="180px"
                                        maxHeight="200px"
                                        sx={{ overflowY: "auto" }}
                                        gap={0.25}
                                    >
                                        <Box
                                            borderBottom={theme => {
                                                if (!entityNameToTemplatesMap || entityNameToTemplatesMap.size === 0) {
                                                    return undefined;
                                                }
                                                return `1px dashed ${theme.palette.divider}`;
                                            }}
                                            width="100%"
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="flex-start"
                                        >
                                            <Typography fontSize="0.875rem" color={grey[800]}>
                                                Tenera
                                            </Typography>
                                            <Button
                                                onClick={() => {
                                                    handleExportToGoogleDocsClick(DEFAULT_TEMPLATE, exportAs);
                                                    setClickedExport(false);
                                                    setExportAs(undefined);
                                                }}
                                            >
                                                Default template
                                            </Button>
                                        </Box>
                                        {Array.from(entityNameToTemplatesMap?.entries() ?? []).map(([entityName, templates], index) => (
                                            <Fragment key={entityName}>
                                                <Box
                                                    key={entityName}
                                                    borderBottom={theme => index !== entityNameToTemplatesMap!.size - 1 ? `1px dashed ${theme.palette.divider}` : undefined}
                                                    width="100%"
                                                    display="flex"
                                                    flexDirection="column"
                                                    alignItems="flex-start"
                                                >
                                                    <Typography
                                                        overflow="hidden"
                                                        textOverflow="ellipsis"
                                                        noWrap
                                                        fontSize="0.875rem"
                                                        color={grey[800]}
                                                    >
                                                        {entityName}
                                                    </Typography>
                                                    {templates.map(template => (
                                                        <Button
                                                            sx={{ maxWidth: "100%" }}
                                                            key={template.id}
                                                            onClick={() => {
                                                                handleExportToGoogleDocsClick(template, exportAs);
                                                            }}
                                                        >
                                                            <Typography
                                                                overflow="hidden"
                                                                textOverflow="ellipsis"
                                                                noWrap
                                                                fontSize="inherit"
                                                            >
                                                                {template.name}
                                                            </Typography>
                                                        </Button>
                                                    ))}
                                                </Box>
                                            </Fragment>
                                        ))}
                                    </Box>
                                </Box>
                            )}
                        </Paper>
                    )}
                </Box>
            </ClickAwayListener>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={isExportingDocument}
            >
                <Alert severity="info">
                    Exporting document...
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={linkToExportedDocument != null}
            >
                <Alert
                    severity="success"
                    action={
                        <Button
                            size="small"
                            onClick={() => {
                                window.open(linkToExportedDocument!, "_blank");
                                setLinkToExportedDocument(null);
                            }}
                        >
                            Open
                        </Button>
                    }
                >
                    Exported to Google Docs
                </Alert>
            </Snackbar>
        </>
    );
};