import { useCallback } from "react";

export const useDownloadFile = () => {
    const downloadFileFromUrl = useCallback((
        url: string,
        fileName: string,
    ) => {
        const tempElement = document.createElement("a");
        tempElement.href = url;
        tempElement.download = fileName;
        document.body.appendChild(tempElement);
        tempElement.click();
        document.body.removeChild(tempElement);
    }, []);

    const downloadFileFromBlob = useCallback((
        blob: Blob,
        fileName: string,
    ) => {
        const url = window.URL.createObjectURL(blob);
        downloadFileFromUrl(url, fileName);
        window.URL.revokeObjectURL(url);
    }, [downloadFileFromUrl]);

    return {
        downloadFileFromUrl,
        downloadFileFromBlob,
    };
}