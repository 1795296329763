import {
    Dispatch,
    SetStateAction
} from "react";

import { CognitoUser } from "@aws-amplify/auth";
import { Hub } from "aws-amplify";
import HubBackedEventListener from "../event/HubBackedEventListener";
import { HubCapsule } from "@aws-amplify/core";
import HubEventHandler from "../event/HubEventHandler";
import UserEventHandlerFactory from "./UserEventHandlerFactory";

export default class HubBackedUserEventListenerFactory {
    private static readonly AUTH_CHANNEL = "auth";

    public static create(
        setUser: Dispatch<SetStateAction<CognitoUser | undefined>>
    ) {
        const signInEventHandler: HubEventHandler = UserEventHandlerFactory.create(setUser);
        return new HubBackedEventListener(
            HubBackedUserEventListenerFactory.AUTH_CHANNEL,
            Hub,
            (data: HubCapsule) => {
                signInEventHandler.handle(data.payload);
            }
        );
    }
}
