import {
    Checkbox,
    Theme
} from "@mui/material";

import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { ModelType } from "../../lib/design/document/ModelType";
import { PriceCalculationType } from "../../lib/design/bidding/PriceCalculationType";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { makeStyles } from "@mui/styles";
import { priceCalculationTypeByProposalItemIdentifierAtomFamily } from "../../lib/design/bidding/state/v2/ProposalItemStates";
import { useMemo } from "react";
import { useRecoilState } from "recoil";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        color: theme.palette.primary.main,
    },
}));

type AutoCalculatePriceCheckBoxProps = {
    proposalItemId: string;
    context: StateContext;
};

export const AutoCalculatePriceCheckBox = ({ proposalItemId, context }: AutoCalculatePriceCheckBoxProps) => {
    const classes = useStyles();
    const proposalItemIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(proposalItemId, context, ModelType.PROPOSAL_ITEM);
    }, [proposalItemId, context]);
    const [priceCalculationType, setPriceCalculationType] = useRecoilState<PriceCalculationType | undefined>(
        priceCalculationTypeByProposalItemIdentifierAtomFamily(proposalItemIdentifier)
    );

    return (
        <Checkbox
            checked={priceCalculationType === PriceCalculationType.WORK_TYPE_UNIT_PRICING}
            onChange={(event) => setPriceCalculationType(event.target.checked ? PriceCalculationType.WORK_TYPE_UNIT_PRICING :
                PriceCalculationType.MANUAL)}
            className={classes.root}
            color="primary"
            size="small"
        />
    );
};

