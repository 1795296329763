import Dropzone, {
    DropzoneProps,
    DropzoneState
} from "react-dropzone";

import { Box } from "@mui/material";
import { DropFilesHereMessage } from "../../componentsV2/image/carouselComponent/DropFilesHereMessage";

export const CustomDropZone = (props: DropzoneProps) => {
    return (
        <Dropzone
            {...props}
        >
            {(state: DropzoneState) => (
                <Box
                    height="100%"
                    width="100%"
                    data-cy={!props.disabled ? "drop-zone" : null}
                    sx={{ aspectRatio: "inherit" }}
                    {...state.getRootProps()}
                >
                    {!props.disabled && <input {...state.getInputProps()} />}
                    {state.isDragActive && <DropFilesHereMessage />}
                    <Box
                        height="100%"
                        width="100%"
                        sx={{
                            display: state.isDragActive ? "none" : "block"
                        }}
                    >
                        {props.children?.(state)}
                    </Box>
                </Box>
            )}
        </Dropzone>
    );
};