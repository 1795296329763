import {
    Box,
    Button,
    Card,
    CardContent,
    Stack,
    Typography
} from '@mui/material';
import {
    Link,
    useParams
} from "react-router-dom";

import { BLACK_OUTLINED_BUTTON } from '../general/button/ButtonStyles';
import ListSolutions from './ListSolutions';
import { PermissionRestrictedControl } from '../general/button/PermissionRestrictedControl';
import { PermissionType } from '../../models';
import { ReportIcon } from '../icons';
import globalStyle from '../../assets/style/globalStyle';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    hiddenTab: {
        display: "none"
    },
    displayTab: {
        display: "block"
    },
    tabs: {
        backgroundColor: "#fff"
    },
    containerWithBorder: {
        border: "1px solid #ddd",
        borderRadius: "4px",
        backgroundColor: "#fff"
    }
}));

interface ViewPropertySolutionsParams {
    readonly propertyId: string;
}

export const ViewPropertySolutions = () => {
    const classes = useStyles();
    const globalClasses = globalStyle();
    const { propertyId } = useParams<ViewPropertySolutionsParams>();

    return (
        <Box className={globalClasses.managerLayout}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h6">
                    Solutions
                </Typography>
                <PermissionRestrictedControl
                    requiredPermission={PermissionType.CREATE_CHILDREN}
                    resourceId={propertyId}
                >
                    {(disabled) => (
                        <Button
                            variant="outlined"
                            sx={{ ...BLACK_OUTLINED_BUTTON }}
                            startIcon={
                                <ReportIcon
                                    primary={disabled ? "disabled" : "inherit"}
                                    accent={disabled ? "disabled" : "inherit"}
                                />
                            }
                            disabled={disabled}
                            component={Link}
                            to={{
                                pathname: `/properties/${propertyId}/solutions/create`
                            }}
                        >
                            Create Solution
                        </Button>
                    )}
                </PermissionRestrictedControl>
            </Stack>
            <Box marginTop={1}>
                <Card className={globalClasses.listContainerWithBorder}>
                    <CardContent style={{ padding: 0 }}>
                        <ListSolutions propertyId={propertyId} />
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};
