import DataStoreAttachmentRecordDAOFactory from "../../../../attachment/record/datastore/DataStoreAttachmentRecordDAOFactory";
import { SolutionAttachmentPopulator } from "./SolutionAttachmentPopulator";
import { SolutionAttachmentPopulatorImpl } from "./SolutionAttachmentPopulatorImpl";

export class SolutionAttachmentPopulatorFactory {
    private static INSTANCE: SolutionAttachmentPopulator  = new SolutionAttachmentPopulatorImpl(
        DataStoreAttachmentRecordDAOFactory.getInstance()
    )

    public static getInstance(): SolutionAttachmentPopulator {
        return SolutionAttachmentPopulatorFactory.INSTANCE;
    }
}