import { createNumberMask } from "text-mask-addons";

export const createPriceNumberMask = (allowNegative: boolean) => createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: allowNegative,
    allowLeadingZeroes: false,
});
