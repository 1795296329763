import { API, graphqlOperation } from "aws-amplify";

import ClientLogger from "../../../logging/ClientLogger";
import { CreateDimensionError } from '../error/CreateDimensionError';
import { CreateDimensionMutation } from "../../../../API";
import { Dimension } from "../../../../models";
import DimensionDAO from "../DimensionDAO";
import DimensionRecordAlreadyExistsError from "../error/DimensionRecordAlreadyExistsError";
import Envelope from "../../../envelope/Envelope";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { IDTokenSupplier } from "../../../auth/IDTokenSupplier";
import { ProducerModelPredicate } from "@aws-amplify/datastore";
import { createDimension } from "../../../../graphql/mutations";

export class GraphQLDimensionDAO implements DimensionDAO {

    private static readonly CREATE_ATTEMPT_METRIC_NAME = "GraphQLDimensionDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "GraphQLDimensionDAO.Create.Failure";

    private readonly api: typeof API;
    private readonly gqlOperation: typeof graphqlOperation;
    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async getDimensionById(dimensionId: string): Promise<Dimension> {
        throw new Error("Method not implemented.");
    }

    public async create(
        record: Dimension
    ): Promise<Dimension> {
        this.logger.info(
            `Creating Dimension record with id=${record.id}`,
            record,
            [GraphQLDimensionDAO.CREATE_ATTEMPT_METRIC_NAME]
        );
        try {
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<CreateDimensionMutation> = await this.api.graphql(
                this.gqlOperation(
                    createDimension,
                    { input: record },
                    authToken
                )
            ) as GraphQLResult<CreateDimensionMutation>;
            return result.data?.createDimension as Dimension;
        } catch (error) {
            if ((error as any)?.errors && (error as any)?.errors[0].errorType === "ConditionalCheckFailedException") {
                const errorMessage = `Dimension record already exists: ${record.id}`;
                this.logger.warn(
                    errorMessage,
                    error,
                    [GraphQLDimensionDAO.CREATE_FAILURE_METRIC_NAME]
                );
                throw new DimensionRecordAlreadyExistsError(
                    errorMessage
                );
            }
            const errorMessage = `Unable to create Dimension record with id=${record.id}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLDimensionDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreateDimensionError(errorMessage, {
                cause: error as Error
            });
        }
    }

    public async createDimensionFromV2Envelope(
        envelope: Envelope,
        propertyId: string,
        parentId: string
    ): Promise<Envelope> {
        throw new Error("Method not implemented.");
    }

    public async getDimensionsByParentId(
        parentId: string
    ): Promise<Dimension[]> {
        throw new Error("Method not implemented.");
    }

    public async updateDimension(
        dimensionId: string,
        dimension: Dimension
    ): Promise<Dimension> {
        throw new Error("Method not implemented.");
    }

    public async updateDimensionByEnvelope(
        dimensionId: string,
        dimension: Envelope
    ): Promise<Envelope> {
        throw new Error("Method not implemented.");
    }

    public async deleteDimension(
        dimensionId: string
    ): Promise<void> {
        throw new Error("Method not implemented.");
    }

    public async getDimensionEnvelopeById(
        dimensionId: string
    ): Promise<Envelope | undefined> {
        throw new Error("Method not implemented.");
    }

    public getDimensionsByParentIdWithLimit(
        // This type needs to be abstracted - see the TODO comment on the interface.
        predicate: ProducerModelPredicate<Dimension>,
        limit: number
    ): Promise<Dimension[]> {
        throw new Error("Method not implemented.");
    }
}
