import {
    Box,
    Skeleton,
    Typography
} from "@mui/material";
import {
    Proposal,
    Solution,
    SolutionMetadata,
    SolutionTenderingType
} from "../../../models";
import {
    WORD_ATTRIBUTE_LABEL,
    WORD_PROPOSAL_METADATA,
    WORD_TENDER_DETAIL_VALUE,
    WORD_TITLE
} from "./ViewSolutionStyles";
import {
    isSolutionSoleSourceSelectorFamily,
    propertyImageUrlAtom,
    proposalRecordAtom,
    solutionMetadataAtom,
    solutionRecordAtom
} from "../../../lib/design/document/state/DocumentState";
import {
    useEffect,
    useRef,
    useState
} from "react";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import Jimp from "jimp";
import { LineBreak } from "./clipboard/LineBreak";
import { ListImageURLsByParentIDOperation } from "../../../lib/attachment/operation/ListImageURLsByParentIDOperation";
import { ListImageURLsByParentIDOperationFactory } from "../../../lib/attachment/operation/ListImageURLsByParentIDOperationFactory";
import { StateContext } from "../../../lib/design/document/state/StateContext";
import { ViewSolutionParams } from "./ViewSolution";
import formatDate from "date-fns/format";
import { formatPhoneNumber } from "react-phone-number-input";
import theme from "../../../assets/style/theme";
import { useParams } from "react-router-dom";

const PROPERTY_IMAGE_WIDTH = 420;
const PROPERTY_IMAGE_HEIGHT = 280;

export const ViewTenderDetail = () => {

    /* Constants */
    const { propertyId, solutionId } = useParams<ViewSolutionParams>();

    /* States */
    const [propertyImageUrl, setPropertyImageUrl] = useRecoilState<string | undefined>(propertyImageUrlAtom);
    const [hasPropertyImage, setHasPropertyImage] = useState<boolean>(false);
    const solutionMetadata = useRecoilValue<SolutionMetadata>(solutionMetadataAtom);
    const solutionRecord = useRecoilValue<Solution | undefined>(solutionRecordAtom);
    const proposalRecord = useRecoilValue<Proposal | undefined>(proposalRecordAtom);
    const isSoleSource = useRecoilValue<boolean>(isSolutionSoleSourceSelectorFamily(StateContext.SOLUTION_VIEWING));

    /* Dependencies */
    const listImageURLsByParentIDOperationRef = useRef<ListImageURLsByParentIDOperation>(ListImageURLsByParentIDOperationFactory.getInstance());

    useEffect(() => {
        const fetchPropertyImage = async () => {
            const propertyImageUrls: Array<string> = await listImageURLsByParentIDOperationRef.current.handle(propertyId);
            if (propertyImageUrls.length > 0) {
                setHasPropertyImage(true);
                const jimpImage = await Jimp.read(propertyImageUrls[0]);
                jimpImage.cover(PROPERTY_IMAGE_WIDTH, PROPERTY_IMAGE_HEIGHT);
                const transformedImage = await jimpImage.getBase64Async(Jimp.MIME_JPEG);
                setPropertyImageUrl(transformedImage);
            }
        };
        fetchPropertyImage();
        return () => {
            setPropertyImageUrl(undefined);
            setHasPropertyImage(false);
        };
    }, []);

    return (
        <>
            <Box component="p">
                <Typography style={{ ...WORD_TITLE, textAlign: "center" }}>
                    {solutionMetadata.name}
                </Typography>
                <LineBreak />
                {propertyImageUrl && (
                    <Box component="p" style={{ textAlign: "center" }}>
                        <img
                            style={{ maxWidth: "100%" }}
                            src={propertyImageUrl}
                        />
                    </Box>
                )}
                {hasPropertyImage && !propertyImageUrl && (
                    <Box display="flex" justifyContent="center">
                        <Skeleton
                            variant="rectangular"
                            width={PROPERTY_IMAGE_WIDTH}
                            height={PROPERTY_IMAGE_HEIGHT}
                        />
                    </Box>
                )}
                <LineBreak />
                <Box component="p" style={{ textAlign: "center" }}>
                    <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                        Address:
                    </Typography>
                    <Typography component="span" style={WORD_TENDER_DETAIL_VALUE}>
                        &nbsp;{solutionMetadata.propertyAddress}
                    </Typography>
                    <Typography component="span" style={{ color: theme.palette.primary.dark }}>
                        &emsp;|&emsp;
                    </Typography>
                    <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                        Customer:
                    </Typography>
                    <Typography component="span" style={WORD_TENDER_DETAIL_VALUE}>
                        &nbsp;{solutionMetadata.propertyContactName}
                    </Typography>
                </Box>
                <LineBreak />
                <Box component="p" style={{ textAlign: "center" }}>
                    <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                        Consultant Email:
                    </Typography>
                    <Typography component="span" style={WORD_TENDER_DETAIL_VALUE}>
                        &nbsp;{solutionMetadata.consultantEmail}
                    </Typography>
                    <Typography component="span" style={{ color: theme.palette.primary.dark }}>
                        &emsp;|&emsp;
                    </Typography>
                    <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                        Consultant Phone:
                    </Typography>
                    <Typography component="span" style={WORD_TENDER_DETAIL_VALUE}>
                        &nbsp;{solutionMetadata.consultantPhoneNumber ? formatPhoneNumber(solutionMetadata.consultantPhoneNumber) : "N/A"}
                    </Typography>
                </Box>
                <LineBreak />
                <Box component="p" style={{ textAlign: "center" }}>
                    <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                        Property Type:
                    </Typography>
                    <Typography component="span" style={WORD_TENDER_DETAIL_VALUE}>
                        &nbsp;{solutionMetadata.propertyType}
                    </Typography>
                    <Typography component="span" style={{ color: theme.palette.primary.dark }}>
                        &emsp;|&emsp;
                    </Typography>
                    <Typography component="span" style={WORD_ATTRIBUTE_LABEL}>
                        Document Type:
                    </Typography>
                    <Typography component="span" style={WORD_TENDER_DETAIL_VALUE}>
                        &nbsp;{solutionMetadata.tenderingType === SolutionTenderingType.TENDERED ? "Tender" : "Sole Source"}
                    </Typography>
                </Box>
            </Box>
            <LineBreak />
            <Box component="p" style={{ textAlign: "center" }}>
                {
                    isSoleSource &&
                    <>
                        <Typography component="span" style={WORD_PROPOSAL_METADATA}>
                            Proposal # {proposalRecord?.proposalNumber}
                        </Typography>
                        <Typography component="span" style={{ color: theme.palette.primary.dark }}>
                            &emsp;|&emsp;
                        </Typography>
                    </>
                }
                <Typography component="span" style={WORD_PROPOSAL_METADATA}>
                    Solution # {solutionRecord?.serialNumber}
                </Typography>
            </Box>
            <LineBreak />
            <Typography style={{ ...WORD_PROPOSAL_METADATA, textAlign: "center" }}>
                Bid Due Date: {solutionMetadata.solutionDueDate ? formatDate(parseInt(solutionMetadata?.solutionDueDate), "MM/dd/yyyy") : "TBD"}
            </Typography>
        </>
    );
};
