import AddressFallbackPropertyNameFactory from "../property/v2/AddressFallbackPropertyNameFactory";
import LinkData from "./LinkData";
import { Property } from "../../../models";
import PropertyNameFactory from "../property/PropertyNameFactory";

export default class SolutionLinkFactory {
    private static propertyNameFactory: PropertyNameFactory = new AddressFallbackPropertyNameFactory();
    
    static createByProperty(
        property: Property
    ): LinkData {
        const url = `/properties/${property.id}/solutions`;
        const propertyName: string = this.propertyNameFactory.getName(property);
        return {
            displayText: propertyName,
            url
        };
    }
}
