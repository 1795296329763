import { dimensionByDimensionIdentifierSelectorFamily, recentlyCreatedRecordIds, recordPersistenceConfigurationAtom } from "../../design/document/state/DocumentState";

import { ContextAwareIdentifier } from "../../design/document/ContextAwareIdentifier";
import DataStoreDimensionDAOFactory from "../dao/datastore/DataStoreDimensionDAOFactory";
import { Dimension } from "../../../models";
import DimensionDAO from "../dao/DimensionDAO";
import { ModelType } from "../../design/document/ModelType";
import { RecordPersistenceConfiguration } from "../../design/document/state/RecordPersistenceConfiguration";
import { RecordPersistenceMode } from "../../design/document/state/RecordPersistenceMode";
import { StateContext } from "../../design/document/state/StateContext";
import { useRecoilCallback } from "recoil";

const dimensionDAO: DimensionDAO = DataStoreDimensionDAOFactory.getDimensionDAO();

export const useUpdateDimension = (context: StateContext) => {

    const updateDimension = useRecoilCallback(({ snapshot, set }) => async (
        dimension: Dimension,
    ) => {
        const release = snapshot.retain();
        try {
            const recordPersistenceConfiguration = await snapshot.getLoadable(recordPersistenceConfigurationAtom).contents as RecordPersistenceConfiguration;
            let updatedDimension = dimension;
            if (recordPersistenceConfiguration.update === RecordPersistenceMode.ALWAYS_PERSIST) {
                updatedDimension = await dimensionDAO.updateDimension(dimension.id, dimension);
            }
            if (recordPersistenceConfiguration.update === RecordPersistenceMode.PERSIST_RECENTLY_CREATED) {
                const recentRecordIds = snapshot.getLoadable(recentlyCreatedRecordIds).contents as Set<string>;
                if (recentRecordIds.has(dimension.id)) {
                    updatedDimension = await dimensionDAO.updateDimension(dimension.id, dimension);
                }
            }
            const dimensionIdentifier = new ContextAwareIdentifier(updatedDimension.id, context, ModelType.DIMENSION);
            set(dimensionByDimensionIdentifierSelectorFamily(dimensionIdentifier), updatedDimension);
            return updatedDimension;
        } finally {
            release();
        }
    }, [context]);

    return { updateDimension };
};
