import {
    Checkbox,
    FormControlLabel,
    FormGroup
} from "@mui/material";

import { IssueStatus } from "../../../models";

interface StatusFilterProps {
    statusFilterState: Map<IssueStatus, boolean>;
    onStatusFilterChange: (status: IssueStatus, isChecked: boolean) => void;
}

export const StatusFilter = (props: StatusFilterProps) => {
    const {statusFilterState, onStatusFilterChange} = props;

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        onChange={(event, isChecked) => onStatusFilterChange(IssueStatus.NONE, isChecked)}
                        checked={statusFilterState.get(IssueStatus.NONE)}
                    />
                }
                label="No Status"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        onChange={(event, isChecked) => onStatusFilterChange(IssueStatus.ARCHIVED, isChecked)}
                        checked={statusFilterState.get(IssueStatus.ARCHIVED)}
                    />
                }
                label="Archived"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        onChange={(event, isChecked) => onStatusFilterChange(IssueStatus.APPROVED, isChecked)}
                        checked={statusFilterState.get(IssueStatus.APPROVED)}
                    />
                }
                label="Approved"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        onChange={(event, isChecked) => onStatusFilterChange(IssueStatus.ON_HOLD, isChecked)}
                        checked={statusFilterState.get(IssueStatus.ON_HOLD)}
                    />
                }
                label="On Hold"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        onChange={(event, isChecked) => onStatusFilterChange(IssueStatus.REJECTED, isChecked)}
                        checked={statusFilterState.get(IssueStatus.REJECTED)}
                    />
                }
                label="Rejected"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        onChange={(event, isChecked) => onStatusFilterChange(IssueStatus.UNDER_REVIEW, isChecked)}
                        checked={statusFilterState.get(IssueStatus.UNDER_REVIEW)}
                    />
                }
                label="Under Review"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        onChange={(event, isChecked) => onStatusFilterChange(IssueStatus.COMPLETED, isChecked)}
                        checked={statusFilterState.get(IssueStatus.COMPLETED)}
                    />
                }
                label="Completed"
            />
        </FormGroup>
    );
};
