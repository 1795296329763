import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.75 4.793 15.957 12 8.75 19.207l-1.414-1.414L13.129 12 7.336 6.207z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(ChevronRightIcon);
