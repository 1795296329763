import GraphQLProposalRecordDAOFactory from "../../dao/record/GraphQLProposalRecordDAOFactory";
import S3ProposalContentDAOFactory from "../../dao/content/S3ProposalContentDAOFactory";
import SaveProposalHandler from "./SaveProposalHandler";
import SaveProposalHandlerImpl from "./SaveProposalHandlerImpl";

export default class SaveProposalHandlerFactory {
    private static readonly INSTANCE: SaveProposalHandler = new SaveProposalHandlerImpl(
        S3ProposalContentDAOFactory.getInstance(),
        GraphQLProposalRecordDAOFactory.getInstance()
    );

    public static getInstance(): SaveProposalHandler {
        return SaveProposalHandlerFactory.INSTANCE;
    }
}
