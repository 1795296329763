import {
    EntityWorkTypeAssociation,
    WorkTypeAssociationScopeEntity
} from "../../../../models";

import DissociateWorkTypeWithEntityError from "./error/DissociateWorkTypeWithEntityError";
import DissociateWorkTypeWithEntityHandler from "./DissociateWorkTypeWithEntityHandler";
import EntityWorkTypeAssociationDAO from '../../relationship/EntityWorkTypeAssociationDAO';
import WorkTypeDTO from "../../DTO/WorkTypeDTO";

export default class DissociateWorkTypeWithEntityHandlerImpl implements DissociateWorkTypeWithEntityHandler {
    private readonly entityWorkTypeAssociationDao: EntityWorkTypeAssociationDAO;
  
    constructor(
        entityWorkTypeAssociationDao: EntityWorkTypeAssociationDAO,
    ) {
        this.entityWorkTypeAssociationDao = entityWorkTypeAssociationDao;
    }
  
    public async handle(
        entityId: string,
        entityType: WorkTypeAssociationScopeEntity,
        workType: WorkTypeDTO
    ): Promise<void> {
        try {
            const existingAssociation: Array<EntityWorkTypeAssociation> = await this.entityWorkTypeAssociationDao.listByEntityId(entityId); 
            for (const association of existingAssociation) {
                if (association.workTypeGroupId === workType.groupId) {
                    await this.entityWorkTypeAssociationDao.delete(association.id);
                }
            }
        } catch (error) {
            throw new DissociateWorkTypeWithEntityError(
                `Error occurred while dissociating workTypes with entity ${entityType} id: ${entityId}`, 
                error as Error
            );
        }
    }
}
