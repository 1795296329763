import Comparator from "../../util/data/sort/Comparator";
import { ComparisonResult } from "../../util/data/sort/ComparisonResult";
import WorkTypeDTO from "../DTO/WorkTypeDTO";

/**
 * Compares two {@link WorkTypeDTO} by name, ascending.
 */
export default class WorkTypeDTONameAscComparator implements Comparator<WorkTypeDTO> {
    compare(a: WorkTypeDTO, b: WorkTypeDTO): ComparisonResult {
        if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
            return ComparisonResult.LESS_THAN;
        }
        if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
            return ComparisonResult.GREATER_THAN;
        }
        return ComparisonResult.EQUAL;
    }
}
