import {
    Box,
    useMediaQuery
} from "@mui/material";

import { QuickCreateIssueKey } from "./QuickCreateIssueKey";
import { QuickCreateLocationKey } from "./QuickCreateLocationKey";
import { QuickCreateWorkSpecificationKey } from "./QuickCreateWorkSpecificationKey";
import { QuickExpandAllKey } from "./QuickExpandAllKey";
import { QuickSaveKey } from "./QuickSaveKey";
import theme from "../../assets/style/theme";

export const HORIZONTAL_QUICK_ACCESS_KEYS_ID = "horizontal_quick_access_keys";
export const VERTICAL_QUICK_ACCESS_KEYS_ID = "vertical_quick_access_keys";

export const QuickAccessKeys = () => {
    const isLandscape = useMediaQuery("(orientation: landscape)");
    const isScreenXSHeight = useMediaQuery(`(max-height: ${theme.breakpoints.values.sm}px)`);

    return (
        isLandscape ? (
            <Box
                display="flex"
                justifyContent="space-between"
                gap={1}
                flexDirection="column"
                height="100%"
                width="100%"
                py={isScreenXSHeight ? 2 : 6}
                id={HORIZONTAL_QUICK_ACCESS_KEYS_ID}
            >
                <Box
                    display="flex"
                    justifyContent="space-evenly"
                    flexDirection="column"
                    height="30%"
                    gap={1}
                >
                    <QuickSaveKey />
                    <QuickCreateLocationKey />
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-evenly"
                    flexDirection="column"
                    height="30%"
                    gap={1}
                >
                    <QuickExpandAllKey />
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-evenly"
                    flexDirection="column"
                    height="30%"
                    gap={1}
                    sx={{pointerEvents: "none"}}
                >
                    <QuickCreateWorkSpecificationKey />
                    <QuickCreateIssueKey />
                </Box>
            </Box >
        ) : (
            <Box
                display="flex"
                justifyContent="space-between"
                gap={1}
                flexDirection="row"
                height="100%"
                width="100%"
                px={{
                    xs: 2,
                    sm: 7
                }}
                id={VERTICAL_QUICK_ACCESS_KEYS_ID}
            >
                <Box
                    display="flex"
                    justifyContent="space-evenly"
                    flexDirection="row"
                    gap={{
                        xs: 2,
                        sm: 4.5
                    }}
                >
                    <QuickSaveKey />
                    <QuickCreateLocationKey />
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-evenly"
                    flexDirection="row"
                    gap={{
                        xs: 2,
                        sm: 4.5
                    }}
                >
                    <QuickExpandAllKey />
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-evenly"
                    flexDirection="row"
                    gap={{
                        xs: 2,
                        sm: 4.5
                    }}
                >
                    <QuickCreateWorkSpecificationKey />
                    <QuickCreateIssueKey />
                </Box>
            </Box >
        )
    );
};