import DefaultMeasurementValidator from "./DefaultMeasurementValidator";
import MeasurementValidator from "./MeasurementValidator";

export default class DefaultMeasurementValidatorFactory {
    private static readonly INSTANCE: MeasurementValidator = new DefaultMeasurementValidator()

    public static getInstance(): MeasurementValidator {
        return DefaultMeasurementValidatorFactory.INSTANCE;
    }
}
