import AuthUsernameSupplier from "./AuthUsernameSupplier";
import AuthUsernameSupplierCachedDecorator from "./AuthUsernameSupplierCachedDecorator";
import UsernameSupplier from "./UsernameSupplier";

export default class AuthUsernameSupplierFactory {
    private static readonly INSTANCE: UsernameSupplier = new AuthUsernameSupplierCachedDecorator(
        new AuthUsernameSupplier()
    );
    public static getInstance() {
        return this.INSTANCE;
    }
}