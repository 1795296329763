import API, {
    GraphQLResult
} from "@aws-amplify/api";
import {
    CreateSolutionMinorVersionMutation,
    DeleteSolutionMinorVersionMutationVariables,
    ListSolutionMinorVersionsBySolutionByMinorVersionQuery,
    ListSolutionMinorVersionsBySolutionByMinorVersionQueryVariables
} from "../../../../../API";
import {
    createSolutionMinorVersion,
    deleteSolutionMinorVersion
} from "../../../../../graphql/mutations";

import ClientLogger from "../../../../logging/ClientLogger";
import CreateSolutionMinorVersionRecordError from "./error/CreateSolutionMinorVersionRecordError";
import { DeleteSolutionMinorVersionRecordError } from "./error/DeleteSolutionMinorVersionRecordError";
import GetSolutionMinorVersionRecordError from "./error/GetSolutionMinorVersionRecordError";
import { IDTokenSupplier } from "../../../../auth/IDTokenSupplier";
import { SolutionMinorVersion } from "../../../../../models";
import SolutionMinorVersionRecordDAO from "./SolutionMinorVersionRecordDAO";
import { graphqlOperation } from "aws-amplify";
import { listSolutionMinorVersionsBySolutionByMinorVersion } from "../../../../../graphql/queries";

export default class GraphQLSolutionMinorVersionRecordDAO implements SolutionMinorVersionRecordDAO {
    private static readonly GET_BY_ID_ATTEMPT_METRIC_NAME = "GraphQLSolutionMinorVersionRecordDAO.GetBySolutionIdAndMinorVersion.Attempt";
    private static readonly GET_BY_ID_FAILURE_METRIC_NAME = "GraphQLSolutionMinorVersionRecordDAO.GetBySolutionIdAndMinorVersion.Failure";
    private static readonly CREATE_ATTEMPT_METRIC_NAME = "GraphQLSolutionMinorVersionRecordDAO.Create.Attempt";
    private static readonly CREATE_FAILURE_METRIC_NAME = "GraphQLSolutionMinorVersionRecordDAO.Create.Failure";
    private static readonly LIST_BY_SOLUTION_ID_ATTEMPT_METRIC_NAME = "GraphQLSolutionMinorVersionRecordDAO.ListBySolutionId.Attempt";
    private static readonly LIST_BY_SOLUTION_ID_FAILURE_METRIC_NAME = "GraphQLSolutionMinorVersionRecordDAO.ListBySolutionId.Failure";
    private static readonly DELETE_ATTEMPT_METRIC_NAME = "GraphQLSolutionMinorVersionRecordDAO.Delete.Attempt";
    private static readonly DELETE_FAILURE_METRIC_NAME = "GraphQLSolutionMinorVersionRecordDAO.Delete.Failure";

    private readonly api: typeof API;
    private readonly gqlOperation: typeof graphqlOperation;
    private readonly idTokenSupplier: IDTokenSupplier;
    private readonly logger: ClientLogger;

    constructor(
        api: typeof API,
        gqlOperation: typeof graphqlOperation,
        idTokenSupplier: IDTokenSupplier,
        logger: ClientLogger
    ) {
        this.api = api;
        this.gqlOperation = gqlOperation;
        this.idTokenSupplier = idTokenSupplier;
        this.logger = logger;
    }

    public async create(
        solutionMinorVersion: SolutionMinorVersion
    ): Promise<SolutionMinorVersion> {
        try {
            this.logger.info(
                `Attempting to create SolutionMinorVersion record with id=${solutionMinorVersion.id}`,
                solutionMinorVersion,
                [GraphQLSolutionMinorVersionRecordDAO.CREATE_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<CreateSolutionMinorVersionMutation> = await this.api.graphql(
                this.gqlOperation(
                    createSolutionMinorVersion,
                    { input: solutionMinorVersion },
                    authToken
                )
            ) as GraphQLResult<CreateSolutionMinorVersionMutation>;
            return result.data?.createSolutionMinorVersion as SolutionMinorVersion;
        } catch (error) {
            this.logger.error(
                `Failed to create SolutionMinorVersion record with id=${solutionMinorVersion.id}`,
                error,
                [GraphQLSolutionMinorVersionRecordDAO.CREATE_FAILURE_METRIC_NAME]
            );
            throw new CreateSolutionMinorVersionRecordError(
                `Failed to create SolutionMinorVersion record with id=${solutionMinorVersion.id}`,
                { cause: error as Error }
            );
        }
    }

    public async getBySolutionIdAndMinorVersion(
        solutionId: string,
        minorVersion: number
    ): Promise<SolutionMinorVersion> {
        try {
            this.logger.info(
                `Attempting to get SolutionMinorVersion record with solutionId=${solutionId} and minorVersion=${minorVersion}`,
                undefined,
                [GraphQLSolutionMinorVersionRecordDAO.GET_BY_ID_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const result: GraphQLResult<ListSolutionMinorVersionsBySolutionByMinorVersionQuery> = await this.api.graphql(
                this.gqlOperation(
                    listSolutionMinorVersionsBySolutionByMinorVersion,
                    {
                        solutionId: solutionId,
                        minorVersion: { "eq": minorVersion }
                    },
                    authToken
                )
            ) as GraphQLResult<ListSolutionMinorVersionsBySolutionByMinorVersionQuery>;

            return result.data?.listSolutionMinorVersionsBySolutionByMinorVersion?.items[0] as SolutionMinorVersion;
        } catch (error) {
            this.logger.error(
                `Failed to get SolutionMinorVersion record with solutionId=${solutionId} and minorVersion=${minorVersion}`,
                error,
                [GraphQLSolutionMinorVersionRecordDAO.GET_BY_ID_FAILURE_METRIC_NAME]
            );
            throw new GetSolutionMinorVersionRecordError(
                `Failed to get SolutionMinorVersion record with solutionId=${solutionId} and minorVersion=${minorVersion}`,
                { cause: error as Error }
            );
        }
    }

    public async deleteByIdAndVersion(
        id: string,
        version: number
    ): Promise<void> {
        try {
            this.logger.info(
                `Attempting to delete SolutionMinorVersion record with id=${id} and version=${version}`,
                undefined,
                [GraphQLSolutionMinorVersionRecordDAO.DELETE_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: DeleteSolutionMinorVersionMutationVariables = {
                input: {
                    id: id,
                    _version: version
                }
            };
            await this.api.graphql(
                this.gqlOperation(
                    deleteSolutionMinorVersion,
                    variables,
                    authToken
                )
            );
        } catch (error) {
            this.logger.error(
                `Failed to delete SolutionMinorVersion record with id=${id} and version=${version}`,
                error,
                [GraphQLSolutionMinorVersionRecordDAO.DELETE_FAILURE_METRIC_NAME]
            );
            throw new DeleteSolutionMinorVersionRecordError(
                `Failed to delete SolutionMinorVersion record with id=${id} and version=${version}`,
                { cause: error as Error }
            );
        }
    }

    public async listBySolutionId(solutionId: string): Promise<Array<SolutionMinorVersion>> {
        try {
            this.logger.info(
                `Attempting to list SolutionMinorVersion record with solutionId=${solutionId}`,
                undefined,
                [GraphQLSolutionMinorVersionRecordDAO.LIST_BY_SOLUTION_ID_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: ListSolutionMinorVersionsBySolutionByMinorVersionQueryVariables = {
                solutionId: solutionId,
            };

            const result: GraphQLResult<ListSolutionMinorVersionsBySolutionByMinorVersionQuery> = await this.api.graphql(
                this.gqlOperation(
                    listSolutionMinorVersionsBySolutionByMinorVersion,
                    variables,
                    authToken
                )
            ) as GraphQLResult<ListSolutionMinorVersionsBySolutionByMinorVersionQuery>;
            return result.data?.listSolutionMinorVersionsBySolutionByMinorVersion?.items as Array<SolutionMinorVersion> ?? [];
        } catch (error) {
            this.logger.error(
                `Failed to list SolutionMinorVersion record with solutionId=${solutionId}`,
                error,
                [GraphQLSolutionMinorVersionRecordDAO.LIST_BY_SOLUTION_ID_FAILURE_METRIC_NAME]
            );
            throw new GetSolutionMinorVersionRecordError(
                `Failed to list SolutionMinorVersion record with solutionId=${solutionId}`,
                { cause: error as Error }
            );
        }
    }
}
