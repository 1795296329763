import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader
} from "@mui/material";
import {
    ProviderContext,
    useSnackbar
} from "notistack";

import { Alert } from "@mui/material";
import { DataStore } from "aws-amplify";
import { SyncReconciliationWorkerFactory } from "../../lib/datastore/reconciliation/SyncReconciliationWorkerFactory";
import Task from "../../lib/util/concurrency/Task";
import { useState } from "react";

export const ViewSettings = () => {
    /* Internal page state */
    const [isRestartDataStoreButtonDisabled, setRestartDataStoreButtonDisabled] = useState<boolean>(false);
    const [isSyncLocalRecordsButtonDisabled, setSyncLocalRecordsButtonDisabled] = useState<boolean>(false);

    /* Dependencies */
    const snackbar: ProviderContext = useSnackbar();

    const handleRestartDataStore = async (): Promise<void> => {
        setRestartDataStoreButtonDisabled(true);

        try {
            snackbar.enqueueSnackbar("Attempting to stop the DataStore", {
                variant: "info"
            });
            await DataStore.stop();
            snackbar.enqueueSnackbar("DataStore stopped", {
                variant: "success"
            });
        } catch (error) {
            snackbar.enqueueSnackbar("Failed to stop the DataStore", {
                variant: "error"
            });
            setRestartDataStoreButtonDisabled(false);
            return;
        }

        try {
            snackbar.enqueueSnackbar("Attempting to start the DataStore", {
                variant: "info"
            });
            await DataStore.start();
            snackbar.enqueueSnackbar("DataStore started", {
                variant: "success"
            });
        } catch (error) {
            snackbar.enqueueSnackbar("Failed to start the DataStore", {
                variant: "error"
            });
        }
        setRestartDataStoreButtonDisabled(false);
    };

    const syncLocalRecords = async (): Promise<void> => {
        setSyncLocalRecordsButtonDisabled(true);
        const worker: Task<void> = SyncReconciliationWorkerFactory.getInstance();
        await worker.run();
        setSyncLocalRecordsButtonDisabled(false);
    };

    return (
        <Box p={2} width={600} margin="auto">
            <Card>
                <CardHeader title="Experimental Settings" />
                <CardContent>
                    <Alert severity="warning">
                        These experimental features are added by developers to troubleshoot the application.
                        By using these features, you accept that you understand these are not intended for regular
                        use and that they may break the application in unexpected ways.
                    </Alert>
                    <Box mt={2}>
                        <Button
                            variant="outlined"
                            disabled={isRestartDataStoreButtonDisabled}
                            onClick={handleRestartDataStore}
                        >
                            Restart DataStore
                        </Button>
                    </Box>
                    <Box mt={2}>
                        <Button
                            variant="outlined"
                            disabled={isSyncLocalRecordsButtonDisabled}
                            onClick={syncLocalRecords}
                        >
                            Sync Local Records
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};
