import {
    Box,
    Typography
} from "@mui/material";
import {
    isSolutionSoleSourceSelectorFamily,
    issueByIssueIdentifierSelectorFamily
} from "../../lib/design/document/state/DocumentState";

import { ContextAwareIdentifier } from "../../lib/design/document/ContextAwareIdentifier";
import { ExpandableDisplayText } from "../misc/ExpandableDisplayText";
import { Issue } from "../../models";
import { ModelType } from "../../lib/design/document/ModelType";
import NumberStringConverter from "../../lib/util/NumberStringConverter";
import { StateContext } from "../../lib/design/document/state/StateContext";
import { proposalItemPriceTotalByIssueIdentifierSelectorFamily } from "../../lib/design/bidding/state/v2/ProposalItemStates";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";

type IssueSummaryProps = {
    readonly issueId: string;
    readonly stateContext: StateContext;
    readonly index: number;
};

export const IssueSummary = (props: IssueSummaryProps) => {
    const { issueId, stateContext, index } = props;
    const issueIdentifier = useMemo<ContextAwareIdentifier>(() => {
        return new ContextAwareIdentifier(issueId, stateContext, ModelType.ISSUE);
    }, [issueId, stateContext]);
    const issue = useRecoilValue<Issue | null>(
        issueByIssueIdentifierSelectorFamily(issueIdentifier)
    );
    const issuePriceTotal = useRecoilValue(proposalItemPriceTotalByIssueIdentifierSelectorFamily(issueIdentifier));
    const isSoleSource = useRecoilValue<boolean>(isSolutionSoleSourceSelectorFamily(stateContext));

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center" flex={3} minWidth={0}> {/* minWidth="0px" is needed to prevent overflow */}
                <Box width="3ch" mr={2} textAlign="end">
                    <Typography fontSize="0.625rem" color="text.secondary" sx={{ verticalAlign: "middle" }}>
                        {index + 1}
                    </Typography>
                </Box>
                <ExpandableDisplayText
                    description={issue?.name || "N/A"}
                    linesToDisplayWhenCollapsed={1}
                    typographyProps={{
                        fontSize: "1rem"
                    }}
                />
            </Box>
            {isSoleSource &&
                <Box display="flex" alignItems="center" mr={1}>
                    <Typography fontSize="0.625rem" color="text.secondary">
                        $&nbsp;
                    </Typography>
                    <Typography fontSize="1rem" lineHeight="125%">
                        {NumberStringConverter.numberToString(issuePriceTotal, 0, true)}
                    </Typography>
                </Box>
            }
        </Box>
    );
};
