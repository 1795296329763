import GraphQLAPI, {
    GraphQLResult,
    graphqlOperation
} from "@aws-amplify/api-graphql";
import {
    ListResourceAggregateUserPermissionsOutput,
    ListResourceAggregateUserPermissionsQuery,
    ListResourceAggregateUserPermissionsQueryVariables,
    PermissionResourceType
} from "../../../API";

import ClientLogger from "../../logging/ClientLogger";
import { IDTokenSupplier } from "../../auth/IDTokenSupplier";
import { ListResourceAggregateUserPermissionError } from "../errors/ListResourceAggregateUserPermissionError";
import { ResourceAggregateUserPermissionDAO } from "./ResourceAggregateUserPermissionDAO";
import { listResourceAggregateUserPermissions } from "../../../graphql/queries";

export class GraphQLResourceAggregateUserPermissionDAO implements ResourceAggregateUserPermissionDAO {
    private static readonly LIST_ATTEMPT_METRIC_NAME = "GraphQLResourceAggregateUserPermissionDAO.List.Attempt";
    private static readonly LIST_FAILURE_METRIC_NAME = "GraphQLResourceAggregateUserPermissionDAO.List.Failure";

    private readonly logger: ClientLogger;
    private readonly idTokenSupplier: IDTokenSupplier;

    constructor(
        logger: ClientLogger,
        idTokenSupplier: IDTokenSupplier
    ) {
        this.logger = logger;
        this.idTokenSupplier = idTokenSupplier;
    }

    public async listByResource(
        resourceId: string,
        resourceType: PermissionResourceType,
        nextToken?: string
    ): Promise<ListResourceAggregateUserPermissionsOutput> {
        try {
            this.logger.info(
                "Attempting to list resource aggregate user permissions.",
                undefined,
                [GraphQLResourceAggregateUserPermissionDAO.LIST_ATTEMPT_METRIC_NAME]
            );
            const authToken: string = await this.idTokenSupplier.get();
            const variables: ListResourceAggregateUserPermissionsQueryVariables = {
                resourceId: resourceId,
                resourceType: resourceType,
                nextToken: nextToken
            };
            const result = await GraphQLAPI.graphql(
                graphqlOperation(
                    listResourceAggregateUserPermissions,
                    variables,
                    authToken
                )
            ) as GraphQLResult<ListResourceAggregateUserPermissionsQuery>;
            return result.data?.listResourceAggregateUserPermissions as ListResourceAggregateUserPermissionsOutput;
        } catch (error) {
            const errorMessage: string = `Unable to list resource aggregate user permissions: ${(error as Error).message}`;
            this.logger.error(
                errorMessage,
                error,
                [GraphQLResourceAggregateUserPermissionDAO.LIST_FAILURE_METRIC_NAME]
            );
            throw new ListResourceAggregateUserPermissionError(errorMessage, error as Error);
        }
    }
}