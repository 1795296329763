import * as yup from "yup";

import {
    Dispatch,
    FormEvent,
    SetStateAction,
    useState
} from "react";

import Alert from '@mui/material/Alert';
import { AlertMessage } from '../../lib/util/AlertMessage';
import { Auth } from "aws-amplify";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LoadingButton from "@mui/lab/LoadingButton";
import { ResetPasswordForm } from './ResetPasswordForm';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from "formik";

const REQUIRED_FIELD_TEXT = "Field cannot be empty";

const forgotPasswordFormSchema = yup.object({
    email: yup.string().email().required(REQUIRED_FIELD_TEXT)
});

type ForgotPasswordFormData = yup.InferType<typeof forgotPasswordFormSchema>;

type ForgotPasswordFormProps = {
    onBackToSignInPage: () => void;
};
export const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {

    const { onBackToSignInPage } = props;
    const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
    const [isProcessingForgotPassword, setIsProcessingForgotPassword] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);

    const forgotPassword = async (data: ForgotPasswordFormData) => {
        try {
            setIsProcessingForgotPassword(true);
            setAlertMessage(undefined);
            await Auth.forgotPassword(data.email);
            setEmail(data.email);
            setShowResetPassword(true);
        } catch (error) {
            setAlertMessage({
                severity: "error",
                message: (error as Error).message
            });
        } finally {
            setIsProcessingForgotPassword(false);
        }
    };

    const formik = useFormik<ForgotPasswordFormData>({
        initialValues: {
            email: ''
        },
        validationSchema: forgotPasswordFormSchema,
        onSubmit: forgotPassword
    });

    return (
        showResetPassword ?
            <ResetPasswordForm email={email!} /> :
            <Container component="main" maxWidth="xs">
                <Box
                    mt={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4" fontWeight="500">
                                    Reset your password
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Enter your email"
                                    name="email"
                                    autoComplete="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            {alertMessage &&
                                <Grid item xs={12}>
                                    <Alert severity={alertMessage.severity}> {alertMessage.message}</Alert>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <LoadingButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    loading={isProcessingForgotPassword}
                                    startIcon={<></>}
                                    loadingPosition="start"
                                >
                                    Send Code
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={onBackToSignInPage}
                                >
                                    Back to Sign In
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
    );
};