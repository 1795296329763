import { ATTACHMENT_UPLOAD_STATUS_REMOVE_EVENT } from "../AttachmentUploadConstant";
import { AttachmentUploadStatus } from "./AttachmentUploadStatus";
import HubEventHandler from "../../../event/HubEventHandler";
import { HubPayload } from "@aws-amplify/core";
import { SetterOrUpdater } from "recoil";

export default class AttachmentUploadStatusRemoveEventHandler implements HubEventHandler {
    private readonly delegate: HubEventHandler;
    private readonly setIncompleteAttachmentIdToAttachmentUploadStatus: SetterOrUpdater<Map<string, AttachmentUploadStatus>>;

    constructor(
        delegate: HubEventHandler,
        setIncompleteAttachmentIdToAttachmentUploadStatus: SetterOrUpdater<Map<string, AttachmentUploadStatus>>,
    ) {
        this.delegate = delegate;
        this.setIncompleteAttachmentIdToAttachmentUploadStatus = setIncompleteAttachmentIdToAttachmentUploadStatus;
    }

    public async handle(payload: HubPayload): Promise<void> {
        if (payload.event === ATTACHMENT_UPLOAD_STATUS_REMOVE_EVENT && payload.data) {
            this.setIncompleteAttachmentIdToAttachmentUploadStatus(original => {
                const newState = new Map<string, AttachmentUploadStatus>(original);
                newState.delete(payload.data.attachmentId);
                return newState;
            });
        }
        this.delegate.handle(payload);
    }

}