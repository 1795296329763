import {
    Auth,
    DataStore,
    Predicates,
    SortDirection
} from 'aws-amplify';
import {
    Box,
    Button,
    Typography
} from "@mui/material";

import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import { BLACK_OUTLINED_BUTTON } from "../../components/general/button/ButtonStyles";
import ClientLoggerFactory from '../../lib/logging/ClientLoggerFactory';
import { Link } from "react-router-dom";
import LoggerCommonTags from '../../lib/logging/LoggerCommonTags';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import { Property } from "../../models";
import { StartPropertiesChildrenSyncWrapper } from '../../components/sync/StartPropertiesChildrenSyncWrapper';
import { useEffect } from "react";

const Home = () => {
    const COMPONENT_NAME = "Home";
    const logger = ClientLoggerFactory.getClientLogger(COMPONENT_NAME);

    useEffect(() => {
        fetchProperties();
    }, [])

    async function fetchProperties() {
        try {
            await DataStore.query(Property, Predicates.ALL, {
                sort: s => s.createdAt(SortDirection.DESCENDING)
            });
        } catch (error) {
            logger.error(
                "Failed to retrieve user properties",
                error,
                [LoggerCommonTags.PROPERTY_LIST_RETRIEVAL_FAILURE]
            );
        }
    }

    return (
        <StartPropertiesChildrenSyncWrapper>
            <Box
                maxWidth="600px"
                display="flex"
                flexDirection="column"
                gap={3}
                m="auto"
                sx={{
                    mt: {
                        xs: 2,
                        sm: 5
                    }
                }}
            >
                <Box data-cy="homePage-greeting" textAlign="center">
                    <Typography
                        variant="h5"
                        component="span"
                        sx={{
                            fontSize: {
                                xs: "1.25rem",
                                sm: "1.5rem"
                            }
                        }}
                    >
                        Hello,
                    </Typography>
                    <Typography
                        variant="h5"
                        component="span"
                        color="primary"
                        sx={{
                            fontSize: {
                                xs: "1.25rem",
                                sm: "1.5rem"
                            }
                        }}
                    >
                        {` ${Auth.user?.attributes.email}`}
                    </Typography>
                </Box>
                <Box
                    display="grid"
                    width="100%"
                    maxWidth="sm"
                    m="auto"
                    sx={{
                        gridTemplateColumns: {
                            xs: "1fr",
                            sm: "1fr 1fr"
                        },
                        gap: {
                            xs: 2,
                            sm: 5
                        }
                    }}
                    px={2}
                >
                    <Box display="flex" justifyContent="center">
                        <Button
                            component={Link}
                            to={{
                                pathname: "/properties",
                                state: { referralComponent: COMPONENT_NAME }
                            }}
                            variant="outlined"
                            color="primary"
                            data-cy="view-properties-button"
                            startIcon={<MapsHomeWorkOutlinedIcon color="primary" />}
                            sx={{
                                width: {
                                    sm: "100%"
                                },
                                ...BLACK_OUTLINED_BUTTON
                            }}
                        >
                            View Properties
                        </Button>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Button
                            component={Link}
                            to="/properties/create"
                            variant="outlined"
                            color="primary"
                            data-cy="create-property-button"
                            startIcon={<AddBusinessOutlinedIcon color="primary" />}
                            sx={{
                                width: {
                                    sm: "100%"
                                },
                                ...BLACK_OUTLINED_BUTTON
                            }}
                        >
                            Create Property
                        </Button>
                    </Box>
                </Box>
            </Box>
        </StartPropertiesChildrenSyncWrapper>
    );
}
export default Home;
