import {
    API,
    graphqlOperation
} from "aws-amplify";

import { AuthIDTokenSupplierFactory } from "../../auth/AuthIDTokenSupplierFactory";
import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import GraphQLOrganizationDAO from "./GraphQLOrganizationDAO";
import OrganizationDAO from "../OrganizationDAO";
import SearchByLegalNameOperation from "../SearchByLegalNameOperation";

export default class GraphQLOrganizationDAOFactory {
    private static readonly INSTANCE: OrganizationDAO & SearchByLegalNameOperation = new GraphQLOrganizationDAO(
        API,
        graphqlOperation,
        ClientLoggerFactory.getClientLogger("GraphQLOrganizationDAO"),
        AuthIDTokenSupplierFactory.getInstance()
    );
    public static getInstance() {
        return this.INSTANCE;
    }
}
