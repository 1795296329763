import {
    useEffect,
    useRef,
    useState
} from "react";

import _ from "lodash";

export const useOnKeysUp = (keys: Set<string>, callback: () => void, options?: { enabled?: boolean; }) => {
    const { enabled } = options || { enabled: true };
    const keysRef = useRef(keys);
    const keysBeingPressed = useRef<Set<string>>(new Set());
    const [memoizedKeys, setMemoizedKeys] = useState(new Set([...keys].map(k => k.toLowerCase())));

    useEffect(() => {
        if (_.isEqual(keys, keysRef.current)) {
            return;
        }
        setMemoizedKeys(new Set([...keys].map(k => k.toLowerCase())));
    }, [keys])

    useEffect(() => {
        if (enabled) {
            const onKeyDown = (e: KeyboardEvent) => {
                const incomingKey = e.key.toLowerCase();
                if (memoizedKeys.has(incomingKey) && !keysBeingPressed.current.has(incomingKey)) {
                    keysBeingPressed.current.add(incomingKey);
                }
            };
            const onKeyUp = (e: KeyboardEvent) => {
                const incomingKey = e.key.toLowerCase();
                if (memoizedKeys.has(incomingKey) && keysBeingPressed.current.has(incomingKey)) {
                    if (keysBeingPressed.current.size === memoizedKeys.size) {
                        callback();
                    }
                    keysBeingPressed.current.delete(incomingKey);
                }
            };
            document.addEventListener('keydown', onKeyDown);
            document.addEventListener('keyup', onKeyUp);
            return () => {
                document.removeEventListener('keydown', onKeyDown);
                document.removeEventListener('keyup', onKeyUp);
                keysBeingPressed.current.clear();
            };
        }
    }, [memoizedKeys, enabled]);
};
