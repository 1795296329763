import RateLimiter from "./RateLimiter";
import TokenBucket from "./TokenBucket";
import TokenBucketRefiller from "./TokenBucketRefiller";

export default class TokenBucketBasedRateLimiter implements RateLimiter {
    private readonly tokenBucket: TokenBucket;
    private readonly tokenBucketRefiller: TokenBucketRefiller;

    constructor(
        tokenBucket: TokenBucket,
        tokenBucketRefiller: TokenBucketRefiller
    ) {
        this.tokenBucket = tokenBucket;
        this.tokenBucketRefiller = tokenBucketRefiller;
    }

    public async acquire(): Promise<void> {
        await this.tokenBucket.acquire();
        this.tokenBucketRefiller.scheduleNextRefill();
    }

    public async tryAcquire(): Promise<boolean> {
        const result = await this.tokenBucket.tryAcquire();
        this.tokenBucketRefiller.scheduleNextRefill();
        return result;
    }

    public async reportSuccess(): Promise<void> {
        return this.tokenBucketRefiller.reportSuccess();
    }

    public async reportFailure(): Promise<void> {
        return this.tokenBucketRefiller.reportFailure();
    }
}
