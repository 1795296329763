import {
    DefaultWorkTypePricing,
    EntityWorkTypeAssociation,
    WorkTypeAssociationScopeEntity
} from "../../../../models";

import AssociateWorkTypeWithEntityError from "./error/AssociateWorkTypeWithEntityError";
import AssociateWorkTypeWithEntityHandler from "./AssociateWorkTypeWithEntityHandler";
import AssociateWorkTypeWithEntityResponse from "./AssociateWorkTypeWithEntityResponse";
import ClientLogger from "../../../logging/ClientLogger";
import DefaultWorkTypePricingDAO from "../../defaultPricing/DefaultWorkTypePricingDAO";
import EntityWorkTypeAssociationDAO from '../../relationship/EntityWorkTypeAssociationDAO';
import WorkTypeDTO from "../../DTO/WorkTypeDTO";
import WorkTypePricingDAO from "../../../design/worktype/pricing/WorkTypePricingDAO";
import WorkTypePricingDTO from "../../../design/worktype/pricing/DTO/WorkTypePricingDTO";

export default class AssociateWorkTypeWithEntityHandlerImpl implements AssociateWorkTypeWithEntityHandler {
    private static readonly HANDLE_ATTEMPT_METRIC_NAME = "AssociateWorkTypeWithEntityHandlerImpl.Handle.Attempt";
    private static readonly HANDLE_SUCCESS_METRIC_NAME = "AssociateWorkTypeWithEntityHandlerImpl.Handle.Success";
    private static readonly HANDLE_FAILURE_METRIC_NAME = "AssociateWorkTypeWithEntityHandlerImpl.Handle.Failure";

    private readonly entityWorkTypeAssociationDAO: EntityWorkTypeAssociationDAO;
    private readonly workTypePricingDAO: WorkTypePricingDAO;
    private readonly defaultWorkTypePricingDAO: DefaultWorkTypePricingDAO;
    private readonly logger: ClientLogger;

    constructor(
        entityWorkTypeAssociationDAO: EntityWorkTypeAssociationDAO,
        workTypePricingDAO: WorkTypePricingDAO,
        defaultWorkTypePricingDAO: DefaultWorkTypePricingDAO,
        logger: ClientLogger
    ) {
        this.entityWorkTypeAssociationDAO = entityWorkTypeAssociationDAO;
        this.workTypePricingDAO = workTypePricingDAO;
        this.defaultWorkTypePricingDAO = defaultWorkTypePricingDAO;
        this.logger = logger;
    }

    public async handle(
        entityId: string,
        entityType: WorkTypeAssociationScopeEntity,
        workType: WorkTypeDTO,
        inheritDefaultPricing: boolean = true
    ): Promise<AssociateWorkTypeWithEntityResponse> {
        try {
            this.logger.info(
                `Associating workType ${workType.id} with entity ${entityType} id: ${entityId}`,
                undefined,
                [AssociateWorkTypeWithEntityHandlerImpl.HANDLE_ATTEMPT_METRIC_NAME]
            );
            const entityWorkTypeAssociation: EntityWorkTypeAssociation = await this.entityWorkTypeAssociationDAO.create(entityId, entityType, workType.groupId, null);
            let defaultWorkTypePricing: DefaultWorkTypePricing | undefined;
            if (inheritDefaultPricing) {
                try {
                    defaultWorkTypePricing = await this.defaultWorkTypePricingDAO.getById(workType.groupId);
                } catch (error) {
                    // Should not block the association if default pricing is not found
                    this.logger.warn(
                        `Error occurred while getting default workType pricing for workTypeGroupId: ${workType.groupId}`,
                        error,
                        [AssociateWorkTypeWithEntityHandlerImpl.HANDLE_FAILURE_METRIC_NAME]
                    );
                }
            }
            const WorkTypePricingToSet: WorkTypePricingDTO = {
                workTypeGroupId: workType.groupId,
                entityId: entityId,
                unitCost: defaultWorkTypePricing?.unitCost ?? 0,
                unitPrice: defaultWorkTypePricing?.unitPrice ?? 0
            };
            const workTypePricingDto: WorkTypePricingDTO = await this.workTypePricingDAO.create(WorkTypePricingToSet);
            this.logger.info(
                `Successfully associated workType ${workType.id} with entity ${entityType} id: ${entityId}`,
                undefined,
                [AssociateWorkTypeWithEntityHandlerImpl.HANDLE_SUCCESS_METRIC_NAME]
            );
            return {
                entityWorkTypeAssociation: entityWorkTypeAssociation,
                workTypePricingDto: workTypePricingDto
            } as AssociateWorkTypeWithEntityResponse;
        } catch (error) {
            this.logger.error(
                `Error occurred while associating workType ${workType.id} with entity ${entityType} id: ${entityId}`,
                error,
                [AssociateWorkTypeWithEntityHandlerImpl.HANDLE_FAILURE_METRIC_NAME]
            );
            throw new AssociateWorkTypeWithEntityError(
                `Error occurred while associating workTypes with entity ${entityType} id: ${entityId}`,
                error as Error
            );
        }
    }
}
