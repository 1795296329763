import ClientLog from "./ClientLog";
import ClientLogDAO from "./ClientLogDAO";

export default class NoOpClientLogDAO implements ClientLogDAO {
    public async save(
        clientLog: ClientLog
    ): Promise<void> {
        // No-op
    }
}
