import {
    LAST_LOGGED_IN_USER_ID_LOCALFORAGE_KEY,
    LOGGED_IN_USER_ID_LOCALFORAGE_TABLE
} from "./LastLoggedInUserConstants";

import ClientLoggerFactory from "../../logging/ClientLoggerFactory";
import { LocalForageLoggedInUserIdDAO } from "./LocalForageLoggedInUserIdDAO";
import localforage from "localforage";

export class LocalForageLastLoggedInUserIdDAOFactory {
    private static INSTANCE: LocalForageLoggedInUserIdDAO = new LocalForageLoggedInUserIdDAO(
        ClientLoggerFactory.getClientLogger("LocalForageLastLoggedInUserIdDAO"),
        localforage.createInstance({name: LOGGED_IN_USER_ID_LOCALFORAGE_TABLE}),
        LAST_LOGGED_IN_USER_ID_LOCALFORAGE_KEY
    );

    public static getInstance(): LocalForageLoggedInUserIdDAO {
        return LocalForageLastLoggedInUserIdDAOFactory.INSTANCE;
    }
}