import {
    Box,
    Button,
    LinearProgress,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {
    availableAudioInputDevicesSelector,
    defaultAudioInputDeviceAtom,
    defaultAudioOutputDeviceIdAtom
} from "../state/ChimeStates";
import {
    useEffect,
    useRef,
    useState
} from "react";
import {
    useRecoilState,
    useRecoilValue
} from "recoil";

import { AudioInputDevice } from "amazon-chime-sdk-js";
import { useAudioVolumeMonitor } from "../../../lib/util/hooks/useAudioVolumeMonitor";

export const AudioInputDeviceTest = () => {
    const selectedAudioOutputDeviceId = useRecoilValue(defaultAudioOutputDeviceIdAtom);
    const availableAudioInputDevices = useRecoilValue(availableAudioInputDevicesSelector);
    const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useRecoilState<AudioInputDevice | undefined>(defaultAudioInputDeviceAtom);
    const { monitorAudio, volume } = useAudioVolumeMonitor();

    const [recordingUrl, setRecordingUrl] = useState<string | null>(null);
    const recordingAudioRef = useRef<HTMLAudioElement | null>(null);

    const [isRecording, setIsRecording] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const stopRecordingRef = useRef<() => void>();

    useEffect(() => {
        return () => {
            stopRecordingRef.current?.();
        };
    }, []);

    const startRecording = async () => {
        const audioInputStream = await navigator.mediaDevices.getUserMedia({
            audio: selectedAudioInputDevice ? { deviceId: selectedAudioInputDevice as string } : true
        });

        // Monitor the audio input volume
        const cleanUpMonitoring = await monitorAudio(audioInputStream);

        // Start recording
        const mediaRecorder = new MediaRecorder(audioInputStream);
        mediaRecorder.ondataavailable = (event) => {
            const audioBlob = new Blob([event.data], { type: 'audio/mp3' });
            const audioUrl = window.URL.createObjectURL(audioBlob);
            setRecordingUrl(audioUrl);
        };
        setIsRecording(true);
        mediaRecorder.onstop = () => {
            setIsRecording(false);
        };
        mediaRecorder.start();
        const stopRecording = () => {
            mediaRecorder.stop();
            cleanUpMonitoring();
            audioInputStream.getTracks().forEach((track) => track.stop());
        };
        // Stop recording after 5 seconds
        const timeout = setTimeout(stopRecording, 5000);
        stopRecordingRef.current = () => {
            clearTimeout(timeout);
            stopRecording();
        };
    };

    const stopRecording = () => {
        stopRecordingRef.current?.();
    };

    const onPlayBtnClicked = async () => {
        if (!recordingUrl) {
            return;
        }
        if (isPlaying) {
            recordingAudioRef.current!.pause();
            setIsPlaying(false);
            return;
        }
        recordingAudioRef.current = new Audio(recordingUrl);
        const cleanUpMonitoring = await monitorAudio(recordingAudioRef.current!, true, selectedAudioOutputDeviceId);
        recordingAudioRef.current!.onended = () => {
            cleanUpMonitoring();
            setIsPlaying(false);
        };
        setIsPlaying(true);
        recordingAudioRef.current!.play();
    };

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={1} alignItems="center">
                <Select
                    value={selectedAudioInputDevice ?? "default"}
                    onChange={(e) => {
                        setSelectedAudioInputDevice(e.target.value as string);
                    }}
                    size="small"
                    fullWidth
                    disabled={isRecording}
                    sx={{ overflow: "auto" }}
                >
                    {availableAudioInputDevices.length > 0 ? (
                        availableAudioInputDevices.map((device) => (
                            <MenuItem key={device.deviceId} value={device.deviceId}>{device.label}</MenuItem>
                        ))
                    ) : (
                        <MenuItem value="default">Default</MenuItem>
                    )}
                </Select>
            </Box>
            <Box display="flex" gap={1} alignItems="center" justifyContent="space-around">
                <Button
                    size="small"
                    variant="outlined"
                    onClick={isRecording ? stopRecording : startRecording}
                    disabled={isPlaying}
                    fullWidth
                >
                    {isRecording ? 'Recording...' : 'Test Microphone'}
                </Button>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={onPlayBtnClicked}
                    disabled={!recordingUrl || isRecording}
                    fullWidth
                >
                    {isPlaying ? 'Playing...' : 'Play Recording'}
                </Button>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
                <Typography>Input Level: </Typography>
                <Box flex={1}>
                    <LinearProgress variant="determinate" value={volume * 100} />
                </Box>
            </Box>
        </Box>
    );
};