import {
    Box,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    ImageList,
    ImageListItem,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {
    ProviderContext,
    useSnackbar
} from 'notistack';
import {
    useEffect,
    useState
} from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import LocalForageAttachmentContentDAOFactory from '../../lib/attachment/content/localforage/LocalForageAttachmentContentDAOFactory';
import { PaginationBar } from '../../components/general/pagination/PaginationBar';
import globalStyle from '../../assets/style/globalStyle';
import { useFetchAttachmentImageUrl } from '../../lib/attachment/hooks/useFetchAttachmentImageUrl';
import { usePageDefaultStyle } from '../../assets/style/usePageDefaultStyle';

const imageContentDAO = LocalForageAttachmentContentDAOFactory.getInstance();
const BACKUP_IMAGE_PAGINATION_KEY_GROUP_ID = 'backup_image_pagination_key_group_id';
const BACKUP_IMAGE_PAGE_SIZE = 9;

type ImageUrlKvp = {
    key: string;
    url: string;
};

const downloadImage = (url: string, title: string) => {
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = title;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        });
};

export const RecoverImageBackups = () => {
    /* Internal page state */
    const [imageBackupKeys, setImageBackupKeys] = useState<Array<string>>([]);
    const [imageKeysToDisplay, setImageKeysToDisplay] = useState<Array<string>>([]);
    const [imagesToDisplay, setImagesToDisplay] = useState<Array<{ key: string, url: string }>>([]);

    /* Dependencies */
    const snackbar: ProviderContext = useSnackbar();
    const globalClasses = globalStyle();
    const { pageDefaultStyle } = usePageDefaultStyle();
    const { fetchAttachmentImageUrlByAttachmentKey } = useFetchAttachmentImageUrl();
    const theme = useTheme();
    const isScreenSizeXs = useMediaQuery(theme.breakpoints.down("sm"));

    /* Calculated State */
    const imageListCols = isScreenSizeXs ? 1 : 3;

    useEffect(() => {
        fetchImageBackupKeys();
    }, []);

    useEffect(() => {
        fetchImageURLsForKeys(imageKeysToDisplay);
    }, [imageKeysToDisplay]);

    const fetchImageBackupKeys = async () => {
        try {
            setImageBackupKeys(await imageContentDAO.listAttachmentContentKeys());
        } catch (error) {
            snackbar.enqueueSnackbar('Failed to fetch backups', { variant: 'error' });
        }
    };

    const fetchImageURLsForKeys = async (keys: Array<string>) => {
        const images: Array<Promise<ImageUrlKvp>> = imageKeysToDisplay.map(async (imageKey: string) => {
            return {
                key: imageKey,
                url: await fetchAttachmentImageUrlByAttachmentKey(imageKey)
            }
        });
        const imageKVPs: Array<ImageUrlKvp> = await Promise.all(images);
        setImagesToDisplay(imageKVPs);
    };

    return (
        <Box sx={pageDefaultStyle}>
            <Box className={globalClasses.managerLayout}>
                <Card>
                    <CardHeader title="Recover Images" />
                    <CardContent>
                        <PaginationBar
                            groupId={BACKUP_IMAGE_PAGINATION_KEY_GROUP_ID}
                            items={imageBackupKeys}
                            itemsPerPage={BACKUP_IMAGE_PAGE_SIZE}
                            setItemsToDisplay={(items: string[]) => {
                                setImageKeysToDisplay(items);
                            }}
                        />
                        <ImageList cols={imageListCols} gap={8}>
                            {imagesToDisplay.map((image: ImageUrlKvp) => (
                                <ImageListItem
                                    key={image.key}
                                    sx={{
                                        position: 'relative',
                                    }}
                                >
                                    <img
                                        src={image.url}
                                        alt={image.key}
                                        style={{
                                            height: 200,
                                            width: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: 8,
                                            right: 8,
                                        }}
                                    >
                                        <IconButton
                                            aria-label="download"
                                            color="primary"
                                            sx={{ background: 'white' }}
                                            onClick={(e) => {
                                                downloadImage(image.url, image.key);
                                            }}
                                        >
                                            <DownloadIcon />
                                        </IconButton>
                                    </Box>
                                </ImageListItem>
                            ))}
                        </ImageList>
                        <PaginationBar
                            groupId={BACKUP_IMAGE_PAGINATION_KEY_GROUP_ID}
                            items={imageBackupKeys}
                            itemsPerPage={BACKUP_IMAGE_PAGE_SIZE}
                            setItemsToDisplay={(items: string[]) => {
                                setImageKeysToDisplay(items);
                            }}
                        />
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};
