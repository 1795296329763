import {
    AudioOutputControl,
    ControlBar,
    ControlBarButton,
    VideoInputControl,
    useMeetingManager
} from "amazon-chime-sdk-component-library-react";

import { AttachmentParent } from "../../componentsV2/image/AttachmentParent";
import { Box } from "@mui/material";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { CustomAudioInputVFControl } from "./customized/CustomAudioInputVFControl";
import { EndMeetingControlBarButton } from "./EndMeetingControlBarButton";
import { ModelType } from "../../lib/design/document/ModelType";
import { PermissionResourceType } from "../../models";
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import RefreshIcon from '@mui/icons-material/Refresh';
import { idInFocusByModelTypeAtomFamily } from "../../lib/ui/InFocusRecoilStates";
import { useCaptureMeeting } from "./hooks/useCaptureMeeting";
import { useContentShareState } from 'amazon-chime-sdk-component-library-react';
import { useQueryClient } from "react-query";
import { useRecoilCallback } from "recoil";
import { useSnackbar } from "notistack";
import { useUploadImages } from "../../componentsV2/image/carouselComponent/useUploadImage";

type MeetingControlBarProps = {
    inspectionMode?: boolean;
};

export const MeetingControlBar = ({ inspectionMode = false }: MeetingControlBarProps) => {
    const meetingManager = useMeetingManager();
    const snackbar = useSnackbar();
    const queryClient = useQueryClient();
    const [uploadImages] = useUploadImages();
    const { captureMeeting: captureVideo } = useCaptureMeeting();
    const { isLocalUserSharing } = useContentShareState();

    const handleTakeSnapshot = useRecoilCallback(({ snapshot }) => async () => {
        if (!inspectionMode) {
            return;
        }
        const issueIdInFocus = await snapshot.getPromise(idInFocusByModelTypeAtomFamily(ModelType.ISSUE));
        const locationIdInFocus = await snapshot.getPromise(idInFocusByModelTypeAtomFamily(ModelType.LOCATION));
        if (!issueIdInFocus && !locationIdInFocus) {
            snackbar.enqueueSnackbar('No location or issue in focus', { variant: 'error' });
            return;
        }
        const attachmentParent: AttachmentParent = issueIdInFocus ?
            { id: issueIdInFocus, type: PermissionResourceType.ISSUE } : { id: locationIdInFocus!, type: PermissionResourceType.LOCATION };
        const blob: Blob | undefined = await captureVideo();
        if (!blob) {
            snackbar.enqueueSnackbar('Failed to take snapshot', { variant: 'error' });
            return;
        }
        await uploadImages([blob], { shouldResize: false }, attachmentParent);
        snackbar.enqueueSnackbar('Snapshot taken and uploaded', { variant: 'success' });
        queryClient.invalidateQueries(["attachments", attachmentParent]);
    }, [inspectionMode, snackbar, meetingManager]);

    const onRejoinButtonClick = async () => {
        const url = new URL(window.location.href);
        url.searchParams.set("rejoin", "true");
        window.location.href = url.toString();
    };

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: theme => theme.zIndex.appBar
            }}
        >
            <ControlBar
                layout="undocked-horizontal"
                showLabels
            >
                <CustomAudioInputVFControl />
                <VideoInputControl />
                <AudioOutputControl />
                {inspectionMode && <ControlBarButton icon={<CameraAltOutlinedIcon sx={{ p: 0.5 }} />} onClick={handleTakeSnapshot} label="Snapshot" />}
                {inspectionMode &&
                <>
                    {isLocalUserSharing ?
                        <ControlBarButton icon={<CancelPresentationIcon sx={{ p: 0.5 }} />} onClick={() => {
                            if (meetingManager.audioVideo) {
                                meetingManager.audioVideo?.stopContentShare();
                            }
                        }}
                            label="End"
                        /> :
                        <ControlBarButton icon={<PresentToAllIcon sx={{ p: 0.5 }} />} onClick={() => {
                            if (meetingManager.audioVideo) {
                                meetingManager.audioVideo?.stopContentShare();
                                meetingManager.audioVideo?.startContentShareFromScreenCapture();
                            }
                        }}
                            label="Screenshare"
                        />
                    }
                </>
                }
                {!inspectionMode && (
                    <ControlBarButton
                        icon={<RefreshIcon sx={{ p: 0.5 }} />}
                        label="Reload"
                        onClick={onRejoinButtonClick}
                    />
                )}
                <EndMeetingControlBarButton />
            </ControlBar>
        </Box>
    );
};