import { ResourceIngressCompletionChecker } from "./ResourceIngressCompletionChecker";
import { ResourcePermissionMetadata } from "./ResourcePermissionMetadata";
import { ResourcePermissionMetadataDAO } from "../dao/ResourcePermissionMetadataDAO";

export class GenericIngressCompletionChecker implements ResourceIngressCompletionChecker {
    private resourcePermissionMetadataDAO: ResourcePermissionMetadataDAO;

    constructor (
        resourcePermissionMetadataDAO: ResourcePermissionMetadataDAO
    ) {
        this.resourcePermissionMetadataDAO = resourcePermissionMetadataDAO;
    }

    public async hasResourceCompletedIngress(resourceId: string): Promise<boolean> {
        const resource: ResourcePermissionMetadata = await this.resourcePermissionMetadataDAO.getResourcePermissionMetadata(resourceId);
        const isIngressComplete: boolean = resource.canViewList != undefined;
        return isIngressComplete;
    }
}