import {
    Autocomplete,
    AutocompleteInputChangeReason,
    AutocompleteValue,
    Box,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    TextField,
    TextFieldProps,
    Typography
} from "@mui/material/";
import {
     ChevronLeftIcon,
     ChevronRightIcon
} from "../../components/icons";
import {
    Fragment,
    useCallback,
    useState
} from "react";

import { SearchResponse } from "../../lib/util/search/SearchResponse";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import { useQuery } from "react-query";

type SearchBoxOption<T> = T & {
    newCreated?: boolean;
    inputValue?: string;
};
interface SearchBoxProps<T, Multiple extends boolean | undefined> {
    readonly label: string;
    readonly typeName: string;
    readonly multiple?: Multiple;
    readonly freeSolo?: boolean;
    readonly onSelect: (option: AutocompleteValue<T, Multiple, undefined, undefined>) => void;
    readonly onClear: () => void;
    readonly search: (searchValue: string, nextToken: string | undefined, limit: number) => Promise<SearchResponse<T>>;
    readonly labelFields: Array<keyof T>;
    readonly optionsPerPage: number;
    readonly value: AutocompleteValue<T, Multiple, undefined, undefined>;
    readonly textFieldStyle?: TextFieldProps;
    readonly queryKeys?: Array<any>;
    readonly searchWithoutSearchTerm?: boolean;
    disabled?: boolean;
}

const useStyles = makeStyles({
    textField: {
        // Fix for bugged top margin when textField is in standard variant
        "& .MuiAutocomplete-endAdornment": {
            top: "auto"
        }
    }
});

export const SearchBox = <T extends { id: string; }, Multiple extends boolean | undefined = undefined>(props: SearchBoxProps<T, Multiple>) => {

    const { label, typeName, multiple, freeSolo, onSelect, onClear, search, labelFields, optionsPerPage, value, disabled, textFieldStyle, queryKeys = [], searchWithoutSearchTerm } = props;
    const [searchTerm, setSearchTerm] = useState<string>("");
    const debouncedSetSearchTerm = useCallback(_.debounce(setSearchTerm, 300), []);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [nextTokenList, setNextTokenList] = useState<Array<string | undefined>>([undefined]);
    const [numberOfPages, setNumberOfPages] = useState<number>(1);

    const classes = useStyles();

    const { data, isFetching, isFetched } = useQuery({
        queryKey: [label, searchTerm, nextTokenList[currentPage], ...queryKeys],
        queryFn: () => handleSearch(searchTerm, nextTokenList[currentPage]),
        enabled: searchWithoutSearchTerm || searchTerm.length > 0,
        staleTime: Infinity
    });

    async function handleSearch(
        searchTerm: string,
        nextToken?: string
    ): Promise<SearchResponse<T>> {
        const searchResponse: SearchResponse<T> = await search(searchTerm, nextToken, optionsPerPage);
        setNumberOfPages(Math.ceil(searchResponse.total / Math.max(optionsPerPage, searchResponse.objects.length)));
        setNextTokenList(nextTokens => {
            if (searchResponse.nextToken == null) {
                return nextTokens;
            }
            return [...nextTokens, searchResponse.nextToken];
        });
        return searchResponse;
    };

    const reset = () => {
        setSearchTerm("");
        setCurrentPage(0);
        setNumberOfPages(1);
        setNextTokenList([undefined]);
    };

    const onPrevPageClicked = () => {
        setCurrentPage(currentPage => Math.max(0, currentPage - 1));
    };

    const onNextPageClicked = () => {
        setCurrentPage(currentPage => Math.min(numberOfPages - 1, currentPage + 1));
    };

    const getDefaultOptionName = (option: SearchBoxOption<T>) => {
        return `${typeName} ${option.id}`;
    };

    return (
        <Autocomplete
            sx={{
                width: "100%"
            }}
            disabled={disabled}
            getOptionLabel={(option: SearchBoxOption<T> | string) => {
                if (typeof option === "string") {
                    return option;
                }
                // New value created by user case
                if (option.newCreated) {
                    return option.inputValue!;
                }
                for (const labelField of labelFields) {
                    if (option[labelField]) {
                        return option[labelField] as string;
                    }
                }
                return getDefaultOptionName(option);
            }}
            filterOptions={(options, params) => {
                if (!freeSolo) {
                    return options;
                }

                // Suggest the creation of a new value
                const { inputValue } = params;
                const isExisting = options.some((option) => {
                    if (typeof option === "string") {
                        return option === inputValue;
                    }
                    for (const labelField of labelFields) {
                        if (option[labelField] != undefined) {
                            return option[labelField] === inputValue;
                        }
                    }
                    return false;
                });
                if (inputValue !== '' && !isExisting) {
                    options.push({
                        inputValue,
                        [labelFields[0]]: `Create new ${typeName} "${inputValue}"`,
                        newCreated: true
                    } as SearchBoxOption<T>);
                }
                return options;
            }}
            options={data?.objects ?? []}
            autoComplete
            loading={isFetching}
            includeInputInList
            isOptionEqualToValue={(option: T, value: T) => option.id === value.id}
            value={value}
            onBlur={reset}
            multiple={multiple}
            limitTags={2}
            noOptionsText={isFetched ? "No results" : "Type to search"}
            onChange={(event, newValue: AutocompleteValue<SearchBoxOption<T>, Multiple, undefined, undefined>) => {
                if (newValue) {
                    if (typeof newValue !== "string" && (newValue as SearchBoxOption<T>).newCreated) {
                        onSelect({
                            [labelFields[0]]: (newValue as SearchBoxOption<T>).inputValue,
                            newCreated: true
                        } as any);
                    } else {
                        onSelect(newValue);
                    }
                    return;
                }
                if (value) {
                    onClear();
                    return;
                }
            }}
            clearOnBlur
            onInputChange={(event, newInputValue: string, reason: AutocompleteInputChangeReason) => {
                if (reason !== "input") {
                    return;
                }
                reset();
                debouncedSetSearchTerm(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    fullWidth
                    className={classes.textField}
                    {...textFieldStyle}
                />
            )}
            renderOption={(props, option: SearchBoxOption<T>) => {
                const isLastElement: boolean = option === data?.objects?.at(-1);
                const hasNextPage: boolean = currentPage < numberOfPages - 1;
                const hasPreviousPage: boolean = currentPage > 0;
                const labelDisplayName = () => {
                    for (const labelField of labelFields) {
                        if (option[labelField]) {
                            return option[labelField] as string;
                        }
                    }
                    return getDefaultOptionName(option);
                };
                return (
                    <Fragment key={option.id ?? option.inputValue}>
                        {option.newCreated && <Divider />}
                        <ListItem {...props}>
                            <ListItemText
                                primary={(
                                    <Typography fontWeight={option.newCreated ? "600" : undefined}>
                                        {labelDisplayName()}
                                    </Typography>
                                )}
                            />
                        </ListItem>
                        {!isLastElement && !option.newCreated && <Divider />}
                        {numberOfPages > 1 && isLastElement && (
                            <>
                                <Divider />
                                <ListItem>
                                    <ListItemText primary="" />
                                    <Box display="flex" width="100%">
                                        {hasPreviousPage && (
                                            <Box mr="auto">
                                                <IconButton onClick={onPrevPageClicked}>
                                                    <ChevronLeftIcon />
                                                </IconButton>
                                            </Box>
                                        )}
                                        {hasNextPage && (
                                            <Box ml="auto">
                                                <IconButton onClick={onNextPageClicked}>
                                                    <ChevronRightIcon />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>
                                </ListItem>
                            </>
                        )}
                    </Fragment>
                );
            }}
        />
    );
};

SearchBox.defaultProps = {
    optionsPerPage: 5
};
