import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Menu,
    Radio,
    RadioGroup
} from "@mui/material";
import {
    propertySortByFieldAtom,
    propertySortDirectionAtom,
    shouldPinOfflinePropertiesAtom
} from "../../../lib/property/state/PropertySortRecoilState";
import {
    useRef,
    useState
} from "react";

import { PropertySortByField } from "../../../lib/property/sorting/PropertySortByField";
import { SortDirection } from "../../../lib/sorting/SortDirection";
import SortIcon from '@mui/icons-material/Sort';
import { useRecoilState } from "recoil";

export const PropertySortMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [sortByField, setSortByField] = useRecoilState<PropertySortByField>(propertySortByFieldAtom);
    const [sortDirection, setSortDirection] = useRecoilState<SortDirection>(propertySortDirectionAtom);
    const [shouldPinOfflineProperties, setShouldPinOfflineProperties] = useRecoilState<boolean>(shouldPinOfflinePropertiesAtom);

    const sortByFieldRef = useRef<PropertySortByField>(sortByField);
    const sortDirectionRef = useRef<SortDirection>(sortDirection);
    const shouldPinOfflinePropertiesRef = useRef<boolean>(shouldPinOfflineProperties);

    const isMenuOpen = Boolean(anchorEl);
    const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const onCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleApply = () => {
        setSortByField(sortByFieldRef.current);
        setSortDirection(sortDirectionRef.current);
        setShouldPinOfflineProperties(shouldPinOfflinePropertiesRef.current);
        onCloseMenu();
    };

    return (
        <>
            <IconButton
                onClick={onOpenMenu}
            >
                <SortIcon />
            </IconButton>
            <Menu
                open={isMenuOpen}
                anchorEl={anchorEl}
                onClose={onCloseMenu}
            >
                <Box px={2}>
                    <Grid container>
                        <Grid item xs={6}>
                            <FormControl>
                                <FormLabel>Sort By</FormLabel>
                                <RadioGroup
                                    defaultValue={sortByField}
                                    onChange={(event) => sortByFieldRef.current = event.target.value as PropertySortByField}
                                >
                                    <FormControlLabel value={PropertySortByField.UPDATED_AT} control={<Radio />} label="Recently Updated" />
                                    <FormControlLabel value={PropertySortByField.CREATED_AT} control={<Radio />} label="Recently Created" />
                                    <FormControlLabel value={PropertySortByField.ADDRESS} control={<Radio />} label="Address" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl>
                                <FormLabel>Direction</FormLabel>
                                <RadioGroup
                                    defaultValue={sortDirection}
                                    onChange={(event) => sortDirectionRef.current = event.target.value as SortDirection}
                                >
                                    <FormControlLabel value={SortDirection.DESCENDING} control={<Radio />} label="Descending" />
                                    <FormControlLabel value={SortDirection.ASCENDING} control={<Radio />} label="Ascending" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} justifyContent={"flex-start"} container>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked={shouldPinOfflineProperties}
                                        onChange={(event) => shouldPinOfflinePropertiesRef.current = event.target.checked}
                                    />
                                }
                                label={"Pin Offline Properties to top"}
                            />
                        </Grid>
                        <Grid item xs={12} justifyContent={"flex-end"} container>
                            <Button
                                variant="contained"
                                onClick={handleApply}
                            >
                                Apply
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Menu>
        </>
    );
};