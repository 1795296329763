import {
    DatePicker,
    LocalizationProvider
} from "@mui/x-date-pickers";
import {
    Stack,
    TextField
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface DateFilterProps {
    createdAtStartDateField: number | null;
    createdAtEndDateField: number | null;
    onDateChange: (from: number | null, to: number | null) => void;
}

export const DateFilter = (props: DateFilterProps) => {
    const {
        onDateChange,
        createdAtStartDateField,
        createdAtEndDateField
    } = props;

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
            >
                <DatePicker
                    disableFuture
                    label="From"
                    value={createdAtStartDateField}
                    onChange={(newValue) => onDateChange(newValue, createdAtEndDateField)}
                    renderInput={(params) => <TextField size="small" {...params} />}
                />
                <DatePicker
                    disableFuture
                    disableMaskedInput
                    label="To"
                    value={createdAtEndDateField}
                    onChange={(newValue) => onDateChange(createdAtStartDateField, newValue)}
                    renderInput={(params) => <TextField size="small" {...params} />}
                />
            </LocalizationProvider>
        </Stack>
    );
};
