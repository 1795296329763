import { DataStore } from "aws-amplify";
import { DataStoreCompletionEventListener } from "./DataStoreCompletionEventListener";
import { DataStoreConfigurationManager } from "../configuration/DataStoreConfigurationManager";
import { DataStoreSyncResult } from "../DataStoreSynchronizationConstants";
import { SetterOrUpdater } from "recoil";

export class DataStoreLifecycleController {
    private configurationManager: DataStoreConfigurationManager;
    private dataStoreCompletionEventListener: DataStoreCompletionEventListener;
    private setIsDataStoreStopped: SetterOrUpdater<boolean>;

    constructor(
        configurationManager: DataStoreConfigurationManager,
        dataStoreCompletionEventListener: DataStoreCompletionEventListener,
        setIsDataStoreStopped:SetterOrUpdater<boolean>
    ) {
        this.configurationManager = configurationManager;
        this.dataStoreCompletionEventListener = dataStoreCompletionEventListener;
        this.setIsDataStoreStopped = setIsDataStoreStopped;
    }

    /**
     * DataStore.configure should only be called once at startup. Any changes to configuration should dynamically
     * happen in HierarcicalDataStoreConfigurationManager
     */
    public async configure(): Promise<void> {
        const configuration = await this.configurationManager.getConfiguration();
        DataStore.configure(configuration);
    }

    public async synchronize(): Promise<DataStoreSyncResult> {
        this.setIsDataStoreStopped(true);
        await DataStore.stop();
        // Listener needs to be set up before starting datastore to ensure it doesn't miss 
        // the datastore ready event in the time it takes to set up the listener
        const result = this.dataStoreCompletionEventListener.waitForDataStoreReady();
        const startDataStorePromise = DataStore.start();
        this.setIsDataStoreStopped(false);
        await startDataStorePromise;
        return await result;
    }
}