import {
    Box,
    Grid,
    Typography,
    TypographyProps
} from "@mui/material";

import { ExpandableDisplayText } from "../misc/ExpandableDisplayText";
import { Property } from "../../models";
import { formatPhoneNumber } from 'react-phone-number-input';
import { propertyIdToPropertySelectorFamily } from "../../lib/property/state/PropertyRecoilState";
import { useRecoilValue } from "recoil";

type PropertyAttributeProps = {
    readonly propertyId: string;
};

const H1_TYPOGRAPHY_STYLE: TypographyProps = {
    fontSize: "1rem",
};

const H2_TYPOGRAPHY_STYLE: TypographyProps = {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "text.secondary"
};

const PropertyAttribute = (props: PropertyAttributeProps) => {
    /* Internal page state */
    const { propertyId } = props;
    const property = useRecoilValue<Property | null>(propertyIdToPropertySelectorFamily(propertyId));

    return (
        <>
            <Box
                display="grid"
                gridTemplateColumns="max-content auto"
                gap={2}
                flex={1}
            >
                <Box>
                    <Typography sx={H2_TYPOGRAPHY_STYLE} noWrap>
                        Property Address:
                    </Typography>
                </Box>
                <Grid
                    item
                    xs={7}
                >
                    <ExpandableDisplayText
                        typographyProps={{ sx: H1_TYPOGRAPHY_STYLE }}
                        description={property?.address || "N/A"}
                        linesToDisplayWhenCollapsed={2}
                    />
                </Grid>
                <Box>
                    <Typography sx={H2_TYPOGRAPHY_STYLE} noWrap>
                        Property Name:
                    </Typography>
                </Box>
                <Box>
                    <ExpandableDisplayText
                        typographyProps={{ sx: H1_TYPOGRAPHY_STYLE }}
                        description={property?.name || "N/A"}
                        linesToDisplayWhenCollapsed={2}
                    />
                </Box>
                <Box>
                    <Typography sx={H2_TYPOGRAPHY_STYLE} noWrap>
                        Description:
                    </Typography>
                </Box>
                <Box>
                    <ExpandableDisplayText
                        typographyProps={{ sx: H1_TYPOGRAPHY_STYLE }}
                        description={property?.description || "N/A"}
                        linesToDisplayWhenCollapsed={2}
                    />
                </Box>
            </Box>
            <Box
                display="grid"
                gridTemplateColumns="1fr 1fr"
                gap={2}
                flex={1}
            >
                <Box gridColumn="1/-1">
                    <Typography variant="h6">
                        Primary Contact
                    </Typography>
                </Box>
                <Box display="flex" flexWrap="wrap" columnGap={2}>
                    <Typography sx={H2_TYPOGRAPHY_STYLE}>
                        Contact Name:
                    </Typography>
                    <ExpandableDisplayText
                        typographyProps={{ sx: H1_TYPOGRAPHY_STYLE }}
                        description={property?.primaryContactName || "N/A"}
                        linesToDisplayWhenCollapsed={2}
                    />
                </Box>
                <Box display="flex" flexWrap="wrap" columnGap={2}>
                    <Typography sx={H2_TYPOGRAPHY_STYLE}>
                        Contact Number:
                    </Typography>
                    <ExpandableDisplayText
                        typographyProps={{ sx: H1_TYPOGRAPHY_STYLE }}
                        description={property?.primaryContactNumber ? formatPhoneNumber(property?.primaryContactNumber) : "N/A"}
                        linesToDisplayWhenCollapsed={2}
                    />
                </Box>
                <Box display="flex" flexWrap="wrap" gridColumn="1 / -1" columnGap={2}>
                    <Typography sx={H2_TYPOGRAPHY_STYLE}>
                        Contact Email:
                    </Typography>
                    <ExpandableDisplayText
                        typographyProps={{ sx: H1_TYPOGRAPHY_STYLE }}
                        description={property?.primaryContactEmail || "N/A"}
                        linesToDisplayWhenCollapsed={2}
                    />
                </Box>
            </Box>
        </>
    );
};

export default PropertyAttribute;
