import {
    Box,
    IconButton,
    useMediaQuery
} from '@mui/material';
import {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { CameraController } from './CameraController';
import CloseIcon from '@mui/icons-material/Close';
import SwitchVideoIcon from '@mui/icons-material/SwitchVideo';
import cameraStyle from '../../../assets/style/cameraStyle';

interface CameraProps {
    automaticControl?: boolean;
    controller?: CameraController;
    onPictureTaken(image: string): Promise<void>;
    onClose(): void;
}

const Camera = (props: CameraProps) => {
    const automaticControl = props.automaticControl ?? false;
    const controller = props.controller;
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isCameraReady, setIsCameraReady] = useState<boolean>(false);
    const [snapshots, setSnapshots] = useState<string[]>([]);
    const [flash, setFlash] = useState<boolean>(false);
    const classes = cameraStyle();
    const isLandscape = useMediaQuery("(orientation: landscape)");
    const [stream, setStream] = useState<MediaStream | undefined>(undefined);
    const streamRef = useRef<MediaStream | undefined>(stream);
    streamRef.current = stream;

    const currentDeviceFacingMode = useMemo<string | undefined>(() => {
        if (!stream) {
            return undefined;
        }
        return stream.getVideoTracks()[0].getSettings().facingMode;
    }, [stream]);

    useEffect(() => {
        startStream("environment");
        return () => {
            streamRef.current?.getTracks().forEach((track) => track.stop());
        };
    }, []);

    const startStream = async (facingMode: string) => {
        try {
            streamRef.current?.getTracks().forEach((track) => track.stop());
            const newMediaStream: MediaStream = await navigator.mediaDevices.getUserMedia({
                video: {
                    width: { ideal: 768 },
                    height: { ideal: 480 },
                    frameRate: { ideal: 30, max: 60 },
                    facingMode: { ideal: facingMode }
                }
            });
            setStream(newMediaStream);
            if (videoRef.current) {
                videoRef.current.srcObject = newMediaStream;
            }
            setIsCameraReady(true);
        } catch (err) {
            console.error('Error accessing camera', err);
        }
    };

    const handleSwitchCamera = () => {
        try {
            if (!currentDeviceFacingMode) {
                return;
            }
            switch (currentDeviceFacingMode) {
                case 'user':
                    startStream('environment');
                    break;
                case 'environment':
                    startStream('user');
                    break;
                default:
                    startStream('environment');
                    break;
            }
        } catch (err) {
            console.error('Error switching camera', err);
        }
    };

    useEffect(() => {
        if (controller) {
            controller.connectCamera(handleTakeSnapshot);
        }
    }, [controller]);

    const handleTakeSnapshot = () => {
        const canvas = document.createElement('canvas');
        if (videoRef.current) {
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const ctx = canvas.getContext('2d');
            const isSelfie = !currentDeviceFacingMode || currentDeviceFacingMode === "user";
            if (isSelfie) {
                ctx?.translate(canvas.width, 0);
                ctx?.scale(-1, 1);
            }
            ctx?.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
            const newSnapshot = canvas.toDataURL('image/jpeg', 0.9);
            if (automaticControl) {
                setSnapshots((prevSnapshots) => [...prevSnapshots, newSnapshot].slice(-3));
                setFlash(true);
                setTimeout(() => setFlash(false), 100);
                return newSnapshot;
            }
            props.onPictureTaken(newSnapshot);
            setSnapshots((prevSnapshots) => [...prevSnapshots, newSnapshot].slice(-3));
            setFlash(true);
            setTimeout(() => setFlash(false), 100);
        }
        return "";
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.videoWrapper}>
                <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    className={classes.video}
                    style={{ transform: currentDeviceFacingMode !== "environment" ? "rotateY(180deg)" : "" }}
                />
                {snapshots.map((snapshot, index) => (
                    <img
                        key={index}
                        src={snapshot}
                        alt={`Snapshot ${index}`}
                        className={classes.snapshot}
                        style={{ transform: `translate(-${index * 10}px, -${index * 10}px)` }}
                    />
                ))}
                {!automaticControl &&
                    <>
                        <IconButton
                            onClick={props.onClose}
                            className={classes.closeButton}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                        <IconButton
                            onClick={handleTakeSnapshot}
                            className={isLandscape ? classes.landscapeCameraButton : classes.portraitCameraButton}
                            disabled={!isCameraReady}
                            size="large"
                        >
                            <CameraAltIcon />
                        </IconButton>
                    </>
                }
                <IconButton
                    onClick={props.onClose}
                    className={classes.closeButton}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
                {currentDeviceFacingMode && (
                    <IconButton
                        onClick={handleSwitchCamera}
                        className={classes.switchButton}
                        disabled={!isCameraReady}
                        size="large"
                    >
                        <SwitchVideoIcon />
                    </IconButton>
                )}
                <IconButton
                    onClick={handleTakeSnapshot}
                    className={isLandscape ? classes.landscapeCameraButton : classes.portraitCameraButton}
                    disabled={!isCameraReady}
                    size="large"
                >
                    <CameraAltIcon />
                </IconButton>
                {flash && <Box sx={{ pointerEvents: "none" }} className={classes.flash} />}
            </Box>
        </Box>
    );
};

export default Camera;
