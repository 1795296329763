import {
    RecoilState,
    Snapshot,
    atom,
    selector,
    useRecoilCallback
} from "recoil";

import CleanUpUserSessionStateError from "./error/CleanUpUserSessionStateError";
import DataStoreUserOrganizationAssociationDAOFactory from "../../organization/DataStoreUserOrganizationAssociationDAOFactory";
import SetUpUserSessionStateError from "./error/SetUpUserSessionStateError";
import { UserOrganizationAssociation } from "../../../models";
import UserOrganizationAssociationDAO from "../../organization/UserOrganizationAssociationDAO";
import { UserSessionStatus } from "./UserSessionStatus";

const userOrganizationAssociationDao: UserOrganizationAssociationDAO = DataStoreUserOrganizationAssociationDAOFactory.getInstance();

/* User Session Operations */
export const getSetUpUserSessionStateCallback = () => useRecoilCallback(({ set, snapshot }) => {
    return async (userName: string) => {
        try {
            await setUpUserSessionState(snapshot, set, userName);
        } catch (error) {
            throw new SetUpUserSessionStateError(
                "Error occurred while trying to set up user session state",
                { cause: error as Error }
            );
        }
    };
});

const setUpUserSessionState = async (
    snapshot: Snapshot,
    set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void,
    userName: string
): Promise<void> => {
    const userOrganizationAssociations: Array<UserOrganizationAssociation> = await userOrganizationAssociationDao.listUserOrganizationAssociationsByUserId(userName);
    set(actingAsEntityIdAtom, userOrganizationAssociations[0].organizationId);
    set(userSessionReadinessStatusAtom, UserSessionStatus.READY);
    set(userIdAtom, userName);
};

export const getCleanUpUserSessionStateCallback = () => useRecoilCallback(({ snapshot, reset }) => {
    return () => {
        try {
            cleanUpUserSessionState(snapshot, reset);
        } catch (error) {
            throw new CleanUpUserSessionStateError(
                `Error occurred while trying to clean up user session state`,
                { cause: error as Error }
            );
        }
    };
});

const cleanUpUserSessionState = (
    snapshot: Snapshot,
    reset: (recoilVal: RecoilState<any>) => void
) => {
    reset(actingAsEntityIdAtom);
    reset(userSessionReadinessStatusAtom);
    reset(userIdAtom);
};

/* User Session States */

export const userSessionReadinessStatusAtom = atom<UserSessionStatus>({
    key: "userSessionReadinessStatusAtom",
    default: UserSessionStatus.NOT_READY
});

export const isUserSessionReadySelector = selector<boolean>({
    key: "isUserSessionReadySelector",
    get: ({ get }) => {
        const userSessionStatus: UserSessionStatus = get(userSessionReadinessStatusAtom);
        return userSessionStatus !== UserSessionStatus.NOT_READY;
    }
});

export const userIdAtom = atom<string | undefined>({
    key: "userIdAtom",
    default: undefined
});

/**
 * The ID of Team that user is acting as
 */
export const actingAsEntityIdAtom = atom<string>({
    key: "actingAsEntityIdAtom",
    default: ""
});