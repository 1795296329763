import type { SVGProps } from "react";
import { withCustomIcon } from "./withCustomIcon";

const CircleTickIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="tenera-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm-.74 16.401-1.49.032-3.501-3.75 1.462-1.365 2.738 2.933L17.24 7.35l1.52 1.302-7.5 8.749z"
      className="primary"
    />
  </svg>
);
export default withCustomIcon(CircleTickIcon);
