/**
 * Class used to generate a default name for a location.
 *      NamePrefix - The portion of the name which is shared between different locations, e.g. "Solution Group"
 *      getLocationNumber - callback method used to get the correct number to use for the new location
 */
export default class LocationDefaultNameFactory {
    private namePrefix: string;
    private getLocationNumber: () => Promise<number>;

    constructor(
        namePrefix: string,
        getLocationNumber: () => Promise<number>,
    ) {
        this.namePrefix = namePrefix;
        this.getLocationNumber = getLocationNumber;
    }

    public async getName(): Promise<string> {
        const locationNumber = await this.getLocationNumber();
        return `${this.namePrefix} ${locationNumber}`;
    }
}
