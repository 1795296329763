import {
    Box,
    Button,
    Typography
} from "@mui/material";
import {
    ProviderContext,
    useSnackbar
} from "notistack";
import {
    filterCreatedAtEndDateAtom,
    filterCreatedAtStartDateAtom,
    getResetIssueFilter,
    isFilterAppliedAtom,
    issueStatusFilterConfigAtom
} from "../../../lib/issue/state/IssueFilterRecoilState";
import {
    useEffect,
    useState
} from "react";
import {
    useRecoilState,
    useSetRecoilState
} from "recoil";

import { BLACK_OUTLINED_BUTTON } from "../../../components/general/button/ButtonStyles";
import { DateFilter } from "./DateFilter";
import { IssueStatus } from "../../../models";
import { StatusFilter } from "./StatusFilter";

const DEFAULT_STATUS_FILTER_STATE = new Map<IssueStatus, boolean>([
    [IssueStatus.NONE, true],
    [IssueStatus.ARCHIVED, false],
    [IssueStatus.UNDER_REVIEW, true],
    [IssueStatus.APPROVED, true],
    [IssueStatus.REJECTED, true],
    [IssueStatus.ON_HOLD, true]
]);

interface IssueFilterProps {
    onFilter?: () => void;
    onReset?: () => void;
}

export const IssueFilter = (props: IssueFilterProps) => {
    const [isFilterApplied, setIsFilterApplied] = useRecoilState<boolean>(isFilterAppliedAtom);
    const [createdAtStartDateField, setCreatedAtStartDateField] = useState<number | null>(null);
    const [createdAtEndDateField, setCreatedAtEndDateField] = useState<number | null>(null);
    const [internalStatusFilterState, setInternalStatusFilterState] = useState<Map<IssueStatus, boolean>>(DEFAULT_STATUS_FILTER_STATE);

    /* Menu states */
    const setCreatedAtStartDate = useSetRecoilState<number | null>(filterCreatedAtStartDateAtom);
    const setCreatedAtEndDate = useSetRecoilState<number | null>(filterCreatedAtEndDateAtom);
    const setStatusFilterState = useSetRecoilState<Map<IssueStatus, boolean>>(issueStatusFilterConfigAtom);

    /* Dependencies */
    const resetFilter = getResetIssueFilter();
    const snackbar: ProviderContext = useSnackbar();

    // Reset the fields if filter reset
    useEffect(() => {
        if (!isFilterApplied) {
            setCreatedAtStartDateField(null);
            setCreatedAtEndDateField(null);
            setInternalStatusFilterState(DEFAULT_STATUS_FILTER_STATE);
        }
    }, [isFilterApplied]);

    const onFilter = () => {
        setIsFilterApplied(true);
        setCreatedAtStartDate(createdAtStartDateField);
        setCreatedAtEndDate(createdAtEndDateField);
        setStatusFilterState(internalStatusFilterState);
        if(props.onFilter) {
            props.onFilter();
        }
    };

    const onReset = () => {
        resetFilter();
        if (props.onReset) {
            props.onReset();
        }
    };

    const isDateRangeValid = (from: number | null, to: number | null) => {
        return (!from || !to || from <= to);
    };

    const onDateChange = (from: number | null, to: number | null) => {
        if (isDateRangeValid(from, to)) {
            setCreatedAtStartDateField(from);
            setCreatedAtEndDateField(to);
        } else {
            snackbar.enqueueSnackbar(
                "\"From\" Date should before \"To\" Date",
                { variant: "warning" }
            );
        }
    };

    const onStatusFilterChange = (status: IssueStatus, isChecked: boolean) => {
        setInternalStatusFilterState((original) => {
            const updated = new Map(original);
            updated.set(status, isChecked);
            return updated;
        });
    };

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Typography>
                Issues Created Date
            </Typography>
            <DateFilter
                createdAtStartDateField={createdAtStartDateField}
                createdAtEndDateField={createdAtEndDateField}
                onDateChange={onDateChange}
            />
            <Typography>
                Issue Status
            </Typography>
            <StatusFilter
                statusFilterState={internalStatusFilterState}
                onStatusFilterChange={onStatusFilterChange}
            />
            <Box display="flex" justifyContent="space-around" gap={2}>
                <Button
                    fullWidth
                    variant="outlined"
                    color="warning"
                    onClick={onReset}
                    sx={{
                        ...BLACK_OUTLINED_BUTTON
                    }}
                    size="small"
                >
                    Reset Filters
                </Button>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={onFilter}
                    size="small"
                >
                    Apply Filters
                </Button>
            </Box>
        </Box>
    );
};